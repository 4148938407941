<template>
 <div class="card incident-history">
        <div class="card-header">Caller Id Details
            </span>
             <button type="button"  class="btn btn-primary btn-sm float-right" style="margin-left: 10px; color: #fff" >
                             <a href="javascript:" style="color: #fff;" v-b-modal.addData>Add Caller Id</a> 

                            <!-- <a href="javascript:;" data-toggle="modal" data-target="#SaveCallerId" style="color: #fff;">Add Caller Id</a> -->
                        </button>
        </div>
        <div class="card-body">
            <div class="row col-md-12 col-sm-6 col-12 mx-0 mt-4">
                <div class="table-responsive">
                    <table class="table table-borderless all-table">
                        <thead>
                            <tr>
                                <th>Account ID</th>
                                <th>Phone Number</th>
                                <th>Caller ID</th>
                                 <th>Country Code</th>
                                <th>Action</th>                               
                            </tr>
                        </thead>
                        <tbody id="table-length">
                            <tr v-if=" callerList != null &&  callerList.length == 0">
                                <td colspan="5" align="center">No activity found recently</td>
                            </tr>
                            <template v-for="(call, index) in callerList" >
                            <tr>
                                <td>{{ call.accountId}}</td>
                                <td> {{ call.phoneNumber}}</td>
                                <td>{{ call.callerId}}</td>
                                <td>{{ call.countryCode}}</td>
                                 <td>
                                            <ul class="navbar-nav">
                                                <li class="nav-item dropdown">
                                                    <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                        <li>
                                                            <!-- <a class="dropdown-item" href="javascript:" @click="deleteCallerData(call.id)" data-toggle="modal" data-target="#EditCallerId"><span class="ti-pencil"></span> Edit</a> -->
                                                            <a class="dropdown-item" href="javascript:" @click="setEditCallerData(call)" v-b-modal.editData><span class="ti-pencil pr-2" ></span>Edit</a> 
                                                            <a class="dropdown-item" href="javascript:;" @click="deleteCaller(call.id)" data-toggle="modal" data-target="#DeleteCallerId"><span class="ti-close"></span> Delete Caller Id</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </td>                           
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-12">
                <b-pagination size="md" class='pull-right'></b-pagination>
            </div>
            <div>
                <b-modal ref="save-my-modal" @hide="clearData" id="SaveCallerId"  hide-footer title="Add Caller Id" size="md" ok-only ok-title="Save" >
                    <div  class="modal-body">
                    
                     <div class="col-md-6 col-sm-6 col-6">
                      <div>
                     <label class="callable"> Account ID: </label>
                      <input type="text" class="form-control callable" v-model="callerinfo.accountId" />   
                       <span class="required-icon"></span>
                        </div>   
                         <label v-if="$v.callerinfo.accountId.$error && !$v.callerinfo.accountId.required" class="text-danger">Account Id is required</label>
                         <label v-if="$v.callerinfo.accountId.$error && !$v.callerinfo.accountId.minLength" class="text-danger">Account Id must've minimum 2 characters</label>
                          <label v-if="$v.callerinfo.accountId.$error && !$v.callerinfo.accountId.maxLength" class="text-danger">Account Id limit is 30 characters</label>                                            
                     </div>

                     </br>
                     <div class="col-md-6 col-sm-6 col-6">
                     <div>
                     <label class="callable"> Phone Number: </label>   
                      <input type="text" class="form-control callable" v-model="callerinfo.phoneNumber" /> 
                       <span class="required-icon"></span>
                        <label v-if="$v.callerinfo.phoneNumber.$error && !$v.callerinfo.phoneNumber.required" class="text-danger">Phone Number is required</label>
                         <label v-if="$v.callerinfo.phoneNumber.$error && !$v.callerinfo.phoneNumber.minLength" class="text-danger">Phone Number must've minimum 2 characters</label>
                          <label v-if="$v.callerinfo.phoneNumber.$error && !$v.callerinfo.phoneNumber.maxLength" class="text-danger">Phone Number limit is 30 characters</label>  
                          </div>   
                     </div>

                     </br>
                      <div class="col-md-6 col-sm-6 col-6">
                      <div>
                     <label class="callable"> Caller ID: </label>   
                      <input type="text" class="form-control callable" v-model="callerinfo.callerId"/> 
                      <span class="required-icon"></span>
                       <label v-if="$v.callerinfo.callerId.$error && !$v.callerinfo.callerId.required" class="text-danger">Caller Id is required</label>
                         <label v-if="$v.callerinfo.callerId.$error && !$v.callerinfo.callerId.minLength" class="text-danger">Caller Id must've minimum 2 characters</label>
                          <label v-if="$v.callerinfo.callerId.$error && !$v.callerinfo.callerId.maxLength" class="text-danger">Caller Id limit is 30 characters</label>  
                           </div>   
                     </div>

                      </br>
                      <div class="col-md-6 col-sm-6 col-6">
                      <div>
                     <label class="callable">Country Code : </label>   
                      <input type="text" class="form-control callable" v-model="callerinfo.countryCode"/>
                      <span class="required-icon"></span> 
                       <label v-if="$v.callerinfo.callerId.$error && !$v.callerinfo.callerId.required" class="text-danger">Country Code  is required</label>
                         <label v-if="$v.callerinfo.callerId.$error && !$v.callerinfo.callerId.minLength" class="text-danger">Country Code  must've minimum 2 characters</label>
                          <label v-if="$v.callerinfo.callerId.$error && !$v.callerinfo.callerId.maxLength" class="text-danger">Country Code  limit is 30 characters</label> 
                           </div>    
                     </div>
                     </div>
                    
                    <div class="modal-footer">
                    <a href="Javascript:void(0);" class="btn btn-primary  float-right" @click="saveCallerInfo" >Save</a>
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="hideModal"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </b-modal>
            </div>

             <div>
                <b-modal ref="my-modal" id="EditCallerId"  hide-footer title="Edit Caller Id" size="md">
                    <div  class="modal-body">
                    
                     <div class="col-md-6 col-sm-6 col-6">
                     <label class="callable"> Account ID: </label>
                      <input type="text" class="form-control callable" v-model="editcallerinfo.accountId" />                                                 
                     </div>
                     </br>
                     <div class="col-md-6 col-sm-6 col-6">
                     <label class="callable"> Phone Number: </label>   
                      <input type="text" class="form-control callable" v-model="editcallerinfo.phoneNumber" /> 
                     </div>
                     </br>
                      <div class="col-md-6 col-sm-6 col-6">
                     <label class="callable"> Caller ID: </label>   
                      <input type="text" class="form-control callable" v-model="editcallerinfo.callerId"/> 
                     </div>
                      </br>
                      <div class="col-md-6 col-sm-6 col-6">
                     <label class="callable">Country Code : </label>   
                      <input type="text" class="form-control callable" v-model="editcallerinfo.countryCode"/> 
                     </div>
                     </div>
                    
                    <div class="modal-footer">
                    <a href="Javascript:void(0);" class="btn btn-primary  float-right" @click="editCallerInfo" :returnParams="editcallerinfo">Save</a>
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="hideModal"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </b-modal>
                <Dialog :onYes="deleteCallerInfo" :returnParams="delCallerParam" id="DeleteCallerId" title="Delete Caller Id Confirmation" message="Are you sure to delete ?" />
                <EditCallerDialog ref="editCallerRef" @refresh-caller="getCallerInfo"  :callData="editcallerinfo" />  
                <AddCallerDialog ref="addCallerRef" @refresh-caller="getCallerInfo"/>  
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import EditCallerDialog from '../editrecruiter/EditCaller';
import AddCallerDialog from '../editrecruiter/AddCaller';
import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
export default {

      components: {
        EditCallerDialog,
        AddCallerDialog,
    },

     props: {
       
    },
    data() {
        return {
      callerinfo: {
                accountId: '',
                phoneNumber: '',
                callerId: '',
                countryCode: '',
                recruiterId:this.$store.getters.currentUser.profileId
               
            }, 
            editcallerinfo: {
                accountId: '',
                phoneNumber: '',
                callerId: '',
                countryCode: '',
                recruiterId:this.$store.getters.currentUser.profileId,
                id:0
               
            },  
            callerList: [],  
            delCallerParam: {
                index: -1,
                id: 0
            },       

        }
    },
     validations: {
        callerinfo: {
            accountId: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
             phoneNumber: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
             callerId: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
             countryCode: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            }
        }
    },

     mounted() {

        //  this.getCallerInfo();
    },


    methods: {

         saveCallerInfo() {
             this.$v.callerinfo.$touch();
             if(!this.$v.callerinfo.$invalid){
            axios.post(this.$store.getters.getAPIBasePath + '/exotel/add', this.callerinfo)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {      
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CliInfoCreSuc,
                        position: 'topRight'
                       
                    });
                    this.$emit('loadRecruiter');
                     this.$refs.save-my-modal.hide();
                    }
                })
             }
        },
        deleteCaller(callerId) {
            //this.delTechSkillParam.index=arg1;
            this.delCallerParam.id = callerId;
            return;
        },
        // deleteCallerData(callerId) {
        //     //this.delTechSkillParam.index=arg1;
        //     this.editcallerinfo.id = callerId;
        //     return;
        // },

         setEditCallerData:function(call){
            this.editcallerinfo.accountId = call.accountId;
            this.editcallerinfo.phoneNumber = call.phoneNumber;
            this.editcallerinfo.callerId = call.callerId;
            this.editcallerinfo.countryCode = call.countryCode;  
            this.editcallerinfo.recruiterId = this.$store.getters.currentUser.profileId;
            this.editcallerinfo.id = call.id;
            this.$refs.editCallerRef.setData(this.editcallerinfo);
        },

         editCallerInfo() {

            axios.put(this.$store.getters.getAPIBasePath + '/exotel/update', this.editcallerinfo)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {      
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CliInfoCreSuc,
                        position: 'topRight'
                       
                    });
                     this.hideModal();
                    }
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliCreErr,
                        position: 'topRight'
                    });
                });
        },
        deleteCallerInfo() {

            axios.put(this.$store.getters.getAPIBasePath + '/exotel/delete/'+ this.delCallerParam.id)
                .then(response => {
                        
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CliInfoCreSuc,
                        position: 'topRight'    
                    });
                     this.getCallerInfo();
                  
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliCreErr,
                        position: 'topRight'
                    });
                });
                console.log("checking refresh")
                 this.$emit('refresh-caller');
        },
           getCallerInfo() {
               let data = {
                   'recruiterid':1
               }
            axios.get(this.$store.getters.getAPIBasePath + '/exotel/getdata/'+this.$store.getters.currentUser.profileId)
                .then(response => {
                    //clientid = response.data.id;
                     this.callerList = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliCreErr,
                        position: 'topRight'
                    });
                });
        },

         hideModal() {
        this.$refs['my-modal'].hide()
      },
       clearData:function(){

        },

    }

}

</script>
