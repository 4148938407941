<template>
    <div>
        <b-modal id="AssignjobModal" class="h-25" :no-close-on-backdrop="true" ref="resumeModal" title="Assign Job" @show="" @hidden="resetModal" @ok="addRecruiter" @cancel="" @close="">
            <div class="modal-dialog m-0" role="document">
                <div class="modal-content">
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-sm-12 recrutierinfo p-0">
                                <div v-if="elements.submitted && !$v.selected.recruiters.checkNull" class="errorcolor">Please select any recruiter to assign job.</div>
                                <div>
                                    <div>
                                        <p class="plr-5">Recruiter Name</p>
                                        <ul class="assign-job-list popup-height">
                                            <li v-for="(recruiter,index) in recruiterlist">
                                                <span>
                                                    <div class="col-sm-12 col-12">
                                                        <input class="form-check-input" type="checkbox" :value="recruiter.id" :id="'chckbox'+recruiter.id" v-model="selected.recruiters" v-on:click="getRecruiterId(recruiter.id,recruiter.email,recruiter.recruiterName,$event)">
                                                        <label class="form-check-label" :for="'chckbox'+recruiter.id">{{recruiter.recruiterName}}
                                                        </label>
                                                    </div>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="p-2">
                                        <div class="row mb-10">
                                            <div class="col-sm-4">
                                                <label>Project</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <multiselect v-model="selected.project" name="project" :options="projectList" label="projectName" track-by="id" select-label="" deselect-label="" class="" :max-height="600" placeholder="Select Project" :searchable="true" @select="getSprintList"></multiselect>
                                                <label v-if="$v.selected.project.$error && !$v.selected.project.required" class="text-danger">Please select project</label>
                                            </div>
                                        </div>
                                        <div class="row mb-10">
                                            <div class="col-sm-4">
                                                <label>Task Group</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <multiselect v-model="selected.taskGroup" name="taskGroup" :options="taskGroupList" label="name" track-by="id" select-label="" deselect-label="" class="" :max-height="600" placeholder="Select TaskGroupName" :searchable="true" @select="getTaskGroup"></multiselect>
                                                <label v-if="$v.selected.taskGroup.$error && !$v.selected.taskGroup.required" class="text-danger">Please select task group</label>
                                            </div>
                                        </div>
                                        <div class="row mb-10">
                                            <div class="col-sm-4">
                                                <label>Description</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <textarea rows="2" class="form-control" v-model="assignJob.description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import { required } from "vuelidate/lib/validators";
export default {
    components: {
        Multiselect
    },
    props: {
        //assignJob: Object
        assignJob: {
            jobId: "",
            recruiterId: [],
            description: "",
            jobTitle: "",
            recruiterEmail: [],
            recruiterName: []
        }
    },
    data() {
        return {
            recruiterlist: this.$store.getters.getRecruiterList,
            recruiters: [],
            projectList: [],
            selected: {
                project: [],
                taskGroupId: null,
                stageId: null,
                mode: "",
                taskGroup: null,
                recruiters: []
            },
            taskGroupList: [],
            saveTask: {
                taskGroupId: "",
                taskCode: "",
                taskType: "",
                stageId: 1,
                priorityId: "",
                title: "",
                description: "",
                assignedTo: "",
                userType: "recruiter",
                startDate: "",
                dueDate: "",
                taskReference: "",
                taskCheckList: [],
                ttClientAccountId: "",
                jobId: "",
                jobTitle: "",
                email: "",
                recruiterName: ""
            },
            selectedValue: "",
            elements: {
                submitted: false,
            },
            //saveFlag: true,
            assignJobList: [],
        }
    },
    validations: {
        saveTask: {
            taskGroupId: { required },
        },
        selected: {
            project: {
                required
            },
            taskGroup: {
                required
            },
            recruiters: {
                checkNull: function(userList) {
                    return userList.length > 0
                }
            }
        }
    },
    mounted() {
        this.loadProjects(),
            this.loadDate();
    },
    methods: {
        loadProjects() {
            axios.get(this.$store.getters.getAPIBasePath + '/projectresource/list/project')
                .then(response => {
                    if (response.data.status) {
                        this.projectList = response.data.projectList;
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        getRecruiterId: function(recruiterId, email, name, event) {
            if (event.target.checked) {
                this.assignJob.recruiterId.push(recruiterId);
                this.assignJob.recruiterEmail.push(email);
                this.assignJob.recruiterName.push(name);
                this.selected.recruiters.push(recruiterId);
            }
        },
        async addRecruiter(bvModalEvt) {
            this.elements.submitted = true;
            this.$v.selected.$touch();
            if (this.$v.selected.$invalid) {
                bvModalEvt.preventDefault();
                return;
            }
            if (this.assignJob != null) {
                await this.assignJobData();
                await this.addAssignJob(this.assignJobList);
            }
        },
        assignJobData: function() {
            this.assignJobList = [];
            for (var rowCount = 0; rowCount < this.assignJob.recruiterId.length; rowCount++) {
                let description = this.assignJob.description + "<p><a href='/job/plan-recruitment/" + this.assignJob.jobId + "'</a>" + this.assignJob.jobTitle + "</p>";
                this.assignJobList.push({
                    description: description,
                    assignedTo: this.assignJob.recruiterId[rowCount],
                    title: this.assignJob.jobTitle,
                    note: this.assignJob.description,
                    taskCode: "",
                    jobId: this.assignJob.jobId,
                    jobTitle: this.assignJob.jobTitle,
                    email: this.assignJob.recruiterEmail[rowCount],
                    recruiterName: this.assignJob.recruiterName[rowCount],
                    taskGroupId: this.saveTask.taskGroupId,
                    startDate: this.saveTask.startDate,
                    dueDate: this.saveTask.dueDate,
                    userType: "recruiter"
                });
            }

        },
        addAssignJob: function(data) {
            axios.post(this.$store.getters.getAPIBasePath + '/taskresource/assignjob', data)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message: this.$config.JobAssignSuc, position: 'topRight' });
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                });
        },
        getSprintList: function(project_info) {
            if (project_info.id != null) {
                axios.get(this.$store.getters.getAPIBasePath + '/projectresource/list/sprint/' + project_info.id)
                    .then(response => {
                        if (response.data.status) {
                            this.taskGroupList = response.data.taskGroupList;
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            }
        },
        getTaskGroup: function(taskGroup_info) {
            if (taskGroup_info.id != null) {
                this.saveTask.taskGroupId = taskGroup_info.id;
            }
        },
        loadDate() {
            this.saveTask.startDate = new Date().toISOString().substr(0, 10);
            this.saveTask.dueDate = new Date().toISOString().substr(0, 10);
        },
        resetModal() {
            this.selected.recruiters = [];
            this.selected.project = "";
            this.selected.taskGroup = "";
            this.assignJob.description = "";
            this.assignJob.recruiterId = [];
            this.assignJob.recruiterEmail = [];
            this.assignJob.recruiterName = [];
            this.$v.selected.$reset();
        }
    }
}

</script>
