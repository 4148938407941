<template>
    <div id="main" class="mt-80 d-height">
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :class="isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
                            <!--  <li><a href="" data-target="main-search" class="boxhide"><span class="ti-angle-down"></span></a></li> -->
                            </ul>
                        </div>
                    </div>
                    <form id="main-search" :class="isExpand?'d-block':'d-none'" class="pb-5">
                        <div class="row form-group ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Job Title</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" name="jobTitle" maxlength="50" value="" id="jobTitle" class="form-control" v-on:keyup.enter="findJobs" v-model="search.params.jobTitle" >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Job Code</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" v-model="search.params.jobCode" name="jobId" maxlength="20" value="" id="jobId" class="smallCombo form-control" v-on:keyup.enter="findJobs">
                                    </div>
                                </div>
                                </div>  
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Client</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                        <!-- <select name="selectedJobType" size="1" id="selectedJobType" class="smallCombo select2"><option value="" selected="selected">All</option></select> -->
                                        <select v-model="search.params.clientId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(data, index) in clientlist" :value="data.id">{{data.client_Name}}</option>
                                        </select>
                                </div>
                                </div>  
                            </div>
                            <!-- <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Industry</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select v-model="search.industry" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(data, index) in industrylist" :value="data.id">{{data.industryName}}</option>
                                            </select>
                                        </div>
                                </div>
                                </div>  
                            </div> -->
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Industry</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <select v-model="search.params.industryId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(data, index) in industrylist" :value="data.id">{{data.industryName}}</option>
                                            </select>
                                        </div>
                                    </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Position</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" v-model="search.params.position" name="jobId" maxlength="100" value="" id="jobId" class="smallCombo form-control" v-on:keyup.enter="findJobs">
                                    </div>
                                </div>
                                </div>  
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Status</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <select v-model="search.params.status" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(data, index) in jobStatuslist" :value="data.id">{{data.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                                <button type="button" @click="resetJobSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                <button type="button" @click="findJobs" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Find</button>
                                <!--  <button type="button" class="btn btn-primary btn-sm float-right advancesearch_btn" style="margin-left: 10px;">Advanced Search</button> -->
                            </div> 
                    </form>
                    <div class="load-indicator"></div>
                </div>
            </div>
            <div class="container-fluid" id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-6 col-sm-12 col-12">
                            <h4 class="page-title">Jobs
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{browsepagination.totalrecords}} - Total)</span>
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                    <div class="dropdown">
                                        <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>    
                                        <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                            <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                        </div>
                                    </div>
                                </span>
                            </h4>
                        </div>
                        <!-- <div class="col-md-6 d-none d-sm-none d-md-block">
                            <button type="button" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;"><router-link :to="{ name: 'addjob' }" style="color: #fff;">New Job</router-link></button>
                            <button type="button" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;"><router-link :to="{ name: 'progressinterviews' }" style="color: #fff;">Interviews</router-link></button>
                        </div> -->
                        <div v-if ="false" class="col-md-6 d-none d-sm-none d-md-block">
                            <ul class="ul-horizontal table-fields text-right">
                                <li>
                                    <span>Show / Hide</span>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="jobTitle" id="jobTitle" checked="">
                                    <label for="jobTitle"  v-on:click="coldisplay.jobTitle = !coldisplay.jobTitle"><span></span>Job Title</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="jobCode" id="jobCode" checked="">
                                    <label for="jobCode"  v-on:click="coldisplay.jobCode = !coldisplay.jobCode"><span></span>Job Code</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="companyName" id="companyName" checked="">
                                    <label for="companyName"  v-on:click="coldisplay.companyName = !coldisplay.companyName"><span></span>company Name</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="client" id="client" checked="">
                                    <label for="client"  v-on:click="coldisplay.client = !coldisplay.client"><span></span>Client</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="industry" id="industry" checked="">
                                    <label for="industry"  v-on:click="coldisplay.industry = !coldisplay.industry"><span></span>Industry</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="position" id="position" checked="">
                                    <label for="position"  v-on:click="coldisplay.position = !coldisplay.position"><span></span>Position</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col"></th>
                                            <th v-if="coldisplay.jobTitle" class="" scope="col">Job Title</th>
                                            <th v-if="coldisplay.jobCode" class="" scope="col">Code</th>
                                            <th v-if="coldisplay.companyName" class="" scope="col">Company Name</th>
                                            <th v-if="coldisplay.client" class="" scope="col">Client</th>
                                            <th v-if="coldisplay.industry" class="" scope="col">Industry</th>
                                            <th v-if="coldisplay.position" class="" scope="col">Position</th>
                                            <th class="" scope="col">Experience</th>
                                            <th class="" scope="col">Location</th>
                                            <th class="" scope="col">Vacancies</th>
                                            <th class="" scope="col">Work Type</th>
                                            <th class="" scope="col">Due Date</th>
                                            <th class="" scope="col">Status</th>
                                            <th class="" scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="joblist.length==0 && elements.loadingJobs == true ">
                                            <td colspan="26" class="text-center">No data found</td>
                                        </tr>
                                        <tr v-for="(jobinfo, index) in joblist">
                                            <td>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox" id="rowchckbox1" value="option1">
                                                    <label class="form-check-label" for="rowchckbox1"></label>
                                                </div>
                                            </td>
                                            <td v-if="coldisplay.jobTitle" class="">
                                                <router-link title="View Job" :to="{ name: 'planrecruitment', params: { jobid: jobinfo.id }}">{{jobinfo.jobTitle }}</router-link>
                                            </td>
                                            <td v-if="coldisplay.jobCode" class="">{{ jobinfo.jobCode }}</td>
                                            <td v-if="coldisplay.companyName" class="">{{ jobinfo.companyName }}</td>
                                            <td v-if="coldisplay.client" class="">{{ jobinfo.clientName }}</td>
                                            <td v-if="coldisplay.industry" class="">{{ jobinfo.industryName }}</td>
                                            <td v-if="coldisplay.position" class="">{{ jobinfo.position }}</td>
                                            <td class="">{{ jobinfo.experience }}</td>
                                            <td class="">{{ jobinfo.location }}</td>
                                            <td class="">{{ jobinfo.vacancies }}</td>
                                            <td class="">{{ jobinfo.workType }}</td>
                                            <td class="endDate" format="MM-dd-yyyy">{{ jobinfo.dueDate | formatDate }}</td>
                                            <td class="">{{ jobinfo.jobStatus}}</td>
                                            <td>
                                                <ul class="navbar-nav">
                                                    <li class="nav-item">
                                                        <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                            <li>
                                                                <router-link  class="dropdown-item" :to="{ name: 'planrecruitment', params: { jobid: jobinfo.id }}"><span class="ti-eye"></span> View Job</router-link>
                                                            </li>
                                                            <li>
                                                                <router-link class="dropdown-item" :to="{ name: 'editjob', params: { jobid: jobinfo.id }}"><span class="ti-pencil"></span> Edit Job</router-link>
                                                            </li>
                                                            <li v-if="false">
                                                                <router-link class="dropdown-item" :to="{ name: 'planrecruitment', params: { jobid: jobinfo.id }}"><span class="fa fa-users"></span> Recruitment</router-link>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item" href="javascript:;" @click="deleteJob(jobinfo.id)" data-toggle="modal" data-target="#removeJob"><span class="ti-cut"></span> Deactivate Job</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-12">                        
                            <b-pagination v-if="joblist.length!=0" size="md" class='pull-right' :current-page="browsepagination.currentpage" :total-rows="browsepagination.totalrecords" v-model="browsepagination.currentpage" :per-page="browsepagination.rowsPerPage" @input="loadJobs()"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div>
                    <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
                </div>                   
            </template> 
        </b-overlay>
        <Dialog id="removeJob" :onYes="delete_Job" :returnParams="delJobParam" title="Deactivate Confirmation" message="Are you sure to deactivate ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    computed:{
        recruiterId(){
            if(this.$store.getters.currentUser.profileId)
               return this.$store.getters.currentUser.profileId;
            else return 0;
        }
    },
    data() {
        return {
            showOverlay:false,
            isExpand:false,
            joblist: [],
            browsepagination: {
                totalrecords: 0,
                rowsPerPage: 10,
                currentpage: 1
            },
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            industrylist:this.$store.getters.getIndustries,
            clientlist:[],
            jobStatuslist: this.$store.getters.getJobStatus,
            elements: {
                showadvancedsearch: false,
                loadingJobs:false,
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    jobTitle: "",
                    jobType: "",
                    technologyId: "",
                    startDate: "",
                    endDate: "",
                    recruiterId:this.recruiterId,
                    clientId:"",
                    industryId:"",
                    status:"",
                    pageNo: "",
                    limit: ""
                },
            },
            coldisplay: {
                jobTitle: true,
                jobCode: true,
                companyName: true,
                client: true,
                industry: true,
                position: true
            },
            delJobParam:{
                index:-1,
                id:0
            }
        }
    },
    watch: {
        'showOverlay': {
            handler: function(value) { 
                if(value == true){
                    $('#globalapploader').css('visibility','hidden');
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.loadJobs();
        this.loadClientList();
    },
    methods: {
        loadClientList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/clientlist')
                .then(response => {
                    this.clientlist = response.data.clientlist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        resetJobSearch() {
            this.search.params = {
                jobTitle: "",
                jobType: "",
                technologyId: "",
                startDate: "",
                endDate: "",
                recruiterId:this.recruiterId,
                clientId:"",
                industryId:"",
                pageNo: "",
                limit: ""
            };
            this.loadJobs();
        },
        async loadJobs() {
            this.showOverlay=true;
            await this.getJobs();
            this.showOverlay=false;
        },
        getJobs() {
            this.showOverlay=true;
            this.search.params.pageNo = this.browsepagination.currentpage;
            this.search.params.limit = this.browsepagination.rowsPerPage;
            //return axios.post(this.$store.getters.getAPIBasePath + '/jobresource/joblist?page=' + this.browsepagination.//currentpage + '&size=' + this.browsepagination.rowsPerPage, this.search.params)
             axios.post(this.$store.getters.getAPIBasePath + '/jobresource/joblist', this.search.params)
                .then(response => {
                    let result = response.data;
                    console.log(result);
                    //this.joblist = result.jobs;
                    this.joblist = result.data;
                    //this.browsepagination.totalrecords = result.pagination[0].total;
                    //this.browsepagination.currentpage = result.pagination[0].currentPage;
                    //this.browsepagination.totalpages = result.pagination[0].lastPage;
                    this.browsepagination.totalrecords = result.totalRecords;
                    this.browsepagination.currentpage = result.currentPage;
                    this.elements.loadingJobs = true;
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });
        },
        findJobs(){
            this.browsepagination.currentpage = 1;
            this.loadJobs();
        },
        changeLimit(newlimit) {
            this.browsepagination.currentpage = 1;
            this.browsepagination.rowsPerPage = newlimit;
            this.loadJobs();
        },
        deleteJob(jobId) {
            this.delJobParam.id=jobId;
            return;

            if(confirm("Do you really want to deactivate this job?")){            
            var params = new URLSearchParams();
            params.append('id', jobId)
            axios.post(this.$store.getters.getAPIBasePath+'/jobresource/delete/job', params)
                .then(response => {
                    var result = response.data;
                    if(result.status == "OK") { 
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position:'topRight'
                        });
                        this.loadJobs(); 
                    } 
                })
                .catch(error => {
                   console.log(error);
                });
            }        
        },
        delete_Job()
        {
            var params = new URLSearchParams();
            params.append('id', this.delJobParam.id)
            axios.post(this.$store.getters.getAPIBasePath+'/jobresource/delete/job', params)
            .then(response => {
                var result = response.data;
                if(result.status == "OK") { 
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position:'topRight'
                    });
                    this.loadJobs(); 
                } 
            })
            .catch(error => {
                console.log(error);
            });
        }
    }
}

</script>
