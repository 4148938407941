<template>
	<div id="app" class="home-container home-blocks">
		<HomeMenu/>
			<slot />
		<Footer/>
	</div>
</template>
<script>
	import '@/assets/css/home-style.css';
	import HomeMenu from '@/components/home/widgets/HomeMenu';
	import Footer from '@/components/home/widgets/Footer';
	export default {
		components: {
			HomeMenu,
			Footer
		}
	}
</script>
