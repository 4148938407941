<template>
    <div class="add-project">
        <b-modal scrollable ref="createProjectModal" size="lg" id="createProjectModal" title="Create Project" @show="getProjectCode" @ok="addProject" @cancel="refreshProjectData" @close="refreshProjectData" ok-title="Save Project">
            <form @submit.stop.prevent="addProject">
                <div class="row col-md-12 mx-0 justify-content-end">
                    <multiselect v-if="elements.showMembersDropdown" v-model="saveProject.projectMembers" :options="options.recruitersList" name="project" label="recruiterName" track-by="id" select-label="" multiple deselect-label="" class="" :max-height="300" placeholder="Select Members" :searchable="true" :close-on-select="false" @close="elements.showMembersDropdown=false"></multiselect>
                    <span v-if="!elements.showMembersDropdown" v-for="TaskMembers in saveProject.projectMembers" :key="TaskMembers.recruiterName">
                        <span :data-letters="TaskMembers.recruiterName | getAcronym" :title="TaskMembers.recruiterName" class="mx-n2 align-self-center"></span>
                    </span>
                    <i v-if="!elements.showMembersDropdown" class="fa fa-plus circle-icon hover-hand ml-n2 pull-right" @click="elements.showMembersDropdown=!elements.showMembersDropdown" title="Add Members" />
                </div>
                <div class="row col-md-12 mx-0 px-0">
                    <div class="form-group col-md-4 col-sm-12">
                        <label for="projectName" class="col-form-label">Project Name</label>
                        <input type="text" name="projectName" id="projectName" v-model="saveProject.projectName" class="form-control" />
                        <label v-if="$v.saveProject.projectName.$error && !$v.saveProject.projectName.required" class="text-danger">Project Name is required</label>
                        <label v-if="$v.saveProject.projectName.$error && !$v.saveProject.projectName.minLength" class="text-danger">Project Name must have at least {{$v.saveProject.projectName.minLength.min}} letters.</label>
                        <label v-if="$v.saveProject.projectName.$error && !$v.saveProject.projectName.maxLength" class="text-danger">Project Name must not exceed {{$v.saveProject.projectName.maxLength.max}} letters.</label>
                    </div>
                    <div class="form-group col-md-4 col-sm-12">
                        <label for="keyName" class="col-form-label">Project Code</label>
                        <label name="keyName" id="keyName" class="form-control">{{ saveProject.keyName }}</label>
                    </div>
                    <div class="form-group col-md-4 col-sm-12">
                        <label for="clientName" class="col-form-label">Client</label>
                        <select class="form-control" v-model="saveProject.clientId">
                            <option value="">Select</option>
                            <option v-for="(client, index) in clientList" :value="client.id">{{client.client_Name}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4 col-sm-12">
                        <label for="addProjectStartDate" class="col-form-label">Start Date</label>
                        <input type="date" v-model="saveProject.startDate" @click="validDate('#projectStartDate')" class="form-control" id="projectStartDate" />
                    </div>
                    <div class="form-group col-md-4 col-sm-12">
                        <label for="addProjectDueDate" class="col-form-label">End Date</label>
                        <input type="date" v-model="saveProject.endDate" @click="validDate('#projectDueDate')" class="form-control" id="projectDueDate" />
                    </div>
                    <div class="form-group col-md-4 col-sm-12">
                        <label for="projectStatus" class="col-form-label">Project Status</label>
                        <select class="form-control" v-model="saveProject.status">
                            <option value="">Select</option>
                            <option v-for="(status, index) in statusList" :value="status.id">{{status.name}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-12 col-sm-12">
                        <label for="projectDescription" class="col-form-label">Description</label>
                        <editor id="projectDescription" api-key="yn4wx6bfcohfz6bwlu5k4urno027lgfkcpxolz0f6k4kku01" :toolbar="editor.toolbarOptions" :init="editor.initOptions" v-model="saveProject.description"></editor>
                        <label v-if="$v.saveProject.description.$error && !$v.saveProject.description.maxLength" class="text-danger">Description must not exceed {{$v.saveProject.description.maxLength.max}} letters.</label>
                    </div>
                    <div class="taskAttachments col-md-12 mb-4">
                        <div class="card">
                            <div class="card-body">
                                <label><b>Attachments</b></label>
                                <div class="d-flex flex-row">
                                    <input type="file" class="form-control mx-2 h-auto flex-fill" placeholder="Add Attachments">
                                    <b-btn variant="primary" class="ml-2 flex-fill">Add</b-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>
<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
import Editor from '@tinymce/tinymce-vue';
import Multiselect from 'vue-multiselect';
export default {
    props: {
        options: Object,
        editor: Object
    },
    components: {
        Editor,
        Multiselect
    },
    data() {
        return {
            elements: {
                showMembersDropdown: false
            },
            statusList: this.$store.getters.getProjectStatusList,
            clientList: this.$store.getters.getClients,
            saveProject: {
                projectName: "",
                keyName: "",
                clientId: "",
                startDate: "",
                endDate: "",
                description: "",
                status: 1,
                roleId: null,
                userType: "recruiter",
                projectMembers: []
            }
        }
    },
    validations: {
        saveProject: {
            projectName: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(50)
            },

            description: {
                maxLength: maxLength(200)
            },
        }
    },
    mounted() {
        this.loadDate();
    },
    methods: {
        loadDate() {
            this.saveProject.startDate = new Date().toISOString().substr(0, 10);
            this.saveProject.endDate = new Date().toISOString().substr(0, 10);
        },
        validateProjectData: function() {
            this.$v.saveProject.$touch();
            if (this.$v.saveProject.$invalid) {
                return false;
            }
            return true;
        },
        refreshProjectData: function() {
            this.updateLogActivity();
            this.$v.saveProject.$reset();
            this.saveProject.projectName = "";
            this.saveProject.clientId = "";
            this.saveProject.description = "";
            this.elements.showMembersDropdown = false;
            this.loadDate();
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        getProjectCode() {
            axios.post(this.$store.getters.getAPIBasePath + '/commonresource/autoincrementcode/project')
                .then(response => {
                    if (response.data.status) {
                        this.saveProject.keyName = response.data.code;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addProject: function(bvModalEvt) {
            this.saveProject.projectMembers.map(function(Members) {
                Members.userId = Members.id;
            });
            if (!this.validateProjectData()) {
                bvModalEvt.preventDefault();
                return false;
            }
            if (this.saveProject.startDate == 'Invalid date' && this.saveProject.endDate == 'Invalid date') {
                this.saveProject.startDate = null;
                this.saveProject.endDate = null;
            }
            axios.post(this.$store.getters.getAPIBasePath + '/projectresource/add/project', this.saveProject)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                        this.$refs.createProjectModal.hide();
                         this.$emit('loadAddProject');
                        this.$emit('getProjectList');
                        this.refreshProjectData();
                       
                    } else if (response.data.status == "OK") {
                        iziToast.info({ title: 'Info', message: response.data.message, position: 'topRight' });
                        this.$refs.createProjectModal.hide();
                        this.refreshProjectData();
                        this.$emit('getProjectList');
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                });
        },
        generateKey: function(name) {
            let keywords = [];
            this.saveProject.keyName = "";
            keywords = name.split(" ");
            let self = this;
            keywords.map(function(keyword) {
                self.saveProject.keyName += keyword.charAt(0);
            });
            this.saveProject.keyName = "PRJ_" + this.saveProject.keyName.toUpperCase();
        },
        validDate(id) {
            var dtToday = new Date();

            var month = dtToday.getMonth() + 1;
            var day = dtToday.getDate();
            var year = dtToday.getFullYear();
            if (month < 10)
                month = '0' + month.toString();
            if (day < 10)
                day = '0' + day.toString();

            var maxDate = year + '-' + month + '-' + day;
            $(id).attr('min', maxDate);
        }
    }
}

</script>
