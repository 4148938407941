<template>
    <div class="col-md-9 col-sm-9">
        <div class="collapse navbar-collapse pull-right" id="demo-navbar">
            <form class="form-inline my-2 my-lg-0 pull-right">
                <ul class="ul-horizontal userprofile-submenu mb-0">
                    <li class="headersearchbox"><input type="text" class="form-control" placeholder="Search..." v-model="searchValue">
                        <button type="button" class="btn btn-primary btn-sm ml-1">Search</button></li>
                    
                    <li class="">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="">{{userinfo.name}} <i class="fa fa-angle-down"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <router-link :to="{ name: 'referralprofile' }" class="dropdown-item">Profile</router-link>
                            <router-link :to="{ name: 'changepasswordreferral' }" class="dropdown-item">Change Password</router-link>
                            <a v-if="!userinfo.havingCandidateAccount" class="dropdown-item" v-b-modal.candidateAccount href="javascript:;">Add as Candidate</a>
                            <a v-if="userinfo.havingCandidateAccount" class="dropdown-item" @click="updateUserAccount('candidate')" href="javascript:;">Switch to Candidate Account</a>
                            <a v-if="!userinfo.havingInterviewerAccount" class="dropdown-item" v-b-modal.interviewerAccount href="javascript:;">Add as Interviewer</a>
                            <a v-if="userinfo.havingInterviewerAccount" class="dropdown-item" @click="updateUserAccount('interviewer')" href="javascript:;">Switch to Interviewer Account</a>
                            <a v-if="false" class="dropdown-item" href="">Setting</a>
                            <a class="dropdown-item" @click="performLogout" href="Javascript:void(0);">Logout</a>
                        </div>
                    </li>
                </ul>
            </form>
        </div>
        <BDialog id="candidateAccount" :onYes="createCandidateAccount" title="Candidate Account" message="Do you want to register as Candidate with the same account?" />
        <BDialog id="interviewerAccount" :onYes="createInterviewerAccount" title="Interviewer Account" message="Do you want to register as Interviewer with the same account?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';

export default {
    mounted() {
        this.InlineButtonClickHandler();
        localStorage.setItem('loggedOut','false');
    },
    data() {
        return {
            userinfo: this.$store.getters.currentUser,
            searchValue: "",
            login: {
                email: '',
                password: '',
                switchAccount: true,                
            },
            messageInfo: "",
            userType: ""
        }
    },
    methods: {
        performLogout() {
            this.$store.dispatch("doLogout");
            localStorage.setItem('loggedOut',true);
            //this.$router.push('/login');
        },
        InlineButtonClickHandler() {
            $(document).ready(function() {
                $('.main-menu > li > div > a').click(function() {
                    $('.main-menu > li').removeClass('active');
                    $(this).parent().parent().addClass('active');
                });
            });
        },
        createCandidateAccount: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/add/candidate/account')
                .then(response => {
                    let result = response.data;
                    this.login.userType = "candidate";
                    this.performLogin();

                    if (!response.status) {
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position: 'topRight'
                        });
                    } else {
                        iziToast.success({
                            title: 'Success',
                            message: this.$config.CandSavSuc,
                            position: 'topRight'
                        });

                    }
                })
                .catch(error => {

                    iziToast.error({
                        title: 'Error',
                        message: this.$config.CreCandErr,
                        position: 'topRight'
                    });
                });

        },
        createInterviewerAccount: function() {

            axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/add/interviewer/account')
                .then(response => {

                    let result = response.data;
                    this.login.userType = "interviewer";
                    this.performLogin();

                    if (!response.status) {
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position: 'topRight'
                        });
                    } else {
                        iziToast.success({
                            title: 'Success',
                            message: this.$config.IntRegSuc,
                            position: 'topRight'
                        });

                    }
                })
                .catch(error => {

                    iziToast.error({
                        title: 'Error',
                        message: this.$config.CreInterErr,
                        position: 'topRight'
                    });
                });
        },
        performLogin() {
            this.login.email = this.userinfo.email;
            axios.post(this.$store.getters.getAPIBasePath + '/userresource/authenticate', this.login)
                .then(response => {
                    let result = response.data;
                    if (result.result) {
                        localStorage.setItem(this.$store.getters.getLocalStorageName, response.data.data.token);
                        window.axios.defaults.headers.common['Authorization'] = response.data.data.token;
                        const defaultredirects = this.$store.getters.getDefaultRedirects;
                        this.$store.dispatch("setupLogin", result).then(() => {
                            if (result.data.userType == "interviewer") {
                                this.$router.push(defaultredirects.interviewer);
                            } else if (result.data.userType == "candidate") {
                                this.$router.push(defaultredirects.candidate);
                            } else if (result.data.userType == "guest") {
                                this.$router.push({ path: defaultredirects.guest });
                            } else if (result.data.userType == "admin") {
                                //this.$router.push(defaultredirects.admin);
                                if (result.data.isSuperAdmin) {
                                    this.$router.push({ path: defaultredirects.superadmin });
                                } else {
                                    this.$router.push({ path: defaultredirects.admin });
                                }
                            } else if (result.data.userType == "referral") {
                                this.$router.push({ path: defaultredirects.referral });
                            } else {
                                //if (result.data.recruitOwner)
                                if (result.data.recruitType == 2 || result.data.recruitType == 3) 
                                    this.$router.push(defaultredirects.recruiteradmin);
                                else
                                    this.$router.push(defaultredirects.recruiter);
                            }
                            this.$router.go(0);
                        });
                    } else {
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.InvCredential,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.TryAg,
                        position: 'topRight'
                    });
                });
        },
        updateUserAccount: function(value) {
            this.userType = value;
            axios.post(this.$store.getters.getAPIBasePath + '/userresource/update/usertype/' + this.userType)
                .then(response => {
                    let result = response.data;
                    if (response.data.status == "OK") {
                        this.login.userType =  this.userType;
                        this.performLogin();
                    }
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.CreInterErr,
                        position: 'topRight'
                    });
                });
        }
    }
}

</script>
