<template>
    <div class="admin-blocks add-recruiter mt-70 pt-10">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="card">
                    <div class="card-header p-2">Add Client Account</div>
                    <div class="card-body p-2">
                        <div class="basic-block">
                            <div class="row ten-columns">
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>First Name</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group required-field-block">
                                                <input type="text" name="firstName" v-model="recruiterInfo.firstName" maxlength="45" size="30" class="form-control" />
                                                <span class="required-icon"></span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <label v-if="$v.recruiterInfo.firstName.$error && !$v.recruiterInfo.firstName.required" class="text-danger">First Name is required</label>
                                            <label v-if="$v.recruiterInfo.firstName.$error && !$v.recruiterInfo.firstName.minLength" class="text-danger">First Name must've minimum 2 characters</label>
                                            <label v-if="$v.recruiterInfo.firstName.$error && !$v.recruiterInfo.firstName.maxLength" class="text-danger">Maximum limit is 30 characters</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Last Name</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input type="text" name="lastName" v-model="recruiterInfo.lastName" maxlength="45" size="30" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Email</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group required-field-block">
                                                <input type="email" name="email" v-model="recruiterInfo.email" maxlength="45" size="30" class="form-control" @blur="$v.recruiterInfo.email.$touch" />
                                                <span class="required-icon"></span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <label v-if="$v.recruiterInfo.email.$error && !$v.recruiterInfo.email.required" class="text-danger">Email is required</label>
                                            <label v-if="$v.recruiterInfo.email.$error && !$v.recruiterInfo.email.email" class="text-danger">Invalid Email</label>
                                            <label v-if="$v.recruiterInfo.email.$error && $v.recruiterInfo.email.email && !$v.recruiterInfo.email.emailexists" class="text-danger">Email already exists</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Account Name</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group required-field-block">
                                                <input type="text" name="accountName" v-model="recruiterInfo.accountName" maxlength="45" size="30" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Account Type</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <select class="form-control" name="accountType" v-model="recruiterInfo.accountType">
                                                <option v-for="Options in accountType" v-bind:key="Options.id" :value="Options.id" :disabled="Options.name=='Individual'">{{Options.name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <label v-if="$v.recruiterInfo.accountType.$error && !$v.recruiterInfo.accountType.required" class="text-danger">Account Type is required</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Company Name</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group required-field-block">
                                                <input type="text" name="companyName" v-model="recruiterInfo.companyName" maxlength="45" size="30" class="form-control" />
                                                <span class="required-icon"></span>
                                            </div>
                                            <p v-if="Errors!='' "class="error">{{ Errors }}</p>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <label v-if="$v.recruiterInfo.companyName.$error && !$v.recruiterInfo.companyName.required" class="text-danger">Company Name is required</label>
                                            <label v-if="$v.recruiterInfo.companyName.$error && !$v.recruiterInfo.companyName.minLength" class="text-danger">Company Name must've minimum 2 characters</label>
                                            <label v-if="$v.recruiterInfo.companyName.$error && !$v.recruiterInfo.companyName.maxLength" class="text-danger">Maximum limit is 30 characters</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ten-columns">
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Phone No.</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group required-field-block">
                                                <input id="phoneNo" type="text" name="phoneNo" v-model="recruiterInfo.companyPhoneno" maxlength="10" size="30" class="form-control" @keypress="formatUsPhone($event,'#phoneNo')" @blur="$v.recruiterInfo.companyPhoneno.$touch" />
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <label v-if="$v.recruiterInfo.companyPhoneno.$error && !$v.recruiterInfo.companyPhoneno.usPhone" class="text-danger">Invalid Phone No.</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Zipcode</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group required-field-block">
                                                <input type="text" name="zipcode" v-model="recruiterInfo.zipcode" maxlength="5" size="30" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <label v-if="elements.submitted && !$v.recruiterInfo.zipcode.uszip" class="text-danger">Invalid Zipcode</label>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Country</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group required-field-block">
                                                <select class="form-control" name="country" v-model="recruiterInfo.companyCountry">
                                                    <option value="">Select</option>
                                                    <option v-for="Country in Countries" v-bind:key="Country.country_Id" :value="Country.country_Id">{{Country.name}}</option>
                                                    <!-- <option v-for="State in USStates" v-bind:key="State.id" :value="State.id">{{State.stateName}}</option> -->
                                                </select>
                                                <span class="required-icon"></span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <label v-if="$v.recruiterInfo.companyCountry.$error && !$v.recruiterInfo.companyCountry.required" class="text-danger">Country is required</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Time Zone</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <Select2 v-model="recruiterInfo.timezone" :options="filteredTimeZones" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Referral</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <select class="form-control" name="country" v-model="recruiterInfo.referralId">
                                                <option value="">Select</option>
                                                <option v-for="referral in referralList" v-bind:key="referral.id" :value="referral.id">{{referral.referralName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Candidate Contact Access</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <select class="form-control" name="plan" v-model="recruiterInfo.candidateContactAccess">
                                                <option value="">Select</option>
                                                <option v-for="access in candContactAccess" v-bind:key="access.id" :value="access.id">{{access.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Plan</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <select class="form-control" name="plan" v-model="recruiterInfo.planId">
                                                <option v-for="plan in planList" v-bind:key="plan.planId" :value="plan.planId">{{plan.servicePlan}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Client Access Level</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <select class="form-control" name="plan" v-model="recruiterInfo.clientAccessLevelId">
                                                <option value="">Select</option>
                                                <option v-for="access in clientAccessList" v-bind:key="access.id" :value="access.id">{{access.clientAccessLevel}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header p-2">Settings</div>
                        <div style="padding: 20px;">
                        <div class="row">
                            <div class="col-md-3 input-group radio_btns tab_pad">Salary Negotiation<span class="pl-2">
                                <p>
                                    <input type="radio" id="acceptlocal1"  v-model="recruiterInfo.salaryNegotiationAccess" value="true" name="acceptlocal" checked="">
                                        <label for="acceptlocal1">Enable</label>
                                </p>
                                <p>
                                    <input type="radio" id="acceptlocal2"  v-model="recruiterInfo.salaryNegotiationAccess" value="false" name="acceptlocal">
                                    <label for="acceptlocal2">Disable</label>
                                </p></span>
                            </div>
                            <div class="col-md-3 input-group radio_btns tab_pad">Display Vendor and Client Information <span class="pl-2">
                                <input class="form-check-input" type="checkbox" v-model="recruiterInfo.vendorClientInfoAccess" id="dispalyInfo" value="true">
                                <label class="form-check-label" for="dispalyInfo"></label>   
                            </span>
                            </div>
                            <div class="col-md-3 input-group radio_btns tab_pad">Offer Letter<span class="pl-2">
                                <p>
                                    <input type="radio" id="offerEnable1"  v-model="recruiterInfo.offerLetterAccess" value="true" name="offerEnable" checked="">
                                        <label for="offerEnable1">Enable</label>
                                </p>
                                <p>
                                    <input type="radio" id="offerDisable1"  v-model="recruiterInfo.offerLetterAccess" value="false" name="offerDisable">
                                    <label for="offerDisable1">Disable</label>
                                </p></span>
                            </div>
                            <div class="col-md-3 input-group radio_btns tab_pad">Upload Logo
                                <input type="file" class="form-control mx-4 h-auto flex-fill upload" @change="handleFileUpload" accept="image/*">
                            </div>
                        </div>   
					</div>
                </div>

                <div class="card-header p-2">
                    <a href="javascript:" @click="saveRecruiter" class="btn btn-success float-right">Invite</a>
                    <router-link :to="{ name: 'listclientaccount' }" class="btn btn-secondary mr-2 text-white float-right">Cancel</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import Select2 from 'v-select2-component';
import iziToast from 'izitoast';
const usPhone = helpers.regex('usPhone', /^\(\d{3}\)\s\d{3}-\d{4}$/);

export default {
    components: {
        Select2
    },
    data() {
        return {
            logo: '',
            Errors: "",
            clientAccountList: [],
            browsepagination: this.$store.state.paginationconfig,
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    accountName: ''
                }
            },
            elements: {
                submitted: false
            },
            Countries: this.$store.getters.getCountries,
            USStates: this.$store.getters.getUSStates,
            timeZones: this.$store.getters.getTimeZones,
            accountType: this.$store.getters.getAccountTypeOptions,
            candContactAccess: this.$store.getters.getCandContactAccess,
            clientAccessList: [],
            referralList: [],
            planList: [],
            recruiterInfo: {
                recruiterCode: "",
                referralId: "",
                firstName: "",
                lastName: "",
                accountName: "",
                companyName: "",
                accountType: "2",
                email: "",
                companyPhoneno: "",
                zipcode: "",
                companyCountry: "",
                timezone: "",
                planId: "1",
                candidateContactAccess: "",
                clientAccessLevelId:"",
                salaryNegotiationAccess: false,
                offerLetterAccess: false,
                vendorClientInfoAccess: false
            }
        }
    },
    validations: {
        recruiterInfo: {
            firstName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            // accountName: {
            //     required,
            //     minLength: minLength(2),
            //     maxLength: maxLength(30)
            // },
            companyName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            accountType: {
                required
            },
            email: {
                required,
                email,
                emailexists(email) {
                    return axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/checkemailavailability', { email: email })
                        .then(response => {
                            if (response.data.status) {
                                return true;
                            }
                            return false;
                        })
                        .catch(error => {
                            return false;
                        });
                }
            },
            companyPhoneno: {
                usPhone
            },
            companyCountry: {
                required
            },
            /*zipcode: {
                // minLength: minLength(5),
                // maxLength: maxLength(5),
                uszip(zipcode){
                    // return /^\d{5}(-\d{4})?$/.test(zipcode);
                    return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(zipcode);
                }
            }*/
            /*country: {

            },
            timezone: {

            }*/
        }
    },
    watch: {
        'recruiterInfo.companyCountry': {
            handler: function(value) {
                var filteredTimeZones = this.timeZones.filter(timeZone => {
                if (value === 'US' || value === 'CA') {
                    this.recruiterInfo.timezone = 'US/Eastern';
                    return timeZone.id.startsWith('US/');
                } else if (value === 'IN') {
                    this.recruiterInfo.timezone = 'Asia/Kolkata';
                    return timeZone.id === 'Asia/Kolkata';
                } else {
                    this.recruiterInfo.timezone = ''
                    return timeZone.id === '';
                }
                });
                this.filteredTimeZones = filteredTimeZones;
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.loadReferrals();
        this.getRecruiterCode();
        this.loadServicePlan();
        this.loadClientAccesslevel();
    },
    methods: {
        handleFileUpload(event) {
            this.logo = event.target.files[0];
        },
        getRecruiterCode() {
            axios.post(this.$store.getters.getAPIBasePath + '/commonresource/autoincrementcode/recruiter')
                .then(response => {
                    if (response.data.status) {
                        this.recruiterInfo.recruiterCode = response.data.code;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        formatUsPhone: function(evt, idx) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                var curchr = $(idx).val().length;
                var curval = $(idx).val();
                if (curchr == 3 && curval.indexOf("(") <= -1) {
                    $(idx).val("(" + curval + ")" + " ");
                } else if (curchr == 4 && curval.indexOf("(") > -1) {
                    $(idx).val(curval + ")-");
                } else if (curchr == 5 && curval.indexOf(")") > -1) {
                    $(idx).val(curval + "-");
                } else if (curchr == 9) {
                    $(idx).val(curval + "-");
                    $(idx).attr('maxlength', '14');
                }
                return true;
            }
        },
        saveRecruiter: function() {
            this.elements.submitted = true;
            this.$v.recruiterInfo.$touch();
            if (this.$v.recruiterInfo.$invalid) {
                iziToast.error({ title: 'Error', message: this.$config.FieldRequir, position: 'topRight' });
                return;
            }
            axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/add/client/account', this.recruiterInfo)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        if (this.logo != '') {
                            let formData = new FormData();
                            formData.append('file', this.logo);
                            formData.append('companyId', response.data.id);
                            formData.append('ttClientAccountId', response.data.ttClientAccountId);
                            axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/upload/company/logo', formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }).then(response => {
                                    console.log(response.message)
                                })
                                .catch(error => {
                                    console.log(error)
                                });
                    }                
                        iziToast.success({ title: 'Success', message: this.$config.CliAccSuc, position: 'topRight' });
                        this.$router.push('/admin/clientaccount');
                    }else if(response.data.status == "ALREADY_REPORTED") {
                        this.Errors = response.data.message;
                    }
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.Updateerr, position: 'topRight' });
                });
            this.elements.submitted = false;
        },
        loadClientAccounts() {
            axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/client/account/list?page=' + this.browsepagination.currentpage + '&size=' + this.search.limit, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.clientAccountList = result.clientAccountList;
                    this.browsepagination.totalrecords = result.pagination[0].total;
                    this.browsepagination.currentpage = result.pagination[0].currentPage;
                    this.browsepagination.totalpages = result.pagination[0].lastPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadReferrals() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/referrallist')
                .then(response => {
                    let result = response.data;
                    this.referralList = result.referrallist;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        loadServicePlan() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/service/plan/list')
                .then(response => {
                    let result = response.data;
                    this.planList = result.servicePlanList;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        loadClientAccesslevel() {
            axios.post(this.$store.getters.getAPIBasePath + '/clientaccesslevel/list')
                .then(response => {
                    let result = response.data;
                    this.clientAccessList = result.recordinfo;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        }
    }
}

</script>
