<template>
    <div id="main" class="mt-70 viewonly">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row no-gutters">
                    <router-link :to="{ name: 'adminlistclients' }" class="btn btn-secondary mb-2 text-white">
                        <i class="fa fa-arrow-left"></i>
						<span> Back</span>
                    </router-link>
                    <b-tabs v-model="currentClientTab" small card class="tab-list driver-tab-list">
                        <b-tab title="Client Details">
                            <ClientInfo :clientinfo="clientinfo" ref="clientinfocomponent"></ClientInfo>
                        </b-tab>
                        <b-tab title="Physical Address">
                            <AddressInfo :addressinfo="addressinfo" ref="addressinfocomponent"></AddressInfo>
                        </b-tab>
                        <b-tab title="Client Contacts">
                            <ClientContacts :clientcontactinfo="clientcontactinfo" ref="clientcontactinfocomponent" @refresh-client="loadClientById(clientid)"></ClientContacts>
                        </b-tab>
                        <b-tab title="Contract Details">
                            <ContractDetails :clientcontractinfo="clientcontractinfo" ref="clientcontractinfocomponent" @refresh-client="loadClientById(clientid)"></ContractDetails>
                        </b-tab>
                    </b-tabs>
                    </div>
                    <!-- <div class="mt-2 pull-right">
                        <a href="Javascript:void(0);" @click="saveCurrentTab" class="btn btn-success btn-sm float-right" style="margin-left: 10px;color: #fff;">Save</a>
                        <a href="Javascript:void(0);" class="btn btn-primary btn-sm"><router-link :to="{ name: 'viewclients' }" style="color : #fff;">Cancel</router-link></a>
                    </div> -->
                
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ClientInfo from './blocks/addclient/ClientInfo';
import AddressInfo from './blocks/addclient/AddressInfo';
import ClientContacts from './blocks/addclient/ClientContacts';
import ContractDetails from './blocks/addclient/ContractDetails';
export default {
    computed: {
        clientid() {
            return this.$route.params.clientid;
        }
    },
    components: {
        ClientInfo,
        AddressInfo,
        ClientContacts,
        ContractDetails
    },
    data() {
        return {
            currentClientTab: 0,
            maxtabs: 3,
            clientinfo: {
                clientCode: "",
                dunsNumber: "",
                clientName: "",
                accountName: "",
                clientGroup: "",
                accountManager: "",
                url: "",
                backupaccountManager: "",
                jobUrl: "",
                accountExecutive: "",
                status: "",
                description: ""
            },
            addressinfo: {
                physicaladdress: {
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "US",
                    faxNumber: ""
                },
                billingaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "US",
                    faxNumber: ""
                }
            },
            clientcontactinfo: [],
            clientcontractinfo: [],

        }
    },
    mounted() {
        this.loadClientById(this.clientid);

    },
    methods: {

        loadClientById(clientId) {
            axios.get(this.$store.getters.getAPIBasePath + '/clientresource/view/client/' + clientId)
                .then(response => {
                    let result = response.data;
                    if (!result.status) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position: 'topRight'
                        });
                        //this.$router.push('/candidates');
                    }
                    let self = this;
                   
                    this.clientinfo = result.clients;
                    this.clientcontactinfo = result.clients.clientContact;
                    this.clientcontractinfo = result.clients.clientContract;
                    result.clients.clientAddress.forEach(function(data) {
                        if (data.addressType.toLowerCase() == "physical") {
                            self.addressinfo.physicaladdress = data;
                        } else {
                            self.addressinfo.billingaddress = data;
                        }
                    });

                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliInfoLoadErr,
                        position: 'topRight'
                    });
                    // this.$router.push('/candidates');
                });
        },
        saveCurrentTab() {
            switch (this.currentClientTab) {
                case 0:
                    if (this.$refs.clientinfocomponent.validateClientInfo()) {
                        this.saveClientInfo();
                    }
                    break;
                case 1:
                    this.saveAddressInfo();
                    break;
                case 2:
                    this.saveClientContactInfo();
                    break;
                case 3:
                    this.saveClientContractInfo();
                    break;
                default:
                    break;
            }
        },
        saveClientInfo() {
           /* let clientinfodata = {
                clientCode: this.clientinfo.clientCode,
                dunsNumber: this.clientinfo.dunsNumber,
                clientName: this.clientinfo.clientName,
                accountName: this.clientinfo.accountName,
                //clientGroup:this.clientinfo.clientGroup,
                //accountManager:this.clientinfo.accountManager,
              url: this.clientinfo.url,
                //backupaccountManager:this.clientinfo.backupaccountManager,
                jobUrl: this.clientinfo.jobUrl,
                //accountExecutive:this.clientinfo.accountExecutive,
                status: this.clientinfo.status,
                description: this.clientinfo.description
            };*/
            axios.post(this.$store.getters.getAPIBasePath + '/clientresource/update/client/' + this.clientid + '/clientdetails', this.clientinfo)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CliPerInfoUpSuc,
                        position: 'topRight'
                    });
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliUpErr,
                        position: 'topRight'
                    });
                });
        },
        saveAddressInfo() {

            let contactupdate = {
                clientAddress: {
                    physicalAddress: this.addressinfo.physicaladdress,
                    billingAddress: this.addressinfo.billingaddress
                }
            };

            axios.post(this.$store.getters.getAPIBasePath + '/clientresource/update/client/' + this.clientid + '/address', contactupdate)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CliAdrsSuc,
                        position: 'topRight'
                    });
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliAdrsUpErr,
                        position: 'topRight'
                    });
                });
        },
        saveClientContactInfo() {

            let clientcontactinfodata = this.newclientcontactinfo;
            console.log(clientcontactinfodata);
            axios.post(this.$store.getters.getAPIBasePath + '/clientresource/update/client/' + this.clientid + '/contact', clientcontactinfodata)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CliConInfoUpSuc,
                        position: 'topRight'
                    });
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliConUpErr,
                        position: 'topRight'
                    });
                });
        },
        saveClientContractInfo() {

            let clientcontractinfodata = this.newclientcontractinfo;
            console.log(clientcontractinfodata);
            axios.post(this.$store.getters.getAPIBasePath + '/clientresource/update/client/' + this.clientid + '/contract', clientcontractinfodata)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CliContraInfoUpSuc,
                        position: 'topRight'
                    });
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliContraUpErr,
                        position: 'topRight'
                    });
                });
        }
    }
}

</script>
