import RecruiterSettings from '@/components/recruiters/Tools&Settings';
import TemplateSettings from '@/components/recruiters/blocks/settings/TemplateSettings';
import Reports from '@/components/recruiters/Reports';
import RecruiterTasks from '@/components/recruiters/Tasks';
import ProjectSettings from '@/components/recruiters/blocks/settings/ProjectSettings';
import EmailTools from '@/components/recruiters/blocks/tools/EmailTools';
import recruiterProfile from '@/components/recruiters/Profile';
import ViewRecruiter from '@/components/recruiters/ViewRecruiter';
import EditRecruiter from '@/components/recruiters/EditRecruiter';
import EditTeamMember from '@/components/recruiters/EditTeamMember';
import ViewTeamMember from '@/components/recruiters/ViewTeamMember';
import ToolDemo from '@/components/recruiters/ToolDemo';
import ListReferrals from '@/components/recruiters/ListReferrals';
import ViewReferral from '@/components/recruiters/ViewReferral';
import EditReferral from '@/components/recruiters/EditReferral';
import CandidateListReport from '@/components/recruiters/blocks/reports/candidate/CandidateListReport';
import CandidateCustomReport from '@/components/recruiters/blocks/reports/candidate/CandidateCustomReport';
import JobListReport from '@/components/recruiters/blocks/reports/job/JobListReport';
import JobCustomReport from '@/components/recruiters/blocks/reports/job/JobCustomReport';
import GeneralSettings from '@/components/recruiters/blocks/settings/GeneralSettings';
import TechSkillSettings from '@/components/recruiters/blocks/settings/TechSkillSettings';
import AddReferral from '@/components/recruiters/AddReferral';
import ChangePassword from '@/components/recruiters/ChangePassword';


export default [{
        path: '/recruiter/settings',
        name: 'recruitersettings',
        component: RecruiterSettings,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/recruiter/settings/:type',
        name: 'recruitersettings',
        component: RecruiterSettings,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/recruiter/settings/template',
        name: 'templatesettings',
        component: TemplateSettings,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/recruiter/reports',
        name: 'reports',
        component: Reports,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/recruiter/tools/email',
        name: 'emailtools',
        component: EmailTools,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/recruiter/profile',
        name: 'recruiterprofile',
        component: recruiterProfile,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/recruiters/edit/:recruiterid',
        name: 'editrecruiter',
        meta: { accessedby: 'recruiter' },
        component: EditRecruiter
    },
    {
        path: '/members/edit/:recruiterid',
        name: 'editteammember',
        meta: { accessedby: 'recruiter' },
        component: EditTeamMember
    },
    {
        path: '/members/view/:recruiterid',
        name: 'viewteammember',
        meta: { accessedby: 'recruiter' },
        component: ViewTeamMember
    },
    {
        path: '/recruiters/view/:recruiterid',
        name: 'viewrecruiter',
        meta: { accessedby: 'recruiter' },
        component: ViewRecruiter
    },
    {
        path: '/recruiter/tool-demo',
        name: 'tooldemo',
        meta: { accessedby: 'recruiter' },
        component: ToolDemo
    },
    {
        path: '/recruiter/referrals',
        name: 'listreferrals',
        meta: { accessedby: 'recruiter' },
        component: ListReferrals
    },
    {
        path: '/recruiter/referral/view/:referralid',
        name: 'viewreferral',
        meta: { accessedby: 'recruiter' },
        component: ViewReferral
    },
    {
        path: '/recruiter/referral/edit/:referralid',
        name: 'editreferral',
        component: EditReferral,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/recruiter/candidate/list',
        name: 'candidatelistreport',
        meta: { accessedby: 'recruiter' },
        component: CandidateListReport
    },
    {
        path: '/recruiter/candidate/custom/report',
        name: 'candidatecustomreport',
        meta: { accessedby: 'recruiter' },
        component: CandidateCustomReport
    },
    {
        path: '/recruiter/job/list/report',
        name: 'joblistreport',
        component: JobListReport,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/recruiter/job/custom/report',
        name: 'jobcustomreport',
        component: JobCustomReport,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/recruiter/tasks',
        name: 'recruitertasks',
        component: RecruiterTasks,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/recruiter/project/settings',
        name: 'projectsettings',
        component: ProjectSettings,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/recruiter/tasks/view/:taskid',
        name: 'viewtask',
        meta: { accessedby: 'recruiter' },
        component: RecruiterTasks
    },
    {
        path: '/recruiter/settings/general',
        name: 'generalsettings',
        component: GeneralSettings,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/recruiter/settings/technologyskill',
        name: 'techskillsettings',
        component: TechSkillSettings,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/referral/add',
        name: 'addreferral',
        component: AddReferral,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/recruiter/changepassword',
        name: 'changepasswordrecruiter',
        component: ChangePassword,
        meta: { accessedby: 'recruiter' }
    }
];
