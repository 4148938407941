<template>
    <div class="kpis reports text-left">
        <div class="row">
            <div class="col-sm-12 p-10">
                <div id="recruiter-settings" class="recruiter settings mt-10">
                    <div class="container-fluid" id="quick-search">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="text-center">Shared Candidates</h5>
                            </div>
                        </div>
                        <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <h4 class="page-title">Filter</h4>
                                </div>
                                <div class="col-md-6 col-sm-6 col-6">
                                    <ul class="ul-horizontal box-tools text-right mb-0">
                                        <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :title="isExpand?'Hide':'Expand'" :class="isExpand?'ti-angle-up':'ti-angle-down'" class="hover-hand"></span></a></li>
                                        <li><i class="ti-close hover-hand px-2" @click="$emit('setSubTab',0);$emit('updateLogActivity');" title="Close"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <form id="main-search" :class="isExpand?'d-block':'d-none'">
                                <div class="row form-group ten-columns">
                                    <div v-if="clientAccount==1" class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Recruiter</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <select class="form-control" v-model="search.params.recruiterId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in recruiterlist" :value="info.id">{{info.recruiterName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Shared To</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <select class="form-control" v-model="search.params.ttClientAccountId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in companyClientList" :value="info.id">{{info.companyName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Start Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledStartDates" placeholder="Start Date" name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.sharedStartDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>End Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledendDates" placeholder="End Date" name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.sharedEndDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group ten-columns">
                                    <div class="col-md-12 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label style="visibility: hidden;"></label>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="button" @click="resetCandidatesSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                                <button type="button" @click="findCandidates" class="btn btn-primary btn-sm float-right">Go</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="load-indicator"></div>
                        </div>
                    </div>
                    <div class="container-fluid" id="main-data">
                        <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                            <div class="row" style="margin-bottom: 15px;">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <h4 class="page-title">Shared Candidates
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                            <div class="dropdown">
                                                <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                                <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                    <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                                </div>
                                            </div>
                                        </span>
                                    </h4>
                                </div>
                                 <div class="col-md-6 d-none d-sm-none d-md-block">
                                        <span class="float-right" @click="exportSharedCandidatesJobs()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table table-borderless all-table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th class="" scope="col">Sl.No</th>
                                                    <th class="recruiter" scope="col">Shared By</th>
                                                    <th class="status" scope="col">Shared To</th>
                                                     <th class="status" scope="col">Created By</th>
                                                    <th class="status" scope="col">Candidates Shared</th>
                                                    <th class="status" scope="col">Shared Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="candidateShareMasterList.length==0">
                                                    <td colspan="7" align="center">No records found</td>
                                                </tr>
                                                <template v-for="(candidateShareMasterInfo, index) in candidateShareMasterList">
                                                    <tr>
                                                        <td>
                                                            <a href="javascript:" class="expand" data-targetopen="panel1">
                                                                <span @click="getSharedCandidates(candidateShareMasterInfo.id)"><i :class="(currentIndex==candidateShareMasterInfo.id) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
                                                                </span>
                                                            </a>
                                                        </td>
                                                        <td>{{((pagination.currentPage-1) * (pagination.rowsPerPage)) + (index+1)}}</td>
                                                        <td class="recruiter">{{ candidateShareMasterInfo.recruiterName }}</td>
                                                        <td>
                                                            {{candidateShareMasterInfo.companyName}}
                                                        </td>
                                                         <td>
                                                            {{candidateShareMasterInfo.createdBy}}
                                                        </td>
                                                        <td>
                                                            {{candidateShareMasterInfo.noOfCandidates}}
                                                        </td>
                                                        <td>{{ candidateShareMasterInfo.sharedDate | formatDate }}</td>
                                                    </tr>
                                                    <tr v-if="currentIndex==candidateShareMasterInfo.id">
                                                        <td colspan="7">
                                                            <div class="table-responsive scroll-down-inbox">
                                                                <table class="table table-borderless all-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">Sl.No</th>
                                                                            <th class="text-left" scope="col">Candidate Code</th>
                                                                            <th class="text-left" scope="col">Candidate Name</th>
                                                                            <th class="text-left" scope="col">Email</th>
                                                                            <th class="text-left" scope="col">Mobile</th>
                                                                            <!-- <th class="text-left" scope="col">Date</th> -->
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <template v-for="(candInfo, index1) in sharedCandidates">
                                                                            <tr>
                                                                                <td>{{(index1+1)}}</td>
                                                                                <td class="text-left">{{ candInfo.candidateCode }}</td>
                                                                                <td class="text-left">{{ candInfo.candidateName }}</td>
                                                                                <td class="text-left">{{ candInfo.email }}</td>
                                                                                <td class="text-left">{{ candInfo.mobile }}</td>
                                                                                <!-- <td class="text-left">{{ candInfo.createdDate | formatDate }}</td> -->
                                                                            </tr>
                                                                        </template>
                                                                        <tr v-if="sharedCandidates.length==0">
                                                                            <td colspan="7" align="center">No Candidates found</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <!-- <tr v-for="(candidateShareMasterInfo, index) in candidateShareMasterList">
                                                    <td>{{((pagination.currentPage-1) * (pagination.rowsPerPage)) + (index+1)}}</td>
                                                    <td>
                                                        {{candidateShareMasterInfo.candidateName}}
                                                    </td>
                                                    <td>
                                                        {{candidateShareMasterInfo.account_Name}}
                                                    </td>
                                                    <td class="recruiter">{{ candidateShareMasterInfo.recruiterName }}</td>
                                                    <td>{{ candidateShareMasterInfo.shared_Date | formatDate }}</td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <b-pagination v-if="candidateShareMasterList.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadCandidates()"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { mapState } from 'vuex';
export default {

    data() {
        return {
            isExpand: false,
            candidateShareMasterList: [],
            clientlist: [],
            sharedCandidates: [],
            technologies: this.$store.getters.getTechOptions,
            industrylist: this.$store.getters.getIndustries,
            recruiterlist: this.$store.getters.getRecruiterList,
            candStatusList: this.$store.getters.getCandStatusReport,
            companyClientList: this.$store.getters.getClientCompanyList,
            searchlimit: this.$store.state.searchlimit,
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            elements: {
                showadvancedsearch: false,
                submitted: null,
                toolbar: 'undo redo formatselect| bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
                initObj: {
                    menubar: false,
                    height: 300,
                    branding: false,
                }
            },
            search: {
                params: {
                    recruiterId: "",
                    ttClientAccountId: "",
                    sharedStartDate: "",
                    sharedEndDate: ""
                }
            },
            coldisplay: {
                recruiter: true,
                title: true,
                technology: true,
                currentcity: true,
                state: true
            },
            clientParams: {
                accountName: ''
            },
            clientAccount: this.$store.getters.currentUser.recruitOwner,
            contactInfo: {
                id: "",
                firstName: "",
                email: ""
            },
            currentIndex: null,
             disabledStartDates: {
                from: new Date()
            
            },
            disabledendDates:{
                from: new Date(),
                to: ''
            }
        }
    },
    computed: mapState(['globalSearchValue']),
    watch: {
        globalSearchValue(newValue, oldValue) {
            this.search.params.firstName = newValue;
            this.loadCandidates();
        },
        'search.params.sharedStartDate': {
            handler: function(value) {
                this.disabledendDates.to = new Date(value);
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
        this.$store.commit('refreshPagination');
        this.loadDate();
        this.loadCandidates();
        this.loadClients();
        this.$emit('updateLogActivity');
        this.$emit('addLogActivity', 'Shared Candidates Report');
    },
    methods: {
        resetCandidatesSearch() {
            this.search.params = {
                recruiterId: "",
                ttClientAccountId: "",
                sharedStartDate: "",
                sharedEndDate: ""
            };
            this.loadDate();
            this.disabledStartDates.from =  new Date();
            this.disabledendDates.from =  new Date();
            this.loadCandidates();
        },
        loadDate() {
            let date = new Date();
            this.search.params.sharedStartDate = date;
            this.search.params.sharedEndDate = date;    
        },
        loadCandidates() {
            if (this.search.params.sharedStartDate > this.search.params.sharedEndDate) {
                iziToast.error({
                    title: 'Error',
                    message: this.$config.DateMismatchErr,
                    position: 'topRight'
                });
                return;
            }
            // axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/list/shared/candidates?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/shared/candidates/master?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.candidateShareMasterList = result.sharedCandidates;
                    this.pagination.totalRecords = result.pagination[0].total;
                    this.pagination.currentPage = result.pagination[0].currentPage;
                    console.log(result);
                })
                .catch(error => {
                    console.log(error);
                });

        },
        changeLimit(newlimit) {
            this.pagination.currentPage = 1;
            this.pagination.rowsPerPage = newlimit;
            this.loadCandidates();
        },
        findCandidates() {
            this.pagination.currentPage = 1;
            this.loadCandidates();
        },
        loadClients() {
            axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/client/account/list?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.clientParams)
                .then(response => {
                    let result = response.data;
                    this.clientlist = result.clientAccountList;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);

                });
        },
        getSharedCandidates(shareMasterId) {
            if (this.currentIndex == shareMasterId) {
                this.currentIndex = null;
            } else {
                this.currentIndex = shareMasterId;
            }
            let data = {
                shareMasterId: shareMasterId,
                sharedStartDate: this.search.params.startDate,
                sharedEndDate: this.search.params.endDate
            };
            console.log(data);
            //axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/list/shared/candidates?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/shared/candidates/list', data)
                .then(response => {
                    let result = response.data;
                    this.sharedCandidates = result.sharedCandidates;
                    console.log(this.sharedCandidates);
                })
                .catch(error => {
                    console.log(error);
                });
        },
         exportSharedCandidatesJobs() {
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/report/sharredcandidates?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "SharedCandidates" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });
        },
    }
}

</script>
