<template>
    <div class="kpis reports text-left">
        <div class="row">
            <div class="col-sm-12 p-10">
                <div id="recruiter-settings" class="recruiter settings mt-10">
                    <div class="container-fluid" id="quick-search">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="text-center">Bulk Resume Log</h5>
                            </div>
                        </div>
                        <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <h4 class="page-title">Filter</h4>
                                </div>
                                <div class="col-md-6 col-sm-6 col-6">
                                    <ul class="ul-horizontal box-tools text-right mb-0">
                                        <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :title="isExpand?'Hide':'Expand'" :class="isExpand?'ti-angle-up':'ti-angle-down'" class="hover-hand"></span></a></li>
                                        <li><i class="ti-close hover-hand px-2" @click="$emit('setSubTab',0);$emit('updateLogActivity');" title="Close"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <form id="main-search" :class="isExpand?'d-block':'d-none'">
                                <div class="row form-group ten-columns">
                                    <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>User Name</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <select v-model="search.params.userId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in userList" :value="info.id">{{info.recruiterName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Start Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="date" v-model="search.params.startDate" class="form-control" id="date" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>End Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="date" v-model="search.params.endDate" class="form-control" id="date" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label style="visibility: hidden;"></label>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="button" @click="resetLogSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                                <button type="button" @click="findResumeReport" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Go</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="load-indicator"></div>
                        </div>
                    </div>
                    <div class="container-fluid" id="main-data">
                        <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                            <div class="row" style="margin-bottom: 15px;">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <h4 class="page-title">Summary
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                            <div class="dropdown">
                                                <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                                <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                    <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                                </div>
                                            </div>
                                        </span>
                                    </h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive" style="min-height: 178px;">
                                        <table class="table table-borderless all-table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th class="" scope="col">Sl.No</th>
                                                    <th class="" scope="col">Company</th>
                                                    <th class="" scope="col">User Name</th>
                                                    <th class="" scope="col">Resume Uploaded</th>
                                                    <th class="text-left" scope="col">Uploaded Date</th>
                                                    <th class="text-left" scope="col">Processed Date</th>
                                                    <th class="text-left" scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="resumeReport.length==0">
                                                    <td colspan="8" class="text-center">No data found</td>
                                                </tr>
                                                <template v-for="(resumeinfo, index) in resumeReport">
                                                    <tr>
                                                        <td>
                                                            <a href="javascript:" class="expand" data-targetopen="panel1">
                                                                <span @click="loadBulkResumeFiles(resumeinfo.id)"><i :class="(currentIndex==resumeinfo.id) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
                                                                </span>
                                                            </a>
                                                        </td>
                                                        <td>{{((pagination.currentPage-1) * (pagination.rowsPerPage)) + (index+1)}}</td>
                                                        <td class="">{{ resumeinfo.companyName }}</td>
                                                        <td class="">{{ resumeinfo.userName }}</td>
                                                        <td class="">{{ resumeinfo.resumeLogCount }}</td>
                                                        <td class="">{{ resumeinfo.uploadedAt |formatDate }}</td>
                                                        <td class="">{{ resumeinfo.processedAt |formatDate }}
                                                            <span v-if="resumeinfo.processedAt == null || resumeinfo.processedAt == ''">--</span>
                                                        </td>
                                                        <td class="">
                                                             <span v-if="resumeinfo.status=='OPEN'" class="label bg-primary text-white p-1 rounded w-50-auto small">{{replaceToSpace(resumeinfo.status)}}</span>
                                                            <span v-if="resumeinfo.status=='IN_PROGRESS'" class="label bg-info text-white p-1 rounded w-50-auto small">{{replaceToSpace(resumeinfo.status)}}</span>
                                                            <span v-if="resumeinfo.status=='CLOSED'" class="label bg-success text-white p-1 rounded w-50-auto small">{{ resumeinfo.status }}</span>                                                           
                                                        </td>
                                                    </tr>
                                                    <tr v-if="currentIndex==resumeinfo.id">
                                                        <td colspan="8">
                                                            <div class="table-responsive">
                                                                <table class="table table-borderless all-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">Sl.No</th>
                                                                            <th class="text-left" scope="col">File Name</th>
                                                                            <th class="text-left" scope="col">Status</th>
                                                                            <th class="text-left" scope="col">Description</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <template v-for="(files, index) in bulkResumeFiles">
                                                                            <tr>
                                                                                <td>{{(index+1)}}</td>
                                                                                <td class="text-left">{{ files.fileName }}</td>
                                                                                <td class="text-left">{{ replaceToSpace(files.status) }}</td>
                                                                                <td class="text-left">{{ files.description }}</td>
                                                                            </tr>
                                                                        </template>
                                                                        <tr v-if="bulkResumeFiles.length==0">
                                                                            <td colspan="6" align="center">No files found</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <b-pagination v-if="resumeReport.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadresumeReport()"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    data() {
        return {
            isExpand: false,
            resumeReport: [],
            bulkResumeFiles: [],
            userList: this.$store.getters.getRecruiterByCompany,
            recruitersList: this.$store.getters.getRecruiters,
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            searchlimit: this.$store.state.searchlimit,
            search: {
                params: {
                    userId: "",
                    startDate: "",
                    endDate: ""
                },
            },
            coldisplay: {
                jobTitle: true,
                jobCode: true,
                client: true,
                industry: true,
                position: false
            },
            currentIndex: null
        }
    },
    mounted() {
        this.$emit('updateLogActivity');
        this.$emit('addLogActivity', 'Bulk Resume Log');
        this.$store.commit('refreshPagination');
        this.loadDate();
        //this.getUserList();
        this.loadBulkResumeReport();
        console.log(this.userList);
    },
    methods: {
        resetLogSearch() {
            this.search.params.userId = "";
            this.currentIndex = null;
            this.loadDate();
            this.loadBulkResumeReport();
        },
        loadDate() {
            this.search.params.startDate = new Date().toISOString().substr(0, 10);
            this.search.params.endDate = new Date().toISOString().substr(0, 10);
        },
        loadBulkResumeReport() {
            axios.post(this.$store.getters.getAPIBasePath + '/resumeparserresource/bulkresumelog/list/by/company?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.resumeReport = result.bulkResumeLogs;
                    this.pagination.totalRecords = result.pagination[0].total;
                    this.pagination.currentPage = result.pagination[0].currentPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        changeLimit(newlimit) {
            this.pagination.rowsPerPage = newlimit;
            this.loadBulkResumeReport();
        },
        findResumeReport() {
            this.pagination.currentPage = 1;
            this.loadBulkResumeReport();
        },
        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },
        getUserList() {
            let companyId = 0;
            axios.get(this.$store.getters.getAPIBasePath + '/userresource/user/list/' + companyId)
                .then(response => {
                    let result = response.data;
                    this.userList = result.userList;
                })
                .catch(error => {
                    console.log(error);
                });

        },
        loadBulkResumeFiles(bulkResumeId) {
            if (this.currentIndex == bulkResumeId) {
                this.currentIndex = null;
            } else {
                this.currentIndex = bulkResumeId;
            }
            let data = {
                id: bulkResumeId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resumeparserresource/bulkresumelogfile/list', data)
                .then(response => {
                    let result = response.data;
                    this.bulkResumeFiles = result.bulkResumeLogFiles;
                })
                .catch(error => {
                    console.log(error);
                });
        },
    }
}

</script>
