<template>
    <div id="content_Other_Details">
        <div class="card">
            <div class="card-header p-2">Technology/Skills Expertise </div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row">
                        <div class="col-md-12">
                            <h6>Industry</h6>
                        </div>
                    </div>
                    <div class="row mb-10">    
                        <div class="col-md-3 col-sm-12 col-12">
                            <div class="col-md-12">
                                <select v-model="industryId" @change="loadTechnologyList()" class="form-control">
                                    <option value="" selected>Select</option>
                                    <option v-for="(Industry, index) in industries" :value="Industry.id" v-bind:key="index">{{Industry.industryName}}</option>
                                </select>
                            </div>    
                        </div>
                    </div>                
                    <div class="row">
                        <div class="col-md-3">
                            <div v-if="!showTechDropDown" class="col-md-12 col-sm-12 col-12">
                                <div class="input-group">
                                    <input class="form-control" type="text" v-model="technologyName" placeholder="Enter Technology" />
                                    <a href="javascript:" class="tbl-add-icon" style="float:left;" @click="setTechDropDown"><span class="input-group-addon"><i tite="existing" class="ti-settings"></i></span></a>
                                </div>
                            </div>
                            <div v-if="showTechDropDown" class="col-md-12 col-sm-12 col-12">
                                <multiselect v-model="techSel" :options="technologies" name="technologies" label="technology" track-by="id" select-label="" deselect-label="" @select="loadSkills" class="" style="width:85%; float:left;" :max-height="600" placeholder="Select Technology"></multiselect>
                                <a href="javascript:" class="tbl-add-icon" style="float:left;" @click="setTechTextBox"><span class="input-group-addon"><i title="cancel" class="fa fa-remove"></i></span></a>
                            </div>
                        </div>
                        <div class="col">
                            <div v-if="!showSkillDropDown" class="col-md-12 col-sm-12 col-12">
                                <!--  <input class="form-control" type="text" v-model="skillName" data-toggle="modal" data-target="#CandidateAvailability"  /> -->
                                <MultiItemInput class="form-control" id="skillInput" :items="skillName" style="width:85%; float:left;" placeholder="Enter Skill" />
                                <a href="javascript:" class="tbl-add-icon" style="float:left;" @click="setSkillDropDown"><span class="input-group-addon"><i title="existing" class="ti-settings"></i></span></a>
                            </div>
                            <div v-if="showSkillDropDown" class="col-md-12 col-sm-12 col-12">
                                <multiselect v-model="skillSel" :options="skills" name="skills" label="skillName" track-by="skillId" select-label="" deselect-label="" class="" style="width:85%; float:left;" :max-height="600" placeholder="Select Skill" :multiple="true" :close-on-select="false"></multiselect>
                                <a href="javascript:" class="tbl-add-icon" style="float:left;" @click="setSkillTextBox"><span class="input-group-addon"><i title="cancel" class="fa fa-close"></i></span></a>
                            </div>
                        </div>
                        <div class="col-md-1 col-sm-2">
                            <a v-if="techEditIndex<0" class="addskills" href="javascript:" @click="saveSkills">
                                <span title="add" class="fa fa-plus-square fa-lg"></span></a>
                            <a v-if="techEditIndex!=-1" style="float:left;" href="javascript:" class="tbl-add-icon" @click="updateSkillDetails(1)"><span class="input-group-addon"><i class="ti-check"></i></span></a>
                            <a v-if="techEditIndex!=-1" style="float:left;" href="javascript:" class="tbl-add-icon" @click="updateSkillDetails(0)"><span class="input-group-addon"><i class="ti-close"></i></span></a>
                        </div>
                    </div>
                    <div v-if="notEmptyTech(Skills)" class="row" style="margin-top:10px;" v-for="(techInfoOne,index) in Skills" v-bind:key="index">
                        <div class="col-md-3">
                            <div class="form-control">
                                <span> {{techInfoOne.technology.technology}}</span>
                                <span> {{techInfoOne.technologyName}}</span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-control skillList" v-if="techInfoOne.skills != null && techInfoOne.skills.length != 0">
                                <ul>
                                    <li v-for="(item1, index1) in techInfoOne.skills" :key="index1" class="item rounded"><span class="text">{{ item1.skillName }}</span> <a href="javascript:;" @click="removeSkill(index, index1)"><i class="ti-close item-close-icon1"></i></a></li>
                                </ul>
                            </div>
                            <div class="form-control skillList" v-if="techInfoOne.skills != null &&  techInfoOne.skills.length == 0">
                                <ul>
                                    <li v-for="(item1, index1) in techInfoOne.skillName" :key="index1" class="item rounded"><span class="text">{{ item1 }}</span> <a href="javascript:;" @click="removeSkill1(index, index1)"><i class="ti-close item-close-icon1"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-1 col-sm-2">
                            <a style="float:left;" href="javascript:" class="tbl-add-icon" @click="removeOneTechDetail(index)"><span class="input-group-addon"><i class="ti-trash"></i></span></a>
                            <a style="float:left;" href="javascript:" class="tbl-add-icon" @click="editOneTechDetail(techInfoOne.technology.technology,index)"><span class="input-group-addon"><i class="ti-pencil"></i></span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-header p-2"><a href="Javascript:void(0);" @click="saveSkills" class="btn btn-primary  float-right"> Save</a></div>
        </div>
        <div class="modal" id="SkillMore" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Skills</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <textarea class="form-control more-text-area" v-model="tempSkillISet"></textarea>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="updateSkillMore()" class="btn btn-primary" data-dismiss="modal">OK</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="removeTechSkills" :onYes="deleteTechSkill" :returnParams="delTechSkillParam" />
        <!-- <div class="card">
            <div class="card-header p-2">Special Note</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <textarea rows="3" class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>
<style>
tbody.cloned_skill_tr>tr>td {
    vertical-align: top !important;
}

</style>
<script>
import { required, integer } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import MultiItemInput from '../../../plugins/multiItemInput';

export default {
    components: {
        Multiselect,
        MultiItemInput
    },
    props: {
        Skills: Array, //dropdown value
        profileId: Number,
        industryId1: Number
    },
    watch: {
        'industryId1': {
            handler: function(value) {
                if(value!=null){
                    this.industryId = value;
                    this.loadTechnologyList();
                }else{
                    this.industryId = "";
                }
            },
            deep: true,
            immediate: true
        },
        'industryId': {
            handler: function(value) {
                if(value != this.industryId1){
                    this.Skills.splice(0,this.Skills.length);
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            industryId:"",
            showTechDropDown: false,
            showSkillDropDown: false,
            elements: {
                submitted: false
            },
            industrylist: this.$store.getters.getIndustries,
            delTechSkillParam: {
                index: -1,
                id: 0
            },
            tempSkillId: 0,
            tempSkillISet: '',
            industries: this.$store.getters.getIndustries,
            // technologies: this.$store.getters.getTechOptions, //dropdown options
            skills: [], //dropdown options
            techSel: "", //dropdown value
            skillSel: "", //dropdown value
            techEditIndex: -1,
            technologyName: "",
            skillName: [],
            technologies: [],
        }
    },
    /*watch: {
        Skills: function(newValue, oldValue) {
            this.skillSel = this.Skills;
        }
    },*/
    validations: {
        skillSel: {
            required
        }
    },
    mounted() {
        this.$emit('loadProfileData');
        this.loadTechnologyList();
    },
    methods: {
        loadTechnologyList() {
            if(this.industryId){
            axios.get(this.$store.getters.getAPIBasePath + '/technologyresource/get/industry/' + this.industryId)
                .then(response => {
                    this.technologies = response.data.technology;
                })
                .catch(error => {
                    console.log(error);
                });
            }
        },
        setTechDropDown: function() {
            this.showTechDropDown = true;
            this.showSkillDropDown = true;
        },
        setTechTextBox: function() {
            this.showTechDropDown = false;
            this.showSkillDropDown = false;
            
        },
        setSkillDropDown: function() {
            this.showSkillDropDown = true;
            this.showTechDropDown = true;
        },
        setSkillTextBox: function() {
            this.showSkillDropDown = false;
            this.showTechDropDown = false;
            this.techSel = "";
            this.skillSel = "";
        },
        loadSkills(selected_technology) {
            this.skills = [];
            if (selected_technology.settingsTechnologySkill)
                this.skills = selected_technology.settingsTechnologySkill;
            else
                this.skills = [];
        },
        saveSkills: function() {
            this.addMoreTechInfo();
            let techAndSkill = [];
            for (var i = 0; i < this.Skills.length; i++) {
                if (this.Skills[i].skills.length > 0) {
                    for (var j = 0; j < this.Skills[i].skills.length; j++) {
                        techAndSkill.push({
                            technologyId: this.Skills[i].technology.id,
                            skillId: this.Skills[i].skills[j].skillId,
                            title: '',
                            technologyName: this.Skills[i].technology.technology, //this.Skills[i].technologyName,
                            skillName: this.Skills[i].skills[j].skillName, //    this.Skills[i].skillName
                            industryId: this.industryId
                        });
                    }
                } else {
                    for (var j = 0; j < this.Skills[i].skillName.length; j++) {
                        techAndSkill.push({
                            technologyId: this.Skills[i].technology.id,
                            skillId: null,
                            title: '',
                            technologyName: this.Skills[i].technologyName,
                            skillName: this.Skills[i].skillName[j],
                            industryId: this.industryId
                        });
                    }
                }
            }

            axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/update/interviewer/' + this.profileId + '/skill', { 'interviewersSkill': techAndSkill })
                .then(response => {
                    iziToast.success({ title: 'Success', message: this.$config.IntTechUpSuc, position: 'topRight' });
                    this.$emit('loadPersInfo');
                })
                .catch(error => {
                    iziToast.error({ title: 'Error Occured', message: this.$config.IntTechUpErr, position: 'topRight' });
                });

        },
         removeSkill1: function(index,index1) {
             this.Skills[index].skillName.splice(index1, 1);
         },
        removeSkill: function(index,index1) {
              this.Skills[index].skills.splice(index1, 1);
        },
        loadSkills1: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/interviewerresource/view/interviewer/' + this.profileId + '/skill')
                .then(response => {
                    this.$parent.$emit('updateSkills', response.data.interviewersSkill);
                    //this.updateSkills(response.data.interviewersSkill);

                });
        },
        updateSkills: function(var_Skills) {
            let skill = Object.assign({}, this.new_TechSkill);
            this.Skills = [];
            if (var_Skills.length == 0) {
                this.Skills = [skill];
            } else {
                for (var i = 0; i < var_Skills.length; i++) {
                    let Value = var_Skills[i];
                    let skill = Object.assign({}, this.new_TechSkill);
                    skill.id = Value.id;
                    skill.skillType = Value.skillType;
                    skill.industryId = Value.industryId;
                    skill.technologyId = Value.technologyId;
                    skill.experience = Value.experience;
                    skill.idx = i + 1;
                    skill.technologyName = Value.technologySet.technology;
                    skill.skillSet = Value.skillSet;
                    this.Skills.push(skill);
                }

            }
            //this.$parent.updateSkills([]);
        },
        deleteTechSkill: function(arg) {
            this.removeSkill(arg.index);
        },
        addMoreTechInfo() {
            if (this.techSel == '' && this.skills.length == 0 && this.technologyName == '' && this.skillName == '')
                return false;

            var searchIdx = -1;
            if (this.techSel != null && this.techSel.id != undefined) {
                for (var i = 0; i < this.Skills.length; i++) {
                    if (this.Skills[i].technology.id == this.techSel.id) {
                        searchIdx = i;
                        break;
                    }
                }
            } else {
                for (var i = 0; i < this.Skills.length; i++) {
                    if (this.Skills[i].technology.technology == this.technologyName) {
                        searchIdx = i;
                        break;
                    }
                }
            }
            let techInfoTmp = '';
            if (searchIdx < 0) {
                techInfoTmp = {
                    technology: (this.techSel != null ? { id: this.techSel.id, technology: this.techSel.technology } : { id: undefined, technology: this.technologyName }),
                    skills: (this.skillSel != null ? this.skillSel : this.skillName),
                    technologyName: this.technologyName,
                    skillName: this.skillName,
                    industryId: this.industryId
                }

                if (this.notNullTech(techInfoTmp)) {
                    this.Skills.push(techInfoTmp);
                }
            } else {
                this.Skills[searchIdx] = {
                    technology: (this.techSel != null ? { id: this.techSel.id, technology: this.techSel.technology } : { id: undefined, technology: this.technologyName }),
                    skills: (this.skillSel != null ? this.skillSel : this.skillName),
                    technologyName: this.technologyName,
                    skillName: this.skillName,
                    industryId: this.industryId
                };
            }
            this.skillSel = [];
            this.techSel = '';
            this.skills = [];
            this.technologyName = '';
            this.skillName = [];
        },
        editOneTechDetail(technologyId,index) {
            var allTech = [];
            var skillname = []; 
            for(var i=0; i< this.technologies.length;i++){
                allTech.push(this.technologies[i].technology)
            }
            if(allTech.includes(this.Skills[index].technology.technology)) {
            this.showTechDropDown = true;
            this.showSkillDropDown = true;
            this.techSel = this.Skills[index].technology;
            this.skillSel = this.Skills[index].skills;
            }else{
                this.showTechDropDown = false;
                this.showSkillDropDown = false;
                console.log(this.Skills[index])
                this.technologyName = (this.Skills[index].technologyName == undefined? this.Skills[index].technology.technology : this.Skills[index].technologyName);
                for(var i= 0; i<this.Skills[index].skills.length ;i++){
                    skillname.push(this.Skills[index].skills[i].skillName)     
                }
                this.skillName = (skillname !=undefined &&  skillname.length !=0? skillname: this.Skills[index].skillName);
            }
            this.techEditIndex = index;
            this.techSkill = this.Skills[index].technology;
            this.skills = [];
            let result =  this.filterSkillsByTechnology(this.techSkill.id);
            if(result[0] != null){
                this.skills = result[0].settingsTechnologySkill;
            }            
        },
        filterSkillsByTechnology: function(techId) {
            return this.technologies.filter(function(data) {
                if (data.id == techId) {                  
                    return data;
                }
            });
        },
        updateSkillDetails(mode) {
            if (mode == 1) {
                this.Skills[this.techEditIndex] = {
                    technology: { id: this.techSel.id, technology: this.techSel.technology },
                    skills: this.skillSel,
                    experience: this.exprSel,
                    industry: this.industrySel,
                    technologyName: this.technologyName,
                    skillName: this.skillName,
                    industryId: this.industryId
                };
            }
            this.techEditIndex = -1;
            this.skills = [];
            this.skillSel = [];
            this.techSel = '';
            this.exprSel = '';
            this.industrySel = '';
            this.technologyName = ''
            this.skillName = [];

        },
        removeOneTechDetail(index) {
            if (index == this.techEditIndex)
                this.updateSkillDetails(0);
            this.techEditIndex = -1;
            this.Skills.splice(index, 1);
        },
        notEmptyTech: function(techSkills) {
            let result = false;
            techSkills.map(techSkill => {
                if ((techSkill.skills != null && techSkill.skills.length) ||
                    (techSkill.skillName != null) || (techSkill.technologyName != null) ||
                    (techSkill.technology != null && techSkill.technology.id != null && techSkill.technology.technology != null))
                    result = true;
            });
            return result;
        },
        notNullTech: function(techSkill) {
            if (typeof techSkill == "object" && techSkill.technology.id == null && techSkill.technology.technology == null && techSkill.skills.length == 0 && techSkill.technologyName == '' && techSkill.skillName == '')
                return false;
            else
                return true;
        }
    }

}

</script>
