var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header p-2"},[_vm._v("View Client Account")]),_c('div',{staticClass:"card-body p-2"},[_c('div',{staticClass:"basic-block col-md-5"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped table-borderless s_table compare-table"},[_c('tbody',[_c('tr',[_vm._m(0),_c('td',{attrs:{"width":"50%"}},[_vm._v(_vm._s(_vm.clientInfo.accountName))])]),_c('tr',[_vm._m(1),_c('td',{attrs:{"width":"50%"}},[_vm._v(_vm._s(_vm.clientInfo.accountTypeName))])]),_c('tr',[_vm._m(2),_c('td',{attrs:{"width":"50%"}},[_vm._v(_vm._s(_vm.clientInfo.companyName))])]),_c('tr',[_vm._m(3),_c('td',{attrs:{"width":"50%"}},[_vm._v(_vm._s(_vm.clientInfo.companyEmail))])]),(_vm.clientInfo.companyPhoneno)?_c('tr',[_vm._m(4),_c('td',{attrs:{"width":"50%"}},[_vm._v(_vm._s(_vm.clientInfo.companyPhoneno))])]):_vm._e(),_c('tr',[_vm._m(5),_c('td',{attrs:{"width":"50%"}},[_vm._v(_vm._s(_vm.getCountryName(_vm.clientInfo.companyCountry)))])]),(_vm.clientInfo.timezone)?_c('tr',[_vm._m(6),_c('td',{attrs:{"width":"50%"}},[_vm._v(_vm._s(_vm.clientInfo.timezone))])]):_vm._e()])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"width":"50%"}},[_c('strong',[_vm._v("Account Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"width":"50%"}},[_c('strong',[_vm._v("Account Type")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"width":"50%"}},[_c('strong',[_vm._v("Company Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"width":"50%"}},[_c('strong',[_vm._v("Email")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"width":"50%"}},[_c('strong',[_vm._v("Phone Number")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"width":"50%"}},[_c('strong',[_vm._v("Country")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"width":"50%"}},[_c('strong',[_vm._v("Time Zone")])])
}]

export { render, staticRenderFns }