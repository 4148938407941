<template>
    <div :class="{ 'viewonly' : availabilityinfo.viewmode == 1}">
        <div id="main">
            <div class="container-fluid" id="main-data">
                <div class="row row-flex">
                    <div v-if = "editmode != 0" class="col-md-3 pr-md-2 pl-0">
                        <div class="box-wrapper shadow-sm p-4  bg-white rounded pos-rel h-100">
                            <div class="key_skills">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <div class="row">
                                            <div class="col-md-10 col-12">
                                                <h4 class="page-title mt-3">Set Availability </h4>
                                            </div>
                                            <!-- <div class="col-md-2 col-12 text-right">                 
                                                <button type="button" class="btn btn-primary btn-sm pull-right mt-3"  data-toggle="modal" data-target="#CandidateAvailability" @click="resetAvailabilityPopupForm" style="margin-left: 10px;">New</button>                            
                                            </div> -->
                                        </div>
                                    </div>
                                </div>    
                                <hr>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Start Date</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group date" data-date-format="mm-dd-yyyy">
                                            <datepicker placeholder="Start Date" v-model="eventinfo.startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" ></datepicker>
                                            <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                        </div>
                                        <label v-if="$v.eventinfo.startDate.$error && !$v.eventinfo.startDate.required" class="text-danger">Please select start date</label> 
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>End Date</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group date" data-date-format="mm-dd-yyyy">
                                            <datepicker placeholder="End Date" v-model="eventinfo.endDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" ></datepicker>
                                            <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                        </div>
                                        <label v-if="$v.eventinfo.endDate.$error && !$v.eventinfo.endDate.required" class="text-danger">Please select end date</label> 
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-sm-6 col-6">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Start Time</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group date" data-date-format="mm-dd-yyyy">
                                                    <b-form-input id="startTime" v-model="eventinfo.startTime" type="time" class="form-control" name="startTime"></b-form-input>
                                                </div>
                                                <label v-if="$v.eventinfo.startTime.$error && !$v.eventinfo.startTime.required" class="text-danger">Please enter start time</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-6 col-6">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>End Time</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group date " data-date-format="mm-dd-yyyy">
                                                    <b-form-input id="endTime" v-model="eventinfo.endTime" type="time" class="form-control" name="endTime"></b-form-input>
                                                </div>
                                                <label v-if="$v.eventinfo.endTime.$error && !$v.eventinfo.endTime.required" class="text-danger">Please enter end time</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3" :class="{ 'disable-btn' : availabilityinfo.viewmode == 1}">
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <button type="button" id="deleteEventButton" class="btn btn-danger btn-sm" data-toggle="modal" data-target="#deleteAvailability" style="box-shadow: 2px 3px 8px #888 !important;"> Delete</button>
                                        <button type="button" class="btn btn-primary float-right btn-sm" style="margin-left: 10px;" @click="saveAvailability()"> Save</button>
                                        <button type="button" class="btn btn-primary float-right btn-sm" style="margin-left: 10px;" @click="resetAvailabilityForm"> Reset</button>
                                    </div>
                                </div>
                            </div>  
                            <hr>                        
                        </div>
                    </div>
                    <div v-if = "editmode != 0" class="col-md-9 pl-md-2 pr-0">
                        <div class="box-wrapper shadow-sm p-4  bg-white rounded pos-rel h-100">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <h4 class="page-title">Candidate Availability</h4>
                                </div>
                            </div>
                            <div class="calendar-parent">
                                <fullcalendar ref="availabilityCal" :calendarEvents="calendarEvents" @event-selected="eventSelected"></fullcalendar>
                            </div>
                        </div>
                    </div>
                    <div v-if = "editmode == 0" class="col-md-12 pl-md-2 pr-0">
                        <div class="box-wrapper shadow-sm p-4  bg-white rounded pos-rel h-100">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <h4 class="page-title">Candidate Availability</h4>
                                </div>
                            </div>
                            <div class="calendar-parent">
                                <fullcalendar ref="availabilityCal" :calendarEvents="calendarEvents"></fullcalendar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <confirm ref="confirm" v-if="deleteflag==1"></confirm> -->
        </div>
        <div class="modal " id="deleteAvailability" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Delete confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                Are you sure to delete this event?
                </div>
                <div class="modal-footer">
                    <button type="button" @click="deleteAvailability()" class="btn btn-primary" data-dismiss="modal">Yes</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import iziToast from 'izitoast';
    import moment from 'moment';
    import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
    export default {
        props: {
            availabilityinfo: Object,
            candidateid: Number
        },
        computed:{
            /*candidateId(){
                if(this.$route.params.candidateid)
                   return this.$route.params.candidateid;
               else return 0;
            }*/
        },
        // components : {
        //     confirm
        // },
        data() {
            return {
                editmode:0,
                userInfo:this.$store.getters.currentUser,
                deleteflag:0,
                eventinfo : {
                    id:"",
                    startDate:"",
                    endDate:"",
                    startTime:"",
                    endTime:"",
                    viewmode:1
                },
                eventPopupInfo : {
                    startDate:"",
                    endDate:"",
                    startTime:"",
                    endTime:""
                },
                CandidateInfo : {
                    name:"",
                    email:"",
                    mobile:""
                },
                availabilityData : {
                    CandidateId:"",
                    availableStartDate:"",
                    availableEndDate:"",
                    endDate:"",
                    availableTimeFrom:"",
                    availableTimeTo:""
                },
                calendarEvents: []
            }
        },
        validations: {
            eventinfo:{
                startDate:{
                    required
                },
                endDate:{
                    required
                },
                startTime:{
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(30)
                },
                endTime:{
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(30)
                }
            },
            eventPopupInfo:{
                startDate:{
                    required
                },
                endDate:{
                    required,
                },
                startTime:{
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(30)
                },
                endTime:{
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(30)
                }
            }
        },
        mounted() {
            this.loadAvilabilityList();
            this.resetAvailabilityForm();
            this.refreshCalendar();
            // this.$root.$confirm = this.$refs.confirm.open
        },
        methods: {
            refreshCalendar(){
                this.$refs.availabilityCal.forceRerender();
            },
            loadAvilabilityList(){
                axios.get(this.$store.getters.getAPIBasePath+'/candidateresource/availabilityList/' + this.candidateid)
                .then(response => {
                    let result = response.data;
                    if(!response.status){
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position:'topRight'
                        });
                    }
                    this.calendarEvents=result;
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.CandidAvailLoadErr,
                        position:'topRight'
                    });
                });
            },
            eventSelected(event) {
                this.editmode=1;
                this.eventinfo.id=event.id;
                this.eventinfo.startDate=event.start;
                this.eventinfo.endDate=event.end;
                this.eventinfo.startTime=moment(String(event.start)).format('HH:mm');
                this.eventinfo.endTime=moment(String(event.end)).format('HH:mm');
                $('html, body').animate({
                   scrollTop: $(".key_skills").offset().top
                }, 1000);
                $('#deleteEventButton').prop('disabled', false);
            },
            resetAvailabilityForm() {
                this.editmode=0;
                this.eventinfo.id="";
                this.eventinfo.startDate="";
                this.eventinfo.endDate="";
                this.eventinfo.startTime="";
                this.eventinfo.endTime="";
                this.$v.eventinfo.$reset();
                $('#deleteEventButton').prop('disabled', true);
            },
            resetAvailabilityPopupForm() {
                this.eventPopupInfo.startDate="";
                this.eventPopupInfo.endDate="";
                this.eventPopupInfo.startTime="";
                this.eventPopupInfo.endTime="";
            },
            saveAvailability() {
                this.$v.eventinfo.$touch();
                if (this.$v.eventinfo.$invalid) {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.FieldRequir,
                        position:'topRight'
                    });
                    return;
                }
                if(this.eventinfo.startDate>this.eventinfo.endDate) {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.DateMismatchErr,
                        position:'topRight'
                    });
                    return;
                }
                if(this.eventinfo.startTime>=this.eventinfo.endTime) {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.TimeMismatchErr,
                        position:'topRight'
                    });
                    return;
                }
                if(this.editmode==0) this.eventinfo.id=0;
                let availabilityData = {
                    id:this.eventinfo.id,
                    candidateId:this.candidateId,
                    availableStartDate:this.formatDateString(this.eventinfo.startDate),
                    availableEndDate:this.formatDateString(this.eventinfo.endDate),
                    endDate:this.formatDateString(this.eventinfo.endDate),
                    availableTimeFrom:this.eventinfo.startTime,
                    availableTimeTo:this.eventinfo.endTime
                };
                console.log(availabilityData);
                axios.post(this.$store.getters.getAPIBasePath+'/candidateresource/save/availability',availabilityData)
                .then(response => {
                    let result = response.data;
                    if(!response.status){
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position:'topRight'
                        });
                    }
                    else {
                        iziToast.success({
                            title: 'Success',
                            message: this.$config.CandidAvailSuc,
                            position:'topRight'
                        });
                        this.loadAvilabilityList();
                        //this.resetAvailabilityForm();
                    }
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.UnableToCreAvailInfo,
                        position:'topRight'
                    });
                });
            },
            deleteAvailability() {
                axios.post(this.$store.getters.getAPIBasePath+'/candidateresource/delete/availability/' + this.eventinfo.id)
                .then(response => {
                    let result = response.data;
                    if(!response.status){
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position:'topRight'
                        });
                    }
                    else {
                        iziToast.success({
                            title: 'Success',
                            message: this.$config.CandAvailDelSuc,
                            position:'topRight'
                        });
                        this.loadAvilabilityList();
                    }
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.CandAvailDelErr,
                        position:'topRight'
                    });
                });
            }
        }
    }
</script>