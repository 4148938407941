<template>
    <div class="d-height">
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mtb-4 bg-white rounded pos-rel">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a @click="searchMore=!searchMore" href="javascribt:;" data-target="main-search" class="boxhide">
                                        <span v-if="!searchMore" class="ti-angle-down"></span>
                                        <span v-if="searchMore" class="ti-angle-up"></span>
                                    </a></li>
                            </ul>
                        </div>
                    </div>
                    <form v-if="searchMore" id="main-search" style="margin-top:15px;">
                        <div class="row form-group ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label> Interview Title</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" class="form-control" v-on:keyup.enter="findinterviews"   placeholder="Interview Title" v-model="search.params.title">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Interview Date</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <datepicker placeholder="Interview Date" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.interviewDate"></datepicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb" style="visibility: hidden;">
                                        <label>End Date</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12 float-right">
                                        <button type="button" class="btn btn-primary  float-right" style="margin-left: 10px;" @click="loadInterviews(1)">Reset</button>
                                        <button type="button" class="btn btn-primary  float-right" style="margin-left: 10px;" @click="findinterviews">Find</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="container-fluid" id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-12 col-sm-12 col-12">
                            <h4 class="page-title">
                                Interviews
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{page_conf.totalRecords}} - Total)</span>
                            </h4>
                        </div>
                    </div>
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-12 col-sm-12 col-12">
                            <div class="dropdown">
                                <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                    <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col">Interview Title
                                            </th>
                                            <th class="" scope="col">Interview Date
                                            </th>
                                            <th class="" scope="col">Start Time
                                            </th>
                                            <th class="" scope="col">End Time
                                            </th>
                                            <th class="" scope="col">Assigned By
                                            </th>
                                            <!--  <th class="" scope="col"> Actions
                                            </th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="this.request_data.length==0">
                                            <td colspan="7" class="text-center">No record found</td>
                                        </tr>
                                        <tr v-for="req_data in this.request_data" v-bind:key="req_data.id">
                                            <td>{{req_data.title}}</td>
                                            <td>{{req_data.showInterviewDate | formatDate }}</td>
                                            <td>{{toClientTimeZoneDate(req_data.interviewStartTime, timeformat) }}</td>
                                            <td>{{toClientTimeZoneDate(req_data.interviewEndTime, timeformat) }}</td>
                                            <td>{{req_data.assignedBy}}</td>
                                            <!-- <td class="actions">
                                                <a href="Javascript:void(0);" @click="viewFeedback(req_data.id,req_data.candidateId)" title="View Interview" class="p-2"><i class="ti-calendar"></i></a>
                                            </td> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <b-pagination v-if="request_data.length!=0" size="md" class='pull-right' :total-rows="page_conf.totalRecords" v-model="page_conf.currentPage" :per-page="page_conf.rowsPerPage" @input="loadInterviews(0)"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div>
                    <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
                </div>                   
            </template> 
        </b-overlay>
       
    </div>
</template>
<script>
import iziToast from 'izitoast';

export default {
    data() {
        return {
            showOverlay:false,
            page_conf: this.$store.state.paginationconfig,
            searchlimit: this.$store.state.searchlimit,
            request_data: [],
            page_conf: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            search: {
                params: {
                    title: '',
                    interviewDate: ''
                }
            },
            assigned_on: {
                date_on: '',
                from_time: '',
                to_time: '',
                id: 0
            },
            searchMore:true,
            dateformat:this.$store.state.dateformat,
            timeformat:this.$store.state.timeformat,
        }
    },
    watch: {
        'showOverlay': {
            handler: function(value) { 
                if(value == true){
                    $('#globalapploader').css('visibility','hidden');
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted: function() {
        this.loadInterviews(0);
        //console.log(this.$store.state.searchlimit);
    },
    methods: {
        findinterviews(){
            this.page_conf.currentPage = 1;
            this.loadInterviews(0);
        },
        async loadInterviews(arg) {
            this.showOverlay=true;
            await this.getInterviews(arg);
            this.showOverlay=false;
        },
        getInterviews: function(arg) {
            this.showOverlay=true;
            if (arg == 1) {
                this.search.params.title = '';
                this.search.params.interviewDate = '';
            }

            return axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/list/candidate/interviews/' + this.$store.getters.currentUser.profileId + '?page=' + this.page_conf.currentPage + '&size=' + this.page_conf.rowsPerPage, this.search.params)
                .then(response => {
                    //console.log(response);
                    let result = response.data;
                    
                    if (!result.interviews) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.RecNotFou,
                            position: 'topRight'
                        });
                    } else {
                        this.request_data = Array.from(result.interviews);
                        //console.log(this.request_data)
                        // this.page_conf=Object.assign({}, result.pagination[0]);
                        this.page_conf.totalRecords = result.pagination[0].total;
                        this.page_conf.currentPage = result.pagination[0].currentPage;
                        this.page_conf.totalpages = result.pagination[0].lastPage;
                    }
                });
        },
       
        changeLimit(newlimit) {
            this.page_conf.currentPage = 1;
            this.page_conf.rowsPerPage = newlimit;
            this.loadInterviews(0);
        },
        viewFeedback(interviewId, candidateId) {
            localStorage.setItem('feedbackInterviewId', parseInt(interviewId))
            localStorage.setItem('feedbackCandidateId', parseInt(candidateId))
            // localStorage.setItem('feedbackRpInterviewId',parseInt(rpInterviewId))
            this.$router.push('/interviewer/feedback/' + interviewId)
        }
    }
}

</script>
