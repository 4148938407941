<template>
    <div class="job reports">
        <div class="row m-0">
            <div class="col-sm-12 p-10">
                <div id="recruiter-settings" class="recruiter settings mt-10">
                    <div class="box-wrapper shadow-sm bg-white rounded pos-rel">
                        <div class="p-4 header mx-0 mb-n1">
                            <div class="row col-md-12 mx-0 px-0">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <h4 class="page-title text-left">Job Report</h4>
                                </div>
                            </div>
                        </div>
                        <div class="row col-md-12 py-3 text-left">
                            <div class="col-md-4">
                                <div @click="$emit('setSubTab',1)" class="row col-md-12 ml-0 left-bar-tab">
                                    <div class="col-md-3 p-3">
                                        <img src="@/assets/icons/applicant-hire-ratio.png" width="100%" height="100%">
                                    </div>
                                    <div class="col-md-8 align-self-center">
                                        <h6><b>Job Details</b></h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div @click="$emit('setSubTab',2)" class="row col-md-12 ml-0 left-bar-tab">
                                    <div class="col-md-3 p-3">
                                        <img src="@/assets/icons/import-file.png" width="100%" height="100%">
                                    </div>
                                    <div class="col-md-8 align-self-center">
                                        <h6><b>Custom Report</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 p-10 mt-4">
                <div class="box-wrapper shadow-sm bg-white rounded pos-rel">
                    <div class="basic-block p-4">
                        <h6 class="text-center customreport-title my-0">Custom Reports</h6>
                        <div class="table-responsive m-0">
                            <table class="table table-borderless all-table">
                                <tbody>
                                    <tr v-for="(report,index) in customReport">
                                        <td class="text-left" @click="$emit('setSubTab',2,'view',report.id)">
                                            <a href="javascript:">{{report.reportName}}</a>
                                        </td>
                                        <td class="text-right">
                                            <a href="javascript:" @click="$emit('setSubTab',2,'edit',report.id)" title="Edit" class="p-0"><i class="fa fa-pencil fa-lg "></i></a>
                                            <a href="javascript:" @click="deleteCustomReport(report.id)" title="Remove" data-toggle="modal" data-target="#removeCustomReport" class="p-0"><i class="fa fa-trash fa-lg pl-2"></i></a>
                                        </td>
                                    </tr>
                                    <tr v-if="customReport.length == 0">
                                        <td colspan="2">No reports found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="removeCustomReport" :onYes="removeCustomReport" title="Delete Confirmation" message="Are you sure to delete this report ?" />
    </div>
</template>

<script>
    import iziToast from 'izitoast';
    export default {
        data() {
            return {
                elements: {
                    toDelete: {
                        customReportId: null
                    }
                },
                customReport: [],
            }
        },
        mounted() {
            this.loadCustomReport();
            this.$emit('addLogActivity','Job Reports');
        },
        methods: {
            loadCustomReport() {
                axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/list/custom/report/2')
                .then(response => {
                    let result = response.data.customReport;
                    this.customReport = result;
                })
                .catch(error => {
                    console.log(error);
                });
            },
            removeCustomReport() {
                axios.delete(this.$store.getters.getAPIBasePath+'/reportsresource/delete/custom/report/'+this.elements.toDelete.customReportId)
                .then(response => {
                    if(response.data.status == "OK"){
                        this.loadCustomReport();
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            },
            deleteCustomReport(id) {
                this.elements.toDelete.customReportId = id;
            }
        }
    }
</script>
