<template>
	<div id="calendarholder">
		<FullCalendar 
			:events="calendarEvents"
			ref="fullCalendar"
			eventColor="#007bff"
			:displayEventEnd="displayEventEnd"
			:eventTimeFormat="eventTimeFormat"
			eventTextColor="#fff" 
			defaultView="dayGridMonth" 
			:plugins="calendarPlugins"
			@eventClick="eventSelected"
			@dateClick="renderDate"
			selectable=true
			:selectAllow="selectRules"
			eventLimit=2
			:buttonText="changeText"
			@eventMouseEnter="renderEventHover"
		/>
	</div>
</template>

<script>
	import FullCalendar from '@fullcalendar/vue';
	import dayGridPlugin from '@fullcalendar/daygrid';
	import moment from 'moment';
	import interactionPlugin from '@fullcalendar/interaction';
	export default {
		props: {
            calendarEvents: Array
        },
		components: {
		    FullCalendar
		},
		mounted(){
			this.obj = this.$refs.fullCalendar.getApi();
			this.obj.viewRender=function(currentView){
		        var minDate = moment();
		        if (minDate >= currentView.start && minDate <= currentView.end) {
		            $(".fc-prev-button").prop('disabled', true); 
		            $(".fc-prev-button").addClass('fc-state-disabled'); 
		        }
		        else {
		            $(".fc-prev-button").removeClass('fc-state-disabled'); 
		            $(".fc-prev-button").prop('disabled', false); 
		        }
		    };
		},
		data() {
			return {
				cobj:null,
				calendarPlugins: [ dayGridPlugin, interactionPlugin ],
				eventTimeFormat : {
					hour: 'numeric',
					minute: '2-digit',
					meridiem: 'short'
				},
			    displayEventEnd:true,
			    disabledDates: {
		          to: new Date(Date.now() - 8640000)
		        },
		        componentKey: 0,
		        changeText: {
		        	today:'Today'
		        }
			}
		},
		methods : {
			eventSelected(event,jsEvent,view){
				this.$emit('event-selected', event.event)
			},
			forceRerender() {
		   		this.obj.render();
		    },
		    renderDate(date){
				this.$emit('date-click', date)
			},
			selectRules(selectInfo) {
		 		return (selectInfo.start < new Date(this.$options.filters.formatDate(new Date())))?false:true;
			},
			renderEventHover(info){
				this.$emit('mouse-hover-event', info)
			}
		}
	}
</script>