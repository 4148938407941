<template>
    <div id="main" class="tech-skill-settings settings recruiter">
        <div class="row col-md-12 px-0 py-4">
            <div class="col-md-4 offset-md-4">
                <h4 class="">Job Documents</h4>
            </div>
            <div class="col-md-1 offset-md-3">
                <a href="Javascript:void(0);" class="pull-right btn-back" @click="$emit('setSubTab',0)">
                    <!-- <i class="ti-arrow-left"></i> Back -->
                    <h5 class="text-blue"><span title="Go Back" class="ti-arrow-left py-4"></span><span class="pl-2">Back</span></h5>
                </a>
            </div>
        </div>
        <div class="row" v-if="editIndex<0">
            <div class="col-md-6 col-sm-8 col-6">
                <!-- <multiItemInput id="multiTech" placeholder="Enter Job Document" :items="newJobDocumentList" class="float-left"></multiItemInput> -->
                <input class="form-control" placeholder="Enter Job Document" v-model="newJobDocument.documentName" />
                <p v-if="formErrors.length">
                    <ul>
                        <li class="error" v-for="error in formErrors">{{ error.message }}</li>
                    </ul>
                </p>
            </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                <a href="Javascript:void(0);" @click="addJobDocument">
                    <span class="ti-plus"></span>
                </a>
            </div>
        </div>
        <div class="row" v-if="editIndex!=-1">
            <div class="col-md-6 col-sm-8 col-6">
                <input class="form-control" placeholder="Enter new sourcing channel" v-model="newJobDocument.documentName" />
            </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                <a style="float:left; margin-right:5px;" href="Javascript:void(0);" @click="updateJobDocument(1)">
                    <span class="ti-check"></span>
                </a>
                <a style="float:left;" href="Javascript:void(0);" @click="updateJobDocument(0)">
                    <span class="ti-close"></span>
                </a>
            </div>
        </div>
        <div class="row item" v-for="(jobDocumentInfo, index) in settingsJobDocumentList" v-bind:key="index">
            <div class="col-md-6 col-sm-8 col-6">
                <div class="form-control">{{jobDocumentInfo.documentName}}</div>
            </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                <a style="float:left; margin-right:5px;" href="Javascript:void(0);" @click="editJobDocument(index)">
                    <span class="ti-pencil"></span>
                </a>
                <a style="float:left;margin-right:5px;" href="Javascript:void(0);" @click="removeJobDocument(index,jobDocumentInfo.id)" data-toggle="modal" data-target="#removJobDocument" title="Remove" >
                        <span class="ti-trash"></span>
                </a>
            </div>
        </div>
         <Dialog id="removJobDocument" :onYes="deleteJobDocument" :returnParams="delParam" />
    </div>
</template>
<style scoped>
div.item {
    margin-top: 5px;
}

</style>
<script>
import multiItemInput from "@/components/plugins/multiItemInput";
import iziToast from "izitoast";

export default {
    components: {
        multiItemInput
    },
    data() {
        return {
            formErrors: [],
            editIndex: -1,
            settingsJobDocumentList: [],
            jobDocumentInfo: {
                documentName: ""
            },
            newJobDocumentList: [],
            //editSourcingChannelList: [],
            //sourcingChannelIndex: -1,
            //selectedSourcingChannel: "",
            newJobDocument: { id: 0, documentName: "" },
            browsepagination: this.$store.state.paginationconfig,
            searchlimit: this.$store.state.searchlimit,
            delParam: {
                index: -1,
                id: 0
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    documentName: ""
                }
            }
        };
    },
    mounted() {
        this.loadJobDocuments();
    },
    methods: {
        loadJobDocuments() {
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/settingsresource/settingjobdocument/list")
                .then(response => {
                    let result = response.data;                    
                    this.settingsJobDocumentList = result.settingsJobDocument;
                  /*  this.browsepagination.totalrecords = result.pagination[0].total;
                    this.browsepagination.currentpage = result.pagination[0].currentPage;
                    this.browsepagination.totalpages = result.pagination[0].lastPage;*/
                    this.newJobDocument = { id: 0, documentName: "" };
                })
                .catch(error => {
                    iziToast.error({
                        title: "Error",
                        message: error,
                        position: "topRight"
                    });
                });
        },
        addJobDocument() {
            // let sourcingChannelList = this.newJobDocumentList.map(
            //     documentName => ({ documentName: documentName })
            // );            
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/settingsresource/add/settingjobdocument", this.newJobDocument
                )
                .then(response => {
                    this.jobDocumentInfo.documentName = "";                    
                    this.newJobDocument = "";
                    this.formErrors = [];
                    if(response.data.status == true) {
                        iziToast.success({
                            title: "Success",
                            message: response.data.message,
                            position: "topRight"
                        });
                    } else {
                        iziToast.info({
                            title: "Info",
                            message: response.data.message,
                            position: "topRight"
                        });
                    }
                    this.loadJobDocuments();
                })
                .catch(error => {
                    this.formErrors = error.response.data.validationErrorList;
                });
            //}
        },
        // openSourcingChannel(index) {
        //     this.editIndex = -1;
        //     this.newSourcingChannel = { id: 0, sourcingChannel: "" };
        //     this.sourcingChannelIndex = index;
        //     this.selectedSourcingChannel = this.sourcingChannellist[
        //         index
        //     ].sourcingChannel;
        // },
        editJobDocument(index) {
            this.editIndex = index;
            this.newJobDocument = {
                id: this.settingsJobDocumentList[index].id,
                documentName: this.settingsJobDocumentList[index].documentName,
                jobDocumentList : [ {documentName : 1} ] //because of validation not null
            };
            console.log(this.newJobDocument);
        },
        removeJobDocument:function(arg1, arg2)
            {
                this.delParam.index=arg1;
                this.delParam.id=arg2;
            },
        deleteJobDocument:function(arg)
            {
            axios.delete(this.$store.getters.getAPIBasePath + '/settingsresource/delete/settingsjobdocument/'+this.delParam.id)
                 .then(response => {
                    let result = response.data;
                    if (result.status == true) {
                        iziToast.success({
                            title: "Success",
                            message: this.$config.JobDocDelSuc,
                            position: "topRight",
                            timeout: 1000
                        });
                        this.settingsJobDocumentList.splice(arg.index, 1);
                    }else{
                        iziToast.error({
                            title: "Error",
                            message: this.$config.JobDocDelErr,
                            position: "topRight"
                        });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        updateJobDocument(mode) {
            console.log(this.newJobDocument);
            if (mode == 1) {
                axios
                    .post(
                        this.$store.getters.getAPIBasePath +
                        "/settingsresource/update/settingjobdocument",
                        this.newJobDocument
                    )
                    .then(response => {
                        // this.newSourcingChannel[
                        //   this.editIndex
                        // ].sourcingChannel = this.newSourcingChannel.sourcingChannel;
                        this.editIndex = -1;
                        this.newJobDocument = { id: 0, documentName: "" };
                        
                        if(response.data.status == true) {
                            iziToast.success({
                                title: "Success",
                                message: this.$config.JobDocUpSuc,
                                position: "topRight"
                            });
                        } else {
                            iziToast.info({
                                title: "Info",
                                message: response.data.message,
                                position: "topRight"
                            });
                        }
                        this.loadJobDocuments();
                    })
                    .catch(error => {
                        iziToast.error({
                            title: "Error",
                            message: error,
                            position: "topRight"
                        });
                    });
            } else {
                this.editIndex = -1;
                this.newJobDocument = { id: 0, documentName: "" };
            }
        }
    }
};

</script>
