<template>
    <div id="content_Personal_Info">
        <div class="card">
            <div class="card-header p-2">Contact Information </div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row col-md-12 my-4">
                        <div class="col-md-6">
                            <div class="table-responsive">
                                <table class="table table-striped table-borderless s_table compare-table">
                                    <tbody>
                                        <tr>
                                            <td width="50%"><i class="ti-mobile pr-2" aria-hidden="true"></i>{{ContactInfo.mobileNo}}</td>
                                        </tr>
                                        <tr>
                                            
                                            <td width="50%"><i class="fa fa-phone pr-2" aria-hidden="true"></i>{{ContactInfo.altMobileNo}}</td>
                                        </tr>
                                        <tr>
                                            <td><i class="ti-location-pin pr-2"></i>{{ContactInfo.addressLine1}} {{ContactInfo.addressLine2}} {{ContactInfo.cityName}} {{ContactInfo.stateName}} {{getCountryName(ContactInfo.countryId)}} - {{ContactInfo.pincode}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-header p-2">Social Connect </div>
            <div class="card-body p-2">
                <div class="basic-block">                    
                    <div class="row col-md-12 my-4">
                        <div class="col-md-4 col-sm-6">
                            <div class="row">
                                <div class="col-md-12">
                                   <label class="font-weight-bold"><i class="ti-skype pr-2"></i>{{ContactInfo.skypeId}} </label> 
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="row">
                                <div class="col-md-12">
                                   <label class="font-weight-bold"><i class="ti-facebook pr-2"></i>{{ContactInfo.facebookUrl}}</label> 
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="row">
                                <div class="col-md-12">
                                   <label class="font-weight-bold"><i class="ti-twitter pr-2"></i>{{ContactInfo.twitterUrl}}</label> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row col-md-12 my-4">
                        <div class="col-md-4 col-sm-6">
                            <div class="row">
                                <div class="col-md-12">
                                   <label class="font-weight-bold"><i class="ti-linkedin pr-2"></i>{{ContactInfo.linkedinUrl}}</label> 
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="row">
                                <div class="col-md-12">
                                   <label class="font-weight-bold"><i class="ti-google pr-2"></i>{{ContactInfo.hangoutId}}</label> 
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="row">
                                <div class="col-md-12">
                                   <label class="font-weight-bold"><i class="ti-world pr-2"></i>{{ContactInfo.websiteUrl}}</label> 
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        ContactInfo:{}
    },
    data() {
        return {  
            Countries:this.$store.getters.getCountries,
            USStates:this.$store.getters.getUSStates
        }
    },
    mounted(){

    },
    methods:
    {   
        getCountryName:function(countryCode){
            if(!countryCode)
                return "";
            let country = this.Countries.filter(obj => {
                return obj.country_Id === countryCode;
            });
            return country[0].name;
        },
        getStateName:function(stateId){
            if(!stateId)
                return "";
            let state = this.USStates.filter(obj => {
                return obj.id === stateId;
            });
            return state[0].stateName;
        }
    }
}
</script>