import ListLead from '@/components/vendor/ListVendor';
import AddLead from '@/components/vendor/AddVendor';
import EditLead from '@/components/vendor/EditVendor';
import viewVendor from '@/components/vendor/ViewVendor';

export default [{
        path: '/lead/add',
        name: 'addlead',
        component: AddLead,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/lead/list',
        name: 'listlead',
        component: ListLead,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/lead/edit/:vendorid',
        name: 'editlead',
        component: EditLead,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/lead/view/:vendorid',
        name: 'viewlead',
        component: viewVendor,
        meta: { accessedby: 'recruiter' }
    }
];