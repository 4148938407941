<template>
    <div id="main" class="mt-70 d-height">
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :class="isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <form id="main-search" :class="isExpand?'d-block':'d-none'">
                        <div class="row form-group ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Code/Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input class="form-control" v-on:keyup.enter="findCandidates" type="text" v-model="search.params.searchString" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Position</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input class="form-control" v-on:keyup.enter="findCandidates" type="text" v-model="search.params.position" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Location</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input class="form-control" v-on:keyup.enter="findCandidates" type="text" v-model="search.params.location" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Technology</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select class="form-control" v-model="search.params.technologyId">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in technologies" :value="info.id">{{info.technology}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Recruiter</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select class="form-control" v-model="search.params.recruiterId">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in recruiterlist" :value="info.id">{{info.recruiterName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;">Technology</label>
                                    </div>
                                    <div class="col-md-12">
                                        <button type="button" @click="resetCandidatesSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                        <button v-if="false" type="button" class="btn btn-primary btn-sm float-right advancesearch_btn shadow" style="margin-left: 10px;">Advanced Search</button>
                                        <button type="button" @click="findCandidates" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Find</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                        </div>
                    </form>
                    <div class="load-indicator"></div>
                </div>
            </div>
            <div class="container-fluid" id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-6 col-sm-12 col-12">
                            <h4 class="page-title">Candidates
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{browsepagination.totalRecords}} - Total)</span>
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                    <div class="dropdown">
                                        <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                        <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                            <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                        </div>
                                    </div>
                                </span>
                            </h4>
                        </div>
                        <div v-if="false" class="col-md-6 d-none d-sm-none d-md-block">
                            <button type="button" @click="loadCandidates" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">
                                <router-link :to="{ name: 'referraladdcandidate' }" style="color: #fff;">New Candidate</router-link>
                            </button>
                            <button type="button" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">
                                <router-link :to="{ name: 'referralbulkupload' }" style="color: #fff;">Import Candidates</router-link>
                            </button>
                            <button v-if="jobApplyData.jobId != 0" type="button" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;" @click="referralToRecruiter">Apply</button>
                            <span class="float-right" @click="exportCandidates()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i></span>
                        </div>
                        <div v-if="false" class="col-md-6 d-none d-sm-none d-md-block">
                            <ul class="ul-horizontal table-fields text-right">
                                <li>
                                    <span>Show/Hide</span>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="recruiter" id="recruiter" checked="">
                                    <label for="recruiter" v-on:click="coldisplay.recruiter = !coldisplay.recruiter"><span></span> Experience</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="specialityunit" id="specialityunit" checked="">
                                    <label for="specialityunit" v-on:click="coldisplay.technology = !coldisplay.technology"><span></span>Technology/Skills</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="currentcity" id="currentcity" checked="">
                                    <label for="currentcity" v-on:click="coldisplay.currentcity = !coldisplay.currentcity"><span></span> Current City</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="state" id="state" checked="">
                                    <label for="state" v-on:click="coldisplay.state = !coldisplay.state"><span></span> State</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row" id="main-data-content" style="margin-bottom: 10px;display:none">
                        <div class="col-md-5 d-none d-sm-none d-md-block">
                            <ul class="ul-horizontal filter-list icon-ul">
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Sample Tool tip"><img alt="images" src="@/assets/icons/sigma.png"></a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Create Mail"><img alt="images" src="@/assets/icons/folder.png"></a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Create SMS"><img alt="images" src="@/assets/icons/answer-email.png"></a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Applicants Resume"><img alt="images" src="@/assets/icons/envelope-with-pencil.png"></a></li>
                            </ul>
                        </div>
                        <div class="col-md-7 d-none d-sm-none d-md-block ">
                            <ul class="ul-horizontal filter-list float-right filter-right" style="margin-bottom: 10px;">
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="New Lead"><img alt="images" src="@/assets/icons/minus.png"> New Lead</a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Resume"><img alt="images" src="@/assets/icons/resume.png"> Resume</a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Do Not Lodge"><img alt="images" src="@/assets/icons/user2.png"> DNL</a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Need Management Approval"><img alt="images" src="@/assets/icons/user3.png"> NMA</a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Do Not Return"><img alt="images" src="@/assets/icons/user4.png"> DNR</a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Do Not Use"><img alt="images" src="@/assets/icons/user5.png"> DNU</a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Need HR Approval"><img alt="images" src="@/assets/icons/user6.png"> NHA</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th v-if="false" class="" scope="col">#</th>
                                            <th v-if="false">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" @click="checkAll" type="checkbox" id="rowchckboxall" value="option1">
                                                    <label class="form-check-label" for="rowchckboxall"></label>
                                                </div>
                                            </th>
                                            <th class="" scope="col">Cand Code</th>
                                            <th class="" scope="col">Name</th>
                                            <th class="" scope="col">Position</th>
                                            <th v-if="coldisplay.recruiter" class="recruiter" scope="col">Experience</th>
                                            <th v-if="coldisplay.technology" class="specialityunit" scope="col">Technology</th>
                                            <th class="specialityunit" scope="col">Skills</th>
                                            <th v-if="coldisplay.currentcity" class="currentcity" scope="col">Location</th>
                                            <th class="" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="candidateslist.length==0 && elements.loadCandidates == true">
                                            <td colspan="14" align="center">No Candidates found</td>
                                        </tr>
                                        <tr v-for="(candidateinfo, index) in candidateslist">
                                            <td v-if="false">
                                                <a href="Javascript:void(0);" class="expand" data-targetopen="panel1"><span class="ti-arrow-circle-down"></span></a>
                                            </td>
                                            <td v-if="false">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox" :id="'rowchckbox'+candidateinfo.id" v-model="jobApplyData.candidateId" :value="candidateinfo.id" checked="" @click="getEmail(candidateinfo.email)">
                                                    <label class="form-check-label" :for="'rowchckbox'+candidateinfo.id"></label>
                                                </div>
                                            </td>
                                            <td><router-link title="View Candidate Profile" :to="{ name: 'guestviewcandidate', params: { candidateid: candidateinfo.id }}">{{ candidateinfo.candidateCode }}</router-link></td>
                                            <td>
                                                <router-link title="View Candidate Profile" :to="{ name: 'guestviewcandidate', params: { candidateid: candidateinfo.id }}">{{getFullName(candidateinfo.firstName,candidateinfo.lastName)}}</router-link>
                                            </td>
                                            <td>{{ candidateinfo.position }}</td>
                                            <td v-if="coldisplay.recruiter" class="recruiter">{{ candidateinfo.experience ? candidateinfo.experience :'' }}<span v-if="candidateinfo.experience != null"> yrs</span></td>
                                            <td v-if="coldisplay.technology" :title="candidateinfo.technologies" v-html="gettechsubstring(candidateinfo.technologies.join(','))" class="specialityunit"></td>
                                            <td :title="candidateinfo.skills" v-html="gettechsubstring(candidateinfo.skills.join(','))"></td>
                                            <td v-if="coldisplay.currentcity" class="currentcity">{{ candidateinfo.location }}</td>
                                            <td>
                                                <ul class="navbar-nav">
                                                    <li class="nav-item dropdown">
                                                        <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                            <li>
                                                                <router-link class="dropdown-item" :to="{ name: 'guestviewcandidate', params: { candidateid: candidateinfo.id }}"><span class="ti-eye pr-2"></span> View</router-link>
                                                            </li>
                                                            <li v-if="false">
                                                                <a href="javascript:" @click="scheduleInterview(candidateinfo.id)" class="dropdown-item"><span class="ti-calendar pr-2"></span> Schedule Interview</a>
                                                            </li>
                                                            <li v-if="false">
                                                                <a class="dropdown-item" href="javascript:" v-b-modal.notes-form-modal @click="addCallback(candidateinfo.id)"><i class="fa fa-phone pr-2" aria-hidden="true"></i> Callback</a>
                                                            </li>
                                                            <li v-if="false">
                                                                <a class="dropdown-item" href="javascript:" v-b-modal.notes-form-modal @click="addNotes(candidateinfo.id)"><i class="fa fa-sticky-note pr-2" aria-hidden="true"></i> Notes</a>
                                                            </li>
                                                            <li v-if="false">
                                                                <a class="dropdown-item" href="javascript:" @click="deleteCandidate(candidateinfo.id)" data-toggle="modal" data-target="#removeCandidate"><span class="ti-close pr-2"></span> Deactivate</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <b-pagination v-if="candidateslist.length!=0" size="md" class='pull-right' :current-page="browsepagination.currentpage" :total-rows="browsepagination.totalRecords" v-model="browsepagination.currentpage" :per-page="browsepagination.rowsPerpage" @input="loadCandidates()"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div>
                    <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
                </div>                   
            </template> 
        </b-overlay>
        <Dialog id="removeCandidate" :onYes="delete_Candidate" :returnParams="delCandidateParam" title="Deactivate Confirmation" message="Are you sure to deactivate?" />
        <b-modal id="notes-form-modal" ref="notesModal" :title="form.title" @hidden="resetModal" @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit" class="row col-md-12">
                <b-form-group label="Communication Mode" label-for="communcation-mode" invalid-feedback="Communication Mode is required" :state="elements.submitted" v-if="form.communication_type=='callback'" class="col-md-6">
                    <b-form-select id="communcation-mode" v-model="form.communication_mode" required>
                        <option value="phone">Phone</option>
                        <option value="email">Email</option>
                    </b-form-select>
                </b-form-group>
                <b-form-group label="Date" label-for="date" invalid-feedback="Date is required" :state="elements.submitted" v-if="form.communication_type=='callback'" class="col-md-6">
                    <b-form-input id="date" type="date"></b-form-input>
                </b-form-group>
                <b-form-group label="Start Time" label-for="start-time" invalid-feedback="Start Time is required" :state="elements.submitted" v-if="form.communication_type=='callback'" class="col-md-6">
                    <b-form-input id="start-time" type="time"></b-form-input>
                </b-form-group>
                <b-form-group label="End Time" label-for="end-time" invalid-feedback="End Time is required" :state="elements.submitted" v-if="form.communication_type=='callback'" class="col-md-6">
                    <b-form-input id="end-time" type="time"></b-form-input>
                </b-form-group>
                <b-form-group label="Notes" label-for="notes" invalid-feedback="Notes is required" v-model=form.notes>
                    <editor mode="wysiwyg" :html="form.notes" :options="editorOptions" @click="addNotes()" />
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { mapState } from 'vuex';
//import 'tui-editor/dist/tui-editor.css';
//import 'tui-editor/dist/tui-editor-contents.css';
//import 'codemirror/lib/codemirror.css';
import { Editor } from '@toast-ui/vue-editor'
export default {
    components: {
        'editor': Editor
    },
    data() {
        return {
            showOverlay:false,
            jobApplyData: {
                jobId: "",
                candidateId: [],
                email: []
            },
            isExpand: false,
            candidateslist: [],
            // browsepagination: this.$store.state.paginationconfig,
            technologies: this.$store.getters.getTechOptions,
            //  recruiterlist: this.$store.getters.getRecruiters,
            recruiterlist: this.$store.getters.getRecruiterByCompany,
            searchlimit: this.$store.state.searchlimit,
            elements: {
                showadvancedsearch: false,
                loadingCandidates: false,
                submitted: null,
                toolbar: 'undo redo formatselect| bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
                initObj: {
                    menubar: false,
                    height: 300,
                    branding: false,
                }
            },
                browsepagination: {
                totalRecords: 0,
                rowsPerpage: 10,
                currentpage: 1
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    candidateCode:"",
                    priority:1,
                    technology:"",
                    skills:"",
                    position:"",
                    searchString: "",
                    email: "",
                    location: "",
                    technologyId: "",
                    recruiterId: "",
                    candidateContactAccess: localStorage.getItem('candidateContactAccess'),
                    pageNo:"",
                    limit:"",
                },
                recruiters: [],
                skillset: [],
                currentstate: '',
                minexp: '',
                maxexp: '',
                region: '',
                referal: '',
                country: '',
                jobtype: '',
                industry: ''
            },
            coldisplay: {
                recruiter: true,
                title: true,
                technology: true,
                currentcity: true,
                state: true
            },
            delCandidateParam: {
                index: -1,
                id: 0
            },
            form: {
                title: "",
                recruiter_id: this.$store.getters.currentUser.accountId,
                user_id: null,
                user_type: "candidate",
                communication_type: "",
                communication_mode: "",
                notes: ""
            },
            editorOptions: {
                minHeight: '200px',
                height: '200px',
                hideModeSwitch: true,
                toolbarItems: [
                    'heading',
                    'bold',
                    'italic',
                    'strike',
                    'divider',
                    'hr',
                    'quote',
                    'divider',
                    'ul',
                    'ol',
                    'indent',
                    'outdent',
                    'divider',
                    'table',
                    'link'
                ],
            },
            notes: "",
            isCheckAll: false,
               candContactAccess: localStorage.getItem('candidateContactAccess'),
        }
    },
    computed: mapState(['globalSearchValue']),
    watch: {
        globalSearchValue(newValue, oldValue) {
            this.search.params.firstName = newValue;
            this.loadCandidates();
        },
        'showOverlay': {
            handler: function(value) { 
                if(value == true){
                    $('#globalapploader').css('visibility','hidden');
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        // alert(this.$store.getters.getGlobalSearchValue);
        // this.search.params.firstName = "femila";
        this.loadCandidates();
        this.viewCandidate();
    },
    methods: {
         viewCandidate(){
            let candId = localStorage.getItem('loginCandid');
            let loginkey = localStorage.getItem('loginKey');
          if(candId !=0 && candId !='null' && loginkey!=null){
                this.$router.push({name: 'guestviewcandidate', params: { candidateid:candId}});
            }
            else{
                return;
            }
        },
        resetCandidatesSearch() {
            this.search.params = {
                candidateCode:"",
                technology:"",
                skills:"",
                email: "",
                location: "",
                technologyId: "",
                recruiterId: "",
                searchString:"",
                position:"",
            };
            this.loadCandidates();
            
        },
        addNotes() {
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/add/candidate/notes', this.form.notes)
                .then(response => {
                    let result = response.data;
                    this.form.notes = result.candidates;
                })
                .catch(error => {
                    console.log(error);
                });

        },
        async loadCandidates() {
            this.showOverlay=true;
            await this.getCandidates();
            this.showOverlay=false;
        },
        getCandidates() {
            this.showOverlay=true;
            this.search.params.pageNo = this.browsepagination.currentpage;
            this.search.params.limit = this.browsepagination.rowsPerpage;
            this.jobApplyData.jobId = localStorage.getItem('loginJobId');
            return axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/get/hotcandidate', this.search.params)
                .then(response => {
                    let result = response.data;
                    this.candidateslist = result.data;
                    this.browsepagination.totalRecords = result.totalRecords;
                    this.browsepagination.currentpage = result.currentPage;
                    this.browsepagination.totalpages = result.totalPages;
                    this.elements.loadCandidates = true;
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });
        },
         findCandidates() {
            this.browsepagination.currentpage = 1;
            this.loadCandidates();
        },
        // changeLimit(newlimit) {
        //     this.browsepagination.currentPage = 1;
        //     this.browsepagination.rowsPerPage = newlimit;
        //     this.loadCandidates();
        // },
        updateSearchRecruiters: function(value) {
            this.search.recruiters = value;
        },
        updateSearchSkillset: function(value) {
            this.search.skillset = value;
        },
        changeLimit(newlimit) {
            this.search.limit = newlimit;
             this.browsepagination.currentpage = 1;
             this.browsepagination.rowsPerpage = newlimit;
            // this.$store.commit('updateActiveSearchLimit', this.search.limit);
            this.loadCandidates();
        },
        gettechsubstring(technology) {
            if (technology != null && technology.length > 10) {
                return technology.substring(0, 15) + "...";
            } else
                return technology;

        },
        deleteCandidate(candidateId) {
            //this.delTechSkillParam.index=arg1;
            this.delCandidateParam.id = candidateId;
            return;
            if (confirm("Do you really want to deactivate the candidate?")) {
                var params = new URLSearchParams();
                params.append('id', candidateId)
                axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/delete/candidate', params)
                    .then(response => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.loadCandidates();
                        }
                    })
                    .catch(error => {
                        console.log(error.data);
                    });
            }
        },
        delete_Candidate() {
            var params = new URLSearchParams();
            var candidateId = this.delCandidateParam.id;
            params.append('id', candidateId)
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/delete/candidate', params)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadCandidates();
                    }
                })
                .catch(error => {
                    console.log(error.data);
                });
        },
        scheduleInterview(candidateId) {
            localStorage.setItem('recPlanId', null);
            localStorage.setItem('jobId', null);
            localStorage.setItem('recruitmentId', null);
            this.$router.push('/candidates/interview/' + candidateId);
        },
        addNotes(candidateId) {
            this.form.title = "Add Notes";
            this.form.user_id = candidateId;
            this.form.communication_type = "notes";
            // this.$refs.notesModal.show();
        },
        addCallback(candidateId) {
            this.form.title = "Callback Settings";
            this.form.user_id = candidateId;
            this.form.communication_type = "callback";
            // this.$refs.notesModal.show();
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.elements.submitted = true;
            this.handleSubmit();
        },
        handleSubmit() {
            if (this.elements.submitted) {
                if (!this.checkFormValidity()) {
                    console.log("error");
                    return false;
                }
                this.$nextTick(() => {
                    this.$refs.notesModal.hide();
                });
            } else {
                return false;
            }
        },
        resetModal() {
            this.form.user_id = null;
            this.form.communication_type = "";
            this.form.communication_mode = "";
            this.form.notes = "";
            this.elements.submitted = false;
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity();
            return valid;
        },
        exportCandidates() {
  this.search.params.candidateContactAccess = this.candContactAccess;
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/referral/candidates?page=' + this.browsepagination.currentpage + '&size=' + this.search.limit, this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "Candidates" + "_" + currentDateWithFormat;

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });

        },
        jobApply: function() {
            console.log("=====Job Apply=====");
            if (this.jobApplyData.candidateId.length != 0) {
                axios.post(this.$store.getters.getAPIBasePath + '/jobresource/add/job/applied', this.jobApplyData)
                    .then(response => {
                        iziToast.success({
                            title: 'Success',
                            message: response.data.message,
                            position: 'topRight'
                        });

                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                iziToast.info({
                    title: 'Info',
                    message: this.$config.ChoCandApply,
                    position: 'topRight'
                });
            }
            // localStorage.clear('loginJobId');
        },
        referralToRecruiter: function() {

            let candidateInfo = {
                recruiterId: localStorage.getItem('recruiterId'),
                ttClientAccountId: localStorage.getItem('clientAccountId'),
                companyId: localStorage.getItem('companyId'),
                candId: this.jobApplyData.candidateId,
                emailList: this.jobApplyData.email
            };
            console.log(candidateInfo);
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/copy/candidate/referral/to/recruiter', candidateInfo)
                .then(response => {
                    this.jobApply();
                })
                .catch(error => {
                    console.log(error);
                });

        },
        checkAll() {
            this.isCheckAll = !this.isCheckAll;
            this.jobApplyData.candidateId = [];
            let self = this;
            if (this.isCheckAll) {
                $.each(this.candidateslist, function(key, value) {
                    console.log(value);
                    self.jobApplyData.candidateId.push(value.id);
                    self.jobApplyData.email.push(value.email);
                });
                console.log(self.jobApplyData.candidateId);
            }
        },
        getEmail: function(email){
            this.jobApplyData.email.push(email);
            console.log(this.jobApplyData.email);
        }
    }
}

</script>
