<template>
    <div id="main" class="mt-70">
        <div id="quick-search" class="container-fluid mb-4 bg-white p-b-15">
            <a href="javascript:" @click="$router.go(-1)" title="Back" class="nav-link p-2">
                <i class="ti-arrow-left"></i> Back
            </a>
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row no-gutters">
                    <b-tabs v-model="currentTab" small card class="tab-list driver-tab-list tab-bg">
                        <b-tab v-if= false title="Interview Requests">
                            <InterviewerReq />
                        </b-tab>
                        <b-tab title="Interviews" @click="refershData">
                            <Interviews ref="Interviews" />
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';

import InterviewerReq from './blocks/request/InterviewerRequest';
import Interviews from './blocks/request/Interviews';

export default {
    components: {
        InterviewerReq,
        Interviews
    },
    data() {
        return {

            currentTab: 0,
        }
    },
    mounted: function() {

    },
    methods: {
        changeTab: function(tab) {
            this.currentTab = tab;
        },
        refershData: function() {
            //console.log(1);
            this.$refs.Interviews.loadRequests(0);
        }
    }
}

</script>
