<template>
    <div id="main" class="tech-skill-settings settings recruiter">
        <div class="row">
            <div class="col-12">
                <h5>
                    <span v-if="skillIndex<0">Technology/Specialization Settings</span>
                    <span v-if="skillIndex>=0">Skill Settings</span>
                    <router-link v-if="type==1 && skillIndex<0" :to="{ name: 'addinterviewer' }" class="pull-right btn-back text-blue"><i class="ti-arrow-left"></i> Back</router-link>
                    <a v-if="skillIndex<0 && type!=1" href="javascript:" class="pull-right btn-back text-blue" @click="$emit('setSubTab',0)"><i class="ti-arrow-left"></i> Back</a>
                    <a v-if="skillIndex>=0" href="javascript:" class="pull-right btn-back text-blue" @click="skillIndex=-1;"><i class="ti-arrow-left"></i> Back</a>
                </h5>
                <p></p>
                <h5 v-if="skillIndex>=0">Technology : {{techSel}}</h5>
            </div>
            <div class="col-md-3 col-sm-6 col-12 mb-10-mr pt-2 pb-3">
                <div class="row">
                <div class="col-md-12 col-sm-12 col-12 lb">
                    <label><h6>Industry</h6></label>
                    </div>
                    <div class="col-md-12 col-sm-12 col-12 ml-3">
                        <select v-model="industryId" @change="getIndustries" class="form-control">
                            <option value="">Select</option>
                            <option v-for="(data, index) in industrylist" :value="data.id">{{data.industryName}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="skillIndex<0">
            <div class="row" v-if="editIndex<0">
                <div class="col-md-6 col-sm-8 col-6">
                    <multiItemInput id="multiTech" placeholder="Enter new Technology" :items="addTechs"></multiItemInput>
                    <p v-if="formErrors.length">
                        <ul>
                            <li class="error" v-for="error in formErrors">{{ error.message }}</li>
                        </ul>
                    </p>                    
                </div>
                <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" v-model="newTech.isStandard" id="standard" value="standard">
                        <label class="form-check-label" for="standard">Standard</label>
                </div>
                <div class="col-md-2 col-sm-4 col-1 pt-2">
                    <a href="javascript:void(0);" @click="addNewTech" class="px-2"><span class="ti-plus"></span></a>
                </div>
            </div>
            <div class="row" v-if="editIndex!=-1">
                <div class="col-md-6 col-sm-8 col-6">
                    <input class="form-control" placeholder="Enter new Technology" v-model="newTech.technology" />
                </div>
                <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" v-model="newTech.isStandard" id="standard" value="standard">
                        <label class="form-check-label" for="standard">Standard</label>
                </div>
                <div class="col-md-2 col-sm-4 col-1 pt-2">
                    <a style="float:left;margin-right:5px;" href="javascript:" @click="updateTech(1)"><span class="ti-check"></span></a>
                    <a style="float:left;" href="javascript:" @click="updateTech(0)"><span class="ti-close"></span></a>
                </div>
            </div>
            <div class="row item" v-for="(tech, index) in technologies" v-bind:key="index">
                <div class="col-md-6 col-sm-8 col-6">
                    <div class="form-control">{{tech.technology}}</div>
                </div>
                <div class="col-md-2 col-sm-4 col-1 pt-2">
                    <a href="javascript:" @click="removeTechnology(index,tech.id)" data-toggle="modal" data-target="#removetechnology" title="Remove" class="px-2"><span class="ti-trash"></span></a>
                    <a href="javascript:" @click="editTech(index)" title="Edit" class="px-2"><span class="ti-pencil"></span></a>
                    <a href="javascript:" @click="openSkill(index)" title="Skills" class="px-2"><span class="ti-files"></span></a>
                </div>
            </div>
        </div>
        <div v-if="skillIndex>=0">
            <div class="row" v-if="editSkillIndex<0">
                <div class="col-md-11 col-sm-8 col-11">
                    <multiItemInput id="multiSkill" placeholder="Enter new Skills" :items="addSkills"></multiItemInput>
                </div>
                <div class="col-md-1 col-sm-4 col-1">
                    <a href="javascript:" @click="addNewSkills"><span class="ti-plus"></span></a>
                </div>
            </div>
            <div class="row" v-if="editSkillIndex!=-1">
                <div class="col-md-11 col-sm-8 col-11">
                    <input class="form-control" placeholder="Enter new Skill" v-model="skillName" />
                </div>
                <div class="col-md-1 col-sm-4 col-1">
                    <a style="float:left;margin-right:5px;" href="javascript:" @click="updateSkill(1)"><span class="ti-check"></span></a>
                    <a style="float:left;" href="javascript:" @click="updateSkill(0)"><span class="ti-close"></span></a>
                </div>
            </div>
            <div class="row ">
                <div class="col">
                    <div class="group-ctrl" v-for="(skill, index) in techSkills" v-bind:key="index">
                        <ul>
                            <li>{{skill.skillName}}</li>
                            <li class="icon"><a href="javascript:" @click="editSkill(index)"><span class="ti-pencil"></span></a></li>
                            <li class="icon"><a href="javascript:" @click="removeSkill(index)"><span class="ti-trash"></span></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="col-md-11 col-sm-8 col-11">
                    
                    <div class="form-control" >{{skill.skillName}}</div>
                </div>
                <div class="col-md-1 col-sm-4 col-1">
                    <a style="float:left;margin-right:5px;" href="javascript:" @click="removeSkill(index)" ><span class="ti-trash"></span></a>
                    <a style="float:left;" href="javascript:" @click="editSkill(index)"><span class="ti-pencil"></span></a>
                </div> -->
            </div>
        </div>
        <Dialog id="removetechnology" :onYes="deletetechnology" :returnParams="delParam" />
    </div>
</template>
<style scoped>
div.item {
    margin-top: 5px;
}

</style>
<script>
import multiItemInput from '@/components/plugins/multiItemInput';
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import iziToast from "izitoast";

export default {
    components: {
        multiItemInput
    },
    props: {
        type: String
    },
    data() {
        return {
            industrylist: this.$store.getters.getIndustries,
            formErrors: [],
            techSkills: [],
            skillName: '',
            addSkills: [],
            industryId: '1',
            addTechs: [],
            editSkillIndex: -1,
            techSel: '',
            skillIndex: -1,
            editIndex: -1,
            technologies: [],
            newTech: { id: 0, technology: '',isStandard: false },
            addTechs: [],
            isStandard: false,
            delParam: {
                index: -1,
                id: 0
            },
        }
    },
    mounted() {
        // this.loadTechnologies();
        this.getIndustries();
    },
    methods: {
        loadTechnologies() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/list/all/technology')
                .then(response => {
                    this.technologies = response.data.technologylist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getIndustries(){
            axios.get(this.$store.getters.getAPIBasePath + '/technologyresource/get/industry/'+this.industryId)
            .then(response => {
                    this.technologies = response.data.technology;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addNewTech() {

            let technologies = this.addTechs.map(tech => ({ technology: tech,isStandard: this.isStandard,industryId: this.industryId}));
            axios.post(this.$store.getters.getAPIBasePath + '/technologyresource/add/clienttechnology', { technologyList: technologies})
                .then(response => {

                    this.newTech.technology = "";
                    this.addTechs = [];
                    this.formErrors = [];
                    this.isStandard = false;
                    iziToast.success({
                        title: "Success",
                        message: response.data.message,
                        position: "topRight"
                    });
                    this.getIndustries();
                })
                .catch(error => {
                    this.formErrors = error.response.data.validationErrorList;
                });
        },
        updateTech(mode) {
            if (mode == 1) {
                axios.post(this.$store.getters.getAPIBasePath + '/technologyresource/update/technology', this.newTech)
                    .then(response => {
                        this.technologies[this.editIndex].technology = this.newTech.technology;
                        this.editIndex = -1;
                        this.newTech = { id: 0, technology: '',isStandard: false };
                        this.isStandard = false;
                        iziToast.success({
                            title: "Success",
                            message: this.$config.TechUpSuc,
                            position: "topRight"
                        });
                        this.getIndustries();
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            } else {
                this.editIndex = -1;
                this.newTech = { id: 0, technology: '', isStandard: false };
            }

        },
        removeTechnology:function(arg1,arg2){
                this.delParam.index=arg1;
                this.delParam.id=arg2;
        },
         deletetechnology:function(){

            axios.post(this.$store.getters.getAPIBasePath + '/technologyresource/delete/technology', {
                    id: this.delParam.id,
                    technologyList: [{ technology: 1 }] //because of validation not null
                })
                .then(response => {
                    let result = response.data;
                    if (result.status == 'ACCEPTED') {
                        iziToast.success({
                            title: "Success",
                            message: this.$config.TechDelSuc,
                            position: "topRight",
                            timeout: 1000
                        });
                        this.getIndustries();
                    }else if(result.status == 'OK'){
                        iziToast.info({
                            title: "Info",
                            message: this.$config.RefTechDelErr,
                            position: "topRight"
                        });
                    }else{
                        iziToast.error({
                            title: "Error",
                            message: this.$config.TechDelErr,
                            position: "topRight"
                        });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });

        },
        editTech(index) {
            this.editIndex = index;
            this.newTech = {
                id: this.technologies[index].id,
                technology: this.technologies[index].technology,
                technologyList: [{ technology: 1 }],//because of validation not null
                isStandard: this.technologies[index].isStandard 
            };
        },
        openSkill(index) {
            this.editIndex = -1;
            this.newTech = { id: 0, technology: '' };
            this.skillIndex = index;
            this.techSel = this.technologies[index].technology;

            this.techSkills = this.technologies[index].settingsTechnologySkill.map(skills => ({ skillId: skills.skillId, skillName: skills.skillName }));

        },
        addNewSkills() {
            let skillItems = this.addSkills.map(skill => ({
                categoryId: null,
                technologyId: this.technologies[this.skillIndex].id,
                skillName: skill,
                isDeleted: 0,
                id: null
            }));
            axios.post(this.$store.getters.getAPIBasePath + '/technologyresource/add/skill', { technologySkill: skillItems })
                .then(response => {
                    this.showTech();
                    iziToast.success({
                        title: "Success",
                        message: this.$config.SkillCreSuc,
                        position: "topRight"
                    });
                    this.getIndustries();
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        updateSkill(mode) {
            if (mode == 1) {
                let newSkill = { id: this.techSkills[this.editSkillIndex].skillId, skillName: this.skillName };
                axios.post(this.$store.getters.getAPIBasePath + '/technologyresource/update/skill', newSkill)
                    .then(response => {
                        this.technologies[this.skillIndex].settingsTechnologySkill[this.editSkillIndex].skillName = this.skillName;
                        this.techSkills[this.editSkillIndex].skillName = this.skillName;
                        this.editSkillIndex = -1;
                        this.skillName = '';
                        iziToast.success({
                            title: "Success",
                            message: this.$config.SkillUpSuc,
                            position: "topRight"
                        });
                    })
                    .catch(function(error) {
                        console.log(error);
                    });

            } else {
                this.editSkillIndex = -1;
                this.skillName = '';
            }

        },
        showTech() {
            this.techSkills = [];
            this.skillName = '';
            this.addSkills = [];
            this.editSkillIndex = -1;
            this.techSel = '';
            this.skillIndex = -1;
        },
        removeSkill(index) {
            let id = this.techSkills[index].skillId;

            axios.post(this.$store.getters.getAPIBasePath + '/technologyresource/delete/skill/' + id)
                .then(response => {

                    this.techSkills.splice(index, 1);
                    this.technologies[this.skillIndex].settingsTechnologySkill.splice(index, 1);
                    this.editSkillIndex = -1;
                    this.skillName = '';

                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        editSkill(index) {
            this.editSkillIndex = index;
            this.skillName = this.techSkills[index].skillName;
        }
    }
}

</script>
