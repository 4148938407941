<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div class="card border-5 mb-3">
                                    <div class="col-md-12 col-sm-12 px-0 about-box">
                                        <div class="col-md-8 px-0 float-left">
                                            <h6 class="m-0 text-left p-2">
                                                <a href="javascript:" @click="getPrevUrl()" title="Back" class="p-2">
                                                    <i class="fa fa-arrow-left"></i>
                                                    <span> Back</span>
                                                </a>
                                            </h6>
                                        </div>
 
                                         <div class="col-md-4 px-0 pull-right py-2 text-right pr-2">
                                            <router-link class="link" title="Edit Client" :to="{ name: 'editclient'}"><i class="fa fa-pencil-square-o fa_custom fa-lg px-1"
                                                @click="addLogActivity('Edit Client')"></i></router-link>
                                        </div>
                                    </div>
                                    <div class="user-card pl-5 pr-5 pb-2 pt-3">
                                        <div class="information">
                                            <div class="imgUp">
                                                <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + clientinfo.imagePath + ')' }"></div>

                                                <!-- <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' +clientinfo.image + '?' + profilePhotoArg + ')' }"></div> -->

                                                <!-- <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' +  (clientinfo.image===''?clientinfo.imagePath:clientinfo.image) + ')' }"></div> -->
                                            </div>
                                            <div class="name">{{clientinfo.clientName}}</div>
                                            <div class="position"><i class="ti-email"></i> {{clientinfo.accountName}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card border-5">
                                    <div class="user-card p-0">
                                        <h5 class="about-box m-0 text-left p-2">Company Info</h5>
                                        <div class="p-3 float-left w-100">
                                            <div class="informations">
                                                <span v-if="clientAddress.length == 0" class="badge p-2 mb-1">No records found</span>
                                                <span v-if="clientAddress.length != 0 && addressinfo.physicaladdress.phoneNumber != ''"><i class="fa fa-phone pr-2" aria-hidden="true"></i>{{addressinfo.physicaladdress.phoneNumber}}</span>
                                                <span v-if="addressinfo.physicaladdress.phoneNumber != ''"><hr></span>
                                                <span v-if="clientAddress.length != 0 && addressinfo.physicaladdress != null"><i class="ti-location-pin pr-2"></i>
                                                    <span v-if="addressinfo.physicaladdress.addressLine1 != ''">{{addressinfo.physicaladdress.addressLine1}} </span>
                                                    <span v-if="addressinfo.physicaladdress.addressLine2 != ''">,{{addressinfo.physicaladdress.addressLine2}}</span>
                                                    <span v-if="addressinfo.physicaladdress.city != ''"> , {{addressinfo.physicaladdress.city}}</span>
                                                    <span v-if="addressinfo.physicaladdress.stateName != ''">,{{addressinfo.physicaladdress.stateName}}</span> <br>
                                                    <span v-if="addressinfo.physicaladdress.countryId != ''">{{getCountryName(addressinfo.physicaladdress.countryId)}}</span>
                                                    <span v-if="addressinfo.physicaladdress.zipCode != ''"> - {{addressinfo.physicaladdress.zipCode}}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="user-card p-0">
                                        <h5 class="about-box m-0 text-left p-2">About Me</h5>
                                        <div class="p-3 float-left w-100">
                                            <h5>Website</h5>
                                            <div class="informations">
                                                <span v-if="clientinfo.url == ''" class="badge p-2 mb-1">No records found</span>
                                                <span v-if="clientinfo.url != ''">{{clientinfo.url}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-9">
                        <div class="row no-gutters">
                            <b-tabs v-model="currentClientTab" small card class="tab-list driver-tab-list">
                                <b-tab title="Client Details">
                                    <ClientInfo :clientinfo="clientinfo" ref="clientinfocomponent"></ClientInfo>
                                </b-tab>
                                <b-tab title="Physical Address">
                                    <AddressInfo :addressinfo="addressinfo" ref="addressinfocomponent"></AddressInfo>
                                </b-tab>
                                <b-tab title="Client Contacts">
                                    <ClientContacts :clientcontactinfo="clientcontactinfo" ref="clientcontactinfocomponent" @refresh-client="loadClientById(clientid)"></ClientContacts>
                                </b-tab>
                                <b-tab v-if="false" title="Contract Details">
                                    <ContractDetails :clientcontractinfo="clientcontractinfo" ref="clientcontractinfocomponent" @refresh-client="loadClientById(clientid)"></ContractDetails>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ClientInfo from './blocks/viewclient/ClientInfo';
import AddressInfo from './blocks/viewclient/AddressInfo';
import ClientContacts from './blocks/viewclient/ClientContacts';
import ContractDetails from './blocks/viewclient/ContractDetails';
export default {
    computed: {
        clientid() {
            return this.$route.params.clientid;
        }
    },
    components: {
        ClientInfo,
        AddressInfo,
        ClientContacts,
        ContractDetails
    },
    data() {
        return {
            currentClientTab: 0,
            maxtabs: 3,
            clientinfo: {
                clientCode: "",
                dunsNumber: "",
                clientName: "",
                accountName: "",
                clientGroup: "",
                accountManager: "",
                url: "",
                backupaccountManager: "",
                jobUrl: "",
                accountExecutive: "",
                status: "",
                description: "",
                imagePath: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
            },
            addressinfo: {
                physicaladdress: {
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "US",
                    faxNumber: "",
                    phoneNumber: "",
                    sameAsAbove: ""
                },
                billingaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "US",
                    faxNumber: "",
                    sameAsAbove: ""
                }
            },
            clientcontactinfo: [],
            clientcontractinfo: [],
            clientAddress: [],
            countrieslist: this.$store.getters.getCountries,
            usstates: this.$store.getters.getUSStates,
        }
    },
    mounted() {
        this.loadClientById(this.clientid);
    },
    methods: {
        getPrevUrl() {
            this.$router.go(-1);
        },
        getCountryName: function(countryCode) {
            if (!countryCode)
                return "";
            let country = this.countrieslist.filter(obj => {
                return obj.country_Id === countryCode;
            });
            return country[0].name;
        },
        getStateName: function(stateId) {
            if (!stateId)
                return "";
            let state = this.usstates.filter(obj => {
                return obj.id === stateId;
            });
            return state[0].stateName;
        },
        loadClientById(clientId) {
            axios.get(this.$store.getters.getAPIBasePath + '/clientresource/view/client/' + clientId)
                .then(response => {
                    let result = response.data;
                    if (!result.status) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    let self = this;
                    this.clientinfo = result.clients;
                    console.log(this.clientinfo)
                    this.clientinfo = {            
                clientName: result.clients.clientName,
                clientCode: result.clients.clientCode,
                dunsNumber: result.clients.dunsNumber,
                statusText:result.clients.statusText,
                description:result.clients.description,
                accountName: result.clients.accountName,
                url:result.clients.url,
                imagePath: (result.clients.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : result.clients.imagePath),
                imageName: result.clients.imageName,

                    };
                    console.log("clientinfoview",this.clientinfo);
                    this.clientcontactinfo = result.clients.clientContact;
                    this.clientcontractinfo = result.clients.clientContract;
                    this.clientAddress = result.clients.clientAddress;
                    result.clients.clientAddress.forEach(function(data) {
                        if (data.addressType.toLowerCase() == "physical") {
                            self.addressinfo.physicaladdress = data;
                        } else {
                            self.addressinfo.billingaddress = data;
                        }

                    });
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliInfoLoadErr,
                        position: 'topRight'
                    });
                });
        },
        addLogActivity: function(action) {
            this.updateLogActivity();
            this.logActivityData = {
                entityName: 'Client',
                actionName: action,
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        }
    }

}

</script>
