<template>
 <b-modal id="EditplanPer" ref="EditplanRef" centered @ok="closeModal()" modal-class="" title="" ok-only ok-title='OK'>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
            <div class="card">
            <div class="card-header p-2">Edit Plan Permission</div>
            <div class="card-body p-2">
                <div class="basic-block col-md-12">
                    <div class="table-responsive">
                        <table class="table table-striped table-borderless s_table compare-table">
                         <thead>
                                    <tr>
                                        <th>Permission</th>
                                        <th>Permission Count</th>
                                    </tr>
                                </thead>
                            <tbody>
                                 <tr>
                                <td class="pl-0"><input type="text" v-model="newpermission.permission" class="form-control text_per"></td>
                                <td class="pr-0"><input type="text" v-model="newpermission.permissionCount" v-on:keyup.enter="saveEditdata" class="form-control text_per"></td>
                                <td v-if="edit_mode" class="pt-3"><i class="ti-check check_plan pr-2" @click="saveEditdata"></i><i class="ti-close close_plan" @click="cancel"></i></td>
                                </tr>
                                <tr v-for="(Permissioninfo,index) in Permissioninfolist">
                                    <td>{{Permissioninfo.permission}}</td>
                                    <td>{{Permissioninfo.permissionCount}}</td>
                                    <td class="pt-3"><i class="ti-pencil edit_plan" @click="editperm(index)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
            </div>
        </b-modal>
</template>
<script>
import iziToast from "izitoast";
export default {
    name: 'Planper',
    props: {  
        seteditperdata: Array
    },
    data() {
        return {
            showOverlay:false,
            edit_mode: false,
            loading: false,
            newpermission:{
                id:0,
                planId:0,
                permission:"",
                permissionCount:"",
            },
            isDisabled:true,
            elements: {
                loadingUsers: false,
            },
            Permissioninfolist:[],
        }
    },
    mounted(){
        this.seteditperData(this.seteditperdata);
    },
    methods: {
      closeModal(bvModalEvt) {
           this.$emit('refresh-per');
            this.newpermission = {
                id: '',
                planId:'',
                permission:'',
                permissionCount: ''
            };
            this.$bvModal.hide(EditplanPer);
            this.edit_mode=false;
        },
        editperm:function(index){
            this.edit_mode=true;
            this.newpermission = {
                id: this.Permissioninfolist[index].id,
                planId: this.Permissioninfolist[index].planId,
                permission: this.Permissioninfolist[index].permission,
                permissionCount: this.Permissioninfolist[index].permissionCount,
            };
        },
        saveEditdata(){
            if((this.newpermission.id && this.newpermission.planId!=0) && (this.newpermission.permission && this.newpermission.permissionCount)!=""){
            axios.post(this.$store.getters.getAPIBasePath + '/serviceplan/serviceplanpermission/edit/'+this.newpermission.id,this.newpermission)
             .then(response => {
                    let result = response.data;
                    if (result.status=="ACCEPTED"){
                        iziToast.success({title: '',message: result.message,position: 'topRight'});
                      this.$emit('refresh-per');
                      this.$refs.EditplanRef.hide(); 
                      this.edit_mode=false;
                    }
                    else{
                         iziToast.info({title: '',message: result.message,position: 'topRight'});
                    }
                    this.newpermission = {id: 0,planId:0,permission:'',permissionCount: ''};
                })
                .catch(e => {
                    this.showOverlay=false;
                });
                }
             else{
                iziToast.info({title: '',message: "fields should not be empty",position: 'topRight'});
             }
        },
        cancel(){
            this.newpermission = {
                id: '',
                planId:'',
                permission:'',
                permissionCount: ''
            };
            this.edit_mode=false;
        },
         seteditperData:function(planinfo){
            this.Permissioninfolist = planinfo;
    }
}
}
</script>