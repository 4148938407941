<template>
    <div id="main" class="tech-skill-settings settings recruiter">
        <div class="row col-md-12 px-0 py-4">
            <div class="col-md-4 offset-md-4">
                <h4 class="">Referral Reward Settings</h4>
            </div>
            <div class="col-md-1 offset-md-3">
                <a href="Javascript:void(0);" class="pull-right btn-back" @click="$emit('setSubTab', 0)">
                    <h5 class="text-blue"><span title="Go Back" class="ti-arrow-left py-4"></span><span
                            class="pl-2">Back</span></h5>
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-sm-8 col-6">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-12 lb">
                        <label>
                            <h6>Reward Types</h6>
                        </label>
                    </div>                    
                    <div class="col-md-12 col-sm-12 col-12 ">
                        <div class="input-group">
                            <select class="form-control" v-model="newRewardTypeSettings.referralRewardTypeId" placeholder="Select">
                                <option value="Select">Select</option>
                                <option v-for="(data, index) in rewardTypes" :value="data.id" v-bind:key="index">
                                    {{ data.name }}</option>
                            </select>  
                        </div>
                        <label
                            v-if="$v.newRewardTypeSettings.referralRewardTypeId.$error && !$v.newRewardTypeSettings.referralRewardTypeId.required"
                            class="text-danger">Please choose Reward Type</label>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-8 col-6 ">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-12 lb">
                        <label>
                            <h6>Points</h6>
                        </label>
                        <input class="form-control" v-model="newRewardTypeSettings.points"
                            :class="{ 'is-invalid': $v.newRewardTypeSettings.points.$error }" placeholder="" />
                    </div>
                    <label v-if="$v.newRewardTypeSettings.points.$error && !$v.newRewardTypeSettings.points.required"
                        class="text-danger pl-4">Please enter Points</label>

                </div>
            </div>
            <div class="col-md-3 col-sm-8 col-6 ">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-12 lb">
                        <label>
                            <h6>Value</h6>
                        </label>
                        <input class="form-control" placeholder="" v-model="newRewardTypeSettings.value"
                            :class="{ 'is-invalid': $v.newRewardTypeSettings.value.$error }" />
                    </div>
                    <label v-if="$v.newRewardTypeSettings.value.$error && !$v.newRewardTypeSettings.value.required"
                        class="text-danger pl-4">Please enter Value</label>

                </div>
            </div>
            <div v-if="editIndex < 0" class="col-md-3 col-sm-6 col-6 pt-5 pr-5">
                <button type="button" class="btn btn-primary btn-sm pull-right"><a href="javascript:" class="text-white"
                        @click="addRewardSettings()">Add</a></button>
            </div>
            <div v-if="editIndex != -1" class="col-md-3 col-sm-6 col-6 pt-5 pr-5">
                <a style="float:left; margin-right:5px;" href="Javascript:void(0);" @click="updaterewardType(1)">
                    <span class="ti-check" title="Save"></span>
                </a>
                <a style="float:left;" href="Javascript:void(0);" @click="updaterewardType(0)">
                    <span class="ti-close" title="Cancel"></span>
                </a>
            </div>
        </div>

        <div class="col-md-12 table-responsive py-3">
            <table class="table table-borderless all-table text-left">
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Reward Types</th>
                        <th>Points</th>
                        <th>Value</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="rewardTypeSettings.length == 0">
                        <td align="center" colspan="5">No records found</td>
                    </tr>
                    <tr v-if="rewardTypeSettings.length" v-for="(reward, index) in rewardTypeSettings">
                        <td>{{ index + 1 }}</td>
                        <td>{{ reward.referralRewardType.name }}</td>
                        <td>{{ reward.points }}</td>
                        <td>{{ reward.value }}</td>
                        <td>
                            <ul class="navbar-nav">
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);"
                                        id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">Actions</a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li>
                                            <a href="javascript:" @click="editrewardType(index)" class="dropdown-item"><span
                                                    class="ti-pencil pr-2"></span> Edit</a>
                                        </li>
                                        <li>
                                            <a href="javascript:" data-toggle="modal" data-target="#removRewardSettings"
                                                @click="removeRewardType(index, reward.id)" class="dropdown-item"><span
                                                    class="ti-trash pr-2"></span> Delete</a>
                                        </li>

                                    </ul>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <Dialog id="removRewardSettings" :onYes="deleteRewardType" :returnParams="delParam" />
    </div>
</template>
<style scoped>
div.item {
    margin-top: 5px;
}
</style>
<script>
import { required } from "vuelidate/lib/validators";
import multiItemInput from "@/components/plugins/multiItemInput";
import iziToast from "izitoast";

export default {
    components: {
        multiItemInput
    },
    data() {
        return {
            formErrors: "",
            editIndex: -1,
            rewardTypes: [],
            rewardTypeSettings: [],
            rewardTypeInfo: {
                rewardType: ""
            },
            newRewardTypeSettings: {
                referralRewardTypeId: "Select",
                points: "",
                value: ""
            },
            browsepagination: this.$store.state.paginationconfig,
            searchlimit: this.$store.state.searchlimit,
            delParam: {
                index: -1,
                id: 0
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    rewardType: ""
                }
            }
        };
    },
    mounted() {
        this.loadRewardTypesList();
        this.loadRewardSettingsList();
    },
    watch: {
        dialog() {
            this.$refs.form.reset()
        }
    },
    validations: {
        newRewardTypeSettings: {

            referralRewardTypeId: { required },
            points: { required },
            value: { required }
        }
    },
    methods: {
        loadRewardTypesList() {
            axios
                .get(this.$store.getters.getAPIBasePath + "/settingsresource/list/referral/reward/types")
                .then(response => {

                    let result = response.data;
                    this.rewardTypes = result.data;
                    this.newRewardTypeSettings = { id: 0, rewardType: "" };
                })
                .catch(error => {
                    iziToast.error({
                        title: "Error",
                        message: error,
                        position: "topRight"
                    });
                });
        },
        loadRewardSettingsList() {
            axios
                .get(this.$store.getters.getAPIBasePath + "/settingsresource/list/referral/reward/settings")
                .then(response => {
                    let result = response.data;
                    this.rewardTypeSettings = result.data;
                    this.newRewardTypeSettings = { id: 0, rewardType: "" };
                })
                .catch(error => {
                    iziToast.error({
                        title: "Error",
                        message: error,
                        position: "topRight"
                    });
                });
        },
        addRewardSettings() {
            this.$v.newRewardTypeSettings.$touch();
            if (this.$v.newRewardTypeSettings.$invalid) {
                return;
            } else {

                axios
                    .post(
                        this.$store.getters.getAPIBasePath +
                        "/settingsresource/add/referral/rewards/settings", this.newRewardTypeSettings
                    )
                    .then(response => {

                        this.formErrors = [];
                        if (response.data.status == true) {
                            iziToast.success({
                                title: "Success",
                                message: response.data.message,
                                position: "topRight"
                            });
                        } else {
                            iziToast.info({
                                title: "Info",
                                message: response.data.message,
                                position: "topRight"
                            });
                        }
                        this.loadRewardSettingsList();
                    })
                    .catch(error => {
                        this.formErrors = error.response.data.validationErrorList;
                    });
                    this.$v.newRewardTypeSettings.$reset();
            }

        },
        removeRewardType: function (arg1, arg2) {
            this.delParam.index = arg1;
            this.delParam.id = arg2;
        },
        deleteRewardType() {

            axios.delete(this.$store.getters.getAPIBasePath + '/settingsresource/delete/referral/reward/settings/' + this.delParam.id)
                .then(response => {
                    iziToast.success({ title: "Success", message: response.data.message, position: "topRight" });
                    this.loadRewardSettingsList();
                })
                .catch(error => {
                    iziToast.error({ title: "Error", message: error, position: "topRight" });
                });
        },

        editrewardType(index) {
            this.editIndex = index;

            this.newRewardTypeSettings = {
                id: this.rewardTypeSettings[index].id,
                referralRewardTypeId: this.rewardTypeSettings[index].referralRewardTypeId,
                points: this.rewardTypeSettings[index].points,
                value: this.rewardTypeSettings[index].value
            };
        },
        updaterewardType(mode) {
            if (mode == 1) {
                axios
                    .post(
                        this.$store.getters.getAPIBasePath +
                        "/settingsresource/update/referral/rewards/settings",
                        this.newRewardTypeSettings
                    )
                    .then(response => {
                        this.editIndex = -1;
                        this.newRewardTypeSettings = { id: 0, referralRewardTypeId: "", points: "", value: "" };
                        if (response.data.status == true) {
                            iziToast.success({
                                title: "Success",
                                message: response.data.message,
                                position: "topRight"
                            });
                        } else {
                            iziToast.info({
                                title: "Info",
                                message: response.data.message,
                                position: "topRight"
                            });
                        }
                        this.loadRewardSettingsList();
                    })
                    .catch(error => {
                        iziToast.error({
                            title: "Error",
                            message: error,
                            position: "topRight"
                        });
                    });
            } else {
                this.editIndex = -1;
                this.newRewardTypeSettings = { id: 0, referralRewardTypeId: "", points: "", value: "" };
            }
        }
    }
};

</script>
