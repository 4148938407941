<template>
    <div id="main" class="tech-skill-settings settings recruiter">
        <div class="row col-md-12 px-0 py-4">
            <div class="col-md-4 offset-md-4">
                <h4 class="">Tax Terms</h4>
            </div>
            <div class="col-md-1 offset-md-3">
                <a href="Javascript:void(0);" class="pull-right btn-back" @click="$emit('setSubTab',0)">
                    <h5 class="text-blue"><span title="Go Back" class="ti-arrow-left py-4"></span><span class="pl-2">Back</span></h5>
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-12 lb">
                <label><h6>Country</h6></label>
            </div>
            <div class="col-md-3 col-sm-3 col-3 mb-10-mr pb-3 ml-3">
                <select v-model="countryId" @change="loadTaxTerms()" class="form-control" :disabled="disabled" >
                    <option value="">Select</option>
                    <option v-for="(data, index) in countries" :value="data.country_Id">{{data.name}}</option>
                </select>
            </div>
        </div>
        <div class="row" v-if="editIndex<0">
            <div class="col-md-6 col-sm-8 col-6">                
                <input class="form-control" placeholder="Enter Tax term" 
                v-model="newTaxTerm.taxTermName" />
                <p v-if="formErrors.length">
                    <ul>
                        <li class="error" v-for="error in formErrors">{{ error.message }}</li>
                    </ul>
                </p>
            </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                <a href="Javascript:void(0);" @click="addTaxTerm">
                    <span class="ti-plus"></span>
                </a>
            </div>
        </div>
        <div class="row" v-if="editIndex!=-1">
            <div class="col-md-6 col-sm-8 col-6">
                <input class="form-control" placeholder="Enter Tax term" v-model="newTaxTerm.taxTermName" />
            </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                <a style="float:left; margin-right:5px;" href="Javascript:void(0);" @click="updateTaxTerm(1)">
                    <span class="ti-check"></span>
                </a>
                <a style="float:left;" href="Javascript:void(0);" @click="updateTaxTerm(0)">
                    <span class="ti-close"></span>
                </a>
            </div>
        </div>
        <div class="row item" v-for="(taxTermInfo, index) in taxTermsList" v-bind:key="index">
            <div class="col-md-6 col-sm-8 col-6">
                <div class="form-control">{{taxTermInfo.taxTermName}}</div>
            </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                <a href="javscript:void(0);" @click="removeWorkAuth(index,taxTermInfo.id)" data-toggle="modal" data-target="#removTaxTerm" title="Remove" class="px-2"><span class="ti-trash"></span></a>
                <a style="float:left; margin-right:5px;" href="Javascript:void(0);" @click="editQualification(index)">
                    <span class="ti-pencil"></span>
                </a>
            </div>
        </div>
         <Dialog id="removTaxTerm" :onYes="deleteTaxTerm" :returnParams="delTaxParam" />
    </div>
</template>
<style scoped>
div.item {
    margin-top: 5px;
}

</style>
<script>
import multiItemInput from "@/components/plugins/multiItemInput";
import iziToast from "izitoast";

export default {
    components: {
        multiItemInput
    },
    data() {
        return {
            disabled: false,
            countryId: 'US',
            formErrors: [],
            editIndex: -1,
            taxTermsList: [],           
            newTaxTerm: { id: 0, taxTermName: "",countryId: "" },
            browsepagination: this.$store.state.paginationconfig,
            countries: this.$store.getters.getCountries,
            searchlimit: this.$store.state.searchlimit,
            delTaxParam: {
                index: -1,
                id: 0
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    taxTermName: ""
                }
            }
        };
    },
    mounted() {
        this.loadTaxTerms();
    },
    methods: {
        loadTaxTerms() {
            axios.post(this.$store.getters.getAPIBasePath +'/settingsresource/get/country/taxterms/list/' + this.countryId)
                .then(response => {
                    let result = response.data;
                    this.taxTermsList = result.recordinfo;        
                    this.newTaxTerm = { id: 0, taxTermName: "",countryId: this.countryId };      
                })
                .catch(error => {
                    iziToast.error({
                        title: "Error",
                        message: error,
                        position: "topRight"
                    });
                });
        },
        addTaxTerm() {                      
            axios.post(this.$store.getters.getAPIBasePath +"/settingsresource/add/settingtaxterm", 
                this.newTaxTerm)
                .then(response => {
                    this.newTaxTerm= "";
                    this.formErrors = [];
                   
                    if(response.data.status == true) {
                       iziToast.success({
                           title: "Success",
                           message: response.data.message,
                           position: "topRight"
                       });
                   } else {
                       iziToast.info({
                           title: "Info",
                           message: response.data.message,
                           position: "topRight"
                       });
                   }                   
                    
                   this.loadTaxTerms();
                })
                .catch(error => {
                    this.formErrors = error.response.data.validationErrorList;
                });
        },
         removeWorkAuth:function(arg1, arg2)
            {
                this.delTaxParam.index=arg1;
                this.delTaxParam.id=arg2;
            },
            deleteTaxTerm:function(arg)
            {   
            axios.delete(this.$store.getters.getAPIBasePath + '/settingsresource/delete/taxterms/' + this.delTaxParam.id)
                .then(response => {
                    let result = response.data;
                    if (result.status == true) {
                        iziToast.success({
                            title: "Success",
                            message: result.message,
                            position: "topRight",
                            timeout: 1000
                        });
                        this.taxTermsList.splice(arg.index, 1);
                    }else{
                        iziToast.error({
                            title: "Error",
                            message: this.$config.TaxDelErr,
                            position: "topRight"
                        });
                    }
                })
                .catch(function(error) {
                    iziToast.error({ title: 'Error', message: 'Error', position: 'topRight' });
                    console.log(error);
                });

        },       
        editQualification(index) {
            this.disabled = true,
            this.editIndex = index;
            this.newTaxTerm= {
                id: this.taxTermsList[index].id,
                taxTermName: this.taxTermsList[index].taxTermName, 
                countryId: this.countryId               
            };
        },
        updateTaxTerm(mode) {
            this.disabled = false;
            if (mode == 1) {
                axios.post(
                        this.$store.getters.getAPIBasePath +
                        "/settingsresource/update/settingtaxterm",
                        this.newTaxTerm
                    )
                    .then(response => {                                
                        this.editIndex = -1;
                        this.newTaxTerm= { id: 0, taxTermName: "",countryId: this.countryId };
                        if(response.data.status == true) {
                           iziToast.success({
                               title: "Success",
                               message: response.data.message,
                               position: "topRight"
                           });
                        } else {
                           iziToast.info({
                               title: "Info",
                               message: response.data.message,
                               position: "topRight"
                           });
                        }
                        this.loadTaxTerms();

                    })
                    .catch(error => {
                        iziToast.error({
                            title: "Error",
                            message: error,
                            position: "topRight"
                        });
                    });
            } else {
                this.editIndex = -1;
                this.newTaxTerm= { id: 0, taxTermName: "",countryId: this.countryId };
            }
        }
    }
};

</script>
