import { render, staticRenderFns } from "./CandidateCustomReport.vue?vue&type=template&id=7ec96413"
import script from "./CandidateCustomReport.vue?vue&type=script&lang=js"
export * from "./CandidateCustomReport.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports