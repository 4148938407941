<template>
	<div>
		<div class="card">
		    <div class="card-header p-2">Contract Details</div>
		    <div class="card-body p-2">
		      	<div class="basic-block">			        
			        <div class="row ten-columns">
			            <div class="col-sm-12">
			                <div class="table-responsive">
			                  	<table class="table table-borderless all-table">
			                    <thead>
			                      	<tr>
				                        <th class="" scope="col"></th>
				                        <th class="" scope="col">Contract ID</th>
				                        <th class="recruiter" scope="col">Ready To Use</th>
				                        <th class="title" scope="col">Begin Date</th>
				                        <th class="state" scope="col">  End Date</th>
			                      	</tr>
			                    </thead>
			                    <tbody>
			                      	<tr class="">
			                        	<td colspan="6" v-if="clientcontractinfo.length==0" class="text-center">No Contract Details Found</td>
			                      	</tr>
			                      	<tr v-for="(info, index) in clientcontractinfo">
			                      		<td></td>
	                                    <td> {{ clientcontractinfo[index].id }}</td>
	                                    <td class="lastnote"> 
	                                      	<input :disabled="true" class="form-check-input" id="readyToUseContact" type="checkbox" v-model="clientcontractinfo[index].readyToUse"> 
	                                       	<label class="form-check-label" for="readyToUseContact"></label>
	                                    </td>
	                                    <td class="recruiter"> {{ clientcontractinfo[index].beginDate | formatDate }}  </td>
	                                    <td class="title"> {{ clientcontractinfo[index].endDate | formatDate }} </td>
	                                </tr>
			                    </tbody>
			                  </table>
			                </div>
			            </div>
			        </div>
		      	</div>
		    </div>
		</div>
		<div class="modal fade" id="clientContract" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  	<div class="modal-dialog modal-lg" role="document">
			    <div class="modal-content">
			      	<div class="modal-header">
			        	<h5 class="modal-title" id="exampleModalLabel">Add Client Contract Details</h5>
			        	<button type="button" class="close" data-dismiss="modal" aria-label="Close">
			          		<span aria-hidden="true">&times;</span>
			        	</button>
			      	</div>
				    <div class="modal-body">
				        <div class="card m-b-3">
							<!-- Add Contract -->
							<div class="card-header">
							<h5 class="modal-title" >Add Contract</h5>
							</div>
							<div class="card-body p-2">
							<div class="basic-block">
							  <div class="row">
							    <div class="col-sm-3 p-r">
							      <div class="row">
							        <div class="col-md-12 col-sm-12 col-12 lb p-a">
										<div class="form-check form-check-inline">
											<input type="checkbox" name="readyUse"  v-model="newclientcontractinfo.readyToUse" value="on" id="readyUse" class="form-check-input form-control">
											<label class="form-check-label" for="readyUse">Ready to Use</label>		 
										</div>
							        </div>
							      </div>
							    </div>
							    <div class="col-sm-3">
							      <div class="row">
							        <div class="col-md-12 col-sm-12 col-12 lb">
							          <label for="tc_type">Begin Date</label>
							        </div>
							        <div class="col-md-12 col-sm-12 col-12">
							        	<div class="input-group required-field-block">
											<datepicker v-model="newclientcontractinfo.beginDate" placeholder="Begin Date" input-class="form-control enablefield " wrapper-class="input-group date" format="MM/dd/yyyy" :class="{ 'is-invalid': $v.newclientcontractinfo.beginDate.$error }" ></datepicker>
											<span class="required-icon"></span>
										</div>
										<label v-if="$v.newclientcontractinfo.beginDate.$error && !$v.newclientcontractinfo.beginDate.required" class="text-danger">Please select begin date</label>
							        </div>
							      </div>
							    </div>
							    <div class="col-sm-3">
							      <div class="row">
							        <div class="col-md-12 col-sm-12 col-12 lb">
							          <label for="tc_type">End Date</label>
							        </div>
							        <div class="col-md-12 col-sm-12 col-12">
							        	<div class="input-group required-field-block">
											<datepicker v-model="newclientcontractinfo.endDate" placeholder="End Date" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" :class="{ 'is-invalid': $v.newclientcontractinfo.endDate.$error }"></datepicker>
											<span class="required-icon"></span>
										</div>
										<label v-if="$v.newclientcontractinfo.endDate.$error && !$v.newclientcontractinfo.endDate.required" class="text-danger">Please select end date</label>									
							        </div>
							      </div>
							    </div>
							    <div class="col-sm-3" style="display: none">
							      <div class="row">
							        <div class="col-md-12 col-sm-12 col-12 lb">
							          <label for="tc_type">Client Contract ID</label>
							        </div>
							        <div class="col-md-12 col-sm-12 col-12">
							          <div class="input-group required-field-block">
							            <input type="text" name="firstName"  maxlength="50" value="" id="firstName" class="Submitaddress txtwidth form-control">
							            <span class="required-icon">
							            </span>
							          </div>
							        </div>
							      </div>
							    </div>
							  </div>
							</div>
							</div>
							<!-- Other Details -->
							<div class="card-header">
								<h5 class="modal-title" >Other Details</h5>
							</div>
							<div class="card-body p-2">
								<div class="basic-block">
									<div class="row">
									    <div class="col-sm-3">
										    <div class="row">
										        <div class="col-md-12 col-sm-12 col-12 lb">
										          <label for="tc_type">Accept Local Candidate?</label>
										        </div>
										        <div class="col-md-12 col-sm-12 col-12">
										          	<div class="input-group radio_btns">
											            <p>
											              	<input type="radio" id="acceptlocal1"  v-model="newclientcontractinfo.acceptLocalCandidate" value="1" name="acceptlocal" checked="">
											              	<label for="acceptlocal1">Yes</label>
											            </p>
											            <p>
											              	<input type="radio" id="acceptlocal2"  v-model="newclientcontractinfo.acceptLocalCandidate" value="2" name="acceptlocal">
											              	<label for="acceptlocal2">No</label>
											            </p>
										          	</div>
										        </div>
										    </div>
									    </div>
									    <div class="col-sm-3">
									        <div class="row">
									            <div class="col-md-12 col-sm-12 col-12 lb">
									              <label for="tc_type">Local Radius</label>
									            </div>
									            <div class="col-md-12 col-sm-12 col-12">
									              <div class="input-group">
									                <input type="text" name="lastName"  v-model="newclientcontractinfo.localRadius" maxlength="7" value="" id="lastName" class="Submitaddress txtwidth form-control">
									              </div>
									            </div>
									        </div>
									    </div>
									    <div class="col-sm-3">
									    	<div class="row">
									            <div class="col-md-12 col-sm-12 col-12 lb">
									              <label for="tc_type">Start Time</label>
									            </div>
									            <div class="col-md-12 col-sm-12 col-12">
									              <div class="input-group date " data-date-format="mm-dd-yyyy">
									                  <!-- <input v-model="newclientcontractinfo.startTime"  class="form-control datepicker" name="startTime" id="startTime" type="text">
									                  <span class="calendar-addon"><span class="ti-calendar"></span></span>	 -->		
									              	<b-form-input id="startTime" v-model="newclientcontractinfo.startTime" type="time" class="form-control" name="startTime"></b-form-input>
									              </div>
									            </div>
									        </div>
									    </div>
									    <div class="col-sm-3">
									    	<div class="row">
									            <div class="col-md-12 col-sm-12 col-12 lb">
									              <label for="tc_type">End Time</label>
									            </div>
									            <div class="col-md-12 col-sm-12 col-12">
									              <div class="input-group date " data-date-format="mm-dd-yyyy">
									                  <b-form-input id="endTime" v-model="newclientcontractinfo.endTime" type="time" class="form-control" name="endTime"></b-form-input>		
									              </div>
									              <label v-if="$v.newclientcontractinfo.endTime.$error && !$v.newclientcontractinfo.endTime.required" class="text-danger">Please select end time</label>
									            </div>
									        </div>
									    </div>
									</div>
								  	<div class="row">
									    <div class="col-sm-12">
									      <div class="row">
									        <div class="col-md-12 col-sm-12 col-12 lb">
									          <label for="tc_type">Note</label>
									        </div>
									        <div class="col-md-12 col-sm-12 col-12">
									          <div class="input-group">
									            <textarea name="notes"  v-model="newclientcontractinfo.note"  rows="3" id="notes" class="txtboxbig Submitaddress form-control"></textarea>
									          </div>
									        </div>
									      </div>
									    </div>
								  	</div>
								</div>
							</div>
							<div class="card-body p-2">
								<div class="basic-block">
								  	<div class="row">
								        <div class="col-sm-12 text-right">
								        	<div class="modal-btns">
								              	<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
								              	<button type="button" class="btn btn-primary" style="margin-left: 10px;" @click="resetNewClientContractForm"> Reset</button>
								              	<button type="button" class="btn btn-primary" style="margin-left: 10px;"  @click="saveClientContractInfo" > Save</button>
								            </div>
								        </div>
								  	</div>
								</div>
							</div>
				    	</div>
					</div>
			    </div>
		  	</div>
		</div>
	</div>
</template>
<script>
    import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
    import iziToast from 'izitoast';
    export default {
        computed:{
            clientid(){
                return this.$route.params.clientid;
            }
        },
        props: {
            clientcontractinfo: Array,
        },
        data(){
          return {
            newclientcontractinfo : {
                id:"",
                readyToUse:0,
                beginDate:"",
                endDate:"",
                acceptLocalCandidate:"",
                startTime:"",
                endTime:"",
                localRadius:"",              
                note:""
            }
          }
        },
        validations: {
            newclientcontractinfo:{
                beginDate:{
                    required
                },
                endDate:{
                    required
                },
                startTime:{
                    required
                },
                endTime:{
                    required
                }
            }
        },
        methods:{
            resetNewClientContractForm(){
                this.newclientcontractinfo = {
                	id:"",
                    readyToUse:0,
                    beginDate:"",
                    endDate:"",
                    acceptLocalCandidate:"",
                    startTime:"",
                    endTime:"",
                    localRadius:"",              
                    note:""
                };
                this.$v.newclientcontractinfo.$reset();
            },
            loadClientContract(contractId){
                axios.get(this.$store.getters.getAPIBasePath+'/clientresource/view/clientcontract/'+contractId)
                .then(response => {
                    let result = response.data;
                    if(!result.status){
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position:'topRight'
                        });
                    }
                    let self = this;
                    this.newclientcontractinfo = {
                    	id:result.clientContract.id,
	                    readyToUse:result.clientContract.readyToUse,
	                    beginDate:result.clientContract.beginDate,
	                    endDate:result.clientContract.endDate,
	                    acceptLocalCandidate:result.clientContract.acceptLocalCandidate,
	                    startTime:result.clientContract.startTime,
	                    endTime:result.clientContract.endTime,
	                    localRadius:result.clientContract.localRadius,            
	                    note:result.clientContract.note,
	                };
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliContraLoadErr,
                        position:'topRight'
                    });
                });
            },
            saveClientContractInfo(){
                this.$v.newclientcontractinfo.$touch();
                let clientcontractinfodata = this.newclientcontractinfo;                
                axios.post(this.$store.getters.getAPIBasePath+'/clientresource/update/client/'+this.clientid+'/contract',clientcontractinfodata)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CliContraInfoUpSuc,
                        position:'topRight'
                    });
                    $('#clientContract').modal('hide');
                    this.resetNewClientContractForm();
                    this.$emit('refresh-client');
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliContraUpErr,
                        position:'topRight'
                    });
                });
            },
            deleteClientContract(clientContractId) {  
               	if(confirm("Do you really want to delete?")){            
                   	var params = new URLSearchParams();
                   	params.append('id', clientContractId)
                   	axios.post(this.$store.getters.getAPIBasePath+'/clientresource/delete/clientcontract', params)
                   	.then(response => {
                      	var result = response.data;
                      	if(result.status == "OK") { 
                          	iziToast.success({
                              	title: 'Success',
                              	message: result.message,
                              	position:'topRight'
                          	}); 
                          	this.$emit('refresh-client');
                       	}                            
                   	})
                   	.catch(error => {
                       console.log(error.data);
                   	});
               	}        
           	},
        }
  	}
</script>