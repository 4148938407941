<template>
    <div>
        <div class="card">
            <div class="card-header p-2">Project Details</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col">Project Title</th>
                                            <th class="videoprofile" scope="col"> Client</th>
                                            <th class="recruiter" scope="col">Technology/Skills</th>
                                            <th class="title" scope="col">Start Date</th>
                                            <th class="state" scope="col">End Date</th>
                                            <th class="phone" scope="col">Role</th>
                                            <th class="lastnote" scope="col">Description</th>
                                            <th class="" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="cloned_tr">
                                    	<tr v-for="(info, index) in projectinfo" :class="{ 'viewonly' : projectinfo.viewonly == 1}">
                                    		<td>
                                                {{projectinfo[index].projectTitle}}
                                    		</td>
                                            <td class="lastnote">
                                                {{projectinfo[index].client}}
                                            </td>
                                            <td class="recruiter">
                                                {{projectinfo[index].technology}}
                                            </td>
                                            <td class="title">
                                                {{projectinfo[index].projectStartDate | formatDate}}
                                            </td>
                                            <td class="specialityunit">
                                                {{projectinfo[index].projectEndDate  | formatDate}}
                                            </td>
                                            <td class="currentcity">
                                                {{projectinfo[index].role}}
                                            </td>
                                            <td class="state">
                                                {{projectinfo[index].projectDescription}}
                                            </td>
                                            <td>
                                            </td>
                                    	</tr>
                                        <tr :class="{ 'norecordfound' : projectinfo.length}" >
                                            <td colspan="8" align="center">No item found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default{
        props: {
            projectinfo: Array,
        },
		data(){
			return {
				newprojectinfo:{
					projectTitle:"",
					client:"",
					technology:"",
					projectStartDate:"",
					projectEndDate:"",
					role:"",
					projectDescription:"",				
                    is_current:false
                },
                tempProjectDescription : '',
                tempProjectDescriptionId : -1,
                tempProjectRole : '',
                tempProjectRoleId : -1,
                tempProjectSkill:'',
                tempProjectSkillId : -1,
                delProjParam:{
                    index:-1,
                    id:0
                }
			}
		},
		methods:{
			resetNewForm(){
				this.newprojectinfo = {
					projectTitle:"",
					client:"",
					technology:"",
					projectStartDate:"",
					projectEndDate:"",
					role:"",
					projectDescription:"",				
                    is_current:false
				};
				$('#addcandidate_projectinfo_add_title').focus();
			},
            removeInfo(index){
                this.projectinfo.splice(index,1);
            },
			addNewProjectInfo(){
                this.newprojectinfo.projectTitle = this.newprojectinfo.projectTitle.trim();
                this.newprojectinfo.client = this.newprojectinfo.client.trim();
                this.newprojectinfo.technology = this.newprojectinfo.technology.trim();
                this.newprojectinfo.role = this.newprojectinfo.role.trim();
                this.newprojectinfo.projectDescription = this.newprojectinfo.projectDescription.trim();
                if(this.newprojectinfo.projectTitle!="" || this.newprojectinfo.client!="" || this.newprojectinfo.technology!=""  || this.newprojectinfo.role!="" || this.newprojectinfo.projectDescription!=""){
                    this.projectinfo.push(this.newprojectinfo);
                    this.resetNewForm();
                }
            },
            showBox: function(arg)
            {
                this.tempProjectDescriptionId = arg;
                if(arg<0)
                {
                    this.tempProjectDescription = this.newprojectinfo.projectDescription;                    
                    //console.log(this.newprojectinfo.projectDescription);
                }
                else
                {
                    this.tempProjectDescription = this.projectinfo[arg].projectDescription;                    
                    //console.log(this.projectinfo[arg].projectDescription);
                }                
            },
            updateProjDes:function()
            {
                if(this.tempProjectDescriptionId < 0)
                {
                    this.newprojectinfo.projectDescription = this.tempProjectDescription;
                }
                else
                {
                    this.projectinfo[this.tempProjectDescriptionId].projectDescription = this.tempProjectDescription;
                }
                // console.log(this.tempProjectDescriptionId);
                // console.log(this.tempProjectDescription);
            },
            showBoxRole: function(arg)
            {
                this.tempProjectRoleId = arg;
                if(arg<0)
                {
                    this.tempProjectRole = this.newprojectinfo.role;                    
                    //console.log(this.newprojectinfo.projectDescription);
                }
                else
                {
                    this.tempProjectRole = this.projectinfo[arg].role;                    
                    //console.log(this.projectinfo[arg].projectDescription);
                }                
            },
            updateProjRole:function()
            {
                if(this.tempProjectRoleId < 0)
                {
                    this.newprojectinfo.role = this.tempProjectRole;
                }
                else
                {
                    this.projectinfo[this.tempProjectRoleId].role = this.tempProjectRole;
                }
                // console.log(this.tempProjectDescriptionId);
                // console.log(this.tempProjectDescription);
            },
            showBoxSkill: function(arg)
            {
                this.tempProjectSkillId = arg;
                if(arg<0)
                {
                    this.tempProjectSkill = this.newprojectinfo.technology;                    
                    //console.log(this.newprojectinfo.projectDescription);
                }
                else
                {
                    this.tempProjectSkill = this.projectinfo[arg].technology;                    
                    //console.log(this.projectinfo[arg].projectDescription);
                }                
            },
            updateProjSkill:function()
            {
                if(this.tempProjectSkillId < 0)
                {
                    this.newprojectinfo.technology = this.tempProjectSkill;
                }
                else
                {
                    this.projectinfo[this.tempProjectSkillId].technology = this.tempProjectSkill;
                }
                // console.log(this.tempProjectDescriptionId);
                // console.log(this.tempProjectDescription);
            },
            deleteProj:function(arg)
            {
                this.projectinfo.splice(arg.index,1);
            },
            confirmProj:function(arg1, arg2)
            {
                this.delProjParam.index=arg1;
                this.delProjParam.id=arg2;
            }
		}
	}
</script>