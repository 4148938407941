<template>
    <div class="add-job mt-80">
        <div v-if="showForm" class="">
            <div class="container-fluid" id="quick-search">
                <div class="row col-md-12 col-sm-12 mx-0 px-0">
                    <div class="col-md-12">
                        <div class="box-wrapper shadow-sm p-0 bg-white rounded pos-rel ">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12">
                                    <h4 class="page-title p-3 d-inline-block text-white candidate-title">JOB INFORMATION</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row col-md-12 mx-0 px-0">
                    <div v-if="false" class="card">
                        <div class="card-header p-2">Contact Info</div>
                        <div class="card-body p-2">
                            <div class="basic-block">
                                <div class="row ten-columns">
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Contact Person</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" name="contactPerson" maxlength="25" value="" class="fldboxbig form-control" v-model="newJobForm.contact.contactPerson">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Email</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" name="email" maxlength="25" value="" class="fldboxbig form-control" v-model="newJobForm.contact.email">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Phone</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" name="phone" maxlength="15" value="" class="fldboxbig form-control" v-model="newJobForm.contact.phone1">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Mobile</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" name="mobile" maxlength="15" value="" class="fldboxbig form-control" v-model="newJobForm.contact.phone2">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Website</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" name="website" maxlength="25" value="" class="fldboxbig form-control" v-model="newJobForm.contact.website">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ten-columns">
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Address </label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input type="text" name="address1" maxlength="200" value="" class="fldboxbig form-control" v-model="newJobForm.contact.addressLine1">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Address 2</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input type="text" name="address2" maxlength="200" value="" class="fldboxbig form-control" v-model="newJobForm.contact.addressLine2">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>City / Place</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input type="text" name="city" maxlength="25" value="" class="fldboxbig form-control" v-model="newJobForm.contact.cityName">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>State</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input type="text" name="state" maxlength="25" value="" class="fldboxbig form-control" v-model="newJobForm.contact.stateName">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Country</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <select v-model="newJobForm.contact.countryId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(data, index) in countryList" :value="data.country_Id">{{data.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Zip Code</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input type="number" name="zipCode" maxlength="10" value="" class="fldboxbig form-control" v-model="newJobForm.contact.zipCode">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-4">
                        <div class="box-wrapper shadow-sm p-4 mb-4 bg-white rounded">
                            <div class="card">
                                <div class="card-header blue-bg text-white mx-0 p-2">Primary Job Info</div>
                                <div class="card-body p-2">
                                    <div class="basic-block">
                                        <div class="row col-md-12 mx-0 py-3">
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Company Name</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group  required-field-block">
                                                            <input type="text" name="clientName" maxlength="200" value="" class="fldboxbig form-control" v-model="newJobForm.clientName">
                                                            <span class="required-icon">
                                                            </span>
                                                        </div>
                                                        <label v-if="$v.newJobForm.clientName.$error && !$v.newJobForm.clientName.required" class="text-danger">Please enter Company Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Email Contact</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group  required-field-block">
                                                            <input type="email" name="accountName" maxlength="200" value="" class="fldboxbig form-control" v-model="newJobForm.accountName">
                                                            <span class="required-icon">
                                                            </span>
                                                        </div>
                                                        <label v-if="$v.newJobForm.accountName.$error && !$v.newJobForm.accountName.required" class="text-danger">Please enter Email Contact</label>
                                                        <label v-if="$v.newJobForm.accountName.$error && !$v.newJobForm.accountName.email" class="text-danger">Email Id not valid</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-md-12 mx-0 py-3">
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Job Title</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group  required-field-block">
                                                            <input type="text" name="jobTitle" maxlength="200" value="" class="fldboxbig form-control" v-model="newJobForm.jobTitle">
                                                            <span class="required-icon">
                                                            </span>
                                                        </div>
                                                        <label v-if="$v.newJobForm.jobTitle.$error && !$v.newJobForm.jobTitle.required" class="text-danger">Please enter Job Title</label>
                                                        <label v-if="$v.newJobForm.jobTitle.$error && !$v.newJobForm.jobTitle.minLength" class="text-danger">Job Title must have at least {{$v.newJobForm.jobTitle.$params.minLength.min}} letters.</label>
                                                        <label v-if="$v.newJobForm.jobTitle.$error && !$v.newJobForm.jobTitle.maxLength" class="text-danger">Job Title must not exceed {{$v.newJobForm.jobTitle.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Industry</label>
                                                    </div>
                                                    <div v-if="showIndustryDropDown" class="col-md-12 col-sm-12 col-12">
                                                        <select v-model="newJobForm.industryId" @change="industryOnChange(newJobForm.industryId)" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(data, index) in industrylist" :value="data.id">{{data.industryName}}</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    </div>
                                                    <div v-if="!showIndustryDropDown" class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <input type="text" name="industry" maxlength="100" v-model="newJobForm.industryName" class="fldboxbig form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-md-12 mx-0 py-3">
                                            <div v-if="false" class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Position</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <input type="text" name="position" maxlength="100" value="" class="fldboxbig form-control" v-model="newJobForm.position">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           <!--  <div class="row col-md-12 mx-0 py-3"> -->
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Country</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <select v-model="newJobForm.countryId" @change="loadState(newJobForm.countryId)" class="form-control" required>
                                                                <option value="">Select</option>
                                                                <option v-for="(data, index) in countries" :value="data.country_Id">{{data.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Location</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <!--  <input type="text" name="location" maxlength="100" value="" class="fldboxbig form-control" v-model="newJobForm.location"> -->
                                                            <!-- <multiselect style="width:92%; float:left;" v-model="newJobForm.location" :options="stateList" track-by="country_Id" label="name" name="newJobForm.location" :multiple="true" :taggable="false" :show-labels="false" placeholder="Select Country"></multiselect> -->
                                                            <multiselect style="width:92%; float:left;" v-model="newJobForm.location" :options="stateList" track-by="id" label="stateName" name="newJobForm.location" :multiple="true" :taggable="false" :show-labels="false" placeholder="Select State"></multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-md-12 mx-0 py-3">
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Qualification</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <!-- <input type="text" name="eligibility" maxlength="500" value="" class="fldboxbig form-control" v-model="newJobForm.eligibility"> -->
                                                            <multiselect style="width:92%; float:left;" v-model="newJobForm.eligibility" :options="qualification" track-by="id" label="qualificationName" name="newJobForm.eligibility" :multiple="true" :taggable="false" :show-labels="false" placeholder="Select Qualification"></multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Experience</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group extended">
                                                            <input type="number" name="experience" maxlength="25" value="" class="fldboxbig form-control" v-model="newJobForm.experience" @keypress="onlyNumber" min=0 oninput="validity.valid||(value='');">
                                                            <div class="input-group-append">
                                                                <select v-model="newJobForm.expType" class="form-control p-0">
                                                                    <option v-for="(data, index) in expTypeList" :value="data.name">{{data.name}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-md-12 mx-0 py-3">
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Salary </label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group extended">
                                                            <select v-model="newJobForm.currency" class="form-control p-0">
                                                                <option v-for="(info, index) in currencies" :value="info.symbol">{{info.symbol}}</option>
                                                            </select>
                                                            <input type="number" class="form-control" maxlength="25" v-model="newJobForm.salary" @keypress="onlyNumber" min=0 oninput="validity.valid||(value='');">
                                                            <div class="input-group-append">
                                                                <select v-model="newJobForm.payFrequency" class="form-control p-0">
                                                                    <option v-for="(data, index) in payFrequencyList" :value="data.name">{{data.name}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Vacancies</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <input type="number" name="vacancies" maxlength="25" value="" class="fldboxbig form-control" v-model="newJobForm.vacancies" @keypress="onlyNumber" min=0 oninput="validity.valid||(value='');">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                         <div class="row col-md-12 mx-0 py-3">
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Minimum Salary </label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <input v-bind:disabled="newJobForm.frequencyPercentage == '' || newJobForm.frequencyPercentage == undefined || newJobForm.frequencyPercentage == null ? false : true" type="number" name="minsalary" maxlength="25" value="" class="fldboxbig form-control" v-model="newJobForm.minSalary" @keypress="onlyNumber" min=0 oninput="validity.valid||(value='');">
                                                        </div>
                                                         <label v-if="!$v.newJobForm.minSalary" class="text-danger">Minimum Salary must be less than Maximum Salary</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Maximum Salary</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <input v-bind:disabled="newJobForm.frequencyPercentage == '' || newJobForm.frequencyPercentage == undefined || newJobForm.frequencyPercentage == null ? false : true" type="number" name="maxsalary" maxlength="25" value="" class="fldboxbig form-control" v-model="newJobForm.maxSalary" @keypress="onlyNumber" min=0 oninput="validity.valid||(value='');">
                                                        </div>
                                                        <label v-if="!$v.newJobForm.maxSalary" class="text-danger">Maximum Salary must be greater than Minimum Salary</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-md-12 mx-0 py-3">
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                 <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Flexibility Percentage</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <input v-bind:disabled="(newJobForm.maxSalary == '' && newJobForm.minSalary == '') || (newJobForm.maxSalary && newJobForm.minSalary == undefined ) || (newJobForm.maxSalary == null && newJobForm.minSalary == null) ? false : true" type="number" name="frequencypercentage" maxlength="25" value="" class="fldboxbig form-control" v-model="newJobForm.frequencyPercentage" @keypress="onlyNumber" min=0 oninput="validity.valid||(value='');">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                               <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Work Type</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <select v-model="newJobForm.workType" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(data, index) in worktypelist" :value="data.id">{{data.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-md-12 mx-0 py-3">
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Due Date</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group date" data-date-format="mm-dd-yyyy">
                                                            <datepicker name="endDate" maxlength="11" value="" id="endDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="newJobForm.dueDate" :disabledDates="disabledDates"></datepicker>
                                                            <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                        </div>
                                                        <!-- <label v-if="$v.newJobForm.dueDate.$error && !$v.newJobForm.dueDate.checkDate" class="invalid-feedback mt-0">Invalid Due Date</label> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="false" class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Status</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <select v-model="newJobForm.status" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(data, index) in jobStatuslist" :value="data.id">{{data.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr d-none">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Job Code</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <input type="text" disabled="disabled" name="jobCode" maxlength="30" value="" class="fldboxbig form-control" v-model="newJobForm.jobCode">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-4">
                        <div class="box-wrapper shadow-sm p-4 mb-4 bg-white rounded">
                            <div class="card mb-4">
                                <div class="card-header blue-bg text-white mx-0 p-2">Skill Details</div>
                                <div class="card-body p-2">
                                    <div class="basic-block clone-education">
                                        <div class="row ten-columns pos-relative clone filled3">
                                            <div class="col-md-4 col-sm-6 col-12 px-0 mb-10-mr">
                                                <div class="row ten-columns">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Technology</label>
                                                    </div>
                                                    <div v-if="!showTechDropDown" class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <input class="form-control" type="text" v-model="technologyName" placeholder="Enter Technology" />
                                                            <a href="javascript:" class="tbl-add-icon" style="float:left;" @click="setTechDropDown"><span class="input-group-addon"><i title="Existing" class="ti-settings"></i></span></a>
                                                        </div>
                                                    </div>
                                                    <div v-if="showTechDropDown" class="col-md-12 col-sm-12 col-12">
                                                        <multiselect v-model="technology_value" :options="technologies" name="technology_value" label="technology" track-by="id" select-label="" deselect-label="" @select="loadSkills" class="" :max-height="600"></multiselect>
                                                        <!-- <a href="javascript:" class="tbl-add-icon" style="float:left;" @click="setTechTextBox"><span class="input-group-addon"><i title="Cancel" class="fa fa-remove"></i></span></a> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8 col-sm-6 col-12 px-0 mb-10-mr">
                                                <div class="row ten-columns">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Skill</label>
                                                    </div>
                                                    <div v-if="!showSkillDropDown" class="col-md-12 col-sm-12 col-12">
                                                        <MultiItemInput class="form-control" id="skillname" :items="skillName" style="width:85%; float:left;" placeholder="Enter Skill" />
                                                        <a href="javascript:" class="tbl-add-icon" style="float:left;" @click="setSkillDropDown"><span class="input-group-addon"><i title="existing" class="ti-settings"></i></span></a>
                                                        <a @click="addTechAndSkill" href="javascript:" class="tbl-add-icon pull-left pl-2"><span class="input-group-addon"><i class="ti-plus"></i></span></a>
                                                    </div>
                                                    <div v-if="showSkillDropDown" class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <div>
                                                                <multiselect style="width:87%; float:left;" v-model="newJobForm.jobSkill" :options="skills" name="jobSkill" label="skillName" track-by="skillId" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                                <a href="javascript:" class="tbl-add-icon" style="float:left;" @click="setSkillTextBox"><span class="input-group-addon"><i title="cancel" class="fa fa-close"></i></span></a>
                                                                <a @click="addTechAndSkill" href="javascript:" class="tbl-add-icon pull-left pl-2"><span class="input-group-addon"><i class="ti-plus"></i></span></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-for="(item, index) in skillDetails" v-bind:key="index" class="row ten-columns pos-relative clone filled3">
                                            <div class="col-md-4 col-sm-12 col-12 lb">
                                                <div class="col-md-12 col-sm-12 col-12 px-0">
                                                    <div v-if="item.technology != null" class="form-control">{{item.technology.technology}}</div>
                                                    <div v-else-if="item.technologyName != null" class="form-control">{{item.technologyName}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-8 col-sm-12 col-12 lb">
                                                <div class="col-md-12 col-sm-12 col-12 px-0">
                                                    <div class="input-group">
                                                        <div class="row mx-0">
                                                            <div style="width:87%;" class="form-control skillList align-left">
                                                                <ul>
                                                                    <li v-for="(item1, index1) in item.skills" :key="index1" class="item rounded"><span class="text">{{ item1.skillName }}</span> <a href="javascript:;" @click="removeSkill(index, index1)"><i class="ti-close item-close-icon1"></i></a></li>
                                                                </ul>
                                                            </div>
                                                            <a href="javascript:" class="tbl-add-icon" @click="removeOneSkillDetail(index)"><span class="input-group-addon"><i class="ti-trash"></i></span></a>
                                                            <a href="javascript:" class="tbl-add-icon" @click="editOneSkillDetail(index)"><span class="input-group-addon"><i class="ti-pencil"></i></span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mb-4">
                                <div class="card-header blue-bg text-white mx-0 p-2">Job Requirement</div>
                                <div class="card-body p-2">
                                    <div class="basic-block">
                                        <div class="row ten-columns">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                                        <!-- <textarea rows="5" class="form-control" v-model="newJobForm.jobRequirement"></textarea> -->
                                                        <editor api-key="yn4wx6bfcohfz6bwlu5k4urno027lgfkcpxolz0f6k4kku01"  v-model="newJobForm.jobRequirement" :options="this.$store.getters.editorOptions"  height="310px" :init="{menubar: false,toolbar:false,statusbar: false,height:310,content_style: 'body { line-height:0.4; font-size: 14px;}'}"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-4 text-right">
                        <a href="javascript:" class="btn btn-primary" @click="saveJobInfo">Submit</a>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!showForm" align="center" style="width:50%;" class=" col-md-8 offset-md-3 mt-80 p-5 bg-white rounded pos-rel text-center">
            <div class="card-body p-2">
                <div class="row">
                    <div class="col-sm-12 ">
                        <h3 class="text-color text-center">Welcome to Talent Turbo !</h3>
                        <p class="text-center">Job posted successfully. Your listing will be visible once approved.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="ClientNameShow" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Client Name</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="card m-b-3">
                            <div class="card-body p-2">
                                <div class="row">
                                    <div class="col-sm-12 clientinfo">
                                        <table class="table table-borderless all-table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                    </th>
                                                    <th>Client Id</th>
                                                    <th>Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="!clientlist.length">
                                                    <td class="text-center" colspan="100%">No Client found</td>
                                                </tr>
                                                <tr v-for="(client,index) in clientlist">
                                                    <td>
                                                        <div class="col-sm-12 col-12">
                                                            <input type="radio" name="jobClient" :id="'cli'+client.id" :value="client.id" v-model="newJobForm.clientId">
                                                        </div>
                                                    </td>
                                                    <td>{{client.id}}</td>
                                                    <td>{{client.client_Name}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" @click="getClientContact()" class="btn btn-primary" data-dismiss="modal">OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MultiInputsDialog from '../../plugins/MultiInputsDialog';
import iziToast from 'izitoast';
// import { Editor } from '@toast-ui/vue-editor'
import Editor from '@tinymce/tinymce-vue';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength } from "vuelidate/lib/validators";
import MultiItemInput from '../../plugins/multiItemInput';
export default {
    components: {
        Multiselect,
        'editor': Editor,
        MultiInputsDialog,
        MultiItemInput
    },
    data() {
        return {
            interval: false,
            showForm: true,
            countryList: [],
            currencies: this.$store.getters.getCurrencies,
            //countryList: this.$store.getters.getCountries,
            industrylist: [],
            stateList: [],
            qualification: [],
            expTypeList: [],
            payFrequencyList: [],
            worktypelist: [],
            jobStatuslist: [],
            technologies: [],
            clientlist: [],
            skills: [],
            skillEditIndex: -1,
            technology_value: "",
            skillDetails: [],
            newSkills: [],
            newTechs: [],
            countries:[],
            newJobForm: {
                jobTitle: "",
                key: this.$route.params.key,
                jobCode: "",
                clientId: "",
                clientName: "",
                accountName: "",
                industryId: "",
                position: "",
                countryId:"",
                location: "",
                eligibility: "",
                experience: "",
                salary: "",
                minSalary: "",
                maxSalary: "",
                frequencyPercentage: "",
                payFrequency: "Per Month",
                expType: "Years",
                currency: "$",
                vacancies: "",
                workType: "",
                status: "",
                dueDate: "",
                jobRequirement: "",
                jobSkill: [],
                technologyList: [],
                contact: {
                    contactPerson: "",
                    email: "",
                    phone1: "",
                    phone2: "",
                    website: "",
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    zipCode: "",
                    countryId: ""
                },
                blockjobtab: 0
            },
            showIndustryDropDown: true,
            showTechDropDown: false,
            showSkillDropDown: false,
            technologyName: "",
            skillName: [],
            disabledDates: {
                to: new Date(Date.now() - 8640000)
            }
        }
    },
    validations: {
        newJobForm: {
            jobTitle: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(200)
            },
            minSalary:function(minSalary,maxSalary) {
                    if (minSalary.minSalary!="" && minSalary.minSalary!=undefined && minSalary.minSalary!=null && minSalary.maxSalary != "" && minSalary.maxSalary != undefined && minSalary.maxSalary != null){
                        if(Number(minSalary.minSalary) > Number(minSalary.maxSalary)){
                            return false;
                            }
                        }
                        return true;
                    },
           
            maxSalary:function(minSalary,maxSalary) {
                    if (minSalary.minSalary != "" && minSalary.minSalary != undefined && minSalary.minSalary != null && minSalary.maxSalary!="" && minSalary.maxSalary!=undefined && minSalary.maxSalary!=null){
                        if(Number(minSalary.minSalary) > Number(minSalary.maxSalary)){
                            return false;
                            }
                    } 
                    return true;
                },
            skill_value: required,
            clientName: { required },
            accountName: { required, email }
        }
    },
    mounted() {
        this.loadData();

       /*  this.loadTechnologyList();
        this.loadIndustryList();
        this.loadWorkTypeList();
        this.loadJobStatusList();
        this.loadPayFrequencyList();
        this.loadJobExpTypeList();
        this.loadClientList();
        this.loadCountryList();
        this.loadQualificationList();
        this.loadStateList(); */
    },
    methods: {
        setTechDropDown: function() {
            this.showTechDropDown = true;
            this.showSkillDropDown = true;
        },
        setTechTextBox: function() {
            this.showTechDropDown = false;
            this.showSkillDropDown = false;
        },
        setSkillDropDown: function() {
            this.showTechDropDown = true;
            this.showSkillDropDown = true;
        },
        setSkillTextBox: function() {
            this.technology_value = "";
            this.newJobForm.jobSkill = [];
            this.showTechDropDown = false;
            this.showSkillDropDown = false;
        },
        industryOnChange(industryId) {
            if (industryId == 'Other') {
                this.showIndustryDropDown = false;
                this.newJobForm.industryId = null;
            }
        },
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        validateJobInfo() {
            this.$v.newJobForm.$touch();
            if (this.$v.newJobForm.$invalid) {
                // this.newJobForm.blockjobtab = 1;
                return false;
            }
            //this.newJobForm.blockjobtab = 0;
            return true;
        },
        updateSkillDetails(mode) {
            if (mode == 1) {
                this.skillDetails[this.skillEditIndex].technology = this.technology_value;
                this.skillDetails[this.skillEditIndex].skills = this.newJobForm.jobSkill;
            }

            this.skillEditIndex = -1;
            this.technology_value = "";
            this.newJobForm.jobSkill = [];
            this.skills = [];
        },
        editOneSkillDetail(index) {
            this.skillEditIndex = index;
            this.technology_value = this.skillDetails[index].technology;
            this.newJobForm.jobSkill = this.skillDetails[index].skills;
            this.loadSkills(this.technology_value);
        },
        removeSkill(index, index1) {
            this.skillDetails[index].skills.splice(index1, 1);
        },
        removeOneSkillDetail(index) {
            // console.log(index);
            // if (index == skillEditIndex)
            //   this.updateSkillDetails(0);
            // skillEditIndex = -1;
            this.skillDetails.splice(index, 1);
        },
        loadData : function(){
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/public/list/all')
                .then(response => {
                    let result = response.data;
                    console.log(result);
                    this.technologies = result.technologylist;
                    this.industrylist = result.industries;
                    this.worktypelist = result.workTypeList;
                    this.jobStatuslist = result.jobStatus;
                    this.payFrequencyList = result.payFrequency;
                    this.expTypeList = result.jobExpType;
                    this.clientlist = result.clientlist;
                    this.countryList = result.countrylist;
                    this.qualification = result.settingsQualification;
                    this.stateList = result.statelist;
                    this.countries = result.countries;                    
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadCountryList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/countrylist')
                .then(response => {
                    this.countryList = response.data.countrylist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadSkills(selected_technology) {
            this.skills = [];
            this.skills = selected_technology.settingsTechnologySkill;
        },
        loadClientList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/clientlist')
                .then(response => {
                    this.clientlist = response.data.clientlist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadQualificationList() {
            axios.post(this.$store.getters.getAPIBasePath + '/settingsresource/settingqualification/list')
                .then(response => {
                    this.qualification = response.data.settingsQualification;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadStateList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/statelist?countryId=us')
                .then(response => {
                    this.stateList = response.data.statelist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadTechnologyList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/listtechnology')
                .then(response => {
                    this.technologies = response.data.technologylist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadIndustryList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/industrylist')
                .then(response => {
                    this.industrylist = response.data.industries;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadWorkTypeList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/worktype')
                .then(response => {
                    this.worktypelist = response.data.workTypeList;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadJobStatusList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/jobStatusList')
                .then(response => {
                    this.jobStatuslist = response.data.jobStatus;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadPayFrequencyList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/payFrequencyList')
                .then(response => {
                    this.payFrequencyList = response.data.payFrequency;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadJobExpTypeList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/jobExpTypeList')
                .then(response => {
                    this.expTypeList = response.data.jobExpType;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        saveJobInfo() {
            this.addTechAndSkill();
            this.addSkillInfo();
            console.log(this.newJobForm.jobSkill);

            if (this.validateJobInfo()) {
                if (this.newJobForm.contact.countryId == "")
                    this.newJobForm.contact.countryId = null;
                let locationValues = "";
                for (var i = 0; i < this.newJobForm.location.length; i++) {
                    if (locationValues == "")
                        locationValues = this.newJobForm.location[i].id
                    else
                        locationValues = locationValues + "," + this.newJobForm.location[i].id;
                }
                let eligibilityData = "";
                for (var j = 0; j < this.newJobForm.eligibility.length; j++) {
                    if (eligibilityData == "")
                        eligibilityData = this.newJobForm.eligibility[j].id
                    else
                        eligibilityData = eligibilityData + "," + this.newJobForm.eligibility[j].id;
                }

                let jobInfoData = {
                    recruiterId: this.recruiterId,
                    jobTitle: this.newJobForm.jobTitle,
                    jobCode: this.newJobForm.jobCode,
                    clientId: this.newJobForm.clientId,
                    clientName: this.newJobForm.clientName,
                    accountName: this.newJobForm.accountName,
                    industryId: this.newJobForm.industryId,
                    industryName: this.newJobForm.industryName,
                    position: this.newJobForm.position,
                    location: locationValues, //this.newJobForm.location,
                    eligibility: eligibilityData, //this.newJobForm.eligibility,
                    experience: this.newJobForm.experience,
                    salary: this.newJobForm.salary,
                    minSalary: this.newJobForm.minSalary,
                    maxSalary: this.newJobForm.maxSalary,
                    frequencyPercentage: this.newJobForm.frequencyPercentage,
                    vacancies: this.newJobForm.vacancies,
                    workType: this.newJobForm.workType,
                    status: this.newJobForm.status,
                    payFrequency: this.newJobForm.payFrequency,
                    expType: this.newJobForm.expType,
                    currency: this.newJobForm.currency,
                    dueDate: this.formatDateString(this.newJobForm.dueDate),
                    jobRequirement: this.newJobForm.jobRequirement,
                    jobSkill: this.newJobForm.jobSkill,
                    contact: this.newJobForm.contact,
                    key: this.$route.params.key,
                    countryId: this.newJobForm.countryId
                };
                console.log(jobInfoData);
                // return;
                axios.post(this.$store.getters.getAPIBasePath + '/jobresource/public/add/job', jobInfoData)
                    .then(response => {
                        this.showForm = false;
                        //clientid = response.data.id;
                        iziToast.success({
                            title: 'Success',
                            message: this.$config.JobInfoCreSuc,
                            position: 'topRight'
                        });
                        //this.$router.push('/jobs');
                    })
                    .catch(error => {
                        console.log(error);
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.JobCreErr,
                            position: 'topRight'
                        });
                    });
            }
        },
        // updateHTMLText() {
        //     this.newJobForm.jobRequirement = this.$refs.tuiEditor_jobRequirement.invoke('getHtml');
        // },
        getClientContact() {
            if (this.newJobForm.clientId != "") {
                let selfContact = this.newJobForm.contact;
                let self = this;
                axios.get(this.$store.getters.getAPIBasePath + '/clientresource/view/client/' + this.newJobForm.clientId)
                    .then(response => {
                        self.newJobForm.clientName = response.data.clients.clientName;
                        self.newJobForm.accountName = response.data.clients.accountName;
                        let clientContact = response.data.clients.clientContact;
                        if (clientContact != null) {
                            selfContact.contactPerson = clientContact[0].contactName;
                            selfContact.email = clientContact[0].email;
                            selfContact.phone1 = clientContact[0].phoneNumber;
                            selfContact.phone2 = clientContact[0].mobile;
                            selfContact.website = clientContact[0].url;
                            selfContact.addressLine1 = clientContact[0].address;
                            selfContact.cityName = clientContact[0].city;
                            selfContact.stateName = clientContact[0].stateName;
                            selfContact.countryId = clientContact[0].countryId;
                            selfContact.zipCode = clientContact[0].zipCode;
                        }
                    })
                    .catch(error => {

                    });
            }
        },
        resetContactInfo() {
            this.newJobForm.clientId = null;
            this.newJobForm.clientName = "";
            this.newJobForm.accountName = "";
            this.newJobForm.contact.contactPerson = "";
            this.newJobForm.contact.email = "";
            this.newJobForm.contact.phone1 = "";
            this.newJobForm.contact.phone2 = "";
            this.newJobForm.contact.website = "";
            this.newJobForm.contact.addressLine1 = "";
            this.newJobForm.contact.cityName = "";
            this.newJobForm.contact.stateName = "";
            this.newJobForm.contact.countryId = "";
            this.newJobForm.contact.zipCode = "";
        },
        addTechAndSkill() {
            if (typeof this.technology_value !== "object")
                return;
            let self = this;
            var validSkills = [];
            validSkills = this.newJobForm.jobSkill.filter(function(skill) {
                if (skill.technologyId == self.technology_value.id) return skill;
            });
            if (!validSkills.length) {
                iziToast.error({ title: 'Error Occured', message: this.$config.SkillSetErr, position: 'topRight' });
                return false;
            }
            if (validSkills.length < this.newJobForm.jobSkill.length) {
                iziToast.info({ title: 'Information', message: validSkills.length + this.$config.SkillSetSuc, position: 'topRight' });
            }

            var matched = false;
            for (var i = 0; i < this.skillDetails.length; i++) {
                if (this.skillDetails[i].technology.id == this.technology_value.id) {
                    this.skillDetails[i].skills = validSkills;
                    matched = true;
                    break;
                }
            }

            if (!matched)
                this.skillDetails.push({ technology: this.technology_value, skills: validSkills });
            this.technology_value = "";
            this.newJobForm.jobSkill = [];
            this.skills = [];
        },
        addSkillInfo() {
            this.newJobForm.jobSkill = [];
            let self = this;
            console.log(this.skillDetails);
            this.skillDetails.map(function(techAndSkill) {
                self.newJobForm.jobSkill = self.newJobForm.jobSkill.concat(techAndSkill.skills);
            });
        },
        loadState:function(countryId){

             axios.get(this.$store.getters.getAPIBasePath + '/commonresource/statelist?countryId=' + countryId)
                .then(response => {
                    this.stateList = response.data.statelist;
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
