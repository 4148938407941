<template>
    <div >
        <div class="container-fluid" id="quick-search">
            <div class="box-wrapper shadow-sm p-2 mtb-4 bg-white rounded pos-rel">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-6">
                        <h4 class="page-title">Search</h4>
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <ul class="ul-horizontal box-tools text-right mb-0">
                            <li><a @click="searchMore=!searchMore" href="javascribt:;" data-target="main-search" class="boxhide">
                                <span v-if="!searchMore" class="ti-angle-down"></span>
                                <span v-if="searchMore" class="ti-angle-up"></span>
                            </a></li>   
                        </ul>
                    </div>
                </div>
                <form v-if="searchMore" id="main-search" style="margin-top:15px;">
                    <div class="row form-group ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                               <div class="col-md-12 col-sm-12 col-12 lb">
                                   <label>  Interview Title</label>
                               </div>
                               <div class="col-md-12 col-sm-12 col-12">
                                  <div class="input-group">
                                    <input type="text" class="form-control" v-on:keyup.enter="loadRequests(0)"  placeholder="Interview Title" v-model="search.params.title">
                                  </div>
                               </div>
                            </div>  
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                               <div class="col-md-12 col-sm-12 col-12 lb">
                                   <label>Interview Date</label>
                               </div>
                               <div class="col-md-12 col-sm-12 col-12">
                                  <div class="input-group">
                                    <datepicker placeholder="Interview Date" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.interviewDate" ></datepicker>
                                  </div>
                               </div>
                            </div>  
                        </div>


                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                           
                        </div>

                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            
                        </div>

                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                               <div class="col-md-12 col-sm-12 col-12 lb" style="visibility: hidden;">
                                   <label>End Date</label>
                               </div>
                               <div class="col-md-12 col-sm-12 col-12 float-right">
                                  <button type="button" class="btn btn-primary  float-right" style="margin-left: 10px;" @click="loadRequests(1)">Reset</button>
                                  <button type="button" class="btn btn-primary  float-right" style="margin-left: 10px;" @click="loadRequests(0)">Find</button>
                               </div>
                            </div>  
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="container-fluid" id="main-data">
            <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                <div class="row" style="margin-bottom: 15px;">
                    <div class="col-md-12 col-sm-12 col-12">
                        <h4 class="page-title">
                            Interview Requests                                 
                        </h4>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 15px;">
                    <!-- <div class="col-md-12 col-sm-12 col-12">
                        Rows/Page
                        <select v-model="search.limit" @change="loadRequests(0)">
                            <option v-for="(item, key) in this.$store.state.searchlimit" v-bind:key="key" :value="item">{{item}}</option>
                        </select>
                    </div> -->
                    <div class="col-md-12 col-sm-12 col-12">
                        <div class="dropdown">
                            <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>    
                            <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-borderless all-table">
                               <thead>
                                    <tr>                                              
                                         <th class="" scope="col">Interview Title
                                        </th>
                                         <th class="" scope="col">Interview Date
                                        </th> 
                                        <th class="" scope="col">Start Time
                                        </th>
                                        <th class="" scope="col">End Time
                                        </th>
                                        <th class="" scope="col">Assigned By
                                        </th>
                                        <th class="" scope="col"> Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="this.request_data.length==0" ><td colspan="7" class="text-center" >No record found</td></tr>
                                    <tr v-for="req_data in this.request_data" v-bind:key="req_data.id">                                          
                                      <td>{{req_data.title}}</td> 
                                      <td>{{toClientTimeZoneDate(req_data.interviewDate, dateformat) }}</td>
                                      <td>{{toClientTimeZoneDate(req_data.interviewStartTime, timeformat) }}</td>
                                      <td>{{toClientTimeZoneDate(req_data.interviewEndTime, timeformat) }}</td>
                                      <td>{{req_data.assignedBy}}</td>
                                      <td class="actions">                                              
                                          <a title="Accept" href="javascript:void(0);" @click="interviewAccept(req_data.id)" data-toggle="modal" data-target="#AcceptRequest"><span class="ti-check"></span></a>
                                           &nbsp; &nbsp; &nbsp;
                                          <a title="Reject" href="javascript:void(0);" @click="interviewReject(req_data.id)" data-toggle="modal" data-target="#RejectRequest"><span class="ti-close"></span></a>
                                        
                                      </td>
                                    </tr>
                                    
                                 </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="col-md-12">
                        <b-pagination v-if="this.page_conf.total>0" size="md" class='pull-right' :current-page="this.page_conf.currentPage" :total-rows="this.page_conf.total" v-model="page_conf.currentPage" :per-page="this.search.limit" @input="this.loadRequests(0)"></b-pagination>
                    </div> -->
                    <div class="col-md-12">
                        <b-pagination v-if="request_data.length!=0" size="md" class='pull-right' :current-page="browsepagination.currentpage" :total-rows="browsepagination.totalrecords" v-model="browsepagination.currentpage" :per-page="search.limit" @input="loadRequests(0)"></b-pagination>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal " id="RejectRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Interview confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                Are you sure to reject this request?
                </div>
                <div class="modal-footer">                        
                    <button type="button" @click="interviewConfirmation('reject')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                </div>
                </div>
            </div>
        </div>

        <div class="modal " id="AcceptRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Interview confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                Are you sure to accept this request?
                </div>
                <div class="modal-footer">
                    <button type="button" @click="interviewConfirmation('accept')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import iziToast from 'izitoast';

export default {
    data() {
        return {
            browsepagination:this.$store.state.paginationconfig,
            searchlimit:this.$store.state.searchlimit,
            request_data:{},
            page_conf:{
                currentPage:1,
                total:0,
            },
            search:{
                limit:10,
                params:{
                    title:'',
                    interviewDate:''
                }
            },
            assigned_on:{
                date_on:'',
                from_time:'',
                to_time:'',
                id:0
            },
            searchMore:true,
            dateformat:this.$store.state.dateformat,
            timeformat:this.$store.state.timeformat,
        }
    },
    mounted:function(){
        this.loadRequests(0);
        //console.log(this.$store.state.searchlimit);
    },
    methods:{
        interviewReject:function(arg)
        {
            this.assigned_on.id=arg;
        },
        interviewAccept:function(arg)
        {
            this.assigned_on.id=arg;
        },
        loadRequests:function(arg){
            if(arg==1)
            {
                this.search.params.title='';
                this.search.params.interviewDate='';
            }
            axios.post(this.$store.getters.getAPIBasePath+'/candidateresource/list/interviewrequest/'+this.$store.getters.currentUser.profileId +'?page='+this.page_conf.currentPage+'&size='+this.search.limit,this.search.params)
            .then(response => {
                //console.log(response);
                let result = response.data;
                if(!result.request){
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.RecNotFou,
                        position:'topRight'
                    });
                }
                else
                {
                    this.request_data=Array.from(result.request);
                    //this.page_conf=Object.assign({}, result.pagination[0]);
                    this.browsepagination.totalrecords = result.pagination[0].total;
                    this.browsepagination.currentpage = result.pagination[0].currentPage;
                    this.browsepagination.totalpages = result.pagination[0].lastPage;
                }
            });
        },
        interviewConfirmation:function(type)
        {
            
            axios.post(this.$store.getters.getAPIBasePath+'/candidateresource/update/candidate/confirmrequest/'+this.assigned_on.id,{'type':type})
            .then(response => {
                console.log(response);
                 let result = response.data;
                 if(result.status)
                 {
                     iziToast.success({
                        title: 'Confirmation Request',
                        message: result.message,
                        position:'topRight'
                    });
                    this.loadRequests(0);
                 }
                 else
                 {
                     iziToast.error({
                        title: 'Confirmation Request',
                        message: this.$config.ReqConfirmErr,
                        position:'topRight'
                    });
                 }                
            });
            return true;
        },
        changeLimit(newlimit){
            this.search.limit = newlimit;
            this.$store.commit('updateActiveSearchLimit',this.search.limit);
            this.loadRequests(0);
        }
    }
}
</script>