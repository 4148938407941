import { render, staticRenderFns } from "./CounterOfferSettings.vue?vue&type=template&id=ef2ef158&scoped=true"
import script from "./CounterOfferSettings.vue?vue&type=script&lang=js"
export * from "./CounterOfferSettings.vue?vue&type=script&lang=js"
import style0 from "./CounterOfferSettings.vue?vue&type=style&index=0&id=ef2ef158&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef2ef158",
  null
  
)

export default component.exports