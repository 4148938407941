<template>
    <div class="card">
        <div class="card-header p-2">Resume Upload</div>
        <div class="card-body p-2">
            <div class="basic-block clone-preference">
                <div class="row ten-columns pos-relative clone filled2">
                    <div class="col-md-6 col-sm-6 col-lg=6 mb-10-mr">
                        <div class="row ten-columns" :class="{ 'noaddedmore' : resumeinfo.viewonly == 1}">
                            <div class="col-md-12 col-sm-12 col-12">
                                <input class="upload" ref="addcandidate_resumeattach_resume" id="candidateadd_resume_fileupload" type="file" accept=".doc,.docx,.pdf" v-on:change="handleFileUpload"></input>
                            </div>
                            <div class="col-md-12 col-sm-12 col-12">
                            	<span>Supports only PDF, Doc., Docx file types</span>
                            </div>
                        </div>
                        <div class="row ten-columns" v-if="resumeinfo.filename!=undefined">
                        	<a :href="resumeinfo.fileurl" target="_blank">{{resumeinfo.filename}}</a>
                        	<span v-if="resumeinfo.filename =='' ">No resume found</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
	export default {
		props:{
			resumeinfo:Object
		},
		data(){
			return {
				allowedfiletypes:['pdf','doc','docx']
			}
		},
		methods:{
			handleFileUpload(){
				let fileelement = this.$refs.addcandidate_resumeattach_resume.files[0];
				let ext = fileelement.name.substring(fileelement.name.lastIndexOf('.') + 1);
				if($.inArray(ext, this.allowedfiletypes) !== -1){
					this.resumeinfo.file = this.$refs.addcandidate_resumeattach_resume.files[0];
				}
				else{
					alert('Selected file extension is not supported');
					$("#candidateadd_resume_fileupload").val('');
					this.resumeinfo.file = '';
				}
			}
		}
	}
</script>