<template>
	<div :class="{ 'viewonly' : personalinfo.viewonly == 1}">
		<div class="card">
            <div class="card-header p-2">
                <div class="row">
                    <div class="col-md-6">
                        Primary Information
                    </div>
                    <div class="col-md-6 text-right">
                        {{personalinfo.candidateCode}}
                    </div>
                </div>
            </div>

            <!-- <div class="card-header p-2">Primary Information</div> -->
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-4 col-sm-6">
                            <div class="row">
                                <div class="col-md-6">
                                   <label class="font-weight-bold">First Name </label> 
                                </div>
                                <div class="col-md-6">
                                    <label>{{personalinfo.firstName}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="row">
                                <div class="col-md-6">
                                   <label class="font-weight-bold">Middle Name </label> 
                                </div>
                                <div class="col-md-6">
                                    <label>{{personalinfo.midleName}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="row">
                                <div class="col-md-6 pl-0-pr">
                                   <label class="font-weight-bold">Last Name </label> 
                                </div>
                                <div class="col-md-6">
                                    <label>{{personalinfo.lastName}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ten-columns">
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12 lb">
                                    <label class="font-weight-bold">Industry</label>
                                </div>
                                <div class="col-md-6 col-sm-12 col-12 input-group">
                                    <label>{{personalinfo.industryName}}</label>
                                </div>
                            </div>  
                        </div>
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12 lb">
                                   <label class="font-weight-bold">Position</label>
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <div class="input-group">
                                      <label>{{personalinfo.position}}</label>  
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12 lb">
                                   <label class="font-weight-bold">Years of Experience</label>
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <div class="input-group">
                                      <label>{{personalinfo.experience}}</label> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ten-columns">
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12 lb">
                                   <label class="font-weight-bold">Location</label>
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <div class="input-group">
                                        <label>{{personalinfo.location}}</label> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                               <div class="col-md-6 col-sm-12 col-12 lb">
                                   <label class="font-weight-bold">Referred By</label>
                               </div>
                               <div class="col-md-6 col-sm-12 col-12">
                                    <div class="input-group">
                                      <label>{{personalinfo.referredBy}}</label> 
                                    </div>
                               </div>
                            </div>  
                        </div>
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                               <div class="col-md-6 col-sm-12 col-12 lb">
                                   <label class="font-weight-bold">Recruiter</label>
                               </div>
                               <div class="col-md-6 col-sm-12 col-12">
                                    <label>{{personalinfo.recruiterName}}</label>
                               </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">Educational Information</div>
            <div class="card-body p-2">
                <div class="basic-block">
                  <div class="row ten-columns">
                    <div class="col-sm-12">
                        <div class="table-responsive">
                          <table class="table table-borderless all-table">
                            <thead>
                              <tr>
                                <th class="" scope="col">Name Of Institution</th>
                                <th class="" scope="col">Degree</th>
                                <th class="" scope="col">Specialization</th>
                                <th class="" scope="col">Year</th>
                                <th class="" scope="col">City</th>
                                <th class="" scope="col">State</th>
                                <th class="" scope="col">Country</th>
                                <th class="" scope="col"></th>
                              </tr>
                            </thead>
                            <tbody class="cloned_tr">
                              <tr v-for="(info, index) in personalinfo.educationinfo">
                                  <td>
                                      <label>{{personalinfo.educationinfo[index].schoolName}}</label> 
                                  </td>
                                  <td>
                                      <label>{{personalinfo.educationinfo[index].degree}}</label> 
                                  </td>
                                  <td>
                                      <label>{{personalinfo.educationinfo[index].specialization}}</label> 
                                  </td>
                                  <td >
                                      <label>{{personalinfo.educationinfo[index].year}}</label> 
                                  </td>
                                  <td >
                                      <label>{{personalinfo.educationinfo[index].city}}</label> 
                                  </td>
                                  <td >
                                      <label>{{personalinfo.educationinfo[index].stateName}}</label> 
                                  </td>
                                  <td>
                                      <label>{{personalinfo.educationinfo[index].country.name}}</label> 
                                  </td>
                                  <td>
                                  </td>
                              </tr>
                              <tr :class="{ 'norecordfound' : personalinfo.educationinfo.length}" >
                                  <td colspan="8" align="center">No item found</td>
                              </tr>                              
                            </tbody>
                          </table>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header p-2">Language(s)</div>
            <div class="card-body p-2">
  				<div class="basic-block">
  					<div class="row ten-columns">
  						<div class="col-md-12">
  							<div class="row">
  								<div class="col-md-12 col-sm-12 col-xs-12 required-field-block">
  									<span v-for="(info, index) in personalinfo.candidateLanguage" v-bind:key="index"><span v-if="index>0">, </span>{{info.languages.text}}</span>
  								</div>
  							</div>
  						</div>
  					</div>
  				</div>
            </div>
        </div>

        <div class="card">
            <div class="card-header p-2">Objective</div>
            <div class="card-body p-2">
  				<div class="basic-block">
  					<div class="row ten-columns">
  						<div class="col-md-12">
  							<div class="row">
  								<div class="col-md-12 col-sm-12 col-xs-12 required-field-block">
  									<div class="form-control">{{personalinfo.notes}}</div>
  								</div>
  							</div>
  						</div>
  					</div>
  				</div>
            </div>
        </div>

        
	</div>
</template>

<script>
	export default {
        props: {
            personalinfo: Object,
        },
        mounted(){
            console.log('--------------');
            console.log(this.personalinfo);
        }
	}
</script>