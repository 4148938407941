var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"job reports"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-sm-12 p-10"},[_c('div',{staticClass:"recruiter settings mt-10",attrs:{"id":"recruiter-settings"}},[_c('div',{staticClass:"box-wrapper shadow-sm bg-white rounded pos-rel"},[_vm._m(0),_c('div',{staticClass:"row col-md-12 py-3 text-left"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"row col-md-12 ml-0 left-bar-tab",on:{"click":function($event){return _vm.$emit('setSubTab',1)}}},[_vm._m(1),_vm._m(2)])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"row col-md-12 ml-0 left-bar-tab",on:{"click":function($event){return _vm.$emit('setSubTab',2)}}},[_vm._m(3),_vm._m(4)])]),(false)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"row col-md-12 ml-0 left-bar-tab",on:{"click":function($event){return _vm.$emit('setSubTab',3)}}},[_vm._m(5),_vm._m(6)])]):_vm._e()])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4 header mx-0 mb-n1"},[_c('div',{staticClass:"row col-md-12 mx-0 px-0"},[_c('div',{staticClass:"col-md-6 col-sm-6 col-6"},[_c('h4',{staticClass:"page-title text-left"},[_vm._v("User Activities")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 p-3"},[_c('img',{attrs:{"src":require("@/assets/icons/applicant-hire-ratio.png"),"width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-8 align-self-center"},[_c('h6',[_c('b',[_vm._v("Activity Log")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 p-3"},[_c('img',{attrs:{"src":require("@/assets/icons/applicant-hire-ratio.png"),"width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-8 align-self-center"},[_c('h6',[_c('b',[_vm._v("Resumes loaded by Recruiters")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 p-3"},[_c('img',{attrs:{"src":require("@/assets/icons/applicant-hire-ratio.png"),"width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-8 align-self-center"},[_c('h6',[_c('b',[_vm._v("Bulk Resume Log")])])])
}]

export { render, staticRenderFns }