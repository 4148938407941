<template>
    <div id="main" class="mt-15 d-height">
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a @click="searchMore = !searchMore" href="javascript:;" data-target="main-search"
                                        class="boxhide"><span :class="searchMore ? 'ti-angle-up' : 'ti-angle-down'"></span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <form id="main-search" v-if="searchMore" v-on:submit.prevent="">
                        <div class="row form-group ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Company Name</label>
                                    </div>
                                    <!-- <div class="col-md-12 col-sm-12 col-12">
                                        <input type="text" v-model="search.params.accountName" v-on:keyup.enter="findClientAccounts" class="form-control">
                                    </div> -->

                                    <div class="col-md-12 col-sm-12 col-12 ">
                                        <v-select v-model="search.params.accountName" :options="accountOptions"
                                            @input="findClientAccounts" placeholder="Select or type an Company"></v-select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr pl-0">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;">Status</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">

                                        <button type="button" @click="findClientAccounts"
                                            class="btn btn-primary btn-sm float-left"
                                            style="margin-left: 10px;">Find</button>
                                        <button type="button" @click="resetClientsSearch"
                                            class="btn btn-primary btn-sm float-left"
                                            style="margin-left: 10px;">Reset</button>
                                    </div>

                                </div>


                            </div>
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr pl-0 ">

                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr pl-0 ">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Start Date</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <input type="date" v-model="startDate" class="form-control" placeholder="Start Date"
                                            style="margin-right: 10px;">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr pl-0">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>End Date</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <input type="date" v-model="endDate" class="form-control" placeholder="End Date"
                                            style="margin-right: 10px;">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 col-sm-6 col-12 mb-10-mr pl-0">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;">Status</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <button type="button" @click="filterAndFetchAccounts"
                                            class="btn btn-primary btn-sm float-left"
                                            style="margin-left: 10px;">Find</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="load-indicator"></div>
                </div>
            </div>
            <div class="container-fluid" id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-12 col-sm-12 col-12">
                            <h4 class="page-title">Client Activity Summary
                                <span
                                    style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{ browsepagination.totalrecords }}
                                    - Total)</span>
                                <span
                                    style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                    <div class="dropdown">
                                        <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href=""
                                            aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                        <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start"
                                            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                            <a v-for="(value, index) in searchlimit" class="dropdown-item"
                                                href="javascript:void(0);" @click="changeLimit(value)">{{ value }}</a>
                                        </div>
                                    </div>
                                </span>
                                <span
                                    style="display: inline-block; padding: 5px 0px; color:#000; font-size:15px; float: right;">

                                    <div v-if="formattedStartDate || formattedEndDate">
                                        <span style="color: #777;">StartDate=</span>{{ formattedStartDate }}
                                        <span style="color: #777;">|| End Date=</span>{{ formattedEndDate }}
                                    </div>
                                </span>
                            </h4>

                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col"></th>
                                            <th class="" scope="col">Client Account ID</th>
                                            <th class="" scope="col">Company Name</th>
                                            <th class="" scope="col">Company Type</th>
                                            <th class="" scope="col">Activity Count</th>


                                        </tr>
                                    </thead>
                                    <tbody id="table-length">
                                        <tr v-if="clientAccountList.length == 0 && elements.loadClientAccounts == true">
                                            <td colspan="7" align="center">No Clients found</td>
                                        </tr>
                                        <template v-for="(clientAccountInfo, index) in clientAccountList">
                                            <tr>

                                                <td v-if="clientAccountInfo.id == 0">

                                                </td>
                                                <td v-else>
                                                    <a href="javascript:" class="expand" data-targetopen="panel1">
                                                        <span @click="filterdate(clientAccountInfo.id)"><i
                                                                :class="(currentIndex == clientAccountInfo.id) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
                                                        </span>
                                                    </a>
                                                </td>

                                                <td>{{ clientAccountInfo.tt_Client_Account_Id }}</td>
                                                <td class="">{{ clientAccountInfo.company_Name }}</td>
                                                <td class="">{{ clientAccountInfo.accountTypeName }}</td>
                                                <td class="">{{ clientAccountInfo.activity }}</td>

                                            </tr>
                                            <tr v-if="currentIndex == clientAccountInfo.id">
                                                <td colspan="7">
                                                    <div class="table-responsive">
                                                        <div style="height: 200px; overflow-y: scroll;">
                                                            <table class="table table-borderless all-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="" scope="col">Action Type ID</th>
                                                                        <th class="" scope="col">User name</th>

                                                                        <th class="" scope="col">Action</th>
                                                                        <!-- <th class="" scope="col">Action Count</th> -->
                                                                        <!-- <th class="">Recruiter Name</th> -->
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="activityinfo in clientactivitylist"
                                                                        :key="activityinfo.id" :id="activityinfo.id">
                                                                        <td>{{ activityinfo.action_Type_Id }}</td>
                                                                        <td>{{ activityinfo.userName }}</td>

                                                                        <td>{{ activityinfo.action }}</td>
                                                                        <!-- <td>{{ activityinfo.action_Count }}</td> -->
                                                                        <!-- <td>{{ activityinfo.recruiterName }}</td> -->

                                                                    </tr>
                                                                    <tr v-if="clientactivitylist.length == 0">
                                                                        <td style="border:0px;" colspan="7" align="center">
                                                                            No Data Found
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <b-pagination v-if="clientAccountList.length != 0" size="md" class='pull-right'
                                :current-page="browsepagination.currentpage" :total-rows="browsepagination.totalrecords"
                                v-model="browsepagination.currentpage" :per-page="browsepagination.rowsPerPage"
                                @input="loadClientAccounts()"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div>
                    <img alt="images" class="loader-img-height" src="/images/loading.gif">
                </div>
            </template>
        </b-overlay>
        <Dialog id="resendMail" :onYes="resendmail" :returnParams="resendParams" title="Confirmation"
            message="Are you sure to resend the Activation mail?" />
        <!-- <Dialog id="removeClient" :onYes="delete_Client" :returnParams="delClientParam" title="Deactivate Confirmation" message="Are you sure to deactivate ?" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            showOverlay: false,
            clientactivitylist: [],
            clientAccountList: [],
            accountOptions: [],
            startDate: '',
            endDate: '',
            filterdateby: 'filter by',
            formattedStartDate: '',
            formattedEndDate: '',
            browsepagination: {
                totalrecords: 0,
                rowsPerPage: 10,
                currentpage: 1
            },

            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            elements: {
                showadvancedsearch: false,
                loadingClientAccount: false,
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    company_Name: ''
                },

            },
            resendParams: {
                index: -1,
                id: 0
            },
            coldisplay: {
                accountName: true,
            },
            delClientParam: {
                index: -1,
                id: 0
            },
            searchMore: false,
            currentIndex: null,
        }
    },
    watch: {
        'showOverlay': {
            handler: function (value) {
                if (value == true) {
                    $('#globalapploader').css('visibility', 'hidden');
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.loadClientAccounts();



    },
    methods: {



        filterAndFetchAccounts() {
            this.filterdate();
            this.loadClientAccounts();
        },

        resetClientsSearch() {
            this.search.params = {
                accountName: "",
            };
            this.loadClientAccounts();
        },
        async loadClientAccounts() {
            this.showOverlay = true;
            await this.getClientAccounts();
            // await this.getDetialActivity();
            await this.getByClientName();

            this.showOverlay = false;
        },
        findClientAccounts() {
            this.browsepagination.currentpage = 1;
            this.loadClientAccounts();
            this.getClientAccounts();
        },

        filterdate(id) {
            this.showOverlay = true;

            if (this.currentIndex == id) {
                this.currentIndex = null;
            } else {
                this.currentIndex = id;
            }

            this.formattedStartDate = this.startDate ? new Date(this.startDate).toISOString().split('T')[0] : '';
            this.formattedEndDate = this.endDate ? new Date(this.endDate).toISOString().split('T')[0] : '';

            const startDate = this.formattedStartDate ? this.formattedStartDate : new Date().toISOString().split('T')[0];
            const endDate = this.formattedEndDate ? this.formattedEndDate : new Date().toISOString().split('T')[0];

            const requestbody={
                "ttClientAccountId": id,
                "startDate": startDate,
                "endDate": endDate
            }

            return axios.post(this.$store.getters.getAPIBasePath + '/incidentresource/incident/clientactivityfilter',requestbody)
                .then(response => {
                    this.showOverlay = false;
                    this.detialactive = response.data.data;
                    this.clientactivitylist = this.detialactive;
                    this.elements.loadClientAccounts = true;
                    console.log("detail data is coming" + this.detialactive.length);
                    console.log(id);
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.log("detials activity is not comming" + error)
                })
        },
        getClientAccounts() {
            this.showOverlay = true;
            let idArray = [];
            this.formattedStartDate = this.startDate ? new Date(this.startDate).toISOString().split('T')[0] : '';
            this.formattedEndDate = this.endDate ? new Date(this.endDate).toISOString().split('T')[0] : '';

            // Check if formattedStartDate and formattedEndDate are empty strings
            const startDate = this.formattedStartDate ? this.formattedStartDate : new Date().toISOString().split('T')[0];
            const endDate = this.formattedEndDate ? this.formattedEndDate : new Date().toISOString().split('T')[0];
            let requestBody = {
                "startdate": startDate,
                "enddate": endDate,
                "accountName": ""
            };
            if (this.search.params) {
                requestBody = { ...requestBody, ...this.search.params };
            }

            if (this.search.params) {
                requestBody = { ...requestBody, ...this.search.params };
            }
            return axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/client/activity/list?page=' + this.browsepagination.currentpage + '&size=' + this.browsepagination.rowsPerPage, requestBody)
                .then(response => {
                    let result = response.data;
                    console.log(result)
                    this.clientAccountList = result.clientAccountList;
                    this.browsepagination.totalrecords = result.pagination[0].total;
                    this.browsepagination.currentpage = result.pagination[0].currentPage;
                    this.browsepagination.totalpages = result.pagination[0].lastPage;
                    this.elements.loadClientAccounts = true;
                    this.clientAccountList.forEach(item => {
                        // console.log("Client account user id is " + item.id);
                        idArray.push(item.id);
                    });
                    console.log("Array of ids:", idArray);
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.log(error);
                });
        },
        getByClientName() {
            this.showOverlay = true;

            let requestBody = {
                "accountName": ""
            };

            if (this.search.params) {
                requestBody = { ...requestBody, ...this.search.params };
            }

            if (this.search.params) {
                requestBody = { ...requestBody, ...this.search.params };
            }

            return axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/client/list/droupdown', requestBody)
                .then(response => {
                    let result = response.data;
                    this.accountOptions = result.clientList.map(account => account.account_Name);
                    console.log("company names: ", this.accountOptions);
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.log("company list error"+error);
                });
        },

        getDetialActivity(id) {
            if (this.currentIndex == id) {
                this.currentIndex = null;
            } else {
                this.currentIndex = id;
            }

            return axios.post(this.$store.getters.getAPIBasePath + '/incidentresource/incident/clientactivity', {
                "ttClientAccountId": id
            })
                .then(response => {
                    this.detialactive = response.data.data;
                    this.clientactivitylist = this.detialactive;
                    this.elements.loadClientAccounts = true;
                    console.log("detail data is coming" + this.detialactive.length);
                    console.log(id);
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.log("detials activity is not comming" + error)
                })
        },
        changeLimit(newlimit) {
            this.browsepagination.currentpage = 1;
            this.browsepagination.rowsPerPage = newlimit;
            this.loadClientAccounts();  
        },
        resendactMail(userId) {
            this.resendParams.id = userId;
        },
        resendmail() {
            var Id = this.resendParams.id;
            axios.post(this.$store.getters.getAPIBasePath + '/userresource/resend/activationemail/' + Id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadClientAccounts();
                    }
                })
                .catch(error => {
                    console.log(error.data);
                    this.unAuthorized(error);
                });
        }, 
    }
}
</script>