<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model">
                <div class="row no-gutters">
                    <b-tabs v-model="currentClientTab" small card class="tab-list driver-tab-list">
                        <b-tab title="Client Details">
                            <ClientInfo :clientinfo="clientinfo" ref="clientinfocomponent"></ClientInfo>
                        </b-tab>
                    </b-tabs>
                </div>
                <div class="mt-3">
                    <div class="row">
                        <div class="col-sm-12">
                            <a href="Javascript:void(0);" @click="saveCurrentTab" class="btn btn-primary btn-sm float-right">Save</a>
                            <a href="Javascript:void(0);" class="btn btn-secondary btn-sm mr-2 float-right">
                                <router-link :to="{ name: 'viewclients' }" class="text-white">Cancel</router-link>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ClientInfo from './blocks/addclient/ClientInfo';
export default {
    components: {
        ClientInfo,

    },
    data() {
        return {
            currentClientTab: 0,
            maxtabs: 3,
            clientinfo: {
                clientCode: '',
                clientName: '',
                dunsNumber: '',
                accountName: '',
                url: '',
                status: '',
                description: ''
            },
            addressinfo: {
                physicaladdress: {
                    clientId: 2,
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "US",
                    faxNumber: "",
                    phoneNumber: "",
                    sameAsAbove: ""
                },
                billingaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "US",
                    faxNumber: "",
                    sameAsAbove: ""
                }
            },
            clientcontactinfo: {

            },
            contractdetailsinfo: {

            }

        }
    },
    mounted() {
        this.getClientCode();

    },
    methods: {
        getClientCode() {
            axios.post(this.$store.getters.getAPIBasePath + '/commonresource/autoincrementcode/client')
                .then(response => {
                    if (response.data.status) {
                        this.clientinfo.clientCode = response.data.code;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },

        saveCurrentTab() {
            switch (this.currentClientTab) {
                case 0:
                    //client info validation
                    if (this.$refs.clientinfocomponent.validateClientInfo()) {
                        this.saveClientInfo();
                    }
                    break;
                default:
                    break;
            }
        },
        saveClientInfo() {

            axios.post(this.$store.getters.getAPIBasePath + '/clientresource/add/client', this.clientinfo)
                .then(response => {
                    //clientid = response.data.id;
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CliInfoCreSuc,
                        position: 'topRight'
                    });

                    this.$store.dispatch("reloadClient").then(() => {
                        console.log('Refresh Client');
                    });

                    this.$router.push('/clients/edit/' + response.data.id);
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliCreErr,
                        position: 'topRight'
                    });
                });
        },
    }
}

</script>
