<template>
    <div>
        <div class="card">
            <div class="card-header p-2"> Physical Address</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row col-md-12 my-4">
                        <div class="col-md-6">
                            <div class="table-responsive">
                                <table class="table table-striped table-borderless s_table compare-table">
                                    <tbody>                                        
                                        <tr>
                                            <td width="50%"><i class="fa fa-phone pr-2" aria-hidden="true"></i>{{addressinfo.physicaladdress.phoneNumber}}</td>
                                        </tr>
                                        <tr>
                                            <td><i class="ti-location-pin pr-2"></i>{{addressinfo.physicaladdress.addressLine1}} {{addressinfo.physicaladdress.addressLine2}} {{addressinfo.physicaladdress.cityName}} {{addressinfo.physicaladdress.stateName}} {{getCountryName(addressinfo.physicaladdress.countryId)}} - {{addressinfo.physicaladdress.zipCode}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">
                Billing Address                
            </div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row col-md-12 my-4">
                        <div class="col-md-6">
                            <div class="table-responsive">
                                <table class="table table-striped table-borderless s_table compare-table">
                                    <tbody>
                                        <tr>
                                            <td><i class="ti-location-pin pr-2"></i>{{addressinfo.billingaddress.addressLine1}} {{addressinfo.billingaddress.addressLine2}} {{addressinfo.billingaddress.cityName}} {{addressinfo.billingaddress.stateName}} {{getCountryName(addressinfo.billingaddress.countryId)}} - {{addressinfo.billingaddress.zipCode}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        addressinfo: Object,
    },
    data() {
        return {
            countrieslist: this.$store.getters.getCountries,
            usstates: this.$store.getters.getUSStates,
        }
    },
    methods: {
        getCountryName: function(countryCode) {
            if (!countryCode)
                return "";
            let country = this.countrieslist.filter(obj => {
                return obj.country_Id === countryCode;
            });
            return country[0].name;
        },
        getStateName: function(stateId) {
            if (!stateId)
                return "";
            let state = this.usstates.filter(obj => {
                return obj.id === stateId;
            });
            return state[0].stateName;
        }
    }
}

</script>
