<template>
    <div>
        <div class="card">
            <div class="card-header p-2">Project Details</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col">Project Title</th>
                                            <th class="videoprofile" scope="col"> Client</th>
                                            <th class="recruiter" scope="col">Technology/Skills</th>
                                            <th class="title" scope="col">Start Date</th>
                                            <th class="state" scope="col">End Date</th>
                                            <th class="phone" scope="col">Role</th>
                                            <th class="lastnote" scope="col">Description</th>
                                            <th class="" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="cloned_tr">
                                    	<tr v-for="(info, index) in projectinfo" :class="{ 'viewonly' : projectinfo.viewonly == 1}">
                                    		<td>
                                    			<input v-model="projectinfo[index].projectTitle" maxlength="45" class="form-control" type="text"/>
                                    		</td>
                                            <td class="lastnote">
                                            	<input v-model="projectinfo[index].client" maxlength="250" class="form-control" type="text"/>
                                            </td>
                                            <td class="recruiter">
                                            	<div class="position-relative" >                                                    
                                                    <input class="form-control" maxlength="500" type="text" v-model="projectinfo[index].technology"/>
                                                    <a href="javascript:;" @click="showBoxSkill(index)" data-toggle="modal" data-target="#SkillShow">
                                                        <span class="search-addon" ><span class="ti-flickr srcicn"></span> </span>  
                                                    </a>
                                                </div>
                                            </td>
                                            <td class="title">
                                                <datepicker v-model="projectinfo[index].projectStartDate" maxlength="10" placeholder="Start Date" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy"></datepicker>
                                            </td>
                                            <td class="specialityunit">
                                                <datepicker v-model="projectinfo[index].projectEndDate" maxlength="10" placeholder="End Date" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy"></datepicker>
                                            </td>
                                            <td class="currentcity">
                                                <div class="position-relative" >                                                    
                                                    <input class="form-control" maxlength="100" type="text" v-model="projectinfo[index].role"/>
                                                    <a href="javascript:;" @click="showBoxRole(index)" data-toggle="modal" data-target="#RoleShow">
                                                        <span class="search-addon" ><span class="ti-flickr srcicn"></span> </span>  
                                                    </a>
                                                </div>
                                            </td>
                                            <td class="state">
                                                <div class="position-relative" >                                                    
                                                    <input class="form-control" maxlength="1000" type="text" v-model="projectinfo[index].projectDescription"/>
                                                    <a href="javascript:;" @click="showBox(index)" data-toggle="modal" data-target="#ProjectDescriptionShow">
                                                        <span class="search-addon" ><span class="ti-flickr srcicn"></span> </span>  
                                                    </a>
                                                </div>
                                               <!-- <input v-model="projectinfo[index].projectDescription" maxlength="1000" class="form-control" type="text"/> -->
                                            </td>
                                            <td>
                                                <a class="removemoretextbox" :class="{ 'removeaddbtn' : projectinfo[index].viewonly == 1}" href="Javascript:void(0);" data-toggle="modal" data-target="#removeProj" @click="confirmProj(index,0)"><span class="ti-trash"></span></a>
                                            </td>
                                    	</tr>
                                        <tr :class="{ 'noaddedmore fill' : projectinfo.viewonly == 1}">
                                            <td>
                                            	<input id="addcandidate_projectinfo_add_title" maxlength="45" class="form-control" type="text" v-model="newprojectinfo.projectTitle"/>
                                            </td>
                                            <td class="lastnote">
                                            	<input class="form-control" type="text" maxlength="200" v-model="newprojectinfo.client"/>
                                            </td>
                                            <td class="recruiter">
                                            	<div class="position-relative" >                                                    
                                                    <input class="form-control" maxlength="100" type="text" v-model="newprojectinfo.technology"/>
                                                    <a href="javascript:;" @click="showBoxSkill(-1)" data-toggle="modal" data-target="#SkillShow">
                                                        <span class="search-addon" ><span class="ti-flickr srcicn"></span> </span>  
                                                    </a>
                                                </div>
                                            </td>
                                            <td class="title">
                                                 <datepicker v-model="newprojectinfo.projectStartDate" maxlength="10" placeholder="Start Date" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy"></datepicker>
                                            </td>
                                            <td class="specialityunit">
                                                <datepicker v-model="newprojectinfo.projectEndDate" maxlength="10" placeholder="End Date" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy"></datepicker>
                                            </td>
                                            <td class="currentcity">
                                                <div class="position-relative" >                                                    
                                                    <input class="form-control" maxlength="100" type="text" v-model="newprojectinfo.role"/>
                                                    <a href="javascript:;" @click="showBoxRole(-1)" data-toggle="modal" data-target="#RoleShow">
                                                        <span class="search-addon" ><span class="ti-flickr srcicn"></span> </span>  
                                                    </a>
                                                </div>
                                            </td>
                                            <td class="state ">
                                                <div class="position-relative" >                                                    
                                                    <input class="form-control" maxlength="1000" type="text" v-model="newprojectinfo.projectDescription"/>
                                                    <a href="javascript:;" @click="showBox(-1)" data-toggle="modal" data-target="#ProjectDescriptionShow">
                                                        <span class="search-addon" ><span class="ti-flickr srcicn"></span> </span>  
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <a class="addmoretextbox" href="Javascript:void(0);" @click="addNewProjectInfo"><span class="ti-plus"></span></a>
                                            </td>
                                        </tr>
                                        <tr :class="{ 'norecordfound' : projectinfo.viewonly == 1}" style="display: none;"><td colspan="8" align="center">No item found</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal" id="ProjectDescriptionShow" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Project Description</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <textarea class="form-control more-text-area" v-model="tempProjectDescription" ></textarea>
                    </div>
                    <div v-if="projectinfo.viewonly != 1" class="modal-footer">                                            
                        <button type="button" @click="updateProjDes()" class="btn btn-primary" data-dismiss="modal">OK</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="RoleShow" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Project Role</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <textarea class="form-control more-text-area" v-model="tempProjectRole" ></textarea>
                    </div>
                    <div v-if="projectinfo.viewonly != 1" class="modal-footer">                                            
                        <button type="button" @click="updateProjRole()" class="btn btn-primary" data-dismiss="modal">OK</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="SkillShow" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Project Skill</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <textarea class="form-control more-text-area" v-model="tempProjectSkill" ></textarea>
                    </div>
                    <div v-if="projectinfo.viewonly != 1" class="modal-footer">                                            
                        <button type="button" @click="updateProjSkill()" class="btn btn-primary" data-dismiss="modal">OK</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="removeProj" :onYes="deleteProj" :returnParams="delProjParam" />

    </div>
</template>

<script>
	export default{
        props: {
            projectinfo: Array,
        },
		data(){
			return {
				newprojectinfo:{
					projectTitle:"",
					client:"",
					technology:"",
					projectStartDate:"",
					projectEndDate:"",
					role:"",
					projectDescription:"",				
                    is_current:false
                },
                tempProjectDescription : '',
                tempProjectDescriptionId : -1,
                tempProjectRole : '',
                tempProjectRoleId : -1,
                tempProjectSkill:'',
                tempProjectSkillId : -1,
                delProjParam:{
                    index:-1,
                    id:0
                }
			}
		},
		methods:{
			resetNewForm(){
				this.newprojectinfo = {
					projectTitle:"",
					client:"",
					technology:"",
					projectStartDate:"",
					projectEndDate:"",
					role:"",
					projectDescription:"",				
                    is_current:false
				};
				$('#addcandidate_projectinfo_add_title').focus();
			},
            removeInfo(index){
                this.projectinfo.splice(index,1);
            },
			addNewProjectInfo(){
                this.newprojectinfo.projectTitle = this.newprojectinfo.projectTitle.trim();
                this.newprojectinfo.client = this.newprojectinfo.client.trim();
                this.newprojectinfo.technology = this.newprojectinfo.technology.trim();
                this.newprojectinfo.role = this.newprojectinfo.role.trim();
                this.newprojectinfo.projectDescription = this.newprojectinfo.projectDescription.trim();
                if(this.newprojectinfo.projectTitle!="" || this.newprojectinfo.client!="" || this.newprojectinfo.technology!=""  || this.newprojectinfo.role!="" || this.newprojectinfo.projectDescription!=""){
                    this.projectinfo.push(this.newprojectinfo);
                    this.resetNewForm();
                }
            },
            showBox: function(arg)
            {
                this.tempProjectDescriptionId = arg;
                if(arg<0)
                {
                    this.tempProjectDescription = this.newprojectinfo.projectDescription;                    
                    //console.log(this.newprojectinfo.projectDescription);
                }
                else
                {
                    this.tempProjectDescription = this.projectinfo[arg].projectDescription;                    
                    //console.log(this.projectinfo[arg].projectDescription);
                }                
            },
            updateProjDes:function()
            {
                if(this.tempProjectDescriptionId < 0)
                {
                    this.newprojectinfo.projectDescription = this.tempProjectDescription;
                }
                else
                {
                    this.projectinfo[this.tempProjectDescriptionId].projectDescription = this.tempProjectDescription;
                }
                // console.log(this.tempProjectDescriptionId);
                // console.log(this.tempProjectDescription);
            },
            showBoxRole: function(arg)
            {
                this.tempProjectRoleId = arg;
                if(arg<0)
                {
                    this.tempProjectRole = this.newprojectinfo.role;                    
                    //console.log(this.newprojectinfo.projectDescription);
                }
                else
                {
                    this.tempProjectRole = this.projectinfo[arg].role;                    
                    //console.log(this.projectinfo[arg].projectDescription);
                }                
            },
            updateProjRole:function()
            {
                if(this.tempProjectRoleId < 0)
                {
                    this.newprojectinfo.role = this.tempProjectRole;
                }
                else
                {
                    this.projectinfo[this.tempProjectRoleId].role = this.tempProjectRole;
                }
                // console.log(this.tempProjectDescriptionId);
                // console.log(this.tempProjectDescription);
            },
            showBoxSkill: function(arg)
            {
                this.tempProjectSkillId = arg;
                if(arg<0)
                {
                    this.tempProjectSkill = this.newprojectinfo.technology;                    
                    //console.log(this.newprojectinfo.projectDescription);
                }
                else
                {
                    this.tempProjectSkill = this.projectinfo[arg].technology;                    
                    //console.log(this.projectinfo[arg].projectDescription);
                }                
            },
            updateProjSkill:function()
            {
                if(this.tempProjectSkillId < 0)
                {
                    this.newprojectinfo.technology = this.tempProjectSkill;
                }
                else
                {
                    this.projectinfo[this.tempProjectSkillId].technology = this.tempProjectSkill;
                }
                // console.log(this.tempProjectDescriptionId);
                // console.log(this.tempProjectDescription);
            },
            deleteProj:function(arg)
            {
                this.projectinfo.splice(arg.index,1);
            },
            confirmProj:function(arg1, arg2)
            {
                this.delProjParam.index=arg1;
                this.delProjParam.id=arg2;
            }
		}
	}
</script>