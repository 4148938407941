<template>
    <div id="main">
        <div class="container-fluid" id="quick-search">
            <div class="row h-50">
                <div class="col-sm-12 h-100 d-table">
                    <div class="d-table-cell align-middle login_div">
                        <div class="col-md-4 offset-md-4">
                            <div class="interviewer-register-block">
                                <div class="box-wrapper shadow-sm p-5 mb-4 bg-white rounded pos-rel">
                                    <h3 class="text-color text-center" style="text-transfomargin-top:10px">Reset Password</h3>
                                    <form>
                                        <div class="row pt-5">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="input-group required-field-block">
                                                                <input type="password" name="Password" placeholder="New Password" maxlength="45" size="30" v-model="activation.password" class="form-control box-none">
                                                            </div>
                                                            <div class="error-block">
                                                                <label v-if="$v.activation.password.$error && !$v.activation.password.required" class="text-danger">Please enter the password</label>
                                                                <label v-if="$v.activation.password.$error && !$v.activation.password.minLength" class="text-danger">Password must have 6 characters</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="row">                                                        
                                                        <div class="col-md-12">
                                                            <div class="input-group required-field-block">
                                                                <input type="password" name="confirmPassword" placeholder="Confirm Password" maxlength="45" size="30" v-model="activation.confirmPassword" class="form-control box-none">
                                                            </div>
                                                            <div class="error-block">
                                                                <label v-if="$v.activation.confirmPassword.$error && !$v.activation.confirmPassword.required" class="text-danger">Please enter the password</label>
                                                                <label v-if="$v.activation.confirmPassword.$error && !$v.activation.confirmPassword.sameAsPassword" class="text-danger">Passwords must match</label>
                                                            </div>                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bg-slategray lt wrap-reset text-left" style="background-color:#fff !important;">
                                            <button type="button" @click="submitResetPwd" class="btn btn-primary" style="float: right;">Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {
computed: {
        userId() {
            return this.$route.params.userid;
        }
    },    
    data() {
        return {           
            activation: {
                password: '',
                confirmPassword: ''
            }
        }
    },
    validations: {
        activation: {
            password: {
                required,
                minLength: minLength(6)
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },
    mounted() {
    },
    methods: {
        updatePassword() {
            let data = { "id": this.userId, "password": this.activation.password };
            axios.post(this.$store.getters.getAPIBasePath + '/userresource/forgotten/password/update',data)
                .then(response => {
                    iziToast.success({ title: 'Success', message: this.$config.PassUpSuc, position: 'topRight' });                                  
                    setTimeout(() => {
                        window.location.href = process.env.VUE_APP_MAIN_URL + "login";
                    }, 2000);                     
                })
                .catch(error => {
                    iziToast.error({ title: 'Error', message: this.$config.ErrMsg, position: 'topRight' });
                    console.log(error);
                });
        },        
        submitResetPwd: function() {
            let validation_status = this.validatePwd();
            if (!validation_status) {
                return false;
            }
            this.updatePassword();
        },
        validatePwd: function() {
            this.$v.activation.$touch();
            if (this.$v.activation.$invalid) {
                return false;
            }
            return true;
        }       
    }
}

</script>
