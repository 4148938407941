<template>
    <div class="candidate settings tab">
        <div class="card">
            <div class="card-header p-2">
                <div class="row">
                    <div class="col-md-6">Settings</div>
                </div>
            </div>
            <div class="card-body p-2">
                <div class="basic-block" :class="$route.name=='addcandidate'?'col-md-9':'col-md-12'">
                    <div class="row col-md-6 m-4 py-4">
                        <div class="col-md-2">
                            <img src="@/assets/icons/time-zone-icon.jpg" style="width: 100%; height: 90%;" />
                        </div>
                        <div class="col-md-10">
                            <div class="col-md-12 col-sm-12 col-12 lb">
                                <label><b>Your Timezone</b></label>
                                <div v-if="$route.name=='referralviewcandidate' || $route.name=='viewcandidate' || $route.name=='candidateprofile' || $route.name=='guestviewcandidate' "><u>{{settingsInfo.timeZone}}</u></div>
                            </div>
                            <div v-if="$route.name!='referralviewcandidate' && $route.name!='viewcandidate' && $route.name!='candidateprofile' && $route.name!='guestviewcandidate'" class="col-md-12 col-sm-12 col-12">
                                <div class="input-group">
                                    <Select2 v-model="settingsInfo.timeZone" :options="timezones" :settings="{width:'100%'}" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row col-md-6 m-4 py-4">
                        <div class="col-md-2">
                            <img src="@/assets/icons/scope-icon.png" style="width: 100%; height: 100%;" />
                        </div>
                        <div class="col-md-10">
                            <div class="col-md-12 col-sm-12 col-12 lb">
                                <label><b>Scope</b></label>
                                <div v-if="$route.name=='referralviewcandidate' || $route.name=='viewcandidate' || $route.name=='candidateprofile' || $route.name=='guestviewcandidate'" v-for="accessLevel in accessLevel" class="form"><u v-if="accessLevel.id==settingsInfo.accessLevel">{{accessLevel.name}}</u>
                                </div>
                            </div>
                            <div v-if="$route.name!='referralviewcandidate' && $route.name!='viewcandidate' && $route.name!='candidateprofile' && $route.name!='guestviewcandidate'" class="col-md-12 col-sm-12 col-12">
                                <div class="input-group">
                                    <select v-model="settingsInfo.accessLevel" class="form-control">
                                        <option v-for="accessLevel in accessLevel" :value="accessLevel.id">{{accessLevel.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Select2 from 'v-select2-component';
import Multiselect from 'vue-multiselect';
export default {
    components: {
        Multiselect,
        Select2
    },
    props: {
        settingsInfo: Object
    },
    data() {
        return {
            timezones: this.$store.getters.getTimeZones,
            accessLevel: this.$store.getters.getCandidateScopeList
        }
    }
}

</script>
