<template>
	<div class="Slider home-slider">
		<b-carousel
		id="carousel-1"
		:interval="4000"
		:indicators="false"
		background="#fff"
		img-width="100%"
		img-height="480"
		style="text-shadow: 1px 1px 2px #333;"
		>
			<!-- Slides with image only -->
			<b-carousel-slide :img-src="banner1"></b-carousel-slide>
			<!-- <b-carousel-slide :img-src="banner2"></b-carousel-slide> -->
			<b-carousel-slide :img-src="banner3"></b-carousel-slide>
			<!-- <div class="circle-wrapper">
				<div class="circle text-center">
					<div>
						<p class="centxt">Helping you get the right talent, on-time every-time</p>
					</div>
				</div>
			</div> -->
		</b-carousel>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				banner1: require('@/assets/images/banner1.jpg'),
				banner2: require('@/assets/images/banner2.jpg'),
				banner3: require('@/assets/images/banner3.jpg')
			}
		},
		methods: {
			
		}
	}
</script>