<template>
    <div class="interview_questions">
        <div v-if="!isAdd" class="questionaries_list">
            <div class="container-fluid">
                <h5 class="text-center py-3">Interview Questions List
                    <a href="javascript:" class="pull-right btn-back text-blue" @click="$emit('setSubTab',0)">
                        <h5><i class="ti-arrow-left"></i> Back</h5>
                    </a></h5>
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :class="isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <form id="main-search" :class="isExpand?'d-block':'d-none'">
                        <div class="row form-group ten-columns">                            
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Question</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input class="form-control" type="text" v-model="search.params.question" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Answer</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input class="form-control" type="text" v-model="search.params.answer" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Technology</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select class="form-control" v-model="search.params.technologyId">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in technologies" :value="info.id">{{info.technology}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;"></label>
                                    </div>
                                    <div class="col-md-12">
                                        <button type="button" @click="resetQuestionSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                        <button type="button" @click="getInterviewQuestions" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Find</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-12 mx-0">
                <div class="col-md-6 col-sm-6 col-6 text-left p-0">
                    <h4 class="page-title">Interview Questions
                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                            <div class="dropdown">
                                <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                    <a v-for="(value, index) in pagination.searchlimit" class="dropdown-item" href="javascript:" @click="changeLimit(value)">{{value}}</a>
                                </div>
                            </div>
                        </span>
                    </h4>
                </div>
                <button type="button" class="btn btn-primary btn-sm pull-right"><a href="javascript:" class="text-white" @click="isAdd = 'true'">New Question</a></button>
            </div>
            <div class="col-md-12 table-responsive py-3">
                <table class="table table-borderless all-table text-left">
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Questions</th>
                            <th>Answers</th>
                            <th>Technology</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="!interviewQuestions.length">
                            <td colspan="8" class="text-center">No records found</td>
                        </tr>
                        <tr v-for="(question,index) in interviewQuestions">
                            <td>{{index+1}}</td>
                            <td>{{question.question}}</td>
                            <td :title="question.answer" v-html="getAnsSubstring(question.answer)">                                                        </td>
                            <td>{{question.technology}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-12 mb-5">
                <b-pagination v-if="interviewQuestions.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="getInterviewQuestions()"></b-pagination>
            </div>
        </div>
        <div v-if="isAdd" class="questionaries_add">
            <h5 class="text-center py-3">Add Question
                <a href="javascript:" class="pull-right btn-back text-blue" @click="isAdd=false;">
                    <h5><i class="ti-arrow-left"></i> Back</h5>
                </a></h5>
            <div class="row col-md-12 mx-0 px-0">
                <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                            <label>Technology</label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                            <div class="input-group">
                                <select class="form-control" v-model="saveQuestions.technologyId">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in technologies" :value="info.id">{{info.technology}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row col-md-12 mx-0 px-0">
                <div class="form-group col-md-12 col-sm-12">
                    <label for="questions" class="col-form-label">Question</label>
                    <textarea rows="1" class="form-control" v-model="saveQuestions.question"></textarea>
                </div>
                <div class="form-group col-md-12 col-sm-12">
                    <label for="questions" class="col-form-label">Answer</label>
                    <textarea rows="3" class="form-control" v-model="saveQuestions.answer"></textarea>
                </div>
            </div>
            <div class="row col-md-12 mx-0 px-0">
                <div class="form-group col-md-6 col-sm-12">
                    <label for="referenceLink" class="col-form-label">Reference Link</label>
                    <input type="text" name="referenceLink" id="referenceLink" v-model="saveQuestions.referenceLink" class="form-control" />
                </div>
                <div class="form-group col-md-6 col-sm-12 pt-5">
                    <button type="button" class="btn btn-secondary btn-sm float-right ml-2" @click="cancel">Cancel</button>
                    <button type="button" class="btn btn-primary btn-sm float-right " @click="addQuestions">Save</button>
                </div>
            </div>
            <div class="row col-md-12">
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    data() {
        return {
            isAdd: false,
            isExpand: false,
            elements: {
                submitted: false
            },
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                searchlimit: this.$store.state.searchlimit
            },
            search: {
                params: {
                    question: "",
                    answer: "",
                    technologyId: ""
                }
            },
            technologies: [],
            interviewQuestions: [],
            saveQuestions: {
                question: "",
                answer: "",
                technologyId: "",
                skillId: "",
                referenceLink: ""
            }
        }
    },
    mounted() {
        this.getInterviewQuestions();
        this.loadTechnologyList();
    },
    methods: {
        loadTechnologyList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/technologylist')
                .then(response => {
                    this.technologies = response.data.technologylist;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        getInterviewQuestions: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/interviewresource/list/interview/questions?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    if (!response.status) {
                        iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                    }
                    this.interviewQuestions = result.interviewQuestions;
                    this.pagination.totalRecords = result.pagination[0].total;
                    this.pagination.currentPage = result.pagination[0].currentPage;
                })
                .catch(error => {
                    iziToast.error({ title: 'Error', message: this.$config.QuesLoadErr, position: 'topRight' });
                });
        },
        addQuestions: function() {
            if(this.saveQuestions.technologyId==""){
               iziToast.error({ title: 'Error', message: this.$config.TechNotempErr, position: 'topRight' });
            }
            else if(this.saveQuestions.question=="")
            {
                iziToast.error({ title: 'Error', message: this.$config.QuesNotempErr, position: 'topRight' });
            }
            else if(this.saveQuestions.answer=="")
            {
                iziToast.error({ title: 'Error', message: this.$config.AnsNotempErr, position: 'topRight' });
            }
            else{
            this.isAdd = true;
            axios.post(this.$store.getters.getAPIBasePath + '/interviewresource/add/interview/questions', this.saveQuestions)
            .then(response => {
                    let result = response.data;
                    if (result.status)
                        iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                    this.resetAddQuestions();
                    this.isAdd = false;
                })
                .catch(error => {
                    console.log(error);
                });
            }
            
        },
        cancel: function() {
            this.isAdd = false;
        },
        changeLimit: function(newlimit) {
            this.pagination.rowsPerPage = newlimit;
        },
        resetQuestionSearch: function() {
            this.search.params = {
                question: "",
                answer: "",
                technologyId: ""
            };
            this.getInterviewQuestions();
        },
        resetAddQuestions: function() {
            this.saveQuestions = {
                question: "",
                answer: "",
                technologyId: "",
                skillId: "",
                referenceLink: ""
            }
            this.getInterviewQuestions();
        },
        getAnsSubstring(answer) {
            if (answer != null && answer.length > 100) {
                return answer.substring(0, 100) + "...";
            } else
                return answer;
        }
    }
}

</script>
