<template>
    <div class="card">
        <div class="card-header p-2">Interviews</div>
        <div class="card-body p-2">
            <div class="basic-block clone-preference">
                <div class="row ten-columns pos-relative clone filled2">
                    <div class="col-md-12 col-sm-12 col-12 mb-10-mr">
                        <div class="table-responsive">
                            <table class="table table-borderless all-table">
                                <thead>
                                    <tr>
                                        <th class="" scope="col"></th>
                                        <th class="" scope="col">Candidate Name</th>
                                        <th class="" scope="col">Job Code</th>
                                        <th class="" scope="col">Start Date</th>
                                        <th class="" scope="col"> End Date </th>
                                        <th class="" scope="col">Status</th>
                                        <th class="">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="recruitment.length==0">
                                        <td style="border:0px;" colspan="7" align="center">No interviews found
                                        </td>
                                    </tr>
                                    <template v-for="(recInfo, rindex) in recruitment">
                                        <tr>
                                            <td>
                                                <a href="Javascript:void(0);" class="expand" data-targetopen="panel1">
                                                    <span @click="getInterviews(recInfo.id)"><i :class="(currentIndex==recInfo.id) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
                                                    </span>
                                                </a>
                                            </td>
                                            <td>
                                                {{recInfo.candidateName}}
                                            </td>
                                            <td>
                                                {{jobCode}}
                                            </td>
                                            <td>
                                            {{toClientTimeZoneDate(recInfo.showInterviewDate, dateformat) }}
                                                <!-- {{recInfo.showInterviewDate | formatDate}} -->
                                            </td>
                                            <td>
                                             {{toClientTimeZoneDate(recInfo.endDate, dateformat) }}
                                                <!-- {{recInfo.endDate | formatDate}} -->
                                            </td>
                                            <td>
                                                <a class="nav-link dropdown-toggle pl-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span :class="getStatusClass(recInfo.recruitmentStatus)" class="label text-white p-1 rounded w-50-auto">{{ recInfo.recruitmentStatus }}</span>
                                                    <div class="dropdown-menu p-0" aria-labelledby="navbarDropdown">
                                                        <ul class="m-0">
                                                            <li v-for="(recStatus, idx1) in recruiterStatus" v-bind:key="idx1">
                                                                <a @click="changeRecruitmentStatus(recInfo.id,recStatus.id,recInfo.recruitmentStatus,recStatus.name)" class="dropdown-item" href="javascript:">{{recStatus.name}}</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </a>
                                            </td>
                                            <td align="right">
                                                <a v-if="recInfo.recruitmentStatus!='Closed'" href="javascript:" title="Schedule Interview" @click="scheduleInterview(recInfo.candidateId,recInfo.id)"><i class="fa fa-calendar-plus-o fa_custom fa-lg px-1"></i></a>
                                                <a v-if="candContactAccess==1" href="javascript:" @click="$emit('emailTools',recInfo)" title="Email"><i class="fa fa-envelope-o fa_custom fa-lg px-1"></i></a>
                                                <a href="javascript:" @click="$emit('viewFeedback',recInfo.id)" title="View Feedback"><i class="fa fa-list-alt fa_custom fa-lg px-1"></i></a>
                                            </td>
                                        </tr>
                                        <tr v-if="currentIndex==recInfo.id">
                                            <td colspan="7">
                                                <div class="table-responsive">
                                                    <table class="table table-borderless all-table">
                                                        <thead>
                                                            <tr>
                                                                <th class="" scope="col">Interview Title</th>
                                                                <th class="" scope="col">Interview Date</th>
                                                                <th class="" scope="col"> Start Time</th>
                                                                <th class="" scope="col"> End Time</th>
                                                                <th class="" scope="col"> Status</th>
                                                                <th class="" scope="col"> </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="interviewInfo in interviewList">
                                                                <td>{{ interviewInfo.title }}</td>
                                                                 <td>{{toClientTimeZoneDate(interviewInfo.showInterviewDate, dateformat) }}</td>
                                                                 <td>{{toClientTimeZoneDate(interviewInfo.interviewStartTime, timeformat) }}</td>
                                                                 <td>{{toClientTimeZoneDate(interviewInfo.interviewEndTime, timeformat) }}</td>
                                                                <td>
                                                                    <a class="nav-link dropdown-toggle " href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <span :class="interviewInfo.interviewStatus" class="label text-white p-1 rounded w-50-auto">{{ interviewInfo.interviewStatus }}</span>
                                                                        <div class="dropdown-menu p-0" aria-labelledby="navbarDropdown">
                                                                            <ul class="m-0">
                                                                                <li v-for="(intStatus, idx2) in interviewStatus" v-bind:key="idx2">
                                                                                    <a v-on:click="changeInterviewStatus(interviewInfo.id,intStatus.id,recInfo.id)" class="dropdown-item" href="javascript:">{{intStatus.name}}</a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <a v-if="interviewInfo.interviewStatus == 'Assigned'" href="javascript:" title="Interview Rescheduling" @click="rescheduleInterview(interviewInfo.id,interviewInfo.candidateId,recInfo.id)"><i class="fa fa-calendar fa_custom fa-lg px-1 mr-1"></i></a>
                                                                    <a v-if="interviewInfo.interviewStatus == 'Assigned'" href="javascript:" @click="cancelInterview(interviewInfo.id,interviewInfo.candidateId)" v-b-modal.interviewCancellation title="Interview Cancellation"><i class="fa fa-calendar-times-o fa_custom fa-lg px-1"></i></a>
                                                                </td>
                                                            </tr>
                                                            <tr v-if="interviewList.length==0">
                                                                <td style="border:0px;" colspan="5" align="center">No Interviews found</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="interviewCancellation" :no-close-on-backdrop="true" ref="cancellationModal" title="Cancellation Note" @show="" @hidden="" @ok="saveCancellation">
            <form ref="form">
                <b-form-group label-for="cancellation-notes">
                    <textarea v-model="recruitmentData.cancelNote" rows="3" class="form-control"></textarea>
                    <div v-if="elements.submitted && !$v.recruitmentData.cancelNote.required" class="errorcolor">Cancellation Note is required</div>
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast'
export default {
    props: {
        recruitment: Array,
        jobCode: String,
         candContactAccess: [String, Number]
    },
    computed: {

    },
    data() {
        return {
            recruitmentData: {
                candidateId: null,
                interviewId: null,
                cancelNote: '',
                id: null
                
            },
            elements: {
                submitted: false,
            },
            recPlanId: localStorage.getItem('setRecPlanId'), //this.$store.getters.getRecPlanId,
            
            jobid: this.$route.params.jobid,
            currentIndex: null,
            interviewList: [],
            rec_interviews: [],
            feedback: true,
            recruiterStatus: [],
            interviewStatus: [],
            dateformat:this.$store.state.dateformat,
            timeformat:this.$store.state.timeformat
        }
    },
    validations: {
        recruitmentData: {
            cancelNote: { required },
        }
    },
    mounted() {
 
        // console.log(this.$route.params)
        // if(this.$route.params.recruitmentId){
        //   this.viewFeedback(this.$route.params.recruitmentId)
        //   $('#RecruitmentFeedback').modal('show');
        // }
        this.getRecruiterStatus();
        this.getInterviewStatus();
        localStorage.removeItem('interviewId');
        localStorage.removeItem('recPlanId');
        localStorage.removeItem('jobId');
        localStorage.removeItem('recruitmentId');
    },
    methods: {
        getStatusClass(name) {
            if (name.toLowerCase() == "in progress") {
                name = "Inprogress";
            }
            return name;
        },
        getInterviews(recruitmentId) {
            this.recruitmentData.id = recruitmentId;
            if (this.currentIndex == recruitmentId) {
                this.currentIndex = null;
            } else {
                this.currentIndex = recruitmentId;
            }
            axios.get(this.$store.getters.getAPIBasePath + '/interviewerresource/candidate/interviews/' + recruitmentId)
                .then(response => {
                    this.interviewList = response.data.interviews;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        getPrevUrl() {
            $('#RecruitmentFeedback').modal('hide');
            if (this.$route.params.recruitmentId)
                this.$router.push('/progress-interviews')
        },
        getRecruiterStatus() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/recruitmentStatusList')
                .then(response => {
                    if (!response.data.status) {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                    this.recruiterStatus = response.data.recruitmentStatus;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    iziToast.error({ title: 'Error', message: this.$config.LoadStatusErr, position: 'topRight' });
                });
        },
        getInterviewStatus() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/interviewStatusList')
                .then(response => {
                    if (!response.data.status) {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                    this.interviewStatus = response.data.interviewStatus;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    iziToast.error({ title: 'Error', message: this.$config.LoadStatusErr, position: 'topRight' });
                });
        },
        changeInterviewStatus: function(interviewId, statusId,recruitmentId) {
    
            let statusData = { status: statusId };
            axios.post(this.$store.getters.getAPIBasePath + '/interviewresource/update/interview/status/' + interviewId, statusData)
                .then(response => {
                    if (!response.data.status) {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                    this.currentIndex = null;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    iziToast.error({ title: 'Error', message: this.$config.UpStatusErr, position: 'topRight' });
                });
            this.getInterviews(recruitmentId);
            this.$emit('refreshIncidentHistory');
        },
        changeRecruitmentStatus: function(interviewId, statusId, previousStatus, currentStatus) {
            console.log(interviewId, statusId, previousStatus, currentStatus);
            if (previousStatus == 'In Progress' && currentStatus == 'Open'){
                return;
            }
            let statusData = { recruitmentStatus: statusId };
            let self = this;
            axios.post(this.$store.getters.getAPIBasePath + '/interviewresource/update/recruitment/status/' + interviewId, statusData)
                .then(response => {
                    if (!response.data.status) {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                    this.currentIndex = null;
                    self.$emit('refreshRecruitment', self.$route.params.jobid);
                    self.$emit('refreshSLcandidates');
                    self.$emit('refreshIncidentHistory');
                })
                .catch(error => {
                    this.unAuthorized(error);
                    iziToast.error({ title: 'Error', message: this.$config.UpStatusErr, position: 'topRight' });
                });
        },
        scheduleInterview(candId, recId) {

            this.recPlanId = localStorage.getItem('setRecPlanId');     
                 
            localStorage.setItem('recPlanId', this.recPlanId);
            localStorage.setItem('jobId', this.jobid);
            localStorage.setItem('recruitmentId', recId);
            //localStorage.setItem('globalJobId',this.jobid);
            //this.$router.push('/candidates/interview/'+candId);
            
            this.$router.push({ name: 'scheduleinterview', params: { candidateid: candId } });
        },
        rescheduleInterview(interviewId, candId, recId) {console.log(interviewId, candId, recId)
            localStorage.setItem('interviewId', interviewId);
            localStorage.setItem('recruitmentId', recId);
            this.$router.push({ name: 'scheduleinterview', params: { candidateid: candId } });
        },
        cancelInterview: function(interviewId, candidateId) {
            this.recruitmentData.candidateId = candidateId;
            this.recruitmentData.interviewId = interviewId;
        },
        saveCancellation: function(bvModalEvt) {
            this.elements.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                bvModalEvt.preventDefault();
                return;
            }
            this.recruitmentData.jobId = this.$route.params.jobid;
            axios.post(this.$store.getters.getAPIBasePath + '/interviewresource/cancel/interview', this.recruitmentData)
                .then(response => {
                    if (!response.data.status) {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    } else {
                        this.recruitmentData.cancelNote = "";
                        this.elements.submitted = false;
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                        this.$emit('refreshRecruitment', this.$route.params.jobid);
                        this.getInterviews(this.recruitmentData.id);
                        this.$emit('refreshSLcandidates');
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    iziToast.error({ title: 'Error', message: this.$config.IntCanclErr, position: 'topRight' });
                });
        }
    }
}

</script>
