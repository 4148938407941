<template>
    <div class="referenceDetailPopup">
        <b-modal id="viewplan" ref="viewplanRef" centered @ok="saveData"  @hide="clearData" modal-class="" title="" ok-only ok-title="Ok" >
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
            <div class="card">
            <div class="card-header p-2">Service Plan Details</div>
            <div class="card-body p-2">
                <div class="basic-block col-md-12">
                    <div class="table-responsive">
                        <table class="table table-striped table-borderless s_table compare-table">
                            <tbody>
                                <tr>
                                    <td><strong>Plan Name</strong></td>
                                    <td>{{serviceInfo.servicePlan}}</td>
                                </tr>
                                <tr>
                                    <td><strong>Service Charge</strong></td>
                                    <td>{{serviceInfo.serviceCharge}}</td>
                                </tr>
                                <tr>
                                    <td><strong>Plan Period</strong></td>
                                    <td>{{serviceInfo.planPeriod}}</td>
                                </tr>
                                <tr>
                                    <td><strong>Description</strong></td>
                                    <td>{{serviceInfo.description}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required,minLength, maxLength} from "vuelidate/lib/validators";
export default {
    name: 'viewPlan',
    props: {  
        setviewdata: Object
    },
    data() {
        return {
            loading: false,
            error:false,
            serviceInfo:{
               id:'',
                servicePlan:'',
                description:'',
                planPeriod:'',
                permissionCount:'',
                serviceCharge:'',
            }
        }
    },
    validations: {
        serviceInfo: {
            servicePlan: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
        }
    },
    mounted(){
        this.setviewData(this.setviewdata);
    },
    methods: {
        saveData(bvModalEvt) {
            this.$bvModal.hide(viewplan)
        },
        loadPlanDetails:function(id){
            if(id!=null && id!=""){
             axios.get(this.$store.getters.getAPIBasePath + '/serviceplan/view/' + id)
                .then(response => {
                    if (response.data.status) {
                        let planDetails = response.data.recordinfo;
                        this.serviceInfo.id = planDetails.id;
                        this.serviceInfo.servicePlan = planDetails.plan;
                        this.serviceInfo.description = planDetails.description;
                        this.serviceInfo.planPeriod = planDetails.planPeriod;
                        this.serviceInfo.permissionCount = planDetails.permissionCount;
                        this.serviceInfo.serviceCharge = planDetails.serviceCharge;
                         }
                })
                .catch(error => {
                    console.log(error);
                });
            }
        },
        setviewData:function(Service){
             this.error = false;
            this.serviceInfo.id = Service.id;
            this.serviceInfo.servicePlan = Service.servicePlan;
            this.serviceInfo.description = Service.description;
            this.serviceInfo.planPeriod = Service.planPeriod;
            this.serviceInfo.permissionCount = Service.permissionCount;
            this.serviceInfo.serviceCharge = Service.serviceCharge; 
            // this.loadPlanDetails(this.serviceInfo.id);
        },
        clearData:function(){

        }

    }
}
</script>