<template>
	<div class="recruiter-performance reports">
		<div class="row m-0">
			<div class="col-sm-12 p-10">
				<div id="recruiter-settings" class="recruiter settings mt-10">
					<div class="box-wrapper shadow-sm bg-white rounded pos-rel">
						<div class="basic-block" style="background-color: #eceff1;">
							<h5 style="background-color: #37a0e4;color: #fff;padding: 5px;">Recruiter-wise Hiring</h5>
							<div class="row col-md-12 mx-0">
								<div class="col-md-6 p-4 mt-10">
									<div class="table-responsive ">
										<table class="table table-borderless all-table">
											<thead>
												<tr>
													<th class="" scope="col" style="text-align:left; padding-left:20px;">Recruiter name</th>
													<th class="accountManager" scope="col">Hired</th>
												</tr>
											</thead>
											<tbody id="table-length">
												<tr v-if="recruiterList != null && recruiterList.length==0">
													<td colspan="4" align="center">No Recruiters found</td>
												</tr>
												<tr v-for="(recruiter, index) in recruiterList">
													<td class="accountManager" style="text-align:left; padding-left:20px;">{{ recruiter.recruiterName }}</td>
													<td class="accountManager">{{ recruiter.hired }}</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div class="col-md-12">
										<b-pagination v-if="recruiterList.length!=0" size="md" class="pull-right" :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadGraph()"></b-pagination>
									</div>				
								</div>
								<div class="col-md-6 p-4">
									<div class="box-wrapper shadow-sm mb-5 bg-white rounded pos-rel">
										<GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
									</div>					
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { GChart } from "vue-google-charts";
export default {
	components: {
		GChart
	},
	data () {
		return {
			browsepagination: this.$store.state.paginationconfig,
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            recruiterList: [],
			chartData:[],
			chartOptions: {
				subtitle: 'Name, Hired',
				height: 350,
				width: "auto"
			},
			pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
			searchlimit: this.$store.state.searchlimit,
		}
	},
	mounted() {
		this.$store.commit('refreshPagination');
		this.loadGraph();
		this.$emit('addLogActivity','Recruiter wise hiring');
	},
	methods: {
		loadGraph() {
            axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/recruiter/performance?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage)
            .then(response => {
                let result = response.data;
				this.chartData=result.recruiterPerformance;
				this.recruiterList=result.recruiterPerformanceList;
				this.pagination.totalRecords = result.pagination[0].total;
				this.pagination.currentPage = result.pagination[0].currentPage;
            })
            .catch(error => {
                console.log(error);
            });
		},
	},

}
</script>