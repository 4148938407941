import Listcandidates from '@/components/admin/candidates/Listcandidates';
import Editcandidate from '@/components/admin/candidates/Editcandidate';
import Viewcandidate from '@/components/admin/candidates/Viewcandidate';
import AddClientAccount from '@/components/admin/account/AddClientAccount';
import AdminDashboard from '@/components/admin/Dashboard';
import AdminSADashboard from '@/components/admin/SADashboard';
// import ListClientAccount from '@/components/admin/account/ListClientAccount';
import ListJobs from '@/components/admin/jobs/Listjobs';
import ViewRecruiter from '@/components/admin/recruiters/ViewRecruiter';
import ListClients from '@/components/admin/clients/Listclients';
import ViewClient from '@/components/admin/clients/Viewclient';
import ListInterviewers from '@/components/admin/interviewers/Listinterviewers';
import ViewInterviewer from '@/components/admin/interviewers/ViewInterviewer';
import EditInterviewer from '@/components/admin/interviewers/EditInterviewer';
import ViewClientAccount from '@/components/admin/account/ClientAccountView';
import EditClientAccount from '@/components/admin/account/ClientAccountEdit';
//import ViewClientAccount from '@/components/admin/account/ViewClientAccount';
import ListContacts from '@/components/admin/account/ListContacts';
import Reports from '@/components/admin/Reports';
import AdminSettings from '@/components/admin/Tools&Settings';
import Users from '@/components/admin/user/Users';
import ListServiceplan from '@/components/admin/serviceplan/Listserviceplan.vue';
import AddServiceplan from '@/components/admin/serviceplan/blocks/Addserviceplan.vue';
import ChangePassword from '@/components/candidate/ChangePassword';
import ListRecruiters from '@/components/admin/recruiters/Listrecruiters';
import ClientAccountMain from '@/components/admin/account/ClientAccountMain'

export default [{
        path: '/admin/dashboard',
        name: 'admindashboard',
        component: AdminDashboard,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/candidates',
        name: 'adminlistcandidate',
        component: Listcandidates,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/candidates/edit/:candidateid',
        name: 'admineditcandidate',
        component: Editcandidate,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/candidates/view/:candidateid',
        name: 'adminviewcandidate',
        component: Viewcandidate,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/add/recruiter',
        name: 'adminaddrecruiter',
        component: AddClientAccount,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/add/plan',
        name: 'adminaddserviceplan',
        component: AddServiceplan,
        meta: { accessedby: 'admin' }
    },
    // {
    //     path: '/admin/clientaccount',
    //     name: 'listclientaccount',
    //     component: ListClientAccount,
    //     meta: { accessedby: 'admin' }
    // },
    {
        path: '/admin/clientaccount',
        name: 'listclientaccount',
        component: ClientAccountMain,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/jobs',
        name: 'adminlistjobs',
        component: ListJobs,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/clients',
        name: 'adminlistclients',
        component: ListClients,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/client/view/:clientid',
        name: 'adminviewclient',
        component: ViewClient,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/interviewers',
        name: 'adminlistinterviewers',
        component: ListInterviewers,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/interviewers/view/:interviewerid',
        name: 'adminviewinterviewer',
        component: ViewInterviewer,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/interviewers/edit/:interviewerid',
        name: 'admineditinterviewer',
        component: EditInterviewer,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/recruiters',
        name: 'adminlistrecruiters',
        component: ListRecruiters,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/view/:recruiterid',
        name: 'adminviewrecruiter',
        meta: { accessedby: 'recruiter' },
        component: ViewRecruiter
    },
    {
        path: '/admin/clientaccount/view/:accountid',
        name: 'viewclientaccount',
        component: ViewClientAccount,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/clientaccount/edit/:accountid',
        name: 'editclientaccount',
        component: EditClientAccount,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/contacts',
        name: 'adminlistcontacts',
        component: ListContacts,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/reports',
        name: 'adminreports',
        component: Reports,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/settings',
        name: 'adminsettings',
        component: AdminSettings,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/user',
        name: 'adminlistuser',
        component: Users,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/serviceplan',
        name: 'adminlistservice',
        component: ListServiceplan,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/changepassword',
        name: 'changepasswordadmin',
        component: ChangePassword,
        meta: { accessedby: 'admin' }
    },
    {
        path: '/admin/sadashboard',
        name: 'saadmindashboard',
        component: AdminSADashboard,
        meta: { accessedby: 'admin' }
    },
    /*     {
            path: '/admin/user/edit/:userid',
            name: 'adminedituser',
            component: Editusers,
            meta: { accessedby: 'admin' }
        }, */
];