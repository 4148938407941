<template>
    <div class="comment">
        <b-modal scrollable ref="commentModal" size="md" id="commentModal" title="Comment" :no-close-on-backdrop="true" @ok="saveActivity" ok-title="Save Activity">
            <form>
                <div class="row col-md-12 mx-0 px-0">
                    <div class="form-group col-md-12 col-sm-12">
                        <span class="font-weight-bold">Activity: </span>
                        <span class="text-capitalize">
                            <!--  {{commentType}} 
 -->
                            <select v-model="addActivity.activityId" name="addActivity" id="addActivity">
                                <option value="">Select</option>
                                <option v-for="(activity,index) in taskTypeActivityList" :value="activity.id">{{ activity.activityName }}</option>
                            </select>
                        </span>
                        <span id="time" class="float-right" v-html="time"></span>
                        <textarea rows="3" class="form-control mt-4" id="comment" v-model="addActivity.description"></textarea>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
export default {
    props: {
        taskId: Number,
        activity: Object,
        time: String
    },
    components: {

    },
    data() {
        return {
            taskTypeId: localStorage.getItem('taskTypeId'),
            commentType: localStorage.getItem('taskActivity'),
            addActivity: {
                id: localStorage.getItem("activityLogId"),
                taskId: "",
                activityId: "",
                startTime: "",
                endTime: "",
                duration: this.time,
                description: ""
            },
            taskTypeActivityList: []
        }
    },
    mounted() {
        this.loadTaskTypeActivity();
        if (localStorage.getItem("timerStatus") == 'started' && localStorage.getItem("activityDropListId") != null) {
            this.addActivity.activityId = localStorage.getItem("activityDropListId");
        }
    },
    methods: {

        saveActivity: function(bvModalEvt) {

            this.addActivity.taskId = this.taskId;
            this.addActivity.startTime = this.activity.startTime;
            this.addActivity.endTime = this.activity.endTime;
            this.addActivity.duration = this.time;

            axios.post(this.$store.getters.getAPIBasePath + '/taskresource/update/task/activitylog', this.addActivity)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                        this.$refs.commentModal.hide();
                        this.$emit('clearTimer');
                        this.$emit('loadTaskActivityLog', this.taskId);
                        this.refreshActivityData();
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                });
        },
        loadTaskTypeActivity: function() {
            if (this.taskTypeId != null) {
                axios.get(this.$store.getters.getAPIBasePath + '/settingsresource/tasktype/activity/' + this.taskTypeId)
                    .then(response => {
                        if (response.data.status) {
                            this.taskTypeActivityList = response.data.activityList;
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                        iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                    });
            }
        },
        refreshActivityData: function() {
            this.addActivity.startTime = "";
            this.addActivity.endTime = "";
            this.addActivity.duration = "";
            this.addActivity.description = "";
        },
        refreshDropbox: function() {alert('refreshDropbox');
            if (localStorage.getItem("timerStatus") == 'started' && localStorage.getItem("activityDropListId") != null) {
                this.addActivity.activityId = localStorage.getItem("activityDropListId");
            }
        }

    }
}

</script>
