<template>
	<div :class="{ 'viewonly' : contactinfo.viewonly == 1}">
		<div class="card">
			<div class="card-header p-2"> Current Address</div>
			<div class="card-body p-2">
				<div class="basic-block">
					<div class="row ten-columns">
						<div class="col-md-2 col-sm-6 col-12 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Address Line 1</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group" >
										<input v-model="contactinfo.currentaddress.addressLine1" type="text" maxlength="45" class="form-control">
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-2 col-sm-6 col-12 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Address Line 2</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group" >
										<input v-model="contactinfo.currentaddress.addressLine2" type="text" maxlength="45" class="form-control">
									</div>
								</div>
							</div>  
						</div>
						<div class="col-md-2 col-sm-6 col-12 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>City</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group" >
										<input v-model="contactinfo.currentaddress.cityName" type="text" maxlength="45" class="form-control">
									</div>
								</div>
							</div>  
						</div>
						<div class="col-md-2 col-sm-6 col-12 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>State/Province</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<select v-if="contactinfo.currentaddress.countryId=='US'" v-model="contactinfo.currentaddress.stateName" class="form-control" required>
                                        <option value="">--</option>
                                        <option v-for="(data, index) in usstates" :value="data.stateName">{{data.stateName}}</option>
                                    </select>
			                        <input v-if="contactinfo.currentaddress.countryId!='US'" v-model="contactinfo.currentaddress.stateName" type="text" maxlength="25" class="form-control">
								</div>
							</div>  
						</div>
						<div class="col-md-2 col-sm-6 col-12 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Zip Code</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group" >
										<input v-model="contactinfo.currentaddress.zipCode" type="text" maxlength="10" class="form-control">
									</div>
								</div>
							</div>  
						</div>
						<div class="col-md-2 col-sm-6 col-12 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Country</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<select v-model="contactinfo.currentaddress.countryId" @change="contactinfo.currentaddress.stateName=''" class="form-control">
										<option value="">--</option>
										<option v-for="(data, index) in countries" :value="data.country_Id">{{data.name}}</option>
									</select>
								</div>
							</div>  
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card">
			<div class="card-header p-2">Permanent Address</div>
			<div class="card-body p-2">
				<div class="basic-block">
					<div class="row ten-columns">
						<div class="col-md-2 col-sm-6 col-12 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Address Line 1</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group" >
										<input v-model="contactinfo.permanentaddress.addressLine1" type="text" maxlength="45" class="form-control">
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-2 col-sm-6 col-12 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Address Line 2</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group" >
										<input v-model="contactinfo.permanentaddress.addressLine2" type="text" maxlength="45" class="form-control">
									</div>
								</div>
							</div>  
						</div>
						<div class="col-md-2 col-sm-6 col-12 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>City</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group" >
										<input v-model="contactinfo.permanentaddress.cityName" type="text" maxlength="45" class="form-control">
									</div>
								</div>
							</div>  
						</div>
						<div class="col-md-2 col-sm-6 col-12 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>State/Province</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<select v-if="contactinfo.permanentaddress.countryId=='US'" v-model="contactinfo.permanentaddress.stateName" class="form-control" required>
                                        <option value="">--</option>
                                        <option v-for="(data, index) in usstates" :value="data.stateName">{{data.stateName}}</option>
                                    </select>
			                        <input v-if="contactinfo.permanentaddress.countryId!='US'" v-model="contactinfo.permanentaddress.stateName" type="text" maxlength="25" class="form-control">
								</div>
							</div>  
						</div>
						<div class="col-md-2 col-sm-6 col-12 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Zip Code</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group" >
										<input v-model="contactinfo.permanentaddress.zipCode" type="text" maxlength="10" class="form-control">
									</div>
								</div>
							</div>  
						</div>
						<div class="col-md-2 col-sm-6 col-12 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Country</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<select v-model="contactinfo.permanentaddress.countryId" @change="contactinfo.permanentaddress.stateName=''" class="form-control">
										<option value="">--</option>
										<option v-for="(data, index) in countries" :value="data.country_Id">{{data.name}}</option>
									</select>
								</div>
							</div>  
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="card">
		    <div class="card-header p-2">Emergency Contact</div>
		    <div class="card-body p-2">
		      <div class="row ten-columns">
		        <div class="col-sm-12">
		            <div class="table-responsive">
		              <table class="table table-borderless all-table">
		                <thead>
		                  <tr>
		                    <th class="" scope="col">Name</th>
		                    <th class="" scope="col">Phone</th>
		                    <th class="" scope="col">Relationship</th>
		                    <th class="" scope="col"></th>
		                  </tr>
		                </thead>
		                <tbody class="cloned_tr">
		                	<tr v-for="(info, index) in contactinfo.emergency">
                                <td>
                                    <input v-model="contactinfo.emergency[index].name" type="text" maxlength="25" class="form-control" v-on:input="contactinfo.emergency[index].name = contactinfo.emergency[index].name.trim()">
                                </td>
                                <td>
                                    <input v-model="contactinfo.emergency[index].phone" type="text" maxlength="15" class="form-control" v-on:input="contactinfo.emergency[index].phone = contactinfo.emergency[index].phone.trim()">
                                </td>
                                <td >
                                    <input v-model="contactinfo.emergency[index].relation" type="text" maxlength="25" class="form-control" v-on:input="contactinfo.emergency[index].relation = contactinfo.emergency[index].relation.trim()">
                                </td>
                                
                                <td>                                    

									<a v-if="contactinfo.viewonly != 1" href="Javascript:void(0);" data-toggle="modal" data-target="#removEmergency" @click="confirmEmergency(index,0)" style="margin-top:5px;"><span class="ti-trash"></span></a>

									
                                </td>
                            </tr>

		                  	<tr class="filled4" :class="{ 'noaddedmore' : contactinfo.viewonly == 1}">
			                    <td>
			                    	<input ref="addcandidate_contactinfo_add_name" v-model="newcontact.name" type="text" maxlength="25" class="form-control">
			                    </td>
			                    <td class="">
				                    <input v-model="newcontact.phone" type="text" maxlength="15" class="form-control">
				                </td>
			                    <td class="">
			                    	<input v-model="newcontact.relation" type="text" maxlength="25" class="form-control">
			                    </td>
			                    <td>
			                      <a href="Javascript:void(0);" @click="addNewContact" style="margin-top:5px;"><span class="ti-plus"></span></a>
			                    </td>
		                  	</tr>

		                  	<tr :class="{ 'norecordfound' : contactinfo.length}" style="display: none;"><td colspan="4" align="center">No item found</td></tr>
		                </tbody>
		              </table>
		            </div>
		        </div>
		      </div>
		    </div>
		</div>

		<Dialog id="removEmergency" :onYes="deleteEmergency" :returnParams="delEmrParam" />
	</div>
</template>

<script>
	export default {
		props: {
            contactinfo: Object,
        },
		data(){
			return {
				countries:this.$store.getters.getCountries,
				usstates:this.$store.getters.getUSStates,
				newcontact:{
					name:"",
					phone:"",
					relation:""
				},
                delEmrParam:{
                    index:-1,
                    id:0
                }
			}
		},
		methods : {
			resetNewForm(){
				this.newcontact = {
					name:"",
					phone:"",
					relation:""
				};
				this.$refs.addcandidate_contactinfo_add_name.focus();
			},
			addNewContact(){
				this.newcontact.name = this.newcontact.name.trim();
				this.newcontact.phone = this.newcontact.phone.trim();
				this.newcontact.relation = this.newcontact.relation.trim();
				if(this.newcontact.name!="" || this.newcontact.phone!="" || this.newcontact.relation!=""){
					this.contactinfo.emergency.push(this.newcontact);
					this.resetNewForm();
				}
			},
			deleteEmergency:function(arg)
            {                
                this.contactinfo.emergency.splice(arg.index,1);                
            },
            confirmEmergency:function(arg1, arg2)
            {
                this.delEmrParam.index=arg1;
                this.delEmrParam.id=arg2;
            }
		}
	}
</script>