<template>
  <div id="wrapper">
    <div id="login">
      <div class="container-fluid">
        <h4 class="text-center">Login</h4>
      </div>
    </div>
  </div>
</template>
<script>
import iziToast from "izitoast";
export default {
	data() {
		return {};
	},
	mounted() {
		this.getParams();
	},
	methods: {
		getParams() {
			var url = window.location.href;
			var params = {};
			var parser = document.createElement("a");
			parser.href = url;
			var query = parser.search.substring(1);
			var vars = query.split("&");
			for (var i = 0; i < vars.length; i++) {
				var pair = vars[i].split("=");
				params[pair[0]] = decodeURIComponent(pair[1]);
			}
			//console.log("params", params.code);
			if (params.code!='') {
				this.doLinkedinAuthentication(params.code);
			}
			else {
				iziToast.error({
					title: "Error Occured",
					message: this.$config.TryAg,
					position: "topRight",
				});
				this.$router.push({ name: "homelogin" });
			}
		},
		doLinkedinAuthentication(code) {
			//console.log("code", code);
			let LinkedinData = {
				code: code,
				clientId: this.$store.getters.getLinkedinClientId,
				redirectUri: this.$store.getters.getLinkedinRedirectUrl,
			};
			axios.post(this.$store.getters.getAPIBasePath + "/userresource/social/linkedin/authenticate", LinkedinData)
			.then((response) => {
				let result = response.data;
				console.log("Result...............", result);
				if (result.result) {
					this.$store.dispatch("setupLogin", result).then(() => {
					console.log("setupLogin", result);
					this.$router.push({ name: "candidatedashboard" });
					// if (result.data.userType == "candidate") {
					//     this.$router.push({ name: "candidatedashboard", params: { jobId: 1 } });
					// }
					});
				} else {
					iziToast.info({
						title: "Error",
						message: this.$config.TryAg,
						position: "topRight",
					});
					this.$router.push({ name: "homelogin" });
				}
			})
			.catch((error) => {
				console.log(error);
				iziToast.error({
					title: "Error Occured",
					message: this.$config.TryAg,
					position: "topRight",
				});
				this.$router.push({ name: "homelogin" });
			});
		},
	},
};

// candidate_download
// entity
// data
// repository
// Candidate resoure
// /candidateresource/downloaded/candidates/count
// candidateresource/add/candidate/download

</script>

