<template>
    <!--  :class="$route.path=='/candidates/dashboard' ? 'mt-3' :''" -->
    <!-- <div :class="usertype=='recruiter' ? 'col-md-8 col-sm-8' : 'col-md-9 col-sm-9'"> -->
    <div class="col-md-9 col-sm-9">
        <div class="collapse navbar-collapse pull-right" id="demo-navbar">
            <!--  -->
            <form v-on:submit.prevent="globalSearch" class="form-inline my-2 my-lg-0 pull-right">
                <ul class="ul-horizontal userprofile-submenu mb-0">
                    <li class="headersearchbox"><input v-if="companyLogo!='null'" type="text" class="form-control recruiter_menu" placeholder="Search..." v-model="searchValue">
                        <input v-else type="text" class="form-control" placeholder="Search..." v-model="searchValue">
                        <button type="button" class="btn btn-primary btn-sm ml-1" @click="globalSearch">Search</button></li>
                    <li v-if="true" class="notify nav-item dropdown">
                        <router-link :title="activityTooltip" v-if="notify != null && notify.taskId > 0" :to="{ name: 'viewtask', params: {taskid : notify.taskId}}" class="nav-link dropdown-toggle " role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="ti-bell"></span>
                            <span class="badge">1</span>
                        </router-link>
                        <div v-if="false" class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <router-link v-if="notify.taskId != 0" :to="{ name: 'viewtask', params: {taskid : taskId}}" class="dropdown-item">Task</router-link>
                        </div>
                    </li>
                    <li class="recruiter-img">
                        <div class="col">
                        <div v-if="companyLogo!='null'"><img :src="companyLogo" width="90%" height="35px"></div>
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="">{{userinfo.name}} <i class="fa fa-angle-down"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a v-if="false" class="dropdown-item" href="">Profile</a>
                            <router-link :to="{ name: 'recruiterprofile' }" class="dropdown-item">Profile</router-link>
                            <router-link  v-if="accountType == 3" :to="{ name: 'recclientaccount' }" class="dropdown-item">Client Account</router-link>
                            <router-link :to="{ name: 'listlead' }" class="dropdown-item">Leads</router-link>
                            <router-link :to="{ name: 'changepasswordrecruiter' }" class="dropdown-item">Change Password</router-link>
                            <router-link :to="{ name: 'recruitersettings' }" class="dropdown-item">Tools & Settings</router-link>
                            <a v-if="!userinfo.havingInterviewerAccount" class="dropdown-item" v-b-modal.interviewerAccount  href="javascript:;">Add as Interviewer</a>
                            <a v-if="userinfo.havingInterviewerAccount" class="dropdown-item" @click="updateUserAccount('interviewer')" href="javascript:;">Switch to Interviewer Account</a>
                            <a class="dropdown-item" @click="performLogout" href="Javascript:void(0);">Logout</a>
                        </div>
                        </div>
                    </li>
                </ul>
            </form>
        </div>
        <BDialog id="interviewerAccount" :onYes="createInterviewerAccount" title="Interviewer Account" message="Do you want to register as Interviewer with the same account?" />
    </div>
</template>
<script>
import { mapState } from 'vuex';
import iziToast from 'izitoast';
export default {
    computed: mapState(['notificationList']),
    watch: {
        notificationList(newValue, oldValue) {
            this.notify = newValue;
        },

    },
    mounted() {     
        this.InlineButtonClickHandler();
        this.ProfileImageButtonClickHandler();
        this.$store.commit('setGlobalSearchValue', "");
        localStorage.setItem('loggedOut', 'false');
    },
    data() {
        return {
            usertype:this.$store.getters.currentUser.userType,
            userinfo: this.$store.getters.currentUser,
            searchValue: "",
            notify: this.$store.getters.getNotificationList,
            activityTooltip: localStorage.getItem("taskActivity"),
            companyLogo: localStorage.getItem("companyLogo"),
            accountType: localStorage.getItem("accountType"),
             login: {
                email: '',
                password: '',
                switchAccount: true,
            },           
        }
    },
    methods: {
        performLogout() {
            this.addLogActivity();
            this.$store.dispatch("doLogout");
            localStorage.setItem('loggedOut', true);
           // this.$router.push('/login');
            localStorage.removeItem('candidateContactAccess');
            localStorage.removeItem('salaryNegotiationAccess');
            localStorage.removeItem('vendorClientInfoAccess');
            localStorage.removeItem('offerLetterAccess');
            localStorage.removeItem('currencyId');
            localStorage.removeItem('workAuthorization');
            localStorage.removeItem('countryId');
            localStorage.removeItem('companyLogo');
            localStorage.removeItem('accountType');
        },
        InlineButtonClickHandler() {
            $(document).ready(function() {
                $('.main-menu > li > div > a').click(function() {
                    $('.main-menu > li').removeClass('active');
                    $(this).parent().parent().addClass('active');
                });
            });
        },
        ProfileImageButtonClickHandler() {
            $(document).on("change", ".uploadFile", function() {
                var uploadFile = $(this);
                var files = !!this.files ? this.files : [];
                if (!files.length || !window.FileReader) return;
                if (/^image/.test(files[0].type)) {
                    var reader = new FileReader();
                    reader.readAsDataURL(files[0]);
                    reader.onloadend = function() {
                        uploadFile.closest(".imgUp").find('.imagePreview').css("background-image", "url(" + this.result + ")");
                    }
                }
            });
        },
        globalSearch() {
            //this.$store.commit('setGlobalSearchValue', this.searchValue);
            // this.$router.push('/candidates/');
            axios.post(this.$store.getters.getAPIBasePath + '/globalsearchresource/global/search', { metaData: this.searchValue })
                .then(response => {
                    let result = response.data.globalSearch;
                    if (result != null) {
                        switch (result.module) {
                            case 'candidate':
                                this.$router.push('/candidates/view/' + result.keyId);
                                break;
                            case 'job':
                                this.$router.push('/job/plan-recruitment/' + result.keyId);
                                break;
                            case 'task':
                                this.$router.push('/recruiter/tasks/view/' + result.keyId);
                                break;
                            case 'interviewer':
                                this.$router.push('/interviewers/view/' + result.keyId);
                                break;
                            case 'recruiter':
                                this.$router.push('/recruiters/view/' + result.keyId);
                                break;
                            case 'client':
                                this.$router.push('/clients/edit/' + result.keyId);
                                break;
                            default:
                                break;
                        }
                    } else {
                        iziToast.info({ title: 'Info', message: this.$config.EntValidSrch, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    iziToast.info({ title: 'Info', message: this.$config.EntValidSrch, position: 'topRight' });
                    console.log(error);
                });
            this.searchValue = "";
        },
        addLogActivity: function() {
            this.logActivityData = {
                entityName: 'Logout',
                actionName: 'Logout',
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {
                    localStorage.setItem('candIdForActivity', response.data.id);
                })
                .catch(error => {

                });
        },
        createInterviewerAccount: function() {

            axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/add/interviewer/account')
                .then(response => {

                    let result = response.data;
                    this.login.userType = "interviewer";
                    this.performLogin();

                    if (!response.status) {
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position: 'topRight'
                        });
                    } else {
                        iziToast.success({
                            title: 'Success',
                            message: this.$config.IntRegSuc,
                            position: 'topRight'
                        });

                    }
                })
                .catch(error => {

                    iziToast.error({
                        title: 'Error',
                        message: this.$config.CreInterErr,
                        position: 'topRight'
                    });
                });
        },
        performLogin() {
            this.login.email = this.userinfo.email;
            axios.post(this.$store.getters.getAPIBasePath + '/userresource/authenticate', this.login)
                .then(response => {
                    let result = response.data;
                    if (result.result) {
                        localStorage.setItem(this.$store.getters.getLocalStorageName, response.data.data.token);
                        window.axios.defaults.headers.common['Authorization'] = response.data.data.token;
                        const defaultredirects = this.$store.getters.getDefaultRedirects;
                        this.$store.dispatch("setupLogin", result).then(() => {
                            if (result.data.userType == "interviewer") {
                                this.$router.push(defaultredirects.interviewer);
                            } else if (result.data.userType == "candidate") {
                                this.$router.push(defaultredirects.candidate);
                            } else if (result.data.userType == "guest") {
                                this.$router.push({ path: defaultredirects.guest });
                            } else if (result.data.userType == "admin") {
                                //this.$router.push(defaultredirects.admin);
                                if (result.data.isSuperAdmin) {
                                    this.$router.push({ path: defaultredirects.superadmin });
                                } else {
                                    this.$router.push({ path: defaultredirects.admin });
                                }
                            } else if (result.data.userType == "referral") {
                                this.$router.push({ path: defaultredirects.referral });
                            } else {
                                //if (result.data.recruitOwner)
                                if (result.data.recruitType == 2 || result.data.recruitType == 3) 
                                    this.$router.push(defaultredirects.recruiteradmin);
                                else
                                    this.$router.push(defaultredirects.recruiter);
                            }
                            this.$router.go(0);
                        });
                    } else {
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.InvCredential,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.TryAg,
                        position: 'topRight'
                    });
                });
        },
        updateUserAccount: function(value) {
            this.userType = value;
            axios.post(this.$store.getters.getAPIBasePath + '/userresource/update/usertype/' + this.userType)
                .then(response => {
                    let result = response.data;
                    if (response.data.status == "OK") {
                        this.login.userType = this.userType;
                       this.performLogin();
                    }
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.SwtAccErr,
                        position: 'topRight'
                    });
                });
        }
    }
}

</script>
