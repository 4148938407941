var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"settings"},[_c('div',{staticClass:"row col-md-12 py-3"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"row col-md-12 ml-0 left-bar-tab p-2",on:{"click":function($event){return _vm.$emit('setSubTab',1)}}},[_vm._m(0),_vm._m(1)])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"row col-md-12 ml-0 left-bar-tab p-2",on:{"click":function($event){return _vm.$emit('setSubTab',2)}}},[_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"row col-md-12 ml-0 left-bar-tab p-2",on:{"click":function($event){return _vm.$emit('setSubTab',3)}}},[_vm._m(4),_vm._m(5)])])]),_c('div',{staticClass:"row col-md-12 py-3"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"row col-md-12 ml-0 left-bar-tab p-2",on:{"click":function($event){return _vm.$emit('setSubTab',4)}}},[_vm._m(6),_vm._m(7)])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"row col-md-12 ml-0 left-bar-tab p-2",on:{"click":function($event){return _vm.$emit('setSubTab',5)}}},[_vm._m(8),_vm._m(9)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 p-3"},[_c('img',{attrs:{"src":require("@/assets/icons/sourcing-channel.png"),"width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-8 text-left align-self-center p-2"},[_c('h6',[_c('b',[_vm._v("Work Authorization")])]),_c('p',[_vm._v("Manage Work Authorization")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 p-3"},[_c('img',{attrs:{"src":require("@/assets/icons/sourcing-channel.png"),"width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-8 text-left align-self-center p-2"},[_c('h6',[_c('b',[_vm._v("Qualification")])]),_c('p',[_vm._v("Manage Qualification")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 p-3"},[_c('img',{attrs:{"src":require("@/assets/icons/sourcing-channel.png"),"width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-8 text-left align-self-center p-2"},[_c('h6',[_c('b',[_vm._v("Tax Terms")])]),_c('p',[_vm._v("Manage Tax Terms")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 p-3"},[_c('img',{attrs:{"src":require("@/assets/icons/sourcing-channel.png"),"width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-8 text-left align-self-center p-2"},[_c('h6',[_c('b',[_vm._v("Client Access Level")])]),_c('p',[_vm._v("Manage Client Access Level")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 p-3"},[_c('img',{attrs:{"src":require("@/assets/icons/sourcing-channel.png"),"width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-8 text-left align-self-center p-1"},[_c('h6',[_c('b',[_vm._v("Country Based Settings")])]),_c('p',[_vm._v("Manage Country Based Settings")])])
}]

export { render, staticRenderFns }