<template>
    <div class="referenceDetailPopup">
        <b-modal id="editplan" ref="editplanRef" centered @ok="saveData"  @hide="clearData" modal-class="" title="Edit Plan" ok-only ok-title="Save" >
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="card-body p-0 px-0 mx-0 mb-1">
                                <div class="col-lg-12 col-xl-12">                                   
                                    <div class="row mb-2">
                                            <div class="col-4 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block">Plan Name</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <div class="input-group required-field-block">
                                                    <input v-model="serviceInfo.servicePlan" type="text" class="form-control">
                                                    <span class="required-icon"></span>
                                                </div>                                       
                                                <label v-if="$v.serviceInfo.servicePlan.$error && !$v.serviceInfo.servicePlan.required" class="text-danger">Plan Name is required</label>
                                                <label v-if="$v.serviceInfo.servicePlan.$error && !$v.serviceInfo.servicePlan.minLength" class="text-danger">Plan Name must've minimum 2 characters</label>
                                                <label v-if="$v.serviceInfo.servicePlan.$error && !$v.serviceInfo.servicePlan.maxLength" class="text-danger">Maximum limit is 30 characters</label>
                                            </div>                                         
                                        </div>
               
                                        <div class="row mt-0 mb-2">
                                            <div class="col-4 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block">Service Charge</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <input v-model="serviceInfo.serviceCharge" type="text" class="form-control">
                                            </div>                                                                          
                                        </div>
               
                                        <div class="row mt-0 mb-2">
                                            <div class="col-4 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block">Period</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <div class="input-group required-field-block">
                                                    <input v-model="serviceInfo.planPeriod" type="text" class="form-control">
                                                    <span class="required-icon"></span>
                                                </div>
                                                <label v-if="$v.serviceInfo.planPeriod.$error && !$v.serviceInfo.planPeriod.required" class="text-danger">Plan Period is required</label>
                                            </div>    
                                        </div>
                                        <div class="row mt-0 mb-2">
                                            <div class="col-4 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block">Description</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <input v-model="serviceInfo.description" type="text" class="form-control">
                                            </div>                                                                          
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
export default {
    name: 'editPlan',
    props: {  
        SerData: Object
    },
    data() {
        return {
            loading: false,
            error:false,
            serviceInfo:{
                id:'',
                servicePlan:'',
                description:'',
                planPeriod:'',
                serviceCharge:'',
            }
        }
    },
    validations: {
        serviceInfo: {
            servicePlan: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            planPeriod: {
                required,
            },
        }
    },
    mounted(){
        this.setData(this.SerData);
    },
    methods: {
        saveData(bvModalEvt) {
            bvModalEvt.preventDefault();
            console.log(this.serviceInfo);
             this.error = false;
            this.$v.serviceInfo.$touch();
            if (!this.$v.serviceInfo.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/serviceplan/edit/'+this.serviceInfo.id,this.serviceInfo)
                .then((response) => {
                    console.log(response);
                    let result = response.data;
                    if(result.status == "OK"){
                       this.error = true;
                    }else{
                        iziToast.success({ title: 'Success', message: "Service Plan Updated Successfully", position: 'topRight' });
                        this.$emit('refresh-user');
                         this.$refs.editplanRef.hide();
                    }
                   
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);                   
                });
            }
           
        },
        setData:function(Service){
            this.error = false;
            this.serviceInfo.id = Service.id;
            this.serviceInfo.servicePlan = Service.servicePlan;
            this.serviceInfo.description = Service.description;
            this.serviceInfo.planPeriod = Service.planPeriod;
            this.serviceInfo.serviceCharge = Service.serviceCharge; 
        },
        clearData:function(){

        }

    }
}
</script>