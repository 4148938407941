<template>
    <div id="main" class="mt-70">
        <div class="container-fluid driver-model" id="main-data">
            <div class="row">
                <div class="col-md-3">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card border-5 mb-3">
                                <!-- <div class="col-md-12 col-sm-12 px-0 py-0 about-box">
                                    <div class="col-md-8 px-0 float-left">
                                        <h6 class="m-0 text-left p-2">
                                            <a href="javascript:" @click="getPrevUrl()" title="Back" class="p-2">
                                                <i class="ti-arrow-left "></i>
                                                <span> Back</span>
                                            </a>
                                            <router-link class="float-right link pr-2" title="View Profile" :to="{ name: 'interviewerprofile'}"><i class="fa fa-eye fa_custom fa-lg "></i> </router-link>
                                        </h6>
                                    </div>
                                </div> -->
                                
                                <h6 class="about-box m-0 text-left p-2">
                                    <a href="javascript:" @click="getPrevUrl()" title="Back" class="p-2">
                                        <i class="fa fa-arrow-left"></i>
                                        <span> Back</span>
                                    </a>
                                    <router-link class="float-right link pr-2" title="View Profile" :to="{ name: 'interviewerviewprofile'}"><i class="fa fa-eye fa_custom fa-lg "></i> </router-link>
                                </h6>
                                <div class="user-card pl-5 pr-5 pt-3">
                                    <div class="information">
                                        <div class="userimage-progress">
                                            <div class="progress-circle">
                                                <vue-circle v-if="showProgress" :progress="ProfileData.profileCompletion" :size="75" :reverse="true" line-cap="round" :fill="fill" empty-fill="rgba(0, 0, 0, .1)" :animation-start-value="0.0" :start-angle="0" insert-mode="append" :thickness="7" :show-percent="true" @vue-circle-progress="progress" @vue-circle-end="progress_end">
                                                    <div class="imgUp">
                                                        <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' +  (ProfileData.image===''?ProfileData.imagePath:ProfileData.image) + ')' }"></div>
                                                        <label class="upload-icon uploadleft"><i class="ti-camera"></i><input ref="interviewer_profile_photo" id="interviewer_profile_photo" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="upload_photo"></label>
                                                        <label class="delete-icon deleteleft"><i class="ti-close closefont" @click="delete_photo"></i></label>
                                                    </div>
                                                </vue-circle>
                                            </div>
                                        </div>
                                        <div class="name">{{tmp_ProfileData.firstName}} {{tmp_ProfileData.lastName}}</div>
                                        <div>{{/*personalinfo.industry.industryName*/}} {{tmp_ProfileData.position}} </div>
                                        <div class="position"><i class="ti-email"></i><a v-bind:href="tmp_ProfileData.email">{{tmp_ProfileData.email}}</a></div>
                                        <div class="position"><i v-if="tmp_ContactInfo != null &&  tmp_ContactInfo.mobileNo != null" class="ti-mobile"></i> {{tmp_ContactInfo.mobileNo}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card border-5">
                                <div class="user-card p-0">
                                    <h5 class="about-box m-0 text-left p-2">About Me</h5>
                                    <div class="p-3 float-left w-100">
                                        <h5>Specialization</h5>
                                        <div class="informations">
                                            <span v-if="TechSkills.length==0" class="badge p-2 mb-1">No records found</span>
                                            <span v-if="TechSkills.length!=0" v-for="(tech,idx) in TechSkills" v-bind:key="idx" class="badge p-1 m-1" :class="colours[idx%5]">{{tech.technology.technology}}</span>
                                            <!-- <span v-if="Skill.experience != 0"> - {{Skill.experience}} y</span> -->
                                        </div>
                                        <div v-if="tmp_ContactInfo!== null" class="w-100 float-left">
                                            <!-- <hr>
                                            <h5><i class="ti-location-pin"></i> Location</h5>
                                            <div class="information">
                                                <p> {{/*personalinfo.location*/}}-- </p>
                                            </div> 
                                            </div>
                                            <div class="w-100 float-left">
                                            <hr>
                                            <h5><i class="ti-briefcase"></i> Education</h5>
                                            <div class="information">
                                                <p >
                                                --
                                                </p>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="box-wrapper shadow-sm  bg-white rounded pos-rel main-search">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row no-gutters">
                                    <div class="col-md-12 pop-tab">
                                        <div class="driver-model">
                                            <div class="row no-gutters">
                                                <b-tabs v-model="currentTab" small card class="tab-list driver-tab-list tab-bg">
                                                    <b-tab title="Primary Info">
                                                        <PersonalInfo :profileId="profileId" :ProfileData="ProfileData" @loadPersInfo="loadPersInfo" ref="personalinfocomponent"></PersonalInfo>
                                                    </b-tab>
                                                    <b-tab title="Contact Info">
                                                        <ContactInfo :profileId="profileId" :ContactInfo="ContactInfo" @loadPersInfo="loadPersInfo" ref="contactInfocomponent"></ContactInfo>
                                                    </b-tab>
                                                    <b-tab title="Technology/Skills">
                                                        <TechnologySkills :industryId1="industryId" :profileId="profileId" @loadPersInfo="loadPersInfo" :Skills="TechSkills" ref="technologyskillscomponent"></TechnologySkills>
                                                    </b-tab>
                                                    <b-tab @click="refreshCalendar()" title="Availability">
                                                        <AvailabilityInfo v-if="showAvailabilityInfo" @loadPersInfo="loadPersInfo" :profileId="profileId" :availabilityinfo="availabilityinfo" ref="availabilityinfocomponent"></AvailabilityInfo>
                                                    </b-tab>
                                                    <b-tab title="Settings">
                                                        <Settings :settingsInfo="settingsInfo" :profileId="profileId" @loadPersInfo="loadPersInfo" ref="settingscomponent"></Settings>
                                                    </b-tab>
                                                </b-tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PersonalInfo from './blocks/profile/PersonalInfo';
import TechnologySkills from './blocks/profile/TechnologySkills';
import ContactInfo from './blocks/profile/ContactInfo';
import AvailabilityInfo from './blocks/profile/AvailabilityInfo';
import Settings from './blocks/profile/Settings';
import iziToast from 'izitoast';
import VueCircle from 'vue2-circle-progress'
export default {
    components: {
        PersonalInfo,
        TechnologySkills,
        ContactInfo,
        AvailabilityInfo,
        VueCircle,
        Settings
    },
    data() {
        return {
            industryId:null,
            currentTab: 0,
            // profileId: Number(this.$store.getters.currentUser.profileId),
            showProgress: false,
            ProfileData: {
                id: 0,
                interviewerCode: "",
                firstName: '',
                lastName: '',
                nickName: '',
                email: '',
                dateOfBirth: '',
                gender: '',
                position: '',
                imageName: '',
                imagePath: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
                image: '',
                industryId: null,
                profileCompletion: 0,
                recruiterId:'',
                referralId:'',
            },
            deleteparams:{
            	id: 0,
            	type:'interviewer'
            },
            tmp_ProfileData: Object,
            TechSkills: [],
            tmp_TechSkills: [],
            new_TechSkill: {
                id: 0,
                skillType: '',
                industryId: '',
                technologyId: '',
                experience: '',
                idx: 1,
                technologyName: '',
                skillSet: ''
            },
            settingsInfo: {
                timezone: "",
                accessLevel: 1
            },
            ContactInfo: Object,
            tmp_ContactInfo: Object,
            availabilityinfo: {},
            colours: ['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info'],
            showAvailabilityInfo: false,
            profileId: this.$store.getters.currentUser.profileId,

            fill: { gradient: ["#37A0E4", "#37A0E4", "#808080"] },
        }
    },
    mounted() {
        this.loadPersInfo();
    },
    methods: {
        progress(event, progress, stepValue) {
            //console.log(stepValue);
        },
        progress_end(event) {
            //console.log("Circle progress end");
        },
        getPrevUrl() {
            this.$router.go(-1);
        },
        createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;

            reader.onload = (e) => {
                this.ProfileData.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        delete_photo: function() {
            this.deleteparams.id=this.$store.getters.currentUser.profileId;
            axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/delete/candidate/photo',this.deleteparams)
                .then(response => {
                    if(response.data.status=="OK"){
                        iziToast.success({ title: 'Photo update', message: response.data.message, position: 'topRight' });
                        this.ProfileData.image="";
                        this.ProfileData.imageName="";
                        this.ProfileData.imagePath="";
                    }
                    else if(response.data.status=="EXPECTATION_FAILED"){
                    console.log(response);
                    iziToast.info({ title: 'Photo update', message: response.data.message, position: 'topRight' });}
                })
                .catch(function(error) {
                    console.log(error);
                });
           
        },
        upload_photo: function() {
            console.log(this.$refs.interviewer_profile_photo.files[0].name);
            if (!/\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(this.$refs.interviewer_profile_photo.files[0].name)) {
                iziToast.error({ title: 'Photo update', message: this.$config.FileFormatErr, position: 'topRight' });
                return false;
            }
            this.createImage(this.$refs.interviewer_profile_photo.files[0]);
            //this.ProfileData.imagePath=this.$refs.interviewer_profile_photo.files[0];
            //this.profilePhoto = this.$refs.addcandidate_profile_photo1.files[0];
            let formData = new FormData();
            formData.append('file', this.$refs.interviewer_profile_photo.files[0]);
            formData.append('id', this.$store.getters.currentUser.profileId);
            formData.append('type', 'interviewer');
            axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/uploadprofilephoto',
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response =>{
                    iziToast.success({ title: 'Photo update', message: response.data.message, position: 'topRight' });
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Photo update', message: response.data.message, position: 'topRight' });
                });
        },
        changeTab: function(tab) {
            this.currentTab = tab;
        },
        loadPersInfo: function() {
            this.showProgress = false;
            axios.get(this.$store.getters.getAPIBasePath + '/interviewerresource/view/interviewer/' + this.profileId)
                .then(response => {
                    let result = response.data;
                    if (!result.interviewer) {
                        iziToast.error({ title: 'Error Occured', message: this.$config.RecNotFou, position: 'topRight' });
                    } else {
                        this.ProfileData.id = result.interviewer.id;
                        this.ProfileData.interviewerCode = result.interviewer.interviewerCode;
                        this.ProfileData.firstName = result.interviewer.firstName;
                        this.ProfileData.lastName = result.interviewer.lastName;
                        this.ProfileData.nickName = result.interviewer.nickName;
                        this.ProfileData.industryId = result.interviewer.industryId;
                        this.ProfileData.email = result.interviewer.email;
                        if (result.interviewer.dateOfBirth != null)
                            this.ProfileData.dateOfBirth = this.formatDateString(result.interviewer.dateOfBirth);
                        if (result.interviewer.gender != null)
                            this.ProfileData.gender = result.interviewer.gender.toUpperCase();
                        this.ProfileData.position = result.interviewer.position;
                        this.ProfileData.imageName = result.interviewer.imageName;
                        this.ProfileData.referralId = result.interviewer.referralId;
                        this.ProfileData.recruiterId = result.interviewer.recruiterId;
                        this.ProfileData.imagePath = (result.interviewer.imagePath === '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195615/avatar.png' : result.interviewer.imagePath);
                        this.tmp_ProfileData = Object.assign({}, this.ProfileData);
                        this.industryId = (result.interviewer.interviewersSkill.length!=0?result.interviewer.interviewersSkill[0].industryId:null);
                        var techAndSkill = [];
                        if (result.interviewer.techList != null) {
                            for (var i = 0; i < result.interviewer.techList.length; i++) {
                                let skillData = result.interviewer.techList[i];
                                if (skillData.technologySkillData.length != 0) {
                                    techAndSkill.push({
                                        technology: { technology: skillData.technologyName, id: null },
                                        skills: []
                                    });
                                    for (var j = 0; j < skillData.technologySkillData.length; j++) {
                                        if (j == 0) {
                                            techAndSkill[i].technology.id = skillData.technologySkillData[j].technologyId;
                                        }
                                        techAndSkill[i].skills.push({
                                            skillId: skillData.technologySkillData[j].id,
                                            skillName: skillData.technologySkillData[j].skillName
                                        });
                                    }
                                }
                            }
                        }
                        this.TechSkills = techAndSkill;
                        this.tmp_TechSkills = Object.assign({}, this.TechSkills);
                        /* let phoneNo = (result.interviewer.interviewersContact != null) ? result.interviewer.interviewersContact.mobileNo : "";
                        let altNo = (result.interviewer.interviewersContact != null) ? result.interviewer.interviewersContact.altMobileNo : "";
                        if (phoneNo != null && phoneNo.startsWith("+")) {
                            let phoneData = phoneNo.split(" ");
                            result.interviewer.interviewersContact.countryCode = phoneData[0];
                            result.interviewer.interviewersContact.mobile = phoneData[1];
                        }else{
                            result.interviewer.interviewersContact.mobile = phoneNo;
                        }
                        if (altNo != null && altNo.startsWith("+")) {
                            let phoneData = altNo.split(" ");
                            result.interviewer.interviewersContact.altcountryCode = phoneData[0];
                            result.interviewer.interviewersContact.altMobile = phoneData[1];
                        }else{
                            result.interviewer.interviewersContact.altMobile = altNo;
                        } */
                        this.ContactInfo = Object.assign({}, result.interviewer.interviewersContact);
                        this.ContactInfo.countryId = (result.interviewer.interviewersContact.countryId== null ? '' : result.interviewer.interviewersContact.countryId);
                        this.tmp_ContactInfo = Object.assign({}, this.ContactInfo);
                        this.ProfileData.profileCompletion = (result.interviewer.profileCompletion == null ? 10 : result.interviewer.profileCompletion);
                        this.settingsInfo.timezone = result.interviewer.timezone == null ? '' : result.interviewer.timezone;
                        this.settingsInfo.accessLevel = result.interviewer.accessLevel == null ? '' : result.interviewer.accessLevel;
                        this.showProgress = true;
                    }
                });
        },
        updateSkills: function(Skills) {
            let self = this;
            self.TechSkills = [];
            $.each(Skills, function(key, value) {
                if (value.technologySkillSet != null)
                    self.TechSkills.push(value.technologySkillSet);
            });
        },
        refreshCalendar() {
            this.showAvailabilityInfo = true;
        }
    }
}

</script>
