<template>
    <div class="add-task">  
        <b-modal class="sample" no-close-on-backdrop no-close-on-esc centered no-enforce-focus @show="atShown" scrollable ref="createTaskModal" size="lg" :id="id"  :title="modalHeader"  @ok="addTask" @hidden="refreshTaskData" ok-title="Save Task">
                  <div class="row col-md-12 mx-0 px-0">
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="addTaskTitle" class="col-form-label">Title</label>
                        <input type="text" v-model="saveTask.title" class="form-control" id="addTaskTitle">
                        <label v-if="$v.saveTask.title.$error && !$v.saveTask.title.required" class="text-danger">Please enter Task Title</label>
                        <label v-if="$v.saveTask.title.$error && !$v.saveTask.title.minLength" class="text-danger">Task Title must have at least {{$v.saveTask.title.$params.minLength.min}} letters.</label>
                        <label v-if="$v.saveTask.title.$error && !$v.saveTask.title.maxLength" class="text-danger">Task Title must not exceed {{$v.saveTask.title.$params.maxLength.max}} letters.</label>
                    </div>
                    <div v-if="false" class="form-group col-md-3 col-sm-12">
                        <label for="taskCode" class="col-form-label">Task Code</label>
                        <input type="text" v-model="saveTask.taskCode" class="form-control" id="taskCode">
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="addTaskSprint" class="col-form-label">Task Group</label>
                        <select v-model="saveTask.taskGroupId" name="addTaskSprint" id="addTaskSprint" class="form-control">
                            <option value="">Select</option>
                            <option v-for="Option in options.sprintList" :value="Option.id">{{ Option.name }}</option>
                        </select>
                        <label v-if="$v.saveTask.taskGroupId.$error && !$v.saveTask.taskGroupId.required" class="text-danger">Please choose the Task Group</label>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="taskType" class="col-form-label">Task Type</label>
                        <select v-model="saveTask.taskType" name="taskType" id="taskType" class="form-control">
                            <option value="">Select</option>
                            <option v-for="Option in options.taskTypeList" :value="Option.id">{{ Option.name }}</option>
                        </select>
                        <label v-if="$v.saveTask.taskType.$error && !$v.saveTask.taskType.required" class="text-danger">Please choose the Task Type</label>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="addTaskPriority" class="col-form-label">Priority</label>
                        <select v-model="saveTask.priorityId" name="addTaskPriority" id="addTaskPriority" class="form-control">
                            <option value="">Select</option>
                            <option v-for="Option in options.priorityList" :value="Option.id">{{ Option.name }}</option>
                        </select>
                        <!-- <label v-if="$v.saveTask.priorityId.$error && !$v.saveTask.priorityId.required" class="text-danger">Priority is required</label> -->
                    </div>
                </div>
                <div class="row col-md-12 mx-0 px-0">
                    
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="addTaskStartDate" class="col-form-label">Start Date</label>
                        <input type="date" v-model="saveTask.startDate" @click="validDate('#addTaskStartDate')" class="form-control" id="addTaskStartDate" />
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="addTaskDueDate" class="col-form-label">Due Date</label>
                        <input type="date" v-model="saveTask.dueDate" @click="validDate('#addTaskDueDate')" class="form-control" id="addTaskDueDate" />
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="addTaskAssignedTo" class="col-form-label">Assigned To</label>
                        <select v-model="saveTask.assignedTo" name="addTaskAssignedTo" class="form-control">
                            <option value="">Select</option>
                            <option v-for="Option in options.recruitersList" :value="Option.id">{{ Option.recruiterName }}</option>
                        </select>
                        <label v-if="$v.saveTask.assignedTo.$error && !$v.saveTask.assignedTo.required" class="text-danger">Assignee is required</label>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="relatedTo" class="col-form-label">Reference</label>
                        <input type="text" name="relatedTo" id="relatedTo" v-model="saveTask.taskReference" class="form-control" />
                    </div>
                </div>
                <div class="form-group col-md-12 col-sm-12">
                    <label for="addTaskDescription" class="col-form-label">Description</label>
                    <editor id="addTaskDescription" api-key="yn4wx6bfcohfz6bwlu5k4urno027lgfkcpxolz0f6k4kku01" :toolbar="editor.toolbarOptions" :init="editor.initOptions" v-model="saveTask.description"></editor>
                    <label v-if="$v.saveTask.description.$error && !$v.saveTask.description.maxLength" class="text-danger">Description must not exceed {{$v.saveTask.description.$params.maxLength.max}} letters.</label>
                </div>
                <div class="taskAttachments col-md-12 mb-4">
                    <div class="card">
                        <div class="card-body">
                            <label><b>Attachments</b></label>
                            <div>
                                <a href="javascript:" class="float-right button-add" @click="addInput"><i class="fa fa-plus-circle pr-2 action-link"></i>Add More</a>
                            </div>
                            <div class="d-flex flex-row col-md-11 col-sm-11 col-lg-11 pt-2" v-for="(input, index) in addMore">
                                <input type="file" class="form-control mx-2 h-auto flex-fill upload" ref="file" id="file" placeholder="Add Attachments"  v-on:change="handleMultiFileUpload($event)" :key="fileInputKey">
                                <!-- <b-btn variant="primary" @click="addInput" class="ml-2 flex-fill">Add</b-btn> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="taskCheckList col-md-12 mb-4">
                    <div class="card">
                        <div class="card-body">
                            <label><b>Checklist</b></label>
                            <div class="d-flex flex-column">
                                <label v-for="(taskCheckList,index) in saveTask.taskCheckList" class="checklist px-2 m-0">
                                    <input type="checkbox"> {{ taskCheckList.title }}
                                    <!-- <span></span> -->
                                </label>
                            </div>
                            <div class="d-flex flex-row">
                                <input type="text" class="form-control mx-2 flex-fill" v-model="elements.checkListTag" placeholder="Add an item..." @keyup.enter="pushChecklistTag">
                                <b-btn variant="primary" @click="pushChecklistTag" class="ml-2 flex-fill">Save</b-btn>
                            </div>                            
                            <label v-if="$v.elements.checkListTag.$error && !$v.elements.checkListTag.minLength" class="text-danger">Checklist must have at least {{$v.elements.checkListTag.$params.minLength.min}} letters.</label>
                            <label v-if="$v.elements.checkListTag.$error && !$v.elements.checkListTag.maxLength" class="text-danger">Checklist must not exceed {{$v.elements.checkListTag.$params.maxLength.max}} letters.</label>
                        </div>
                    </div>
                </div>      
        </b-modal>
    </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue';
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect';
import iziToast from 'izitoast';
export default {
    props: {
        options: Object,
        editor: Object,
        selectedInfo: Object,
        taskData: Object,
        id:{type:String, default:'createTaskModal'},
    },
    components: {
        Editor,
        Multiselect
    },
    data() {
        return {
            file:'',
            addMore: [],
            allowedfiletypes: ['pdf', 'doc', 'docx', 'DOCX', 'rtf'],
            fileInputKey: 0,
            elements: {
                checkListTag: ""
            },
            modalHeader: "",
            attachments: [],
            saveTask: {
                project: this.selectedInfo.project,
                taskGroupId: this.selectedInfo.taskGroupId,
                taskCode: "",
                taskType: "",
                stageId: "",
                priorityId: "",
                title: "",
                description: "",
                assignedTo: "",
                userType: "recruiter",
                startDate: "",
                dueDate: "",
                taskReference: "",
                taskCheckList: [],
                
            }
        }
    },
    validations: {
        saveTask: {
            taskGroupId: { required },
            title: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(50)
            },
            taskType: { required },
            description: {
                maxLength: maxLength(2000)
            },
            assignedTo: { required }
        },
        elements: {
            checkListTag: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(100)
            }
        }
    },
    mounted() {
         this.addMore.push(this.addMore.length + 1);
        /*if(!this.options.sprintList.length) {
        	iziToast.info({title: 'Info', message: "Please choose the project", position: 'topRight'});
        }*/
        this.loadDate();
    },
    methods: {   
        handleMultiFileUpload(event) {
            for (let file of event.target.files) {
                try {
                    let reader = new FileReader();
                    reader.readAsDataURL(file); // Not sure if this will work in this context.
                    this.attachments.push(file);
                } catch {}
            }
        },
        loadDate() {
            this.saveTask.startDate = new Date().toISOString().substr(0, 10);
            this.saveTask.dueDate = new Date().toISOString().substr(0, 10);
        },
        refreshTaskData: function() {
            this.$v.saveTask.$reset();
            this.saveTask.stageId = "";
            this.saveTask.priorityId = "";
            this.saveTask.title = "";
            this.saveTask.description = "";
            this.saveTask.assignedTo = "";
            this.saveTask.userType = "recruiter";
            this.saveTask.taskCheckList = [];
            this.saveTask.taskType = "";
            this.addMore.length = 1;
            this.fileInputKey++;
            
        },
        refreshTaskAttach: function() {
            this.addMore.length = 1;
            this.attachments = [];
            this.fileInputKey++;
        },
        validateAddTaskData: function() {
            this.$v.saveTask.$touch();
            if (this.$v.saveTask.$invalid) {
                return false;
            }
            return true;
        },
        pushChecklistTag: function() {
            this.$v.elements.checkListTag.$touch();
            if (this.$v.elements.checkListTag.$invalid) {
                return false;
            }
            this.saveTask.taskCheckList.push({ title: this.elements.checkListTag });
            this.elements.checkListTag = "";
        },
        addInput: function() {
            this.addMore.push(this.addMore.length + 1)
        },
        addTask: function(bvModalEvt) {
            if (!this.validateAddTaskData()) {
                bvModalEvt.preventDefault();
                return false;
            }
            axios.post(this.$store.getters.getAPIBasePath + '/taskresource/add/task', this.saveTask)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        let taskId = response.data.id;
                         if (this.attachments != null && this.attachments.length > 0) {
                                let formData = new FormData();

                                for (const row of Object.keys(this.attachments)) {
                                    formData.append('files', this.attachments[row]);
                                }
                    
                                formData.append('id', taskId);

                                axios.post(this.$store.getters.getAPIBasePath + '/taskresource/uploadattachment/multiple', formData, {
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                    .then(response => {
                                        let result = response.data;
                                        console.log("result",result)
                                        this.refreshTaskAttach();
                                        // if (result.status == 'ACCEPTED') {
                                           
                                        //     iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                                        // } else {
                                        //     iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                                        // }
                                    })
                                    .catch(error => {
                                        this.unAuthorized(error);
                                        console.log(error.data);
                                    });
                            }
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                        this.$refs.createTaskModal.hide();
                        this.$emit('loadTasks', this.saveTask.taskGroupId);
                        this.refreshTaskData();
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                });
        },
        getSprintList: function(project_info) {
            axios.get(this.$store.getters.getAPIBasePath + '/projectresource/list/sprint/' + project_info.id)
                .then(response => {
                    if (response.data.status) {
                        this.options.sprintList = response.data.sprintList;
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        atShown: function(bvModalEvt) {
            this.getTaskCode();
            
            if (this.selectedInfo.mode == 'duplicate') {       
                this.saveTask = this.taskData;
            } else {
                this.refreshTaskData();
            }
            this.saveTask.taskGroupId = this.selectedInfo.taskGroupId;
            this.saveTask.project = this.selectedInfo.project;
        },
        getTaskCode() {
            this.loadDate();
            axios.post(this.$store.getters.getAPIBasePath + '/commonresource/autoincrementcode/task')
                .then(response => {
                    if (response.data.status) {
                        this.saveTask.taskCode = response.data.code;
                        this.modalHeader = "Create Task "+ " - "+this.saveTask.taskCode;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        validDate(id) {
            var dtToday = new Date();

            var month = dtToday.getMonth() + 1;
            var day = dtToday.getDate();
            var year = dtToday.getFullYear();
            if (month < 10)
                month = '0' + month.toString();
            if (day < 10)
                day = '0' + day.toString();

            var maxDate = year + '-' + month + '-' + day;
            $(id).attr('min', maxDate);
        }
    }
}

</script>
