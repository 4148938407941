<template>
    <div id="main">
        <div class="container-fluid" id="quick-search">
            <div class="row h-50">
                <div class="col-sm-12 h-100 d-table">
                    <div class="d-table-cell align-middle login_div">
                        <div class="col-md-4 offset-md-4">
                            <div class="interviewer-register-block">
                                <div v-if="!showForm" class="box-wrapper shadow-sm p-5 mb-4 bg-white rounded pos-rel">
                                    <h3 class="text-color text-center" style="text-transfomargin-top:10px">Confirmation Message</h3>
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col-sm-12 clientinfo">
                                                <p style="text-align: center;">{{message}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="approval" :onYes="confirmApproval" :onNo="cancelApproval" title="Approval" :message="title" />
    </div>
</template>
<script>
export default {
    computed: {
        key() {
            return this.$route.params.key;
        },
        type() {
            return this.$route.params.type;
        },
    },
    data() {
        return {
            message: "",
            showForm: true,
            showConfirm: false,
            title: "Are you sure you want to accept this Offer?"
        }
    },

    mounted() {
        $('#approval').modal('show');
        if (this.type == 'accept') {
            this.title = "Are you sure you want to accept this Offer?";
        } else if (this.type == 'reject') {
            this.title = "Are you sure you want to reject this Offer?";
        }
    },
    methods: {
        loadCheckAccessToken(key) {

            axios.get(this.$store.getters.getAPIBasePath + '/setldresource/update/public/confirmoffer/' + this.key)
                .then(response => {
                    let result = response.data;
                    if (result.status) {
                        this.message = result.message;
                    } else {
                        this.message = result.message;
                    }
                    this.showForm = false;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        confirmApproval: function() {
            this.loadCheckAccessToken(this.key);
        },
        cancelApproval: function(){
            this.message = "Cancel the process...";
            this.showForm = false;
        }
    }
}

</script>
