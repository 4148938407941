<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="card-header p-2">Add Interviewers</div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div class="row ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>First Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group required-field-block">
                                            <input type="text" name="firstName" maxlength="45" size="30" v-model="interviewerinfo.firstName" class="form-control " :class="{ 'is-invalid': $v.interviewerinfo.firstName.$error }">
                                            <span class="required-icon"></span>
                                        </div>
                                        <label v-if="$v.interviewerinfo.firstName.$error && !$v.interviewerinfo.firstName.minLength" class="text-danger">First Name must have at least {{$v.interviewerinfo.firstName.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.interviewerinfo.firstName.$error && !$v.interviewerinfo.firstName.required" class="text-danger">Please enter First Name</label>
                                        <label v-if="$v.interviewerinfo.firstName.$error && !$v.interviewerinfo.firstName.maxLength" class="text-danger">First Name must not exceed {{$v.interviewerinfo.firstName.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Last Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group ">
                                            <input type="text" name="lastName" maxlength="45" size="30" v-model="interviewerinfo.lastName" class="form-control">
                                            <span class="required-icon"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group required-field-block">
                                            <input type="text" name="primaryEmail" maxlength="80" v-model="interviewerinfo.email" class="Submitaddress form-control" :class="{ 'is-invalid': $v.interviewerinfo.email.$error }" @blur="$v.interviewerinfo.email.$touch">
                                            <span class="required-icon"></span>
                                        </div>
                                        <label v-if="$v.interviewerinfo.email.$error && !$v.interviewerinfo.email.required" class="text-danger">Please enter Email</label>
                                        <label v-if="$v.interviewerinfo.email.$error && !$v.interviewerinfo.email.email" class="text-danger">Please enter a valid email</label>
                                        <label v-if="emailexists" class="text-danger">Email already exists</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Mobile</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <PhoneNumber ref="phoneNumber" :value="interviewerinfo.interviewersContact.mobileNo" :mandatory="phoneMandatory"  @phone-input="phoneControlInput" :isValidcheck="isValidCheck" />                                     
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Industry</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="interviewerinfo.industryId" class="form-control">
                                            <option value="" selected>Select</option>
                                            <option v-for="(Industry, index) in industries" :value="Industry.id" v-bind:key="index">{{Industry.industryName}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Scope</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="interviewerinfo.scope" class="form-control">
                                            <option value="" selected>Select</option>
                                            <option v-for="(scope, index) in scopeList" :value="scope.id" v-bind:key="index">{{scope.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ten-columns">
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Specialization / Tools and Tech</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <multiselect v-model="technology_value" :options="technologies" name="technology_value" label="technology" track-by="id" select-label="" deselect-label="" @select="loadSkills" class=""></multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7 col-sm-12 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Skills</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <multiselect v-model="interviewerinfo.interviewersSkill" :options="skills" name="interviewerinfo.interviewersSkill" label="skillName" track-by="skillId" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" @input="onChange"></multiselect>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 pt-2 mt-3 text-right">
                        <router-link class="btn btn-secondary mr-2 text-white" :to="{ name: 'referralinterviewers' }">Cancel</router-link>
                        <a href="Javascript:void(0);" @click="saveInterviewer" class="btn btn-primary ">Invite</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect
    },
    computed: {
        dobDates() {
            let date = new Date();
            date.setFullYear(date.getFullYear() - 15);
            return {
                from: date
            };
        },
        interviewerId() {
            if (this.$route.params.interviewerid)
                return this.$route.params.interviewerid;
            else return 0;
        }
    },
    data() {
        return {
            formErrors: [],
            elements: {
                submitted: false
            },
            phoneMandatory: true,
            isValidCheck:false,
            emailexists: false,
            // technologies:this.$store.getters.getTechOptions,
            scopeList: this.$store.getters.getInterviewerScopeList,
            industries: this.$store.getters.getIndustries,
            technologies: [], //dropdown options
            skills: [], //dropdown options
            technology_value: "", //dropdown value
            availableDates: {
                to: new Date(Date.now() - 864000)
            },
            interviewerinfo: {
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                phoneNo: "",
                blockpersonaltab: 0,
                viewonly: 0,
                technology: "",
                scope: 1,
                industryId: "",
                interviewersSkill: [], //dropdown value
                interviewersContact: {
                    countryCode: "+1",
                    mobileNo: "",
                    mobile:""
                }
            }
        }
    },
    validations: {
        interviewerinfo: {
            firstName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },  
            email: {
                required,
                email,
            }
        }
    },
    mounted() {
        this.loadTechnologyList();
    },
    methods: {
        emailValidation(email) {

            if (email === '') return true;
            let ajaxdata = { email: email };
            if (this.interviewerinfo.profileid != undefined) {
                ajaxdata.id = this.interviewerinfo.profileid;
            }
            return axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/checkemailavailability', ajaxdata)
                .then(response => {
                    if (response.data.status) {
                        return true;
                    }
                    return false;
                })
                .catch(error => {
                    return false;
                });
        },
        loadTechnologyList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/technologylist')
                .then(response => {
                    this.technologies = response.data.technologylist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadSkills(selected_technology) {
            this.skills = [];
            if (selected_technology.settingsTechnologySkill)
                this.skills = selected_technology.settingsTechnologySkill;
            else
                this.skills = [];
        },
        async saveInterviewer() {
            this.elements.submitted = true;
            this.$v.$touch();
            if(!this.isValidCheck && this.$refs.phoneNumber.validatePhone()){
                if (!this.$v.$invalid) {
                    let isexists = await this.emailValidation(this.interviewerinfo.email);
                    if (!isexists) {
                        this.emailexists = true;
                        iziToast.info({ message: this.$config.EmailExistErr, position: 'topRight' });
                        return;
                    }

                    axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/update/interviewer/' + this.interviewerId, this.interviewerinfo)
                        .then(response => {
                            let result = response.data;
                            if (!response.status) {
                                iziToast.error({
                                    title: 'Error',
                                    message: result.message,
                                    position: 'topRight'
                                });
                            } else {
                                iziToast.success({
                                    title: 'Success',
                                    message: this.$config.IntDataSuc,
                                    position: 'topRight'
                                });
                                this.$router.push('/referral/interviewers');
                            }
                        })
                        .catch(error => {
                            this.formErrors = error.response.data.validationErrorList;
                            iziToast.error({
                                title: 'Error',
                                message: this.$config.CreInterErr,
                                position: 'topRight'
                            });
                        });
                
                }
            }
        },
        onChange() {
            // console.log(this.interviewerinfo.interviewersSkill);
        },
        phoneControlInput:function(value,isValid){
            this.isValidCheck = isValid;
            this.interviewerinfo.interviewersContact.mobileNo = value;
        },
    }
}

</script>
