<template>
    <div :class="{ 'viewonly' : technologyinfo.viewonly == 1}">
        <div class="card">
            <div class="card-header p-2">Technology/Skills Expertise </div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <h6>Industry</h6>
                                </div>
                            </div>
                            <div class="row mb-10">    
                                <div class="col-md-3 col-sm-12 col-12">
                                    <div class="col-md-12">
                                        <select v-model="industryId" @change="loadTechnologyList()" class="form-control">
                                            <option value="" selected>Select</option>
                                            <option v-for="(Industry, index) in industries" :value="Industry.id" v-bind:key="index">{{Industry.industryName}}</option>
                                        </select>
                                    </div>    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <h6>Specialization/Technology</h6>
                                </div>
                                <div class="col">
                                    <h6>Skills</h6>
                                </div>
                                <div  class="col-md-2">
                                    <h6>Is Primary</h6>                 
                                </div>
                                <div v-if="false" class="col-md-2">
                                    <h6>Primary Exp.(# of Years)</h6>
                                </div>
                                <div v-if="false" class="col-md-2">
                                    <h6>Industry</h6>
                                </div>
                                <div class="col-md-1 col-sm-2"></div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div v-if="!showTechDropDown" class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input class="form-control" type="text" v-model="technologyName" placeholder="Enter Technology" @keypress="blockSpecialChar($event)" />
                                            <a href="javascript:" class="tbl-add-icon" style="float:left;" @click="setTechDropDown"><span class="input-group-addon"><i tite="existing" class="ti-settings"></i></span></a>
                                        </div>
                                    </div>
                                    <div v-if="showTechDropDown" class="col-md-12 col-sm-12 col-12">
                                        <multiselect v-model="techSel" :options="technologies" name="technologies" label="technology" track-by="id" select-label="" deselect-label="" @select="loadSkills"  class="" style="width:85%; float:left;" :max-height="600" placeholder="Select Technology"></multiselect>
                                        <a href="javascript:" class="tbl-add-icon" style="float:left;" @click="setTechTextBox"><span class="input-group-addon"><i title="cancel" class="fa fa-remove"></i></span></a>
                                    </div>
                                </div>
                                <div class="col">
                                    <div v-if="!showSkillDropDown" class="col-md-12 col-sm-12 col-12">
                                        <!--  <input class="form-control" type="text" v-model="skillName" data-toggle="modal" data-target="#CandidateAvailability"  /> -->
                                        <MultiItemInput class="form-control" id="skillInput" :items="skillName" style="width:85%; float:left;" placeholder="Enter Skill" @keypress="blockSpecialChar($event)"/>
                                        <a href="javascript:" class="tbl-add-icon" style="float:left;" @click="setSkillDropDown"><span class="input-group-addon"><i title="existing" class="ti-settings"></i></span></a>
                                    </div>
                                    <div v-if="showSkillDropDown" class="col-md-12 col-sm-12 col-12">
                                        <multiselect v-model="skillSel" :options="skills" name="skills" label="skillName" track-by="skillId" select-label="" deselect-label="" class="" style="width:85%; float:left;" :max-height="600" placeholder="Select Skill" :multiple="true" :close-on-select="false"></multiselect>
                                        <a href="javascript:" class="tbl-add-icon" style="float:left;" @click="setSkillTextBox"><span class="input-group-addon"><i title="cancel" class="fa fa-close"></i></span></a>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <input class="form-check-input" type="checkbox" id="primaryskill" @click="primarySkill($event)" :checked="isPrimary">
                                    <label class="form-check-label" for="primaryskill"></label>
                                </div>
                                <div v-if="false" class="col-md-2">
                                    <multiselect v-model="exprSel" :options="experience" name="experience" label="label" track-by="value" select-label="" deselect-label="" class="" :max-height="600" placeholder="Select Experience"></multiselect>
                                </div>
                                <div v-if="false" class="col-md-2">
                                    <multiselect v-model="industrySel" :options="industries" name="industry" label="industryName" track-by="id" select-label="" deselect-label="" class="" :max-height="600" placeholder="Select Industry"></multiselect>
                                </div>
                                <div class="col-md-1 col-sm-2">
                                    <a v-if="techEditIndex<0" class="addskills" href="javascript:" @click="addMoreTechInfo">
                                        <span title="add" class="fa fa-plus-square fa-lg"></span></a>
                                    <a v-if="techEditIndex!=-1" style="float:left;" href="javascript:" class="tbl-add-icon" @click="updateSkillDetails(1)"><span class="input-group-addon"><i class="ti-check"></i></span></a>
                                    <a v-if="techEditIndex!=-1" style="float:left;" href="javascript:" class="tbl-add-icon" @click="updateSkillDetails(0)"><span class="input-group-addon"><i class="ti-close"></i></span></a>
                                </div>
                            </div>
                            <div v-if="notEmptyTech(technologyinfo)" class="row mt-10" v-for="(techInfoOne,index) in technologyinfo" v-bind:key="index">
                                <div class="col-md-3">
                                    <div class="form-control">
                                        <span v-if="techInfoOne.technology!= null"> {{techInfoOne.technology.technology}}</span>
                                        <span> {{techInfoOne.technologyName}}</span>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-control skillList" v-if="techInfoOne.skills != null && techInfoOne.skills.length != 0">
                                        <ul>
                                            <li v-for="(item1, index1) in techInfoOne.skills" :key="index1" class="item rounded"><span class="text">{{ item1.skillName }}</span> <a href="javascript:;" @click="removeSkill(index, index1)"><i class="ti-close item-close-icon1"></i></a></li>
                                        </ul>
                                    </div>
                                    <div class="form-control skillList" v-if="techInfoOne.skills != null &&  techInfoOne.skills.length == 0">
                                        <ul>
                                            <li v-for="(item1, index1) in techInfoOne.skillName" :key="index1" class="item rounded"><span class="text">{{ item1 }}</span> <a href="javascript:;" @click="removeSkill1(index, index1)"><i class="ti-close item-close-icon1"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <input class="form-check-input" disabled type="checkbox" :checked="techInfoOne.isPrimary"  />                                   
                                    <label class="form-check-label" ></label>       
                                </div>
                                <div v-if="false" class="col-md-2">
                                    <div class="form-control">{{techInfoOne.experience.label}}</div>
                                </div>
                                <div v-if="false" class="col-md-2">
                                    <div class="form-control">{{techInfoOne.industry.industryName}}</div>
                                </div>
                                <div class="col-md-1 col-sm-2">
                                    <a style="float:left;" href="javascript:" class="tbl-add-icon" @click="removeOneTechDetail(index)"><span class="input-group-addon"><i class="ti-trash"></i></span></a>
                                    <a style="float:left;" href="javascript:" class="tbl-add-icon" @click="editOneTechDetail(index)"><span class="input-group-addon"><i class="ti-pencil"></i></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MultiInputsDialog id="addNewTech" title="Add new technologies" inputPlaceholder="Enter new technology" :inputItems="newTechs" :onSave="saveNewTech" :onCancel="cancelNewTech" />
        <MultiInputsDialog id="addNewSkill" title="Add new skills" inputPlaceholder="Enter new skill" :inputItems="newSkills" :onSave="saveNewSkills" :onCancel="cancelNewSkills" />
    </div>
</template>
<script>
import MultiInputsDialog from '../../../plugins/MultiInputsDialog';
import MultiItemInput from '../../../plugins/multiItemInput';
import Multiselect from 'vue-multiselect';
import { required } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {
    props: {
        technologyinfo: { type: Array, default: [] },
        techinfo: { type: Array, default: [] }
    },
    components: {
        Multiselect,
        MultiInputsDialog,
        MultiItemInput
    },
    watch: {
        'technologyinfo': {
            handler: function(value) {
                if(value.length!=0){
                    this.industryId = value[0].industryId;
                    this.loadTechnologyList();
                }else if(this.industryId == 1){
                    this.industryId = "1";
                    this.loadTechnologyList();
                }else{
                    this.industryId = "";
                    this.loadTechnologyList();
                }
            },
            deep: true,
            immediate: true
        },
        'industryId': {
            handler: function(value) {
                if(this.technologyinfo.length!=0){
                    if(this.technologyinfo[0].industryId != value){
                        this.technologyinfo.splice(0,this.technologyinfo.length);
                    }
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            industryId:"",
            showTechDropDown: false,
            showSkillDropDown: false,
            newTechs: [],
            newSkills: [],
            techEditIndex: -1,
            industrySel: '',
            exprSel: '',
            techSel: '',
            skillSel: [],
            skills: [],
            experience: [],
            technologies: [],
            industries: this.$store.getters.getIndustries,
            technologyName: '',
            skillName: [],
            isPrimary: false
        }
    },
    mounted() {
        this.generateExperience();
        this.loadTechnologyList();
    },
    methods: {
        primarySkill : function(event) {
        if (event.target.checked) {
            this.isPrimary = true
        }else{
            this.isPrimary = false
        }
      },
        setTechDropDown: function() {
            this.showTechDropDown = true;
            this.showSkillDropDown = true;
        },
        setTechTextBox: function() {
            this.techSel = "";
            this.skillSel = "";
            this.showTechDropDown = false;
            this.showSkillDropDown = false;
        },
        setSkillDropDown: function() {
            this.showTechDropDown = true;
            this.showSkillDropDown = true;
        },
        setSkillTextBox: function() {
            this.techSel = "";
            this.skillSel = "";
            this.showTechDropDown = false;
            this.showSkillDropDown = false;
        },
        loadTechnologyList() {
            if(this.industryId){
            axios.get(this.$store.getters.getAPIBasePath + '/technologyresource/get/industry/' + this.industryId)
                .then(response => {
                    this.technologies = response.data.technology;
                    //this.newTechs = this.technologies.map(tech => tech.technology);
                })
                .catch(error => {
                    console.log(error);
                });
            }
        },
        cancelNewSkills() {
            this.newSkills = [];
        },
        cancelNewTech() {
            this.newTechs = [];
        },
        saveNewSkills() {
            var skillItems = [];
            var match = false;
            this.skills.map(skill => {
                if (!this.newSkills.includes(skill.skillName)) { //is deleted
                    skill.isDeleted = 1;
                    skillItems.push(skill);
                }
            });
            this.newSkills.map(newSkill => { //add new items
                match = false;
                this.skills.map(skill => {
                    if (newSkill == skill.skillName) {
                        match = true;
                        return true;
                    }
                });
                if (!match) {
                    skillItems.push({
                        categoryId: null,
                        technologyId: this.techSel.id,
                        skillName: newSkill,
                        isDeleted: 0,
                        id: null,
                        technologyName: this.technologyName,
                        skillName: this.skillName
                    });
                }
            });

            /*var idx = 0;
            var match = false;
            for(var i=0; i<this.skills.length; i++)
            {
                idx= this.newSkills.indexOf(this.skills[i].skillName);
                if(idx<0)
                {                       
                    skillItems.push({
                        categoryId:null,
                        technologyId:this.techSel.id,
                        skillName:this.skills[i].skillName,
                        isDeleted:1,
                        id:this.skills[i].skillId
                    });
                }
            }
                
            for(var i=0; i<this.newSkills.length; i++)
            {
                match = false;
                for(var j=0; j<this.skills.length; j++)
                {
                    if(this.skills[j].skillName == this.newSkills[i])
                    {
                        match = true;
                        break;                        
                    }
                }

                if(!match)
                {                       
                    skillItems.push({
                        categoryId:null,
                        technologyId:this.techSel.id,
                        skillName:this.newSkills[i],
                        isDeleted:0,
                        id:null
                    });
                }
            }*/

            if (skillItems != null && skillItems.length) {
                axios.post(this.$store.getters.getAPIBasePath + '/technologyresource/add/skill', { technologySkill: skillItems })
                    .then(response => {
                        /*for(var i=0; i<skillItems.length; i++)
                        {
                            if(skillItems[i].isDeleted == 1)
                            {
                                match = false;                              
                                for(var j=0; j<this.technologyinfo.length; j++)
                                {                                
                                    if(skillItems[i].technologyId == this.technologyinfo[j].technology.id)
                                    {                                       
                                        this.technologyinfo.splice(j--, 1);
                                        match = true;
                                        break;                                    
                                    }
                                }

                                if(match) break;
                            }
                        }*/
                        this.techEditIndex = -1;
                        this.skillSel = [];
                        this.techSel = '';
                        this.skill = [];
                        this.exprSel = '';
                        this.industrySel = '';
                        this.skills = [];
                        this.loadTechnologyList();

                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            }
        },
        saveNewTech() {
            var newItems = [];
            newItems.length = 0;
            this.newTechs.map(newTech => {
                newItems.push({ technology: newTech });
            });
            if (skillItems != null && skillItems.length) {
                axios.post(this.$store.getters.getAPIBasePath + '/technologyresource/add/clienttechnology', { technologyList: newItems })
                    .then(response => {
                        this.techEditIndex = -1;
                        this.skillSel = [];
                        this.techSel = '';
                        this.skill = [];
                        this.exprSel = '';
                        this.industrySel = '';
                        this.loadTechnologyList();
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            }
            this.newTechs = [];
        },
        updateSkillDetails(mode) {
            if (mode == 1) {
                this.technologyinfo[this.techEditIndex] = {
                    technology: { id: this.techSel.id, technology: this.techSel.technology },
                    skills: this.skillSel,
                    experience: this.exprSel,
                    industry: this.industrySel,
                    technologyName: this.technologyName,
                    skillName: this.skillName,
                    isPrimary: this.isPrimary,
                    industryId: this.industryId
                
                } 
                }  
             
            this.techEditIndex = -1;
            this.skills = [];
            this.skillSel = [];
            this.techSel = '';
            this.exprSel = '';
            this.industrySel = '';
            this.isPrimary = false;
            this.technologyName = ''
            this.skillName = [];
        },
        removeSkill(index, index1) {
            this.technologyinfo[index].skills.splice(index1, 1);
        },
        removeSkill1(index, index1) {
            this.technologyinfo[index].skillName.splice(index1, 1);
        },
        editOneTechDetail(index) {
            var allTech = [];
            var skillname = []; 
            for(var i=0; i< this.technologies.length;i++){
                allTech.push(this.technologies[i].technology)
            }
            if(allTech.includes(this.technologyinfo[index].technology.technology)) {
                this.showTechDropDown = true;
                this.showSkillDropDown = true;
                this.techSel = this.technologyinfo[index].technology;
                this.skillSel = this.technologyinfo[index].skills;
               }else{
                this.showTechDropDown = false;
                this.showSkillDropDown = false;
                this.technologyName = (this.technologyinfo[index].technologyName == undefined? this.technologyinfo[index].technology.technology : this.technologyinfo[index].technologyName);
                for(var i= 0; i<this.technologyinfo[index].skills.length ;i++){
                    skillname.push(this.technologyinfo[index].skills[i].skillName)     
                }
                this.skillName = (skillname !=undefined &&  skillname.length !=0? skillname: this.technologyinfo[index].skillName);
            }
            this.techEditIndex = index;
            this.techSkill = this.technologyinfo[index].technology;
            this.isPrimary = this.technologyinfo[index].isPrimary;
            this.skills = [];
            let result =  this.filterSkillsByTechnology(this.techSkill.id);
            if(result[0] != null){
                this.skills = result[0].settingsTechnologySkill;
            }           
        },
        filterSkillsByTechnology: function(techId) {
            return this.technologies.filter(function(data) {
                if (data.id == techId) {                  
                    return data;
                }
            });
        },
        removeOneTechDetail(index) {
            if (index == this.techEditIndex)
                this.updateSkillDetails(0);
            this.techEditIndex = -1;
            this.technologyinfo.splice(index, 1);
        },
        addMoreTechInfo() {

            if (this.techSel == '' && this.skills.length == 0 && this.exprSel == '' && this.industrySel == '' &&
                this.technologyName == '' && this.skillName == '')
                return false;

            var searchIdx = -1;
            if (this.techSel != null && this.techSel.id != undefined) {
                for (var i = 0; i < this.technologyinfo.length; i++) {
                    if (this.technologyinfo[i].technology.id == this.techSel.id) {
                        searchIdx = i;
                        break;
                    }
                }
            } else {
                for (var i = 0; i < this.technologyinfo.length; i++) {
                    if (this.technologyinfo[i].technology.technology == this.technologyName) {
                        searchIdx = i;
                        break;
                    }
                }
            }
            let techInfoTmp = '';
            if (searchIdx < 0) {
                techInfoTmp = {
                    technology: (this.techSel != null ? { id: this.techSel.id, technology: this.techSel.technology } : { id: undefined, technology: this.technologyName }),
                    skills: (this.skillSel != null ? this.skillSel : this.skillName),
                    experience: this.exprSel,
                    industry: this.industrySel,
                    technologyName: this.technologyName,
                    skillName: this.skillName,
                    isPrimary: this.isPrimary,
                    industryId: this.industryId
                }

                if (this.notNullTech(techInfoTmp)) {
                    this.technologyinfo.push(techInfoTmp);
                }
            } else {
                this.technologyinfo[searchIdx] = {
                    technology: (this.techSel != null ? { id: this.techSel.id, technology: this.techSel.technology } : { id: undefined, technology: this.technologyName }),
                    skills: (this.skillSel != null ? this.skillSel : this.skillName),
                    experience: this.exprSel,
                    industry: this.industrySel,
                    technologyName: this.technologyName,
                    skillName: this.skillName,
                    isPrimary: this.isPrimary,
                    industryId: this.industryId
                };
            } 
            this.skillSel = [];
            this.techSel = '';
            this.exprSel = '';
            this.skills = [];
            this.industrySel = '';
            this.technologyName = '';
            this.skillName = [];
            this.isPrimary = false;
            // if(this.tech !=0){
            //     this.saveTechInformation();
            // }
        },
        loadSkills(tech) {
            this.skillSel ="";
            this.skills = [];
            this.skills = tech.settingsTechnologySkill;
            /*for(var i=0; i<this.technologies.length; i++)
            {
                if(this.technologies[i].id === tech.id)
                {
                    for(var j=0; j<this.technologies[i].settingsTechnologySkill.length; j++)
                    {
                        this.skills.push({skillId:this.technologies[i].settingsTechnologySkill[j].skillId, skillName:this.technologies[i].settingsTechnologySkill[j].skillName});
                    }
                    break;                    
                }
            }*/
            this.newSkills = this.skills.map(skill => skill.skillName);
        },
        generateExperience() {
            for (var i = 1; i <= 20; i++) {
                var x = i.toString();
                if (i == 1) {
                    this.experience.push({ "value": "0", "label": "<1" });
                }
                this.experience.push({ "value": x, "label": x });
            }
            this.experience.push({ "value": "21", "label": "20+" });
        },
        addNewSkillset() {},
        getTechfromTechs: function(techId) {
            return this.technologies.filter(technology => technology.id == techId);
        },
        notEmptyTech: function(techSkills) {
            let result = false;
            //      (techSkill.skillName != null) || (techSkill.technologyName != null) ||
            techSkills.map(techSkill => {
                if ((techSkill.experience.label != null && techSkill.experience.value != null) ||
                    (techSkill.industry.id != null && techSkill.industry.industryName != "") ||
                    (techSkill.skills != null && techSkill.skills.length) ||
                    (techSkill.skillName != null) || (techSkill.technologyName != null) ||
                    (techSkill.technology != null && techSkill.technology.id != null && techSkill.technology.technology != null))
                    result = true;
            });
            return result;
        },
        notNullTech: function(techSkill) {
            if (typeof techSkill == "object" && techSkill.technology.id == null && techSkill.technology.technology == null &&
                techSkill.skills.length == 0 && techSkill.experience.label == null && techSkill.experience.value == null &&
                techSkill.industry.id == null && techSkill.industry.industryName == "" && techSkill.technologyName == '' &&
                techSkill.skillName == '')
                return false;
            else
                return true;
        },
        blockSpecialChar(e) {
            if (!((event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode >= 48) && (event.keyCode <= 57))) {
                e.preventDefault();
            }
           return;
        }

    }
}

</script>
