<template>
    <div id="content_Personal_Info">
        <div class="card">
            <div class="card-header p-2">
                <div class="row">
                    <div class="col-md-6">
                        Primary Information
                    </div>
                    <div class="col-md-6 text-right">
                        {{ProfileData.interviewerCode}}
                    </div>
                </div>
            </div>
            <div class="card-body p-2">
                <div class="basic-block col-md-4">
                    <div class="table-responsive">
                        <table class="table table-striped table-borderless s_table compare-table">
                            <tbody>
                                <tr>
                                    <td width="50%"><strong>Name</strong></td>
                                    <td width="50%" class="text-center">{{ProfileData.firstName}} {{ProfileData.lastName}}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><strong>Nick Name</strong></td>
                                    <td width="50%" class="text-center">{{ProfileData.nickName}}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><strong>UserName (Email)</strong></td>
                                    <td width="50%" class="text-center">{{ProfileData.email}}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><strong>Industry</strong></td>
                                    <td width="50%" class="text-center" v-if="ProfileData.industryId!=null">{{getIndustryName(ProfileData.industryId)}}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><strong>Position</strong></td>
                                    <td width="50%" class="text-center">{{ProfileData.position}}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><strong>Gender</strong></td>
                                    <td width="50%" class="text-center" v-if="ProfileData.gender=='F'">Female</td>
                                    <td width="50%" class="text-center" v-if="ProfileData.gender=='M'">Male</td>
                                </tr>
                                <tr>
                                    <td width="50%"><strong>Date Of Birth</strong></td>
                                    <td width="50%" class="text-center">{{ProfileData.dateOfBirth | formatDate}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.p-2 {
    margin-right: 0.1rem;
}

</style>
<script>
export default {
    props: {
        ProfileData: Object,
    },
    data() {
        return {
            industries: this.$store.getters.getIndustries,
            EducationalInfos: [{
                id: 1
            }]
        }
    },
    mounted() {},
    methods: {
        getIndustryName: function(industryId) {
            let industry_name = this.industries.filter(obj => {
                return (obj.id === industryId)
            });
            return industry_name[0].industryName;
        },
        getGenderName: function(genderCode) {

        }
    }
}

</script>
