<template>
    <div class="card">
        <div class="card-header p-2">Add Previous Employment History</div>
        <div class="card-body p-2">
            <div class="basic-block">
                <div class="row ten-columns">
                    <div class="col-sm-12">
                        <div class="table-responsive overflow-visible">
                            <table class="table table-borderless all-table">
                                <thead>
                                    <tr>
                                        <th class="" scope="col">Company Name</th>
                                        <th class="videoprofile" scope="col">Position</th>
                                        <th class="recruiter" scope="col">Technology/Skills</th>
                                        <th class="title" scope="col">Place</th>
                                        <th class="title" scope="col">Country</th>
                                        <!-- <th class="state" scope="col">State</th> -->
                                        <th class="phone" scope="col">From</th>
                                        <th class="lastnote" scope="col">To</th>
                                        <th class="" scope="col">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody class="cloned_tr">
                                    <tr v-for="(info, index) in employmentinfo" :class="{ 'viewonly' : employmentinfo.viewonly == 1}">
                                        <td>
                                            <input v-model="employmentinfo[index].companyName" class="form-control" maxlength="45" type="text" />
                                        </td>
                                        <td class="lastnote">
                                            <input v-model="employmentinfo[index].jobTitle" class="form-control" maxlength="45" type="text" />
                                        </td>
                                        <td class="recruiter">
                                            <div class="position-relative">
                                                <input v-model="employmentinfo[index].skillSet" class="form-control" maxlength="5000" type="text" />
                                                <a href="javascript:;" @click="showBoxEmpSkill(index)" data-toggle="modal" data-target="#EmpSkillShow">
                                                    <span class="search-addon"><span class="ti-flickr srcicn"></span> </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td class="title">
                                            <input v-model="employmentinfo[index].city" class="form-control" maxlength="25" type="text" />
                                        </td>
                                        <!-- <td class="specialityunit">
                                            <input v-model="employmentinfo[index].stateName" class="form-control" maxlength="25" type="text" />
                                        </td> -->
                                        <td class="specialityunit">
                                            <select v-model="employmentinfo[index].countryId" class="form-control">
                                                <option value="">--</option>
                                                <option v-for="(data, index) in educountrieslist" :value="data.country_Id">{{data.name}}</option>
                                            </select>
                                        </td>
                                        
                                        <td class="currentcity">
                                            <!-- <datepicker v-model="employmentinfo[index].employedFrom" placeholder="Employed From" input-class="form-control enablefield" maxlength="10" wrapper-class="input-group date" format="MM/dd/yyyy"></datepicker>  -->
                                            <!-- format="yyyy-MM" :formatter="formatNumericMonYear" -->
                                            <input type="month" value="" v-model="employmentinfo[index].employedFrom1" placeholder="Employed From" input-class="form-control enablefield" maxlength="10" wrapper-class="input-group date">
                                        </td>
                                        <td class="state">
                                            <!--  <datepicker v-model="employmentinfo[index].employedTo" placeholder="Employed To" input-class="form-control enablefield" maxlength="10" wrapper-class="input-group date" format="MM/dd/yyyy"></datepicker>  -->
                                            <input type="month" v-model="employmentinfo[index].employedTo1"  placeholder="Employed To" input-class="form-control enablefield" maxlength="10" wrapper-class="input-group date">
                                        </td>
                                        <td>
                                            <a class="addmoretextbox" :class="{ 'removeaddbtn' : employmentinfo.viewonly == 1}" href="Javascript:void(0);" @click="setRefDetails(index)" data-toggle="modal" data-target="#EmpRefDetails" title="Reference Details">Ref</a> &nbsp;
                                            <a class="removemoretextbox" :class="{ 'removeaddbtn' : employmentinfo.viewonly == 1}" href="Javascript:void(0);" @click="confirmCompany(index,employmentinfo[index].id)" data-toggle="modal" data-target="#removCompany"><span class="ti-trash"></span></a>
                                        </td>
                                    </tr>
                                    <tr :class="{'noaddedmore fill': employmentinfo.viewonly == 1}">
                                        <td>
                                            <input id="addcandidate_employmentinfo_add_companyname" class="form-control" type="text" maxlength="45" v-model="newemploymentinfo.companyName" />
                                        </td>
                                        <td class="lastnote">
                                            <input class="form-control" type="text" maxlength="45" v-model="newemploymentinfo.jobTitle" />
                                        </td>
                                        <td class="recruiter ">
                                            <div class="position-relative">
                                                <input class="form-control" type="text" maxlength="400" v-model="newemploymentinfo.skillSet" />
                                                <a href="javascript:;" @click="showBoxEmpSkill(-1)" data-toggle="modal" data-target="#EmpSkillShow">
                                                    <span class="search-addon"><span class="ti-flickr srcicn"></span> </span>
                                                </a>
                                            </div>
                                        </td>
                                        <td class="title">
                                            <input class="form-control" type="text" maxlength="25" v-model="newemploymentinfo.city" />
                                        </td>
                                        <!-- <td class="specialityunit">
                                            <input class="form-control" type="text" maxlength="25" v-model="newemploymentinfo.stateName" />
                                        </td> -->
                                        <td class="specialityunit">
                                            <select v-model="newemploymentinfo.countryId" class="form-control">
                                                <option value="">--</option>
                                                <option v-for="(data, index) in educountrieslist" :value="data.country_Id">{{data.name}}</option>
                                            </select>
                                        </td>
                                        <td class="currentcity">
                                            <!-- existing code !-->
                                            <!-- <datepicker v-model="newemploymentinfo.employedFrom" placeholder="Employed From" input-class="form-control enablefield" maxlength="10" wrapper-class="input-group date" format="MM/dd/yyyy"></datepicker>  -->
                                            <input type="month" v-model="newemploymentinfo.employedFrom" placeholder="Employed From" input-class="form-control enablefield" maxlength="10" wrapper-class="input-group date">
                                        </td>
                                        <td class="state">
                                            <!-- existing code !-->
                                            <!-- <datepicker v-model="newemploymentinfo.employedTo" placeholder="Employed To" input-class="form-control enablefield" maxlength="10" wrapper-class="input-group date" format="MM/dd/yyyy"></datepicker> -->
                                            <input type="month" v-model="newemploymentinfo.employedTo" placeholder="Employed To" input-class="form-control enablefield" maxlength="10" wrapper-class="input-group date">
                                        </td>
                                        <td>
                                            <a class="addmoretextbox" href="Javascript:void(0);" @click="setRefDetails(-1)" data-toggle="modal" data-target="#EmpRefDetails" title="Reference Details">Ref</a> &nbsp;
                                            <a class="addmoretextbox" href="Javascript:void(0);" @click="addNewEmploymentInfo"><span class="ti-plus"></span></a>
                                        </td>
                                    </tr>
                                    <tr :class="{'norecordfound': employmentinfo.viewonly == 1}" style="display: none;">
                                        <td colspan="8" align="center">No item found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="EmpSkillShow" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Technology / Skill</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <textarea class="form-control more-text-area" v-model="tempEmpSkill"></textarea>
                    </div>
                    <div v-if="employmentinfo.viewonly != 1" class="modal-footer">
                        <button type="button" @click="updateEmpSkill()" class="btn btn-primary" data-dismiss="modal">OK</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="EmpRefDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Reference Details</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class='col'>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Name</label>
                                    <input type="text" class="form-control" placeholder="Name" maxlength="25" v-model="empRefInfoEdit.referenceName">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class='col'>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Phone</label>
                                    <input type="text" class="form-control" placeholder="Phone" maxlength="15" v-model="empRefInfoEdit.referencePhone">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class='col'>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Email</label>
                                    <input type="text" class="form-control" placeholder="Email" maxlength="50" v-model="empRefInfoEdit.referenceEmail">
                                </div>
                                <label v-if="$v.empRefInfoEdit.referenceEmail.$error && !$v.empRefInfoEdit.referenceEmail.email" class="text-danger">Please enter a valid email</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class='col'>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Position</label>
                                    <input type="text" class="form-control" placeholder="Position" maxlength="50" v-model="empRefInfoEdit.referenceRelationship">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="employmentinfo.viewonly != 1" class="modal-footer">
                        <button type="button" @click="saveRefDetails" class="btn btn-primary" data-dismiss="modal">OK</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="removCompany" :onYes="deleteCompany" :returnParams="delCompanyParam" />
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {
    props: {
        employmentinfo: Array,
    },
    data() {
        return {
            empRefInfoEdit: {
                referenceName: '',
                referencePhone: '',
                referenceEmail: '',
                referenceRelationship: '',
                index: -1
            },
            newemploymentinfo: {
                companyName: "",
                jobTitle: "",
                skillSet: "",
                city: "",
                stateName: "",
                employedFrom: "",
                employedTo: "",
                leavingReason: "",
                referenceName: "",
                referencePhone: "",
                referenceEmail: "",
                referenceRelationship: "",
                is_current: false,
                referenceName: '',
                referencePhone: '',
                referenceEmail: '',
                referenceRelationship: '',
                countryId: "US"
            },
            tempEmpSkillId: -1,
            tempEmpSkill: '',
            delCompanyParam: {
                index: -1,
                id: 0
            },
            educountrieslist:this.$store.getters.geteduCountries,
        }
    },
    validations: {
        empRefInfoEdit: {

            referenceEmail: {
                email
            }
        }
    },
    methods: {
        saveRefDetails() {
            if (this.empRefInfoEdit.index < 0) {
                this.newemploymentinfo.referenceName = this.empRefInfoEdit.referenceName;
                this.newemploymentinfo.referencePhone = this.empRefInfoEdit.referencePhone;
                this.newemploymentinfo.referenceEmail = this.empRefInfoEdit.referenceEmail;
                this.newemploymentinfo.referenceRelationship = this.empRefInfoEdit.referenceRelationship;
            } else {
                this.employmentinfo[this.empRefInfoEdit.index].referenceName = this.empRefInfoEdit.referenceName;
                this.employmentinfo[this.empRefInfoEdit.index].referencePhone = this.empRefInfoEdit.referencePhone;
                this.employmentinfo[this.empRefInfoEdit.index].referenceEmail = this.empRefInfoEdit.referenceEmail;
                this.employmentinfo[this.empRefInfoEdit.index].referenceRelationship = this.empRefInfoEdit.referenceRelationship;
            }
        },
        setRefDetails(index) {
            if (index < 0) {
                this.empRefInfoEdit = {
                    referenceName: this.newemploymentinfo.referenceName,
                    referencePhone: this.newemploymentinfo.referencePhone,
                    referenceEmail: this.newemploymentinfo.referenceEmail,
                    referenceRelationship: this.newemploymentinfo.referenceRelationship,
                    index: -1
                }

            } else {
                this.empRefInfoEdit = {
                    referenceName: this.employmentinfo[index].referenceName,
                    referencePhone: this.employmentinfo[index].referencePhone,
                    referenceEmail: this.employmentinfo[index].referenceEmail,
                    referenceRelationship: this.employmentinfo[index].referenceRelationship,
                    index: index
                };
            }
            //console.log(this.employmentinfo[index]);
        },
        resetNewForm() {
            this.newemploymentinfo = {
                companyName: "",
                jobTitle: "",
                skillSet: "",
                city: "",
                stateName: "",
                employedFrom: "",
                employedTo: "",
                leavingReason: "",
                countryId: "US",
                referenceName: "",
                referencePhone: "",
                referenceEmail: "",
                referenceRelationship: "",
                is_current: false
            };
            $('#addcandidate_employmentinfo_add_companyname').focus();
        },
        removeInfo(index) {
            this.employmentinfo.splice(index, 1);
        },
        addNewEmploymentInfo() {
            this.newemploymentinfo.companyName = this.newemploymentinfo.companyName.trim();
            this.newemploymentinfo.jobTitle = this.newemploymentinfo.jobTitle.trim();
            this.newemploymentinfo.skillSet = this.newemploymentinfo.skillSet.trim();
            this.newemploymentinfo.city = this.newemploymentinfo.city.trim();
            this.newemploymentinfo.stateName = this.newemploymentinfo.stateName.trim();

            if (this.newemploymentinfo.employedFrom != "" && this.newemploymentinfo.employedFrom != null) {
                this.newemploymentinfo.employedFrom = this.newemploymentinfo.employedFrom + "-11";
                this.newemploymentinfo.employedFrom1 = this.formatMonth(this.newemploymentinfo.employedFrom);
            }
            if (this.newemploymentinfo.employedTo != "" && this.newemploymentinfo.employedTo != null) {
                this.newemploymentinfo.employedTo = this.newemploymentinfo.employedTo + "-11";
                this.newemploymentinfo.employedTo1 = this.formatMonth(this.newemploymentinfo.employedTo);
            }

            if (this.newemploymentinfo.companyName != "" || this.newemploymentinfo.jobTitle != "" || this.newemploymentinfo.skillSet != "" || this.newemploymentinfo.city != "" || this.newemploymentinfo.stateName != "") {
                this.employmentinfo.push(this.newemploymentinfo);
                this.resetNewForm();
            }
        },
        deleteCompany: function(arg) {
            this.employmentinfo.splice(arg.index, 1);
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/delete/candidate/employment/' + this.delCompanyParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                    }
                })
                .catch(error => {
                    console.log(error.data);
                });
            this.delCompanyParam.id = null;
        },
        confirmCompany: function(arg1, arg2) {
            this.delCompanyParam.index = arg1;
            this.delCompanyParam.id = arg2;
        },
        showBoxEmpSkill: function(arg) {
            this.tempEmpSkillId = arg;
            if (arg < 0) {
                this.tempEmpSkill = this.newemploymentinfo.skillSet;
            } else {
                this.tempEmpSkill = this.employmentinfo[arg].skillSet;
                //console.log(this.projectinfo[arg].projectDescription);
            }
        },
        updateEmpSkill: function() {
            if (this.tempEmpSkillId < 0) {
                this.newemploymentinfo.skillSet = this.tempEmpSkill;
            } else {
                this.employmentinfo[this.tempEmpSkillId].skillSet = this.tempEmpSkill;
            }
        },
    }
}

</script>
