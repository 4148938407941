<template>
    <div id="main" class="mt-70">
        <div class="container-fluid recruiter-dashboard interviewer dashboard">
            <div class="row">
                <main role="main" class="col-md-12 ml-sm-auto col-lg-12 pt-2">
                    <div class="row" style="padding-left:15px; padding-right:15px">
                        <div class="card-list">
                            <div class="row p-25 dashboard-admin-ad" style="margin:0px">
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'listclientaccount' }" class="">
                                        <div class="card sanmarino">
                                            <div class="value" >Client Accounts</div>
                                            <div class="value">
                                               <!--  <img class="" src="@/assets/icons/customer-white.png" alt=""> -->
                                                <img class="" src="@/assets/icons/value-white.png" alt=""> 
                                            </div>
                                            <div class="stat">Manage Account</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'adminlistservice' }" class="">
                                        <div class="card pink">
                                            <div class="value">Service Plan</div>
                                            <div class="value"><img class="" src="@/assets/icons/statistics-white.png" alt=""></div>
                                            <div class="stat">Manage Service Plan</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'adminlistuser' }" class="">
                                        <div class="card darkshadegreen">
                                            <div class="value">Users</div>
                                            <div class="value"><img class="" src="@/assets/icons/candidate-white.png" alt=""></div>
                                            <div class="stat">Manage User</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'adminlistcandidate' }" class="">
                                        <div class="card blue">
                                            <div class="value">Candidates</div>
                                            <div class="value"><img class="" src="@/assets/icons/candidate-white.png" alt=""></div>
                                            <div class="stat">Manage Candidate</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'adminlistjobs' }" class="">
                                        <div class="card green">
                                            <div class="value">Jobs</div>
                                            <div class="value"><img class="" src="@/assets/icons/jobprofile-white.png" alt=""></div>
                                            <div class="stat">Manage Job</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'adminlistclients' }" class="">
                                        <div class="card orange">
                                            <div class="value">Clients</div>
                                            <div class="value"><img class="" src="@/assets/icons/value-white.png" alt=""></div>
                                            <div class="stat">Manage Client</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'adminlistinterviewers' }" class="">
                                        <div class="card red">
                                            <div class="value">Interviewers</div>
                                            <div class="value"><img class="" src="@/assets/icons/meeting-white.png" alt=""></div>
                                            <div class="stat">Manage Interviewer</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'adminlistrecruiters' }" class="">
                                        <div class="card yellowgreen">
                                            <div class="value">Recruiters</div>
                                            <div class="value"><img class="" src="@/assets/icons/recruit-32.png" alt=""></div>
                                            <div class="stat">Manage Recruiter</div>
                                        </div>
                                    </router-link> 
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'adminsettings' }" class="">
                                        <div class="card grey">
                                            <div class="value">Settings</div>
                                            <div class="value"><img class="" src="@/assets/icons/settings-gears.png" alt=""></div>
                                            <div class="stat">Manage Setting</div>
                                        </div>
                                    </router-link>                                
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'adminreports' }" class="">
                                        <div class="card darkgreen">
                                            <div class="value">Report</div>
                                            <div class="value"><img class="" src="@/assets/icons/statistics-white.png" alt=""></div>
                                            <div class="stat">Manage Reports</div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                            <!-- <div class="row pt-3">
                                <div class="col-sm-10">
                                    <h5>Recruitment Chart
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;"></span>
                                    </h5>
                                    <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                                        <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3 scroll-down-inbox pr-0 ">
                            <h5 style="background-color: #37a0e4;color: #fff;padding: 5px;" class="">My Inbox</h5>
                            <div class="box-wrapper p-1 mb-5 rounded">
                                <div class="card-media" v-if="clientAccountList.length==0">
                                    <div class="col-md-12 pt-4 pb-4" style="text-align : center;background-color: #ffff">No records found
                                    </div>
                                </div>
                                <div class="card-media" v-for="(clientAccount, index) in clientAccountList">
                                    <div class="card-media-body">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="card-media-body-top u-float-right  w-100 text-right hrline" style="margin-top:0px;">
                                                    <!-- <span class="card-media-body-heading job-link float-left p-0">{{clientAccount.company_Name}}</span> -->
                                                    <router-link :to="{ name: 'viewclientaccount', params: {accountid:clientAccount.id}}"> <span class="card-media-body-heading job-link float-left p-0">{{clientAccount.company_Name}}</span></router-link>
                                                    <span class="card-media-body-heading job-link pull-right pt-0 pb-0">{{clientAccount.createdOn | formatDate}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <span class="card-media-body-heading job-link p-0">Account Type: {{clientAccount.accountTypeName}}</span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <span class="card-media-body-heading job-link p-0">Email: {{ clientAccount.company_Email }}</span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-8">
                                                <span v-if="clientAccount.company_Phoneno" class="card-media-body-heading job-link p-0">Phone: {{clientAccount.company_Phoneno}}</span>
                                            </div>
                                            <!-- <div class="col-sm-4" style="padding-right:0px;">
                                                <input class="" type="checkbox" /> Approve
                                            </div> -->
                                        </div>
                                        <div v-if="false" class="card-media-body-top">
                                            <div class="card-media-body-top u-float-right  w-100 text-right hrline" style="margin-top:8px;">
                                                <span class="job-link float-right pt-0 pb-0">
                                                    <input class="" type="checkbox" /> Approve
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-9  ">
                             <h5>User Activity
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;"></span>
                            </h5>
                            <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                                <GChart type="ColumnChart" :data="activityData" :options="chartOptions" />
                            </div>

                            <!-- <h5>Recruitment by Client
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;"></span>
                            </h5>
                            <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                                <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
                            </div> -->
                        </div>
                    </div>
                </main>
            </div>
        </div>
        <div class="modal " id="RejectRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Interview confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to reject this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="interviewConfirmation('reject')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="AcceptRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Interview confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to accept this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="interviewConfirmation('accept')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>





    </div>
</template>
<script>
import iziToast from 'izitoast';
import { GChart } from "vue-google-charts";

export default {

    name: 'app',
    components: {
        GChart
    },
    props: {
        // screeninginfo:Object
    },
    data() {
        return {
            joblist: [],
            requestData: [],
            interviewData: [],
            browsepagination: this.$store.state.paginationconfig,
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            industrylist: this.$store.getters.getIndustries,
            clientlist: this.$store.getters.getClients,
            jobStatuslist: this.$store.getters.getJobStatus,
            clientAccountList: [],
            elements: {
                showadvancedsearch: false,
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    jobTitle: "",
                    jobType: "",
                    technologyId: "",
                    startDate: "",
                    endDate: "",
                    recruiterId: this.recruiterId,
                    clientId: "",
                    industryId: "",
                    status: ""
                },
            },
            coldisplay: {
                jobTitle: true,
                jobCode: true,
                client: true,
                industry: true,
                position: true
            },
            delJobParam: {
                index: -1,
                id: 0
            },
            labels: ['Talents Identified', 'Shortlisted', 'Hired'],
            subLabels: ['Direct', 'Social Media', 'Ads'],
            values: [120, 57, 9],
            colors: ['#DA932C', '#66B92E', '#24A0ED', '#A9A9A9'],
            chartColors: ['#DA932C', '#66B92E', '#24A0ED'],

            direction: 'vertical',
            gradientDirection: 'vertical',
            height: 347,
            width: 300,
            activityData:[],
            chartData: [
                ['Client', 'Recruitment'],
                ['Mark', 12],
                ['Frank', 15],
                ['John', 25],
                ['Clara', 20],
                ['Kim', 5],
                ['Virat', 25],
                ['Dany', 20],
                ['Sam', 5],
            ],
            chartOptions: {
                //title: 'Interview [Month-wise]',
                subtitle: 'Client, Recruitment',
                //colors: ['#2298f1', '#898a8e'],
                height: 350
            },
            intervieId: 0
        }
    },
    mounted() {
        this.loadRequests();
        this.loadInterviews();
        this.loadClientAccounts();
        this.loadGraph();
    },
    methods: {
        setInterviewId(id) {
            this.interviewId = id;
        },
        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },
        loadClientAccounts() {
            axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/client/account/list?page=' + this.browsepagination.currentpage + '&size=' + this.search.limit, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.clientAccountList = result.clientAccountList;
                    this.browsepagination.totalrecords = result.pagination[0].total;
                    this.browsepagination.currentpage = result.pagination[0].currentPage;
                    this.browsepagination.totalpages = result.pagination[0].lastPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadRequests() {
            axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/list/interviewrequest/' + this.$store.getters.currentUser.profileId + '?page=' + this.browsepagination.currentpage + '&size=' + this.search.limit, this.search.params)
                .then(response => {
                    let result = response.data;
                    if (!result.request) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.RecNotFou,
                            position: 'topRight'
                        });
                    } else {
                        this.requestData = Array.from(result.request);
                        //this.page_conf=Object.assign({}, result.pagination[0]);
                        this.browsepagination.totalrecords = result.pagination[0].total;
                        this.browsepagination.currentpage = result.pagination[0].currentPage;
                        this.browsepagination.totalpages = result.pagination[0].lastPage;
                    }
                });
        },
        loadInterviews() {
            axios.post(this.$store.getters.getAPIBasePath + '/interviewresource/list/interviewer/interviews/' + +this.$store.getters.currentUser.profileId + '?page=' + this.browsepagination.currentpage + '&size=' + this.search.limit, this.search.params)
                .then(response => {
                    let result = response.data;
                    if (!result.interviews) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.RecNotFou,
                            position: 'topRight'
                        });
                    } else {
                        this.interviewData = Array.from(result.interviews);
                        // this.page_conf=Object.assign({}, result.pagination[0]);
                        this.browsepagination.totalrecords = result.pagination[0].total;
                        this.browsepagination.currentpage = result.pagination[0].currentPage;
                        this.browsepagination.totalpages = result.pagination[0].lastPage;
                    }
                });
        },
        interviewConfirmation: function(type) {
            axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/update/interviewer/confirmrequest/' + this.interviewId, { 'type': type })
                .then(response => {
                    let result = response.data;
                    if (result.status) {
                        iziToast.success({
                            title: 'Confirmation Request',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadRequests();
                    } else {
                        iziToast.error({
                            title: 'Confirmation Request',
                            message: this.$config.ReqConfirmErr,
                            position: 'topRight'
                        });
                    }
                });
            return true;
        },
        loadYearlyRecruitments() {
            var currentDate = new Date();
            var month = currentDate.getMonth();
            let data = { "createdYear": "2019" };

            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/yearly/recruitment/candidate', data)
                .then(response => {
                    let result = response.data;
                    this.values = result.recruitment;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadRecruitmentsByJob(jobId) {
            var currentDate = new Date();
            var month = currentDate.getMonth();
            let data = { "jobId": jobId };

            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/recruitment/job', data)
                .then(response => {
                    let result = response.data;
                    this.values = result.recruitment;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        viewFeedback(interviewId, candidateId) {
            localStorage.setItem('feedbackInterviewId', parseInt(interviewId))
            localStorage.setItem('feedbackCandidateId', parseInt(candidateId))
            // localStorage.setItem('feedbackRpInterviewId',parseInt(rpInterviewId))
            this.$router.push('/interviewer/feedback/' + interviewId)
        },
        loadGraph() {
            axios.post(this.$store.getters.getAPIBasePath + '/dashboardresource/clientaccount/dashboard/chart')
                .then(response => {
                    let result = response.data;
                    this.activityData = result.useractivity;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
    }
}

</script>
