<template>
    <div class="">
        <div class="card">
            <div class="card-header p-2">Plan Information</div>
            <div class="card-body p-2">
                <div class="basic-block col-md-4">
                    <div class="table-responsive">
                        <table class="table table-striped table-borderless s_table compare-table">
                            <tbody>
                                <tr>
                                    <td width="50%"><strong>Plan Name</strong></td>
                                    <td width="50%">{{PlanData.planName}}</td>
                                </tr>
                                <!-- <tr v-if="PlanData.viewResumeCount!== null">
                                    <td width="50%"><strong>Remaining View Resume Count</strong></td>
                                    <td width="50%">{{PlanData.viewResumeCount}}</td>
                                </tr> -->
                                <tr v-if="PlanData.downloadResumeCount!== null">
                                    <td width="50%"><strong>Remaining Download Resume Count</strong></td>
                                    <td width="50%">{{PlanData.downloadResumeCount}}</td>
                                </tr>
                                <!-- <tr v-if="PlanData.shareResumeCount!== null">
                                    <td width="50%"><strong>Remaining Share Resume Count</strong></td>
                                    <td width="50%">{{PlanData.shareResumeCount}}</td>
                                </tr> -->
                                <tr v-if="PlanData.expiryDate!== null ">
                                    <td width="50%"><strong>Plan Will Expire On</strong></td>
                                    <td width="50%">{{PlanData.expiryDate}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Select2 from 'v-select2-component';

export default {
    computed: {
        accountid() {
            return this.$route.params.accountid;
        }
    },
    components: {
        Select2
    },
    props: {
        PlanData: Object,
    },
    data() {
        return {
        }
    },
    mounted() {
        
    },
    methods: {
       
    }
}

</script>
