<template>
    <div id="main" class="mt-70 bulk-upload">
        <div class="box-wrapper shadow-sm py-4 mb-4 bg-white rounded text-center">
            <h5 class="">Import Candidates</h5>
            <div class="row col-md-12 mx-0 py-4">
                <div class="col-md-6 px-0 text-left">
                    <div class="card">
                        <div class="card-header p-2 mx-0 text-center">
                            <span>Source</span>
                        </div>
                        <div class="card-body">
                            <div class="row col-md-12  pb-2">
                                <div class="col-md-4">Import From</div>
                                <div class="col-md-8">
                                    <div class="bulk-import-container">
                                        <div class="file-drop-container">
                                            <div class="file-drop-area">
                                                <span class="file-msg"></span>
                                                <input type="file" v-if="uploadReady"  title="Import data from CSV or Excel file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ref="file" id="importloadfile" class="attachment file-input" @change="onFileChange">            
                                            </div>
											<div style="margin-top: 11px;">
											<!-- <link rel="stylesheet" href="@/assets/SampleFile.xlsx" type="text/css"> -->
                                               <a href="#" @click="downloadFile()">Template for uploading the candidate</a>
                                            </div>
                                            <label v-if="$v.fileName.$error && !$v.fileName.required" class="text-danger">Import data from CSV or Excel file</label>
                                            <div class="upload-error-container">
                                                <div id="uploadLoader" style="display: none;">
                                                    <img src="/image/loader.gif">&nbsp; Import data in progress. please wait...
                                                </div>
                                                <div class="alert" id="error-container" style="display:none">
                                                    <span id="upload-response-msg"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 px-0 text-left">
                    <div class="card">
                        <div class="card-header p-2 mx-0 text-center">
                            <span>Destination</span>
                        </div>
                        <div class="card-body">
                            <div class="row col-md-12">
                                <div class="col-md-4">Import To</div>
                                <div class="col-md-8">
                                    <select class="form-control" name="displayTableName" id="displayTableName" v-model="displayTableName" @blur="$v.displayTableName.$touch">
                                        <option value="">-Select Entity-</option>
                                        <option v-for="entity in list" :value="entity.id">{{entity.displayTableName}}</option>
                                    </select>
                                    <label v-if="$v.displayTableName.$error && !$v.displayTableName.required" class="text-danger">Please select a table name</label>
                                </div>
								<div class="col-md-8" style="height:30px;"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-end col-md-12 mx-0 pt-0">
                <span class="pull-right">
					<button type="submit" class="btn btn-secondary mr-2" value="reset" @click="goback()">Back</button>
                    <button type="submit" class="btn btn-primary mr-2" value="reset" @click="reset()">Reset</button>
                    <button type="submit" class="btn btn-primary" value="compare" @click="compare()">Map</button>
                </span>
            </div>
            <div class="col-md-12 mx-0 pt-4 text-left">
                <div v-if="showFlag && !$v.displayTableName.$error" class="card-body card-header  p-2">Field Mapping
                    <div class="table-responsive pt-4">
                        <table class="table table-borderless all-table" style="color: #6d6d6d!important">
                            <thead>
                                <tr>
                                    <th class="" scope="col">Mapped</th>
                                    <th class="" scope="col">Source columns</th>
                                    <th class="" scope="col">Source sample data</th>
                                    <th class="" scope="col">Destination columns</th>
                                    <th class="" scope="col">
                                        <label class="form-checkbox">
                                            <input class="checkbox_style" type="checkbox" v-model="selectAll" @click="select" />
                                        </label>
                                        <span class="checkbox-text">Save as Keyword</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(mappedData, index) in mapColumList">
                                    <tr>
                                        <td>
                                            <img v-if="mappedData.matchKeyWord" src="@/assets/images/checked.png" @click="unselect(index)" align="absmiddle" border="0" :title="mappedData.matchKeyWord" />
                                        </td>
                                        <td>{{mappedData.csvHeading}}</td>
                                        <td>{{mappedData.csvContent}}</td>
                                        <td>
                                            <span>
                                                <select class="form-control" name="fieldName" id="fieldName" v-model="mappedData.dataName" @change="NewMapping(index)">
                                                    <option value="">-Select Entity-</option>
                                                    <option v-for="entity in displayFieldName" :value="entity.dataName">{{entity.displayFieldName}}</option>
                                                </select>
                                            </span>
                                        </td>
                                        <td>
                                            <label class="form-checkbox">
                                                <input class="checkbox_style" type="checkbox" v-on:click="preventclick(index)" :value="mappedData.csvHeading" v-model="selectedCheckBox">
                                                <i class="form-icon"></i>
                                                <p class="error"></p>
                                            </label>
                                            <span class="checkbox-text">{{mappedData.csvHeading}}</span>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-if="showFlag &&!$v.displayTableName.$error" class="mt-2 text-right">
                    <!-- <a href="" class="btn btn-primary">Reset</a> -->
                    <b-button v-b-modal.modal-lg class="btn btn-primary mx-2" @click="importData(type2)">Preview</b-button>
                    <input type="submit" id="addDriver" class="btn btn-primary mx-2" value="Save" @click="importData(type1)" />
                </div>
                <div v-if="outputResults.length >0" class="row">
                    <div class="col-md-12 pt-4">
                        <h4 class="page-title">Results </h4>
                        <p class="htmlText" v-html="outputResults"></p>
                    </div>
                </div>
            </div>
        </div>
        <b-modal v-if="requiredStatus" id="modal-lg" ref="modal" :no-close-on-backdrop="true" scrollable size="full" title="Preview" style="" @ok="handleOk">
            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-borderless all-table">
                            <thead>
                                <tr>
                                    <template v-for="(heading, index) in resultData">
                                        <th scope="col">{{ getDisplayName(heading) }}</th>
                                    </template>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(candidateinfo, index) in previewDetails">
                                    <template v-for="(heading, index) in resultData">
                                        <td>{{ getColumnValue(candidateinfo,heading)}}</td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div v-if="outputResults.length >0" class="row">
                <div class="col-md-12 pt-4">
                    <h4 class="page-title">Results </h4>
                    <p class="htmlText" v-html="outputResults"></p>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script type="text/javascript" src="//cdnjs.cloudflare.com/ajax/libs/lodash.js/0.10.0/lodash.min.js"></script>
<script>
    import iziToast from 'izitoast';
	import exportFromJSON from "export-from-json";
	import { required } from "vuelidate/lib/validators";
	export default {
		data() {
			return {
				fileName:"",
				list:[],
				displayTableName:"",
				showFlag :false,
				mapColumList:[],
				displayFieldName:[],
				csvheadings:[],
				dbSelValues:[],
				type1:'save',
				type2:'preview',
				resultData:[],
				previewHeader:[],
				selectedCheckBox: [],
				requiredStatus :true,
				previewDetails:[],
				selectAll: false,
				outputResults:"",
				uploadReady: true,
				CandidatesList: [
        			{
						FirstName: "user",
						LastName: "Zoe",
						Email: "user19@example.com",
						PhoneNo: "(111)-111-1111",
						Industry: "IT",
						Position: "Software Engineer",
						Experience: "5",
						Location: "Location",
						SourcingChannel: "YouTube",
						AddressLine1 : "33 cross street, ",
						AddressLine2 : "BGL Building",
						City: "axcey",
						State: "MaryLand",
						ZipCode: "45666",
						Country: "United states",
        			},
        
      			],
			}
		},
		validations: {
			fileName:{
				required,
			},
			displayTableName:{
				required,
			},
			fieldDisplayArray:[],
		},
		mounted() {
			this.getEntityList() ;
		},
		methods: {
			downloadFile() {
      			const data = this.CandidatesList;
      			const fileName = "CandidateTemplate";
      			const exportType = exportFromJSON.types.csv;

      			if (data) exportFromJSON({ data, fileName, exportType });
    		},
			goback(){
				this.$router.go(-1);
			},
			getEntityList() {
				axios.get(this.$store.getters.getAPIBasePath+'/bulkuploadresource/tablelist')
				.then(response => {
					var result = response.data;		
					if(response.status == 200) {
						this.list = result ;
					}
				})
				.catch(error => {
					console.log(error.data);
				});
			},
			onFileChange(e) {	
				var files = e.target.files || e.dataTransfer.files;
		
				if (!files.length)
					return;
				let formData = new FormData();
				formData.append('file', files[0])
				this.fileName = files[0]['name'];
				this.csvFile = files[0];
				this.fileExtension = this.fileName.substr(this.fileName.lastIndexOf('.') + 1);		
			},
			handleOk(bvModalEvt) {
				this.outputResults = "";
				bvModalEvt.preventDefault();
				this.importData(this.type1);
			},
			compare(){				
				this.$v.displayTableName.$touch();
				this.$v.fileName.$touch();
				if (this.$v.displayTableName.$invalid || this.$v.fileName.$invalid ){
					return false;
				}

				let validFileType = false;
				if(this.fileName.split(".").pop() == 'csv' || this.fileName.split(".").pop() == 'xlsx'){
					validFileType = true;
				}

				if(!validFileType){
					iziToast.error({
						title: 'Error',
						message: this.$config.SelectCSVFile,
						position: 'topRight'
					},{timeout: 3000});	
					return;
				}

				this.showFlag = true;
				let formData = new FormData();
				formData.append('file', this.csvFile);
				formData.append('tableId', this.displayTableName);
				formData.append('fileExtension', this.fileExtension);
				axios({
					url: this.$store.getters.getAPIBasePath+'/bulkuploadresource/findmatchlist',
					method: 'POST',
					data: formData,
				})
				.then(response => {
					var result = response.data;
					
					if(response.status == 200) {
						this.count = Object.keys(result.mapColumList).length;
						this.mapColumList = result.mapColumList ;
						this.displayFieldName =result.displayFieldList;
					
						this.csvheadings = [];
						for(var i=0;i<this.count;i++)
						{
							this.csvheadings.push(this.mapColumList[i].csvHeading);
							if(this.mapColumList[i].matchKeyWord == true)
							{
								this.dbSelValues.push(this.mapColumList[i].dataName);
							}
						}
					}
					else{
						iziToast.error({
							title: 'Error',
							message: this.$config.EmptyCSV,
							position: 'topRight'
						},{timeout: 3000});
					}
				})
				.catch(error => {
					console.log(error.data);
				});
			},
			unselect(index)
			{
				this.mapColumList[index].matchKeyWord=false;
				this.mapColumList[index].dataName="";
				var index = this.selectedCheckBox.indexOf(this.csvheadings[index]);
				if (index >= 0) {
					this.selectedCheckBox.splice( index, 1 );
				}
			},
			NewMapping(index){
				for(var i=0;i<this.count;i++)
				{
					if(this.mapColumList[index].dataName !="") {
						if(this.mapColumList[index].dataName == this.mapColumList[i].dataName)
						{		
							if(index != i)
							{
								this.fieldMappingValidationFlag = true ;
								alert("Kindly check the repeated destination columns");
								break;
							}
						}
					}
				}
				if(this.mapColumList[index].dataName == "")
				{
					this.mapColumList[index].matchKeyWord = false;
				}
				else
				{
					this.mapColumList[index].matchKeyWord = true;
				}
				if( this.mapColumList[index].dataName !== '' )
				{
					this.selectedCheckBox.push(this.csvheadings[index]);
				}
				if( this.mapColumList[index].dataName == "")
				{
					var index = this.selectedCheckBox.indexOf(this.csvheadings[index]);
					if (index >= 0) {
						this.selectedCheckBox.splice( index, 1 );
					}
				}
			},
			requiredValidation(){
				for(var i in this.displayFieldName){
					if(this.displayFieldName[i].displayFieldName.includes("*"))
					{
						if(!this.resultData.includes(this.displayFieldName[i].dataName))
						{
							//alert("Please make sure to select all the required fields");
							iziToast.info({
								title: 'Info',
								message: this.$config.SelectAllFields,
								position: 'topRight'
							},{timeout: 5000})
							this.requiredStatus = false;
							return false;
						}
					}
				}
			},
			importData(type){
				this.outputResults = "";
				this.resultData.length = 0
				for(var i=0;i<this.count;i++)
				{
					this.resultData.push(this.mapColumList[i].dataName);
					this.previewHeader.push(this.mapColumList[i].displayFieldName);
				}
				var res = this.requiredValidation();
				if(res == false)
				{
					return ;
				}			
				this.requiredStatus = true;
				let formData = new FormData();
				formData.append('file', this.csvFile);
				formData.append('type', type);
				formData.append('selectedId', this.resultData);
				formData.append('headings', this.csvheadings);
				formData.append('checkbox', this.selectedCheckBox);
				formData.append('TableId', this.displayTableName);

				if(this.fileName.split(".").pop() == 'csv'){
					this.previewCSV(formData);
				}
				else if(this.fileName.split(".").pop() == 'xlsx'){
					this.previewExcel(formData);
				}

			},
			select() {
				this.selectedCheckBox = [];
				if (!this.selectAll) {
					for (let i in this.mapColumList) {
						if( this.mapColumList[i].dataName != null )
						{
							this.selectedCheckBox.push(this.csvheadings[i]);
						}
						if( this.mapColumList[i].dataName == "" )
						{
							this.selectedCheckBox.pop(this.csvheadings[i]);
						}
					}
				}
			},
			preventclick:function(index){
				if( this.mapColumList[index].dataName == null || this.mapColumList[index].dataName == "")
				{
					alert("Please select a Destination column");
					event.preventDefault();
				}
			},
			previewExcel: function(formData){
				axios({
					url: this.$store.getters.getAPIBasePath+'/bulkuploadresource/excelcontent',
					method: 'POST',
					data: formData,
				})
				.then((response) => {
					var result = response.data;
					if(result.Message == "Preview")
					{
						$(".modal-footer button:last-child").html("Save");
						this.previewDetails = result.details;						
					}
					else if(result.Message == "saveSuccess") {
						iziToast.success({
							title: 'Success',
							message: this.$config.BulkUploadSuc,
							position: 'topRight'
						});
						// setTimeout( () => this.$router.go({ path: '/fieldmapping'}), 4000);
					}
					else {
						//this.$refs.modal.hide();
						this.outputResults = result.Message;
						/*iziToast.info({
							title: 'Info',
							message:'Something went wrong while saving',
							position: 'topRight'
						});*/
					}
				})
				.catch((err) => {
					console.log(err);
				})
			},
			previewCSV: function(formData){
				axios({
					url: this.$store.getters.getAPIBasePath+'/bulkuploadresource/csvcontent',
					method: 'POST',
					data: formData,
				})
				.then((response) => {
					var result = response.data;
					if(result.Message == "Preview")
					{
						$(".modal-footer button:last-child").html("Save");
						this.previewDetails = result.details;						
					}
					else if(result.Message == "saveSuccess") {
						this.$refs.modal.hide();
						iziToast.success({
							title: 'Success',
							message: this.$config.BulkUploadSuc,
							position: 'topRight'
						});
						// setTimeout( () => this.$router.go({ path: '/fieldmapping'}), 4000);
					}
					else {
						this.$refs.modal.hide();
						iziToast.error({
							title: 'Error',
							message: result.Message,
							position: 'topRight'
						});
					}
				})
				.catch((err) => {
					console.log(err);
				})
			},
			reset:function(){
				this.outputResults = "";
				this.showFlag = false;
				this.displayTableName = "";
				this.fileName = "";
				this.$v.displayTableName.$reset();	
				this.clearFile();
			},
			clearFile: function() {
		      	this.uploadReady = false
		        this.$nextTick(() => {
	        		this.uploadReady = true
	        	})
		    },
			getDisplayName: function(dataName) {
	            let displayName = "";
	            this.displayFieldName.map(function(value, key) {
	                if (value.dataName == dataName)
	                    displayName = value.displayFieldName;
	            });

	            return displayName;
        	},
        	getColumnValue: function(candidateinfo,heading){
    			let columnValue = "";
        		if(heading != null)
        			columnValue =candidateinfo[heading];  
        		return columnValue;     		
        	}
		}
	}
</script>
<style type="text/css">
.checkbox_style {
    width: 30px;
    height: 18px;
    vertical-align: bottom;
    position: relative;
    top: 7px;
}

.error {
    color: red;
}

.modal-full {
    min-width: 100%;
    margin: 0;
}

.modal-full .modal-content {
    min-height: 100vh;
}

</style>
