<template>
    <div class="job reports">
        <div v-if="!showEmailTools" class="container-fluid" id="quick-search">
            <div class="card">
                <div class="card-header">
                    <h5 class="text-center">Downloaded Resumes</h5>
                </div>
            </div>
            <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-6">
                        <h4 class="page-title pull-left">Filter</h4>
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <ul class="ul-horizontal box-tools text-right mb-0">
                            <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :title="isExpand?'Hide':'Expand'" :class="isExpand?'ti-angle-up':'ti-angle-down'" class="hover-hand"></span></a></li>
                            <li v-if="false"><i class="ti-close hover-hand px-2" @click="$emit('setSubTab',0)" title="Close"></i></li>
                        </ul>
                    </div>
                </div>
                <form id="main-search" :class="isExpand?'d-block':'d-none'">
                    <div class="row form-group ten-columns">
                        <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lbsk pull-left">
                                    <label class="pull-left">Company</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <select v-model="search.params.companyId" @change="getUserList(search.params.companyId)" class="form-control">
                                            <option value="">Select</option>
                                            <option v-if="info.accountName!= ''" v-for="(info, index) in companyClientList" :value="info.id">{{info.companyName}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label class="pull-left">User</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <select v-model="search.params.userId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in userList" :value="info.id">{{info.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label class="pull-left">Start Date</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <datepicker :disabledDates="disabledStartDates" placeholder="Start Date" name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.startDate"></datepicker>
                                        <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label class="pull-left">End Date</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <datepicker :disabledDates="disabledendDates" placeholder="End Date" name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.endDate"></datepicker>
                                        <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label style="visibility: hidden;"></label>
                                </div>
                                <div class="col-md-12">
                                    <button type="button" @click="resetClientsSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                    <button type="button" @click="findDownloadCountList" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Go</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="load-indicator"></div>
            </div>
        </div>
        <div v-if="!showEmailTools" class="container-fluid" id="main-data">
            <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                <div class="row" style="margin-bottom: 15px;">
                    <div class="col-md-6 col-sm-12 col-12">
                        <h4 class="page-title pull-left">Downloads
                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{browsepagination.totalrecords}} - Total)</span>
                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                <div class="dropdown">
                                    <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                    <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                        <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                    </div>
                                </div>
                            </span>
                        </h4>
                    </div>
                    <div class="col-md-6 d-none d-sm-none d-md-block">
                                        <span class="float-right" @click="adminResumeDownloadedByRecruiters()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i></span>
                                </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-borderless all-table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th scope="col">Sl.No</th>
                                        <th class="text-left" scope="col">Company</th>
                                        <th class="text-left" scope="col">User</th>
                                        <th class="text-left" scope="col">No of Count</th>
                                    </tr>
                                </thead>
                                <tbody id="table-length">
                                    <tr v-if="downloadsList.length==0">
                                        <td colspan="5" align="center">No record found</td>
                                    </tr>
                                    <template v-for="(contactInfo, index) in downloadsList">
                                        <tr>
                                            <td>
                                                <a href="javascript:" class="expand" data-targetopen="panel1">
                                                    <span @click="getDownloadedCandidates(contactInfo.userId)"><i :class="(currentIndex==contactInfo.userId) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
                                                    </span>
                                                </a>
                                            </td>
                                            <td>{{((browsepagination.currentPage-1) * (browsepagination.rowsPerPage)) + (index+1)}}</td>
                                            <td class="text-left">{{ contactInfo.companyName }}</td>
                                            <td class="text-left">{{ contactInfo.userName }}</td>
                                            <td class="text-left">{{ contactInfo.candidateCount }}</td>
                                        </tr>
                                        <tr v-if="currentIndex==contactInfo.userId">
                                            <td colspan="5">
                                                <div class="table-responsive">
                                                    <table class="table table-borderless all-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Sl.No</th>
                                                                <th class="text-left" scope="col">Candidate Code</th>
                                                                <th class="text-left" scope="col">Candidate Name</th>
                                                                <th class="text-left" scope="col">Email</th>
                                                                <th class="text-left" scope="col">Mobile</th>
                                                                <th class="text-left" scope="col">Download Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-for="(candInfo, index) in downloadedCandidates">
                                                                <tr>
                                                                    <td>{{(index+1)}}</td>
                                                                    <td class="text-left">{{ candInfo.candidateCode }}</td>
                                                                    <td class="text-left">{{ candInfo.candidateName }}</td>
                                                                    <td class="text-left">{{ candInfo.email }}</td>
                                                                    <td class="text-left">{{ candInfo.mobile }}</td>
                                                                    <td class="text-left">{{ candInfo.createdDate | formatDate }}</td>
                                                                </tr>
                                                            </template>
                                                            <tr v-if="downloadedCandidates.length==0">
                                                                <td colspan="6" align="center">No Candidates found</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <b-pagination v-if="downloadsList.length!=0" size="md" class='pull-right' :current-page="browsepagination.currentPage" :total-rows="browsepagination.totalrecords" v-model="browsepagination.currentPage" :per-page="browsepagination.rowsPerPage" @input="getDownloadCountList()"></b-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row no-gutters">
                <EmailTools v-if="showEmailTools" :mailTo="{email: contactInfo.email, id: contactInfo.id, name: contactInfo.firstName}" @closeEmailTools="showEmailTools=false" />
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import EmailTools from '@/components/recruiters/blocks/tools/EmailTools';

export default {
    components: {
        EmailTools
    },
    data() {
        return {
            isExpand: false,
            downloadsList: [],
            downloadedCandidates: [],
            //  browsepagination: this.$store.state.paginationconfig,
            searchlimit: this.$store.state.searchlimit,
            recruitersList: this.$store.getters.getRecruiters,
            clientList: this.$store.getters.getTtClients,
            companyClientList: this.$store.getters.getClientCompanyList,
            browsepagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            userList: [],
            elements: {
                showadvancedsearch: false,
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    userId: "",
                    companyId: "",
                    startDate: "",
                    endDate: ""
                }
            },
            coldisplay: {
                accountName: true,
            },
            showEmailTools: false,
            contactInfo: {
                id: "",
                firstName: "",
                email: ""
            },
            currentIndex: null,
              pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            disabledStartDates: {
                from: new Date()
            
            },
            disabledendDates:{
                from: new Date(),
                to: ''
            }
        }
    },
    mounted() {
        this.loadDate();
        this.getDownloadCountList();
    },
    watch: {
        'search.params.startDate': {
            handler: function(value) {
                this.disabledendDates.to = new Date(value);
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        resetClientsSearch() {
            this.search.params = {
                userId: "",
                companyId: "",
                startDate: "",
                endDate: ""
            };
            this.currentIndex = null;
            this.userList = [];
            this.loadDate();
            this.getDownloadCountList();
        },
        emailPrepare(contactInfo) {
            this.contactInfo.id = contactInfo.id;
            this.contactInfo.email = contactInfo.email;
            this.contactInfo.firstName = contactInfo.firstName;
            this.showEmailTools = true;
        },
        findDownloadCountList(){
            this.browsepagination.currentPage = 1;
            this.getDownloadCountList();
        },
        getDownloadCountList() {
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/downloaded/candidates/count?page=' + this.browsepagination.currentPage + '&size=' + this.browsepagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    console.log(result)
                    this.downloadsList = result.downloadedCandidatesCount;
                    this.browsepagination.totalrecords = result.pagination[0].total;
                    this.browsepagination.currentPage = result.pagination[0].currentPage;
                    this.browsepagination.totalpages = result.pagination[0].lastPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        changeLimit(newlimit) {
            this.browsepagination.currentPage = 1;
            this.browsepagination.rowsPerPage = newlimit;
            this.getDownloadCountList();
        },
        getUserList(companyId) {
            axios.get(this.$store.getters.getAPIBasePath + '/userresource/user/list/' + companyId)
                .then(response => {
                    let result = response.data;
                    this.userList = result.userList;
                })
                .catch(error => {
                    console.log(error);
                });

        },
        loadDate() {
            this.search.params.startDate = new Date().toISOString().substr(0, 10);
            this.search.params.endDate = new Date().toISOString().substr(0, 10);
        },
        getDownloadedCandidates(userId) {
            if (this.currentIndex == userId) {
                this.currentIndex = null;
            } else {
                this.currentIndex = userId;
            }
            let data = {
                userId: userId,
                startDate: this.search.params.startDate,
                endDate: this.search.params.endDate
            };
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/list/downloaded/candidates', data)
                .then(response => {
                    let result = response.data;
                    this.downloadedCandidates = result.downloadedCandidates;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        adminResumeDownloadedByRecruiters() {
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/recruiter/resumes/download/count/report?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "DownloadedResumeCandidates" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });
        },
    }
}

</script>
