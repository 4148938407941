<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div class="card border-5 mb-3">
                                    <div class="col-md-12 col-sm-12 px-0 about-box">
                                        <div class="col-md-6 px-0 float-left">
                                            <h6 class="m-0 text-left p-2">
                                                <a href="javascript:" @click="getPrevUrl()" title="Back" class="p-2">
                                                    <i class="fa fa-arrow-left"></i>
                                                    <span> Back</span>
                                                </a>
                                            </h6>
                                        </div>
                                        <div class="col-md-6 px-0 pull-right py-2 text-right pr-2">
                                            <a v-if="!personalinfo.showContact" href="javascript:" data-toggle="modal"
                                                data-target="#contactInfoRequest" @click="setContactReqParams(personalinfo)" title="Contact Request"
                                                class="pl-5"><i class="fa fa-address-card px-1"></i></a>

                                            <a v-if="(ttClientAccountId == personalinfo.ttClientAccountId || personalinfo.isViewed == 1)"
                                                href="javascript:" @click="emailtab" title="Email To" class=""><i
                                                    class="fa fa-envelope px-1"></i></a>
                                            <a v-if="false" href="javascript:" v-b-modal.notes-form-modal
                                                @click="addCallback" title="Callback"><i class="fa fa-phone px-1"></i></a>
                                            <a v-if="false" href="javascript:" v-b-modal.notes-form-modal @click="addNotes"
                                                title="Add Notes"><i class="fa fa-sticky-note px-1"></i></a>
                                            <a v-if="(ttClientAccountId == personalinfo.ttClientAccountId || personalinfo.isViewed == 1)"
                                                href="javascript:" v-b-modal.joblist-form-modal
                                                @click="loadOpenJobs(); setCandidateId(candidateid);"
                                                title="Schedule Interview"><i class="ti-calendar px-1"></i></a>
                                            <router-link class="link" v-if="personalinfo.showContact" title="Edit Candidate"
                                                :to="{ name: 'editcandidate' }"><i
                                                    class="fa fa-pencil-square-o fa_custom fa-lg px-1"></i></router-link>
                                        </div>
                                    </div>
                                    <div class="user-card pl-5 pr-5 pb-2 pt-3">
                                        <div class="information">
                                            <div class="userimage-progress">
                                                <div class="progress-circle">
                                                    <vue-circle v-if="showProgress"
                                                        :progress="personalinfo.profileCompletion" :size="75"
                                                        :reverse="true" line-cap="round" :fill="fill"
                                                        empty-fill="rgba(0, 0, 0, .1)" :animation-start-value="0.0"
                                                        :start-angle="0" insert-mode="append" :thickness="7"
                                                        :show-percent="true" @vue-circle-progress="progress"
                                                        @vue-circle-end="progress_end">
                                                        <div class="imgUp">
                                                            <div class="imagePreview"
                                                                v-bind:style="{ backgroundImage: 'url(' + personalinfo.imagePath + '?' + profilePhotoArg + ')' }">
                                                            </div>
                                                            <label v-if="personalinfo.viewonly == 0" class="upload-icon"><i
                                                                    class="ti-camera"></i><input type="file"
                                                                    class="uploadFile img" value="Upload Photo"
                                                                    style="width: 0px;height: 0px;overflow: hidden;"></label>
                                                        </div>
                                                    </vue-circle>
                                                </div>
                                            </div>
                                            <div class="name">{{ personalinfo.firstName }} {{ personalinfo.lastName }}</div>
                                            <div>{{/*personalinfo.industry.industryName*/ }} {{ personalinfo.position }}
                                            </div>
                                            <div v-if="personalinfo.showContact && personalinfo.email && (ttClientAccountId == personalinfo.ttClientAccountId || personalinfo.isViewed == 1)"
                                                class="position"><i class="ti-email"></i> {{ personalinfo.email }}</div>
                                            <div v-if="personalinfo.showContact && personalinfo.mobile && (ttClientAccountId == personalinfo.ttClientAccountId || personalinfo.isViewed == 1)"
                                                class="position"><i class="ti-mobile"></i> {{ personalinfo.mobile }}</div>
                                            <div><a href="javascript:;" data-toggle="modal"
                                                    data-target="#RecruitmentCalls"><i class="ti-headphone-alt"></i></a>
                                            </div>
                                            <!-- <div><i class="ti-headphone-alt"></i> </div> -->
                                            <!--  <div class="position"><i class="ti-mobile"></i> {{personalinfo.firstName}}, {{personalinfo.experience}} Y </div> -->
                                            <h4>Profile Completion {{ personalinfo.profileCompletion }}%</h4>
                                            <div class="progress">
                                                <div class="progress-bar"
                                                    :style="applyPercentage(personalinfo.profileCompletion)"
                                                    role="progressbar" aria-valuenow="personalinfo.profileCompletion"
                                                    aria-valuemin="0" aria-valuemax="100">
                                                    <span class="title">{{ personalinfo.profileCompletion }}%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="personalinfo.candStatus.includes('Post')">
                                        <h5 class="about-box m-0 text-left p-2">
                                            <span class="job-link pt-0 pb-0">
                                                <a title="Reject" href="javascript:void(0);"
                                                    @click="setCandidateId(candidateid,personalinfo.referralId)" data-toggle="modal"
                                                    data-target="#RejectCandidate"><i class="ti-close"
                                                        style="color: red;"></i><span
                                                        style="color: red; padding-right:10px; font-family: sans-serif;">
                                                        Reject</span></a>
                                            </span>
                                            <span class="job-link float-right pt-0 pb-0">
                                                <a title="Accept" href="javascript:void(0);"
                                                    @click="setCandidateId(candidateid)" data-toggle="modal"
                                                    data-target="#AcceptCandidate"><i class="ti-check"
                                                        style="color: green;"></i><span class="pr-10"
                                                        style="color: green; font-family: sans-serif;">Accept</span></a>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                                <div class="card border-5">
                                    <div class="user-card p-0">
                                        <h5 class="about-box m-0 text-left p-2">About Me</h5>
                                        <div class="p-3 float-left w-100">
                                            <h5>Technology / Specialization</h5>
                                            <div class="informations">
                                                <span v-for="(info, index) in technologyinfo" v-bind:key="index"
                                                    class="p-1 mb-2 mr-1 badge " :class="colours[index % 5]">
                                                    <span v-if="checkEmpty(technologyinfo[index].technologyName)">
                                                        {{ technologyinfo[index].technologyName }} <span
                                                            v-if="technologyinfo[index].years != null">-
                                                            {{ technologyinfo[index].years }} y </span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div v-if="contactinfo.currentaddress.addressLine1 !== null"
                                                class="w-100 float-left">
                                                <hr>
                                                <h5><i class="ti-location-pin"></i> Location</h5>
                                                <div class="information">
                                                    <p> {{ personalinfo.location }}. </p>
                                                </div>
                                            </div>
                                            <div v-if="personalinfo.educationinfo.length != 0" class="w-100 float-left">
                                                <hr>
                                                <h5><i class="ti-briefcase"></i> Education</h5>
                                                <div class="information">
                                                    <p v-for="(info, index) in personalinfo.educationinfo">
                                                        <span v-if="checkEmpty(personalinfo.educationinfo[index].degree)">
                                                            {{ personalinfo.educationinfo[index].degree }} </span>
                                                        <span v-if="checkEmpty(personalinfo.educationinfo[index].year)"> -
                                                            {{ personalinfo.educationinfo[index].year }} </span>
                                                        <span
                                                            v-if="checkEmpty(personalinfo.educationinfo[index].schoolName)">
                                                            , {{ personalinfo.educationinfo[index].schoolName }}</span>
                                                        <span v-if="checkEmpty(personalinfo.educationinfo[index].city)"> ,
                                                            {{ personalinfo.educationinfo[index].city }} </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div v-if="personalinfo.candStatus !== null" class="w-100 float-left">
                                                <hr>
                                                <h5><i class="ti-info-alt"></i> Status</h5>
                                                <div class="information">
                                                    <p> {{ personalinfo.candStatus }}. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="personalinfo.candidateShare != null && personalinfo.candidateShare.length > 0"
                                    class="card border-5">
                                    <div class="user-card p-0">
                                        <h5 class="about-box m-0 text-left p-2">Shared Info</h5>
                                        <div class="p-3 float-left w-100">
                                            <table class="Shared-Info-details">
                                                <thead>
                                                    <tr>
                                                        <th>Shared By</th>
                                                        <th>Shared To</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(info, index) in personalinfo.candidateShare">
                                                        <td>{{ info.recruiterName }}</td>
                                                        <td>{{ info.company_Name }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-sm-9">

                        <div class="row no-gutters text-break">
                            <b-tabs v-if="!elements.showEmailTools" v-model="currentCandidateTab" small card
                                class="tab-list driver-tab-list">

                                <b-tab title="Pri Info">
                                    <PersonalInfo :candidateId="candidateid" :personalinfo="personalinfo" :candContactAccess="candContactAccess"
                                        :ttClientAccountId="ttClientAccountId" ref="personalinfocomponent"></PersonalInfo>
                                </b-tab>
                                <b-tab
                                    v-if="(personalinfo.showContact && ttClientAccountId == personalinfo.ttClientAccountId || personalinfo.isViewed == 1)"
                                    title="Add Info">
                                    <ContactInfo :contactinfo="contactinfo" :candContactAccess="candContactAccess"
                                        ref="contactinfocomponent"></ContactInfo>
                                </b-tab>
                                <b-tab title="Emp History">
                                    <EmploymentInfo :employmentinfo="employmentinfo" ref="employmentinfocomponent">
                                    </EmploymentInfo>
                                </b-tab>
                                <!-- <b-tab title="Reference Details">
                                    <ReferenceInfo :referenceinfo="employmentinfo"></ReferenceInfo>
                                </b-tab> -->
                                <b-tab title="Tech/Skills">
                                    <TechnologyInfo :technologyinfo="technologyinfo" ref="technologyinfocomponent">
                                    </TechnologyInfo>
                                </b-tab>
                                <b-tab title="Projects">
                                    <ProjectInfo :projectinfo="projectinfo" ref="projectinfocomponent"></ProjectInfo>
                                </b-tab>
                                <b-tab title="Preferences">
                                    <PreferenceInfo :preferenceinfo="preferenceinfo" ref="preferenceinfocomponent">
                                    </PreferenceInfo>
                                </b-tab>
                                <b-tab title="Availability" @click="refreshCalendar">
                                    <AvailabilityInfo v-if="showAvailabilityInfo" :candidateid="candidateid"
                                        :availabilityinfo="availabilityinfo" ref="availabilityinfocomponent">
                                    </AvailabilityInfo>
                                </b-tab>
                                <b-tab title="Resume">
                                    <ResumeAttach :resumeinfo="resumeinfo" :multipleFile="multipleFile"
                                        :candidateId="candidateid" :candContactAccess="candContactAccess"
                                        ref="resumeattachcomponent" @refresh-candidate="loadCandidateById(candidateid)">
                                    </ResumeAttach>
                                </b-tab>
                                <b-tab title="Interviews">
                                    <Interviews v-if="!elements.feedback.showFeedback" @viewFeedback="viewFeedback"
                                        :interviews="interviews" ref="interviewscomponent"></Interviews>
                                    <Feedback v-if="elements.feedback.showFeedback" :recId="elements.feedback.recruitmentId"
                                        @closeFeedback="closeFeedback" />
                                </b-tab>
                                <!-- <b-tab title="History">
                                    <ChangeHistory :changeHistoryInfo="changeHistoryInfo" ref="changeHistoryInfocomponent"></ChangeHistory>
                                </b-tab> -->
                                <b-tab title="Settings">
                                    <Settings :settingsInfo="settingsInfo" ref="settingscomponent"></Settings>
                                </b-tab>
                                <b-tab title="Incident">
                                    <IncidentHistory ref="incidentHistoryComponent"></IncidentHistory>
                                </b-tab>
                                <b-tab title="Email">
                                    <!-- <EmailInfo  :emailInfo="emailInfo"  ref="emailComponent"></EmailInfo> -->
                                    <Inprogress />
                                </b-tab>
                                <b-tab title="Calls">
                                    <!-- <CallInfo ref="callComponent"></CallInfo> -->
                                    <Inprogress />
                                </b-tab>
                                <!-- <b-tab @click="emailtab" title="Mail">
                                    <EmailTools v-if="elements.showEmailTools" :mailTo="{email: personalinfo.email, id: candidateid, name: personalinfo.firstName+' '+personalinfo.lastName}"></EmailTools>
                                </b-tab> -->
                            </b-tabs>
                            <EmailTools v-if="elements.showEmailTools"
                                :mailTo="{ email: personalinfo.email, id: candidateid, name: personalinfo.firstName + ' ' + personalinfo.lastName, userType: 'candidate' }"
                                @closeEmailTools="elements.showEmailTools = false"></EmailTools>
                            <!-- <div class="col-md-12">
                                <a href="Javascript:void(0);" @click="saveCurrentTab" class="btn btn-success btn-sm float-right" style="margin-left: 10px;color: #fff;">Save</a>
                            </div> -->







                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal size="xl" id="joblist-form-modal" :no-close-on-backdrop="true" ref="jobList" title="Jobs" @show=""
            @hidden="cancelJobList" @ok="scheduleInterview" @cancel="" @close="">
            <div class="card m-b-3">
                <div class="card-body p-2">
                    <div class="basic-block">
                        <form id="main-search">
                            <div class="row form-group ten-columns">
                                <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Job Code</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input type="text" v-model="params.jobCode" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Job Title</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input type="text" v-model="params.jobTitle" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                    <div class="row pull-right">
                                        <button type="button" class="btn btn-primary btn-sm filter-button float-right "
                                            @click="filterJobs()" style="margin-left: 10px;">Find</button>
                                        <button type="button" class="btn btn-primary btn-sm filter-button float-right "
                                            @click="resetFilter()" style="margin-left: 10px;">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="row scroll-down-list">
                            <div class="col-sm-12">
                                <div v-if="elements.submitted && !$v.selectedValue.required" class="errorcolor">Please
                                    select a job to schedule interview</div>
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Job Code</th>
                                            <th>Job Title</th>
                                            <th>Client Name</th>
                                            <th>Position</th>
                                            <th>Experience</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="!jobList.length">
                                            <td class="text-center" colspan="100%">No Jobs found</td>
                                        </tr>
                                        <tr v-for="job in jobList">
                                            <th>
                                                <div class="form-check form-check-inline">
                                                    <input type="radio" :id="'radiobtn' + job.id" :value="job.id"
                                                        v-model="selectedValue" @click="setRecPlanId(job.recPlanId)">
                                                    <label :for="'radiobtn' + job.id"></label>
                                                </div>
                                            </th>
                                            <td>{{ job.jobCode }}</td>
                                            <td>{{ job.jobTitle }}</td>
                                            <td>{{ job.clientName }}</td>
                                            <td>{{ job.position }}</td>
                                            <td>{{ job.experience }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal id="notes-form-modal" ref="notesModal" title="Add Notes" @show="resetModal" @hidden="resetModal"
            @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Communication Mode" label-for="communcation-mode"
                    invalid-feedback="Communication Mode is required">
                    <b-form-select id="communcation-mode" v-model="form.communcation_mode" required>
                        <option v-if="(ttClientAccountId == personalinfo.ttClientAccountId || personalinfo.isViewed == 1)"
                            value="phone">Phone</option>
                        <option v-if="(ttClientAccountId == personalinfo.ttClientAccountId || personalinfo.isViewed == 1)"
                            value="email">Email</option>
                    </b-form-select>
                </b-form-group>
                <b-form-group lable="Notes" label-for="notes" invalid-feedback="Notes is required">
                    <editor mode="wysiwyg" :html="form.notes" :options="$store.getters.editorOptions" />
                </b-form-group>
            </form>
        </b-modal>
        <div>
            <b-modal ref="my-modal" id="RecruitmentCalls" hide-footer title="Call" size="md">
                <div class="modal-body">
                    <label class="callable"> To: </label><label class="callable">8610005443</label>
                    </br>
                    <label class="callable"> From: </label><label class="callable">8610005443</label>
                </div>
                <div class="callbtn">
                    <center>
                        <button type="button" class="btn btn-primary btn-sm call-button">Start Call</button>
                    </center>
                </div>
                <div class="callnotes">
                    <label class="callablenotes">Notes</label>
                    <center>
                        <textarea style="resize:none" cols="60" rows="10"></textarea>
                    </center>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal"> Close </button>
                </div>
            </b-modal>
        </div>
        <div class="modal " id="RejectCandidate" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Candidate confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to reject this candidate?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="candidateConfirmation('reject')" class="btn btn-primary"
                            data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal " id="AcceptCandidate" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Candidate confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to accept this candidate?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="candidateConfirmation('approve')" class="btn btn-primary"
                            data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="contactInfoRequest" :onYes="confirmContactRequest" title="Confirmation"
            message="Are you sure to request the candidate contact info to referral?" />

    </div>
</template>
<style>
ul.skill-root>li {
    margin-bottom: 15px;
}

ul.skill-root>li>span,
ul.skill-root>li>ul>li>span {
    color: #fff;
    background-color: #007bff;
    border-radius: .25rem;
    padding: 2px;
}

ul.skill-root>li>ul>li:first-child {
    padding-left: 15px !important;
}

ul.skill-root>li>ul>li {
    display: inline-block;
    margin: 4px 0 4px 2px;
}

ul.skill-root>li>ul>li>span {
    background-color: #28a745 !important;
}
</style>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import PersonalInfo from './blocks/viewcandidate/PersonalInfo';
import ContactInfo from './blocks/viewcandidate/ContactInfo';
import EmploymentInfo from './blocks/viewcandidate/EmploymentInfo';
import ReferenceInfo from './blocks/viewcandidate/ReferenceInfo';
import TechnologyInfo from './blocks/viewcandidate/TechnologyInfo';
import PreferenceInfo from './blocks/viewcandidate/PreferenceInfo';
import AvailabilityInfo from './blocks/viewcandidate/AvailabilityInfo';
import ResumeAttach from './blocks/viewcandidate/ResumeAttach';
import ProjectInfo from './blocks/viewcandidate/ProjectInfo';
import Interviews from './blocks/viewcandidate/Interviews';
import ChangeHistory from './blocks/viewcandidate/ChangeHistory';
import Settings from './blocks/addcandidate/Settings';
import IncidentHistory from './blocks/viewcandidate/IncidentHistory';
import Inprogress from './blocks/viewcandidate/Inprogress';
import EmailInfo from './blocks/viewcandidate/EmailInfo';
import CallInfo from './blocks/viewcandidate/CallInfo';

import EmailTools from '@/components/recruiters/blocks/tools/EmailTools';
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import Feedback from '../../layouts/blocks/Feedback';
//import 'tui-editor/dist/tui-editor.css';
//import 'tui-editor/dist/tui-editor-contents.css';
//import 'codemirror/lib/codemirror.css';
import { Editor } from '@toast-ui/vue-editor';
import VueCircle from 'vue2-circle-progress';
export default {
    computed: {
        candidateid() {
            return Number(this.$route.params.candidateid);
        }
    },
    components: {
        PersonalInfo,
        ContactInfo,
        EmploymentInfo,
        ReferenceInfo,
        TechnologyInfo,
        PreferenceInfo,
        AvailabilityInfo,
        ResumeAttach,
        ProjectInfo,
        Interviews,
        ChangeHistory,
        Settings,
        IncidentHistory,
        EmailInfo,
        CallInfo,
        Inprogress,
        EmailTools,
        Feedback,
        'editor': Editor,
        VueCircle
    },
    data() {
        return {
            elements: {
                feedback: {
                    recruitmentId: null,
                    showFeedback: false
                },
                submitted: false,
                showEmailTools: false
            },
            ttClientAccountId: localStorage.getItem('clientAccountId'),
            emailInfo: {
                candidateName: "",
                userId: "",
                candidateEmail: "",

            },
            showProgress: false,
            currentCandidateTab: 0,
            maxtabs: 6,
            candContactAccess: localStorage.getItem('candidateContactAccess'),
            personalinfo: {
                candidateCode: "",
                firstName: "",
                middlename: "",
                lastName: "",
                email: "",
                mobile: "",
                dateOfBirth: "",
                dateAvailable: "",
                industryName: "",
                recruiterName: "",
                position: "",
                location: "",
                experience: "",
                expType: "",
                expMonth: "",
                referredBy: "",
                payMode: "",
                clientId: "",
                visaTypeId: "",
                visaTypeText: "",
                notes: "",
                educationinfo: [],
                blockpersonaltab: 0,
                viewonly: 1,
                profileCompletion: 0,
                imageName: '',
                imagePath: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
                candidateLanguage: [],
                sourcingChannelName: "",
                sourcingRefLink: "",
                linkedInUrl: "",
                candidateAdditionalFields: [],
                candStatus: "",
                candidateType: "",
                willRelocate: "",
                candidateShare: [],
                isViewed: 0,
                candidateTTClientAccountId: "",
                expiredDate: "",
                showContact: "",
                referralId: ""
            },
            contactinfo: {
                currentaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "US"
                },
                permanentaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "US"
                },
                emergency: [],
                viewonly: 1
            },
            employmentinfo: [],
            // technologyinfo: {
            //     skillset: [],
            //     specialnotes: "",
            //     viewonly: 1
            // },
            technologyinfo: [],
            preferenceinfo: {
                communicationMode: [],
                basepreference: [],
                otherpreference: {
                    timetocall: "",
                    workType: "",
                    expectedSalary: "",
                    minSalary: "",
                    maxSalary: "",
                    frequencyPercentage: "",
                    currency: "",
                    payFrequency: "",
                    parttime: "no",
                    fulltime: "no"
                },
                viewonly: 1
            },
            availabilityinfo: {
                viewmode: 1
            },
            resumeinfo: {
                file: '',
                fileurl: '',
                filename: '',
                viewonly: 1,
                resumeViewCount: '',
                downloadResumeCount: '',
                candidateId: '',
                ttClientAccountId: '',
                isViewed: '',
                expiredDate: ''
            },
            multipleFile: [],
            multipleFileNames: [],
            multipleFilePaths: [],
            projectinfo: [],
            interviews: [],
            emails: [],
            changeHistoryInfo: [],
            Skills: [],
            showAvailabilityInfo: false,
            showEmailInfo: false,
            colours: ['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info'],
            profilePhotoArg: 1,
            settingsInfo: {
                timeZone: "",
                accessLevel: 1
            },
            form: {
                recruiter_id: this.$store.getters.currentUser.accountId,
                user_id: this.candidateid,
                user_type: "candidate",
                communication_type: "",
                communcation_mode: "",
                notes: "<h6>Add your notes here</h6>"
            },
            fill: { gradient: ["#37A0E4", "#37A0E4", "#808080"] },
            params: {
                jobTitle: "",
                jobCode: ""
            },
            jobList: [],
            emails: [],
            recPlanId: "",
            selectedValue: "",
            params: {

                ttclientaccountid: "",
                userid: "",
                candidateemail: "",
                pageno: "",
                pagesize: ""

            },
            candContactRequest:{
                referralId: "",
                candidateId: "",
                referralRewardSettingsId: ""
            }
        }
    },
    validations: {
        selectedValue: { required },
    },
    watch: {
        /*interviews(newValue, oldValue) {
            let recId = parseInt(localStorage.getItem('recruitmentId'));
            if (recId) {
                this.currentCandidateTab = 9;
                this.viewFeedback(recId);
            }
        }*/
    },
    mounted() {
        this.loadCandidateById(this.candidateid);
        // this.getEmailList();
    },
    methods: {
        progress(event, progress, stepValue) {
            //console.log(stepValue);
        },
        progress_end(event) {
            //console.log("Circle progress end");
        },
        checkEmpty(obj) {

            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },
        applyPercentage(percentage) {
            return 'max-width:' + percentage + '%'
        },
        loadOpenJobs() {


            axios.post(this.$store.getters.getAPIBasePath + '/jobresource/inboxjoblist', this.params)
                .then(response => {
                    let result = response.data;
                    if (result.status) {
                        this.jobList = result.jobs;
                    } else {
                        let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.LoadingErr
                            ;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.LoadingErr
                        ;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
        },
        setRecPlanId: function (recPlanId) {
            this.recPlanId = recPlanId;
        },
        resetFilter: function () {
            this.params.jobTitle = "";
            this.params.jobCode = "";
            this.loadOpenJobs();
        },
        cancelJobList: function () {
            this.params.jobTitle = "";
            this.params.jobCode = "";
            this.selectedValue = "";
            this.$v.selectedValue.$reset();
            this.elements.submitted = false;

        },
        setCandidateId: function (candidateId,referralId) {
            this.candidateId = candidateId;
            this.referralId = referralId;
        },
        loadCandidateById(candidateId) {
            this.showProgress = false;
            axios.get(this.$store.getters.getAPIBasePath + '/candidateresource/view/candidate/' + candidateId)
                .then(response => {
                    let result = response.data;
                    if (!result.status) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$router.push('/candidates');
                    }
                    let self = this;
                    this.emailInfo.candidateEmail = result.candidates.email;
                    // this.$refs.emailComponent.setSearchData(this.emailInfo);
                    this.resumeinfo.resumeViewCount = result.candidates.resumeViewCount;
                    this.resumeinfo.downloadResumeCount = result.candidates.downloadResumeCount;
                    this.resumeinfo.candidateId = candidateId;
                    this.resumeinfo.ttClientAccountId = result.candidates.ttClientAccountId;
                    this.resumeinfo.isViewed = result.candidates.isViewed;
                    this.resumeinfo.expiredDate = result.candidates.expiredDate;
                    this.personalinfo = result.candidates;
                    this.personalinfo = {
                        candidateCode: result.candidates.candidateCode,
                        firstName: result.candidates.firstName,
                        middlename: result.candidates.middlename,
                        lastName: result.candidates.lastName,
                        email: result.candidates.email,
                        mobile: result.candidates.mobile,
                        dateOfBirth: result.candidates.dateOfBirth,
                        dateAvailable: result.candidates.dateAvailable,
                        industryName: result.candidates.industryName,
                        recruiterName: result.candidates.recruiterName,
                        position: result.candidates.position,
                        location: result.candidates.location,
                        experience: result.candidates.experience,
                        expMonth: result.candidates.expMonth,
                        expType: result.candidates.expType,
                        // expType: (result.candidates.expType == null) ? "Years" : result.candidates.expType,
                        referredBy: result.candidates.referredBy,
                        payMode: (result.candidates.payMode == undefined) ? '' : result.candidates.payMode,
                        clientId: (result.candidates.clientId == undefined) ? '' : result.candidates.clientId,
                        visaTypeId: (result.candidates.visaTypeId == undefined) ? '' : result.candidates.visaTypeId,
                        visaTypeText: result.candidates.visaTypeText,
                        notes: result.candidates.notes,
                        educationinfo: result.candidates.candidateEducation,
                        blockpersonaltab: 0,
                        viewonly: 1,
                        profileid: this.candidateid,
                        profileCompletion: result.candidates.profileCompletion,
                        imageName: result.candidates.imageName,
                        imagePath: (result.candidates.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : result.candidates.imagePath),
                        candidateLanguage: result.candidates.candidateLanguage,
                        sourcingChannelName: result.candidates.sourcingChannelName,
                        sourcingRefLink: result.candidates.sourcingRefLink,
                        linkedInUrl: result.candidates.linkedInUrl,
                        candidateAdditionalFields: result.candidates.candidateAdditionalFields,
                        candStatus: result.candidates.candStatus,
                        candidateType: result.candidates.candidateTypeName,
                        willRelocate: result.candidates.willRelocate,
                        createdByName: result.candidates.createdByName,
                        candidateShare: result.candidates.candidateShare,
                        isViewed: result.candidates.isViewed,
                        ttClientAccountId: result.candidates.ttClientAccountId,
                        expiredDate: result.candidates.expiredDate,
                        showContact: result.candidates.showContact,
                        referralId: result.candidates.referralId
                    };
                    result.candidates.candidateContact.forEach(function (data) {

                        if (data.contactType != null && data.contactType.toLowerCase() == "current") {
                            self.contactinfo.currentaddress = data;
                        } else {
                            self.contactinfo.permanentaddress = data;
                        }
                    });
                    this.contactinfo.emergency = result.candidates.candidateEmergencyContact;
                    this.employmentinfo = result.candidates.candidateEmployment;
                    this.employmentinfo.viewonly = 1;

                    this.projectinfo = result.candidates.candidateProject;
                    this.projectinfo.viewonly = 1;
                    /*this.projectinfo.forEach((info)=>{
                        info['viewonly'] = 1;
                    });*/

                    let techId = 0;
                    let j = 0;
                    for (let i = 0; i < result.candidates.candidateSkill.length; i++) {
                        if (result.candidates.candidateSkill[i].technologyId == techId && this.Skills[j - 1] != null) {

                            this.Skills[j - 1].techSkills.push({ 'skill': result.candidates.candidateSkill[i].title, 'exprYear': result.candidates.candidateSkill[i].years });
                        } else {
                            techId = result.candidates.candidateSkill[i].technologyId;
                            if (result.candidates.candidateSkill[i].technologySet != null) {
                                this.Skills.push({ 'techTitle': result.candidates.candidateSkill[i].technologySet.technology, 'techSkills': [{ 'skill': result.candidates.candidateSkill[i].title, 'exprYear': result.candidates.candidateSkill[i].years }] });
                            }
                            j++;
                        }
                    }
                    //this.technologyinfo.skillset = result.candidates.candidateSkill;

                    let techInfo = [];
                    if (result.candidates.techList != null) {
                        if (result.candidates.techList != null) {
                            for (let i = 0; i < result.candidates.techList.length; i++) {
                                techInfo.push({
                                    technologyName: result.candidates.techList[i].technologyName,
                                    years: null,
                                    industryName: '',
                                    skills: [],
                                    isPrimary: (result.candidates.techList[i].isPrimary == null ? false : result.candidates.techList[i].isPrimary)
                                });

                                for (let j = 0; j < result.candidates.techList[i].technologySkillData.length; j++) {
                                    if (j == 0) {
                                        techInfo[i].years = result.candidates.techList[i].technologySkillData[0].years;
                                        techInfo[i].industryName = result.candidates.techList[i].technologySkillData[0].industryName;
                                    }
                                    techInfo[i].skills.push(result.candidates.techList[i].technologySkillData[j].skillName);
                                }
                            }
                        }
                    }
                    this.technologyinfo = techInfo;
                    this.preferenceinfo.basepreference = result.candidates.candidatePreference;
                    this.preferenceinfo.otherpreference.timetocall = result.candidates.timeToCall;
                    this.preferenceinfo.otherpreference.workType = result.candidates.workType;
                    this.preferenceinfo.otherpreference.expectedSalary = result.candidates.expectedSalary;
                    this.preferenceinfo.otherpreference.minSalary = (result.candidates.minSalary == 0.0 ? '' : result.candidates.minSalary);
                    this.preferenceinfo.otherpreference.maxSalary = (result.candidates.maxSalary == 0.0 ? '' : result.candidates.maxSalary);
                    let freq = (result.candidates.flexibilityPercentage == 0.0 ? '' : result.candidates.flexibilityPercentage);
                    if (freq > 0) { this.preferenceinfo.otherpreference.frequencyPercentage = freq + "%" }
                    else {
                        this.preferenceinfo.otherpreference.frequencyPercentage = freq;
                    }
                    this.preferenceinfo.otherpreference.currency = result.candidates.currency;
                    this.preferenceinfo.otherpreference.payFrequency = result.candidates.payFrequency;
                    this.preferenceinfo.communicationMode = result.candidates.communication;

                    if (result.candidates.isPartTime) {
                        this.preferenceinfo.otherpreference.parttime = "yes";
                    } else {
                        this.preferenceinfo.otherpreference.parttime = "no";
                    }
                    if (result.candidates.isFullTime) {
                        this.preferenceinfo.otherpreference.fulltime = "yes";
                    } else {
                        this.preferenceinfo.otherpreference.fulltime = "no";
                    }
                    if (result.candidates.fileName != undefined && result.candidates.fileName != "") {
                        this.resumeinfo.filename = result.candidates.fileName;
                        this.resumeinfo.fileurl = result.candidates.filePath;


                    }
                    this.multipleFile = result.candidates.multipleFileData;
                    this.settingsInfo.timeZone = result.candidates.timeZone == null ? '' : result.candidates.timeZone;
                    this.settingsInfo.accessLevel = result.candidates.accessLevel == null ? '' : result.candidates.accessLevel;
                    this.showProgress = true;
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CandidLoadErr,
                        position: 'topRight'
                    });
                });

            axios.get(this.$store.getters.getAPIBasePath + '/interviewerresource/candidate/recruitment/' + candidateId)
                .then(response => {
                    let result = response.data;
                    this.interviews = result.interviews;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        candidateConfirmation(type) {
           
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/candidate/approval', { 'id': this.candidateId, referralId: this.personalinfo.referralId, 'statusName': type })
                .then(response => {
                    let result = response.data;
                    if (result.status) {
                        iziToast.success({
                            title: 'Confirmation Request',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadCandidateById(this.candidateid);
                    } else {
                        iziToast.error({
                            title: 'Confirmation Request',
                            message: 'Unable to update status',
                            position: 'topRight'
                        });
                    }
                });
            return true;
        },
        refreshCalendar() {
            this.showAvailabilityInfo = true;
        },
        viewFeedback(recId) {
            this.elements.feedback.recruitmentId = recId;
            this.elements.feedback.showFeedback = true;
        },
        closeFeedback() {
            this.elements.feedback.showFeedback = false;
            if (parseInt(localStorage.getItem('recruitmentId'))) {
                localStorage.setItem('recruitmentId', null);
                this.$router.push('/progress-interviews');
            }
        },
        getPrevUrl() {
            this.$router.go(-1);
        },
        scheduleInterview(bvModalEvt) {
            this.elements.submitted = true;
            this.$v.selectedValue.$touch();
            if (this.$v.selectedValue.$invalid) {
                bvModalEvt.preventDefault();
                return;
            }
            localStorage.setItem('recPlanId', this.recPlanId);
            localStorage.setItem('jobId', this.selectedValue);
            localStorage.setItem('recruitmentId', null);
            this.$router.push('/candidates/interview/' + this.candidateId);
            this.elements.submitted = false;
        },
        addNotes() {
            this.form.communication_type = "notes";
        },
        addCallback() {
            this.form.communication_type = "callback";
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.elements.submitted = true;
            this.handleSubmit();

        },
        handleSubmit() {
            if (this.elements.submitted) {
                if (!this.checkFormValidity()) {
                    return false;
                }
                this.$nextTick(() => {
                    this.$refs.notesModal.hide();
                });
            } else {
                return false;
            }
        },
        resetModal() {
            this.form.communication_type = "";
            this.form.communcation_mode = "";
            this.form.notes = "<h6>Add your notes here</h6>";
            this.elements.submitted = false;
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity();
            return valid;
        },
        emailtab() {
            this.elements.showEmailTools = true;
        },
        setContactReqParams(personalinfo){
           
           this.candContactRequest = {
            candidateId : personalinfo.profileid
           }
        },
        confirmContactRequest() {

            axios.post(this.$store.getters.getAPIBasePath + '/referralresource/referral/candidate/contact/request', this.candContactRequest)
                .then(response => {
                    let result = response.data;
                    if (result.status == 'OK') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    this.loadCandidateById(this.candidateid);
                 })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
