<template>
    <div class="account-info">
        <div class="card">
            <div class="card-header p-2">Account Information</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row col-md-12 my-4">
                        <div class="col-md-4">
                            <div class="table-responsive">
                                <table class="table table-striped table-borderless s_table compare-table">
                                    <tbody>
                                        <tr>
                                            <td width="50%"><strong>Total Candidates</strong></td>
                                            <td width="50%" class="text-center">{{ calcData.totalCandidates }}</td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong>Total Interviewers</strong></td>
                                            <td width="50%" class="text-center">{{ calcData.totalInterviewers }}</td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong>Total Clients</strong></td>
                                            <td width="50%" class="text-center">{{ calcData.totalClients }}</td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong>Total Referrals</strong></td>
                                            <td width="50%" class="text-center">{{ calcData.totalReferrals }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">Referral Rewards</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row col-md-12 my-4">
                        <div class="col-md-4">
                            <div class="table-responsive">
                                <table class="table table-striped table-borderless s_table compare-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col"></th>
                                            <th class="text-center" scope="col">Count</th>
                                            <th class="text-center" scope="col">Points</th>
                                            <th class="text-center" scope="col">Values</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(acceptedCandidates, index) in calcData.totalRefAcceptedCandidates">
                                            <td width="50%"><strong>Total Accepted Candidates</strong></td>
                                            <td width="50%" class="text-center">{{ acceptedCandidates.totalCount }}</td>
                                            <td v-if="acceptedCandidates.totalPoints != null" width="50%" class="text-center">{{ acceptedCandidates.totalPoints }}</td><td v-else width="50%" class="text-center">0</td>
                                            <td v-if="acceptedCandidates.totalValues != null" width="50%" class="text-center">{{ acceptedCandidates.totalValues }}</td><td v-else width="50%" class="text-center">0</td>
                                        </tr>
                                        <tr v-for="(candContactrequest, index) in calcData.totalRefCandContactRequest">
                                            <td width="50%"><strong>Total Candidates Contact Request</strong></td>
                                            <td width="50%" class="text-center">{{ candContactrequest.totalCount }}</td>
                                            <td v-if="candContactrequest.totalPoints != null" width="50%" class="text-center">{{ candContactrequest.totalPoints }}</td><td v-else width="50%" class="text-center">0</td>
                                            <td v-if="candContactrequest.totalValues != null" width="50%" class="text-center">{{ candContactrequest.totalValues }}</td><td v-else width="50%" class="text-center">0</td>
                                        </tr>
                                        <tr v-for="(hiredCandidates, index) in calcData.totalRefCandidatesHired">
                                            <td width="50%"><strong>Total Hired Candidates</strong></td>
                                            <td width="50%" class="text-center">{{ hiredCandidates.totalCount }}</td>
                                            <td v-if="hiredCandidates.totalPoints != null" width="50%" class="text-center">{{ hiredCandidates.totalPoints }}</td><td v-else width="50%" class="text-center">0</td>
                                            <td v-if="hiredCandidates.totalValues != null" width="50%" class="text-center">{{ hiredCandidates.totalValues }}</td><td v-else width="50%" class="text-center">0</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        profileId: Number
    },
    data() {
        return {
            calcData: {
                totalCandidates: '',
                totalInterviewers: '',
                totalClients: '',
                totalReferrals: '',
                totalRefAcceptedCandidates: [],
                totalRefCandContactRequest: [],
                totalRefCandidatesHired: []
            }
        }
    },
    mounted() {
        this.loadCount();
    },
    methods: {
        loadCount: function () {
            axios.get(this.$store.getters.getAPIBasePath + '/referralresource/referral/info/count/' + this.profileId)
                .then(response => {
                    console.log(response.data);
                    this.calcData.totalInterviewers = response.data.interviewers;
                    this.calcData.totalCandidates = response.data.candidates;
                    this.calcData.totalClients = response.data.clients;
                    this.calcData.totalReferrals = response.data.referrals;
                    this.calcData.totalRefAcceptedCandidates = response.data.refTotalAcceptedCandidates;
                    this.calcData.totalRefCandContactRequest = response.data.refTotalCandContactRequest;
                    this.calcData.totalRefCandidatesHired = response.data.refTotalCandidatesHired;
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
