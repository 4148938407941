<template>
    <div>
        <BulkUpload />
    </div>
</template>
<script>
import BulkUpload from '@/components/candidates/blocks/bulkupload/BulkUpload.vue';
export default {
    components: {
        BulkUpload
    },
}
</script>
