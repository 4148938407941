<template>
	<div class="email-template-add text-left">
		<div class="row col-md-12 p-4 mx-0">
			<h5>Create Social Media Template</h5>
		</div>
		<div class="row col-md-12 py-4 mx-0">
			<div class="col-md-6">
				<div class="row col-md-12 px-0">
					<div class="col-md-4">Template Name</div>
					<div class="col-md-8">
						<div class="col-md-12 col-sm-12 col-12">
							<input type="text" v-model="template.templateName" maxlength="90" class="form-control">
						</div>
						<div class="col-md-12 col-sm-12 col-12">
							<div v-if="elements.submitted && !$v.template.templateName.required" class="invalid-feedback mt-0">Template Name is required</div>
							<div v-if="elements.submitted && !$v.template.templateName.minLength" class="invalid-feedback mt-0">Template Name must have atleast 5 characters</div>
							<div v-if="elements.submitted && !$v.template.templateName.maxLength" class="invalid-feedback mt-0">Template Name shouldn't exceed more than 100 characters</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="row col-md-12 px-0">
					<div class="col-md-4">Template Type</div>
					<div class="col-md-8">
						<div class="col-md-12 col-sm-12 col-12">
							<select v-model="template.templateType" class="form-control" required>
								<option v-for="Type in templateTypesList" :value="Type.id">{{Type.name}}</option>
							</select>
						</div>
						<div class="col-md-12 col-sm-12 col-12">
							<div v-if="elements.submitted && !$v.template.templateType.required" class="invalid-feedback mt-0">Template Type is required</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row col-md-12 py-4 mx-0">
			<div class="col-md-6">
				<div class="row col-md-12 px-0">
					<div class="col-md-4">Social Media</div>
					<div class="col-md-8">
						<div class="col-md-12 col-sm-12 col-12">
							<select v-model="template.socialMediaId" class="form-control" required>
								<option v-for="SocialMedia in socialMediaList" :value="SocialMedia.id">{{SocialMedia.name}}</option>
							</select>
						</div>
						<div class="col-md-12 col-sm-12 col-12">
							<div v-if="elements.submitted && !$v.template.socialMediaId.required" class="invalid-feedback mt-0">Social Media is required</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="row col-md-12 px-0">
					<div class="col-md-4">Scope</div>
					<div class="col-md-8">
						<div class="col-md-12 col-sm-12 col-12">
							<select v-model="template.accessLevel" class="form-control" required disabled="true">
								<option v-for="Scope in emailTemplateScope" :value="Scope.id">{{Scope.name}}</option>
							</select>
						</div>
						<div class="col-md-12 col-sm-12 col-12">
							<div v-if="elements.submitted && !$v.template.accessLevel.required" class="invalid-feedback mt-0">Template Scope is required</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row col-md-12 py-4 mx-0">
			<div class="col-md-2">Content</div>
			<div class="col-md-10 pl-0">
				<div class="col-md-12 col-sm-12 col-12">
					<editor api-key="yn4wx6bfcohfz6bwlu5k4urno027lgfkcpxolz0f6k4kku01" :toolbar="toolbar" :init="initObj" v-model="template.content"></editor>
				</div>
				<div class="col-md-12 col-sm-12 col-12">
					<div v-if="elements.submitted && !$v.template.content.required" class="invalid-feedback mt-0">Content is required</div>
					<div v-if="elements.submitted && !$v.template.content.minLength" class="invalid-feedback mt-0">Content length must be 30 atleast</div>
				</div>
			</div>
		</div>
		<div class="col-md-12 col-sm-12 col-12 py-4">
			<div class="icons pull-right pr-3">
				<a href="javascript:" @click="$emit('closeActionTemplate')" class="edit-only cancel-icon btn btn-secondary mx-1">Cancel</a>
				<a href="javascript:" @click="saveTemplate" class="edit-only save-icon btn btn-primary mx-1">Save</a>	
			</div>
		</div>
	</div>
</template>

<script>
	import Editor from '@tinymce/tinymce-vue';
	import iziToast from 'izitoast';
	import { required, minLength, maxLength } from "vuelidate/lib/validators";
	export default {
		components: {
			Editor
		},
		props: {
			templatesList: Array,
			socialMediaList: Array
		},
		data(){
			let self = this;
			return {
				elements: {
					submitted: false
				},
				templateTypesList: this.$store.getters.getSocialMediaTemplateTypesList,
				emailTemplateScope: this.$store.getters.getEmailTemplateScope,
				weblinks: this.$store.getters.getWeblinks,
				toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
				template: {
					templateName: "",
					templateType: null,
					socialMediaId: null,
					accessLevel: 1,
					content: 'Edit Your Content Here!',
					existingTemplate: ""
				},
				initObj: {
					height: 300,
					branding: false,
					plugins: ['link','paste', 'wordcount', 'preview', 'image imagetools'],
					paste_data_images: true,
					images_upload_url: 'postAcceptor.php',
					automatic_uploads: false,
					file_picker_types: 'image',
					relative_urls : false,
					remove_script_host : false,
					setup: function(editor){
						editor.ui.registry.addMenuButton('weblinks', {
							text: "Web URL",
							icon: "link",
							fetch: function (callback) {
								var items = [];
								self.weblinks.map(function(value,key){
									console.log(value);
									items.push({
										type: 'menuitem',
										text: value.name,
										onAction: function(){
											editor.insertContent('<a href="'+value.id+'">'+value.name+'</a>');
										}
									})
								});
								callback(items);
							},
							onSelect: function(e) {
								tinyMCE.execCommand(this.value());
								console.log(this.value());
							},
						});
					}
				}
			}
		},
		validations: {
			template: {
				templateName: { required, minLength:minLength(5), maxLength:maxLength(100) },
				templateType: { required },
				socialMediaId: { required },
				accessLevel: { required },
				content: { required, minLength:minLength(30) }
			}
		},
		mounted(){
			this.$emit('getSocialMediaTemplatesList',0);
		},
		methods: {
			saveTemplate:function(){
				this.elements.submitted = true;
				this.$v.$touch();
				if(this.$v.$invalid) return false;
				axios.post(this.$store.getters.getAPIBasePath+'/templateresource/add/socialmedia/template',this.template)
				.then(response => {
					let result = response.data;
					if (result.status != "ACCEPTED" || result.status == "CONFLICT") {
						iziToast.error({title: 'Error', message: result.message, position: 'topRight'});
						return false;
					}
					iziToast.success({title: 'Success', message: result.message, position: 'topRight'});
					this.elements.submitted = false;
					this.$emit('getSocialMediaTemplatesList',0);
					this.$emit('closeActionTemplate');
				})
				.catch(error => {
					iziToast.error({title: 'Error', message: this.$config.TempSavErr, position: 'topRight'});
				});
			}
		}
	}
</script>