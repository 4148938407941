<template>
    <div id="content_Personal_Info">
        <div class="card">
            <div class="card-header p-2">
                <div class="row">
                    <div class="col-md-6">
                        Primary Information
                    </div>
                    <div class="col-md-6 text-right">
                        {{ProfileData.recruiterCode}}
                    </div>
                </div>
            </div>
            <div class="card-body p-2">
                <div class="basic-block col-md-6">
                    <div class="table-responsive">
                        <table class="table table-striped table-borderless s_table compare-table">
                            <tbody>
                                <tr>
                                    <td width="30%"><strong>Name</strong></td>
                                    <td width="70%" class="text-left">{{ProfileData.firstName}} {{ProfileData.lastName}}</td>
                                </tr>
                               
                                <tr>
                                    <td width="30%"><strong>UserName (Email)</strong></td>
                                    <td width="70%" class="text-left">{{ProfileData.email}}</td>
                                </tr>
                                <tr>
                                    <td width="30%"><strong>Company</strong></td>
                                    <td width="70%" class="text-left" v-if="ProfileData.company!=null">{{ProfileData.company}}</td>
                                </tr>
                                 <tr v-if="ProfileData.role">
                                    <td width="30%"><strong>Role</strong></td>
                                    <td width="70%" class="text-left">{{ProfileData.role}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.p-2 {
    margin-right: 0.1rem;
}
</style>

<script>
export default {
    props: {
            ProfileData: Object,
        },
    data() {
        return {
            industries:this.$store.getters.getIndustries,
            EducationalInfos:[
                {
                    id:1
                }
            ]
        }
    },
    mounted(){
    },
    methods:
    {   
        getIndustryName:function(industryId){
            let industry_name = this.industries.filter(obj => {
                return(obj.id === industryId)
            });
            return industry_name[0].industryName;
        },
        getGenderName:function(genderCode){

        }
    }
}

</script>