<template>
	<div id="interviewer-edit-block" class="interviewer-edit-block mt-70">
		<div class="container-fluid driver-model" id="main-data">
			<div class="row">
				<div class="col-md-3">
					<div class="row">
						<div class="col-sm-12">
							<div class="card border-5 mb-3">
								<div class="col-md-12 col-sm-12 px-0 about-box">
									<div class="col-md-10 px-0 float-left">
										<h6 class="m-0 text-left p-2">
											<a href="javascript:" @click="$router.go(-1)" title="Back" class="p-2">
												<i class="ti-arrow-left "></i>
												<span> Back</span>
											</a>
										</h6>
									</div>
									<div class="col-md-2 px-0 pull-right py-2">
										<a href="javascript:" @click="elements.showEmailTools=true" title="Email To"><i class="ti-email px-1"></i></a>
										<router-link title="Edit Interviewer" :to="{ name: 'referraleditinterviewer', params: {interviewerid: ProfileData.id}}"><i class="fa fa-pencil px-1"></i></router-link>
									</div>
								</div>
								<div class="user-card pl-5 pr-5 pt-3">
									<div class="information">
										<div class="imgUp">
											<div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + ProfileData.image + ')' }"></div>
										</div>
										<div class="name">{{ProfileData.firstName}} {{ProfileData.lastName}}</div>
										<div>{{ProfileData.position}} </div>
										<div v-if="ProfileData.email" class="position"><i class="ti-email"></i> {{ProfileData.email}}</div> 
										<div v-if="ContactInfo.mobileNo" class="position"><i class="ti-mobile"></i> {{ContactInfo.mobileNo}}</div>
									</div>
								</div>
							</div>
							<div class="card border-5">
								<div class="user-card p-0">
									<h5 class="about-box m-0 text-left p-2">About Me</h5>
									<div class="p-3 float-left w-100">
										<h5>Specialization</h5>
										<div class="informations">
											<span v-if="TechSkills.length==0" class="badge p-2 mb-1">No records found</span>
											<span v-if="TechSkills.length!=0" v-for="(Skill,idx) in TechSkills" v-bind:key="idx" class="badge p-1 m-1" :class="colours[idx%5]">{{Skill.skillName}}</span>
										</div>
										<div v-if="ContactInfo!== null" class="w-100 float-left">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-9">
					<div class="box-wrapper shadow-sm  bg-white rounded pos-rel main-search">
						<div class="row">
							<div class="col-sm-12">
								<div class="row no-gutters">
									<div class="col-md-12 pop-tab">
										<div class="driver-model">
											<div class="row no-gutters">
												<b-tabs v-if="!elements.showEmailTools" v-model="currentTab" small card class="tab-list driver-tab-list tab-bg">
													<b-tab title="Primary Info">
														<PersonalInfo :ProfileData=ProfileData ref="personalinfocomponent"></PersonalInfo>
													</b-tab>
													<b-tab title="Contact Info">
														<ContactInfo :ContactInfo=ContactInfo ref="contactInfocomponent"></ContactInfo>
													</b-tab>
												</b-tabs>
												<EmailTools v-if="elements.showEmailTools" :mailTo="{email: ProfileData.email, id: ProfileData.id, name: ProfileData.firstName+' '+ProfileData.lastName, userType: 'interviewer'}" @closeEmailTools="elements.showEmailTools=false"></EmailTools>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PersonalInfo from '@/components/interviewers/blocks/viewinterviewer/PersonalInfo';
	import ContactInfo from '@/components/interviewers/blocks/viewinterviewer/ContactInfo';
	import EmailTools from '@/components/recruiters/blocks/tools/EmailTools';
	export default {
		components: {
			PersonalInfo,
			ContactInfo,
			EmailTools
		},
		data() {
			return {
				elements: {
			
					showEmailTools: false
				},
				currentTab:0,
				interviewerId: this.$route.params.interviewerid,
				ProfileData:{
					id:0,
					firstName:'',
					lastName:'',
					nickName:'',
					email:'',
					dateOfBirth:'',
					gender:'',
					position:'',
					image:'',
					imageName:'',
					imagePath:'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
					image:'',
					industryId: null
				},
				ContactInfo: Object,
				TechSkills:[],
				colours: ['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info']
			}
		},
		mounted(){
			this.loadInterviewer();
		},
		methods: {
			loadInterviewer:function(){
				axios.get(this.$store.getters.getAPIBasePath+'/interviewerresource/view/interviewer/'+this.interviewerId)
				.then(response => {
					if(response.data.status){
						let interviewerData = response.data.interviewer;
						this.ProfileData.id = interviewerData.id;
						this.ProfileData.firstName = interviewerData.firstName;
						this.ProfileData.lastName = interviewerData.lastName;
						this.ProfileData.nickName = interviewerData.nickName;
						this.ProfileData.email = interviewerData.email;
						this.ProfileData.dateOfBirth = interviewerData.dateOfBirth;
						this.ProfileData.gender = interviewerData.gender;
						this.ProfileData.position = interviewerData.position;
						this.ProfileData.imageName = interviewerData.imageName;
						this.ProfileData.imagePath = (interviewerData.imagePath==''?'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png':interviewerData.imagePath);
						this.ProfileData.image = (interviewerData.imagePath==''?'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png':interviewerData.imagePath);
						this.ProfileData.industryId = interviewerData.industryId;
						this.updateSkills(interviewerData.interviewersSkill);
						this.ContactInfo = Object.assign({}, interviewerData.interviewersContact);
					}
				})
				.catch(error => {
					console.log(error);
				});
			},
			updateSkills:function(Skills){
				let self = this;
				self.TechSkills = [];
				$.each(Skills,function(key,value){
					if(value.technologySkillSet != null)
						self.TechSkills.push(value.technologySkillSet);
				});
			}
		}
	}
</script>