<template>
    <div id="main" class=" mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
         <!--    <div class=" wrapper w-100 px-0">     -->       
                <div class="driver-model" style="padding:0px 0px 10px;">
                  <!--   <div class="editcan content-wrapper"> -->
                        <div class="row">
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="card border-5 mb-3">
                                            <h6 class="about-box m-0 text-left p-2">
                                                <a href="javascript:" @click="getPrevUrl()" title="Back" class="p-2">
                                                    <i class="fa fa-arrow-left"></i>
                                                    <span> Back</span>
                                                </a>
                                                <router-link class="float-right link pr-2" title="View Profile" :to="{ name: 'candidateprofile'}"><i class="fa fa-eye fa_custom fa-lg "></i> </router-link>
                                            </h6>
                                            <div class="user-card p-3">
                                                <div class="information">
                                                    <div class="userimage-progress">
                                                        <div class="progress-circle">
                                                            <vue-circle v-if="showProgress" :progress="personalinfo.profileCompletion" :size="75" :reverse="true" line-cap="round" :fill="fill" empty-fill="rgba(0, 0, 0, .1)" :animation-start-value="0.0" :start-angle="0" insert-mode="append" :thickness="7" :show-percent="true" @vue-circle-progress="progress" @vue-circle-end="progress_end">
                                                                <div class="imgUp">
                                                                    <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + personalinfo.image + ')' }"></div>
                                                                    <label class="upload-icon uploadleft"><i class="ti-camera"></i><input ref="candidate_add_profile_photo" id="candidate_aad_profile_photo" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="upload_photo"></label>
                                                                    <label class="delete-icon deleteleft"><i class="ti-close closefont" @click="delete_photo"></i></label>
                                                                </div>
                                                            </vue-circle>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="imgUp">
                                                <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + personalinfo.imagePath + ')' }"></div>
                                                <label class="upload-icon"><i class="ti-camera"></i><input ref="candidate_add_profile_photo" id="candidate_aad_profile_photo" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="upload_photo"></label>
                                                 <input class="upload" ref="addcandidate_resumeattach_resume" id="candidateadd_resume_fileupload" type="file" accept=".doc,.docx,.pdf" v-on:change="handleFileUpload"></input>
                                            </div> -->
                                                    <div class="name">{{personalinfo.firstName}} {{personalinfo.lastName}}</div>
                                                    <div class="code">[{{personalinfo.candidateCode}}]</div>
                                                    <div>{{/*personalinfo.industry.industryName*/}} {{personalinfo.position}} </div>
                                                    <div v-if="personalinfo.email && candContactAccess==1" class="position"><i class="ti-email"></i> {{personalinfo.email}}</div>
                                                    <div v-if="personalinfo.mobile && candContactAccess==1" class="position"><i class="ti-mobile"></i> {{personalinfo.mobile}}</div>
                                                    <!-- <div class="position"><i class="ti-mobile"></i> {{personalinfo.firstName}}, {{personalinfo.experience}} Y </div> -->
                                                    <h4>Profile Completion {{personalinfo.profileCompletion}}%</h4>
                                                    <div class="progress">
                                                        <div class="progress-bar" :style="applyPercentage(personalinfo.profileCompletion)" role="progressbar" aria-valuenow="personalinfo.profileCompletion" aria-valuemin="0" aria-valuemax="100">
                                                            <span class="title">{{personalinfo.profileCompletion}}%</span>
                                                        </div>
                                                    </div>
                                                    <!-- <b-progress  :style="applyPercentage(personalinfo.profileCompletion)" :value="personalinfo.profileCompletion" :max="max" show-progress  class="mb-3"></b-progress> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-5">
                                            <div class="user-card p-0">
                                                <h5 class="about-box m-0 text-left p-2">About Me</h5>
                                                <div class="p-3 float-left w-100">
                                                    <h5>Technology / Specialization</h5>
                                                    <div class="informations">
                                                        <ul>
                                                            <li :class="colours[index%5]" class="badge" v-for="(info, index) in technologyinfo" v-bind:key="index">
                                                                {{info.technology.technology}}
                                                                <span v-if="info.experience.label != null"> -
                                                                    {{info.experience.label}} y </span>
                                                            </li>
                                                        </ul>
                                                        <!-- <span v-for="(info, index) in technologyinfo.skillset" class="p-1 mb-2 mr-1 badge " :class="colours[index%5]"><span v-if="technologyinfo.skillset[index].technologySet != null">{{technologyinfo.skillset[index].technologySet.technology}} - {{technologyinfo.skillset[index].years}} y
                                                    </span>
                                                </span> -->
                                                        <!-- <span v-for="(info, index) in technologyinfo.skillset" class="p-1 mb-2 mr-1 badge badge-primary">{{technologyinfo.skillset[index].technologySet.technology}} - {{technologyinfo.skillset[index].years}} y
                                                </span> -->
                                                    </div>
                                                    <div v-if="contactinfo.currentaddress.addressLine1!== null" class="w-100 float-left">
                                                        <hr>
                                                        <h5><i class="ti-location-pin"></i> Location</h5>
                                                        <div class="information">
                                                            <p> {{personalinfo.location}}. </p>
                                                        </div>
                                                    </div>
                                                    <div v-if="personalinfo.educationinfo.length!=0" class="w-100 float-left">
                                                        <hr>
                                                        <h5><i class="ti-briefcase"></i> Education</h5>
                                                        <div class="information">
                                                            <p v-for="(info, index) in personalinfo.educationinfo">
                                                                {{personalinfo.educationinfo[index].degree}} - {{personalinfo.educationinfo[index].year}}, {{personalinfo.educationinfo[index].schoolName}}, {{personalinfo.educationinfo[index].city}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-9 pl-0">
                                <div class="row no-gutters">
                                    <b-tabs v-model="currentCandidateTab" small card class="tab-list driver-tab-list">
                                        <b-tab title="Primary Info">
                                            <PersonalInfo v-if="elements.showSubComponents" :personalinfo="personalinfo" :newAdditionalField="newAdditionalField" :candContactAccess="candContactAccess" ref="personalinfocomponent"></PersonalInfo>
                                        </b-tab>
                                        <b-tab v-if="candContactAccess==1" title="Address Info">
                                            <ContactInfo v-if="elements.showSubComponents" :contactinfo="contactinfo" :candContactAccess="candContactAccess" ref="contactinfocomponent"></ContactInfo>
                                        </b-tab>
                                        <b-tab title="Emp History">
                                            <EmploymentInfo v-if="elements.showSubComponents" :employmentinfo="employmentinfo" ref="employmentinfocomponent"></EmploymentInfo>
                                        </b-tab>
                                        <!-- <b-tab title="Reference Details">
                                    <ReferenceInfo :referenceinfo="employmentinfo"></ReferenceInfo>
                                </b-tab> -->
                                        <b-tab title="Technology/Skills">
                                            <TechnologyInfo v-if="elements.showSubComponents" :technologyinfo="technologyinfo" :techinfo="techinfo" ref="technologyinfocomponent"></TechnologyInfo>
                                        </b-tab>
                                        <b-tab title="Projects">
                                            <ProjectInfo v-if="elements.showSubComponents" :projectinfo="projectinfo" ref="projectinfocomponent"></ProjectInfo>
                                        </b-tab>
                                        <b-tab title="Preferences">
                                            <PreferenceInfo v-if="elements.showSubComponents" :preferenceinfo="preferenceinfo" :candStatus="candStatus" ref="preferenceinfocomponent"></PreferenceInfo>
                                        </b-tab>
                                        <b-tab title="Availability" @click="refreshCalendar">
                                            <AvailabilityInfo v-if="elements.showAvailabilityInfo" :availabilityinfo="availabilityinfo" ref="availabilityinfocomponent"></AvailabilityInfo>
                                        </b-tab>
                                        <b-tab v-if="candContactAccess==1" title="Resume">
                                            <ResumeAttach v-if="elements.showSubComponents" :isResumeCapture="isResumeCapture" :resumeinfo="resumeinfo" :attachments="attachments" :attachmentTypes="attachmentTypes" :multipleFile="multipleFile" ref="resumeattachcomponent" :candidateId="candidateid" :candContactAccess="candContactAccess"></ResumeAttach>
                                        </b-tab>
                                        <!-- hide this for alignment -->
                                        <!-- <b-tab title="Screening Results">
                                            <Screening v-if="elements.showSubComponents" ref="screeningcomponent"></Screening>
                                        </b-tab> -->
                                        <b-tab title="Settings">
                                            <Settings v-if="elements.showSubComponents" :settingsInfo="settingsInfo" ref="settingscomponent"></Settings>
                                        </b-tab>
                                        <!-- <b-tab title="Incident History">
                                            <IncidentHistory ref="incidentHistoryComponent"></IncidentHistory>
                                        </b-tab> -->
                                    </b-tabs>
                                    <div class="col-md-12 pt-2 mt-3 text-right">
                                        <a v-if="currentCandidateTab!=6 && currentCandidateTab!=9" href="Javascript:void(0);" @click="saveCurrentTab" class="btn btn-primary float-right">Save</a>
                                        <a v-if="currentCandidateTab!=6 && currentCandidateTab!=9" href="Javascript:void(0);" class="btn btn-secondary mr-2">
                                            <router-link :to="{ name: 'candidateprofile' }" class="text-white">Cancel</router-link>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                  <!--   </div> -->
                </div>
          <!--   </div> -->
        </div>
        <b-modal id="newResumeConfirm" :no-close-on-backdrop="true" hide-header-close centered size="sm" title="Confirmation" @ok="newResumeConfirm" @cancel="cancelNewResume" @hidden="" ok-title="Yes" cancel-title="No">
            <p class="my-1">Do you want to attach new resume?</p>
        </b-modal>
    </div>
</template>
<style>
.informations>ul>li {
    /*display: inline;*/
    margin: 2px;
}

</style>
<script>
import iziToast from 'izitoast';
import PersonalInfo from '@/components/candidates/blocks/addcandidate/PersonalInfo';
import ContactInfo from '@/components/candidates/blocks/addcandidate/ContactInfo';
import EmploymentInfo from '@/components/candidates/blocks/addcandidate/EmploymentInfo';
import ReferenceInfo from '@/components/candidates/blocks/addcandidate/ReferenceInfo';
import TechnologyInfo from '@/components/candidates/blocks/addcandidate/TechnologyInfo';
import PreferenceInfo from '@/components/candidates/blocks/addcandidate/PreferenceInfo';
import ResumeAttach from '@/components/candidates/blocks/addcandidate/ResumeAttach';
import AvailabilityInfo from '@/components/candidates/blocks/addcandidate/AvailabilityInfo';
import ProjectInfo from '@/components/candidates/blocks/addcandidate/ProjectInfo';
import Settings from '@/components/candidates/blocks/addcandidate/Settings';
import VueCircle from 'vue2-circle-progress';


export default {
    computed: {
        candidateid() {
            return this.$route.params.candidateid;
        }
    },
    components: {
        PersonalInfo,
        ContactInfo,
        EmploymentInfo,
        ReferenceInfo,
        TechnologyInfo,
        PreferenceInfo,
        AvailabilityInfo,
        ResumeAttach,
        ProjectInfo,
        Settings,
        VueCircle
    },
    data() {
        return {
            elements: {
                showAvailabilityInfo: false,
                showSubComponents: false,
                showIframe: false,
            },
            showProgress: false,
            value: 0,
            max: 100,
            currentCandidateTab: 0,
            maxtabs: 11,
            personalinfo: {
                candidateCode: "",
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                countryCode: "+91",
                mobile: "",
                mobileNo:"",
                dateOfBirth: "",
                dateAvailable: "",
                industryId: "",
                position: "",
                location: "",
                experience: "",
                expMonth: "",
                expType: "Years",
                referredBy: "",
                payMode: "",
                recruiterId: "",
                visaTypeId: "",
                visaType: "",
                notes: "",
                educationinfo: [],
                blockpersonaltab: 0,
                profileCompletion: 0,
                image:"",
                imageName: '',
                imagePath: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
                candidateLanguageSet: [],
                candidateLanguage: [],
                sourcingChannel: "",
                sourcingRefLink: "",
                linkedInUrl: "",
                candidateAdditionalFields: [],
                status: null,
                extractedResume: "",
                candidateType: "",
                willRelocate: "",
                editIndex: false
            },
            deleteparams:{
            id: 0,
            type:'Candidate'
            },
            contactinfo: {
                currentaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "US"
                },
                permanentaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "US"
                },
                emergency: []
            },
            employmentinfo: [],
            technologyinfo: [],
            techinfo: [],
            candStatus: "",
            preferenceinfo: {
                basepreference: [],
                otherpreference: {
                    timetocall: "",
                    workType: "",
                    expectedSalary: "",
                    minSalary: "",
                    maxSalary: "",
                    frequencyPercentage: "",
                    parttime: "no",
                    fulltime: "no",
                    currency:"$",
                    payFrequency:"Per Month",
                },
                candidateCommunication: []
            },
            resumeinfo: {
                id: '',
                file: '',
                fileurl: '',
                filename: '',
                title: 'Resume Upload',
                supportedTypes: 'Supports only PDF, Doc., Docx file types'
            },
            availabilityinfo: {

            },
            projectinfo: [],
            colours: ['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info'],
            screeninginfo: [],
            settingsInfo: {
                timeZone: "",
                accessLevel: 1
            },
            newAdditionalField: {
                id: 0,
                nameValueId: 0,
                valueFormatId: "",
                intValue: "",
                stringValue: "",
                dateValue: "",
                booleanValue: ""
            },
            taskId: 0,
            activityId: 0,
            fill: { gradient: ["#37A0E4", "#37A0E4", "#808080"] },
            attachments: [],
            attachmentTypes: [],
            candidateIdForAttachment: '',
            isResumeCapture: false,
            multipleFile: [],
            docSrc: "",
            candContactAccess: 1,
            updatedFileName: ''
        }
    },
    watch: {
        'resumeinfo.fileurl': {
            handler: function(value) {
                this.bindIframeSrc();
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
       // if (localStorage.getItem('emailAlreadyExists') == null) {
            //this.sidebarToggle();
       // }

        this.loadCandidateById(this.candidateid);
        // this.refreshCalendar();       
        this.bindIframeSrcemailAlreadyExists
    },
    methods: {
        bindIframeSrc: function() {
            console.log(1, this.resumeinfo.fileurl);
            console.log(localStorage.getItem('parsedResumeFileName'));
            console.log(localStorage.getItem('emailAlreadyExists'));

            let filePath = '';
            if (localStorage.getItem('emailAlreadyExists') != null && localStorage.getItem('emailAlreadyExists') == "true") {
                filePath = localStorage.getItem('parsedResumeFileName');
            } else {
                filePath = this.resumeinfo.fileurl;
            }
            this.docSrc = "https://docs.google.com/gview?url=" + filePath + "&embedded=true";
            if (filePath != '')
                this.elements.showIframe = true;
            else
                this.elements.showIframe = false;

            console.log(this.elements.showIframe);
        },
        progress(event, progress, stepValue) {
            //console.log(stepValue);
        },
        progress_end(event) {
            //console.log("Circle progress end");
        },
         delete_photo: function() {
            this.deleteparams.id=this.candidateid;
            axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/delete/candidate/photo',this.deleteparams)
                .then(response => {
                    if(response.data.status=="OK"){
                        iziToast.success({ title: 'Photo update', message: response.data.message, position: 'topRight' });
                        this.personalinfo.image="";
                        this.personalinfo.imageName="";
                        this.personalinfo.imagePath="";
                        this.loadCandidateById(this.candidateid);
                    }
                    else if(response.data.status=="EXPECTATION_FAILED"){
                    console.log(response);
                    iziToast.info({ title: 'Photo update', message: response.data.message, position: 'topRight' });}
                })
                .catch(function(error) {
                    console.log(error);
                });
           
        },
         createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;
            reader.onload = (e) => {
                this.personalinfo.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        upload_photo: function() {
            if (!/\.(jpg|svg|jpeg|png|bmp|gif|jfif)$/i.test(this.$refs.candidate_add_profile_photo.files[0].name)) {
                iziToast.error({
                    title: 'Photo update',
                    message: this.$config.FileFormatErr,
                    position: 'topRight'
                });
                return false;
            }
            this.createImage(this.$refs.candidate_add_profile_photo.files[0]);
            //this.profilePhoto = this.$refs.addcandidate_profile_photo1.files[0];
            let formData = new FormData();
            formData.append('file', this.$refs.candidate_add_profile_photo.files[0]);
            formData.append('id', this.candidateid);
            formData.append('type', 'candidate');
            axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/uploadprofilephoto',
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.ProfileUpSuc,
                        position: 'topRight',
                        timeout: 1000
                    });
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.ProfileUpErr,
                        position: 'topRight'
                    });
                });
        },
        applyPercentage(percentage) {
            return 'max-width:' + percentage + '%'
        },
        loadCandidateById(candidateId) {
            this.showProgress = false;
            axios.get(this.$store.getters.getAPIBasePath + '/candidateresource/view/candidate/' + candidateId)
                .then(response => {
                    let result = response.data;
                    if (!result.status) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$router.push('/candidate/profile');                       
                    }

                    let languages1 = [];
                    let languages2 = [];

                    for (var i = 0; i < result.candidates.candidateLanguage.length; i++) {
                        languages1.push({ languageId: result.candidates.candidateLanguage[i].languageId });
                        languages2.push(result.candidates.candidateLanguage[i].languageId);
                    }

                    var techAndSkill = [];
                    if (result.candidates.techList != null) {
                        for (var i = 0; i < result.candidates.techList.length; i++) {
                            let skillData = result.candidates.techList[i];

                            if (skillData.technologySkillData.length != 0) {
                                techAndSkill.push({
                                    technology: { technology: skillData.technologyName, id: null },
                                    skills: [],
                                    experience: { value: null, label: null },
                                    industry: { industryName: '', id: null },
                                    isPrimary: (skillData.isPrimary == null ? false : skillData.isPrimary)
                                });
                                for (var j = 0; j < skillData.technologySkillData.length; j++) {
                                    if (j == 0) {
                                        techAndSkill[i].technology.id = skillData.technologySkillData[j].technologyId;
                                        techAndSkill[i].experience.value = skillData.technologySkillData[j].years;
                                        techAndSkill[i].experience.label = skillData.technologySkillData[j].years;
                                        techAndSkill[i].experience.label = (techAndSkill[i].experience.label == 0 ? '<1' : techAndSkill[i].experience.label);
                                        techAndSkill[i].industry.industryName = skillData.technologySkillData[j].industryName;
                                        techAndSkill[i].industry.id = skillData.technologySkillData[j].industryId;
                                    }

                                    techAndSkill[i].skills.push({
                                        skillId: skillData.technologySkillData[j].id,
                                        skillName: skillData.technologySkillData[j].skillName
                                    });
                                }
                            }
                        }
                    }
                    this.candStatus = result.candidates.candStatus;
                    let self = this;
                    this.personalinfo = result.candidates;
                    this.personalinfo = {
                        candidateCode: result.candidates.candidateCode,
                        firstName: result.candidates.firstName,
                        middleName: result.candidates.middleName,
                        lastName: result.candidates.lastName,
                        middleName: result.candidates.middleName,
                        email: result.candidates.email,
                        mobile: result.candidates.mobile,
                        dateOfBirth: result.candidates.dateOfBirth,
                        dateAvailable: result.candidates.dateAvailable,
                        industryId: (result.candidates.industryId == undefined) ? '' : result.candidates.industryId,
                        position: result.candidates.position,
                        location: result.candidates.location,
                        experience: result.candidates.experience,
                        expMonth: result.candidates.expMonth,
                        expType: (result.candidates.expType == null) ? "Years" : result.candidates.expType,
                        referredBy: result.candidates.referredBy,
                        sourcingChannel: (result.candidates.sourcingChannelId == undefined) ? '' : result.candidates.sourcingChannelId,
                        sourcingRefLink: result.candidates.sourcingRefLink,
                        linkedInUrl: result.candidates.linkedInUrl,
                        payMode: (result.candidates.payMode == undefined) ? '' : result.candidates.payMode,
                        recruiterId: (result.candidates.recruiterId == undefined) ? '' : result.candidates.recruiterId,
                        visaTypeId: (result.candidates.visaTypeId == undefined) ? '' : result.candidates.visaTypeId,
                        visaType: { id: result.candidates.visaTypeId, text: result.candidates.visaTypeText },

                        candidateType: (result.candidates.candidateType == undefined) ? '' : result.candidates.candidateType,
                        notes: result.candidates.notes,
                        educationinfo: result.candidates.candidateEducation,
                        blockpersonaltab: 0,
                        profileid: this.candidateid,
                        profileCompletion: result.candidates.profileCompletion,
                        imageName: result.candidates.imageName,
                        image: (result.candidates.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : result.candidates.imagePath),
                        imagePath: (result.candidates.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : result.candidates.imagePath),
                        candidateLanguageSet: languages2,
                        candidateLanguage: languages1,
                        candidateAdditionalFields: result.candidates.candidateAdditionalFields,
                        status: result.candidates.status,
                        extractedResume: result.candidates.extractedResume,
                        willRelocate: result.candidates.willRelocate,
                        referralId: result.candidates.referralId
                    };
                    if (this.candContactAccess == 1) {
                        this.personalinfo.editIndex = true;
                    } else {
                        this.personalinfo.editIndex = false;
                    }
                    if (result.candidates.visaTypeId == null && result.candidates.visaTypeText == null) {
                        this.personalinfo.visaType = "";
                    }

                    value: result.candidates.profileCompletion,
                        result.candidates.candidateContact.forEach(function(data) {
                            if (data.contactType != null && data.contactType.toLowerCase() == "current") {
                                self.contactinfo.currentaddress = data;
                            } else {
                                self.contactinfo.permanentaddress = data;
                            }
                        });
                    this.contactinfo.emergency = result.candidates.candidateEmergencyContact;
                    this.employmentinfo = result.candidates.candidateEmployment;

                    this.contactinfo.emergency.forEach(function(data) {
                        let phoneNo = data.phone;
                            data.phoneNo = phoneNo;
                                   
                    }); 

/*                     let phoneNo = result.candidates.mobile;
                    this.personalinfo.mobileNo = phoneNo;
                    if (phoneNo != null && phoneNo.startsWith("+")) {
                        let phoneData = phoneNo.split(" ");
                        this.personalinfo.countryCode = phoneData[0];
                        this.personalinfo.mobile = phoneData[1];
                    }else{
                        this.personalinfo.mobile = phoneNo;
                    } */


                    for (var i = 0; i < this.employmentinfo.length; i++) {
                        if (this.employmentinfo[i].employedFrom != null) {
                            this.employmentinfo[i].employedFrom = this.employmentinfo[i].employedFrom.substring(0, 7);
                            this.employmentinfo[i].employedFrom1 = this.employmentinfo[i].employedFrom.substring(0, 7);
                        } else {
                            this.employmentinfo[i].employedFrom = "";
                        }

                        if (this.employmentinfo[i].employedTo != null) {
                            this.employmentinfo[i].employedTo = this.employmentinfo[i].employedTo.substring(0, 7);
                            this.employmentinfo[i].employedTo1 = this.employmentinfo[i].employedTo.substring(0, 7);
                        } else {
                            this.employmentinfo[i].employedTo = "";
                        }
                    }

                    this.projectinfo = result.candidates.candidateProject;
                    for (var i = 0; i < this.projectinfo.length; i++) {
                        if (this.projectinfo[i].projectStartDate != null) {
                            this.projectinfo[i].projectStartDate1 = this.projectinfo[i].projectStartDate.substring(0, 7);
                        } else {
                            this.projectinfo[i].projectStartDate = "";
                        }

                        if (this.projectinfo[i].projectEndDate != null) {
                            this.projectinfo[i].projectEndDate1 = this.projectinfo[i].projectEndDate.substring(0, 7);
                        } else {
                            this.projectinfo[i].projectEndDate = "";
                        }
                    }

                    //this.technologyinfo.skillset = result.candidates.candidateSkill;
                    this.technologyinfo = techAndSkill;
                    this.preferenceinfo.basepreference = result.candidates.candidatePreference;
                    this.preferenceinfo.otherpreference.timetocall = result.candidates.timeToCall;
                    this.preferenceinfo.otherpreference.workType = (result.candidates.workType == null ? '' : result.candidates.workType);
                    this.preferenceinfo.otherpreference.expectedSalary = (result.candidates.expectedSalary == null ? '' : result.candidates.expectedSalary);
                    this.preferenceinfo.otherpreference.minSalary = (result.candidates.minSalary == 0.0 ? '' : result.candidates.minSalary);
                    this.preferenceinfo.otherpreference.maxSalary = (result.candidates.maxSalary == 0.0 ? '' : result.candidates.maxSalary);
                    this.preferenceinfo.otherpreference.frequencyPercentage = (result.candidates.flexibilityPercentage == 0.0 ? '' : result.candidates.flexibilityPercentage);
                    this.preferenceinfo.otherpreference.currency = (result.candidates.currency == null ? '$' : result.candidates.currency);
                    this.preferenceinfo.otherpreference.payFrequency = (result.candidates.payFrequency == null ? 'Per Month' : result.candidates.payFrequency);
                    if (result.candidates.isPartTime) {
                        this.preferenceinfo.otherpreference.parttime = "yes";
                    } else {
                        this.preferenceinfo.otherpreference.parttime = "no";
                    }
                    if (result.candidates.isFullTime) {
                        this.preferenceinfo.otherpreference.fulltime = "yes";
                    } else {
                        this.preferenceinfo.otherpreference.fulltime = "no";
                    }
                    if (result.candidates.candidateCommunication.length) {
                        this.preferenceinfo.candidateCommunication = result.candidates.candidateCommunication;
                    }
                    if (result.candidates.fileName != undefined && result.candidates.fileName != "") {
                        this.resumeinfo.filename = result.candidates.fileName;
                        this.resumeinfo.fileurl = result.candidates.filePath;
                        this.resumeinfo.id = result.candidates.resumeId;
                    }
                    this.settingsInfo.timeZone = result.candidates.timeZone == null ? '' : result.candidates.timeZone;
                    this.settingsInfo.accessLevel = result.candidates.accessLevel == null ? '' : result.candidates.accessLevel;
                    this.elements.showSubComponents = true;
                    this.showProgress = true;
                    this.multipleFile = result.candidates.multipleFileData;

                    this.bindIframeSrc();
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CandidLoadErr,
                        position: 'topRight'
                    });
                    this.$router.push('/candidate/profile');
                });
        },
        async saveCurrentTab() {
            if (localStorage.getItem('emailAlreadyExists') != null && localStorage.getItem('emailAlreadyExists') == 'true') {
                await this.showconfirmDialog();
            } else {
                this.saveData();
            }

        },
        saveData() {
            switch (this.currentCandidateTab) {
                case 0:
                    //personal info validation
                    if (this.$refs.personalinfocomponent.validatePersonalInfo()) {

                        this.$refs.personalinfocomponent.addEducationInfo();
                        if (this.personalinfo.blockpersonaltab == 0) {
                            this.savePersonalInfo();
                        }
                    }

                    break;
                case 1:
                    //contact info tab
                    this.$refs.contactinfocomponent.addNewContact();
                    this.saveContactInformation();
                    break;
                case 2:
                    this.$refs.employmentinfocomponent.addNewEmploymentInfo();
                    this.saveEmploymentInformation();
                    break;
                case 3:
                    this.$refs.technologyinfocomponent.addMoreTechInfo();
                    this.$refs.technologyinfocomponent.addNewSkillset();
                    this.saveTechInformation();
                    break;
                case 4:
                    this.$refs.projectinfocomponent.addNewProjectInfo();
                    this.saveProjectInformation();
                    break;
                case 5:
                    this.$refs.preferenceinfocomponent.addNewPreferenceInfo();
                    this.$refs.preferenceinfocomponent.saveCommunicationMode();
                    this.savePreferenceInfo();
                    break;
                case 6:
                    // this.$refs.preferenceinfocomponent.addNewPreferenceInfo();
                    // this.savePreferenceInfo();
                    // break;
                case 7:
                    this.uploadNewResume();
                    break;
                case 8:
                    this.saveSettings();
                default:
                    break;
            }
        },
        savePreferenceInfo() {
            let preferenceupdate = {
                candidatePreference: this.preferenceinfo.basepreference,
                timeToCall: this.preferenceinfo.otherpreference.timetocall,
                workType: this.preferenceinfo.otherpreference.workType,
                expectedSalary: this.preferenceinfo.otherpreference.expectedSalary,
                minSalary: this.preferenceinfo.otherpreference.minSalary,
                maxSalary: this.preferenceinfo.otherpreference.maxSalary,
                flexibilityPercentage: this.preferenceinfo.otherpreference.frequencyPercentage,
                currency: this.preferenceinfo.otherpreference.currency,
                payFrequency: this.preferenceinfo.otherpreference.payFrequency,
                isPartTime: (this.preferenceinfo.otherpreference.parttime == 'yes') ? true : false,
                isFullTime: (this.preferenceinfo.otherpreference.fulltime == 'yes') ? true : false,
                candidateCommunication: this.preferenceinfo.candidateCommunication
            };
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/' + this.candidateid + '/preference', preferenceupdate)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CandidPreUpSuc,
                        position: 'topRight',
                        timeout: 1000
                    });
                    this.loadCandidateById(this.candidateid);
                    //this.$router.push('/candidates/view/' + this.candidateid);
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CandidUpErr,
                        position: 'topRight'
                    });
                });
        },
        saveTechInformation() {
            let techAndSkill = [];

            for (var i = 0; i < this.technologyinfo.length; i++) {
                if (this.technologyinfo[i] != null && this.technologyinfo[i].skills != null && this.technologyinfo[i].skills.length > 0) {
                    for (var j = 0; j < this.technologyinfo[i].skills.length; j++) {
                        techAndSkill.push({
                            industryId: this.technologyinfo[i].industryId,
                            technologyId: this.technologyinfo[i].technology.id,
                            years: this.technologyinfo[i].experience.value,
                            skillId: this.technologyinfo[i].skills[j].skillId,
                            title: '',
                            technologyName: this.technologyinfo[i].technology.technology, //this.technologyinfo[i].technologyName,
                            skillName: this.technologyinfo[i].skills[j].skillName, //    this.technologyinfo[i].skillName
                            isPrimary: (this.technologyinfo[i].isPrimary == (null && undefined) ? false : this.technologyinfo[i].isPrimary)
                        });
                    }
                } else {
                    if (this.technologyinfo[i] != null && this.technologyinfo[i].skillName != null) {
                        console.log(this.technologyinfo[i]);
                        for (var j = 0; j < this.technologyinfo[i].skillName.length; j++) {
                            techAndSkill.push({
                                industryId: this.technologyinfo[i].industryId,
                                technologyId: this.technologyinfo[i].technology.id,
                                years: this.technologyinfo[i].experience.value,
                                skillId: null,
                                title: '',
                                technologyName: this.technologyinfo[i].technologyName,
                                skillName: this.technologyinfo[i].skillName[j],
                                isPrimary: (this.technologyinfo[i].isPrimary == (null && undefined) ? false : this.technologyinfo[i].isPrimary)
                            });
                        }
                    }
                }
            }


            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/' + this.candidateid + '/skills', { candidateSkill: techAndSkill })
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CandidTechUpSuc,
                        position: 'topRight',
                        timeout: 1000
                    });
                    this.loadCandidateById(this.candidateid);
                    //this.$router.push('/candidates/view/' + this.candidateid);
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CandidUpErr,
                        position: 'topRight'
                    });
                });
        },
        saveProjectInformation() {
            for (var i = 0; i < this.projectinfo.length; i++) {
                if (this.projectinfo[i].projectStartDate1 != "" && this.projectinfo[i].projectEndDate1 > this.projectinfo[i].projectStartDate1 ) {
                    this.projectinfo[i].projectStartDate = this.projectinfo[i].projectStartDate1 + "-11";
                }
                if (this.projectinfo[i].projectEndDate1 != "" && this.projectinfo[i].projectEndDate1 > this.projectinfo[i].projectStartDate1 ) {
                    this.projectinfo[i].projectEndDate = this.projectinfo[i].projectEndDate1 + "-11";
                }
                else if (this.projectinfo[i].projectEndDate1 < this.projectinfo[i].projectStartDate1){
                    iziToast.info({
                   title: 'Info',
                   message: this.$config.ProDateErr,
                   position: 'topRight'
               });
                }
            }

            let projectinfoupdate = {
                candidateProject: this.projectinfo
            };
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/' + this.candidateid + '/project', projectinfoupdate)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CandidProUpSuc,
                        position: 'topRight',
                        timeout: 1000
                    });
                    this.loadCandidateById(this.candidateid);
                    //this.$router.push('/candidates/view/' + this.candidateid);
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CandidUpErr,
                        position: 'topRight'
                    });
                });
        },
        saveEmploymentInformation() {

            for (var i = 0; i < this.employmentinfo.length; i++) {
                if (this.employmentinfo[i].employedFrom1 != "" && this.employmentinfo[i].employedFrom1 != null) {
                    this.employmentinfo[i].employedFrom = this.employmentinfo[i].employedFrom1 + "-11";
                }
                if (this.employmentinfo[i].employedTo1 != "" && this.employmentinfo[i].employedTo1 != null) {
                    this.employmentinfo[i].employedTo = this.employmentinfo[i].employedTo1 + "-11";
                }
            }

            let employmentupdate = {
                candidateEmployment: this.employmentinfo
            };
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/' + this.candidateid + '/employment', employmentupdate)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CandidEmpInfoUpSuc,
                        position: 'topRight',
                        timeout: 1000
                    });
                    this.loadCandidateById(this.candidateid);
                    //this.$router.push('/candidates/view/' + this.candidateid);
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CandidUpErr,
                        position: 'topRight'
                    });
                });
        },
        savePersonalInfo() {
            localStorage.setItem('emailAlreadyExists', null);
            if (localStorage.getItem("taskId") != null && localStorage.getItem("taskId") > 0) {
                this.taskId = localStorage.getItem("taskId");
                this.activityId = localStorage.getItem("activityLogId");
            }

            let dataType = this.$store.getters.getAdditionalFieldsType;

            let candidateAdditionalFields = [...this.personalinfo.candidateAdditionalFields];

            if (this.newAdditionalField.valueFormatId !== "" && this.newAdditionalField.nameValueId !== "") {
                if (this.newAdditionalField.valueFormatId == dataType.xNumber && this.newAdditionalField.intValue !== "") {
                    candidateAdditionalFields.push({ ...this.newAdditionalField });
                } else if (this.newAdditionalField.valueFormatId == dataType.xText && this.newAdditionalField.stringValue !== "") {
                    candidateAdditionalFields.push({ ...this.newAdditionalField });
                } else if (this.newAdditionalField.valueFormatId == dataType.xYesNo && this.newAdditionalField.booleanValue !== "") {
                    candidateAdditionalFields.push({ ...this.newAdditionalField });
                } else if (this.newAdditionalField.valueFormatId == dataType.xDate && this.newAdditionalField.dateValue !== "") {
                    candidateAdditionalFields.push({ ...this.newAdditionalField });
                }
            }

            this.personalinfo.candidateLanguage = [];
            for (var index = 0; index < this.personalinfo.candidateLanguageSet.length; index++) {
                this.personalinfo.candidateLanguage.push({ languageId: this.personalinfo.candidateLanguageSet[index] });
            }
/*             let mobile = '';
            if (this.personalinfo.mobile != '' && this.personalinfo.mobile != null) {
                mobile = this.personalinfo.countryCode + " " + this.personalinfo.mobile;
            } */

            let personalupdate = {
                email: this.personalinfo.email,
                mobile: this.personalinfo.mobile,
                dateOfBirth: this.personalinfo.dateOfBirth,
                notes: this.personalinfo.notes,
                firstName: this.personalinfo.firstName,
                middleName: this.personalinfo.middleName,
                lastName: this.personalinfo.lastName,
                dateAvailable: this.personalinfo.dateAvailable,
                industryId: this.personalinfo.industryId,
                position: this.personalinfo.position,
                location: this.personalinfo.location,
                experience: this.personalinfo.experience,
                expMonth: this.personalinfo.expMonth,
                expType: this.personalinfo.expType,
                payMode: this.personalinfo.payMode,
                recruiterId: this.personalinfo.recruiterId,
                visaTypeId: this.personalinfo.visaType.id,
                visaTypeText: this.personalinfo.visaType.text,
                candidateEducation: this.personalinfo.educationinfo,
                timeZone: this.personalinfo.timeZone,
                sourcingChannelId: this.personalinfo.sourcingChannel,
                sourcingRefLink: this.personalinfo.sourcingRefLink,
                linkedInUrl: this.personalinfo.linkedInUrl,
                candidateLanguage: this.personalinfo.candidateLanguage,
                candidateAdditionalFields: candidateAdditionalFields,
                status: this.personalinfo.status,
                taskId: this.taskId,
                activityId: this.activityId,
                candidateType: this.personalinfo.candidateType,
                willRelocate: this.personalinfo.willRelocate,
                parsedResumeFileName: this.updatedFileName,
                referralId: this.personalinfo.referralId
            };

            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/' + this.candidateid + '/personal', personalupdate)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CandidPerInfoUpSuc,
                        position: 'topRight',
                        timeout: 1000
                    });
                    this.loadCandidateById(this.candidateid);
                    this.$refs.personalinfocomponent.clearAdditionalField();
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CandidUpErr,
                        position: 'topRight'
                    });
                });

        },
        saveContactInformation() {
            this.contactinfo.emergency.forEach(function(data) {
                if (data.phoneNo != null && data.phoneNo != '') {
                    data.phone = data.phoneNo;                
                }                   
            }); 
            let contactupdate = {
                candidateAddress: {
                    currentAddress: this.contactinfo.currentaddress,
                    permanentAddress: this.contactinfo.permanentaddress,
                    emergencyContact: this.contactinfo.emergency
                }
            };
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/' + this.candidateid + '/contact', contactupdate)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CandidConInfoUpSuc,
                        position: 'topRight',
                        timeout: 1000
                    });
                    this.loadCandidateById(this.candidateid);
                    //this.$router.push('/candidates/view/' + this.candidateid);
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CandidUpErr,
                        position: 'topRight'
                    });
                });
        },
        uploadNewResume() {
            let types = [];
            if (this.attachments.length > 0) {
                let formData = new FormData();

                for (const row of Object.keys(this.attachments)) {
                    formData.append('files', this.attachments[row]);
                }

                for (const row of Object.keys(this.attachmentTypes)) {
                    console.log(this.attachmentTypes[row]);
                    formData.append('attachmentTypes', this.attachmentTypes[row].name);
                    formData.append('attachmentTypesId', this.attachmentTypes[row].id);
                }
                formData.append('id', this.candidateid);

                axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/uploadresume/multiple', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        let result = response.data;
                        if (result.status == "ACCEPTED") {
                            this.resumeinfo.filename = "";
                            iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                        } else {
                            iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                        }
                    })
                    .catch(error => {
                        this.unAuthorized(error);
                        console.log(error.data);
                    });
            }
            if (this.resumeinfo.file != '') {
                let formData = new FormData();
                formData.append('file', this.resumeinfo.file);
                formData.append('id', this.candidateid);
                axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/uploadresume', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        iziToast.success({
                            title: 'Success',
                            message: this.$config.CandidResUpSuc,
                            position: 'topRight',
                            timeout: 1000
                        });
                        this.loadCandidateById(this.candidateid);
                        //this.$router.push('/candidates/view/' + this.candidateid);
                    })
                    .catch(error => {
                        iziToast.error({
                            title: 'Error occured',
                            message: this.$config.CandidResUpErr,
                            position: 'topRight'
                        });
                    });
            } else if (this.resumeinfo.file == '' && this.attachments.length == 0) {
                iziToast.info({
                    title: ' ',
                    message: this.$config.PlsUpRes,
                    position: 'topRight'
                });
            }
        },
        saveSettings() {
            if (this.settingsInfo.timeZone != "" || this.settingsInfo.accessLevel != "") {
                // return false;
                axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/' + this.candidateid + '/settings', this.settingsInfo)
                    .then(response => {
                        iziToast.success({ title: 'Success', message: this.$config.CandidInfoUpSuc, position: 'topRight' });
                        this.loadCandidateById(this.candidateid);
                        //this.$router.push('/candidates/view/' + this.candidateid);
                    })
                    .catch(error => {
                        console.log(error);
                        iziToast.error({ title: 'Error Occured', message: this.$config.CandidUpErr, position: 'topRight' });
                    });
            }
        },
        refreshCalendar() {
            this.elements.showAvailabilityInfo = true
        },
        getPrevUrl() {
            this.$router.go(-1);
        },
        sidebarToggle: function(event) {
            $(".reports.settings").toggleClass('sidebar-collapse');
            //this.showWord = false;
        },
        newResumeConfirm: function() {
            this.updatedFileName = localStorage.getItem("parsedResumeFileName");
            localStorage.setItem('emailAlreadyExists', null);
            localStorage.setItem('parsedResumeFileName', '');
            this.updateResume();
            this.saveData();
        },
        cancelNewResume: function() {
            this.updatedFileName = '';
            localStorage.setItem('emailAlreadyExists', null);
            localStorage.setItem('parsedResumeFileName', '');
            this.saveData();
        },
        showNewResumeDialog() {
            if (localStorage.getItem('emailAlreadyExists') != null && localStorage.getItem('emailAlreadyExists') == 'true') {
                this.$root.$emit('bv::show::modal', 'newResumeConfirm');
            }
        },
        updateResume: function() {
            let data = {
                id: this.candidateid,
                parsedResumeFileName: this.updatedFileName,
                email: this.personalinfo.email,
                firstName: this.personalinfo.firstName,
                resumeId: this.resumeinfo.id,
                filePath: this.resumeinfo.fileurl
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/upload/newresume', data)
                .then(response => {
                    console.log("Resume uploaded successfuly");
                    this.loadCandidateById(this.candidateid);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        showconfirmDialog() {
            this.showNewResumeDialog();
        },

    }
}

</script>
