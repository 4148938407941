<template>
    <div class="kpis reports text-left">
        <div class="row">
            <div class="col-sm-12 p-10">
                <div id="recruiter-settings" class="recruiter settings mt-10">
                    <div class="container-fluid" id="quick-search">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="text-center">Job Custom Report</h5>
                            </div>
                        </div>
                        <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <h4 class="page-title">Filter</h4>
                                </div>
                                <div class="col-md-6 col-sm-6 col-6">
                                    <ul class="ul-horizontal box-tools text-right mb-0">
                                        <li><a href="Javascript:void(0);" v-on:click="elements.isExpand=!elements.isExpand;" data-target="main-search" class="boxhide"><span :title="elements.isExpand?'Hide':'Expand'" :class="elements.isExpand?'ti-angle-up':'ti-angle-down'" class="hover-hand"></span></a></li>
                                        <li><i class="ti-close hover-hand px-2" @click="$emit('setSubTab',0);$emit('updateLogActivity');" title="Close"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <form id="main-search" :class="elements.isExpand?'d-block':'d-none'">
                                <div class="row form-group ten-columns">
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Job Title</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" name="jobTitle" maxlength="50" value="" id="jobTitle" class="form-control" v-on:keyup.enter="filterJobs()" v-model="search.params.jobTitle">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Job Code</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" v-on:keyup.enter="filterJobs()" v-model="search.params.jobCode" name="jobId" maxlength="25" value="" id="jobId" class="smallCombo form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Client</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <select v-model="search.params.clientId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(data, index) in options.clientlist" :value="data.id">{{data.client_Name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Industry</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <select v-model="search.params.industryId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(data, index) in options.industrylist" :value="data.id">{{data.industryName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Position</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" v-model="search.params.position" name="jobId" maxlength="100" value="" id="jobId" class="smallCombo form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Status</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <select v-model="search.params.status" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(data, index) in options.jobStatuslist" :value="data.id">{{data.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group ten-columns">
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Start Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledStartDates" placeholder="Start Date" name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.startDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>End Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledendDates" placeholder="End Date" name="endDate" value="" id="endDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.endDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label style="visibility: hidden;">Technology</label>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="button" @click="resetJobSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                                <button type="button" @click="filterJobs" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Go</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                </div>
                            </form>
                            <div class="load-indicator"></div>
                        </div>
                    </div>
                    <div v-if="action.mode!='view'" class="container-fluid" id="filter">
                        <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                            <div class="row col-md-12 mx-0">
                                <div class="d-flex flex-row bd-highlight mb-3">
                                    <div class="mr-4">
                                        <h4 class="page-title">Choose Column Header</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="row col-md-12 mx-0">
                                <multiselect v-model="reportData.customReportFields" :options="options.fieldList" name="tablefields" label="label" track-by="fieldId" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" :allow-empty="true" />
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid" id="main-data">
                        <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                            <div v-if="reportData.customReportFields.length" class="row" style="margin-bottom: 15px;">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <h4 class="page-title">Jobs
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                            <div class="dropdown">
                                                <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                                <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                    <a v-for="(value, index) in options.searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                                </div>
                                            </div>
                                        </span>
                                    </h4>
                                </div>
                                <div class="col-md-6 d-none d-sm-none d-md-block">
                                    <button v-if="action.mode!='view'" type="button" v-b-modal.reportFormModal class="btn btn-primary btn-sm float-right">Save Report</button>
                                    <span class="float-right mr-2" @click="exportJobs()">
                                        <i class="value">
                                            <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt="">
                                        </i>
                                    </span>
                                </div>
                            </div>
                            <div v-if="reportData.customReportFields.length" class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive" style="min-height: 178px;">
                                        <b-table show-empty hover :items="list.joblist" :fields="reportData.customReportFields" class="all-table">
                                            <template v-slot:empty="scope">
                                                <label class="text-center w-100">No data found</label>
                                            </template>
                                             <template slot="createdDate" slot-scope="data">434343
                                                {{data.value | formatDate}}
                                            </template> 
                                           <template slot="clientName" slot-scope="data">
                                                <span v-if="data.item.ttClientAccountId == ttClientAccountId">  
                                                    {{data.value}}
                                                </span>
                                            </template>  
                                        </b-table>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <b-pagination v-if="list.joblist.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadJobs()"></b-pagination>
                                </div>
                            </div>
                            <div v-if="!reportData.customReportFields.length" class="row col-md-12 mx-0">
                                <div class="card-header w-100 text-center">Sorry, there is no data to be displayed. Please select column headers to view the report</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal ref="reportFormModal" id="reportFormModal" :hide-footer="true" title="Custom Report">
            <div class="row col-md-12 mx-0 px-0">
                <div class="col-md-8 px-0 offset-md-2">
                    <div class="row col-md-12">
                        <label>Report Name</label>
                    </div>
                    <div class="row col-md-12">
                        <input class="form-control" type="text" name="reportName" v-model="reportData.reportName" />
                    </div>
                    <div class="row col-md-12">
                        <label v-if="$v.reportData.reportName.$error && !$v.reportData.reportName.required" class="text-danger">Report Name is required</label>
                        <label v-if="$v.reportData.reportName.$error && !$v.reportData.reportName.minLength" class="text-danger">Report Name must have at least {{$v.reportData.reportName.$params.minLength.min}} letters.</label>
                        <label v-if="$v.reportData.reportName.$error && !$v.reportData.reportName.maxLength" class="text-danger">Report Name must not exceed {{$v.reportData.reportName.$params.maxLength.max}} letters.</label>
                        <label v-if="$v.reportData.customReportFields.$error && !$v.reportData.customReportFields.required" class="text-danger">Fields are required. Please choose some fields.</label>
                    </div>
                </div>
                <div class="row col-md-10 justify-content-end py-3">
                    <a href="javascript:" class="btn btn-primary ml-2" @click="saveReport">Save</a>
                    <a href="javascript:" class="btn btn-primary ml-2" @click="$refs.reportFormModal.hide()">Cancel</a>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
    import iziToast from 'izitoast';
    import Multiselect from 'vue-multiselect';
    import { required, minLength, maxLength, email, sameAs } from "vuelidate/lib/validators";
    export default {
        props: {
            action: Object,
        },
        components: {
            Multiselect
        },
        computed: {
            ttClientAccountId() {
                if (this.$store.getters.currentUser.ttClientAccountId)
                    return this.$store.getters.currentUser.ttClientAccountId;
                else return 0;
            }
        },
        watch: {
        'search.params.startDate': {
            handler: function(value) {
                this.disabledendDates.to = new Date(value);
            },
            deep: true,
            immediate: true
        },
    },
        data() {
            return {
                elements: {
                    showadvancedsearch: false,
                    isExpand: false,
                },
                pagination: {
                    totalRecords: 0,
                    rowsPerPage: 10,
                    currentPage: 1
                },
                list: {
                    joblist: []
                },
                options: {
                    searchlimit: this.$store.state.searchlimit,
                    industrylist: this.$store.getters.getIndustries,
                    clientlist: this.$store.getters.getClients,
                    jobStatuslist: this.$store.getters.getJobStatus,
                    fieldList: []
                },
                search: {
                    params: {
                        jobTitle: "",
                        jobType: "",
                        technologyId: "",
                        startDate: "",
                        endDate: "",
                        clientId: "",
                        industryId: "",
                        status: "",
                        recId: "",
                        reportFields: [],
                        pageNo: "",
                        limit: ""
                    },
                },
                coldisplay: {
                    jobTitle: true,
                    jobCode: true,
                    client: true,
                    industry: true,
                    position: true
                },
                reportData: {
                    id: 0,
                    reportName: "",
                    reportTypeId: 2,    
                    customReportFields: []
                },
                disabledStartDates: {
                    from: new Date()
            
                },
                disabledendDates:{
                    from: new Date(),
                    to: ''
                }
            }
        },
        validations: {
            reportData: {
                reportName: { required, minLength: minLength(3), maxLength: maxLength(100) },
                reportTypeId: { required },
                customReportFields: { required }
            }
        },
        mounted() {
            this.loadDate();
            this.loadJobs();
            this.loadFieldList();
            if (this.action.mode == 'edit' || this.action.mode == 'view') {
                this.loadCustomReport(this.action.id);
            }
            this.$emit('updateLogActivity');
            this.$emit('addLogActivity','Job Custom Report'); 
        },
        methods: {
            resetJobSearch() {
                this.search.params = {
                    jobTitle: "",
                    jobType: "",
                    technologyId: "",
                    clientId: "",
                    industryId: "",
                    status: "",
                    recId: "",
                    startDate: "",
                    endDate: "",
                    pageNo: "",
                    limit: ""
                };
                this.loadDate();
                this.disabledStartDates.from =  new Date();
                this.disabledendDates.from =  new Date();
                this.loadJobs();
            },
            filterJobs(){
                this.pagination.currentPage=1;
                this.loadJobs();
            },
            loadDate() {
                let date = new Date();
                this.search.params.startDate = date; //new Date(date.getFullYear(), date.getMonth(), 1);
                this.search.params.endDate = date;
            },
            loadJobs() {
                if (this.search.params.startDate > this.search.params.endDate) {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.DateMismatchErr,
                        position: 'topRight'
                    });
                    return;
                }
                this.search.params.pageNo = this.pagination.currentPage;
                this.search.params.limit = this.pagination.rowsPerPage;
                axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/jobreport', this.search.params)
                //axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/jobreport?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    //this.list.joblist = result.jobs;
                    this.list.joblist = result.data;
                    //this.pagination.totalRecords = result.pagination[0].total;
                    //this.pagination.currentPage = result.pagination[0].currentPage;
                    this.pagination.totalRecords = result.totalRecords;
                    this.pagination.currentPage = result.currentPage;
                })
                .catch(error => {
                    console.log(error);
                });
            },
            loadFieldList: function() {
                axios.get(this.$store.getters.getAPIBasePath + '/commonresource/fieldlist/' + this.reportData.reportTypeId)
                .then(response => {
                    if (response.data.status) {
                        this.options.fieldList = response.data.fieldlist;
                    } else
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                })
                .catch(error => {
                    console.log(error);
                });
            },
            changeLimit(newlimit) {
                this.pagination.currentPage=1;
                this.pagination.rowsPerPage = newlimit;
                this.loadJobs();
            },
            saveReport: function() {
                if (!this.validateReportData()) {
                    return false;
                }
                let axiosUrl = this.reportData.id ? '/reportsresource/update/custom/report' : '/reportsresource/add/custom/report';
                axios.post(this.$store.getters.getAPIBasePath + axiosUrl, this.reportData)
                .then(response => {
                    let result = response.data;
                    if (result.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                        this.$refs.reportFormModal.hide();
                        this.$emit('setSubTab',0,'list',null);
                    } else {
                        iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            },
            validateReportData: function() {
                this.$v.reportData.$touch();
                if (this.$v.reportData.$invalid) {
                    return false;
                }
                return true;
            },
            loadCustomReport: function(id) {
                this.reportData.id = id;
                axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/view/custom/report', this.reportData)
                .then(response => {
                    this.reportData.reportName = response.data.customReport.reportName;
                    this.reportData.reportTypeId = response.data.customReport.reportTypeId;
                    this.reportData.customReportFields = response.data.customReport.settingsReportFields;
                })
                .catch(error => {
                    console.log(error);
                });
            },
            exportJobs() {
                this.search.params.reportFields = this.reportData.customReportFields;
                console.log(this.search.params);
                this.search.params.pageNo = this.pagination.currentPage;
                this.search.params.limit = this.pagination.rowsPerPage;
                axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/custom/report/jobs' , this.search.params, { responseType: 'arraybuffer' })
                //axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/custom/report/jobs?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');
                    let fileName = "Jobs" + "_" + currentDateWithFormat + ".xlsx";
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({title: 'Warning!', message: error.response.data.message, position: 'topRight'});
                    this.reports = [];
                });
            }
        }
    }
</script>
