<template>
	<div class="kpis reports">
		<div class="row m-0">
			<div class="col-sm-12 p-10">
				<div id="recruiter-settings" class="recruiter settings mt-10">
					<div class="box-wrapper shadow-sm bg-white rounded pos-rel">
						<div class="basic-block">
							<h5 style="background-color: #37a0e4;color: #fff;padding: 5px;">Recruitment Performance Indicator</h5>
							<div class="row col-md-12" style="padding-top: 10px; padding-left: 40px;">
								<div class="col-md-4 p-4 mt-10">
									<div class="row col-md-12 ml-0 pl-0 pr-0">
										<div :title="avgHireTitle" class="col-md-2 ml-0 pl-0 pr-0"><img  src="@/assets/icons/average-time-hire.png" width="80%" height="50%" /></div>
										<div class="col-md-10 ml-0 pl-4 pr-0 text-left">
											<h6>Average Hire Time</h6>
											<p class="mt-4"><b><span class="reports-badge">{{ averageDifference }} Hrs</span></b></p>
										</div>
									</div>						
								</div>
								<div class="col-md-4 p-4 mt-10 tab-disabled">
									<div class="row col-md-12 ml-0 pl-0 pr-0">
										<div :title="applicantHireTitle" class="col-md-2 ml-0 pl-0 pr-0"><img src="@/assets/icons/applicant-hire-ratio.png" width="80%" height="50%" /></div>
										<div class="col-md-10 ml-0 pl-4 pr-0 text-left">
											<h6>Applicant to Hire Ratio</h6>
											<p class="mt-4"><b><span class="reports-badge">{{ applicantHiredRatio }}</span></b></p>
										</div>
									</div>						
								</div>
								<div class="col-md-4 p-4 mt-10">
									<div class="row col-md-12 ml-0 pl-0 pr-0">
										<div :title="talentIdentifiedTitle" class="col-md-2 ml-0 pl-0 pr-0"><img src="@/assets/icons/talentidentified-hire-ratio.png" width="80%" height="50%" /></div>
										<div class="col-md-10 ml-0 pl-4 pr-0 text-left">
											<h6>Talent identified to Hire Ratio</h6>
											<p class="mt-4"><b><span class="reports-badge">{{ hireRatio }}</span></b></p>
										</div>
									</div>						
								</div>
							</div>
							<div class="row col-md-12" style="padding-top: 10px; padding-left: 40px;">
								<div class="col-md-4 p-4 mt-10">
									<div class="row col-md-12 ml-0 pl-0 pr-0">
										<div :title="offerAcceptTitle" class="col-md-2 ml-0 pl-0 pr-0"><img src="@/assets/icons/offers-accepted.png" width="80%" height="50%" /></div>
										<div class="col-md-10 ml-0 pl-4 pr-0 text-left">
											<h6>Offer Acceptance Rate</h6>
											<p class="mt-4"><b><span class="reports-badge">{{ acceptancePercentage }}%</span></b></p>
										</div>
									</div>
								</div>
								<div class="col-md-4 p-4 mt-10  ">
									<div class="row col-md-12 ml-0 pl-0 pr-0">
										<div :title="qualityHireTitle" class="col-md-2 ml-0 pl-0 pr-0"><img src="@/assets/icons/quality-of-hire.png" width="80%" height="50%" /></div>
										<div class="col-md-10 ml-0 pl-4 pr-0 text-left">
											<h6>Quality of Hire</h6>
											<p class="mt-4"><b><span class="reports-badge">{{ qualityOfHire }}%</span></b></p>
										</div>
									</div>						
								</div>
								<div class="col-md-4 p-4 mt-10">
									<div class="row col-md-12 ml-0 pl-0 pr-0">
										<div :title="productivityTitle" class="col-md-2 ml-0 pl-0 pr-0"><img src="@/assets/icons/productivity.png" width="80%" height="50%" /></div>
										<div class="col-md-10 ml-0 pl-4 pr-0 text-left">
											<h6>Productivity</h6>
											<p class="mt-4"><b><span class="reports-badge">{{ productivityPercentage }} per day</span></b></p>
										</div>
									</div>						
								</div>
							</div>
							<div class="row col-md-12" style="padding-top: 10px; padding-left: 40px;">
								<div class="col-md-4 p-4 mt-10 tab-disabled">
									<div class="row col-md-12 ml-0 pl-0 pr-0">
										<div class="col-md-2 ml-0 pl-0 pr-0"><img src="@/assets/icons/call-connect.png" width="80%" height="50%" /></div>
										<div class="col-md-10 ml-0 pl-4 pr-0 text-left">
											<h6>Calls To Connect Candidate</h6>
											<p class="mt-4"><b><span class="reports-badge">5</span></b></p>
										</div>
									</div>
								</div>
								<div class="col-md-4 p-4 mt-10 tab-disabled">
									<div class="row col-md-12 ml-0 pl-0 pr-0">
										<div class="col-md-2 ml-0 pl-0 pr-0"><img src="@/assets/icons/average-call.png" width="80%" height="50%" /></div>
										<div class="col-md-10 ml-0 pl-4 pr-0 text-left">
											<h6>Average Connect To Get Qualified Candidate</h6>
											<p class="mt-4"><b><span class="reports-badge">10</span></b></p>
										</div>
									</div>
								</div>
								<div class="col-md-4 p-4 mt-10 ">
									<div class="row col-md-12 ml-0 pl-0 pr-0">
										<div :title="avgInterviewTitle" class="col-md-2 ml-0 pl-0 pr-0"><img src="@/assets/icons/avg-interviews-offer.png" width="80%" height="50%" /></div>
										<div class="col-md-10 ml-0 pl-4 pr-0 text-left">
											<h6>Avg No. Of Interviews To Get An Offer</h6>
											<p class="mt-4"><b><span class="reports-badge">{{ interviewsGetHired }} per hire</span></b></p>
										</div>
									</div>
								</div>
							</div>
							<div class="row col-md-12" style="padding-top: 10px; padding-left: 40px;">
								<div class="col-md-4 p-4 mt-10 tab-disabled">
									<div class="row col-md-12 ml-0 pl-0 pr-0">
										<div class="col-md-2 ml-0 pl-0 pr-0"><img src="@/assets/icons/offers-denied.png" width="80%" height="50%" /></div>
										<div class="col-md-10 ml-0 pl-4 pr-0 text-left">
											<h6>Percentage of Placement Never started</h6>
											<p class="mt-4"><b><span class="reports-badge">5%</span></b></p>
										</div>
									</div>
								</div>
								<div class="col-md-4 p-4 mt-10 tab-disabled">
									<div class="row col-md-12 ml-0 pl-0 pr-0">
										<div class="col-md-2 ml-0 pl-0 pr-0"><img src="@/assets/icons/failure.png" width="80%" height="50%" /></div>
										<div class="col-md-10 ml-0 pl-4 pr-0 text-left">
											<h6>Background or Drug test Failure Percentage</h6>
											<p class="mt-4"><b><span class="reports-badge">12%</span></b></p>
										</div>
									</div>
								</div>
								<div class="col-md-4 p-4 mt-10 tab-disabled">
									<div class="row col-md-12 ml-0 pl-0 pr-0">
										<div class="col-md-2 ml-0 pl-0 pr-0"><img src="@/assets/icons/avg-candidates.png" width="80%" height="50%" /></div>
										<div class="col-md-10 ml-0 pl-4 pr-0 text-left">
											<h6>Average Candidate Submitted For An Interview</h6>
											<p class="mt-4"><b><span class="reports-badge">50</span></b></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return{
			hireRatio:'',
			averageDifference:'',
			acceptancePercentage:'',
			productivityPercentage:'',
			qualityOfHire:'',
			interviewsGetHired:'',
			applicantHiredRatio:'',
			avgHireTitle:'Average time to hire a candidate.',
			applicantHireTitle:'Total Application for Job to Hire Ratio.',
			talentIdentifiedTitle:'Total Talent Identified for Job to Hire Ratio.',
			avgInterviewTitle:'Total hires per day in percentage.',
			offerAcceptTitle:'Percentage of Offer Accepted to Offer Denied.',
			productivityTitle:'Total No. of Hires to Period of Hiring.',
			qualityHireTitle:'Average Candidate Performance Rating and Employee Retention Period.'

		}
	},
	mounted() {
		this.loadPerformanceData();
		this.$emit('updateLogActivity');
		this.$emit('addLogActivity','Recruitment Performance Indicator');
	},
	methods: {
		loadPerformanceData() {
		 axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/key/performance')
			.then(response => {
				let result = response.data.keyPerformance;
				this.hireRatio=result.identifiedHireRatio;
				this.averageDifference=result.avgHireTime;
				this.acceptancePercentage=result.acceptancePercentage;
				this.productivityPercentage=result.productivityPercentage;
				this.qualityOfHire=result.qualityOfHire;
				this.interviewsGetHired=result.interviewsGetHired;
				this.applicantHiredRatio=result.applicantHiredRatio;				
			})
			.catch(error => {
				console.log(error);
			});
		}
	}
}
</script>