<template>
    <div :class="{ 'viewonly' : personalinfo.viewonly == 1}">
        <div class="card">
            <div class="card-header p-2">
                <div class="row">
                    <div class="col-md-6">
                        Primary Information
                    </div>
                    <div class="col-md-6 text-right">
                        <Select2 v-model="personalinfo.timeZone" :options="timeZones" :settings="{width:'50%'}" />
                    </div>
                </div>
            </div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Candidate Code</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" disabled = "disabled" name="candidateCode" maxlength="45" size="30" v-model="personalinfo.candidateCode" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>First Name</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="text" name="firstName" maxlength="45" size="30" v-model="personalinfo.firstName" class="form-control " :class="{ 'is-invalid': $v.personalinfo.firstName.$error }">
                                        <span class="required-icon"></span>
                                    </div>
                                    <label v-if="$v.personalinfo.firstName.$error && !$v.personalinfo.firstName.minLength" class="text-danger">First Name must have at least {{$v.personalinfo.firstName.$params.minLength.min}} letters.</label>
                                    <label v-if="$v.personalinfo.firstName.$error && !$v.personalinfo.firstName.required" class="text-danger">Please enter First Name</label>
                                    <label v-if="$v.personalinfo.firstName.$error && !$v.personalinfo.firstName.maxLength" class="text-danger">First Name must not exceed {{$v.personalinfo.firstName.$params.maxLength.max}} letters.</label>
                                </div>
                            </div>
                        </div>
                        <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Middle Name</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" name="middleName" maxlength="45" size="30" v-model="personalinfo.middleName" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Last Name</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="text" name="lastName" maxlength="45" size="30" v-model="personalinfo.lastName" class="form-control" :class="{ 'is-invalid': $v.personalinfo.lastName.$error }">
                                        <span class="required-icon"></span>
                                    </div>
                                    <label v-if="$v.personalinfo.lastName.$error && !$v.personalinfo.lastName.minLength" class="text-danger">Last Name must have at least {{$v.personalinfo.lastName.$params.minLength.min}} letters.</label>
                                    <label v-if="$v.personalinfo.lastName.$error && !$v.personalinfo.lastName.required" class="text-danger">Please enter Last Name</label>
                                    <label v-if="$v.personalinfo.lastName.$error && !$v.personalinfo.lastName.maxLength" class="text-danger">Last Name must not exceed {{$v.personalinfo.lastName.$params.maxLength.max}} letters.</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Email</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="text" name="primaryEmail" maxlength="80" v-model.lazy="personalinfo.email" class="Submitaddress form-control" :class="{ 'is-invalid': $v.personalinfo.email.$error }" @blur="$v.personalinfo.email.$touch">
                                        <span class="required-icon"></span>
                                    </div>
                                    <label v-if="$v.personalinfo.email.$error && !$v.personalinfo.email.required" class="text-danger">Please enter Email</label>
                                    <label v-if="$v.personalinfo.email.$error && !$v.personalinfo.email.email" class="text-danger">Please enter a valid email</label>
                                    <label v-if="$v.personalinfo.email.$error && !$v.personalinfo.email.emailexists && $v.personalinfo.email.email && $v.personalinfo.email.required" class="text-danger">Email already exists</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Phone</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" name="primaryPhone" maxlength="15" v-model="personalinfo.mobile" id="primaryPhone" class="SubmitPhone form-control">
                                    </div>
                                    <!-- <label v-if="$v.personalinfo.mobile.$error && !$v.personalinfo.mobile.usPhone" class="text-danger">Please enter valid Phone</label> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Date Of Birth</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <datepicker v-model="personalinfo.dateOfBirth" placeholder="Date of Birth" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" :disabledDates="dobDates"></datepicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Industry</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12 input-group">
                                    <select v-model="personalinfo.industryId" class="form-control">
                                        <option value="">Select</option>
                                        <option v-for="(info, index) in industries" :value="info.id">{{info.industryName}}</option>
                                    </select>
                                    <a v-if="personalinfo.viewonly==0" href="Javascript:void(0);" class="tbl-add-icon" data-toggle="modal" data-target="#addNewIndustry" @click="newIndustry.industryName=''"><span class="input-group-addon"><i class="ti-settings"></i></span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Position</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" name="position" id="position" maxlength="50" size="50" v-model="personalinfo.position" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Years of Experience</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="number" name="yearsOfExp" id="yearsOfExp" maxlength="2" size="2" v-model="personalinfo.experience" class="form-control" @keypress="isPositiveInt($event,2)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Location</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" name="location" id="location" maxlength="50" size="30" v-model="personalinfo.location" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Referred By</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" name="referredBy" maxlength="30" size="25" v-model="personalinfo.referredBy" id="referredBy" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                               <div class="col-md-12 col-sm-12 col-12 lb">
                                   <label>Pay</label>
                               </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <select name="selectedDivision" id="selectedDivision" v-model="personalinfo.payMode" class="form-control">
                                        <option value="">--Select--</option>
                                        <option v-for="(data, index) in paymentmodes" :value="data.id">{{data.name}}</option>
                                    </select>
                                </div>
                            </div>  
                        </div> -->
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Recruiter</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <select name="recruiterId" id="recruiterName" v-model="personalinfo.recruiterId" class="form-control">
                                        <option value="" selected="selected">Select</option>
                                        <option v-for="(data, index) in recruiters" :value="data.id">{{data.recruiterName}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <!--  <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                               <div class="col-md-12 col-sm-12 col-12 lb">
                                   <label>Visa Type</label>
                               </div>
                               <div class="col-md-12 col-sm-12 col-12">
                                    <select name="selectedVisaType" id="selectedVisaType" v-model="personalinfo.visaTypeId" class="smallCombo_search form-control" tabindex="-1" aria-hidden="true">
                                        <option value="">--Select--</option>
                                        <option v-for="(data, index) in visatypes" :value="data.id">{{data.visaName}}</option>
                                    </select>
                               </div>
                            </div>  
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">Educational Information</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col">Name Of Institution</th>
                                            <th class="" scope="col">Degree</th>
                                            <th class="" scope="col">Specialization</th>
                                            <th class="" scope="col">Year</th>
                                            <th class="" scope="col">City</th>
                                            <th class="" scope="col">State</th>
                                            <th class="" scope="col">Country</th>
                                            <th class="" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="cloned_tr">
                                        <tr v-for="(info, index) in personalinfo.educationinfo">
                                            <td>
                                                <input v-model="personalinfo.educationinfo[index].schoolName" type="text" maxlength="50" minlength="6" size="23" class="form-control" required>
                                            </td>
                                            <td>
                                                <input v-model="personalinfo.educationinfo[index].degree" type="text" maxlength="100" minlength="2" size="23" class="form-control" required>
                                            </td>
                                            <td>
                                                <input v-model="personalinfo.educationinfo[index].specialization" type="text" maxlength="100" minlength="2" size="23" class="form-control" required>
                                            </td>
                                            <td>
                                                <input v-model="personalinfo.educationinfo[index].year" type="number" maxlength="4" min="1970" max="2019" class="form-control" required oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                            </td>
                                            <td>
                                                <input v-model="personalinfo.educationinfo[index].city" type="text" maxlength="50" minlength="2" size="23" class="form-control" required>
                                            </td>
                                            <td>
                                                <select v-if="personalinfo.educationinfo[index].countryId=='US'" v-model="personalinfo.educationinfo[index].stateName" class="form-control" required>
                                                    <option value="">--</option>
                                                    <option v-for="(data, index) in usstates" :value="data.stateName">{{data.stateName}}</option>
                                                </select>
                                                <input v-if="personalinfo.educationinfo[index].countryId!='US'" v-model="personalinfo.educationinfo[index].stateName" type="text" maxlength="25" minlength="2" size="23" class="form-control" required>
                                            </td>
                                            <td>
                                                <select v-model="personalinfo.educationinfo[index].countryId" @change="personalinfo.educationinfo[index].stateName=''" class="form-control" required>
                                                    <option value="">--</option>
                                                    <option v-for="(data, index) in countrieslist" :value="data.country_Id">{{data.name}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <!-- <button type="button" :class="{ 'removeaddbtn' : personalinfo.viewonly == 1}" class="addeducation actionprimary" @click="personalinfo.educationinfo.splice(index,1)"><span class="ti-trash"></span></button> -->
                                                <a class="removemoretextbox" :class="{'removeaddbtn' : personalinfo.viewonly != 1 }" href="Javascript:void(0);" data-toggle="modal" data-target="#removeEduInfo" @click="confirmEduInfo(index,personalinfo[index].id)"><span class="ti-trash"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="filled3" :class="{ 'noaddedmore' : personalinfo.viewonly == 1}">
                                            <td>
                                                <input ref="addcandidate_educationinfo_add_name" v-model="neweducationform.schoolName" type="text" maxlength="50" minlength="6" size="23" class="form-control">
                                            </td>
                                            <td class="">
                                                <input v-model="neweducationform.degree" type="text" maxlength="100" minlength="2" size="23" class="form-control" :class="{ 'is-invalid': $v.neweducationform.degree.$error }">
                                            </td>
                                            <td class="">
                                                <input v-model="neweducationform.specialization" type="text" maxlength="100" minlength="2" size="23" class="form-control" :class="{ 'is-invalid': $v.neweducationform.degree.$error }">
                                            </td>
                                            <td class="">
                                                <input v-model="neweducationform.year" type="number" maxlength="4" min="1970" max="2019" class="form-control" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                            </td>
                                            <td class="">
                                                <input v-model="neweducationform.city" type="text" maxlength="50" minlength="2" size="23" class="form-control">
                                            </td>
                                            <td class="">
                                                <select v-if="neweducationform.countryId=='US'" v-model="neweducationform.stateName" class="form-control">
                                                    <option value="">--</option>
                                                    <option v-for="(data, index) in usstates" :value="data.stateName">{{data.stateName}}</option>
                                                </select>
                                                <input v-if="neweducationform.countryId!='US'" v-model="neweducationform.stateName" type="text" maxlength="25" minlength="2" size="23" class="form-control">
                                            </td>
                                            <td class="">
                                                <select v-model="neweducationform.countryId" @change="neweducationform.stateName=''" class="form-control">
                                                    <option value="">--</option>
                                                    <option v-for="(data, index) in countrieslist" :value="data.country_Id">{{data.name}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <button type="button" class="addeducation actionprimary" @click="addEducationInfo"><span class="ti-plus"></span></button>
                                                <!-- <a href="Javascript:void(0);" class="removeeducation" :class="{ 'removeaddbtn' : educationinfo.viewonly == 1}" @click="confirmEduInfo(index,educationinfo[index].id)"><span class="ti-trash"></span></a> -->
                                                <a class="removeeducation" @click="confirmCompany(index,employmentinfo[index].id)" data-toggle="modal" data-target="#removCompany"><span class="ti-trash"></span></a>
                                            </td>
                                        </tr>
                                        <tr :class="{ 'norecordfound' : personalinfo.viewonly == 1}" style="display: none;">
                                            <td colspan="7" align="center">No item found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">Language(s)</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12 required-field-block">
                                    <Select2 v-model="personalinfo.candidateLanguageSet" :options="languages" :settings="{width:'100%', multiple:true}" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">Objective</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12 required-field-block">
                                    <textarea rows="3" class="form-control" v-model="personalinfo.notes"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="addNewIndustry" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Add New Industry</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body input-group required-field-block">
                        <div class="input-group required-field-block">
                            <input v-model="newIndustry.industryName" type="text" maxlength="100" minlength="2" class="form-control">
                            <span class="required-icon"></span>
                        </div>
                        <label v-if="$v.newIndustry.industryName.$error && !$v.newIndustry.industryName.required" class="text-danger">Enter Industry</label>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="addNewIndustry()" class="btn btn-primary">OK</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="removeEduInfo" :onYes="deleteEduInfo" :returnParams="delEduParam" />
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
import Select2 from 'v-select2-component';

export default {
    // mounted(){
    //     console.log('--------------');
    //     console.log(this.$store.getters.getTimeZones);
    // },
    components: { Select2 },
    props: {
        personalinfo: Object,
    },
    computed: {
        dobDates() {
            let date = new Date();
            date.setFullYear(date.getFullYear() - 15);
            return {
                from: date
            };
        }
    },
    data() {
        return {
            countrieslist: this.$store.getters.getCountries,
            paymentmodes: this.$store.getters.getSalaryModes,
            recruiters: this.$store.getters.getRecruiters,
            visatypes: this.$store.getters.getVisaOptions,
            usstates: this.$store.getters.getUSStates,
            industries: this.$store.getters.getIndustries,
            timeZones: this.$store.getters.getTimeZones,
            languages: this.$store.getters.getLanguages,
            neweducationform: {
                schoolName: "",
                degree: "",
                city: "",
                stateName: "",
                year: "",
                countryId: "US",
                percentage: 0
            },
            availableDates: {
                to: new Date(Date.now() - 864000)
            },
            newIndustry: {
                industryName: ''
            },
            delEduParam: {
                index: -1,
                id: 0
            }

        }
    },
    validations: {
        personalinfo: {
            firstName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            lastName: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(30)
            },
            email: {
                required,
                email,
                emailexists(email) {
                    if (email === '') return true;
                    let ajaxdata = { email: email };
                    if (this.personalinfo.profileid != undefined) {
                        ajaxdata.id = this.personalinfo.profileid;
                    }
                    return axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/checkemailavailability', ajaxdata)
                        .then(response => {
                            if (response.data.status) {
                                return true;
                            }
                            return false;
                        })
                        .catch(error => {
                            return false;
                        });
                }
            }
        },
        neweducationform: {
            degree: {
                required,
                minLength: minLength(2),
                maxlength: maxLength(100)
            }
        },
        newIndustry: {
            industryName: {
                required
            }
        },
        deleteObject: {
            callBack: ''
        }
    },
    methods: {
        isPositiveInt(el, len) {
            if (el.key == "+" || el.key == "-" || el.target.value.length >= len) {
                el.preventDefault();
            }
        },
        validatePersonalInfo() {
            this.$v.personalinfo.$touch();
            if (this.$v.personalinfo.$invalid) {
                this.personalinfo.blockpersonaltab = 1;
                return false;
            }
            this.personalinfo.blockpersonaltab = 0;
            return true;
        },
        resetNewEducationForm() {
            this.neweducationform = {
                schoolName: "",
                degree: "",
                city: "",
                stateName: "",
                year: "",
                countryId: "US",
                percentage: 80,
                specialization: ''
            };
            this.$refs.addcandidate_educationinfo_add_name.focus();
            this.$v.neweducationform.$reset();
        },
        addEducationInfo() {
            if (this.neweducationform.schoolName != "" || this.neweducationform.degree != "" || this.neweducationform.city != "" || this.neweducationform.stateName != "" || this.neweducationform.stateName != "") {
                this.$v.neweducationform.$touch();
                if (!this.$v.neweducationform.$invalid) {
                    this.personalinfo.blockpersonaltab = 0;
                    this.personalinfo.educationinfo.push(this.neweducationform);
                    this.resetNewEducationForm();
                    console.log(this.personalinfo.educationinfo);
                } else {
                    this.personalinfo.blockpersonaltab = 1;
                }
            } else {
                this.$v.neweducationform.$reset();
            }
            return;
        },
        addNewIndustry: function() {
            this.$v.newIndustry.$touch();
            console.log(this.$v.newIndustry);
            if (this.$v.newIndustry.$invalid) {
                return false;
            }

            axios.post(this.$store.getters.getAPIBasePath + '/industryresource/add/industry', this.newIndustry)
                .then(response => {
                    if (response.data.status) {
                        this.$store.dispatch("reloadIndustries").then(() => {
                            this.industries = this.$store.getters.getIndustries;
                        });
                        iziToast.success({
                            title: 'Success',
                            message: this.$config.IndusSuc,
                            position: 'topRight'
                        });
                    } else {
                        let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.NewIndusErr;
                        iziToast.error({
                            title: 'Error',
                            message: errmsg,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    //console.log(error.response);
                    let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.NewIndusErr;
                    iziToast.error({
                        title: 'Error',
                        message: errmsg,
                        position: 'topRight'
                    });
                });
        },
        itemDeleted: function() {
            if (typeof this.deleteObject.callBack === 'function') {
                this.deleteObject.callBack();
            }
        },
        deleteEduInfo: function(arg) {
            alert(arg.id);

            this.personalinfo.educationinfo.splice(arg.index, 1);
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/delete/candidate/education/' + this.delEduParam.id)
                .then(response => {
                    var result = response.data;
                    alert(result.status);
                    if (result.status == "OK") {
                        iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                    }
                })
                .catch(error => {
                    console.log(error.data);
                });
            this.delEduParam.id = null;
        },
        confirmEduInfo: function(arg1, arg2) {
            this.delEduParam.index = arg1;
            this.delEduParam.id = arg2;
        }
    }
}

</script>
