<template>
	<div id="main" class="template-settings settings recruiter mt-70">
		<div class="row col-md-12 box-wrapper shadow-sm my-4 mx-0 px-0 rounded pos-rel">
			<div class="col-md-3 settings-leftbar px-0 bg-white" id="social-media-accordion">
				<div class="p-4 row header mx-0">
					<div class="col-md-9 px-0"><h5>Template Management</h5></div>
					<div class="col-md-3 px-0 text-right">
						<router-link :to="{name: 'referralsettings'}">
							<h5 class="text-blue">
								<span class="ti-arrow-left py-4" title="Go Back"></span>
								<span class="pl-2">Back</span>
							</h5>
						</router-link>
					</div>
				</div>
				<div @click="resetEmailTemplates" class="row col-md-12 py-4 mx-0 left-bar-tab" :class="elements.activeTab==1?'left-tab-active':''">
					<div class="col-md-3">
						<img src="@/assets/icons/mail-icon.png" width="60%" height="80%">
					</div>
					<div class="col-md-8">Email Templates</div>
				</div>
				<div v-if="false" @click="elements.activeTab=2" class="row col-md-12 py-4 mx-0 left-bar-tab" :class="elements.activeTab==2?'left-tab-active':''">
					<div class="col-md-3">
						<img src="@/assets/icons/sms-icon.png" width="60%" height="80%">
					</div>
					<div class="col-md-8">SMS Templates</div>
				</div>
			</div>
			<div class="col-md-9">
				<div class="basic-block text-center p-4 col-md-12 bg-white">
					<EmailTemplates v-if="elements.activeTab==1 && elements.showList"
					:pagination="pagination"
					@changeLimit="changeLimit"
					@getEmailTemplatesList="getEmailTemplatesList"
					:templatesList="data.email.templatesList"
					@addTemplate="addTemplate"
					@viewTemplate="viewTemplate"
					@editTemplate="editTemplate"/>
					<SmsTemplates v-if="elements.activeTab==2" />

					<!-- Email Templates -->
					<EmailAddTemplate v-if="elements.activeTab==1 && !elements.showList && elements.action=='add'"
					:pagination="pagination"
					@changeLimit="changeLimit"
					@getEmailTemplatesList="getEmailTemplatesList"
					:templatesList="data.email.templatesList"
					@closeActionTemplate="elements.showList=true"/>
					<EmailEditTemplate v-if="elements.activeTab==1 && !elements.showList && elements.action=='edit'"
					:pagination="pagination"
					@changeLimit="changeLimit"
					@getEmailTemplatesList="getEmailTemplatesList"
					:templatesList="data.email.templatesList"
					@closeActionTemplate="elements.showList=true"
					:templateData="data.email.templateData"/>
					<EmailViewTemplate v-if="elements.activeTab==1 && !elements.showList && elements.action=='view'" @closeActionTemplate="elements.showList=true"
					:templateData="data.email.templateData"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import iziToast from 'izitoast';
	import EmailTemplates from '@/components/recruiters/blocks/settings/templatesettings/EmailTemplates';
		import EmailAddTemplate from '@/components/recruiters/blocks/settings/templatesettings/email/AddTemplate';
		import EmailEditTemplate from '@/components/recruiters/blocks/settings/templatesettings/email/EditTemplate';
		import EmailViewTemplate from '@/components/recruiters/blocks/settings/templatesettings/email/ViewTemplate';
	import SmsTemplates from '@/components/recruiters/blocks/settings/templatesettings/SmsTemplates';
	export default {
		components: {
			EmailTemplates,
				EmailAddTemplate,
				EmailEditTemplate,
				EmailViewTemplate,
			SmsTemplates,
		},
		data(){
			return {
				elements: {
					activeTab: 1,
					action: "",
					showList: true,
				},
				pagination: {
					totalRecords: 0,
					rowsPerPage: 10,
					currentPage: 1,
					searchlimit: this.$store.state.searchlimit
				},
				data: {
					email: {
						templatesList: [],
						templateData: {}
					}
				}
			}
		},
		mounted(){
			// this.elements.activeTab = 1;
			
			// this.getEmailTemplatesList();
		},
		methods: {
			filterArrayByValue(listarray,arrayname,keyword,value){
				arrayname = listarray.filter(function(data,key){
					if(data.keyword==value){
						return data;
					}
				});
			},
			refreshPagination: function(){
				this.rowsPerPage = 10;
				this.pagination.currentPage = 1;
			},
			resetEmailTemplates: function(){
				this.refreshPagination();
				this.elements.activeTab = 1;
				this.elements.showList = true;
			},
			getTemplateData:function(template_id){
				axios.post(this.$store.getters.getAPIBasePath+'/templateresource/view/template',{"id":template_id})
				.then(response => {
					let result = response.data;
					if (!response.status) {
						iziToast.error({title: 'Error', message: result.message, position: 'topRight'});
					}
					this.data.email.templateData = result.emailTemplate;
				})
				.catch(error => {
					iziToast.error({title: 'Error', message: this.$config.LoadTempErr, position: 'topRight'});
				});
			},
			addTemplate: function(){
				this.elements.showList = false;
				this.elements.action = "add";
			},
			editTemplate: function(template_id){
				this.getTemplateData(template_id);
				this.elements.showList = false;
				this.elements.action = "edit";
			},
			viewTemplate: function(template_id){
				this.getTemplateData(template_id);
				this.elements.showList = false;
				this.elements.action = "view";
			},
			changeLimit: function(newlimit){
				this.pagination.rowsPerPage = newlimit;
			},
			getEmailTemplatesList: function(){
				axios.post(this.$store.getters.getAPIBasePath + '/templateresource/templatelist?page='+this.pagination.currentPage+'&size='+this.pagination.rowsPerPage,{})
				.then(response => {
					let result = response.data;
					if (!response.status) {
						iziToast.error({title: 'Error', message: result.message, position: 'topRight'});
					}
					this.data.email.templatesList = result.emailTemplates;
					this.pagination.totalRecords = result.pagination[0].total;
					this.pagination.currentPage = result.pagination[0].currentPage;
				})
				.catch(error => {
					iziToast.error({title: 'Error', message: this.$config.LoadEmailTempErr, position: 'topRight'});
				});
			}

		}
	}	
</script>