<template>
    <div :class="{ 'viewonly' : personalinfo.viewonly == 1}">
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header p-2">
                        <div class="row">
                            <div class="col-md-6">
                                Primary Information
                            </div>
                            <div class="col-md-6 text-right">
                                {{personalinfo.candidateCode}}
                            </div>
                            <!-- <div class="col-md-6 text-right">
                                <Select2 v-model="personalinfo.timeZone" :options="timeZones" :settings="{width:'50%'}" />
                            </div> -->
                        </div>
                    </div>
                    <div class="card-body p-2">
                        <div class="basic-block">
                            <div class="row ten-columns">
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>First Name</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group required-field-block">
                                                <input type="text" name="firstName" maxlength="45" size="30" v-model="personalinfo.firstName" class="form-control " :class="{ 'is-invalid': $v.personalinfo.firstName.$error }" @keyup="checkValue">
                                                <span class="required-icon"></span>
                                            </div>
                                            <label v-if="$v.personalinfo.firstName.$error && !$v.personalinfo.firstName.minLength" class="text-danger">First Name must have at least {{$v.personalinfo.firstName.$params.minLength.min}} letters.</label>
                                            <label v-if="$v.personalinfo.firstName.$error && !$v.personalinfo.firstName.required" class="text-danger">Please enter First Name</label>
                                            <label v-if="$v.personalinfo.firstName.$error && !$v.personalinfo.firstName.maxLength" class="text-danger">First Name must not exceed {{$v.personalinfo.firstName.$params.maxLength.max}} letters.</label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Middle Name</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input type="text" name="middleName" maxlength="45" size="30" v-model="personalinfo.middleName" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Last Name </label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group required-field-block">
                                                <input type="text" name="lastName" maxlength="45" size="30" v-model="personalinfo.lastName" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="personalinfo.editIndex && (ttClientAccountId == personalinfo.ttClientAccountId || personalinfo.isViewed==1)" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Email</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <!-- @blur="$v.personalinfo.email.$touch" -->
                                            <div class="input-group required-field-block">
                                                <input type="text" name="primaryEmail" maxlength="80" v-model="personalinfo.email" class="Submitaddress form-control" :class="{ 'is-invalid': $v.personalinfo.email.$error }"  @blur="$v.personalinfo.email.$touch">
                                                <span class="required-icon"></span>
                                            </div>
                                            <label v-if="$v.personalinfo.email.$error && !$v.personalinfo.email.required" class="text-danger">Please enter Email</label>
                                            <label v-if="$v.personalinfo.email.$error && !$v.personalinfo.email.email" class="text-danger">Please enter a valid email</label>
                                            <label v-if="$v.personalinfo.email.$error && !$v.personalinfo.email.emailexists && $v.personalinfo.email.email && $v.personalinfo.email.required" class="text-danger">Email already exists.
                                            </label>
                                            <!--  <label v-if="$v.personalinfo.email.$error && !$v.personalinfo.email.emailexists && $v.personalinfo.email.email && $v.personalinfo.email.required" class="text-danger"><a href="Javascript:void(0);" @click="viewCandidateInfo(candidateId)">Email already exists.  Do you like to update the candidate?</a></label> -->
                                        </div>
                                    </div>
                                </div>
                                <div v-if="personalinfo.editIndex && (ttClientAccountId == personalinfo.ttClientAccountId || personalinfo.isViewed==1)" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Phone</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <PhoneNumber ref="phoneNumber" :value="personalinfo.mobile"  :isValidcheck="isValidCheck" @phone-input="phoneControlInput" />
                                            <div v-if="false" class="input-group phone-border">
                                                <select class="form-control box-none col-sm-3 pl-0 pr-0 max-w-45 border-bottom-0" v-model="personalinfo.countryCode">
                                                    <option value="+1">+1</option>
                                                    <option value="+91">+91</option>
                                                </select>
                                                <input-mask class="form-control box-none col-sm-9 border-bottom-0" v-model="personalinfo.mobile" mask="(999)-999-9999" placeholder="Phone No" :alwaysShowMask="false" maskChar="_"></input-mask>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <!-- We have to add client settings later -->
                                <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Date Of Birth</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <datepicker v-model="personalinfo.dateOfBirth" placeholder="Date of Birth" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" :disabledDates="dobDates"></datepicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Industry</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12 input-group">
                                            <select v-model="personalinfo.industryId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in industries" :value="info.id">{{info.industryName}}</option>
                                            </select>
                                            <a v-if="personalinfo.viewonly==0" href="Javascript:void(0);" class="tbl-add-icon" data-toggle="modal" data-target="#addNewIndustry" @click="newIndustry.industryName=''"><span class="input-group-addon"><i class="ti-settings"></i></span></a>
                                        </div>
                                    </div>
                                </div>
                                <!-- </div>
                            <div class="row ten-columns"> -->
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Position</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input type="text" name="position" id="position" maxlength="100" v-model="personalinfo.position" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div+ v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Years of Experience</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group extended">
                                                <input type="number" name="yearsOfExp" id="yearsOfExp" maxlength="4" size="2" v-model="personalinfo.experience" class="form-control" @keypress="isPositiveInt($event,2)" min=0 oninput="validity.valid||(value='');" />
                                                <div class="input-group-append">
                                                    <select v-model="personalinfo.expType" class="form-control p-0">
                                                        <option v-for="(data, index) in expTypeList" :value="data.name">{{data.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Years of Experience</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <div class="input-group mb-3 yearexperience-select">
                                                <div class="input-group-prepend">
                                                    <!-- <input type="number" name="yearsOfExp" id="yearsOfExp" maxlength="4" size="2" v-model="personalinfo.experience" class="form-control" @keypress="isPositiveInt($event,2)" min=0 oninput="validity.valid||(value='');" /> -->
                                                    <!-- <multiselect v-model="personalinfo.experience" :options="experience" name="experience" label="label" track-by="expyear" select-label="" deselect-label="" class="" :max-height="600"></multiselect> -->
                                                    <div class="input-group-append w-40">
                                                        <select v-model="personalinfo.experience" class="form-control">
                                                            <option v-for="(data, index) in yearexperience" :value="data.expyear">{{data.label}}</option>
                                                        </select>
                                                    </div>
                                                    <span class="input-group-text p-0-5" id="basic-addon1">Y</span>
                                                    <!-- <input type="number" name="yearsOfExp" id="yearsOfExp" maxlength="4" size="2" v-model="personalinfo.experience" class="form-control" @keypress="isPositiveInt($event,2)" min=0 oninput="validity.valid||(value='');" /> -->
                                                    <!-- <multiselect v-model="personalinfo.expMonth" :options="experience" name="experience" label="label" track-by="expyear" select-label="" deselect-label="" class="" :max-height="600"></multiselect> -->
                                                    <div class="input-group-append w-40">
                                                        <select v-model="personalinfo.expMonth" class="form-control">
                                                            <option v-for="(data, index) in monthexperience" :value="data.expmonth">{{data.label}}</option>
                                                        </select>
                                                    </div>
                                                    <span class="input-group-text p-0-5" id="basic-addon1">M</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Location</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" name="location" id="location" maxlength="50" size="30" v-model="personalinfo.location" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Referred By</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" name="referredBy" maxlength="30" size="25" v-model="personalinfo.referredBy" id="referredBy" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="personalinfo.editIndex" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Candidate Source</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select name="source" id="" v-model="personalinfo.sourcingChannel" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(data, index) in sourcingChannel" :value="data.id">{{data.sourcingChannel}}</option>
                                            </select>
                                            <a class="addmoretextbox pt-2 pl-1" href="Javascript:void(0);" data-toggle="modal" data-target="#ReferenceLink" title="Source Reference Link"><i class="fa fa-link" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="personalinfo.editIndex" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>LinkedIn URL</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" name="linkedInUrl" maxlength="400" v-model="personalinfo.linkedInUrl" id="linkedInUrl" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Pay</label>
                                    </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <select name="selectedDivision" id="selectedDivision" v-model="personalinfo.payMode" class="form-control">
                                                <option value="">--Select--</option>
                                                <option v-for="(data, index) in paymentmodes" :value="data.id">{{data.name}}</option>
                                            </select>
                                        </div>
                                    </div>  
                                </div> -->
                            <div v-if="recruiters != undefined" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Recruiter</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select name="source" id="recruiterName" v-model="personalinfo.recruiterId" class="form-control">
                                            <option value="" selected="selected">Select</option>
                                            <option v-for="(data, index) in recruiters" :value="data.id">{{data.recruiterName}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Status</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12 input-group">
                                        <select v-model="personalinfo.status" class="form-control">
                                            <option value="" selected="selected">Select</option>
                                            <option v-for="(info, index) in candidatesStatusList" :value="info.id">{{info.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div v-if="workAuthorization == true" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Work Authorization</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select name="selectedVisaType" id="selectedVisaType" v-model="personalinfo.visaType" class="smallCombo_search form-control" tabindex="-1" aria-hidden="true">
                                            <option value="">Select</option>
                                            <option v-for="(data, index) in workAuthorizations" :value="{id: data.id, text:data.workAuthorizationName}">{{data.workAuthorizationName}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Candidate Type</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12 input-group">
                                        <select v-model="personalinfo.candidateType" class="form-control">
                                            <option value="" selected="selected">Select</option>
                                            <option v-for="(info, index) in candidateTypeList" :value="info.id">{{info.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Willing to relocate</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12 input-group">
                                        <select v-model="personalinfo.willRelocate" class="form-control">
                                            <option value="" selected="selected">Select</option>
                                            <option v-for="(info, index) in relocationStatus" :value="info.id">{{info.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header p-2">Educational Information</div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div class="row ten-columns">
                            <div class="col-sm-12">
                                <div class="table-responsive">
                                    <table class="table table-borderless cand-education-table">
                                        <thead>
                                            <tr>
                                                <th class="" scope="col" width="40%;">Degree</th>
                                                <th class="" scope="col">Specialization</th>
                                                <th class="" scope="col">Year</th>
                                                <th class="" scope="col">Name Of Institution</th>
                                                <th class="" scope="col">City</th>
                                                <th class="" scope="col">Country</th>
                                                <th class="" scope="col">State</th>
                                                <th class="" scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody class="cloned_tr">
                                            <tr v-for="(info, index) in personalinfo.educationinfo">
                                                <td>
                                                    <input v-model="personalinfo.educationinfo[index].degree" type="text" maxlength="100" minlength="2" size="23" class="form-control" required>
                                                </td>
                                                <td>
                                                    <input v-model="personalinfo.educationinfo[index].specialization" type="text" maxlength="100" minlength="2" size="23" class="form-control" required>
                                                </td>
                                                <td>
                                                    <input v-model="personalinfo.educationinfo[index].year" type="number" maxlength="4" min="1970" max="2019" class="form-control" required oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                                </td>
                                                <td>
                                                    <input v-model="personalinfo.educationinfo[index].schoolName" type="text" maxlength="50" minlength="6" size="23" class="form-control" required>
                                                </td>
                                                <td>
                                                    <input v-model="personalinfo.educationinfo[index].city" type="text" maxlength="50" minlength="2" size="23" class="form-control" required>
                                                </td>
                                                <td>
                                                    <select v-model="personalinfo.educationinfo[index].countryId" @change="personalinfo.educationinfo[index].stateName=''" class="form-control" required>
                                                        <option value="null">Select</option>
                                                        <option v-for="(data, index) in educountrieslist" :value="data.country_Id">{{data.name}}</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select v-if="personalinfo.educationinfo[index].countryId=='US'" v-model="personalinfo.educationinfo[index].stateName" class="form-control" required>
                                                        <option value="">--</option>
                                                        <option v-for="(data, index) in usstates" :value="data.stateName">{{data.stateName}}</option>
                                                    </select>
                                                    <input v-if="personalinfo.educationinfo[index].countryId!='US'" v-model="personalinfo.educationinfo[index].stateName" type="text" maxlength="25" minlength="2" size="23" class="form-control" required>
                                                </td>
                                                <td>
                                                    <!-- <button type="button" :class="{ 'removeaddbtn' : personalinfo.viewonly == 1}" class="addeducation actionprimary" @click="personalinfo.educationinfo.splice(index,1)"><span class="ti-trash"></span></button> -->
                                                    <a class="removemoretextbox" :class="{'removeaddbtn' : personalinfo.viewonly != 1 }" href="Javascript:void(0);" data-toggle="modal" data-target="#removeEduInfo" @click="confirmEduInfo(index,info.id)"><span class="ti-trash"></span></a>
                                                </td>
                                            </tr>
                                            <tr class="filled3" :class="{ 'noaddedmore' : personalinfo.viewonly == 1}">
                                                <td class="">
                                                    <input v-model="neweducationform.degree" type="text" maxlength="100" minlength="2" size="23" class="form-control" :class="{ 'is-invalid': $v.neweducationform.degree.$error }">
                                                </td>
                                                <td class="">
                                                    <input v-model="neweducationform.specialization" type="text" maxlength="100" minlength="2" size="23" class="form-control" :class="{ 'is-invalid': $v.neweducationform.degree.$error }">
                                                </td>
                                                <td class="">
                                                    <input v-model="neweducationform.year" type="number" maxlength="4" min="1970" max="2019" class="form-control" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                                </td>
                                                <td>
                                                    <input ref="addcandidate_educationinfo_add_name" v-model="neweducationform.schoolName" type="text" maxlength="50" minlength="6" size="23" class="form-control">
                                                </td>
                                                <td class="">
                                                    <input v-model="neweducationform.city" type="text" maxlength="50" minlength="2" size="23" class="form-control">
                                                </td>
                                                <td class="">
                                                    <select v-model="neweducationform.countryId" @change="neweducationform.stateName=''" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(data, index) in educountrieslist" :value="data.country_Id">{{data.name}}</option>
                                                    </select>
                                                </td>
                                                <td class="">
                                                    <select v-if="neweducationform.countryId=='US'" v-model="neweducationform.stateName" class="form-control">
                                                        <option value="">--</option>
                                                        <option v-for="(data, index) in usstates" :value="data.stateName">{{data.stateName}}</option>
                                                    </select>
                                                    <input v-if="neweducationform.countryId!='US'" v-model="neweducationform.stateName" type="text" maxlength="25" minlength="2" size="23" class="form-control">
                                                </td>
                                                <td>
                                                    <button type="button" class="addeducation actionprimary" @click="addEducationInfo"><span class="ti-plus"></span></button>
                                                    <!-- <a href="Javascript:void(0);" class="removeeducation" :class="{ 'removeaddbtn' : educationinfo.viewonly == 1}" @click="confirmEduInfo(index,educationinfo[index].id)"><span class="ti-trash"></span></a> -->
                                                    <a class="removeeducation" @click="confirmCompany(index,employmentinfo[index].id)" data-toggle="modal" data-target="#removCompany"><span class="ti-trash"></span></a>
                                                </td>
                                            </tr>
                                            <tr :class="{ 'norecordfound' : personalinfo.viewonly == 1}" style="display: none;">
                                                <td colspan="7" align="center">No item found</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header p-2">Additional Information</div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <AddtionalFields ref="additionalFields" :additionalInfo="personalinfo.candidateAdditionalFields" :newItem="newAdditionalField"></AddtionalFields>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card">
                <div class="card-header p-2">Language(s)</div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div class="row ten-columns">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-xs-12 required-field-block">
                                        <Select2 v-model="personalinfo.candidateLanguageSet" :options="languages" :settings="{width:'100%', multiple:true}" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header p-2">Objective/Summary</div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div class="row ten-columns">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-xs-12 required-field-block">
                                        <textarea rows="3" class="form-control" v-model="personalinfo.notes"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" id="addNewIndustry" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Add New Industry</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body input-group required-field-block">
                    <div class="input-group required-field-block">
                        <input v-model="newIndustry.industryName" type="text" maxlength="100" minlength="2" class="form-control">
                        <span class="required-icon"></span>
                    </div>
                    <label v-if="$v.newIndustry.industryName.$error && !$v.newIndustry.industryName.required" class="text-danger">Enter Industry</label>
                </div>
                <div class="modal-footer">
                    <button type="button" @click="addNewIndustry()" class="btn btn-primary">OK</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" id="ReferenceLink" tabindex="1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Enter Reference Link</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" data-backdrop="static" data-keyboard="false">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body input-group">
                    <div class="input-group">
                        <input v-model="personalinfo.sourcingRefLink" type="text" maxlength="100" minlength="2" class="form-control">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
                    <button type="button" class="btn btn-primary" @click="resetRefLink">Clear</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <Dialog id="removeEduInfo" :onYes="deleteEduInfo" :returnParams="delEduParam" />
    <b-modal id="compareCandidate" :no-close-on-backdrop="true" hide-header-close centered size="sm" title="Confirmation" @ok="compareCandidate" ok-title="Yes" cancel-title="No">
        <p class="my-1">Email already exists. Are you sure you want to compare?</p>
    </b-modal>
    <b-modal id="uploadResume" size="small" :no-close-on-backdrop="true" centered ref="resumeModal" title="Please upload the new resume" @show="" @hidden="" @ok="uploadResume" @cancel="clearResume" @close="">
        <div class="card m-b-3">
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row scroll-down-list">
                        <div class="col-sm-12">
                            <ResumeAttach :isResumeCapture="isResumeCapture" :resumeinfo="resumeinfo" :multipleFile="multipleFile" ref="resumeattachcomponent"></ResumeAttach>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
import Select2 from 'v-select2-component';
import AddtionalFields from '../../../plugins/AddtionalFields';
import Multiselect from 'vue-multiselect';
import MultiInputsDialog from '../../../plugins/MultiInputsDialog';
import ResumeAttach from './ResumeAttach';

export default {
    components: { Select2, AddtionalFields, Multiselect, MultiInputsDialog, ResumeAttach },
    props: {
        personalinfo: Object,
        newAdditionalField: {
            type: Object,
            default: {
                id: 0,
                nameValueId: 0,
                valueFormatId: "",
                intValue: "",
                stringValue: "",
                dateValue: "",
                booleanValue: ""
            }
        },
        candContactAccess: [String, Number],
        ttClientAccountId: [String, Number]
    },
    computed: {
        dobDates() {
            let date = new Date();
            date.setFullYear(date.getFullYear() - 15);
            return {
                from: date
            };
        }
    },
    data() {
        return {
            isResumeCapture: true,
            userId: "",
            candidateId: "",
            error: "",
            sourcingRefLink: "",
            yearexperience: [],
            monthexperience: [],
            educountrieslist:this.$store.getters.geteduCountries,
            paymentmodes: this.$store.getters.getSalaryModes,
            recruiters: this.$store.getters.getRecruiterList,
            visatypes: this.$store.getters.getVisaOptions,
            usstates: this.$store.getters.getUSStates,
            industries: this.$store.getters.getIndustries,
            timeZones: this.$store.getters.getTimeZones,
            languages: this.$store.getters.getLanguages,
            sourcingChannel: this.$store.getters.getSourcingChannel,
            expTypeList: this.$store.getters.getJobExpTypeOptions,
            candidatesStatusList: this.$store.getters.getCandidatesStatusList,
            candidateTypeList: this.$store.getters.getCandidateTypeList,
            relocationStatus: this.$store.getters.getRelocationStatus,
            neweducationform: {
                schoolName: "",
                degree: "",
                city: "",
                stateName: "",
                year: "",
                countryId: "US",
                percentage: 0
            },
            availableDates: {
                to: new Date(Date.now() - 864000)
            },
            newIndustry: {
                industryName: ''
            },
            delEduParam: {
                index: -1,
                id: 0
            },
            workAuthorizations: [],
            compareCandidateParam: {
                index: -1,
                id: 0
            },
            resumeinfo: {
                file: '',
                title: 'Choose Resume',
                supportedTypes: 'Supports only PDF, Doc., Docx file types',
                page: 1,
                extension: ''
            },
            multipleFile: [],
            workAuthorization: Boolean,
            isValidCheck:false,
        }
    },
    mounted() {
        this.generateExpYear();
        this.generateExpMonth();
        this.getWorkAuthorizations();
        this.workAuth();
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    validations: {
        personalinfo: {

            firstName: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(45)
            },
            email: {
                required,
                email,
                async emailexists(email) {
                    return await this.emailValidation(email);
                }
            }
        },

        neweducationform: {
            degree: {
                required,
                minLength: minLength(2),
                maxlength: maxLength(100)
            }
        },
        newIndustry: {
            industryName: {
                required
            }
        },
        deleteObject: {
            callBack: ''
        }
    },
    methods: {
        workAuth(){
            if(localStorage.getItem('workAuthorization') === "1"){
                this.workAuthorization  = true
            }else {
                this.workAuthorization  = false
            }
        },
        emailValidation(email) {
            if (email === '') return true;
            let ajaxdata = { email: email };
            if (this.personalinfo.profileid != undefined) {
                ajaxdata.id = this.personalinfo.profileid;
            }
            return axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/checkemailavailability', ajaxdata)
                .then(response => {
                    if (response.data.userId != null)
                        this.userId = response.data.userId;
                    if (response.data.candidateId != null)
                        this.candidateId = response.data.candidateId;
                    if (response.data.status) {
                        return true;
                    } else {
                        this.$root.$emit('bv::show::modal', 'compareCandidate')
                        return false;
                    }
                })
                .catch(error => {
                    return false;
                });
        },
        onPaste(e) {
            if (e.clipboardData.getData('text').match(/[^\d ()+-]/))
                e.preventDefault();
        },
        viewCandidateInfo(candidateId) {
            this.$router.push('/candidates/edit/' + candidateId);
            return;
        },
        resetRefLink: function() {
            this.personalinfo.sourcingRefLink = "";
        },
        checkValue() {
            if (/[^a-zA-Z]/.test(this.input)) {
                this.error = 'Input value contains other syntax.';
            } else {
                this.error = '';
            }
        },
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        validatePhone: function(evt, idx) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            console.log(charCode);
            if ((charCode > 33 && (charCode < 48 || charCode > 57)) && charCode !== 45 && charCode !== 46 && charCode !== 40 && charCode !== 41 && charCode !== 43) {
                evt.preventDefault();
            } //except numbers,open/close brackets,minus and space         
        },
        generateExpYear() {
            for (var i = 1; i <= 20; i++) {
                var x = i.toString();
                if (i == 1) {
                    this.yearexperience.push({ "expyear": "0", "label": "0" });
                }
                this.yearexperience.push({ "expyear": x, "label": x });
            }
            this.yearexperience.push({ "expyear": "21", "label": "20+" });
        },
        generateExpMonth() {
            for (var i = 1; i < 12; i++) {
                var x = i.toString();
                if (i == 1) {
                    this.monthexperience.push({ "expmonth": "0", "label": "0" });
                }
                this.monthexperience.push({ "expmonth": x, "label": x });
            }
        },
        handleScroll() {
            this.$v.personalinfo.email.$touch();
        },
        isPositiveInt(el, len) {
            if (el.key == "+" || el.key == "-" || el.target.value.length >= len) {
                el.preventDefault();
            }
        },
        validatePersonalInfo() {

           if(!this.personalinfo.editIndex){
                this.$v.personalinfo.firstName.$touch();
                if (this.$v.personalinfo.firstName.$invalid) {
                    this.personalinfo.blockpersonaltab = 1;
                    return false;
                }
                this.personalinfo.blockpersonaltab = 0;
                return true;
           }else if(!this.isValidCheck){
                this.$v.personalinfo.$touch();
                if (this.$v.personalinfo.$invalid) {
                    this.personalinfo.blockpersonaltab = 1;
                    return false;
                }
                this.personalinfo.blockpersonaltab = 0;
                return true;
            }        
        },
        resetNewEducationForm() {
            this.neweducationform = {
                schoolName: "",
                degree: "",
                city: "",
                stateName: "",
                year: "",
                countryId: "US",
                percentage: 80,
                specialization: ''
            };
            this.$refs.addcandidate_educationinfo_add_name.focus();
            this.$v.neweducationform.$reset();
        },
        addEducationInfo() {
            if (this.neweducationform.schoolName != "" || this.neweducationform.degree != "" || this.neweducationform.city != "" || this.neweducationform.stateName != "" || this.neweducationform.stateName != "") {
                this.$v.neweducationform.$touch();
                if (!this.$v.neweducationform.$invalid) {
                    this.personalinfo.blockpersonaltab = 0;
                    this.personalinfo.educationinfo.push(this.neweducationform);
                    this.resetNewEducationForm();
                } else {
                    this.personalinfo.blockpersonaltab = 1;
                }
            } else {
                this.$v.neweducationform.$reset();
            }
            return;
        },
        addNewIndustry: function() {
            this.$v.newIndustry.$touch();
            if (this.$v.newIndustry.$invalid) {
                return false;
            }

            axios.post(this.$store.getters.getAPIBasePath + '/industryresource/add/industry', this.newIndustry)
                .then(response => {
                    if (response.data.status) {
                        this.$store.dispatch("reloadIndustries").then(() => {
                            this.industries = this.$store.getters.getIndustries;
                        });
                        iziToast.success({
                            title: 'Success',
                            message: 'Industry added successfully',
                            position: 'topRight'
                        });
                    } else {
                        let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.NewIndusErr;
                        iziToast.error({
                            title: 'Error',
                            message: errmsg,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.NewIndusErr;
                    iziToast.error({
                        title: 'Error',
                        message: errmsg,
                        position: 'topRight'
                    });
                });
        },
        itemDeleted: function() {
            if (typeof this.deleteObject.callBack === 'function') {
                this.deleteObject.callBack();
            }
        },
        deleteEduInfo: function(arg) {
            this.personalinfo.educationinfo.splice(arg.index, 1);
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/delete/candidate/education/' + this.delEduParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                    }
                })
                .catch(error => {
                    console.log(error.data);
                });
            this.delEduParam.id = null;
        },
        confirmEduInfo: function(arg1, arg2) {
            this.delEduParam.index = arg1;
            this.delEduParam.id = arg2;
        },
        getWorkAuthorizations: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/settingsresource/settingworkauthorization/list')
                .then(response => {
                    this.workAuthorizations = response.data.settingsWorkAuthorization;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        updatePhoneNumber(data) {
            this.personalinfo.mobile = data.phoneNumber;
            this.personalinfo.countryCode = data.countryCode;
        },
        compareCandidate: function() {
            this.$root.$emit('bv::show::modal', 'uploadResume')
        },
        uploadResume: function() {
            let formData = new FormData();
            formData.append('file', this.resumeinfo.file);
            formData.append('resume_file', this.resumeinfo.file);
            axios.post(this.$store.getters.getAPIBasePath + '/resumeparserresource/singleparser/api',
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    }).then(response => {
                    console.log(response);
                    localStorage.setItem('emailAlreadyExists', "true");
                    localStorage.setItem('parsedResumeFileName', response.data.filePath);
                    localStorage.setItem('parseFilePath', response.data.filePath);
                    this.$router.push('/candidates/edit/' + this.candidateId);
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);

                });
        },
        checkEmail: function() {
            this.$v.personalinfo.email.$reset();
            this.$v.personalinfo.email.$touch;
        },
        clearResume: function() {
            localStorage.setItem('emailAlreadyExists', null);
            localStorage.setItem('parsedResumeFileName', "");
            localStorage.setItem('parseFilePath', "");
        },
        phoneControlInput: function (value,isValid) {
             this.personalinfo.mobile = value;
             this.isValidCheck = isValid;
        },
        clearAdditionalField: function(){
            this.$refs.additionalFields.clearData();
        }
    }
}

</script>
