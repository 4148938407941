var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header p-2"},[_vm._v("Client Details")]),_c('div',{staticClass:"card-body p-2"},[_c('div',{staticClass:"basic-block col-md-4"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped table-borderless s_table compare-table"},[_c('tbody',[_c('tr',[_vm._m(0),_c('td',{staticClass:"text-center",attrs:{"width":"50%"}},[_vm._v(_vm._s(_vm.clientinfo.clientName))])]),_c('tr',[_vm._m(1),_c('td',{staticClass:"text-center",attrs:{"width":"50%"}},[_vm._v(_vm._s(_vm.clientinfo.clientCode))])]),_c('tr',[_vm._m(2),_c('td',{staticClass:"text-center",attrs:{"width":"50%"}},[_vm._v(_vm._s(_vm.clientinfo.accountName))])]),_c('tr',[_vm._m(3),_c('td',{staticClass:"text-center",attrs:{"width":"50%"}},[_vm._v(_vm._s(_vm.clientinfo.dunsNumber))])]),_c('tr',[_vm._m(4),_c('td',{staticClass:"text-center",attrs:{"width":"50%"}},[_vm._v(_vm._s(_vm.clientinfo.url))])]),_c('tr',[_vm._m(5),_c('td',{staticClass:"text-center",attrs:{"width":"50%"}},[_vm._v(_vm._s(_vm.clientinfo.statusText))])])])])])])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header p-2"},[_vm._v("Description")]),_c('div',{staticClass:"card-body p-2"},[_c('div',{staticClass:"basic-block"},[_c('div',{staticClass:"row ten-columns"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"form-control objective"},[_vm._v(_vm._s(_vm.clientinfo.description))])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"width":"50%"}},[_c('strong',[_vm._v("Client Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"width":"50%"}},[_c('strong',[_vm._v("Client Code")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"width":"50%"}},[_c('strong',[_vm._v("Account Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"width":"50%"}},[_c('strong',[_vm._v("DUNS Number")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"width":"50%"}},[_c('strong',[_vm._v("Website")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"width":"50%"}},[_c('strong',[_vm._v("Status")])])
}]

export { render, staticRenderFns }