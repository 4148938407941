<template>
	<div id="home">
		<div class="home home-page">
			<Slider/>
			<PublicNavigators/>
			<PlatformFeatures/>
			<BannerBottom/>
		</div>
	</div>
</template>
<script>
	import Slider from './widgets/Slider';
	import PublicNavigators from './widgets/PublicNavigators';
	import PlatformFeatures from './widgets/PlatformFeatures';
	import BannerBottom from './widgets/BannerBottom';
	export default {
		components: {
			Slider,
			PublicNavigators,
			PlatformFeatures,
			BannerBottom
		}
	}
</script>