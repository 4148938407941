<template>
    <div class="card">
        <div class="card">
            <div class="card-header p-2">Search</div>
            <div class="card-body p-2">
                <div>
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                            <label>Search by Name</label>
                        </div>
                        <div class="col-md-4 col-sm-4 col-4" style="margin-left: 2px">
                           <input class="form-control" v-on:keyup.enter="getEmailList" type="text" v-model="emailInfo.candidateName" />
                            
                        </div>
                        <div class="col-md-2 col-sm-2 col-2">
                            <button
                                type="button"
                                class="
                                    btn btn-primary btn-sm
                                    float-right
                                    ml-10
                                "
                            >
                                Reset
                            </button>
                            <button type="button" @click="getEmailList"  class="    btn btn-primary btn-sm float-right ml-10 " > Find </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header p-2">Emails</div>
            <div class="card-body p-2">
                <div class="basic-block clone-preference">
                    <div class="row ten-columns pos-relative clone filled2">
                        <div class="col-md-12 col-sm-12 col-12 mb-10-mr">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col"></th>
                                            <th class="" scope="col">Sender</th>
                                            <th class="" scope="col">
                                                Receiver
                                            </th>
                                            <th class="" scope="col">
                                                Subject
                                            </th>
                                            <th class="" scope="col">
                                                Email Date
                                            </th>
                                            <!-- <th class="" scope="col">
                                                Attachment
                                            </th> -->
                                            <th class="">Content</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if=" emails != null &&  emails.length == 0">
                                            <td
                                                style="border: 0px"
                                                colspan="6"
                                                align="center"
                                            >
                                                No emails found
                                            </td>
                                        </tr>

                                        <template v-for="(email, index) in emails" >
                                            <tr>
                                                <td>
                                                   <!-- <span @click="getInterviews(recInfo.id)"><i :class="(currentIndex==recInfo.id) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i> -->
                                                 <a href="Javascript:void(0);" class="expand" data-targetopen="panel1">
                                                <span @click="getReplies(email.id) " ><i :class="(currentIndex==email.id) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i></span>
                                                 </a>
                                                <!-- <a href="Javascript:void(0);"class="expand" data-targetopen="panel1"> <span><i :class="currentIndex == email.id ? 'ti-arrow-circle-up': 'ti-arrow-circle-down' "></i></span> </a> -->
                                                </td>
                                                <td>
                                                    {{ email.sender }}
                                                </td>
                                                <td>
                                                    {{ email.receiver }}
                                                </td>
                                                <td>
                                                    {{ email.subject }}
                                                </td>
                                                 <td>{{toClientTimeZoneDate(email.emailDate, dateformat) }}</td>
                                        
                                                <!-- <td>
                                                    <button v-on:click>
                                                        Open
                                                    </button>
                                                </td> -->
                                                <td>
                                                    <a  @click="sendInfo(email)" href="javascript:;" data-toggle="modal" data-target="#RecruitmentFeedback"><i class=" ti-comment-alt"></i></a>
                                                </td>
                                            </tr>
                                            <tr v-if="currentIndex==email.id">
                                                <td colspan="7">
                                                    <div
                                                        class="table-responsive"
                                                    >
                                                        <table
                                                            class="
                                                                table
                                                                table-borderless
                                                                all-table
                                                            "
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th
                                                                        class=""
                                                                        scope="col"
                                                                    >
                                                                        Sender
                                                                    </th>
                                                                    <th
                                                                        class=""
                                                                        scope="col"
                                                                    >
                                                                        Receiver
                                                                    </th>
                                                                    <th
                                                                        class=""
                                                                        scope="col"
                                                                    >
                                                                        Subject
                                                                    </th>
                                                                    <th
                                                                        class=""
                                                                        scope="col"
                                                                    >
                                                                        Email
                                                                        Date
                                                                    </th>
                                                                    <th
                                                                        class=""
                                                                        scope="col"
                                                                    >
                                                                        Attachment
                                                                    </th>
                                                                    <th
                                                                        class=""
                                                                    >
                                                                        Content
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    v-for="(
                                                                        Info,
                                                                        index
                                                                    ) in email.replies"
                                                                >
                                                                    <td>
                                                                        {{
                                                                            Info.sender
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            Info.receiver
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            Info.subject
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            Info.emailDate
                                                                        }}
                                                                    </td>
                                                                    <!-- <td>  {{replies[0].sender}} </td> -->
                                                                    <!-- <td>  {{replies[0].receiver}} </td> -->
                                                                    <!-- <td>  {{replies[0].subject}} </td> -->
                                                                    <!-- <td>  {{replies[0].emailDate}} </td> -->
                                                                    <td>
                                                                        <button
                                                                            v-on:click
                                                                        >
                                                                            Open
                                                                        </button>
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            href="javascript:"
                                                                            ><i
                                                                                class="
                                                                                    ti-comment-alt
                                                                                "
                                                                                @click="
                                                                                    setResume
                                                                                "
                                                                            ></i
                                                                        ></a>
                                                                    </td>
                                                                </tr>
                                                                <tr
                                                                    v-if="
                                                                        email.replies !=
                                                                            null &&
                                                                        email
                                                                            .replies
                                                                            .length ==
                                                                            0
                                                                    "
                                                                >
                                                                    <td
                                                                        style="
                                                                            border: 0px;
                                                                        "
                                                                        colspan="5"
                                                                        align="center"
                                                                    >
                                                                        No
                                                                        replies
                                                                        found
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <b-modal
                    ref="my-modal"
                    id="RecruitmentFeedback"
                    hide-footer
                    title="Content"
                    size="lg "
                >
                    <div v-html ="selectedUser.content" class="modal-body"></div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="hideModal"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </b-modal>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        // emails: Array,
       
    },

    computed: {},
    data() {
        return {
            
            userInfo:this.$store.getters.currentUser,
            currentIndex: null,
            emails: [],
            replyList: [],
            selectedUser: "",
            dateformat:this.$store.state.dateformat,
             data: {
                params: {
                    searchByName: ""
                },
                
            },

            emailInfo:{
            candidateName: "",
            candidateEmail:"",

        },
        };
    },

   

      mounted() {
    },

    methods: {

        setSearchData(data){
             this.emailInfo.candidateName = data.candidateName;
             this.emailInfo.candidateEmail = data.candidateEmail
            this.getEmailList();

        },

       
         getEmailList(){


             this.params = {
                ttclientaccountid: this.userInfo.recruitOwner,
                userid: this.$store.getters.currentUser.accountId,
                candidateemail:  this.emailInfo.candidateEmail,
                // pageno: 1,
                // pagesize:100,
                searchbyemail:  this.emailInfo.candidateName,
            }
           
            // console.log("EmailInfo",this.params);
            axios.post(this.$store.getters.getAPIBasePath + '/emailsearchresource/getCandidateMailDetails' , this.params)
                .then(response => {
                    let result = response.data.data;
                    this.emails = result;
                    this.cont = result
                    // console.log(response);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        sendInfo(item) {
            this.selectedUser = item;
            // console.log("selectedUser", this.selectedUser);
        },
          getReplies(emailId) {
              console.log(emailId);
            if (this.currentIndex == emailId) {
                this.currentIndex = null;
            } else {
                this.currentIndex = emailId;
            }
        },
        setResume() {
            //this.resumeinfo.id = id;
            this.showModal();
            // this.deleteResume();
        },
        showModal() {
            this.$refs["my-modal"].show();
        },
        hideModal() {
            this.$refs["my-modal"].hide();
        },
    },
};
</script>
