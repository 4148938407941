import Interviews from '@/components/interviewers/Interviews';
import Profile from '@/components/interviewers/Profile';
import viewProfile from '@/components/interviewers/viewProfile';
import Dashboard from '@/components/interviewers/Dashboard';
import Requests from '@/components/interviewers/Requests';
import interviewFeedback from '@/components/interviewers/InterviewFeedback';
import Availability from '@/components/interviewers/Availability';
import ChangePassword from '@/components/interviewers/ChangePassword';

export default [
    {
		path: '/interviewer/interviews',
		name: 'interviewerinterviews',
		component: Interviews,
		meta:{accessedby:'interviewer'}
	},
	{
		path: '/interviewer/profile',
		name: 'interviewerprofile',
		component: Profile,
		meta:{accessedby:'interviewer'}
	},
	{
		path: '/interviewer/view',
		name: 'interviewerviewprofile',
		component: viewProfile,
		meta:{accessedby:'interviewer'}
	},
	{
		path: '/interviewer/dashboard',
		name: 'interviewerdashboard',
		component: Dashboard,
		meta:{accessedby:'interviewer'}
	},
	{
		path: '/interviewer/requests',
		name: 'interviewerrequests',
		component: Requests,
		meta:{accessedby:'interviewer'}
	},
	{
		path: '/interviewer/feedback/:interviewId',
		name: 'interviewfeedback',
		component: interviewFeedback,
		meta:{accessedby:'interviewer'}
	},
	{
		path: '/interviewer/availability',
		name: 'intervieweravailability',
		component: Availability,
		meta:{accessedby:'interviewer'}
	},
	{
        path: '/interviewer/changepassword',
        name: 'changepasswordinterviewer',
        component: ChangePassword,
        meta: { accessedby: 'interviewer' }
    }
];