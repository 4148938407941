/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.interceptors.request.use(config => {
	$('#globalapploader').fadeIn('slow');
	return config
});

window.axios.interceptors.response.use(function (response) {
	$('#globalapploader').fadeOut('slow');
	// console.log(response);
  	return response;
}, function (error) {
	$('#globalapploader').fadeOut('slow');
	console.log(error.response);
	return Promise.reject(error) // this is the important part
});

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

// let token = document.head.querySelector('meta[name="csrf-token"]');
// let acctoken = document.head.querySelector('meta[name="access-token"]');

// if (token) {
//     window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
// } else {
//     console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
// }

// if(acctoken){
// 	window.axios.defaults.headers.common['Authorization'] = acctoken.content;
// }