<template>
    <div id="main" class="mt-80 d-height">
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :class="isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <form id="main-search" :class="isExpand?'d-block':'d-none'"  v-on:submit.prevent="">
                        <div class="row form-group ten-columns">
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" v-on:keyup.enter="findInterviewers" v-model="params.name" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Technology</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select class="form-control" v-model="params.tech">
                                                <option value="">Select</option>                                          
                                                <option v-for="(info, index) in technologies" :value="{id:info.id,text:info.technology}">{{info.technology}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Mobile No</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" v-on:keyup.enter="findInterviewers" v-model="params.mobileNo" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr pl-0">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;">End Date</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12 float-right">
                                        <button type="button" class="btn btn-primary float-left" @click="findInterviewers" style="margin-left: 10px;">Find</button>
                                        <button type="button" class="btn btn-primary float-left" @click="resetFilter()" style="margin-left: 10px;">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="container-fluid" id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-6 col-sm-12 col-12">
                            <h4 class="page-title">Interviewers
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                    <div class="dropdown">
                                        <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                        <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                            <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                        </div>
                                    </div>
                                </span>
                            </h4>
                        </div>
                        <div class="col-md-6 d-none d-sm-none d-md-block">
                            <button type="button" @click="addLogActivity('New Interviewer')" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">
                                <router-link :to="{ name: 'addinterviewer' }" style="color: #fff;">New Interviewer</router-link>
                            </button>
                            <span class="float-right" @click="exportInterviewers()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i></span>
                        </div>
                    </div>
                    <div class="row pt-20 mt-20">
                        <div class="col-sm-12">
                            <table class="table table-borderless all-table">
                                <thead>
                                    <tr>
                                        <th v-if="false">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" @click="checkAll" type="checkbox" id="rowchckboxall" value="option1">
                                                <label class="form-check-label" for="rowchckboxall"></label>
                                            </div>
                                        </th>
                                        <th>Interviewer Code</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Technology</th>
                                        <th>Phone No</th>
                                        <th>Approved</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="!recruitersList.length && elements.loadingInterviewers == true">
                                        <td class="text-center" colspan="100%">No interviewers found</td>
                                    </tr>
                                    <tr v-for="recruiter in recruitersList">
                                        <td v-if="false">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" :id="'rowchckbox'+recruiter.id" v-model="panelMem" :value="recruiter.id" checked="">
                                                <label class="form-check-label" :for="'rowchckbox'+recruiter.id"></label>
                                            </div>
                                        </td>
                                        <td>
                                            <a href="Javascript:void(0);" @click="addLogActivity('View Interviewer')"><router-link :to="{ name:'viewinterviewer', params:{interviewerid: recruiter.id}}">{{recruiter.interviewerCode}}</router-link></a>
                                        </td>
                                        <td>
                                        <a href="Javascript:void(0);" @click="addLogActivity('View Interviewer')"> <router-link :to="{ name:'viewinterviewer', params:{interviewerid: recruiter.id}}">{{formatName(recruiter.interviewerName)}}</router-link></a>
                                        </td>
                                        <td>{{recruiter.email}}</td>
                                        <td>{{recruiter.technology}}</td>
                                        <td>{{recruiter.phoneNo}}</td>
                                        <td>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" :id="'approval-chckbox-'+recruiter.id" checked="" @change="changeApproval(recruiter.id,recruiter.approved)" v-model="recruiter.approved" :value="recruiter.approved" data-toggle="modal" data-target="#approval" />
                                                <!--  v-model="approved" :value="recruiter.approved" data-toggle="modal" data-target="#approval"-->
                                                <label class="form-check-label" :for="'approval-chckbox-'+recruiter.id"></label>
                                            </div>
                                        </td>
                                        <td>
                                            <ul class="navbar-nav">
                                                <li class="nav-item dropdown">
                                                    <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="javascript:" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                        <li>
                                                            <router-link class="dropdown-item" :to="{ name: 'viewinterviewer', params: { interviewerid: recruiter.id }}"><span class="ti-eye pr-2"></span> View</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link class="dropdown-item" :to="{ name: 'editinterviewer', params: { interviewerid: recruiter.id }}"><span class="ti-pencil pr-2" @click="addLogActivity('Edit Interviewer')"></span> Edit</router-link>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" href="javascript:" @click="deleteInterviewer(recruiter.id)" data-toggle="modal" data-target="#removeInterviewer"><span class="ti-close pr-2"></span> Deactivate</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <b-pagination v-if="recruitersList.length!=0" size="md" class='pull-right'  :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadInterviewersList()"></b-pagination>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div>
                    <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
                </div>                   
            </template> 
        </b-overlay>
        <Dialog id="approval" :onYes="confirmApproval" :returnParams="elements.approvalParams" :onNo="cancelApproval" :onExit="cancelApproval" title="Approval" message="Are you sure to change the approval?" />
        <Dialog id="removeInterviewer" :onYes="delInterviewer" :returnParams="delInterviewerParam" title="Deactivate Confirmation" message="Are you sure to deactivate?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
// import { BModal, VBModal } from 'bootstrap-vue'
export default {
    // components: {
    //     BModal,
    //     VBModal
    // },
    data() {
        return {
            showOverlay:false,
            elements: {
                approvalParams: {
                    id: null,
                    approved: 0,
                    statusName: ""
                },
                loadingInterviewers: false,
            },
            isExpand: false,
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            edit_mode: true,
            recruitersList: [],
            params: {
                name: "",
                technology: "",
                technologyName:"",
                tech:"",
                mobileNo:""
            },
            panelMem: [],
            delInterviewerParam: {
                index: -1,
                id: 0
            },
        }
    },
    computed: {
        candidateId() {
            return this.$route.params.candidateid
        }
    },
    watch: {
        'showOverlay': {
            handler: function(value) { 
                if(value == true){
                    $('#globalapploader').css('visibility','hidden');
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.$store.commit('refreshPagination');
        this.loadInterviewersList();
        console.log(this.technologies)
    },
    methods: {
        noop(){

        },
        async loadInterviewersList() {
            this.showOverlay=true;
            await this.getInterviewersList();
            this.showOverlay=false;
        }, 
        getInterviewersList() {
            this.showOverlay=true;
            if(this.params.tech != null){
                this.params.technology = this.params.tech.id;
                 this.params.technologyName = this.params.tech.text
            }


        //     const specialChars = /[()-]/;
        //     if(!(specialChars.test(this.params.mobileNo))){
        //         if(this.params.mobileNo.startsWith("+1")){
        //             this.params.mobileNo=this.params.mobileNo.replace(/\s+/g,'');
        //             this.params.mobileNo=this.params.mobileNo.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2)-$3-$4")
        //         }
        //         else if(this.params.mobileNo.startsWith("+91")&&!(specialChars.test(this.params.mobileNo))){
        //             this.params.mobileNo=this.params.mobileNo.replace(/\s+/g,'');
        //             this.params.mobileNo=this.params.mobileNo.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1 ($2)-$3-$4")
        //         }
        //         else 
        //             this.params.mobileNo=this.params.mobileNo.replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3"); 
        //     }
        //    else
        //     this.params.mobileNo=this.params.mobileNo

            this.params.pageNo = this.pagination.currentPage;
            this.params.limit = this.pagination.rowsPerPage;
            //return axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/list/interviewers?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.params)
            return axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/list/interviewers' , this.params)
                .then(response => {
                    let result = response.data;
                    console.log(result)
                    if (result.status){
                        //this.recruitersList = result.interviewer;
                        //this.pagination.totalRecords = result.pagination[0].total;
                        //this.pagination.currentPage = result.pagination[0].currentPage;

                         this.recruitersList = result.data;
                        this.pagination.totalRecords = result.totalRecords;
                        this.pagination.currentPage = result.currentPage;
                        this.elements.loadingInterviewers = true;
                   }                                    
                })
                .catch(e => {
                    this.showOverlay=false;
                    console.log(e);
                    //this.errors.push(e)
                });
        },
        filterInterviewers() {
            this.loadInterviewersList();
        },
        resetFilter() {
            this.params.name = "";
            this.params.technology = "";
            this.params.technologyName = "";  
            this.params.tech = "";                      
            this.params.mobileNo = "";                    
            this.loadInterviewersList();
        },
        checkAll() {
            this.isCheckAll = !this.isCheckAll;
            this.panelMem = [];
            let self = this
            if (this.isCheckAll) {
                $.each(this.recruitersList, function(key, value) {
                    self.panelMem.push(value.id)
                })
            }
        },
        changeLimit(newlimit) {
            this.pagination.currentPage = 1;
            this.pagination.rowsPerPage = newlimit;
            this.loadInterviewersList();
        },
        changeApproval(interviewerId, val) {
            // console.log(interviewerId+'--'+val);
            // let approvalParams = {"id" :interviewerId, "approved" : val?1:0};
            this.elements.approvalParams.id = interviewerId;
            this.elements.approvalParams.approved = val ? 1 : 0;
            this.elements.approvalParams.statusName = val ? "accept" : "reject";
        },
        confirmApproval() {
            axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/interviewer/approval', this.elements.approvalParams)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message: this.elements.approvalParams.approved ? this.$config.InterAproveSuc : this.$config.InterAproveCan, position: 'topRight' });
                    } else {
                        iziToast.error({ title: 'Error', message: this.$config.Updateerr, position: 'topRight' });
                    }
                    this.loadInterviewersList();
                    this.elements.approvalParams.id = null;
                    this.elements.approvalParams.approved = 0;
                })
                .catch(e => {
                    console.log(e);
                    iziToast.error({ title: 'Error', message: this.$config.Updateerr, position: 'topRight' });
                    this.loadInterviewersList();
                    this.elements.approvalParams.id = null;
                    this.elements.approvalParams.approved = 0;
                });
        },
        cancelApproval() {

            this.loadInterviewersList();
            this.elements.approvalParams.id = null;
            this.elements.approvalParams.approved = 0;
        },
        exportInterviewers() {
            this.addLogActivity();
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/interviewers?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "Interviewers" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });

        },
        findInterviewers() {
            this.pagination.currentPage = 1;
            this.loadInterviewersList();
        },
        addLogActivity: function(action) {
            this.updateLogActivity();
            let logActivityData = {
                entityName: 'Interviewer',
                actionName: action,
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', logActivityData)
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        formatName: function(name){
           return name.replace(/[^a-zA-Z ]/g, "");
        },
        deleteInterviewer(interviewerId) {
            this.delInterviewerParam.id = interviewerId;
        },
        delInterviewer() {
            var params = new URLSearchParams();
            var interviewerId = this.delInterviewerParam.id;
            params.append('id', interviewerId)
            axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/delete/interviewer', params)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadInterviewersList();
                    }
                })
                .catch(error => {
                    console.log(error.data);
                    this.unAuthorized(error);
                });
        },
    }
}

</script>
