<template>
    <div id="main" class="mt-70 plan-recruitment">
        <div id="quick-search" class="container-fluid mtb-4 bg-white p-tb-15">
            <div class="driver-model">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="box-wrapper  p-4 mtb-4 bg-white rounded pos-rel">
                            <div class="row">
                                <div class="col-sm-12 col-lg-12 col-md-12 col-12">
                                 <div class="col-md-12 col-sm-12 px-0 about-box">
                                    <div class="col-md-6 px-0 float-left">
                                    <h4 style="color: #37a0e4;">Job Information</h4>
                                    </div>
                                    <div class="col-md-6 px-0 float-right">
                                        <span v-if="jobDetails.ttClientAccountId == ttClientAccountId">
                                            <router-link class="float-right link pr-2" title="Edit Job" :to="{ name: 'editjob', params: { jobid: jobId}}"><span style="margin-top: -6px;" class="fa fa-pencil-square-o fa_custom fa-2x" @click="addLogActivity"></span></router-link>
                                        </span>
                                        <a href="javascript:">
                                            <social-sharing :url="redirectURL" title="TalentTurbo" :description="getSocialMediaShareInfo(jobDetails.jobTitle)" :quote="getSocialMediaShareInfo(jobDetails.jobTitle)" :twitter-user="jobDetails.jobCode" :hashtags="getSocialMediaHashTag(jobDetails.jobTitle,jobDetails.id)" inline-template>
                                                <network network="facebook">
                                                    <i class="fa fa-facebook-official fa_custom fa-lg hover-hand float-right pr-2 font24"></i>
                                                </network>
                                            </social-sharing>
                                        </a>
                                        <a href="javascript:">
                                            <social-sharing :url="redirectURL" title="TalentTurbo" :description="getSocialMediaShareInfo(jobDetails.jobTitle)" :quote="getSocialMediaShareInfo(jobDetails.jobTitle)" :twitter-user="jobDetails.jobCode" :hashtags="getSocialMediaHashTag(jobDetails.jobTitle,jobDetails.id)" inline-template>
                                                <network network="linkedin">
                                                    <i class="fa fa-linkedin-square fa_custom fa-lg hover-hand float-right pr-2 font24" aria-hidden="true"></i>
                                                </network>
                                            </social-sharing>
                                        </a>
                                        <a href="javascript:" @click.prevent="emailtabjob" title="Email To" class="float-right pr-2 "><i class="fa fa-envelope font24"></i></a>
                                        <a href="javascript:" @click="assignJobToRecruiters(jobId,jobDetails.jobTitle)" title="Task" class="float-right pr-2 "><i class="fa fa-tasks font24"></i></a>
                                     </div>
                                     </div>   <!--<router-link class="float-right link pr-2" title="AssignJob" :to="{ name: 'viewtask', params: { taskid: 65}}"><span style="margin-top: -6px;" class="fa fa-tasks fa_custom fa-2x" @click="viewtask"></span></router-link>-->
                                    <div class="my-inbox user-card">
                                        <div class="card w-100">
                                            <div class="card-body p-0">
                                                <div class="p-0">
                                                    <div class="row ml-0 mr-0">
                                                        <div class="col-sm-12 col-lg-12 col-md-12 col-12">
                                                            <div class="row pt-3" style="background-color: #f1f1f1;">
                                                                <div class="col-sm-12 col-md-12 col-12">
                                                                    <div class="headjobs-left">
                                                                        <h5 style="color: #37a0e4;margin-bottom: 10px;">{{jobDetails.jobTitle}} <span v-if="checkEmpty(jobDetails.jobCode)" class="pr-5">- [{{jobDetails.jobCode}}]</span> <span v-if="checkEmpty(jobDetails.vacancies)" title="Vacancies" class="badge badgebg pull-right">{{jobDetails.vacancies}}</span></h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row pb-3" style="background-color: #f1f1f1;">
                                                                <div class="col-sm-12 nomarginplz">
                                                                    <div class="row jobbrief padgap">
                                                                        <div class="col-sm-12">
                                                                            <div v-if="checkEmpty(jobDetails.industryName)" class="row">
                                                                                <div class="col-sm-4 nomarginplz">Industry : </div>
                                                                                <div class="col-sm-8 nomarginplz">{{jobDetails.industryName}}</div>
                                                                            </div>
                                                                            <!-- <div v-if="checkEmpty(jobDetails.position)" class="row">
                                                                                <div class="col-sm-4 nomarginplz">Position : </div>
                                                                                <div class="col-sm-8 nomarginplz">{{jobDetails.position}}</div>
                                                                            </div> -->
                                                                            <div v-if="checkEmpty(jobDetails.experience)" class="row">
                                                                                <div class="col-sm-4 nomarginplz">Experience : </div>
                                                                                <div class="col-sm-8 nomarginplz">
                                                                                    {{jobDetails.experience}}
                                                                                    <span class="pl-1 text-lowercase">{{jobDetails.expType}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div v-if="checkEmpty(jobDetails.workType)" class="row">
                                                                                <div class="col-sm-4 nomarginplz">Work Type : </div>
                                                                                <div class="col-sm-8 nomarginplz">{{jobDetails.workType}}</div>
                                                                            </div>
                                                                            <div v-if="jobDetails.locationData != null && jobDetails.locationData.length > 0" class="row">
                                                                                <div class="col-sm-4 nomarginplz">Location : </div>
                                                                                <div class="col-sm-8 nomarginplz">
                                                                                    <span v-for="(loc, index) in jobDetails.locationData">
                                                                                        <span>{{loc.dataName}}</span><span v-if="index+1 < jobDetails.locationData.length">, </span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div v-if="jobDetails.eligibilityData != null && jobDetails.eligibilityData.length > 0" class="row">
                                                                                <div class="col-sm-4 nomarginplz">Qualification : </div>
                                                                                <div class="col-sm-8 nomarginplz">
                                                                                    <span v-for="(eligibility, index) in jobDetails.eligibilityData">
                                                                                        <span>{{eligibility.dataName}}</span><span v-if="index+1 < jobDetails.eligibilityData.length">, </span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div v-if="checkEmpty(jobDetails.salary)" class="row">
                                                                                <div class="col-sm-4 nomarginplz">Salary : </div>
                                                                                <div class="col-sm-8 nomarginplz">
                                                                                    <span class="text-capitalize">{{jobDetails.currency}}</span>
                                                                                    {{jobDetails.salary}}
                                                                                    <span class="pl-1 text-capitalize">{{jobDetails.payFrequency}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div v-if="checkEmpty(jobDetails.minSalary)" class="row">
                                                                                <div class="col-sm-4 nomarginplz">Offered Amount : </div>
                                                                                <div class="col-sm-8 nomarginplz">
                                                                                    <span class="text-capitalize">{{jobDetails.currency}}</span>
                                                                                    {{jobDetails.minSalary}}
                                                                                    <span class="pl-1 text-capitalize">{{jobDetails.payFrequency}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div v-if="checkEmpty(jobDetails.maxSalary)" class="row">
                                                                                <div class="col-sm-4 nomarginplz">Max Salary : </div>
                                                                                <div class="col-sm-8 nomarginplz">
                                                                                    <span class="text-capitalize">{{jobDetails.currency}}</span>
                                                                                    {{jobDetails.maxSalary}}
                                                                                    <span class="pl-1 text-capitalize">{{jobDetails.payFrequency}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div v-if="checkEmpty(jobDetails.frequencyPercentage)" class="row">
                                                                                <div class="col-sm-4 nomarginplz">Flex Perc : </div>
                                                                                <div class="col-sm-8 nomarginplz">{{jobDetails.frequencyPercentage}}</div>
                                                                            </div>
                                                                            <div v-if="jobDetails.workAuthData != null && jobDetails.workAuthData.length > 0" class="row">
                                                                                <div class="col-sm-4 nomarginplz">Work Auth. : </div>
                                                                                <div class="col-sm-8 nomarginplz">
                                                                                    <span v-for="(workAuth, index) in jobDetails.workAuthData">
                                                                                        <span>{{workAuth.dataName}}</span><span v-if="index+1 < jobDetails.workAuthData.length">, </span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div v-if="jobDetails.jobDocumentData != null && jobDetails.jobDocumentData.length > 0" class="row">
                                                                                <div class="col-sm-4 nomarginplz">Req Docs: </div>
                                                                                <div class="col-sm-8 nomarginplz">
                                                                                    <span v-for="(jobDoc, index) in jobDetails.jobDocumentData">
                                                                                        <span>{{jobDoc.dataName}}</span><span v-if="index+1 < jobDetails.jobDocumentData.length">, </span>
                                                                                    </span>
                                                                                </div>
                                                                            </div> 
                                                                            <div v-if="checkEmpty(jobDetails.taxTerm)" class="row">
                                                                                <div class="col-sm-4 nomarginplz">Tax Term: </div>
                                                                                <div class="col-sm-8 nomarginplz">{{jobDetails.taxTerm}}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-sm-4 nomarginplz">Due Date : </div>
                                                                                <div class="col-sm-8 nomarginplz">{{jobDetails.dueDate | formatDate}}
                                                                                    <!-- <span v-if="checkEmpty(jobDetails.days)" class="d-inline-block pull-right"><i class="ti-alarm-clock"></i> <span class="pl-2 font-weight-bold" style="color:#e83e8c;">{{jobDetails.days}}</span> <span class="pl-1 small ">days more</span></span> -->
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 daysmore mb-n4">
                                                                            <span v-if="checkEmpty(jobDetails.days)" class="d-inline-block pull-right"><i class="ti-alarm-clock"></i> <span class="pl-2 font-weight-bold" style="color:#e83e8c;">{{jobDetails.days}}</span> <span class="pl-1 small ">days more</span></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row pt-4">
                                                                <div class="col-sm-12">
                                                                    <div class="headjobs-left pb-1">
                                                                        <h5 style="color: #37a0e4;margin-bottom: 10px;">Hiring Info</h5>
                                                                        <div class="informations">
                                                                            <span class="p-1 mb-2 mr-1 badge badge-success"> Talent Identified - {{ recCandInfo.talentCount }}</span>
                                                                            <span class="p-1 mb-2 mr-1 badge badge-secondary"> Shortlisted Candidates - {{ recCandInfo.candShortlistedCount }}</span>
                                                                            <span class="p-1 mb-2 mr-1 badge badge-warning"> Client Reviewed - {{ recCandInfo.clientReviewedCount }}</span>
                                                                            <span class="p-1 mb-2 mr-1 badge badge-info"> Hired - {{ recCandInfo.hiredCount }}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row pt-4">
                                                                <div class="col-sm-12">
                                                                    <div class="headjobs-left pb-2">
                                                                        <h5 style="color: #37a0e4;margin-bottom: 10px;">Job Skills</h5>
                                                                        <div class="informations">
                                                                            <span v-if="jobDetails.jobSkill != null && jobDetails.jobSkill.length==0" class="p-1 mb-2 mr-1">No skills found</span>
                                                                            <div v-for="(skill,index) in jobDetails.technologyList" class="">
                                                                                {{skill.technologyName}}
                                                                                <div>
                                                                                    <span v-for="(skilldata,idx) in skill.technologySkillData" class="p-1 mb-2 mr-1 badge " :class="colours[idx%5]">
                                                                                        {{skilldata.skillName}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-sm-12">
                                                                    <div class="headjobs-left">
                                                                        <h5 style="color: #37a0e4;margin-bottom: 10px;">Job Requirement</h5>
                                                                        <p class="htmlText" v-html="jobDetails.jobRequirement"></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-sm-12">
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                            <div class="row" v-if="jobDetails.vendorDetails.length > 0">
                                                                <div class="col-sm-12">
                                                                    <div class="headjobs-left">
                                                                        <h5 style="color: #37a0e4;margin-bottom: 10px;">Vendor Info</h5>
                                                                        <div v-for="(vendor,index) in jobDetails.vendorDetails" class="">
                                                                            <p class="w-100 d-inline-block float-left" title="Client Name"> <b>{{vendor.clientName}} </b></p>
                                                                            <p v-if="checkEmpty(vendor.contactPerson)" class="w-50 d-inline-block float-left" title="Contact person"><i class="ti-user pr-2"></i> {{vendor.contactPerson}} </p>
                                                                            <p v-if="checkEmpty(vendor.phone1)" class="w-50 d-inline-block float-left" title="Phone1"><i class="fa fa-phone pr-2" aria-hidden="true"></i>{{vendor.phone1}}</p>
                                                                            <p v-if="checkEmpty(vendor.email)" class="w-50 d-inline-block float-left" title="Email"><i class="ti-email pr-2"></i> {{vendor.email}} </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row" v-if="jobDetails.vendorDetails.length > 0">
                                                                <div class="col-sm-12">
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                            <div v-if="jobDetails.ttClientAccountId == ttClientAccountId" class="row pb-2" >
                                                                <div class="col-sm-12">
                                                                    <div class="headjobs-left">
                                                                        <h5 style="color: #37a0e4;margin-bottom: 10px;">Client Information</h5>
                                                                        <p class="w-100 d-inline-block float-left" title="Client Name"> {{jobDetails.clientName}} </p>
                                                                        <p v-if="checkEmpty(jobDetails.contact.contactPerson)" class="w-50 d-inline-block float-left" title="Contact person"><i class="ti-user pr-2"></i> {{jobDetails.contact.contactPerson}} </p>
                                                                        <p v-if="checkEmpty(jobDetails.contact.phone1)" class="w-50 d-inline-block float-left" title="Phone1"><i class="fa fa-phone pr-2" aria-hidden="true"></i>{{jobDetails.contact.phone1}}</p>
                                                                        <p v-if="checkEmpty(jobDetails.contact.phone2)" class="w-50 d-inline-block float-left" title="Phone2"><i class="ti-mobile pr-2" aria-hidden="true"></i> {{ jobDetails.contact.phone2 }}</p>
                                                                        <p v-if="checkEmpty(jobDetails.contact.email)" class="w-50 d-inline-block float-left" title="Email"><i class="ti-email pr-2"></i> {{jobDetails.contact.email}} </p>
                                                                        <p v-if="checkEmpty(jobDetails.contact.website)" class="w-50 d-inline-block float-left" title="Web site"><i class="ti-world pr-2"></i> {{jobDetails.contact.website}}</p>
                                                                        <!-- <span v-if="checkEmpty(jobDetails.contact.addressLine1) && checkEmpty(jobDetails.contact.cityName  )" class="float-left  w-100" title="Address">
                                      <div v-if="jobDetails.contact.addressLine1!='' && jobDetails.contact.cityName!=''"><i class="ti-location-pin pr-2"></i>{{jobDetails.contact.addressLine1+","}} {{jobDetails.contact.cityName}}</div>
                                      <div v-else-if="jobDetails.contact.cityName==''"><i class="ti-location-pin pr-2"></i>{{jobDetails.contact.addressLine1}}</div>
                                      <div v-else-if="jobDetails.contact.addressLine1!=''"><i class="ti-location-pin pr-2"></i>{{jobDetails.contact.cityName}}</div>
                                    </span> -->
                                                                        <div class="w-100">
                                                                            <i v-if="!checkAllEmpty(jobDetails.contact)" class="ti-location-pin pr-2"></i>
                                                                            <span v-if="checkEmpty(jobDetails.contact.addressLine1)">
                                                                                {{jobDetails.contact.addressLine1}}
                                                                            </span>
                                                                            <span v-if="checkEmpty(jobDetails.contact.cityName)">
                                                                                {{jobDetails.contact.cityName}}
                                                                            </span>
                                                                            <span v-if="checkEmpty(jobDetails.contact.stateName)">
                                                                                {{jobDetails.contact.stateName}}
                                                                            </span>
                                                                            <span v-if="checkEmpty(jobDetails.contact.countryId)">
                                                                                {{jobDetails.contact.countryId}}
                                                                            </span>
                                                                            <span v-if="checkEmpty(jobDetails.contact.zipCode)">
                                                                                {{jobDetails.contact.zipCode}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="status == 6" style="width: 100%;padding: 11px 15px;">
                                    <h5 class="about-box m-0 text-left p-2" style="border-radius: 3px;">
                                        <span class="job-link pt-0 pb-0">
                                            <a title="Reject" href="javascript:void(0);" data-toggle="modal" data-target="#RejectJob"><i class="ti-close" style="color: red;"></i><span style="color: red; padding-right:10px; font-family: sans-serif;"> Reject</span></a>
                                        </span>
                                        <span class="job-link float-right pt-0 pb-0">
                                            <a title="Accept" href="javascript:void(0);" data-toggle="modal" data-target="#AcceptJob"><i class="ti-check" style="color: green;"></i><span class="pr-10" style="color: green; font-family: sans-serif;">Accept</span></a>
                                        </span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="box-wrapper shadow-sm mtb-4 bg-white rounded pos-rel">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="row no-gutters">
                                        <div class="col-md-12 pop-tab">
                                            <div class="card">
                                                <div v-if="!elements.showEmailTools && !elements.showEmailToolsJob" class="card-body p-4">
                                                    <div class="driver-model">
                                                        <div class="row no-gutters">
                                                            <b-tabs small card class=" ul-horizontal tab-list driver-tab-list">
                                                                <b-tab title="Interview Plan">
                                                                    <div class="row no-gutters">
                                                                        <div class="col-md-12 pop-contents tt_form">
                                                                            <InterviewPlan @refreshIncidentHistory="refreshIncidentHistory" />
                                                                        </div>
                                                                    </div>
                                                                </b-tab>
                                                                <b-tab @click="elements.showCanShortList=true" title="Talent Search">
                                                                    <div class="row no-gutters">
                                                                        <div class="col-md-12 pop-contents tt_form">
                                                                            <TalentSearch v-if="elements.showCanShortList && elements.showShortListComponents && !elements.showCompareCandidate" @refreshIncidentHistory="refreshIncidentHistory" @refreshSLcandidates="viewCanShortList" :shortlistedCandidates="candShortlist" @viewCompareCandidate="viewCompareCandidate" @changeSLcandStatus="changeShortlistCandStatus" @emailTools="emailtab" :jobDetails="jobDetails" :candContactAccess="candContactAccess" />
                                                                            <CompareCandidate v-if="elements.showCompareCandidate" :compareData="compare.accData" @closeCompareCandidate="elements.showCompareCandidate=false" />
                                                                        </div>
                                                                    </div>
                                                                </b-tab>
                                                                <b-tab title="Interviews">
                                                                    <div class="row no-gutters">
                                                                        <div class="col-md-12 pop-contents tt_form">
                                                                            <Interviews v-if="elements.showInterviews && !elements.showFeedback" @refreshIncidentHistory="refreshIncidentHistory" @refreshSLcandidates="viewCanShortList" @refreshRecruitment="loadData" :recruitment="interviews" @viewFeedback="viewFeedback" @emailTools="emailtab" :jobCode="jobDetails.jobCode" ref="interviewscomponent" :candContactAccess="candContactAccess" />
                                                                            <Feedback v-if="elements.showFeedback" :recId="recruitmentId" @closeFeedback="elements.showFeedback=false" />
                                                                        </div>
                                                                    </div>
                                                                </b-tab>
                                                                <b-tab @click="elements.showCanShortList=true" title="Candidate Shortlisted">
                                                                    <div class="row no-gutters">
                                                                        <div class="col-md-12 pop-contents tt_form">
                                                                            <CandidateShortlist v-if="elements.showCanShortList && elements.showShortListComponents" @refreshIncidentHistory="refreshIncidentHistory" :jobDetails="jobDetails" :shortlistedCandidates="candShortlist" :candContactAccess="candContactAccess" @changeSLcandStatus="changeShortlistCandStatus" />
                                                                        </div>
                                                                    </div>
                                                                </b-tab>
                                                                <b-tab title="Client Reviewed">
                                                                    <div class="row no-gutters">
                                                                        <div class="col-md-12 pop-contents tt_form">
                                                                            <ClientReviewed v-if="elements.showShortListComponents" @refreshIncidentHistory="refreshIncidentHistory" :shortlistedCandidates="candShortlist" :candContactAccess="candContactAccess" @changeSLcandStatus="changeShortlistCandStatus" :jobDetails="jobDetails" />
                                                                        </div>
                                                                    </div>
                                                                </b-tab>
                                                                <b-tab title="Hired">
                                                                    <div class="row no-gutters">
                                                                        <div class="col-md-12 pop-contents tt_form">
                                                                            <Hired v-if="elements.showShortListComponents" @refreshIncidentHistory="refreshIncidentHistory" :shortlistedCandidates="candShortlist" :jobInfo="this.jobDetails" :candContactAccess="candContactAccess" @changeSLcandStatus="changeShortlistCandStatus" />
                                                                        </div>
                                                                    </div>
                                                                </b-tab>
                                                                <b-tab title="Follow up">
                                                                    <div class="row no-gutters">
                                                                        <div class="col-md-12 pop-contents tt_form">
                                                                            <Followup v-if="elements.showShortListComponents" @refreshIncidentHistory="refreshIncidentHistory" :shortlistedCandidates="candShortlist" @changeSLcandStatus="changeShortlistCandStatus" />
                                                                        </div>
                                                                    </div>
                                                                </b-tab>
                                                                <b-tab title="Incident History">
                                                                    <div class="row no-gutters">
                                                                        <div class="col-md-12 pop-contents tt_form">
                                                                            <IncidentHistory ref="incidentHistoryComponent" />
                                                                        </div>
                                                                    </div>
                                                                </b-tab>
                                                            </b-tabs>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <div class="row no-gutters">
                                                        <EmailTools v-if="elements.showEmailTools" :mailTo="{email: emailTools.emailId, id: emailTools.id, name: emailTools.name, userType: emailTools.userType}" @closeEmailTools="elements.showEmailTools=false" />
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <div class="row no-gutters">
                                                        <EmailToolsJob v-if="elements.showEmailToolsJob" :jobInfo="{id : 9, jobTitle : this.jobDetails.jobTitle,redirectURL : this.redirectURL,jobRequirement:this.jobDetails.jobRequirement}" @closeEmailTools="elements.showEmailToolsJob=false" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AssignjobModal :assignJob="assignJob" />
        <div class="modal " id="RejectJob" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Job confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to reject this job?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="jobConfirmation('reject')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal " id="AcceptJob" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Job confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to accept this job?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="jobConfirmation('approve')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InterviewPlan from './blocks/recplan/InterviewPlan';
import TalentSearch from './blocks/recplan/TalentSearch';
import Interviews from './blocks/recplan/Interviews';
import CandidateShortlist from './blocks/recplan/CandidateShortlist';
import ClientReviewed from './blocks/recplan/ClientReviewed';
import Hired from './blocks/recplan/Hired';
import Followup from './blocks/recplan/Followup';
import IncidentHistory from './blocks/recplan/IncidentHistory';
import Feedback from '../../layouts/blocks/Feedback';
import CompareCandidate from './blocks/recplan/CompareCandidate';
import EmailTools from '@/components/recruiters/blocks/tools/EmailTools';
import EmailToolsJob from '@/components/recruiters/blocks/tools/EmailTools';
import { mapState } from 'vuex';
import iziToast from 'izitoast';
import AssignjobModal from './blocks/AssignJob';
export default {
    components: {
        InterviewPlan,
        TalentSearch,
        Interviews,
        CandidateShortlist,
        ClientReviewed,
        Hired,
        Followup,
        IncidentHistory,
        Feedback,
        CompareCandidate,
        EmailTools,
        EmailToolsJob,
        AssignjobModal
    },
    data() {
        return {
            redirectURL: "",
            status: this.$route.params.status,
            elements: {
                showCanShortList: false,
                showInterviews: false,
                showShortListComponents: false,
                showFeedback: false,
                showCompareCandidate: false,
                showEmailTools: false,
                showEmailToolsJob: false
            },
            //ttClientAccountId : 0,
            incidentHistory: [],
            jobId: this.$route.params.jobid,
            interviews: [],
            candContactAccess: localStorage.getItem('candidateContactAccess'),
            jobDetails: {
                id:"",
                jobTitle: "",
                jobCode: "",
                clientName: "",
                industryName: "",
                position: "",
                eligibility: "",
                experience: "",
                salary: "",
                minSalary: "",
                maxSalary: "",
                frequencyPercentage: "",
                currency: "",
                vacancies: "",
                workType: "",
                taxTerm:"",
                expType: "",
                payFrequency: "",
                dueDate: "",
                //locationData:[],
                //eligibilityData:[],
                jobRequirement: "",
                jobSkill: [],
                vendorDetails: [],
                technologyList: [],
                days: 0,
                contact: {
                    contactPerson: "",
                    email: "",
                    phone1: "",
                    phone2: "",
                    website: "",
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    countryId: null,
                    zipCode: ""
                }
            },
            recruitmentId: null,
            candShortlist: [],
            colours: ['badge-primary', 'badge-success', 'badge-secondary', 'badge-danger', 'badge-warning', 'badge-info'],
            compare: {
                candIdJson: [],
                accData: []
            },
            recCandInfo: {
                talentCount: 0,
                candShortlistedCount: 0,
                clientReviewedCount: 0,
                hiredCount: 0
            },
            emailTools: {
                id: null,
                name: "",
                emailId: "",
                userType: ""
            },
            assignJob: {
                jobId: "",
                recruiterId: [],
                description: "",
                jobTitle: "",
                recruiterEmail: [],
                recruiterName: []

            }
            // metaInfo() {
            //     return {
            //         title: 'Test',
            //         meta: [
            //             { name: 'description', content: 'Connect and follow ' + this.jobDetails.jobTitle },
            //             { property: 'og:title', content: this.jobDetails.jobTitle + ' - Test' },
            //             { property: 'og:site_name', content: 'Test' },
            //             { property: 'og:description', content: 'Connect and follow ' + this.jobDetails.jobTitle },
            //             { property: 'og:type', content: 'profile' },
            //             { property: 'og:url', content: 'https://epiloge.com/@' + this.jobDetails.jobTitle }
            //         ]
            //     }
            // },
        }
    },
    //computed: mapState(['recPlanId']),
    computed: {
        ttClientAccountId() {
            if (this.$store.getters.currentUser.ttClientAccountId)
                return this.$store.getters.currentUser.ttClientAccountId;
            else return 0;
        },
    // mix this into the outer object with the object spread operator
    ...mapState(['recPlanId'])
    },
    watch: {
        recPlanId(newValue, oldValue) {
            this.elements.showCanShortList = true;
        }
    },
    mounted() {
        this.$store.commit('refreshPagination');
        this.updateLogActivity();
        this.loadData(this.$route.params.jobid);
        this.getJobInfo(this.$route.params.jobid);
        this.viewCanShortList();
        this.$root.$on('viewCanShortList', () => {
            this.viewCanShortList();
        })
    }, 
    methods: {
        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },
        checkAllEmpty(contact) {
            if (this.checkEmpty(contact.addressLine1) == false && this.checkEmpty(contact.cityName) == false && this.checkEmpty(contact.stateName) == false && this.checkEmpty(contact.countryId) == false && this.checkEmpty(contact.zipCode) == false) {
                return true;
            }
            return false;
        },
        loadData(jobid) {
            let recruitmentData = {
                jobId: jobid
            };
            axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/job/recruitment', recruitmentData)
                .then(response => {
                    let result = response.data;
                    this.interviews = result.recruitment;
                    this.elements.showInterviews = true;
                    this.refreshIncidentHistory();
                    this.updateLogActivity();
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        changeShortlistCandStatus(idx) {
            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/update/rp/candshortlist/status/' + idx.rpCandSLid, { "status": idx.CandSLstatusId,"candidateId":idx.candidateId,"jobId": this.$route.params.jobid })
                .then(response => {
                    if (response.data.status) {
                        this.viewCanShortList();
                        this.refreshIncidentHistory();
                        let successmsg = (response.data.message != undefined) ? response.data.message : this.$config.StsUpSuc;
                        iziToast.success({ title: 'Success', message: successmsg, position: 'topRight' });
                      this.loadData(this.$route.params.jobid);
                    } else {
                        let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.UpdatingErr;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                    let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.UpdatingErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
        },
        getJobInfo(jobId) {
            axios.get(this.$store.getters.getAPIBasePath + '/jobresource/view/job/' + jobId)
                .then(response => {
                    let result = response.data;
                    if (!result.status) {
                        iziToast.error({ title: 'Error Occured', message: result.message, position: 'topRight' });
                    }
                    this.jobDetails.id = result.jobs.id;
                    this.jobDetails.experience = result.jobs.experience;
                    this.jobDetails.jobTitle = result.jobs.jobTitle;
                    this.jobDetails.salary = result.jobs.salary;
                    this.jobDetails.minSalary = result.jobs.minSalary;
                    this.jobDetails.maxSalary = result.jobs.maxSalary;
                    let freq = result.jobs.frequencyPercentage;
                    if(freq>0){this.jobDetails.frequencyPercentage = freq+"%"}
                    else{
                        this.jobDetails.frequencyPercentage = freq;
                    }
                    this.jobDetails.currency = result.jobs.currency;
                    this.jobDetails.industryName = result.jobs.industryName;
                    // this.jobDetails.industryName = (result.jobs.technologyList[0].technologySkillData.length!=0?result.jobs.technologyList[0].technologySkillData[0].industryName:"");
                    this.jobDetails.position = result.jobs.position;
                    this.jobDetails.workType = result.jobs.workType;
                    this.jobDetails.expType = result.jobs.expType;
                    this.jobDetails.taxTerm = result.jobs.taxTerm;
                    this.jobDetails.jobCode = result.jobs.jobCode;
                    this.jobDetails.dueDate = result.jobs.dueDate;
                    this.jobDetails.ttClientAccountId = result.jobs.ttClientAccountId;
                    //this.jobDetails.location = result.jobs.location;                   
                    //this.jobDetails.workAuthorization = result.jobs.workAuthorization;
                    //this.jobDetails.jobDocuments = result.jobs.jobDocuments;
                    //this.jobDetails.eligibility = result.jobs.eligibility;
                    this.jobDetails.jobRequirement = result.jobs.jobRequirement;
                    this.jobDetails.vacancies = result.jobs.vacancies;
                    this.jobDetails.payFrequency = result.jobs.payFrequency;
                    this.jobDetails.days = result.jobs.days;
                    if (result.jobs.locationData != null) {
                        this.jobDetails.locationData = result.jobs.locationData;
                    }
                    if (result.jobs.eligibilityData != null) {
                        this.jobDetails.eligibilityData = result.jobs.eligibilityData;
                    }
                    if (result.jobs.jobVendorInfo != null) {
                        this.jobDetails.vendorDetails = result.jobs.jobVendorInfo;
                    }
                    if (result.jobs.workAuthData != null) {
                        this.jobDetails.workAuthData = result.jobs.workAuthData;
                    }
                    if (result.jobs.jobDocumentData != null) {
                        this.jobDetails.jobDocumentData = result.jobs.jobDocumentData;
                    }
                    if (result.jobs.contact != null) {
                        // let address = "";
                        // if(result.jobs.contact.addressLine1!="" && result.jobs.contact.addressLine2!=""){
                        //  address = addressLine1+", "+addressLine2;
                        // } else {
                        //  if(result.jobs.contact.addressLine1!="") address.=
                        // }
                        this.jobDetails.contact.contactPerson = result.jobs.contact.contactPerson;
                        this.jobDetails.contact.email = result.jobs.contact.email;
                        this.jobDetails.contact.phone1 = result.jobs.contact.phone1;
                        this.jobDetails.contact.phone2 = result.jobs.contact.phone2;
                        this.jobDetails.contact.website = result.jobs.contact.website;
                        this.jobDetails.contact.cityName = result.jobs.contact.cityName;
                        this.jobDetails.contact.stateName = result.jobs.contact.stateName;
                        this.jobDetails.contact.zipCode = result.jobs.contact.zipCode;
                        this.jobDetails.contact.countryId = result.jobs.contact.countryId;
                        this.jobDetails.contact.addressLine1 = result.jobs.contact.addressLine1;
                    }

                    this.jobDetails.technologyList = result.jobs.technologyList;
                    this.jobDetails.jobSkill = result.jobs.jobSkill;
                    this.jobDetails.clientName = result.jobs.clientName;
                    //this.jobDetails.endClientName = result.jobs.endClientName;
                    this.getEncodedKey(jobId);
                    this.updateLogActivity();
                })
                .catch(error => {
                    this.unAuthorized(error);
                    iziToast.error({ title: 'Error Occured', message: this.$config.JobLoadErr, position: 'topRight' });
                });

        },
        viewCanShortList() {
            this.updateLogActivity();

            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/list/candidate/shortlist', { "jobId": this.$route.params.jobid })
                .then(response => {
                    if (response.data.status) {
                        this.candShortlist = response.data.candShortlist;
                        this.recCandInfo.talentCount = this.candShortlist.length;
                        this.recCandInfo.candShortlistedCount = this.filterCandShortList().length;
                        this.recCandInfo.clientReviewedCount = this.filterCientReview().length;
                        this.recCandInfo.hiredCount = this.filterByStatus("7").length;
                        this.elements.showShortListComponents = true;
                        this.refreshIncidentHistory();
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        viewFeedback(recId) {
            this.recruitmentId = recId;
            this.elements.showFeedback = true;
        },
        viewCompareCandidate(candidateId) {
            this.compare.candIdJson = [];
            let self = this;
            $.each(candidateId, function(key, value) {
                self.compare.candIdJson.push({ "id": value.id });
            });
            let data = { "candidateId": self.compare.candIdJson }
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/candidates/compare', data).then(response => {
                    if (response.data.status) {
                        self.compare.accData = response.data.candidates;
                        this.elements.showCompareCandidate = true;
                        this.refreshIncidentHistory();
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        filterByStatus(statusId) {
            return this.candShortlist.filter(function(data, key) {
                if (data.status == statusId) {
                    return data;
                }
            });
        },
        filterCandShortList() {
            return this.candShortlist.filter(function(data, key) {
                if (data.status >= 3 && data.status <= 7) {
                    return data;
                }
            });
        },
        filterCientReview() {
            return this.candShortlist.filter(function(data, key) {
                if (data.status >= 4 && data.status <= 7) {
                    return data;
                }
            });
        },
        refreshIncidentHistory: function() {
            this.$refs.incidentHistoryComponent.getIncidentHistory();
        },
        emailtab(candidateData) {
            this.emailTools.id = candidateData.candidateId;
            this.emailTools.name = candidateData.candidateName;
            this.emailTools.emailId = candidateData.email;
            this.emailTools.userType = "candidate";
            this.elements.showEmailTools = true;
        },
        emailtabjob() {
            this.elements.showEmailToolsJob = true;
            this.elements.showEmailTools = false;
        },
        getEncodedKey(jobId) {
            axios.post(this.$store.getters.getAPIBasePath + '/jobresource/key/encode/fb/share', { "id": jobId })
                .then(response => {
                    var encodedKey = response.data.encodedKey;
                    var job = response.data.job;
                    this.redirectURL = this.$store.getters.getVUEBasePath + "/share/job/view/" + job.jobTitle.replace(/ /g, '') + "/" + job.jobCode + "/" + encodedKey;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getSocialMediaShareInfo(title) {},
        getSocialMediaHashTag(title, code) {},
        addLogActivity: function() {
            this.logActivityData = {
                entityName: 'Job',
                actionName: 'Edit',
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        viewtask(jobDetails, jobId) {
            console.log("view task");
            console.log(jobDetails.jobTitle);
            console.log(jobId);

        },
        assignJobToRecruiters(jobId, jobTitle) {
            this.assignJob.jobId = jobId;
            this.assignJob.jobTitle = jobTitle;
            this.$root.$emit('bv::show::modal', 'AssignjobModal')
        },
        jobConfirmation(type) {
            axios.post(this.$store.getters.getAPIBasePath + '/jobresource/job/approval', { 'id': this.jobId, 'statusName': type })
                .then(response => {
                    let result = response.data;
                    if (result.status) {
                        iziToast.success({
                            title: 'Confirmation Request',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getJobInfo(this.jobId);
                        this.$router.go(-1);
                    } else {
                        iziToast.error({
                            title: 'Confirmation Request',
                            message: this.$config.ConfirmReqErr,
                            position: 'topRight'
                        });
                    }
                });
            return true;
        },
    }
}

</script>
