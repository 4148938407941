<template>
    <div >
          <ChangePassword/>                                                
           
    </div>
</template>
<script>
import ChangePassword from '@/components/Common/ChangePassword';

export default {
    components: {
        ChangePassword
    },
                       

}

</script>
