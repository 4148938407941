<template>
    <div id="main" class="template-settings settings recruiter mt-70">
        <div class="row col-md-12 box-wrapper shadow-sm my-4 mx-0 px-0 rounded pos-rel">
            <div class="col-md-3 settings-leftbar px-0 bg-white" id="social-media-accordion">
                <div class="p-4 row header mx-0">
                    <div class="col-md-9 px-0">
                        <h5>General Settings</h5>
                    </div>
                    <div class="col-md-3 px-0 text-right">
                        <router-link :to="{name: 'recruitersettings'}">
                            <h5 class="text-blue">
                                <span class="ti-arrow-left py-4" title="Go Back"></span>
                                <span class="pl-2">Back</span>
                            </h5>
                        </router-link>
                    </div>
                </div>
                <div @click="resetGeneralSettings" class="row col-md-12 py-4 mx-0 left-bar-tab" :class="elements.activeTab==1?'left-tab-active':''">
                    <div class="col-md-3">
                        <img src="@/assets/icons/mail-icon.png" width="60%" height="80%">
                    </div>
                    <div class="col-md-8">Auto Approvals</div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="basic-block text-center p-4 col-md-12 bg-white">
                    <AutoApprovals v-if="elements.activeTab==1 && elements.showList" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AutoApprovals from './generalsettings/AutoApprovals';

export default {
    components: {
        AutoApprovals
    },
    data() {
        return {
            elements: {
                activeTab: 1,
                action: "",
                showList: true,
            }
        }
    },   
    methods: {             
        resetGeneralSettings: function() {
            this.elements.activeTab = 1;
            this.elements.showList = true;
        }        
    }
}
</script>
