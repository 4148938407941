<template>
    <div>
        <div class="card">
            <div class="card-header p-2">References</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="table-responsive">
                                <table class="table table-border all-table ">
                                    <thead>
                                        <tr>
                                            <th>Company Name</th>
                                            <th style="text-align: center;border-left: 1px solid rgba(248, 248, 248, 0.2);">
                                                Technology/Skills
                                            </th>
                                            <th style="text-align: center;border-left: 1px solid rgba(248, 248, 248, 0.2);">
                                                From
                                            </th>
                                            <th style="text-align: center;border-left: 1px solid rgba(248, 248, 248, 0.2);">
                                                To
                                            </th>
                                            <th colspan="4" style="text-align: center;border-right: 1px solid rgba(248, 248, 248, 0.2);border-left: 1px solid rgba(248, 248, 248, 0.2);">
                                                Reference
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(info, index) in referenceinfo" :class="{ 'viewonly' : referenceinfo[index].viewonly == 1}">
                                            <td>{{info.companyName}}</td>
                                            <td style="text-align: center;">{{info.skillSet}}</td>
                                            <td style="text-align: center;">{{info.employedFrom | formatDate}}</td>
                                            <td style="text-align: center;">{{info.employedTo | formatDate}}</td>
                                            <td style="text-align: center;">
                                                <label>{{referenceinfo[index].referenceName}}</label>
                                            </td>
                                            <td style="text-align: center;">
                                                <label>{{referenceinfo[index].referencePhone}}</label>
                                            </td>
                                            <td style="text-align: center;">
                                                <label>{{referenceinfo[index].referenceEmail}}</label>
                                            </td>
                                            <td style="text-align: center;">
                                                <label>{{referenceinfo[index].referenceRelationship}}</label>
                                            </td>
                                        </tr>
                                        <tr :class="{ 'norecordfound' : referenceinfo.length}" >
                                            <td colspan="8" align="center">Please add Employment info to update Reference Information</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
        props: {
            referenceinfo: Array,
        }
	}
</script>