import { render, staticRenderFns } from "./HomePages.vue?vue&type=template&id=63626301"
import script from "./HomePages.vue?vue&type=script&lang=js"
export * from "./HomePages.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports