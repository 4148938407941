<template>
    <div id="main" class="mt-70">
        <div class="container-fluid" id="quick-search">
            <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-6">
                        <h4 class="page-title">Search</h4>
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <ul class="ul-horizontal box-tools text-right mb-0">
                            <li><a @click="searchMore=!searchMore" href="javascript:;" data-target="main-search" class="boxhide"><span :class="searchMore?'ti-angle-up':'ti-angle-down'"></span></a></li>
                        </ul>
                    </div>
                </div>
                <form id="main-search" v-if="searchMore">
                    <div class="row form-group ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Company Name</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <input type="text" v-on:keyup.enter="findVendors" v-model="search.params.companyName" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Email</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <input type="text" v-on:keyup.enter="findVendors" v-model="search.params.email" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Phone</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <input type="number" v-on:keyup.enter="findVendors" class="form-control" v-model="search.params.phoneNumber">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr pl-0">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label style="visibility: hidden;"></label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <button type="button" @click="findVendors" class="btn btn-primary btn-sm float-left ml-10">Find</button>
                                    <button type="button" @click="resetVendorSearch" class="btn btn-primary btn-sm float-left ml-10">Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="load-indicator"></div>
            </div>
        </div>
        <div class="container-fluid" id="main-data">
            <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                <div class="row" style="margin-bottom: 15px;">
                    <div class="col-md-6 col-sm-12 col-12">
                        <h4 class="page-title">Leads
                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                <div class="dropdown">
                                    <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                    <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                        <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                    </div>
                                </div>
                            </span>
                        </h4>
                    </div>
                    <div class="col-md-6 d-none d-sm-none d-md-block">
                        <button type="button" @click="addLogActivity()" class="btn btn-primary btn-sm float-right ml-10">
                            <router-link :to="{ name: 'addlead' }" style="color: #fff;">New Lead</router-link>
                        </button>
                    </div>
      
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-borderless all-table">
                                <thead>
                                    <tr>
                                        <th >Lead Code</th>
                                        <th >Company Name</th>
                                        <th >Email</th>
                                        <th >Phone</th>
                                        <th >City</th>
                                        <th >State</th>
                                        <th >Zip Code</th>
                                        <th >Industry</th>
                                        <!-- <th >Status</th> -->
                                        <th >Action</th>
                                    </tr>
                                </thead>
                                <tbody id="table-length">
                                    <tr v-if="vendorList.length==0">
                                        <td colspan="10" align="center">No Leads found</td>
                                    </tr>
                                    <tr v-for="vendorInfo in vendorList">            
                                        <td>
                                            <router-link :to="{ name: 'viewlead', params: { vendorid: vendorInfo.id }}">{{ vendorInfo.leadCode }}</router-link>
                                        </td>
                                        <td >
                                            <router-link :to="{ name: 'viewlead', params: { vendorid: vendorInfo.id }}">{{ vendorInfo.companyName }}</router-link>
                                        </td>
                                        <td >{{ vendorInfo.email }}</td>
                                        <td >{{ vendorInfo.phoneNumber }}</td>
                                        <td >{{ vendorInfo.cityName }}</td>
                                        <td >{{ vendorInfo.stateName }}</td>
                                        <td >{{ vendorInfo.zipCode }}</td>
                                        <td >{{ ( vendorInfo.industry != null) ? vendorInfo.industry.industryName : '' }}</td>
                                        <!-- <td >
                                            <span v-if="vendorInfo.status == 1">Lead</span>
                                        </td> -->
                                        <td>
                                            <ul class="navbar-nav">
                                                <li class="nav-item dropdown">
                                                    <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                        <li>
                                                            <router-link class="dropdown-item" :to="{ name: 'viewlead', params: { vendorid: vendorInfo.id }}"><span class="ti-eye"></span> View</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link class="dropdown-item" :to="{ name: 'editlead', params: { vendorid: vendorInfo.id }}"><span class="ti-pencil"></span> Edit</router-link>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" href="javascript:;" @click="delVendor(vendorInfo.id)" data-toggle="modal" data-target="#removeVendor"><span class="ti-close"></span> Deactivate</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <b-pagination v-if="vendorList.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadVendors()"></b-pagination>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="removeVendor" :onYes="deleteVendor" :returnParams="delVendorParam" title="Deactivate Confirmation" message="Are you sure to deactivate ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    data() {
        return {
            vendorList: [],
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            searchlimit: this.$store.state.searchlimit,
            search: {
                params: {
                    companyName: '',
                    email: '',
                    phoneNumber: '',                   
                    pageNo:"",
                    limit:"",
                },
            },
            delVendorParam: {
                index: -1,
                id: 0
            },
            searchMore: false
        }
    },
    mounted() {
        this.loadVendors();
        this.updateLogActivity();
    },
    methods: {
        resetVendorSearch() {
            this.search.params = {
                companyName : "",
                email : "",
                phoneNumber : "",
                pageNo : "",
                limit : "",
            };       
            this.loadVendors();
        },
        loadVendors() {
            this.search.params.pageNo = this.pagination.currentPage;
            this.search.params.limit = this.pagination.rowsPerPage;
            const specialChars = /[()-]/;
            if(!(specialChars.test(this.search.params.phoneNumber))){
           if( this.search.params.phoneNumber.startsWith("+1")){
            this.search.params.phoneNumber= this.search.params.phoneNumber.replace(/\s+/g,'');
            this.search.params.phoneNumber= this.search.params.phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2)-$3-$4")
            }
            else if( this.search.params.phoneNumber.startsWith("+91")){
            this.search.params.phoneNumber= this.search.params.phoneNumber.replace(/\s+/g,'');
            this.search.params.phoneNumber= this.search.params.phoneNumber.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1 ($2)-$3-$4")
            }
            else 
            this.search.params.phoneNumber= this.search.params.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3");
            }
            else
            this.search.params.phoneNumber= this.search.params.phoneNumber
            axios.post(this.$store.getters.getAPIBasePath + '/lead/list', this.search.params)
                .then(response => {
                    let result = response.data;
                    this.vendorList = result.recordinfo;
                    this.pagination.totalRecords = result.totalrecords;
                    this.pagination.currentPage = result.currentpage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        changeLimit(newlimit) {
            this.pagination.rowsPerPage = newlimit;
            this.loadVendors();
        },
        delVendor(vendorId) {
            this.delVendorParam.id = vendorId;           
        },
        deleteVendor() {
            axios.post(this.$store.getters.getAPIBasePath + '/lead/delete/'+this.delVendorParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    this.loadVendors();
                })
                .catch(error => {
                    console.log(error.data);
                });
        },
        findVendors() {
            this.pagination.currentPage = 1;
            this.loadVendors();
        },
        addLogActivity: function(action) {
            this.updateLogActivity();
            this.logActivityData = {
                entityName: 'Vendor',
                actionName: action,
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
