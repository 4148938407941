<template>
<div id="quick-search" class="container-fluid bg-white">
    <div class="driver-model" style="padding:0px 0px 10px;">
        <div class="row no-gutters">
        <b-tabs small card class="tab-list driver-tab-list tab-bg">
        <b-tab title="Shared Candidates to Other Companies">
        <div class="kpis reports text-left">
        <div class="row">
            <div class="col-sm-12 p-10">
                <div id="recruiter-settings" class="recruiter settings mt-10">
                    <div class="container-fluid" id="quick-search">
                        <!-- <div class="card">
                            <div class="card-header">
                                <h5 class="text-center">Shared Candidates</h5>
                            </div>
                        </div> -->
                        <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <h4 class="page-title">Filter</h4>
                                </div>
                                <div class="col-md-6 col-sm-6 col-6">
                                    <ul class="ul-horizontal box-tools text-right mb-0">
                                        <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :title="isExpand?'Hide':'Expand'" :class="isExpand?'ti-angle-up':'ti-angle-down'" class="hover-hand"></span></a></li>
                                        <li><i class="ti-close hover-hand px-2" @click="$emit('setSubTab',0);$emit('updateLogActivity');" title="Close"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <form id="main-search" :class="isExpand?'d-block':'d-none'">
                                <div class="row form-group ten-columns">
                                    <div v-if="clientAccount==1" class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Recruiter</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <select class="form-control" v-model="search.params.recruiterId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in recruiterlist" :value="info.id">{{info.recruiterName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Shared To</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <select class="form-control" v-model="search.params.ttClientAccountId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in clientlist" :value="info.id">{{info.company_Name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Start Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledStartDates" placeholder="Start Date" name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.sharedStartDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>End Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledendDates" placeholder="End Date" name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.sharedEndDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="row form-group ten-columns">
                                    <div class="col-md-12 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label style="visibility: hidden;"></label>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="button" @click="resetCandidatesSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                                <button type="button" @click="findCandidates" class="btn btn-primary btn-sm float-right">Go</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="load-indicator"></div>
                        </div>
                    </div>
                    <div class="container-fluid" id="main-data">
                        <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                            <div class="row" style="margin-bottom: 15px;">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <h4 class="page-title">Shared To
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                            <div class="dropdown">
                                                <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                                <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                    <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                                </div>
                                            </div>
                                        </span>
                                    </h4>
                                </div> 
                                 <div class="col-md-6 d-none d-sm-none d-md-block">
                                        <span class="float-right" @click="recruiterSharedCandidateTo()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i></span>
                                </div>                               
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table table-borderless all-table">
                                            <thead>
                                                <tr>
                                                    <th class="" scope="col">Sl.No</th>
                                                    <th class="status" scope="col">Candidate</th>
                                                    <th class="recruiter" scope="col">Shared By</th>
                                                    <th class="status" scope="col">Shared To</th>
                                                    <th class="status" scope="col">Created By</th>
                                                    <th class="status" scope="col">Shared Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="candidateslist.length==0">
                                                    <td colspan="6" align="center">No Candidates found</td>
                                                </tr>
                                                <tr v-for="(candidateinfo, index) in candidateslist">
                                                    <td>{{((pagination.currentPage-1) * (pagination.rowsPerPage)) + (index+1)}}</td>
                                                    <td>
                                                        {{candidateinfo.candidateName}}
                                                    </td>
                                                     <td>
                                                        {{candidateinfo.recruiterName}}
                                                    </td>
                                                    <td>
                                                        {{candidateinfo.account_Name}}
                                                    </td>
                                                    <td class="recruiter">{{ candidateinfo.createdBy }}</td>
                                                    <td>{{ candidateinfo.shared_Date | formatDate }}</td>
                                                    
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <b-pagination v-if="candidateslist.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadCandidates()"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </b-tab>
            <b-tab title="Shared Candidates from Other Companies">
               <div class="kpis reports text-left">
        <div class="row">
            <div class="col-sm-12 p-10">
                <div id="recruiter-settings" class="recruiter settings mt-10">
                    <div class="container-fluid" id="quick-search">
                        <!-- <div class="card">
                            <div class="card-header">
                                <h5 class="text-center">Shared Candidates</h5>
                            </div>
                        </div> -->
                        <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <h4 class="page-title">Filter</h4>
                                </div>
                                <div class="col-md-6 col-sm-6 col-6">
                                    <ul class="ul-horizontal box-tools text-right mb-0">
                                        <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :title="isExpand?'Hide':'Expand'" :class="isExpand?'ti-angle-up':'ti-angle-down'" class="hover-hand"></span></a></li>
                                        <li><i class="ti-close hover-hand px-2" @click="$emit('setSubTab',0);$emit('updateLogActivity');" title="Close"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <form id="main-search" :class="isExpand?'d-block':'d-none'">
                                <div class="row form-group ten-columns">
                                    <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Shared From</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <select class="form-control" v-model="receivesearch.params.recruiterId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in recruiterlist" :value="info.id">{{info.recruiterName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Start Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledRecStartDates" placeholder="Start Date" name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="receivesearch.params.sharedStartDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>End Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledRecEndDates" placeholder="End Date"name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="receivesearch.params.sharedEndDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="row form-group ten-columns">
                                    <div class="col-md-12 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label style="visibility: hidden;"></label>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="button" @click="resetfromCandidatesSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                                <button type="button" @click="findshareCandidates" class="btn btn-primary btn-sm float-right">Go</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="load-indicator"></div>
                        </div>
                    </div>
                    <div class="container-fluid" id="main-data">
                        <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                            <div class="row" style="margin-bottom: 15px;">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <h4 class="page-title">Shared From
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{receivepagination.totalRecords}} - Total)</span>
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                            <div class="dropdown">
                                                <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                                <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                    <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeshareLimit(value)">{{value}}</a>
                                                </div>
                                            </div>
                                        </span>
                                    </h4>
                                </div> 
                                 <div class="col-md-6 d-none d-sm-none d-md-block">
                                        <span class="float-right" @click="recruiterSharedCandidateFrom()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i></span>
                                </div>                               
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table table-borderless all-table">
                                            <thead>
                                                <tr>
                                                    <th class="" scope="col">Sl.No</th>
                                                    <th class="status" scope="col">Candidate</th>
                                                    <th class="company" scope="col">Company Name</th>
                                                    <th class="recruiter" scope="col">Shared From</th>
                                                    <th class="status" scope="col">Shared Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="receivecandidateslist.length==0">
                                                    <td colspan="6" align="center">No Candidates found</td>
                                                </tr>
                                                <tr v-for="(candidateinfo, index) in receivecandidateslist">
                                                    <td>{{((receivepagination.currentPage-1) * (receivepagination.rowsPerPage)) + (index+1)}}</td>
                                                    <td>
                                                        {{candidateinfo.candidateName}}
                                                    </td>
                                                    <td>
                                                        {{candidateinfo.company_Name}}
                                                    </td>
                                                     <td>
                                                        {{candidateinfo.recruiterName}}
                                                    </td>
                                                    <td>{{ candidateinfo.shared_Date | formatDate }}</td>
                                                    
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <b-pagination v-if="receivecandidateslist.length!=0" size="md" class='pull-right' :total-rows="receivepagination.totalRecords" v-model="receivepagination.currentPage" :per-page="receivepagination.rowsPerPage" @input="loadSharefromCandidates()"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
            </b-tab>
        </b-tabs>
        </div>
    </div>
</div>
</template>
<script>
import iziToast from 'izitoast';
import { mapState } from 'vuex';
export default {

    data() {
        return {

            isExpand: false,
            candidateslist: [],
            receivecandidateslist:[],
            clientlist: [],
            technologies: this.$store.getters.getTechOptions,
            industrylist: this.$store.getters.getIndustries,
            recruiterlist: this.$store.getters.getRecruiters,
            candStatusList: this.$store.getters.getCandStatusReport,
            searchlimit: this.$store.state.searchlimit,
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            receivepagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
             disabledStartDates: {
                from: new Date()
            
            },
             disabledendDates:{
                from: new Date(),
                to: ''
            },
             disabledRecStartDates: {
                from: new Date()
            
            },
             disabledRecEndDates:{
                from: new Date(),
                to: ''
            },
            elements: {
                showadvancedsearch: false,
                submitted: null,
                toolbar: 'undo redo formatselect| bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
                initObj: {
                    menubar: false,
                    height: 300,
                    branding: false,
                }
            },
            search: {
                params: {
                    recruiterId: "",
                    ttClientAccountId: "",
                    sharedStartDate: "",
                    sharedEndDate: ""
                }
            },
            receivesearch: {
                params: {
                    recruiterId: "",
                    ttClientAccountId: "",
                    sharedStartDate: "",
                    sharedEndDate: ""
                }
            },
            coldisplay: {
                recruiter: true,
                title: true,
                technology: true,
                currentcity: true,
                state: true
            },
            clientParams: {
                accountName: ''
            },
            clientAccount: this.$store.getters.currentUser.recruitOwner,
        }
    },
    computed: mapState(['globalSearchValue']),
    watch: {
        globalSearchValue(newValue, oldValue) {
            this.search.params.firstName = newValue;
            this.loadCandidates();
        },
        'search.params.sharedStartDate': {
            handler: function(value) {
                this.disabledendDates.to = new Date(value);
            },
            deep: true,
            immediate: true
        },
        'receivesearch.params.sharedStartDate': {
            handler: function(value) {
                this.disabledRecEndDates.to = new Date(value);
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
        this.$store.commit('refreshPagination');
        this.loadDate();
        this.loadCandidates();
        this.loadSharefromCandidates();
        this.loadClients();
        this.$emit('updateLogActivity');
        this.$emit('addLogActivity','Shared Candidates Report'); 
    },
    methods: {
        resetCandidatesSearch() {
            this.search.params = {               
                recruiterId: "",    
                ttClientAccountId: "",           
                sharedStartDate: "",
                sharedEndDate: ""
            };
            this.loadDate();
            this.disabledStartDates.from = new Date();
            this.disabledendDates.from = new Date();
            this.loadCandidates();
        },
        resetfromCandidatesSearch() {
            this.receivesearch.params = {               
                recruiterId: "",    
                ttClientAccountId: "",           
                sharedStartDate: "",
                sharedEndDate: ""
            };
            this.loadDate();
            this.disabledRecStartDates.from = new Date();
            this.disabledRecEndDates.from = new Date();
            this.loadSharefromCandidates();
        },
        loadDate() {
            let date = new Date();
            this.search.params.sharedStartDate = date;
            this.search.params.sharedEndDate = date;
            this.receivesearch.params.sharedStartDate = date;
            this.receivesearch.params.sharedEndDate = date;
        },
        loadCandidates() {
            if (this.search.params.sharedStartDate > this.search.params.sharedEndDate) {
                iziToast.error({
                    title: 'Error',
                    message: this.$config.DateMismatchErr,
                    position: 'topRight'
                });
                return;
            }
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/list/shared/candidates?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.candidateslist = result.sharedCandidates;
                    this.pagination.totalRecords = result.pagination[0].total;
                    this.pagination.currentPage = result.pagination[0].currentPage;
                })
                .catch(error => {
                    console.log(error);
                });

        },   
        loadSharefromCandidates(){
            if (this.receivesearch.params.sharedStartDate > this.receivesearch.params.sharedEndDate) {
                iziToast.error({
                    title: 'Error',
                    message: this.$config.DateMismatchErr,
                    position: 'topRight'
                });
                return;
            }
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/list/receive/candidates?page=' + this.receivepagination.currentPage + '&size=' + this.receivepagination.rowsPerPage, this.receivesearch.params)
                .then(response => {
                    let result = response.data;
                    console.log(result)
                    this.receivecandidateslist = result.sharedCandidates;
                    this.receivepagination.totalRecords = result.pagination[0].total;
                    this.receivepagination.currentPage = result.pagination[0].currentPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        changeLimit(newlimit) {
            this.pagination.currentPage = 1;
            this.pagination.rowsPerPage = newlimit;
            this.loadCandidates();
        },    
        changeshareLimit(newlimit) {
            this.receivepagination.currentPage = 1;
            this.receivepagination.rowsPerPage = newlimit;
            this.loadSharefromCandidates();
        },    
        findshareCandidates() {
            this.receivepagination.currentPage = 1;
            this.loadSharefromCandidates();        
        },   
        findCandidates() {
            this.pagination.currentPage = 1;
            this.loadCandidates();        
        },
        loadClients() {
            //axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/client/account/list?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.clientParams)
              axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/client/list', this.clientParams)
                .then(response => {
                    let result = response.data;
                    this.clientlist = result.clientAccountList;                    
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);

                });
        },
        recruiterSharedCandidateTo() {
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/recruiter/sharred/report?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "RecruiterSharedCandidateReport" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });
        },
        recruiterSharedCandidateFrom() {
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/recruiter/receive/report?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "RecruiterSharedCandidateReport" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });
        },
    }
}

</script>
