<template>
	<div class="col-md-9 col-sm-9">
		<div class="collapse navbar-collapse" id="demo-navbar">
			<ul class=" mr-auto ul-horizontal main-menu mb-0">
                <!-- <li class="nav-item dropdown active">
                    <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img alt="images" src="@/assets/icons/candidates.png" class="menuicon">
                        <img alt="images" src="@/assets/icons/candidates-hvr.png" class="hovericon">
                        Candidate
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <router-link :to="{ name: 'adminlistcandidate' }" class="dropdown-item">LIST CANDIDATES</router-link>
                    </div>
                </li> -->
			</ul>
			<form class="form-inline my-2 my-lg-0">
				<ul class="ul-horizontal userprofile-submenu mb-0">
					<li v-if="false" class="nav-item dropdown">
						<a class="nav-link dropdown-toggle " href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="ti-bell"></span>
						</a>
						<div class="dropdown-menu" aria-labelledby="navbarDropdown">
							<a class="dropdown-item" href="">notification 1</a>
							<a class="dropdown-item" href="">notification 2 </a>
						</div>
					</li>
					<li class="">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="">{{userinfo.name}} <i class="fa fa-angle-down"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <router-link :to="{ name: 'adminsettings' }" class="dropdown-item">Tools & Settings</router-link>
                            <router-link :to="{ name: 'changepasswordadmin' }" class="dropdown-item">Change Password</router-link>
							<a class="dropdown-item" @click="performLogout" href="Javascript:void(0);">Logout</a>
                        </div>
                    </li>
				</ul>
			</form>
		</div>
	</div>
</template>

<script>
export default {
	mounted() {	
		this.InlineButtonClickHandler();
		localStorage.setItem('loggedOut','false');
		//console.log(this.$store.getters.currentUser);
	},
	data(){
		return {
			userinfo:this.$store.getters.currentUser
		}
	},
	methods:{
		performLogout(){
			this.addLogActivity();
            this.$store.dispatch("doLogout");
            localStorage.setItem('loggedOut',true);
            //this.$router.push('/login');
		},
		InlineButtonClickHandler(){
			$(document).ready(function(){
				$('.main-menu > li > div > a').click(function(){
					$('.main-menu > li').removeClass('active');
					$(this).parent().parent().addClass('active');
				});
			});
		},
		addLogActivity: function() {
            this.logActivityData = {
                entityName: 'Logout',
                actionName: 'Logout',
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {
                    localStorage.setItem('candIdForActivity', response.data.id);
                })
                .catch(error => {

                });
        }
	}
}
</script>