<template>
    <div id="main" class="tech-skill-settings settings recruiter">
        <div class="row col-md-12 px-0 py-4">
            <div class="col-md-4 offset-md-4">
                <h4 class="">Country Based Settings</h4>
            </div>
            <div class="col-md-1 offset-md-3">
                <a href="Javascript:void(0);" class="pull-right btn-back" @click="$emit('setSubTab',0)">
                    <h5 class="text-blue"><span title="Go Back" class="ti-arrow-left py-4"></span><span class="pl-2">Back</span></h5>
                </a>
            </div>
        </div>
        <div class="row pb-2">
            <div class="col-3 col-md-3" style="font-weight: 600;">Country</div>
            <div class="col-3 col-md-3" style="font-weight: 600;">Currency</div>
            <div class="col-3 col-md-3" style="font-weight: 600;">Work Authorization</div>
            <div class="col-3 col-md-3" style="font-weight: 600;"></div>
        </div>
        <div class="row">
                <div class="col-3 col-md-3">
                    <select v-model="newCountrysettgs.countryId" class="form-control">
                        <option value="">Select</option>
                        <option v-for="(data, index) in educountrieslist" :value="data.country_Id">{{data.name}}</option>
                    </select>
                </div>
                <div class="col-3 col-md-3">
                    <select v-model="newCountrysettgs.currencyId" class="form-control p-0" >
                        <option value="0">&nbsp;Select</option>
                        <option v-for="(data, index) in currencies" :value="data.id">{{data.symbol}}</option>
                    </select>
                </div>
                <div class="col-3 col-md-3">
                    <select v-model="newCountrysettgs.workAuthorization" class="form-control">
				        <option value="">Select</option>
				        <option value="1">Yes</option>
				        <option value="0">No</option>
				    </select>
                </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                <a href="Javascript:void(0);" @click="addCountrysettings">
                    <span class="ti-plus"></span>
                </a>
            </div>
        </div>
        <div class="row item" v-for="(country, index) in countrysetlist" v-bind:key="index">
            <div class="col-md-3 col-3">
                <select v-model="country.countryId" class="form-control" style="-webkit-appearance: none;" disabled>
                    <option v-for="(data, index) in educountrieslist" :value="data.country_Id">{{data.name}}</option>
                </select>
            </div>
            <div class="col-md-3 col-3">
                <select v-model="country.currencyId" class="form-control" style="-webkit-appearance: none;"  disabled>
                    <option v-for="(data, index) in currencies" :value="data.id">{{data.symbol}}</option>
                </select>
            </div>
            <div class="col-md-3 col-3">
                <select v-model="country.workAuthorization" style="-webkit-appearance: none;"  disabled class="form-control">
				        <option value="">Select</option>
				        <option value="1">Yes</option>
				        <option value="0">No</option>
				</select>
            </div>
            <div class="col-md-3 col-3">
                <a href="Javascript:void(0);" title="Edit" data-toggle="modal" data-target="#addSettings" @click="loadEditdata(country.id,index)">
                    <span class="ti-pencil"></span>
                </a>
            </div> 
        </div>
        <div class="modal" id="addSettings" ref="addSettings" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Edit</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-lg-12 col-xl-12">                                   
                            <div class="row mb-2">
                                    <div class="col-4 col-md-3 pr-0">
                                        <label class="mb-0 p-1 pt-2 pb-2 d-block" style="font-weight: 600;">Country</label>
                                    </div>
                                    <div class="col-8 col-md-8 pl-0">
                                        <div class="input-group">
                                            <select v-model="countryInfo.countryId" class="form-control" disabled>
                                                <option v-for="(data, index) in educountrieslist" :value="data.country_Id">{{data.name}}</option>
                                            </select>
                                        </div>                                       
                                    </div>                                         
                                </div>
               
                                <div class="row mt-0 mb-2">
                                    <div class="col-4 col-md-3 pr-0">
                                        <label class="mb-0 p-1 pt-2 pb-2 d-block" style="font-weight: 600;">Currency</label>
                                    </div>
                                    <div class="col-8 col-md-8 pl-0">
                                        <select v-model="countryInfo.currencyId" class="form-control">
                                            <option v-for="(data, index) in currencies" :value="data.id">{{data.symbol}}</option>
                                        </select>
                                    </div>                                                                          
                                </div>
               
                                <div class="row mt-0 mb-2">
                                    <div class="col-4 col-md-3 pr-0">
                                        <label class="mb-0 p-1 pt-2 pb-2 d-block" style="font-weight: 600;">Work Authorization</label>
                                    </div>
                                    <div class="col-8 col-md-8 pl-0">
                                        <div class="input-group">
                                            <select v-model="countryInfo.workAuthorization" class="form-control">
				                                <option value="1">Yes</option>
				                                <option value="0">No</option>
				                            </select>
                                        </div>
                                    </div>    
                                </div>
                            </div>
                        </div>
                    <div class="modal-footer">                                            
                        <button type="submit" class="btn btn-primary" @click="savesettingsdata">Save</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
div.item {
    margin-top: 5px;
}

</style>
<script>
import iziToast from "izitoast";
import Multiselect from 'vue-multiselect';
export default {
    components: {
        Multiselect
    },
    data() {
        return {
            workAuthorizations: [],
            countrysetlist:[],
            editIndex: 0,
            countrieslist: this.$store.getters.getCountries,
            educountrieslist:this.$store.getters.geteduCountries,
            countryInfo: {
                countryId: "",
                currencyId: "",
                workAuthorization:""
            },
            currencies: this.$store.getters.getCurrencies,
            newCountrysettgs: { id: 0,currencyId: 0,countryId:"",workAuthorization:""},
            editshow: false,
        };
    },
    mounted() {
        this.getWorkAuthorizations();
        this.loadcountryList();
    },
    methods: {
        loadcountryList(){
            axios.post(this.$store.getters.getAPIBasePath +"/companysettings/list")
                .then(response => {
                    let result = response.data;
                    this.countrysetlist = result.recordinfo;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addCountrysettings() {           
            if(this.newCountrysettgs.countryId !='' && this.newCountrysettgs.currencyId !='' && this.newCountrysettgs.workAuthorization != ''){
                axios.post(this.$store.getters.getAPIBasePath +"/companysettings/add", this.newCountrysettgs)
                    .then(response => {
                    if (response.data.status == "ACCEPTED") {
                            iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                            this.newCountrysettgs.currencyId = 0;
                            this.newCountrysettgs.countryId = "";
                            this.newCountrysettgs.workAuthorization = "";
                        } else {
                            iziToast.info({ title: 'Info', message: this.$config.couExists, position: 'topRight' });
                        }
                    this.loadcountryList();  
                    })
                    .catch(error => {
                        console.log(error);
                    });
                }
            else{
                iziToast.info({ title: 'Info', message: this.$config.SelectFields, position: 'topRight' });
            }
        },     
        Clearselectedstgs(){
            this.newCountrysettgs.currencyId = 0;
            this.newCountrysettgs.countryId = "";
            this.newCountrysettgs.workAuthorization = "";
        },
        loadEditdata(id,idx){
            this.editIndex=idx;
            axios.get(this.$store.getters.getAPIBasePath +"/companysettings/view/"+id)
                .then(response => {
                    let result = response.data;
                    this.countryInfo = {
                    	countryId:result.recordinfo.countryId,
	                    currencyId:result.recordinfo.currencyId,
	                    workAuthorization:result.recordinfo.workAuthorization,
	                };
                })
                .catch(error => {
                    console.log(error);
                });
        },
        savesettingsdata(){
                axios.post(this.$store.getters.getAPIBasePath +"/companysettings/edit/"+this.countrysetlist[this.editIndex].id,this.countryInfo)
                    .then(response => {
                        if(response.data.status == "ACCEPTED") {
                                iziToast.success({
                                    title: "Success",
                                    message: response.data.message,
                                    position: "topRight",
                                    timeout: 1000
                                });                            
                            } else {
                                iziToast.info({
                                title: "Info",
                                message: response.data.message,
                                position: "topRight"
                                });
                            }
                            this.loadcountryList();
                            $('#addSettings').modal('hide');
                        })
                        .catch(error => {
                            iziToast.info({
                                title: "Info",
                                message: error.response.data.message,
                                position: "topRight",
                                timeout: 1000
                            });
                        });
        },
        getWorkAuthorizations: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/settingsresource/settingworkauthorization/list')
                .then(response => {
                    this.workAuthorizations = response.data.settingsWorkAuthorization;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        
    }
};

</script>
