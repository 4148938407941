<template>
	<div id="main" class="mt-70">
        <div class="container-fluid" id="main-data">
            <div class="row row-flex">
                <div class="col-md-3 pr-md-2 mb-2">
                    <div class="box-wrapper shadow-sm p-4 mtb-4 bg-white rounded pos-rel h-100">
                        <div class="candidate_info">
                            <h4 class="page-title">Interviewer Info </h4>
                            <hr>
                            <div class="user-card">
                                <div class="user-profile">
                                    <div class="profile"></div>
                                </div>
                                <div class="user-stats">
                                    <div class="w-clearfix">
                                        <div class="score w-clearfix">
                                            <span class="ti-user"></span>
                                            <p class="total-score pt-0 pl-2">{{ userInfo.name }}</p>
                                        </div>
                                        <div class="score w-clearfix">
                                            <span class="ti-email"></span>
                                            <p class="total-score pt-0 pl-2">{{ userInfo.email }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="key_skills">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <div class="row">
                                        <div class="col-md-6 col-6">
                                            <h4 class="page-title mt-3">Set Availability </h4>
                                        </div>
                                        <div class="col-md-6 col-6 text-right">                 
                                            <button type="button" class="btn btn-primary btn-sm pull-right mt-3"  data-toggle="modal" data-target="#interviewerAvailability" style="margin-left: 10px;">New</button>                            
                                        </div>
                                    </div>
                                </div>
                            </div>    
                            <hr>
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Start Date</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group date" data-date-format="mm-dd-yyyy">
                                        <datepicker placeholder="Start Date" v-model="eventinfo.startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" ></datepicker>
                                        <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                    </div>
                                    <label v-if="$v.eventinfo.startDate.$error && !$v.eventinfo.startDate.required" class="text-danger">Please select start date</label> 
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-sm-6 col-6">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Start Time</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group date" data-date-format="mm-dd-yyyy">
                                                <b-form-input id="startTime" v-model="eventinfo.startTime" type="time" class="form-control" name="startTime"></b-form-input>
                                            </div>
                                            <label v-if="$v.eventinfo.startTime.$error && !$v.eventinfo.startTime.required" class="text-danger">Please enter start time</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-6 col-6">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>End Time</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group date " data-date-format="mm-dd-yyyy">
                                                <b-form-input id="endTime" v-model="eventinfo.endTime" type="time" class="form-control" name="endTime"></b-form-input>
                                            </div>
                                            <label v-if="$v.eventinfo.endTime.$error && !$v.eventinfo.endTime.required" class="text-danger">Please enter end time</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3 ">
                                <div class="col-md-12 col-sm-12 col-12 text-right">
                                    <button type="button" class="btn btn-primary" style="margin-left: 10px;" @click="resetAvailabilityForm"> Reset</button>
                                    <button type="button" class="btn btn-primary" style="margin-left: 10px;" @click="updateAvailability()"> Save</button>
                                </div>
                            </div>
                        </div> 
                        <hr>                            
                    </div>
                </div>
                <div class="col-md-9 pl-md-2 mb-2">
                    <div class="box-wrapper shadow-sm p-4 mtb-4 bg-white rounded pos-rel">
                        <div class="row" style="margin-bottom: 15px;">
                            <div class="col-md-6 col-sm-12 col-12">
                                <h4 class="page-title">Interviewer Availability</h4>
                            </div>
                        </div>
                        <fullcalendar :calendarEvents="calendarEvents" @event-selected="eventSelected"></fullcalendar>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="interviewerAvailability" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Interviewer Availability</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="card m-b-3">
                            <div class="card-header">
                                <h5 class="modal-title" >Create new availability</h5>
                            </div>
                            <div class="card-body p-2">
                                <div class="basic-block">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">Start Date</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group date" data-date-format="mm-dd-yyyy">
                                                        <datepicker placeholder="Start Date" v-model="eventPopupInfo.startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" ></datepicker>
                                                        <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                    </div>
                                                    <label v-if="$v.eventPopupInfo.startDate.$error && !$v.eventPopupInfo.startDate.required" class="text-danger">Please select start date</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">End Date</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group date" data-date-format="mm-dd-yyyy">
                                                        <datepicker placeholder="End Date" v-model="eventPopupInfo.endDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" ></datepicker>
                                                        <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                    </div>
                                                    <label v-if="$v.eventPopupInfo.endDate.$error && !$v.eventPopupInfo.endDate.required" class="text-danger">Please select end date</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>    
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                  <label for="tc_type">Start Time</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group date " data-date-format="mm-dd-yyyy">
                                                        <b-form-input id="startTime" v-model="eventPopupInfo.startTime" type="time" class="form-control" name="startTime"></b-form-input>
                                                    </div>
                                                    <label v-if="$v.eventPopupInfo.startTime.$error && !$v.eventPopupInfo.startTime.required" class="text-danger">Please enter start time</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">End Time</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group date " data-date-format="mm-dd-yyyy">
                                                        <b-form-input id="endTime" v-model="eventPopupInfo.endTime" type="time" class="form-control" name="endTime"></b-form-input>
                                                    </div>
                                                    <label v-if="$v.eventPopupInfo.endTime.$error && !$v.eventPopupInfo.endTime.required" class="text-danger">Please enter end time</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body p-2">
                                <div class="basic-block">
                                    <div class="row">
                                        <div class="col-sm-12 text-right">
                                            <div class="modal-btns">
                                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                                <button type="button" class="btn btn-primary" style="margin-left: 10px;" @click="resetAvailabilityPopupForm"> Reset</button>
                                                <button type="button" class="btn btn-primary" style="margin-left: 10px;" @click="createAvailability"> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import iziToast from 'izitoast';
    import moment from 'moment';
    import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
    export default {
        data() {
            return {
                userInfo:this.$store.getters.currentUser,
                eventinfo : {
                    id:"",
                    startDate:"",
                    endDate:"",
                    startTime:"",
                    endTime:""
                },
                eventPopupInfo : {
                    startDate:"",
                    endDate:"",
                    startTime:"",
                    endTime:""
                },
                interviewerInfo : {
                    name:"",
                    email:"",
                    mobile:""
                },
                availabilityData : {
                    interviewerId:"",
                    availableStartDate:"",
                    availableEndDate:"",
                    endDate:"",
                    availableTimeFrom:"",
                    availableTimeTo:""
                },
                calendarEvents: []
            }
        },
        validations: {
            eventinfo:{
                startDate:{
                    required
                },
                startTime:{
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(30)
                },
                endTime:{
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(30)
                }
            },
            eventPopupInfo:{
                startDate:{
                    required
                },
                endDate:{
                    required,
                },
                startTime:{
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(30)
                },
                endTime:{
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(30)
                }
            }
        },
        mounted() {
            this.loadAvilabilityList();
        },
        methods: {
            loadAvilabilityList(){
                axios.get(this.$store.getters.getAPIBasePath+'/interviewavailability/availabilityList/' + this.userInfo.profileId)
                .then(response => {
                    let result = response.data;
                    if(!response.status){
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position:'topRight'
                        });
                    }
                    this.calendarEvents=result;
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.CandidAvailLoadErr,
                        position:'topRight'
                    });
                });
            },
            eventSelected(event) {
                this.eventinfo.id=event.id;
                this.eventinfo.startDate=event.start;
                this.eventinfo.endDate=event.end;
                this.eventinfo.startTime=moment(String(event.start)).format('HH:mm');
                this.eventinfo.endTime=moment(String(event.end)).format('HH:mm');
                $('html, body').animate({
                   scrollTop: $(".candidate_info").offset().top
                }, 1000);
            },
            resetAvailabilityForm() {
                this.eventinfo.id="";
                this.eventinfo.startDate="";
                this.eventinfo.endDate="";
                this.eventinfo.startTime="";
                this.eventinfo.endTime="";
            },
            resetAvailabilityPopupForm() {
                this.eventPopupInfo.startDate="";
                this.eventPopupInfo.endDate="";
                this.eventPopupInfo.startTime="";
                this.eventPopupInfo.endTime="";
            },
            createAvailability() {
                this.$v.eventPopupInfo.$touch();
                if (this.$v.eventPopupInfo.$invalid) {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.FieldRequir,
                        position:'topRight'
                    });
                }
                else {
                    let availabilityData = {
                        interviewerId:this.userInfo.profileId,
                        availableStartDate:this.formatDateString(this.eventPopupInfo.startDate),
                        availableEndDate:this.formatDateString(this.eventPopupInfo.endDate),
                        endDate:this.formatDateString(this.eventPopupInfo.endDate),
                        availableTimeFrom:this.eventPopupInfo.startTime,
                        availableTimeTo:this.eventPopupInfo.endTime
                    };
                    console.log(availabilityData);
                    axios.post(this.$store.getters.getAPIBasePath+'/interviewavailability/add/availability',availabilityData)
                    .then(response => {
                        let result = response.data;
                        if(!response.status){
                            iziToast.error({
                                title: 'Error',
                                message: result.message,
                                position:'topRight'
                            });
                        }
                        else {
                            iziToast.success({
                                title: 'Success',
                                message: this.$config.IntAvailCreSuc,
                                position:'topRight'
                            });
                            $('#interviewerAvailability').modal('hide');
                            this.loadAvilabilityList();
                        }
                    })
                    .catch(error => {
                        iziToast.error({
                            title: 'Error',
                            message: this.$config.UnableToCreIntAvailInfo,
                            position:'topRight'
                        });
                    });
                }
            },
            updateAvailability() {
                this.$v.eventinfo.$touch();
                if (this.$v.eventinfo.$invalid) {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.FieldRequir,
                        position:'topRight'
                    });
                }
                else {
                    let availabilityData = {
                        id:this.eventinfo.id,
                        interviewerId:this.userInfo.profileId,
                        availableStartDate:this.formatDateString(this.eventinfo.startDate),
                        availableEndDate:this.formatDateString(this.eventinfo.startDate),
                        endDate:this.formatDateString(this.eventinfo.endDate),
                        availableTimeFrom:this.eventinfo.startTime,
                        availableTimeTo:this.eventinfo.endTime
                    };
                    this.$v.eventinfo.$touch();
                    axios.post(this.$store.getters.getAPIBasePath+'/interviewavailability/edit/availability/' + this.eventinfo.id,availabilityData)
                    .then(response => {
                        let result = response.data;
                        if(!response.status){
                            iziToast.error({
                                title: 'Error',
                                message: result.message,
                                position:'topRight'
                            });
                        }
                        else {
                            iziToast.success({
                                title: 'Success',
                                message: this.$config.IntAvailInfoUpSuc,
                                position:'topRight'
                            });
                            this.loadAvilabilityList();
                        }
                    })
                    .catch(error => {
                        iziToast.error({
                            title: 'Error',
                            message: this.$config.IntAvailInfoUpErr,
                            position:'topRight'
                        });
                    });
                }
            }
        }
    }
</script>