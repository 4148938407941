<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div class="card border-5 mb-3">
                                    <div class="col-md-12 col-sm-12 px-0 about-box">
                                        <div class="col-md-8 px-0 float-left">
                                            <h6 class="m-0 text-left p-2">
                                                <a href="javascript:" @click="getPrevUrl()" title="Back" class="p-2">
                                                    <i class="fa fa-arrow-left"></i>
                                                    <span> Back</span>
                                                </a>
                                            </h6>
                                        </div>
                                        <div class="col-md-4 px-0 pull-right py-2 text-right pr-2">
                                            <router-link class="link" title="View Client Account" :to="{ name: 'viewcorpclientaccount'}"><i class="fa fa-pencil-square-o fa_custom fa-eye px-1"></i></router-link>
                                        </div>
                                    </div>
                                    <div class="user-card pl-5 pr-5 pb-2 pt-3">
                                        <div class="information">
                                            <div class="imgUp">
                                                <div class="logoPreview" v-bind:style="{ backgroundImage: 'url(' + clientInfo.logoPath + ')' }"></div>
                                                <label class="upload-icon"><i class="ti-camera" title="upload logo"></i><input ref="client_add_logo" id="client_logo"" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="upload_photo"></label>
                                            </div>
                                            <div class="name">{{clientInfo.companyName}}</div>
                                            <div class="position"><i class="ti-email"></i> {{clientInfo.email}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card border-5">
                                    <div class="user-card p-0">
                                        <h5 class="about-box m-0 text-left p-2">Company Info</h5>
                                        <div class="p-3 float-left w-100">
                                            <div class="informations">
                                                <span v-if="clientInfo.length == 0" class="badge p-2 mb-1">No records found</span>
                                                <span v-if="clientInfo.phoneNo"><i class="fa fa-phone pr-2" aria-hidden="true"></i>{{clientInfo.phoneNo}}</span>     
                                                <hr>                                          
                                                <span v-if="clientInfo.countryId != null"><i class="ti-location-pin pr-2"></i>  
                                                    <span v-if="clientInfo.countryId != null">{{getCountryName(clientInfo.countryId)}}</span>
                                                    <span v-if="clientInfo.zipCode != null"> - {{clientInfo.zipCode}}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-9">
                        <div class="row no-gutters">
                            <b-tabs v-model="currentClientTab" small card class="tab-list driver-tab-list">
                                <b-tab title="Client Account Details">
                                    <ClientInfo ref="clientinfocomponent"></ClientInfo>
                                </b-tab>
                                <b-tab title="Plan Details">
                                    <Plan ref="planinfocomponent"></Plan>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ClientInfo from '@/components/admin/account/blocks/EditClientAccount.vue';
import Plan from '@/components/admin/account/blocks/EditPlan.vue';

export default {
    computed: {
        accountid() {
            return this.$route.params.accountid;
        }
    },
    components: {
        ClientInfo,
        Plan
    },
    data() {
        return {
            currentClientTab: 0,
            maxtabs: 3,
            clientInfo: {
                firstName: "",
                lastName: "",
                accountName: "",
                companyName: "",
                accountType: "2",
                email: "",
                phoneNo: "",
                zipcode: "",
                country: "US",
                timezone: "",
                logoPath: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png'
            },
            planInfo: {
                viewResumeCount: "",
                downloadResumeCount: "",
                shareResumeCount: "",
                planName: "",
                planId:""
            },
            countrieslist: this.$store.getters.getCountries,
            usstates: this.$store.getters.getUSStates,
            companyId:"",
            ttClientAccountId:"",
        }
    },
    mounted() {
        this.loadByAccountId();
    },
    methods: {
        getPrevUrl() {
            this.$router.go(-1);
        },
        getCountryName: function(countryCode) {
            if (!countryCode)
                return "";
            let country = this.countrieslist.filter(obj => {
                return obj.country_Id === countryCode;
            });
            return country[0].name;
        },
        getStateName: function(stateId) {
            if (!stateId)
                return "";
            let state = this.usstates.filter(obj => {
                return obj.id === stateId;
            });
            return state[0].stateName;
        },
        loadByAccountId() {
            axios.get(this.$store.getters.getAPIBasePath + '/recruiterresource/client/account/view/' + this.accountid)
                .then(response => {
                    let result = response.data;
                    console.log(result);
                    if (!result.status) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$router.push('/candidates');
                    }
                    if (result.clientAccount) {
                        this.companyId = result.clientAccount.companyId,
                        this.ttClientAccountId = result.clientAccount.ttClientAccountId,
                        this.clientInfo = {
                            firstName: result.clientAccount.firstName,
                            lastName: result.clientAccount.lastName,
                            accountName: result.clientAccount.accountName,
                            companyName: result.clientAccount.companyName,
                            accountType: "2",
                            email: result.clientAccount.companyEmail,
                            phoneNo: result.clientAccount.companyPhoneno,
                            zipcode: result.clientAccount.companyZipcode,
                            country: "US",
                            timezone: result.clientAccount.timezone,
                            logoPath: result.clientAccount.logo,
                        },
                        this.planInfo = {
                            viewResumeCount:  result.clientAccount.viewResumeCount,
                            downloadResumeCount:  result.clientAccount.downloadResumeCount,
                            shareResumeCount:  result.clientAccount.shareResumeCount,
                            planName: result.clientAccount.planName,            
                            planId: (result.clientAccount.planId == 0 ? '' : result.clientAccount.planId)
                        }  
                    }
                    //this.clientAccount = result.clientAccount;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        upload_photo(event) {
            if (event.target.files[0] != '') {
                let formData = new FormData();
                formData.append('file', event.target.files[0]);
                formData.append('companyId', this.companyId);
                formData.append('ttClientAccountId', this.ttClientAccountId);
                axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/upload/company/logo', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        iziToast.success({
                            title: 'Success',
                            message: response.data.message,
                            position: 'topRight'
                        });this.loadByAccountId();
                    })
                    .catch(error => {
                        iziToast.error({
                            title: 'Error',
                            message: response.data.message,
                            position: 'topRight'
                            });
                    });
                    }     
        },

    }

}

</script>
