<template>
    <div id="main">
        <div class="container-fluid" id="quick-search">
            <div class="col-md-12 col-sm-6">
                <div class="col-sm-12 h-100 d-table">
                    <div class="d-table-cell align-middle login_div">
                        <div class="col-md-4 offset-md-4 col-sm-12">
                            <div class="referral-register-block box-wrapper p-5 mb-4 bg-white rounded pos-rel mt-80">
                                <h3 class="text-color text-left">Insufficient Data</h3>
                                Please fillup missing fields
                                <hr class="hrline-border">
                                <form>
                                    </br>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="input-group required-field-block">
                                                            <input type="text" name="firstName" disabled placeholder="First Name" maxlength="45" size="30" v-model="logininfo.firstName" class="form-control box-none">
                                                            <span class="required-icon"></span>
                                                        </div>
                                                        <label v-if="$v.logininfo.firstName.$error && !$v.logininfo.firstName.required" class="text-danger">Please enter First Name</label>
                                                        <label v-if="$v.logininfo.firstName.$error && !$v.logininfo.firstName.minLength" class="text-danger">First Name must have at least {{$v.logininfo.firstName.$params.minLength.min}} letters.</label>
                                                        <label v-if="$v.logininfo.firstName.$error && !$v.logininfo.firstName.maxLength" class="text-danger">First Name must not exceed {{$v.logininfo.firstName.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="input-group">
                                                            <input type="text" name="lastName" disabled placeholder="Last Name" maxlength="45" size="30" v-model="logininfo.lastName" class="form-control box-none">
                                                        </div>                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <input type="text" class="form-control box-none" id="nickName" placeholder="Nick Name" v-model="referralinfo.nickName">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="input-group required-field-block">
                                                            <select class="form-control box-none col-sm-3  max-w-45 pl-0 pr-0 " v-model="referralinfo.referralContact.countryCode">
                                                                <option value="+1">+1</option>
                                                                <option value="+91">+91</option>
                                                            </select>
                                                            <input-mask class="form-control box-none" v-model="referralinfo.referralContact.mobile" mask="(999)-999-9999" placeholder="Phone No" :alwaysShowMask="false" maskChar="_"></input-mask>
                                                            <span class="required-icon"></span>
                                                        </div>
                                                    </div>
                                                    <label v-if="$v.referralinfo.referralContact.mobile.$error && !$v.referralinfo.referralContact.mobile.required" class="text-danger">Please enter Phone No.</label>
                                                    <label v-if="$v.referralinfo.referralContact.countryCode.$error && !$v.referralinfo.referralContact.countryCode.requiredIf" class="text-danger">Please enter phone number with country code</label>   

                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="input-group required-field-block">
                                                            <input type="email" name="primaryEmail" placeholder="Email" maxlength="80" v-model.lazy="logininfo.email" class="Submitaddress form-control box-none" :class="{ 'is-invalid': $v.logininfo.email.$error }" @blur="$v.logininfo.email.$touch">
                                                            <span class="required-icon"></span>
                                                        </div>
                                                        <label v-if="$v.logininfo.email.$error && !$v.logininfo.email.required" class="text-danger pl-4">Please enter Email</label>
                                                        <label v-if="$v.logininfo.email.$error && !$v.logininfo.email.email" class="text-danger pl-4">Please enter a valid email</label>
                                                        <label v-if="$v.logininfo.email.$error && !$v.logininfo.email.emailexists && $v.logininfo.email.email && $v.logininfo.email.required" class="text-danger pl-4">Email already exists</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </br>
                                    </br>
                                    <!-- <div class="col-md-12">
                                        <vue-recaptcha ref="recaptcha" sitekey="6LcroP8dAAAAAEoRtHk0LaYM5ZO3Ukva_RoIk0lt" secretkey="6LcroP8dAAAAAD647YPFwUJTpR_y50jxgJUyNRVC" :loadRecaptchaScript="true" badge="inline" @verify="markRecaptchaAsVerified"></vue-recaptcha>
                                        <div v-if="!this.form.robot" class="errorcolor">{{form.errorMessage}}</div>
                                    </div> -->
                                    <div class="bg-slategray lt wrap-reset text-left mt-1" style="background-color:#fff !important;">
                                        <button type="button" @click="doServerAuthentication(logininfo)" class="btn btn-primary" style="float: right;">Continue</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs, helpers, requiredIf } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {
    data() {
        return {
            logininfo: {
                socialLoginId: this.$route.params.socialData.socialLoginId,
                firstName: this.$route.params.socialData.firstName,
                lastName: this.$route.params.socialData.lastName,
                socialLoginProvider: this.$route.params.socialData.socialLoginProvider,
                userType: this.$route.params.socialData.userType
            }
        }
    },
    mounted() {
    },
    validations: {
        logininfo: {
            firstName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },           
            email: {
                required,
                email
                // emailexists(email) {
                //     if (email === '') return true;
                //     let ajaxdata = { email: email };
                //     return axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/public/checkemailavailability', ajaxdata)
                //         .then(response => {
                //             if (response.data.status) {
                //                 return true;
                //             }
                //             return false;
                //         })
                //         .catch(error => {
                //             return false;
                //         });
                // }
            }
        }
    },
    methods: {
        doServerAuthentication(socialData) {
            console.log("socialData", socialData);
            if(socialData.email==null)
                this.$router.push({ name: "intermediatepage", params: { socialData : socialData } });
            axios
            .post(
            this.$store.getters.getAPIBasePath +
                "/userresource/social/authenticate",
            socialData
            )
            .then((response) => {
                let result = response.data;
                const defaultredirects = this.$store.getters.getDefaultRedirects;
                console.log("Result...............", result);
                if (result.result) {
                    this.$store.dispatch("setupLogin", result).then(() => {
                    console.log("setupLogin", result);
                    console.log(result.data.userType);
                    //this.$router.push({ name: "candidatedashboard" });
                    if (result.data.userType == "candidate") {
                        this.$router.push({ name: "candidatedashboard" });
                        //this.$router.push({ name: "candidatedashboard", params: { jobId: 1 } });
                    }
                    else if(result.data.userType == "referral") {
                        console.log(this.login.jobId);
                        this.$router.push({ path: defaultredirects.referral, params: { jobId: this.login.jobId } });
                        //this.$router.push({ path: defaultredirects.referral });
                    }
                    });
                } else {
                    iziToast.warning({
                    message: result.message,
                    position: "topRight",
                    });
                }
            })
            .catch((error) => {
            console.log(error);
            iziToast.error({
                title: "Error Occured",
                message: this.$config.TryAg,
                position: "topRight",
            });
            });
        }
    }
}
</script>
