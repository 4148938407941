<template>
	<div class="email-account-settings">
		<div class="row col-md-12 mx-0 px-0">
			<div class="col-md-3 offset-md-4">
				<h5 class="text-center py-3">Edit Email Account</h5>
			</div>
			<div class="col-md-2 offset-md-3 px-0 align-self-center">
				<a href="javascript:" class="pull-right btn-back text-blue" @click="$emit('closeAction')">
					<h5><i class="ti-arrow-left"></i> Back</h5>
				</a>
				<a href="javascript:" class="pull-right btn-back text-blue px-4" @click="$emit('setAction','view')">
					<h5><i class="ti-eye"></i> View</h5>
				</a>
			</div>
		</div>
		<div class="card user-information">
			<div class="card-header">User Information</div>
			<div class="card-body">
				<div class="row col-md-12 py-2">
					<div class="col-md-4">
						<div class="row col-md-12">
							<label>Name</label>
						</div>
						<div class="row col-md-12">
							<input class="form-control" type="text" name="user-name" v-model="accountParams.fromName" @blur="$v.accountParams.fromName.$touch" />
						</div>
						<div class="row col-md-12">
							<label v-if="$v.accountParams.fromName.$error && !$v.accountParams.fromName.required" class="text-danger">Name is required</label>
							<label v-if="$v.accountParams.fromName.$error && !$v.accountParams.fromName.minLength" class="text-danger">Name must have at least {{$v.accountParams.fromName.$params.minLength.min}} letters.</label>
							<label v-if="$v.accountParams.fromName.$error && !$v.accountParams.fromName.maxLength" class="text-danger">Name must not exceed {{$v.accountParams.fromName.$params.maxLength.max}} letters.</label>
						</div>
					</div>
					<div class="col-md-4 offset-md-1">
						<div class="row col-md-12">
							<label>Email Address</label>
						</div>
						<div class="row col-md-12">
							<input class="form-control" type="email" name="user-email" v-model="accountParams.fromEmail" @blur="$v.accountParams.fromEmail.$touch" />
						</div>
						<div class="row col-md-12">
							<label v-if="$v.accountParams.fromEmail.$error && !$v.accountParams.fromEmail.required" class="text-danger">Email is required</label>
							<label v-if="$v.accountParams.fromEmail.$error && !$v.accountParams.fromEmail.email" class="text-danger">Email is not valid</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card server-information">
			<div class="card-header">Server Information</div>
			<div class="card-body">
				<div class="row col-md-12 py-2">
					<div class="col-md-4">
						<div class="row col-md-12">
							<label>Incoming Host</label>
						</div>
						<div class="row col-md-12">
							<input class="form-control" type="text" name="server-host" v-model="accountParams.incomingHost"/>
						</div>
					</div>
					<div class="col-md-4 offset-md-1">
						<div class="row col-md-12">
							<label>Incoming Port</label>
						</div>
						<div class="row col-md-12">
							<input class="form-control" type="number" name="server-port" v-model="accountParams.incomingPort" />
						</div>
						<div class="row col-md-12">
							<label v-if="$v.accountParams.incomingPort.$error && !$v.accountParams.incomingPort.required" class="text-danger">Incoming Port is required</label>
							<label v-if="$v.accountParams.incomingPort.$error && (!$v.accountParams.incomingPort.minLength || !$v.accountParams.incomingPort.maxLength)" class="text-danger">Port is not valid</label>
						</div>
					</div>
				</div>
				<div class="row col-md-12 py-2">
					<div class="col-md-4">
						<div class="row col-md-12">
							<label>Outgoing Mail Server (SMTP)</label>
						</div>
						<div class="row col-md-12">
							<input class="form-control" type="text" name="server-host" v-model="accountParams.host" />
						</div>
						<!-- <div class="row col-md-12">
							<label v-if="$v.accountParams.host.$error && !$v.accountParams.host.required" class="text-danger">Host is required</label>
							<label v-if="$v.accountParams.host.$error && $v.accountParams.host.required && !$v.accountParams.host.validateIPaddress" class="text-danger">Host is not valid</label>
						</div> -->
					</div>
					<div class="col-md-4 offset-md-1">
						<div class="row col-md-12">
							<label>Port</label>
						</div>
						<div class="row col-md-12">
							<input class="form-control" type="number" name="server-port" v-model="accountParams.port" @blur="$v.accountParams.port.$touch" />
						</div>
						<div class="row col-md-12">
							<label v-if="$v.accountParams.port.$error && !$v.accountParams.port.required" class="text-danger">Port is required</label>
							<label v-if="$v.accountParams.port.$error && (!$v.accountParams.port.minLength || !$v.accountParams.port.maxLength)" class="text-danger">Port is not valid</label>
						</div>
					</div>
				</div>
				<div class="row col-md-12 py-2">
					<div class="col-md-4">
						<div class="row col-md-12">
							<label>Username</label>
						</div>
						<div class="row col-md-12">
							<input class="form-control" type="text" name="server-username" v-model="accountParams.userName" @blur="$v.accountParams.userName.$touch" />
						</div>
						<div class="row col-md-12">
							<label v-if="$v.accountParams.userName.$error && !$v.accountParams.userName.required" class="text-danger">Username is required</label>
							<label v-if="$v.accountParams.userName.$error && !$v.accountParams.userName.minLength" class="text-danger">Username must have at least {{$v.accountParams.userName.$params.minLength.min}} letters</label>
						</div>
					</div>
					<div class="col-md-4 offset-md-1">
						<!-- <div class="row col-md-12">
							<label>Password</label>
						</div>
						<div class="row col-md-12">
							<input class="form-control" type="password" name="server-password" v-model="accountParams.password" @blur="$v.accountParams.password.$touch" />
						</div>
						<div class="row col-md-12">
							<label v-if="$v.accountParams.password.$error && !$v.accountParams.password.required" class="text-danger">Password is required</label>
							<label v-if="$v.accountParams.password.$error && $v.accountParams.password.required && !$v.accountParams.password.goodPassword" class="text-danger">Password must contain a small letter, capital letter, number and the length must be 8</label>
						</div> -->
						<div class="row col-md-12">
							<a href="javascript:" v-b-modal.changePassword><label class="pt-4 hover-hand">Change Password</label></a>
						</div>
					</div>
				</div>
				<div class="row col-md-12 py-2">
					<div class="col-md-12">
						<input type="checkbox" id="isDefault" v-model="accountParams.isDefault" @change="deselectPrimaryAccountConfirm">
						<label class="p-3" for="isDefault">Is Primary Account?</label>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-12 py-2 px-0 text-right">
			<a href="javascript:" class="btn btn-primary" @click="updateEmailAccountSettings">Save</a>
		</div>
		<Dialog id="removePrimaryAccount" :onYes="deselectPrimaryAccount" :onNo="selectPrimaryAccount" title="Confirm" message="Are you sure to remove Primary Account?" />
		<b-modal ref="changePassword" id="changePassword" :hide-footer="true" title="Update Password">
			<div class="col-md-10 offset-md-1">
				<div class="row col-md-12 mx-0">
					<div class="row col-md-12">
						<label>Current Password</label>
					</div>
					<div class="row col-md-12">
						<input class="form-control" type="password" name="old-server-password" v-model="password.oldPassword" @blur="$v.password.oldPassword.$touch"/>
					</div>
					<div class="row col-md-12">
						<label class="text-danger" v-if="$v.password.oldPassword.$error && !$v.password.oldPassword.required">Current Password is required</label>
					</div>
				</div>
				<div class="row col-md-12 mx-0">
					<div class="row col-md-12">
						<label>New Password</label>
					</div>
					<div class="row col-md-12">
						<input class="form-control" type="password" name="new-server-password" v-model="password.newPassword" @blur="$v.password.newPassword.$touch" />
					</div>
					<div class="row col-md-12">
						<label v-if="$v.password.newPassword.$error && !$v.password.newPassword.required" class="text-danger">Password is required</label>
						<!-- <label v-if="$v.password.newPassword.$error && $v.password.newPassword.required && !$v.password.newPassword.goodPassword" class="text-danger">Password must contain a small letter, capital letter, number and the length must be 8</label> -->
					</div>
				</div>
				<div class="row col-md-12 mx-0">
					<div class="row col-md-12">
						<label>Confirm Password</label>
					</div>
					<div class="row col-md-12">
						<input class="form-control" type="password" name="confirm-server-password" v-model="password.confirmPassword" @blur="$v.password.confirmPassword.$touch" />
					</div>
					<div class="row col-md-12">
						<label class="text-danger" v-if="$v.password.confirmPassword.$error && !$v.password.confirmPassword.required">Confirm Password is required</label>
						<label class="text-danger" v-else-if="$v.password.confirmPassword.$error && !$v.password.confirmPassword.sameAsPassword">Passwords must match</label>
					</div>
				</div>
				<div class="row col-md-11 justify-content-end px-0 py-3">
					<a href="javascript:" class="btn btn-success" @click="changePassword">Submit</a>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required, minLength, maxLength, email, sameAs } from "vuelidate/lib/validators";
	import iziToast from 'izitoast';
	export default {
		props: {
			settingsEmailAccountId: Number
		},
		data(){
			return {
				elements: {
					submitted: false
				},
				accountParams: {
					fromName: "",
					fromEmail: "",
					incomingPort:"",
					incomingHost:"",
					host: "",
					port: "",
					userName: "",
					password: "",
					outgoingMailServer: "",
					incomingMailServer: "",
					isDefault: null
				},
				password: {
					id: null,
					oldPassword: "",
					newPassword: "",
					confirmPassword: ""
				}
			}
		},
		validations: {
			accountParams: {
				fromName: { required, minLength: minLength(4), maxLength: maxLength(100) },
				fromEmail: { required, email },
				/*host: {
					required,
					validateIPaddress(hostName){
						if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(hostName)) {
							return true;
						}
						return false;
					}
				},*/
				port: { required, minLength: minLength(2), maxLength: maxLength(4) },
				incomingPort: { required, minLength: minLength(2), maxLength: maxLength(4) },
				userName: { required, minLength: minLength(6) }
			},
			password: {
				oldPassword: { required },
				newPassword: {
					required,
					/*goodPassword:(password) => {
						return password.length >= 8 &&
						/[a-z]/.test(password) &&
						/[A-Z]/.test(password) &&
						/[0-9]/.test(password)
					}*/
				},
				confirmPassword: { required, sameAsPassword: sameAs('newPassword') }
			}
		},
		mounted(){
			this.viewSettingsEmailAccount();
		},
		methods: {
			validateEmailAccountSettings: function(){
				this.$v.accountParams.$touch();
				if (this.$v.accountParams.$invalid) {
					return false;
				}
				return true;
			},
			validatePasswordSettings: function(){
				this.$v.password.$touch();
				if (this.$v.password.$invalid) {
					return false;
				}
				return true;
			},
			updateEmailAccountSettings: function(){
				this.elements.submitted = true;
				if(!this.validateEmailAccountSettings()){
					iziToast.error({title: 'Error', message: this.$config.PlsFillFields, position: 'topRight'});
					return false;
				}
				axios.post(this.$store.getters.getAPIBasePath+'/emailresource/update/email/account',this.accountParams)
				.then(response => {
					let result = response.data;
					if (!response.status) {
						iziToast.error({title: 'Error', message: result.message, position: 'topRight'});
					}
					iziToast.success({title: 'Success', message: result.message, position: 'topRight'});
					this.$emit('closeAction');
				})
				.catch(error => {
					iziToast.error({title: 'Error', message: this.$config.LoadTempListErr, position: 'topRight'});
				});
			},
			viewSettingsEmailAccount: function(){
				axios.get(this.$store.getters.getAPIBasePath+'/emailresource/view/email/account/'+this.settingsEmailAccountId)
				.then(response => {
					let result = response.data;
					if (!response.status) {
						iziToast.error({title: 'Error', message: result.message, position: 'topRight'});
					}
					this.accountParams = result.settingsEmailAccountViewData;
					this.password.id = this.accountParams.id;
				})
				.catch(error => {
					iziToast.error({title: 'Error', message: this.$config.LoadTempListErr, position: 'topRight'});
				});
			},
			deselectPrimaryAccountConfirm: function(e){
				e.preventDefault();
				if(!this.accountParams.isDefault){
					$('#removePrimaryAccount').modal('show');
				}
			},
			deselectPrimaryAccount: function(){
				this.accountParams.isDefault = false;
				$('#removePrimaryAccount').modal('hide');
			},
			selectPrimaryAccount: function(){
				this.accountParams.isDefault = true;
				$('#removePrimaryAccount').modal('hide');
			},
			changePassword: function(){
				if(!this.validatePasswordSettings()){
					// iziToast.error({title: 'Error', message: "Invalid data provided", position: 'topRight'});
					return false;
				}
				axios.post(this.$store.getters.getAPIBasePath+'/emailresource/update/email/account/password',this.password)
				.then(response => {
					let result = response.data;
					if (!response.status) {
						iziToast.error({title: 'Error', message: result.message, position: 'topRight'});
					}
					this.clearPasswordForm();
					iziToast.success({title: 'Success', message: result.message, position: 'topRight'});
				})
				.catch(error => {
					iziToast.error({title: 'Error', message: this.$config.SomeTryAg, position: 'topRight'});
				});
			},
			clearPasswordForm: function(){
				this.password.oldPassword = "";
				this.password.newPassword = "";
				this.password.confirmPassword = "";
			}
		}
	}
</script>