<template>
    <div id="interviewer-edit-block" class="interviewer-edit-block mt-70">
        <div class="container-fluid driver-model" id="main-data">
            <div class="row">
                <div class="col-md-3">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card border-5 mb-3">
                                <div class="col-md-12 col-sm-12 px-0 about-box">
                                    <div class="col-md-10 px-0 float-left">
                                        <h6 class="m-0 text-left p-2">
                                            <a href="javascript:" @click="$router.go(-1)" title="Back" class="p-2">
                                                <i class="fa fa-arrow-left"></i>
                                                <span> Back</span>
                                            </a>
                                        </h6>
                                    </div>
                                    <div class="col-md-2 px-0 pull-right py-2">
                                        <a href="javascript:" @click="elements.showEmailTools=true" title="Email To"><i class="ti-email px-1"></i></a>
                                        <router-link title="Edit Member" :to="{ name: 'editteammember', params: {recruiterid: profileId,ismember:true} }"><i class="fa fa-pencil-square-o fa_custom fa-lg px-1"></i></router-link>
                                    </div>
                                </div>
                                <div class="user-card pl-5 pr-5 pt-3">
                                    <div class="information">
                                        <div class="imgUp">
                                            <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' +  (ProfileData.image===''?ProfileData.imagePath:ProfileData.image) + ')' }"></div>
                                        </div>
                                        <div class="name">{{ProfileData.firstName}} {{ProfileData.lastName}}</div>
                                        <div>{{ProfileData.position}} </div>
                                        <div v-if="ProfileData.email" class="position"><i class="ti-email"></i> {{ProfileData.email}}</div>
                                        <div v-if="ContactInfo.mobileNo" class="position"><i class="ti-mobile"></i> {{ContactInfo.mobileNo}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card border-5">
                                <div class="user-card p-0">
                                    <h5 class="about-box m-0 text-left p-2">About Me</h5>
                                    <div class="p-3 float-left w-100">
                                        <h5>Company</h5>
                                        <div class="informations">
                                            <span v-if="ProfileData.company == ''" class="badge p-2 mb-1">No records found</span>
                                            <span v-if="ProfileData.companyName != ''">{{ProfileData.company}}</span>
                                        </div>
                                        <div v-if="ContactInfo!== null" class="w-100 float-left">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="box-wrapper shadow-sm bg-white rounded pos-rel main-search">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row no-gutters">
                                    <div class="col-md-12 pop-tab">
                                        <div class="driver-model">
                                            <div class="row no-gutters">
                                                <b-tabs v-if="!elements.showEmailTools" v-model="currentTab" small card class="tab-list driver-tab-list tab-bg">
                                                    <b-tab title="Primary Info">
                                                        <PersonalInfo :ProfileData=ProfileData :ismember="$route.params.ismember" ref="personalinfocomponent"></PersonalInfo>
                                                    </b-tab>
                                                    <b-tab title="Contact Info">
                                                        <ContactInfo :ContactInfo=ContactInfo ref="contactInfocomponent"></ContactInfo>
                                                    </b-tab>
                                                    <b-tab title="Settings">
                                                        <Settings :settingsInfo="settingsInfo" :profileId="profileId" @loadMember="loadMember" ref="settingscomponent"></Settings>
                                                    </b-tab>
                                                    <b-tab title="Activity Log">
                                                        <ActivityLog :profileId="profileId" :ActivityLog=ActivityLog ref="incidenthistorycomponent">
                                                        </ActivityLog>
                                                    </b-tab>
                                                    <b-tab title="Caller Id">
                                                    <Inprogress/>
                                                        <!-- <AddCallerId  ref="incidenthistorycomponent"></AddCallerId> -->
                                                    </b-tab>
                                                     <b-tab title="Plan Info">
                                                        <plan :PlanData="PlanData" ref="planInfocomponent"></plan>
                                                    </b-tab>
                                                </b-tabs>
                                                <EmailTools v-if="elements.showEmailTools" :mailTo="{email: ProfileData.email, id: ProfileData.id, name: ProfileData.firstName+' '+ProfileData.lastName, userType: 'recruiter'}" @closeEmailTools="elements.showEmailTools=false"></EmailTools>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import PersonalInfo from './blocks/viewrecruiter/PersonalInfo';
import ContactInfo from './blocks/viewrecruiter/ContactInfo';
import ActivityLog from './blocks/viewrecruiter/ActivityLog';
import AddCallerId from './blocks/viewrecruiter/AddCallerId';
import Inprogress from '@/components/candidates/blocks/viewcandidate/Inprogress';
import EmailTools from '@/components/recruiters/blocks/tools/EmailTools';
import Settings from '@/components/recruiters/blocks/editrecruiter/Settings';
import Plan from './blocks/viewrecruiter/Plan';
export default {
    components: {
        PersonalInfo,
        AddCallerId,
        Inprogress,
        ContactInfo,
        ActivityLog,
        EmailTools,
        Settings,
        Plan
    },
    computed: {
        recownerid() {
            return this.$store.getters.currentUser.recruitOwner;
        }
    },
    data() {
        return {
            profileId: Number(this.$route.params.recruiterid),
            elements: {
                showAvailabilityInfo: false,
                showEmailTools: false
            },
            currentTab: 0,
            ProfileData: {
                id: 0,
                recruiterCode: '',
                firstName: '',
                lastName: '',
                nickName: '',
                email: '',
                dateOfBirth: '',
                gender: '',
                position: '',
                image: '',
                imageName: '',
                imagePath: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
                image: '',
                industryId: null,
                company: '',
                role: '',
            },
            PlanData: {
                planName: '',
                viewResumeCount: '',
                downloadResumeCount: '',
                shareResumeCount: '',
                expiryDate:''
            },
            ContactInfo: Object,
            ActivityLog: Object,
            settingsInfo: {
                timezone: "",
                accessLevel: 1
            },
            colours: ['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info']
        }
    },
    mounted() {
        this.loadMember();
    },
    methods: {

        loadMember: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/recruiterresource/view/recruiter/' + this.profileId)
                .then(response => {
                    if (response.data.status) {
                        let memberData = response.data.recruiter;
                        this.ProfileData.id = memberData.recruiterId;
                        this.ProfileData.recruiterCode = memberData.recruiterCode;
                        this.ProfileData.firstName = memberData.firstName;
                        this.ProfileData.lastName = memberData.lastName;
                        this.ProfileData.nickName = memberData.nickName;
                        this.ProfileData.email = memberData.email;
                        this.ProfileData.dateOfBirth = memberData.dateOfBirth;
                        this.ProfileData.gender = memberData.gender;
                        this.ProfileData.role = memberData.role;
                        this.ProfileData.position = memberData.position;
                        this.ProfileData.imageName = memberData.imageName;
                        this.ProfileData.imagePath = (memberData.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : memberData.imagePath);
                        this.ProfileData.image = (memberData.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : memberData.imagePath);
                        this.ProfileData.industryId = memberData.industryId;
                        this.ProfileData.company = memberData.companyName;
                        this.ContactInfo = Object.assign({}, memberData.recruiterContact);
                        this.settingsInfo.timezone = memberData.timezone == null ? '' : memberData.timezone;
                        this.PlanData.planName = memberData.planName;
                        this.PlanData.viewResumeCount =  memberData.viewResumeCount;
                        this.PlanData.downloadResumeCount =  memberData.downloadResumeCount;
                        this.PlanData.shareResumeCount =  memberData.shareResumeCount;
                        this.PlanData.expiryDate =  memberData.expiryDate;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        updateSkills: function(Skills) {
            let self = this;
            self.TechSkills = [];
            $.each(Skills, function(key, value) {
                if (value.technologySkillSet != null)
                    self.TechSkills.push(value.technologySkillSet);
            });
        }
    }
}

</script>
