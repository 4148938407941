<template>
	<header id="main-header" class="navbar navbar-expand-md navbar-dark fixed-top">
		<div class="container-fluid">
			<div class="fluid-box d-block w-100">
				<div class="row">
					<div class="col-sm-12">
						<nav class="navbar-expand-lg navbar-light p-0">
							<div class="row">
								<div class="col-md-4 col-sm-4">
									<router-link v-if="false" class="nav-link" :to="{ name: 'home' }"   @click.native="setActive('home')">
										<img class="img-responsive mt-3" alt="images" src="@/assets/images/talentturbo1.png"/>
									</router-link>

									<a :href="webAppURL">
										<img class="img-responsive mt-3" alt="images" src="@/assets/images/talentturbo1.png"/>
									</a>
								</div>
								<div class="col-md-8 col-sm-8">
									<div class="collapse navbar-collapse pull-right" id="demo-navbar">
										<ul class=" mr-auto ul-horizontal main-menu mb-0">
											<li v-if="false"  @click="setActive('home')" class="nav-item dropdown ml-3 mr-3" :class="{ active: activeTab=='home' }">
												<router-link class="nav-link" :to="{ name: 'home' }" >Home</router-link>
											</li>
											<li v-if="false"  @click="setActive('services')" class="nav-item dropdown ml-3 mr-3" :class="{ active: activeTab=='services' }">
												<router-link class="nav-link" :to="{ name: 'services' }" >Services</router-link>
											</li>
											<li v-if="false" @click="setActive('pricing')" class="nav-item dropdown ml-3 mr-3" :class="{ active: activeTab=='pricing' }">
												<router-link class="nav-link" :to="{ name: 'pricing' }" >Pricing</router-link>
											</li>
											<!-- <li  @click="setActive('jobs')" class="nav-item dropdown ml-3 mr-3" :class="{ active: activeTab=='jobs' }">
												<a class="nav-link dropdown-toggle" href="http://example.com" id="jobs-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Jobs</a>
												<ul class="dropdown-menu" aria-labelledby="jobs-dropdown">
													<li><a class="dropdown-item" href="#">List Jobs</a></li>
													<li>
														<router-link class="nav-link" :to="{ name: 'addjob-publicportal' }" >Add Job</router-link>
													</li>
													<li>
														<router-link class="nav-link" :to="{ name: 'listjobs-publicportal' }" >List Jobs</router-link>
													</li>
												</ul>
											</li> -->
											<li  @click="setActive('contact')" class="nav-item dropdown ml-3 mr-3" :class="{ active: activeTab=='contact' }">
												<a class="nav-link" @click="contactUs" href="Javascript:void(0);">Contact Us</a>
											</li>
											<li  @click="setActive('homelogin')" class="nav-item dropdown ml-3 mr-3" :class="{ active: activeTab=='homelogin' }">									
												<a class="nav-link" @click="performLogin" href="Javascript:void(0);">Login</a>
											</li>
										</ul>										
									</div>
								</div>
							</div>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>
<script>
	export default {
		data(){
			return {
				activeTab: 'home',
				webAppURL: this.$store.getters.getWebAppURL,
			}
		},
		mounted(){
			this.setActive(this.$route.name);			
		},
		methods: {
			contactUs() {
            	window.location.href = process.env.VUE_APP_MAIN_URL + "contactus";
        	},
			setActive:function(tab){		
				this.activeTab = tab;
				console.log(this.activeTab);
			},

		}
	}
</script>