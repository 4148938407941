<template>
    <div class="kpis reports text-left">
        <div class="row">
            <div class="col-sm-12 p-10">
                <div id="recruiter-settings" class="recruiter settings mt-10">
                    <div class="container-fluid" id="quick-search">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="text-center">Resumes loaded by Recruiters</h5>
                            </div>
                        </div>
                        <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <h4 class="page-title">Filter</h4>
                                </div>
                                <div class="col-md-6 col-sm-6 col-6">
                                    <ul class="ul-horizontal box-tools text-right mb-0">
                                        <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :title="isExpand?'Hide':'Expand'" :class="isExpand?'ti-angle-up':'ti-angle-down'" class="hover-hand"></span></a></li>
                                        <li><i class="ti-close hover-hand px-2" @click="$emit('setSubTab',0);$emit('updateLogActivity');" title="Close"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <form id="main-search" :class="isExpand?'d-block':'d-none'">
                                <div class="row form-group ten-columns">
                                    <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Recruiter Name</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <!-- <input type="text" name="userName" maxlength="50" value="" id="userName" class="form-control" v-model="search.params.userName"> -->
                                                    <select v-model="search.params.recruiterId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in recruitersList" :value="info.id">{{info.recruiterName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Start Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledStartDates" placeholder="Start Date" name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.startDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>End Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledendDates" placeholder="End Date" name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.endDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label style="visibility: hidden;"></label>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="button" @click="resetLogSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                                <button type="button" @click="findResumeReport" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Go</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="load-indicator"></div>
                        </div>
                    </div>
                    <div class="container-fluid" id="main-data">
                        <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                            <div class="row" style="margin-bottom: 15px;">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <h4 class="page-title">Summary
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                            <div class="dropdown">
                                                <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                                <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                    <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                                </div>
                                            </div>
                                        </span>
                                    </h4>
                                </div>
                                 <div class="col-md-6 d-none d-sm-none d-md-block">
                                        <span class="float-right" @click="resumeLoadedByRecruiters()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive" style="min-height: 178px;">
                                        <table class="table table-borderless all-table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th class="" scope="col">Sl.No</th>
                                                    <th class="" scope="col">Recruiter Name</th>
                                                    <th class="" scope="col">Resume Count</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="resumeReport.length==0">
                                                    <td colspan="8" class="text-center">No data found</td>
                                                </tr>

                                                <template v-for="(resumeinfo, index) in resumeReport">
                                                <tr>


                                                <td>
                                                <a href="javascript:" class="expand" data-targetopen="panel1">
                                                    <span @click="getResumeLoadedCandidates(resumeinfo.userId, resumeinfo.recruiterId)"><i :class="(currentIndex==resumeinfo.userId) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
                                                    </span>
                                                </a>
                                                </td>
                                                    <td>{{((pagination.currentPage-1) * (pagination.rowsPerPage)) + (index+1)}}</td>
                                                    <td class="">{{ resumeinfo.recruiterName }}</td>
                                                    <td class="">{{ resumeinfo.candCount }}</td>
                                                </tr>


                                                <tr v-if="currentIndex==resumeinfo.userId">
                                            <td colspan="5">
                                                <div class="table-responsive recscroll">
                                                    <table class="table table-borderless all-table">
                                                        <thead>
                                                            <tr>
                                                                <!-- <th scope="col">Sl.No</th> -->
                                                                <th class="text-left" scope="col">Candidate Code</th>
                                                                <th class="text-left" scope="col">Candidate Name</th>
                                                                <th v-if="candContactAccess==1" class="text-left" scope="col">Email</th>
                                                                <th class="text-left" scope="col">Uploaded Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-for="(candInfo, index) in resumeLoadedCandidates">
                                                                <tr>
                                                                    <!-- <td>{{((pagination.currentPage-1) * (search.limit)) + (index+1)}}</td> -->
                                                                    <td class="text-left">{{ candInfo.candidateCode }}</td>
                                                                    <td class="text-left">{{ candInfo.candidateName }}</td>
                                                                    <td v-if="candContactAccess==1" class="text-left">{{ candInfo.email }}</td>
                                                                    <td class="text-left">{{ candInfo.createdDate | formatDate }}</td>
                                                                </tr>
                                                            </template>
                                                            <tr v-if="resumeLoadedCandidates.length==0">
                                                                <td colspan="6" align="center">No Candidates found</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>



                                               </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <b-pagination v-if="resumeReport.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadresumeReports()"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    data() {
        return {
            isExpand: false,
            resumeReport: [],
            //recruitersList: this.$store.getters.getRecruiters, 
            recruitersList: this.$store.getters.getRecruiterList, 
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            searchlimit: this.$store.state.searchlimit,
            search: {
                params: {
                    recruiterId: "",
                    startDate: "",
                    endDate: ""
                },
            },
            coldisplay: {
                jobTitle: true,
                jobCode: true,
                client: true,
                industry: true,
                position: false
            },
            currentIndex: null,
            browsepagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            resumeLoadedCandidates: [],
            candContactAccess: localStorage.getItem('candidateContactAccess'),
            disabledStartDates: {
                from: new Date()
            
            },
            disabledendDates:{
                from: new Date(),
                to: ''
            }

        }
    },
    mounted() {
        this.$emit('updateLogActivity');
        this.$emit('addLogActivity','Resume Report');
        this.$store.commit('refreshPagination');
        this.loadDate();
        this.loadresumeReports();
    },
     watch: {
        'search.params.startDate': {
            handler: function(value) {
                this.disabledendDates.to = new Date(value);
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        resetLogSearch() {
            this.search.params.recruiterId = "";
            this.search.params = {
                    recruiterId: "",
                    startDate: "",
                    endDate: ""
                }
            this.loadDate();
            this.disabledStartDates.from =  new Date();
            this.disabledendDates.from =  new Date();
            this.loadresumeReports();
            this.currentIndex = null; 
        },
        loadDate() {
            this.search.params.startDate = new Date();
            this.search.params.endDate = new Date();
        },
        loadresumeReports() {
            if (this.search.params.startDate > this.search.params.endDate) {
                iziToast.error({
                    title: 'Error',
                    message: this.$config.DateMismatchErr,
                    position: 'topRight'
                });
                return;
            }
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/recruiter/resumes/count/by/company?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.resumeReport = result.resumesByRecruiter;
                    this.pagination.totalRecords = result.pagination[0].total;
                    this.pagination.currentPage = result.pagination[0].currentPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        changeLimit(newlimit) {
            this.pagination.currentPage = 1;
            this.pagination.rowsPerPage = newlimit;
            this.loadresumeReports();
        },
        findResumeReport() {
            this.pagination.currentPage = 1;
            this.loadresumeReports();
        },
        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },
         resumeLoadedByRecruiters() {
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/resumeloadedrecruiters/report?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "SharedCandidates" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });
        },

        getResumeLoadedCandidates(userId, recruiterId) {
            if (this.currentIndex == userId) {
                this.currentIndex = null;
            } else {
                this.currentIndex = userId;
            }
            let data = {
                userId: userId,
                recruiterId: recruiterId,
                startDate: this.search.params.startDate,
                endDate: this.search.params.endDate
            };
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/list/resume/loaded/candidates', data)
                .then(response => {
                    let result = response.data;
                    this.resumeLoadedCandidates = result.resumeLoadedCandidates;
                    console.log(result);
                })
                .catch(error => {
                    console.log(error);
                });
        },


    }
}

</script>
