<template>
    <div>
        <div class="card">
            <div class="card-header p-2">Add Previous Employment History</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col">Company Name</th>
                                            <th class="videoprofile" scope="col">Position</th>
                                            <th class="recruiter" scope="col">Technology/Skills</th>
                                            <th class="title" scope="col">Place</th>
                                            <th class="title" scope="col">Country</th>
                                            <th class="phone" scope="col">From</th>
                                            <th class="lastnote" scope="col">To</th>
                                            <th class="" scope="col">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody class="cloned_tr">
                                        <tr v-for="(info, index) in employmentinfo" :class="{ 'viewonly' : employmentinfo.viewonly == 1}">
                                            <td>
                                                <label>{{employmentinfo[index].companyName}}</label>
                                            </td>
                                            <td class="lastnote">
                                                <label>{{employmentinfo[index].jobTitle}}</label>
                                            </td>
                                            <td class="recruiter">
                                                <div class="position-relative">
                                                    <label>{{employmentinfo[index].skillSet}}</label>
                                                </div>
                                            </td>
                                            <td class="title">
                                                <label>{{employmentinfo[index].city}}</label>
                                            </td>
                                            <td class="specialityunit">
                                                <label>{{getCountryName(employmentinfo[index].countryId)}}</label>
                                            </td>
                                            <td class="currentcity">
                                                <label>{{employmentinfo[index].employedFrom | formatMonYear}}</label>
                                            </td>
                                            <td class="state">
                                                <label>{{employmentinfo[index].employedTo | formatMonYear}}</label>
                                            </td>
                                            <td>
                                                <a class="removemoretextbox" :class="{ 'removeaddbtn' : employmentinfo.viewonly == 1}" href="Javascript:void(0);" @click="confirmCompany(index,0)" data-toggle="modal" data-target="#removCompany"><span class="ti-trash"></span></a>
                                                <a href="Javascript:void(0);" data-toggle="modal" data-target="#EmpRefDetails" class="addmoretextbox" @click="setRefDetails(index)" title="Reference Details
">Ref</a>
                                            </td>
                                        </tr>
                                        <tr :class="{'norecordfound': employmentinfo.length}">
                                            <td colspan="8" align="center">No item found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="EmpRefDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Reference Details</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class='col'>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Name</label>
                                    <div class="form-control">{{empRefInfoEdit.referenceName}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class='col'>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Phone</label>
                                    <div class="form-control">{{empRefInfoEdit.referencePhone}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class='col'>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Email</label>
                                    <div class="form-control">{{empRefInfoEdit.referenceEmail}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class='col'>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Position</label>
                                    <div class="form-control">{{empRefInfoEdit.referenceRelationship}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        employmentinfo: Array,
    },
    data() {
        return {
            empRefInfoEdit: {
                referenceName: '',
                referencePhone: '',
                referenceEmail: '',
                referenceRelationship: '',
                index: -1
            },
            countries: this.$store.getters.getCountries
        }
    },
    methods: {
        setRefDetails(index) {

            this.empRefInfoEdit = {
                referenceName: this.employmentinfo[index].referenceName,
                referencePhone: this.employmentinfo[index].referencePhone,
                referenceEmail: this.employmentinfo[index].referenceEmail,
                referenceRelationship: this.employmentinfo[index].referenceRelationship,
                index: index
            };
        },
        getCountryName: function(countryCode) {
            if (!countryCode)
                return "";
            let country = this.countries.filter(obj => {
                return obj.country_Id === countryCode;
            });
            return country[0].name;
        },
    }
}

</script>
