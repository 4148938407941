<template>
    <div class="compare-candidate" id="CompareCandidate">
        <div class="modal-dialog mw-100 w-100" role="document">
            <div class="modal-content">
                <div class="modal-header text-white blue-bg-title pt-0 pb-0">
                    <h5 class="modal-title pt-2" id="CompareModal">Candidate Comparison</h5>
                    <ul>
                        <li>
                            <a href="javascript:" @click="$emit('closeCompareCandidate')" class="ctm-btn close text-white opacity-100" aria-label="Close"><span aria-hidden="true">&times;</span></a>
                        </li>
                    </ul>
                </div>
                <div class="modal-body">
                    <div class="table-responsive">
                        <table class="table table-striped table-borderless s_table s_caps compare-table">
                            <tbody>
                                <tr>
                                    <td width="20%"><i class="ti-view-grid headingicon hidden-xs"></i> Specification</td>
                                    <td width="20%" class="text-left" v-for="(candidate,index) in compareData">
                                        <router-link title="View Candidate Profile" :to="{ name: 'viewcandidate', params: { candidateid: candidate.id}}">{{candidate.candidateName}} <span v-if="candidate.candidateCode">({{candidate.candidateCode}})</span></router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button class="text-left" block href="#" v-b-toggle.accordion-1 variant="info">Primary Info</b-button>
                        </b-card-header>
                        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <div class="table-responsive">
                                    <table class="table table-striped table-borderless s_table compare-table">
                                        <tbody>
                                            <tr>
                                                <td width="20%">Industry</td>
                                                <td width="20%" class="text-left" v-for="(candidate,index) in compareData">{{candidate.industryName}}</td>
                                            </tr>
                                            <tr>
                                                <td width="20%">Position</td>
                                                <td width="20%" class="text-left" v-for="(candidate,index) in compareData">{{candidate.position}}</td>
                                            </tr>
                                            <tr>
                                                <td width="20%">Year of Experience</td>
                                                <td width="20%" class="text-left" v-for="(candidate,index) in compareData">
                                                    {{candidate.experience}}</td>
                                            </tr>
                                            <tr>
                                                <td width="20%">Location</td>
                                                <td width="20%" class="text-left" v-for="(candidate,index) in compareData">{{candidate.location}}</td>
                                            </tr>
                                            <tr>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button class="text-left" block href="#" v-b-toggle.accordion-2 variant="info">Education</b-button>
                        </b-card-header>
                        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <div class="table-responsive">
                                    <table class="table table-striped table-borderless s_table compare-table">
                                        <tbody>
                                            <tr>
                                                <td width="20%">Qualification</td>
                                                <td width="20%" class="text-left" v-for="(candidate,index) in compareData"><span class="p-1 mb-2 mr-1 badge badge-primary" v-for="Education in candidate.candidateEducation">{{Education.degree}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button class="text-left" block href="#" v-b-toggle.accordion-3 variant="info">Technology/Skills</b-button>
                        </b-card-header>
                        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <div class="table-responsive">
                                    <table class="table table-striped table-borderless s_table compare-table">
                                        <tbody>
                                            <tr>
                                                <td width="20%">Technology</td>
                                                <td width="20%" class="text-left" v-for="(candidate,index) in compareData">
                                                    <span class="p-1 mb-2 mr-1" v-for="Tech in candidate.techNameList">                                                          
                                                        <span v-if="Tech!=null">{{Tech}}</span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="20%">Skills</td>
                                                <td width="20%" class="text-left" v-for="(candidate,index) in compareData">
                                                    <span class="p-1 mb-2 mr-1 badge" :class="colours[getRandom(colours)]" v-for="skill in candidate.skillList">                                                          
                                                        <span v-if="skill!=null">{{skill}}</span>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button class="text-left" block href="#" v-b-toggle.accordion-4 variant="info">Employment</b-button>
                        </b-card-header>
                        <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <div class="table-responsive">
                                    <table class="table table-striped table-borderless s_table compare-table">
                                        <tbody>
                                            <tr>
                                                <td width="20%">Company Name</td>
                                                <td width="20%" class="text-left" v-for="(candidate,index) in compareData">
                                                    <span v-for="(Employment,idx) in candidate.candidateEmployment">
                                                      <!-- candidate.candidateEmployment.length-1 -->
                                                        <span v-if="idx == 0">
                                                            {{Employment.companyName}}
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="20%">Position</td>
                                                <td width="20%" class="text-left" v-for="(candidate,index) in compareData">
                                                    <span v-for="(Employment,idx) in candidate.candidateEmployment">
                                                        <span v-if="idx == 0">
                                                            {{Employment.jobTitle}}
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="20%">Year</td>
                                                <td width="20%" class="text-left" v-for="(candidate,index) in compareData">
                                                    <span v-for="(Employment,idx) in candidate.candidateEmployment">
                                                        <span v-if="idx == 0 && Employment.employedFrom && Employment.employedTo">
                                                            {{Employment.employedFrom | formatCustomTime('YYYY')}} to {{Employment.employedTo | formatCustomTime('YYYY')}}
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button class="text-left" block href="#" v-b-toggle.accordion-5 variant="info">Projects</b-button>
                        </b-card-header>
                        <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <div class="table-responsive">
                                    <table class="table table-striped table-borderless s_table compare-table">
                                        <tbody>
                                            <tr>
                                                <td width="20%">Project Title</td>
                                                <td width="20%" class="text-center" v-for="(candidate,index) in compareData">
                                                    <span v-for="(Project,idx) in candidate.candidateProject" class="badge text-white bg-success p-1 m-1">{{Project.projectTitle}}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="20%">Client</td>
                                                <td width="20%" class="text-center one" v-for="(candidate,index) in compareData">
                                                    <span v-for="(Project,idx) in candidate.candidateProject" class="badge text-white bg-info p-1 m-1">{{Project.client}}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="20%">Technology/Skills</td>
                                                <td width="20%" class="text-center one" v-for="(candidate,index) in compareData">
                                                    <span v-for="(Project,idx) in candidate.candidateProject" class="badge text-white bg-primary p-1 m-1">{{Project.technology}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button class="text-left" block href="#" v-b-toggle.accordion-6 variant="info">Screening Results</b-button>
                        </b-card-header>
                        <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <div class="table-responsive">
                                    <table class="table table-striped table-borderless s_table compare-table">
                                        <tbody>
                                            <tr>
                                                <td width="20%">SSN verification</td>
                                                <td width="20%" class="text-center" v-for="candidate in compareData"><i class="ti-check-box font-weight-bold  p-0 pr-3 text-success"></i>Success</td>
                                            </tr>
                                            <tr>
                                                <td width="20%">Email verification</td>
                                                <td width="20%" class="text-center pl-0" v-for="candidate in compareData"><i class="ti-na font-weight-bold p-0 pr-3 text-danger"></i>Failed</td>
                                            </tr>
                                            <tr>
                                                <td width="20%">Phone verification</td>
                                                <td width="20%" class="text-center one" v-for="candidate in compareData"><i class="ti-check-box font-weight-bold  p-0 pr-3 text-success"></i>Success</td>
                                            </tr>
                                            <tr>
                                                <td width="20%">Passport verification</td>
                                                <td width="20%" class="text-center pl-0" v-for="candidate in compareData"><i class="ti-na font-weight-bold p-0 pr-3 text-danger"></i>Failed</td>
                                            </tr>
                                            <tr>
                                                <td width="20%">DriverId verification</td>
                                                <td width="20%" class="text-center one" v-for="candidate in compareData"><i class="ti-check-box font-weight-bold  p-0 pr-3 text-success"></i>Success</td>
                                            </tr>
                                            <tr>
                                                <td width="20%">Identity verification</td>
                                                <td width="20%" class="text-center one" v-for="candidate in compareData"><i class="ti-check-box font-weight-bold  p-0 pr-3 text-success"></i>Success</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button class="text-left" block href="#" v-b-toggle.accordion-7 variant="info">Preferences</b-button>
                        </b-card-header>
                        <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <div class="table-responsive">
                                    <table class="table table-striped table-borderless s_table compare-table">
                                        <tbody>
                                            <tr>
                                                <td width="20%">Title</td>
                                                <td width="20%" class="text-left" v-for="(candidate,index) in compareData">
                                                    <span v-for="(Preference,idx) in candidate.candidatePreference">
                                                        {{Preference.title}}
                                                        <span v-if="idx+1 < candidate.candidatePreference.length">, </span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="20%">State</td>
                                                <td width="20%" class="text-left" v-for="(candidate,index) in compareData">
                                                    <span v-for="(Preference,idx) in candidate.candidatePreference">
                                                        {{Preference.stateName}}
                                                        <span v-if="idx+1 < candidate.candidatePreference.length">, </span>
                                                    </span>
                                                </td>
                                            </tr>   
                                            <tr>
                                                <td width="20%">City</td>
                                                <td width="20%" class="text-left" v-for="(candidate,index) in compareData">
                                                    <span v-for="(Preference,idx) in candidate.candidatePreference">
                                                        {{Preference.city}}
                                                        <span v-if="idx+1 < candidate.candidatePreference.length">, </span>
                                                    </span>                                                    
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="20%">Technology/Skills</td>
                                                <td width="20%" class="text-left" v-for="(candidate,index) in compareData">
                                                    <span v-for="(Preference,idx) in candidate.candidatePreference">
                                                        {{Preference.skills}}
                                                        <span v-if="idx+1 < candidate.candidatePreference.length">, </span>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        compareData: Array,
    },
    data() {
        return {
            colours: ['badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info', 'badge-secondary']
        }
    },
    mounted() {
        console.log(this.compareData);
    },
    methods: {
        getRandom(dataArray) {
            return Math.floor(Math.random() * dataArray.length);
        },
        explodeString(skills) {
            if (!skills)
                return false;
            return skills.split(",");
        }
    }
}

</script>
