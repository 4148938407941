<template>
    <div id="main" class="mt-70 d-height"><b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
    <div class="driver-model container-fluid">
    <b-tabs small card class="tab-list driver-tab-list">
        <b-tab title="Recruiters">
            <div id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a @click="searchMore=!searchMore" href="javascript:;" data-target="main-search" class="boxhide"><span :class="searchMore?'ti-angle-up':'ti-angle-down'"></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <form id="main-search" v-if="searchMore">
                        <div class="row form-group ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Recruiter Code</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <input type="text" v-on:keyup.enter="findRecruiters()" v-model="search.params.recruiterCode" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Recruiter Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <input type="text" v-on:keyup.enter="findRecruiters()" v-model="search.params.firstName" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <input type="text" v-on:keyup.enter="findRecruiters()" class="form-control" v-model="search.params.email">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr pl-0">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;">Status</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <button type="button" @click="findRecruiters()" class="btn btn-primary btn-sm float-left" style="margin-left: 10px;">Find</button>
                                        <button type="button" @click="resetRecruiterSearch" class="btn btn-primary btn-sm float-left" style="margin-left: 10px;">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="load-indicator"></div>
                </div>
            </div>
            <div id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-6 col-sm-12 col-12">
                            <h4 class="page-title">Recruiters
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                    <div class="dropdown">
                                        <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                        <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                            <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                        </div>
                                    </div>
                                </span>
                            </h4>
                        </div>
                        <div class="col-md-6 d-none d-sm-none d-md-block">
                            <button type="button" @click="addLogActivity('New Recruiter')" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">
                                <router-link :to="{ name: 'addrecruiter' }" style="color: #fff;">New Recruiter</router-link>
                            </button>
                            <span class="float-right" @click="exportRecruiters()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col"></th>
                                            <th class="">Recruiter Code</th>
                                            <th v-if="coldisplay.recruiterName" class="phoneNumber" scope="col">Recruiter name</th>
                                            <th v-if="coldisplay.email" class="accountManager" scope="col">Email</th>
                                            <th class="mobileNo" scope="col">Phone</th>
                                            <th class="clntName" scope="col">Company name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody id="table-length">
                                        <tr v-if="recruiterList.length==0 && elements.loadingRecruiters == true">
                                            <td colspan="7" align="center">No Recruiters found</td>
                                        </tr>
                                        <tr v-for="(recruiter, index) in recruiterList">
                                            <td>
                                                <!-- <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox" :id="'rowchckbox1-'+recruiter.id" :value="recruiter.id" v-bind:key="recruiter.id">
                                                    <label class="form-check-label" :for="'rowchckbox1-'+recruiter.id"></label>
                                                </div> -->
                                            </td>
                                            <td>
                                                <router-link :to="{ name:'viewrecruiter', params:{recruiterid: recruiter.id}}">{{ recruiter.recruiterCode }}</router-link>
                                            </td>
                                            <td v-if="coldisplay.recruiterName" class="accountManager">
                                                <router-link :to="{ name:'viewrecruiter', params:{recruiterid: recruiter.id}}">{{recruiter.recruiterName}}</router-link>
                                            </td>
                                            <td v-if="coldisplay.email" class="accountManager">{{ recruiter.email }}</td>
                                            <td class="mobileNo">{{ recruiter.mobile_No }}</td>
                                            <td class="clntName">{{ recruiter.company_Name }}</td>
                                            <td>
                                                <ul class="navbar-nav">
                                                    <li class="nav-item">
                                                        <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                            <li>
                                                                <router-link class="dropdown-item" :to="{ name:'viewrecruiter', params:{recruiterid: recruiter.id}}">
                                                                    <span class="ti-eye pr-2"></span> View
                                                                </router-link>
                                                            </li>
                                                            <li>
                                                                <router-link class="dropdown-item" :to="{ name:'editrecruiter', params:{recruiterid: recruiter.id}}">
                                                                    <span class="ti-pencil pr-2"></span> Edit
                                                                </router-link>
                                                            </li>
                                                            <li><a class="dropdown-item" href="javascript:" @click="deleteRecruiter(recruiter.id)" data-toggle="modal" data-target="#removerecruiter"><span class="ti-close pr-2"></span> Deactivate</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <b-pagination v-if="recruiterList.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadRecruiterList()"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </b-tab>
            <b-tab title="Team Members">
            <div id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a @click="searchMore=!searchMore" href="javascript:;" data-target="main-search" class="boxhide"><span :class="searchMore?'ti-angle-up':'ti-angle-down'"></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <form id="main-search" v-if="searchMore">
                        <div class="row form-group ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Team Member Code</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <input type="text" v-on:keyup.enter="findRecruiters()" v-model="memberSearch.params.recruiterCode" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Team Member Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <input type="text" v-on:keyup.enter="findRecruiters()" v-model="memberSearch.params.firstName" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <input type="text" v-on:keyup.enter="findRecruiters()" class="form-control" v-model="memberSearch.params.email">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr pl-0">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;">Status</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <button type="button" @click="findMembers()" class="btn btn-primary btn-sm float-left" style="margin-left: 10px;">Find</button>
                                        <button type="button" @click="resetMemberSearch" class="btn btn-primary btn-sm float-left" style="margin-left: 10px;">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="load-indicator"></div>
                </div>
            </div>
            <div id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-6 col-sm-12 col-12">
                            <h4 class="page-title">Team Members
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{memberListPagination.totalRecords}} - Total)</span>
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                    <div class="dropdown">
                                        <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                        <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                            <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changerowLimit(value)">{{value}}</a>
                                        </div>
                                    </div>
                                </span>
                            </h4>
                        </div>
                        <div class="col-md-6 d-none d-sm-none d-md-block">
                            <button v-if="recuiterType == 2 || recuiterType == 3" type="button" @click="addLogActivity('New RecManager')" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">
                                <router-link :to="{ name: 'addteammember' }" style="color: #fff;">New Team Member</router-link>
                            </button>
                            <span class="float-right" @click="exportMembers()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col"></th>
                                            <th class="">Team Member Code</th>
                                            <th v-if="coldisplay.recruiterName" class="phoneNumber" scope="col">Team Member name</th>
                                            <th scope="col">Role</th>
                                            <th v-if="coldisplay.email" class="accountManager" scope="col">Email</th>
                                            <th class="mobileNo" scope="col">Phone</th>
                                            <th class="clntName" scope="col">Company name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody id="table-length">
                                        <tr v-if="memberList.length==0 && elements.loadingMembers == true">
                                            <td colspan="7" align="center">No Team Members found</td>
                                        </tr>
                                        <tr v-for="(recruiter, index) in memberList">
                                            <td>
                                                <!-- <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox" :id="'rowchckbox1-'+recruiter.id" :value="recruiter.id" v-bind:key="recruiter.id">
                                                    <label class="form-check-label" :for="'rowchckbox1-'+recruiter.id"></label>
                                                </div> -->
                                            </td>
                                            <td>
                                                <router-link :to="{ name:'viewteammember', params:{recruiterid: recruiter.id,ismember:true}}">{{ recruiter.recruiterCode }}</router-link>
                                            </td>
                                            <td v-if="coldisplay.recruiterName" class="accountManager">
                                                <router-link :to="{ name:'viewteammember', params:{recruiterid: recruiter.id,ismember:true}}">{{recruiter.recruiterName}}</router-link>
                                            </td>
                                            <td>{{ recruiter.role }}</td>
                                            <td v-if="coldisplay.email" class="accountManager">{{ recruiter.email }}</td>
                                            <td class="mobileNo">{{ recruiter.mobile_No }}</td>
                                            <td class="clntName">{{ recruiter.company_Name }}</td>
                                            <td>
                                                <ul class="navbar-nav">
                                                    <li class="nav-item">
                                                        <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                            <li>
                                                                <router-link class="dropdown-item" :to="{ name:'viewteammember', params:{recruiterid: recruiter.id,ismember:true}}">
                                                                    <span class="ti-eye pr-2"></span> View
                                                                </router-link>
                                                            </li>
                                                            <li>
                                                                <router-link class="dropdown-item" :to="{ name:'editteammember', params:{recruiterid: recruiter.id,ismember:true}}">
                                                                    <span class="ti-pencil pr-2"></span> Edit
                                                                </router-link>
                                                            </li>
                                                            <li><a class="dropdown-item" href="javascript:" @click="deleteRecruiter(recruiter.id)" data-toggle="modal" data-target="#removerecruiter"><span class="ti-close pr-2"></span> Deactivate</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <b-pagination v-if="memberList.length!=0" size="md" class='pull-right' :total-rows="memberListPagination.totalRecords" v-model="memberListPagination.currentPage" :per-page="memberListPagination.rowsPerPage" @input="loadRecruiterList()"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
            </b-tab>
        </b-tabs>
        </div>
        <Dialog id="removerecruiter" :onYes="delete_Recruiter" :returnParams="delrecruiterParam" title="Deactivate Confirmation" message="Are you sure to deactivate ?" />
        <template #overlay>
            <div>
                <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
            </div>                   
        </template> 
        </b-overlay>
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    data() {
        return {
            showOverlay:false,
            recruiterslist: [],
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            recuiterType: this.$store.getters.currentUser.recruitType,
            recownerid: this.$store.getters.currentUser.recruitOwner,
            recruiterList: [],
            memberList: [],
            elements: {
                showadvancedsearch: false,
                loadingRecruiters: false,
                loadingMembers: false,
            },
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            memberListPagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            search: {
                params: {
                    recruiterCode: '',
                    firstName: '',
                    email: '',
                    type:1,
                    pageNo: '',
                    limit: ''
                },
            },
            memberSearch: {
                params: {
                    recruiterCode: '',
                    firstName: '',
                    email: '',
                    type:3,
                    pageNo: '',
                    limit: ''
                },
            },
            coldisplay: {
                companyName: true,
                email: true,
                recruiterName: true
            },
            delrecruiterParam: {
                index: -1,
                id: 0
            },
            searchMore: false
        }
    },
    watch: {
        'showOverlay': {
            handler: function(value) { 
                if(value == true){
                    $('#globalapploader').css('visibility','hidden');
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.$store.commit('refreshPagination');
        this.loadRecruiterList();
    },
    methods: {
        resetRecruiterSearch() {
            this.search.params = {
                recruiterCode: '',
                firstName: '',
                email: '',
                type:1,
                pageNo: '',
                limit: ''
            };
            this.loadRecruiterList();
        },
        resetMemberSearch() {
            this.memberSearch.params = {
                recruiterCode: '',
                firstName: '',
                email: '',
                type:3,
                pageNo: '',
                limit: ''
            };
            this.loadRecruiterList();
        },
        async loadRecruiterList() {
            this.showOverlay=true;
            await this.getRecruiterList();
            await this.getMemberList();
            this.showOverlay=false;
        }, 
        getRecruiterList() {
            this.showOverlay=true;
            this.search.params.pageNo = this.pagination.currentPage;
            this.search.params.limit = this.pagination.rowsPerPage;
            //return axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/recruiter/list?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
            return axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/recruiter/list', this.search.params)
                .then(response => {
                    let result = response.data;
                    this.recruiterList = result.data;
                    this.pagination.totalRecords = result.totalRecords;
                    this.pagination.currentPage = result.currentPage;
                    this.elements.loadingRecruiters = true;
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });
        },
         getMemberList() {
            this.showOverlay=true;
            this.memberSearch.params.pageNo = this.memberListPagination.currentPage;
            this.memberSearch.params.limit = this.memberListPagination.rowsPerPage;
            return axios.post(this.$store.getters.getAPIBasePath + '/member/list', this.memberSearch.params)
                .then(response => {
                    let result = response.data;
                    this.memberList = result.data;
                    this.memberListPagination.totalRecords = result.totalRecords;
                    this.memberListPagination.currentPage = result.currentPage;
                    this.elements.loadingMembers = true;
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });
        },
        changeLimit(newlimit) {
            this.pagination.currentPage = 1;
            this.pagination.rowsPerPage = newlimit;
            this.loadRecruiterList();
        },
        changerowLimit(newlimit) {
            this.memberListPagination.currentPage = 1;
            this.memberListPagination.rowsPerPage = newlimit;
            this.loadRecruiterList();
        },
        deleteRecruiter(recruiterId){
            this.delrecruiterParam.id = recruiterId;
            return;
            if (confirm("Do you really want to deactivate the recruiter?")) {
                var params = new URLSearchParams();
                params.append('id', recruiterId)
                axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/delete/recruiter', params)
                    .then(response => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.loadRecruiterList();
                        }
                    })
                    .catch(error => {
                        console.log(error.data);
                        this.unAuthorized(error);
                    });
            }
        },
        delete_Recruiter() {
           var params = new URLSearchParams();
            var recruiterId = this.delrecruiterParam.id;
            params.append('id', recruiterId)
            axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/delete/recruiter',params)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    this.loadRecruiterList();
                })
                .catch(error => {
                    console.log(error.data);
                });
        },
        exportRecruiters() {
            this.addLogActivity('Export Recruiters');
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/recruiters?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "Recruiters" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });

        },
        exportMembers() {
            this.memberSearch.params.pageNo = this.memberListPagination.currentPage;
            this.memberSearch.params.limit = this.memberListPagination.rowsPerPage;
            this.addLogActivity('Export Members');
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/members', this.memberSearch.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "Members" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });

        },
        findRecruiters() {
            this.pagination.currentPage = 1;
            this.loadRecruiterList();
        },
        findMembers() {
            this.memberListPagination.currentPage = 1;
            this.loadRecruiterList();
        },
        addLogActivity: function(action) {
            this.updateLogActivity();
            this.logActivityData = {
                entityName: 'Recruiter',
                actionName: action,
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {})
                .catch(error => {});
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
    }
}

</script>
