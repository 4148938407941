<template>
	<div class="one-content active" id="candidate_shortlist">

		<div class="card">
			<div class="card-header p-2">Follow up Candidates</div>
			<div class="card-body p-2">
				<div class="basic-block">
					<div class="row">
						<div class="col-sm-12">
							<div class="table-responsive">
								<table class="table table-borderless all-table">
									<thead>
										<tr>
											<th scope="col">Name</th>
											<th scope="col">Position</th>
											<!-- <th scope="col">Skills</th> -->
											<th scope="col">Place</th>
											<th v-if="candContactAccess==1" scope="col">Phone</th>
											<th v-if="candContactAccess==1" scope="col">Email</th>
											<th scope="col">Hired Date</th>
											<th scope="col">Resign/Term Date</th>
											<th scope="col">Status</th>
											<th scope="col">Rating</th>
										</tr>
									</thead>
									<tbody>
										<tr v-if="shortList.shortListCand.length==0">
											<td colspan=9 align="center">No Candidates found</td>
										</tr>
										<tr v-for="(ShortListCandidate,index) in shortList.shortListCand">
											<td><router-link v-b-tooltip.hover title="View Candidate Profile" :to="{ name: 'viewcandidate', params: { candidateid: ShortListCandidate.candidateId }}">{{ShortListCandidate.candidateName}}</router-link></td>
											<td>{{ShortListCandidate.position}}</td>
											<!-- <td>{{ShortListCandidate.technology}}</td> -->
											<td>{{ShortListCandidate.cityName}} {{ShortListCandidate.stateName}}</td>
											<td v-if="candContactAccess==1">{{ShortListCandidate.mobile}}</td>
											<td v-if="candContactAccess==1">{{ShortListCandidate.email}}</td>
											<td>
												<span>{{ShortListCandidate.hiredDate |formatDate}}</span>
											</td>
											<td>
												<span>{{ShortListCandidate.resignedTerminationDate |formatDate}}</span>
											</td>										
											<td>
												<a class="nav-link dropdown-toggle " href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													<span :class="ShortListCandidate.statusName" class="label text-white p-1 rounded w-50-auto slstatus">{{ShortListCandidate.statusName}}</span>
													<div class="dropdown-menu p-0" aria-labelledby="navbarDropdown">
														<ul class="m-0">
															<li v-for="(CandSLStatus, idx) in shortList.candSLStatus" v-bind:key="idx">
																<a v-on:click="$emit('changeSLcandStatus', {'rpCandSLid':ShortListCandidate.rpCandShortlistId, 'CandSLstatusId':CandSLStatus.id})" class="dropdown-item" href="javascript:">{{CandSLStatus.name}}</a>
															</li>
														</ul>
													</div>
												</a>
											</td>
											<td>
												<div class="dropdown followup text-center">
													<a class="dropdown-toggle" data-toggle="dropdown" href="javascript:;">
														{{ShortListCandidate.clientRating}}
														<i class="ti-star"></i>
													</a>
													<div class="dropdown-menu">														
														<fieldset class="rating dropdown-item">
															<input :id="'rate_'+index+'-star5'" type="radio" value="5" @change="ratingChange(index,ShortListCandidate.rpCandShortlistId, 5)" v-model="ShortListCandidate.clientRating" />
															<label :for="'rate_'+index+'-star5'" title="Excellent">5</label>

															<input :id="'rate_'+index+'-star4'" type="radio" value="4" @change="ratingChange(index,ShortListCandidate.rpCandShortlistId, 4)" v-model="ShortListCandidate.clientRating"/>
															<label :for="'rate_'+index+'-star4'" title="Good">4</label>

															<input :id="'rate_'+index+'-star3'" type="radio" value="3" @change="ratingChange(index,ShortListCandidate.rpCandShortlistId, 3)" v-model="ShortListCandidate.clientRating"/>
															<label :for="'rate_'+index+'-star3'" title="Satisfactory">3</label>

															<input :id="'rate_'+index+'-star2'" type="radio" value="2" @change="ratingChange(index,ShortListCandidate.rpCandShortlistId, 2)" v-model="ShortListCandidate.clientRating"/>
															<label :for="'rate_'+index+'-star2'" title="Bad">2</label>

															<input :id="'rate_'+index+'-star1'" type="radio" value="1" @change="ratingChange(index,ShortListCandidate.rpCandShortlistId, 1)" v-model="ShortListCandidate.clientRating"/>
															<label :for="'rate_'+index+'-star1'" title="Very bad">1</label>
														</fieldset>
													</div>
												</div>
												 
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<style scoped>
div.followup >.dropdown-toggle::after
{
	display: none;
}
</style>
<script>
	export default {
		props: {
			shortlistedCandidates: Array,
			candContactAccess: [String, Number]
		},
		computed:{
	        jobid(){
	            return this.$route.params.jobid;
	        }
	    },
	    watch: {
			shortlistedCandidates(newValue,oldValue){
				this.shortList.shortListCand = this.filterByStatus("4");
			}
		},
		data(){
			return {
				recPlanId: localStorage.getItem('setRecPlanId'),//this.$store.getters.getRecPlanId,
				elements:{
					showSearch: false,
					showCanList: false
				},
				shortList:{
					candSLStatus: this.$store.getters.getShortListedCandidateStatus,
					selCandidatesId:[],
					selCanIdJson:[],
					shortListCand:[]
				}
			}
		},
		mounted(){
			this.shortList.shortListCand = this.filterByStatus("4");
			this.shortList.candSLStatus = this.filterByDropDownStatus("4");
			// console.log("-----1----");
			// console.log(this.shortList.shortListCand);
			// console.log("-----1----");
		},
		methods: {
			ratingChange(index, CandShortlistId, starVal){
				console.log(index);
				//recruitmentplanresource/update/rp/candshortlist/clientrating/2
				axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/update/rp/candshortlist/clientrating/'+CandShortlistId, {clientRating:starVal})
				.then(response => {				
					console.log(response);
				})
				.catch(error => {
					this.unAuthorized(error);
					console.log(error);
				});
			},
			scheduleInterview(candId){
				//console.log(candId);
				localStorage.setItem('recPlanId',this.recPlanId);
				this.$router.push('/candidates/interview/'+candId);
			},
			filterByStatus(statusId){
				return this.shortlistedCandidates.filter(function(data,key){
					if(data.status>statusId){
						return data;
					}
				});
			},
			filterByDropDownStatus(statusId){
				return this.shortList.candSLStatus.filter(function(data,key){
					if(data.id>statusId){
						return data;
					}
				});
			}
		}
	}
</script>