<template>
    <div id="main" class="mt-80 d-height">
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :class="isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <form id="main-search" :class="isExpand?'d-block':'d-none'" v-on:submit.prevent="">
                        <div class="row form-group ten-columns">
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" v-on:keyup.enter="findInterviewer" v-model="params.name" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Technology</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select class="form-control" v-model="params.tech">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in technologies" :value="{id:info.id,text:info.technology}">{{info.technology}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr pl-0">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;">End Date</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12 float-right">
                                        <button type="button" class="btn btn-primary float-left" @click="findInterviewer" style="margin-left: 10px;">Find</button>
                                        <button type="button" class="btn btn-primary float-left" @click="resetFilter" style="margin-left: 10px;">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="container-fluid" id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-6 col-sm-12 col-12">
                            <h4 class="page-title">Interviewers
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{browsepagination.totalRecords}} - Total)</span>
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                    <div class="dropdown">
                                        <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                        <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                            <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                        </div>
                                    </div>
                                </span>
                            </h4>
                        </div>
                        <div class="col-md-6 d-none d-sm-none d-md-block">
                            <button type="button" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">
                                <router-link :to="{ name: 'referraladdinterviewer' }" style="color: #fff;">New Interviewer</router-link>
                            </button>
                            <span class="float-right" @click="exportInterviewers()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i></span>
                        </div>
                    </div>
                    <div class="row pt-20 mt-20">
                        <div class="col-sm-12">
                            <table class="table table-borderless all-table">
                                <thead>
                                    <tr>                                    
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Technology</th>
                                        <th>Phone No</th>
                                        <th>Approved</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="!recruitersList.length && elements.loadingInterviewers == true">
                                        <td class="text-center" colspan="100%">No interviewers found</td>
                                    </tr>
                                    <tr v-for="recruiter in recruitersList">                                    
                                        <td>
                                            <router-link :to="{ name:'referralviewinterviewer', params:{interviewerid: recruiter.id}}">{{recruiter.interviewerName}}</router-link>
                                        </td>
                                        <td>{{recruiter.email}}</td>
                                        <td>{{recruiter.technology}}</td>
                                        <td>{{recruiter.phoneNo}}</td>
                                        <td>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" :id="'approval-chckbox-'+recruiter.id" checked="" @change="changeApproval(recruiter.id,recruiter.approved)" v-model="recruiter.approved" :value="recruiter.approved" data-toggle="modal" data-target="#approval" />
                                                <!--  v-model="approved" :value="recruiter.approved" data-toggle="modal" data-target="#approval"-->
                                                <label class="form-check-label" :for="'approval-chckbox-'+recruiter.id"></label>
                                            </div>
                                        </td>
                                        <td>
                                            <ul class="navbar-nav">
                                                <li class="nav-item dropdown">
                                                    <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="javascript:" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                        <li>
                                                            <router-link class="dropdown-item" :to="{ name: 'referralviewinterviewer', params: { interviewerid: recruiter.id }}"><span class="ti-eye pr-2"></span> View</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link class="dropdown-item" :to="{ name: 'referraleditinterviewer', params: { interviewerid: recruiter.id }}"><span class="ti-pencil pr-2"></span> Edit</router-link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <b-pagination v-if="recruitersList.length!=0" size="md" class='pull-right' :current-page="browsepagination.currentpage" :total-rows="browsepagination.totalRecords" v-model="browsepagination.currentpage" :per-page="search.limit" @input="loadRecruitersList()"></b-pagination>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div>
                    <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
                </div>                   
            </template> 
        </b-overlay>
        <Dialog id="approval" :onYes="confirmApproval" :returnParams="elements.approvalParams" :onNo="cancelApproval" :onExit="cancelApproval" title="Approval" message="Are you sure to change the approval?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
// import { BModal, VBModal } from 'bootstrap-vue'
export default {
    // components: {
    //     BModal,
    //     VBModal
    // },
    data() {
        return {
            showOverlay:false,
            elements: {
                approvalParams: {
                    id: null,
                    approved: 0,
                    statusName: ""
                }
            },
            isExpand: false,
            technologies: this.$store.getters.getTechOptions,
            // browsepagination: this.$store.state.paginationconfig,
            searchlimit: this.$store.state.searchlimit,
            browsepagination: {
                totalRecords: 0,
                rowsPerpage: 10,
                currentpage: 1
            },
            edit_mode: true,
            recruitersList: [],
            params: {
                name: "",
                technology: "",
                 tech:""
            },
            panelMem: [],
            search: {
                limit: 10,
            },
             elements: {
                loadingInterviewers: false,
            },
        }
    },
    computed: {
        candidateId() {
            return this.$route.params.candidateid
        }
    },
    watch: {
        'showOverlay': {
            handler: function(value) { 
                if(value == true){
                    $('#globalapploader').css('visibility','hidden');
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.loadRecruitersList();
    },
    methods: {
        async loadRecruitersList() {
            this.showOverlay=true;
            await this.getRecruitersList();
            this.showOverlay=false;
        },
        getRecruitersList() {
            this.showOverlay=true;
            if(this.params.tech != null){
                this.params.technology = this.params.tech.id;
                 this.params.technologyName = this.params.tech.text
            }
            this.params.pageNo = this.browsepagination.currentpage;
            this.params.limit = this.browsepagination.rowsPerPage;
            //return axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/list/interviewers/by/referral?page=' + this.browsepagination.currentpage + '&size=' + this.search.limit, this.params)
            return axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/list/interviewers/by/referral' , this.params)
                .then(response => {
                    let result = response.data;
                    if (result.status){
                        this.recruitersList = result.data;
                        console.log(result)
                        this.browsepagination.totalRecords = result.totalRecords;//result.pagination[0].total;
                        this.browsepagination.currentpage = result.currentPage; //result.pagination[0].currentPage;
                        this.elements.loadingInterviewers = true;
                    }
                })
                .catch(e => {
                    this.showOverlay=false;
                    console.log(e);
                    //this.errors.push(e)
                });
        },
        findInterviewer(){
            this.browsepagination.currentpage = 1;
            this.loadRecruitersList();
        },
        filterInterviewers() {
            this.loadRecruitersList();
        },
        resetFilter() {
            this.params.name = "";
            this.params.technology = "";
            this.params.tech = ""; 
            this.loadRecruitersList();
        },
        checkAll() {
            this.isCheckAll = !this.isCheckAll;
            this.panelMem = [];
            let self = this
            if (this.isCheckAll) {
                $.each(this.recruitersList, function(key, value) {
                    self.panelMem.push(value.id)
                })
            }
        },
        changeLimit(newlimit) {
            console.log(newlimit);
            this.search.limit = newlimit;
              this.browsepagination.currentpage = 1;
             this.browsepagination.rowsPerpage = newlimit;
            // this.$store.commit('updateActiveSearchLimit', this.search.limit);
            this.loadRecruitersList();
        },
        changeApproval(interviewerId, val) {
            let approved = val ? 1 : 0;
            let statusName = val ? "accept" : "reject";
            this.elements.approvalParams={"id" : interviewerId,"approved" : approved,"statusName" : statusName}
        },
        confirmApproval() {
            axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/interviewer/approval', this.elements.approvalParams)
                .then(response => {
                    console.log(response);
                    if (response.data.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message: this.elements.approvalParams.approved ? this.$config.InterAproveSuc : this.$config.InterAproveCan, position: 'topRight' });
                    } else {
                        iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                    }
                    this.loadRecruitersList();
                    this.elements.approvalParams.id = null;
                    this.elements.approvalParams.approved = 0;
                })
                .catch(e => {
                    console.log(e);
                    iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                    this.loadRecruitersList();
                    this.elements.approvalParams.id = null;
                    this.elements.approvalParams.approved = 0;
                });
        },
        cancelApproval() {
            this.loadRecruitersList();
            this.elements.approvalParams.id = null;
            this.elements.approvalParams.approved = 0;
        },
        exportInterviewers() {

            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/interviewers/by/referral', this.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "Interviewers" + "_" + currentDateWithFormat;

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });

        }
    }
}

</script>
