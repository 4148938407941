<template>
	<div :class="{ 'viewonly' : preferenceinfo.viewonly == 1}">
		<div class="card">
		    <div class="card-header p-2">Preference</div>
		    <div class="card-body p-2">
		    	<div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col">Title</th>
                                            <th class="videoprofile" scope="col">State</th>
                                            <th class="title" scope="col">City</th>
                                            <th class="recruiter" scope="col">Technology/Skills</th>
                                            <th class="state" scope="col">Travel</th>
                                            <th class="" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="cloned_tr">
                                    	<tr v-for="(info, index) in preferenceinfo.basepreference">
                                    		<td>
                                    			{{ preferenceinfo.basepreference[index].title }}
                                    		</td>
                                            <td class="lastnote">
                                            	{{ preferenceinfo.basepreference[index].stateName }}
                                            </td>
                                            <td class="recruiter">
                                            	{{ preferenceinfo.basepreference[index].city }}
                                            </td>
                                            <td class="title">
                                            	{{ preferenceinfo.basepreference[index].skills }}
                                            </td>
                                            <td class="specialityunit">
                                            	{{ preferenceinfo.basepreference[index].canTravel }}
                                            </td>
                                            <td>
                                            </td>
                                    	</tr>
                                        <tr :class="{ 'norecordfound' : preferenceinfo.basepreference.length}" >
                                            <td colspan="6" align="center">No item found</td>
                                        </tr>                                         
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
		    </div>
            <div class="card">
                <div class="card-header p-2">Mode of Communication</div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div class="row ten-columns">
                            <div class="col-md-12 col-sm-12 col-12 lb">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label class="font-weight-bold">Preferred Communication Mode</label> 
                                    </div>
                                    <div>
                                        <label>{{preferenceinfo.communicationMode}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <div class="card-header p-2">Other Preference</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row">
                        <div v-if="false" class="col-md-3 col-sm-6">
                            <div class="row">
                                <div class="col-md-6">
                                   <label class="font-weight-bold">Best Time to Call</label> 
                                </div>
                                <div class="col-md-6">
                                    <label>{{preferenceinfo.otherpreference.timetocall}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                            <div class="row">
                                <div class="col-md-6">
                                   <label class="font-weight-bold">Work Type</label> 
                                </div>
                                <div class="col-md-6">
                                    <label>{{preferenceinfo.otherpreference.workType}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                            <div class="row">
                                <div class="col-md-6 pl-0-pr">
                                   <label class="font-weight-bold">Expected Salary</label> 
                                </div>
                                <div class="col-md-6">
                                    <label v-if="preferenceinfo.otherpreference.expectedSalary > 0">{{preferenceinfo.otherpreference.currency}}{{" "}}{{preferenceinfo.otherpreference.expectedSalary}}{{" "}}{{preferenceinfo.otherpreference.payFrequency}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6">
                            <div class="row">
                                <div class="col-md-7">
                                   <label class="font-weight-bold">Minimum Salary</label> 
                                </div>
                                <div class="col-md-5">
                                    <label v-if="preferenceinfo.otherpreference.minSalary > 0 ">{{preferenceinfo.otherpreference.currency}}{{" "}}{{preferenceinfo.otherpreference.minSalary}}{{" "}}{{preferenceinfo.otherpreference.payFrequency}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6">
                            <div class="row">
                                <div class="col-md-7">
                                   <label class="font-weight-bold">Maximum Salary</label> 
                                </div>
                                <div class="col-md-5">
                                    <label v-if="preferenceinfo.otherpreference.maxSalary > 0 ">{{preferenceinfo.otherpreference.currency}}{{" "}}{{preferenceinfo.otherpreference.maxSalary}}{{" "}}{{preferenceinfo.otherpreference.payFrequency}}</label>
                                </div>
                            </div>
                        </div>
                        <div  v-if="preferenceinfo.otherpreference.frequencyPercentage != 0.0 && preferenceinfo.otherpreference.frequencyPercentage != '' && preferenceinfo.otherpreference.frequencyPercentage != null && preferenceinfo.otherpreference.frequencyPercentage != undefined " class="col-md-2 col-sm-6">
                            <div class="row">
                                <div class="col-md-7">
                                   <label class="font-weight-bold">Flexibility <span class="fW_bold">%</span></label> 
                                </div>
                                <div class="col-md-5">
                                    <label>{{preferenceinfo.otherpreference.frequencyPercentage}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			preferenceinfo:Object
		}
	}
</script>