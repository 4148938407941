<template>
	<div :class="{ 'viewonly' : technologyinfo.viewonly == 1}">
		<div class="card">
		    <div class="card-header p-2">Technology/Skills Expertise</div>
		    <div class="card-body p-2">
		    	<div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="state" scope="col">Industry</th>
                                            <th class="title" scope="col">Specialization/Technology</th>
                                            <th class="" scope="col">Skills</th>
                                            <th v-if="false" class="videoprofile" scope="col">Primary Exp.(# of Years)</th>
                                            <th class="" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="cloned_tr">
                                    	<tr v-for="(info, index) in technologyinfo.skillset">
                                            <td class="title">
                                            	   <label v-if="technologyinfo.Industry != null">{{technologyinfo.Industry}}</label>
                                            </td>
                                            <td class="lastnote">
                                            	<label v-if="info.technologyName != null">{{info.technologyName}}</label>
                                            </td>
                                    		<td class="lastnote">
                                    			<label v-for="(skill, index1) in info.technologySkillData" class="badge mr-1" :class="colours[index%5]">{{skill.skillName}}</label>
                                    		</td>

                                            <td v-if="false" class="recruiter">
                                            	<label>{{technologyinfo.skillset[index].years}}</label>
                                            </td>
                                            <td>
                                            </td>
                                    	</tr>
                                         <tr :class="{ 'norecordfound' : technologyinfo.skillset.length}" >
                                            <td colspan="5" align="center">No item found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
		    </div>
		</div>
	</div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
	import iziToast from 'izitoast';
	export default {
		props: {
            technologyinfo: Object,
        },
        data() {
            return {
                colours: ['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info']
            }
        }
	}
</script>