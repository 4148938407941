<template>
	<div id="main" class="">
		<div class="container-fluid">
			<h5 class="text-center">Project List</h5>
			<div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
				<div class="row">
					<div class="col-md-6 col-sm-6 col-6">
						<h4 class="page-title">Search</h4>
					</div>
					<div class="col-md-6 col-sm-6 col-6">
						<ul class="ul-horizontal box-tools text-right mb-0">
							<li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :class="isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
						</ul>
					</div>
				</div>
				<form id="main-search" :class="isExpand?'d-block':'d-none'" v-on:submit.prevent="">
					<div class="row form-group ten-columns">
						<div class="col-md-3 col-sm-6 col-12 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Name</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group">
										<input class="form-control" v-on:keyup.enter="getProjectName(search.params.projectName)" type="text" v-model="search.params.projectName" />
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-sm-6 col-12 mb-10-mr">
						</div>
						<!-- <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
						</div> -->
						<div class="col-md-3 col-sm-6 col-12 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label style="visibility: hidden;"></label>
								</div>
								<div class="col-md-12">
									<button type="button" @click="resetProjectsSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
									<button type="button" @click="getProjectName(search.params.projectName)" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Find</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row col-md-12 col-sm-12 col-12 py-3 mx-0">
				<div class="col-md-6 col-sm-6 col-6 text-left p-0">
					<h4 class="page-title">Projects
						<span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
						<span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
							<div class="dropdown">
								<a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
								<div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
									<a v-for="(value, index) in pagination.searchlimit" class="dropdown-item" href="javascript:" @click="changeLimit(value)">{{value}}</a>
								</div>
							</div>
						</span>
					</h4>
				</div>
				<div class="col-md-6 col-sm-6 col-6 p-0">
					<button type="button" @click="$emit('loadProjects')" class="btn btn-primary btn-sm pull-right" style="margin-left: 10px;">
						<a href="javascript:" v-b-modal.createProjectModal style="color: #fff;">New Project</a>
					</button>
				</div>
			</div>
			<div class="col-md-12 table-responsive py-3">
				<table class="table table-borderless all-table text-left">
					<thead>
						<tr>
							<th> </th>
							<th>Project Name</th>
							<th>Key</th>
							<th>Owner</th>
							<th>Start Date</th>
							<th>End Date</th>
							<th>Status</th>
							<th class= "pl-5">Action</th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="projectList.length==0">
							<td align="center" colspan="8">No Projects found</td>
						</tr>
						<template v-if="projectList.length" v-for="(projectinfo,index) in projectList">
							<tr>
								<td>
									<a href="Javascript:void(0);" class="expand" data-targetopen="panel1">
										<span @click="getSprints(projectinfo.id)"><i :class="(currentIndex==projectinfo.id) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
										</span>
									</a>
								</td>
								<td>
									<router-link title="View Project" :to="{ name: 'recruitertasks'}">{{projectinfo.projectName}}</router-link>
								</td>
								<td>{{ projectinfo.keyName }}</td>
								<td>{{ projectinfo.recruiterName }}</td>
								<td>{{projectinfo.startDate}}</td>
								<td>{{projectinfo.endDate}}</td>
								<td>{{projectinfo.projectStatus}} </td>	
								<td class= "pl-4">
									<ul class="navbar-nav">
										<li class="nav-item">
											<a href="javascript:" v-b-modal.createSprintModal @click="addSprint(projectinfo.id)" class="pr-2" title="Add Task Group"><span class="fa fa-plus fa-lg pr-2"></span></a>
											<a href="javascript:" v-b-modal.updateProjectModal @click="loadProjectData(projectinfo.id)" class="pr-2" title="Edit Project"><span class="fa fa-pencil fa-lg pr-2"></span></a>
											<a href="javascript:" @click="deleteProject(projectinfo.id)" data-toggle="modal" data-target="#removeProject" class="" title="Delete Project"><span class="fa fa-trash fa-lg pr-2"></span> </a>
										</li>
									</ul>
								</td>
							</tr>
							<tr v-if="currentIndex==projectinfo.id">
								<td colspan="8">
									<div class="col-md-12 table-responsive py-3">
										<table class="table table-borderless all-table text-left">
											<thead>
												<tr>
													<th>Task Group Name</th>
													<th>Task Group Code</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												<tr v-if="options.sprintList.length==0">
													<td align="center" colspan="5">No task groups found</td>
												</tr>
												<template v-if="options.sprintList.length" v-for="(sprintinfo,index) in options.sprintList">
													<tr>
														<td>
															{{sprintinfo.name}}
														</td>
														<td>{{sprintinfo.taskGroupCode}}</td>
														<td>
															<ul class="navbar-nav">
																<li class="nav-item">
																	<a href="javascript:" v-b-modal.updateSprintModal @click="viewTaskGroup(sprintinfo.id)" class="pr-2"><span class="fa fa-pencil fa-lg pr-2" title="Edit"></span></a>
																	<a href="javascript:" @click="deleteSprint(sprintinfo.id,sprintinfo.projectId)" data-toggle="modal" data-target="#removeSprint" class=""><span class="fa fa-trash fa-lg pr-2" title="Delete"></span> </a>
																</li>
															</ul>
														</td>
													</tr>
												</template>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<div class="col-md-12 mb-5">
				<b-pagination v-if="projectList.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="$emit('loadProjects')"></b-pagination>
			</div>
		</div>
		<Dialog id="removeProject" :onYes="delete_Project" :returnParams="delProjectParam" title="Delete Confirmation" message="Are you sure to delete ?" />
		<Dialog id="removeSprint" :onYes="delete_Sprint" :returnParams="delSprintParam" title="Delete Confirmation" message="Are you sure to delete ?" />
		<AddProjectModal :editor="editor" :options="options" @getProjectList="getProjectList" @loadAddProject="$emit('loadProjects')"/>
		<EditProjectModal :project="saveProject" :options="options" :editor="editor" @getProjectList="getProjectList" @loadAddProject="$emit('loadProjects')"/> 
		<AddTaskGroupModal :project="saveProject" :options="options" :editor="editor" @getProjectList="getProjectList" @loadSprints="getSprints(saveSprint.projectId)"/>
		<EditTaskGroupModal :editTaskGroup="editTaskGroup" :project="saveProject" :options="options" :editor="editor" @loadSprints="getSprints(saveSprint.projectId)" @getProjectList="getProjectList" />
	</div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import Editor from '@tinymce/tinymce-vue';
import AddProjectModal from '@/components/recruiters/blocks/tasks/AddProject';
import EditProjectModal from '@/components/recruiters/blocks/tasks/EditProject';
import AddTaskGroupModal from '@/components/recruiters/blocks/tasks/AddTaskGroup';
import EditTaskGroupModal from '@/components/recruiters/blocks/tasks/EditTaskGroup';
export default {
	props: {
		projectList: Array,
		pagination: Object,
		searchParams: Object,
		getProjectName:Function
	},
	components: {
		Editor,
		AddProjectModal,
		EditProjectModal,
		AddTaskGroupModal,
		EditTaskGroupModal
	},
	data() {
		return {
			isExpand: false,
			currentIndex: null,
			options: {
				sprintList: [],
				projectList: [],
				recruitersList: this.$store.getters.getRecruiterList
			},
			search: {
				params: {
					projectName: ""
				}
			},
			saveProject: {
				id: null,
				projectName: "",
				keyName: "",
				clientId: "",
				startDate: "",
				endDate: "",
				description: "",
				status: "",
				userType: "recruiter",
				projectMembers: []
			},
			saveSprint: {
				id: "",
				projectId: "",
				title: ""
			},
			editTaskGroup: {
				id: "",
				projectId: "",
				title: "",
				taskGroupCode: "",
				description: "",
				taskRelatedTo: "",
				startDate: "",
				endDate: "",
				status: "",
				userType: "recruiter",
				taskGroupMembers: []
			},
			delProjectParam: {
				index: -1,
				id: 0
			},
			delSprintParam: {
				index: -1,
				id: 0
			},
			editor: {
				toolbarOptions: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
				initOptions: {
					content_style: 'body { font-size:  13px; }',
					height: 200,
					branding: false,
					menubar: false,
					plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists', 'advlist'],
					relative_urls: false,
					remove_script_host: false,
				}
			}
		}
	},
	watch: {
		projectList(newValue, oldValue) {}
	},
	validations: {
		saveProject: {
			projectName: {
				required,
				minLength: minLength(3),
				maxLength: maxLength(50)
			}
		},
		saveSprint: {
			title: {
				required
			}
		}
	},
	mounted() {
		this.$emit('loadProjects');
		this.getProjectList();
	},
	methods: {
		changeLimit: function(newValue) {
			this.$emit('changeLimit', newValue);
			this.$emit('loadProjects');
		},
		resetProjectsSearch() {
			this.search.params.projectName = "";
			this.$emit('resetProjectsSearch');
			this.getProjectList();
		},
		getProjectList() {
			axios.post(this.$store.getters.getAPIBasePath + '/projectresource/list/all/projects?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
			.then(response => {
				let result = response.data;
				this.options.projectList = result.projectList;
				this.pagination.totalRecords = result.pagination[0].total;
				this.pagination.currentPage = result.pagination[0].currentPage;
			})
			.catch(error => {
				console.log(error);
			});
		},
		loadProjectData(projectId) {
			axios.get(this.$store.getters.getAPIBasePath + '/projectresource/view/project/' + projectId)
			.then(response => {
				if (response.data.status) {
					let projectData = response.data.project;
					this.saveProject.id = projectId;
					this.saveProject.projectName = projectData.projectName;
					this.saveProject.keyName = projectData.keyName;
					this.saveProject.clientId = projectData.clientId;
					this.saveProject.startDate = moment(String(projectData.startDate)).format('YYYY-MM-DD');
					this.saveProject.endDate = moment(String(projectData.endDate)).format('YYYY-MM-DD');
					this.saveProject.description = projectData.description;
					this.saveProject.status = projectData.status;
					if(projectData.projectMembersList)
						this.saveProject.projectMembers = this.options.recruitersList.filter(Recruiter => projectData.projectMembersList.some(ProjectMember => ProjectMember.userId == Recruiter.id));
					else
						this.saveProject.projectMembers = [];
				}
			})
			.catch(error => {
				console.log(error);
			});
		},
		addProject: function() {
			if (!this.validateProjectData()) {
				return false;
			}
			axios.post(this.$store.getters.getAPIBasePath + '/projectresource/add/project', this.saveProject)
			.then(response => {
				if (response.data.status == "ACCEPTED") {
					iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
					this.$refs.createProjectModal.hide();
					this.$emit('loadProjects');
				} else {
					iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
				}
			})
			.catch(function(error) {
				console.log(error);
				iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
			});
		},
		updateProject: function() {
			if (!this.validateProjectData()) {
				return false;
			}
			axios.post(this.$store.getters.getAPIBasePath + '/projectresource/update/project', this.saveProject)
			.then(response => {
				if (response.data.status == "ACCEPTED") {
					iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
					this.$refs.updateProjectModal.hide();
					this.refreshProjectData();
					this.$emit('loadProjects');
				} else {
					iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
				}
			})
			.catch(function(error) {
				console.log(error);
				iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
			});
		},
		deleteProject(id) {
			this.delProjectParam.id = id;
			return;
		},
		delete_Project() {
			axios.delete(this.$store.getters.getAPIBasePath + '/projectresource/delete/project/' + this.delProjectParam.id)
			.then(response => {
				var result = response.data;
				if (result.status == "OK") {
					iziToast.success({title: 'Success', message: result.message, position: 'topRight'});
					this.$emit('loadProjects');
					this.getProjectList();
				}
			})
			.catch(error => {
				 iziToast.error({
                        title: "Error",
                        message: this.$config.ProSetDelErr,
                        position: "topRight"
                    });
				console.log(error);
			});
		},
		getSprints(projectId) {
			if (this.currentIndex == projectId) {
				this.currentIndex = null;
			} else {
				this.currentIndex = projectId;
			}
			axios.get(this.$store.getters.getAPIBasePath + '/projectresource/list/sprint/' + projectId)
			.then(response => {
				this.options.sprintList = response.data.taskGroupList;
			})
			.catch(error => {
				console.log(error);
			});
		},
		addSprint(projectId) {
			this.saveSprint.projectId = projectId;
			this.saveProject.id = projectId;			
			this.loadProjectData(projectId);
		},
		add_Sprint: function() {
			if (!this.validateSprintData()) {
				return false;
			}
			axios.post(this.$store.getters.getAPIBasePath + '/projectresource/add/project/sprint', this.saveSprint)
			.then(response => {
				if (response.data.status == "ACCEPTED") {
					iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
					this.$refs.createSprintModal.hide();
					this.refreshSprintData();
					this.getSprints(this.saveSprint.projectId);
				} else {
					iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
				}
			})
			.catch(function(error) {
				console.log(error);
				iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
			});
		},
		deleteSprint(id, projectId) {
			this.delSprintParam.id = id;
			this.saveSprint.projectId = projectId;
			return;
		},
		delete_Sprint: function() {
			axios.delete(this.$store.getters.getAPIBasePath + '/projectresource/delete/project/sprint/' + this.delSprintParam.id)
			.then(response => {
				var result = response.data;
				if (result.status == "OK") {
					iziToast.success({title: 'Success', message: result.message, position: 'topRight'});
						//this.$emit('loadProjects');
						this.getSprints(this.saveSprint.projectId);
					}
				})
			.catch(error => {
				console.log(error);
			});
		},
		generateKey: function(name) {
			let keywords = [];
			this.saveProject.keyName = "";
			keywords = name.split(" ");
			let self = this;
			keywords.map(function(keyword) {
				self.saveProject.keyName += keyword.charAt(0);
			});
			this.saveProject.keyName = this.saveProject.keyName.toUpperCase();
		},
		refreshProjectData: function() {
			this.saveProject.projectName = "";
			this.saveProject.keyName = "";
		},
		refreshSprintData: function() {
			this.saveSprint.title = "";
		},
		validateProjectData: function() {
			this.$v.saveProject.$touch();
			if (this.$v.saveProject.$invalid) {
				return false;
			}
			return true;
		},
		validateSprintData: function() {
			this.$v.saveSprint.$touch();
			if (this.$v.saveSprint.$invalid) {
				return false;
			}
			return true;
		},
		viewTaskGroup: function(sprintId) {
			axios.get(this.$store.getters.getAPIBasePath + '/projectresource/view/project/sprint/' + sprintId)
			.then(response => {
				if (response.data.status) {
					let taskGroupData = response.data.taskGroup;
					this.editTaskGroup.id = taskGroupData.id;
					this.editTaskGroup.projectId = taskGroupData.projectId;
					this.editTaskGroup.title = taskGroupData.title;
					this.editTaskGroup.taskGroupCode = taskGroupData.taskGroupCode;
					this.editTaskGroup.description = taskGroupData.description;
					this.editTaskGroup.taskRelatedTo = taskGroupData.taskRelatedTo;
					this.editTaskGroup.startDate = moment(String(taskGroupData.startDate)).format('YYYY-MM-DD');
					this.editTaskGroup.endDate = moment(String(taskGroupData.endDate)).format('YYYY-MM-DD');
					this.editTaskGroup.status = taskGroupData.status;
					if(taskGroupData.taskGroupMembersList)
						this.editTaskGroup.taskGroupMembers = this.options.recruitersList.filter(Recruiter => taskGroupData.taskGroupMembersList.some(TaskGroupMember => TaskGroupMember.userId == Recruiter.id));
					else 
						this.editTaskGroup.taskGroupMembers = [];
				}
			})
			.catch(function(error) {
				console.log(error);
			});
		},
		getRecruiterName: function(recruiterId) {
			let result_name = "";
			this.options.recruitersList.map(function(Recruiter,key){
				if(Recruiter.id==recruiterId)
					result_name = Recruiter.recruiterName;
			});
			return result_name;
		}
	}
}

</script>
