<template>
    <div class="form-control" :class="cssClass" :id="id">
        <ul>
            <li v-for="(item, index) in items" :key="index" class="item rounded"><span class="text">{{ item }}</span><a href="javascript:;" @click="removeItem(index)"><i class="ti-close"></i></a></li>
            <li v-if="items.length">
                <input  type="text" class="newItem" v-model="item" v-on:keyup.enter="addItem" :placeholder="placeholder" @keypress="blockSpecialChar($event)"/>
            </li>
            <li v-else class="input_sty">
                <input type="text" class="input_sty" v-model="item" v-on:keyup.enter="addItem" :placeholder="placeholder" @keypress="blockSpecialChar($event)"/>
            </li>
        </ul>
    </div>
</template>
<style>
div.myctrl {
    padding: 2px 1px;
    display: block;
    height: auto;
}

div.myctrl>ul {
    list-style-type: none;
    margin: 0;
}

div.myctrl>ul>li {
    display: inline-block;
    padding: 0 2px 2px 0;
}

div.myctrl>ul>li.item {
    background-color: #84b9f1;
    margin: 2px;
}

div.myctrl>ul>li.item>span.text {
    padding: 2px 2px;
    color: #000000;
}

div.myctrl>ul>li.item>a>i {
    font-size: 8px;
    color: #f52424;
}

div.myctrl>ul>li>input {
    border: none;
    margin-top: 3px;
}

.input_sty{
    width:100%
}
</style>
<script>
export default {
    props: {
        id: { type: String, default: 'myInput' },
        cssClass: { type: String, default: 'myctrl' },
        items: { type: Array, default: () => [] },
        placeholder: { type: String, default: 'Enter here' }
    },
    data() {
        return {
            item: ''
        }
    },
    methods: {
        addItem() {
            this.item = this.item.trim();
            if (this.item === "")
                return;
            this.items.push(this.item);
            this.item = '';
        },
        removeItem(index) {
            //console.log(index);
            this.items.splice(index, 1);
        },
        blockSpecialChar(e) {
            if (!((event.keyCode >= 64) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122)|| (event.keyCode >= 48) && (event.keyCode <= 57)||  (event.keyCode <= 43) && (event.keyCode <= 57) )) {
                e.preventDefault();
            }
            return;
        }
    }
}

</script>
