<template>
    <div id="main" class="mt-70">
        <div id="quick-search" class="container-fluid mtb-4 bg-white p-tb-15">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row no-gutters">
                    <b-tabs  v-model="currentTab" small card class="tab-list driver-tab-list tab-bg">
                        <b-tab title="Job List">
                            <Listjobs/>
                        </b-tab>
                        <b-tab title="Applied Jobs">
                            <AppliedJobs :key="value" />
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
            
    </div>
</template>

<script>
import iziToast from 'izitoast';
import Listjobs from '@/components/candidate/Listjobs';
import AppliedJobs from '@/components/candidate/AppliedJobs';

export default {
    components : {
        Listjobs,
        AppliedJobs
    },
    data() {
        return {
            value: 0,
            currentTab:0,
        }
    },
      mounted() {
        this.$root.$on('reloadappliedjobs', () => {
            this.reload();
        })
    },
    
    methods:{
        changeTab: function(tab){
            this.currentTab=tab;
        },
        reload() {
            this.value++;
        }
        
    }
}
</script>