<template>
    <div id="wrapper">
        <div id="login">
            <div class="container-fluid">
                <div class="row h-50">
                    <div class="col-sm-12 h-100 d-table">
                        <div class="d-table-cell align-middle login_div">
                            <div class="col-md-4 offset-md-4">
                                <div v-if="!showForm" class="box-wrapper shadow-sm p-4 mb-4 bg-white rounded pos-rel">
                                    <a href="" class="logo text-center"><img src="@/assets/images/talentturbo1.png"></a>
                                    <h4 class="text-center">Forgot Password</h4>
                                    <form @submit.prevent="resetPassword()" id="login-form" class="tt_form">
                                        <div class="row form-group">
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                                        <label class="labl">Email</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                                        <input v-model="pwdReset.email" type="text" name="email" class="form-control" autocomplete="off" @blur="$v.pwdReset.email.$touch">
                                                    </div>
                                                    <label v-if="$v.pwdReset.email.$error && !$v.pwdReset.email.required" class="text-danger pl-4">Please enter Email</label>
                                                    <label v-if="$v.pwdReset.email.$error && !$v.pwdReset.email.email" class="text-danger pl-4">Please enter a valid email</label>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="row">
                                            <div class="col-md-12">
                                                <button type="submit" class="btn btn-primary  float-right">Reset Password</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div v-if="showForm" class="box-wrapper shadow-sm p-4 mb-4 bg-white rounded pos-rel">
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col-sm-12 clientinfo">
                                                <a href="" class="logo text-center"><img src="@/assets/images/talentturbo1.png"></a>
                                                <p class="text-center">Reset request sent to your registered email address</p>
                                                <p class="text-center">Thanks!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            pwdReset: {
                email: '',
                userType: this.$route.params.userType,
            },
            pwdReseterrors: [],
            showForm: false
        }
    },
    mounted() {},
    validations: {
        pwdReset: {
            email: {
                required,
                email,
            }
        }
    },
    methods: {
        resetPassword() {
            this.$v.pwdReset.$touch();
            if (this.$v.pwdReset.$invalid) {
                return false;
            } else {
                axios.post(this.$store.getters.getAPIBasePath + '/userresource/reset/password', this.pwdReset)
                    .then(response => {
                        let result = response.data;
                        this.pwdReset.email = "";
                        this.$v.pwdReset.$reset();
                        this.showForm = true;
                    })
                    .catch(error => {
                        console.log(error);
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.TryAg,
                            position: 'topRight'
                        });
                    });
            }
        }
    }
}

</script>
