<template>
    <div class="modal" v-bind:id="id" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">{{title}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cancelClick">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <multiItemInput :id="thisId" :items="inputItems" :placeholder="inputPlaceholder" :ref="thisId" />
                </div>
                <div class="modal-footer">                                            
                    <button type="button" class="btn btn-primary" @click="saveClick" data-dismiss="modal" >Save</button>
                    <button type="button" class="btn btn-secondary" @click="cancelClick" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import multiItemInput from '../plugins/multiItemInput';
export default {
    components: {
        multiItemInput
    },
    data() {
        return {
            thisId:this.id+"0012"
        };
    },
    props: {
        id:{type:String, default:'_confirm1'},
        title:{type:String, default:'Add new items'},
        inputPlaceholder:{type:String, default:'Enter here'},        
        inputItems:{type:Array, default:[]},
        onSave:{type:Function,default:function(){ }},
        onCancel:{type:Function,default:function(){}}
    },    
    methods:{        
        saveClick()
        {   
            this.$refs[this.thisId].addItem();
            this.onSave();
        },
        cancelClick(){            
            this.onCancel();
        }
    }
}
</script>
