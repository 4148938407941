<template>
    <div class="task-status-update">
        <div class="modal-header text-white blue-bg-title pt-0 pb-0">
            <h5 class="modal-title pt-2" id="FeedbackModal">Task Status Update</h5>
            <ul>
                <li>
                    <a href="javascript:" @click="$emit('closeModal');updateLogActivity();" class="ctm-btn close text-white opacity-100" aria-label="Close"><span aria-hidden="true">&times;</span>
                    </a>
                </li>
                <li>
                </li>
            </ul>
        </div>
        <aside class="main-sidebar right-sidemenu float-right">
            <section class="sidebar">
                <ul class="sidebar-menu" data-widget="tree">
                    <li v-if="false"><a href="#" @click="updateNotification('sourcing');stopWatch()"><img alt="images" src="@/assets/icons/web-design-hover.png" class="menuicon"> <span>Sourcing</span></a></li>
                    <li>
                        <!--    <a href="#" @click="updateNotification('add candidate');stopWatch()"><img alt="images" src="@/assets/icons/user-silhouette-hover.png" class="menuicon"> <span>New Candidate</span></a> -->
                        <router-link :to="{ name: 'addcandidate' }"><img alt="images" src="@/assets/icons/user-silhouette-hover.png" class="menuicon"><span>New Candidate</span> </router-link>
                    </li>
                    <li>
                        <!-- <a href="#" @click="updateNotification('add job');stopWatch()"><img alt="images" src="@/assets/icons/working-with-laptop-hover.png" class="menuicon"> <span>New Job</span></a> -->
                        <router-link :to="{ name: 'addjob' }"><img alt="images" src="@/assets/icons/working-with-laptop-hover.png" class="menuicon"><span>New Job</span> </router-link>
                    </li>
                </ul>
            </section>
        </aside>
        <div class="modal-body">
            <!-- <form @submit.stop.prevent="updateTask"> -->
                <h6 class="text-center py-2 text-uppercase"><strong>{{ taskInfo.title }}<span v-if="checkEmpty(taskInfo.taskCode)"> - [{{taskInfo.taskCode}}]</span></strong></h6>
                <div class="card pull-right">
                    <div class="card-body activity">
                        <!-- <label class="">Activity </label>
                        <div class="col-md-12">
                            <select name="activity" id="activity">
                                <option value="">Select</option>
                                <option v-for="(activity,index) in activityList" :value="activity.id">{{ activity.activityName }}</option>
                            </select>
                        </div> -->
                        <label for="tasktypeactivty" class="col-form-label pull-left">Activity</label><span id="time" class="float-right" v-html="time"></span>
                        <select v-model="activity.activityId" name="tasktypeactivty" id="tasktypeactivty" class="form-control">
                            <option value="">Select</option>
                            <option v-for="Option in activityList" :value="Option.id">{{ Option.activityName }}</option>
                        </select>
                        <label v-if="$v.activity.activityId.$error && !$v.activity.activityId.required" class="text-danger">Please select the activity</label>
                        <div style="margin-top: 15px;">
                            <b-btn variant="primary" class="ml-2 flex-fill" @click="startActivity" :disabled="disableButton">Start Activity</b-btn>
                            <b-btn variant="danger" class="ml-2 flex-fill" @click="endActivity">End Activity</b-btn>
                        </div>
                    </div>
                </div>
                <!-- <div class="row"> -->
                <div class="d-flex flex-row justify-content-around py-3">
                    <span v-if="taskInfo.startDate" class="start-date row mx-2">
                        <h6 class="mx-3">Start Date</h6>
                        <label class="mx-3"><i class="ti-time mr-2"></i>{{ taskInfo.startDate | formatDate}}</label>
                    </span>
                    <span class="due-date row mx-2 text-right">
                        <h6 class="mx-3">Due Date</h6>
                        <label v-if="taskInfo.dueDate > currentDate" class="mx-3"><i class="ti-time mr-2"></i>{{ taskInfo.dueDate | formatDate}}</label>
                        <label v-if="taskInfo.dueDate <= currentDate" class="mx-3 text-danger"><i class="ti-time mr-2 text-danger"></i>{{ taskInfo.dueDate | formatDate}}</label>
                        <!--  <span v-if="Task.dueDate > currentDate" class="due-date flex-fill"><i title="Due date" class="ti-time mr-2"></i>{{ Task.dueDate | formatDate}} </span>
                        <span v-if="Task.dueDate <= currentDate" class="due-date flex-fill text-danger"><i title="Due date" class="ti-time mr-2"></i>{{ Task.dueDate | formatDate}}</span> -->
                    </span>
                </div>
                <!--  </div> -->
                <div class="row">
                    <div class="d-flex flex-row mx-3 px-1" :class="taskInfo.relatedTo?'w-100':'w-100'">
                        <div class="form-group col-md-4 col-sm-12 pl-0">
                            <!--  <label for="taskType" class="col-form-label"><strong>Task Type</strong></label>
                            <select v-model="taskInfo.taskType" name="taskType" id="taskType" class="form-control">
                                <option value="">Select</option>
                                <option v-for="Option in options.taskTypeList" :value="Option.id">{{ Option.name }}</option>
                            </select> -->
                            <h6>
                                <strong>Task Type</strong>
                            </h6>
                            <label class="bg-tturbo-light w-100 p-2">{{ taskInfo.taskType | getNameById(options.taskTypeList)}}</label>
                        </div>
                        <div class="form-group col-md-4 col-sm-12">
                            <h6>
                                <strong>Task Group</strong>
                            </h6>
                            <label class="bg-tturbo-light w-100 p-2">{{ taskInfo.taskGroupId | getNameById(options.sprintList) }}</label>
                        </div>
                        <div v-if="taskInfo.priorityId != null" class="form-group col-md-4 col-sm-12">
                            <h6>
                                <strong>Priority</strong>
                            </h6>
                            <label class="bg-tturbo-light w-100 p-2">{{ taskInfo.priorityId | getNameById(options.priorityList) }}</label>
                        </div>
                        <div v-if="taskInfo.relatedTo" class="flex-fill ml-5">
                            <h6>
                                <strong>Related To</strong>
                            </h6>
                            <label class="bg-tturbo-light w-100 p-2">{{ taskInfo.relatedTo }}</label>
                        </div>
                    </div>
                </div>
                <div class="assignees">
                    <span v-if="taskInfo.recruiterName">{{ taskInfo.recruiterName }}</span>
                </div>
                <div v-html="taskInfo.description" class="description col-md-12"></div>
                <div class="row col-md-12 mx-0 px-0">
                    <div class="form-group col-md-4 col-sm-12 pb-2">
                        <label for="taskType" class="col-form-label">Assigned By</label>
                        <span class="form-control">{{ taskInfo.assignedByName }}</span>
                    </div>
                    <div class="task-grp form-group col-md-4 col-sm-12 slideinputcontainer">
                        <label for="progressPercentage" class="col-form-label">Progress</label>
                        <input v-model="taskInfo.progressPercentage" type="range" min="5" max="100" class="slider-input" id="progressPercentage">
                        <span class="pl-1">{{ taskInfo.progressPercentage }}%</span>
                    </div>
                    <div class="form-group col-md-4 col-sm-12">
                        <label for="taskStatus" class="col-form-label">Status</label>
                        <select v-model="taskInfo.statusId" id="taskStatus" name="taskStatus" class="form-control">
                            <option v-for="Option in options.statusList" :value="Option.id">{{ Option.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="taskCheckList col-md-12 mb-4">
                    <div class="card">
                        <div class="card-body">
                            <label><b>Checklist</b></label>
                            <div v-if="true" class="d-flex flex-column">
                                <label v-for="(taskCheckList,index) in taskInfo.taskCheckList" class="checklist px-2 m-0">
                                    <input type="checkbox" @change="$emit('setTaskCheckList',taskCheckList)" v-model="taskCheckList.isCompleted"> {{ taskCheckList.title }}
                                </label>
                            </div>
                            <div v-if="true" class="d-flex flex-row">
                                <input type="text" class="form-control mx-2 flex-fill" v-model="elements.checkListTag" placeholder="Add an item..." @keyup.enter="pushChecklistTag">
                                <b-btn variant="primary" @click="pushChecklistTag" class="ml-2 flex-fill">Add</b-btn>
                            </div>
                            <!-- <label v-if="$v.elements.checkListTag.$error && !$v.elements.checkListTag.minLength" class="text-danger">Checklist must have at least {{$v.elements.checkListTag.$params.minLength.min}} letters.</label>
                            <label v-if="$v.elements.checkListTag.$error && !$v.elements.checkListTag.maxLength" class="text-danger">Checklist must not exceed {{$v.elements.checkListTag.$params.maxLength.max}} letters.</label> -->
                        </div>
                    </div>
                </div>
                <div class="taskComments col-md-12 mb-4">
                    <div class="card">
                        <div class="card-body scroll-down-list">
                            <label><b>Comments</b></label>
                            <div v-for="(taskComments,index) in taskInfo.taskComment" class="comments my-2 col-md-10 offset-md-1 p-3">
                                <div class="d-flex flex-row">
                                    <span :data-letters="taskComments.userName | getAcronym" :title="taskComments.userName"></span>
                                    <div class="d-flex flex-column w-100">
                                        <div class="d-flex userName py-1 justify-content-between">
                                            <span>{{ taskComments.userName }}</span>
                                            <span class="">{{ taskComments.updatedAt | formatCustomTime('DD-MMM-YYYY hh:mm A') }}</span>
                                        </div>
                                        <div class="task-comment-view-edit py-1">
                                            <span v-if="modeInfo.mode=='view' || modeInfo.id!=taskComments.id" class="comment-box" v-html="taskComments.comment"></span>
                                            <div v-if="modeInfo.mode=='edit' && modeInfo.type=='comments' && modeInfo.id==taskComments.id" class="d-flex flex-row">
                                                <!-- <input type="text" v-model="taskComments.comment" class="form-control flex-fill mx-1" /> -->
                                                <div class="flex-fill d-flex flex-column">
                                                    <div class="flex-row py-1"> 
                                                <editor class="form-control flex-fill mx-1" api-key="yn4wx6bfcohfz6bwlu5k4urno027lgfkcpxolz0f6k4kku01" :init="{menubar: false,toolbar:false,statusbar: false,height:100,content_style: 'body { line-height:0.4}'}" v-model="taskComments.comment"></editor>
                                               </div></div>
                                            </div>
                                        </div>
                                        <div v-if="modeInfo.mode=='view' && taskComments.userId==elements.currentUser.accountId" class="d-flex flex-row taskCommentActions py-1">
                                            <a href="javascript:" @click="$emit('changeCMode','edit','comments',taskComments.id);refreshedits(index)" class="mr-2">Edit</a>
                                            <a href="javascript:" class="ml-2" @click="removecomment(taskComments.id)" data-toggle="modal" data-target="#removecomment" title="Remove">Delete</a>
                                        </div>
                                    </div>
                                     <i v-if="modeInfo.mode=='edit' && modeInfo.type=='comments' && modeInfo.id==taskComments.id" class="fa fa-check text-tturbo flex-fill mx-1 align-self-center" title="Update" style="font-size: 1.5rem" @click="$emit('updateTaskComment',taskComments)"></i>
                                    <i v-if="modeInfo.mode=='edit' && modeInfo.type=='comments' && modeInfo.id==taskComments.id" @click="cancelTaskComments(index,taskComments);" class="fa fa-times text-danger flex-fill mx-1 align-self-center" title="Cancel" style="font-size: 1.5rem"></i>
                                </div>
                            </div>
                            <div class="d-flex flex-row add-comments py-3">
                                <span :data-letters="elements.currentUser.name | getAcronym" :title="elements.currentUser.name" class="mr-3"></span>
                                <div class="flex-fill d-flex flex-column">
                                    <!-- <div v-if="false" class="flex-row py-1">
                                        <span class="align-self-center">Activity</span>
                                        <select v-model="elements.activity" class="form-control mx-2">
                                            <option value="">Select</option>
                                            <option v-for="Option in activityList" :value="Option.id">{{ Option.activityName }}</option>
                                        </select>
                                        <span class="align-self-center">Duration</span>
                                        <input type="time" class="form-control ml-2" v-model="elements.duration">
                                    </div> -->
                                    <div class="flex-row py-1">
                                        <editor api-key="yn4wx6bfcohfz6bwlu5k4urno027lgfkcpxolz0f6k4kku01" :init="{menubar: false,toolbar:false,statusbar: false,height:100,content_style: 'body { line-height:0.4}'}" v-model="elements.commentTag"></editor>
                                        <label v-if="$v.elements.commentTag.$error && !$v.elements.commentTag.required" class="text-danger">Comment is required</label>
                                        <label v-if="$v.elements.commentTag.$error && !$v.elements.commentTag.minLength" class="text-danger">Comment must have at least {{$v.elements.commentTag.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.elements.commentTag.$error && !$v.elements.commentTag.maxLength" class="text-danger">Comment must not exceed {{$v.elements.commentTag.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                            </div>
                              <b-btn variant="primary" @click="addCommentTag" class="ml-4 float-right">Add</b-btn>
                        </div>
                    </div>
                </div>
                <div class="card mr_ml">
                    <div class="row ml-1 mrt-1">
                        <div class="col-md-12 col-sm-12 col-12 m-t-15">
                            <h4 class="page-title">Attachments
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{taskInfo.taskAttachment.length}})</span>      
                            </h4>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12 task_attachment">
                            <div v-for="taskAttachments in taskInfo.taskAttachment" class="col-md-2 col-sm-3 col-12 task_mb_pd">
                                <div v-if="taskAttachments.filename !='' && taskAttachments.fileType == 'png' || taskAttachments.fileType == 'jpeg' || taskAttachments.fileType == 'jpg'" class="col-md-12 col-sm-12 col-12 pd_right">
                                    <img width="130" height="98" v-bind:src="taskAttachments.filePath" @click="Open(taskAttachments.filePath)" class="br_5px" /><div class="tsknmeUpdate" @click="Open(taskAttachments.filePath)"><a  :href="taskAttachments.filePath" class="color-black" target="_blank"> {{taskAttachments.fileName}}</a></div>
                                
                                </div>
                                <div v-if="taskAttachments.filename !='' && taskAttachments.fileType == 'docx' || taskAttachments.fileType == 'doc'"  class="col-md-12 col-sm-12 col-12 pd_right">
                                    <img src="@/assets/images/word.jpg" alt="images" height="98" width="130" @click="Open(taskAttachments.filePath)" class="br_5px"><div class="tsknmeUpdate" @click="Open(taskAttachments.filePath)" ><a  :href="taskAttachments.filePath" class="color-black" target="_blank"> {{taskAttachments.fileName}}</a></div></img>
                                </div>
                                <div v-if="taskAttachments.filename !='' && taskAttachments.fileType == 'pdf'"  class="col-md-12 col-sm-12 col-12 pd_right">
                                    <img src="@/assets/images/Pdf.jpg" alt="images" height="98" width="130" @click="Open(taskAttachments.filePath)" class="br_5px"><div class="tsknmeUpdate" @click="Open(taskAttachments.filePath)"><a  :href="taskAttachments.filePath" class="color-black" target="_blank"> {{taskAttachments.fileName}}</a></div></img>
                                </div>
                                <div v-if="taskAttachments.filename !='' && taskAttachments.fileType == 'xlsx'"  class="col-md-12 col-sm-12 col-12 pd_right">
                                    <img src="@/assets/images/microsoftexcel.jpg" alt="images" height="98" width="130" @click="Open(taskAttachments.filePath)" class="br_5px"><div class="tsknmeUpdate" @click="Open(taskAttachments.filePath)"><a  :href="taskAttachments.filePath" class="color-black" target="_blank"> {{taskAttachments.fileName}}</a></div></img>
                                </div>
                                <div v-if="taskAttachments.filename !='' && taskAttachments.fileType != 'png' && taskAttachments.fileType != 'jpeg' && taskAttachments.fileType != 'jpg' && taskAttachments.fileType != 'xlsx' && taskAttachments.fileType != 'pdf' && taskAttachments.fileType != 'docx' && taskAttachments.fileType != 'doc'"  class="col-md-12 col-sm-12 col-12 pd_right">
                                    <img width="130" height="98" src="@/assets/images/file2.png"  class="br_5px" /><div class="tsknmeUpdate" @click="Open(taskAttachments.filePath)"><a  :href="taskAttachments.filePath" class="color-black" target="_blank"> {{taskAttachments.fileName}}</a></div>
                                
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12 mb-18" v-if="taskInfo.taskAttachment == null || taskInfo.taskAttachment==undefined || taskInfo.taskAttachment.length==0">No attachment found</div>  
                    </div>
                    <div class="pt-2 mb-18" >
                        <div class="d-flex_flt-r col-md-2 col-sm-2 col-lg-2">
                            <a href="javascript:" class="float-right button-add" @click="addInput"><i class="fa fa-plus-circle pr-2 action-link"></i>Add More</a>
                        </div>
                        <div class=" col-md-10 col-sm-10 col-lg-10 pt-2" v-for="(input, index) in addMore">
                            <input type="file" class="form-control mx-2 h-auto flex-fill upload" ref="file" id="file" placeholder="Add Attachments"  v-on:change="handleMultiFileUpload($event)" :key="fileInputKey">              
                        </div>
                    </div>
                </div>
                <div class="row mt-22 ml-1 mr-1">
                    <div class="col-md-6 col-sm-6 col-6">
                        <h4 class="page-title">Activity
                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                <div class="dropdown">
                                    <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                    <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                        <a v-for="(value, index) in pagination.searchlimit" class="dropdown-item" href="javascript:" @click="changeLimit(value)">{{value}}</a>
                                    </div>
                                </div>
                            </span>
                        </h4>
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <ul class="ul-horizontal box-tools text-right mb-0">
                            <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :class="isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
                        </ul>
                    </div>
                </div>
                <div class="taskActivity col-md-12 mb-4" :class="isExpand?'d-block':'d-none'">
                    <table class="table table-borderless all-table">
                        <thead>
                            <tr>
                                <th>Sl.No</th>
                                <th>Activity</th>
                                <th>Start time</th>
                                <th>End time</th>
                                <th>Duration <span class="text-lowercase">(hh:mm)</span></th>
                                <th>Comment</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="activityLogList.length==0">
                                <td align="center" colspan="6">No Activity found</td>
                            </tr>
                            <template v-if="activityLogList.length" v-for="(activityinfo,index) in activityLogList">
                                <tr>
                                    <td>{{((pagination.currentPage-1) * (pagination.rowsPerPage)) + (index+1)}}</td>
                                    <td>{{activityinfo.activityName}}</td>
                                    <td>{{ activityinfo.startTime | formatDateTime}}</td>
                                    <td>{{activityinfo.endTime | formatDateTime}}</td>
                                    <td>{{activityinfo.duration }}</td>
                                    <td :title="activityinfo.description"> {{getSubstring(activityinfo.description)}}</td>
                                </tr>
                            </template>
                        </tbody>
                        </tbody>
                    </table>
                    <div class="col-md-12 mb-5">
                        <b-pagination v-if="activityLogList.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadTaskActivityLog(taskInfo.id)"></b-pagination>
                    </div>
                </div>
                <div class="clearboth"></div>
                <div class="row  ml-1 mr-1 ">
                    <div class="col-md-6 col-sm-6 col-6 pt-3">
                        <h4 class="page-title">Deliverables
                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{deliverablesPagination.totalRecords}} - Total)</span>
                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                <div class="dropdown">
                                    <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                    <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                        <a v-for="(value, index) in deliverablesPagination.searchlimit" class="dropdown-item" href="javascript:" @click="changeDeliverablesLimit(value)">{{value}}</a>
                                    </div>
                                </div>
                            </span>
                        </h4>
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <ul class="ul-horizontal box-tools text-right mb-0">
                            <li class="pt-3 float-right"><a href="Javascript:void(0);" v-on:click="isDeliverablesExpand=!isDeliverablesExpand;" data-target="main-search" class="boxhide"><span :class="isDeliverablesExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
                        </ul>
                    </div>
                </div>
                <div class="taskActivity col-md-12 mb-4" :class="isDeliverablesExpand?'d-block':'d-none'">
                    <table class="table table-borderless all-table">
                        <thead>
                            <tr>
                                <th>Sl.No</th>
                                <th>Activity</th>
                                <th>Date</th>
                                <th>Incident Type</th>
                                <th>Action</th>
                                <th>Deliverables</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="deliverablesList.length==0">
                                <td align="center" colspan="6">No Deliverable found</td>
                            </tr>
                            <template v-if="deliverablesList.length" v-for="(deliverableinfo,index) in deliverablesList">
                                <tr>
                                    <td>{{((deliverablesPagination.currentPage-1) * (deliverablesPagination.rowsPerPage)) + (index+1)}}</td>
                                    <td>{{deliverableinfo.activityName}}</td>
                                    <td>{{ deliverableinfo.createdAt | formatDateTime}}</td>
                                    <td>{{deliverableinfo.incidentTypeName}}</td>
                                    <td>{{deliverableinfo.action}}</td>
                                    <td> <a href="javascript:" @click="redirectURL(deliverableinfo);">
                                            {{getCode(deliverableinfo)}}
                                        </a>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <div class="col-md-12 mb-5">
                        <b-pagination v-if="deliverablesList.length!=0" size="md" class='pull-right' :total-rows="deliverablesPagination.totalRecords" v-model="deliverablesPagination.currentPage" :per-page="deliverablesPagination.rowsPerPage" @input="loadDeliverables(taskInfo.id)"></b-pagination>
                    </div>
                </div>
           <!--  </form> -->
            <div class="taskComments col-md-12 mb-4 text-right float-right">
                <b-btn variant="primary" @click="updateTask" class="mr-2">Update</b-btn>
                <b-btn variant="secondary" @click="$emit('closeModal')" class="pr-2">Cancel</b-btn>
                <b-btn variant="primary" ref="myBtn" v-b-modal.commentModal class="ml-2 flex-fill hide-div">Dialog</b-btn>
            </div>
        </div>
        <Comment ref="cmdModal" :taskId="taskInfo.id" :activity="activity" :time="time" @clearTimer="clearTimer" @loadTaskActivityLog="loadTaskActivityLog" @loadTaskActivity="loadTaskActivity" />
        <Dialog id="removecomment" :onYes="deletecomment" :returnParams="taskcommentParam" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Editor from '@tinymce/tinymce-vue';
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import Comment from '../tasks/taskstatusupdate/Comment';
export default {
    props: {
        options: Object,
        taskInfo: Object,
        modeInfo: Object
    },
    components: {
        Comment,
        Editor
    },
    data() {
        return {
            addMore: [],
            fileInputKey: 0,
            attachments:[],
            disableButton: false,
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                searchlimit: this.$store.state.searchlimit
            },
            deliverablesPagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                searchlimit: this.$store.state.searchlimit
            },
            state: "started",
            startTime: "",
            currentTime: "",
            interval: null,
            elements: {
                showCommentDialog: false,
                checkListTag: "",
                currentUser: this.$store.getters.currentUser,
                commentTag: "",
                activity: ""
            },
            statuseditdata: "",
            activityList: [],
            activityLogList: [],
            deliverablesList: [],
            isExpand: false,
            isDeliverablesExpand: false,
            taskActivity: {},
            activity: {
                startTime: "",
                endTime: "",
                activityId: ""
            },
            currentDate: "",
             taskcommentParam:{
                id: 0
            }
        }
    },
    validations: {
        taskInfo: {

        },
        activity: {
            activityId: {
                required
            }
        },
        elements: {
            commentTag: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(2000)
            },
            checkListTag: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(2000)
            }
        }
    },
    mounted() {
        if (localStorage.getItem("timerStatus") == 'started') {
            this.startTime = localStorage.getItem("taskTime");
            this.currentTime = Date.now();
            this.activity.startTime = this.startTime;
            this.activity.endTime = this.currentTime;
            this.stopWatch();
            this.disableButton = true;
            this.elements.showCommentDialog = true;
            if (localStorage.getItem("activityDropListId") != null) {
                this.activity.activityId = localStorage.getItem("activityDropListId");
            }
        }
        this.loadTaskActivityLog(this.taskInfo.id);
        this.loadTaskActivity();
        this.loadDeliverables(this.taskInfo.id);
        this.addMore.push(this.addMore.length + 1);
    },
    destroyed: function() {
        clearInterval(this.interval)
    },
    computed: {
        time: function() {
            return this.hours + ':' + this.minutes + ':' + this.seconds;
        },
        milliseconds: function() {
            return this.currentTime - this.$data.startTime;
        },
        hours: function() {
            var lapsed = this.milliseconds;
            var hrs = Math.floor((lapsed / 1000 / 60 / 60));
            return hrs >= 10 ? hrs : '0' + hrs;
        },
        minutes: function() {
            var lapsed = this.milliseconds;
            var min = Math.floor((lapsed / 1000 / 60) % 60);
            return min >= 10 ? min : '0' + min;
        },
        seconds: function() {
            var lapsed = this.milliseconds;
            var sec = Math.ceil((lapsed / 1000) % 60);
            return sec >= 10 ? sec : '0' + sec;
        }
    },
    methods: {
        Open(url){
            window.open(url, '_blank')
        },
        addInput: function() {
            this.addMore.push(this.addMore.length + 1)
        },
        handleMultiFileUpload(event) {
            for (let file of event.target.files) {
                try {
                    let reader = new FileReader();
                    reader.readAsDataURL(file); // Not sure if this will work in this context.
                    this.attachments.push(file);
                    let attachmentData =  this.attachments 
                    this.$emit('addTaskAttachments', attachmentData);
                } catch {}
            }
        },
        stopWatch: function() {
            this.interval = setInterval(this.updateCurrentTime, 1000);
            return time;
        },
        reset: function() {
            this.$data.state = "started";
            this.$data.startTime = Date.now();
            this.$data.currentTime = Date.now();
        },
        pause: function() {
            this.$data.state = "paused";
        },
        resume: function() {
            this.$data.state = "started";
        },
        updateCurrentTime: function() {
            if (this.$data.state == "started") {
                this.currentTime = Date.now();
            }
        },
        refreshedits: function(index){
            this.statuseditdata=this.taskInfo.taskComment[index].comment;
        },
        cancelTaskComments: function(index) {
            this.taskInfo.taskComment[index].comment = this.statuseditdata;
            this.$emit('updateTaskComment',this.taskInfo.taskComment[index])
            this.$emit('changeCMode','view')
        },
        addCommentTag: function() {
            this.$v.elements.commentTag.$touch();
            if (this.$v.elements.commentTag.$invalid) {
                return false;
            }
            let comments= this.elements.commentTag;
            let commentData = { taskId: this.taskInfo.id, comment: comments }
            this.$emit('addTaskComments', commentData);
            this.elements.commentTag = "";
            this.$v.elements.commentTag.$reset();
        },
        removecomment:function(arg1){
            this.taskcommentParam.id=arg1;
        },
        deletecomment:function(){
           this.$emit('deleteTaskComment',this.taskcommentParam.id)
        },
        validateTaskData: function() {
            this.$v.taskInfo.$touch();
            if (this.$v.taskInfo.$invalid) {
                return false;
            }
            return true;
        },
        updateTask: function() {
            if (!this.validateTaskData()) {
                return false;
            }
            this.$emit('closeModal')
            this.$emit('updateTask', this.taskInfo);
        },
        loadTaskActivity: function() {
            this.currentDate = moment(new Date()).format("YYYY-MM-DD");
            console.log("current date", this.currentDate);
            console.log("due date", this.taskInfo.dueDate);
            if (this.taskInfo.taskType != null) {
                axios.get(this.$store.getters.getAPIBasePath + '/settingsresource/tasktype/activity/' + this.taskInfo.taskType)
                    .then(response => {
                        if (response.data.status) {
                            this.activityList = response.data.activityList;
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                        iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                    });
            }
        },
        loadTaskActivityLog: function(taskId) {
            if (taskId != null) {
                axios.post(this.$store.getters.getAPIBasePath + '/taskresource/list/task/activitylog/' + taskId + '?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage)
                    .then(response => {
                        if (response.data.status) {
                            this.activityLogList = response.data.activityLogList;
                            this.pagination.totalRecords = response.data.pagination[0].total;
                            this.pagination.currentPage = response.data.pagination[0].currentPage;
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                        iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                    });
            }
        },
        loadDeliverables: function(taskId) {
            if (taskId != null) {
                axios.post(this.$store.getters.getAPIBasePath + '/taskresource/list/task/delivarables/' + taskId + '?page=' + this.deliverablesPagination.currentPage + '&size=' + this.deliverablesPagination.rowsPerPage)
                    .then(response => {
                        if (response.data.status) {
                            this.deliverablesList = response.data.deliverablesList;
                            this.deliverablesPagination.totalRecords = response.data.pagination[0].total;
                            this.deliverablesPagination.currentPage = response.data.pagination[0].currentPage;
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                        iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                    });
            }
        },
        pushChecklistTag: function() {
            this.$v.elements.checkListTag.$touch();
            if (this.$v.elements.checkListTag.$invalid) {
                return false;
            }
            let checklistData = { taskId: this.taskInfo.id, title: this.elements.checkListTag }
            this.$emit('addTaskChecklist', checklistData);
            this.elements.checkListTag = "";
        },
        changeLimit: function(newlimit) {
            this.pagination.rowsPerPage = newlimit;
            this.loadTaskActivityLog();
        },
        changeDeliverablesLimit: function(newlimit) {
            this.deliverablesPagination.rowsPerPage = newlimit;
            this.loadTaskActivityLog();
        },
        clearTimer: function() {
            this.reset();
            clearInterval(this.interval);
            localStorage.removeItem("taskTime");
            localStorage.removeItem("timerStatus");
            localStorage.removeItem("taskActivity");
            localStorage.removeItem("taskId");
            localStorage.removeItem("taskTypeId");
            localStorage.removeItem("activityLogId");
            localStorage.removeItem("activityDropListId");
            this.$store.dispatch("updateNotificationList", { taskId: 0 });
            this.activity.activityId = "";
        },
        updateNotification: function(type) {
            var data = {
                taskId: this.taskInfo.id
            }
            if (localStorage.getItem("timerStatus") == 'started') {
                this.clearTimer();
            } else {
                this.startTime = Date.now();
                this.currentTime = Date.now();
                localStorage.setItem("taskTime", Date.now());
                localStorage.setItem("timerStatus", "started");
                localStorage.setItem("taskActivity", type);
                localStorage.setItem("taskId", this.taskInfo.id);
                localStorage.setItem("taskTypeId", this.taskInfo.taskType);
                this.$store.dispatch("updateNotificationList", data);
            }

        },
        getSubstring(description) {
            if (description != null && description.length >= 6) {
                return description.substring(0, 10) + "...";
            } else {
                return description;
            }
        },
        startActivity: function() {

            this.$v.activity.$touch();

            if (this.$v.activity.$invalid) {
                return false;
            }

            let activityName = this.getActivityNameById(this.activity.activityId, this.activityList);
            this.updateNotification(this.getActivityName(activityName));
            this.stopWatch();
            this.disableButton = true;
            let data = {
                taskId: this.taskInfo.id,
                activityId: this.activity.activityId,
                startTime: this.startTime
            }
            localStorage.setItem("activityDropListId", this.activity.activityId);
            axios.post(this.$store.getters.getAPIBasePath + '/taskresource/add/task/activitylog', data)
                .then(response => {
                    console.log(response);

                    if (response.data.status == "ACCEPTED") {
                        localStorage.setItem("activityLogId", response.data.id);
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                });
        },
        getActivityNameById: function(id, list) {
            let result_name = "";
            list.map(function(value, key) {
                if (value.id == id)
                    result_name = value.activityName;
            });
            if (result_name != "") {
                result_name = result_name.toLowerCase();
            }
            return result_name;
        },
        getActivityName: function(name) {
            let output = "";
            if (name.includes('sourcing')) {
                output = "sourcing";
            } else if (name.includes('new candidate')) {
                output = "add candidate";
            } else if (name.includes('new job')) {
                output = "add job";
            }

            return output;
        },
        endActivity: function() {
            if (localStorage.getItem("timerStatus") == 'started') {
                this.elements.showCommentDialog = true;
                this.$refs.myBtn.click();
            }
            //this.activity.activityId = "";
            this.disableButton = false;
        },
        getCode: function(activity) {
            let output = "";
            switch (activity.incidentTypeName.toLowerCase()) {
                default:
                    break;
                case "job":
                    output = activity.jobCode;
                    break;
                case "candidate":
                    output = activity.candidateCode;
                    break;
            }
            if (!this.checkEmpty(output))
                output = activity.incidentReferenceId;
            return output;
        },
        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },
        redirectURL: function(activity) {
            switch (activity.incidentTypeName.toLowerCase()) {
                default:
                    break;
                case "job":
                    this.$router.push('/job/view/' + activity.incidentReferenceId);
                    break;
                case "candidate":
                    this.$router.push('/candidates/view/' + activity.incidentReferenceId);
                    break;
            }

        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>

