<template>
    <div id="interviewer-edit-block" class="interviewer-edit-block mt-70">
        <div class="container-fluid driver-model" id="main-data">
            <div class="row">
                <div class="col-md-3 mb-4">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card border-5 mb-3">
                                <h6 class="about-box m-0 text-left p-2">
                                    <a href="javascript:" @click="$router.go(-1)" title="Back" class="p-2">
                                        <i class="fa fa-arrow-left"></i>
                                        <span> Back</span>
                                    </a>
                                     <router-link class="float-right link pr-2" title="View Member" :to="{ name: 'viewteammember', params: {recruiterid: profileId} }"><i class="fa fa-eye fa_custom fa-lg  px-1"></i> </router-link>
                                </h6>
                                <div class="user-card pl-5 pr-5 pt-3">
                                    <div class="information">
                                        <div class="imgUp">
                                            <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' +  (ProfileData.image===''?ProfileData.imagePath:ProfileData.image) + ')' }"></div>
                                            <label class="upload-icon"><i class="ti-camera"></i><input ref="recruiter_profile_photo" id="recruiter_profile_photo" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="upload_photo"></label>
                                            <label class="delete-icon"><i class="ti-close closefont" @click="delete_photo"></i></label>
                                        </div>
                                        <div class="name">{{ProfileData.firstName}} {{ProfileData.lastName}}</div>
                                        <div>{{ProfileData.position}} </div>
                                        <div class="position"><i v-if="checkEmpty(ProfileData.email)" class="ti-email"></i> {{ProfileData.email}}</div>
                                        <div class="position"><i v-if="checkEmpty(ContactInfo.mobileNo)" class="ti-mobile"></i> {{ContactInfo.mobileNo}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="box-wrapper shadow-sm mb-4 bg-white rounded pos-rel main-search">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row no-gutters">
                                    <div class="col-md-12 pop-tab">
                                        <div class="driver-model">
                                            <div class="row no-gutters">
                                                <b-tabs v-model="currentTab" small card class="tab-list driver-tab-list tab-bg">
                                                    <b-tab title="Primary Info">
                                                        <PersonalInfo :ProfileData="ProfileData" ref="personalinfocomponent"></PersonalInfo>
                                                    </b-tab>
                                                    <b-tab title="Contact Info">
                                                        <ContactInfo :ContactInfo="ContactInfo" ref="contactInfocomponent"></ContactInfo>
                                                    </b-tab>
                                                    <b-tab title="Settings">
                                                        <Settings :settingsInfo="settingsInfo" :profileId="profileId" @loadMember="loadMember" ref="settingscomponent"></Settings>
                                                    </b-tab>
                                                </b-tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import PersonalInfo from './blocks/editrecruiter/PersonalInfo';
import ContactInfo from './blocks/editrecruiter/ContactInfo';
import Settings from './blocks/editrecruiter/Settings';
export default {
    components: {
        PersonalInfo,
        ContactInfo,
        Settings
    },
    data() {
        return {
            profileId: Number(this.$route.params.recruiterid),
            elements: {
                showAvailabilityInfo: false
            },
            currentTab: 0,
            ProfileData: {
                id: 0,
                firstName: '',
                lastName: '',
                email: '',
                image: '',
                imageName: '',
                imagePath: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
                image: '',
                industryId: null,
                company: '',
                timezone: '',
                role: ''
            },
            deleteparams:{
            id: 0,
            type:'Recruiter'
            },
            ContactInfo: {
                addressLine1: 'North cross street',
                addressLine2: 'kottar',
                countryId: 'US',
                stateName: '',
                cityName: '',
                pincode: '',
                skypeId: '',
                countryCode:'+1',
                mobileNo: '',
                mobile: '',
                altcountryCode:'+1',
                altMobileNo: '',
                altMobile: '',
                websiteUrl: '',
                linkedinUrl: '',
                hangoutId: '',
                facebookUrl: '',
                twitterUrl: ''
            },
            settingsInfo: {
                timezone: "",
                accessLevel: 1
            },
            colours: ['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info']
        }
    },
    mounted() {
        console.log(this.$route.params.recruiterid);
        this.loadMember();
    },
    methods: {
        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },

        createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;

            reader.onload = (e) => {
                this.ProfileData.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        delete_photo: function() {
            this.deleteparams.id=this.profileId;
            axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/delete/candidate/photo',this.deleteparams)
                .then(response => {
                    if(response.data.status=="OK"){
                        iziToast.success({ title: 'Photo update', message: response.data.message, position: 'topRight' });
                        this.ProfileData.image="";
                        this.ProfileData.imageName="";
                        this.ProfileData.imagePath="";
                    }
                    else if(response.data.status=="EXPECTATION_FAILED"){
                        console.log(response);
                        iziToast.info({ title: 'Photo update', message: response.data.message, position: 'topRight' });}
                })
                .catch(function(error) {
                    console.log(error);
                });
           
        },
         createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;

            reader.onload = (e) => {
                this.ProfileData.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        upload_photo: function() {
            console.log(this.$refs.recruiter_profile_photo.files[0].name);
            if (!/\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(this.$refs.recruiter_profile_photo.files[0].name)) {
                iziToast.error({ title: 'Photo update', message: this.$config.FileFormatErr, position: 'topRight' });
                return false;
            }
            this.createImage(this.$refs.recruiter_profile_photo.files[0]);

            let formData = new FormData();
            formData.append('file', this.$refs.recruiter_profile_photo.files[0]);
            formData.append('id', this.profileId);
            formData.append('type', 'recruiter');
            axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/uploadprofilephoto',
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response => {
                    console.log('SUCCESS!!');
                    iziToast.success({ title: 'Photo update', message: this.$config.ProfileUpSuc, position: 'topRight' });
                })
                .catch(function(error) {
                    console.log('FAILURE!!');
                    console.log(error);
                    iziToast.error({ title: 'Photo update', message: this.$config.ProfileUpErr, position: 'topRight' });
                });
        },
        loadMember: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/recruiterresource/view/recruiter/' + this.profileId)
                .then(response => {
                    if (response.data.status) {
                        let memberData = response.data.recruiter;
                        console.log(memberData);
                        this.ProfileData.id = memberData.recruiterId;
                        this.ProfileData.firstName = memberData.firstName;
                        this.ProfileData.lastName = memberData.lastName;
                        this.ProfileData.email = memberData.email;
                        this.ProfileData.timezone = memberData.timezone;
                        this.ProfileData.imageName = memberData.imageName;
                        this.ProfileData.imagePath = (memberData.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : memberData.imagePath);
                        this.ProfileData.image = (memberData.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : memberData.imagePath);
                        this.ProfileData.company = memberData.companyName; 
                        this.ProfileData.role = memberData.role; 
                        this.settingsInfo.timezone = memberData.timezone == null ? '' : memberData.timezone;

                        let phoneNo = memberData.recruiterContact.mobileNo;
                        if (phoneNo != null && phoneNo.startsWith("+")) {
                            let phoneData = phoneNo.split(" ");
                            memberData.recruiterContact.countryCode = phoneData[0];
                            memberData.recruiterContact.mobile = phoneData[1];           
                        }
                        phoneNo = "";
                        phoneNo = memberData.recruiterContact.altMobileNo;
                        if (phoneNo != null && phoneNo.startsWith("+")) {
                            let phoneData = phoneNo.split(" ");
                            memberData.recruiterContact.altcountryCode = phoneData[0];
                            memberData.recruiterContact.altMobile = phoneData[1];           
                        }
                        this.ContactInfo = Object.assign({}, memberData.recruiterContact);

                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
