import Dashboard from '@/components/referral/Dashboard';
import ListJob from '@/components/referral/job/Listjobs';
import ListCandidates from '@/components/referral/candidate/ListCandidates';
import ListInterviewers from '@/components/referral/interviewer/ListInterviewers';
import ViewJob from '@/components/referral/job/ViewJob';
import ViewCandidate from '@/components/referral/candidate/ViewCandidate';
import EditCandidate from '@/components/referral/candidate/EditCandidate';
import Profile from '@/components/referral/Profile';
import EditReferral from '@/components/referral/profile/EditProfile';
import AddCandidate from '@/components/referral/candidate/AddCandidate';
import AddInterviewer from '@/components/referral/interviewer/AddInterviewer';
import ReferralSettings from '@/components/referral/ToolsAndSettings';
import ViewInterviewer from '@/components/referral/interviewer/ViewInterviewer';
import EditInterviewer from '@/components/referral/interviewer/EditInterviewer';
//import BulkUpload from '@/components/candidates/BulkUpload';
import BulkUpload from '@/components/referral/BulkUpload';
import ReferralTemplateSettings from '@/components/referral/blocks/settings/TemplateSettings';
import ReferralToolDemo from '@/components/referral/ToolDemo';
import ReferralReport from '@/components/referral/Reports';
import ChangePassword from '@/components/referral/ChangePassword';

export default [{
        path: '/referral/dashboard',
        name: 'referraldashboard',
        component: Dashboard,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/profile',
        name: 'referralprofile',
        component: Profile,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/edit/:id',
        name: 'referraledit',
        component: EditReferral,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/jobs',
        name: 'referraljoblist',
        component: ListJob,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/job/view/:jobid',
        name: 'referralviewjob',
        component: ViewJob,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/candidates',
        name: 'referralcandidates',
        component: ListCandidates,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/candidate/view/:candidateid',
        name: 'referralviewcandidate',
        component: ViewCandidate,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/candidate/edit/:candidateid',
        name: 'referraleditcandidate',
        component: EditCandidate,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/candidate/add',
        name: 'referraladdcandidate',
        component: AddCandidate,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/interviewers',
        name: 'referralinterviewers',
        component: ListInterviewers,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/interviewer/add',
        name: 'referraladdinterviewer',
        component: AddInterviewer,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/interviewer/view/:interviewerid',
        name: 'referralviewinterviewer',
        component: ViewInterviewer,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/interviewer/edit/:interviewerid',
        name: 'referraleditinterviewer',
        component: EditInterviewer,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/settings',
        name: 'referralsettings',
        component: ReferralSettings,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/bulkupload',
        name: 'referralbulkupload',
        component: BulkUpload,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/settings/template',
        name: 'referraltemplatesettings',
        component: ReferralTemplateSettings,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/tool-demo',
        name: 'referraltooldemo',
        component: ReferralToolDemo,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/report',
        name: 'referralreport',
        component: ReferralReport,
        meta: { accessedby: 'referral' }
    },
    {
        path: '/referral/changepassword',
        name: 'changepasswordreferral',
        component: ChangePassword,
        meta: { accessedby: 'recruiter' }
    }
];