<template>
	<div :class="{ 'viewonly' : technologyinfo.viewonly == 1}">
		<div class="card">
		    <div class="card-header p-2">Technology/Skills Expertise</div>
		    <div class="card-body p-2">
		    	<div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="title" scope="col">Specialization/Technology</th>
                                            <th class="" scope="col">Skills</th>
                                            <th class="videoprofile" scope="col">Primary Exp.(# of Years)</th>
                                            <th class="state" scope="col">Industry</th>
                                            <th class="" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="cloned_tr">
                                    	<tr v-for="(info, index) in technologyinfo.skillset">
                                            <td class="lastnote">
                                            	<select v-model="technologyinfo.skillset[index].technologyId" class="form-control">
				                                    <option value="">Select</option>
				                                    <option v-for="(info, index) in technologies" :value="info.id">{{info.technology}}</option>
				                                </select>
                                            </td>
                                    		<td>
                                    			<div class="position-relative" >                                                    
                                                    <input class="form-control" maxlength="100" type="text" v-model="technologyinfo.skillset[index].title"/>
                                                    <a href="javascript:;" @click="showBoxTechSkill(index)" data-toggle="modal" data-target="#TechSkillShow">
                                                        <span class="search-addon" ><span class="ti-flickr srcicn"></span> </span>  
                                                    </a>
                                                </div>
                                    		</td>
                                            <td class="recruiter">
                                            	<select v-model="technologyinfo.skillset[index].years" class="form-control">
				                                    <option value="">Select</option>
				                                    <option v-for="(info, index) in experience" :value="info.value">{{info.label}}</option>
				                                </select>
                                            </td>
                                            <td class="title">
                                            	<select v-model="technologyinfo.skillset[index].industryId" class="form-control">
				                                    <option value="">Select</option>
				                                    <option v-for="(info, index) in industries" :value="info.id">{{info.industryName}}</option>
				                                </select>
                                            </td>
                                            <td>

												<a class="addskills" :class="{ 'removeaddbtn' : technologyinfo.viewonly == 1}" href="Javascript:void(0);" @click="confirmTech(index,0)" data-toggle="modal" data-target="#removTech"><span class="ti-trash"></span></a>
                                            </td>
                                    	</tr>
                                        <tr class="fill" :class="{ 'noaddedmore' : technologyinfo.viewonly == 1}">
                                            <td class="lastnote">
                                            	<div class="input-group">
													<select v-model="addtech.technologyId" class="form-control">
					                                    <option value="">Select</option>
					                                    <option v-for="(info, index) in technologies" :value="info.id">{{info.technology}}</option>
					                                </select>
													<a href="Javascript:void(0);" class="tbl-add-icon" data-toggle="modal" data-target="#addNewTech" @click="newTech.techName=''" ><span class="input-group-addon"><i class="ti-settings"></i></span></a>
													
												</div>
                                            </td>
                                            <td>
                                            	<div class="position-relative" >                                                    
                                                    <input class="form-control" maxlength="100" type="text" v-model="addtech.title"/>
                                                    <a href="javascript:;" @click="showBoxTechSkill(-1)" data-toggle="modal" data-target="#TechSkillShow">
                                                        <span class="search-addon" ><span class="ti-flickr srcicn"></span> </span>  
                                                    </a>
                                                </div>
                                            </td>
                                            <td class="recruiter">
                                            	<select v-model="addtech.years" class="form-control">
				                                    <option value="">Select</option>
				                                    <option v-for="(info, index) in experience" :value="info.value">{{info.label}}</option>
				                                </select>
                                            </td>
                                            <td class="specialityunit">
                                            	<select v-model="addtech.industryId" class="form-control">
				                                    <option value="">Select</option>
				                                    <option v-for="(info, index) in industries" :value="info.id">{{info.industryName}}</option>
				                                </select>
                                            </td>
                                            <td>
                                                <a class="addskills" href="Javascript:void(0);" @click="addNewSkillset"><span class="ti-plus"></span></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
		    </div>
		</div>
		<div class="card" v-if="false">
		    <div class="card-header p-2">Special Note</div>
		    <div class="card-body p-2">
		        <div class="basic-block">
		            <div class="row">
		                <div class="col-md-12">
		                    <div class="form-group">
		                        <textarea v-model="technologyinfo.specialnotes" class="form-control" rows="3"></textarea>
		                    </div>
		                </div>
		            </div>
		        </div>
		    </div>
		</div>

		<div class="modal" id="TechSkillShow" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Please enter the name of new skill</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <textarea class="form-control more-text-area" v-model="tempTechSkill" ></textarea>
                    </div>
                    <div v-if="technologyinfo.viewonly != 1" class="modal-footer">                                            
                        <button type="button" @click="updateTechSkill()" class="btn btn-primary" data-dismiss="modal">OK</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

		<div class="modal" id="addNewTech" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Please enter the name of new skill</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body input-group required-field-block">
                        <div class="input-group required-field-block">
                            <input v-model="newTech.techName" type="text" maxlength="100" minlength="2" class="form-control">
                            <span class="required-icon"></span>
                            
                        </div>
                        <label v-if="$v.newTech.techName.$error && !$v.newTech.techName.required" class="text-danger">Enter Technology</label>
                    </div>
                    <div class="modal-footer">                                            
                        <button type="button" @click="addNewTechnology()" class="btn btn-primary" >OK</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

		<Dialog id="removTech" :onYes="deleteTech" :returnParams="delTechParam" />

	</div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
	import iziToast from 'izitoast';
	export default {
		props: {
            technologyinfo: Object,
        },
		data(){
			return {
				addtech:{
					title:"",
					technologyId:"",
					years:"",
					industryId:""
				},
				experience:[],
				technologies:this.$store.getters.getTechOptions,
				industries:this.$store.getters.getIndustries,
				tempTechSkillId:-1,
				tempTechSkill:'',
				newTech : {
                    techName:''
                },
                delTechParam:{
                    index:-1,
                    id:0
                }
				
				
			}
		},
		mounted(){
			this.generateExperience();
		},
		methods : {
			resetNewForm(){
				this.addtech = {
					title:"",
					technologyId:"",
					years:"",
					industryId:""
				};
				this.$refs.addcandidate_techinfo_add_title.focus();
			},
			addNewSkillset(){
				this.addtech.title = this.addtech.title.trim();
                this.addtech.years = this.addtech.years.trim();
                if(this.addtech.title!="" || this.addtech.technologyId!="" || this.addtech.years!="" || this.addtech.industryId!=""){
					this.technologyinfo.skillset.push(this.addtech);
					this.resetNewForm();
				}
			},
			addNewTechnology(){
				
				this.$v.newTech .$touch();
                if (this.$v.newTech.$invalid){
                    return false;
                }
				axios.post(this.$store.getters.getAPIBasePath+'/technologyresource/add/technology',{technology:this.newTech.techName})
				.then(response => {
					if(response.data.status){
						this.$store.dispatch("reloadSkills").then(() => {
							this.technologies = this.$store.getters.getTechOptions;
						});
						 iziToast.success({
			                    title: 'Success',
			                    message: this.$config.TechSuc,
			                    position:'topRight'
			                });
					}
					else{
						let errmsg = (response.data.message!=undefined)?response.data.message:this.$config.NewTechErr;
						iziToast.error({
			                    title: 'Error',
			                    message: errmsg,
			                    position:'topRight'
			                });
					}
				})
				.catch(error => {
					let errmsg = (error.response.data.message!=undefined)?error.response.data.message:this.$config.NewTechErr;
					iziToast.error({
			                    title: 'Error',
			                    message: errmsg,
			                    position:'topRight'
			                });
				});
			},
			showErrorMessage(msg){
                iziToast.error({
                    title: 'Error Occured',
                    message: msg,
                    position:'topRight'
                });
			},
			generateExperience(){
				for(var i = 1;i<=20;i++){
					var x = i.toString();
					if(i==1){
						this.experience.push({"value":"0","label":"<1"});
					}
					this.experience.push({"value":x,"label":x});
				}
				this.experience.push({"value":"21","label":"20+"});
			},
			showBoxTechSkill: function(arg){
				//console.log(arg);
				this.tempTechSkillId = arg;
                if(arg<0)
                {
                    this.tempTechSkill = this.addtech.title;                    
                    //console.log(this.newprojectinfo.projectDescription);
                }
                else
                {
                    this.tempTechSkill = this.technologyinfo.skillset[arg].title;                    
                    //console.log(this.projectinfo[arg].projectDescription);
                }
			},
			updateTechSkill: function()
			{
				 if(this.tempTechSkillId < 0)
                 {
                    this.addtech.title = this.tempTechSkill;
                 }
                 else
                 {
					this.technologyinfo.skillset[this.tempTechSkillId].title = this.tempTechSkill;                                     
				}
			},
			deleteTech:function(arg)
            {                
                this.technologyinfo.skillset.splice(arg.index,1);                
            },
            confirmTech:function(arg1, arg2)
            {
                this.delTechParam.index=arg1;
                this.delTechParam.id=arg2;
            }
		},
		validations: {
			newTech : {
				techName:{
					required
				}
			}
		}
	}
</script>