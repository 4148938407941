<template>
	<div class="email-template-edit text-left">
		<div class="row col-md-12 p-4 mx-0">
			<div class="col-md-6 col-sm-6 col-6 p-0">
				<h5>View Email template</h5>
			</div>
			<div class="col-md-6 col-sm-6 col-6 p-0">
				<button type="button" class="btn btn-primary btn-sm pull-right"><a href="javascript:" class="text-white" @click="$emit('closeActionTemplate')">Back</a></button>
			</div>
		</div>
		<div class="row col-md-12 py-4 mx-0">
			<div class="col-md-6">
				<div class="row col-md-12 px-0">
					<div class="col-md-4"><b>Template Name</b></div>
					<div class="col-md-8">
						<span class="px-3">:</span><span>{{templateData.templateName}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="row col-md-12 px-0">
					<div class="col-md-4"><b>Template Type</b></div>
					<div class="col-md-8">
						<span class="px-3">:</span><span>{{templateData.templateType | getNameById($store.getters.getTemplateTypesList)}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="row col-md-12 py-4 mx-0">
			<div class="col-md-6">
				<div class="row col-md-12 px-0">
					<div class="col-md-4"><b>Scope</b></div>
					<div class="col-md-8">
						<span class="px-3">:</span><span>{{templateData.accessLevel | getNameById($store.getters.getEmailTemplateScope)}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="row col-md-12 py-4 mx-0">
			<div class="col-md-2"><b>Content</b></div><span class="px-4">:</span>
			<div class="col-md-9 card p-4" v-html="templateData.content"></div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			templateData: Object
		}
	}
</script>