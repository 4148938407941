<template>
    <div id="main" class="mt-70 d-height">
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a @click="searchMore=!searchMore" href="javascript:;" data-target="main-search" class="boxhide"><span :class="searchMore?'ti-angle-up':'ti-angle-down'"></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <form id="main-search" v-if="searchMore" v-on:submit.prevent="">
                        <div class="row form-group ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Account Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <input type="text" v-model="search.params.accountName" v-on:keyup.enter="findClientAccounts" class="form-control">
                                    </div>
                                </div>
                            </div>                        
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr pl-0">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;">Status</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <button type="button" @click="findClientAccounts" class="btn btn-primary btn-sm float-left" style="margin-left: 10px;">Find</button>
                                        <button type="button" @click="resetClientsSearch" class="btn btn-primary btn-sm float-left" style="margin-left: 10px;">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="load-indicator"></div>
                </div>
            </div>
            <div class="container-fluid" id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-6 col-sm-12 col-12">
                            <h4 class="page-title">Client Accounts
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{browsepagination.totalrecords}} - Total)</span>
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                    <div class="dropdown">
                                        <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                        <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                            <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                        </div>
                                    </div>
                                </span>
                            </h4>
                        </div>
                        <div class="col-md-6 d-none d-sm-none d-md-block">
                            <button type="button" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;"><router-link :to="{ name: 'adminaddrecruiter' }" style="color: #fff;">New Client Account</router-link></button>
                            <button type="button" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;"><router-link :to="{ name: 'adminlistcontacts' }" style="color: #fff;">Contacts</router-link></button>
                        </div>                    
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col"></th>
                                            <th class="clntName" scope="col">Account ID</th>
                                            <th class="accountManager" scope="col">Account Type</th>
                                            <th v-if="coldisplay.accountName" class="phoneNumber" scope="col">Account Name</th>
                                            <th class="physicalAddCity" scope="col">Company Name</th>
                                            <th scope="col">Action</th>
                                            <!-- <th scope="col">Action</th> -->
                                        </tr>
                                    </thead>
                                    <tbody id="table-length">
                                        <tr v-if="clientAccountList.length==0 && elements.loadClientAccounts == true">
                                            <td colspan="7" align="center">No Clients found</td>
                                        </tr>
                                        <template v-for="(clientAccountInfo, index) in clientAccountList">
                                        <tr >
                                            <td v-if="clientAccountInfo.account_Type==3">
                                                <a href="javascript:" class="expand" data-targetopen="panel1">
                                                    <span @click="getCorpClientList(clientAccountInfo.id)"><i :class="(currentIndex==clientAccountInfo.id) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
                                                    </span>
                                                </a>
                                                </td>
                                                <td v-else>
                                                </td>
                                
                                            <td><router-link :to="{ name:'viewclientaccount', params:{accountid: clientAccountInfo.id}}">{{clientAccountInfo.tt_Client_Account_Id}}</router-link></td>
                                            <!-- <td class="clntName">{{ clientAccountInfo.tt_Client_Account_Id }}</td> -->
                                            <td class="accountManager">{{ clientAccountInfo.accountTypeName }}</td>
                                            <td v-if="coldisplay.accountName" class="accountManager">{{ clientAccountInfo.account_Name }}</td>
                                            <td v-else class="accountManager">{{ clientAccountInfo.company_Name }}</td>
                                            <td class="physicalAddCity">{{ clientAccountInfo.company_Name }}</td>
                                            <td>
                                                <ul class="navbar-nav">
                                                    <li class="nav-item dropdown">
                                                        <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                            <li>
                                                                <router-link class="dropdown-item" :to="{ name:'viewclientaccount', params:{accountid: clientAccountInfo.id}}"><span class="ti-eye"></span> View</router-link>     
                                                            </li>
                                                            <li>
                                                                <router-link class="dropdown-item" :to="{ name: 'editclientaccount', params: { accountid: clientAccountInfo.id }}"><span class="ti-pencil"></span> Edit</router-link>
                                                            </li>
                                                            <li v-if="false">
                                                                <a class="dropdown-item" href="javascript:" @click="resendactMail(clientAccountInfo.id)" data-toggle="modal" data-target="#resendMail"><span class="ti-email pr-2 fs-12"></span> Resend Activation Mail</a>
                                                            </li>
                                                            <!-- <li>
                                                                <a class="dropdown-item" href="javascript:;" @click="deleteClient(clientAccountInfo.id)" data-toggle="modal" data-target="#removeClient"><span class="ti-close"></span> Deactivate</a>
                                                            </li> -->
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr v-if="currentIndex == clientAccountInfo.id">
                                                <td colspan="7">
                                                    <div class="table-responsive">
                                                        <table class="table table-borderless all-table">
                                                            <thead>
                                                                <tr>
                                                                    <th class="" scope="col">Account ID</th>
                                                                    <th class="" scope="col">Account Type</th>
                                                                    <th class="" scope="col">Account Name</th>    
                                                                    <th class="" scope="col">Company Name</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="cmpyInfo in subCorpsCmpyList" :key="cmpyInfo.id" :id="cmpyInfo.id">
                                                                    <td>{{ cmpyInfo.tt_Client_Account_Id }}</td>
                                                                    <td>{{ cmpyInfo.accountTypeName }}</td>
                                                                    <td>{{ cmpyInfo.account_Name }}</td>
                                                                    <td>{{ cmpyInfo.company_Name }}</td>
                                                                </tr>
                                                                <tr  v-if="subCorpsCmpyList.length==0">
                                                                    <td style="border:0px;" colspan="7" align="center">No Data Found
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                        
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <b-pagination v-if="clientAccountList.length!=0" size="md" class='pull-right' :current-page="browsepagination.currentpage" :total-rows="browsepagination.totalrecords" v-model="browsepagination.currentpage" :per-page="browsepagination.rowsPerPage" @input="loadClientAccounts()"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div>
                    <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
                </div>                   
            </template> 
        </b-overlay>
        <Dialog id="resendMail" :onYes="resendmail" :returnParams="resendParams" title="Confirmation" message="Are you sure to resend the Activation mail?" />
        <!-- <Dialog id="removeClient" :onYes="delete_Client" :returnParams="delClientParam" title="Deactivate Confirmation" message="Are you sure to deactivate ?" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    data() {
        return {
            showOverlay:false,
            subCorpsCmpyList: [],
            clientAccountList: [],     
            browsepagination: {
                totalrecords: 0,
                rowsPerPage: 10,
                currentpage: 1
            },
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            elements: {
                showadvancedsearch: false,
                loadingClientAccount: false,
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    accountName: ''
                },

            },
            resendParams: {
                index: -1,
                id: 0
            },
            coldisplay: {
                accountName: true,
            },
            delClientParam: {
                index: -1,
                id: 0
            },
            searchMore: false,
            currentIndex: null,
        }
    },
    watch: {
        'showOverlay': {
            handler: function(value) { 
                if(value == true){
                    $('#globalapploader').css('visibility','hidden');
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.loadClientAccounts();
    },
    methods: {
        getCorpClientList(id){
            if (this.currentIndex == id) {
                this.currentIndex = null;
            } else {
                this.currentIndex = id;
            }
            axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/client/account/byaccounttype' , { parentId: id })
                .then(response => {
                    let result = response.data;
                    this.subCorpsCmpyList = result.recordinfo;
                    this.elements.loadClientAccounts = true;
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });
        },
        resetClientsSearch() {
            this.search.params = {
                accountName: "",
            };
            this.loadClientAccounts();
        },
        async loadClientAccounts() {
            this.showOverlay=true;
            await this.getClientAccounts();
            this.showOverlay=false;
        },
        findClientAccounts(){
            this.browsepagination.currentpage = 1;
            this.loadClientAccounts();
        },
        getClientAccounts() {
            this.showOverlay=true;
            return axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/client/account/list?page=' + this.browsepagination.currentpage + '&size=' + this.browsepagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    console.log(result)
                    this.clientAccountList = result.clientAccountList;
                    this.browsepagination.totalrecords = result.pagination[0].total;
                    this.browsepagination.currentpage = result.pagination[0].currentPage;
                    this.browsepagination.totalpages = result.pagination[0].lastPage;
                    this.elements.loadClientAccounts = true;
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });
        },

        changeLimit(newlimit) {
            this.browsepagination.currentpage = 1;
            this.browsepagination.rowsPerPage = newlimit;
            this.loadClientAccounts(); 

            /* console.log(newlimit);
            this.search.limit = newlimit;
            this.$store.commit('updateActiveSearchLimit', this.search.limit);
            this.loadClientAccounts(); */
        },
        resendactMail(userId) {
            this.resendParams.id = userId;
        },
        resendmail(){
            var Id = this.resendParams.id;
            axios.post(this.$store.getters.getAPIBasePath + '/userresource/resend/activationemail/' + Id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadClientAccounts();
                    }
                })
                .catch(error => {
                    console.log(error.data);
                    this.unAuthorized(error);
                });
        },
        // deleteClient(clientId) {
        //     this.delClientParam.id = clientId;
        //     return;

        //     if (confirm("Do you really want to deactivate the client?")) {
        //         var params = new URLSearchParams();
        //         params.append('id', clientId)
        //         axios.post(this.$store.getters.getAPIBasePath + '/clientresource/delete/client', params)
        //             .then(response => {
        //                 var result = response.data;
        //                 if (result.status == "OK") {
        //                     iziToast.success({
        //                         title: 'Success',
        //                         message: result.message,
        //                         position: 'topRight'
        //                     });
        //                 }
        //                 this.loadClients();
        //             })
        //             .catch(error => {
        //                 console.log(error.data);
        //             });
        //     }
        // },
        // delete_Client() {
        //     var params = new URLSearchParams();
        //     params.append('id', this.delClientParam.id)
        //     axios.post(this.$store.getters.getAPIBasePath + '/clientresource/delete/client', params)
        //         .then(response => {
        //             var result = response.data;
        //             if (result.status == "OK") {
        //                 iziToast.success({
        //                     title: 'Success',
        //                     message: result.message,
        //                     position: 'topRight'
        //                 });
        //             }
        //             this.loadClients();
        //         })
        //         .catch(error => {
        //             console.log(error.data);
        //         });
        // }

    }
}

</script>
