<template>
	<div class="email-account-settings">
		<div class="row col-md-12 mx-0 px-0">
			<div class="col-md-3 offset-md-4">
				<h5 class="text-center py-3">Email Account Settings</h5>
			</div>
			<div class="col-md-2 offset-md-3 px-0 align-self-center">
				<a href="javascript:" class="pull-right btn-back text-blue" @click="$emit('setSubTab',0)">
					<h5><i class="ti-arrow-left"></i> Back</h5>
				</a>
			</div>
		</div>
		<div class="col-md-12 mx-0">
			<button type="button" class="btn btn-primary btn-sm pull-right"><a href="javascript:" class="text-white" @click="$emit('setAction','add')">New Settings</a></button>
		</div>
		<div class="col-md-12 table-responsive py-3">
			<table class="table table-borderless all-table text-left">
				<thead>
					<tr>
						<th>S.No.</th>
						<th>Name</th>
						<th>Email</th>
						<th>SMTP Server</th>
						<th>Port</th>
						<th>Username</th>
						<th>Is Default</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!emailAccountsList.length">
						<td colspan="8" class="text-center">No records found</td>
					</tr>
					<tr v-for="(EmailAccount,index) in emailAccountsList">
						<td>{{index+1}}</td>
						<td>{{EmailAccount.fromName}}</td>
						<td>{{EmailAccount.fromEmail}}</td>
						<td>{{EmailAccount.host}}</td>
						<td>{{EmailAccount.port}}</td>
						<td>{{EmailAccount.userName}}</td>
						<td>
							<span v-if="EmailAccount.isDefault" class="ti-check"></span>
							<span v-if="!EmailAccount.isDefault" class="ti-close"></span>
						</td>
						<td>
							<ul class="navbar-nav">
								<li class="nav-item dropdown">
									<a class="nav-link dropdown-toggle p-0 font-weight-bold" href="javascript:" id="emailAccountAction" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
									<ul class="dropdown-menu" aria-labelledby="emailAccountAction">
										<li>
											<a href="javascript:" @click="$emit('setAction','view',EmailAccount.id)" class="dropdown-item"><span class="ti-eye pr-2"></span> View</a>
										</li>
										<li>
											<a href="javascript:" @click="$emit('setAction','edit',EmailAccount.id)" class="dropdown-item"><span class="ti-pencil pr-2"></span> Edit</a>
										</li>
									</ul>
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
	import iziToast from 'izitoast';
	export default {
		data(){
			return {
				elements: {
					submitted: false
				},
				emailAccountsList: []
			}
		},
		mounted(){
			this.getSettingsEmailAccounts();
		},
		methods: {
			getSettingsEmailAccounts: function(){
				axios.get(this.$store.getters.getAPIBasePath+'/emailresource/list/email/account')
				.then(response => {
					let result = response.data;
					if (!response.status) {
						iziToast.error({title: 'Error', message: result.message, position: 'topRight'});
					}
					this.emailAccountsList = result.emailAccountsList;
				})
				.catch(error => {
					iziToast.error({title: 'Error', message: this.$config.LoadTempListErr, position: 'topRight'});
				});
			}
		}
	}
</script>