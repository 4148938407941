<template>
    <div class="one-content active" id="candidate_shortlist">
        <div class="card">
            <!-- <div class="card">
                <div class="card-header p-2">Search
                    
                </div>
                <div class="card-body p-2">
                    
                    
                </div>
            </div> -->
            <div class="card">
                <div class="card-header p-2">Candidates List
                    <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                    <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                        <div class="dropdown">
                            <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="javascript:" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                            <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:" @click="changeLimit(value)">{{value}}</a>
                            </div>
                        </div>
                    </span>
                    <a href="javascript:" @click="elements.showCanList=!elements.showCanList;" data-target="canList" class="boxhide pull-right"><span :class="elements.showCanList?'ti-angle-up':'ti-angle-down'"></span></a>
                </div>
                <div :class="elements.showCanList?'card-body p-2 d-block':'card-body p-2 d-none'" id="canList">
                    <div class="card-header p-2">Search
                        <a href="javascript:" @click="elements.showSearch=!elements.showSearch;" data-target="main-search" class="boxhide"><span class="pull-right" :class="elements.showSearch?'ti-angle-up':'ti-angle-down'"></span></a>
                        <div class="load-indicator"></div>
                        <b-form-checkbox id="applied-candidates" name="applied-candidates" v-model="elements.showAppliedCandidates" @change="jobAppliedCandidates" class="px-5 pull-right">Applied Candidates</b-form-checkbox>
                        <!-- <b-form-checkbox id="verified-candidates" name="verified-candidates" v-model="search.params.status" :value=1 @change="findVerifiedStatus($event)" class="px-5 pull-right">Verified Candidates</b-form-checkbox> -->
                    </div>
                    <form id="main-search" :class="elements.showSearch?'d-block':'d-none'">
                        <div class="row form-group ten-columns">
                            <div class="col-md-3 col-sm-6 col-12">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Code/Name/Email/Phone</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input class="form-control" v-on:keyup.enter="findCandidates" type="text" v-model="search.params.searchString" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="false" class="col-md-3 col-sm-6 col-12">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input class="form-control" v-on:keyup.enter="findCandidates" type="text" v-model="search.params.firstName" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Industry</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select class="form-control" v-model="search.params.industryId">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in industries" :value="info.id">{{info.industryName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Status</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select class="filter form-control" v-model="search.params.status">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in statusList" :value="info.id">{{info.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Position</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input class="form-control" v-on:keyup.enter="findCandidates" type="text" v-model="search.params.position" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group ten-columns">
                            <div class="col-md-3 col-sm-6 col-12 mt-1 mb-1">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Location</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input class="form-control" v-on:keyup.enter="findCandidates" type="text" v-model="search.params.location" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12 mt-1 mb-1">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Technology</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input class="form-control" v-on:keyup.enter="findCandidates" type="text" v-model="search.params.technology" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12 mt-1 mb-1">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Skills</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input class="form-control" v-on:keyup.enter="findCandidates" type="text" v-model="search.params.skills" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12 mt-1 mb-1">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Exp</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <select class="form-control" v-model="search.params.experienceFrom">
                                                        <option class="pl-2" value="">Select</option>
                                                        <option v-for="(data, index) in yearexperience" :value="data.expyear">{{data.label}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="false" class="col-md-6 col-sm-12 col-12">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Match %</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input class="form-control" type="number" v-model="search.params.jobMatch" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="false" class="col-md-2 col-sm-6 col-12 offset-md-1 mt-2">
                                <div class="row">
                                    <div class="col-md-12 mt-4">
                                        <button type="button" @click="resetCandidatesSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                        <button type="button" @click="findCandidates" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Find</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group ten-columns">
                            <div class="col-md-12 float-right text-right">
                                <a @click="findCandidates" title="Find" class="btn btn-primary btn-sm hover-hand mt-2"><i class="fa fa-search" aria-hidden="true"></i></a>
                                <a @click="resetCandidatesSearch" title="Reset" class="btn btn-primary btn-sm hover-hand mt-2 ml-2"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                <a @click="" title="Job Match Criteria" class="btn btn-primary btn-sm hover-hand mt-2 ml-2"><i class="fa fa-navicon" aria-hidden="true" data-toggle="modal" data-target="#matchCriteria"></i></a>
                            </div>
                        </div>
                    </form>
                    <div class="basic-block">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="table-responsive mh-25 min-vh-25">
                                    <table class="table table-borderless all-table">
                                        <thead>
                                            <tr>
                                                <!-- <th class="" scope="col">#</th> -->
                                                <th scope="col"></th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Position</th>
                                                <th scope="col">Exp</th>
                                                <th scope="col">Technology</th>
                                                <th scope="col">Skills</th>
                                                <th scope="col">Place</th>
                                                <th v-if="false" scope="col">Phone</th>
                                                <th v-if="false" scope="col">Email</th>
                                                <th scope="col">Job Match %</th>
                                                <!-- <th scope="col">Action</th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="candidates.candidateslist.length==0">
                                                <td colspan=9 align="center">No Candidates found</td>
                                            </tr>
                                            <tr v-for="(Candidate,index) in candidates.candidateslist">
                                                <!-- <td><a href="" class="expand" data-targetopen="panel1"><span class="ti-arrow-circle-down"></span></a></td> -->
                                                <!--   ,name:Candidate.candidateName -->
                                                <td>
                                                    <div class="form-check form-check-inline">
                                                        <!-- <input class="form-check-input" v-model="shortList.selCandidatesId" type="checkbox" checked="" :id="'rowchckbox'+Candidate.id" :value="{id:Candidate.id,attachmentTypeId:Candidate.attachmentTypeId,candidateName:Candidate.candidateName}"> -->
                                                        <input class="form-check-input" v-model="shortList.selectedCandidatesIds" type="checkbox" checked="" :id="'rowchckbox'+Candidate.id" :value="Candidate.id">
                                                        <label class="form-check-label" :for="'rowchckbox'+Candidate.id"></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <router-link title="View Candidate Profile" :to="{ name: 'viewcandidate', params: { candidateid: Candidate.id }}">{{getFullName(Candidate.firstName,Candidate.lastName)}}</router-link>
                                                </td>
                                                <td>{{Candidate.position}}</td>
                                                <td>{{Candidate.experience}}</td>
                                                <td :title="Candidate.technologies" v-html="gettechsubstring(Candidate.technologies.join(','))"></td>
                                                <td :title="Candidate.skills" v-html="gettechsubstring(Candidate.skills.join(','))"></td>
                                                <td>{{Candidate.location}}</td>
                                                <td v-if="false">{{Candidate.mobile}}</td>
                                                <td v-if="false">{{Candidate.email}}</td>
                                                <td class="text-center"> {{Candidate.matchingPercentage}}%</td>
                                                <!-- <td>
                                                    <div class="dropdown">
                                                        <span data-toggle="dropdown" class="ti-more-alt more-span"></span>
                                                        <div class="dropdown-menu">
                                                            <a class="dropdown-item" href="editcandidate.html"><span class="ti-cut"></span> Edit</a>
                                                            <a class="dropdown-item" href="scheduledinterviews.html"><span class="ti-files"></span> Schedule Interview</a>
                                                            <a class="dropdown-item" href="#"><span class="ti-close"></span>  Deactivate</a>
                                                        </div>
                                                    </div>                                                
                                                </td> -->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <b-pagination v-if="candidates.candidateslist.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadCandidates()"></b-pagination>
                            </div>
                        </div>
                        <a href="javascript:" @click="addCanShortList" class="pull-right btn btn-primary"><i class="ti-plus button"></i> Add</a>
                        <a href="javascript:" @click="elements.showCanList=!elements.showCanList;" class="pull-right btn btn-danger mr-3"><i class="ti-close button"></i> Cancel</a>
                        <!-- <router-link class="float-left btn btn-primary " title="Compare Candidate" :to="{ name: 'comparecandidate'}"></i> Compare Candidate</router-link> -->
                        <a class="float-left btn btn-primary " href="javascript:" @click="viewCompareCandidate" title="Compare Candidate"> Compare Candidate</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">Talents Identified</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <!-- <th class="" scope="col">#</th> -->
                                            <!-- <th scope="col"></th> -->
                                            <th scope="col">Name</th>
                                            <th scope="col">Position</th>
                                            <th scope="col">Experience</th>
                                            <!-- <th scope="col">Skills</th> -->
                                            <th scope="col">Place</th>
                                            <th v-if="false" scope="col">Phone</th>
                                            <th v-if="false" scope="col">Email</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="shortlistedCandidates.length==0">
                                            <td colspan=9 align="center">No Candidates found</td>
                                        </tr>
                                        <tr v-for="(ShortListCandidate,index) in shortlistedCandidates">
                                            <!-- <td><a href="" class="expand" data-targetopen="panel1"><span class="ti-arrow-circle-down"></span></a></td> -->
                                            <!-- <td>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" v-model="shortList.selCandidatesId" type="checkbox" :id="'rowchckbox'+Candidate.id" :value="Candidate.id">
                                                    <label class="form-check-label" :for="'rowchckbox'+Candidate.id"></label>
                                                </div>
                                            </td> -->
                                            <td>
                                                <router-link title="View Candidate Profile" :to="{ name: 'viewcandidate', params: { candidateid: ShortListCandidate.candidateId }}">{{ShortListCandidate.candidateName}}</router-link>
                                            </td>
                                            <td>{{ShortListCandidate.position}}</td>
                                            <td>{{ShortListCandidate.experience}}</td>
                                            <!-- <td>{{ShortListCandidate.technology}}</td> -->
                                            <td>{{ShortListCandidate.cityName}} {{ShortListCandidate.stateName}}</td>
                                            <td v-if="false">{{ShortListCandidate.mobile}}</td>
                                            <td v-if="false">{{ShortListCandidate.email}}</td>
                                            <td>
                                                <!-- <span :class="ShortListCandidate.statusName" class="label text-white p-1 rounded w-50-auto slstatus">{{ShortListCandidate.statusName}}</span> -->
                                                <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span :class="ShortListCandidate.statusName"  class="label text-white p-1 rounded w-50-auto slstatus" :style="[shouldSetWidth === true ? {width: '170px'}: {width: '117px'}]">{{ShortListCandidate.statusName}}</span>
                                                    <div class="m-0 dropdown-menu p-0" aria-labelledby="slstatus-dropdown">
                                                        <ul class="m-0">
                                                            <li v-for="(CandSLStatus, idx) in shortList.candSLStatus" v-bind:key="idx">
                                                                <a v-on:click="changeStatus(ShortListCandidate.rpCandShortlistId,CandSLStatus.id,ShortListCandidate.attachmentTypeId,ShortListCandidate.visaTypeText,ShortListCandidate.candidateId,ShortListCandidate.salaryNegotiationCount)" class="dropdown-item" href="javascript:">{{CandSLStatus.name}}</a>
                                                                <!-- <a v-on:click="$emit('changeSLcandStatus', {'rpCandSLid':ShortListCandidate.rpCandShortlistId, 'CandSLstatusId':CandSLStatus.id})" class="dropdown-item" href="javascript:">{{CandSLStatus.name}}</a> -->
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </a>
                                            </td>
                                            <td>
                                                <a v-if="ShortListCandidate.status=='1' && (ttClientAccountId == ShortListCandidate.ttClientAccountId || ShortListCandidate.viewed==1)" href="javascript:" title="Schedule Interview" @click="scheduleInterview(ShortListCandidate.candidateId)"><i class="fa fa-calendar-plus-o fa_custom fa-lg px-1"></i></a>
                                                <a v-if=" (ttClientAccountId == ShortListCandidate.ttClientAccountId || ShortListCandidate.viewed==1)" href="javascript:" @click="$emit('emailTools',ShortListCandidate)" title="Email"><i class="fa fa-envelope-o fa_custom fa-lg px-1"></i></a>
                                                <a v-if="ShortListCandidate.status=='1'" href="javascript:" title="Remove" data-toggle="modal" data-target="#removeCandidate" @click="deleteCandidate(ShortListCandidate.rpCandShortlistId)"><i class="fa fa-trash-o fa_custom fa-lg  px-1"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row mt-3">
            <div class="col-md-12">
                <button type="button" class="btn btn-primary float-right" style="margin-left: 10px;">Save</button>
                <button type="button" class="btn btn-danger float-right" style="margin-left: 10px;">Cancel</button>
            </div>
        </div> -->
        <Dialog id="removeCandidate" :onYes="removeCandidate" title="Delete Confirmation" message="Are you sure to delete this candidate ?" />
        <Dialog id="comparisonLimit" title="Comparison Limit" message="You can compare 4 candidates at the maximum" />
        <!-- <b-modal id="matchCriteria" ref="matchCriteriaModal" title="Match Criteria" @ok="check" @cancel="cancelMatchField" @close="cancelMatchField" class="w-100">
            <form ref="form" @submit.stop.prevent="" class="col-md-12 col-sm-12 col-xs-12">
                <div class="row">
                    <multiselect v-model="matchFieldsSelected" :options="matchFields" name="label" label="label" track-by="id" select-label="" deselect-label="" class="w-100" :max-height="600" placeholder="Choose match fields" :multiple="true" :close-on-select="false"></multiselect>
                </div>
            </form>
        </b-modal> -->
        <div class="modal" id="salaryNegConfirm" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Information</h5>
                        <button type="button" @click="closeModal"  class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div>You are trying to skip the Salary Negotiation step. Do you wish to continue?</div>
                    </div>
                    <div class="modal-footer">                                            
                        <button type="submit" @click="changeStat()" class="btn btn-primary">Yes</button>
                        <button type="button" @click="openSalNeg()" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="matchCriteria" tabindex="-1" role="dialog" aria-labelledby="matchCriteriaModal" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Match Criteria</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cancelMatchField">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <multiselect v-model="matchFieldsSelected" :options="matchFields" name="label" label="label" track-by="id" select-label="" deselect-label="" class="w-100" :max-height="600" placeholder="Choose match fields" :multiple="true" :close-on-select="false"></multiselect>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="fieldsMatching()" class="btn btn-primary" data-dismiss="modal">OK</button>
                        <button type="button" @click="cancelMatchField()" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import iziToast from 'izitoast';
import _ from 'lodash';
iziToast.settings({
    timeout: 2000,
})

export default {
    props: {
        shortlistedCandidates: Array,
        jobDetails: Object,
        candContactAccess: [String, Number]
    },
    components: {
        Multiselect
    },
    computed: {
        jobid() {
            return this.$route.params.jobid;
        },
        shouldSetWidth() {
            return this.shortlistedCandidates.some(candidate => candidate.statusName === "End Client Interview Scheduled");
        }
    },
    data() {
        return {
            hiredCount: '',
            salaryNegotiationAccess:Boolean,
            shortListdata: [],
            recPlanId: localStorage.getItem('setRecPlanId'), // this.$store.getters.getRecPlanId,
            technologies: this.$store.getters.getTechOptions,
            industries: this.$store.getters.getIndustries,
            statusList: this.$store.getters.getCandidatesStatusList,
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            searchlimit: this.$store.state.searchlimit,
            elements: {
                showSearch: true, //false,
                showCanList: true, //false,
                delCanSLId: null,
                showAppliedCandidates: false
                //showVerifiedCandidates: true
            },
            yearexperience: [],
            search: {
                params: {
                    firstName: "",
                    lastName: "",
                    searchString: "",
                    cityName: "",
                    technologyId: "",
                    technology: "",
                    skills: "",
                    experienceFrom: "",
                    skillId: "",
                    industryId: "",
                    location: "",
                    position: "",
                    jobId: 0,
                    jobMatch: "",
                    eligibility: "",
                    workType: "",
                    salary: "",
                    minSalary: "",
                    maxSalary: "",
                    frequencyPercentage: "",
                    jobTechnology: "",
                    technologySet: [],
                    jobSkill: "",
                    skillSet: [],
                    fieldCount: 0,
                    status: "",
                    fieldNameList: [],
                    talentJobId:''
                }
            },
            candidates: {
                candidateslist: [],
                jobAppliedCandidates: []
            },
            shortList: {
                candSLStatus: this.$store.getters.getShortListedCandidateStatus,
                selCandidatesId: [],
                selCanIdJson: [],
                shortListCand: [],
                selectedCandidatesIds: []
            },
            matchFieldsSelected: [],
            htmlError: '',
            matchFields: [
                { id: 1, label: "Experience", fieldName: "experience" },
                { id: 2, label: "Industry", fieldName: "industry" },
                { id: 3, label: "Position", fieldName: "position" },
               /*  { id: 4, label: "Eligibility", fieldName: "eligibility" }, */
                { id: 5, label: "Work Type", fieldName: "workType" },
                { id: 6, label: "Expected Salary", fieldName: "expectedSalary" },
                { id: 7, label: "Technology", fieldName: "technology" },
                { id: 8, label: "Skill", fieldName: "skill" },
            ],
            fieldName: [],
            ttClientAccountId: localStorage.getItem('clientAccountId'),

        }
    },
    watch: {
        shortlistedCandidates(newValue, oldValue) {
            // this.shortList.shortListCand = this.filterByStatus("1");
            this.checkCandidatesList();
        }
    },
    mounted() { 
        this.loadCandidates();
       // this.checkCandidatesList(); 
        this.generateExpYear();
        this.shortList.candSLStatus = this.filterByDropDownStatus([3, 4, 5, 6, 7, 10, 11, 12, 13, 14, 15]);
        // this.shortList.shortListCand = this.filterByStatus("1");   
        this.salaryAccess();   
        const hiredCount = this.shortlistedCandidates.filter(candidate => (candidate.status === "7" || candidate.status === "5")).length;
        this.hiredCount = hiredCount;
    },
    methods: {
        salaryAccess(){
            if(localStorage.getItem('salaryNegotiationAccess') === "false"){
                    this.salaryNegotiationAccess  = false
                }else if(localStorage.getItem('salaryNegotiationAccess') === "true"){
                    this.salaryNegotiationAccess  = true
            }
        },
        openSalNeg(){
			// this.getSalaryNegotiation(this.salNeg.candidateId,this.salNeg);
			this.closeModal();
		},
		changeStat(){
            if(this.jobDetails.vacancies > this.hiredCount || this.jobDetails.vacancies== "" || this.jobDetails.vacancies== null || this.jobDetails.vacancies== undefined){
			    this.$emit('changeSLcandStatus', {'rpCandSLid':this.shortListdata[0].rpCandShortlistId, 'CandSLstatusId':this.shortListdata[0].candSLStatusId,'candidateId':this.shortListdata[0].candidateId});
            }else{
				iziToast.info({ message:this.$config.JobVacLimit, position: 'topRight'});
            }
			this.closeModal();
		},
        closeModal() {
			document.getElementById('salaryNegConfirm').classList.remove('show');
			document.getElementById('salaryNegConfirm').style.display = 'none';
			document.getElementById('salaryNegConfirm').setAttribute('aria-hidden', 'true');
		},
        jobDocumentData: function() {
            var result = '';
            if (this.jobDetails.jobDocumentData != null) {
                result = this.jobDetails.jobDocumentData.map(function(val) {
                    //val.dataName;
                    return val.id;
                }).join(',');
            }
            return result;
        },
        fieldsMatching: function(bvModalEvt) {
            let jobDetails = this.jobDetails;
            let self = this;
            self.search.params.fieldCount = 0;
            self.search.params.fieldNameList = [];
            self.matchFieldsSelected.filter(function(data, key) {
               
                switch (data.fieldName) {
                    case "experience":
                        {
                            //self.search.params.experience = jobDetails.experience;
                            self.search.params.fieldNameList.push(data.fieldName);
                            self.search.params.fieldCount = self.search.params.fieldCount + 1;
                            break;
                        }
                    case "industry":
                        {
                            //self.search.params.industryId = jobDetails.industryId;
                            self.search.params.fieldNameList.push(data.fieldName);
                            self.search.params.fieldCount = self.search.params.fieldCount + 1;
                            break;
                        }
                    case "position":
                        {
                            //self.search.params.position = jobDetails.position;
                            self.search.params.fieldNameList.push(data.fieldName);
                            self.search.params.fieldCount = self.search.params.fieldCount + 1;
                            break;
                        }
                    case "eligibility":
                        {
                            self.search.params.eligibility = jobDetails.eligibility;
                            self.search.params.fieldNameList.push(data.fieldName);
                            self.search.params.fieldCount = self.search.params.fieldCount + 1;
                            break;
                        }
                    case "workType":
                        {
                            self.search.params.workType = jobDetails.workType;
                            self.search.params.fieldNameList.push(data.fieldName);
                            self.search.params.fieldCount = self.search.params.fieldCount + 1;
                            break;
                        }
                    case "expectedSalary":
                        {
                            //self.search.params.salary = jobDetails.salary;
                            self.search.params.fieldNameList.push(data.fieldName);
                            self.search.params.fieldCount = self.search.params.fieldCount + 1;
                            break;
                        }
                    case "technology":
                        {
                            let jobTech = jobDetails.technologyList;
                            jobTech.filter(function(data, key) {
                                if (data.technologyName != null) {
                                    self.search.params.technologySet.push(data.technologyName);
                                }
                            });
                            self.search.params.fieldNameList.push("technology");
                            self.search.params.fieldCount = self.search.params.fieldCount + 1;
                            break;
                        }
                    case "skill":
                        {
                            let jobSkill = jobDetails.jobSkill;
                            jobSkill.filter(function(data, key) {
                                if (data.technologySkillSet != null) {
                                    self.search.params.skillSet.push(data.technologySkillSet.skillName);
                                }

                            });
                            self.search.params.fieldNameList.push("skill");
                            self.search.params.fieldCount = self.search.params.fieldCount + 1;
                            break;
                        }
                    default:
                        console.log("No Match field");
                }
                self.loadCandidates();
            });
        },
        generateExpYear() {
            for (var i = 1; i <= 20; i++) {
                var x = i.toString();
                if (i == 1) {
                    this.yearexperience.push({ "expyear": "0", "label": "0" });
                }
                this.yearexperience.push({ "expyear": x, "label": x });
            }
            this.yearexperience.push({ "expyear": "21", "label": "20+" });
        },
        resetCandidatesSearch() {
            this.search.params = {
                firstName: "",
                lastName: "",
                searchString: "",
                cityName: "",
                technologyId: "",
                technology: "",
                skills: "",
                experienceFrom: "",
                skillId: "",
                industryId: "",
                location: "",
                position: "",
                jobId: 0,
                jobMatch: "",
                eligibility: "",
                workType: "",
                salary: "",
                jobTechnology: "",
                technologySet: [],
                jobSkill: "",
                skillSet: [],
                fieldCount: 0,
                status: "",
                fieldNameList: []
            };
            this.matchFieldsSelected = [];
            this.loadCandidates();
        },
        findVerifiedStatus(e) {
            if (this.search.params.status === true || this.search.params.status > 0) {
                this.search.params.status = 0;
            } else {
                this.search.params.status = 8;
            }

            this.pagination.currentPage = 1;
            this.loadCandidates();
        },
        findCandidates() {
            this.pagination.currentPage = 1;
            this.loadCandidates();
        },
        loadCandidates() {
            this.search.params.pageNo = this.pagination.currentPage;
            this.search.params.limit = this.pagination.rowsPerPage;
            this.search.params.jobId = this.jobid;
            this.search.params.talentJobId = this.jobid;
           
            const specialChars = /[()-]/;
            if(!(specialChars.test(this.search.params.searchString))){
            if(this.search.params.searchString.startsWith("+1")){
            this.search.params.searchString=this.search.params.searchString.replace(/\s+/g,'');
            this.search.params.searchString=this.search.params.searchString.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2)-$3-$4")
            }
            else if(this.search.params.searchString.startsWith("+91")&&!(specialChars.test(this.search.params.searchString))){
            this.search.params.searchString=this.search.params.searchString.replace(/\s+/g,'');
            this.search.params.searchString=this.search.params.searchString.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1 ($2)-$3-$4")
            }
            else 
            this.search.params.searchString=this.search.params.searchString.replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3"); 
            }
           else
           this.search.params.searchString=this.search.params.searchString
                 axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/candidatesearchnew', this.search.params)
                .then(response => {
                    let result = response.data;
                    this.candidates.candidateslist =  _.orderBy(result.data, ['matchingPercentage'], ['desc']);
                    this.candidates.jobAppliedCandidates = result.responseData;
                    this.pagination.totalRecords = result.totalRecords;
                    this.pagination.currentPage = result.currentPage;                    
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);

                });

        },
        changeLimit(newlimit) {
            this.pagination.currentPage = 1;
            this.pagination.rowsPerPage = newlimit;
            this.loadCandidates();
        },
        // addCanShortList() {
        //     let output = [];
        //     let self = this;
        //     self.shortList.selCanIdJson = [];
        //     self.recPlanId = localStorage.getItem('setRecPlanId');
        //     if (!self.recPlanId) self.recPlanId = null;
        //     console.log("=======================================")
        //     let jobDocumentResult = self.jobDocumentData();
        //     let jobDocumentData = [];
        //     console.log(jobDocumentResult);
        //     if (jobDocumentResult != null) {
        //         jobDocumentData = jobDocumentResult.split(',');
        //     }
        //     $.each(self.shortList.selCandidatesId, function(key, value) {
        //         console.log(value);
        //         self.shortList.selCanIdJson.push({ "recruitmentPlanId": self.recPlanId, "jobId": self.jobid, "candidateId": value.id, "candidateDocuments": value.attachmentTypeId });

        //         let isExists = false;
        //         if (value.attachmentTypeId != null) {
        //             isExists = true;
        //             for (var item in jobDocumentData) {
        //                 console.log(jobDocumentData[item]);
        //                 if (!value.attachmentTypeId.includes(jobDocumentData[item])) {
        //                     isExists = false;
        //                 }
        //             }
        //         }
        //         if (!isExists) {
        //             output.push(value.candidateName);
        //         }

        //     });
        //     console.log(output.join(', '));
        //     let error = "Candidates " + output + " not satisfied the required documents";
        //     //iziToast.error({ title: 'Error', message: error, position: 'topRight' });

        //     //return;
        //     let data = {
        //         rpCandShortlist: self.shortList.selCanIdJson,
        //         jobDocument: jobDocumentResult,
        //     };
        //     console.log(data, jobDocumentResult);
        //     axios.post(self.$store.getters.getAPIBasePath + '/recruitmentplanresource/add/rp/candidate/shortlist', data)
        //         .then(response => {
        //             if (response.data.status == "ACCEPTED") {
        //                 self.$emit('refreshSLcandidates');
        //                 self.$emit('refreshIncidentHistory');
        //                 // self.shortList.shortListCand = [];
        //                 // self.shortList.shortListCand = this.filterByStatus("1");
        //                 self.elements.showSearch = false;
        //                 self.elements.showCanList = false;
        //                 let successmsg = (response.data.message != undefined) ? response.data.message : this.$config.CandsShortsuc;
        //                 iziToast.success({ title: 'Success', message: successmsg, position: 'topRight' });
        //             } else {
        //                 let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.ProcessErr;
        //                 iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
        //             }
        //         })
        //         .catch(error => {
        //             this.unAuthorized(error);
        //             console.log(error);
        //             let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.UpdatingErr;
        //             iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
        //         });
        // },
        addCanShortList() {
            let output = [];
            let self = this;
            let errorMsg = "";
            self.shortList.selCanIdJson = [];
            self.recPlanId = localStorage.getItem('setRecPlanId');
            if (!self.recPlanId) self.recPlanId = null;
            
            let jobDocumentResult = self.jobDocumentData();
            let jobDocumentData = [];
          
            if (jobDocumentResult != null) {
                jobDocumentData = jobDocumentResult.split(',');
            }
            let documentFlg = true;
            let data=null;
            let finalCandidates=[];
           /* START dont delete this comment block
           if (jobDocumentResult.length > 0) {
                if(self.shortList.selCandidatesId.length>0) {
                    if(self.shortList.selectedCandidatesIds.length==0) {
                        finalCandidates=self.shortList.selCandidatesId;
                    }
                    else {
                        $.each(self.shortList.selectedCandidatesIds, function(key, value) {
                        self.candidates.candidateslist.filter(function(candidate, key) {
                            if (candidate.id == value) {
                                finalCandidates.push({ "candidateName": candidate.firstName, "id": value, "attachmentTypeId": candidate.attachmentTypeId });
                            }
                        });
                        // self.shortList.selCanIdJson.push({ "recruitmentPlanId": self.recPlanId, "jobId": self.jobid, "candidateId": value, "candidateDocuments": value });
                    });
                    }
                    let isExists = false;
                    //$.each(self.shortList.selectedCandidatesIds, function(key, value) {
                    $.each(finalCandidates, function(key, value) {
                        console.log('value',value);
                        if (value.attachmentTypeId != null) {
                            isExists = true;
                            for (var item in jobDocumentData) {
                                console.log('jobDocumentData[item]',jobDocumentData[item]);
                                if (!value.attachmentTypeId.includes(jobDocumentData[item])) {
                                    isExists = false;
                                    documentFlg=false;
                                    output.push(value.candidateName);
                                    console.log('name: ',value.candidateName);
                                }
                            }
                            if(isExists) { 
                                self.shortList.selCanIdJson.push({ "recruitmentPlanId": self.recPlanId, "jobId": self.jobid, "candidateId": value.id, "candidateDocuments": value.attachmentTypeId });
                            }
                        }
                        else {
                            output.push(value.candidateName);
                            documentFlg=false;
                            errorMsg = "Some of the candidate[s] hasn't uploaded the required documents.";
                   
                            iziToast.info({ message: errorMsg + 'No candidates shortlisted....', position: 'topRight'});
                            return;
                       
                        }
                    });
                    if(!documentFlg) {
                        errorMsg = "Some of the candidate[s] hasn't uploaded the required documents.";
                       // if(self.shortList.selCandidatesId.length==output.length){
                            iziToast.info({ message: errorMsg + 'No candidates shortlisted....', position: 'topRight'});
                            return;
                        //}
                    }
                }
                else {
                    $.each(self.shortList.selectedCandidatesIds, function(key, value) {
                        self.candidates.candidateslist.filter(function(candidate, key) {
                            if (candidate.id == value) {
                                documentFlg=self.validateCandidateAttachment(candidate.attachmentTypeId,jobDocumentData);
                                if(documentFlg) { 
                                    self.shortList.selCanIdJson.push({ "recruitmentPlanId": self.recPlanId, "jobId": self.jobid, "candidateId": value, "candidateDocuments": candidate.attachmentTypeId });
                                }
                            }
                        });
                        // self.shortList.selCanIdJson.push({ "recruitmentPlanId": self.recPlanId, "jobId": self.jobid, "candidateId": value, "candidateDocuments": value });
                    });
                    if(!documentFlg) {
                        errorMsg = "Some of the candidate[s] hasn't uploaded the required documents.";
                        iziToast.info({ message: errorMsg + 'No candidates shortlisted....', position: 'topRight'});
                        return;
                    }
                }
                data = {
                    rpCandShortlist: self.shortList.selCanIdJson,
                    jobDocument: jobDocumentResult,
                };
            } END dont delete this comment block*/
           /* else {*/
                if(self.shortList.selCandidatesId>0) {
                    $.each(self.shortList.selCandidatesId, function(key, value) {
                        self.shortList.selCanIdJson.push({ "recruitmentPlanId": self.recPlanId, "jobId": self.jobid, "candidateId": value.id, "candidateDocuments": value.attachmentTypeId });
                    });
                }
                else {
                    $.each(self.shortList.selectedCandidatesIds, function(key, value) {
                        self.candidates.candidateslist.filter(function(candidate, key) {
                            if (candidate.id == value) {
                                self.shortList.selCanIdJson.push({ "recruitmentPlanId": self.recPlanId, "jobId": self.jobid, "candidateId": value, "candidateDocuments": candidate.attachmentTypeId });
                            }
                        });
                        //self.shortList.selCanIdJson.push({ "recruitmentPlanId": self.recPlanId, "jobId": self.jobid, "candidateId": value, "candidateDocuments": value });
                    });
                }
                data = {
                    rpCandShortlist: self.shortList.selCanIdJson,
                    jobDocument: jobDocumentResult,
                };
           /* }*/
            // let data = {
            //     rpCandShortlist: self.shortList.selCanIdJson,
            //     jobDocument: jobDocumentResult,
            // };

           if  (self.shortList.selectedCandidatesIds.length == 0){
                iziToast.info({ message:  this.$config.PlsSelCandToShort, position: 'topRight'});
                return;
           }
            axios.post(self.$store.getters.getAPIBasePath + '/recruitmentplanresource/add/rp/candidate/shortlist', data)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        self.$emit('refreshSLcandidates');
                        self.$emit('refreshIncidentHistory');
                        self.loadCandidates();
                        self.shortList.selectedCandidatesIds = [];
                        // self.shortList.shortListCand = [];
                        // self.shortList.shortListCand = this.filterByStatus("1");
                        self.elements.showSearch = false;
                        self.elements.showCanList = false;
                        let successmsg = (response.data.message != undefined) ? errorMsg + response.data.message : errorMsg + this.$config.CandsShortsuc;
                        if(successmsg==''){
                            iziToast.error({ timeout:7000, title: 'Error', message: this.$config.NoCandsErr, position: 'topRight' });
                        }
                        else {
                            iziToast.success({ timeout:7000, title: 'Success', message: successmsg, position: 'topRight' });
                        }
                        
                    } else {
                        let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.ProcessErr;
                        iziToast.error({ timeout:7000, title: 'Error', message: errmsg, position: 'topRight' });
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                    let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.UpdatingErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
        },
        areDifferentByIds1: function(a, b) {
            var idsA = a.map(function(x) {
                return x.id;
            }).unique().sort();
            var idsB = b.map(function(x) { return x.id; }).unique().sort();
            return (idsA.join(',') !== idsB.join(','));
        },
        areDifferentByIds: function(a, b) {
            var idsA = a.map(function(x) { return x.id; }).unique();
            var idsB = b.map(function(x) { return x.id; }).unique();
            var idsAB = a.concat(b).unique();
            return idsAB.length !== idsA.length
        },
        scheduleInterview(candId) {
            localStorage.setItem('recPlanId', this.recPlanId);
            localStorage.setItem('jobId', this.jobid);
            localStorage.setItem('recruitmentId', null);
            this.$router.push({ name: 'scheduleinterview', params: { candidateid: candId } })
        },
        filterByStatus(statusId) {
            return this.shortlistedCandidates.filter(function(data, key) {
                if (data.status == statusId) {
                    return data;
                }
            });
        },
        deleteCandidate(id) {
            this.elements.delCanSLId = id;
        },
        removeCandidate() {
            let self = this;
            axios.delete(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/delete/candshortlist/' + this.elements.delCanSLId)
                .then(response => {
                    if (response.data.status == "OK") {
                        self.$emit('refreshSLcandidates');
                        self.$emit('refreshIncidentHistory');
                        self.loadCandidates();
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                });
            this.elements.delCanSLId = null;
        },
        checkCandidatesList() {
            this.shortList.selCandidatesId = [];
            this.shortList.selectedCandidatesIds = [];
            let self = this;
            $.each(this.shortlistedCandidates, function(key, value) {
                //self.shortList.selCandidatesId.push(value.candidateId);
                self.shortList.selCandidatesId.push({ id: value.candidateId, attachmentTypeId: value.attachmentTypeId, candidateName: value.candidateName });
                self.shortList.selectedCandidatesIds.push(value.candidateId)
            });
        },
        getrandomNumber() {
            return Math.floor(Math.random() * (100 - 1 + 1)) + 1;
        },
        gettechsubstring(technology) {
            if (technology != null && technology.length > 10) {
                return technology.substring(0, 10) + "...";
            } else
                return technology;
        },
        viewCompareCandidate() {
            // if (this.shortList.selCandidatesId.length > 4) {
            //     iziToast.warning({ title: 'Limit Exceeded', message: "Comparison Limit is 4 candidates. You're trying to compare " + this.shortList.selCandidatesId.length + " candidates", position: 'topRight' });
            //     return false;
            // }

            // this.$emit('viewCompareCandidate', this.shortList.selCandidatesId);

            let candidateList = [];
            let flg = false;
            if (this.shortList.selectedCandidatesIds.length > 4) {
                iziToast.warning({ title: 'Limit Exceeded', message: this.$config.CmpLmtCands + this.shortList.selectedCandidatesIds.length + " candidates", position: 'topRight' });
                return false;
            }
            this.shortList.selCandidatesId.map((selCandidate,index) => {
                this.shortList.selectedCandidatesIds.map((candidateId) => {
                    if(selCandidate.id == candidateId){
                        candidateList.push(selCandidate);
                    }
                });
            });
            this.shortList.selectedCandidatesIds.map((candidateId,index) => {
                flg = false;
                this.shortList.selCandidatesId.map((selCandidate) => {
                    if(candidateId == selCandidate.id){
                        flg = true;
                    }
                });
                if(!flg){
                    this.candidates.candidateslist.map((candidate) => {
                    if(candidateId == candidate.id){
                        candidateList.push({ id: candidate.id, attachmentTypeId: candidate.attachmentTypeId, candidateName: candidate.candidateName });
                    }
                });
                }
            });
            this.$emit('viewCompareCandidate', candidateList);
        },
        filterByDropDownStatus(startId,endId){
				return this.shortList.candSLStatus.filter(function(data,key){
					if((data.id>=startId && data.id<=endId)&& (data.id != 5 && data.id != 6 )){
						return data;
					}
				});
		},
        filterByDropDownStatus(selStatus) {
            return this.shortList.candSLStatus.filter(function(data, key) {
                if (selStatus.includes(data.id) && (data.id != 5 && data.id != 6 )) {
                    return data;
                }
            });
        },
        jobAppliedCandidates: function(checkboxValue) {
            this.search.params.jobId = this.jobid;
            if (checkboxValue) {
                axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/jobapplied/candidates?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                    .then(response => {
                        let result = response.data;
                        this.candidates.candidateslist =  _.orderBy(result.data, ['matchingPercentage'], ['desc']);                        
                        this.pagination.totalRecords = result.totalRecords;
                        this.pagination.currentPage = result.currentPage;           
                       /*  this.candidates.candidateslist = _.orderBy(result.candidates, ['jobMatchPercentage'], ['desc']);
                        this.candidates.jobAppliedCandidates = result.candidates;
                        this.pagination.totalRecords = result.pagination[0].total;
                        this.pagination.currentPage = result.pagination[0].currentPage; */
                    })
                    .catch(error => {
                        this.unAuthorized(error);
                        console.log(error);
                    });
            } else {
                this.loadCandidates();
            }
        },
        cancelMatchField: function() {
            this.matchFieldsSelected = [];
            this.search.params.fieldCount = 0;
            this.search.params.fieldNameList = [];
        },
        handleSubmit() {
            this.$nextTick(() => {
                this.$refs.matchCriteriaModal.hide();
            });

        },
        validateCandidateAttachmentold(attachmentTypeId, jobDocumentData) {
            let isExistsLoc = true;
           
            for (var item in jobDocumentData) {
                if (attachmentTypeId==="" || !attachmentTypeId.includes(jobDocumentData[item])) {
                    isExistsLoc = false;
                    //documentFlg=false;
                    // output.push(candidate.candidateName);                    
                }
            }
            return isExistsLoc;
        },
        async validateCandidateAttachment(attachmentTypeId) {
            let isExistsLoc = true;
            let jobRequiredDocument = await this.jobDocumentData();          

            for (var item in jobRequiredDocument) {
                if (attachmentTypeId==="" ||  attachmentTypeId == null || !attachmentTypeId.includes(jobRequiredDocument[item])) {
                    isExistsLoc = false;
                }
            }
            if(!isExistsLoc) {
                iziToast.info({ message:this.$config.CandReqDocUpErr, position: 'topRight'});
                return isExistsLoc;
            }
        
            return isExistsLoc;
        },
		async changeStatus(rpCandShortlistId, candSLStatusId, attachmentTypeId, visaType, candidateId,salaryNegotiationCount){
				const type = ["us citizen", "green card", "gcead","uscitizen"];
				let isVisaType = false;
				if(visaType != null && visaType.length > 0)
					isVisaType = type.includes(visaType.toLowerCase());
				let isValid; 
				if(candSLStatusId == 3 || candSLStatusId == 6 || candSLStatusId == 10 || candSLStatusId == 11 || candSLStatusId == 12 || candSLStatusId == 13 || candSLStatusId == 14 || candSLStatusId == 15 || isVisaType == true){
					isValid = true;
				}
				else{
					isValid  = await this.validateCandidateAttachment(attachmentTypeId);
				}
				if(isValid){
                    if(this.salaryNegotiationAccess  == true && (salaryNegotiationCount == 0) && (candSLStatusId == 4 || candSLStatusId == 7 )){
						var shortListdata = [{
							"rpCandShortlistId": rpCandShortlistId,
							"candSLStatusId": candSLStatusId,
                            "candidateId": candidateId
						}]
						this.shortListdata = shortListdata;
						document.getElementById('salaryNegConfirm').classList.add('show');
						document.getElementById('salaryNegConfirm').style.display = 'block';
						document.getElementById('salaryNegConfirm').setAttribute('aria-hidden', 'false');
					}else if(
                        (this.jobDetails.vacancies !== null &&
                        this.jobDetails.vacancies !== undefined &&
                        this.jobDetails.vacancies !== "" &&
                        parseInt(this.jobDetails.vacancies) <= this.hiredCount) &&
                        (candSLStatusId == 7)                        ){
						iziToast.info({ message:this.$config.JobVacLimit, position: 'topRight'});
                        }
                        else{
					this.$emit('changeSLcandStatus', {'rpCandSLid':rpCandShortlistId, 'CandSLstatusId':candSLStatusId,'candidateId':candidateId});
                    }
                }
			}
		}
}

</script>
