<template>
    <div class="settings">
        <div class="row col-md-12 py-3">
            <div class="col-md-4">
                <div @click="$emit('setSubTab',1)" class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <img src="@/assets/icons/technology-skills-icons.png" width="100%" height="100%" />
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <h6><b>Technologies & Skills</b></h6>
                        <p>Set Technology & Skills</p>
                    </div>
                </div>
            </div>            
            <div class="col-md-4">
                <div @click="$emit('setSubTab',2)" class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <img src="@/assets/icons/sourcing-channel.png" width="100%" height="100%" />
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <h6><b>Technology Standards</b></h6>
                        <p>Manage Technologies</p>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</template>
<script>
export default {
    
    data() {
        return {
           userInfo:this.$store.getters.currentUser
        }
    },
    mounted() {
    }
}

</script>
