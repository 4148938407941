<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div class="card border-5 mb-3">
                                    <div class="col-md-12 col-sm-12 px-0 about-box">
                                        <div class="col-md-8 px-0 float-left">
                                            <h6 class="m-0 text-left p-2">
                                                <a href="javascript:" @click="getPrevUrl()" title="Back" class="p-2">
                                                    <i class="fa fa-arrow-left"></i>
                                                    <span> Back</span>
                                                </a>
                                            </h6>
                                        </div>
                                        <div class="col-md-4 px-0 pull-right py-2 text-right pr-2">
                                            <router-link class="link" title="View Lead" :to="{ name: 'viewlead'}"><i class="fa fa-eye fa_custom fa-lg px-1"></i></router-link>
                                        </div>
                                    </div>
                                    <div class="user-card pl-5 pr-5 pb-2 pt-3">
                                        <div class="information">
                                            <div class="imgUp">
                                                <!-- <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' +  (clientinfo.image===''?clientinfo.imagePath:clientinfo.image) + ')' }"></div> -->
                                            </div>
                                            <div>{{vendorInfo.companyName}}</div>
                                            <div  v-if="vendorInfo.email !=  ''"><i class="ti-email"></i> {{vendorInfo.email}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="user-card p-0">
                                    <h5 class="about-box m-0 text-left p-2">Company Info</h5>
                                    <div class="p-3 float-left w-100">
                                        <div class="informations">
                                            <span v-if="vendorInfo.length == 0" class="badge p-2 mb-1">No records found</span>
                                            <span v-if="vendorInfo.length != 0 && vendorInfo.phone != ''">
                                                <i class="fa fa-phone pr-2" aria-hidden="true"></i>
                                                {{vendorInfo.phoneNumber}}
                                            </span>
                                            <span v-if="vendorInfo.phone != ''"><hr></span>
                                            <span v-if="vendorInfo != null && vendorInfo.length != 0">
                                                <i v-if="vendorInfo.cityName != '' && vendorInfo.stateName != '' && vendorInfo.countryId != '' && vendorInfo.zipCode != ''"  class="ti-location-pin pr-2"></i>                                     
                                                <span v-if="vendorInfo.cityName != ''">{{vendorInfo.cityName}}</span>
                                                <span v-if="vendorInfo.stateName != ''">,{{vendorInfo.stateName}}</span>
                                                <span v-if="vendorInfo.countryId != ''">,{{vendorInfo.countryId}}</span>
                                                <span v-if="vendorInfo.zipCode != ''"> - {{vendorInfo.zipCode}}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card border-5">
                                    <div class="user-card p-0">
                                        <h5 class="about-box m-0 text-left p-2">About Me</h5>
                                        <div class="p-3 float-left w-100">
                                            <h5>Website</h5>
                                            <div class="informations">
                                                <span v-if="vendorInfo.websiteUrl == ''" class="badge p-2 mb-1">No records found</span>
                                                <span v-if="vendorInfo.websiteUrl != ''">{{vendorInfo.websiteUrl}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-9">
                        <div class="row no-gutters">
                            <b-tabs v-model="currentClientTab" small card class="tab-list driver-tab-list">
                                <b-tab title="Lead Details">
                                    <VendorInfo :vendorinfo="vendorInfo" ref="vendorinfocomponent"></VendorInfo>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <div class="row">
                        <div class="col-sm-12">
                            <a href="Javascript:void(0);" @click="saveCurrentTab" class="btn btn-primary btn-sm float-right">Save</a>
                            <a href="Javascript:void(0);" @click="getPrevUrl" class="btn btn-secondary btn-sm mr-2  float-right">
                                Cancel
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import VendorInfo from './blocks/addvendor/VendorInfo';
export default {
    computed: {
        vendorid() {
            return this.$route.params.vendorid;
        }
    },
    components: {
        VendorInfo,

    },
    data() {
        return {
            currentClientTab: 0,
            maxtabs: 3,
            vendorInfo: {
                companyName: '',
                email: '',
                phone:'',
                phoneNumber:'',
                websiteUrl: '',
                industryId: '',
                linkedinUrl:'',
                facebookUrl:'',
                twitterUrl:'',
                instagramUrl:'',
                street:'',
                cityName:'',
                stateName:'',
                countryId:'',
                zipCode:'',
                yearFounded:'',
                // countryCode: '+91',
            },
        }
    },
    mounted() {
        this.loadVendor(this.vendorid);
    },
    methods: {
        loadVendor(vendorid){
            axios.get(this.$store.getters.getAPIBasePath + '/lead/view/' + vendorid)
                .then(response => {
                    let result = response.data;
                    this.vendorInfo = result.recordinfo;
                    this.vendorInfo.industryId = (this.vendorInfo.industryId != null ? this.vendorInfo.industryId : '');

                    // let phoneNo = result.recordinfo.phone;
                    // if (phoneNo != null && phoneNo.startsWith("+")) {
                    //     let phoneData = phoneNo.split(" ");
                    //     this.vendorInfo.countryCode = phoneData[0];
                    //     this.vendorInfo.phone = phoneData[1];
                    // }
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.LeadInfoCreErrTryAg,
                        position: 'topRight'
                    });                   
                });
        },
        saveCurrentTab() {
            switch (this.currentClientTab) {
                case 0:
                    if (this.$refs.vendorinfocomponent.validatevendorinfo()) {
                        this.saveVendorInfo();
                    }
                    break;
                default:
                    break;
            }
        },
        saveVendorInfo() {
            let phoneNumber = '';
            if (this.vendorInfo.phone != '') {
                phoneNumber = this.vendorInfo.countryCode + " " + this.vendorInfo.phone;
                const phoneNum = phoneNumber.replace("undefined", "").trim();
                this.vendorInfo.phoneNumber = phoneNum;
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lead/edit/' + this.vendorid, this.vendorInfo)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.LeadInfoCreSuc,
                        position: 'topRight'
                    }); 
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.LeadInfoCreErr,
                        position: 'topRight'
                    });
                });
        },
    }
}

</script>
