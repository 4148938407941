<template>
    <div class="d-height">
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mtb-4 bg-white rounded pos-rel">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right">
                            <li><a @click="searchMore=!searchMore" href="javascribt:;" data-target="main-search" class="boxhide">
                                <span v-if="!searchMore" class="ti-angle-down"></span>
                                <span v-if="searchMore" class="ti-angle-up"></span>
                            </a></li> 
                                <!-- <li><a href="javascribt:;" data-target="main-search" class="boxhide"><span class="ti-angle-down"></span></a></li>    -->
                            </ul>
                        </div>
                    </div>
                    <form v-if="searchMore" id="main-search" style="margin-top:15px;">
                        <div class="row form-group ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                   <div class="col-md-12 col-sm-12 col-12 lb">
                                       <label>  Interview Title</label>
                                   </div>
                                   <div class="col-md-12 col-sm-12 col-12">
                                      <div class="input-group">
                                        <input type="text" class="form-control" v-on:keyup.enter="findRequests" placeholder="Interview Title" v-model="search.params.title">
                                      </div>
                                   </div>
                                </div>  
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                   <div class="col-md-12 col-sm-12 col-12 lb">
                                       <label>Interview Date</label>
                                   </div>
                                   <div class="col-md-12 col-sm-12 col-12">
                                      <div class="input-group">
                                        <datepicker placeholder="Interview Date" v-on:keyup.enter="findRequests" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.interviewDate" ></datepicker>
                                      </div>
                                   </div>
                                </div>  
                            </div>


                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                               
                            </div>

                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                
                            </div>

                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                   <div class="col-md-12 col-sm-12 col-12 lb" style="visibility: hidden;">
                                       <label>End Date</label>
                                   </div>
                                   <div class="col-md-12 col-sm-12 col-12 float-right">
                                      <button type="button" class="btn btn-primary  float-right" style="margin-left: 10px;" @click="loadRequests(1)">Reset</button>
                                      <button type="button" class="btn btn-primary  float-right" style="margin-left: 10px;" @click="findRequests">Find</button>
                                   </div>
                                </div>  
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="container-fluid" id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-12 col-sm-12 col-12">
                            <h4 class="page-title">
                                Interviews 
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{browsepagination.totalrecords}} - Total)</span>
                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                            <div class="dropdown">
                                <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>    
                                <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                    <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                </div>
                            </div>
                            </span>                                
                            </h4>
                        </div>
                    </div>
                   
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                              <table class="table table-borderless all-table">
                                   <thead>
                                        <tr>                                              
                                             <th class="" scope="col">Interview Title
                                            </th>
                                             <th class="" scope="col">Interview Date
                                            </th> 
                                            <th class="" scope="col">Start Time
                                            </th>
                                            <th class="" scope="col">End Time
                                            </th>
                                            <th class="" scope="col">Assigned By
                                            </th>
                                            <th class="" scope="col"> Actions
                                            </th>
                                                         
                                   </tr></thead>
                                     <tbody>
                                        <tr v-if="this.request_data.length==0" ><td colspan="7" class="text-center" >No record found</td></tr>
                                        <tr v-for="req_data in this.request_data" v-bind:key="req_data.id">                                          
                                          <td>{{req_data.title}}</td>
                                          <td>{{req_data.interviewStartTime | formatDate}}</td>
                                          <td>{{toClientTimeZoneDate(req_data.interviewStartTime, timeformat) }}  </td> 
                                          <td>{{toClientTimeZoneDate(req_data.interviewEndTime, timeformat) }}  </td> 
                                          <!-- <td>{{req_data.showInterviewDate | formatDate}}</td>
                                          <td>{{req_data.interviewStartTime | formatTime}}</td>
                                          <td>{{req_data.interviewEndTime | formatTime}}</td> -->
                                          <td>{{req_data.assignedBy}}</td>
                                          <td class="actions">    
                                            <a href="Javascript:void(0);" @click="viewFeedback(req_data.id,req_data.candidateId)" title="View Interview" class="p-2"><i class="ti-calendar"></i></a>                                          
                                              <!-- <a title="Accept" href="javascript:void(0);" @click="interviewAccept(req_data.id)" data-toggle="modal" data-target="#AcceptRequest"><span class="ti-check"></span></a>
                                               &nbsp; &nbsp; &nbsp;
                                              <a title="Reject" href="javascript:void(0);" @click="interviewReject(req_data.id)" data-toggle="modal" data-target="#RejectRequest"><span class="ti-close"></span></a> -->
                                            
                                          </td>
                                        </tr>
                                        
                                     </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="col-md-12">
                            <b-pagination v-if="this.page_conf.total>0" size="md" class='pull-right' :current-page="this.page_conf.currentPage" :total-rows="this.page_conf.total" v-model="page_conf.currentPage" :per-page="this.search.limit" @input="this.loadRequests(0)"></b-pagination>
                        </div> -->
                        <div class="col-md-12">
                            <!-- <b-pagination v-if="request_data.length!=0" size="md" class='pull-right' :current-page="browsepagination.currentpage" :total-rows="browsepagination.totalrecords" v-model="browsepagination.currentpage" :per-page="search.limit" @input="loadRequests(0)"></b-pagination> -->
                            <b-pagination v-if="request_data.length!=0" size="md" class='pull-right' :current-page="browsepagination.currentpage" :total-rows="browsepagination.totalrecords" v-model="browsepagination.currentpage" :per-page="browsepagination.rowsPerPage" @input="loadRequests(0)"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal " id="RejectRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Interview confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                    Are you sure to reject this request?
                    </div>
                    <div class="modal-footer">                        
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                        <button type="button" @click="interviewConfirmation(1)" class="btn btn-primary" data-dismiss="modal">Yes</button>
                    </div>
                    </div>
                </div>
            </div>

            <div class="modal " id="AcceptRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Interview confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                    Are you sure to accept this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                        <button type="button" @click="interviewConfirmation(2)" class="btn btn-primary" data-dismiss="modal">Yes</button>
                    </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div>
                    <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
                </div>                   
            </template> 
        </b-overlay>
            
    </div>
</template>

<script>
import iziToast from 'izitoast';

export default {
    data() {
        return {
            showOverlay:false,
            browsepagination:this.$store.state.paginationconfig,
            searchlimit:this.$store.state.searchlimit,
            dateformat:this.$store.state.dateformat,
            timeformat:this.$store.state.timeformat,
            request_data:{},
            page_conf:{
                currentPage:1,
                total:0,
            },
            browsepagination: {
                totalrecords: 0,
                rowsPerPage: 10,
                currentpage: 1
            },
            search:{
                limit:10,
                params:{
                    title:'',
                    interviewDate:''
                }
            },
            assigned_on:{
                date_on:'',
                from_time:'',
                to_time:'',
                id:0
            },
            searchMore:true,
        }
    },
    watch: {
        'showOverlay': {
            handler: function(value) { 
                if(value == true){
                    $('#globalapploader').css('visibility','hidden');
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted:function(){
        this.loadRequests(0);
        //console.log(this.$store.state.searchlimit);
    },
    methods:{
        findRequests(){
            this.browsepagination.currentpage = 1;
            this.loadRequests(0);
        },
        interviewReject:function(arg)
        {
            this.assigned_on.id=arg;
        },
        interviewAccept:function(arg)
        {
            this.assigned_on.id=arg;
        },
        async loadRequests(arg) {
            this.showOverlay=true;
            await this.getRequests(arg);
            this.showOverlay=false;
        },
        getRequests:function(arg){
            this.showOverlay=true;
            if(arg==1)
            {
                this.search.params.title='';
                this.search.params.interviewDate='';
            }
            return axios.post(this.$store.getters.getAPIBasePath+'/interviewresource/list/interviewer/interviews/'+this.$store.getters.currentUser.profileId +'?page='+this.browsepagination.currentpage+'&size='+ this.browsepagination.rowsPerPage,this.search.params)
            // axios.post(this.$store.getters.getAPIBasePath+'/interviewerresource/list/interviewrequest/'+this.$store.getters.currentUser.profileId +'?page='+this.browsepagination.currentpage+'&size='+ this.browsepagination.rowsPerPage,this.search.params)
            .then(response => {
                //console.log(response);
                let result = response.data;
                if(!result.interviews){
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.RecNotFou,
                        position:'topRight'
                    });
                }
                else
                {
                    this.request_data=Array.from(result.interviews);
                    // this.page_conf=Object.assign({}, result.pagination[0]);
                    this.browsepagination.totalrecords = result.pagination[0].total;
                    this.browsepagination.currentpage = result.pagination[0].currentPage;
                    this.browsepagination.totalpages = result.pagination[0].lastPage;
                }
            });
        },
        interviewConfirmation:function(arg1)
        {
            let type_v = "accept";
            if(arg1 == 1)
            {
                type_v = "reject";
            }
            axios.post(this.$store.getters.getAPIBasePath+'/interviewerresource/update/interviewer/confirmrequest/'+this.assigned_on.id,{'type':type_v})
            .then(response => {
                //console.log(response);
                 let result = response.data;
                 if(result.status)
                 {
                     iziToast.success({
                        title: 'Confirmation Request',
                        message: result.message,
                        position:'topRight'
                    });
                    this.loadRequests(0);
                 }
                 else
                 {
                     iziToast.error({
                        title: 'Confirmation Request',
                        message: this.$config.ReqConfirmErr,
                        position:'topRight'
                    });
                 }                
            });
            return true;
        },
        // changeLimit(newlimit){
        //     this.search.limit = newlimit;
        //     this.$store.commit('updateActiveSearchLimit',this.search.limit);
        //     this.loadRequests(0);
        // },
        changeLimit(newlimit){
            this.browsepagination.currentpage = 1;
            this.browsepagination.rowsPerPage = newlimit;
            this.loadRequests(0);
        },
        viewFeedback(interviewId,candidateId){
            localStorage.setItem('feedbackInterviewId',parseInt(interviewId))
            localStorage.setItem('feedbackCandidateId',parseInt(candidateId))
            // localStorage.setItem('feedbackRpInterviewId',parseInt(rpInterviewId))
            this.$router.push('/interviewer/feedback/'+interviewId)
        }
    }
}
</script>