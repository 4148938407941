<template>
    <div id="interviewer-edit-block" class="interviewer-edit-block mt-70">
        <div class="container-fluid driver-model" id="main-data">
            <div class="row">
                <div class="col-md-3">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card border-5 mb-3">
                                <h6 class="about-box m-0 text-left p-2">
                                    <a href="javascript:" @click="$router.go(-1)" title="Back" class="p-2">
                                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                        <span> Back</span>
                                    </a>
                                    <router-link class="float-right link pr-2" title="Edit Profile" :to="{ name: 'referraledit', params: {id: profileId} }"><i class="fa fa-pencil"></i> Edit </router-link>
                                </h6>
                                <div class="user-card pl-5 pr-5 pt-3">
                                    <div class="information">
                                        <div class="userimage-progress">
                                            <div class="progress-circle">
                                                <vue-circle v-if="elements.showProgress" :progress="ProfileData.profileCompletion" :size="75" :reverse="true" line-cap="round" :fill="fill" empty-fill="rgba(0, 0, 0, .1)" :animation-start-value="0.0" :start-angle="0" insert-mode="append" :thickness="7" :show-percent="true" @vue-circle-progress="progress" @vue-circle-end="progress_end">
                                                    <div class="imgUp">
                                                        <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + ProfileData.image + ')' }"></div>
                                                        <!-- <label class="upload-icon"><i class="ti-camera"></i><input ref="interviewer_profile_photo" id="interviewer_profile_photo" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="upload_photo"></label> -->
                                                    </div>
                                                </vue-circle>
                                            </div>
                                        </div>
                                        <!--   <div class="imgUp">
                                            <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + ProfileData.image + ')' }"></div>
                                        </div> -->
                                        <div class="name">{{ProfileData.firstName}} {{ProfileData.lastName}}</div>
                                        <div>{{ProfileData.position}} </div>
                                        <div class="position"><i class="ti-email"></i> {{ProfileData.email}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card border-5">
                                <div class="user-card p-0">
                                    <h5 class="about-box m-0 text-left p-2">About Me</h5>
                                    <div class="p-3 float-left w-100">
                                        <h5>Company</h5>
                                        
                                        <div class="informations">
                                            <span v-if="ProfileData.company == ''" class="badge p-2 mb-1">No records found</span>
                                            <span v-if="ProfileData.companyName != ''">{{ProfileData.company}}</span>
                                        </div>
                                        <div v-if="ContactInfo!== null" class="w-100 float-left">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="candidateCount != 0 || interviewerCount != 0 || clientCount != 0" class="card border-5">
                                <div class="user-card p-0">
                                    <h5 class="about-box m-0 text-left p-2">Referral Info</h5>
                                    <div class="p-3 float-left w-100">
                                        <div v-if="candidateCount != 0" class="value"> <img class="hover-hand" title="Candidate Count" src="@/assets/icons/user.png" alt="">
                                                <span class="pl-5 font-weight-bold">{{candidateCount}}</span>
                                        </div>
                                        <span v-if="candidateCount != 0" class="font-weight-bold">Candidate Count<hr></span>
                                        
                                        <div v-if="interviewerCount != 0" class="value"> <img class="hover-hand" title="Interviewer Count" src="@/assets/icons/interview (1).png" alt="">
                                                <span class="pl-5 font-weight-bold">{{interviewerCount}}</span>
                                        </div> 
                                        <span v-if="interviewerCount != 0" class="font-weight-bold">Interviewer Count<hr></span>
                                        
                                        <div v-if="clientCount != 0" class="value"> <img class="hover-hand" title="Client Count" src="@/assets/icons/support.png" alt="">
                                                <span class="pl-5 font-weight-bold">{{clientCount}}</span>
                                        </div>  
                                        <span v-if="clientCount != 0" class="font-weight-bold">Client Count</span>                                                                           
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="box-wrapper shadow-sm bg-white rounded pos-rel main-search">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row no-gutters">
                                    <div class="col-md-12 pop-tab">
                                        <div class="driver-model">
                                            <div class="row no-gutters">
                                                <b-tabs v-model="currentTab" small card class="tab-list driver-tab-list tab-bg">
                                                    <b-tab title="Primary Info">
                                                        <PersonalInfo :ProfileData=ProfileData ref="personalinfocomponent"></PersonalInfo>
                                                    </b-tab>
                                                    <b-tab title="Contact Info">
                                                        <ContactInfo :ContactInfo=ContactInfo ref="contactInfocomponent"></ContactInfo>
                                                    </b-tab>
                                                    <b-tab title="Settings">
                                                        <Settings :settingsInfo="settingsInfo" :profileId="profileId" @loadReferral="loadReferral" ref="settingscomponent"></Settings>
                                                    </b-tab>
                                                    <b-tab title="Account Info">
                                                        <AccountInfo :profileId = "profileId" ref="contactInfocomponent" />
                                                    </b-tab>
                                                </b-tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import PersonalInfo from './profile/blocks/view/PersonalInfo';
import ContactInfo from './profile/blocks/view/ContactInfo';
import AccountInfo from './profile/blocks/view/AccountInfo';
import VueCircle from 'vue2-circle-progress';
import Settings from '@/components/referral/profile/blocks/edit/Settings';

export default {
    components: {
        PersonalInfo,
        ContactInfo,
        AccountInfo,
        VueCircle,
        Settings
    },
    data() {
        return {
            profileId: this.$store.getters.currentUser.profileId,
            elements: {
                showAvailabilityInfo: false,
                showProgress: false,
            },
            currentTab: 0,
            ProfileData: {
                id: 0,
                firstName: '',
                lastName: '',
                nickName: '',
                email: '',
                dateOfBirth: '',
                gender: '',
                position: '',
                image: '',
                imageName: '',
                imagePath: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
                image: '',
                industryId: null,
                company: '',
                profileCompletion: 0
            },
            ContactInfo: Object,
            settingsInfo: {
                timezone: "",
                accessLevel: 1
            },
            colours: ['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info'],
            options: {
                text: {
                    color: '#FFFFFF',
                    shadowEnable: true,
                    shadowColor: '#000000',
                    fontSize: 14,
                    fontFamily: 'Helvetica',
                    dynamicPosition: false,
                    hideText: false
                },
                progress: {
                    color: '#2dbd2d',
                    backgroundColor: '#C0C0C0'
                },
                layout: {
                    height: 35,
                    width: 140,
                    verticalTextAlign: 61,
                    horizontalTextAlign: 43,
                    zeroOffset: 0,
                    strokeWidth: 30,
                    progressPadding: 0,
                    type: 'line'
                }
            },
            value: 30,
            fill: { gradient: ["#37A0E4", "#37A0E4", "#808080"] },
            candidateCount: '',
            interviewerCount: '',
            clientCount: ''
        }
    },
    mounted() {
        this.loadReferral();
        this.loadCount();
    },
    methods: {
        progress(event, progress, stepValue) {
          //  console.log(stepValue);
        },
        progress_end(event) {
          //  console.log("Circle progress end");
        },
        loadReferral: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/referralresource/view/referral/' + this.profileId)
                .then(response => {
                    if (response.data.status) {

                        let referralData = response.data.referral;
                        this.ProfileData.id = referralData.id;
                        this.ProfileData.firstName = referralData.firstName;
                        this.ProfileData.lastName = referralData.lastName;
                        this.ProfileData.nickName = referralData.nickName;
                        this.ProfileData.email = referralData.email;
                        this.ProfileData.dateOfBirth = referralData.dateOfBirth;
                        this.ProfileData.gender = referralData.gender;
                        this.ProfileData.position = referralData.position;
                        this.ProfileData.imageName = referralData.imageName;
                        this.ProfileData.imagePath = (referralData.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : referralData.imagePath);
                        this.ProfileData.image = (referralData.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : referralData.imagePath);
                        this.ProfileData.industryId = referralData.industryId;
                        this.ProfileData.company = referralData.companyName;
                        this.ProfileData.profileCompletion = (referralData.profileCompletion == null ? 10 : referralData.profileCompletion);
                        this.elements.showProgress = true;
                        this.ContactInfo = Object.assign({}, referralData.referralContact);
                        this.settingsInfo.timezone = referralData.timezone == null ? '' : referralData.timezone;

                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadCount: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/referralresource/referral/info/count/' + this.profileId)
                .then(response => {
                    this.interviewerCount = response.data.interviewers;
                    this.candidateCount = response.data.candidates;
                    this.clientCount = response.data.clients;
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
