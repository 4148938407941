<template>
	<div class="col-md-3 col-sm-3 logo-img">
		<div style="height: 72px;">
			<router-link  v-if="usertype=='recruiter' && recuiterType == 1" :to="{ name: 'candidatesdashboard' }" class="navbar-brand">
				<img style="width: 220px;margin-top: 7px;" alt="images" @click="updateLogActivity" src="@/assets/images/talentturbo1.png">
			</router-link>
			<router-link  v-if="usertype=='recruiter' && (recuiterType===2 | recuiterType===3) && (accountType != 3)" :to="{ name: 'rmdashboard' }" class="navbar-brand">
				<img style="width: 220px;margin-top: 7px;" alt="images" @click="updateLogActivity" src="@/assets/images/talentturbo1.png">
			</router-link>
			<router-link  v-if="usertype=='recruiter' && (accountType === '3') " :to="{ name: 'corpdashboard' }" class="navbar-brand">
				<img style="width: 220px;margin-top: 7px;" alt="images" @click="updateLogActivity" src="@/assets/images/talentturbo1.png">
			</router-link>
			<router-link  v-if="usertype=='admin' && isSuperAdmin===0" :to="{ name: 'admindashboard' }" class="navbar-brand">
				<img style="width: 220px;margin-top: 7px;" alt="images" @click="updateLogActivity" src="@/assets/images/talentturbo1.png">
			</router-link>
			<router-link  v-if="usertype=='admin' && isSuperAdmin===1" :to="{ name: 'saadmindashboard' }" class="navbar-brand">
				<img style="width: 220px;margin-top: 7px;" alt="images" @click="updateLogActivity" src="@/assets/images/talentturbo1.png">
			</router-link>
			<router-link  v-if="usertype=='interviewer'" :to="{ name: 'interviewerdashboard' }" class="navbar-brand">
				<img style="width: 220px;margin-top: 7px;" alt="images" src="@/assets/images/talentturbo1.png">
			</router-link>
			<router-link  v-if="usertype=='candidate'" :to="{ name: 'candidatedashboard' }" class="navbar-brand">
				<img style="width: 220px;margin-top: 7px;" alt="images" src="@/assets/images/talentturbo1.png">
			</router-link>
			<router-link  v-if="usertype=='referral'" :to="{ name: 'referraldashboard' }" class="navbar-brand">
				<img style="width: 220px;margin-top: 7px;" alt="images" src="@/assets/images/talentturbo1.png">
			</router-link>
			<router-link  v-if="usertype=='guest'" :to="{ name: 'guestdashboard' }" class="navbar-brand">
				<img style="width: 220px;margin-top: 7px;" alt="images" src="@/assets/images/talentturbo1.png">
			</router-link>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#demo-navbar" aria-controls="demo-navbar" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
		</div>
	</div>	
</template>

<script>
export default {
	data(){
		return {
			usertype:this.$store.getters.currentUser.userType,
            recownerid : this.$store.getters.currentUser.recruitOwner,
			recuiterType: this.$store.getters.currentUser.recruitType,
			isSuperAdmin : this.$store.getters.currentUser.isSuperAdmin,
			accountType: localStorage.getItem("accountType"),
		}
	},
	mounted() {	
		
	},
	methods: {
		updateLogActivity: function() {            
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {
                    console.log(response.data);
                    localStorage.setItem('candIdForActivity', null);
                })
                .catch(error => {

                });
        }
	}

}
</script>