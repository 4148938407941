<template>
    <div id="interviewer-edit-block" class="interviewer-edit-block mt-70">
        <div class="container-fluid driver-model" id="main-data">
            <div class="row">
                <div class="col-md-3 mtb-4">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card border-5 mb-3">
                                <h6 class="about-box m-0 text-left p-2">
                                    <a href="javascript:" @click="$router.go(-1)" title="Back" class="p-2">
                                        <i class="ti-arrow-left "></i>
                                        <span> Back</span>
                                    </a>
                                    <router-link class="float-right link pr-2" title="View Referral" :to="{ name: 'viewreferral', params: {referralid: profileId} }"><i class="ti-eye"></i> View </router-link>
                                </h6>
                                <div class="user-card pl-5 pr-5 ">
                                    <div class="information">
                                        <div class="imgUp">
                                            <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + ProfileData.image + ')' }"></div>
                                            <!-- <label class="upload-icon"><i class="ti-camera"></i><input ref="recruiter_profile_photo" id="recruiter_profile_photo" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="upload_photo"></label> -->
                                            <label class="upload-icon"><i class="ti-camera"></i><input ref="referral_add_profile_photo" id="referral_aad_profile_photo" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="upload_photo"></label>
                                            <label class="delete-icon"><i class="ti-close closefont" @click="delete_photo"></i></label>
                                            <!-- <label class="upload-icon"><i class="ti-camera"></i><input ref="interviewer_profile_photo" id="interviewer_profile_photo" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="upload_photo"></label> -->
                                        </div>
                                        <div class="name">{{ProfileData.firstName}} {{ProfileData.lastName}}</div>
                                        <div class="position">{{ProfileData.position}} </div>
                                        <div class="email"><i class="ti-email"></i> {{ProfileData.email}}</div>
                                        <div class="mobile"><i v-if="ContactInfo.mobileNo != null" class="ti-mobile"></i> {{ContactInfo.mobileNo}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card border-5">
                                <div class="user-card p-0">
                                    <h5 class="about-box m-0 text-left p-2">About Me</h5>
                                    <div class="p-3 float-left w-100">
                                        <h5>Company</h5>
                                        <div class="informations">
                                            <span v-if="ProfileData.company == undefined" class="badge p-2 mb-1">No records found</span>
                                            <span v-if="ProfileData.companyName != ''">{{ProfileData.company}}</span>
                                        </div>
                                        <div v-if="ContactInfo!== null" class="w-100 float-left">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="candidateCount != 0 || interviewerCount != 0 || clientCount != 0" class="card border-5">
                                <div class="user-card p-0">
                                    <h5 class="about-box m-0 text-left p-2">Referral Info</h5>
                                    <div class="p-3 float-left w-100">
                                        <div v-if="candidateCount != 0" class="value"> <img class="hover-hand" title="Candidate Count" src="@/assets/icons/user.png" alt="">
                                                <span class="pl-5 font-weight-bold">{{candidateCount}}</span>
                                        </div>
                                        <span v-if="candidateCount != 0" class="font-weight-bold">Candidate Count<hr></span>
                                        
                                        <div v-if="interviewerCount != 0" class="value"> <img class="hover-hand" title="Interviewer Count" src="@/assets/icons/interview (1).png" alt="">
                                                <span class="pl-5 font-weight-bold">{{interviewerCount}}</span>
                                        </div> 
                                        <span v-if="interviewerCount != 0" class="font-weight-bold">Interviewer Count<hr></span>
                                        
                                        <div v-if="clientCount != 0" class="value"> <img class="hover-hand" title="Client Count" src="@/assets/icons/support.png" alt="">
                                                <span class="pl-5 font-weight-bold">{{clientCount}}</span>
                                        </div>  
                                        <span v-if="clientCount != 0" class="font-weight-bold">Client Count</span>                                                                           
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="box-wrapper shadow-sm mtb-4 bg-white rounded pos-rel main-search">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row no-gutters">
                                    <div class="col-md-12 pop-tab">
                                        <div class="driver-model">
                                            <div class="row no-gutters">
                                                <b-tabs v-model="currentTab" small card class="tab-list driver-tab-list tab-bg">
                                                    <b-tab title="Primary Info">
                                                        <PersonalInfo :ProfileData="ProfileData" :profileId="profileId" ref="personalinfocomponent"></PersonalInfo>
                                                    </b-tab>
                                                    <b-tab title="Contact Info">
                                                        <ContactInfo :ContactInfo="ContactInfo" :profileId="profileId" ref="contactInfocomponent"></ContactInfo>
                                                    </b-tab>
                                                     <b-tab title="Settings">
                                                        <Settings :settingsInfo="settingsInfo" :profileId="profileId" @loadReferral="loadReferral" ref="settingscomponent"></Settings>
                                                    </b-tab>
                                                </b-tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PersonalInfo from '@/components/referral/profile/blocks/edit/PersonalInfo';
import ContactInfo from '@/components/referral/profile/blocks/edit/ContactInfo';
import Settings from '@/components/referral/blocks/referral/Settings';
import iziToast from 'izitoast';
export default {
    components: {
        PersonalInfo,
        ContactInfo,
         Settings
    },
    data() {
        return {

            profileId: String(this.$route.params.referralid),
            elements: {
                showAvailabilityInfo: false
            },
            currentTab: 0,
            ProfileData: {
                id: 0,
                firstName: '',
                lastName: '',
                email: '',
                image: '',
                imageName: '',
                imagePath: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
                image: '',
                industryId: null,
                company: '',
            },
            ContactInfo: {
                addressLine1: '',
                addressLine2: '',
                countryId: 'US',
                stateName: '',
                cityName: '',
                pincode: '',
                skypeId: '',
                mobileNo: '',
                altMobileNo: '',
                mobile: '',
                altMobile: '',
                websiteUrl: '',
                linkedinUrl: '',
                hangoutId: '',
                facebookUrl: '',
                twitterUrl: '',
                countryCode:'+1',
                altcountryCode:'+1',
            },
            candidateCount: '',
            interviewerCount: '',
            clientCount: '',
             settingsInfo: {
                timezone: "",
                accessLevel: 1
            },
            deleteparams:{
            id: 0,
            type:'Referral'
            },
            colours: ['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info']
        }
        
    },
    mounted() {
        this.loadCount();
        this.loadReferral();
    },
    methods: {
        loadCount: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/referralresource/referral/info/count/' + this.profileId)
                .then(response => {
                    this.interviewerCount = response.data.interviewers;
                    this.candidateCount = response.data.candidates;
                    this.clientCount = response.data.clients;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        delete_photo: function() {
               this.deleteparams.id=this.ProfileData.id;
            axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/delete/candidate/photo',this.deleteparams)
                .then(response => {
                    if(response.data.status=="OK"){
                        iziToast.success({ title: 'Photo update', message: response.data.message, position: 'topRight' });
                        this.ProfileData.image="";
                        this.ProfileData.imageName="";
                        this.ProfileData.imagePath="";
                    }
                    else if(response.data.status=="EXPECTATION_FAILED"){
                    console.log(response);
                    iziToast.info({ title: 'Photo update', message: response.data.message, position: 'topRight' });}
                })
                .catch(function(error) {
                    console.log('FAILURE!!');
                    console.log(error);
                });
           
        },
         upload_photo: function() {
           
             if (!/\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(this.$refs.referral_add_profile_photo.files[0].name)) {
                iziToast.error({ title: 'Photo update', message: this.$config.FileFormatErr, position: 'topRight' });
                return false;
            }
            //this.profilePhoto = this.$refs.addcandidate_profile_photo1.files[0];
            let formData = new FormData();
            formData.append('file', this.$refs.referral_add_profile_photo.files[0]);
            formData.append('id', this.ProfileData.id);
            formData.append('type', 'referral');
               axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/uploadprofilephoto',
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response => {
                    iziToast.success({ title: 'Photo update', message: this.$config.ProfileUpSuc, position: 'topRight' });
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Photo update', message: this.$config.ProfileUpErr, position: 'topRight' });
                });
        },
        loadReferral: function() {

            axios.get(this.$store.getters.getAPIBasePath + '/referralresource/view/referral/' + this.profileId)
                .then(response => {
                    if (response.data.status) {              
                        let referralData = response.data.referral;
                        this.ProfileData.id = referralData.id;
                        this.ProfileData.firstName = referralData.firstName;
                        this.ProfileData.lastName = referralData.lastName;
                        this.ProfileData.nickName = referralData.nickName;
                        this.ProfileData.email = referralData.email;
                        this.ProfileData.dateOfBirth = referralData.dateOfBirth;
                        this.ProfileData.gender = referralData.gender;
                        this.ProfileData.position = referralData.position;
                        this.ProfileData.imageName = referralData.imageName;
                        this.ProfileData.imagePath = (referralData.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : referralData.imagePath);
                        this.ProfileData.image = (referralData.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : referralData.imagePath);
                        this.ProfileData.industryId = referralData.industryId;
                        this.ProfileData.company = referralData.companyName;
                         this.settingsInfo.timezone = referralData.timezone == null ? '' : referralData.timezone;
        /*                 let phoneNo = referralData.referralContact.mobileNo;
                        if (phoneNo != null && phoneNo.startsWith("+")) {
                            let phoneData = phoneNo.split(" ");
                            referralData.referralContact.countryCode = phoneData[0];
                            referralData.referralContact.mobile = phoneData[1];           
                        }
                        phoneNo = "";
                        phoneNo = referralData.referralContact.altMobileNo;
                        if (phoneNo != null && phoneNo.startsWith("+")) {
                            let phoneData = phoneNo.split(" ");
                            referralData.referralContact.altcountryCode = phoneData[0];
                            referralData.referralContact.altMobile = phoneData[1];           
                        } */
                        this.ContactInfo = Object.assign({}, referralData.referralContact);
                        this.ContactInfo.countryId = (referralData.referralContact.countryId==null ? '': referralData.referralContact.countryId);

                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
