<template>
    <div id="main" class="tech-skill-settings settings recruiter">
        <div class="row col-md-12 px-0 py-4">
            <div class="col-md-4 offset-md-4">
                <h4 class="">Industries</h4>
            </div>
            <div class="col-md-1 offset-md-3">
                <a href="Javascript:void(0);" class="pull-right btn-back" @click="$emit('setSubTab',0)">
                    <!-- <i class="ti-arrow-left"></i> Back -->
                    <h5 class="text-blue"><span title="Go Back" class="ti-arrow-left py-4"></span><span class="pl-2">Back</span></h5>
                </a>
            </div>
        </div>
        <div class="row" v-if="editIndex<0">
            <div class="col-md-6 col-sm-8 col-6">
                <!-- <multiItemInput id="multiTech" placeholder="Enter Job Document" :items="newTemplateTypeList" class="float-left"></multiItemInput> -->
                <input class="form-control" placeholder="Enter Industry" v-model="newIndustry.industryName" />
                <p v-if="formErrors.length">
                    <ul>
                        <li class="error">{{ formErrors }}</li>
                    </ul>
                </p>
            </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                <a href="Javascript:void(0);" @click="addIndustry">
                    <span class="ti-plus"></span>
                </a>
            </div>
        </div>
        <div class="row" v-if="editIndex!=-1">
            <div class="col-md-6 col-sm-8 col-6">
                <input class="form-control" placeholder="Enter Industry" v-model="newIndustry.industryName" />
            </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                <a style="float:left; margin-right:5px;" href="Javascript:void(0);" @click="updateIndustry(1)">
                    <span class="ti-check"></span>
                </a>
                <a style="float:left;" href="Javascript:void(0);" @click="updateIndustry(0)">
                    <span class="ti-close"></span>
                </a>
            </div>
        </div>
        <div class="row item" v-for="(industryInfo, index) in industryList" v-bind:key="index">
            <div class="col-md-6 col-sm-8 col-6">
                <div class="form-control">{{industryInfo.industryName}}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-2 pt-2">
                <a style="float:left; margin-right:5px;" href="Javascript:void(0);" @click="editIndustry(index)">
                    <span class="ti-pencil"></span>
                </a>
                 <a style="float:left; margin-right:5px;" href="Javascript:void(0);" @click="removeIndustry(index,industryInfo.id)" data-toggle="modal" data-target="#removIndustry" title="Remove"  class="px-2">
                 <span class="ti-trash"></span>
                 </a>
            </div>
        </div>
         <Dialog id="removIndustry" :onYes="deleteIndustry" :returnParams="delParam" />
    </div>
</template>
<style scoped>
div.item {
    margin-top: 5px;
}

</style>
<script>
import multiItemInput from "@/components/plugins/multiItemInput";
import iziToast from "izitoast";

export default {
    components: {
        multiItemInput
    },
    data() {
        return {
            formErrors: "",
            editIndex: -1,
            industryList:[],
            IndustryInfo: {
                industryName: ""
            },
            newIndustry: { id: 0, industryName: "" },
            browsepagination: this.$store.state.paginationconfig,
            searchlimit: this.$store.state.searchlimit,
             delParam: {
                index: -1,
                id: 0
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    industryName: ""
                }
            }
        };
    },
    mounted() {
        this.loadindustryList();
        // console.log(this.templateTypesList)
    },
    methods: {
        loadindustryList() {
            axios
                .get(this.$store.getters.getAPIBasePath + "/settingsresource/get/settingsindustry")
                .then(response => {
                    this.industryList = response.data;  
            
                    console.log(this.industryList) 
                    this.newIndustry = { id: 0, industryName: "" };
                })
                .catch(error => {
                    iziToast.error({
                        title: "Error",
                        message: error,
                        position: "topRight"
                    });
                });
        }, 
        isExist : function(){
            for(var i=0; i < this.industryList.length; i++){
                if( this.industryList[i].industryName == this.newIndustry.industryName){
                    return true;
                }
            }
        },
        addIndustry(){   
            if(!this.newIndustry.industryName){  
            this.formErrors="Enter the Industry";
            }
            else{
            if (this.isExist()) {
                  iziToast.info({
                            title: "Info",
                            message: this.$config.indusExists,
                            position: "topRight"
                        });
            }
            else{
            axios.post(this.$store.getters.getAPIBasePath +"/settingsresource/add/settingsindustry", this.newIndustry)
                .then(response => {
                    this.IndustryInfo.industryName = "";                    
                    this.newIndustry = "";
                    this.formErrors = [];
                    console.log(response);
                    if(response.data.Status == true) {
                        iziToast.success({
                            title: "Success",
                            message: response.data.Message,
                            
                            position: "topRight"
                        });
                         this.loadindustryList();
                    } else {
                        iziToast.info({
                            title: "Info",
                            message: response.data.message,
                            position: "topRight"
                        });
                    }
                    this.loadindustryList();
                    this.loadMasterInfo();
                })
                .catch(error => {
                    this.formErrors = error.response.data.validationErrorList;
                });
            }
           
            }
            //}
        },
       removeIndustry:function(arg1, arg2)
            {
                this.delParam.index=arg1;
                this.delParam.id=arg2;
            },
            deleteIndustry:function(arg)
            {
         axios.delete(this.$store.getters.getAPIBasePath + '/settingsresource/delete/settingsindustry/'+this.delParam.id)
                 .then(response => {
                    let result = response.data;
                    if (result.status == true) {
                        iziToast.success({
                            title: "Success",
                            message: this.$config.InsDelSuc,
                            position: "topRight",
                            timeout: 1000
                        });
                        this.industryList.splice(arg.index, 1);
                        this.loadMasterInfo();
                    }else{
                        iziToast.error({
                            title: "Error",
                            message: this.$config.InsDelErr,
                            position: "topRight"
                        });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
    
        editIndustry(index) {
            this.editIndex = index;
            this.newIndustry = {
                id: this.industryList[index].id,
                industryName: this.industryList[index].industryName,
                industryList : [ {industryName : 1} ] 
            };
            console.log(this.newIndustry);
        },
        updateIndustry(mode){
            if (mode == 1) {
                axios
                    .post(this.$store.getters.getAPIBasePath + "/settingsresource/update/settingsindustry",this.newIndustry)
                    .then(response => {
                        this.editIndex = -1;
                        this.newIndustry = { id: 0, industryName: "" };
                        
                        if(response.data.status == true) {
                            iziToast.success({
                                title: "Success",
                                message: this.$config.InsUpSuc,
                                position: "topRight"
                            });
                        } else {
                            iziToast.info({
                                title: "Info",
                                message: response.data.message,
                                position: "topRight"
                            });
                        }
                        this.loadindustryList();
                        this.loadMasterInfo();
                    })
                    .catch(error => {
                        iziToast.error({
                            title: "Error",
                            message: error,
                            position: "topRight"
                        });
                    });
            } else {
                this.editIndex = -1;
                this.newIndustry = { id: 0, industryName: "" };
            }
        },
         loadMasterInfo() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/list/all')
                .then(response => {
                    if (response.data.status) {
                        // this.$store.commit('updateCountries', response.data.countrylist);
                        this.$store.commit('updateEduCountries', response.data.countrylist);
                        this.$store.commit('updateVisaOptions', response.data.visalist);
                        this.$store.commit('updateTechOptions', response.data.technologylist);
                        this.$store.commit('updateSalaryModes', response.data.paymentmodelist);
                        this.$store.commit('updateRecruiters', response.data.recruiterlist);
                        this.$store.commit('updateIndustries', response.data.industrylist);
                        this.$store.commit('updateShiftOptions', response.data.shiftlist);
                        this.$store.commit('updateClientContactType', response.data.clientcontactlist);
                        this.$store.commit('markAsConfigLoaded', 1);
                        this.$store.commit('setInterviewStatus', response.data.interviewStatus);
                        this.$store.commit('setInterviewType', response.data.interviewType);
                        this.$store.commit('updateTimeZones', response.data.timeZoneList);
                        this.$store.commit('updateLanguages', response.data.languagelist);
                        this.$store.commit('setClients', response.data.clientlist);
                        this.$store.commit('setWorkType', response.data.workTypeList);
                        this.$store.commit('setJobStatus', response.data.jobStatus);
                        this.$store.commit('setShortlistedCandidateStatus', response.data.shortlistedCandidateStatus);
                        this.$store.commit('setPayFrequencyList', response.data.payFrequency);
                        this.$store.commit('setInterviewerScopeList', response.data.interviewerAccessLevel);
                        this.$store.commit('setCandidateScopeList', response.data.candidateAccessLevel);
                        this.$store.commit('setJobScopeList', response.data.jobAccessLevel);
                        this.$store.commit('setAccountTypeOptions', response.data.accountType);
                        this.$store.commit('setJobExpTypeOptions', response.data.jobExpType);
                        this.$store.commit('setTemplateTypesList', response.data.templateType);
                        this.$store.commit('setSocialMediaTemplateTypesList', response.data.socialMediaTemplateType);
                        this.$store.commit('setEmailTemplateScope', response.data.emailTemplateScope);
                        this.$store.commit('setWeblinks', response.data.webLink);
                        this.$store.commit('setTagNames', response.data.tagNames);
                        this.$store.commit('setSourcingChannel', response.data.sourcingChannel);
                        this.$store.commit('setRecruiterList', response.data.recruitermanagerlist);
                        this.$store.commit('setCandidatesStatusList', response.data.candidateStatus);
                        this.$store.commit('setCandStatusReport', response.data.candStatusReport);
                        this.$store.commit('setProjectStatusList', response.data.projectStatusList);
                        this.$store.commit('setTaskGroupStatusList', response.data.taskGroupStatusList);
                        this.$store.commit('setRecruiterByCompany', response.data.recruiterByCompany);
                        this.$store.commit('setQualificationData', response.data.settingsQualification);
                        this.$store.commit('setJobDocumentData', response.data.settingsJobDocument);
                        this.$store.commit('setWorkAuthorizationData', response.data.settingsWorkAuthorization);
                        this.$store.commit('setCandidateTypeList', response.data.candidateTypeList);
                        this.$store.commit('setRelocationStatus', response.data.candidateRelocation);
                        this.$store.commit('setTtClients', response.data.ttClients);
                        this.$store.commit('setCandContactAccess', response.data.candContactAccess);
                        this.$store.commit('updateUsaIndCountries', response.data.country_us_in);
                        axios.get(this.$store.getters.getAPIBasePath + '/commonresource/statelist?countryId=us')
                            .then(response => {
                                this.$store.commit('updateStatesList', response.data.statelist);
                                this.configloaded = this.$store.getters.isConfigLoaded;
                            })
                            .catch(error => {
                                this.$store.commit('updateStatesList', []);
                            });
                    } else {
                        iziToast.error({
                            title: '',
                            message: this.$config.PageLoadErr,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    iziToast.error({
                        title: '',
                        message: this.$config.PageLoadErr,
                        position: 'topRight'
                    });
                });

            axios.post(this.$store.getters.getAPIBasePath + '/settingsresource/namevalue/list')
                .then(response => {
                    this.$store.commit('setNameValueList', response.data.nameValueList);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};

</script>
