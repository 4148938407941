<template>
    <div class="candidate reports custom-reports text-left">
        <div class="row">
            <div class="col-md-12 p-10">
                <div class="container-fluid" id="quick-search">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="text-center">Candidate Custom Report</h5>
                        </div>
                    </div>
                    <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                        <div class="row mx-0">
                            <div class="col-md-6 col-sm-6 col-6">
                                <h4 class="page-title">Filter</h4>
                            </div>
                            <div class="col-md-6 col-sm-6 col-6">
                                <ul class="ul-horizontal box-tools text-right mb-0">
                                    <li>
                                        <a href="Javascript:void(0);" v-on:click="elements.isExpand=!elements.isExpand;" data-target="main-search" class="boxhide">
                                            <span :title="elements.isExpand?'Hide':'Expand'" class="hover-hand" :class="elements.isExpand?'ti-angle-up':'ti-angle-down'"></span>
                                        </a>
                                    </li>
                                    <li><i class="ti-close hover-hand px-2" title="Close" @click="$emit('setSubTab',0);$emit('updateLogActivity');"></i></li>
                                </ul>
                            </div>
                        </div>
                        <form id="main-search" :class="elements.isExpand?'d-block':'d-none'">
                            <div class="row form-group ten-columns">
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Name</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input class="form-control" type="text" v-on:keyup.enter="filterCandidates()" v-model="search.params.firstName" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="candContactAccess==1" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Email</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input class="form-control" type="text" v-on:keyup.enter="filterCandidates()" v-model="search.params.email" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Location</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input class="form-control" type="text" v-on:keyup.enter="filterCandidates()" v-model="search.params.location" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Technology</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <select class="form-control" v-model="search.params.technologyId">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in options.technologies" :value="info.id">{{info.technology}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Recruiter</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <select class="form-control" v-model="search.params.recruiterId">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in options.recruiterlist" :value="info.id">{{info.recruiterName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label style="visibility: hidden;">Technology</label>
                                        </div>
                                        <div class="col-md-12">
                                            <button type="button" @click="resetCandidatesSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                            <button type="button" @click="loadCandidates" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Go</button>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="row form-group ten-columns">
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Start Date</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <datepicker :disabledDates="disabledStartDates" placeholder="Start Date" name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.startDate"></datepicker>
                                                <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>End Date</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <datepicker :disabledDates="disabledendDates" placeholder="End Date" name="endDate" value="" id="endDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.endDate"></datepicker>
                                                <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Position</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input class="form-control" type="text" v-on:keyup.enter="filterCandidates()" v-model="search.params.position" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Industry</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <select class="form-control" v-model="search.params.industryId">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in options.industrylist" :value="info.id">{{info.industryName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Status</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <select class="form-control" v-model="search.params.status">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in options.candStatusList" :value="info.id">{{info.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label style="visibility: hidden;">Technology</label>
                                        </div>
                                        <div class="col-md-12">
                                            <button type="button" @click="resetCandidatesSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                            <button type="button" @click="filterCandidates" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Go</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                            </div>
                        </form>
                        <div class="load-indicator"></div>
                    </div>
                </div>
                <div v-if="action.mode!='view'" class="container-fluid" id="filter">
                    <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                        <div class="row col-md-12 mx-0">
                            <div class="d-flex flex-row bd-highlight mb-3">
                                <div class="mr-4">
                                    <h4 class="page-title">Choose Column Header</h4>
                                </div>
                            </div>
                        </div>
                        <div class="row col-md-12 mx-0">
                            <multiselect v-model="reportData.customReportFields" :options="options.fieldList" name="tablefields" label="label" track-by="fieldId" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" :allow-empty="true" />
                        </div>
                    </div>
                </div>
                <div class="container-fluid" id="main-data">
                    <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                        <div v-if="reportData.customReportFields.length" class="row pagination-container" style="margin-bottom: 15px;">
                            <div class="col-md-6 col-sm-6 col-6">
                                <h4 class="page-title">Candidates
                                    <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                    <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                        <div class="dropdown">
                                            <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                            <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                <a v-for="(value, index) in options.searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                            </div>
                                        </div>
                                    </span>
                                </h4>
                            </div>
                            <div class="col-md-6 col-sm-6 col-6 pull-right">
                                <button v-if="action.mode!='view'" type="button" v-b-modal.reportFormModal class="btn btn-primary btn-sm float-right">Save Report</button>
                                <span class="float-right pr-2" @click="exportCandidates()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i></span>
                            </div>
                        </div>
                        <div v-if="reportData.customReportFields.length" class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <b-table show-empty striped hover :items="list.candidateslist" :fields="tableFields" class="all-table">
                                        <template v-slot:empty="scope">
                                                <label class="text-center w-100">No data found</label>
                                        </template>
                                        <template slot="dateOfBirth" slot-scope="data">
                                            {{data.value | formatDate}}
                                        </template>
                                        <template slot="createdAt" slot-scope="data">
                                            {{data.value | formatDate}}
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <b-pagination v-if="list.candidateslist.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadCandidates()"></b-pagination>
                            </div>
                        </div>
                        <div v-if="!reportData.customReportFields.length" class="row col-md-12 mx-0">
                            <div class="card-header w-100 text-center">Sorry, there is no data to be displayed. Please select column headers to view the report</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal ref="reportFormModal" id="reportFormModal" :hide-footer="true" title="Custom Report">
            <div class="row col-md-12 mx-0 px-0">
                <div class="col-md-8 px-0 offset-md-2">
                    <div class="row col-md-12">
                        <label>Report Name</label>
                    </div>
                    <div class="row col-md-12">
                        <input class="form-control" type="text" name="reportName" v-model="reportData.reportName" />
                    </div>
                    <div class="row col-md-12">
                        <label v-if="$v.reportData.reportName.$error && !$v.reportData.reportName.required" class="text-danger">Report Name is required</label>
                        <label v-if="$v.reportData.reportName.$error && !$v.reportData.reportName.minLength" class="text-danger">Report Name must have at least {{$v.reportData.reportName.$params.minLength.min}} letters.</label>
                        <label v-if="$v.reportData.reportName.$error && !$v.reportData.reportName.maxLength" class="text-danger">Report Name must not exceed {{$v.reportData.reportName.$params.maxLength.max}} letters.</label>
                        <label v-if="$v.reportData.customReportFields.$error && !$v.reportData.customReportFields.required" class="text-danger">Fields are required. Please choose some fields.</label>
                    </div>
                </div>
                <!-- <div class="col-md-6 px-0">
                    <div class="row col-md-12">
                        <label>Report Type</label>
                    </div>
                    <div class="row col-md-12">
                        <label class="form-control">{{ reportData.reportType.toUpperCase() }}</label>
                    </div>
                    <div class="row col-md-12">
                        <label v-if="$v.reportData.reportType.$error" class="text-danger">Report Type is invalid. Try reload the page.</label>
                    </div>
                </div> -->
                <div class="row col-md-10 justify-content-end py-3">
                    <a href="javascript:" class="btn btn-primary ml-2" @click="saveReport">Save</a>
                    <a href="javascript:" class="btn btn-primary ml-2" @click="$refs.reportFormModal.hide();">Cancel</a>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import { required, minLength, maxLength, email, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        action: Object,
        candContactAccess: [String, Number]
    },
    components: {
        Multiselect
    },
    watch: {
        'search.params.startDate': {
            handler: function(value) {
                this.disabledendDates.to = new Date(value);
            },
            deep: true,
            immediate: true
        },
    },
    data() {
        return {
            elements: {
                isExpand: false,
                showadvancedsearch: false
            },
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            list: {
                candidateslist: []
            },
            options: {
                candStatusList: this.$store.getters.getCandStatusReport,
                industrylist: this.$store.getters.getIndustries,
                technologies: this.$store.getters.getTechOptions,
                recruiterlist: this.$store.getters.getRecruiterList,
                searchlimit: this.$store.state.searchlimit,
                fieldList: []
            },
            search: {
                params: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    location: "",
                    technologyId: "",
                    recruiterId: "",
                    position: "",
                    industryId: "",
                    status: "",
                    startDate: "",
                    endDate: "",
                    recruiterName: "",
                    customReportFields: []
                }
            },
            reportData: {
                id: 0,
                reportName: "",
                reportTypeId: 1,
                customReportFields: []
            },
            disabledStartDates: {
                from: new Date()
            
            },
            disabledendDates:{
                from: new Date(),
                to: ''
            }
        }
    },
    validations: {
        reportData: {
            reportName: { required, minLength: minLength(3), maxLength: maxLength(100) },
            reportTypeId: { required },
            customReportFields: { required }
        }
    },
    computed: {
        serialNumber(index) {
            return ((this.pagination.currentPage - 1) * this.pagination.rowsPerPage) + (index + 1);
        },
        tableFields: {
            get() {
                console.log(this.reportData.customReportFields);
                return this.reportData.customReportFields;
            }
        }
    },
    mounted() {
        this.loadDate();
        this.$emit('updateLogActivity');
        this.$emit('addLogActivity', 'Candidate Custom Report');
        if (this.action.mode == 'edit' || this.action.mode == 'view') {
            this.loadCustomReport(this.action.id);
        }
        this.loadCandidates();
        this.loadFieldList();
    },
    methods: {
        resetCandidatesSearch() {
            this.search.params = {
                firstName: "",
                lastName: "",
                email: "",
                location: "",
                technologyId: "",
                recruiterId: "",
                position: "",
                industryId: "",
                status: "",
                endDate: "",
                startDate:"",
            };
            this.loadDate();
            this.disabledStartDates.from =  new Date();
            this.disabledendDates.from =  new Date();
            this.loadCandidates();
        },
        loadDate() {
            let date = new Date();
            this.search.params.startDate = date;
            this.search.params.endDate = date;        
        },
        loadCandidates() {
            if (this.search.params.startDate > this.search.params.endDate) {
                iziToast.error({
                    title: 'Error',
                    message: this.$config.DateMismatchErr,
                    position: 'topRight'
                });
                return;
            }
            axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/candidatereport?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.list.candidateslist = result.candidates;
                    this.pagination.totalRecords = result.pagination[0].total;
                    this.pagination.currentPage = result.pagination[0].currentPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadFieldList: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/fieldlist/' + this.reportData.reportTypeId)
                .then(response => {
                    if (response.data.status) {
                        this.options.fieldList = response.data.fieldlist;
                        if (this.candContactAccess == 0) {
                            this.options.fieldList = this.filterReportFields("email", "mobile");
                            this.options.fieldList  = this.filterReportFields("candidateSource","sourcingRefLink");
                        }
                    } else
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        filterReportFields(field1, field2) {        
            return this.options.fieldList.filter(function(data, key) {
                if (data.key != field1 && data.key != field2) {
                    return data;
                }
            });
        },
        filterCandidates(){
            this.pagination.currentPage=1;
            this.loadCandidates();
        },
        changeLimit(newlimit) {
            this.pagination.rowsPerPage = newlimit;
            this.pagination.currentPage = 1;
            this.loadCandidates();
        },
        saveReport: function() {
            if (!this.validateReportData()) {
                // iziToast.error({title: 'Error', message: "Please fill the fields with appropriate data", position: 'topRight'});
                return false;
            }
            let axiosUrl = this.reportData.id ? '/reportsresource/update/custom/report' : '/reportsresource/add/custom/report';
            axios.post(this.$store.getters.getAPIBasePath + axiosUrl, this.reportData)
                .then(response => {
                    let result = response.data;
                    if (result.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                        this.$refs.reportFormModal.hide();
                        this.$emit('setSubTab', 0, 'list', null);
                    } else {
                        iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        validateReportData: function() {
            this.$v.reportData.$touch();
            if (this.$v.reportData.$invalid) {
                return false;
            }
            return true;
        },
        loadCustomReport: function(id) {
            this.reportData.id = id;
            axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/view/custom/report', this.reportData)
                .then(response => {
                    this.reportData.reportName = response.data.customReport.reportName;
                    this.reportData.reportTypeId = response.data.customReport.reportTypeId;
                    this.reportData.customReportFields = response.data.customReport.settingsReportFields;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        exportCandidates() {
            this.search.params.customReportFields = this.reportData.customReportFields;
            console.log(this.search.params);
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/candidates/report?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    console.log(response.data);
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "Candidates" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({ title: 'Warning!', message: error.response.data.message, position: 'topRight' });
                    this.reports = [];
                });
        }
    }
}

</script>
