<template>
	<div id="interviewer-edit-block" class="interviewer-edit-block mt-70">
		<div class="container-fluid driver-model" id="main-data">
			<div class="row">
				<div class="col-md-3">
					<div class="row">
						<div class="col-sm-12">
							<div class="card border-5 mb-3">
								  <h6 class="about-box m-0 text-left p-2">
                                    <a href="javascript:" @click="$router.go(-1)" title="Back" class="p-2">
                                        <i class="ti-arrow-left "></i>
                                        <span> Back</span>
                                    </a>
                                    <router-link class="float-right link pr-2" title="View Interviewer" :to="{ name: 'referralviewinterviewer', params:{interviewerid: interviewerId}}"><i class="ti-eye px-1"></i> View </router-link>
                                </h6>
								<div class="user-card pl-5 pr-5 ">
									<div class="information">
										<div class="imgUp">
											<div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + ProfileData.image + ')' }"></div>
											<label class="upload-icon"><i class="ti-camera"></i><input ref="interviewer_profile_photo" id="interviewer_profile_photo" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="upload_photo"></label>
										    <label class="delete-icon"><i class="ti-close closefont" @click="delete_photo"></i></label>
										</div>
										<div class="name">{{ProfileData.firstName}} {{ProfileData.lastName}}</div>
										<div>{{ProfileData.position}} </div>
										<div v-if="ProfileData.email" class="position"><i class="ti-email"></i> {{ProfileData.email}}</div> 
										<div v-if="ContactInfo.mobileNo" class="position"><i class="ti-mobile"></i> {{ContactInfo.mobileNo}}</div>
									</div>
								</div>
							</div>
							<div class="card border-5">
								<div class="user-card p-0">
									<h5 class="about-box m-0 text-left p-2">About Me</h5>
									<div class="p-3 float-left w-100">
										<h5>Specialization</h5>
										<div class="informations">
											<span v-if="TechSkills.length==0" class="badge p-2 mb-1">No records found</span>
											<span v-if="TechSkills.length!=0" v-for="(Skill,idx) in TechSkills" v-bind:key="idx" class="badge p-1 m-1" :class="colours[idx%5]">{{Skill.technology.technology}}</span>
										</div>

			

										<div v-if="ContactInfo!== null" class="w-100 float-left">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-9">
					<div class="box-wrapper shadow-sm mtb-4 bg-white rounded pos-rel main-search">
						<div class="row">
							<div class="col-sm-12">
								<div class="row no-gutters">
									<div class="col-md-12 pop-tab">
										<div class="driver-model">
											<div class="row no-gutters">
												<b-tabs v-model="currentTab" small card class="tab-list driver-tab-list tab-bg">
													<b-tab title="Primary Info">
														<PersonalInfo :profileId="interviewerId" :ProfileData=ProfileData @loadProfileData="loadInterviewer" ref="personalinfocomponent"></PersonalInfo>
													</b-tab>
													<b-tab title="Contact Info">
														<ContactInfo :profileId="interviewerId" :ContactInfo=ContactInfo ref="contactInfocomponent"></ContactInfo>
													</b-tab>
													<b-tab title="Technology/Skills">
														<TechnologySkills :profileId="interviewerId" @loadProfileData="loadInterviewer" :Skills="TechSkills" ref="technologyskillscomponent"></TechnologySkills>
													</b-tab>
												</b-tabs>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PersonalInfo from '@/components/interviewers/blocks/profile/PersonalInfo';
	import TechnologySkills from '@/components/interviewers/blocks/profile/TechnologySkills';
	import ContactInfo from '@/components/interviewers/blocks/profile/ContactInfo';
	import AvailabilityInfo from '@/components/interviewers/blocks/profile/AvailabilityInfo';
	import iziToast from 'izitoast';
	export default {
		components : {
			PersonalInfo,
			TechnologySkills,
			ContactInfo,
			AvailabilityInfo
		},
		data(){
			return {
				elements: {
					showAvailabilityInfo: false
				},
				currentTab:0,
				interviewerId: Number(this.$route.params.interviewerid),
				ProfileData:{
					id:0,
					firstName:'',
					lastName:'',
					nickName:'',
					email:'',
					dateOfBirth:'',
					gender:'',
					position:'',
					image:'',
					imageName:'',
					imagePath:'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
					image:'',
					industryId: null
				},
				deleteparams:{
            		id: 0,
            		type:'interviewer'
            	},
				ContactInfo: Object,
				TechSkills:[],	
				colours: ['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info']
			}
		},
		mounted(){
			this.loadInterviewer();
		},
		methods: {
			loadInterviewer:function(){
				axios.get(this.$store.getters.getAPIBasePath+'/interviewerresource/view/interviewer/'+this.interviewerId)
				.then(response => {
					if(response.data.status){
						let interviewerData = response.data.interviewer;
						this.ProfileData.id = interviewerData.id;
						this.ProfileData.firstName = interviewerData.firstName;
						this.ProfileData.lastName = interviewerData.lastName;
						this.ProfileData.nickName = interviewerData.nickName;
						this.ProfileData.email = interviewerData.email;
						this.ProfileData.dateOfBirth = interviewerData.dateOfBirth;
						this.ProfileData.gender = interviewerData.gender;
						this.ProfileData.position = interviewerData.position;
						this.ProfileData.imageName = interviewerData.imageName;
						this.ProfileData.imagePath = (interviewerData.imagePath==''?'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png':interviewerData.imagePath);
						this.ProfileData.image = (interviewerData.imagePath==''?'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png':interviewerData.imagePath);
						this.ProfileData.industryId = interviewerData.industryId;
						//this.updateSkills(interviewerData.interviewersSkill);
						var techAndSkill = [];
                        if (interviewerData.techList != null) {
                            for (var i = 0; i < interviewerData.techList.length; i++) {
                                let skillData = interviewerData.techList[i];
                                if (skillData.technologySkillData.length != 0) {
                                    techAndSkill.push({
                                        technology: { technology: skillData.technologyName, id: null },
                                        skills: []                                        
                                    });
                                    for (var j = 0; j < skillData.technologySkillData.length; j++) {
                                        if (j == 0) {
                                            techAndSkill[i].technology.id = skillData.technologySkillData[j].technologyId;
                                        }
                                        techAndSkill[i].skills.push({
                                            skillId: skillData.technologySkillData[j].id,
                                            skillName: skillData.technologySkillData[j].skillName,
											technology: skillData.technologySkillData[j].technologyName
                                        });
                                    }
                                }
                            }
                        }
						this.TechSkills = techAndSkill;
				/* 		if(interviewerData.interviewersContact!=null){
                            let phoneNo = interviewerData.interviewersContact.mobileNo;
                            if (phoneNo != null && phoneNo.startsWith("+")) {
                                let phoneData = phoneNo.split(" ");
                                interviewerData.interviewersContact.countryCode = phoneData[0];
                                interviewerData.interviewersContact.mobile = phoneData[1];           
                            }
                            let altPhoneNo = interviewerData.interviewersContact.altMobileNo;                   
                            if (altPhoneNo != null && altPhoneNo.startsWith("+")) {
                                let phoneData = altPhoneNo.split(" ");
                                interviewerData.interviewersContact.altcountryCode = phoneData[0];
                                interviewerData.interviewersContact.altMobile = phoneData[1];                     
                            }
                        } */

						this.ContactInfo = Object.assign({}, interviewerData.interviewersContact);
					}
				})
				.catch(error => {
					console.log(error);
				});
			},
			updateSkills:function(Skills){
				let self = this;
				self.TechSkills = [];
				$.each(Skills,function(key,value){
					if(value.technologySkillSet != null)
						self.TechSkills.push(value.technologySkillSet);
				});
			},
			createImage(file) {
				var image = new Image();
				var reader = new FileReader();
				var vm = this;
				reader.onload = (e) => {
					this.ProfileData.image = e.target.result;
				};
				reader.readAsDataURL(file);
			},
			delete_photo: function() {
            this.deleteparams.id=this.interviewerId;
            axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/delete/candidate/photo',this.deleteparams)
                .then(response => {
                    if(response.data.status=="OK"){
                    	iziToast.success({ title: 'Photo update', message: response.data.message, position: 'topRight' });
                    	this.ProfileData.image="";
                        this.ProfileData.imageName="";
                        this.ProfileData.imagePath="";
                    }
                    else if(response.data.status=="EXPECTATION_FAILED"){
                    console.log(response);
                    iziToast.info({ title: 'Photo update', message: response.data.message, position: 'topRight' });}
                })
                .catch(function(error) {
                    console.log(error);
                });
           
        },
			upload_photo: function(){
				console.log(this.$refs.interviewer_profile_photo.files[0].name);
				if (! /\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(this.$refs.interviewer_profile_photo.files[0].name)) {
					iziToast.error({
						title: 'Photo update',
						message: this.$config.FileFormatErr,
						position:'topRight'
					});
					return false;
				}
				this.createImage(this.$refs.interviewer_profile_photo.files[0]);
				//this.ProfileData.imagePath=this.$refs.interviewer_profile_photo.files[0];
				//this.profilePhoto = this.$refs.addcandidate_profile_photo1.files[0];
				let formData = new FormData();
				formData.append('file', this.$refs.interviewer_profile_photo.files[0]);
				formData.append('id',this.interviewerId);
				formData.append('type','interviewer');
				axios.post( this.$store.getters.getAPIBasePath+'/resumeresource/uploadprofilephoto',
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}
				).then(response=> {
					console.log('SUCCESS!!');
					iziToast.success({title: 'Photo update', message: this.$config.IntProPicDelSuc, position:'topRight'});
				})
				.catch(function(error){
					console.log('FAILURE!!');
					console.log(error);
					iziToast.error({title: 'Photo update', message: this.$config.ProfileUpErr, position:'topRight'});
				});
			}
		}
	}
</script>