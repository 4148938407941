import Listjobs from '@/components/jobs/Listjobs';
import Addjob from '@/components/jobs/Addjob';
import Editjob from '@/components/jobs/Editjob';
import JobView from '@/components/jobs/JobView';
import PlanRecruitment from '@/components/jobs/PlanRecruitment';
import Joblist from '@/components/jobs/JobList';


export default [{
        path: '/jobs',
        name: 'listjobs',
        component: Listjobs,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/jobs/add',
        name: 'addjob',
        component: Addjob,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/jobs/edit/:jobid',
        name: 'editjob',
        component: Editjob,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/job/view/:jobid',
        name: 'viewjob',
        component: JobView,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/job/plan-recruitment/:jobid',
        name: 'planrecruitment',
        component: PlanRecruitment,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/jobs/list',
        name: 'joblist',
        component: Joblist,
        meta: { accessedby: 'recruiter' }
    }
];
