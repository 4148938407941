<template>
        <div id="main" class="reports settings mt-70 mar-top resumeExtract">
        <div class="container-fluid bg-white pr-0 pl-0" id="quick-search">
            <div class=" wrapper settings-leftbar w-100 px-0">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div class="editcan content-wrapper left-wrapper apply-wrapper">
            <aside v-if="toggle" class="newcan sub-sidebar main-sidebar bg-white" style="border: 1px solid #ececec;">
                    <section class="sidebar h-100">  
                    <div id="loadImg">
                        <div>
                            <img src="/images/loading.gif" style="height: 68px;width: 68px;"/>
                        </div></div>
                        <div class="pr-3 pl-3 pb-2 pt-2 d-flex flex-row justify-content-between">
                            <div class="reports-title">
                                <h5>Offer Letter</h5>  
                            </div>
                            <div class="back-link">
                                <h5 class="text-secondary">
                                    <a href="javascript:void(0);" @click="closesidebarToggle()" title="Close" class="p-2">
                                        <i class="fa fa-close"></i>
                                    </a>
                                </h5>
                            </div>
                        </div>
                        <div>
                        <!-- <editor class="form-control flex-fill mx-1" api-key="yn4wx6bfcohfz6bwlu5k4urno027lgfkcpxolz0f6k4kku01" disabled :init="{menubar: false,toolbar:false,statusbar: false,height:345,content_style: 'body { font-size:14px}'}" v-model="content"></editor> -->
                         <iframe class="embed-responsive-item" :src="docsrc" allowfullscreen width="550" height="510" style="border: 1px solid #cfcfcf;"  onload="document.getElementById('loadImg').style.display='none';"></iframe> 
                        <div class="btn-position">
                            <button v-if="showbtn && joblist[idx].offerSent==1 && joblist[idx].recruitmentStatusId == 5" type="button" class="btn btn-info  float-right" style="width:102px;margin-left: 10px;opacity:0.9"><a style="color:white" href="javascript:;" data-toggle="modal" data-target="#counteroffers">Counter Offers</a></button>
                            <button v-if="showbtn && joblist[idx].offerSent==1 && joblist[idx].recruitmentStatusId == 5" type="button" class="btn btn-success  float-right" style="width:90px;margin-left: 10px;opacity:0.9" @click="changeShortlistCandStatus(16,false)">Accept</button>
                        </div>
                        <div class="offerbtn">
                            <button v-if="showbtn && joblist[idx].offerSent==2 && joblist[idx].recruitmentStatusId == 5" type="button" class="btn btn-danger  float-right" style="width:102px;margin-left: 10px;opacity:0.9"><a style="color:white" href="javascript:;" data-toggle="modal" data-target="#rejectnotes">Reject</a></button>  
                            <button v-if="showbtn && joblist[idx].offerSent==2 && joblist[idx].recruitmentStatusId == 5" type="button" class="btn btn-success  float-right" style="width:90px;margin-left: 10px;opacity:0.9" @click="changeShortlistCandStatus(16,false)">Accept</button>
                        </div>
                        <div v-if="false" class="downloadhide"><button type="button" title="Download" class="btn" style="height:30px;width:32px;background-color: #3c3a3a;"><a style="color:white" :href="joblist[idx].offerLetterPath"><i class="fa fa-download" style="font-size:14px"></i></a></button></div>
                    </div>
                </section>
            </aside>
            <div class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 mt-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :class="isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <form id="main-search" :class="isExpand?'d-block':'d-none'">
                        <div class="row form-group ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Job Title</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" v-on:keyup.enter="findJobs"  name="jobTitle" maxlength="50" value="" id="jobTitle" class="form-control" v-model="search.params.jobTitle">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Job Code</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" v-on:keyup.enter="findJobs"  v-model="search.params.jobCode" name="jobId" maxlength="15" value="" id="jobId" class="smallCombo form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Industry</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="search.params.industryId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(data, index) in industrylist" :value="data.id">{{data.industryName}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>                  
                            <div class="col-md-1 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Status</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="search.params.status" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(data, index) in jobStatuslist" :value="data.id">{{data.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Hot Jobs</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="search.params.priority" class="form-control">
                                                <option value="" >Select</option>
                                                <option value=true>Yes</option>
                                                <option value=false>No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div  class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;">Recruiter</label>
                                    </div>
                                    <div class="col-md-12">
                                        <button type="button" @click="findJobs" class="btn btn-primary btn-sm" style="margin-left: 10px;">Find</button>
                                        <button type="button" @click="resetJobSearch" class="btn btn-primary btn-sm" style="margin-left: 10px;">Reset</button>
                                    </div>     
                                </div>
                            </div>
                        </div>                  
                    </form>
                    <div class="load-indicator"></div>
                </div>
            </div>
            <div class="container-fluid" id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-6 col-sm-12 col-12">
                            <h4 class="page-title">Applied Jobs
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{browsepagination.totalRecords}} - Total)</span>
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                    <div class="dropdown">
                                        <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                        <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                            <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                        </div>
                                    </div>
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th v-if="false" class="" scope="col"></th>
                                            <th class="" scope="col"></th>
                                            <th v-if="coldisplay.jobTitle" class="" scope="col">Job Title</th>
                                            <th v-if="coldisplay.jobCode" class="" scope="col">Code</th>
                                            <th v-if="coldisplay.industry" class="" scope="col">Industry</th>
                                            <th v-if="coldisplay.position" class="" scope="col">Position</th>
                                            <th class="" scope="col">Experience</th>
                                            <th class="" scope="col">Location</th>
                                            <th class="" scope="col">Vacancies</th>
                                            <th class="" scope="col">Due Date</th>
                                            <th class="" scope="col">Recruitment Status</th>
                                            <th class="" scope="col">Recruiter</th>
                                            <th class="" scope="col">offer letter</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="joblist.length==0">
                                            <td colspan="26" class="text-center">No data found</td>
                                        </tr>
                                        <template :style="[jobinfo.priority === true ? {backgroundColor: 'lightblue'}: {backgroundColor: 'white'}]" v-for="(jobinfo, index) in joblist">
                                            <tr>
                                            <td>
                                               <a href="javascript:" class="expand" data-targetopen="panel1">
                                                    <span @click="getSalaryNegotiation(jobinfo.id)"><i :class="(currentIndex==jobinfo.id) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
                                                    </span>
                                                </a>
                                            </td>
                                            <td v-if="false">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox" id="rowchckbox1" value="option1">
                                                    <label class="form-check-label" for="rowchckbox1"></label>
                                                </div>
                                            </td>
                                            <td v-if="coldisplay.jobTitle" class="">
                                                <router-link title="View Job" :to="{ name: 'candidateviewjob', params: { jobid: jobinfo.id }}">{{jobinfo.jobTitle }}</router-link>
                                            </td>
                                            <td v-if="coldisplay.jobCode" class="">{{ jobinfo.jobCode }}</td>
                                            <td v-if="coldisplay.industry" class="">{{ jobinfo.industryName }}</td>
                                            <td v-if="coldisplay.position" class="">{{ jobinfo.position }}</td>
                                            <td class="">{{ jobinfo.experience }}</td>
                                            <td class="">{{ jobinfo.location }}</td>
                                            <td class="">{{ jobinfo.vacancies }}</td>
                                            <td class="endDate" format="MM-dd-yyyy">{{ jobinfo.dueDate | formatDate }}</td>
                                            <td class="">{{ jobinfo.recruitmentStatus}}</td>
                                            <td class="">{{ jobinfo.recruiterName}}</td>
                                            <td class=""><a href="Javascript:void(0);" class="tbl-add-icon" @click="sidebarToggle" title="Offer letter" data-toggle="push-menu"><span v-if="jobinfo.offerLetterPath && (jobinfo.recruitmentStatusId == 5||jobinfo.recruitmentStatusId ==16|| jobinfo.recruitmentStatusId ==18 ||jobinfo.recruitmentStatusId ==6)"  class="input-group-addon"><i class="ti-email" @click="setIndex(index,jobinfo.offerLetterPath,jobinfo.shortListId);"></i></span></a></td>  
                                        </tr>
                                        <tr v-if="currentIndex == jobinfo.id">
                                                <td colspan="11">
                                                    <div class="table-responsive">
                                                        <table class="table table-borderless all-table">
                                                            <thead>
                                                                <tr>
                                                                    <th class="" scope="col">Candidate Salary Negotiation</th>
                                                      
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                <td class="fs-fw" >
                                                                <div  class="card" style="background-color: #f0f0f0;padding: 20px;">
                                                                    <div class="" v-if="salaryNegotiation.negotiationProcessStatus=='open' && salaryNegotiation.status=='Recruiter_Accepted'">
                                                                        <div class="col-sm-12 pb_20">Client Offer: {{salaryNegotiation.currency}} {{salaryNegotiation.bestOffer }} {{salaryNegotiation.payFrequency}}</div>
                                                                        <div class="col-sm-12 pb_20">Are you willing to  
                                                                        <span class="job-link pt-0 pb-0">
                                                                        <button type="button" @click="requestid(salaryNegotiation.id)" class="btn btn-success " style="margin-left: 10px;"><a title="Accept" href="javascript:void(0);" data-toggle="modal" data-target="#AcceptRequest"><i class="ti-check c-white"></i><span class="pr-10 c-white" style="color: white; font-family: sans-serif;">Accept</span></a></button>
                                                                    
                                                                        </span>
                                                                        <span class="job-link  pt-0 pb-0">
                                                                        <button type="button" @click="requestid(salaryNegotiation.id)" class="btn btn-danger " style="margin-left: 10px;"> <a title="Reject" href="javascript:void(0);" data-toggle="modal" data-target="#RejectRequest"><i class="ti-close c-white"></i><span style="color: white;font-family: sans-serif;"> Reject</span></a></button>
                                                                        
                                                                        </span>
                                                                        </div>
                                                                    </div>
                                                                    <div v-if="salaryNegotiation.status=='Candidate_Accepted'  && salaryNegotiation.negotiationProcessStatus=='closed'" class="col-sm-12 pb_20">Your Accepted Offer is <span class="text-success">{{salaryNegotiation.currency}} {{salaryNegotiation.bestOffer }} {{salaryNegotiation.payFrequency}}</span></div>
                                                                    <div v-if="salaryNegotiation.status=='Candidate_Rejected'  && salaryNegotiation.negotiationProcessStatus=='closed'" class="col-sm-12 pb_20">Your Rejected Offer is <span class="text-danger">{{salaryNegotiation.currency}} {{salaryNegotiation.bestOffer }} {{salaryNegotiation.payFrequency}}</span></div>
                                                                    <div v-if="recordInfo.length == '0'" class="col-sm-12 pb_20">No data found</div>     
                                                                    <div v-if="salaryNegotiation.status=='Received'" class="col-sm-12 pb_20">Salary Negotiation Inprogress...</div>     
                                                                </div>  
                                                                </td>
                                                                     
                                                                
                                                                </tr>
                                                                         
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                        
                                                </td>
                                            </tr>

                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <b-pagination v-if="joblist.length!=0" size="md" class="pull-right" :total-rows="browsepagination.totalRecords" v-model="browsepagination.currentPage" :per-page="browsepagination.rowsPerPage" @input="loadJobs()"></b-pagination>                        
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <template #overlay>
                <div>
                    <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
                </div>                   
            </template> 
        </b-overlay>
        <div class="modal " id="RejectRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to reject this Offer?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="requestConfirmation('reject')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal " id="AcceptRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to accept this Offer?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="requestConfirmation('accept')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
         <div class="modal" id="counteroffers" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" style="width:34%" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Counter Offers</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-borderless all-table">
                            <thead>
                                <tr>
                                    <th>Counter Offers</th>
                                    <th>Old Value</th>
                                    <th>New Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(offerinfo, index) in counterOfferList">
                                    <td>
                                        <label>{{offerinfo.settingsCounterOffer.name}}</label></td>
                                    <td>
                                        <input disabled type="text" maxlength="30" class="form-control" size="3" v-model="offerinfo.recruiterValue">
                                    </td>
                                    <td>
                                        <input type="text" maxlength="30" class="form-control" size="3" v-model="offerinfo.candidateValue">
                                        <!-- <select v-model="offerinfo.candidateValue" class="form-control">
                                            <option value="null">Select</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">                                            
                        <button type="submit" @click="updateCounterofferList" class="btn btn-primary">Send</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="rejectnotes" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" style="width:34%!important" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Reject Confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div>You have chosen to reject the offer. You may be required to contact recruitment team for further action. Would like to proceed?</div>
                    </div>
                    <div class="modal-footer">                                            
                        <button type="submit" @click="changeShortlistCandStatus(6,false);" class="btn btn-primary">Ok</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Editor from '@tinymce/tinymce-vue';
export default {    
    components: {
        Editor
    },  
    data() {
        return {
            currentIndex: null,
            isShow : false,
            showOverlay:false,
            isExpand: false,
            joblist: [],
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            industrylist: this.$store.getters.getIndustries,
            clientlist: this.$store.getters.getClients,
            jobStatuslist: this.$store.getters.getJobStatus,
            recruiterlist: this.$store.getters.getRecruiterList,
            candidateId: this.$store.getters.currentUser.profileId,
            browsepagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            recCandInfo: {
                talentCount: 0,
                candShortlistedCount: 0,
                clientReviewedCount: 0,
                hiredCount: 0,
                talentIdentified: [],
                htmlMessage: "",
            },
            content:"",
            elements: {
                showadvancedsearch: false,
            },
            counterOfferList: [],
            dispalyInfo:true,
            docsrc:'',
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    jobTitle: "",
                    jobType: "",
                    technologyId: "",
                    startDate: "",
                    endDate: "",                    
                    clientId: "",
                    industryId: "",
                    status: "",
                    recId: "",
                    priority: "",
                    candidateId: this.$store.getters.currentUser.profileId,
                },
            },
            toggle:false,
            showbtn:false,
            rejectNotes:'',
            coldisplay: {
                jobTitle: true,
                jobCode: true,
                client: true,
                industry: true,
                //position: true
            },
            delJobParam: {
                index: -1,
                id: 0
            },
            salaryNegotiation: {
                id: '',
                currency: '',
                payFrequency: '',
                bestOffer : '',
                negotiationProcessStatus:'',
                status:''
            },
            idx: 0,
            recordInfo:[],
            salaryNegId:""
        }
    },
    mounted() {
        this.loadJobs();
        this.$root.$on('appliedjobs', () => {
            this.loadJobs();
        });
    },
    
    watch:{
       'idx': {
			handler: function(value) {
                this.docSrc(this.joblist[value].offerLetterPath);
            }
        },
    },
    methods: {
        docSrc(filePath){
            if(filePath){
               this.docsrc="https://docs.google.com/gview?url="+filePath+"&embedded=true";
           }
        },
        async getSalaryNegotiation(id){

            await this.getNegotiationData(id);
           
            if (this.currentIndex == id) {
                this.currentIndex = null;
            } else {
                this.currentIndex = id;
                
            }},
            closesidebarToggle() {
                this.toggle=false;
                this.showbtn=true;
            },
        resetJobSearch() {
            this.search.params = {
                jobTitle: "",
                jobType: "",
                technologyId: "",
                startDate: "",
                endDate: "",
                clientId: "",
                industryId: "",
                recId: "",
                status: "",
                priority: "",
                candidateId: this.$store.getters.currentUser.profileId,
            };
            this.loadJobs();
        },
        async loadJobs() {
            this.showOverlay=true;
            await this.getJobList();
            this.showOverlay=false;
        },
        getJobList() {
            this.showOverlay=true;
            return axios.post(this.$store.getters.getAPIBasePath + '/jobresource/candidate/applied/job/list?page=' + this.browsepagination.currentPage + '&size=' + this.browsepagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.joblist = result.jobs;
                    this.browsepagination.totalRecords = result.pagination[0].total;
                    this.browsepagination.currentPage = result.pagination[0].currentPage;
                    this.browsepagination.totalpages = result.pagination[0].lastPage;
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                }); 
        },
        findJobs() {
            this.browsepagination.currentPage = 1;
            this.loadJobs();
        },
        changeLimit(newlimit) {
            this.browsepagination.currentPage = 1;
            this.browsepagination.rowsPerPage = newlimit;
            this.loadJobs();
        },
        getNegotiationData(jobId) {
             let data = {
                candidateId:this.$store.getters.currentUser.profileId,
                jobId:jobId
             }

            this.showOverlay=true;
            return axios.post(this.$store.getters.getAPIBasePath + '/jobresource/candidate/salary/negotiation', data)
                .then(response => {
                    let result = response.data;
                    this.recordInfo= result.recordinfo;
                    this.bindSalaryNegotiationData(this.recordInfo);
                    this.showOverlay=false;
                   
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });
        },
        bindSalaryNegotiationData(data){
				if(data != null && data.length > 0){
					if(data.length == 1){
                        this.salaryNegotiation.id = data[0].id
						this.salaryNegotiation.currency = data[0].currency
						this.salaryNegotiation.payFrequency = data[0].payFrequency
						this.salaryNegotiation.bestOffer  = data[0].bestOffer 
                        this.salaryNegotiation.negotiationProcessStatus = data[0].negotiationProcessStatus
                        this.salaryNegotiation.status = data[0].status
					}else if(data.length == 2){
                        this.salaryNegotiation.id = data[1].id
						this.salaryNegotiation.currency = data[1].currency
						this.salaryNegotiation.payFrequency = data[1].payFrequency
						this.salaryNegotiation.bestOffer  = data[1].bestOffer 
                        this.salaryNegotiation.negotiationProcessStatus = data[1].negotiationProcessStatus
                        this.salaryNegotiation.status = data[1].status
				}else if(data.length == 3){
                        this.salaryNegotiation.id = data[2].id
						this.salaryNegotiation.currency = data[2].currency
						this.salaryNegotiation.payFrequency = data[2].payFrequency
						this.salaryNegotiation.bestOffer  = data[2].bestOffer 
                        this.salaryNegotiation.negotiationProcessStatus = data[2].negotiationProcessStatus
                        this.salaryNegotiation.status = data[2].status
				}
			}else{
                    this.salaryNegotiation.id = ''
						this.salaryNegotiation.currency = ''
						this.salaryNegotiation.payFrequency = ''
						this.salaryNegotiation.bestOffer  = ''
                        this.salaryNegotiation.negotiationProcessStatus = ''
                        this.salaryNegotiation.status = ''
                }
        }, 
        requestid(id) {
            this.salaryNegId = id;
        },
        sidebarToggle: function(event) {
            this.showbtn=true;
        },
         setIndex: function(index,url,id) {
            this.idx=index;
            this.docSrc(url);
            this.toggle=true;
             axios.put(this.$store.getters.getAPIBasePath + '/jobresource/get/counteroffer/'+id)
                .then(response => {  
                    this.counterOfferList = response.data.recordinfo;
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });
        },
        changeShortlistCandStatus(stsId,rejNotes) {
            if(!rejNotes || (rejNotes && this.rejectNotes!='')){
            let CandSLstatusId = stsId;
            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/update/rp/candshortlist/status/' + this.joblist[this.idx].shortListId, { "status": CandSLstatusId,"candidateId":this.$store.getters.currentUser.profileId,"jobId": this.joblist[this.idx].id,"note":this.rejectNotes})
                .then(response => {
                    if (response.data.status) {
                        let successmsg = (response.data.message != undefined) ? response.data.message : this.$config.StsUpSuc;
                        iziToast.success({ title: 'Success', message: successmsg, position: 'topRight' });
                        this.$root.$emit('reloadappliedjobs')
                        $('#rejectnotes').modal('hide'); 
                        this.currentTab=1;
                        this.toggle = false;
                    } else {
                        let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.UpdatingErr;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                        this.$root.$emit('reloadappliedjobs')
                        $('#rejectnotes').modal('hide'); 
                        this.currentTab=1;
                        this.toggle = false;
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                    let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.UpdatingErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
            }
            else{
                    iziToast.error({ title: 'Error', message: this.$config.FillRejNotErr, position: 'topRight' });
                }
        },
        UpdateOfferStatus(){  
            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/update/rp/candshortlist/status/' + this.joblist[this.idx].shortListId, { "status": 18,"candidateId":this.$store.getters.currentUser.profileId,"jobId": this.joblist[this.idx].id,"note":this.rejectNotes})
                .then(response => {
                   if (response.data.status) {
                        console.log(response.data.status)
                        this.$root.$emit('reloadappliedjobs')
                    } else {
                        console.log(response.data.status)
                        this.$root.$emit('reloadappliedjobs')
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        updateCounterofferList(){
            let filtered = this.counterOfferList.filter(row => row.candidateValue != '');
            if(filtered.length!=0){
            let counterOfferList =  this.counterOfferList.map(v =>  ({...v,status: "Candidate_Responded" }))
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/counteroffer',counterOfferList)
                .then(response => {  
                    let result = response.data                
                    if(result.status="ACCEPTED"){
                        this.showOverlay=false;
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position:'topRight'
                        });    
                        $('#counteroffers').modal('hide');
                        this.UpdateOfferStatus();
                        this.toggle=false;
                    }else{
                        this.showOverlay=false;
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position:'topRight'
                        }); 
                        $('#counteroffers').modal('hide'); 
                        this.toggle=false;           
                    }
                   
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });
            }
            else{
                iziToast.info({
                    title: 'Info',
                    message: 'please enter new offer value',
                    position:'topRight'
                }); 
            }
            
        },
        requestConfirmation(type){
            axios.post(this.$store.getters.getAPIBasePath + '/jobresource/candidate/salary/negotiation/request/'+this.salaryNegId, {type:type, userType:this.$store.getters.currentUser.userType,offerAmount: this.salaryNegotiation.bestOffer})
                .then(response => {  
                    let result = response.data                
                    if(result.status="ACCEPTED"){
                        this.showOverlay=false;
                        iziToast.success({
                            title: 'Confirmation Request',
                            message: result.message,
                            position:'topRight'
                        });
                        this.$root.$emit('reloadappliedjobs')                   
                    }else{
                        this.showOverlay=false;
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position:'topRight'
                        });             
                    }
                   
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });
        }
        //  ------- to get Negotiation information         /candidate/salary/negotiation
    }
}

</script>
<style scoped>
.all-table tbody td {
    background-color: transparent;

}

</style>
