<template>
    <div id="main" class="tech-skill-settings settings recruiter">
        <div class="row col-md-12 px-0 py-4">
            <div class="col-md-4 offset-md-4">
                <h4 class="">Template Types</h4>
            </div>
            <div class="col-md-1 offset-md-3">
                <a href="Javascript:void(0);" class="pull-right btn-back" @click="$emit('setSubTab',0)">
                    <!-- <i class="ti-arrow-left"></i> Back -->
                    <h5 class="text-blue"><span title="Go Back" class="ti-arrow-left py-4"></span><span class="pl-2">Back</span></h5>
                </a>
            </div>
        </div>
        <div class="row" v-if="editIndex<0">
            <div class="col-md-6 col-sm-8 col-6">
                <!-- <multiItemInput id="multiTech" placeholder="Enter Job Document" :items="newTemplateTypeList" class="float-left"></multiItemInput> -->
                <input class="form-control" placeholder="Enter Template Type" v-model="newTemplateType.templateType" />
                <p v-if="formErrors.length">
                    <ul>
                        <li class="error">{{ formErrors }}</li>
                    </ul>
                </p>
            </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                <a href="Javascript:void(0);" @click="addTemplateType">
                    <span class="ti-plus"></span>
                </a>
            </div>
        </div>
        <div class="row" v-if="editIndex!=-1">
            <div class="col-md-6 col-sm-8 col-6">
                <input class="form-control" placeholder="Enter Template Type" v-model="newTemplateType.templateType" />
            </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                <a style="float:left; margin-right:5px;" href="Javascript:void(0);" @click="updateTemplateType(1)">
                    <span class="ti-check"></span>
                </a>
                <a style="float:left;" href="Javascript:void(0);" @click="updateTemplateType(0)">
                    <span class="ti-close"></span>
                </a>
            </div>
        </div>
        <div class="row item" v-for="(TemplateTypeInfo, index) in templateTypesList" v-bind:key="index">
            <div class="col-md-6 col-sm-8 col-6">
                <div class="form-control">{{TemplateTypeInfo.name}}</div>
            </div>
            <div class="col-md-2 col-sm-4 col-2 pt-2">
                <a style="float:left; margin-right:5px;" href="Javascript:void(0);" @click="editTemplateType(index)">
                    <span class="ti-pencil"></span>
                </a>
                 <a style="float:left; margin-right:5px;" href="Javascript:void(0);" @click="removeTemplateType(index,TemplateTypeInfo.id)" data-toggle="modal" data-target="#removTemplateType" title="Remove"  class="px-2">
                 <span class="ti-trash"></span>
                 </a>
            </div>
        </div>
         <Dialog id="removTemplateType" :onYes="deleteTemplateType" :returnParams="delParam" />
    </div>
</template>
<style scoped>
div.item {
    margin-top: 5px;
}

</style>
<script>
import multiItemInput from "@/components/plugins/multiItemInput";
import iziToast from "izitoast";

export default {
    components: {
        multiItemInput
    },
    data() {
        return {
            formErrors: "",
            editIndex: -1,
            templateTypesList:[],
            TemplateTypeInfo: {
                templateType: ""
            },
            newTemplateType: { id: 0, templateType: "" },
            browsepagination: this.$store.state.paginationconfig,
            searchlimit: this.$store.state.searchlimit,
             delParam: {
                index: -1,
                id: 0
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    templateType: ""
                }
            }
        };
    },
    mounted() {
        this.loadtemplateTypesList();
        // console.log(this.templateTypesList)
    },
    methods: {
        loadtemplateTypesList() {
            axios
                .get(this.$store.getters.getAPIBasePath + "/settingsresource/settingsemailtemplatetype/getall")
                .then(response => {
                    let result = response.data;  
                    this.templateTypesList = result.data;
                    console.log(this.templateTypesList) 
                    this.newTemplateType = { id: 0, templateType: "" };
                })
                .catch(error => {
                    iziToast.error({
                        title: "Error",
                        message: error,
                        position: "topRight"
                    });
                });
        },
        addTemplateType(){   
            if(!this.newTemplateType.templateType){  
            this.formErrors="Enter the Template Type";
            }
            else{
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/settingsresource/add/settingsemailtemplatetype", this.newTemplateType
                )
                .then(response => {
                    this.TemplateTypeInfo.name = "";                    
                    this.newTemplateType = "";
                    this.formErrors = [];
                    if(response.data.status == true) {
                        iziToast.success({
                            title: "Success",
                            message: response.data.message,
                            position: "topRight"
                        });
                    } else {
                        iziToast.info({
                            title: "Info",
                            message: response.data.message,
                            position: "topRight"
                        });
                    }
                    this.loadtemplateTypesList();
                })
                .catch(error => {
                    this.formErrors = error.response.data.validationErrorList;
                });
            
            }
            //}
        },
       removeTemplateType:function(arg1, arg2)
            {
                this.delParam.index=arg1;
                this.delParam.id=arg2;
            },
            deleteTemplateType:function(arg)
            {
         axios.delete(this.$store.getters.getAPIBasePath + '/settingsresource/delete/settingsemailtemplatetype/'+this.delParam.id)
                 .then(response => {
                    let result = response.data;
                    if (result.status == true) {
                        iziToast.success({
                            title: "Success",
                            message: this.$config.TempTypeDelSuc,
                            position: "topRight",
                            timeout: 1000
                        });
                        this.templateTypesList.splice(arg.index, 1);
                    }else{
                        iziToast.error({
                            title: "Error",
                            message: this.$config.TempTypeDelErr,
                            position: "topRight"
                        });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
    
        editTemplateType(index) {
            this.editIndex = index;
            this.newTemplateType = {
                id: this.templateTypesList[index].id,
                templateType: this.templateTypesList[index].name,
                TemplateTypeList : [ {templateType : 1} ] 
            };
            console.log(this.newTemplateType);
        },
        updateTemplateType(mode){
            if (mode == 1) {
                axios
                    .post(
                        this.$store.getters.getAPIBasePath +
                        "/settingsresource/update/settingsemailtemplatetype",
                        this.newTemplateType
                    )
                    .then(response => {
                        this.editIndex = -1;
                        this.newTemplateType = { id: 0, templateType: "" };
                        
                        if(response.data.status == true) {
                            iziToast.success({
                                title: "Success",
                                message: this.$config.TempTypeUpSuc,
                                position: "topRight"
                            });
                        } else {
                            iziToast.info({
                                title: "Info",
                                message: response.data.message,
                                position: "topRight"
                            });
                        }
                        this.loadtemplateTypesList();
                    })
                    .catch(error => {
                        iziToast.error({
                            title: "Error",
                            message: error,
                            position: "topRight"
                        });
                    });
            } else {
                this.editIndex = -1;
                this.newTemplateType = { id: 0, templateType: "" };
            }
        }
    }
};

</script>
