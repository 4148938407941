import ListCandidates from '@/components/candidates/Listcandidates';
import Addcandidate from '@/components/candidates/Addcandidate';
import Editcandidate from '@/components/candidates/Editcandidate';
import Viewcandidate from '@/components/candidates/Viewcandidate';
import Scheduleinterview from '@/components/candidates/Scheduledinterviews';
import ListInterviewers from '@/components/candidates/ListInterviewers';
import Addinterviewer from '@/components/candidates/Addinterviewer';
import EditInterviewer from '@/components/candidates/EditInterviewer';
import ViewInterviewer from '@/components/candidates/Viewinterviewer';
import RecruitmentFeedback from '@/components/candidates/RecruitmentFeedback';
import CandidateLayout from '@/components/candidates/Layout';
import CandidateDashboard from '@/components/candidates/Dashboard';
import AddRecruiter from '@/components/candidates/AddRecruiter';
import AddTeamMember from '@/components/candidates/AddTeamMember';
import ListRecruiter from '@/components/candidates/ListRecruiter';
import RMDashboard from '@/components/candidates/RMDashboard';
import CorpDashboard from '@/components/candidates/CorpDashboard';
import listClientAccount from '@/components/candidates/clientAccount/listClientAccount';
import addClientAccount from '@/components/candidates/clientAccount/addClientAccount';
import editcorpClientAccount from '@/components/candidates/clientAccount/editClientAccount';
import viewcorpClientAccount from '@/components/candidates/clientAccount/viewClientAccount';
import BulkUpload from '@/components/candidates/BulkUpload';


export default [{
        path: '/candidates',
        name: 'listcandidates',
        component: ListCandidates,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/candidates/add',
        name: 'addcandidate',
        component: Addcandidate,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/candidates/edit/:candidateid',
        name: 'editcandidate',
        component: Editcandidate,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/candidates/view/:candidateid',
        name: 'viewcandidate',
        meta: { accessedby: 'recruiter' },
        component: Viewcandidate

    },
    {
        path: '/candidates/interview/:candidateid',
        name: 'scheduleinterview',
        meta: { accessedby: 'recruiter' },
        component: Scheduleinterview
    },
    {
        path: '/interviewers',
        name: 'interviewers',
        component: ListInterviewers,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/interviewers/add',
        name: 'addinterviewer',
        component: Addinterviewer,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/interviewers/edit/:interviewerid',
        name: 'editinterviewer',
        meta: { accessedby: 'recruiter' },
        component: EditInterviewer
    },
    {
        path: '/interviewers/view/:interviewerid',
        name: 'viewinterviewer',
        meta: { accessedby: 'recruiter' },
        component: ViewInterviewer
    },
    {
        path: '/candidates/recruitment/:recruitmentId',
        name: 'recruitmentfeedback',
        meta: { accessedby: 'recruiter' },
        component: RecruitmentFeedback
    },
    {
        path: '/candidates/layout',
        name: 'candidatelayout',
        meta: { accessedby: 'recruiter' },
        component: CandidateLayout
    },
    {
        path: '/candidates/dashboard',
        name: 'candidatesdashboard',
        meta: { accessedby: 'recruiter' },
        component: CandidateDashboard
    },
    {
        path: '/candidates/add/recruiter',
        name: 'addrecruiter',
        meta: { accessedby: 'recruiter' },
        component: AddRecruiter
    },
    {
        path: '/candidates/add/member',
        name: 'addteammember',
        meta: { accessedby: 'recruiter' },
        component: AddTeamMember
    },
    {
        path: '/candidates/list/recruiter',
        name: 'listrecruiter',
        meta: { accessedby: 'recruiter' },
        component: ListRecruiter
    },
    {
        path: '/candidates/recdashboard',
        name: 'rmdashboard',
        meta: { accessedby: 'recruiter' },
        component: RMDashboard
    },
    {
        path: '/bulkupload',
        name: 'bulkupload',
        component: BulkUpload,
        meta: { accessedby: 'recruiter' }
    },
    {
        path: '/candidates/corpdashboard',
        name: 'corpdashboard',
        meta: { accessedby: 'recruiter' },
        component: CorpDashboard
    },
    {
        path: '/candidates/clientaccount',
        name: 'recclientaccount',
        meta: { accessedby: 'recruiter' },
        component: listClientAccount
    },
    {
        path: '/candidates/corp/clientaccount/add',
        name: 'addcorpclientaccount',
        meta: { accessedby: 'recruiter' },
        component: addClientAccount
    },
    {
        path: '/candidates/corp/clientaccount/edit/:accountid',
        name: 'editcorpclientaccount',
        meta: { accessedby: 'recruiter' },
        component: editcorpClientAccount
    },
    {
        path: '/candidates/corp/clientaccount/view/:accountid',
        name: 'viewcorpclientaccount',
        meta: { accessedby: 'recruiter' },
        component: viewcorpClientAccount
    }

];
