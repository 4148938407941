<template>
    <div id="main" class="mt-70 d-height">
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded pos-rel">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :class="isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <form id="main-search" :class="isExpand?'d-block':'d-none'">
                        <div class="row form-group ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label> Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input class="form-control" type="text" v-on:keyup.enter="findCandidates" v-model="search.params.firstName" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input class="form-control" type="text" v-on:keyup.enter="findCandidates" v-model="search.params.email" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>City</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input class="form-control" type="text" v-on:keyup.enter="findCandidates" v-model="search.params.cityName" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Technology</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select class="form-control" v-model="search.params.technologyId">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in technologies" :value="info.id">{{info.technology}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Recruiter</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select name="recruiterId" id="recruiterName" v-model="search.params.recruiterId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(data, index) in recruiterlist" :value="data.id">{{data.recruiterName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;">Technology</label>
                                    </div>
                                    <div class="col-md-12">
                                        <button type="button" @click="resetCandidatesSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                        <button v-if="false" type="button" class="btn btn-primary btn-sm float-right advancesearch_btn shadow" style="margin-left: 10px;">Advanced Search</button>
                                        <button type="button" @click="findCandidates" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Find</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="advancesearch" v-if="elements.showadvancedsearch">
                            <div class="row form-group ten-columns">
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Recruiter</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <drop-box :options="search.fieldvalues.recruiters.values" @selected-value="updateSearchRecruiters"></drop-box>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Available(From)</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group date" data-date-format="mm-dd-yyyy">
                                                <input class="form-control datepicker" name="dateAvailableFrom" type="text" readonly />
                                                <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Available(To)</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group date" data-date-format="mm-dd-yyyy">
                                                <input class="form-control datepicker" name="dateAvailableTo" type="text" readonly />
                                                <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>State</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <select v-model="search.currentstate" class="form-control" id="currentState" name="currentState">
                                                <option v-for="(value, index) in search.fieldvalues.currentstate.values" :value="index">{{value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Experience</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="row">
                                                <div class="col-sm-6 p-r-5">
                                                    <select v-model="search.minexp" class="form-control" id="minexp" name="minexp">
                                                        <option v-for="(value, index) in search.fieldvalues.exp.values" :value="index">{{value}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-6 p-l-5">
                                                    <select v-model="search.maxexp" class="form-control" id="maxexp" name="maxexp">
                                                        <option v-for="(value, index) in search.fieldvalues.exp.values" :value="index">{{value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr p-r">
                                    <div class="row p-a">
                                        <div class="col-sm-6 col-6">
                                            <div class="form-check form-check-inline">
                                                <input type="checkbox" name="resume" value="on" id="resume" class="form-check-input">
                                                <label class="form-check-label" for="resume">Resume</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-6">
                                            <div class="form-check form-check-inline">
                                                <input type="checkbox" name="noResume" value="on" id="noResume" class="form-check-input">
                                                <label class="form-check-label" for="noResume">No Resume</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group ten-columns">
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Skill Set</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <drop-box :options="search.fieldvalues.skillset.values" @selected-value="updateSearchSkillset"></drop-box>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Radius</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input class="form-control" name="candidateRadius" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Region</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <select v-model="search.region" class="form-control" id="region" name="region">
                                                <option v-for="(value, index) in search.fieldvalues.region.values" :value="index">{{value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Zip Code</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input type="text" class="form-control" name="zipCode" maxlength="5" value="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Referral</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <select v-model="search.referal" class="form-control" id="referal" name="referal">
                                                <option v-for="(value, index) in search.fieldvalues.recruiters.values" :value="value.id">{{value.text}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr p-r">
                                    <div class="row p-a">
                                        <div class="col-sm-6 col-6">
                                            <div class="form-check form-check-inline">
                                                <input type="checkbox" name="interim" value="on" id="interim" class="form-check-input">
                                                <label class="form-check-label" for="interim">Part Time</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-6">
                                            <div class="form-check form-check-inline">
                                                <input type="checkbox" name="fulltime" value="on" id="fulltime" class="form-check-input">
                                                <label class="form-check-label" for="fulltime">Full Time</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group ten-columns">
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>GPM</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input class="form-control " id="GPM" name="GPM" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Country</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <select v-model="search.country" class="form-control" id="country" name="country">
                                                <option v-for="(value, index) in search.fieldvalues.country.values" :value="index">{{value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Type</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <select v-model="search.jobtype" class="form-control" id="jobtype" name="jobtype">
                                                <option v-for="(value, index) in search.fieldvalues.jobtype.values" :value="index">{{value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Industry</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <select v-model="search.industry" class="form-control" id="industry" name="industry">
                                                    <option v-for="(value, index) in search.fieldvalues.industry.values" :value="index">{{value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr p-r">
                                    <div class="row p-a">
                                        <div class="col-md-2 col-sm-2 col-12 lbl">
                                        </div>
                                        <div class="col-md-10 col-sm-10 col-12">
                                            <div class="form-check form-check-inline">
                                                <input type="checkbox" name="emailMarketing" value="on" id="emailMarketing" class="form-check-input">
                                                <label class="form-check-label" for="emailMarketing">Available for Email Marketing</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr p-r">
                                    <div class="row p-a">
                                        <div class="col-sm-6 col-6">
                                            <div class="form-check form-check-inline">
                                                <input type="checkbox" name="Notes" value="on" id="Notes" class="form-check-input">
                                                <label class="form-check-label" for="Notes">Notes</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-6">
                                            <div class="form-check form-check-inline">
                                                <input type="checkbox" name="noNotes" value="on" id="noNotes" class="form-check-input">
                                                <label class="form-check-label" for="noNotes">No-Notes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                        </div>
                    </form>
                    <div class="load-indicator"></div>
                </div>
            </div>
            <div class="container-fluid" id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-6 col-sm-12 col-12">
                            <h4 class="page-title">Candidates
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{browsepagination.totalrecords}} - Total)</span>
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                    <div class="dropdown">
                                        <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                        <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                            <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                        </div>
                                    </div>
                                </span>
                            </h4>
                        </div>
                        <div v-if="false" class="col-md-6 d-none d-sm-none d-md-block">
                            <ul class="ul-horizontal table-fields text-right">
                                <li>
                                    <span>Show/Hide</span>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="recruiter" id="recruiter" checked="">
                                    <label for="recruiter" v-on:click="coldisplay.recruiter = !coldisplay.recruiter"><span></span> Experience</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="specialityunit" id="specialityunit" checked="">
                                    <label for="specialityunit" v-on:click="coldisplay.technology = !coldisplay.technology"><span></span>Technology/Skills</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="currentcity" id="currentcity" checked="">
                                    <label for="currentcity" v-on:click="coldisplay.currentcity = !coldisplay.currentcity"><span></span> Current City</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="state" id="state" checked="">
                                    <label for="state" v-on:click="coldisplay.state = !coldisplay.state"><span></span> State</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="false" class="row" id="main-data-content" style="margin-bottom: 10px;">
                        <div class="col-md-5 d-none d-sm-none d-md-block">
                            <ul class="ul-horizontal filter-list icon-ul">
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Sample Tool tip"><img alt="images" src="@/assets/icons/sigma.png"></a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Create Mail"><img alt="images" src="@/assets/icons/folder.png"></a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Create SMS"><img alt="images" src="@/assets/icons/answer-email.png"></a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Applicants Resume"><img alt="images" src="@/assets/icons/envelope-with-pencil.png"></a></li>
                            </ul>
                        </div>
                        <div class="col-md-7 d-none d-sm-none d-md-block ">
                            <ul class="ul-horizontal filter-list float-right filter-right" style="margin-bottom: 10px;">
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="New Lead"><img alt="images" src="@/assets/icons/minus.png"> New Lead</a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Resume"><img alt="images" src="@/assets/icons/resume.png"> Resume</a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Do Not Lodge"><img alt="images" src="@/assets/icons/user2.png"> DNL</a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Need Management Approval"><img alt="images" src="@/assets/icons/user3.png"> NMA</a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Do Not Return"><img alt="images" src="@/assets/icons/user4.png"> DNR</a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Do Not Use"><img alt="images" src="@/assets/icons/user5.png"> DNU</a></li>
                                <li><a href="" data-toggle="tooltip" data-placement="top" title="Need HR Approval"><img alt="images" src="@/assets/icons/user6.png"> NHA</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th v-if="false" class="" scope="col">#</th>
                                            <th v-if="" class="" scope="col"></th>
                                            <th class="" scope="col">Cand Code</th>
                                            <th class="" scope="col">Name</th>
                                            <th class="" scope="col">Position</th>
                                            <th class="recruiter" scope="col">Experience</th>
                                            <th class="specialityunit" scope="col">Technology/Skills</th>
                                            <th class="currentcity" scope="col">Current City</th>
                                            <th class="state" scope="col">State</th>
                                            <th class="phone" scope="col">Phone</th>
                                            <th class="email" scope="col">Email</th>
                                            <th class="recruiter" scope="col">Recruiter</th>
                                            <th class="recruiter" scope="col">Company</th>
                                            <th class="" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="candidateslist.length==0 && elements.loadCandidates == true">
                                            <td colspan="14" align="center">No Candidates found</td>
                                        </tr>
                                        <template v-for="(candidateinfo, index) in candidateslist">
                                            <tr>
                                                <td>
                                                <a href="javascript:" class="expand" data-targetopen="panel1">
                                                    <span @click="getCandidateNotes(candidateinfo.id)"><i :class="(currentIndex==candidateinfo.id) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
                                                    </span>
                                                </a>
                                                </td>
                                                <td v-if="false">
                                                    <a href="Javascript:void(0);" class="expand" data-targetopen="panel1"><span class="ti-arrow-circle-down"></span></a>
                                                </td>
                                                <td v-if="false">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" id="rowchckbox1" value="option1">
                                                        <label class="form-check-label" for="rowchckbox1"></label>
                                                    </div>
                                                </td>
                                                <td>
                                                  <router-link title="View Candidate Profile" :to="{ name: 'adminviewcandidate', params: { candidateid: candidateinfo.id }}">{{ candidateinfo.candidateCode }}</router-link>
                                                </td>
                                                <td>{{ candidateinfo.candidateName }}</td>
                                                <td>{{ candidateinfo.position }}</td>
                                                <td v-if="coldisplay.recruiter" class="recruiter">{{ candidateinfo.experience }}</td>
                                                <td v-if="coldisplay.technology" class="specialityunit">{{ candidateinfo.technology }}</td>
                                                <td v-if="coldisplay.currentcity" class="currentcity">{{ candidateinfo.cityName }}</td>
                                                <td v-if="coldisplay.state" class="state">{{ candidateinfo.stateName }}</td>
                                                <td class="phone">{{ candidateinfo.mobile }}</td>
                                                <td class="email">{{ candidateinfo.email }}</td>
                                                <td class="recruiter">{{ candidateinfo.recruiterName }}</td>
                                                <td class="recruiter">{{ candidateinfo.companyName }}</td>
                                                <td>
                                                    <ul class="navbar-nav">
                                                        <li class="nav-item dropdown">
                                                            <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                                <li>
                                                                    <router-link class="dropdown-item" :to="{ name: 'adminviewcandidate', params: { candidateid: candidateinfo.id }}"><span class="ti-eye pr-2"></span> View</router-link>
                                                                </li>
                                                                <li v-if="false">
                                                                    <router-link class="dropdown-item" :to="{ name: 'admineditcandidate', params: { candidateid: candidateinfo.id }}"><span class="ti-pencil pr-2"></span> Edit</router-link>
                                                                </li>
                                                                <li v-if="false">
                                                                    <a href="javascript:" @click="scheduleInterview(candidateinfo.id)" class="dropdown-item"><span class="ti-calendar pr-2"></span> Schedule Interview</a>
                                                                </li>
                                                                <li>
                                                                    <a class="dropdown-item" href="javascript:" @click="deleteCandidate(candidateinfo.id)" data-toggle="modal" data-target="#removeCandidate"><span class="ti-close pr-2"></span> Deactivate</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </td>
                                                
                                            </tr>

                                            <tr v-if="currentIndex == candidateinfo.id">
                                                <td colspan="15">
                                                    <div class="table-responsive">
                                                        <table class="table table-borderless all-table">
                                                            <thead>
                                                                <tr>
                                                                    <th class="" scope="col">Title</th>
                                                                    <th class="" scope="col">Note</th>
                                                                    <th class="" scope="col">Added On</th>    
                                                                    <th class="" scope="col">Added By</th>
                                                                    <th class="" scope="col">Action</th>
                                                                    
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="info in visibleNotes" :key="info.id" :id="info.id">
                                                                <td>{{ info.title }}</td>
                                                                <td>{{ info.description }}</td>
                                                                <td>{{ info.createdUtc | formatDate }}</td>
                                                                <td>{{ info.addedByName }}</td>
                                                                
                                                                <td>  
                                                                <a v-if="info.typeId==5 && candidateCreatedId==info.addedBy" href="javascript:;" @click="deleteNote(info.id,info.referenceId)" data-toggle="modal" data-target="#removeJobNotes"><span class="ti-cut"></span></a>                                     
                                                                </td>  
                                                                
                                                                </tr>
                                                                
                                                                <td colspan="14" >
                                                                <div style="margin-left:900px">
                                                                <a v-if="candidateNotesList.length>3" href="javascript:;" style="align: 'center'," data-toggle="modal" data-target="#RecruitmentNotes" ><i ></i >Load more</a>
                                                                </div>
                                                                </td>                                                        
                                                                    <tr  v-if="candidateNotesList.length==0">
                                                                        <td style="border:0px;" colspan="14" align="center">No Notes Found
                                                                        </td>
                                                                    </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                        
                                                </td>
                                            </tr>

                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-12">                        
                            <b-pagination v-if="candidateslist.length!=0" size="md" class='pull-right' :current-page="browsepagination.currentpage" :total-rows="browsepagination.totalrecords" v-model="browsepagination.currentpage" :per-page="browsepagination.rowsPerPage" @input="loadCandidates()"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div>
                    <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
                </div>                   
            </template> 
        </b-overlay>
        <div>
                    <b-modal ref="my-modals" id="RecruitmentNotes"  hide-footer title="Notes" size="lg">
                        <div  class="modal-body">
                            <table class="table table-borderless all-table">
                                <thead>
                                    <tr>
                                        <th class="" scope="col">Title</th>
                                        <th class="" scope="col">Note</th>
                                        <th class="" scope="col">Added On</th>
                                        <th class="" scope="col">Added By</th>
                                        <th class="" scope="col">Action</th>                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="info in candidateNotesList">
                                        <td>{{ info.title }}</td>
                                        <td>{{ info.description }}</td>
                                        <td>{{ info.createdUtc | formatDate }}  {{ info.createdUtc | formatTime }}</td>
                                        <td>{{ info.addedByName }}</td>
                                        <td>                            
                                            <a v-if="info.typeId==5 && candidateCreatedId==info.addedBy" href="javascript:;" @click="deletePopupNote(info.id,info.referenceId)" data-toggle="modal"><i class="ti-cut"></i></a>                                           
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                @click="hideModal"
                                data-dismiss="modal">
                                Close
                            </button>
                        </div> -->
                    </b-modal>
                </div>
        <Dialog id="removeCandidate" :onYes="delete_Candidate" :returnParams="delCandidateParam" title="Deactivate Confirmation" message="Are you sure to deactivate ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { mapState } from 'vuex';
export default {
    data() {
        return {
            showOverlay:false,
            currentIndex: null,
            isExpand: false,
            candidateNotesList: [],
            candidateslist: [],
            browsepagination: {
                totalrecords: 0,
                rowsPerPage: 10,
                currentpage: 1
            },
            technologies: this.$store.getters.getTechOptions,
            recruiterlist: this.$store.getters.getRecruiters,
            searchlimit: this.$store.state.searchlimit,
            elements: {
                showadvancedsearch: false,
                loadCandidates: false,
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    cityName: "",
                    technologyId: "",
                    recruiterId: ""
                },
                recruiters: [],
                skillset: [],
                currentstate: '',
                minexp: '',
                maxexp: '',
                region: '',
                referal: '',
                country: '',
                jobtype: '',
                industry: ''
            },
            coldisplay: {
                recruiter: true,
                title: true,
                technology: true,
                currentcity: true,
                state: true
            },
            delCandidateParam: {
                index: -1,
                id: 0
            },
        }
    },
    computed: {
          mapState(){
                'globalSearchValue'
          },
          visibleNotes() {
            return this.candidateNotesList.slice(0, this.notesVisible)
        },
         
    },
    // computed: mapState(['globalSearchValue']),
    watch: {
        globalSearchValue(newValue, oldValue) {
            this.search.params.firstName = newValue;
            this.loadCandidates();
        },
        'showOverlay': {
            handler: function(value) { 
                if(value == true){
                    $('#globalapploader').css('visibility','hidden');
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.loadCandidates();
    },
    methods: {
        getCandidateNotes(id){
           
            if (this.currentIndex == id) {
                this.currentIndex = null;
            } else {
                this.currentIndex = id;
                this.selectedInterviewId = id;
            }
            axios.get(this.$store.getters.getAPIBasePath + '/notesresource/get/notes/' + id)
                .then(response => {
                    let result = response.data.data;
                    this.candidateNotesList = result;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        resetCandidatesSearch() {
            this.search.params = {
                firstName: "",
                lastName: "",
                email: "",
                cityName: "",
                technologyId: "",
                recruiterId: ""
            };
            this.loadCandidates();
        },
        findCandidates() {
            this.browsepagination.currentpage = 1;
            this.loadCandidates();
        },
        async loadCandidates() {
            this.showOverlay=true;
            await this.getCandidates();
            this.showOverlay=false;
        },
        getCandidates() {
            this.showOverlay=true;
            return axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/candidatebyrecruiter?page=' + this.browsepagination.currentpage + '&size=' + this.browsepagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.candidateslist = result.candidates;
                    this.browsepagination.totalrecords = result.pagination[0].total;
                    this.browsepagination.currentpage = result.pagination[0].currentPage;
                    this.browsepagination.totalpages = result.pagination[0].lastPage;
                    this.elements.loadCandidates = true;
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });
        },
        updateSearchRecruiters: function(value) {
            this.search.recruiters = value;
            console.log(this.search.recruiters);
        },
        updateSearchSkillset: function(value) {
            this.search.skillset = value;
            console.log(this.search.skillset);
        },
        changeLimit(newlimit) {
            this.browsepagination.currentpage = 1;
            this.browsepagination.rowsPerPage = newlimit;
            this.loadCandidates(); 
        },
        deleteCandidate(candidateId) {
            //this.delTechSkillParam.index=arg1;
            this.delCandidateParam.id = candidateId;
            return;
            if (confirm("Do you really want to deactivate the candidate?")) {
                var params = new URLSearchParams();
                params.append('id', candidateId)
                axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/delete/candidate', params)
                    .then(response => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.loadCandidates();
                        }
                    })
                    .catch(error => {
                        console.log(error.data);
                    });
            }
        },
        delete_Candidate() {
            var params = new URLSearchParams();
            var candidateId = this.delCandidateParam.id;
            params.append('id', candidateId)
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/delete/candidate', params)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadCandidates();
                    }
                })
                .catch(error => {
                    console.log(error.data);
                });
        },
        scheduleInterview(candidateId) {
            localStorage.setItem('recPlanId', null);
            localStorage.setItem('jobId', null);
            localStorage.setItem('recruitmentId', null);
            this.$router.push('/candidates/interview/' + candidateId);
        }
    }
}

</script>
