import ScheduledInterviews from '@/components/candidates/Scheduledinterviews';
import ProgressInterviews from '@/components/candidates/Interviews';
import CompletedInterviews from '@/components/candidates/Completedinterviews';

export default [
    {
		path: '/scheduled-interviews',
		name: 'scheduledinterviews',
		component: ScheduledInterviews,
		meta:{accessedby:'recruiter'}
	},
	{
		path: '/progress-interviews',
		name: 'progressinterviews',
		component: ProgressInterviews,
		meta:{accessedby:'recruiter'}
	},
	{
		path: '/completed-interviews',
		name: 'completedinterviews',
		component: CompletedInterviews,
		meta:{accessedby:'recruiter'}
	}
];