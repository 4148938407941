<template>
    <div id="main" class="mt-70 interviewer-feedback">
        <div class="container-fluid" id="main-data">
            <div class="row">
                <div class="col-md-12">
                    <div class="box-wrapper shadow-sm p-4  bg-white rounded pos-rel h-auto">
                        <div class="key_skills mb-3">
                            <div class="widget-wrapper">
                                <div class="widget-content">
                                    <div class="header card">
                                        <span class="title">Interview Details</span>                                        
                                        <div style="clear:both"></div>
                                    </div>
                                    <div class="inner-content card">
                                        <div class="row data-cell">
                                            <span class="data-output-label col-md-3">Title</span>
                                            <span class="data-output-value col-md-3">{{candidateInfo.title}}</span>
                                            <span class="data-output-label col-md-3">Date & Time</span>
                                            <div class="row col-md-3 data-output-value ml-0">
                                                <span class="data-output-value">{{toClientTimeZoneDate(candidateInfo.interviewStartTime, dateformat) }}</span>
                                                <span class="data-output-value"> {{toClientTimeZoneDate(candidateInfo.interviewStartTime, timeformat)}} to {{toClientTimeZoneDate(candidateInfo.interviewEndTime, timeformat)}}</span>
                                            </div>
                                            <div style="clear:both"></div>
                                        </div>
                                        <div class="row data-cell">
                                            <span class="data-output-label col-md-3">Interview Type</span>
                                            <span class="data-output-value col-md-3">{{candidateInfo.interviewTypeName}}</span>
                                            <span class="data-output-label col-md-3">Status</span>
                                            <span class="data-output-value col-md-3">{{candidateInfo.statusName}}</span>
                                            <div style="clear:both"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div v-if="!isAnswerFound" class="box-wrapper shadow-sm p-4 mtb-4 bg-white rounded pos-rel">
                        <div class="candidate_info">
                            <h4 class="page-title p-3 d-inline-block text-white candidate-title">CANDIDATE INFO</h4>
                            <a href="javascript:" @click="getPrevUrl()" class="btn btn-primary pull-right" style="margin-top: 5px;margin-right: 10px;">Back</a>
                            <hr>
                            <div class="row">
                                <div class="col-md-5 text-left"><span class="pr-2"><i class="ti-user"></i></span>{{candidateInfo.candidateName}}</div>
                                <div class="col-md-5 text-left">{{candidateInfo.position}}</div>
                                <div class="col-md-2 text-left" v-if="candidateInfo.experience">{{candidateInfo.experience}} yrs</div>
                            </div>
                            <div v-if="candContactAccess==1" class="row">
                                <div class="col-md-6 text-left"><span class="pr-2"><i class="ti-email"></i></span>{{candidateInfo.email}}</div>
                                <div class="col-md-6 text-right"><span class="pr-2"><i class="ti-mobile"></i></span>{{candidateInfo.mobile}}</div>
                            </div>
                        </div>
                        <div class="key_skills">
                        
                            <h4 class="page-title mt-3">Specialization</h4>
                            <hr>
                            <div class="inner-content min-scroll">                           
                            <span v-if="candidateInfo.candidateSkills==null || candidateInfo.candidateSkills.length==0" class="p-2 mb-1">No skills found</span>
                            <span v-for="(Skill, index) in candidateInfo.candidateSkills" v-bind:key="index" class="badge p-2 mb-1 badge-primary ">
                                <span>{{Skill.skillName}}</span><span v-if="Skill.years"> - {{Skill.years}}</span>
                            </span>
                            </div>
                        </div>
                        <div v-if="candContactAccess==1" class="key_skills">
                            <h4 class="page-title mt-3">Resume</h4>
                            <hr>  
                                <div class="data-cell">                         
                            <p><a v-bind:href="candidateInfo.filePath" @click="addCandidateDownload" :title="candidateInfo.resume_filename" v-html="getResumeSubstring(candidateInfo.resume_filename)">
                            <i class="ti-files"></i>{{candidateInfo.resume_filename}}</a></p>
                        </div>
                        
                        </div>
                        <div class="key_skills">                            
                            <h4 class="page-title mt-3">Overall Rating</h4>
                            <hr>
                            <p>
                                <fieldset class="rating">
                                    <span v-for="n in candidateInfo.rating" :key="n" class="rating-star mt-1">&#9733;</span>                                   
                                </fieldset>
                            </p>
                            <div class="clearfix"></div>
                        </div>
                        <div v-if="candidateInfo.interviewRequirement!= null && candidateInfo.interviewRequirement.length>0"  class="key_skills pt-5">
                            <div class="header">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6 col-6">
                                        <span class="title">Interview Requirement</span>
                                        <div style="clear:both"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="inner-content min-scroll">
                                <div class="data-cell">
                                    <span class="data-output-label1">{{candidateInfo.interviewRequirement}}</span>
                                    <div style="clear:both"></div>
                                </div>
                                <div style="clear:both"></div>
                            </div>
                        </div>
                        <div class="key_skills">
                            <h4 class="page-title mt-50">Interviewer Notes</h4>
                            <hr>
                            <p>{{candidateInfo.interviewerNotes}}</p>
                        </div>
                    </div>
                    <div v-if="isAnswerFound" class="box-wrapper shadow-sm p-4 mtb-4 bg-white rounded pos-rel">
                        <div class="candidate_info">
                            <h4 class="page-title p-3 d-inline-block text-white candidate-title">QUESTION / ANSWER</h4>
                            <a href="javascript:" @click="isAnswerFound=false;" class="btn btn-primary pull-right" style="margin-top: 5px;margin-right: 10px;">Close</a>
                            <hr>
                            <div v-if="interviewQuestion.question != null" class="row">
                                <div class="col-md-12 text-left font-weight-bold"><h3>{{interviewQuestion.question}}</h3></div>
                            </div>                            
                            <div v-if="interviewQuestion.answer != null" class="row">
                                <div class="col-md-12 text-left"><span class="pr-2"><i class="ti-pencil-alt"></i></span>{{interviewQuestion.answer}}</div>
                            </div>
                            <div v-for="(reference, index) in interviewQuestion.questionReference" class="row">
                                <span class="pl-3"><i class="ti-link"></i><a :href="reference.referenceLink" target="_blank" class="col-md-6 text-left pl-2">{{reference.referenceLink}}</a></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="box-wrapper shadow-sm mtb-4 bg-white rounded pos-rel p-3">
                        <!-- <div class="row" style="margin-bottom: 15px;">
                                <div class="col-md-6 col-sm-12 col-12">
                                     <h4 class="page-title">Interviewer Interviews</h4>
                                </div>
                             </div> -->
                        <!-- <div id='calendar'></div> -->
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="modal-content border border-0">
                                    <div class="modal-header col-md-12 col-sm-12 col-12 pl-0 pr-0">
                                        <h4 class="text-center page-title w-100 p-3 d-inline-block text-white feedback-title">FEEDBACK<i v-if="edit_mode==false" @click="changeMode()" class="ti-pencil text-right display-only edit-pencil" style="margin-left: 90%" aria-hidden="true"></i><i v-if="edit_mode" @click="changeMode" class="ti-eye text-right display-only view-eye" aria-hidden="true" style="margin-left: 90%"></i></h4>
                                    </div>
                                    <div role="tablist" class="interview-feedback-accordion">
                                        <b-card no-body class="mb-1" v-for="(Category,index) in candidateInfo.categorySkillList">
                                            <b-card-header v-if="Category.categoryName != null" header-tag="header" class="p-1" role="tab">
                                                <b-button block href="javascript:" v-b-toggle="'accordion-'+index+'-'+Category.id" variant="info" class="text-left">{{Category.categoryName}}</b-button>
                                            </b-card-header>
                                            <b-collapse v-if="Category.id != null" :id="'accordion-'+index+'-'+Category.id" visible accordion="my-accordion" role="tabpanel" class="accordion-content">
                                                <b-card-body>
                                                    <div v-for="(Item,idx) in Category.technologyList" v-bind:key="idx" class="row m-0 pt-1 pb-1 border-1-bottom">
                                                        <div class="col-sm-3 pos-relative">
                                                            <span class="pt-8">{{Item.technology}}</span>
                                                        </div>
                                                        <div class="col-sm-3 pos-relative">
                                                            <fieldset v-if="edit_mode" class="rating pt-8">
                                                                <input :id="'rate_'+index+'_'+idx+'-star5'" type="radio" value="5" v-model="Item.rating" :disabled="edit_mode?false:true" />
                                                                <label :for="'rate_'+index+'_'+idx+'-star5'" title="Excellent">5</label>
                                                                <input :id="'rate_'+index+'_'+idx+'-star4'" type="radio" value="4" v-model="Item.rating" :disabled="edit_mode?false:true" />
                                                                <label :for="'rate_'+index+'_'+idx+'-star4'" title="Good">4</label>
                                                                <input :id="'rate_'+index+'_'+idx+'-star3'" type="radio" value="3" v-model="Item.rating" :disabled="edit_mode?false:true" />
                                                                <label :for="'rate_'+index+'_'+idx+'-star3'" title="Satisfactory">3</label>
                                                                <input :id="'rate_'+index+'_'+idx+'-star2'" type="radio" value="2" v-model="Item.rating" :disabled="edit_mode?false:true" />
                                                                <label :for="'rate_'+index+'_'+idx+'-star2'" title="Bad">2</label>
                                                                <input :id="'rate_'+index+'_'+idx+'-star1'" type="radio" value="1" v-model="Item.rating" :disabled="edit_mode?false:true" />
                                                                <label :for="'rate_'+index+'_'+idx+'-star1'" title="Very bad">1</label>
                                                                <a class="pr-2" @click="Item.rating = 0;" title="Reset Rating"><i class="fa fa-refresh text-danger" aria-hidden="true"></i></a>
                                                            </fieldset>
                                                            <fieldset v-if="edit_mode==false" class="rating pt-8">
                                                                <span v-for="n in Item.rating" :key="n" class="rating-star mt-1">&#9733;</span>
                                                            </fieldset>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <input type="text" v-model="Item.comment" class="form-control status" :disabled="edit_mode?false:true">
                                                            <a href="javascript:;" @click="showBoxComment(index)" data-toggle="modal" data-target="#FeedbackModal">
                                                                <span class="search-addon pr-4"><span class="fa fa-ellipsis-h"></span> </span>
                                                            </a>
                                                        </div>
                                                        <div v-if="Category.categoryName == 'Questions' && Item.questionId != null" class="col-sm-2">
                                                            <button type="button" @click="loadInterviewQuestion(Item.questionId)" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Ans Ref</button>
                                                        </div>
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                    <div class="card">
                                        <div class="card-header p-2">Overall Comments</div>
                                        <div class="card-body p-2">
                                            <div class="basic-block">
                                                <div class="row ten-columns">
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                                <b-card-text v-if="edit_mode==false">{{candidateInfo.overallComments}}</b-card-text>
                                                                <b-form-textarea v-if="edit_mode" class="data-output-input1" v-model="candidateInfo.overallComments" rows="3" max-rows="6" name="overallComments"></b-form-textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="javascript:" @click="saveFeedback()" class="btn btn-primary pull-right" style="margin-top: 5px;margin-right: 10px;">Save</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="FeedbackModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Comment</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <textarea class="form-control more-text-area" v-model="tempComment"></textarea>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="setComment()" class="btn btn-primary" data-dismiss="modal">OK</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    data() {
        return {
            dateformat:this.$store.state.dateformat,
            timeformat:this.$store.state.timeformat,
            candidateInfo: {
                candidateName: "",
                candidateNotes: "",
                interviewerNotes: "",
                candidateSkills: [],
                categorySkillList: [],
                email: "",
                filePath: "",
                mobile: "",
                rating: 0,
                resume_filename: "",
                overallComments: ""
            },
            tempComment: "",
            tempSkillId: -1,
            otherInfo: {},
            params: {
                interviewId: localStorage.getItem('feedbackInterviewId'),
                candidateId: localStorage.getItem('feedbackCandidateId'),
                // rpInterviewId:localStorage.getItem('feedbackRpInterviewId'),
                interviewerId: parseInt(this.$store.getters.currentUser.profileId)
            },
            edit_mode: false,
            saveData: [],
            feedbackData: {
                interviewersFeedbackData: []
            },
            ratingScore: [],
            isAnswerFound: false,
            interviewQuestion: {
                answer: "",
                questionReference: ""
            },
            candContactAccess: localStorage.getItem('candidateContactAccess')
        }
    },
    computed: {
        average: {
            get: function() {
                return this.candidateInfo.rating
            },
            set: function(newValue) {
                if (newValue.length) {
                    this.candidateInfo.rating = 0
                    let self = this
                    $.each(newValue, function(key, value) {
                        self.candidateInfo.rating = self.candidateInfo.rating + value
                    })
                    this.candidateInfo.rating = parseInt(this.candidateInfo.rating / newValue.length)
                }
            }
        }
    },
    mounted() {
        this.loadFeedbackDetails();
        this.otherInfo = {
            resume_filename: "resume.docx",
            resume_link: "#",
            notes: 'Test the dot net skills',
            overallRating: 3
        }
    },
    methods: {
        showBoxComment: function(arg) {
            this.tempSkillId = arg;
            this.tempComment = this.candidateInfo.categorySkillList[arg].technologyList[arg].comment;
        },
        setComment: function() {
            this.candidateInfo.categorySkillList[this.tempSkillId].technologyList[this.tempSkillId].comment = this.tempComment;
        },
        getCommentSubstring(comment) {
            if (comment != null) {
                return comment.substring(0, 5);
            }
        },
        getResumeSubstring(resume_filename) {
            if (resume_filename != null && resume_filename.length > 10) {
                return resume_filename.substring(0,20) + "...";
            } else 
            return resume_filename;
        },
        loadFeedbackDetails() {
            axios.post(this.$store.getters.getAPIBasePath + '/interviewresource/view/interview/feedback', this.params).then(response => {
                    if (response.data.status) {                        
                        this.candidateInfo = response.data.candidateInfo;
                        this.edit_mode = (this.candidateInfo.dataFlag == "false");
                        this.candidateInfo.resume_filename = (this.candidateInfo.filePath) ? this.candidateInfo.filePath.substring(this.candidateInfo.filePath.lastIndexOf('/') + 1) : "";
                        this.candidateInfo.rating = this.candidateInfo.rating ? this.candidateInfo.rating : 0;
                    } else {
                        let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.LoadingErr;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                    }
                })
                .catch(error => {
                    let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.LoadingErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });

        },
        getPrevUrl() {
            this.$router.go(-1);
        },
        changeMode() {
            this.edit_mode = !this.edit_mode;
        },
        saveFeedback() {
            this.prepareData()
            new Promise((resolve, reject) => {
                axios.post(this.$store.getters.getAPIBasePath + '/interviewresource/update/interviewer/' + this.params.interviewId + '/feedback', this.feedbackData).then(response => {
                        if (response.data.status == "ACCEPTED") {
                            let successmsg = (response.data.message != undefined) ? response.data.message : this.$config.FeedBckSuc;
                            iziToast.success({ title: 'Success', message: successmsg, position: 'topRight' });
                            this.loadFeedbackDetails();
                            resolve(true);
                        } else {
                            let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.ProcessErr;
                            iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.ProcessErr;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                    });
            });
        },
        prepareData() {
            this.saveData = [];
            let self = this;
            $.each(this.candidateInfo.categorySkillList, function(index, value) {
                $.each(value.technologyList, function(key, technology) {
                    self.saveData.push({
                        "interviewerId": self.$store.getters.currentUser.profileId,
                        "skillId": value.categoryName == "Questions" ? 0 : technology.id,
                        "comment": technology.comment,
                        "score": technology.rating ? parseInt(technology.rating) : 0,
                        "isPrimary": technology.isPrimary ? 1 : 0,
                        "question": value.categoryName == "Questions" ? technology.technology : ""
                    });
                });
            });
            this.calcOverallRating();
            this.feedbackData.interviewersFeedbackData = this.saveData;
            this.feedbackData.overallComments = this.candidateInfo.overallComments;
            this.feedbackData.overallRating = this.candidateInfo.rating;
        },
        calcOverallRating() {
            this.ratingScore = [];
            let self = this;
            $.each(this.saveData, function(key, value) {
                if (value.score)
                    self.ratingScore.push(value.score);
            });
            this.average = this.ratingScore;
        },
        loadInterviewQuestion(questionId) {            
            this.isAnswerFound = true;
            axios.post(this.$store.getters.getAPIBasePath + '/interviewresource/view/interview/question/' + questionId).then(response => {
                    if (response.data.status) {
                        let questionData = response.data.interviewQuestion;
                        this.interviewQuestion.question = questionData.question;
                        this.interviewQuestion.answer = questionData.answer;
                        this.interviewQuestion.questionReference = response.data.questionReference;
                    } else {
                        iziToast.error({ title: 'Error', message: this.$config.NoAnsAvailErr, position: 'topRight' });
                    }
                })
                .catch(error => {
                    iziToast.error({ title: 'Error', message: this.$config.LoadingErr, position: 'topRight' });
                });

        },
        addCandidateDownload() {           
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/add/candidate/download', { candidateId: this.candidateInfo.candidateId })
                .then(response => {
                    let result = response.data;
                    // iziToast.success({
                    //     title: 'Success',
                    //     message: result.message,
                    //     position: 'topRight'
                    // });
                })
                .catch(error => {
                    console.log(error);
                    this.unAuthorized(error);
                });
        }
    }
}

</script>
