<template>
    <div class="admin-blocks add-recruiter mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="card-header">
                    <div class="row pl-2 ten-columns">
                        <div class="col-md-12">
                            Add Team Member<a href="javascript:(0);" @click="$router.go(-1)" class="pull-right btn-back text-blue"><i class="ti-arrow-left"></i> Back</a>
                        </div>
                        <div v-if="false" class="col-md-6 text-right">
                            {{teamMemberInfo.recruiterCode}}
                        </div>
                    </div>
                </div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div class="row ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>First Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group required-field-block">
                                            <input type="text" name="firstName" v-model="teamMemberInfo.firstName" maxlength="45" size="30" class="form-control" />
                                            <span class="required-icon"></span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <label v-if="$v.teamMemberInfo.firstName.$error && !$v.teamMemberInfo.firstName.required" class="text-danger">First Name is required</label>
                                        <label v-if="$v.teamMemberInfo.firstName.$error && !$v.teamMemberInfo.firstName.minLength" class="text-danger">First Name must've minimum 2 characters</label>
                                        <label v-if="$v.teamMemberInfo.firstName.$error && !$v.teamMemberInfo.firstName.maxLength" class="text-danger">Maximum limit is 30 characters</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Last Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group ">
                                            <input type="text" name="lastName" v-model="teamMemberInfo.lastName" maxlength="45" size="30" class="form-control" />
                                            <span class="required-icon"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group required-field-block">
                                            <input type="email" name="email" v-model="teamMemberInfo.email" maxlength="45" size="30" class="form-control"  />
                                            <span class="required-icon"></span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <label v-if="$v.teamMemberInfo.email.$error && !$v.teamMemberInfo.email.required" class="text-danger">Email is required</label>
                                        <label v-if="$v.teamMemberInfo.email.$error && !$v.teamMemberInfo.email.email" class="text-danger">Invalid Email</label>
                                        <label v-if="emailexists" class="text-danger">Email already exists</label>
                                        <!-- <label v-if="$v.teamMemberInfo.email.$error && $v.teamMemberInfo.email.email && !$v.teamMemberInfo.email.emailexists" class="text-danger">Email already exists</label> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Phone No.</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <PhoneNumber ref="phoneNumber" :value="teamMemberInfo.mobileNo" :isValidcheck="isValidCheck" @phone-input="phoneControlInput"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Country</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select class="form-control" name="country" v-model="teamMemberInfo.countryId">
                                            <option v-for="Country in Countries" v-bind:key="Country.country_Id" :value="Country.country_Id">{{Country.name}}</option>
                                            <!-- <option v-for="State in USStates" v-bind:key="State.id" :value="State.id">{{State.stateName}}</option> -->
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Time Zone</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <Select2 v-model="teamMemberInfo.timezone" :options="timeZones" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Role</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <input type="text" name="role" v-model="teamMemberInfo.role" maxlength="45" size="30" class="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-header p-2"><a href="javascript:" @mousedown="saveMember" class="btn btn-primary float-right">Invite</a></div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import Select2 from 'v-select2-component';
import iziToast from 'izitoast';
const usPhone = helpers.regex('usPhone', /^\(\d{3}\)\s\d{3}-\d{4}$/);

export default {
    components: {
        Select2
    },
    data() {
        return {
            elements: {
                submitted: false
            },
            emailexists: false,
            isValidCheck:false,
            Countries: this.$store.getters.getCountries,
            USStates: this.$store.getters.getUSStates,
            timeZones: this.$store.getters.getTimeZones,
            accountType: this.$store.getters.getAccountTypeOptions,
            teamMemberInfo: {
                recruiterCode: "",
                firstName: "",
                lastName: "",
                accountName: "",
                companyName: "",
                accountType: "2",
                email: "",
                mobileNo: "",
                zipcode: "",
                role: "",
                countryId: "US",
                timezone: "",
                countryCode:"+1",
                type:3 //member type
            }
        }
    },
    validations: {
        teamMemberInfo: {
            firstName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            email: {
                required,
                email,
           
            }
        }
    },
    mounted() {
        this.getRecruiterCode();
    },
    methods: {
        emailValidation(email) {
            return axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/checkemailavailability', { email: email })
                .then(response => {
                    if (response.data.status) {
                        return true;
                    }
                    return false;
                })
                .catch(error => {
                    return false;
                });
        },
        getRecruiterCode() {
            axios.post(this.$store.getters.getAPIBasePath + '/commonresource/autoincrementcode/recruiter')
                .then(response => {
                    if (response.data.status) {
                        this.teamMemberInfo.recruiterCode = response.data.code;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        phoneControlInput: function (value,isValid) {
             this.teamMemberInfo.mobileNo = value;
             this.isValidCheck = isValid;
        },
        async saveMember() {
            this.elements.submitted = true;
            this.emailexists = false;
            this.$v.teamMemberInfo.$touch();
            if(!this.isValidCheck){
            if (!this.$v.teamMemberInfo.$invalid) {

                let isexists = await this.emailValidation(this.teamMemberInfo.email);

                if (!isexists) {
                    this.emailexists = true;
                    iziToast.info({ message: EmailExistErr, position: 'topRight' });
                    return;
                }
                axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/add/recruiter', this.teamMemberInfo)
                    .then(response => {
                        if (response.data.status == "ACCEPTED") {
                            iziToast.success({ title: 'Success', message: this.$config.MemAddSuc, position: 'topRight' });
                            this.$router.push('/candidates/list/recruiter');
                        } else {
                            iziToast.error({ title: 'Error', message: this.$config.Updateerr, position: 'topRight' });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        iziToast.error({ title: 'Error', message: this.$config.Updateerr, position: 'topRight' });
                    });
                this.elements.submitted = false;
            }
            }

        },
        addLogActivity: function() {
            this.updateLogActivity();
            this.logActivityData = {
                entityName: 'Recruitment Manager',
                actionName: 'New Recruitment Manager',
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {
                    localStorage.setItem('candIdForActivity', response.data.id);
                })
                .catch(error => {

                });
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
    }
}

</script>
