<template>
    <div class="">
        <div class="card">
            <div class="card-header p-2">Edit Plan</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Plan</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <select class="form-control" name="plan" @change="updateCount(ProfileData.planId)" v-model="ProfileData.planId">
                                        <option v-for="plan in planList" v-bind:key="plan.planId" :value="plan.planId" :disabled="plan.servicePlan=='Trial'">{{plan.servicePlan}}</option>                                    </select>
                                </div>
                            </div>
                        </div>
                        <div v-if="false" class="col-md-3 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Remaining View Resume Count</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="number" name="viewCount" v-model="ProfileData.viewResumeCount" maxlength="45" size="30" class="form-control" @keypress="onlyNumber" :disabled="disableviewCount"/>
                                    </div>
                                </div>     
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Remaining Download Resume Count</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="number" name="downloadCount" v-model="ProfileData.downloadResumeCount" maxlength="45" size="30" class="form-control" @keypress="onlyNumber" :disabled="disabledownloadCount"/>
                                    </div>
                                </div>     
                            </div>
                        </div>
                        <div v-if="false" class="col-md-3 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Remaining Share Resume count</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="number" name="shareCount" v-model="ProfileData.shareResumeCount" maxlength="45" size="30" class="form-control" @keypress="onlyNumber" :disabled="disableshareCount"/>
                                    </div>
                                </div>     
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="card-header p-2"><a href="javascript:" @click="saveUser" class="btn btn-primary  float-right">Save</a></div>
        </div>
    </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import Select2 from 'v-select2-component';
import iziToast from 'izitoast';
import moment from 'moment';
const usPhone = helpers.regex('usPhone', /^\(\d{3}\)\s\d{3}-\d{4}$/);

export default {
    components: {
        Select2
    },
    props: {
        ProfileData: Object,
    },
    data() {
        return {
            profileId: this.$store.getters.currentUser.profileId,
            elements: {
                submitted: false
            },
            accountType: this.$store.getters.getAccountTypeOptions,
            candContactAccess: this.$store.getters.getCandContactAccess,
            planList: [],
            disableviewCount: false,
            disabledownloadCount: false,
            disableshareCount: false,
            currentDate:"",
            expDate:"",
        }
    },
    
    mounted() {
        this.loadGuestById();
        this.loadServicePlan();
         
    },
    methods: {
        onlyNumber($event) {
            //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
       showDate()
                {  
                    var currentdate = moment().format('DD MMMM YYYY');
                    const dateIsBefore = moment(this.ProfileData.expiryDate).isBefore(moment().format('DD MMMM YYYY'));
                    this.expDate = dateIsBefore
                },
        updateCount(id){
            if(id == 1){
                this.ProfileData.downloadResumeCount = '50'
            }else if(id == 2){
                this.ProfileData.downloadResumeCount = '100'
            }else if(id == 3){
                this.ProfileData.downloadResumeCount = '200'
            }else if(id == 4){
                this.ProfileData.downloadResumeCount = '300'
            }
        },
        loadGuestById() {
            axios.get(this.$store.getters.getAPIBasePath + '/guestresource/view/guest/' + this.profileId)
                .then(response => {
                    let result = response.data;
                    if (!result.status) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    if (result.guest) {
                        this.ProfileData = {
                            viewResumeCount: (result.guest.viewResumeCount == 0 ? '' : result.guest.viewResumeCount),
                            downloadResumeCount: (result.guest.downloadResumeCount == 0 ? '' : result.guest.downloadResumeCount),
                            shareResumeCount: (result.guest.shareResumeCount == 0 ? '' : result.guest.shareResumeCount),
                            companyId: result.guest.companyId,
                            planId: result.guest.planId,
                            expiryDate: result.guest.expiryDate
                        }
                    }
                    this.showDate()
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadServicePlan() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/service/plan/list')
                .then(response => {
                    let result = response.data;
                    this.planList = result.servicePlanList;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        saveUser: function() {
            this.elements.submitted = true;
            axios.post(this.$store.getters.getAPIBasePath + '/guestresource/update/guest/' + this.profileId, this.ProfileData)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message:response.data.message, position: 'topRight' });
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                });
            this.elements.submitted = false;
        },
    }
}

</script>
