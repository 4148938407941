<template>
    <div id="main" class="mt-70">
        <div id="quick-search" class="container-fluid mtb-4 bg-white p-tb-15">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row no-gutters">
                    <b-tabs  v-model="currentTab" small card class="tab-list driver-tab-list tab-bg">
                        <b-tab title="Users">
                            <ListUsers/>
                        </b-tab>
                        <b-tab title="Deactivated Users">
                            <DeactivatedUsers/>
                        </b-tab>
                        <b-tab title="Deleted Users">
                            <DeletedUsers/>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
            
    </div>
</template>

<script>
import iziToast from 'izitoast';
import ListUsers from '@/components/admin/user/ListUsers';
import DeletedUsers from '@/components/admin/user/DeletedUsers';
import DeactivatedUsers from '@/components/admin/user/DeactivatedUsers';

export default {
    components : {
        ListUsers,
        DeletedUsers,
        DeactivatedUsers
    },
    data() {
        return {
            
            currentTab:0,
        }
    },
    mounted:function(){
        
    },
    methods:{
        changeTab: function(tab){
            this.currentTab=tab;
        },
        
    }
}
</script>