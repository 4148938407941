<template>
    <div class="card incident-history">
        <div class="card-header">Incident History Details</div>
        <div class="card-body">
            <div class="row col-md-12 col-sm-6 col-12 mx-0 mt-4">
                <div class="table-responsive">
                    <table class="table table-borderless all-table">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Recruiter</th>
                                <th>Incident Date</th>
                                <th>Incident Type</th>
                                <th>Action Type</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody id="table-length">
                            <tr v-if="incidentList==null || incidentList.length==0">
                                <td colspan="6" align="center">No incident found recently</td>
                            </tr>
                            <tr v-for="(Incident, index) in incidentList">
                                <td>{{ index+1 }}</td>
                                <td>{{ Incident.recruiterName }} </td>
                                <td>{{ Incident.createdAt | formatDateTime}}</td>
                                <td>{{ Incident.incidentTypeName }}</td>
                                <td>{{ Incident.action }}</td>
                                <td>
                                    <a v-if="Incident.action == 'Callback'" v-b-modal.viewCallbackModal @click="referenceId=Incident.incidentReferenceId" href="javascript:">View</a>
                                    <a v-if="Incident.action == 'Note'" v-b-modal.viewNoteModal @click="referenceId=Incident.incidentReferenceId" href="javascript:">View</a>
                                    <router-link v-if="false" :to="{ name:'viewtask', params:{taskid: Incident.taskId}}">View</router-link>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <ViewNoteModal :referenceId="referenceId" />
        <ViewCallbackModal :referenceId="referenceId" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ViewNoteModal from '@/components/recruiters/blocks/note/ViewNotes';
import ViewCallbackModal from '@/components/recruiters/blocks/callback/ViewCallback';
export default {
    components: {
        ViewNoteModal,
        ViewCallbackModal
    },
    data() {
        return {
            incidentList: [],
            incidentinfo: {},
            referenceId: 0
        }
    },
    mounted() {
        this.getIncidentHistory();
    },
    computed: {
        candidateid() {
            return this.$route.params.candidateid;
        }
    },
    methods: {
        getIncidentHistory: function() {
            this.incidentinfo = {
                userId: this.candidateid,
                userType: 'candidate'
            }
            axios.post(this.$store.getters.getAPIBasePath + '/incidentresource/incident/list', this.incidentinfo)
                .then(response => {
                    if (response.data.status) {
                        this.incidentList = response.data.incidentList;
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(error => {
                    iziToast.error({ title: 'Error', message: this.$config.LoadIncHisErr, position: 'topRight' });
                });
        },
        showModal(referenceId, action) {
            this.referenceId = referenceId;
            switch (action) {
                case "Callback":
                    {
                        this.$refs.viewCallbackModal.show();
                        break;
                    }
                case "Note":
                    {
                        this.$refs.viewNoteModal.show();
                        break;
                    }
                default:
                    console.log("No Model");
            }
        },
    }
}

</script>
