<template>
    <div id="interviewer-edit-block" class="interviewer-edit-block mt-70">
        <div class="container-fluid driver-model" id="main-data">
            <div class="row">
                <div class="col-md-3">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card border-5 mb-3">
                                <div class="col-md-12 col-sm-12 px-0 about-box">
                                    <div class="col-md-10 px-0 float-left">
                                        <h6 class="m-0 text-left p-2">
                                            <a href="javascript:" @click="$router.go(-1)" title="Back" class="p-2">
                                                <i class="ti-arrow-left "></i>
                                                <span> Back</span>
                                            </a>
                                        </h6>
                                    </div>
                                    <div class="col-md-2 px-0 pull-right py-2">
                                        <a href="javascript:" @click="elements.showEmailTools=true" title="Email To"><i class="ti-email px-1"></i></a>
                                        <router-link title="Edit Interviewer" :to="{ name: 'editinterviewer', params: {interviewerid: ProfileData.id}}"><i class="fa fa-pencil-square-o fa_custom fa-lg px-1"></i></router-link>
                                    </div>
                                </div>
                                <div class="user-card pl-5 pr-5 pt-3">
                                    <div class="information">
                                        <div class="userimage-progress">
                                            <div class="progress-circle">
                                                <vue-circle v-if="showProgress" :progress="ProfileData.profileCompletion" :size="50" :reverse="true" line-cap="round" :fill="fill" empty-fill="rgba(0, 0, 0, .1)" :animation-start-value="0.0" :start-angle="0" insert-mode="append" :thickness="5" :show-percent="true" @vue-circle-progress="progress" @vue-circle-end="progress_end">
                                                    <div class="imgUp">
                                                        <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + ProfileData.image + ')' }"></div>
                                                    </div>
                                                </vue-circle>
                                            </div>
                                        </div>
                                        <div class="name">{{ProfileData.firstName}} {{ProfileData.lastName}}</div>
                                        <div>{{ProfileData.position}} </div>
                                        <div v-if="ProfileData.email" class="position"><i class="ti-email"></i> {{ProfileData.email}}</div>
                                        <div v-if="ContactInfo.mobileNo" class="position"><i v-if="ContactInfo.mobileNo != null" class="ti-mobile"></i> {{ContactInfo.mobileNo}}</div>
                                    </div>
                                </div>
                                <div v-if="this.verified == 'No'">
                                    <h5 class="about-box m-0 text-left p-2">
                                        <span class="job-link pt-0 pb-0">
                                            <a title="Reject" href="javascript:void(0);" data-toggle="modal" data-target="#RejectRequest"><i class="ti-close" style="color: red;"></i><span style="color: red; padding-right:10px; font-family: sans-serif;"> Reject</span></a>
                                        </span>
                                        <span class="job-link float-right pt-0 pb-0">
                                            <a title="Accept" href="javascript:void(0);" data-toggle="modal" data-target="#AcceptRequest"><i class="ti-check" style="color: green;"></i><span class="pr-10" style="color: green; font-family: sans-serif;">Accept</span></a>
                                        </span>
                                    </h5>
                                </div>
                            </div>
                            <div class="card border-5">
                                <div class="user-card p-0">
                                    <h5 class="about-box m-0 text-left p-2">About Me</h5>
                                    <div class="p-3 float-left w-100">
                                        <h5>Specialization</h5>
                                        <div class="informations">
                                            <span v-if="TechSkills.length==0" class="badge p-2 mb-1">No records found</span>
                                            <span v-if="TechSkills.length!=0" v-for="(tech,idx) in TechSkills" v-bind:key="idx" class="badge p-1 m-1" :class="colours[idx%5]">{{tech.technology.technology}}</span>
                                        </div>
                                        <div v-if="ContactInfo!== null" class="w-100 float-left">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="box-wrapper shadow-sm  bg-white rounded pos-rel main-search">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row no-gutters">
                                    <div class="col-md-12 pop-tab">
                                        <div class="driver-model">
                                            <div class="row no-gutters">
                                                <b-tabs v-if="!elements.showEmailTools" v-model="currentTab" small card class="tab-list driver-tab-list tab-bg">
                                                    <b-tab title="Primary Info">
                                                        <PersonalInfo :ProfileData=ProfileData ref="personalinfocomponent"></PersonalInfo>
                                                    </b-tab>
                                                    <b-tab title="Contact Info">
                                                        <ContactInfo :ContactInfo=ContactInfo ref="contactInfocomponent"></ContactInfo>
                                                    </b-tab>
                                                    <b-tab title="Technology/Skills">
                                                        <TechInfo :technologyinfo="TechSkills" ref="contactInfocomponent"></TechInfo>
                                                    </b-tab>
                                                    <b-tab @click="elements.showAvailabilityInfo=true;" title="Availability">
                                                        <AvailabilityInfo v-if="elements.showAvailabilityInfo" ref="availabilityinfocomponent"></AvailabilityInfo>
                                                    </b-tab>
                                                    <b-tab title="Settings">
                                                        <Settings :settingsInfo="settingsInfo" ref="settingscomponent"></Settings>
                                                    </b-tab>
                                                </b-tabs>
                                                <EmailTools v-if="elements.showEmailTools" :mailTo="{email: ProfileData.email, id: ProfileData.id, name: ProfileData.firstName+' '+ProfileData.lastName, userType: 'interviewer'}" @closeEmailTools="elements.showEmailTools=false"></EmailTools>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal " id="RejectRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Interviewer confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to reject this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="interviewConfirmation('reject')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal " id="AcceptRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Interviewer confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to accept this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="interviewConfirmation('approve')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PersonalInfo from '@/components/interviewers/blocks/viewinterviewer/PersonalInfo';
import ContactInfo from '@/components/interviewers/blocks/viewinterviewer/ContactInfo';
import TechInfo from '@/components/interviewers/blocks/viewinterviewer/TechnologyInfo';
import AvailabilityInfo from '@/components/interviewers/blocks/viewinterviewer/AvailabilityInfo';
import EmailTools from '@/components/recruiters/blocks/tools/EmailTools';
import VueCircle from 'vue2-circle-progress';
import Settings from '@/components/interviewers/blocks/profile/Settings';
import iziToast from 'izitoast';
export default {
    components: {
        PersonalInfo,
        ContactInfo,
        TechInfo,
        AvailabilityInfo,
        EmailTools,
        VueCircle,
        Settings
    },
    data() {
        return {
            elements: {
                showAvailabilityInfo: false,
                showEmailTools: false
            },
            currentTab: 0,
            interviewerId: this.$route.params.interviewerid,
            verified: this.$route.params.verified,
            ProfileData: {
                id: 0,
                interviewerCode: "",
                firstName: '',
                lastName: '',
                nickName: '',
                email: '',
                dateOfBirth: '',
                gender: '',
                position: '',
                image: '',
                imageName: '',
                imagePath: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
                image: '',
                industryId: null,
                profileCompletion: 0
            },
            ContactInfo: Object,
            settingsInfo: {
                timezone: "",
                accessLevel: 1
            },
            TechSkills: [],
            colours: ['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info'],
            showProgress: false,
            fill: { gradient: ["#37A0E4", "#37A0E4", "#808080"] }
        }
    },
    mounted() {
        this.loadInterviewer();
    },
    methods: {
        progress(event, progress, stepValue) {
            // console.log(stepValue);
        },
        progress_end(event) {
            //  console.log("Circle progress end");
        },
        loadInterviewer: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/interviewerresource/view/interviewer/' + this.interviewerId)
                .then(response => {
                    if (response.data.status) {
                        let interviewerData = response.data.interviewer;
                        this.ProfileData.id = interviewerData.id;
                        this.ProfileData.interviewerCode = interviewerData.interviewerCode;
                        this.ProfileData.firstName = interviewerData.firstName;
                        this.ProfileData.lastName = interviewerData.lastName;
                        this.ProfileData.nickName = interviewerData.nickName;
                        this.ProfileData.email = interviewerData.email;
                        this.ProfileData.dateOfBirth = interviewerData.dateOfBirth;
                        this.ProfileData.gender = interviewerData.gender;
                        this.ProfileData.position = interviewerData.position;
                        this.ProfileData.imageName = interviewerData.imageName;
                        this.ProfileData.imagePath = (interviewerData.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : interviewerData.imagePath);
                        this.ProfileData.image = (interviewerData.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : interviewerData.imagePath);
                        this.ProfileData.industryId = interviewerData.industryId;
                        var techAndSkill = [];
                        if (interviewerData.techList != null) {
                            for (var i = 0; i < interviewerData.techList.length; i++) {
                                let skillData = interviewerData.techList[i];
                                if (skillData.technologySkillData.length != 0) {
                                    techAndSkill.push({
                                        technology: { technology: skillData.technologyName, id: null },
                                        skills: [],
                                        industryName:(interviewerData.interviewersSkill[0].industrySet==null? "": interviewerData.interviewersSkill[0].industrySet.industryName)                                     
                                    });
                                    for (var j = 0; j < skillData.technologySkillData.length; j++) {
                                        if (j == 0) {
                                            techAndSkill[i].technology.id = skillData.technologySkillData[j].technologyId;
                                        }
                                        techAndSkill[i].skills.push({
                                            skillId: skillData.technologySkillData[j].id,
                                            skillName: skillData.technologySkillData[j].skillName
                                        });
                                    }
                                }
                            }
                        }
                        this.TechSkills = techAndSkill;
                        //this.updateSkills(interviewerData.interviewersSkill);
                        this.ContactInfo = Object.assign({}, interviewerData.interviewersContact);
                        this.ProfileData.profileCompletion = interviewerData
                            .profileCompletion == null ? 0 : interviewerData.profileCompletion;
                        this.settingsInfo.timezone = interviewerData.timezone == null ? '' : interviewerData.timezone;
                        this.settingsInfo.accessLevel = interviewerData.accessLevel == null ? '' : interviewerData.accessLevel;
                        this.showProgress = true;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        interviewConfirmation(type) {
            axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/interviewer/approval', { 'id': this.interviewerId, 'statusName': type })
                .then(response => {
                    let result = response.data;
                    if (result.status) {
                        iziToast.success({
                            title: this.$config.ConfirmReq,
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadInterviewer();
                        this.$router.go(-1);
                    } else {
                        iziToast.error({
                            title: 'Confirmation Request',
                            message: this.$config.ConfirmReqErr,
                            position: 'topRight'
                        });
                    }
                });
            return true;
        },
        updateSkills: function(Skills) {
            let self = this;
            self.TechSkills = [];
            $.each(Skills, function(key, value) {
                if (value.technologySkillSet != null)
                    self.TechSkills.push(value.technologySkillSet);
            });
        }
    }
}

</script>
