<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div class="card border-5 mb-3">
                                    <div class="col-md-12 col-sm-12 px-0 about-box">
                                        <div class="col-md-8 px-0 float-left">
                                            <h6 class="m-0 text-left p-2">
                                                <a href="javascript:" @click="getPrevUrl()" title="Back" class="p-2">
                                                    <i class="fa fa-arrow-left"></i>
                                                    <span> Back</span>
                                                </a>
                                            </h6>
                                        </div>
                                        <div class="col-md-4 px-0 pull-right py-2 text-right pr-2">
                                            <router-link class="link" title="Edit Lead" :to="{ name: 'editlead'}"><i class="fa fa-pencil-square-o fa_custom fa-lg px-1"
                                                @click="addLogActivity"></i></router-link>
                                        </div>                                        
                                    </div>
                                    <div class="user-card pl-5 pr-5 pb-2 pt-3">
                                        <div class="information">
                                            <div class="imgUp">
                                                <!-- <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' +  (clientinfo.image===''?clientinfo.imagePath:clientinfo.image) + ')' }"></div> -->
                                            </div>
                                            <div >{{vendorInfo.companyName}}</div>
                                            <div v-if="vendorInfo.email != ''"><i class="ti-email"></i> {{vendorInfo.email}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="user-card p-0">
                                    <h5 class="about-box m-0 text-left p-2">Company Info</h5>
                                    <div class="p-3 float-left w-100">
                                        <div class="informations">
                                            <span v-if="vendorInfo.length == 0" class="badge p-2 mb-1">No records found</span>
                                            <span v-if="vendorInfo.length != 0 && vendorInfo.phoneNumber != ''">
                                                <i class="fa fa-phone pr-2" aria-hidden="true"></i>
                                                {{vendorInfo.phoneNumber}}
                                            </span>
                                            <span v-if="vendorInfo.phoneNumber != ''"><hr></span>
                                            <span v-if="vendorInfo != null && vendorInfo.length != 0">
                                                <i v-if="vendorInfo.cityName != '' && vendorInfo.stateName != '' && vendorInfo.countryId != '' && vendorInfo.zipCode != ''"  class="ti-location-pin pr-2"></i>                    
                                                <span v-if="vendorInfo.cityName != ''">{{vendorInfo.cityName}}</span>
                                                <span v-if="vendorInfo.stateName != ''">,{{vendorInfo.stateName}}</span>
                                                <span v-if="vendorInfo.countryId != ''">,{{vendorInfo.countryId}}</span>
                                                <span v-if="vendorInfo.zipCode != ''"> - {{vendorInfo.zipCode}}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card border-5">
                                    <div class="user-card p-0">
                                        <h5 class="about-box m-0 text-left p-2">About Me</h5>
                                        <div class="p-3 float-left w-100">
                                            <h5>Website</h5>
                                            <div class="informations">
                                                <span v-if="vendorInfo.websiteUrl == ''" class="badge p-2 mb-1">No records found</span>
                                                <span v-if="vendorInfo.websiteUrl != ''">{{vendorInfo.websiteUrl}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-9">
                        <div class="row no-gutters">
                            <b-tabs v-model="currentClientTab" small card class="tab-list driver-tab-list">
                                <b-tab title="Lead Details">
                                    <VendorInfo :vendorinfo="vendorInfo" ref="vendorinfocomponent"></VendorInfo>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import VendorInfo from './blocks/viewvendor/VendorInfo';
export default {
    computed: {
        vendorid() {
            return this.$route.params.vendorid;
        }
    },
    components: {
        VendorInfo,

    },
    data() {
        return {
            currentClientTab: 0,
            maxtabs: 3,
            vendorInfo: {
                companyName: '',
                email: '',
                phoneNumber: '',
                websiteUrl: '',
                industryId: '',
                linkedinUrl:'',
                facebookUrl:'',
                twitterUrl:'',
                instagramUrl:'',
                street:'',
                cityName:'',
                stateName:'',
                countryId:'',
                zipCode:'',
                yearFounded:''
            },
        }
    },
    mounted() {
        this.loadVendor(this.vendorid);
    },
    methods: {
        getPrevUrl() {
            this.$router.go(-1);
        },
        getCountryName: function(countryCode) {
            if (!countryCode)
                return "";
            let country = this.countrieslist.filter(obj => {
                return obj.country_Id === countryCode;
            });
            return country[0].name;
        },
        getStateName: function(stateId) {
            if (!stateId)
                return "";
            let state = this.usstates.filter(obj => {
                return obj.id === stateId;
            });
            return state[0].stateName;
        },
        loadVendor(vendorid){
            axios.get(this.$store.getters.getAPIBasePath + '/lead/view/' + vendorid)
                .then(response => {
                    let result = response.data;
                    this.vendorInfo = result.recordinfo;
                    console.log( this.vendorInfo);
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.LeadInfoCreErrTryAg,
                        position: 'topRight'
                    });                   
                });
        },
        addLogActivity: function(action) {
            this.updateLogActivity();
            this.logActivityData = {
                entityName: 'Client',
                actionName: action,
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        }
    }

}

</script>
