<template>
       <div class="card-holder">
            <h1>Work In Progress...</h1>
        </div>  
</template>
<script>
export default {
    
}
</script>
<style>
 .card-holder{
    background-color:#dfdfdf;
    width: 72vw;
    height: 35vw;
  }
  h1{
  font-size: 3rem;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  line-height: 75vh;
}
</style>