<template>
    <div class="contact-block">
        <img src="@/assets/images/innerpages_banner1.jpg" alt="images" class="w-100">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <h3 class="heading pt-5">Contact Us</h3>
                    <br>                    
                    <p class="para">
                       <!--  <b>USA</b><br> -->
                        691 S Milpitas Blvd,<br>
                        Milpitas,<br> CA 95035,<br>
                        <!-- <b></b> -->
                        USA.
                    </p>              
                    <table cellspacing="0" cellpadding="0" class="tab mt-11">
                        <tbody>
                           <!--  <tr>
                                <td>Phone</td>
                                <td style="padding-left:10px;padding-right:10px;">:</td>
                                <td>+1 (866) 258-3217</td>
                            </tr> -->
                            <tr>
                                <td>Enquiry</td>
                                <td class="pl-10">:</td>
                                <td><a target="_blank" href="" class="color-black pl-10">contact@talentturbo.us</a></td>
                            </tr>
                        </tbody>
                    </table>                   
                    <div v-if="false" class="contact_map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d206.5897685962322!2d-84.1712045!3d34.0583283!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f598d89644bd55%3A0xe58ec8e4340b71d6!2s11340+Lakefield+Dr+%23200%2C+Johns+Creek%2C+GA+30097%2C+USA!5e0!3m2!1sen!2sin!4v1479805316692" width="550" height="200" frameborder="0" style="border:0" allowfullscreen></iframe>
                    </div>
                </div>
                <div class="col-sm-6">
                    <h3 class="heading pt-5"></h3>
                    <form role="form" id="main-contact-form" class="pt-4">                        
                        <div class="form-group required-field-block">
                            <div class="input-group">
                                <input class="form-control" v-model="webContactInfo.firstName" placeholder="Your Name">
                                <span class="required-icon"></span>
                            </div>
                            <label v-if="$v.webContactInfo.firstName.$error && !$v.webContactInfo.firstName.required" class="text-danger">Please enter name</label>
                        </div>
                        <div class="form-group required-field-block">
                        <PhoneNumber ref="phoneNumber" :value="webContactInfo.phoneNo" :mandatory="phoneMandatory" :isValidcheck="isValidCheck" @phone-input="phoneControlInput"  />
                            <!-- <div class="input-group">
                                <select class="form-control box-none col-sm-3" v-model="webContactInfo.countryCode">
                                    <option value="+1">+1</option>
                                    <option value="+91">+91</option>
                                </select>
                                <input-mask class="form-control box-none" v-model="webContactInfo.phoneNo" mask="(999)-999-9999" placeholder="Phone No" :alwaysShowMask="false" maskChar="_"></input-mask>
                                <span class="required-icon"></span>
                            </div>
                            <label v-if="$v.webContactInfo.phoneNo.$error && !$v.webContactInfo.phoneNo.required" class="text-danger">Please enter mobile number</label> -->
                        </div>
                        <div class="form-group required-field-block">
                            <div class="input-group">
                                <input type="email" class="form-control" v-model="webContactInfo.email" placeholder="Enter Your Email">
                                <span class="required-icon"></span>
                            </div>
                            <label v-if="$v.webContactInfo.email.$error && !$v.webContactInfo.email.required" class="text-danger">Please enter email</label>
                            <label v-if="$v.webContactInfo.email.$error && !$v.webContactInfo.email.email" class="text-danger">Please enter valid email</label>
                        </div>
                        <div class="form-group required-field-block">
                            <div class="input-group">
                                <textarea class="form-control" rows="5" v-model="webContactInfo.message" placeholder="Message"></textarea>
                                <span class="required-icon"></span>
                            </div>
                            <label v-if="$v.webContactInfo.message.$error && !$v.webContactInfo.message.required" class="text-danger">Please enter message</label>
                        </div>
                        <div class="clear"></div>
                        <button type="button" class="btn btn-secondary pull-right log ml-2" @click="resetWebContact()">Reset</button>
                        <button type="button" class="btn btn-info pull-right log" @click="saveWebContact()">Submit</button>
                    </form>
                </div>
            </div>
            <br>
        </div>
    </div>
</template>
<script>
import HomeLayout from '@/layouts/HomePages.vue';
import Slider from './widgets/Slider';
import { required, email } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {
    components: {
        HomeLayout,
        Slider
    },
    data() {
        return {
            webContactInfo: {
                firstName: '',
                lastName: '',
                email: '',
                // countryCode:'+1',
                // phone: '',
                phoneNo:'',
                message: ''
            },
            phoneMandatory:true,
            isValidCheck:false,
        }
    },
    validations: {
        webContactInfo: {
            firstName: { required },
            email: { required, email },
            // phoneNo: { required },
            message: { required },
        }
    },
    methods: {
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        validateWebContact() {
            this.$v.webContactInfo.$touch();
            if (this.$v.webContactInfo.$invalid) {
                return false;
            }
            return true;
        },
        saveWebContact: function() {
            if (this.validateWebContact() && !this.isValidCheck && this.$refs.phoneNumber.validatePhone()) {
                this.webContactInfo.phone = this.webContactInfo.countryCode+" "+this.webContactInfo.phoneNo;
                console.log(this.webContactInfo.phone);
                axios.post(this.$store.getters.getAPIBasePath + '/webcontactinforesource/add/web/contact', this.webContactInfo)
                    .then((response) => {
                        let result = response.data;
                        console.log(result);
                        if (result.status == 'ACCEPTED') {
                            iziToast.success({
                                title: 'Success',
                                message: this.$config.WebConInfoCreSuc,
                                position: 'topRight'
                            });
                            this.resetWebContact();
                        }

                    })

            }

        },
        phoneControlInput:function(value,isValid){
            this.isValidCheck = isValid;
            this.webContactInfo.phoneNo = value;
        },
        resetWebContact: function() {
            this.webContactInfo.firstName = "";
            this.webContactInfo.email = "";
            // this.webContactInfo.phone = "";
            this.webContactInfo.message = "";
            // this.webContactInfo.phone = "";
            this.webContactInfo.phoneNo = "";          
            // this.countryCode = '+1';
            this.$v.webContactInfo.$reset();
        }
    }

}

</script>
