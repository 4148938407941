<template>
    <div id="main" class="mt-70">
        <div id="quick-search" class="container-fluid mtb-4 bg-white p-tb-15">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row no-gutters">
                    <b-tabs  v-model="currentTab" small card class="tab-list driver-tab-list tab-bg">
                        <b-tab v-if="false" title="Interview Requests">
                            <InterviewRequests />
                        </b-tab>
                        <b-tab title="Interviews" @click="refershData" >
                            <Interviews ref="Interviews"/>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
            
    </div>
</template>

<script>
import iziToast from 'izitoast';

import InterviewRequests from './blocks/requests/InterviewRequests';
import Interviews from './blocks/requests/Interviews';

export default {
    components : {
        InterviewRequests,
        Interviews
    },
    data() {
        return {
            
            currentTab:0,
        }
    },
    mounted:function(){
        
    },
    methods:{
        changeTab: function(tab){
            this.currentTab=tab;
        },
        refershData:function()
        {
            //console.log(1);
            this.$refs.Interviews.loadInterviews(0);
        }
    }
}
</script>