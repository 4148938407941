<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card border-5 mb-3">
                                    <div class="user-card pl-5 pr-5	">
                                        <div class="information">
                                            <div class="imgUp">
                                                <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + personalinfo.imagePath + ')' }"></div>
                                                <label class="upload-icon"><i class="ti-camera"></i><input ref="candidate_add_profile_photo" id="candidate_aad_profile_photo" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="upload_photo"></label>
                                                <!--  <input class="upload" ref="addcandidate_resumeattach_resume" id="candidateadd_resume_fileupload" type="file" accept=".doc,.docx,.pdf" v-on:change="handleFileUpload"></input> -->
                                                <label class="upload-icon uploadleft"><i class="ti-camera"></i><input ref="candidate_add_profile_photo" id="candidate_aad_profile_photo" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="upload_photo"></label>
                                            </div>
                                            <div class="name">{{personalinfo.firstName}} {{personalinfo.lastName}}</div>
                                            <div>{{/*personalinfo.industry.industryName*/}} {{personalinfo.position}} </div>
                                            <div class="position"><i class="ti-email"></i> {{personalinfo.email}}</div>
                                            <div class="position"><i class="ti-mobile"></i> {{personalinfo.mobile}}</div>
                                            <!-- <div class="position"><i class="ti-mobile"></i> {{personalinfo.firstName}}, {{personalinfo.experience}} Y </div> -->
                                            <h4>Profile Completion {{personalinfo.profileCompletion}}%</h4>
                                            <div class="progress">
                                                <div class="progress-bar" :style="applyPercentage(personalinfo.profileCompletion)" role="progressbar" aria-valuenow="personalinfo.profileCompletion" aria-valuemin="0" aria-valuemax="100">
                                                    <span class="title">{{personalinfo.profileCompletion}}%</span>
                                                </div>
                                            </div>
                                            <!-- <b-progress  :style="applyPercentage(personalinfo.profileCompletion)" :value="personalinfo.profileCompletion" :max="max" show-progress  class="mb-3"></b-progress> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="card border-5">
                                    <div class="user-card p-0">
                                        <h5 class="about-box m-0 text-left p-2">About Me</h5>
                                        <div class="p-3 float-left w-100">
                                            <h5>Specialization</h5>
                                            <div class="informations">
                                                <span v-for="(info, index) in technologyinfo.skillset" class="p-1 mb-2 mr-1 badge " :class="colours[index%5]"><span v-if="technologyinfo.skillset[index].technologySet != null">{{technologyinfo.skillset[index].technologySet.technology}} - {{technologyinfo.skillset[index].years}} y
                                                    </span>
                                                </span>
                                                <!-- <span v-for="(info, index) in technologyinfo.skillset" class="p-1 mb-2 mr-1 badge badge-primary">{{technologyinfo.skillset[index].technologySet.technology}} - {{technologyinfo.skillset[index].years}} y
										  		</span> -->
                                            </div>
                                            <div v-if="contactinfo.currentaddress.addressLine1!== null" class="w-100 float-left">
                                                <hr>
                                                <h5><i class="ti-location-pin"></i> Location</h5>
                                                <div class="information">
                                                    <p> {{personalinfo.location}}. </p>
                                                </div>
                                            </div>
                                            <div v-if="personalinfo.educationinfo.length!=0" class="w-100 float-left">
                                                <hr>
                                                <h5><i class="ti-briefcase"></i> Education</h5>
                                                <div class="information">
                                                    <p v-for="(info, index) in personalinfo.educationinfo">
                                                        {{personalinfo.educationinfo[index].degree}} - {{personalinfo.educationinfo[index].year}}, {{personalinfo.educationinfo[index].schoolName}}, {{personalinfo.educationinfo[index].city}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-9 pl-0">
                        <div class="row no-gutters">
                            <b-tabs v-model="currentCandidateTab" small card class="tab-list driver-tab-list">
                                <b-tab title="Primary Info">
                                    <PersonalInfo :personalinfo="personalinfo" ref="personalinfocomponent"></PersonalInfo>
                                </b-tab>
                                <b-tab title="Address Info">
                                    <ContactInfo :contactinfo="contactinfo" ref="contactinfocomponent"></ContactInfo>
                                </b-tab>
                                <b-tab title="Employment History">
                                    <EmploymentInfo :employmentinfo="employmentinfo" ref="employmentinfocomponent"></EmploymentInfo>
                                </b-tab>
                                <b-tab title="Reference Details">
                                    <ReferenceInfo :referenceinfo="employmentinfo"></ReferenceInfo>
                                </b-tab>
                                <b-tab title="Technology/Skills">
                                    <TechnologyInfo :technologyinfo="technologyinfo" ref="technologyinfocomponent"></TechnologyInfo>
                                </b-tab>
                                <b-tab title="Projects">
                                    <ProjectInfo :projectinfo="projectinfo" ref="projectinfocomponent"></ProjectInfo>
                                </b-tab>
                                <b-tab title="Preferences">
                                    <PreferenceInfo :preferenceinfo="preferenceinfo" ref="preferenceinfocomponent"></PreferenceInfo>
                                </b-tab>
                                <b-tab title="Availability" @click="refreshCalendar">
                                    <AvailabilityInfo v-if="showAvailabilityInfo" :availabilityinfo="availabilityinfo" ref="availabilityinfocomponent"></AvailabilityInfo>
                                </b-tab>
                                <b-tab title="Resume">
                                    <ResumeAttach :resumeinfo="resumeinfo" ref="resumeattachcomponent"></ResumeAttach>
                                </b-tab>
                                <b-tab title="Screening Results">
                                    <Screening ref="screeningcomponent"></Screening>
                                </b-tab>
                            </b-tabs>
                            <div class="col-md-12 pt-2 mt-3 text-right"">
								<a href=" Javascript:void(0);" @click="saveCurrentTab" class="btn btn-success float-right" style="margin-left: 10px;color: #fff;">Save</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import PersonalInfo from './blocks/addcandidate/PersonalInfo';
import ContactInfo from './blocks/addcandidate/ContactInfo';
import EmploymentInfo from './blocks/addcandidate/EmploymentInfo';
import ReferenceInfo from './blocks/addcandidate/ReferenceInfo';
import TechnologyInfo from './blocks/addcandidate/TechnologyInfo';
import PreferenceInfo from './blocks/addcandidate/PreferenceInfo';
import ResumeAttach from './blocks/addcandidate/ResumeAttach';
import AvailabilityInfo from './blocks/addcandidate/AvailabilityInfo';
import ProjectInfo from './blocks/addcandidate/ProjectInfo';
import Screening from './blocks/addcandidate/ScreeningResult';
export default {
    computed: {
        candidateid() {
            return this.$route.params.candidateid;
        }
    },
    components: {
        PersonalInfo,
        ContactInfo,
        EmploymentInfo,
        ReferenceInfo,
        TechnologyInfo,
        PreferenceInfo,
        AvailabilityInfo,
        ResumeAttach,
        ProjectInfo,
        Screening
    },
    data() {
        return {
            value: 0,
            max: 100,
            currentCandidateTab: 0,
            maxtabs: 6,
            personalinfo: {
                candidateCode: "",
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                mobile: "",
                dateOfBirth: "",
                dateAvailable: "",
                industryId: "",
                position: "",
                location: "",
                experience: "",
                referredBy: "",
                payMode: "",
                recruiterId: "",
                visaTypeId: "",
                notes: "",
                educationinfo: [],
                blockpersonaltab: 0,
                profileCompletion: 0,
                imageName: '',
                imagePath: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
                candidateLanguageSet: [],
                candidateLanguage: []
            },
            contactinfo: {
                currentaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "US"
                },
                permanentaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "US"
                },
                emergency: []
            },
            employmentinfo: [],
            technologyinfo: {
                skillset: [],
                specialnotes: ""
            },
            preferenceinfo: {
                basepreference: [],
                otherpreference: {
                    timetocall: "",
                    parttime: "no",
                    fulltime: "no"
                }
            },
            deleteparams:{
            id: 0,
            type:'Candidate'
            },
            resumeinfo: {
                file: '',
                fileurl: '',
                filename: ''
            },
            availabilityinfo: {

            },
            projectinfo: [],
            showAvailabilityInfo: false,
            colours: ['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info'],
            screeninginfo: []

        }
    },
    mounted() {
        this.loadCandidateById(this.candidateid);
        // this.refreshCalendar();
    },
    methods: {
       delete_photo: function() {
            this.deleteparams.id=this.candidateid;
            axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/delete/candidate/photo',this.deleteparams)
                .then(response => {
                    if(response.data.status=="OK"){
                        iziToast.success({ title: 'Photo update', message: response.data.message, position: 'topRight' });
                        this.personalinfo.image="";
                        this.personalinfo.imageName="";
                        this.personalinfo.imagePath="";
                        this.loadCandidateById(this.candidateid);
                    }
                    else if(response.data.status=="EXPECTATION_FAILED"){
                    console.log(response);
                    iziToast.info({ title: 'Photo update', message: response.data.message, position: 'topRight' });}
                })
                .catch(function(error) {
                    console.log(error);
                });
           
        },
         createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;
            reader.onload = (e) => {
                this.personalinfo.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        upload_photo: function() {
            //console.log(this.$refs.candidate_add_profile_photo.files[0].name);
            if (!/\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(this.$refs.candidate_add_profile_photo.files[0].name)) {
                iziToast.error({
                    title: 'Photo update',
                    message: this.$config.FileFormatErr,
                    position: 'topRight'
                });
                return false;
            }
            this.createImage(this.$refs.candidate_add_profile_photo.files[0]);
            //this.profilePhoto = this.$refs.addcandidate_profile_photo1.files[0];
            let formData = new FormData();
            formData.append('file', this.$refs.candidate_add_profile_photo.files[0]);
            formData.append('id', this.candidateid);
            formData.append('type', 'candidate');
            axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/uploadprofilephoto',
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response => {
                    console.log('SUCCESS!!');
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.ProfileUpSuc,
                        position: 'topRight'
                    });
                })
                .catch(function(error) {
                    console.log('FAILURE!!');
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.ProfileUpErr,
                        position: 'topRight'
                    });
                });
        },
        applyPercentage(percentage) {
            return 'max-width:' + percentage + '%'
        },
        loadCandidateById(candidateId) {
            axios.get(this.$store.getters.getAPIBasePath + '/candidateresource/view/candidate/' + candidateId)
                .then(response => {
                    let result = response.data;
                    if (!result.status) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$router.push('/candidates');
                    }

                    let languages1 = [];
                    let languages2 = [];

                    for (var i = 0; i < result.candidates.candidateLanguage.length; i++) {
                        languages1.push({ languageId: result.candidates.candidateLanguage[i].languageId });
                        languages2.push(result.candidates.candidateLanguage[i].languageId);
                    }

                    let self = this;
                    this.personalinfo = result.candidates;
                    this.personalinfo = {
                        candidateCode: result.candidates.candidateCode,
                        firstName: result.candidates.firstName,
                        middleName: result.candidates.middleName,
                        lastName: result.candidates.lastName,
                        middleName: result.candidates.middleName,
                        email: result.candidates.email,
                        mobile: result.candidates.mobile,
                        dateOfBirth: result.candidates.dateOfBirth,
                        dateAvailable: result.candidates.dateAvailable,
                        industryId: (result.candidates.industryId == undefined) ? '' : result.candidates.industryId,
                        position: result.candidates.position,
                        location: result.candidates.location,
                        experience: result.candidates.experience,
                        referredBy: result.candidates.referredBy,
                        payMode: (result.candidates.payMode == undefined) ? '' : result.candidates.payMode,
                        recruiterId: (result.candidates.recruiterId == undefined) ? '' : result.candidates.recruiterId,
                        visaTypeId: (result.candidates.visaTypeId == undefined) ? '' : result.candidates.visaTypeId,
                        notes: result.candidates.notes,
                        educationinfo: result.candidates.candidateEducation,
                        blockpersonaltab: 0,
                        profileid: this.candidateid,
                        profileCompletion: result.candidates.profileCompletion,
                        timeZone: (result.candidates.timeZone == null ? '' : result.candidates.timeZone),
                        imageName: result.candidates.imageName,
                        image : (result.candidates.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : result.candidates.imagePath),
                        imagePath: (result.candidates.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : result.candidates.imagePath),
                        candidateLanguageSet: languages2,
                        candidateLanguage: languages1
                    };
                    value: result.candidates.profileCompletion,
                        result.candidates.candidateContact.forEach(function(data) {
                            if (data.contactType.toLowerCase() == "current") {
                                self.contactinfo.currentaddress = data;
                            } else {
                                self.contactinfo.permanentaddress = data;
                            }
                        });
                    this.contactinfo.emergency = result.candidates.candidateEmergencyContact;
                    this.employmentinfo = result.candidates.candidateEmployment;
                    this.projectinfo = result.candidates.candidateProject;
                    this.technologyinfo.skillset = result.candidates.candidateSkill;
                    this.preferenceinfo.basepreference = result.candidates.candidatePreference;
                    this.preferenceinfo.otherpreference.timetocall = result.candidates.timeToCall;
                    if (result.candidates.isPartTime) {
                        this.preferenceinfo.otherpreference.parttime = "yes";
                    } else {
                        this.preferenceinfo.otherpreference.parttime = "no";
                    }
                    if (result.candidates.isFullTime) {
                        this.preferenceinfo.otherpreference.fulltime = "yes";
                    } else {
                        this.preferenceinfo.otherpreference.fulltime = "no";
                    }
                    if (result.candidates.fileName != undefined && result.candidates.fileName != "") {
                        this.resumeinfo.filename = result.candidates.fileName;
                        this.resumeinfo.fileurl = result.candidates.filePath;
                    }

                  
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CandidLoadErr,
                        position: 'topRight'
                    });
                    this.$router.push('/candidates');
                });
        },
        saveCurrentTab() {
            switch (this.currentCandidateTab) {
                case 0:
                    //personal info validation
                    if (this.$refs.personalinfocomponent.validatePersonalInfo()) {
                        this.$refs.personalinfocomponent.addEducationInfo();
                        if (this.personalinfo.blockpersonaltab == 0) {
                            this.savePersonalInfo();
                        }
                    }
                    break;
                case 1:
                    //contact info tab
                    this.$refs.contactinfocomponent.addNewContact();
                    this.saveContactInformation();
                    break;
                case 2:
                    this.$refs.employmentinfocomponent.addNewEmploymentInfo();
                    this.saveEmploymentInformation();
                    break;
                case 3:
                    this.saveEmploymentInformation();
                    break;
                case 4:
                    this.$refs.technologyinfocomponent.addNewSkillset();
                    this.saveTechInformation();
                    break;
                case 5:
                    this.$refs.projectinfocomponent.addNewProjectInfo();
                    this.saveProjectInformation();
                    break;
                case 6:
                    this.$refs.preferenceinfocomponent.addNewPreferenceInfo();
                    this.savePreferenceInfo();
                    break;
                case 8:
                    this.uploadNewResume();
                    break;
                default:
                    break;
            }
        },
        savePreferenceInfo() {
            let preferenceupdate = {
                candidatePreference: this.preferenceinfo.basepreference,
                 timeToCall: this.preferenceinfo.otherpreference.timetocall,
                isPartTime: (this.preferenceinfo.otherpreference.parttime == 'yes') ? true : false,
                isFullTime: (this.preferenceinfo.otherpreference.fulltime == 'yes') ? true : false
            };
            console.log(preferenceupdate);
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/' + this.candidateid + '/preference', preferenceupdate)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CandidPreUpSuc,
                        position: 'topRight'
                    });
                    this.loadCandidateById(this.candidateid);
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CandidUpErr,
                        position: 'topRight'
                    });
                });
        },
        saveTechInformation() {
            let techinfoupdate = {
                candidateSkill: this.technologyinfo.skillset
            };
            console.log(techinfoupdate);
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/' + this.candidateid + '/skills', techinfoupdate)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CandidTechUpSuc,
                        position: 'topRight'
                    });
                    this.loadCandidateById(this.candidateid);
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CandidUpErr,
                        position: 'topRight'
                    });
                });
        },
        saveProjectInformation() {
            let projectinfoupdate = {
                candidateProject: this.projectinfo
            };
            console.log(projectinfoupdate);
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/' + this.candidateid + '/project', projectinfoupdate)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CandidProUpSuc,
                        position: 'topRight'
                    });
                    this.loadCandidateById(this.candidateid);
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CandidUpErr,
                        position: 'topRight'
                    });
                });
        },
        saveEmploymentInformation() {
            let employmentupdate = {
                candidateEmployment: this.employmentinfo
            };
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/' + this.candidateid + '/employment', employmentupdate)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CandidEmpInfoUpSuc,
                        position: 'topRight'
                    });
                    this.loadCandidateById(this.candidateid);
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CandidUpErr,
                        position: 'topRight'
                    });
                });
        },
        savePersonalInfo() {

            this.personalinfo.candidateLanguage = [];
            for (var index = 0; index < this.personalinfo.candidateLanguageSet.length; index++) {
                this.personalinfo.candidateLanguage.push({ languageId: this.personalinfo.candidateLanguageSet[index] });
            }

            //console.log(this.personalinfo.candidateLanguage);
            //return;

            let personalupdate = {
                email: this.personalinfo.email,
                mobile: this.personalinfo.mobile,
                dateOfBirth: this.personalinfo.dateOfBirth,
                notes: this.personalinfo.notes,
                firstName: this.personalinfo.firstName,
                middleName: this.personalinfo.middleName,
                lastName: this.personalinfo.lastName,
                dateAvailable: this.personalinfo.dateAvailable,
                industryId: this.personalinfo.industryId,
                position: this.personalinfo.position,
                location: this.personalinfo.location,
                experience: this.personalinfo.experience,
                payMode: this.personalinfo.payMode,
                recruiterId: this.personalinfo.recruiterId,
                visaTypeId: this.personalinfo.visaTypeId,
                candidateEducation: this.personalinfo.educationinfo,
                timeZone: this.personalinfo.timeZone,
                candidateLanguage: this.personalinfo.candidateLanguage
            };
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/' + this.candidateid + '/personal', personalupdate)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CandidPerInfoUpSuc,
                        position: 'topRight'
                    });
                    this.loadCandidateById(this.candidateid);
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CandidUpErr,
                        position: 'topRight'
                    });
                });
        },
        saveContactInformation() {
            let contactupdate = {
                candidateAddress: {
                    currentAddress: this.contactinfo.currentaddress,
                    permanentAddress: this.contactinfo.permanentaddress,
                    emergencyContact: this.contactinfo.emergency
                }
            };
            console.log(contactupdate);
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/' + this.candidateid + '/contact', contactupdate)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CandidConInfoUpSuc,
                        position: 'topRight'
                    });
                    this.loadCandidateById(this.candidateid);
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CandidUpErr,
                        position: 'topRight'
                    });
                });
        },
        uploadNewResume() {
            if (this.resumeinfo.file != '') {
                let formData = new FormData();
                formData.append('file', this.resumeinfo.file);
                formData.append('id', this.candidateid);
                axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/uploadresume', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        iziToast.success({
                            title: 'Success',
                            message: this.$config.CandidResUpSuc,
                            position: 'topRight'
                        });
                        this.loadCandidateById(this.candidateid);
                    })
                    .catch(error => {
                        iziToast.error({
                            title: 'Error occured',
                            message: this.$config.CandidResUpErr,
                            position: 'topRight'
                        });
                    });
            } else {
                iziToast.info({
                    title: '',
                    message: this.$config.PlsUpRes,
                    position: 'topRight'
                });
            }
        },
        refreshCalendar() {
            this.showAvailabilityInfo = true
        }
    }
}

</script>
