<template>
    <div class="view-note">
        <b-modal scrollable ref="viewCallbackModal" size="lg" id="viewCallbackModal" title="Callback" ok-only @show="loadCallbacks">
            <form @submit.stop.prevent="addProject">
                <div class="basic-block">
                    <!-- <div class="form-group col-md-12 col-sm-12">
                        <p class="htmlText" v-html="candidateCallback.callbackNotes"></p>
                    </div>-->
                    <div class="row ten-columns">
                        <div class="col-md-4 col-sm-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <label class="font-weight-bold">Communication Mode</label>
                                </div>
                                <div class="col-md-6">
                                    <label>
                                        {{candidateCallback.callbackMode}}
                                    </label>
                                </div>
                            </div>
                        </div>
                         <div class="col-md-4 col-sm-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <label class="font-weight-bold">Callback Date</label>
                                </div>
                                <div class="col-md-6">
                                    <label>
                                        {{candidateCallback.callbackDate | formatDateTime}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ten-columns">
                        <div class="col-md-12 col-sm-6">
                            <div class="row">
                                <div class="col-md-2">
                                    <label class="font-weight-bold">Notes</label>
                                </div>
                                <div class="col-md-10">
                                    <label>
                                        <span class="htmlText" v-html="candidateCallback.callbackNotes"></span>
                                    </label>
                                </div>
                            </div>
                        </div>                      
                        
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    props: {
        referenceId: Number,
    },
    components: {

    },
    data() {
        return {
            candidateCallback: {}
        }
    },
    mounted() {

    },
    methods: {
        loadCallbacks() {
            axios.get(this.$store.getters.getAPIBasePath + '/candidateresource/view/candidate/callback/' + this.referenceId)
                .then(response => {
                    if (response.data.status) {
                        this.candidateCallback = response.data.candidateCallback;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
