<template>
    <div class="card">

        <div id="main">
            <div class="container-fluid" id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-6 col-sm-12 col-12">
                            <h4 class="page-title">Change History </h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col">Date</th>
                                            <th class="" scope="col">Modified By</th>
                                            <th class="" scope="col">Changes </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="title">23-Jun-2019</td>
                                            <td class="">Kayal</td>
                                            <td class="">Personal Info</td>
                                        </tr>
                                        <tr>
                                            
                                            <td class="">21-Jun-2019</td>
                                            <td class="">Kanmani</td>
                                            <td class="">Skill Info</td>
                                        </tr>
                                        <tr>
                                            
                                            <td class="title">23-Sep-2019 </td>
                                            <td class="">Pavithra</td>
                                            <td class="">Technical Info</td>
                                        </tr>
                                        <tr>
                                            
                                            <td>02-Aug-2019</td>
                                            <td class="">Arathi</td>
                                            <td class="">Personal Info</td>
                                        </tr>
                                        <tr>
                                           
                                            <td>03-Nov-2019</td>
                                            <td class="">Anbu</td>
                                            <td class="">Technical Info</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        changeHistoryInfo: Array,
    }
}

</script>
