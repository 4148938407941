<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="card-header p-2">
                    <div class="row">
                        <div class="col-md-6">
                            Add Interviewer
                        </div>
                        <div class="col-md-6 text-right">
                            {{interviewerinfo.interviewerCode}}
                        </div>
                    </div>
                </div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div v-if="false" class="row">
                            <div class="col-md-6 col-sm-8 col-6">
                                <p v-if="formErrors.length">
                                    <b>Please correct the following error(s):</b>
                                    <ul>
                                        <li class="error" v-for="error in formErrors">{{ error.message }}</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div class="row ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>First Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group required-field-block">
                                            <input type="text" name="firstName" maxlength="45" size="30" v-model="interviewerinfo.firstName" class="form-control " :class="{ 'is-invalid': $v.interviewerinfo.firstName.$error }">
                                            <span class="required-icon"></span>
                                        </div>
                                        <label v-if="$v.interviewerinfo.firstName.$error && !$v.interviewerinfo.firstName.minLength" class="text-danger">First Name must have at least {{$v.interviewerinfo.firstName.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.interviewerinfo.firstName.$error && !$v.interviewerinfo.firstName.required" class="text-danger">Please enter First Name</label>
                                        <label v-if="$v.interviewerinfo.firstName.$error && !$v.interviewerinfo.firstName.maxLength" class="text-danger">First Name must not exceed {{$v.interviewerinfo.firstName.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Last Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" name="lastName" maxlength="45" size="30" v-model="interviewerinfo.lastName" class="form-control">
                                            <span class="required-icon"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group required-field-block">
                                            <input type="text" name="primaryEmail" maxlength="80" v-model="interviewerinfo.email" class="Submitaddress form-control" :class="{ 'is-invalid': $v.interviewerinfo.email.$error }" @blur="$v.interviewerinfo.email.$touch">
                                            <span class="required-icon"></span>
                                        </div>
                                        <label v-if="$v.interviewerinfo.email.$error && !$v.interviewerinfo.email.required" class="text-danger">Please enter Email</label>
                                        <label v-if="$v.interviewerinfo.email.$error && !$v.interviewerinfo.email.email" class="text-danger">Please enter a valid email</label>
                                        <label v-if="emailexists" class="text-danger">Email already exists</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Mobile</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                    <PhoneNumber ref="phoneNumber" :value="interviewerinfo.interviewersContact.mobileNo" :mandatory="phoneMandatory" :isValidcheck="isValidCheck" @phone-input="phoneControlInput"/>
                                        <!-- <div class="input-group phone-border required-field-block">
                                            <select class="orm-control box-none col-sm-3 pl-0 pr-0 max-w-45 border-bottom-0" v-model="interviewerinfo.interviewersContact.countryCode">
                                                <option value="+1">+1</option>
                                                <option value="+91">+91</option>
                                            </select>
                                            <input-mask class="SubmitPhone form-control box-none border-bottom-0" v-model="interviewerinfo.interviewersContact.mobile" mask="(999)-999-9999" :alwaysShowMask="false" maskChar="_"></input-mask>
                                            <span class="required-icon"></span>
                                        </div>
                                        <label v-if="$v.interviewerinfo.interviewersContact.mobile.$error && !$v.interviewerinfo.interviewersContact.mobile.required" class="text-danger">Please enter Phone No.</label>                                   
                                        <label v-if="$v.interviewerinfo.interviewersContact.countryCode.$error && !$v.interviewerinfo.interviewersContact.countryCode.requiredIf" class="text-danger">Please enter phone number with country code</label>    -->
                                    </div>                                     
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Industry</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="interviewerinfo.industryId" @change="loadTechnologyList()" class="form-control">
                                            <option value="" selected>Select</option>
                                            <option v-for="(Industry, index) in industries" :value="Industry.id" v-bind:key="index">{{Industry.industryName}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Scope</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="interviewerinfo.scope" class="form-control">
                                            <option value="" selected>Select</option>
                                            <option v-for="(scope, index) in scopeList" :value="scope.id" v-bind:key="index">{{scope.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Timezone</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <Select2 v-model="interviewerinfo.timezone" :options="timezones" :settings="{width:'100%'}" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Specialization / Tools and Tech</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <multiselect v-model="technology_value" :options="technologies" name="technology_value" label="technology" track-by="id" select-label="" deselect-label="" @select="loadSkills" class="" style="width:92%; float:left;" :max-height="600"></multiselect>
                                        <!-- <a href="Javascript:" class="tbl-add-icon" style="float:left;" @click="newTech()"><span class="input-group-addon"><i class="ti-settings" title="Add new Technology"></i></span></a> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7 col-sm-12 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Skills</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <multiselect v-model="interviewerinfo.interviewersSkill" :options="skills" name="interviewerinfo.interviewersSkill" label="skillName" track-by="skillId" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" @input="onChange" style="width:92%; float:left;" :max-height="600"></multiselect>
                                        <!-- <a href="Javascript:" class="tbl-add-icon" style="float:left;" @click="newTech()"><span class="input-group-addon"><i class="ti-settings" title="Add new Skill"></i></span></a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 pt-2 mt-3 text-right">
                        <a href="Javascript:void(0);" @click="updateLogActivity" class="btn btn-secondary mr-2">
                            <router-link :to="{ name: 'interviewers' }" class="text-white">Cancel</router-link>
                        </a>
                        <a href="Javascript:void(0);" @mousedown="saveInterviewer" class="btn btn-primary ">Invite</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs, helpers, requiredIf } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import Select2 from 'v-select2-component';
const usPhone = helpers.regex('usPhone', /^\(\d{3}\)\s\d{3}-\d{4}$/)

export default {
    components: {
        Multiselect,
        Select2
    },
    computed: {
        dobDates() {
            let date = new Date();
            date.setFullYear(date.getFullYear() - 15);
            return {
                from: date
            };
        },
        interviewerId() {
            if (this.$route.params.interviewerid)
                return this.$route.params.interviewerid;
            else return 0;
        }
    },
    data() {
        return {
            formErrors: [],
            elements: {
                submitted: false
            },
            emailexists: false,
            // technologies:this.$store.getters.getTechOptions,
            scopeList: this.$store.getters.getInterviewerScopeList,
            industries: this.$store.getters.getIndustries,
            timezones: this.$store.getters.getTimeZones,
            technologies: [], //dropdown options
            skills: [], //dropdown options
            technology_value: "", //dropdown value
            availableDates: {
                to: new Date(Date.now() - 864000)
            },
            phoneMandatory:true,
            isValidCheck:false,
            interviewerinfo: {
                interviewerCode: "",
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                phoneNo: "",
                blockpersonaltab: 0,
                viewonly: 0,
                technology: "",
                scope: 1,
                industryId: "1",
                // countryCode: "+1",
                interviewersSkill: [], //dropdown value
                interviewersContact: {
                    mobileNo: "",
                },
                timezone: ""
            }
        }
    },
    validations: {
        interviewerinfo: {
            firstName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            email: {
                required,
                email
            },
  
        }
    },
    mounted() {
        this.loadTechnologyList();
        this.getInterviewerCode();
    },
    methods: {
        emailValidation(email) {

            if (email === '') return true;
            let ajaxdata = { email: email };
            if (this.interviewerinfo.profileid != undefined) {
                ajaxdata.id = this.interviewerinfo.profileid;
            }
            return axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/checkemailavailability', ajaxdata)
                .then(response => {
                    if (response.data.status) {
                        return true;
                    }
                    return false;
                })
                .catch(error => {
                    return false;
                });
        },
        getInterviewerCode() {
            axios.post(this.$store.getters.getAPIBasePath + '/commonresource/autoincrementcode/interviewer')
                .then(response => {
                    if (response.data.status) {
                        this.interviewerinfo.interviewerCode = response.data.code;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        newTech: function() {
            this.$router.push('/recruiter/settings/1');
        },
        loadTechnologyList() {
            if(this.interviewerinfo.industryId){
                axios.get(this.$store.getters.getAPIBasePath + '/technologyresource/get/industry/' + this.interviewerinfo.industryId)
                    .then(response => {
                        this.technologies = response.data.technology;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
        loadSkills(selected_technology) {
            this.skills = [];
            if (selected_technology.settingsTechnologySkill)
                this.skills = selected_technology.settingsTechnologySkill;
            else
                this.skills = [];
        },
        async saveInterviewer() {

            this.$v.interviewerinfo.$touch();
            if(!this.isValidCheck && this.$refs.phoneNumber.validatePhone()){
            if (!this.$v.interviewerinfo.$invalid) {
                let isexists = await this.emailValidation(this.interviewerinfo.email);
                if (!isexists) {
                    this.emailexists = true;
                    iziToast.info({ message:this.$config.EmailExistErr, position: 'topRight' });
                    return;
                }

                // if (this.interviewerinfo.interviewersContact.mobile != null && this.interviewerinfo.interviewersContact.mobile != '') {
                //     this.interviewerinfo.interviewersContact.mobileNo = this.interviewerinfo.interviewersContact.countryCode + " " + this.interviewerinfo.interviewersContact.mobile; 
                //     this.interviewerinfo.countryCode = this.interviewerinfo.interviewersContact.countryCode              
                // }

                axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/update/interviewer/' + this.interviewerId, this.interviewerinfo)
                    .then(response => {
                        let result = response.data;
                        if (!response.status) {
                            iziToast.error({
                                title: 'Error',
                                message: result.message,
                                position: 'topRight'
                            });
                        } else {
                            iziToast.success({
                                title: 'Success',
                                message: this.$config.IntDataSuc,
                                position: 'topRight'
                            });
                            this.$router.push('/interviewers');
                        }
                    })
                    .catch(error => {
                        elements.submitted = false;
                        this.formErrors = error.response.data.validationErrorList;
                        iziToast.error({
                            title: 'Error',
                            message: this.$config.CreInterErr,
                            position: 'topRight'
                        });
                    });
            }
        }
        },
        onChange() {
            // console.log(this.interviewerinfo.interviewersSkill);
        },
         phoneControlInput: function (value,isValid) {
            this.interviewerinfo.interviewersContact.mobileNo = value;
            this.isValidCheck = isValid;
        },
        addLogActivity: function(action) {
            this.$v.$reset();
            this.updateLogActivity();
            this.logActivityData = {
                entityName: 'Candidate',
                actionName: action,
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
