<template>
    <div id="main" class="template-settings settings recruiter mt-70">
        <div class="row col-md-12 box-wrapper shadow-sm my-4 mx-0 px-0 rounded pos-rel">
            <div class="col-md-3 settings-leftbar px-0 bg-white">
                <div class="p-4 row header mx-0">
                    <div class="col-md-9 px-0">
                        <h5>Tools & Settings</h5>
                    </div>
                    <div class="col-md-3 px-0 text-right">
                        <router-link v-if="type!=1" :to="{name: 'admindashboard'}">
                            <h5 class="text-blue"><span class="ti-arrow-left py-4" title="Go Back"></span><span class="pl-2">Back</span></h5>
                        </router-link>
                    </div>
                </div>
                <div @click="settingsTab" class="row col-md-12 py-4 mx-0 left-bar-tab" :class="elements.activeTab==1?'left-tab-active':''">
                    <div class="col-md-3">
                        <img src="@/assets/icons/settings-border.png" width="90%" height="90%">
                    </div>
                    <div class="col-md-8">Settings</div>
                </div>
                <div @click="techSkillsTab" class="row col-md-12 py-4 mx-0 left-bar-tab" :class="elements.activeTab==2?'left-tab-active':''">
                    <div class="col-md-3">
                        <img src="@/assets/icons/settings-border.png" width="90%" height="90%">
                    </div>
                    <div class="col-md-8">Technlogy / Skills</div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="basic-block p-4 bg-white">
                    <Settings v-if="elements.activeTab==1 && elements.subActiveTab==0" @setSubTab="setSubTab" />                   
                    <WorkAuthSettings v-if="elements.activeTab==1 && elements.subActiveTab==1" @setSubTab="setSubTab" />
                    <QualificationSettings v-if="elements.activeTab==1 && elements.subActiveTab==2" @setSubTab="setSubTab" />
                    <TaxTermsSettings v-if="elements.activeTab==1 && elements.subActiveTab==3" @setSubTab="setSubTab" />
                    <ClientAccessLevel v-if="elements.activeTab==1 && elements.subActiveTab==4" @setSubTab="setSubTab" />
                    <TechnologySkills v-if="elements.activeTab==2 && elements.subActiveTab==0" @setSubTab="setSubTab" />
                    <TechSkillSettings :type="type" v-if="elements.activeTab==2 && elements.subActiveTab==1" @setSubTab="setSubTab" />
                    <TechnologyStandards :type="type" v-if="elements.activeTab==2 && elements.subActiveTab==2" @setSubTab="setSubTab" />
                    <CountryBasedSettings v-if="elements.activeTab==1 && elements.subActiveTab==5" @setSubTab="setSubTab" />

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Settings from './settings/Settings';
import QualificationSettings from './settings/blocks/QualificationSettings';
import WorkAuthSettings from './settings/blocks/WorkAuthSettings';
import TaxTermsSettings from './settings/blocks/TaxTermsSettings';
import TechnologySkills from './settings/Technology&Skills';
import TechSkillSettings from './settings/blocks/TechSkillSettings';
import TechnologyStandards from './settings/blocks/TechnologyStandards';
import ClientAccessLevel from './settings/blocks/ClientAccessLevel';
import CountryBasedSettings from '@/components/recruiters/blocks/settings/CountryBasedSettings';
export default {
    components: {
        Settings,        
        QualificationSettings,
        WorkAuthSettings,
        TaxTermsSettings,
        TechnologySkills,
        TechSkillSettings,
        TechnologyStandards,
        ClientAccessLevel,
        CountryBasedSettings
    },
    computed: {
        type() {
            return this.$route.params.type;
        }
    },
    data() {
        return {
            elements: {
                activeTab: 1,
                subActiveTab: 0,
                action: "list",
                actionId: null
            },
            recownerid: this.$store.getters.currentUser.recruitOwner
        }
    },
    mounted() {
        this.elements.activeTab = 1;
        this.elements.subActiveTab = 0;
    },
    methods: {
        setSubTab: function(subTab) {
            this.elements.subActiveTab = subTab;
        },
        settingsTab: function() {
            this.elements.activeTab = 1;
            this.elements.subActiveTab = 0;
            this.elements.action = "list";
        },
        techSkillsTab: function(){
            this.elements.activeTab = 2;
            this.elements.subActiveTab = 0;
        },
        setAction: function(action, actionId = this.elements.actionId) {
            this.elements.action = action;
            this.elements.actionId = actionId;
        }
    }
}

</script>
