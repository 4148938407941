<template>
    <div id="content_Personal_Info">
        <div class="card">
            <div class="card-header p-2">Primary Information </div>
            <div class="card-body p-2">
                <div class="basic-block col-md-4">
                    <div class="table-responsive">
                        <table class="table table-striped table-borderless s_table compare-table">
                            <tbody>
                                <tr>
                                    <td width="50%"><strong>Name</strong></td>
                                    <td width="50%">{{ProfileData.firstName}} {{ProfileData.lastName}}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><strong>UserName (Email)</strong></td>
                                    <td width="50%">{{ProfileData.email}}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><strong>Phone</strong></td>
                                    <td width="50%">{{ProfileData.phoneNumber}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.p-2 {
    margin-right: 0.1rem;
}
</style>

<script>
export default {
    props: {
            ProfileData: Object,
        },
    data() {
        return {
            industries:this.$store.getters.getIndustries,
            EducationalInfos:[
                {
                    id:1
                }
            ]
        }
    },
    mounted(){
    },
    methods:
    {   
        getIndustryName:function(industryId){
            let industry_name = this.industries.filter(obj => {
                return(obj.id === industryId)
            });
            return industry_name[0].industryName;
        },
        getGenderName:function(genderCode){

        }
    }
}

</script>