<template>
    <div>
        <div class="card">
            <div class="card-header p-2">Client Details</div>
            <div class="card-body p-2">
                <div class="basic-block col-md-4">
                    <div class="table-responsive">
                        <table class="table table-striped table-borderless s_table compare-table">
                            <tbody>
                                <tr>
                                    <td width="50%"><strong>Client Name</strong></td>
                                    <td width="50%" class="text-center">{{clientinfo.clientName}}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><strong>Client Code</strong></td>
                                    <td width="50%" class="text-center">{{clientinfo.clientCode}}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><strong>Account Name</strong></td>
                                    <td width="50%" class="text-center">{{clientinfo.accountName}}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><strong>DUNS Number</strong></td>
                                    <td width="50%" class="text-center">{{clientinfo.dunsNumber}}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><strong>Website</strong></td>
                                    <td width="50%" class="text-center">{{clientinfo.url}}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><strong>Status</strong></td>
                                    <td width="50%" class="text-center">{{clientinfo.statusText}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">Description</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-control objective">{{clientinfo.description}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        clientinfo: Object,
    },
    data() {
        return {}
    },
    mounted(){
console.log(this.clientinfo)
    },
    methods: {}
}

</script>
