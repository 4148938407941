require('./bootstrap');

window.$ = require('jquery');

window.jQuery = require('jquery');

/*jquery plugins*/
require('./jqplugins/select/select');
require('./jqplugins/timepicki/timepicki');
/*jquery plugins*/

/* css blocks*/
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'izitoast/dist/css/iziToast.min.css';
import './jqplugins/select/select.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import './jqplugins/timepicki/timepicki.css';
/* css blocks*/

import Vue from 'vue';
import Router from 'vue-router';
import Vuex from 'vuex';
import BootstrapVue from 'bootstrap-vue';
import App from './App';
import routes from './router';
import StoreData from './store';
import Datepicker from 'vuejs-datepicker';
import Vuelidate from 'vuelidate';
import moment from 'moment-timezone';
import VueMask from 'v-mask';
import Default from "./layouts/Default.vue";
import Login from "./layouts/Login.vue";
import HomePages from "./layouts/HomePages.vue";
import Mobile from "./layouts/Mobile.vue";
import FullCalendar from "./components/plugins/FullCalendar.vue";
import ConfirmBox from "./components/plugins/ConfirmDialog.vue";

import Dialog from "./components/plugins/Dialog.vue";
import PhoneControl from '@/components/usercontrol/PhoneControl.vue';
import LoadingSpinner from "./components/animations/LoadingSpinner.vue";
import BDialog from "./components/plugins/BDialog.vue";
import Multiselect from 'vue-multiselect';
import VueMeta from 'vue-meta';
import VueRouter from 'vue-router';
import VueHead from 'vue-head';
import VueTimeSelector from 'vue-timeselector';
import VueAuthenticate from 'vue-authenticate';
import ProgressBar from 'vuejs-progress-bar';

Vue.use(ProgressBar);

var smShare = require('vue-social-sharing');
Vue.use(smShare);
Vue.use(VueMask);
Vue.use(Router);
Vue.use(Vuex);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(Multiselect);
Vue.use(VueMeta);
Vue.use(VueHead);
Vue.use(VueRouter);
Vue.use(VueTimeSelector);
Vue.config.productionTip = false;


Vue.component('default-layout', Default);
Vue.component('login-layout', Login);
Vue.component('home-layout', HomePages);
Vue.component('mobile-layout', Mobile);
Vue.component('datepicker', Datepicker);
Vue.component('fullcalendar', FullCalendar);
Vue.component('Dialog', Dialog);
Vue.component('confirm', ConfirmBox);
Vue.component('PhoneNumber', PhoneControl);
Vue.component('LoadingSpinner', LoadingSpinner);
Vue.component('BDialog', BDialog);

const store = new Vuex.Store(StoreData);

let router = new Router({
    routes,
    mode: 'history',
});
fetch(process.env.BASE_URL + "config.json")
    .then((response) => response.json())
    .then((config) => {
        Vue.prototype.$config = config
    });
Vue.use(VueAuthenticate, {
    providers: {
        google: {
            clientId: store.getters.getGoogleClientId,
            redirectUri: store.getters.getGoogleRedirectUrl,
            responseType: 'token'
        },
        facebook: {
            clientId: store.getters.getFacebookClientId,
            redirectUri: store.getters.getFacebookRedirectUrl,
            responseType: 'token',
            authorizationEndpoint: 'https://www.facebook.com/v3.0/dialog/oauth',
            popupOptions: { width: 452, height: 633 },
        }
    }
});

Vue.mixin({
    methods: {
        performLogin() {
            window.location.href = process.env.VUE_APP_MAIN_URL + "login";
        },
        formatMonth(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM');
            }
        },
        formatDateString(dateobj) {

            try {
                if (dateobj === null)
                    return '';
                return new Date(dateobj).toISOString().slice(0, 10);
            } catch (e) {
                return '';
            }
        },
        unAuthorized: function(error) {
            console.log(error.response)
            if (error.response && error.response.data.status == 'UNAUTHORIZED') {
                localStorage.clear();
                this.$store.dispatch("doLogout");
                this.$router.push('/login');
            }
        },
        replaceToSpace: function(data) {
            if (data != null) {
                data = data.replace("_", " ");
            }
            return data;
        },
        getFullName: function(firstname, lastname) {
            return firstname + ' ' + lastname;
        },
        getCandidateStatus(value) {
            let statusName = '';
            if (value != null) {
                switch (value) {
                    case 1:
                        statusName = "Draft";
                        break;
                    case 2:
                        statusName = "Reviewed";
                        break;
                    case 3:
                        statusName = "Screened";
                        break;
                    case 4:
                        statusName = "Evaluated";
                        break;
                    case 5:
                        statusName = "Public Post";
                        break;
                    case 6:
                        statusName = "Rejected";
                        break;
                    case 7:
                        statusName = "Referral Post";
                        break;
                    case 8:
                        statusName = "Verified";
                        break;
                    case 9:
                        statusName = "Prospect";
                        break;
                }
            }
            return statusName;
        },
        getPrevUrl() {
            this.$router.go(-1);
        },
        toClientTimeZoneDate(value, format) {
            if (value != null) {
                let zone = '';
                let userinfo = this.$store.getters.currentUser;
                // console.log(userinfo);
                if (userinfo.timezone != null && userinfo.timezone.length > 0) {
                    zone = userinfo.timezone;
                } else {
                    zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                }
                // console.log(value, format, zone);
                let getTime = moment(value).tz(zone).format('Z');
                let symbol = getTime.charAt(0);
                getTime = getTime.substring(1);
                let hourMin = getTime.split(":");
                let updatedDate = moment(moment(value)).add(symbol + hourMin[0], 'hours').add(symbol + hourMin[1], 'minutes').toDate();
                // console.log(updatedDate);
                return moment(updatedDate).format(format);
            }
        },
        toClientTimeToUTC(value, format) {
            let zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            // console.log(value, format, zone);
            // construct a moment object
            var updatedDate = moment.tz(value, format, zone);
            // convert it to utc
            updatedDate.utc();
            return moment(updatedDate).format(format);
        },
    }
});
Vue.filter('formatMonYear', function(value) {
    if (value) {
        return moment(String(value)).format('MMM, YYYY');
    }
});
Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MMM-YYYY');
    }
});
Vue.filter('formatTime', function(value) {
    if (value) {
        return moment(String(value), ["h:mm:ss"]).format('LT');
    }
});
Vue.filter('formatDuration', function(value) {
    if (value) {
        return moment(String(value), ["hh:mm"]).format('hh:mm');
    }
});
Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MMM-YYYY  hh:mm');
    }
});
Vue.filter('formatDateAndTime', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MMM-YYYY  hh:mm a');
    }
});
Vue.filter('formatCustomTime', function(value, type) {
    if (value)
        return moment(String(value)).format(type);
});
Vue.filter('getNameById', function(id, list) {
    let result_name = "";
    list.map(function(value, key) {
        if (value.id == id)
            result_name = value.name;
    });
    return result_name;
});
Vue.filter('getAcronym', function(keyName) {
    let keywords = [];
    let acronym = "";
    keywords = keyName.split(" ");
    let self = this;
    keywords.map(function(keyword) {
        acronym += keyword.charAt(0);
    });
    acronym = acronym.toUpperCase();
    return acronym;
});
Vue.filter('findIndexOf', function(array, condition) {
    const item = array.find(condition)
    return array.indexOf(item)
});
Vue.filter('getFilePath', function(fileName) {
    var filePath = require('@/assets/' + fileName);
    return filePath;
});

router.beforeEach((to, from, next) => {
    let activationPage = false;
    if (to.path.includes("interviewer/activation") || to.path.includes("recruiter/activation") || to.path.includes("candidate/register") ||
        to.path.includes("interviewer/register") || to.path.includes("job/add") || to.path.includes("referral/register") || to.path.includes("referral/activation") ||
        to.path.includes("share/job/view") || to.path.includes("resetpassword") || to.path.includes("candidate/confirmoffer")) {
        activationPage = true;
    }
    const publicPages = [
        '/',
        '/services',
        '/pricing',
        '/contact',
        '/privacy-policy',
        '/terms-of-service',
        '/login',
        '/interviewer/onboarding',
        '/admin/register',
        '/job/add',
        '/job/list',
        '/forgotpassword',
        '/auth/linkedin',
        '/mobile-privacy-policy',
        '/mobile-terms-of-service',
    ];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('userAuthData');
    const userinfo = store.getters.currentUser;
    const defaultredirects = store.getters.getDefaultRedirects;
    let defaultURL = '';
    if (authRequired && !loggedIn && !activationPage) {
        return next('/');
    }
    if (userinfo.userType == "interviewer") {
        defaultURL = defaultredirects.interviewer;
    } else {
        defaultURL = defaultredirects.recruiter;
    }
    if (to.path == publicPages[0] && loggedIn) {
        next(defaultURL);
    }
    next();
});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');

Vue.component('drop-box', {
    template: '<select/>',
    props: ['options'],
    mounted: function() {
        var self = this;
        $(this.$el).select2({
            data: self.options,
            disabled: false,
            multiple: true,
            width: '100%'
        });
        $(this.$el).on('select2:select select2:unselect', function(e) {
            self.$emit('selected-value', $(this).val());
        });
    },
    beforeDestroy: function() {
        $(this.$el).select2("destroy");
    }
});



Vue.component('timepicker', {
    template: '<input   v-bind:value="value"  class="form-control" />',
    data: function() {
        return {
            time: this.value
        }
    },
    props: ['value'],
    mounted: function() {
        this.renderTimePicki();
    },
    methods: {
        renderTimePicki: function() {
            var self = this;
            $(this.$el).timepicki({
                start_time: self.startTime($(self.$el).val()),
                overflow_minutes: true,
                increase_direction: 'up',
                disable_keyboard_mobile: false,
                input_writable: false,
                show_meridian: true,
                reset: true,
                on_change: function(e) {
                    self.$emit('selected-value', $(e).val());
                },
            });
        },
        setTime: function(value) {
            let formattedTime = this.startTime(value);
            $(this.$el).attr('data-timepicki-tim', formattedTime[0]);
            $(this.$el).attr('data-timepicki-mini', formattedTime[1]);
            $(this.$el).attr('data-timepicki-meri', formattedTime[2]);
        },
        startTime: function(value) {
            let ruturnTime;
            let splitMeri;
            let resultSplit;
            resultSplit = value.split(':');
            if (resultSplit.length == 2) {
                splitMeri = resultSplit[1].split(' ');
                if (splitMeri.length == 2) {
                    ruturnTime = [resultSplit[0], splitMeri[0], splitMeri[1]];
                }
            }
            return ruturnTime;
        }
    },
    beforeDestroy: function() {
        $(this.$el).timepicki("destroy");
    },
});