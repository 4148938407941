<template>
    <div class="referenceDetailPopup">

        <b-modal id="editUser" ref="editUserRef" centered @ok="saveData" @cancel="clearData" @close="clearData" @hide="clearData"
            modal-class="" title="Edit User" ok-title="Save">
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="card-body p-0 px-0 mx-0 mb-1">
                                <div class="col-lg-12 col-xl-12">


                                    <div class="row mb-2">
                                        <div class="col-4 col-md-3 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block">First Name</label>
                                        </div>
                                        <div class="col-8 col-md-8 pl-0">
                                            <div class="input-group required-field-block">
                                                <input v-model="userInfo.firstName" id="email" type="text"
                                                    class="form-control">
                                                <span class="required-icon"></span>
                                            </div>
                                            <label
                                                v-if="$v.userInfo.firstName.$error && !$v.userInfo.firstName.required"
                                                class="text-danger">First Name is required</label>
                                            <label
                                                v-if="$v.userInfo.firstName.$error && !$v.userInfo.firstName.minLength"
                                                class="text-danger">First Name must've minimum 2 characters</label>
                                            <label
                                                v-if="$v.userInfo.firstName.$error && !$v.userInfo.firstName.maxLength"
                                                class="text-danger">Maximum limit is 30 characters</label>
                                        </div>
                                    </div>

                                    <div class="row mt-0 mb-2">
                                        <div class="col-4 col-md-3 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block">Last Name</label>
                                        </div>
                                        <div class="col-8 col-md-8 pl-0">
                                            <input v-model="userInfo.lastName" id="text" type="email"
                                                class="form-control">
                                        </div>
                                    </div>

                                    <div class="row mt-0 mb-2">
                                        <div class="col-4 col-md-3 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block">Email</label>
                                        </div>
                                        <div class="col-8 col-md-8 pl-0">
                                            <div class="input-group required-field-block">
                                                <input v-model="userInfo.email" id="email" type="email"
                                                    class="form-control">
                                                <span class="required-icon"></span>
                                            </div>
                                            <label v-if="$v.userInfo.email.$error && !$v.userInfo.email.required"
                                                class="text-danger">Email is required</label>
                                            <label v-if="$v.userInfo.email.$error && !$v.userInfo.email.email"
                                                class="text-danger">Invalid Email</label>
                                            <label v-if="error" class="text-danger">Email already exists.</label>
                                        </div>
                                    </div>

                                    <div v-if="false" class="row mt-0 mb-2">
                                        <div class="col-4 col-md-3 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block">Timezone</label>
                                        </div>
                                        <div class="col-8 col-md-8 pl-0">
                                            <label class="p-2 mb-0 d-block header-details">{{ userInfo.timezone }}</label>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!--<b>Custom Footer</b> -->
            <template v-if="false" #modal-footer="">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="saveData()" class="float-right">Save</b-button>
                    <b-button size="sm" variant="primary" @click="clearData()"
                        class="float-right  mr-2">Cancel</b-button>
                    <!--  <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left mr-2">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                     <b-button title="Email Share" size="sm" variant="outline-primary"  class="float-left emailShare-icon">                        
                         <a :title="emailShareModalTitle" @click.prevent="showEmailShareDialog"  href="javascript:" >
                             <i class="fa fa-envelope action-link"></i>
                        </a>
                    </b-button> -->
                </div>
            </template>

        </b-modal>

    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
export default {
    name: 'editUser',
    props: {
        userData: Object
    },
    data() {
        return {
            loading: false,
            error: false,
            userInfo: {
                id: '',
                firstName: '',
                lastName: '',
                email: '',
                timezone: '',
                recruiterId: '',
                interviewerId: '',
                referralId: '',
                candidateId: ''
            }
        }
    },
    validations: {
        userInfo: {
            firstName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            email: {
                required,
                email,
            }
        }
    },
    mounted() {
        this.setData(this.userData);
    },
    methods: {
        saveData(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.error = false;
            this.$v.userInfo.$touch();
            if (!this.$v.userInfo.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/userresource/update/accountuser', this.userInfo)
                    .then((response) => {
                        console.log(response);
                        let result = response.data;
                        if (result.status == "OK" && result.message == 'User already exists') {
                            this.error = true;
                        } else {
                            this.$emit('refresh-user');
                            this.$refs.editUserRef.hide();
                        }

                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
            }

        },
        setData: function (user) {

            this.error = false;
            this.userInfo.id = user.id;
            this.userInfo.firstName = user.firstName;
            this.userInfo.lastName = user.lastName;
            this.userInfo.email = user.email;
            this.userInfo.timezone = user.timezone;
            this.userInfo.recruiterId = user.recruiterId;
            this.userInfo.interviewerId = user.interviewerId;
            this.userInfo.referralId = user.referralId;
            this.userInfo.candidateId = user.candidateId;
        },
        clearData: function () {

        }

    }
}
</script>