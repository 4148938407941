0<template>
    <div class="viewonly">
        <div id="main">
            <div class="container-fluid" id="main-data">
                <div class="row row-flex">
                    <div class="col-md-12 p-0">
                        <div class="box-wrapper shadow-sm p-4  bg-white rounded pos-rel h-100">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <h4 class="page-title">Interviewer Availability</h4>
                                </div>
                            </div>
                            <fullcalendar ref="availabilityCal" :calendarEvents="calendarEvents"></fullcalendar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        availabilityinfo: Object,
        profileId: Number
    },
    computed: {
        interviewerId() {
            if (this.$route.params.interviewerid)
                return this.$route.params.interviewerid;
            else return this.$store.getters.currentUser.profileId;
        }
    },
    data() {
        return {
            calendarEvents: []
        }
    },
    mounted() {
        this.loadAvilabilityList();
        this.refreshCalendar();
    },
    methods: {
        refreshCalendar() {
            this.$refs.availabilityCal.forceRerender();
        },
        loadAvilabilityList() {
            axios.get(this.$store.getters.getAPIBasePath + '/interviewavailability/availabilityList/' + this.interviewerId)
                .then(response => {
                    let result = response.data;
                    if (!response.status) {
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    this.calendarEvents = result;
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.CandidAvailLoadErr,
                        position: 'topRight'
                    });
                });
        }
    }
}

</script>
