<template>
	<div id="main" class="add-recruiter mt-80">
		<div class="container-fluid" id="quick-search">
			<div class="col-md-4 offset-md-4">
				<h5 v-if="false" style="background-color: rgb(55, 160, 228);" class="text-white text-center p-3 mb-5">TT Client Account Registration</h5>
				<div class="box-wrapper p-4 my-5 bg-white rounded pos-rel">
					<h4 class="text-color text-left">Client Account Registration</h4>
					<hr class="hrline-border">
					<div class="row form-group py-3">
						<div class="col-md-6 px-1">
							<div class="col-md-12 col-sm-12 col-12">
								<div class="input-group required-field-block">
									<input type="text" name="firstName" placeholder="First Name" v-model="recruiterInfo.firstName" maxlength="45" size="30" class="form-control box-none" />
									<span class="required-icon"></span>
								</div>
							</div>
							<div class="col-md-12 col-sm-12 col-12">
								<label v-if="elements.submitted && !$v.recruiterInfo.firstName.required" class="text-danger">First Name is required</label>
								<label v-if="elements.submitted && !$v.recruiterInfo.firstName.minLength" class="text-danger">First Name must've minimum 2 characters</label>
								<label v-if="elements.submitted && !$v.recruiterInfo.firstName.maxLength" class="text-danger">Maximum limit is 30 characters</label>
							</div>
						</div>
						<div class="col-md-6 px-1">
							<div class="col-md-12 col-sm-12 col-12">
								<div class="input-group required-field-block">
									<input type="text" name="lastName" placeholder="Last Name" v-model="recruiterInfo.lastName" maxlength="45" size="30" class="form-control box-none" />
									<span class="required-icon"></span>
								</div>
							</div>
							<div class="col-md-12 col-sm-12 col-12">
								<label v-if="elements.submitted && !$v.recruiterInfo.lastName.required" class="text-danger">Last Name is required</label>
								<label v-if="elements.submitted && !$v.recruiterInfo.lastName.minLength" class="text-danger">Last Name must've minimum 1 characters</label>
								<label v-if="elements.submitted && !$v.recruiterInfo.lastName.maxLength" class="text-danger">Maximum limit is 30 characters</label>
							</div>
						</div>
					</div>
					<div class="row form-group py-3">
						<div class="col-md-6 px-1">
							<div class="col-md-12 col-sm-12 col-12">
								<div class="input-group required-field-block">
									<input type="text" name="accountName" placeholder="Account Name" v-model="recruiterInfo.accountName" maxlength="45" size="30" class="form-control box-none" />
									<span class="required-icon"></span>
								</div>
							</div>
							<div class="col-md-12 col-sm-12 col-12">
								<label v-if="elements.submitted && !$v.recruiterInfo.accountName.required" class="text-danger">Account Name is required</label>
								<label v-if="elements.submitted && !$v.recruiterInfo.accountName.minLength" class="text-danger">Account Name must've minimum 2 characters</label>
								<label v-if="elements.submitted && !$v.recruiterInfo.accountName.maxLength" class="text-danger">Maximum limit is 30 characters</label>
							</div>
						</div>
						<div class="col-md-6 px-1">
							<div class="col-md-12 col-sm-12 col-12">
								<div class="input-group required-field-block">
									<select class="form-control box-none" name="accountType" v-model="recruiterInfo.accountType">
										<option value="">Account Type</option>
										<option v-for="Options in accountType" v-bind:key="Options.id" :value="Options.id" :disabled="Options.name!='Company'">{{Options.name}}</option>
									</select>
								</div>
							</div>
							<div class="col-md-12 col-sm-12 col-12">
								<label v-if="elements.submitted && !$v.recruiterInfo.accountType.required" class="text-danger">Account Type is required</label>
							</div>
						</div>
					</div>
					<div class="row form-group py-3">
						<div class="col-md-6 px-1">
							<div class="col-md-12 col-sm-12 col-12">
								<div class="input-group required-field-block">
									<input type="email" name="email" placeholder="Email" v-model="recruiterInfo.email" maxlength="45" size="30" class="form-control box-none"/>
									<span class="required-icon"></span>
								</div>
							</div>
							<div class="col-md-12 col-sm-12 col-12">
								<label v-if="elements.submitted && !$v.recruiterInfo.email.required" class="text-danger">Email is required</label>
								<label v-if="elements.submitted && !$v.recruiterInfo.email.email" class="text-danger">Invalid Email</label>
								<label v-if="elements.submitted && $v.recruiterInfo.email.email && !$v.recruiterInfo.email.emailexists" class="text-danger">Email already exists</label>
							</div>
						</div>
						<div class="col-md-6 px-1">
							<div class="col-md-12 col-sm-12 col-12">
								<div class="input-group required-field-block">
									<input id="phoneNo" type="text" name="phoneNo" placeholder="Phone No." v-model="recruiterInfo.phoneNo" maxlength="10" size="30" class="form-control box-none" @keypress="formatUsPhone($event,'#phoneNo')" @blur="$v.recruiterInfo.phoneNo.$touch"/>
								</div>
							</div>
							<div class="col-md-12 col-sm-12 col-12">
								<label v-if="elements.submitted && !$v.recruiterInfo.phoneNo.usPhone" class="text-danger">Invalid Phone No.</label>
							</div>
						</div>
					</div>
					<div class="row form-group py-3">
						<div class="col-md-6 px-1">
							<div class="col-md-12 col-sm-12 col-12">
								<div class="input-group required-field-block">
									<input type="text" name="companyName" placeholder="Company Name" v-model="recruiterInfo.companyName" maxlength="45" size="30" class="form-control box-none" />
									<span class="required-icon"></span>
								</div>
							</div>
							<div class="col-md-12 col-sm-12 col-12">
								<label v-if="elements.submitted && !$v.recruiterInfo.companyName.required" class="text-danger">Company Name is required</label>
								<label v-if="elements.submitted && !$v.recruiterInfo.companyName.minLength" class="text-danger">Company Name must've minimum 2 characters</label>
								<label v-if="elements.submitted && !$v.recruiterInfo.companyName.maxLength" class="text-danger">Maximum limit is 30 characters</label>
							</div>
						</div>
						<div class="col-md-6 px-1">
							<div class="col-md-12 col-sm-12 col-12">
								<select class="form-control box-none" name="country" v-model="recruiterInfo.country">
									<option value="">Country</option>
									<option v-for="Country in Countries" v-bind:key="Country.country_Id" :value="Country.country_Id">{{Country.name}}</option>
									<!-- <option v-for="State in USStates" v-bind:key="State.id" :value="State.id">{{State.stateName}}</option> -->
								</select>
							</div>
						</div>
					</div>
					<div class="row form-group py-3">
						<div class="col-md-12 col-sm-12 col-12">
							<div class="input-group required-field-block">
								<multiselect v-model="recruiterInfo.timezone" :options="timeZones" placeholder="Time Zone" name="timezone" label="text" track-by="id" select-label="" deselect-label="" class="form-control box-none"></multiselect>
							</div>
						</div>
					</div>
					<div class="p-2"><a href="javascript:" @click="saveRecruiter" class="btn btn-success float-right">Save</a></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
	import iziToast from 'izitoast';
	const usPhone = helpers.regex('usPhone', /^\(\d{3}\)\s\d{3}-\d{4}$/);
	import Multiselect from 'vue-multiselect';

	export default {
		components: {
			Multiselect
		},
		data(){
			return {
				elements: {
					submitted: false
				},
				Countries:this.$store.getters.getCountries,
				USStates:this.$store.getters.getUSStates,
				timeZones: this.$store.getters.getTimeZones,
				accountType: this.$store.getters.getAccountTypeOptions,
				recruiterInfo: {
					firstName: "",
					lastName: "",
					accountName: "",
					companyName: "",
					accountType: "2",
					email: "",
					phoneNo: "",
					zipcode: "",
					country: "US",
					timezone: ""
				}
			}
		},
		validations: {
			recruiterInfo:{
				firstName: {
					required,
					minLength: minLength(2),
					maxLength: maxLength(30)
				},
				lastName: {
					required,
					minLength: minLength(1),
					maxLength: maxLength(30)
				},
				accountName: {
					required,
					minLength: minLength(2),
					maxLength: maxLength(30)
				},
				companyName: {
					required,
					minLength: minLength(2),
					maxLength: maxLength(30)
				},
				accountType: {
					required
				},
				email: {
					required,
					email,
					emailexists(email){
						if (email === '') return true;
						let ajaxdata = {email:email};
						// if(this.interviewerinfo.profileid!=undefined){
						// 	ajaxdata.id = this.interviewerinfo.profileid;
						// }
						return axios.post(this.$store.getters.getAPIBasePath+'/recruiterresource/checkemailavailability',ajaxdata)
						.then(response => {
							if(response.data.status){
								return true;
							}
							return false;
						})
						.catch(error => {
							return false;
						});
					}
				},
				phoneNo: {
					usPhone
				}
				/*zipcode: {
					// minLength: minLength(5),
					// maxLength: maxLength(5),
					uszip(zipcode){
						// return /^\d{5}(-\d{4})?$/.test(zipcode);
						return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(zipcode);
					}
				}*/
				/*country: {

				},
				timezone: {

				}*/
			}
		},
		methods: {
			formatUsPhone: function(evt, idx)
			{
				evt = (evt) ? evt : window.event;
				var charCode = (evt.which) ? evt.which : evt.keyCode;
				if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
					evt.preventDefault();
				}
				else {
					var curchr = $(idx).val().length;
					var curval = $(idx).val();
					if (curchr == 3 && curval.indexOf("(") <= -1) {
						$(idx).val("(" + curval + ")" + " ");
					} else if (curchr == 4 && curval.indexOf("(") > -1) {
						$(idx).val(curval + ")-");
					} else if (curchr == 5 && curval.indexOf(")") > -1) {
						$(idx).val(curval + "-");
					} else if (curchr == 9) {
						$(idx).val(curval + "-");
						$(idx).attr('maxlength', '14');
					}
					return true;
				}
			},
			saveRecruiter:function(){
				this.elements.submitted = true;
				this.$v.recruiterInfo.$touch();
				if(this.$v.recruiterInfo.$invalid){
					iziToast.error({title: 'Error', message: this.$config.FieldRequir, position:'topRight'});
					return;
				}
				axios.post(this.$store.getters.getAPIBasePath+'/recruiterresource/add/recruiter',this.recruiterInfo)
				.then(response => {
					if(response.data.status == "ACCEPTED"){
						iziToast.success({title: 'Success', message: this.$config.RecAddSuc, position:'topRight'});
					} else{
						iziToast.error({title: 'Error', message: this.$config.Updateerr, position:'topRight'});
					}
				})
				.catch(error => {
					console.log(error);
					iziToast.error({title: 'Error', message: this.$config.Updateerr, position:'topRight'});
				});
				this.elements.submitted = false;
			}
		}
	}
</script>