<template>
	<div :class="{ 'viewonly' : preferenceinfo.viewonly == 1}">
		<div class="card">
		    <div class="card-header p-2">Preference</div>
		    <div class="card-body p-2">
		    	<div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col">Title</th>
                                            <th class="videoprofile" scope="col">State</th>
                                            <th class="title" scope="col">City</th>
                                            <th class="recruiter" scope="col">Technology/Skills</th>
                                            <th class="state" scope="col">Travel</th>
                                            <th class="" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="cloned_tr">
                                    	<tr v-for="(info, index) in preferenceinfo.basepreference">
                                    		<td>
                                    			<input v-model="preferenceinfo.basepreference[index].title" maxlength="100"  class="form-control" type="text"/>
                                    		</td>
                                            <td class="lastnote">
                                            	<input v-model="preferenceinfo.basepreference[index].stateName" maxlength="25"  class="form-control" type="text"/>
                                            </td>
                                            <td class="recruiter">
                                            	<input v-model="preferenceinfo.basepreference[index].city" maxlength="20"  class="form-control" type="text"/>
                                            </td>
                                            <td class="title">
                                            	<input v-model="preferenceinfo.basepreference[index].skills" maxlength="500"  class="form-control" type="text"/>
                                            </td>
                                            <td class="specialityunit">
                                            	<select v-model="preferenceinfo.basepreference[index].canTravel" class="form-control">
				                            		<option value="">Select</option>
				                            		<option value="yes">Yes</option>
				                            		<option value="no">No</option>
				                            	</select>
                                            </td>
                                            <td>

												<a :class="{ 'removeaddbtn' : preferenceinfo.viewonly == 1}" class="removemoretextbox" href="Javascript:void(0);" data-toggle="modal" data-target="#removPrefer" @click="confirmPrefer(index,0)"><span class="ti-trash"></span></a>
                                            </td>
                                    	</tr>
                                        <tr class="fill" :class="{ 'noaddedmore' : preferenceinfo.viewonly == 1}">
                                            <td>
                                            	<input v-model="newpreference.title" ref="addcandidate_preferenceinfo_add_title" class="form-control" maxlength="100"  type="text"/>
                                            </td>
                                            <td class="lastnote">
                                            	<input class="form-control" type="text" maxlength="25"  v-model="newpreference.stateName"/>
                                            </td>
                                            <td class="recruiter">
                                            	<input class="form-control" type="text" maxlength="20"  v-model="newpreference.city"/>
                                            </td>
                                            <td class="title">
                                            	<input class="form-control" type="text" maxlength="500"  v-model="newpreference.skills"/>
                                            </td>
                                            <td class="specialityunit">
                                            	<select v-model="newpreference.canTravel" class="form-control">
				                            		<option value="">Select</option>
				                            		<option value="yes">Yes</option>
				                            		<option value="no">No</option>
				                            	</select>
                                            </td>
                                            <td>
                                                <a class="addmoretextbox" href="Javascript:void(0);" @click="addNewPreferenceInfo"><span class="ti-plus"></span></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
		    </div>
		</div>
		<div class="card">
		    <div class="card-header p-2">Other Preference</div>
		    <div class="card-body p-2">
		        <div class="basic-block">
		            <div class="row ten-columns">
		                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
		                    <div class="row ten-columns">
		                        <div class="col-md-12 col-sm-12 col-12 lb">
		                            <label for="tc_type">Best Time to Call</label>
		                        </div>
		                        <div class="col-md-12 col-sm-12 col-12">
		                            <div class="input-group date" data-date-format="mm-dd-yyyy">
										<b-form-input id="startTime" v-model="preferenceinfo.otherpreference.timetocall" type="time" class="form-control" name="timetocall"></b-form-input>
									</div>
		                        </div>
		                    </div>
		                </div>
		                <div class="col-md-2 col-sm-2 col-12 mb-10-mr p-r">
		                    <div class="row p-a ten-columns">
		                        <div class="col-sm-12 col-12">
		                            <b-form-group label=" ">
										<b-form-radio v-model="preferenceinfo.otherpreference.parttime" @input="preferenceinfo.otherpreference.fulltime=(preferenceinfo.otherpreference.parttime=='yes'?'no':'yes')" name="parttime_fulltime" value="yes">Part Time?</b-form-radio>
									</b-form-group>
		                        </div>
		                    </div>
		                </div>
		                <div class="col-md-2 col-sm-2 col-12 mb-10-mr p-r">
		                    <div class="row p-a ten-columns">
		                        <div class="col-sm-12 col-12">
		                            <b-form-group label=" ">
										<b-form-radio v-model="preferenceinfo.otherpreference.fulltime" @input="preferenceinfo.otherpreference.parttime=(preferenceinfo.otherpreference.fulltime=='yes'?'no':'yes')" name="parttime_fulltime" value="yes">Full Time?</b-form-radio>
									</b-form-group>
		                        </div>
		                    </div>
		                </div>
		            </div>
		        </div>
		    </div>
		</div>
		<Dialog id="removPrefer" :onYes="deletePrefer" :returnParams="delPreferParam" />
	</div>
</template>

<script>
	export default {
		props:{
			preferenceinfo:Object
		},
		data(){
			return {
				newpreference:{
					title:"",
					stateName:"",
					city:"",
					skills:"",
					canTravel:"",
					zipCode:"",
					countryId:"US"
				},
                delPreferParam:{
                    index:-1,
                    id:0
				}
			}
		},
		methods:{
			resetNewForm(){
				
				this.newpreference = {
					title:"",
					stateName:"",
					city:"",
					skills:"",
					canTravel:"",
					zipCode:"",
					countryId:"US"
				};
				this.$refs.addcandidate_preferenceinfo_add_title.focus();
			},
			addNewPreferenceInfo(){
				this.newpreference.title = this.newpreference.title.trim();
                this.newpreference.stateName = this.newpreference.stateName.trim();
                this.newpreference.city = this.newpreference.city.trim();
                this.newpreference.skills = this.newpreference.skills.trim();
				if(this.newpreference.title!="" || this.newpreference.stateName!="" || this.newpreference.city!="" || this.newpreference.skills!="" || this.newpreference.canTravel!=""){
					this.preferenceinfo.basepreference.push(this.newpreference);
					this.resetNewForm();
				}
			},
			deletePrefer:function(arg)
            {                
                this.preferenceinfo.basepreference.splice(arg.index,1);                
            },
            confirmPrefer:function(arg1, arg2)
            {
                this.delPreferParam.index=arg1;
                this.delPreferParam.id=arg2;
			}
		}
	}
</script>