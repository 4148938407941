<template>
    <div class="referenceDetailPopup">

        <b-modal id="addData" ref="addCallerRef" centered @ok="addCallerData"  @hide="clearData" modal-class="" title="Edit User" ok-only ok-title="Save" >
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="card-body p-0 px-0 mx-0 mb-1">
                                <div class="col-lg-12 col-xl-12">                                   
                                    
                      
                                        <div class="row mb-2">
                                            <div class="col-4 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block">Account Id</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <div class="input-group required-field-block">  
                                                    <input v-model="addcallerinfo.accountId" id="email" type="text" class="form-control">
                                                    <span class="required-icon"></span>
                                                </div>                                       
                                                <label v-if="$v.addcallerinfo.accountId.$error && !$v.addcallerinfo.accountId.required" class="text-danger">Account Id is required</label>
                                                <label v-if="$v.addcallerinfo.accountId.$error && !$v.addcallerinfo.accountId.minLength" class="text-danger">First Name must've minimum 2 characters</label>
                                                <label v-if="$v.addcallerinfo.accountId.$error && !$v.addcallerinfo.accountId.maxLength" class="text-danger">Maximum limit is 30 characters</label>
                                            </div>                                         
                                        </div>
               
                                        <div class="row mt-0 mb-2">
                                            <div class="col-4 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block">Phone Number</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <div class="input-group required-field-block">
                                                    <input v-model="addcallerinfo.phoneNumber" id="email" type="text" class="form-control">
                                                    <span class="required-icon"></span>
                                                </div>                                       
                                                <label v-if="$v.addcallerinfo.phoneNumber.$error && !$v.addcallerinfo.phoneNumber.required" class="text-danger">phone Number is required</label>
                                               
                                            </div>                                                                          
                                        </div>

                                        <div class="row mt-0 mb-2">
                                            <div class="col-4 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block">Country Code</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <div class="input-group required-field-block">
                                                    <input v-model="addcallerinfo.countryCode" id="email" type="text" class="form-control">
                                                    <span class="required-icon"></span>
                                                </div>                                       
                                                <label v-if="$v.addcallerinfo.countryCode.$error && !$v.addcallerinfo.countryCode.required" class="text-danger">Country Code is required</label>
                                               
                                            </div>                                                                          
                                        </div>

                                         <div class="row mt-0 mb-2">
                                            <div class="col-4 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block">Caller Id</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <div class="input-group required-field-block">
                                                    <input v-model="addcallerinfo.callerId" id="email" type="text" class="form-control">
                                                    <span class="required-icon"></span>
                                                </div>                                       
                                                <label v-if="$v.addcallerinfo.callerId.$error && !$v.addcallerinfo.callerId.required" class="text-danger">Caller Id is required</label>
                                               
                                            </div>                                                                          
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!--<b>Custom Footer</b> -->
            <template v-if="false" #modal-footer="">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="addCallerData()" class="float-right" >Save</b-button>
                    <b-button size="sm" variant="primary" @click="clearData()" class="float-right  mr-2" >Cancel</b-button>
                
                </div>
            </template>

        </b-modal>
       
    </div>
</template>
<script>
import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {

 name: 'addData',

 props: {  
      
    },

    data() {

        return {
            loading: false,
            error:false,
            addcallerinfo:{
                accountId: '',
                phoneNumber: '',
                callerId: '',
                countryCode: '',
                recruiterId:this.$store.getters.currentUser.profileId,
                id:0
            }
        }
     },
      validations: {
        addcallerinfo: {
            accountId: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            phoneNumber: {
                required
            },
             callerId: {
                required
            },
             countryCode: {
                required
            }
        }
    },

   mounted(){
       
    },

     methods: {

         addCallerData(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.$v.addcallerinfo.$touch();
             console.log("outside if");
             if(!this.$v.addcallerinfo.$invalid){
                 console.log("inside if");
            axios.post(this.$store.getters.getAPIBasePath + '/exotel/add', this.addcallerinfo)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {      
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CliInfoCreSuc,
                        position: 'topRight'
                       
                    });
                    this.$emit('refresh-caller');
                     this.$refs.addCallerRef.hide();
                    }
                })
             }
        },
         clearData:function(){

        },

     },

}

</script>