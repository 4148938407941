<template>
    <div class="email-template-list text-center">
        <div class="row col-md-12 mx-0 px-0">
            <div class="col-md-3 offset-md-4">
                <h5>Email Templates List</h5>
            </div>
            <div class="col-md-2 offset-md-3 px-0 align-self-center">
                <a href="javascript:" class="pull-right btn-back text-blue" @click="getPrevUrl()">
                    <h5><i class="ti-arrow-left"></i> Back</h5>
                </a>
            </div>
        </div>
        <div class="row col-md-12 col-sm-12 col-12 py-3 mx-0">
            <div class="col-md-6 col-sm-6 col-6 text-left p-0">
                <h4 class="page-title">Templates
                    <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.emailtotalRecords}} - Total)</span>
                    <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                        <div class="dropdown">
                            <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                            <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                <a v-for="(value, index) in pagination.searchlimit" class="dropdown-item" href="javascript:" @click="changeLimit(value)">{{value}}</a>
                            </div>
                        </div>
                    </span>
                </h4>
            </div>
            <div class="col-md-6 col-sm-6 col-6 p-0">
                <button type="button" class="btn btn-primary btn-sm pull-right"><a href="javascript:" class="text-white" @click="$emit('addTemplate')">New Template</a></button>
            </div>
        </div>
        <div class="col-md-12 table-responsive py-3">
            <table class="table table-borderless all-table text-left">
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Scope</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="templatesList.length==0">
                        <td align="center" colspan="5">No records found</td>
                    </tr>
                    <tr v-if="templatesList.length" v-for="(Template,index) in templatesList">
                        <td>{{((pagination.currentPage-1) * (pagination.rowsPerPage)) + (index+1)}}</td>
                        <td>{{Template.template_Name}}</td>
                        <td>{{Template.template_Type | getNameById($store.getters.getTemplateTypesList)}}</td>
                        <td>{{Template.access_Level | getNameById($store.getters.getEmailTemplateScope)}}</td>
                        <td>
                            <ul class="navbar-nav">
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li>
                                            <a href="javascript:" @click="$emit('viewTemplate',Template.id)" class="dropdown-item"><span class="ti-eye pr-2"></span> View</a>
                                        </li>
                                        <li>
                                            <a href="javascript:" @click="$emit('editTemplate',Template.id)" class="dropdown-item"><span class="ti-pencil pr-2"></span> Edit</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-12 mb-5">
            <b-pagination v-if="templatesList.length!=0" size="md" class='pull-right' :total-rows="pagination.emailtotalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="$emit('getEmailTemplatesList')"></b-pagination>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    props: {
        templatesList: Array,
        pagination: Object
    },
    watch: {
        templatesList(newValue, oldValue) {}
    },
    mounted() {
        this.$emit('getEmailTemplatesList');
    },
    methods: {
        getPrevUrl() {
            this.$router.go(-1);
        },
        changeLimit: function(newValue) {
            this.$emit('changeLimit', newValue);
            this.$emit('getEmailTemplatesList');
        }
    }
}

</script>
