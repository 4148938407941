<template>
	<div id="main" class="template-settings settings recruiter mt-70">
		<div class="row col-md-12 box-wrapper shadow-sm my-4 mx-0 px-0 rounded pos-rel">
			<div class="col-md-3 settings-leftbar px-0 bg-white" id="social-media-accordion">
				<div class="p-4 row header mx-0">
					<div class="col-md-9 px-0"><h5>Template Management</h5></div>
					<div class="col-md-3 px-0 text-right">
						<router-link :to="{name: 'recruitersettings'}">
							<h5 class="text-blue">
								<span class="ti-arrow-left py-4" title="Go Back"></span>
								<span class="pl-2">Back</span>
							</h5>
						</router-link>
					</div>
				</div>
				<div @click="resetEmailTemplates" class="row col-md-12 py-4 mx-0 left-bar-tab" :class="elements.activeTab==1?'left-tab-active':''">
					<div class="col-md-3">
						<img src="@/assets/icons/mail-icon.png" width="60%" height="80%">
					</div>
					<div class="col-md-8">Email Templates</div>
				</div>
				<div v-if="false" @click="elements.activeTab=2" class="row col-md-12 py-4 mx-0 left-bar-tab" :class="elements.activeTab==2?'left-tab-active':''">
					<div class="col-md-3">
						<img src="@/assets/icons/sms-icon.png" width="60%" height="80%">
					</div>
					<div class="col-md-8">SMS Templates</div>
				</div>
				<!-- <div @click="elements.activeTab=3" class="row col-md-12 py-4 mx-0 left-bar-tab" :class="elements.activeTab==3?'left-tab-active':''">
					<div class="col-md-3">
						<img src="@/assets/icons/facebook-icon.png" width="60%" height="80%">
					</div>
					<div class="col-md-8">Facebook Templates</div>
				</div> -->
				<!-- <div @click="elements.activeTab=4" class="row col-md-12 py-4 mx-0 left-bar-tab" :class="elements.activeTab==4?'left-tab-active':''">
					<div class="col-md-3">
						<img src="@/assets/icons/twitter-icon.png" width="60%" height="80%">
					</div>
					<div class="col-md-8">Twitter Templates</div>
				</div> -->
				<!-- <div @click="elements.activeTab=6" class="row col-md-12 py-4 mx-0 left-bar-tab" :class="elements.activeTab==6?'left-tab-active':''">
					<div class="col-md-3">
						<img src="@/assets/icons/share.png" width="70%" height="80%">
					</div>
					<div class="col-md-8">Social Media Share</div>
				</div> -->
				<div  v-if="false" @click="resetSocialMediaTemplates(6,0)" class="row col-md-12 py-4 mx-0 left-bar-tab accordion-header collapsed" data-toggle="collapse" data-parent="#social-media-accordion" href="#socialmedia">
					<div class="col-md-3">
						<img class="p-1" src="@/assets/icons/share.png" width="70%" height="80%">
					</div>
					<div class="col-md-8 align-self-center">Social Media Templates</div>
				</div>
				<div class="row col-md-12 px-0 mx-0 collapse" data-parent="social-media-accordion" id="socialmedia">
					<div class="d-flex flex-column mb-3 col-md-12 mx-0">
						<div @click="resetSocialMediaTemplates(6,1)" class="socialmedia-btn m-2 hover-hand" style="background-image: linear-gradient(to right, #3b5998,#fff);">
							<span class="logo-span ti-facebook mx-2"></span><span class="title-span mx-2">Facebook</span>
						</div>
						<div @click="resetSocialMediaTemplates(6,3)" class="socialmedia-btn m-2 hover-hand" style="background-image: linear-gradient(to right, #007bb6,#fff);">
							<span class="logo-span ti-linkedin mx-2"></span><span class="title-span mx-2">LinkedIn</span>
						</div>
						<div @click="resetSocialMediaTemplates(6,2)" class="socialmedia-btn m-2 hover-hand" style="background-image: linear-gradient(to right, #00aced,#fff);">
							<span class="logo-span ti-twitter-alt mx-2"></span><span class="title-span mx-2">Twitter</span>
						</div>
					</div>
				</div>
				<!-- <div class="row col-md-12 px-0 mx-0 collapse" data-parent="social-media-accordion" id="socialmedia">
					<div class="d-flex justify-content-right bd-highlight">
						<div class="m-4 bd-highlight socialmedia-tab-icon" v-for="socialMedia in data.socialmedia.socialMediaList">
							<img @click="filterArrayByValue(data.socialmedia.templatesList, 'this.data.socialmedia.templatesList', 'socialMediaId', socialMedia.id)" class="p-3" :src="getSocialMediaImage(socialMedia.logo)" width="100%" height="100%" />
						</div>
					</div>
				</div> -->
			</div>
			<div class="col-md-9">
				<div class="basic-block text-center p-4 col-md-12 bg-white">
					<EmailTemplates v-if="elements.activeTab==1 && elements.showList"
					:pagination="pagination"
					@changeLimit="changeLimit"
					@getEmailTemplatesList="getEmailTemplatesList"
					:templatesList="data.email.templatesList"
					@addTemplate="addTemplate"
					@viewTemplate="viewTemplate"
					@editTemplate="editTemplate"/>
					<SmsTemplates v-if="elements.activeTab==2" />
					<SocialMediaTemplates v-if="elements.activeTab==6 && elements.showList"
					:pagination="pagination"
					@changeLimit="changeLimit"
					@resetSocialMedia="resetSocialMediaTemplates"
					:templatesList="data.socialmedia.templatesList"
					:socialMediaList="data.socialmedia.socialMediaList"
					@addTemplate="addTemplate"
					@viewTemplate="viewTemplate"
					@editTemplate="editTemplate"/>
					<!-- <SocialMediaTemplates v-if="elements.activeTab==6"
					:pagination="pagination"
					@changeLimit="changeLimit"
					@getEmailTemplatesList="getEmailTemplatesList"
					:templatesList="data.email.templatesList"
					@addTemplate="addTemplate"
					@viewTemplate="viewTemplate"
					@editTemplate="editTemplate"/> -->

					<!-- Email Templates -->
					<EmailAddTemplate v-if="elements.activeTab==1 && !elements.showList && elements.action=='add'"
					:pagination="pagination"
					@changeLimit="changeLimit"
					@getEmailTemplatesList="getEmailTemplatesList"
					:templatesList="data.email.templatesList"
					@closeActionTemplate="elements.showList=true"/>
					<EmailEditTemplate v-if="elements.activeTab==1 && !elements.showList && elements.action=='edit'"
					:pagination="pagination"
					@changeLimit="changeLimit"
					@getEmailTemplatesList="getEmailTemplatesList"
					:templatesList="data.email.templatesList"
					@closeActionTemplate="elements.showList=true"
					:templateData="data.email.templateData"/>
					<EmailViewTemplate v-if="elements.activeTab==1 && !elements.showList && elements.action=='view'" @closeActionTemplate="elements.showList=true"
					:templateData="data.email.templateData"/>

					<!-- Social Media Templates -->
					<SocialMediaAddTemplate v-if="elements.activeTab==6 && !elements.showList && elements.action=='add'"
					@changeLimit="changeLimit"
					@getSocialMediaTemplatesList="getSocialMediaTemplatesList"
					:templatesList="data.socialmedia.templatesList"
					:socialMediaList="data.socialmedia.socialMediaList"
					@closeActionTemplate="elements.showList=true"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import iziToast from 'izitoast';
	import EmailTemplates from './templatesettings/EmailTemplates';
	import SmsTemplates from './templatesettings/SmsTemplates';
	import SocialMediaTemplates from './templatesettings/SocialMediaTemplates';
	import EmailAddTemplate from './templatesettings/email/AddTemplate';
	import EmailEditTemplate from './templatesettings/email/EditTemplate';
	import EmailViewTemplate from './templatesettings/email/ViewTemplate';
	import SocialMediaAddTemplate from './templatesettings/socialmedia/AddTemplate';
	export default {
		components: {
			EmailTemplates,
			SmsTemplates,
			SocialMediaTemplates,
			EmailAddTemplate,
			EmailEditTemplate,
			EmailViewTemplate,
			SocialMediaAddTemplate
		},
		data(){
			return {
				elements: {
					activeTab: 1,
					action: "",
					showList: true,
				},
				pagination: {
					totalRecords: 0,
					emailtotalRecords: 0,
					rowsPerPage: 10,
					currentPage: 1,
					searchlimit: this.$store.state.searchlimit
				},
				data: {
					email: {
						templatesList: [],
						templateData: {}
					},
					socialmedia: {
						socialMediaList: [],
						templatesList: []
					}
				}
			}
		},
		mounted(){
			// this.elements.activeTab = 1;
			
			// this.getEmailTemplatesList();
			this.getSocialMediaTemplatesList(0);
		},
		methods: {
			filterArrayByValue(listarray,arrayname,keyword,value){
				arrayname = listarray.filter(function(data,key){
					if(data.keyword==value){
						return data;
					}
				});
			},
			refreshPagination: function(){
				this.rowsPerPage = 10;
				this.pagination.currentPage = 1;
			},
			resetEmailTemplates: function(){
				this.refreshPagination();
				this.elements.activeTab = 1;
				this.elements.showList = true;
			},
			resetSocialMediaTemplates: function(activeTab, moduleId){
				this.refreshPagination();
				this.elements.activeTab = activeTab;
				this.elements.showList = true;
				this.getSocialMediaList();
				this.getSocialMediaTemplatesList(moduleId);
			},
			getTemplateData:function(template_id){
				axios.post(this.$store.getters.getAPIBasePath+'/templateresource/view/template',{"id":template_id})
				.then(response => {
					let result = response.data;
					if (!response.status) {
						iziToast.error({title: 'Error', message: result.message, position: 'topRight'});
					}
					this.data.email.templateData = result.emailTemplate;
				})
				.catch(error => {
					iziToast.error({title: 'Error', message: this.$config.LoadTempErr, position: 'topRight'});
				});
			},
			addTemplate: function(){
				this.elements.showList = false;
				this.elements.action = "add";
			},
			editTemplate: function(template_id){
				this.getTemplateData(template_id);
				this.elements.showList = false;
				this.elements.action = "edit";
			},
			viewTemplate: function(template_id){
				this.getTemplateData(template_id);
				this.elements.showList = false;
				this.elements.action = "view";
			},
			changeLimit: function(newlimit){
				this.pagination.currentPage=1;
				this.pagination.rowsPerPage = newlimit;
			},
			getEmailTemplatesList: function(){
				axios.post(this.$store.getters.getAPIBasePath + '/templateresource/templatelist?page='+this.pagination.currentPage+'&size='+this.pagination.rowsPerPage,{})
				.then(response => {
					let result = response.data;
					if (!response.status) {
						iziToast.error({title: 'Error', message: result.message, position: 'topRight'});
					}
					this.data.email.templatesList = result.emailTemplates;
					this.pagination.emailtotalRecords = result.pagination[0].total;
					this.pagination.currentPage = result.pagination[0].currentPage;
				})
				.catch(error => {
					iziToast.error({title: 'Error', message: this.$config.LoadTempErr, position: 'topRight'});
				});
			},
			getSocialMediaList: function(){
				axios.get(this.$store.getters.getAPIBasePath + '/templateresource/socialmedia/list')
				.then(response => {
					let result = response.data;
					if (!result.status) {
						iziToast.error({title: 'Error', message: result.message, position: 'topRight'});
					}
					this.data.socialmedia.socialMediaList = result.socialMediaList;
				})
				.catch(error => {
					iziToast.error({title: 'Error', message: this.$config.LoadSocialMedErr, position: 'topRight'});
				});
			},
			getSocialMediaTemplatesList: function(moduleId){
				axios.post(this.$store.getters.getAPIBasePath + '/templateresource/socialmedia/template/list/'+moduleId+'?page='+this.pagination.currentPage+'&size='+this.pagination.rowsPerPage,{})
				.then(response => {
					let result = response.data;
					if (!response.status) {
						iziToast.error({title: 'Error', message: result.message, position: 'topRight'});
					}
					this.data.socialmedia.templatesList = result.socialMediaTemplates;
					this.pagination.totalRecords = result.pagination[0].total;
					this.pagination.currentPage = result.pagination[0].currentPage;
				})
				.catch(error => {
					iziToast.error({title: 'Error', message: this.$config.LoadTempErr, position: 'topRight'});
				});
			},
			getSocialMediaImage(fileName) {
				var images = require.context('@/assets/socialmedia', false, /\.png$/);
				return images('./' + fileName );
			}

		}
	}	
</script>