<template>
    <div :class="{ 'viewonly' : technologyinfo.viewonly == 1}">
        <div class="card">
            <div class="card-header p-2">Technology/Skills Expertise</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="Industry" scope="col">Industry</th>
                                            <th class="title" scope="col">Specialization/Technology</th>
                                            <th class="" scope="col">Skills</th>
                                            <th  v-if="false" class="videoprofile" scope="col">Primary Exp.(# of Years)</th>
                                            <th v-if="false"  class="state" scope="col">Industry</th>
                                            <th class="" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="cloned_tr">
                                        <tr v-for="(info, index) in technologyinfo" v-bind:key="index">
                                            <td class="lastnote">
                                                <label>{{info.industryName}}</label>
                                            </td>
                                            <td class="lastnote">
                                                <label>{{info.technology==undefined?info.technologyName:info.technology.technology}}</label>
                                            </td>
                                            <td class="lastnote">
                                                <span v-for="(skill, index1) in info.skills" v-bind:key="index1" class="badge mr-1" :class="colours[index%5]">{{skill.skillName == undefined?skill:skill.skillName}}</span>
                                            </td>
                                            <td v-if="false"  class="recruiter">
                                                <label>{{info.years}}</label>
                                            </td>
                                            <td v-if="false"  class="title">
                                                <label>{{info.industryName}}</label>
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr :class="{ 'norecordfound' : technologyinfo.length}">
                                            <td colspan="5" align="center">No item found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {
    props: {
        technologyinfo: { type: Array, default: [] },
    },
    data() {
        return {
            colours: ['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info']
        }
    }
}

</script>
