<template>
    <div class="card">
        <div class="card">
            <div class="card-header p-2">Search</div>
            <div class="card-body p-2">
                <div>
                <div class="row">
                <div class="col-md-3 col-sm-3 col-3">
                            <label>Search by Recruiter</label>
                </div>
                 <div class="col-md-3 col-sm-3 col-3">
                            <label>Search by Name</label>
                        </div>
                         <div class="col-md-3 col-sm-3 col-3">
                                                <label>Date</label>
                                            </div>
                </div>
                    <div class="row">
                        
                        <div class="col-md-3 col-sm-3 col-3">
                            <div class="input-group">
                                <select class="filter form-control">
                                    <option value="">Select</option>
                                    <option
                                        v-for="(info, index) in recruiterlist"
                                        :value="info.id"
                                    >
                                        {{ info.recruiterName }}
                                    </option>
                                </select>
                            </div>
                            
                        </div>
                       
                        <div class="col-md-3 col-sm-3 col-3" style="margin-left: 2px">
                            <input class="form-control" type="text" />
                        </div>

                         <div class="col-md-3 col-sm-3 col-3">
                                                <div class="input-group">
                                                    <input type="date" class="form-control" id="date" />
                                                </div>
                                            </div>
                        
                        <div class="col-md-2 col-sm-2 col-2">
                            <button
                                type="button"
                                class="btn btn-primary btn-sm float-right ml-10"
                            >
                                Reset
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary btn-sm float-right ml-10"
                            >
                                Find
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header p-2">Calls</div>
            <div class="card-body p-2">
                <div class="basic-block clone-preference">
                    <div class="row ten-columns pos-relative clone filled2">
                        <div class="col-md-12 col-sm-12 col-12 mb-10-mr">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col">
                                                Caller Name
                                            </th>
                                            <th class="" scope="col">
                                                Answered By
                                            </th>
                                            <th class="" scope="col">From</th>
                                            <th class="" scope="col">To</th>
                                            <th class="" scope="col">
                                                Start Time
                                            </th>
                                            <th class="" scope="col">
                                                End Time
                                            </th>
                                            <th class="" scope="col">
                                                Recording
                                            </th>
                                            <th class="" scope="col">Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-if="
                                                callListData != null &&
                                                callListData.length == 0
                                            "
                                        >
                                            <td
                                                style="border: 0px"
                                                colspan="6"
                                                align="center"
                                            >
                                                No Calls Found
                                            </td>
                                        </tr>
                                        <template
                                            v-for="(call, index) in callListData
                                                .TwilioResponse.Call"
                                        >
                                            <tr>
                                                <td>
                                                    {{ "Karthihayan" }}
                                                </td>
                                                <td>
                                                    {{ call.AnsweredBy }}
                                                </td>
                                                <td>
                                                    {{ call.From }}
                                                </td>
                                                <td>
                                                    {{ call.To }}
                                                </td>
                                                <td>
                                                    {{ call.StartTime }}
                                                </td>
                                                <td>
                                                    {{ call.EndTime }}
                                                </td>
                                                <td> <a href="javascript:;" data-toggle="modal" data-target="#RecruitmentRecording" ><i class=" ti-control-play" ></i></a>
                                                    <!-- {{ call.RecordingUrl }} -->
                                                </td>
                                                <td>
                                                    <a href="javascript:;" data-toggle="modal" data-target="#RecruitmentNotes" ><i class="ti-comment-alt "></i ></a>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <div>
                <b-modal ref="my-modal" id="RecruitmentNotes"  hide-footer title="Notes" size="md">
                    <div  class="modal-body">
                     <textarea  style="resize:none" cols="60" rows="10"></textarea>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="hideModal"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </b-modal>
            </div>

            <div>
                <b-modal ref="my-modal" id="RecruitmentRecording"  hide-footer title="Recording" size="md">
                    <div  class="modal-body">

                    <label class="callable"> Caller Name: </label> 
                     <label class="callable floatright">Karthihayan</label>
                    </br>
                    <label class="callable"> Answered By: </label><label class="callable">Naresh</label>
                    
                    </br>
                    
                     <div class="row">
                     <div class="col-md-6 col-sm-6 col-6">
                     <label class="callable"> From: </label>     <label class="callable">8610005443</label>
                     </div>
                     <div class="col-md-6 col-sm-6 col-6">
                     <label class="callable"> To: </label><label class="callable">8610005443</label>
                     </div>
                    
                    </div>

                    </br>
                    
                     <div class="row">
                     <div class="col-md-6 col-sm-6 col-6">
                     <label class="callable"> Start Time: </label>     <label class="callable">8610005443</label>
                     </div>
                     <div class="col-md-6 col-sm-6 col-6">
                     <label class="callable"> End Time: </label><label class="callable">8610005443</label>
                     </div>
                     </div>
                    

                     <label class="callable"> Duration: </label> <label class="callable">24</label>
                      </br>
                      <center>
                      <div class="row">
                        <div class="col-md-4 col-sm-4 col-4">
                         <i class="ti-control-pause plleft callable"></i >
                        </div>
                        <div class="col-md-4 col-sm-4 col-4">
                         <i class="ti-control-play callable"></i >
                        </div>
                        <div class="col-md-4 col-sm-4 col-4">
                         <i class="ti-control-stop plright callable"></i >
                        </div>
                      </div>
                      </center>
                    

         </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="hideModal"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </b-modal>
            </div>


        </div>
    </div>
</template>
<script>
import calls from "@/components/candidates/blocks/viewcandidate/calls.json";

export default {
    props: {},

    computed: {},
    data() {
        return {
            callListData: calls,
            recruiterlist: this.$store.getters.getRecruiterList,
        };
    },

    mounted() {},

    methods: {
         hideModal() {
            this.$refs["my-modal"].hide();
        },
    },
};
</script>
