<template>
    <div class="card incident-history">
        <div class="card-header">Activity Log Details
            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                <div class="dropdown">
                    <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                    <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                        <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:" @click="changeLimit(value)">{{value}}</a>
                    </div>
                </div>
            </span>
        </div>
        <div class="card-body">
            <div class="row col-md-12 col-sm-6 col-12 mx-0 mt-4">
                <div class="table-responsive">
                    <table class="table table-borderless all-table">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Candidate Name</th>
                                <th>Incident Date</th>
                                <th>Incident Type</th>
                                <th>Action Type</th>                               
                            </tr>
                        </thead>
                        <tbody id="table-length">
                            <tr v-if="incidentList==null || incidentList.length==0">
                                <td colspan="5" align="center">No activity found recently</td>
                            </tr>
                            <tr v-for="(Incident, index) in incidentList">
                                <td>{{ ((pagination.currentPage-1) * (pagination.rowsPerPage)) + (index+1) }}</td>
                                <td>{{ Incident.candidateName }} </td>
                                <td>{{ Incident.createdAt | formatDate}}</td>
                                <td>{{ Incident.incidentTypeName }}</td>
                                <td>{{ Incident.action }}</td>                               
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-12">
                <b-pagination v-if="incidentList.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="getIncidentHistory()"></b-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    props: {
        profileId: { type: Number, required: true }
    },
    data() {
        return {
           
            searchlimit: this.$store.state.searchlimit,
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            incidentList: [],
            incidentinfo: {}
        }
    },

    mounted() {
         this.$store.commit('refreshPagination');
        this.getIncidentHistory();
    },
    methods: {
        changeLimit: function(newlimit) {
             this.pagination.currentPage=1;
            this.pagination.rowsPerPage = newlimit;           
            this.getIncidentHistory();
        },
        getIncidentHistory: function() {
            this.incidentinfo = {
                userId: this.profileId,
                userType: 'guest'
            }
            axios.post(this.$store.getters.getAPIBasePath + '/incidentresource/incident/list/by/guest?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.incidentinfo)
                .then(response => {
                    if (response.data.status) {                        
                        let result = response.data;
                        this.incidentList = result.incidentList;
                        this.pagination.totalRecords = result.pagination[0].total;
                        this.pagination.currentPage = result.pagination[0].currentPage;
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
