<template>
        <div class="card">
            <div class="card-header p-2">Add Previous Employment History</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col">Company Name</th>
                                            <th class="videoprofile" scope="col">Position</th>
                                            <th class="recruiter" scope="col">Technology/Skills</th>
                                            <th class="title" scope="col">City</th>
                                            <th class="state" scope="col">State</th>
                                            <th class="phone" scope="col">From</th>
                                            <th class="lastnote" scope="col">To</th>
                                            <th class="" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="cloned_tr">
                                    	<tr v-for="(info, index) in employmentinfo" :class="{ 'viewonly' : employmentinfo.viewonly == 1}">
                                    		<td>
                                    			<input v-model="employmentinfo[index].companyName" class="form-control" maxlength="45" type="text"/>
                                    		</td>
                                            <td class="lastnote">
                                            	<input v-model="employmentinfo[index].jobTitle" class="form-control" maxlength="45" type="text"/>
                                            </td>
                                            <td class="recruiter">
                                                <div class="position-relative">
                                                    <input v-model="employmentinfo[index].skillSet" class="form-control" maxlength="400" type="text"/>
                                                    <a href="javascript:;" @click="showBoxEmpSkill(index)" data-toggle="modal" data-target="#EmpSkillShow">
                                                        <span class="search-addon" ><span class="ti-flickr srcicn"></span> </span>  
                                                    </a>
                                                </div>
                                            </td>
                                            <td class="title">
                                            	<input v-model="employmentinfo[index].city" class="form-control" maxlength="25" type="text"/>
                                            </td>
                                            <td class="specialityunit">
                                            	<input v-model="employmentinfo[index].stateName" class="form-control" maxlength="25" type="text"/>
                                            </td>
                                            <td class="currentcity">
                                                <datepicker v-model="employmentinfo[index].employedFrom" placeholder="Employed From" input-class="form-control enablefield" maxlength="10" wrapper-class="input-group date" format="MM/dd/yyyy"></datepicker>
                                            </td>
                                            <td class="state">
                                                <datepicker v-model="employmentinfo[index].employedTo" placeholder="Employed To" input-class="form-control enablefield" maxlength="10" wrapper-class="input-group date" format="MM/dd/yyyy"></datepicker>
                                            </td>
                                            <td>
                                                <a class="removemoretextbox" :class="{ 'removeaddbtn' : employmentinfo.viewonly == 1}" href="Javascript:void(0);" @click="confirmCompany(index,employmentinfo[index].id)" data-toggle="modal" data-target="#removCompany"><span class="ti-trash"></span></a>
                                            </td>
                                    	</tr>
                                        <tr  :class="{'noaddedmore fill': employmentinfo.viewonly == 1}">
                                            <td>
                                            	<input id="addcandidate_employmentinfo_add_companyname" class="form-control" type="text" maxlength="45" v-model="newemploymentinfo.companyName"/>
                                            </td>
                                            <td class="lastnote">
                                            	<input class="form-control" type="text" maxlength="45" v-model="newemploymentinfo.jobTitle"/>
                                            </td>
                                            <td class="recruiter ">
                                                <div class="position-relative">
                                            	<input class="form-control" type="text" maxlength="400" v-model="newemploymentinfo.skillSet"/>
                                                <a href="javascript:;" @click="showBoxEmpSkill(-1)" data-toggle="modal" data-target="#EmpSkillShow">
                                                        <span class="search-addon" ><span class="ti-flickr srcicn"></span> </span>  
                                                    </a>
                                                    </div>
                                            </td>
                                            <td class="title">
                                            	<input class="form-control" type="text" maxlength="25" v-model="newemploymentinfo.city"/>
                                            </td>
                                            <td class="specialityunit">
                                            	<input class="form-control" type="text" maxlength="25" v-model="newemploymentinfo.stateName"/>
                                            </td>
                                            <td class="currentcity">
                                                <datepicker v-model="newemploymentinfo.employedFrom" placeholder="Employed From" input-class="form-control enablefield" maxlength="10" wrapper-class="input-group date" format="MM/dd/yyyy"></datepicker>
                                            </td>
                                            <td class="state">
                                                <datepicker v-model="newemploymentinfo.employedTo" placeholder="Employed To" input-class="form-control enablefield" maxlength="10" wrapper-class="input-group date" format="MM/dd/yyyy"></datepicker>
                                            </td>
                                            <td>
                                                <a class="addmoretextbox" href="Javascript:void(0);" @click="addNewEmploymentInfo"><span class="ti-plus"></span></a>
                                            </td>
                                        </tr>
                                        <tr :class="{'norecordfound': employmentinfo.viewonly == 1}" style="display: none;"><td colspan="8" align="center">No item found</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal" id="EmpSkillShow" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Technology / Skill</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <textarea class="form-control more-text-area" v-model="tempEmpSkill" ></textarea>
                        </div>
                        <div v-if="employmentinfo.viewonly != 1" class="modal-footer">                                            
                            <button type="button" @click="updateEmpSkill()" class="btn btn-primary" data-dismiss="modal">OK</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog id="removCompany" :onYes="deleteCompany" :returnParams="delCompanyParam" />

        </div>

        

    </div>
</template>

<script>
    import iziToast from 'izitoast';
	export default{
        props: {
            employmentinfo: Array,
        },
		data(){
			return {
				newemploymentinfo:{
					companyName:"",
					jobTitle:"",
					skillSet:"",
					city:"",
					stateName:"",
					employedFrom:"",
					employedTo:"",
					leavingReason:"",
					countryId:"US",
                    referenceName:"",
                    referencePhone:"",
                    referenceEmail:"",
                    referenceRelationship:"",
                    is_current:false
                },
                tempEmpSkillId:-1,
				tempEmpSkill:'',
                delCompanyParam:{
                    index:-1,
                    id:0
                }
			}
		},
		methods:{
			resetNewForm(){
				this.newemploymentinfo = {
					companyName:"",
					jobTitle:"",
					skillSet:"",
					city:"",
					stateName:"",
					employedFrom:"",
					employedTo:"",
					leavingReason:"",
					countryId:"US",
                    referenceName:"",
                    referencePhone:"",
                    referenceEmail:"",
                    referenceRelationship:"",
                    is_current:false
				};
				$('#addcandidate_employmentinfo_add_companyname').focus();
			},
            removeInfo(index){
                this.employmentinfo.splice(index,1);
            },
			addNewEmploymentInfo(){
                this.newemploymentinfo.companyName = this.newemploymentinfo.companyName.trim();
                this.newemploymentinfo.jobTitle = this.newemploymentinfo.jobTitle.trim();
                this.newemploymentinfo.skillSet = this.newemploymentinfo.skillSet.trim();
                this.newemploymentinfo.city = this.newemploymentinfo.city.trim();
                this.newemploymentinfo.stateName = this.newemploymentinfo.stateName.trim();
                if(this.newemploymentinfo.companyName!="" || this.newemploymentinfo.jobTitle!="" || this.newemploymentinfo.skillSet!="" || this.newemploymentinfo.city!="" || this.newemploymentinfo.stateName!=""){
                    this.employmentinfo.push(this.newemploymentinfo);
                    this.resetNewForm();
                }
			},
			deleteCompany:function(arg)
            {   
                this.employmentinfo.splice(arg.index,1);   
                 axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/delete/candidate/employment/' + this.delCompanyParam.id)
                .then(response => {
                    var result = response.data;
                    alert(result.status);
                    if (result.status == "OK") {
                        iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                    }
                })
                .catch(error => {
                    console.log(error.data);
                });
            this.delCompanyParam.id = null;           
            },
            confirmCompany:function(arg1, arg2)
            {
                this.delCompanyParam.index=arg1;
                this.delCompanyParam.id=arg2;
            },
            showBoxEmpSkill: function(arg){
				//console.log(arg);
				this.tempEmpSkillId = arg;
                if(arg<0)
                {
                    this.tempEmpSkill = this.newemploymentinfo.skillSet;                    
                    //console.log(this.newprojectinfo.projectDescription);
                }
                else
                {                    
                    this.tempEmpSkill = this.employmentinfo[arg].skillSet;                    
                    //console.log(this.projectinfo[arg].projectDescription);
                }
			},
			updateEmpSkill: function()
			{
				 if(this.tempEmpSkillId < 0)
                 {
                    this.newemploymentinfo.skillSet = this.tempEmpSkill;
                 }
                 else
                 {
					this.employmentinfo[this.tempEmpSkillId].skillSet = this.tempEmpSkill;                                     
				}
			},
		}
	}
</script>