<template>
	<div class="email-account-settings">
		<div class="row col-md-12 mx-0 px-0">
			<div class="col-md-3 offset-md-4">
				<h5 class="text-center py-3">Edit Email Account</h5>
			</div>
			<div class="col-md-2 offset-md-3 px-0 align-self-center">
				<a href="javascript:" class="pull-right btn-back text-blue" @click="$emit('closeAction')">
					<h5><i class="ti-arrow-left"></i> Back</h5>
				</a>
				<a href="javascript:" class="pull-right btn-back text-blue px-4" @click="$emit('setAction','edit')">
					<h5><i class="ti-pencil"></i> Edit</h5>
				</a>
			</div>
		</div>
		<div class="card user-information">
			<div class="card-header">User Information</div>
			<div class="card-body">
				<div class="row col-md-12 py-2">
					<div class="col-md-4">
						<div class="row col-md-12">
							<label>Name</label>
						</div>
						<div class="row col-md-12">
							<label class="form-control" type="text" name="user-name">{{accountParams.fromName}}</label>
						</div>
					</div>
					<div class="col-md-4 offset-md-1">
						<div class="row col-md-12">
							<label>Email Address</label>
						</div>
						<div class="row col-md-12">
							<label class="form-control" type="email" name="user-email">{{accountParams.fromEmail}}</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card server-information">
			<div class="card-header">Server Information</div>
			<div class="card-body">
			<div class="row col-md-12 py-2">
					<div class="col-md-4">
						<div class="row col-md-12">
							<label>Incoming Host</label>
						</div>
						<div class="row col-md-12">
							<label class="form-control" type="text" name="server-host">{{accountParams.incomingHost}}</label>
						</div>
					</div>
					<div class="col-md-4 offset-md-1">
						<div class="row col-md-12">
							<label>Incoming Port</label>
						</div>
						<div class="row col-md-12">
							<label class="form-control" type="number" name="server-port">{{accountParams.incomingPort}}</label>
						</div>
					</div>
				</div>
				<div class="row col-md-12 py-2">
					<div class="col-md-4">
						<div class="row col-md-12">
							<label>Outgoing Mail Server (SMTP)</label>
						</div>
						<div class="row col-md-12">
							<label class="form-control" type="text" name="server-host">{{accountParams.host}}</label>
						</div>
					</div>
					<div class="col-md-4 offset-md-1">
						<div class="row col-md-12">
							<label>Port</label>
						</div>
						<div class="row col-md-12">
							<label class="form-control" type="number" name="server-port">{{accountParams.port}}</label>
						</div>
					</div>
				</div>
				<div class="row col-md-12 py-2">
					<div class="col-md-4">
						<div class="row col-md-12">
							<label>Username</label>
						</div>
						<div class="row col-md-12">
							<label class="form-control" type="text" name="server-username">{{accountParams.userName}}</label>
						</div>
					</div>
					<!-- <div class="col-md-4 offset-md-1">
						<div class="row col-md-12">
							<label>Password</label>
						</div>
						<div class="row col-md-12">
							<label class="form-control" type="password" name="server-password">{{accountParams.password}}</label>
						</div>
					</div> -->
				</div>
				<div class="row col-md-12 py-2">
					<div class="col-md-12">
						<span v-if="accountParams.isDefault" class="ti-check"></span>
						<span v-if="!accountParams.isDefault" class="ti-close"></span>
						<label class="p-3" for="isDefault">Is Primary Account?</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { required, minLength, maxLength, email } from "vuelidate/lib/validators";
	import iziToast from 'izitoast';
	export default {
		props: {
			settingsEmailAccountId: Number
		},
		data(){
			return {
				accountParams: {
					fromName: "",
					fromEmail: "",
					incomingPort:"",
					incomingHost:"",
					host: "",
					port: "",
					userName: "",
					password: "",
					outgoingMailServer: "",
					incomingMailServer: ""
				}
			}
		},
		mounted(){
			this.viewSettingsEmailAccount();
		},
		methods: {
			viewSettingsEmailAccount: function(){
				axios.get(this.$store.getters.getAPIBasePath+'/emailresource/view/email/account/'+this.settingsEmailAccountId)
				.then(response => {
					let result = response.data;
					if (!response.status) {
						iziToast.error({title: 'Error', message: result.message, position: 'topRight'});
					}
					this.accountParams = result.settingsEmailAccountViewData;
				})
				.catch(error => {
					iziToast.error({title: 'Error', message: this.$config.LoadTempListErr, position: 'topRight'});
				});
			}
		}
	}
</script>