<template>
    <div id="main" class="reports settings recruiter mt-70">
        <div class="row col-md-12 box-wrapper shadow-sm my-4 rounded pos-rel mx-0 px-0">
         <div class="wrapper settings-leftbar w-100 px-0">
            <aside class="main-sidebar bg-white">
                <section class="sidebar">
                    <div class="p-4 header d-flex flex-row justify-content-between">
                            <i class="fa fa-bars mr-3 fa-2x text-secondary" @click="sidebarToggle" data-toggle="push-menu" role="button"></i>
                        <div class="reports-title">
                            <h5>Reports</h5>
                        </div>
                        <div class="back-link">
                            <router-link :to="{name: 'referraldashboard'}">
                                <h5 class="text-blue">
                                    <span class="ti-arrow-left py-4" title="Go Back"></span>
                                    <span class="pl-2">Back</span>
                                </h5>
                            </router-link>
                        </div>
                    </div>
                    <ul class="sidebar-menu" data-widget="tree">
                        <li @click="candidateReportTab" :class="elements.activeTab==1?'left-tab-active':''"><a href="#"><img alt="images" src="@/assets/icons/recruiter-performance.png" class="menuicon"> <span>Candidate Reports</span></a></li>
                    </ul>
                </section>
            </aside>
            <div class="basic-block text-center">
                <div class="content-wrapper">
                    <section class="content-header">
                        <CandidateReport v-if="elements.activeTab==1 && elements.subActiveTab==0" @setSubTab="setSubTab" :reportType="reportType"/>
                        <CandidateListReport v-if="elements.activeTab==1 && elements.subActiveTab==1" @setSubTab="setSubTab"  reportType="candidate" :candContactAccess="candContactAccess"/>
                        <CandidateCustomReport v-if="elements.activeTab==1 && elements.subActiveTab==2" :action="elements.action" @setSubTab="setSubTab" reportType="candidate" :candContactAccess="candContactAccess"/>
                    </section>
                </div>
            </div>
         </div>
        </div>
    </div>
</template>
<script>
import CandidateListReport from '@/components/recruiters/blocks/reports/candidate/CandidateListReport';
import CandidateReport from '@/components/recruiters/blocks/reports/CandidateReport';
import CandidateCustomReport from '@/components/recruiters/blocks/reports/candidate/CandidateCustomReport';
export default {
    components: {
        CandidateListReport,
        CandidateReport,
        CandidateCustomReport,
    },
    data() {
        return {
            elements: {
                activeTab: 1,
                subActiveTab: 0,
                action: {
                    mode: "list",
                    id: null
                }
            },
            reportType:1,
            recownerid: this.$store.getters.currentUser.recruitOwner,
            candContactAccess: localStorage.getItem('candidateContactAccess')
        }
    },
    mounted() {
        // this.elements.activeTab = 1;
    },
    methods:{
        setSubTab: function(subTab, mode, id) {
            this.elements.subActiveTab = subTab;
            if (mode != "")
                this.elements.action.mode = mode;
            if (id)
                this.elements.action.id = id;
        },
        sidebarToggle: function(event) {
            $(".reports.settings").toggleClass('sidebar-collapse');
        },
        candidateReportTab: function() {
            this.elements.activeTab = 1;
            this.elements.subActiveTab = 0;
        },
    }
}

</script>
