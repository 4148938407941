<template>
    <div class="admin-blocks add-recruiter mt-70 pt-10">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="card-header p-2">Add Service Plan</div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div class="row ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 add_mar">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Plan Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group required-field-block">
                                            <input type="text" name="planName" v-model="serviceInfo.servicePlan" maxlength="45" size="30" class="form-control" />
                                            <span class="required-icon"></span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <label v-if="$v.serviceInfo.servicePlan.$error && !$v.serviceInfo.servicePlan.required" class="text-danger">Plan Name is required</label>
                                        <label v-if="$v.serviceInfo.servicePlan.$error && !$v.serviceInfo.servicePlan.minLength" class="text-danger">First Name must've minimum 2 characters</label>
                                        <label v-if="$v.serviceInfo.servicePlan.$error && !$v.serviceInfo.servicePlan.maxLength" class="text-danger">Maximum limit is 30 characters</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 add_mar">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Service Charge</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group required-field-block">
                                            <input type="text" name="serviceCharge" v-model="serviceInfo.serviceCharge" maxlength="45" size="30" class="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 add_mar">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Plan Period</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group required-field-block">
                                            <input type="text" name="planPeriod" v-model="serviceInfo.planPeriod" maxlength="45" size="30" class="form-control" />
                                            <span class="required-icon"></span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <label v-if="$v.serviceInfo.planPeriod.$error && !$v.serviceInfo.planPeriod.required" class="text-danger">Plan Period is required</label>
                                    </div>
                                </div>
                            </div>
                             <div class="col-md-2 col-sm-6 col-12 add_mar">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Description</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group required-field-block">
                                            <input type="text" name="description" v-model="serviceInfo.description" maxlength="45" size="30" class="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-header p-2 mr-12">
                    <a href="javascript:" @click="addServicePlan" class="btn btn-success float-right">Add</a>
                    <router-link :to="{ name: 'adminlistservice' }" class="btn btn-secondary mr-2 text-white float-right">Cancel</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import Select2 from 'v-select2-component';
import iziToast from 'izitoast';
const usPhone = helpers.regex('usPhone', /^\(\d{3}\)\s\d{3}-\d{4}$/);

export default {
    components: {
        Select2
    },
    data() {
        return {
            serviceInfo:{
               id:'',
                servicePlan:'',
                description:'',
                planPeriod:'',
                permissionCount:'',
                serviceCharge:'',
            }
        }
    },
    validations: {
        serviceInfo: {
            servicePlan: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
             planPeriod: {
                required,
            }
        }
    },
    mounted() {},
    methods: {
        addServicePlan: function() {
            this.$v.serviceInfo.$touch();
            if (!this.$v.serviceInfo.$invalid){
            axios.post(this.$store.getters.getAPIBasePath + '/serviceplan/add', this.serviceInfo)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message: this.$config.AddSerPlanSuc, position: 'topRight' });
                        this.$router.push('/admin/serviceplan');
                        } 
                    })
                .catch(error => {
                    console.log(error);
                });
            }
        },
    }
}

</script>
