<template>
    <div id="main" class="tech-skill-settings settings recruiter">
        <div class="container-fluid">
            <div class=" shadow-sm p-4 mb-2 bg-white rounded ">
                <div class="row">
                    <div class="col-12">
                        <h5>
                            <span style="padding-top:10px;">Counter Offers</span>
                            <a href="Javascript:void(0);" class="pull-right btn-back text-blue" @click="$emit('setSubTab',0)">
                                <i class="ti-arrow-left"></i> Back
                            </a>
                        </h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-sm-8 col-8">
                        <div class="input-group">
                            <input type="text" name="" placeholder="Enter Counter Offer" maxlength="30" value="" v-model="name" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-8 col-4">
                        <div class="input-group">
                         <input type="text" name="" maxlength="30" value="" v-model="offervalue" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-4 col-1">
                        <a href="javascript:" @click="reset" class="cancel-icon btn btn-secondary mx-1">Cancel</a>
                        <a href="javascript:" @click="addCounterOffer" class="save-icon btn btn-primary mx-1">Save</a>
                    </div>
                </div>
            </div>
            <div class="box-wrapper shadow-sm pt-4 pb-4 bg-white rounded">
                <div calss="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-borderless all-table">
                                <thead>
                                    <tr>
                                        <th class="" scope="col" style="text-align:left; padding-left:20px;">Name</th>
                                        <th class="accountManager" scope="col">Value</th>
                                        <th class="accountManager" scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody id="table-length">
                                    <tr v-if="counterOfferList != null && counterOfferList.length==0">
                                        <td colspan="4" align="center">No Counter Offers found</td>
                                    </tr>
                                    <tr v-for="(offerinfo, index) in counterOfferList">
                                        <td class="accountManager" style="text-align:left; padding-left:20px;">{{ offerinfo.name }}</td>
                                        <td class="accountManager">{{ offerinfo.value }}</td>
                                        <td>
                                            <ul class="navbar-nav">
                                                <li class="nav-item dropdown">
                                                    <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                        <li>
                                                            <a class="dropdown-item" href="javascript:;" @click="editCounterOffer(offerinfo.id,index)" data-toggle="modal"><span class="ti-pencil pr-2"></span>Edit</a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" href="javascript:;" data-toggle="modal" data-target="#removeField" @click="changeIdValue(offerinfo.id)"><span class="ti-cut pr-2"></span>Delete</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="removeField" :onYes="deleteField" title="Delete Confirmation" message="Are you sure to delete counter offer setting?" />
    </div>
</template>
<style scoped>
div.item {
    margin-top: 5px;
}

</style>
<script>
import iziToast from "izitoast";

export default {
    components: {

    },
    data() {
        return {
            id: '',
            name: '',
            offervalue: 'Yes',
            editMode: 0,
            counterOfferList: [],
            valueFormatList: [{"id": 1,"offervalue": "Yes"},{"id": 2,"offervalue": "No"}],
            newcounterOfferList: []
        };
    },
    mounted() {
        this.loadCounterOffersList();
    },
    methods: {
        loadCounterOffersList() {
            axios.post(this.$store.getters.getAPIBasePath + "/settingsresource/conteroffer/list")
                .then(response => {
                    let result = response.data;
                    this.counterOfferList = result.recordinfo;
                })
                .catch(error => {
                    iziToast.error({ title: "Error", message: error, position: "topRight" });
                });
        },
        addCounterOffer() {
            let url = "";
            let params = {};
            if (this.editMode === 0) {
                url = this.$store.getters.getAPIBasePath + "/settingsresource/conteroffer/add";
                params = { name: this.name, value: this.offervalue };
            } else if(this.editMode === 1) {
                url = this.$store.getters.getAPIBasePath + "/settingsresource/conteroffer/edit";
                params = { id: this.id, name: this.name, value: this.offervalue };
            }
            axios.post(url, params)
                .then(response => {
                    this.reset();
                    iziToast.success({ title: "Success", message: response.data.message, position: "topRight" });
                    this.loadCounterOffersList();
                })
                .catch(error => {
                    iziToast.error({ title: "Error", message: this.$config.PlsFillFields, position: "topRight" });
                });
        },
        editCounterOffer(id,idx) {
            this.editMode = 1;
            this.id = id;
            this.name = this.counterOfferList[idx].name;
            this.offervalue = this.counterOfferList[idx].value;
               
        },
        reset() {
            this.name = "";
            this.offervalue = "";
            this.editMode = 0;
        },
        changeIdValue(id) {
            this.id = id;
            return;
        },
        deleteField() {

            axios.delete(this.$store.getters.getAPIBasePath + "/settingsresource/delete/conteroffer/" + this.id)
                .then(response => {
                    iziToast.success({ title: "Success", message: response.data.message, position: "topRight" });
                    this.loadCounterOffersList();
                })
                .catch(error => {
                    iziToast.error({ title: "Error", message: error, position: "topRight" });
                });
        }
    }
};

</script>
