<template>
    <div id="main" class="mt-80">
        <div class="row col-md-12 col-sm-12">
            <div class="col-md-3 col-sm-3 scroll-down">
                <h5 style="background-color: #37a0e4;color: #fff;padding: 5px; " class="">Jobs</h5>
                <div class="box-wrapper p-0 mb-5 rounded">
                    <div class="card-media" v-if="joblist.length==0">
                        <div class="col-md-12 pt-4 pb-4" style="text-align : center;background-color: #ffff">No records found
                        </div>
                    </div>
                    <div class="card-media" v-for="(jobinfo, index) in joblist">
                        <div class="card-media-body">
                            <div class="row">
                                <div class="col-md-10 col-sm-10">
                                    <span class="card-media-body-heading job-link pl-0" @click="routerChange(jobinfo.id)">{{ jobinfo.jobTitle }} <span v-if="checkEmpty(jobinfo.jobCode)">- [{{jobinfo.jobCode}}]</span> </span>
                                </div>
                                <div class="col-md-2 col-sm-2">
                                    <span v-if="checkEmpty(jobinfo.vacancies)" title="Vacancies" class="badge badgebgdashboard pull-right">{{jobinfo.vacancies}}</span>
                                </div>
                                <div v-if="checkEmpty(jobinfo.clientName)" class="col-md-12 col-sm-12 mb-2">
                                    <span class=""><i class="fa fa-building-o pr-2" aria-hidden="true"></i>{{ jobinfo.clientName }}</span>
                                </div>
                            </div>
                            <ul class="mb-2">
                                <li v-if="checkEmpty(jobinfo.experience)" class="d-inline-block pr-4"><i class="ti-bag"></i> {{jobinfo.experience}} years</li>
                                <li v-if="checkEmpty(jobinfo.location)" class="d-inline-block pr-4"><i class="ti-location-pin"></i> {{jobinfo.location}}</li>
                                <li v-if="checkEmpty(jobinfo.days)" class="d-inline-block pull-right"><i class="ti-alarm-clock"></i> <span class="pl-2 font-weight-bold" style="color:#e83e8c;">{{jobinfo.days}}</span> <span class="pl-1 small ">days more</span></li>
                            </ul>
                            <div class="card-media-body-top">
                                <div class="card-media-body-top u-float-right  w-100 text-right hrline">
                                    <span class="float-left" v-if="checkEmpty(jobinfo.dueDate)"><i class="ti-calendar pr-2"></i>{{jobinfo.dueDate | formatDate}}</span>
                                    <!-- <span class="job-link">
                                        <span class="ti-pencil"></span>Edit Job 
                                    </span> -->
                                    <!--  <span class="job-link" @click="getJobInfo(jobinfo.id)">
                                        <i class="fa fa-users"></i> Recruitment
                                    </span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9 col-sm-6 box-wrapper bg-white rounded">
                <div class="row">
                    <div class="col-sm-12 p-4">
                        <h4 class="text-blue py-2">Job Information
                            <router-link class="float-right" title="Recruitment" :to="{ name: 'planrecruitment', params: { jobid: jobid }}"><i class="fa fa-users pl-2"></i></router-link>
                            <span >
                                <router-link class="float-right" title="Edit Job" :to="{ name: 'editjob', params: { jobid: jobid}}"><span class="fa fa-pencil"></span></router-link>
                            </span>
                            <!-- <router-link class="btn btn-primary necttab pull-right" :to="{ name: 'candidateregister-publicportal', params: { key: 'cHVibGljcG9zdA==', jobId: jobDetails.id } }">Apply1</router-link> -->
                            <span class="job-link">
                                <social-sharing :url="redirectURL" title="TalentTurbo" :description="getSocialMediaShareInfo(jobDetails.jobTitle)" :quote="getSocialMediaShareInfo(jobDetails.jobTitle)" :twitter-user="jobDetails.jobCode" :hashtags="getSocialMediaHashTag(jobDetails.jobTitle,jobDetails.id)" inline-template>
                                    <network network="facebook">
                                        <i class="fa fa-facebook-official fa_custom fa-lg hover-hand"></i>
                                    </network>
                                </social-sharing>
                            </span>

                            <!-- <span v-if="joblist.length!=0">
                                <button @click="getKey(jobId)" class="btn btn-primary necttab pull-right">
                                    Apply</button>
                            </span> -->
                        </h4>
                        <div class="my-inbox user-card">
                            <div class="card" v-if="joblist.length==0">
                                <div class="col-md-12 pt-4 pb-4" style="text-align : center;background-color: #ffff">No job information
                                </div>
                            </div>
                            <div class="card" v-if="joblist.length!=0">
                                <div class="card-body p-0">
                                    <div class="p-0">
                                        <div class="row ml-0 mr-0">
                                            <div class="col-sm-12">
                                                <div class="row pt-3 job-info-bg">
                                                    <div class="col-sm-12">
                                                        <div class="headjobs-left">
                                                            <h5 class="text-blue">{{jobDetails.jobTitle}} <span v-if="checkEmpty(jobDetails.jobCode)" class="pr-5">- [{{jobDetails.jobCode}}]</span> <span v-if="checkEmpty(jobDetails.vacancies)" title="Vacancies" class="badge badgebg pull-right">{{jobDetails.vacancies}}</span></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row pb-3 job-info-bg">
                                                    <div class="col-sm-12 nomarginplz">
                                                        <div class="row jobbrief padgap">
                                                            <div class="col-sm-10 py-2">
                                                                <div v-if="checkEmpty(jobDetails.industryName)" class="row py-1">
                                                                    <div class="col-sm-4 nomarginplz">Industry : </div>
                                                                    <div class="col-sm-8 nomarginplz">{{jobDetails.industryName}}</div>
                                                                </div>
                                                                <div v-if="checkEmpty(jobDetails.position)" class="row py-1">
                                                                    <div class="col-sm-4 nomarginplz">Position : </div>
                                                                    <div class="col-sm-8 nomarginplz">{{jobDetails.position}}</div>
                                                                </div>
                                                                <div v-if="checkEmpty(jobDetails.experience)" class="row py-1">
                                                                    <div class="col-sm-4 nomarginplz">Experience : </div>
                                                                    <div class="col-sm-8 nomarginplz">
                                                                        {{jobDetails.experience}}
                                                                        <span class="pl-1 text-lowercase">{{jobDetails.expType}}</span>
                                                                    </div>
                                                                </div>
                                                                <div v-if="checkEmpty(jobDetails.workType)" class="row py-1">
                                                                    <div class="col-sm-4 nomarginplz">Work Type : </div>
                                                                    <div class="col-sm-8 nomarginplz">{{jobDetails.workType}}</div>
                                                                </div>
                                                                <div v-if="checkEmpty(jobDetails.location)" class="row py-1">
                                                                    <div class="col-sm-4 nomarginplz">Location : </div>
                                                                    <div class="col-sm-8 nomarginplz">{{jobDetails.location}}</div>
                                                                </div>
                                                                <div v-if="checkEmpty(jobDetails.eligibility)" class="row py-1">
                                                                    <div class="col-sm-4 nomarginplz">Qualification : </div>
                                                                    <div class="col-sm-8 nomarginplz">{{jobDetails.eligibility}}</div>
                                                                </div>
                                                                <div v-if="checkEmpty(jobDetails.salary)" class="row py-1">
                                                                    <div class="col-sm-4 nomarginplz">Salary : </div>
                                                                    <div class="col-sm-8 nomarginplz">
                                                                        <span class="pl-1 text-lowercase">{{jobDetails.currency}}</span>
                                                                        {{jobDetails.salary}}
                                                                        <span class="pl-1 text-lowercase">{{jobDetails.payFrequency}}</span>
                                                                    </div>
                                                                </div>
                                                                  <div v-if="checkEmpty(jobDetails.minSalary)" class="row  py-1">
                                                                        <div class="col-sm-4 nomarginplz">Minimum Salary: </div>
                                                                            <div class="col-sm-8 nomarginplz">{{jobDetails.minSalary}}
                                                                        </div>
                                                                </div>
                                                                  <div v-if="checkEmpty(jobDetails.maxSalary)" class="row  py-1">
                                                                        <div class="col-sm-4 nomarginplz">Maximum Salary: </div>
                                                                            <div class="col-sm-8 nomarginplz">{{jobDetails.maxSalary}}
                                                                        </div>
                                                                </div>
                                                                  <div v-if="checkEmpty(jobDetails.frequencyPercentage)" class="row  py-1">
                                                                        <div class="col-sm-4 nomarginplz">Flexibility Percentage: </div>
                                                                            <div class="col-sm-8 nomarginplz">{{jobDetails.frequencyPercentage}}
                                                                        </div>
                                                                </div>
                                                                 <div v-if="checkEmpty(jobDetails.taxTerm)" class="row  py-1">
                                                                        <div class="col-sm-4 nomarginplz">Tax Term: </div>
                                                                            <div class="col-sm-8 nomarginplz">{{jobDetails.taxTerm}}
                                                                        </div>
                                                                </div>
                                                                <div class="row py-1">
                                                                    <div class="col-sm-4 nomarginplz">Due Date : </div>
                                                                    <div class="col-sm-8 nomarginplz">{{jobDetails.dueDate | formatDate}}
                                                                        <!-- <span v-if="checkEmpty(jobDetails.days)" class="d-inline-block pull-right"><i class="ti-alarm-clock"></i> <span class="pl-2 font-weight-bold" style="color:#e83e8c;">{{jobDetails.days}}</span> <span class="pl-1 small ">days more</span></span> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 daysmore mb-n4">
                                                                <span v-if="checkEmpty(jobDetails.days)" class="d-inline-block pull-right"><i class="ti-alarm-clock"></i> <span class="pl-2 font-weight-bold" style="color:#e83e8c;">{{jobDetails.days}}</span> <span class="pl-1 small ">days more</span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row pt-4">
                                                    <div class="col-sm-12">
                                                        <div class="headjobs-left pb-1">
                                                            <h5 class="text-blue py-1">Hiring Info</h5>
                                                            <div class="informations">
                                                                <span class="p-1 mb-2 mr-1 badge badge-success"> Talent Identified - {{ recCandInfo.talentCount }}</span>
                                                                <span class="p-1 mb-2 mr-1 badge badge-secondary"> Shortlisted Candidates - {{ recCandInfo.candShortlistedCount }}</span>
                                                                <span class="p-1 mb-2 mr-1 badge badge-warning"> Client Reviewed - {{ recCandInfo.clientReviewedCount }}</span>
                                                                <span class="p-1 mb-2 mr-1 badge badge-info"> Hired - {{ recCandInfo.hiredCount }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row pt-4">
                                                    <div class="col-sm-12">
                                                        <div class="headjobs-left pb-2">
                                                            <h5 class="text-blue py-1">Job Skills</h5>
                                                            <div class="informations">
                                                                <span v-if="jobDetails.jobSkill != null && jobDetails.jobSkill.length==0" class="p-1 mb-2 mr-1">No skills found</span>
                                                                <div v-for="(skill,index) in jobDetails.technologyList" class="">
                                                                    {{skill.technologyName}}
                                                                    <div>
                                                                        <span v-for="(skilldata,idx) in skill.technologySkillData" class="p-1 mb-2 mr-1 badge " :class="colours[idx%5]">
                                                                            {{skilldata.skillName}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="headjobs-left">
                                                            <h5 class="text-blue py-1">Job Requirement</h5>
                                                            <p class="htmlText" v-html="jobDetails.jobRequirement"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <hr>
                                                    </div>
                                                </div>
                                                <div class="w-100">
                                                    <i v-if="!checkAllEmpty(jobDetails.contact)" class="ti-location-pin pr-2"></i>
                                                    <span v-if="checkEmpty(jobDetails.contact.addressLine1)">
                                                        {{jobDetails.contact.addressLine1}}
                                                    </span>
                                                    <span v-if="checkEmpty(jobDetails.contact.cityName)">
                                                        {{jobDetails.contact.cityName}}
                                                    </span>
                                                    <span v-if="checkEmpty(jobDetails.contact.stateName)">
                                                        {{jobDetails.contact.stateName}}
                                                    </span>
                                                    <span v-if="checkEmpty(jobDetails.contact.countryId)">
                                                        {{jobDetails.contact.countryId}}
                                                    </span>
                                                    <span v-if="checkEmpty(jobDetails.contact.zipCode)">
                                                        {{jobDetails.contact.zipCode}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import VueMeta from 'vue-meta';
export default {
    computed: {
        recruiterId() {
            if (this.$store.getters.currentUser.profileId)
                return this.$store.getters.currentUser.profileId;
            else return 0;
        },
        jobid() {
            return this.$route.params.jobid;
        },
         ttClientAccountId() {
            if (this.$store.getters.currentUser.ttClientAccountId)
                return this.$store.getters.currentUser.ttClientAccountId;
            else return 0;
        }
    },

    data() {
        return {
            redirectURL: "",
            isExpand: false,
            joblist: [],
            browsepagination: this.$store.state.paginationconfig,
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            industrylist: [],
            clientlist: [],
            jobStatuslist: [],
            interviews: [],
            recruiterlist: this.$store.getters.getRecruiterList,
            recCandInfo: {
                talentCount: 0,
                candShortlistedCount: 0,
                clientReviewedCount: 0,
                hiredCount: 0,
                talentIdentified: [],
                htmlMessage: "",
            },
            colours: ['badge-primary', 'badge-success', 'badge-secondary', 'badge-danger', 'badge-warning', 'badge-info'],
            jobId: this.jobid,
            jobDetails: {
                jobTitle: "",
                jobCode: "",
                clientName: "",
                industryName: "",
                position: "",
                eligibility: "",
                experience: "",
                salary: "",
                minSalary: "",
                maxSalary: "",
                frequencyPercentage: "",
                currency: "",
                vacancies: "",
                workType: "",
                expType: "",
                payFrequency: "",
                dueDate: "",
                jobRequirement: "",
                taxTerm:"",
                jobSkill: [],
                technologyList: [],
                days: 0,
                contact: {
                    contactPerson: "",
                    email: "",
                    phone1: "",
                    phone2: "",
                    website: "",
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    countryId: null,
                    zipCode: ""
                }
            },
            elements: {
                showadvancedsearch: false,
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    jobTitle: "",
                    jobType: "",
                    technologyId: "",
                    startDate: "",
                    endDate: "",
                    recruiterId: this.recruiterId,
                    clientId: "",
                    industryId: "",
                    status: "",
                    recId: ""
                },
            },
            coldisplay: {
                jobTitle: true,
                jobCode: true,
                client: true,
                industry: true,
                position: true
            },
            delJobParam: {
                index: -1,
                id: 0
            },
            fbJobTitle: "Dynamic Title for job",
            infoPage: {
                title: 'Job View',
                description: 'website description',
                image: false,
                app: 'app name',
            }
        }
    },
    mounted() {
        this.getJobInfo(this.jobid);
        this.loadJobs();
        this.loadJobStatusList();
        this.loadIndustryList();
        this.loadClientList();
    },
    head: {
        title: function() {
            return {
                inner: this.infoPage.title,
                separator: ' ',
                complement: ' '
            }
        },
        meta: function() {
            return [
                { name: 'application-name', content: this.infoPage.app },
                { name: 'description', content: this.infoPage.description },
                { name: 'twitter:title', content: this.infoPage.title },
                { n: 'twitter:description', c: this.infoPage.description },
                { property: 'fb:app_id', content: '651920748870782' },
                { property: 'og:title', content: 'Content Title' },
                { property: 'og:description', content: 'facebook job description' },
            ]
        }
    },
    methods: {
        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },
        checkAllEmpty(contact) {

            if (this.checkEmpty(contact.addressLine1) == false && this.checkEmpty(contact.cityName) == false && this.checkEmpty(contact.stateName) == false && this.checkEmpty(contact.countryId) == false && this.checkEmpty(contact.zipCode) == false) {
                return true;
            }
            return false;
        },
        routerChange(jobId) {
            this.getJobInfo(jobId);
            this.$router.push({ name: 'viewjob', params: { jobid: jobId } });
        },
        getJobInfo(jobId) {

            axios.get(this.$store.getters.getAPIBasePath + '/jobresource/view/job/' + jobId)
                .then(response => {
                    let result = response.data;
                    if (!result.status) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    this.jobDetails.id = result.jobs.id;
                    this.jobDetails.experience = result.jobs.experience;
                    this.jobDetails.jobTitle = result.jobs.jobTitle;
                    this.jobDetails.salary = result.jobs.salary;
                    this.jobDetails.minSalary = result.jobs.minSalary;
                    this.jobDetails.maxSalary = result.jobs.maxSalary;
                    let freq = result.jobs.frequencyPercentage;
                    if(freq>0){this.jobDetails.frequencyPercentage = freq+"%"}
                    else{
                        this.jobDetails.frequencyPercentage = freq;
                    }
                    this.jobDetails.currency = result.jobs.currency;
                    this.jobDetails.industryName = result.jobs.industryName;
                    this.jobDetails.position = result.jobs.position;
                    this.jobDetails.workType = result.jobs.workType;
                    this.jobDetails.expType = result.jobs.expType;
                    this.jobDetails.jobCode = result.jobs.jobCode;
                    this.jobDetails.dueDate = result.jobs.dueDate;
                    this.jobDetails.taxTerm = result.jobs.taxTerm;
                    this.jobDetails.location = result.jobs.location;
                    this.jobDetails.eligibility = result.jobs.eligibility;
                    this.jobDetails.jobRequirement = result.jobs.jobRequirement;
                    this.jobDetails.vacancies = result.jobs.vacancies;
                    this.jobDetails.payFrequency = result.jobs.payFrequency;
                    this.jobDetails.days = result.jobs.days;
                    if (result.jobs.contact != null) {
                        // let address = "";
                        // if(result.jobs.contact.addressLine1!="" && result.jobs.contact.addressLine2!=""){
                        //  address = addressLine1+", "+addressLine2;
                        // } else {
                        //  if(result.jobs.contact.addressLine1!="") address.=
                        // }
                        this.jobDetails.contact.contactPerson = result.jobs.contact.contactPerson;
                        this.jobDetails.contact.email = result.jobs.contact.email;
                        this.jobDetails.contact.phone1 = result.jobs.contact.phone1;
                        this.jobDetails.contact.phone2 = result.jobs.contact.phone2;
                        this.jobDetails.contact.website = result.jobs.contact.website;
                        this.jobDetails.contact.cityName = result.jobs.contact.cityName;
                        this.jobDetails.contact.stateName = result.jobs.contact.stateName;
                        this.jobDetails.contact.zipCode = result.jobs.contact.zipCode;
                        this.jobDetails.contact.countryId = result.jobs.contact.countryId;
                        this.jobDetails.contact.addressLine1 = result.jobs.contact.addressLine1;
                    }
                    this.jobDetails.technologyList = result.jobs.technologyList;
                    this.jobDetails.jobSkill = result.jobs.jobSkill;
                    this.jobDetails.clientName = result.jobs.clientName;
                    this.getEncodedKey(this.jobDetails.id);
                })
                .catch(error => {
                    iziToast.error({ title: 'Error Occured', message: this.$config.JobLoadErr, position: 'topRight' });
                });
        },

        loadIndustryList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/industrylist')
                .then(response => {
                    this.industrylist = response.data.industries;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadClientList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/clientlist')
                .then(response => {
                    this.clientlist = response.data.clientlist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadJobStatusList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/jobStatusList')
                .then(response => {
                    this.jobStatuslist = response.data.jobStatus;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadJobs() {
            axios.post(this.$store.getters.getAPIBasePath + '/jobresource/joblist?page=' + this.browsepagination.currentpage + '&size=' + this.search.limit, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.joblist = result.jobs;
                    this.browsepagination.totalrecords = result.pagination[0].total;
                    this.browsepagination.currentpage = result.pagination[0].currentPage;
                    this.browsepagination.totalpages = result.pagination[0].lastPage;
                    //this.getJobInfo(this.joblist[0].id);

                })
                .catch(error => {
                    console.log(error);
                });
        },
        getKey(jobId) {

            axios.post(this.$store.getters.getAPIBasePath + '/jobresource/public/jobapplied/key', { "id": jobId })
                .then(response => {
                    var key = response.data;
                    this.$router.push({ name: 'homelogin', params: { key: key, jobId: jobId } })
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getEncodedKey(jobId) {

            axios.post(this.$store.getters.getAPIBasePath + '/jobresource/key/encode/fb/share', { "id": jobId })
                .then(response => {
                    var encodedKey = response.data;
                    this.redirectURL = this.$store.getters.getVUEBasePath + "/share/job/view/" + encodedKey;                    
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getSocialMediaShareInfo(title) {            
        },
        getSocialMediaHashTag(title, code) {
        }
    }
}

</script>
