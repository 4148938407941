<template>
	<div id="content_Personal_Info">
		<div class="card">
			<div class="card-header p-2">Primary Information </div>
			<div class="card-body p-2">
				<div class="basic-block">
					<div class="row ten-columns">
						<div class="col-md-3 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>First Name</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group required-field-block" >
										<input type="text" maxlength="25" size="30" class="form-control" v-model="ProfileData.firstName">
										<span class="required-icon">
										</span>

									</div>
									<label v-if="$v.ProfileData.firstName.$error && !$v.ProfileData.firstName.required" class="text-danger">First name is required</label>
									<label v-if="$v.ProfileData.firstName.$error && !($v.ProfileData.firstName.minLength && $v.ProfileData.firstName.maxLength)" class="text-danger">First name should be at least {{this.$v.ProfileData.firstName.$params.minLength.min}} or maximum {{this.$v.ProfileData.firstName.$params.maxLength.max}} character length</label>
								</div>
							</div>
						</div>
						<div class="col-md-3 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Last Name</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group required-field-block" >
										<input type="text" v-model="ProfileData.lastName" maxlength="45" size="30"  class="form-control">
									</div> 
								</div>
							</div>
						</div>
						<div class="col-md-3 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Username (Email)</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group required-field-block" >
										<input type="text" maxlength="50" v-model="ProfileData.email" class="Submitaddress form-control"  disabled>
										<span class="required-icon"></span>
									</div>

									<label v-if="$v.ProfileData.email.$error && !$v.ProfileData.email.required" class="text-danger">Email is required</label>
									<label v-if="$v.ProfileData.email.$error && !$v.ProfileData.email.maxLength" class="text-danger">Email names should be maximum {{this.$v.ProfileData.email.$params.maxLength.max}} character length</label>
									<label v-if="$v.ProfileData.email.$error && !$v.ProfileData.email.email" class="text-danger">Email is not valid</label>
								</div>
							</div>  
						</div>
						<div v-if="false" class="col-md-3 col-sm-6 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Time Zone</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<Select2 v-model="ProfileData.timezone" :options="timeZones"/>
								</div>
							</div>  
						</div>
						<div v-if="ismember" class="col-md-3 col-sm-6 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Role</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group required-field-block" >
										<input type="text" v-model="ProfileData.role" maxlength="45" size="30"  class="form-control">
									</div> 
								</div>
							</div>  
						</div>
					</div>
				</div>
			</div>
			<div class="card-header p-2">
				<a href="Javascript:void(0);" @click="savePersInfo" class="btn btn-primary  float-right">Save</a>
			</div>                
		</div>
	</div>
</template>
<style>
	.p-2 {
		margin-right: 0.1rem;
	}
</style>

<script>
	import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
	import Select2 from 'v-select2-component';
	import iziToast from 'izitoast';

	export default {
		props: {
			ProfileData: Object,
		},
		components: {
			Select2
		},
		data() {
			return {
				ismember: this.$route.params.ismember,
				industries:this.$store.getters.getIndustries,
				Countries:this.$store.getters.getCountries,
				timeZones: this.$store.getters.getTimeZones,
				EducationalInfos:[ { id:1 } ]
			}
		},
		validations: {
			ProfileData:{
				firstName:{
					required,
					minLength: minLength(2),
					maxLength: maxLength(30)
				},
				email:{
					required,                
					maxLength: maxLength(50),
					email,
				}
			}
		},
		methods:
		{
			savePersInfo:function() {
				console.log(this.ProfileData);
				this.$v.ProfileData.$touch();
				if(this.$v.ProfileData.$invalid)
				{                
					console.log('Error');
				}
				else
				{
					axios.post(this.$store.getters.getAPIBasePath+'/recruiterresource/update/recruiter/'+this.ProfileData.id,this.ProfileData)
					.then(response => {
						console.log(response)
						if(response.data.status == "ACCEPTED"){
							iziToast.success({title: 'Success', message: this.$config.IntPerInfoSuc, position:'topRight'});
						} else {
							iziToast.error({title: 'Error Occured', message: this.$config.IntPerInfoErr, position:'topRight'});
						}
					})
					.catch(error => {
						console.log(error);
						iziToast.error({title: 'Error Occured', message: this.$config.IntPerInfoErr, position:'topRight'});
					});
				}
			}
		}
	}

</script>