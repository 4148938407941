<style type="text/css">
.input-group.extended>div {
    width: auto !important;
}

</style>
<template>
    <div class="one-content active" id="interview_plan">
        <div v-if="elements.isEdit || elements.isAdd" class="">
            <div class="card">
                <div class="card-header p-2">Interview Plan
                    <a href="javascript:" class="pull-right" @click="loadExistingInterviews" data-toggle="modal" data-target="#BrowseInterviews"> Use existing plan</a>
                </div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div class="row ten-columns">
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Interview Title</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group ">
                                            <input v-model="newInterview.title" type="text" name="title" maxlength="40" value="" class="fldboxbig form-control">
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div v-if="elements.submitted && !$v.newInterview.title.required" class="invalid-feedback mt-0">Title is required</div>
                                        <div v-if="elements.submitted && !$v.newInterview.title.minLength" class="invalid-feedback mt-0">Title must have atleast 5 characters</div>
                                        <div v-if="elements.submitted && !$v.newInterview.title.maxLength" class="invalid-feedback mt-0">Title shouldn't exceed more than 100 characters</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Interview Type</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="newInterview.interviewType" name="interviewType" class="form-control">
                                            <option v-for="options in interviewType" :value="options.id">{{options.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div v-if="elements.submitted && !$v.newInterview.interviewType.required" class="invalid-feedback mt-0">Interview Type is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Interview Requirement</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <!-- <div class="input-group" >
                                            <input v-model="newInterview.interviewRequirement" type="text" name="newInterview.interviewRequirement" maxlength="200" value="" class="fldboxbig form-control">
                                        </div> -->
                                        <div class="position-relative">
                                            <input class="form-control" type="text" max-length=200 v-model="newInterview.interviewRequirement" :title="newInterview.interviewRequirement" />
                                            <a href="javascript:;" @click="showBoxRequirement(-1)" data-toggle="modal" data-target="#RequirementShow">
                                                <span class="search-addon"><span class="fa fa-ellipsis-h"></span> </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div v-if="elements.submitted && !$v.newInterview.interviewRequirement.required" class="invalid-feedback mt-0">Interview Requirement is required</div>
                                        <div v-if="elements.submitted && !$v.newInterview.interviewRequirement.minLength" class="invalid-feedback mt-0">Interview Requirement must have atleast 5 characters</div>
                                        <div v-if="elements.submitted && !$v.newInterview.interviewRequirement.maxLength" class="invalid-feedback mt-0">Interview Requirement shouldn't exceed more than 2000 characters</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Due Date</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group date" data-date-format="mm-dd-yyyy">
                                            <datepicker v-model="newInterview.dueDate" name="dueDate" input-class="form-control enablefield" wrapper-class="input-group date" :disabled-dates="disabledDates" format="MM/dd/yyyy"></datepicker>
                                            <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                         <div v-if="elements.submitted && !$v.newInterview.dueDate.required" class="invalid-feedback mt-0">Due Date is required</div> 
                                        <!-- <div v-if="elements.submitted && !$v.newInterview.dueDate.checkDate" class="invalid-feedback mt-0">Invalid Due Date</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header p-2">Functional / Technical Requirements
                    <a href="javascript:" class="pull-right" @click="loadQuestions" data-toggle="modal" data-target="#ExistingQuestions"> Use existing question</a></div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div class="row ten-columns specialization-form">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Question pattern</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group ">
                                            <select v-model="questionPattern" class="select2 questionPattern form-control">
                                                <option>-- Select --</option>
                                                <option value="scoreCard">Score card</option>
                                                <option value="questions">Questions</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="questionPattern=='scoreCard'" class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Specialization / Tools and Tech</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <multiselect v-model="specialization" :options="technologies" name="specialization" label="technology" track-by="id" select-label="" deselect-label="" @select="loadSkills" class=""></multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="questionPattern=='scoreCard'" class="col-md-7 col-sm-12 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Skills</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <multiselect v-model="skill_value" :options="skills" name="skill_value" label="skillName" track-by="skillId" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" @select="updateTech" :max-height="150" :limit="2"></multiselect>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div v-if="elements.submitted && !$v.skill_value.requirements" class="invalid-feedback mt-0">Please add either skills or questions</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="questionPattern=='questions'" class="col-md-10 col-sm-10 col-10">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <label>Add Questions</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group extended">
                                            <input type="text" class="form-control h-38" maxlength="200" v-model="questionTag" placeholder="Enter Question" aria-label="Enter Question" @keyup.enter="addQuestionTag" aria-describedby="basic-addon2">
                                            <div class="input-group-append">
                                                <i aria-hidden="true" @click="addQuestionTag" class="ti-plus add-icon btn btn-primary pt-3"></i>
                                                <!-- <a aria-hidden="true" @click="addQuestionTag" class="btn btn-primary pt-3 ml-3">ANS REF</a> -->
                                                <a href="javascript:" class="btn btn-primary pt-3 ml-3" @click="loadQuestions" data-toggle="modal" data-target="#ExistingQuestions"> ANS REF</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div v-if="elements.submitted && !$v.questions.requirements" class="invalid-feedback mt-0">Please add either skills or questions</div>
                                        <!-- <div v-if="elements.submitted && !$v.technicalRequirements" class="invalid-feedback mt-0">Please add either skills or questions.</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- v-if="questionPattern=='questions' && questions.length" -->
                        <div class="row p-4 ml-0 mr-0">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead v-if="skill_value.length > 0 || questions.length > 0">
                                        <tr>
                                            <th>Question Pattern</th>
                                            <th>Specialization / Tools and Tech</th>
                                            <th>Reference</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="skill_value.length > 0" v-for="(technology,index) in skillsByTechnology">
                                            <td>Score Card</td>
                                            <td>{{technology[0].techName}} (<span class="badge badge-primary mr-1 ml-1" v-for="(skills,idx) in technology">{{skills.skillName}}<a class="pl-2" href="javascript:" @click="popSkill(idx,skills)"><i class="ti-close text-white"></i></a></span>)</td>
                                            <td>--</td>
                                            <td>--</td>
                                        </tr>
                                        <tr v-if="questions.length > 0" v-for="(Question,index) in questions">
                                            <td>Question</td>
                                            <td>{{Question.interviewQuestions}}</td>
                                            <td :title="Question.answer" v-html="getAnsSubstring(Question.answer)"></td>
                                            <td>
                                                <a href="javascript:" @click="popQuestion(index)"><i class="ti-close"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header p-2">Interviewers
                    <div class="icons pull-right">
                        <i class="ti-plus add-icon hover-hand" @click="loadInterviewerList()" aria-hidden="true" data-toggle="modal" data-target="#BrowseInterviewers"></i>
                    </div>
                </div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div class="row ten-columns">
                            <div class="col-sm-12">
                                <div v-if="panelMembers.length" class="table-responsive">
                                    <table class="table table-borderless all-table">
                                        <thead>
                                            <tr>
                                                <!-- <th></th> -->
                                                <th>Name</th>
                                                <th>Position</th>
                                                <th>Technology</th>
                                                <th>Phone No.</th>
                                                <th>Email</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style="" v-for="(panelMember,index) in panelMembers">
                                                <!-- <td style="text-align:center;width: 60px;">
                                                    <div class="profile-icon"><img src="icons/candidates.png" alt=""></div>
                                                </td> -->
                                                <td>{{ panelMember.interviewerName }}</td>
                                                <td>{{ panelMember.position }}</td>
                                                <td>{{ panelMember.technology }}</td>
                                                <td>{{ panelMember.phoneNo }}</td>
                                                <td>{{ panelMember.email }}</td>
                                                <td>
                                                    <a href="javascript:" @click="popPanMem(index,panelMember.id)" data-toggle="modal" data-target="#removeInterviewer" title="Remove" ><i class="ti-close"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!--  <div v-if="elements.submitted && !$v.panelMembers.required" class="invalid-feedback mt-0">Interviewer is required</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 pl-0">
                <a href="javascript:" @click="refreshAll" class="pull-right btn btn-secondary"><i class="ti-close button"></i> Cancel</a>
                <a v-if="elements.isAdd" href="javascript:" @click="addInterview" class="pull-right btn btn-primary mr-3"><i class="ti-plus button"></i> Add</a>
                <a v-if="elements.isEdit" href="javascript:" @click="updateInterview" class="pull-right btn btn-primary mr-3"><i class="fa fa-pencil button"></i> Update</a>
            </div>
        </div>
        <div class="card col-md-12 p-0">
            <div class="card-header p-2">Interviews <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{interviewPlanpagination.totalRecords}} - Total)</span>
            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                        <div class="dropdown">
                                            <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                            <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                <a v-for="(value, index) in interviewPlanpagination.searchlimit" class="dropdown-item" href="javascript:void(0);" @click="interviewplanchangeLimit(value)">{{value}}</a>
                                            </div>
                                        </div>
                                        </span>
                <a v-if="!elements.isEdit && !elements.isAdd" href="javascript:" @click="elements.isAdd=true" class="pull-right btn btn-primary ml-2"><i class="ti-plus button "></i> Add</a>
                <a href="javascript:" v-if="!elements.isEdit && !elements.isAdd" @click="goBack" class="pull-right btn btn-secondary">Back</a>
            </div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Interview Title</th>
                                            <th>Interview Type</th>
                                            <th>Interview Requirements</th>
                                            <th>Due Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="interviewPlanInterviews.length==0">
                                            <td colspan=6 align="center">No Interviews found</td>
                                        </tr>
                                        <tr v-for="(Interview,index) in interviewPlanInterviews">
                                            <td>{{((interviewPlanpagination.currentPage-1) * (interviewPlanpagination.rowsPerPage)) + (index+1)}}</td>
                                            <td>{{Interview.interviewTitle}}</td>
                                            <td>{{Interview.interviewType}}</td>
                                            <td>{{Interview.interviewRequirement}}</td>
                                            <td>{{Interview.dueDate | formatDate}}</td>
                                            <td>
                                                <a href="javascript:" @click="editInterview(Interview.id)" title="Edit" class="p-0"><i class="fa fa-pencil-square-o fa-custom fa-lg"></i></a>
                                                <a href="javascript:" @click="deleteInterview(Interview.id)" title="Remove" data-toggle="modal" data-target="#removeInterview" class="p-0"><i class="fa fa-trash-o fa-custom fa-lg pl-2"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-12">
                                <b-pagination size="md" class='pull-right' v-if="interviewPlanInterviews.length!=0" :total-rows="interviewPlanpagination.totalRecords" v-model="interviewPlanpagination.currentPage" :per-page="interviewPlanpagination.rowsPerPage" @input="loadInterviewPlan()"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="mt-2 pull-right">
            <a href="" class="btn btn-primary ">Save Draft</a>
            <a href="" style="margin-left: 10px;color: #fff;" class="btn btn-primary ">Save</a>
            <a href="" class="btn btn-danger" style="margin-left: 10px;color: #fff;">Cancel</a>
        </div> -->
        <div class="modal fade" id="BrowseInterviewers" tabindex="-1" role="dialog" aria-labelledby="InterviewersModal" aria-hidden="true">
            <div class="modal-dialog mw-100 w-75" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="InterviewersModal">Interviewers</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetFilter()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="card m-b-3">
                            <div class="card-body p-2">
                                <div class="basic-block">
                                    <form id="main-search">
                                        <div class="row form-group ten-columns">
                                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Name</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <input type="text" v-model="params.name" class="form-control" v-on:keyup.enter="filterInterviewers">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label>Email</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Technology</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                        <input type="text" v-model="params.technologyName" class="form-control" v-on:keyup.enter="filterInterviewers">
                                                            <select v-if="false" class="form-control" v-model="params.technology">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in technologies" :value="info.id">{{info.technology}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row pull-right">
                                                    <button type="button" class="btn btn-primary btn-sm filter-button float-right" @click="filterInterviewers()" style="margin-left: 10px;">Find</button>
                                                    <button type="button" class="btn btn-primary btn-sm filter-button float-right" @click="resetFilter()" style="margin-left: 10px;">Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="row scroll-down-list">
                                        <div class="col-sm-12">
                                            <table class="table table-borderless all-table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" @click="checkAll" type="checkbox" id="rowchckboxall" value="option1">
                                                                <label class="form-check-label" for="rowchckboxall"></label>
                                                            </div>
                                                        </th>
                                                        <th>Name</th>
                                                        <th>Position</th>
                                                        <th>Technology</th>
                                                        <th>Phone No.</th>
                                                        <th>Email</th>
                                                        <!-- <th>Date</th>
                                                    <th>Available From</th>
                                                    <th>Available Till</th> -->
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="!recruiters_list.length">
                                                        <td class="text-center" colspan="100%">No Interviewers found</td>
                                                    </tr>
                                                    <tr v-for="Recruiter in recruiters_list">
                                                        <td>
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox" :id="'rowchckbox'+Recruiter.id" v-model="panelMem" :value="Recruiter.id" checked="">
                                                                <label class="form-check-label" :for="'rowchckbox'+Recruiter.id"></label>
                                                            </div>
                                                        </td>
                                                        <td>{{Recruiter.interviewerName}}</td>
                                                        <td>{{Recruiter.position}}</td>
                                                        <td>{{Recruiter.technologyName}}</td>
                                                        <td>{{Recruiter.phoneNo == '+1 '&&  '+91 '? '':Recruiter.phoneNo}}</td>
                                                        <td>{{Recruiter.email}}</td>
                                                        <!-- <td>{{selInterview.interviewDate}}</td>
                                                    <td>{{Recruiter.availableFrom | formatTime}}</td>
                                                    <td>{{Recruiter.availableTo | formatTime}}</td> -->
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="offset-sm-9 col-sm-3 float-right">
                                            <button type="button" class="btn btn-primary  float-right" data-dismiss="modal" @click="addInterviewers" style="margin-left: 10px;">Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="ExistingQuestions" tabindex="-1" role="dialog" aria-labelledby="QuestionsModal" aria-hidden="true">
            <div class="modal-dialog mw-100 w-75" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="QuestionsModal">Interview Questions</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetQuestions()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="card m-b-3">
                            <div class="card-body p-2">
                                <div class="basic-block">
                                    <form id="main-search">
                                        <div class="row form-group ten-columns">
                                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Technology</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <select class="form-control" v-model="params.technologyId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in technologies" :value="info.id">{{info.technology}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row pull-right">
                                                    <button type="button" class="btn btn-primary btn-sm filter-button float-right" @click="loadQuestions" style="margin-left: 10px;">Find</button>
                                                    <button type="button" class="btn btn-primary btn-sm filter-button float-right" @click="resetQuestions()" style="margin-left: 10px;">Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="row scroll-down-list">
                                        <div class="col-sm-12">
                                            <table class="table table-borderless all-table text-left">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Questions</th>
                                                        <th>Answers</th>
                                                        <th>Technology</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="!interviewQuestions.length">
                                                        <td colspan="8" class="text-center">No records found</td>
                                                    </tr>
                                                    <tr v-for="(question,index) in interviewQuestions">
                                                        <td>
                                                            <!-- <div class="form-check form-check-inline">
                                                                <input type="radio" :id="'radiobtn'+question.id" :value="question.id" v-model="selectedValue" @click="setQuestionId(question.id)">
                                                                <label :for="'radiobtn'+question.id"></label>
                                                            </div> -->
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox" :id="'rowcheckbox1'+question.id" v-model="selectedValueCheck" :value="question.id" checked="" @click="addExistingQuestion(question.id)">
                                                                <label class="form-check-label" :for="'rowcheckbox1'+question.id"></label>
                                                            </div>
                                                        </td>
                                                        <td>{{question.question}}</td>
                                                        <td>{{question.answer}}</td>
                                                        <td>{{question.technology}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="offset-sm-9 col-sm-3 float-right">
                                            <button type="button" class="btn btn-primary  float-right" data-dismiss="modal" @click="clearQuestionCheck" style="margin-left: 10px;">Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="BrowseInterviews" tabindex="-1" role="dialog" aria-labelledby="InterviewersModal" aria-hidden="true">
            <div class="modal-dialog mw-100 w-75" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetInterviews()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="card m-b-3">
                            <div class="card-body p-2">
                                <div class="basic-block">
                                    <form id="main-search" v-on:submit.prevent>
                                        <div class="row form-group ten-columns">
                                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Interview Title</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <input type="text" v-on:keyup.enter="loadExistingInterviews" v-model="existingInterviews.title" class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                                <div class="row pull-right">
                                                    <button type="button" class="btn btn-primary btn-sm filter-button float-right" @click="filterexistingplan" style="margin-left: 10px;">Find</button>
                                                    <button type="button" class="btn btn-primary btn-sm filter-button float-right" @click="resetInterviews()" style="margin-left: 10px;">Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="row scroll-down-list">
                                        <div class="col-sm-12">
                                         <h5 class="modal-title" id="InterviewersModal">Interviews
                                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{existingpagination.totalRecords}} - Total)</span>
                                       
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                        <div class="dropdown">
                                            <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                            <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                <a v-for="(value, index) in existingpagination.searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                            </div>
                                        </div>
                                        </span> 
                                    </h5>
                                            <table class="table table-borderless all-table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                        </th>
                                                        <th>Interview Title</th>
                                                        <th>Interview Type</th>
                                                        <th>Interview Requirement</th>
                                                        <th>Due Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="!existingInterviewsList.length">
                                                        <td class="text-center" colspan="100%">No Interviews found</td>
                                                    </tr>
                                                    <tr v-for="interview in existingInterviewsList">
                                                        <td>
                                                            <div class="form-check form-check-inline">
                                                                <input type="radio" :id="'radiobtn'+interview.id" :value="interview.id" v-model="selectedValueRadio" @click="interviewId(interview.id)">
                                                                <label :for="'radiobtn'+interview.id"></label>
                                                            </div>
                                                        </td>
                                                        <td>{{interview.interviewTitle}}</td>
                                                        <td>{{interview.interviewType}}</td>
                                                        <td>{{interview.interviewRequirement}}</td>
                                                        <td>{{interview.dueDate | formatDate }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-md-12">
                                            <b-pagination v-if="existingInterviewsList.length!=0" size="md" class='pull-right'  :total-rows="existingpagination.totalRecords" v-model="existingpagination.currentPage" :per-page="existingpagination.rowsPerPage" @input="loadExistingInterviews()"></b-pagination>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="offset-sm-9 col-sm-3 float-right">
                                            <button type="button" class="btn btn-primary  float-right" data-dismiss="modal" @click="addExistingInterview()" style="margin-left: 10px;">Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="RequirementShow" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Please enter the interview requirement</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <textarea class="form-control more-text-area" v-model="interviewRequirement"></textarea>
                    </div>
                    <div v-if="newInterview.viewonly != 1" class="modal-footer">
                        <button type="button" @click="updateRequirement()" class="btn btn-primary" data-dismiss="modal">OK</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="removeInterviewer" :onYes="deleteInterviewer" :returnParams="delParam" title="Delete Confirmation" message="Are you sure to remove this interviewer ?"/>
        <Dialog id="removeInterview" :onYes="removeInterview" title="Delete Confirmation" message="Are you sure to delete this interview ?" />
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import Select2 from 'v-select2-component';
import iziToast from 'izitoast';
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
    components: {
        Multiselect,
        Select2
    },  
    data() {
        return {
            deletevalue: "",
            interviewType: this.$store.getters.getInterviewType,
            recruiters_list: [], //all recruiters
            all_recruiters_list: [],
            elements: {
                isAdd: false,
                isEdit: false,
                submitted: false,
                delInterviewId: null,
                editInterviewId: null
            },
            newInterview: {
                jobId: this.$route.params.jobid,
                title: "",
                interviewType: "",
                interviewRequirement: "",
                dueDate: null,
                interviewerId: [],
                candSkillsEval: [],
                id: null,
            },
            requirementId: -1,
            interviewRequirement: '',
            questionPattern: "scoreCard",
            technologies: [], //dropdown options
            skills: [], //dropdown options
            specialization: "", //dropdown value
            skill_value: [], //dropdown value
            questions: [], //all questions
            questionTag: "", //single question
            panelMem: [], //selected recruiters with id only
            panelMembers: [], //selected recruiters with details
            isCheckAll: false,
            params: {
                name: "",
                technology: "",
                technologyId: "",
                technologyName:""
                // availableDate: ""
            },
            interviewPlanInterviews: [],
            interviewPlanInterviewsCount: 0,
            existingInterviewsList: [],
            existingInterviews: {
                title: ""
            },
            selectedValueCheck: [],
            selectedValueRadio: [],

            existingInterviewId: "",
            interviewQuestions: [],
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                searchlimit: this.$store.state.searchlimit
            },
            existingpagination:{
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                searchlimit: this.$store.state.searchlimit
            },
            interviewPlanpagination:{
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                searchlimit: this.$store.state.searchlimit
            },
            delParam: {
                index: -1,
                id: 0
            },
            existingQuestionId: [],
            disabledDates: {
                to: new Date(Date.now() - 8640000)
            }
        }
    },
    validations: {
        newInterview: {
            title: { required, minLength: minLength(5), maxLength: maxLength(100) },
            interviewType: { required },
            interviewRequirement: { required, minLength: minLength(5), maxLength: maxLength(2000) },
            dueDate: {
                required,
                // checkDate: function(dueDate) {
                //     return dueDate >= new Date();
                // }
            }
        },
        skill_value: {
            requirements: function(skill_value, vueComponent) {
                return (vueComponent.skill_value.length || vueComponent.questions.length);
            }
        },
        questions: {
            requirements: function(questions, vueComponent) {
                return (vueComponent.skill_value.length || vueComponent.questions.length);
            }
        },
        //panelMembers: { required }
    },
    mounted() {
        localStorage.removeItem('setRecPlanId');
        this.loadTechnologyList();
        this.getInterviewersList();
        this.loadInterviewPlan();

    },
    computed: {
        skillsByTechnology() {
            return _.groupBy(this.skill_value, 'technologyId');
        }
    },
    methods: {
        async getInterviewersList(){
            await this.loadInterviewerList();
            this.all_recruiters_list = this.recruiters_list;
        },
        goBack(){
            this.$router.go(-1);
            //this.$router.push("/jobs");
        },
        loadTechnologyList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/technologylist')
                .then(response => {
                    this.technologies = response.data.technologylist;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        loadSkills(selected_technology) {
            this.skills = [];
            if (selected_technology.settingsTechnologySkill)
                this.skills = selected_technology.settingsTechnologySkill;
            else
                this.skills = [];
        },
        updateTech(selected_skill) {
            if (this.specialization != null)
                Object.assign(selected_skill, { techName: this.specialization.technology });
        },
        loadInterviewerList() {
            return axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/list/all/interviewers', this.params)
                .then(response => {
                    let result = response.data;
                    console.log(result)
                    if (result.status) {
                        //this.recruiters_list = response.data.interviewer;
                        this.recruiters_list = result.data;
                            console.log(this.recruiters_list)
                    } else {
                        let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.LoadingErr;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.LoadingErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
        },
        addInterviewers() {
            this.resetFilter();
            this.panelMembers = this.loadInterviewersById(this.panelMem);
            return this.panelMembers;
        },
        loadInterviewersById(id = []) {
            return this.all_recruiters_list.filter(function(rdata, key) {
                if (id.includes(rdata.id)) {
                    return rdata;
                }
            });
        },
        checkAll() {
            this.isCheckAll = !this.isCheckAll;
            this.panelMem = [];
            let self = this;
            if (this.isCheckAll) {
                $.each(this.recruiters_list, function(key, value) {
                    self.panelMem.push(value.id);
                });
            }
        },
        filterInterviewers() {
            this.pagination.currentPage = 1;
            this.loadInterviewerList();
        },
         filterexistingplan() {
            this.existingpagination.currentPage = 1;
            this.loadExistingInterviews();
        },
        resetFilter() {
            this.params.name = "";
            this.params.technology = "";
            this.params.technologyName = ""; 
            this.loadInterviewerList();
        },
        resetQuestions() {
            this.params.technologyId = "";
            this.selectedValueCheck = "";
            this.loadQuestions();
        },
        resetInterviews() {
            this.existingInterviews.title = "";
            this.selectedValueRadio = "";
            this.loadExistingInterviews();
        },
        popPanMem:function(arg1, arg2)
            {
                this.delParam.index=arg1;
                this.delParam.id=arg2;
            },
        deleteInterviewer:function(arg){
            if(this.elements.editInterviewId && this.delParam.id !== null||""){
            let data = {
                "rpInterviewId": this.elements.editInterviewId,
                "interviewerId": this.delParam.id
            };
            axios.delete(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/delete/recruitmentplan/interview', {data})
            .then(response => {
                let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({ title: 'Success', message: this.$config.IntDelSuc, position: 'topRight' });
                        this.$delete(this.panelMembers, this.delParam.index);
                        this.$delete(this.panelMem, this.panelMem.indexOf(this.delParam.id));
                    } else {
                        iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    iziToast.error({ title: 'Error', message: this.$config.ErrMsg, position: 'topRight' });
                    console.log(error);
                });
            }
            else if(this.delParam.id !== null||""){
                this.$delete(this.panelMembers, this.delParam.index);
                this.$delete(this.panelMem, this.panelMem.indexOf(this.delParam.id));
            }

        },
        addInterview() {
            this.elements.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) return false;
            this.prepareDataBeforeSave();
            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/add/rp/interview', this.newInterview)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        let successmsg = (response.data.message != undefined) ? response.data.message : 'Interview added successfully...';
                        iziToast.success({ title: 'Success', message: successmsg, position: 'topRight' });
                        // this.$store.commit('setInterviewId', response.data.id);
                        localStorage.setItem('setRecPlanId', response.data.id);
                        this.loadInterviewPlan();
                        this.$emit('refreshIncidentHistory');
                        this.elements.isEdit = false;
                        this.elements.submitted = false;
                        this.selectedValueRadio = [];
                        this.scrollToTop();
                    } else {
                        let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.ProcessErr;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.ProcessErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
        },
        updateInterview() {
            this.elements.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) return false;
            this.prepareDataBeforeSave();
            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/update/rp/interview/' + this.elements.editInterviewId, this.newInterview)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        let successmsg = (response.data.message != undefined) ? response.data.message : this.$config.IntUpSuc;
                        iziToast.success({ title: 'Success', message: successmsg, position: 'topRight' });
                        this.loadInterviewPlan();
                        this.$emit('refreshIncidentHistory');
                        this.elements.isEdit = false;
                        this.elements.editInterviewId = null;
                        this.elements.submitted = false;
                        this.scrollToTop();
                    } else {
                        let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.ProcessErr;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.ProcessErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
        },
        prepareDataBeforeSave() {
            let self = this;
            // Assign skills with technology id and question pattern
            $.each(this.skill_value, function(key, value) {
                value.evalType = "scoreCard";
                value.interviewQuestions = null;
            });
            this.newInterview.candSkillsEval = this.skill_value.concat(this.questions);
            // Assign interviewer id
            this.newInterview.interviewerId = [];
            $.each(this.panelMembers, function(key, value) {
                self.newInterview.interviewerId.push({ "interviewerId": value.id });;
            });
            this.newInterview.id = localStorage.getItem('setRecPlanId'); //self.$store.getters.getRecPlanId;
        },
        loadInterviewPlan() {
            this.refreshAll();
            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/interviewlist?page='+ this.interviewPlanpagination.currentPage + '&size=' + this.interviewPlanpagination.rowsPerPage, { "jobId": this.newInterview.jobId })
                .then(response => {
                    if (response.data.status) {
                        console.log(response.data);
                        this.interviewPlanInterviews = response.data.interviews;
                        this.interviewPlanpagination.totalRecords = response.data.pagination[0].total;
                        this.interviewPlanpagination.currentPage = response.data.pagination[0].currentPage;
                        //this.$store.commit('setInterviewId', response.data.rpPlanId);
                        localStorage.setItem('setRecPlanId', response.data.rpPlanId);
                    } else {
                        let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.LoadingErr;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    let errmsg = (error.response) ? error.response.data.message : this.$config.LoadingErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
        },
        addQuestionTag() {
            if (this.questionTag != null && this.questionTag.length != 0)
                this.questions.push({
                    evalType: "questions",
                    skillId: null,
                    technologyId: null,
                    interviewQuestions: this.questionTag
                });
            this.questionTag = "";
        },
        popQuestion(index) {
            this.$delete(this.questions, index);
        },
        popSkill(index,skills) {
            var skillid =this.skill_value.findIndex((element) => element.skillId == skills.skillId)
            this.$delete(this.skill_value, skillid);
        },


        editInterview(id) {
            this.refreshAll();
            this.elements.isEdit = true;
            this.elements.isAdd = false;
            this.elements.editInterviewId = id;
            axios.get(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/view/rp/interview/' + id)
                .then(response => {
                    if (response.data.status) {
                        this.elements.isEdit = true;
                        let interviewData = response.data.interview;
                        this.newInterview.id = interviewData.id;
                        this.newInterview.title = interviewData.interviewTitle;
                        this.newInterview.interviewType = interviewData.interviewType;
                        this.newInterview.interviewRequirement = interviewData.interviewRequirement;
                        this.newInterview.dueDate = new Date(interviewData.dueDate);
                        this.panelMem, this.panelMembers = [];
                        let self = this;
                        $.each(response.data.rpInterviewers, function(key, value) {
                            self.panelMem.push(value.interviewerId);
                        });
                        this.panelMembers = this.loadInterviewersById(this.panelMem);
                        this.skill_value = [];
                        $.each(response.data.rpCandSkillsEvalSettings, function(key, value) {
                            if (value.evalType == "scoreCard") {
                                value.technologySkillSet.techName = value.technologySet.technology;
                                self.skill_value.push(value.technologySkillSet);
                            } else {
                                self.questions.push({
                                    evalType: "questions",
                                    skillId: null,
                                    technologyId: null,
                                    interviewQuestions: value.interviewQuestions,
                                });
                            }
                        });
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    let errmsg = (error.response && error.response.data.message != undefined) ? error.response.data.message : this.$config.UpdatingErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
        },
        deleteInterview(id) {
            this.elements.delInterviewId = id;
        },
        removeInterview() {
            axios.delete(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/delete/rp/interview/' + this.elements.delInterviewId)
                .then(response => {
                    var result = response.data;
                    this.$emit('refreshIncidentHistory');
                    if (result.status == "OK") {
                        iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                        this.loadInterviewPlan();
                    } else {
                        iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                        this.loadInterviewPlan();
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error.data);
                });
            this.elements.delInterviewId = null;
        },
        refreshAll() {
            this.elements.isAdd = false;
            this.elements.isEdit = false;

            this.newInterview.title = "";
            this.newInterview.interviewType = "";
            this.newInterview.interviewRequirement = "";
            this.newInterview.dueDate = null;
            this.newInterview.interviewerId = [];
            this.newInterview.candSkillsEval = [];
            this.newInterview.id = null;

            this.questionPattern = "scoreCard";
            this.specialization = "";
            this.skills = [];
            this.skill_value = [];
            this.panelMem = [];
            this.panelMembers = [];
            this.questions = [];
            this.questionTag = "";
        },
        showBoxRequirement: function(arg) {
            this.requirementId = arg;
            this.interviewRequirement = this.newInterview.interviewRequirement;
        },
        updateRequirement: function() {
            if (this.requirementId < 0) {
                this.newInterview.interviewRequirement = this.interviewRequirement;
            }
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        loadExistingInterviews() {
            this.refreshAll();
            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/existing/interviews?page='+ this.existingpagination.currentPage + '&size=' + this.existingpagination.rowsPerPage, { "title": this.existingInterviews.title })
                .then(response => {
                    if (response.data.status) {
                        this.existingInterviewsList = response.data.interviews;
                        this.existingpagination.totalRecords = response.data.pagination[0].total;
                        this.existingpagination.currentPage = response.data.pagination[0].currentPage;
                        console.log(this.existingpagination.totalRecords)
                    } else {
                        let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.LoadingErr;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    let errmsg = (error.response) ? error.response.data.message : this.$config.LoadingErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
        },
        changeLimit(newlimit) {
            this.existingpagination.currentPage = 1;
            this.existingpagination.rowsPerPage = newlimit;
            this.loadExistingInterviews();
        },
        interviewplanchangeLimit(newlimit){
            this.interviewPlanpagination.currentPage = 1;
            this.interviewPlanpagination.rowsPerPage = newlimit;
            this.loadInterviewPlan();
        },
        interviewId: function(id) {
            this.existingInterviewId = id;
        },
        addExistingInterview() {
            if(this.existingInterviewId){
            axios.get(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/view/rp/interview/' + this.existingInterviewId)
                .then(response => {
                    if (response.data.status) {
                        this.elements.isAdd = true;
                        this.elements.isEdit = false;
                        let interviewData = response.data.interview;
                        this.newInterview.id = interviewData.id;
                        this.newInterview.title = interviewData.interviewTitle;
                        this.newInterview.interviewType = interviewData.interviewType;
                        this.newInterview.interviewRequirement = interviewData.interviewRequirement;
                        this.newInterview.dueDate = new Date(interviewData.dueDate);
                        this.panelMem, this.panelMembers = [];
                        let self = this;
                        $.each(response.data.rpInterviewers, function(key, value) {
                            self.panelMem.push(value.interviewerId);
                        });
                        this.panelMembers = this.loadInterviewersById(this.panelMem);
                        this.skill_value = [];
                        $.each(response.data.rpCandSkillsEvalSettings, function(key, value) {
                            if (value.evalType == "scoreCard") {
                                value.technologySkillSet.techName = value.technologySet.technology;
                                self.skill_value.push(value.technologySkillSet);
                            } else {
                                self.questions.push({
                                    evalType: "questions",
                                    skillId: null,
                                    technologyId: null,
                                    interviewQuestions: value.interviewQuestions
                                });
                            }
                        });
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    let errmsg = (error.response && error.response.data.message != undefined) ? error.response.data.message : this.$config.UpdatingErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
            }
        },
        loadQuestions: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/interviewresource/list/interview/questions?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.params)
                .then(response => {
                    let result = response.data;
                    if (!result.status) {
                        iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                    }
                    this.interviewQuestions = result.interviewQuestions;
                    this.pagination.totalRecords = result.pagination[0].total;
                    this.pagination.currentPage = result.pagination[0].currentPage;
                })
                .catch(error => {
                    iziToast.error({ title: 'Error', message: this.$config.QuesLoadErr, position: 'topRight' });
                });
        },
        setQuestionId: function(id) {
            this.existingQuestionId = id;
        },
        addExistingQuestion: function(questionId) {
            axios.get(this.$store.getters.getAPIBasePath + '/interviewresource/view/interview/question/' + questionId)
                .then(response => {
                    if (response.data.status) {
                        this.elements.isAdd = true;
                        this.elements.isEdit = false;
                        let data = response.data.question;
                        this.questions.push({
                            evalType: "questions",
                            skillId: null,
                            technologyId: null,
                            interviewQuestions: data.question,
                            answer: data.answer,
                            interviewQuestionId: questionId
                        });

                    }
                    this.existingQuestionId = "";
                })
                .catch(error => {
                    this.unAuthorized(error);
                    let errmsg = (error.response && error.response.data.message != undefined) ? error.response.data.message : this.$config.UpdatingErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
        },
        clearQuestionCheck: function() {
            this.selectedValueCheck = "";
        },
        getAnsSubstring(answer) {
            if (answer != null && answer.length > 100) {
                return answer.substring(0, 100) + "...";
            } else
                return answer;
        }
    }
}

</script>
