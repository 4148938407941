<template>
    <div  class="job reports text-left mt-60">
        <div v-if="false" class="container-fluid" id="quick-search">
            <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-6">
                        <h4 class="page-title">Search</h4>
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <ul class="ul-horizontal box-tools text-right mb-0">
                            <li><a @click="searchMore=!searchMore" href="javascript:;" data-target="main-search" class="boxhide"><span :class="searchMore?'ti-angle-up':'ti-angle-down'"></span></a></li>
                        </ul>
                    </div>
                </div>
                <form id="main-search" v-if="searchMore">
                    <div class="row form-group ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Name</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <input type="text" v-model="search.params.name" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr pl-0">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label style="visibility: hidden;">Status</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <button type="button" @click="getContactList" class="btn btn-primary btn-sm float-left" style="margin-left: 10px;">Find</button>
                                    <button type="button" @click="resetClientsSearch" class="btn btn-primary btn-sm float-left" style="margin-left: 10px;">Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="load-indicator"></div>
            </div>
        </div>
        <div v-if="!showEmailTools" class="container-fluid" id="main-data">
            <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                <div class="row" style="margin-bottom: 15px;">
                    <div class="col-md-6 col-sm-12 col-12">
                        <h4 class="page-title pull-left">Contacts
                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{browsepagination.totalrecords}} - Total)</span>
                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                <div class="dropdown">
                                    <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                    <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                        <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                    </div>
                                </div>
                            </span>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-borderless all-table">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Message</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody id="table-length">
                                    <tr v-if="contactList.length==0">
                                        <td colspan="6" align="center">No Contacts found</td>
                                    </tr>
                                    <tr v-for="(contactInfo, index) in contactList">
                                        <td>
                                            {{contactInfo.firstName}}
                                        </td>
                                        <td><i v-if="contactInfo.email != null" class="fa fa-envelope pl-2  hover-hand" :title="contactInfo.email" @click="emailPrepare(contactInfo)" aria-hidden="true"></i><span class="p-2 hover-hand">{{contactInfo.email}}</span></td>
                                        <td>
                                            <i v-if="contactInfo.phone != null" class="fa fa-mobile pl-2" :title="contactInfo.phone" aria-hidden="true"></i><span class="p-2">{{contactInfo.phone}}</span></td>
                                        <td>{{ contactInfo.message }}</td>
                                        <td>{{ contactInfo.webContactStatus }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <b-pagination v-if="contactList.length!=0" size="md" class='pull-right' :total-rows="browsepagination.totalrecords" :current-page="browsepagination.currentpage" v-model="browsepagination.currentpage" :per-page="browsepagination.rowsPerPage" @input="getContactList()"></b-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row no-gutters">
                <EmailTools v-if="showEmailTools" :mailTo="{email: contactInfo.email, id: contactInfo.id, name: contactInfo.firstName}" @closeEmailTools="showEmailTools=false" />
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import EmailTools from '@/components/recruiters/blocks/tools/EmailTools';

export default {
    components: {
        EmailTools
    },
    data() {
        return {
            contactList: [],
            // browsepagination: this.$store.state.paginationconfig,
             browsepagination: {
                totalrecords: 0,
                rowsPerPage: 10,
                currentpage: 1
            },
            searchlimit: this.$store.state.searchlimit,
            elements: {
                showadvancedsearch: false,
            },
            // search: {
            //     limit: this.$store.getters.getActiveSearchLimit,
            // },
            coldisplay: {
                accountName: true,
            },
            showEmailTools: false,
            contactInfo:{
                id: "",
                firstName: "",
                email: ""
            }
        }
    },
    mounted() {
        this.getContactList();
    },
    methods: {
        resetClientsSearch() {
            this.search.params = {
                accountName: "",
            };
            this.getContactList();
        },
        emailPrepare(contactInfo) {
            this.contactInfo.id = contactInfo.id;
            this.contactInfo.email = contactInfo.email;
            this.contactInfo.firstName = contactInfo.firstName;
            this.showEmailTools = true;
        },
        getContactList() {
            axios.post(this.$store.getters.getAPIBasePath + '/webcontactinforesource/web/contact/list?page=' + this.browsepagination.currentpage + '&size=' + this.browsepagination.rowsPerPage)
                .then(response => {
                    let result = response.data;
                    this.contactList = result.webContacts;
                    this.browsepagination.totalrecords = result.pagination[0].total;
                    this.browsepagination.currentpage = result.pagination[0].currentPage;
                    this.browsepagination.totalpages = result.pagination[0].lastPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        changeLimit(newlimit) {
            console.log(newlimit);
            // this.search.limit = newlimit;
            // this.$store.commit('updateActiveSearchLimit', this.search.limit);
            this.browsepagination.currentpage = 1;
            this.browsepagination.rowsPerPage = newlimit;
            this.getContactList();
        },


    }
}

</script>
