<template>
    <div id="main" class="tech-skill-settings settings recruiter">
        <div class="row col-md-12 px-0 py-4">
            <div class="col-md-4 offset-md-4">
                <h4 class="">Client Access Level</h4>
            </div>
            <div class="col-md-1 offset-md-3">
                <a href="Javascript:void(0);" class="pull-right btn-back" @click="$emit('setSubTab',0)">
                    <h5 class="text-blue"><span title="Go Back" class="ti-arrow-left py-4"></span><span class="pl-2">Back</span></h5>
                </a>
            </div>
        </div>
        <div class="row" v-if="editIndex<0">
            <div class="col-md-6 col-sm-8 col-6">
                <input class="form-control" placeholder="Enter Client Access Level" v-model="newclientAccessLevel.clientAccessLevel" />
                <p v-if="formErrors.length">
                    <ul>
                        <li class="error" v-for="error in formErrors">{{ error.message }}</li>
                    </ul>
                </p>
                <p v-if="validationError">
                    <ul>
                        <li class="error">Enter client access level</li>
                    </ul>
                </p>
            </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                <a href="Javascript:void(0);" @click="addClientAccessLevel">
                    <span class="ti-plus"></span>
                </a>
            </div>
        </div>
        <div class="row" v-if="editIndex!=-1">
            <div class="col-md-6 col-sm-8 col-6">
                <input class="form-control" placeholder="Enter new sourcing channel" v-model="newclientAccessLevel.clientAccessLevel" />
            </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                <a style="float:left; margin-right:5px;" href="Javascript:void(0);" @click="updateClientAccess(1)">
                    <span class="ti-check"></span>
                </a>
                <a style="float:left;" href="Javascript:void(0);" @click="updateClientAccess(0)">
                    <span class="ti-close"></span>
                </a>
            </div>
        </div>
        <div class="row item" v-for="(accessInfo, index) in clientAccessLevelList" v-bind:key="index">
            <div class="col-md-6 col-sm-8 col-6">
                <div class="form-control">{{accessInfo.clientAccessLevel}}</div>
            </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                <a href="javscript:" @click="removeAccesslevel(index,accessInfo.id)" data-toggle="modal" data-target="#removWorkAuth" title="Remove" class="px-2"><span class="ti-trash"></span></a>
                <a style="float:left; margin-right:5px;" href="Javascript:void(0);" @click="editClientAccesslevel(index)">
                    <span class="ti-pencil"></span>
                </a>
            </div>
        </div>
         <Dialog id="removWorkAuth" :onYes="deleteWorkAuth" :returnParams="delAccessParam" />
    </div>
</template>
<style scoped>
div.item {
    margin-top: 5px;
}

</style>
<script>
import multiItemInput from "@/components/plugins/multiItemInput";
import iziToast from "izitoast";

export default {
    components: {
        multiItemInput
    },
    data() {
        return {
            formErrors: [],
            validationError:false,
            editIndex: -1,
            clientAccessLevelList: [],
            accessInfo: {
                clientAccessLevel: ""
            },
            newclientAccessLevelList: [],
            newclientAccessLevel: { id: 0, clientAccessLevel: "" },
            browsepagination: this.$store.state.paginationconfig,
            searchlimit: this.$store.state.searchlimit,
            delAccessParam: {
                index: -1,
                id: 0
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    clientAccessLevel: ""
                }
            }
        };
    },
    mounted() {
        this.loadClientAccesslist();
    },
    methods: {
        loadClientAccesslist() {
            axios
                .post(
                this.$store.getters.getAPIBasePath +"/clientaccesslevel/list")
                .then(response => {
                    let result = response.data;                 
                    this.clientAccessLevelList = result.recordinfo;
                    this.newclientAccessLevel = { id: 0, clientAccessLevel: "" };
                })
                .catch(error => {
                    iziToast.error({
                        title: "Error",
                        message: error,
                        position: "topRight"
                    });
                });
        },
        addClientAccessLevel() {
            if(this.newclientAccessLevel.clientAccessLevel){
            axios
                .post(
                    this.$store.getters.getAPIBasePath +"/clientaccesslevel/add", this.newclientAccessLevel)
                .then(response => {

                    this.accessInfo.clientAccessLevel = "";
                    this.newclientAccessLevel = "";
                    this.formErrors = [];
                    if(response.data.status == true) {
                        iziToast.success({
                            title: "Success",
                            message: response.data.message,
                            position: "topRight",
                            timeout: 1000
                        });
                    } else {
                        iziToast.info({
                            title: "Info",
                            message: response.data.message,
                            position: "topRight"
                        });
                    }
                     this.loadClientAccesslist();
                     this.validationError = false;
                })
                .catch(error => {
                    this.formErrors = error.response.data.validationErrorList;
                });
            }
            else{
                this.validationError = true;
            }
        },
        removeAccesslevel:function(arg1, arg2)
            {
                this.delAccessParam.index=arg1;
                this.delAccessParam.id=arg2;
            },
            deleteWorkAuth:function(arg)
            {                 
            axios.post(this.$store.getters.getAPIBasePath + '/clientaccesslevel/delete/' + this.delAccessParam.id)
                .then(response => {
                    let result = response.data;
                   if (result.status == "OK") {
                        iziToast.success({
                            title: "Success",
                            message: result.message,
                            position: "topRight",
                            timeout: 1000
                        });
                         this.clientAccessLevelList.splice(arg.index,1);   
                    }else{
                        iziToast.error({
                            title: "Error",
                            message: this.$config.CliAccessDelErr,
                            position: "topRight"
                        });
                    }
                })
                .catch(function(error) {
                    iziToast.error({ title: 'Error', message: this.$config.ErrMsg, position: 'topRight' });
                    console.log(error);
                });

        },
        editClientAccesslevel(index) {            
            this.editIndex = index;
            this.newclientAccessLevel = {
                id: this.clientAccessLevelList[index].id,
                clientAccessLevel: this.clientAccessLevelList[index].clientAccessLevel
            };
        },
        updateClientAccess(mode) {            
            if (mode == 1) {
                axios
                    .post(
                        this.$store.getters.getAPIBasePath +"/clientaccesslevel/edit/"+this.newclientAccessLevel.id,this.newclientAccessLevel)
                    .then(response => {
                        this.editIndex = -1;
                        this.newclientAccessLevel = { id: 0, clientAccessLevel: "" };
                        if(response.data.status == "ACCEPTED") {
                            console.log("DSfsdf",response)
                            iziToast.success({
                                title: "Success",
                                message: response.data.message,
                                position: "topRight",
                                timeout: 1000
                            });                            
                        } else {
                            iziToast.info({
                            title: "Info",
                            message: response.data.message,
                            position: "topRight"
                            });
                        }
                        this.loadClientAccesslist();
                    })
                    .catch(error => {
                        iziToast.error({
                            title: "Error",
                            message: error,
                            position: "topRight"
                        });
                    });
            } else {
                this.editIndex = -1;
                this.newclientAccessLevel = { id: 0, clientAccessLevel: "" };
            }
        }
    }
};

</script>
