<template>
    <div id="main" class="mt-4 progress-interview">
        <div class="container-fluid" id="quick-search">
            <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded pos-rel">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-6">
                        <h4 class="page-title">Search</h4>
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <ul class="ul-horizontal box-tools text-right mb-0">
                            <li><a @click="serarch_more_toggle" href="javascript:" data-target="main-search" class="boxhide"><span :class="{'ti-angle-up': serarch_more, 'ti-angle-down': !serarch_more }"></span></a></li>
                        </ul>
                    </div>
                </div>
                <form v-if="serarch_more" id="main-search">
                    <div class="row form-group ten-columns">
                        <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Interview Title</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" v-on:keyup.enter="filterInterviews" v-model="search.params.title" maxlength="90" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label> Candidate Name</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" v-on:keyup.enter="filterInterviews" v-model="search.params.candidateName" maxlength="90" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label> Job Code</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" v-on:keyup.enter="filterInterviews" v-model="search.params.jobCode" maxlength="90" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Interviewer</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                    <input type="text" v-model="search.params.interviewerName" maxlength="11" class="smallCombo form-control enablefield">
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Interview Date</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <datepicker v-model="search.params.interviewDate" placeholder="Select" input-class="form-control enablefield" maxlength="10" wrapper-class="input-group date" format="MM/dd/yyyy"></datepicker>                                    
                                    </div>
                                </div>
                            </div>  
                        </div> -->
                        <!--  <div class="col-md-3 col-sm-6 col-3 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Status</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <select v-model="search.params.status" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(stat, index) in recruiterStatus" :value="stat.id" v-bind:key="index">{{stat.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                        </div> -->
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr pl-0">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label style="visibility: hidden;">End Date</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12 float-right">
                                    <button type="button" @click="filterInterviews" class="btn btn-primary float-left" style="margin-left: 10px;">Find</button>
                                    <button type="button" @click="resetSearch" class="btn btn-primary float-left" style="margin-left: 10px;">Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="container-fluid" id="main-data">
            <div v-if="!elements.feedback.showFeedback" class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                <!-- <div class="row" style="margin-bottom: 15px;">
                    <div class="col-md-6 col-sm-12 col-12">
                        <h4 class="page-title">Pending Interviews</h4>
                    </div>
                </div> -->
                <div class="row" style="margin-bottom: 15px;">
                    <div class="col-md-12 col-sm-12 col-12">
                        <h4 class="page-title">Pending Interviews 
                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalrecords}} - Total)</span>
                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                <div class="dropdown">
                                    <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                    <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                        <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                    </div>
                                </div>
                            </span>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-borderless all-table">
                                <thead>
                                    <tr>
                                        <th>Interview Title</th>
                                        <th>Interview Date</th>
                                        <th>Candidate Name</th>
                                        <th>Cand. Accepted</th>
                                        <!--  <th>Rejected</th> -->
                                        <th>Interviewers</th>
                                        <th>Accepted Interviewers</th>
                                        <!--  <th>Rejected</th> -->
                                        <th>Job Code</th>
                                        <!--  <th>Start Date</th>
                                        <th>End Date</th>
                                        <th v-if="false">Status</th> -->
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="interviewList != null && interviewList.length==0">
                                        <td style="border:0px;" colspan="12" align="center">No interviews found
                                        </td>
                                    </tr>
                                    <template v-for="(interview,index) in interviewList">
                                        <tr>
                                            <td>{{ interview.title }}</td>
                                            <td>{{ interview.interviewDate | formatDate}}</td>
                                            <td>{{ interview.candidateName }}</td>
                                            <td><span title='accepted' class="text-green fa fa-check" v-if="interview.candidateAccepted==1"></span>
                                                <span title='pending' class="text-blue fa fa-minus" v-if="interview.candidateAccepted==0"></span>
                                                <span title='rejected' class="text-red fa fa-times" v-if="interview.candidateAccepted==2"></span>
                                            </td>
                                            <td>{{ interview.interviewerName }}</td>
                                            <td v-html="processAcceptedStatus(interview.acceptedStatus)"></td>
                                            <td>
                                                <a href="Javascript:void(0);" class="" @click="redirectUrl(interview.jobId)">{{interview.jobCode}}</a>
                                            </td>
                                            <td>
                                                <a v-if="interview.interviewStatus == 'Assigned'" href="javascript:" title="Interview Rescheduling" @click="rescheduleInterview(interview.id,interview.candidateId,interview.recruitmentId)"><i class="fa fa-calendar fa_custom fa-lg px-1 mr-1"></i></a>
                                                <a v-if="interview.interviewStatus == 'Assigned'" href="javascript:" @click="cancelInterview(interview.id,interview.candidateId)" v-b-modal.interviewCancellation title="Interview Cancellation"><i class="fa fa-calendar-times-o fa_custom fa-lg px-1"></i></a>
                                                <a v-if="true" href="javascript:" @click="viewInterview(interview.id,interview.candidateId)" title="View Interview Schedule" class="p-2"><i class="ti-eye"></i></a>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <b-pagination v-if="interviewList != null && interviewList.length!=0" size="md" class='pull-right' :current-page="pagination.currentpage" :total-rows="pagination.totalrecords" v-model="pagination.currentpage" :per-page="pagination.rowsPerPage" @input="loadInterviewsSchedules()"></b-pagination>
                    </div>
                </div>
            </div>
            <Feedback v-if="elements.feedback.showFeedback" :recId="elements.feedback.recruitmentId" @closeFeedback="closeFeedback" />
        </div>
        <div class="modal fade" id="viewFeedback" tabindex="-1" role="dialog" aria-labelledby="examplemodalLabel" aria-hidden="true">
            <div class="modal-dialog mw-100 w-75" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="examplemodalLabel">Feedback</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="card m-b-3">
                            <!-- Add Contract -->
                            <div v-for="(feedBack,index2) in feedBacks.categories" v-bind:key="index2" class="collapse-group">
                                <div class="card-header">
                                    <h5 class="modal-title">{{feedBack.title}}</h5>
                                </div>
                                <div class="card-body p-2">
                                    <div class="basic-block">
                                        <div v-for="(v_details,index3) in feedBack.list" v-bind:key="index3" class="row m-0 pt-1 pb-1 border-1-bottom">
                                            <div class="col-sm-3 pos-relative">
                                                <span class="pt-8">{{v_details.title}}</span>
                                            </div>
                                            <div class="col-sm-3 pos-relative">
                                                <fieldset class="rating pt-8">
                                                    <input disabled :id="'A'+index2+'-B'+index3+'-rate1-star5'" type="radio" v-model="v_details.rating" value="5" />
                                                    <label :for="'A'+index2+'-B'+index3+'-rate1-star5'" title="Excellent">5</label>
                                                    <input disabled :id="'A'+index2+'-B'+index3+'-rate1-star4'" type="radio" v-model="v_details.rating" value="4" />
                                                    <label :for="'A'+index2+'-B'+index3+'-rate1-star4'" title="Good">4</label>
                                                    <input disabled :id="'A'+index2+'-B'+index3+'-rate1-star3'" type="radio" v-model="v_details.rating" value="3" />
                                                    <label :for="'A'+index2+'-B'+index3+'-rate1-star3'" title="Satisfactory">3</label>
                                                    <input disabled :id="'A'+index2+'-B'+index3+'-rate1-star2'" type="radio" v-model="v_details.rating" value="2" />
                                                    <label :for="'A'+index2+'-B'+index3+'-rate1-star2'" title="Bad">2</label>
                                                    <input disabled :id="'A'+index2+'-B'+index3+'-rate1-star1'" type="radio" v-model="v_details.rating" value="1" />
                                                    <label :for="'A'+index2+'-B'+index3+'-rate1-star1'" title="Very bad">1</label>
                                                </fieldset>
                                            </div>
                                            <div class="col-sm-2 pos-relative">
                                                <label class="pt-8"><input type="checkbox" v-model="v_details.isPrimary" :checked="v_details.isPrimary"> Is Primary</label>
                                            </div>
                                            <div class="col-sm-4">
                                                <textarea class="form-control" placeholder="Your Comments" v-model="v_details.status"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse-group">
                                <div class="card-header">
                                    <h5 class="modal-title">Overall Comments</h5>
                                </div>
                                <div class="card-body p-2">
                                    <div class="basic-block">
                                        <div class="row m-0 pt-1 pb-1">
                                            <div class="col-sm-12">
                                                <textarea class="form-control" placeholder="Your Comments" v-model="feedBacks.overallComments"></textarea>
                                            </div>
                                        </div>
                                        <div class="row m-0 pt-1 pb-1 mb-0">
                                            <div class="offset-sm-8 col-sm-4 text-right">
                                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="interviewCancellation" :no-close-on-backdrop="true" ref="cancellationModal" title="Cancellation Note" @show="" @hidden="" @ok="saveCancellation">
            <form ref="form">
                <b-form-group label-for="cancellation-notes">
                    <textarea v-model="recruitmentData.cancelNote" rows="3" class="form-control"></textarea>
                    <div v-if="elements.submitted && !$v.recruitmentData.cancelNote.required" class="errorcolor">Cancellation Note is required</div>
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Feedback from '../../../../layouts/blocks/Feedback';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    components: {
        Feedback
    },
    data() {
        return {
            elements: {
                feedback: {
                    recruitmentId: null,
                    showFeedback: false
                }
            },
            currentIndex: null,
            interviewList: {},
            candidateInterviewList: [],
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            //pagination: this.$store.state.paginationconfig,
            searchlimit: this.$store.state.searchlimit,
            selectedInterviewId: null,
            serarch_more: true,
            page_conf: {
                currentPage: 1,
                total: 0,
            },
            search: {
                limit: 10,
                params: {
                    candidateName: '',
                    interviewerName: '',
                    interviewDate: '',
                    status: '',
                    jobCode: '',
                    title: ''
                }
            },
            page_conf: {
                total: 3,
                currentPage: 1,
            },
            feedBacks: {},
            status: [],
            recruiterStatus: [],
            recruitmentData: {
                candidateId: null,
                interviewId: null,
                cancelNote: '',
                id: null
            }
        }
    },
    validations: {
        recruitmentData: {
            cancelNote: { required },
        }
    },
    mounted: function() {
        this.loadInterviewsSchedules();
        this.getStatus();
        this.getRecruiterStatus();
    },
    methods: {
        redirectUrl(jobId) {
            this.$router.push('/job/plan-recruitment/' + jobId);
        },
        rescheduleInterview(interviewId, candId, recId) {
            localStorage.setItem('interviewId', interviewId);
            localStorage.setItem('recruitmentId', recId);
            this.$router.push({ name: 'scheduleinterview', params: { candidateid: candId } });
        },
        cancelInterview: function(interviewId, candidateId) {
            this.recruitmentData.candidateId = candidateId;
            this.recruitmentData.interviewId = interviewId;
        },
        viewInterview(interviewId, candId) {
            localStorage.setItem('interviewId', interviewId);
            localStorage.setItem('interviewMode', 'view');
            this.$router.push({ name: 'scheduleinterview', params: { candidateid: candId } });
        },
        saveCancellation: function(bvModalEvt) {
            this.elements.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                bvModalEvt.preventDefault();
                return;
            }
            axios.post(this.$store.getters.getAPIBasePath + '/interviewresource/cancel/interview', this.recruitmentData)
                .then(response => {
                    if (!response.data.status) {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    } else {
                        this.recruitmentData.cancelNote = "";
                        this.elements.submitted = false;
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    iziToast.error({ title: 'Error', message: this.$config.IntCanclErr, position: 'topRight' });
                });
        },
        resetSearch() {
            this.search.params.candidateName = '';
            this.search.params.interviewerName = '';
            this.search.params.interviewDate = '';
            this.search.params.status = '';
            this.search.params.jobCode = '';
            this.search.params.title = '';
            this.loadInterviewsSchedules();
        },
        getStatus() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/interviewStatusList')
                .then(response => {
                    let result = response.data;
                    if (!response.status) {
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    this.status = result.interviewStatus;
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.LoadStatusErr,
                        position: 'topRight'
                    });
                });
        },
        getRecruiterStatus() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/recruitmentStatusList')
                .then(response => {
                    let result = response.data;
                    if (!response.status) {
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    this.recruiterStatus = result.recruitmentStatus;
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.LoadStatusErr,
                        position: 'topRight'
                    });
                });
        },
        loadInterviewsSchedules() {
            if (this.search.params.interviewDate != null)
                this.search.params.interviewDate = this.formatDateString(this.search.params.interviewDate);
            axios.post(this.$store.getters.getAPIBasePath + '/interviewresource/interview/recruitment?page=' + this.pagination.currentpage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    if (!response.status) {
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    this.interviewList = result.interviewRecruitment;
                    this.pagination.totalrecords = result.pagination[0].total;
                    this.pagination.currentpage = result.pagination[0].currentPage;
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.LoadPendIntErr,
                        position: 'topRight'
                    });
                });
        },
        changeStatus: function(interviewId, statusId) {
            let statusData = { status: statusId };
            axios.post(this.$store.getters.getAPIBasePath + '/interviewresource/update/interview/status/' + interviewId, statusData)
                .then(response => {
                    let result = response.data;
                    if (!response.status) {
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    this.currentIndex = null;
                    this.getInterviews(this.selectedInterviewId);
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.UpStatusErr,
                        position: 'topRight'
                    });
                });
        },
        changeRecruiterStatus: function(interviewId, statusId) {
            let statusData = { recruitmentStatus: statusId };
            axios.post(this.$store.getters.getAPIBasePath + '/interviewresource/update/recruitment/status/' + interviewId, statusData)
                .then(response => {
                    let result = response.data;
                    if (!response.status) {
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    this.loadInterviewsSchedules();
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.UpStatusErr,
                        position: 'topRight'
                    });
                });
        },
        getInterviews(id) {
            if (this.currentIndex == id) {
                this.currentIndex = null;
            } else {
                this.currentIndex = id;
                this.selectedInterviewId = id;
            }

            axios.get(this.$store.getters.getAPIBasePath + '/interviewerresource/candidate/interviews/' + id)
                .then(response => {
                    let result = response.data;
                    this.candidateInterviewList = result.interviews;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        filterInterviews() {
            this.pagination.currentpage = 1;
            this.loadInterviewsSchedules();
        },
        changeLimit(newlimit) {
            this.pagination.currentpage = 1;
            this.pagination.rowsPerPage = newlimit;
            this.loadInterviewsSchedules();
        },
        serarch_more_toggle: function() {
            this.serarch_more = !this.serarch_more;
        },
        additional_interview: function(candidateId, interviewId) {
            localStorage.setItem('recruitmentId', interviewId);
            localStorage.setItem('recPlanId', null);
            localStorage.setItem('jobId', null);
            this.$router.push({ name: 'scheduleinterview', params: { candidateid: candidateId, recruitmentId: interviewId } })
        },
        viewFeedback(candidateId, recruitmentId) {
            this.elements.feedback.recruitmentId = recruitmentId;
            this.elements.feedback.showFeedback = true;
        },
        closeFeedback() {
            this.elements.feedback.showFeedback = false;
            this.elements.feedback.recruitmentId = null;
        },
        processAcceptedStatus(value) {  
            if(value!= null){
                value = value.replace(new RegExp("-", 'g'), "");
                value = value.replace(new RegExp(",", 'g'), "<br\>");
                value = value.replace(new RegExp("Accepted", 'g'), " <i title='accepted' class='text-green fa fa-check' ></i>");
                value = value.replace(new RegExp("Rejected", 'g'), " <i title='rejected' class='text-red fa fa-times' ></i>");
                value = value.replace(new RegExp("Pending", 'g'), " <i title='pending' class='text-blue fa fa-minus' ></i>");
            }
            return value;
        }
    }
}

</script>
