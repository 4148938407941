<template>
    <div id="main" class="mt-70">
        <div class="container-fluid recruiter-dashboard interviewer dashboard">
            <div class="row">
                <main role="main" class="col-xl-12 col-md-12 ml-sm-auto col-lg-12 my-3 pt-2">
                    <div class="row">
                        <div class="card-list">
                            <div class="row" style="padding-left:15px; padding-right:15px">
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: 'interviewerviewprofile' }" class="">
                                        <div class="card green">
                                            <div class="value">Profile</div>
                                            <div class="value"><img class="" src="@/assets/icons/candidate-white.png" alt=""></div>
                                            <div class="stat">Manage profile</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: 'interviewerrequests' }" class="">
                                        <div class="card blue">
                                            <div class="value">Interviews</div>
                                            <div class="value"><img class="" src="@/assets/icons/value-white.png" alt=""></div>
                                            <div class="stat">Manage interviews</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: 'interviewerinterviews' }" class="">
                                        <div class="card orange">
                                            <div class="value">Calendar</div>
                                            <div class="value"><img class="" src="@/assets/icons/calendar.png" alt=""></div>
                                            <div class="stat">Manage calendar</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: '' }" class="default">
                                        <div class="card grey">
                                            <div class="value">Settings</div>
                                            <div class="value"> <img class="" src="@/assets/icons/settings-gears.png" alt=""></div>
                                            <div class="stat">Manage Setting</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <a href="Javascript:void(0);" class="default">
                                        <div class="card darkgreen">
                                            <div class="value">Reports</div>
                                            <div class="value"><img class="" src="@/assets/icons/statistics-white.png" alt=""></div>
                                            <div class="stat">Manage Report</div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <a href="Javascript:void(0);" class="default">
                                        <div class="card sanmarino">
                                            <div class="value">Tool Demo</div>
                                            <div class="value"><img class="" src="@/assets/icons/tool-demo-white.png" alt=""></div>
                                            <div class="stat">Tool Demo</div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3 pr-0 ">
                            <h5 style="background-color: #37a0e4;color: #fff;padding: 5px;" class="">Inbox</h5>
                            <div class="box-wrapper p-1 mb-5 rounded">
                                <b-tabs card class="tab-list interviewers-inbox inbox-tab-list driver-tab-list tab-bg">
                                    <b-tab v-if="false" title="Interview Requests">
                                        <div class="card-media" v-if="requestData.length==0">
                                            <div class="col-md-12 pt-4 pb-4" style="text-align : center;background-color: #ffff">No records found
                                            </div>
                                        </div>
                                        <div class="card-media" v-for="(request, index) in requestData">
                                            <div class="card-media-body">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="card-media-body-top u-float-right  w-100 text-right hrline" style="margin-top:0px;">
                                                            <span class="card-media-body-heading job-link float-left p-0">{{ request.title }}</span>
                                                            <span class="card-media-body-heading job-link pull-right pt-0 pb-0">{{request.createdAt | formatDate}}</span>
                                                        </div>
                                                        <span class="card-media-body-heading job-link p-0">Type: {{ request.interviewType }}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <span class="card-media-body-heading job-link p-0">Candidate: {{request.canName}}<span v-if="checkEmpty(request.candidateCode)">[{{request.candidateCode}}]</span></span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <span class="card-media-body-heading job-link p-0">Interview Date: {{toClientTimeZoneDate(request.interviewStartTime, dateformat) }}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <span v-if="checkEmpty(request.interviewStartTime)" title="interviewStartTime" class="badge badgebgdashboard pull-left" style="width: 60px;">{{toClientTimeZoneDate(request.interviewStartTime, timeformat) }}</span>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <span v-if="checkEmpty(request.interviewEndTime)" title="interviewEndTime" class="badge badgebgdashboard pull-right" style="width: 60px;">{{toClientTimeZoneDate(request.interviewEndTime, timeformat) }}</span>
                                                    </div>
                                                </div>
                                                <div class="card-media-body-top">
                                                    <div class="card-media-body-top u-float-right  w-100 text-right hrline" style="margin-top:8px;">
                                                        <span class="job-link float-left pt-0 pb-0">
                                                            <a title="Reject" href="javascript:void(0);" @click="setInterviewId(request.id)" data-toggle="modal" data-target="#RejectRequest"><i class="ti-close" style="color: red;"></i><span style="color: red; padding-right:10px; font-family: sans-serif;"> Reject</span></a>
                                                        </span>
                                                        <span class="job-link float-right pt-0 pb-0">
                                                            <a title="Accept" href="javascript:void(0);" @click="setInterviewId(request.id)" data-toggle="modal" data-target="#AcceptRequest"><i class="ti-check" style="color: green;"></i><span class="pr-10" style="color: green; font-family: sans-serif;">Accept</span></a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Interviews"  class="scroll-down-inbox">
                                        <div class="card-media" v-if="interviewData.length==0">
                                            <div class="col-md-12 pt-4 pb-4" style="text-align : center;background-color: #ffff">No records found
                                            </div>
                                        </div>
                                        <div class="card-media" v-for="(interview, index) in interviewData">
                                            <div class="card-media-body">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="card-media-body-top u-float-right  w-100 text-right hrline" style="margin-top:0px;">
                                                            <a class="pull-left" href="javascript:void(0);" @click="viewFeedback(interview.id,interview.candidateId)">{{ interview.title }}</a>
                                                            <!-- <span class="card-media-body-heading job-link float-left p-0" @click="viewFeedback(interview.id,interview.candidateId)">{{ interview.title }}</span> -->
                                                            <span class="card-media-body-heading job-link pull-right pt-0 pb-0">{{interview.createdAt | formatDate}}</span>
                                                        </div>
                                                        <span class="card-media-body-heading job-link p-0">Type: {{ interview.interviewType }}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <span class="card-media-body-heading job-link p-0">Candidate: {{interview.canName}}<span v-if="checkEmpty(interview.candidateCode)">[{{interview.candidateCode}}]</span></span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <span class="card-media-body-heading job-link p-0">Interview Date: {{toClientTimeZoneDate(interview.interviewStartTime, dateformat) }}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <span v-if="checkEmpty(interview.interviewStartTime)" title="interviewStartTime" class="badge badgebgdashboard pull-left" style="width: 60px;">{{toClientTimeZoneDate(interview.interviewStartTime, timeformat) }}</span>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <span v-if="checkEmpty(interview.interviewEndTime)" title="interviewEndTime" class="badge badgebgdashboard pull-right" style="width: 60px;">{{toClientTimeZoneDate(interview.interviewEndTime, timeformat) }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                        <div class="col-sm-9 p-0 pl-3">
                            <div class="row">
                                <div class="col-md-12 col-xl-4">
                                    <div class="card">
                                        <div class="p-0 card-body">
                                            <div class="p-3 media">
                                                <div class="media-body"><span class="text-muted text-uppercase font-size-12 font-weight-bold">Open Interviews</span>
                                                    <h2 class="text-black mb-0">{{interviews.open}}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xl-4">
                                    <div class="card">
                                        <div class="p-0 card-body">
                                            <div class="p-3 media">
                                                <div class="media-body"><span class="text-muted text-uppercase font-size-12 font-weight-bold">Interviews Conducted</span>
                                                    <h2 class="text-black mb-0">{{interviews.conducted}}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xl-4">
                                    <div class="card">
                                        <div class="p-0 card-body">
                                            <div class="p-3 media">
                                                <div class="media-body"><span class="text-muted text-uppercase font-size-12 font-weight-bold">Reward Points</span>
                                                    <h2 class="text-black mb-0">{{interviews.rewardPoints}}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="false" class="col-md-12 col-xl-3">
                                    <div class="card">
                                        <div class="p-0 card-body">
                                            <div class="p-3 media">
                                                <div class="media-body"><span class="text-muted text-uppercase font-size-12 font-weight-bold">Hired</span>
                                                    <h2 class="text-black mb-0"></h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 p-4">
                                    <h5>Performance Chart</h5>
                                    <div style="position:relative;">
                                        <div class="" style="position:absolute;top:20px;left:900px; z-index:2000">
                                            <select v-model="year" @change="loadGraph(year)">
                                                <option value="2019">2019</option>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!--  <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel"> -->
                                    <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
                                    <!-- </div>
 -->
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
          
        <div class="modal " id="RejectRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Interview confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to reject this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="interviewConfirmation('reject')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="AcceptRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Interview confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to accept this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="interviewConfirmation('accept')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
  

    </div>
</template>
<script>
import iziToast from 'izitoast';
import { GChart } from "vue-google-charts";

export default {

    name: 'app',
    components: {
        GChart
    },
    props: {
        // screeninginfo:Object
    },
    data() {
        return {
            joblist: [],
            requestData: [],
            interviewData: [],
            dateformat:this.$store.state.dateformat,
            timeformat:this.$store.state.timeformat,
            browsepagination: this.$store.state.paginationconfig,
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            industrylist: this.$store.getters.getIndustries,
            clientlist: this.$store.getters.getClients,
            jobStatuslist: this.$store.getters.getJobStatus,
            elements: {
                showadvancedsearch: false,
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    jobTitle: "",
                    jobType: "",
                    technologyId: "",
                    startDate: "",
                    endDate: "",
                    recruiterId: this.recruiterId,
                    clientId: "",
                    industryId: "",
                    status: ""
                },
            },
            coldisplay: {
                jobTitle: true,
                jobCode: true,
                client: true,
                industry: true,
                position: true
            },
            delJobParam: {
                index: -1,
                id: 0
            },
            interviews: {
                open: 0,
                conducted: 0,
                rewardPoints: 0,
                hired: 0
            },
            labels: ['Talents Identified', 'Shortlisted', 'Hired'],
            subLabels: ['Direct', 'Social Media', 'Ads'],
            values: [120, 57, 9],
            colors: ['#DA932C', '#66B92E', '#24A0ED', '#A9A9A9'],
            chartColors: ['#DA932C', '#66B92E', '#24A0ED'],

            direction: 'vertical',
            gradientDirection: 'vertical',
            height: 347,
            width: 300,
            chartData: [],
            /*chartData: [
                ['Month', 'Interviews','Hired'],
                ['Jan', 12,10],
                ['Feb', 15,5],
                ['Mar', 25,20],
                ['Apr', 20,11],
                ['May', 5,3],
                ['Jun', 30,20],
                ['Jul', 20,10],
                ['Aug', 10,5],
                ['Sep', 22,6],
                ['Oct', 40,10],
                ['Nov', 30,20],
                ['Dec', 15,10]
            ],*/
            chartOptions: {
                // title: 'Interview [Month-wise]',
                subtitle: 'Month, Interviews',
                colors: ['#2298f1', '#898a8e'],
                height: 350
            },
            year: 2023,
            intervieId: 0
        }
    },
    mounted() {
        this.loadRequests();
        this.loadInterviews();
        this.loadGraph(2023);
    },
    methods: {
        setInterviewId(id) {
            this.interviewId = id;
        },
        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },

        loadRequests() {
            axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/list/interviewrequest/' + this.$store.getters.currentUser.profileId + '?page=' + this.browsepagination.currentpage + '&size=' + this.search.limit, this.search.params)
                .then(response => {
                    let result = response.data;
                    if (!result.request) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.RecNotFou,
                            position: 'topRight'
                        });
                    } else {
                        this.requestData = Array.from(result.request);
                        //this.page_conf=Object.assign({}, result.pagination[0]);
                        this.browsepagination.totalrecords = result.pagination[0].total;
                        this.browsepagination.currentpage = result.pagination[0].currentPage;
                        this.browsepagination.totalpages = result.pagination[0].lastPage;
                    }
                });
        },
        loadInterviews() {
            axios.post(this.$store.getters.getAPIBasePath + '/interviewresource/list/interviewer/interviews/' + +this.$store.getters.currentUser.profileId + '?page=' + this.browsepagination.currentpage + '&size=' + this.search.limit, this.search.params)
                .then(response => {
                    let result = response.data;                    
                    if (!result.interviews) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.RecNotFou,
                            position: 'topRight'
                        });
                    } else {
                        this.interviewData = Array.from(result.interviews);
                        // this.page_conf=Object.assign({}, result.pagination[0]);
                        this.browsepagination.totalrecords = result.pagination[0].total;
                        this.browsepagination.currentpage = result.pagination[0].currentPage;
                        this.browsepagination.totalpages = result.pagination[0].lastPage;
                    }
                });
        },
        interviewConfirmation: function(type) {
            axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/update/interviewer/confirmrequest/' + this.interviewId, { 'type': type })
                .then(response => {
                    let result = response.data;
                    if (result.status) {
                        iziToast.success({
                            title: 'Confirmation Request',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadInterviews();
                        this.loadRequests();
                    } else {
                        iziToast.error({
                            title: 'Confirmation Request',
                            message: this.$config.ReqConfirmErr,
                            position: 'topRight'
                        });
                    }
                });
            return true;
        },
        loadYearlyRecruitments() {
            var currentDate = new Date();
            var month = currentDate.getMonth();
            let data = { "createdYear": "2019" };

            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/yearly/recruitment/candidate', data)
                .then(response => {

                    let result = response.data;
                    this.values = result.recruitment;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadRecruitmentsByJob(jobId) {
            var currentDate = new Date();
            var month = currentDate.getMonth();
            let data = { "jobId": jobId };

            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/recruitment/job', data)
                .then(response => {

                    let result = response.data;
                    this.values = result.recruitment;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        viewFeedback(interviewId, candidateId) {
            localStorage.setItem('feedbackInterviewId', parseInt(interviewId))
            localStorage.setItem('feedbackCandidateId', parseInt(candidateId))
            // localStorage.setItem('feedbackRpInterviewId',parseInt(rpInterviewId))
            this.$router.push('/interviewer/feedback/' + interviewId)
        },
        loadGraph(year) {
            axios.post(this.$store.getters.getAPIBasePath + '/dashboardresource/interviewer/dashboard/chart/' + year)
                .then(response => {
                    let result = response.data;
                    this.chartData = result.interviewerPerformance;
                    this.interviews.open = result.progressCount.openInterviews;
                    this.interviews.conducted = result.progressCount.conductedInterviews;
                    this.interviews.rewardPoints = result.progressCount.conductedInterviews;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
    }
}

</script>
