<template>
    <div id="main" class="mt-70">
        <div class="container-fluid full-screen" id="quick-search">
            <div class="box-wrapper shadow-sm p-4 mtb-4 bg-white rounded pos-rel full-screen-1">
                <div class="row h-50">
                    <div class="col-sm-12 h-100 d-table">
                        <div class="d-table-cell align-middle login_div1 ">
                            <div class="col-md-6 offset-md-3">
                                <div class="row form-group text-center">
                                    <div class="col-12 col-sm-4 col-md-4 col-xl-4 grid-margin stretch-card mb-4">
                                        <div class="card box-wrapper mb-4 bg-white h-100 dashboard-menu-border">
                                            <div class="mtb-2 h-100 rounded-lg dashboard-menu">
                                                <a href="">
                                                    <div class="card-body d-table h-100 w-100">
                                                        <div class="d-table-cell align-middle">
                                                            <img class="dashboard-icon" alt="images" src="@/assets/icons/myinbox.png">
                                                            <img class="dashboard-icon-hover" alt="images" src="@/assets/icons/myinbox-hover.png">
                                                            <h4 class="card-title">
                                                                <router-link :to="{ name: 'joblist' }" class="">My Inbox</router-link>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-xl-4 grid-margin stretch-card mb-4">
                                        <div class="card box-wrapper bg-white h-100 dashboard-menu-border">
                                            <div class="mtb-2 rounded-lg h-100 dashboard-menu">
                                                <a href="">
                                                    <div class="card-body d-table h-100 w-100">
                                                        <div class="d-table-cell align-middle">
                                                            <img class="dashboard-icon" alt="images" src="@/assets/icons/working-with-laptop.png">
                                                            <img class="dashboard-icon-hover" alt="images" src="@/assets/icons/working-with-laptop-hover.png">
                                                            <h4 class="card-title">
                                                                <router-link :to="{ name: 'listjobs' }" class="">Job Profile</router-link>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-xl-4 grid-margin stretch-card mb-4">
                                        <div class="card box-wrapper bg-white h-100 dashboard-menu-border">
                                            <div class="mtb-2 h-100 rounded-lg dashboard-menu">
                                                <a href="">
                                                    <div class="card-body d-table h-100 w-100">
                                                        <div class="d-table-cell align-middle">
                                                            <img class="dashboard-icon" alt="images" src="@/assets/icons/user-silhouette.png">
                                                            <img class="dashboard-icon-hover" alt="images" src="@/assets/icons/user-silhouette-hover.png">
                                                            <h4 class="card-title">
                                                                <router-link :to="{ name: 'listcandidates' }" class=""> Manage Candidate</router-link>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-xl-4 offset-xl-2 offset-md-2 offset-sm-2 grid-margin stretch-card mb-4">
                                        <div class="card box-wrapper bg-white h-100 dashboard-menu-border">
                                            <div class="mtb-2 h-100 rounded-lg dashboard-menu">
                                                <a href="">
                                                    <div class="card-body d-table h-100 w-100">
                                                        <div class="d-table-cell align-middle">
                                                            <img class="dashboard-icon" alt="images" src="@/assets/icons/dashboard.png">
                                                            <img class="dashboard-icon-hover" alt="images" src="@/assets/icons/dashboard-hover.png">
                                                            <h4 class="card-title">
                                                                <router-link :to="{ name: 'candidatesdashboard' }" class="">Dashboard</router-link>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-xl-4 grid-margin stretch-card mb-4">
                                        <div class="card box-wrapper bg-white h-100 dashboard-menu-border">
                                            <div class="mtb-2 h-100 rounded-lg dashboard-menu dashboard-menu-border">
                                                <a href="">
                                                    <div class="card-body d-table h-100 w-100">
                                                        <div class="d-table-cell align-middle">
                                                            <img class="dashboard-icon-hover" alt="images" src="@/assets/icons/web-design-hover.png">
                                                            <img class="dashboard-icon" alt="images" src="@/assets/icons/web-design.png">
                                                            <h4 class="card-title">Tool Demo</h4>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        // screeninginfo:Object
    },
    data() {
        return {

        }
    },
    methods: {

    }
}

</script>
