<template>
	<div id="main" class="template-settings settings recruiter mt-70">
		<div class="row col-md-12 box-wrapper shadow-sm my-4 mx-0 px-0 rounded pos-rel">
			<div class="col-md-3 settings-leftbar px-0 bg-white">
				<div class="p-4 row header mx-0">
					<div class="col-md-9 px-0">
						<h5>Tools & Settings</h5>
					</div>
					<div class="col-md-3 px-0 text-right">
						<router-link :to="{name: 'referraldashboard'}">
							<h5 class="text-blue"><span class="ti-arrow-left py-4" title="Go Back"></span><span class="pl-2">Back</span></h5>
						</router-link>
					</div>
				</div>
				<div @click="settingsTab" class="row col-md-12 py-4 mx-0 left-bar-tab" :class="elements.activeTab==1?'left-tab-active':''">
					<div class="col-md-3">
						<img src="@/assets/icons/settings-border.png" width="90%" height="90%">
					</div>
					<div class="col-md-8">Settings</div>
				</div>
				<div @click="toolsTab" class="row col-md-12 py-4 mx-0 left-bar-tab" :class="elements.activeTab==2?'left-tab-active':''">
					<div class="col-md-3">
						<img src="@/assets/icons/tools.png" width="85%" height="85%">
					</div>
					<div class="col-md-8">Tools</div>
				</div>
			</div>
			<div class="col-md-9">
				<div class="basic-block p-4 bg-white">
					<Settings v-if="elements.activeTab==1 && elements.subActiveTab==0" @setSubTab="setSubTab" />
						<AdditionalSettings v-if="elements.activeTab==1 && elements.subActiveTab==1" @setSubTab="setSubTab" />
						<EmailAccountSettings v-if="elements.activeTab==1 && elements.subActiveTab==2 && elements.action=='list'" @setSubTab="setSubTab" @setAction="setAction" />
							<AddEmailAccount v-if="elements.activeTab==1 && elements.subActiveTab==2 && elements.action=='add'" @closeAction="elements.action='list'" />
							<ViewEmailAccount v-if="elements.activeTab==1 && elements.subActiveTab==2 && elements.action=='view'" @setAction="setAction" @closeAction="elements.action='list'" :settingsEmailAccountId="elements.actionId" />
							<EditEmailAccount v-if="elements.activeTab==1 && elements.subActiveTab==2 && elements.action=='edit'" @setAction="setAction" @closeAction="elements.action='list'" :settingsEmailAccountId="elements.actionId" />
					<Tools v-if="elements.activeTab==2 && elements.subActiveTab==0" @setSubTab="setSubTab" />
						<EmailTools v-if="elements.activeTab==2 && elements.subActiveTab==1" @setSubTab="setSubTab" />
						<BulkUpload v-if="elements.activeTab==2 && elements.subActiveTab==2" @setSubTab="setSubTab" class="mt-0" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Settings from './blocks/Settings';
	import AdditionalSettings from '@/components/recruiters/blocks/settings/AdditionalSettings';
	import EmailAccountSettings from '@/components/recruiters/blocks/settings/EmailAccountSettings';
		import AddEmailAccount from '@/components/recruiters/blocks/settings/emailaccountsettings/AddEmailAccount';
		import ViewEmailAccount from '@/components/recruiters/blocks/settings/emailaccountsettings/ViewEmailAccount';
		import EditEmailAccount from '@/components/recruiters/blocks/settings/emailaccountsettings/EditEmailAccount';
import Tools from '@/components/recruiters/blocks/Tools';
	import EmailTools from '@/components/recruiters/blocks/tools/EmailTools';
	import BulkUpload from '@/components/candidates/BulkUpload';
	export default {
		components: {
			Settings,
				AdditionalSettings,
				EmailAccountSettings,
					AddEmailAccount,
					ViewEmailAccount,
					EditEmailAccount,
			Tools,
				EmailTools,
				BulkUpload
		},
		data() {
			return {
				elements: {
					activeTab: 1,
					subActiveTab: 0,
					action: "list",
					actionId: null
				}
			}
		},
		methods: {
			setSubTab: function(subTab) {
				this.elements.subActiveTab = subTab;
			},
			settingsTab: function() {
				this.elements.activeTab = 1;
				this.elements.subActiveTab = 0;
				this.elements.action = "list";
			},
			toolsTab: function() {
				this.elements.activeTab = 2;
				this.elements.subActiveTab = 0;
			},
			setAction: function(action, actionId = this.elements.actionId) {
				this.elements.action = action;
				this.elements.actionId = actionId;
			}
		}
	}

</script>
