<script>
	import HomeMenu from '@/components/home/widgets/LoginHomeMenu';
	export default {
		components: {
			HomeMenu
		}
	}
</script>

<template>
	<div>
		<HomeMenu/>
		<slot />
	</div>
</template>