<template>
	<div :class="{ 'viewonly' : preferenceinfo.viewonly == 1}">
		<div class="card">
		    <div class="card-header p-2">Preference</div>
		    <div class="card-body p-2">
		    	<div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col">Title</th>
                                            <th class="videoprofile" scope="col">State</th>
                                            <th class="title" scope="col">City</th>
                                            <th class="recruiter" scope="col">Technology/Skills</th>
                                            <th class="state" scope="col">Travel</th>
                                            <th class="" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="cloned_tr">
                                    	<tr v-for="(info, index) in preferenceinfo.basepreference">
                                    		<td>
                                    			{{ preferenceinfo.basepreference[index].title }}
                                    		</td>
                                            <td class="lastnote">
                                            	{{ preferenceinfo.basepreference[index].stateName }}
                                            </td>
                                            <td class="recruiter">
                                            	{{ preferenceinfo.basepreference[index].city }}
                                            </td>
                                            <td class="title">
                                            	{{ preferenceinfo.basepreference[index].skills }}
                                            </td>
                                            <td class="specialityunit">
                                            	{{ preferenceinfo.basepreference[index].canTravel }}
                                            </td>
                                            <td>
                                            </td>
                                    	</tr>
                                        <tr :class="{ 'norecordfound' : preferenceinfo.basepreference.length}" >
                                            <td colspan="8" align="center">No item found</td>
                                        </tr>                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
		    </div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			preferenceinfo:Object
		}
	}
</script>