<template>
		<div id="main" class="mt-70 interviewer-feedback">
			<div class="container-fluid" id="main-data">
				<div class="row p-4">
					<div class="col-md-12 pb-4 pr-0 pl-0">
						<div class="box-wrapper shadow-sm p-0 bg-white rounded pos-rel ">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12">
									<h4 class="page-title p-3 d-inline-block text-white candidate-title">FEEDBACK</h4>
									<a href="javascript:" @click="getPrevUrl()" class="btn btn-primary pull-right" style="margin-top: 5px;margin-right: 10px;">Back</a>
								</div>
							</div>
						</div>
					</div>
					<b-carousel id="recruitment-carousel" :controls=true :interval="0" background="#fff" no-animation img-height=500 :indicators=false class="col-md-12">
						<b-carousel-slide img-blank v-for="(Recruitment,index) in interviews" :key="index">
							<div class="box-wrapper shadow-sm mtb-4 bg-white rounded pos-rel p-3">
								<div class="row">
									<div class="col-sm-12">
										<div class="modal-content border border-0">
											<div class="modal-header col-md-12 col-sm-12 col-12 pl-0 pr-0">
												<h4 class="text-center page-title w-100 p-3 d-inline-block text-white blue-bg-title">{{Recruitment.interviewTitle}}</h4>
											</div>
											<div role="tablist" class="interview-feedback-accordion">
												<b-card no-body class="mb-1" v-for="(categorySkill,index) in Recruitment.categorySkill" :key="index">
													<b-card-header header-tag="header" class="p-1" role="tab">
														<b-button block href="#" v-b-toggle="'accordion-'+categorySkill.id"  variant="info">{{categorySkill.categoryName}}</b-button>
													</b-card-header>
													<b-collapse :id="'accordion-'+categorySkill.id" visible accordion="my-accordion" role="tabpanel" class="accordion-content">
														<b-card-body>
															<div v-for="(Technology,key) in categorySkill.technologyList" v-bind:key="key" class="row m-0 pt-1 pb-1 border-1-bottom" >
																<div class="col-sm-3 pos-relative">
																	<span class="pt-8">{{Technology.technology}}</span>
																</div>
																<div class="col-sm-3 pos-relative">
																	<fieldset class="rating pt-8">
																		<input :id="'rate_'+index+'_'+key+'-star5'" type="radio" value="5" v-model="Technology.rating" />
																		<label :for="'rate_'+index+'_'+key+'-star5'" title="Excellent">5</label>

																		<input :id="'rate_'+index+'_'+key+'-star4'" type="radio" value="4" v-model="Technology.rating" />
																		<label :for="'rate_'+index+'_'+key+'-star4'" title="Good">4</label>

																		<input :id="'rate_'+index+'_'+key+'-star3'" type="radio" value="3" v-model="Technology.rating" />
																		<label :for="'rate_'+index+'_'+key+'-star3'" title="Satisfactory">3</label>

																		<input :id="'rate_'+index+'_'+key+'-star2'" type="radio" value="2" v-model="Technology.rating" />
																		<label :for="'rate_'+index+'_'+key+'-star2'" title="Bad">2</label>

																		<input :id="'rate_'+index+'_'+key+'-star1'" type="radio" value="1" v-model="Technology.rating" />
																		<label :for="'rate_'+index+'_'+key+'-star1'" title="Very bad">1</label>
																	</fieldset>	
																</div>
																<div class="col-sm-2 pos-relative">
																	<label class="pt-8"><input type="checkbox" v-model="Technology.isPrimary" :checked="Technology.isPrimary"> Is Primary</label>                 
																</div>
																<div class="col-sm-4">
																	<span class="pt-8 form-control readonly">{{Technology.comment}}</span>
																</div>
															</div> 
														</b-card-body>
													</b-collapse>
												</b-card>
												<div class="card col-md-12 data-cell">
													<div v-if="feedback" class="card-header p-3">
														<span class="data-output-label col-md-3">Overall Comments</span>
														<span class="data-output-value col-md-3">{{Recruitment.overallComments}}</span>
														<span class="data-output-label col-md-3">Overall Rating</span>
														<span class="data-output-value col-md-3">
															<fieldset class="rating">
																<input :id="'rate_'+index+'-star5'" type="radio" value="5" v-model="Recruitment.overallRating" />
																<label :for="'rate_'+index+'-star5'" title="Excellent">5</label>

																<input :id="'rate_'+index+'-star4'" type="radio" value="4" v-model="Recruitment.overallRating" />
																<label :for="'rate_'+index+'-star4'" title="Good">4</label>

																<input :id="'rate_'+index+'-star3'" type="radio" value="3" v-model="Recruitment.overallRating" />
																<label :for="'rate_'+index+'-star3'" title="Satisfactory">3</label>

																<input :id="'rate_'+index+'-star2'" type="radio" value="2" v-model="Recruitment.overallRating" />
																<label :for="'rate_'+index+'-star2'" title="Bad">2</label>

																<input :id="'rate_'+index+'-star1'" type="radio" value="1" v-model="Recruitment.overallRating" />
																<label :for="'rate_'+index+'-star1'" title="Very bad">1</label>
															</fieldset>	
														</span>
														<div style="clear:both"></div>
													</div>
													<div v-if="feedback==false" class="card-header text-center p-3">
														<span>No feedback found</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</b-carousel-slide>
					</b-carousel>
				</div>
			</div>
		</div>
</template>


<script>
	import { BCarousel } from 'bootstrap-vue'
	import iziToast from 'izitoast'
	export default {
		data() {
			return {
				recruitmentId:this.$route.params.recruitmentId,
				interviews:[],
				feedback:false
			}
		},
		mounted(){
			this.loadInterviewFeedback()
		},
		methods:{
			loadInterviewFeedback(){
				axios.get(this.$store.getters.getAPIBasePath+'/interviewresource/view/feedbacks/'+this.recruitmentId).then(response =>{
					if(response.data.status){
						this.interviews=response.data.interviews
						this.feedback=true
					}
					else{
						let errmsg = (response.data.message!=undefined)?response.data.message:this.$config.NoFeedback;
						iziToast.error({
							title: 'Error',
							message: errmsg,
							position:'topRight'
						});
						this.getPrevUrl()
					}

				})
				.catch(error => {
					let errmsg = this.$config.LoadingErr;
					iziToast.error({
						title: 'Error',
						message: errmsg,
						position:'topRight'
					});
					this.getPrevUrl()
				});
			},
			getPrevUrl(){
				this.$router.go(-1);
			}
		}
	}
</script>