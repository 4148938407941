<template>
    <div id="main" class="reports settings recruiter mt-70">
        <div class="row col-md-12 box-wrapper shadow-sm my-4 rounded pos-rel mx-0 px-0">
            <div class="wrapper settings-leftbar w-100 px-0">
                <aside class="main-sidebar bg-white">
                    <!-- <nav class="navbar navbar-static-top">
                        <a href="#" @click="sidebarToggle" class="fa fa-bars sidebar-toggle" data-toggle="push-menu" role="button">
                            <span class="sr-only">Toggle navigation</span>
                        </a>
                    </nav> -->
                    <section class="sidebar">
                        <div class="p-4 header d-flex flex-row justify-content-between">
                            <i class="fa fa-bars mr-3 fa-2x text-secondary" @click="sidebarToggle" data-toggle="push-menu" role="button"></i>
                            <div class="reports-title">
                                <h5>Reports</h5>
                            </div>
                            <div class="back-link">
                                <router-link :to="{name: 'admindashboard'}">
                                    <h5 class="text-secondary">
                                        <span class="ti-arrow-left py-4" title="Go Back"></span>
                                        <span class="pl-2 back-link">Back</span>
                                    </h5>
                                </router-link>
                            </div>
                        </div>
                        <ul class="sidebar-menu" data-widget="tree">
                            
                            <li @click="userActivitiesTab" :class="elements.activeTab==1?'left-tab-active':''"><a href="#"><img alt="images" src="@/assets/icons/effectiveness.png" class="menuicon"> <span>User Activities</span></a></li>
                            <li @click="contactsTab" :class="elements.activeTab==2?'left-tab-active':''"><a href="#"><img alt="images" src="@/assets/icons/effectiveness.png" class="menuicon"> <span>Contacts</span></a></li>
                            <li @click="downloadsTab" :class="elements.activeTab==23?'left-tab-active':''"><a href="#"><img alt="images" src="@/assets/icons/effectiveness.png" class="menuicon"> <span>Downloaded Resumes</span></a></li>
                            <li @click="sharedCandidatesTab" :class="elements.activeTab==4?'left-tab-active':''"><a href="#"><img alt="images" src="@/assets/icons/effectiveness.png" class="menuicon"> <span> Shared Candidates </span></a></li>
                        </ul>
                    </section>
                </aside>
                <div class="basic-block text-center">
                    <div class="content-wrapper">
                        <section class="content-header">                            
                            <UserActivities v-if="elements.activeTab==1 && elements.subActiveTab==0" @setSubTab="setSubTab" />
                            <ActivityLog v-if="elements.activeTab==1 && elements.subActiveTab==1" @setSubTab="setSubTab" />
                            <ResumeReport v-if="elements.activeTab==1 && elements.subActiveTab==2" @setSubTab="setSubTab" />
                            <Contacts v-if="elements.activeTab==2 && elements.subActiveTab==0" @setSubTab="setSubTab" />
                            <Downloads v-if="elements.activeTab==3 && elements.subActiveTab==0" @setSubTab="setSubTab" />
                            <SharedCandidates v-if="elements.activeTab==4 && elements.subActiveTab==0" @setSubTab="setSubTab" />
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Contacts from './account/ListContacts';
import UserActivities from './activities/UserActivities';
import ActivityLog from './activities/blocks/ActivityLog';
import ResumeReport from './activities/blocks/ResumeReport';
import Downloads from './account/ListDownloads';
import SharedCandidates from './account/SharedCandidates';


export default {
    components: {
        Contacts,
        UserActivities,
        ActivityLog,
        ResumeReport,
        Downloads,
        SharedCandidates
    },
    data() {
        return {
            elements: {
                activeTab: 1,
                subActiveTab: 0,
                action: {
                    mode: "list",
                    id: null
                }
            },
            recownerid: this.$store.getters.currentUser.recruitOwner
        }
    },
    methods: {
        setSubTab: function(subTab, mode, id) {
            this.elements.subActiveTab = subTab;
            if (mode != "")
                this.elements.action.mode = mode;
            if (id)
                this.elements.action.id = id;
        },
        contactsTab: function() {
            this.elements.activeTab = 2;
            this.elements.subActiveTab = 0;
        },
        userActivitiesTab: function() {
            this.elements.activeTab = 1;
            this.elements.subActiveTab = 0;
        },
        downloadsTab: function(){
            this.elements.activeTab = 3;
            this.elements.subActiveTab = 0;
        },
        sharedCandidatesTab: function(){
            this.elements.activeTab = 4;
            this.elements.subActiveTab = 0;
        },
        
        sidebarToggle: function(event) {
            $(".reports.settings").toggleClass('sidebar-collapse');
        }
    }
}

</script>
