<template>
    <div class="kpis reports text-left">
        <div class="row">
            <div class="col-sm-12 p-10">
                <div id="recruiter-settings" class="recruiter settings mt-10">
                    <div class="container-fluid" id="quick-search">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="text-center">Referral List</h5>
                            </div>
                        </div>
                        <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <h4 class="page-title">Filter</h4>
                                </div>
                                <div class="col-md-6 col-sm-6 col-6">
                                    <ul class="ul-horizontal box-tools text-right mb-0">
                                        <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :title="isExpand?'Hide':'Expand'" :class="isExpand?'ti-angle-up':'ti-angle-down'" class="hover-hand"></span></a></li>
                                        <li><i class="ti-close hover-hand px-2" @click="$emit('setSubTab',0);$emit('updateLogActivity');" title="Close"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <div id="main-search" :class="isExpand?'d-block':'d-none'">
                                <div class="row form-group ten-columns">
                                    <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Name</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" name="userName" maxlength="50" v-on:keyup.enter="findReferralList" value="" id="name" class="form-control" v-model="search.params.name">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="month" value="" v-model="search.params.date" placeholder="Date" input-class="form-control enablefield" maxlength="10" wrapper-class="input-group date">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label style="visibility: hidden;"></label>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="button" @click="resetLogSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                                <button type="button" @click="findReferralList" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Go</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="load-indicator"></div>
                        </div>
                    </div>
                    <div class="container-fluid" id="main-data">
                        <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                            <div class="row" style="margin-bottom: 15px;">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <h4 class="page-title">Summary
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                            <div class="dropdown">
                                                <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                                <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                    <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                                </div>
                                            </div>
                                        </span>
                                    </h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive" style="min-height: 178px;">
                                        <table class="table table-borderless all-table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th  scope="col">Sl.No</th>
                                                    <th  scope="col">Referral Code</th>
                                                    <th  scope="col">Name</th>
                                                    <th  scope="col">Position</th>
                                                    <!-- <th  scope="col">Industry</th> -->
                                                    <th  scope="col">Email</th>
                                                    <th  scope="col">Phone No.</th>
                                                    <th  scope="col">Total Reward Points</th>
                                                    <th  scope="col">Total Reward Values</th>
                                                    <th  scope="col">Candidate Count</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="referralList.length==0">
                                                    <td colspan="8" class="text-center">No data found</td>
                                                </tr>
                                                <template v-for="(referral, index) in referralList">
                                                    <tr>
                                                        <td>
                                                            <a href="javascript:" class="expand" data-targetopen="panel1">
                                                                <span @click="loadCandidatesList(referral.id)"><i :class="(currentIndex==referral.id) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
                                                                </span>
                                                            </a>
                                                        </td>
                                                        <td>{{((pagination.currentPage-1) * (pagination.rowsPerPage)) + (index+1)}}</td>
                                                        <td class="">{{ referral.referralCode }}</td>
                                                        <td class="">{{ referral.referralName }}</td>
                                                        <td class="">{{ referral.position }}</td>
                                                        <!-- <td class="">{{ referral.industry }}</td> -->
                                                        <td class="">{{ referral.email }}</td>
                                                        <td class="">{{ referral.phoneNumber }}</td>
                                                        <td class="text-center">{{ referral.totalPoints }}</td>
                                                        <td class="text-center">{{ referral.totalValues }}</td>
                                                        <td class="">
                                                            <span class="badge badge-bg">{{ referral.candidateCount }}</span>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="currentIndex==referral.id">
                                                        <td colspan="9">
                                                            <div class="table-responsive recscroll">
                                                                <table class="table table-borderless all-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">Sl.No</th>
                                                                            <th class="text-left" scope="col">Cand Code</th>
                                                                            <th class="text-left" scope="col">Name</th>
                                                                            <th v-if="candContactAccess==1" class="text-left" scope="col">Email</th>
                                                                            <th v-if="candContactAccess==1" class="text-left" scope="col">Phone</th>
                                                                            <th class="text-left" scope="col">Position</th>
                                                                            <th class="text-left" scope="col">Industry</th>
                                                                            <th class="text-left" scope="col">Experience</th>
                                                                            <th class="text-left" scope="col">Created Date</th>
                                                                            <!-- <th class="text-left" scope="col">Technology</th>
                                                                            <th class="text-left" scope="col">Skills</th> -->
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <template v-for="(candidate, index) in candidateList">
                                                                            <tr>
                                                                                <td>{{(index+1)}}</td>
                                                                                <td class="text-left">{{ candidate.candidateCode }}</td>
                                                                                <td class="text-left">{{ candidate.candidateName }}</td>
                                                                                <td v-if="candContactAccess==1" class="text-left">{{ candidate.email }}</td>
                                                                                <td v-if="candContactAccess==1" class="text-left">{{ candidate.phoneNumber }}</td>
                                                                                <td class="text-left">{{ candidate.position }}</td>
                                                                                <td class="text-left">{{ candidate.industry }}</td>
                                                                                <td class="text-left">{{ candidate.experience }}</td>
                                                                                <td class="text-left">{{ candidate.createdAt | formatDate}}</td>
                                                                                <!-- <td class="text-left">{{ candidate.technology }}</td>
                                                                                <td class="text-left">{{ candidate.skill }}</td> -->
                        
                                                                            </tr>
                                                                        </template>
                                                                        <tr v-if="candidateList == null">
                                                                            <td colspan="8" align="center">No records found</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <b-pagination v-if="referralList.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadReferralList()"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    data() {
        return {
            isExpand: false,
            referralList: [],
            candidateList: [],
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            searchlimit: this.$store.state.searchlimit,
            search: {
                params: {
                    name: "",
                    date: "",
                    year: "",
                    month: "",
                    referralName: "",
                },
            },
            currentIndex: null,
            candContactAccess: localStorage.getItem('candidateContactAccess')
        }
    },
    mounted() {
        this.$emit('updateLogActivity');
        this.$emit('addLogActivity', 'Admin Referral List');
        this.$store.commit('refreshPagination');
        this.loadReferralList();
    },
    methods: {
        resetLogSearch() {
            this.search.params.name = "";
            this.search.params.date = "";
            this.search.params.year = "";
            this.search.params.month = "";
            this.search.params.referralName = "";
            this.currentIndex = null;
            this.loadReferralList();
        },
        loadReferralList() {

            if (this.search.params.date != null && this.search.params.date != '') {
                let resultSplit = this.search.params.date.split('-');
                if (resultSplit.length == 2) {
                    this.search.params.year = resultSplit[0];
                    this.search.params.month = resultSplit[1];
                }
            }

            axios.post(this.$store.getters.getAPIBasePath + '/referralresource/get/referrallist?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    console.log("ref list",result)
                    if(result.recordinfo != null){
                        this.referralList = result.recordinfo;
                        this.pagination.totalRecords = result.totalrecords;
                        this.pagination.currentPage = result.currentpage;
                    }
                    else{
                        this.referralList = [];
                        this.pagination.totalRecords = 0;
                        this.pagination.currentPage = 1;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        changeLimit(newlimit) {
            this.pagination.currentPage = 1;
            this.pagination.rowsPerPage = newlimit;
            this.loadReferralList();
        },
        findReferralList() {
            this.pagination.currentPage = 1;
            this.loadReferralList();
        },

        loadCandidatesList(referralId) {
            if (this.currentIndex == referralId) {
                this.currentIndex = null;
            } else {
                this.currentIndex = referralId;
            }
            let data = {
                referralId: referralId
            }
            axios.get(this.$store.getters.getAPIBasePath + '/candidateresource/get/candidatelist/'+ referralId )
                .then(response => {
                    let result = response.data;        
                        this.candidateList = result.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
    }
}

</script>
