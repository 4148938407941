<template>
    <div id="main">
        <div class="container-fluid" id="quick-search">
            <div class="row h-50">
                <div class="col-sm-12 h-100 d-table">
                    <div class="d-table-cell align-middle login_div">
                        <div class="col-md-4 offset-md-4">
                            <div class="interviewer-register-block">
                                <div v-if="showForm" class="box-wrapper shadow-sm p-5 mb-4 bg-white rounded pos-rel">
                                    <h3 class="text-color text-center" style="text-transfomargin-top:10px">Activation</h3>
                                    <form>
                                        <div class="row pt-5">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="input-group required-field-block">
                                                                <input type="password" name="Password" placeholder="Password" maxlength="45" size="30" v-model="activation.password" class="form-control box-none">
                                                                <!-- <span class="required-icon"></span> -->
                                                            </div>
                                                            <div class="error-block">
                                                                <label v-if="$v.activation.password.$error && !$v.activation.password.required" class="text-danger">Please enter the password</label>
                                                                <label v-if="$v.activation.password.$error && !$v.activation.password.minLength" class="text-danger">Password must have 6 characters</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="row">
                                                        <!--   <label for="inputtext" class="col-md-3 col-form-label">lastName:</label> -->
                                                        <div class="col-md-12">
                                                            <div class="input-group required-field-block">
                                                                <input type="password" name="confirmPassword" placeholder="Confirm Password" maxlength="45" size="30" v-model="activation.confirmPassword" class="form-control box-none">
                                                                <!-- <span class="required-icon"></span> -->
                                                            </div>
                                                            <div class="error-block">
                                                                <label v-if="$v.activation.confirmPassword.$error && !$v.activation.confirmPassword.required" class="text-danger">Please enter the password</label>
                                                                <label v-if="$v.activation.confirmPassword.$error && !$v.activation.confirmPassword.sameAsPassword" class="text-danger">Passwords must match</label>
                                                            </div>
                                                            <!-- <input type="text" class="form-control box-none" id="" placeholder="Last Name" v-model="activation.confirmPassword"> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bg-slategray lt wrap-reset text-left" style="background-color:#fff !important;">
                                            <button type="button" @click="submitActivation" class="btn btn-primary" style="float: right;">Submit
                                                <router-link :to="{ name: 'interviewerprofile' }" style="color : #fff;"></router-link></button>
                                        </div>
                                    </form>
                                </div>
                                <div v-if="!showForm" class="box-wrapper shadow-sm p-5 mb-4 bg-white rounded pos-rel">
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col-sm-12 clientinfo">
                                                <p class="text-center"> {{message}}</p>
                                                <p class="text-center" v-if="this.activationFlag == 'activated'">
                                                   <a class="nav-link" @click="performLogin" href="Javascript:void(0);">Click here to login</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {

    computed: {
        activationKey() {
            return this.$route.params.key;
        }
    },
    data() {
        return {
            showForm: true,
            elements: {
                submitted: false
            },
            activation: {
                id: "",
                password: "",
                userTypeId: "",
            },
            userInfo: [],
            login: {
                email: '',
                password: ''
            },
            message: '',
            activationFlag: ''
        }
    },
    validations: {
        activation: {
            password: {
                required,
                minLength: minLength(6)
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },
    mounted() {
        this.loadCheckAccessToken(this.activationKey);
    },
    methods: {
        updatePassword() {
            let data = { "id": this.userInfo.userId, "password": this.activation.password, "userTypeId": this.userInfo.userTypeId };
            axios.post(this.$store.getters.getAPIBasePath + '/userresource/update/password', data)
                .then(response => {
                    this.performLogins();
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadCheckAccessToken(activationKey) {

            let data = { "activationKey": this.activationKey };
            axios.post(this.$store.getters.getAPIBasePath + '/userresource/checkaccesstoken', data)
                .then(response => {
                    let result = response.data;

                    if (result.status) {
                        this.userInfo = result.user;
                    } else {
                        this.message = result.message;
                        this.activationFlag = result.flag;
                        iziToast.success({
                            title: 'Success',
                            message: this.message,
                            position: 'topRight'
                        });
                        this.showForm = false;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        submitActivation: function() {
            this.elements.submitted = true;
            let validation_status = this.validateActivation();
            if (!validation_status) {
                return false;
            }
            this.updatePassword();
        },
        validateActivation: function() {
            this.$v.activation.$touch();
            if (this.$v.activation.$invalid) {
                return false;
            }
            return true;
        },
        performLogins() {
            this.login.email = this.userInfo.email;
            this.login.password = this.activation.password;
            this.login.userType = this.userInfo.userType;

            axios.post(this.$store.getters.getAPIBasePath + '/userresource/authenticate', this.login)
                    .then(response => {
                        let result = response.data;                   
                        if (result.result) {
                            localStorage.setItem('candidateContactAccess', result.data.candidateContactAccess);
                            localStorage.setItem('salaryNegotiationAccess', result.data.salaryNegotiationAccess);
                            localStorage.setItem('offerLetterAccess', result.data.offerLetterAccess);
                            localStorage.setItem('vendorClientInfoAccess', result.data.vendorClientInfoAccess);
                            localStorage.setItem('currencyId', result.data.currencyId);
                            localStorage.setItem('workAuthorization', result.data.workAuthorization);
                            localStorage.setItem('countryId', result.data.countryId);
                            localStorage.setItem('companyLogo', result.data.companyLogo);
                            localStorage.setItem('accountType', result.data.accountType);
                            const defaultredirects = this.$store.getters.getDefaultRedirects;
                            this.$store.dispatch("setupLogin", result).then(() => {
                                if (result.data.userType == "interviewer") {
                                    this.$router.push({ path: defaultredirects.interviewer });
                                } else if (result.data.userType == "candidate") {
                                    this.$router.push({ name: "candidatedashboard", params: { jobId: this.login.jobId } });
                                    if (localStorage.getItem('clientAccountId') != null) {
                                        let candInfo = {
                                            recruiterId: localStorage.getItem('recruiterId'),
                                            ttClientAccountId: localStorage.getItem('clientAccountId'),
                                            companyId: localStorage.getItem('companyId'),
                                            candidateId: this.$store.getters.currentUser.profileId,
                                            email: this.login.email
                                        };
                                        axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/add/candidate/other/company', candInfo)
                                            .then(response => {
                                                //console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                    }
                                } else if (result.data.userType == "admin") {
                                    //this.$router.push({ path: defaultredirects.admin });
                                    if (result.data.isSuperAdmin) {
                                            this.$router.push({ path: defaultredirects.superadmin });
                                    } else {
                                        this.$router.push({ path: defaultredirects.admin });
                                    }
                                } else if (result.data.userType == "guest") {
                                        this.$router.push({ path: defaultredirects.guest });
                                } else if (result.data.userType == "referral") {
                                    this.$router.push({ path: defaultredirects.referral, params: { jobId: this.login.jobId } });
                                    localStorage.setItem('loginJobId', this.login.jobId);
                                    if (localStorage.getItem('clientAccountId') != null) {
                                        let referralInfo = {
                                            recruiterId: localStorage.getItem('recruiterId'),
                                            ttClientAccountId: localStorage.getItem('clientAccountId'),
                                            companyId: localStorage.getItem('companyId'),
                                            referralId: this.$store.getters.currentUser.profileId,
                                            email: this.login.email
                                        };
                                        axios.post(this.$store.getters.getAPIBasePath + '/referralresource/add/referral/other/company', referralInfo)
                                            .then(response => {
                                                //console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                    }
                                } else {
                                    if (result.data.clientServiceExpired == true) {
                                        iziToast.info({
                                            title: '',
                                            message: 'Your account expired',
                                            position: 'topRight'
                                        });
                                        return;
                                    }
                                    if (result.data.ttClientAccountId != null) {
                                        localStorage.setItem('clientAccountId', result.data.ttClientAccountId);
                                    }
                                    //if (result.data.recruitOwner)
                                    if (result.data.recruitType == 2 || result.data.recruitType == 3) { // this for recruiter manager
                                        if(result.data.accountType == 3){
                                            this.$router.push({ path: defaultredirects.corpadmin });
                                        }
                                        else{
                                            this.$router.push({ path: defaultredirects.recruiteradmin });
                                        }
                                    } else {
                                        this.$router.push({ path: defaultredirects.recruiter });
                                    }
                                }
                            });
                        } else {
                            iziToast.info({
                                message: result.message,
                                position: 'topRight'
                            });

                        }
                    })
                    .catch(error => {
                        console.log(error);
                        iziToast.error({
                            title: 'Error Occured',
                            message: 'Please try again',
                            position: 'topRight'
                        });
                    });
                    
            /* axios.post(this.$store.getters.getAPIBasePath + '/userresource/authenticate', this.login)
                .then(response => {
                    let result = response.data;
                    if (result.result) {
                        localStorage.setItem('candidateContactAccess', result.data.candidateContactAccess);
                        const defaultredirects = this.$store.getters.getDefaultRedirects;
                        this.$store.dispatch("setupLogin", result).then(() => {
                            if (result.data.userType == "interviewer") {
                                this.$router.push(defaultredirects.interviewer);
                            } else if (result.data.userType == "candidate") {
                                this.$router.push(defaultredirects.candidate);
                            } else if (result.data.userType == "admin") {
                                this.$router.push(defaultredirects.admin);
                            } else if (result.data.userType == "referral") {
                                this.$router.push({ path: defaultredirects.referral });
                            } else {
                                if (result.data.recruitOwner)
                                    this.$router.push(defaultredirects.recruiteradmin);
                                else
                                    this.$router.push(defaultredirects.recruiter);
                            }
                        });
                    } else {
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.InvCredential,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.TryAg,
                        position: 'topRight'
                    });
                }); */
        }

    }
}

</script>
