import Login from '@/components/Login';
import homeroutes from './homeroutes';
import candidatesroutes from './candidatesroutes';
import clientsroutes from './clientsroutes';
import joblistroutes from './joblistroutes';
import interviewerbase from './interviewer/baseroutes';
import interviewerqueue from './interview_queue';
import candmodulebase from './candidate/baseroutes';
import adminmodulebase from './admin/baseroutes';
import recruiterbase from './recruiter/baseroutes';
import referralbase from './referral/baseroutes';
import AuthLogin from '@/components/LinkedinRedirect';
import vendorroutes from './vendorroutes';
import guestbase from './guest/baseroutes';

const baseroutes = [{
        path: '/login',
        name: 'homelogin',
        meta: { layout: 'login' },
        component: Login
    },
    {
        path: '/auth/linkedin',
        name: 'sociallogin',
        meta: { layout: 'login' },
        component: AuthLogin
    }
];

const routes = baseroutes.concat(homeroutes, candidatesroutes, clientsroutes, joblistroutes, interviewerbase, interviewerqueue, candmodulebase, adminmodulebase, recruiterbase, referralbase, vendorroutes, guestbase);

export default routes;