<template>
    <div id="main" class="mt-70">
        <div class="container-fluid recruiter-dashboard interviewer dashboard">
            <div class="row">
                <main role="main" class="col-md-12 ml-sm-auto col-lg-12 pt-2">
                    <div class="row" style="margin: 0px">
                        <div class="card-list">
                            <div class="row dashboard-admin-rm">
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'listjobs' }" class="">
                                        <div class="card dashboard-mr green" style="">
                                            <div class="value">Jobs</div>
                                            <div class="value">
                                                <img class="" src="@/assets/icons/jobprofile-white.png" alt=""
                                                    style="height: 90%" />
                                                <span class="float-right font-weight-bold">{{ count.jobs }}</span>
                                            </div>
                                            <div class="stat mt-0">
                                                Manage Job
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'listcandidates' }" class="">
                                        <div class="card dashboard-mr blue" style="">
                                            <div class="value">Candidates</div>
                                            <div class="value">
                                                <img class="" src="@/assets/icons/candidate-white.png" alt=""
                                                    style="height: 90%" />
                                                <span class="float-right font-weight-bold">{{
                                                    count.candidates
                                                }}</span>
                                            </div>
                                            <div class="stat mt-0">
                                                Manage Candidate
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'viewclients' }" class="">
                                        <div class="card dashboard-mr orange" style="">
                                            <div class="value">Clients</div>
                                            <div class="value">
                                                <img class="" src="@/assets/icons/value-white.png" alt=""
                                                    style="height: 90%" />
                                                <span class="float-right font-weight-bold">{{ count.clients }}</span>
                                            </div>
                                            <div class="stat mt-0">
                                                Manage Client
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'interviewers' }" class="">
                                        <div class="card dashboard-mr red" style="">
                                            <div class="value">
                                                Interviewers
                                            </div>
                                            <div class="value">
                                                <img class="" src="@/assets/icons/meeting-white.png" alt=""
                                                    style="height: 90%" />
                                                <span class="float-right font-weight-bold">{{
                                                    count.interviewers
                                                }}</span>
                                            </div>
                                            <div class="stat-rm mt-0">
                                                Manage Interviewer
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'listreferrals' }" class="">
                                        <div class="card dashboard-mr lightred" style="">
                                            <div class="value">Referrals</div>
                                            <div class="value">
                                                <img class="" src="@/assets/icons/meeting-white.png" alt=""
                                                    style="height: 90%" />
                                                <span class="float-right font-weight-bold">{{ count.referrals }}</span>
                                            </div>
                                            <div class="stat mt-0">
                                                Manage Referral
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div v-if="(recruiterType == 2) |
                                    (recruiterType == 3)
                                    " class="col-12 col-md-6 col-lg-2 col-sm-3 mb-3">
                                    <router-link :to="{ name: 'listrecruiter' }" class="">
                                        <div class="card dashboard-mr" style="background-color: #c0ca33">
                                            <div class="value">
                                                Recruiters & Members
                                            </div>
                                            <div class="value">
                                                <img class="" src="@/assets/icons/recruit-32.png" alt=""
                                                    style="height: 90%" />
                                                <span class="float-right font-weight-bold">{{
                                                    count.recruiters
                                                }}</span>
                                            </div>
                                            <div class="stat mt-0">
                                                Manage Recruiter & Member
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'recruitertasks' }" class="">
                                        <div class="card dashboard-mr" style="background-color: grey">
                                            <div class="value">Tasks</div>
                                            <div class="value">
                                                <img class="" src="@/assets/icons/settings-tools-white.png" alt=""
                                                    width="32px" height="100%" />
                                                <span class="float-right font-weight-bold">{{ count.tasks }}</span>
                                            </div>
                                            <div class="stat mt-0">
                                                Manage Task
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'reports' }" class="">
                                        <div class="card dashboard-mr" style="background-color: green">
                                            <div class="value">Reports</div>
                                            <div class="value">
                                                <img class="" src="@/assets/icons/statistics-white.png" alt=""
                                                    style="height: 90%" />
                                            </div>
                                            <div class="stat mt-0">
                                                Manage Report
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-3">
                                    <router-link :to="{ name: 'tooldemo' }" class="">
                                        <div class="card dashboard-mr" style="background-color: #3f729b">
                                            <div class="value">Tool Demo</div>
                                            <div class="value">
                                                <img class="" src="@/assets/icons/tool-demo-white.png" alt=""
                                                    style="height: 90%" />
                                            </div>
                                            <div class="stat mt-0">
                                                Tool Demo
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3 pr-0">
                            <h5 style="
                                    background-color: #37a0e4;
                                    color: #fff;
                                    padding: 5px;
                                " class="">
                                Inbox
                            </h5>
                            <div class="box-wrapper mb-5 rounded">
                                <b-tabs card class="tab-list inbox-tab-list driver-tab-list tab-bg">
                                    <b-tab title="Jobs" class="scroll-down-inbox">
                                        <div class="card-media" v-if="joblist.length == 0">
                                            <div class="col-md-12 pt-4 pb-4" style="
                                                    text-align: center;
                                                    background-color: #ffff;
                                                ">
                                                No records found
                                            </div>
                                        </div>
                                        <div class="card-media" v-for="(jobinfo, index) in joblist">
                                            <div class="card-media-body">
                                                <div class="row">
                                                    <div class="col-md-9 col-sm-9">
                                                        <router-link :to="{
                                                            name: 'planrecruitment',
                                                            params: {
                                                                jobid: jobinfo.id,
                                                                status: jobinfo.status,
                                                            },
                                                        }">
                                                            <span class="card-media-body-heading job-link pl-0">{{
                                                                jobinfo.jobTitle
                                                            }}
                                                                <span v-if="checkEmpty(
                                                                            jobinfo.jobCode
                                                                        )
                                                                        ">- [{{
            jobinfo.jobCode
        }}]</span>
                                                            </span>
                                                        </router-link>
                                                    </div>
                                                    <div class="col-md-3 col-sm-3">
                                                        <div class="vacancies">
                                                            <span v-if="checkEmpty(
                                                                        jobinfo.vacancies
                                                                    )
                                                                    " title="Vacancies"
                                                                class="badge badgebgdashboard pull-right">{{
                                                                    jobinfo.vacancies
                                                                }}</span>
                                                        </div>
                                                        <div class="hotJob">
                                                            <span v-if="jobinfo.priority ==
                                                                    true
                                                                    " class="redclr">Hot Job</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="checkEmpty(
                                                        jobinfo.clientName
                                                    )
                                                        " class="col-md-12 col-sm-12 mb-2">
                                                        <span class="">{{
                                                            jobinfo.clientName
                                                        }}</span>
                                                    </div>
                                                </div>
                                                <ul class="mb-2">
                                                    <li v-if="checkEmpty(
                                                                jobinfo.experience
                                                            )
                                                            " class="d-inline-block pr-4">
                                                        <i class="ti-bag"></i>
                                                        {{ jobinfo.experience }}
                                                        years
                                                    </li>
                                                    <li v-if="checkEmpty(
                                                        jobinfo.location
                                                    )
                                                        " class="d-inline-block pr-4">
                                                        <i class="ti-location-pin"></i>
                                                        {{ jobinfo.location }}
                                                    </li>
                                                    <li v-if="checkEmpty(
                                                        jobinfo.days
                                                    )
                                                        " class="d-inline-block pull-right">
                                                        <i class="ti-alarm-clock"></i>
                                                        <span class="pl-2 font-weight-bold" style="
                                                                color: #e83e8c;
                                                            ">{{
                                                                jobinfo.days
                                                            }}</span>
                                                        <span class="pl-1 small">days more</span>
                                                    </li>
                                                </ul>
                                                <div v-if="jobinfo.status == 6" class="card-media-body-top">
                                                    <div class="card-media-body-top u-float-right w-100 text-right hrline"
                                                        style="margin-top: 8px">
                                                        <span class="job-link float-left pt-0 pb-0">
                                                            <router-link :to="{
                                                                name: 'editjob',
                                                                params: {
                                                                    jobid: jobinfo.id,
                                                                },
                                                            }">
                                                                <span class="ti-eye"></span>
                                                                Review
                                                            </router-link>
                                                        </span>
                                                        <span class="job-link pt-0 pb-0">
                                                            <a title="Reject" href="javascript:void(0);" @click="
                                                                setJobId(
                                                                    jobinfo.id
                                                                )
                                                                " data-toggle="modal" data-target="#RejectJob"><i
                                                                    class="ti-close" style="
                                                                        color: red;
                                                                    "></i><span style="
                                                                        color: red;
                                                                        padding-right: 10px;
                                                                        font-family: sans-serif;
                                                                    ">
                                                                    Reject</span></a>
                                                        </span>
                                                        <span class="job-link float-right pt-0 pb-0">
                                                            <a title="Accept" href="javascript:void(0);" @click="
                                                                setJobId(
                                                                    jobinfo.id
                                                                )
                                                                " data-toggle="modal" data-target="#AcceptJob"><i
                                                                    class="ti-check" style="
                                                                        color: green;
                                                                    "></i><span class="pr-10" style="
                                                                        color: green;
                                                                        font-family: sans-serif;
                                                                    ">Accept</span></a>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div v-if="jobinfo.status != 6" class="card-media-body-top">
                                                    <div class="card-media-body-top u-float-right w-100 text-right hrline">
                                                        <span class="float-left" v-if="checkEmpty(
                                                            jobinfo.dueDate
                                                        )
                                                         "><i class="ti-calendar pr-2"></i>{{
                                                              jobinfo.dueDate
                                                             | formatDate
                                                         }}</span>
                                                        <span v-if="false">
                                                            <a style="
                                                                    cursor: pointer;
                                                                " @click="
                                                                    loadRecruitmentsByJob(
                                                                        jobinfo.id
                                                                    )
                                                                    ">
                                                                <i class="ti-bar-chart"></i></a>
                                                        </span>
                                                        <span class="job-link">
                                                            <router-link title="Edit Job" :to="{
                                                                name: 'editjob',
                                                                params: {
                                                                    jobid: jobinfo.id,
                                                                },
                                                            }"><span
                                                                    class="fa fa-pencil-square-o fa_custom fa-lg"></span>
                                                                Edit</router-link>
                                                        </span>
                                                        <span class="job-link">
                                                            <router-link title="Recruitment" :to="{
                                                                name: 'planrecruitment',
                                                                params: {
                                                                    jobid: jobinfo.id,
                                                                },
                                                            }"><i class="fa fa-users"></i>
                                                                Recruitment</router-link>
                                                        </span>
                                                        <span v-if="false" class="job-link">
                                                            <social-sharing url="https://development.talentturbo.us/"
                                                                title="TalentTurbo" :description="getSocialMediaShareInfo(
                                                                    jobinfo.jobTitle
                                                                )
                                                                    " :quote="getSocialMediaShareInfo(
                                                                        jobinfo.jobTitle
                                                                    )
                                                                        " :hashtags="getSocialMediaHashTag(
                                                                        jobinfo.jobTitle,
                                                                        jobinfo.jobCode
                                                                    )
                                                                        " :twitter-user="jobinfo.jobCode
                                                                        " inline-template>
                                                                <network network="facebook">
                                                                    <i class="fa fa-facebook-square" style="
                                                                            color: #007bff;
                                                                            font-size: 14px;
                                                                        "></i>
                                                                </network>
                                                            </social-sharing>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Candidates" class="scroll-down-inbox">
                                        <div class="card-media" v-if="candidatesList.length == 0">
                                            <div class="col-md-12 pt-4 pb-4" style="
                                                    text-align: center;
                                                    background-color: #ffff;
                                                ">
                                                No records found
                                            </div>
                                        </div>
                                        <div class="card-media" v-for="(
                                                candidateInfo, index
                                            ) in candidatesList">
                                            <!-- v-if="candidateInfo.status == 5 || candidateInfo.status == 7" -->
                                            <div class="card-media-body">
                                                <div class="row">
                                                    <div class="col-md-7 col-sm-7">
                                                        <router-link :to="{
                                                            name: 'viewcandidate',
                                                            params: {
                                                                candidateid:
                                                                    candidateInfo.id,
                                                            },
                                                        }"><span class="card-media-body-heading job-link pl-0">{{
                                                                candidateInfo.candidateName
                                                            }}
                                                            </span>
                                                        </router-link>
                                                    </div>
                                                    <div class="col-md-5 col-sm-5">
                                                        <div class="vacancies">
                                                            <span v-if="checkEmpty(
                                                                        candidateInfo.experience
                                                                    )
                                                                    " title="Experience"
                                                                class="badge badgebgcaninbox pull-right">{{
                                                                    candidateInfo.experience
                                                                }}
                                                                yrs</span>
                                                        </div>
                                                        
                                                        <div class="hotJob">
                                                            <span v-if="candidateInfo.priority ==
                                                                    true
                                                                    " class="redclr">Hot
                                                                Candidate</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="checkEmpty(
                                                    candidateInfo.position
                                                )
                                                    " class="row">
                                                    <span style="
                                                            margin-left: 15px;
                                                        ">
                                                        {{
                                                            candidateInfo.position
                                                        }}
                                                    </span>
                                                </div>
                                                <div v-if="checkEmpty(
                                                            candidateInfo.industryName
                                                        )
                                                        " class="row">
                                                    <span style="
                                                            margin-left: 15px;
                                                        ">
                                                        <i class="fa fa-building-o pr-2"></i>{{
                                                            candidateInfo.industryName
                                                        }}</span>
                                                </div>
                                                <div v-if="ttClientAccountId ==
                                                        candidateInfo.ttClientAccountId ||
                                                        candidateInfo.isViewed ==
                                                        1
                                                        " class="row">
                                                    <span v-if="candidateInfo.showContact &&
                                                        checkEmpty(
                                                            candidateInfo.email
                                                        )
                                                        " style="
                                                            margin-left: 15px;
                                                        "><i class="ti-email pr-2">
                                                        </i>
                                                        {{
                                                            candidateInfo.email
                                                        }}
                                                    </span>
                                                    <span v-if="candidateInfo.showContact && checkEmpty(
                                                                candidateInfo.mobile
                                                            )
                                                            " style="
                                                            margin-left: 15px;
                                                        "><i class="ti-mobile pr-2">
                                                        </i>
                                                        {{
                                                            candidateInfo.mobile
                                                        }}</span>
                                                </div>
                                                <div class="card-media-body-top">
                                                    <div class="card-media-body-top u-float-right w-100 text-right hrline"
                                                        style="margin-top: 8px">
                                                        <span class="job-link float-left pt-0 pb-0">
                                                            <router-link :to="{
                                                                        name: 'viewcandidate',
                                                                        params: {
                                                                            candidateid:
                                                                                candidateInfo.id,
                                                                        },
                                                                    }">
                                                                <span class="ti-eye"></span>
                                                                Review
                                                            </router-link>
                                                        </span>
                                                        <span class="job-link pt-0 pb-0">
                                                            <a title="Reject" href="javascript:void(0);" @click="
                                                                setCandidateId(
                                                                    candidateInfo.id
                                                                )
                                                                " data-toggle="modal" data-target="#RejectCandidate"><i
                                                                    class="ti-close" style="
                                                                        color: red;
                                                                    "></i><span style="
                                                                        color: red;
                                                                        padding-right: 10px;
                                                                        font-family: sans-serif;
                                                                    ">
                                                                    Reject</span></a>
                                                        </span>
                                                        <span class="job-link float-right pt-0 pb-0">
                                                            <a title="Accept" href="javascript:void(0);" @click="
                                                                setCandidateId(
                                                                    candidateInfo.id,
                                                                    candidateInfo.referralId
                                                                )
                                                                " data-toggle="modal" data-target="#AcceptCandidate"><i
                                                                    class="ti-check" style="
                                                                        color: green;
                                                                    "></i><span class="pr-10" style="
                                                                        color: green;
                                                                        font-family: sans-serif;
                                                                    ">Accept</span></a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Interviewers" class="scroll-down-inbox">
                                        <div class="card-media" v-if="interviewersList.length == 0">
                                            <div class="col-md-12 pt-4 pb-4" style="
                                                    text-align: center;
                                                    background-color: #ffff;
                                                ">
                                                No records found
                                            </div>
                                        </div>
                                        <div class="card-media" v-for="(
                                                interviewerInfo, index
                                            ) in interviewersList">
                                            <div class="card-media-body">
                                                <div class="row">
                                                    <div class="col-sm-10">
                                                        <router-link :to="{
                                                            name: 'viewinterviewer',
                                                            params: {
                                                                interviewerid:
                                                                    interviewerInfo.id,
                                                                verified:
                                                                    interviewerInfo.verified,
                                                            },
                                                        }">
                                                            <span class="card-media-body-heading job-link pl-0">{{
                                                                interviewerInfo.interviewerName
                                                            }}
                                                            </span>
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <span style="
                                                            margin-left: 15px;
                                                        ">
                                                        Email :
                                                        {{
                                                            interviewerInfo.email
                                                        }}
                                                    </span>
                                                </div>
                                                <div v-if="interviewerInfo.phoneNo
                                                        " class="row">
                                                    <span style="
                                                            margin-left: 15px;
                                                        ">
                                                        Phone :
                                                        {{
                                                            interviewerInfo.phoneNo
                                                        }}
                                                    </span>
                                                </div>
                                                <div v-if="interviewerInfo.position
                                                        " class="row">
                                                    <span style="
                                                            margin-left: 15px;
                                                        ">
                                                        Position :
                                                        {{
                                                            interviewerInfo.position
                                                        }}
                                                    </span>
                                                </div>
                                                <div v-if="interviewerInfo.technology
                                                        " class="row">
                                                    <span style="
                                                            margin-left: 15px;
                                                        ">
                                                        Technology :
                                                        {{
                                                            interviewerInfo.technology
                                                        }}
                                                    </span>
                                                </div>
                                                <div class="card-media-body-top">
                                                    <div class="card-media-body-top u-float-right w-100 text-right hrline"
                                                        style="margin-top: 8px">
                                                        <span class="job-link float-left pt-0 pb-0">
                                                            <a title="Reject" href="javascript:void(0);" @click="
                                                            setInterviewId(
                                                                interviewerInfo.id
                                                            )
                                                                " data-toggle="modal" data-target="#RejectRequest"><i
                                                                    class="ti-close" style="
                                                                        color: red;
                                                                    "></i><span style="
                                                                        color: red;
                                                                        padding-right: 10px;
                                                                        font-family: sans-serif;
                                                                    ">
                                                                    Reject</span></a>
                                                        </span>
                                                        <span class="job-link float-right pt-0 pb-0">
                                                            <a title="Accept" href="javascript:void(0);" @click="
                                                                setInterviewId(
                                                                    interviewerInfo.id
                                                                )
                                                                " data-toggle="modal" data-target="#AcceptRequest"><i
                                                                    class="ti-check" style="
                                                                        color: green;
                                                                    "></i><span class="pr-10" style="
                                                                        color: green;
                                                                        font-family: sans-serif;
                                                                    ">Accept</span></a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Referral" class="scroll-down-inbox">
                                        <div class="card-media" v-if="referralsList.length == 0">
                                            <div class="col-md-12 pt-4 pb-4" style="
                                                    text-align: center;
                                                    background-color: #ffff;
                                                ">
                                                No records found
                                            </div>
                                        </div>
                                        <div class="card-media" v-for="(
                                                referralInfo, index
                                            ) in referralsList">
                                            <div class="card-media-body">
                                                <div class="row">
                                                    <div class="col-sm-10">
                                                        <router-link :to="{
                                                            name: 'viewreferral',
                                                            params: {
                                                                referralid:
                                                                    referralInfo.id,
                                                                verified:
                                                                    referralInfo.verified,
                                                            },
                                                        }">
                                                            <span class="card-media-body-heading job-link pl-0">{{
                                                                referralInfo.referralName
                                                            }}</span>
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <span style="
                                                            margin-left: 15px;
                                                        ">
                                                        Email :
                                                        {{ referralInfo.email }}
                                                    </span>
                                                </div>
                                                <div class="row" v-if="referralInfo.phoneNo !=
                                                    null &&
                                                    referralInfo.phoneNo
                                                        .length > 0
                                                    ">
                                                    <span style="
                                                            margin-left: 15px;
                                                        ">
                                                        Phone :
                                                        {{
                                                            referralInfo.phoneNo
                                                        }}
                                                    </span>
                                                </div>
                                                <div class="row" v-if="referralInfo.position !=
                                                        null &&
                                                        referralInfo.position
                                                            .length > 0
                                                        ">
                                                    <span style="
                                                            margin-left: 15px;
                                                        ">
                                                        Position :
                                                        {{
                                                            referralInfo.position
                                                        }}
                                                    </span>
                                                </div>
                                                <div class="card-media-body-top">
                                                    <div class="card-media-body-top u-float-right w-100 text-right hrline"
                                                        style="margin-top: 8px">
                                                        <span class="job-link float-left pt-0 pb-0">
                                                            <a title="Reject" href="javascript:void(0);" @click="
                                                            setReferralId(
                                                                referralInfo.id
                                                            )
                                                                " data-toggle="modal"
                                                                data-target="#ReferralRejectRequest"><i class="ti-close"
                                                                    style="
                                                                        color: red;
                                                                    "></i><span style="
                                                                        color: red;
                                                                        padding-right: 10px;
                                                                        font-family: sans-serif;
                                                                    ">
                                                                    Reject</span></a>
                                                        </span>
                                                        <span class="job-link float-right pt-0 pb-0">
                                                            <a title="Accept" href="javascript:void(0);" @click="
                                                                setReferralId(
                                                                    referralInfo.id
                                                                )
                                                                " data-toggle="modal"
                                                                data-target="#ReferralAcceptRequest"><i class="ti-check"
                                                                    style="
                                                                        color: green;
                                                                    "></i><span class="pr-10" style="
                                                                        color: green;
                                                                        font-family: sans-serif;
                                                                    ">Accept</span></a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Task" class="scroll-down-inbox">
                                        <div class="card-media" v-if="tasklist.length == 0">
                                            <div class="col-md-12 pt-4 pb-4" style="
                                                    text-align: center;
                                                    background-color: #ffff;
                                                ">
                                                No records found
                                            </div>
                                        </div>
                                        <div class="card-media" v-for="(
                                                taskinfo, index
                                            ) in tasklist">
                                            <div class="card-media-body">
                                                <div class="row">
                                                    <div class="col-md-10 col-sm-10">
                                                        <router-link :title="activityTooltip
                                                            " :to="{
        name: 'viewtask',
        params: {
            taskid: taskinfo.id,
        },
    }" role="button" data-toggle="dropdown"
                                                            aria-haspopup="true" aria-expanded="false">
                                                            <a href="javascript:void(0);" class="hover-hand">
                                                                <h6 title="Title" style="
                                                                        color: #2980c9;
                                                                    ">
                                                                    {{
                                                                        taskinfo.title
                                                                    }}<span v-if="checkEmpty(
            taskinfo.taskCode
        )
        ">
                                                                        - [{{
                                                                            taskinfo.taskCode
                                                                        }}]</span>
                                                                </h6>
                                                            </a>
                                                        </router-link>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 mb-2">
                                                        <span class="float-left">{{
                                                            taskinfo.taskType
                                                        }}</span>
                                                        <span class="float-right">{{
                                                            taskinfo.priority
                                                        }}</span>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 mb-2">
                                                        <span v-if="taskinfo.startDate
                                                                " class="completed-date flex-fill"><i title="Start date"
                                                                class="ti-time mr-2"></i>{{
                                                                    taskinfo.startDate
                                                                    | formatDate
                                                                }}</span>
                                                        <span v-if="taskinfo.dueDate >
                                                                currentDate
                                                                " class="float-right"><i title="Due date"
                                                                class="ti-time mr-2"></i>{{
                                                                    taskinfo.dueDate
                                                                    | formatDate
                                                                }}
                                                        </span>
                                                        <span v-if="taskinfo.dueDate <=
                                                                currentDate
                                                                " class="float-right text-danger"><i title="Due date"
                                                                class="ti-time mr-2"></i>{{
                                                                    taskinfo.dueDate
                                                                    | formatDate
                                                                }}</span>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 mb-2">
                                                        <span class="float-left">
                                                            <span title="Assigned by" v-if="taskinfo.assignedByName
                                                                    ">{{
            taskinfo.assignedByName
        }}</span>
                                                        </span>
                                                        <span class="float-right">
                                                            <span title="Assigned to" v-if="taskinfo.recruiterName
                                                                    ">{{
            taskinfo.recruiterName
        }}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                        <div class="col-sm-9 p-0 pl-2 pr-4">
                            <div class="row">
                                <div class="col-md-12 col-xl-3">
                                    <div class="card">
                                        <div class="p-0 card-body">
                                            <div class="p-3 media">
                                                <div class="media-body">
                                                    <span
                                                        class="text-muted text-uppercase font-size-12 font-weight-bold">Candidates</span>
                                                    <h2 class="text-black mb-0">
                                                        {{
                                                            recruiter.candidates
                                                        }}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xl-3">
                                    <div class="card">
                                        <div class="p-0 card-body">
                                            <div class="p-3 media">
                                                <div class="media-body">
                                                    <span
                                                        class="text-muted text-uppercase font-size-12 font-weight-bold">Interviews</span>
                                                    <h2 class="text-black mb-0">
                                                        {{
                                                            recruiter.interviews
                                                        }}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xl-3">
                                    <div class="card">
                                        <div class="p-0 card-body">
                                            <div class="p-3 media">
                                                <div class="media-body">
                                                    <span
                                                        class="text-muted text-uppercase font-size-12 font-weight-bold">Shortlisted</span>
                                                    <h2 class="text-black mb-0">
                                                        {{
                                                            recruiter.shortlisted
                                                        }}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xl-3">
                                    <div class="card">
                                        <div class="p-0 card-body">
                                            <div class="p-3 media">
                                                <div class="media-body">
                                                    <span
                                                        class="text-muted text-uppercase font-size-12 font-weight-bold">Hired</span>
                                                    <h2 class="text-black mb-0">
                                                        {{ recruiter.hired }}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-xl-6">
                                    <h5>
                                        <span class="dashboard-rec-chart">Recruitment Chart</span>
                                    </h5>
                                    <div v-if="isShow == true"
                                        class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                                        <GChart type="PieChart" :settings="{
                                            packages: ['corechart'],
                                        }" :data="pieChartData" :options="pieChartOptions" />
                                    </div>
                                </div>
                                <div class="col-md-12 col-xl-6">
                                    <h5>
                                        <span class="dashboard-rec-chart">Recruiter Performance</span>
                                    </h5>
                                    <div v-if="isShow == true"
                                        class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                                        <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
        <div class="modal" id="RejectRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                            Interviewer confirmation
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to reject this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="interviewConfirmation('reject')" class="btn btn-primary"
                            data-dismiss="modal">
                            Yes
                        </button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            No
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="AcceptRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                            Interviewer confirmation
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to accept this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="interviewConfirmation('approve')" class="btn btn-primary"
                            data-dismiss="modal">
                            Yes
                        </button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            No
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="ReferralRejectRequest" tabindex="-1" role="dialog" aria-labelledby="ReferralRejectRequest"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                            Referral confirmation
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to reject this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="referralConfirmation('reject')" class="btn btn-primary"
                            data-dismiss="modal">
                            Yes
                        </button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            No
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="ReferralAcceptRequest" tabindex="-1" role="dialog" aria-labelledby="ReferralAcceptRequest"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                            Referral confirmation
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to accept this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="referralConfirmation('approve')" class="btn btn-primary"
                            data-dismiss="modal">
                            Yes
                        </button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            No
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="RejectJob" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                            Job confirmation
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to reject this job?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="jobConfirmation('reject')" class="btn btn-primary"
                            data-dismiss="modal">
                            Yes
                        </button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            No
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="AcceptJob" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                            Job confirmation
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to accept this job?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="jobConfirmation('approve')" class="btn btn-primary"
                            data-dismiss="modal">
                            Yes
                        </button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            No
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="RejectCandidate" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                            Candidate confirmation
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to reject this candidate?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="candidateConfirmation('reject')" class="btn btn-primary"
                            data-dismiss="modal">
                            Yes
                        </button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            No
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="AcceptCandidate" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                            Candidate confirmation
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to accept this candidate?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="candidateConfirmation('approve')" class="btn btn-primary"
                            data-dismiss="modal">
                            Yes
                        </button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            No
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="tool-demo" size="lg" title="Tool Demo" :hide-footer="true">
            <b-embed type="video" aspect="16by9" controls allowfullscreen>
                <source src="@/assets/videos/tool-demo/AddCandidate.mp4" type="video/mp4" />
            </b-embed>
        </b-modal>
    </div>
</template>
<script>
import { GChart } from "vue-google-charts";
import iziToast from "izitoast";

export default {
    name: "app",
    components: {
        GChart,
    },
    props: {
        // screeninginfo:Object
    },
    computed: {
        recownerid() {
            return this.$store.getters.currentUser.recruitOwner;
        },
        recruiterType() {
            return this.$store.getters.currentUser.recruitType;
        },
    },
    data() {
        return {
            value: 100,
            max: 100,
            joblist: [],
            interviewersList: [],
            referralsList: [],
            candidatesList: [],
            // taskList:[],
            tasklist: [],
            browsepagination: this.$store.state.paginationconfig,
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            industrylist: this.$store.getters.getIndustries,
            clientlist: this.$store.getters.getClients,
            jobStatuslist: this.$store.getters.getJobStatus,
            activityTooltip: localStorage.getItem("taskActivity"),
            elements: {
                showadvancedsearch: false,
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    jobTitle: "",
                    jobType: "",
                    technologyId: "",
                    startDate: "",
                    endDate: "",
                    recruiterId: this.recruiterId,
                    clientId: "",
                    industryId: "",
                    status: "",
                },
            },
            coldisplay: {
                jobTitle: true,
                jobCode: true,
                client: true,
                industry: true,
                position: true,
            },
            delJobParam: {
                index: -1,
                id: 0,
            },
            count: {
                candidates: 0,
                jobs: 0,
                clients: 0,
                interviewers: 0,
                recruiters: 0,
                tasks: 0,
                referrals: 0,
            },
            recruiter: {
                candidates: 0,
                interviews: 0,
                shortlisted: 0,
                hired: 0,
            },
            labels: ["Talents Identified", "Shortlisted", "Hired"],
            subLabels: ["Direct", "Social Media", "Ads"],
            values: [120, 57, 9],
            colors: ["#37a0e4", "#898a8e"],
            colors1: ["#DA932C", "#66B92E", "#24A0ED", "#A9A9A9"],
            chartColors: ["#DA932C", "#66B92E", "#24A0ED"],

            direction: "vertical",
            gradientDirection: "vertical",
            height: 347,
            width: 300,
            // chartData: [
            //     ['Name', 'Hired'],
            //     ['Femila', 12],
            //     ['Nancy', 15],
            //     ['Jessina', 20],
            //     ['John', 30]
            // ],
            isShow: false,
            chartData: [],
            chartOptions: {
                //title: 'Interview [Month-wise]',
                subtitle: "Name, Hired",
                height: 350,
                //colors: ['#2298f1', '#898a8e'],
            },
            pieChartData: [],
            pieChartOptions: {
                //pieHole: 0.4,
                is3D: true,
                height: 350,
                legend: "bottom",
                slices: {
                    0: { offset: 0.01 },
                    1: { offset: 0.02 },
                    0: { offset: 0.04 },
                },
                // chartArea: {width: '50%'}
            },
            candContactAccess: localStorage.getItem("candidateContactAccess"),
            ttClientAccountId: localStorage.getItem("clientAccountId"),
            currentDate: new Date(),
        };
    },
    mounted() {
        this.$store.commit("setCurrentPage", 1);
        this.$store.commit("refreshPagination");
        this.loadInterviewersList();
        this.loadReferralsList();
        this.loadCandidatesList();
        this.loadJobs();
        //this.loadGraph();
        this.loadCount();
        this.loadRecruiterDetail();
        //this.loadYearlyRecruitments();
        this.loadTasks();
    },
    methods: {
        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },
        loadJobs() {
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/jobresource/inboxjoblist",
                    this.search.params
                )
                .then((response) => {
                    let result = response.data;
                    this.joblist = result.jobs;
                })
                .catch((error) => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        loadInterviewersList() {
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/interviewerresource/dashboard/interviewers/list?page=" +
                    this.browsepagination.currentpage +
                    "&size=" +
                    this.search.limit,
                    this.search.params
                )
                .then((response) => {
                    let result = response.data;
                    if (result.status)
                        this.interviewersList = result.interviewer;
                    // this.browsepagination.totalrecords = result.pagination[0].total;
                    // this.browsepagination.currentpage = result.pagination[0].currentPage;
                    // this.browsepagination.totalpages = result.pagination[0].lastPage;
                })
                .catch((error) => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        loadReferralsList() {
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/referralresource/dashboard/referral/list?page=" +
                    this.browsepagination.currentpage +
                    "&size=" +
                    this.search.limit,
                    this.search.params
                )
                .then((response) => {
                    let result = response.data;
                    if (result.status) this.referralsList = result.referrals;
                })
                .catch((error) => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        loadCandidatesList() {
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/candidateresource/dashboard/candidatesearch",
                    this.search.params
                )
                .then((response) => {
                    let result = response.data;
                    if (result.status) this.candidatesList = result.recordinfo;
                })
                .catch((error) => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        loadYearlyRecruitments() {
            var currentDate = new Date();
            var month = currentDate.getMonth();
            let data = { createdYear: "2019" };
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/recruitmentplanresource/yearly/recruitment/candidate",
                    data
                )
                .then((response) => {
                    let result = response.data;
                    this.values = result.recruitment;
                })
                .catch((error) => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        loadRecruitmentsByJob(jobId) {
            var currentDate = new Date();
            var month = currentDate.getMonth();
            let data = { jobId: jobId };
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/recruitmentplanresource/recruitment/job",
                    data
                )
                .then((response) => {
                    let result = result.jobs;
                    // this.browsepagination.totalrecords = result.pagination[0].total;
                    // this.browsepagination.currentpage = result.pagination[0].currentPage;
                    // this.browsepagination.totalpages = result.pagination[0].lastPage;se.data;
                    this.values = result.recruitment;
                })
                .catch((error) => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        setInterviewId(id) {
            this.interviewId = id;
        },
        setReferralId(id) {
            this.referralId = id;
        },
        setJobId(id) {
            this.jobId = id;
        },
        setCandidateId(id, referralId) {
            this.candidateId = id;
            if (referralId === undefined) {
                this.referralId = null;
            } else {
                this.referralId = referralId;
            }
        },
        interviewConfirmation(type) {
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/interviewerresource/interviewer/approval",
                    { id: this.interviewId, statusName: type }
                )
                .then((response) => {
                    let result = response.data;
                    if (result.status) {
                        iziToast.success({
                            title: this.$config.ConfirmReq,
                            message: result.message,
                            position: "topRight",
                        });
                        this.loadInterviewersList();
                    } else {
                        iziToast.error({
                            title: "Confirmation Request",
                            message: this.$config.ConfirmReqErr,
                            position: "topRight",
                        });
                    }
                });
            return true;
        },
        referralConfirmation(type) {
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/referralresource/referral/approval",
                    { id: this.referralId, statusName: type }
                )
                .then((response) => {
                    let result = response.data;
                    if (result.status) {
                        iziToast.success({
                            title: "Confirmation Request",
                            message: result.message,
                            position: "topRight",
                        });
                        this.loadReferralsList();
                    } else {
                        iziToast.error({
                            title: "Confirmation Request",
                            message: this.$config.ConfirmReqErr,
                            position: "topRight",
                        });
                    }
                });
            return true;
        },
        jobConfirmation(type) {
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/jobresource/job/approval",
                    { id: this.jobId, statusName: type }
                )
                .then((response) => {
                    let result = response.data;
                    if (result.status) {
                        iziToast.success({
                            title: "Confirmation Request",
                            message: result.message,
                            position: "topRight",
                        });
                        this.loadJobs();
                    } else {
                        iziToast.error({
                            title: "Confirmation Request",
                            message: this.$config.ConfirmReqErr,
                            position: "topRight",
                        });
                    }
                });
            return true;
        },
        candidateConfirmation(type) {
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/candidateresource/candidate/approval",
                    {
                        id: this.candidateId,
                        referralId: this.referralId,
                        statusName: type,
                    }
                )
                .then((response) => {
                    let result = response.data;
                    if (result.status) {
                        iziToast.success({
                            title: "Confirmation Request",
                            message: result.message,
                            position: "topRight",
                        });
                        this.loadCandidatesList();
                    } else {
                        iziToast.error({
                            title: "Confirmation Request",
                            message: this.$config.ConfirmReqErr,
                            position: "topRight",
                        });
                    }
                });
            return true;
        },
        loadGraph() {
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/recruiterresource/recruiter/performance"
                )
                .then((response) => {
                    let result = response.data;
                    this.chartData = result.recruiterPerformance;
                })
                .catch((error) => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        getSocialMediaShareInfo(title) {
            return (
                "Recruitment is going on for " +
                title +
                ". Click the link to Find Jobs and Get Hired"
            );
        },
        getSocialMediaHashTag(title, code) {
            let hashtag = title + "_" + code;
            /*let shareInfo = {
                title: title.replace(/[\s\/]/g, ''),
                description: description
            }*/
            return hashtag.replace(/[\s\/]/g, "");
        },
        loadCount: function () {
            axios
                .get(
                    this.$store.getters.getAPIBasePath +
                    "/dashboardresource/recmanager/dashboard"
                )
                .then((response) => {
                    let result = response.data;
                    if (result.status) {
                        this.count.candidates = result.candidates;
                        this.count.jobs = result.jobs;
                        this.count.clients = result.clients;
                        this.count.interviewers = result.interviewers;
                        this.count.recruiters = result.recruiters;
                        this.count.tasks = result.tasks;
                        this.count.referrals =
                            result.referrals == null ? 0 : result.referrals;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        loadRecruiterDetail: function () {
            axios
                .get(
                    this.$store.getters.getAPIBasePath +
                    "/dashboardresource/recmanager/dashboard/interviews/count"
                )
                .then((response) => {
                    let result = response.data;
                    if (result.status) {
                        this.recruiter.candidates = result.candidateCount;
                        this.recruiter.interviews = result.interviewsCount;
                        this.recruiter.shortlisted = result.shortlistedCount;
                        this.recruiter.hired = result.hiredCount;
                        this.values = result.totalRecruitment;
                        this.chartData = result.recruiterPerformance;
                        this.pieChartData = result.recruitmentData;
                        this.isShow = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        loadTasks() {
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/taskresource/inboxtasklist",
                    this.search.params
                )
                .then((response) => {
                    let result = response.data;
                    this.tasklist = result.tasks;
                })
                .catch((error) => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        viewTask: function (taskId, mode, model) {
            this.updateLogActivity();

            this.selected.mode = mode;
            axios
                .get(
                    this.$store.getters.getAPIBasePath +
                    "/taskresource/view/task/" +
                    taskId
                )
                .then((response) => {
                    if (response.data.status) {
                        this.editTask.id =
                            mode == "edit" ? response.data.task.id : null;
                        this.editTask.taskGroupId =
                            response.data.task.taskGroupId;
                        this.editTask.taskCode = response.data.task.taskCode;
                        this.selected.project = response.data.task.project;
                        if (this.selected.project != null)
                            this.getSprintList(this.selected.project);
                        this.selected.taskGroupId = this.editTask.taskGroupId;
                        this.editTask.statusId =
                            mode == "edit" ? response.data.task.statusId : null;
                        this.editTask.priorityId =
                            response.data.task.priorityId;
                        this.editTask.title = response.data.task.title;
                        this.editTask.description =
                            response.data.task.description;
                        this.editTask.progressPercentage =
                            response.data.task.progressPercentage != null
                                ? response.data.task.progressPercentage
                                : 0;
                        this.editTask.assignedTo =
                            mode == "edit"
                                ? response.data.task.assignedTo
                                : null;
                        this.editTask.userType = "recruiter";
                        this.editTask.taskType = response.data.task.taskType;
                        this.editTask.taskReference =
                            response.data.task.taskReference;
                        if (mode == "duplicate") {
                            this.addLogActivity("Task Duplicate");
                            this.editTask.startDate = new Date()
                                .toISOString()
                                .substr(0, 10);
                            this.editTask.dueDate = new Date()
                                .toISOString()
                                .substr(0, 10);
                        } else {
                            this.editTask.startDate = moment(
                                String(response.data.task.startDate)
                            ).format("YYYY-MM-DD");
                            this.editTask.dueDate = moment(
                                String(response.data.task.dueDate)
                            ).format("YYYY-MM-DD");
                        }
                        this.editTask.assignedByName =
                            response.data.task.assignedByName;
                        this.editTask.completedDate =
                            response.data.task.completedDate != null
                                ? moment(
                                    String(response.data.task.completedDate)
                                ).format("YYYY-MM-DD")
                                : "";
                        if (mode == "edit") {
                            this.addLogActivity("Edit");
                            this.getTaskCommentsByTaskId(this.editTask.id);
                        }
                        this.getTaskChecklistByTaskId(taskId);

                        if (model == "taskupdate") {
                            this.addLogActivity("Task Status Update");
                            this.showTaskUpdate = true;
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
};
</script>
