<template>
	<div class="recruiter-performance reports">
		<div class="row m-0">
			<div class="col-sm-12 p-10">
				<div id="recruiter-settings" class="recruiter settings mt-10">
					<div class="box-wrapper shadow-sm bg-white rounded pos-rel">
						<div class="basic-block">
							<h5 style="background-color: #37a0e4;color: #fff;padding: 5px;">Overall Recruiter Performance</h5>
							<div class="row col-md-12" style="padding-top: 10px; margin-left: 0px;">
								<div class="table-responsive">
									<table class="table table-borderless all-table">
										<thead>
											<tr>
												<th class="" scope="col" style="text-align:left; padding-left:20px;">Recruiter name</th>
												<th class="accountManager" scope="col">Hired</th>
												<th class="accountManager" scope="col">Hired per day</th>
												<th class="accountManager" scope="col">Time to Hired</th>
												<th class="accountManager" scope="col">Talent identified to Hire Ratio</th>
												<th class="accountManager" scope="col">Offer Acceptance</th>
												<th class="accountManager" scope="col">Quality of Hire</th>
											</tr>
										</thead>
										<tbody id="table-length">
											<tr v-if="recruiterList != null && recruiterList.length==0">
												<td colspan="7" align="center">No Recruiters found</td>
											</tr>
											<tr v-for="(recruiter, index) in recruiterList">
												<td class="accountManager" style="text-align:left; padding-left:20px;">{{ recruiter.recruiterName }}</td>
												<td class="accountManager">{{ recruiter.hired }}</td>
												<td class="accountManager">{{ recruiter.productivityPercentage }} </td>
												<td class="accountManager">{{ recruiter.avgHireTime }} hrs</td>
												<td class="accountManager">{{ recruiter.identifiedHireRatio }}</td>
												<td class="accountManager">{{ recruiter.acceptancePercentage }} %</td>
												<td class="accountManager">{{ recruiter.qualityOfHire  }}</td>
											</tr>
										</tbody>
									</table>
								</div>				
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { GChart } from "vue-google-charts";
export default {
	components: {
		GChart
	},
	data () {
		return {
			browsepagination: this.$store.state.paginationconfig,
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            recruiterList: [],
			chartData:[],
			chartOptions: {
				subtitle: 'Name, Hired',
				height:350
			}
		}
	},
	mounted() {		
		this.$emit('updateLogActivity');
		this.$emit('addLogActivity','Overall Recruiter Performance');
		this.loadGraph();
	},
	methods: {
		loadGraph() {
			// axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/recruiter/performance')
			axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/recruiter/overall/performance')
            .then(response => {
                let result = response.data;
				// this.chartData=result.recruiterPerformance;
				this.recruiterList=result.overallPerformanceChart;
            })
            .catch(error => {
                console.log(error);
            });
		},
	},

}
</script>