<template>
	<div>
        <div class="card">
            <div class="card-header p-2">Client Details</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                               <div class="col-md-12 col-sm-12 col-12 lb">
                                   <label>Client</label>
                               </div>
                               <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group  required-field-block">
                                        <input type="text" name="clientName" value="" id="clientName" class="Submitlookup form-control"> 
                                        <a href="javascript:;">
                                          <span class="search-addon" id="submitLookUp" style="display: inline;"><span class="ti-search srcicn"></span> </span>  
                                        </a>
                                        <div class="required-icon">
                                        </div>
                                    </div>
                               </div>
                            </div>
                        </div>

                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                   <label>Client Contact</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                  <div class="input-group  required-field-block">
                                      <input type="text" name="clientContact" value="" id="clientContact" class="Submitlookup form-control"> 
                                      <a href="javascript:;">
                                        <span class="search-addon" id="submitLookUp" style="display: inline;"><span class="ti-search srcicn"></span> </span>  
                                      </a>
                                      <div class="required-icon">
                                      </div>
                                  </div>
                                </div>
                            </div>  
                        </div>

                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                               <div class="col-md-12 col-sm-12 col-12 lb">
                                   <label>Account Manager</label>
                               </div>
                               <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group" >
                                        <input type="text" name="accountManager" maxlength="1024" value="" id="accountManager" class="form-control">
                                    </div>
                               </div>
                            </div>  
                        </div>
                        
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                               <div class="col-md-12 col-sm-12 col-12 lb">
                                   <label>Address</label>
                               </div>
                               <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group" >
                                        <input type="text" name="address" maxlength="1024" value="" id="address" class="form-control">
                                    </div>
                               </div>
                            </div>  
                        </div>

                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                               <div class="col-md-12 col-sm-12 col-12 lb">
                                   <label>City</label>
                               </div>
                               <div class="col-md-12 col-sm-12 col-12">
                                  <div class="input-group" >
                                      <input type="text" name="city" value="" id="city" class="Submitlookup form-control">
                                  </div>
                               </div>
                            </div>  
                        </div>

                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                               <div class="col-md-12 col-sm-12 col-12 lb">
                                   <label>State</label>
                               </div>
                               <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group" >
                                      <input type="text" name="state" id="state" maxlength="1024" value="" class="form-control">
                                    </div>
                               </div>
                            </div>  
                        </div>
                    </div>
                    <div class="row ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                   <label>Zip/Postal Code</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group" >
                                        <input type="text" name="postalCode" value=""  id="postalCode" class="Submitlookup form-control"> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                               <div class="col-md-12 col-sm-12 col-12 lb">
                                   <label>Country</label>
                               </div>
                               <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group" >
                                        <input type="text" name="country" id="country" maxlength="1024" value="" class="form-control">
                                    </div>
                               </div>
                            </div>  
                        </div>

                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                               <div class="col-md-12 col-sm-12 col-12 lb">
                                   <label>Region</label>
                               </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group" >
                                      <input type="text" name="region" value="" id="region" class="Submitlookup form-control"> 
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header p-2">Shift Details</div>
            <div class="card-body p-2">
              <div class="basic-block">
                <div class="row ten-columns">
                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                        <div class="row">
                           <div class="col-md-12 col-sm-12 col-12 lb">
                               <label>Shift Time</label>
                           </div>
                           <div class="col-md-12 col-sm-12 col-12">
                                <div class="input-group  required-field-block">
                                    <select class="select2">
                                      <option>All</option>
                                    </select>
                                    <div class="required-icon">
                                    </div>
                                </div>
                           </div>
                        </div>
                    </div>

                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-12 lb">
                               <label>Days Per Week</label>
                            </div>
                            <div class="col-md-12 col-sm-12 col-12">
                              <div class="input-group  required-field-block">
                                  <select class="select2">
                                    <option>All</option>
                                  </select>
                                  <div class="required-icon">
                                  </div>
                              </div>
                            </div>
                        </div>  
                    </div>

                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                        <div class="row">
                           <div class="col-md-12 col-sm-12 col-12 lb">
                               <label>Shift Length</label>
                           </div>
                           <div class="col-md-12 col-sm-12 col-12">
                              <div class="input-group  required-field-block">
                                  <select class="select2">
                                    <option>All</option>
                                  </select>
                                  <div class="required-icon">
                                  </div>
                              </div>
                           </div>
                        </div>  
                    </div>
                    
                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                        <div class="row">
                           <div class="col-md-12 col-sm-12 col-12 lb">
                               <label>Days/Nights</label>
                           </div>
                           <div class="col-md-12 col-sm-12 col-12">
                                <div class="input-group required-field-block" >
                                    <input type="text" name="address" maxlength="1024" value="" id="address" class="form-control">
                                    <div class="required-icon">
                                    </div>
                                </div>
                           </div>
                        </div>  
                    </div>

                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                        <div class="row">
                           <div class="col-md-12 col-sm-12 col-12 lb">
                               <label>Hours Per Week</label>
                           </div>
                           <div class="col-md-12 col-sm-12 col-12">
                              <div class="input-group" >
                                <input type="text" name="state" id="state" maxlength="1024" value="" class="form-control">
                              </div>
                           </div>
                        </div>  
                    </div>

                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                        <div class="row">
                           <div class="col-md-12 col-sm-12 col-12 lb">
                               <label>Additional Shift Info</label>
                           </div>
                           <div class="col-md-12 col-sm-12 col-12">
                              <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#specialityModal">ADD</button>
                           </div>
                        </div>  
                    </div>
                </div>
              </div>
            </div>
        </div>
	</div>
</template>

<script>
	export default {
		props: {
            clientinfo: Object,
        },
		data(){
			return {
			}	
		},
		methods: {
		
		}
	}
</script>