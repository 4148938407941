<template>
    <div class="card">
        <div class="card-header p-2">Interviews</div>
        <div class="card-body p-2">
            <div class="basic-block clone-preference">
                <div class="row ten-columns pos-relative clone filled2">
                    <div class="col-md-12 col-sm-12 col-12 mb-10-mr">
                      <div class="table-responsive">
                        <table class="table table-borderless all-table">
                            <thead>
                                <tr>
                                    <th class="" scope="col"></th>
                                    <th class="" scope="col">Recruiter</th>
                                    <th class="" scope="col">Start Date</th>
                                    <th class="" scope="col"> End Date </th>
                                    <th class="" scope="col">Status</th>
                                    <th class="">Action</th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr v-if="interviews.length==0">
                                    <td style="border:0px;" colspan="6" align="center">No interviews found
                                    </td>
                                </tr>
                                <template  v-for="(info, index) in interviews">
                                    <tr >
                                        <td>
                                            <a href="Javascript:void(0);" class="expand" data-targetopen="panel1">
                                                <span @click="getInterviews(info.id)"><i :class="(currentIndex==info.id) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
                                                </span>
                                            </a>
                                        </td>
                                        <td >
                                            {{interviews[index].assignedBy}}
                                        </td>
                                        <td >
                                            {{interviews[index].startDate | formatDate}}
                                        </td>
                                        <td >
                                            {{interviews[index].endDate | formatDate}}
                                        </td>
                                        <td >
                                            <span :class="getStatus(interviews[index].recruitmentStatus)" class="label text-white p-1 rounded w-50-auto">{{ interviews[index].recruitmentStatus }}</span>
                                        </td>
                                        <td>
                                            <a href="javascript:" @click="$emit('viewFeedback',info.id)" title="View Feedback"><i class="ti-comment-alt "></i></a>
                                        </td>
                                    </tr>

                                    <tr v-if="currentIndex==info.id">
                                        <td colspan="6" >                                      
                                            <div class="table-responsive">
                                              <table class="table table-borderless all-table">
                                                <thead>
                                                  <tr>
                                                    <th class="" scope="col">Interview Title</th>
                                                    <th class="" scope="col">Interview Date</th>
                                                    <th class="" scope="col"> Start Time</th>
                                                    <th class="" scope="col"> End Time</th>
                                                    <th class="" scope="col"> Status</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(Info, index) in interviewList">
                                                        <td>{{ Info.title }}</td>
                                                        <td>{{toClientTimeZoneDate(Info.showInterviewDate, dateformat) }}</td>
                                                        <td>{{toClientTimeZoneDate(Info.interviewStartTime, timeformat) }}</td>
                                                        <td>{{toClientTimeZoneDate(Info.interviewEndTime, timeformat) }}</td>
                                                        <td><span :class="Info.interviewStatus" class="label text-white p-1 rounded w-50-auto">{{ Info.interviewStatus }}</span></td>
                                                    </tr>
                                                    <tr v-if="interviewList.length==0">
                                                        <td style="border:0px;" colspan="5" align="center">No interviews found
                                                        </td>
                                                    </tr>
                                                </tbody>
                                              </table>
                                            </div>                                           
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
    import iziToast from 'izitoast'
    export default {
        props: {
             interviews: Array,
        },
        computed:{
          
        },
        data() {
            return {
                currentIndex:null, 
                interviewList:[],
                rec_interviews:[],
                feedback:true,
                dateformat:this.$store.state.dateformat,
                timeformat:this.$store.state.timeformat
            }
        },
        methods:{
            getStatus(name){

                if (name.toLowerCase() =="in progress"){
                    name="Inprogress";
                }
            return name;
            },
            getInterviews(id) {                
                if(this.currentIndex == id) {
                    this.currentIndex = null ;
                }                
                else {
                    this.currentIndex = id ;
                }

                axios.get(this.$store.getters.getAPIBasePath+'/interviewerresource/candidate/interviews/'+id)
                .then(response => {
                    let result = response.data;
                    this.interviewList = result.interviews;
                })
                .catch(error => {
                    console.log(error);
                });

            },
            /*viewFeedback(recruitmentId){
                console.log(recruitmentId)
                axios.get(this.$store.getters.getAPIBasePath+'/interviewresource/view/feedbacks/'+recruitmentId).then(response =>{
                    console.log(response.data)
                    if(response.data.status){
                        this.rec_interviews=response.data.interviews
                        this.feedback=true
                    }
                    else{
                        this.feedback=false
                        this.rec_interviews=[]
                        let errmsg = (response.data.message!=undefined)?response.data.message:'No feedback found';
                        iziToast.error({title: 'Error', message: errmsg, position:'topRight'});
                        // this.getPrevUrl()
                    }

                })
                .catch(error => {
                    this.feedback=false
                    this.rec_interviews=[]
                    let errmsg = 'Something went wrong while loading... Please try again !!';
                    iziToast.error({title: 'Error', message: errmsg, position:'topRight'});
                    // this.getPrevUrl()
                });
            },*/
            getPrevUrl(){
                $('#RecruitmentFeedback').modal('hide');
                if(this.$route.params.recruitmentId)
                    this.$router.push('/progress-interviews')
            }
        }
    }
</script>