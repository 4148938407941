<template>
    <div id="main" class="mt-80 d-height">
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :class="isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <form id="main-search" :class="isExpand?'d-block':'d-none'">
                        <div class="row form-group ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Referral Code</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" v-on:keyup.enter="findReferrals" v-model="params.referralCode" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" v-on:keyup.enter="findReferrals" v-model="params.name" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Position</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" v-on:keyup.enter="findReferrals" v-model="params.position" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Industry</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select class="filter form-control" v-model="params.industryId">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in industries" :value="info.id">{{info.industryName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="email" v-on:keyup.enter="findReferrals" v-model="params.email" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Phone</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" v-on:keyup.enter="findReferrals" v-model="params.mobileNo" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Status</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select class="filter form-control" v-model="params.status">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in referralStatus" :value="info.id">{{info.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-10 col-sm-6 col-12 mb-10-mr pl-0">
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr pl-0">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;">End Date</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12 float-right">
                                        <button type="button" class="btn btn-primary float-right" @click="resetFilter" style="margin-left: 10px;">Reset</button>
                                        <button type="button" class="btn btn-primary float-right" @click="findReferrals" style="margin-left: 10px;">Find</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="container-fluid" id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-6 col-sm-12 col-12">
                            <h4 class="page-title">Referrals
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                    <div class="dropdown">
                                        <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                        <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                            <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                        </div>
                                    </div>
                                </span>
                            </h4>
                        </div>
                        <div class="col-md-6 d-none d-sm-none d-md-block">
                            <button type="button" @click="addLogActivity()" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">
                                <router-link :to="{ name: 'addreferral' }" style="color: #fff;">New Referral</router-link>
                            </button>
                        </div>
                    </div>
                    <div class="row pt-20 mt-20">
                        <div class="col-sm-12">
                            <table class="table table-borderless all-table">
                                <thead>
                                    <tr>
                                        <th>Referral Code</th>
                                        <th>Name</th>
                                        <th>Position</th>
                                        <th>Industry</th>
                                        <th>Email</th>
                                        <th>Phone No.</th>
                                        <th>Invited</th>
                                        <th>Registered</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="!referralsList.length && elements.loadingReferral == true">
                                        <td class="text-center" colspan="100%">No Referrals found</td>
                                    </tr>
                                    <tr v-for="referral in referralsList">
                                        <td>
                                            <router-link :to="{ name:'viewreferral', params:{referralid: referral.id}}">
                                                {{referral.referralCode}}</router-link>
                                        </td>
                                        <td>
                                            <router-link :to="{ name:'viewreferral', params:{referralid: referral.id}}">{{referral.referralName}}</router-link>
                                        </td>
                                        <td>{{referral.position}}</td>
                                        <td>{{referral.industryName}}</td>
                                        <td>{{referral.email}}</td>
                                        <td>{{referral.phoneNo}}</td>
                                        <td>{{referral.inviteesCount}}</td>
                                        <td>{{referral.registeredCount}}</td>
                                        <td>{{referral.statusName}}</td>
                                        <td>
                                            <ul class="navbar-nav">
                                                <li class="nav-item dropdown">
                                                    <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="javascript:" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                        <li>
                                                            <router-link class="dropdown-item" :to="{ name: 'viewreferral', params: { referralid: referral.id }}"><span class="ti-eye pr-2"></span> View</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link class="dropdown-item" :to="{ name: 'editreferral', params: { referralid: referral.id }}"><span class="ti-pencil pr-2"></span> Edit</router-link>
                                                        </li>
                                                        <li><a class="dropdown-item" href="javascript:" @click="setReferralId(referral.id)" data-toggle="modal" data-target="#removeReferral"><span class="ti-close pr-2"></span> Deactivate</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <b-pagination v-if="referralsList.length!=0" size="md" class='pull-right' :current-page="pagination.currentPage" :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadReferralList()"></b-pagination>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div>
                    <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
                </div>                   
            </template> 
        </b-overlay>
        <Dialog id="removeReferral" :onYes="deleteReferral" :returnParams="delReferralParam" title="Deactivate Confirmation" message="Are you sure to deactivate?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    data() {
        return {
            showOverlay:false,
            elements: {
                approvalParams: {
                    id: null,
                    approved: 0,
                    statusName: ""
                },
                loadingReferral:false
            },
            isExpand: false,
            searchlimit: this.$store.state.searchlimit,
            industries: this.$store.getters.getIndustries,
            referralStatus: [],
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            edit_mode: true,
            referralsList: [],
            params: {
                referralCode: "",
                name: "",
                position: "",
                industryId: "",
                email: "",
                mobileNo: "",
                status: ""
            },
            delReferralParam: {
                index: -1,
                id: 0
            },
        }
    },
    computed: {
        candidateId() {
            return this.$route.params.candidateid
        }
    },
    watch: {
        'showOverlay': {
            handler: function(value) { 
                if(value == true){
                    $('#globalapploader').css('visibility','hidden');
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.loadReferralList();
        this.loadStatusList();
    },
    methods: {
       async loadReferralList() {
            this.showOverlay=true;
            await this.getReferralList();
            this.showOverlay=false;
        }, 
        getReferralList() {
            this.showOverlay=true;
            //  const specialChars = /[()-]/;
        //     if(!(specialChars.test(this.params.mobileNo))){
        //         if(this.params.mobileNo.startsWith("+1")){
        //             this.params.mobileNo=this.params.mobileNo.replace(/\s+/g,'');
        //             this.params.mobileNo=this.params.mobileNo.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2)-$3-$4")
        //         }
        //         else if(this.params.mobileNo.startsWith("+91")&&!(specialChars.test(this.params.mobileNo))){
        //             this.params.mobileNo=this.params.mobileNo.replace(/\s+/g,'');
        //             this.params.mobileNo=this.params.mobileNo.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1 ($2)-$3-$4")
        //         }
        //         else 
        //             this.params.mobileNo=this.params.mobileNo.replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3"); 
        //     }
        //    else
        //     this.params.mobileNo=this.params.mobileNo

            return axios.post(this.$store.getters.getAPIBasePath + '/referralresource/list/referrals?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.params)
                .then(response => {
                    let result = response.data;
                    if (result.status)

                    this.referralsList = result.referrals;
                    this.pagination.totalRecords = result.pagination[0].total;
                    this.pagination.currentPage = result.pagination[0].currentPage;
                    this.elements.loadingReferral = true;
                        // this.referralsList = result.recordinfo;
                        // this.pagination.totalRecords = result.totalrecords;
                        // this.pagination.currentPage = result.currentpage;
                })
                .catch(e => {
                    this.showOverlay=false;
                    console.log(e);
                });
        },
        loadStatusList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/referral/status/list')
                .then(response => {
                    let result = response.data;
                    if (result.status)
                        this.referralStatus = result.referralStatus;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        resetFilter() {
            this.params.name = "";
            this.params.referralCode = "";
            this.params.email = "";
            this.params.position = "";
            this.params.industryId = "";
            this.params.mobileNo = "";
            this.loadReferralList();
        },
        findReferrals() {
            this.pagination.currentPage = 1;
            this.loadReferralList();
        },
        changeLimit(newlimit) {
            this.pagination.currentPage = 1;
            this.pagination.rowsPerPage = newlimit;
            this.loadReferralList();
        },
        findReferrals() {
            this.pagination.currentPage = 1;
            this.loadReferralList();
        },
        setReferralId: function(referralId) { this.delReferralParam.id = referralId; },
        deleteReferral: function() {
            var referralId = this.delReferralParam.id;
            axios.post(this.$store.getters.getAPIBasePath + '/referralresource/delete/referral/' + referralId)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadReferralList();
                    }
                })
                .catch(error => {
                    console.log(error.data);
                    this.unAuthorized(error);
                });
        },
        addLogActivity: function() {
            this.updateLogActivity();
            this.logActivityData = {
                entityName: 'Referral',
                actionName: 'New Referral',
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {})
                .catch(error => {});
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {});
        },
    }
}

</script>
