<template>
    <div class="kpis reports text-left">
        <div class="row">
            <div class="col-sm-12 p-10">
                <div id="recruiter-settings" class="recruiter settings mt-10">
                    <div class="container-fluid" id="quick-search">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="text-center">Job Details</h5>
                            </div>
                        </div>
                        <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <h4 class="page-title">Filter</h4>
                                </div>
                                <div class="col-md-6 col-sm-6 col-6">
                                    <ul class="ul-horizontal box-tools text-right mb-0">
                                        <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :title="isExpand?'Hide':'Expand'" :class="isExpand?'ti-angle-up':'ti-angle-down'" class="hover-hand"></span></a></li>
                                        <li><i class="ti-close hover-hand px-2" @click="$emit('setSubTab',0);$emit('updateLogActivity');" title="Close"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <form id="main-search" :class="isExpand?'d-block':'d-none'">
                                <div class="row form-group ten-columns">
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Job Title</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" name="jobTitle" maxlength="50" value="" id="jobTitle" class="form-control"  v-on:keyup.enter="findJobs()" v-model="search.params.jobTitle">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Job Code</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text"  v-on:keyup.enter="findJobs()" v-model="search.params.jobCode" name="jobId" maxlength="25" value="" id="jobId" class="smallCombo form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Client</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <select v-model="search.params.clientId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(data, index) in clientlist" :value="data.id">{{data.client_Name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Industry</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <select v-model="search.params.industryId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(data, index) in industrylist" :value="data.id">{{data.industryName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <!--  <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Position</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" v-model="search.params.position" name="jobId" maxlength="100" value="" id="jobId" class="smallCombo form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Status</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <select v-model="search.params.status" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(data, index) in jobStatuslist" :value="data.id">{{data.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group ten-columns">
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Start Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledStartDates" placeholder="Start Date" name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.startDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>End Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledendDates" placeholder="End Date" name="endDate" value="" id="endDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.endDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label style="visibility: hidden;">Technology</label>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="button" @click="resetJobSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                                <button type="button" @click="findJobs" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Go</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                </div>
                            </form>
                            <div class="load-indicator"></div>
                        </div>
                    </div>
                    <div class="container-fluid" id="main-data">
                        <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                            <div class="row" style="margin-bottom: 15px;">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <h4 class="page-title">Jobs
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                            <div class="dropdown">
                                                <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                                <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                    <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                                </div>
                                            </div>
                                        </span>
                                    </h4>
                                </div>
                                <div class="col-md-6 d-none d-sm-none d-md-block">
                                    <span class="float-right" @click="exportJobs()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive" style="min-height: 178px;">
                                        <table class="table table-borderless all-table">
                                            <thead>
                                                <tr>
                                                    <th class="" scope="col">Sl.No</th>
                                                    <th v-if="coldisplay.jobCode" class="" scope="col">Job Code</th>
                                                    <th v-if="coldisplay.jobTitle" class="" scope="col">Job Title</th>
                                                    <th class="" scope="col">Location</th>
                                                    <!-- <th v-if="coldisplay.client" class="" scope="col">Client</th> -->
                                                    <th v-if="coldisplay.industry" class="" scope="col">Industry</th>
                                                    <th v-if="coldisplay.position" class="" scope="col">Position</th>
                                                    <th class="" scope="col">Technology/skill</th>
                                                    <th class="" scope="col">Exp</th>
                                                    <th class="" scope="col">Vacancies</th>
                                                    <th class="" scope="col">Created Date</th>
                                                    <th class="" scope="col">Due Date</th>
                                                    <th class="" scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="joblist.length==0">
                                                    <td colspan="26" class="text-center">No data found</td>
                                                </tr>
                                                <tr v-for="(jobinfo, index) in joblist">
                                                    <td>{{((pagination.currentPage-1) * (pagination.rowsPerPage)) + (index+1)}}</td>
                                                    <td v-if="coldisplay.jobCode" class="">{{ jobinfo.jobCode }}</td>
                                                    <td v-if="coldisplay.jobTitle" class="">
                                                        {{jobinfo.jobTitle }}
                                                    </td>
                                                    <td class="">{{ jobinfo.location }}</td>
                                                    <!-- <td v-if="coldisplay.client" class="">{{ jobinfo.clientName }}</td> -->
                                                    <td v-if="coldisplay.industry" class="">{{ jobinfo.industryName }}</td>
                                                    <td v-if="coldisplay.position" class="">{{ jobinfo.position }}</td>
                                                    <td class="">{{ jobinfo.technology }}</td>
                                                    <td class="">{{ jobinfo.experience }} <span v-if="checkEmpty(jobinfo.experience)"> yrs </span></td>
                                                    <td class="">{{ jobinfo.vacancies }}</td>
                                                    <td class="endDate" format="MM-dd-yyyy">{{ jobinfo.createdDate | formatDate }}</td>
                                                    <td class="endDate" format="MM-dd-yyyy">{{ jobinfo.dueDate | formatDate }}</td>
                                                    <td class="">{{ jobinfo.jobStatus}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <b-pagination v-if="joblist.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadJobs()"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {

    data() {
        return {
            isExpand: false,
            joblist: [],
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            industrylist: this.$store.getters.getIndustries,
            clientlist: this.$store.getters.getClients,
            jobStatuslist: this.$store.getters.getJobStatus,
            recruiterlist: this.$store.getters.getRecruiterList,

            elements: {
                showadvancedsearch: false,
            },
            search: {
                params: {
                    jobTitle: "",
                    jobType: "",
                    technologyId: "",
                    startDate: "",
                    endDate: "",
                    clientId: "",
                    industryId: "",
                    status: "",
                    recId: "",
                    startDate: "",
                    endDate: "",
                    pageNo: "",
                    limit: ""
                },
            },
            coldisplay: {
                jobTitle: true,
                jobCode: true,
                client: true,
                industry: true,
                position: false
            },
            disabledStartDates: {
                from: new Date()
            },
            disabledendDates:{
                from: new Date(),
                to: ''
            }
        }
    },
    mounted() {
        this.$store.commit('refreshPagination');
        this.loadDate();
        this.loadJobs();
        this.$emit('updateLogActivity');
        this.$emit('addLogActivity','Job List Report'); 
    },
    watch: {
        'search.params.startDate': {
            handler: function(value) {
                this.disabledendDates.to = new Date(value);
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        resetJobSearch() {
            this.search.params = {
                jobTitle: "",
                jobType: "",
                technologyId: "",
                recruiterId: this.recruiterId,
                clientId: "",
                industryId: "",
                status: "",
                recId: "",
                startDate: "",
                endDate: "",
                pageNo: "",
                limit: ""
            };
            this.loadDate();
            this.disabledStartDates.from =  new Date();
            this.disabledendDates.from =  new Date();
            this.loadJobs();
        },
        loadDate() {
            let date = new Date();
            this.search.params.startDate = date; //new Date(date.getFullYear(), date.getMonth(), 1);
            this.search.params.endDate = date;
        },
        loadJobs() {
            if (this.search.params.startDate > this.search.params.endDate) {
                iziToast.error({
                    title: 'Error',
                    message: this.$config.DateMismatchErr,
                    position: 'topRight'
                });
                return;
            }
            this.search.params.pageNo = this.pagination.currentPage;
            this.search.params.limit = this.pagination.rowsPerPage;
            axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/jobreport', this.search.params)
            //axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/jobreport?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    //this.joblist = result.jobs;
                    this.joblist = result.data;
                    //this.pagination.totalRecords = result.pagination[0].total;
                    //this.pagination.currentPage = result.pagination[0].currentPage;
                    this.pagination.totalRecords = result.totalRecords;
                    this.pagination.currentPage = result.currentPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        changeLimit(newlimit) {
            this.pagination.currentPage=1;
            this.pagination.rowsPerPage = newlimit;
            this.loadJobs();
        },
        exportJobs() {
            this.search.params.pageNo = this.pagination.currentPage;
            this.search.params.limit = this.pagination.rowsPerPage;
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/jobs' , this.search.params, { responseType: 'arraybuffer' })
            //axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/jobs?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "Jobs" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });

        },
        findJobs() {
            this.pagination.currentPage = 1;
            this.loadJobs();

        },
        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        }
    }
}

</script>
