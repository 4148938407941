<template>
    <div class="">
        <div class="card">
            <div class="card-header p-2">View Plan</div>
            <div class="card-body p-2">
                <div class="basic-block col-md-4">
                    <div class="table-responsive">
                           <table class="table table-striped table-borderless s_table compare-table">
                            <tbody>
                                <tr>
                                    <td width="50%"><strong>Plan Name</strong></td>
                                    <td width="50%">{{planInfo.planName}}</td>
                                </tr>
                                <!-- <tr v-if="planInfo.viewResumeCount!== null && planInfo.planName == 'Basic'">
                                    <td width="50%"><strong>Remaining View Resume Count</strong></td>
                                    <td width="50%">{{planInfo.viewResumeCount}}</td>
                                </tr> -->
                                <tr v-if="planInfo.downloadResumeCount!== null">
                                    <td width="50%"><strong>Remaining Download Resume Count</strong></td>
                                    <td width="50%">{{planInfo.downloadResumeCount}}</td>
                                </tr>
                                <!-- <tr v-if="planInfo.shareResumeCount!== null && planInfo.planName != 'Basic'">
                                    <td width="50%"><strong>Remaining Share Resume Count</strong></td>
                                    <td width="50%">{{planInfo.shareResumeCount}}</td>
                                </tr> -->
                                <tr v-if="planInfo.expiryDate!== null ">
                                    <td width="50%"><strong>Plan Will Expire On</strong></td>
                                    <td width="50%">{{planInfo.expiryDate}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import Select2 from 'v-select2-component';
import iziToast from 'izitoast';
const usPhone = helpers.regex('usPhone', /^\(\d{3}\)\s\d{3}-\d{4}$/);

export default {
    computed: {
        accountid() {
            return this.$route.params.accountid;
        }
    },
    components: {
        Select2
    },
    data() {
        return {
            elements: {
                submitted: false
            },
            countrieslist: this.$store.getters.getCountries,
            usstates: this.$store.getters.getUSStates,
            timeZones: this.$store.getters.getTimeZones,
            accountType: this.$store.getters.getAccountTypeOptions,
            planInfo: {
                viewResumeCount: "",
                downloadResumeCount: "",
                shareResumeCount: "",
                expiryDate:"",
                planName: ""
            },
        }
    },
    mounted() {
        this.loadByAccountId();
    },
    methods: {
        formatUsPhone: function(evt, idx) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                var curchr = $(idx).val().length;
                var curval = $(idx).val();
                if (curchr == 3 && curval.indexOf("(") <= -1) {
                    $(idx).val("(" + curval + ")" + " ");
                } else if (curchr == 4 && curval.indexOf("(") > -1) {
                    $(idx).val(curval + ")-");
                } else if (curchr == 5 && curval.indexOf(")") > -1) {
                    $(idx).val(curval + "-");
                } else if (curchr == 9) {
                    $(idx).val(curval + "-");
                    $(idx).attr('maxlength', '14');
                }
                return true;
            }
        },
        getCountryName: function(countryCode) {
            if (!countryCode)
                return "";
            let country = this.countrieslist.filter(obj => {
                return obj.country_Id === countryCode;
            });
            return country[0].name;
        },
        getStateName: function(stateId) {
            if (!stateId)
                return "";
            let state = this.usstates.filter(obj => {
                return obj.id === stateId;
            });
            return state[0].stateName;
        },
        loadByAccountId() {
            axios.get(this.$store.getters.getAPIBasePath + '/recruiterresource/client/account/view/' + this.accountid)
                .then(response => {
                    let result = response.data;
                    console.log(result);
                    if (!result.status) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$router.push('/candidates');
                    }
                    if (result.clientAccount) {
                        this.planInfo = {
                            viewResumeCount:  result.clientAccount.viewResumeCount,
                            downloadResumeCount:  result.clientAccount.downloadResumeCount,
                            shareResumeCount:  result.clientAccount.shareResumeCount,
                            planName: result.clientAccount.planName,
                            expiryDate: result.clientAccount.expiryDate,
                        }
                    }
                    //this.clientAccount = result.clientAccount;
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
