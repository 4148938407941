<template>
	<div id="content_Personal_Info">
		<div class="card">
			<div class="card-header p-2">Contact Information </div>
			<div class="card-body p-2">
				<div class="basic-block">
					<div class="row ten-columns">
						<div class="col col1 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Mobile</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<PhoneNumber ref="phoneNumber" :value="ContactInfo.mobileNo"  @phone-input="phoneControlInput" :isValidcheck="isValidCheck"/>
									<!-- <div v-if="false" class="input-group">
										<select class="form-control col-sm-3" v-model="ContactInfo.countryCode">
											<option value="+1">+1</option>
											<option value="+91">+91</option>
										</select>
										<input-mask class="form-control" v-model="ContactInfo.mobile" mask="(999)-999-9999" :alwaysShowMask="false" maskChar="_"></input-mask>
									</div> -->
								</div>
							</div>
						</div>
						<div class="col col2 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Alternate Mobile</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<PhoneNumber ref="altPhoneNumber" :value="ContactInfo.altMobileNo"  @phone-input="altPhoneControlInput"  :isValidcheck="isaltValidCheck"/>
									<!-- <div v-if="false" class="input-group">
										<select class="form-control col-sm-3" v-model="ContactInfo.altcountryCode">
											<option value="+1">+1</option>
											<option value="+91">+91</option>
										</select>
										<input-mask class="form-control" v-model="ContactInfo.altMobile" mask="(999)-999-9999" :alwaysShowMask="false" maskChar="_"></input-mask>
									</div> -->
								</div>
							</div>
						</div>
						<div class="col col3 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Address Line #1</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group required-field-block" >
										<input type="text" maxlength="25" size="30" class="form-control" v-model="ContactInfo.addressLine1">
									</div>
								</div>
							</div>
						</div>
						<div class="col col4 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Address Line #2</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group required-field-block" >
										<input type="text" maxlength="25" size="30" class="form-control" v-model="ContactInfo.addressLine2">
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row ten-columns">
						<div class="col col1 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Country</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group required-field-block" >
										<select class="form-control" v-model="ContactInfo.countryId" >
											<option value="" >Select</option>
											<option v-for="Country in Countries" v-bind:key="Country.country_Id" :value="Country.country_Id">{{Country.name}}</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div class="col col2 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>State</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group required-field-block" >
										<select class="form-control" v-if="ContactInfo.countryId=='US'"  v-model="ContactInfo.stateName">
											<option value="" >Select</option>
											<option v-for="State in USStates" v-bind:key="State.id" :value="State.id">{{State.stateName}}</option>
										</select>
										<input v-if="ContactInfo.countryId!='US'"  type="text" maxlength="25" size="30" class="form-control" v-model="ContactInfo.stateName">
									</div>
								</div>
							</div>
						</div>
						<div class="col col3 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>City</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group required-field-block" >
										<input type="text" maxlength="25" size="30" class="form-control" v-model="ContactInfo.cityName">
									</div>
								</div>
							</div>
						</div>
						<div class="col col4 mb-10-mr">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-12 lb">
									<label>Zip Code</label>
								</div>
								<div class="col-md-12 col-sm-12 col-12">
									<div class="input-group required-field-block" >
										<input type="text" maxlength="25" size="30" class="form-control" v-model="ContactInfo.pincode">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-header p-2"><a href="Javascript:void(0);" @click="saveContactInfo" class="btn btn-primary  float-right">Save</a></div>
		</div>

	</div>
</template>
<script>
	import { url, helpers } from "vuelidate/lib/validators";
	import iziToast from 'izitoast';

	const usPhone = helpers.regex('usPhone', /^\(\d{3}\)\s\d{3}-\d{4}$/)

	export default {
		props: {
			ContactInfo:{}
		},
		data() {
			return {  
				Countries:this.$store.getters.getCountries,
				USStates:this.$store.getters.getUSStates,
				isValidCheck:false,
            	isaltValidCheck:false,
			}
		},
		validations: {
		},
		mounted(){
		},
		methods:
		{   
			saveContactInfo:function()
			{
				if(!this.isValidCheck && !this.isaltValidCheck){
					/* if (this.ContactInfo.mobile != null && this.ContactInfo.mobile != '') {
						this.ContactInfo.mobileNo = this.ContactInfo.countryCode + " " + this.ContactInfo.mobile;                
					}

					if (this.ContactInfo.altMobile != null && this.ContactInfo.altMobile != '') {
						this.ContactInfo.altMobileNo = this.ContactInfo.altcountryCode + " " + this.ContactInfo.altMobile;                
					} */
					axios.post(this.$store.getters.getAPIBasePath+'/recruiterresource/update/recruiter/contact/'+this.$route.params.recruiterid,this.ContactInfo)
					.then(response => {                 
						iziToast.success({
							title: 'Success',
							message: this.$config.IntConInfoUpSuc,
							position:'topRight'
						});
					})
					.catch(error => {
						console.log(error);
						iziToast.error({
							title: 'Error Occured',
							message: this.$config.IntConInfoUpErr,
							position:'topRight'
						});
					});
				}
			},
			phoneControlInput:function(value,isValid){
				this.isValidCheck = isValid;
				this.ContactInfo.mobileNo = value;
			},
			altPhoneControlInput:function(value,isValid){
				this.isaltValidCheck = isValid;
				this.ContactInfo.altMobileNo = value;
			}
		}
	}
</script>