<template>
    <div id="content_Personal_Info">
        <div class="card">
            <div class="card-header p-2">Primary Information </div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-3 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>First Name</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="text" maxlength="25" size="30" class="form-control" v-model="ProfileData.firstName">
                                        <span class="required-icon">
                                        </span>
                                    </div>
                                    <label v-if="$v.ProfileData.firstName.$error && !$v.ProfileData.firstName.required" class="text-danger">First name is required</label>
                                    <label v-if="$v.ProfileData.firstName.$error && !($v.ProfileData.firstName.minLength && $v.ProfileData.firstName.maxLength)" class="text-danger">First name should be at least {{this.$v.ProfileData.firstName.$params.minLength.min}} or maximum {{this.$v.ProfileData.firstName.$params.maxLength.max}} character length</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Last Name</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="text" v-model="ProfileData.lastName" maxlength="10" size="30" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Nick Name</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" v-model="ProfileData.nickName" maxlength="10" size="30" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Username (Email)</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="text" maxlength="50" v-model="ProfileData.email" class="Submitaddress form-control" disabled>
                                        <span class="required-icon"></span>
                                    </div>
                                    <label v-if="$v.ProfileData.email.$error && !$v.ProfileData.email.required" class="text-danger">Email is required</label>
                                    <label v-if="$v.ProfileData.email.$error && !$v.ProfileData.email.maxLength" class="text-danger">Email names should be maximum {{this.$v.ProfileData.email.$params.maxLength.max}} character length</label>
                                    <label v-if="$v.ProfileData.email.$error && !$v.ProfileData.email.email" class="text-danger">Email is not valid</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ten-columns">
                        <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Industry</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <select v-model="ProfileData.industryId" class="form-control">
                                        <option value="" selected>Select</option>
                                        <option v-for="(Industry, index) in industries" :value="Industry.id" v-bind:key="index">{{Industry.industryName}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Position</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="text" maxlength="50" v-model="ProfileData.position" class="Submitaddress form-control">
                                        <!-- <span class="required-icon"></span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Gender</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group radio_btns">
                                        <p>
                                            <input type="radio" v-model="ProfileData.gender" name="gender" value="M" id="male" />
                                            <label for="male">Male</label>
                                        </p>
                                        <p>
                                            <input type="radio" v-model="ProfileData.gender" name="gender" value="F" id="female" />
                                            <label for="female">Female</label>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Date Of Birth</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block date" data-date-format="mm-dd-yyyy">
                                        <datepicker placeholder="Date of Birth" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="ProfileData.dateOfBirth" :disabledDates="dobDates"></datepicker>
                                        <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                        <!-- <span class="required-icon"></span> -->
                                    </div>
                                    <!-- <label v-if="$v.ProfileData.dateOfBirth.$error && !$v.ProfileData.dateOfBirth.required" class="text-danger">First names is required</label>  -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-header p-2">
                <a href="Javascript:void(0);" @click="savePersInfo" class="btn btn-primary  float-right">Save</a>
            </div>
        </div>
    </div>
</template>
<style>
.p-2 {
    margin-right: 0.1rem;
}

</style>
<script>
import { required, email, minLength, maxLength } from "vuelidate/lib/validators";
import iziToast from 'izitoast';

export default {
    props: {
        ProfileData: Object,
        profileId: Number
    },
    computed: {
        dobDates() {
            let date = new Date();
            date.setFullYear(date.getFullYear() - 10);
            return {
                from: date
            };
        }
    },
    data() {
        return {
            industries: this.$store.getters.getIndustries,
            EducationalInfos: [{
                id: 1
            }]
        }
    },
    validations: {
        ProfileData: {
            firstName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            email: {
                required,
                maxLength: maxLength(50),
                email,
            }
        }
    },
    methods: {
        savePersInfo: function() {
            this.$v.ProfileData.$touch();
            if (!this.$v.ProfileData.$invalid) {

                // this.ProfileData.imagePath = "";
                axios.post(this.$store.getters.getAPIBasePath + '/referralresource/update/referral/' + this.profileId, this.ProfileData)

                    .then(response => {

                        console.log(this.profileId);
                        //clientid = response.data.id;
                        if (response.data.status == "ACCEPTED") {

                            iziToast.success({ title: 'Success', message: this.$config.IntPerInfoSuc, position: 'topRight' });
                            this.$emit('loadReferral');
                        } else {
                            iziToast.error({ title: 'Error Occured', message: this.$config.IntPerInfoErr, position: 'topRight' });
                        }
                        //this.$router.push('/clients/edit/'+response.data.id);
                    })
                    .catch(error => {
                        console.log(error);
                        iziToast.error({ title: 'Error Occured', message: this.$config.IntPerInfoErr, position: 'topRight' });
                    });
            }
            //console.log(this.ProfileData);
        },
        addEduInfo: function() {
            this.EducationalInfos.push({ id: this.EducationalInfos.length + 1 });
        },
        removeEduInfo: function(id) {
            //console.log(id);
            let v_array = this.EducationalInfos.filter(function(value, index, arr) {
                return value.id != id;
            });

            for (var i = id - 1; i < v_array.length; i++)
                v_array[i].id--;

            this.EducationalInfos = v_array;
        }
    }
}

</script>
