<template>
    <div :class="{ 'viewonly': contactinfo.viewonly == 1 }">
        <div class="card">
            <div class="card-header p-2"> Current Address</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address Line 1</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input v-model="contactinfo.currentaddress.addressLine1" @input="validateAddress"
                                            type="text" maxlength="45" class="form-control">
                                        <div v-if="addressError" class="invalid-feedback">Symbols not allowed</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address Line 2</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input v-model="contactinfo.currentaddress.addressLine2" @input="validateAddress2"
                                            type="text" maxlength="45" class="form-control">
                                        <div v-if="addressError2" class="invalid-feedback">Symbols not allowed</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Country</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <select v-model="contactinfo.currentaddress.countryId"
                                        @change="contactinfo.currentaddress.stateName = ''" class="form-control">
                                        <option value="">Select</option>
                                        <option v-for="(data, index) in countries" :value="data.country_Id">{{ data.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>State/Province</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <select v-if="contactinfo.currentaddress.countryId == 'US'"
                                        v-model="contactinfo.currentaddress.stateName"
                                        @change="onStateChange($event, 'current')" class="form-control" required>
                                        <option value="">Select</option>
                                        <option v-for="(data, index) in usstates" :value="data.stateName">{{ data.stateName }}
                                        </option>
                                    </select>
                                    <input v-if="contactinfo.currentaddress.countryId != 'US'"
                                        v-model="contactinfo.currentaddress.stateName" type="text" maxlength="25"
                                        class="form-control">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>City</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input v-model="contactinfo.currentaddress.cityName" type="text" @input="cityvalid"
                                            maxlength="45" class="form-control"
                                            @blur="handleInputCityName($event, 'current')">
                                        <div v-if="city1" class="invalid-feedback">Symbols not allowed</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Zip Code</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input v-if="!isMultipleCurrentZip" v-model="contactinfo.currentaddress.zipCode"
                                            type="text" maxlength="10" class="form-control">

                                        <select v-if="isMultipleCurrentZip" v-model="contactinfo.currentaddress.zipCode"
                                            class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(data, index) in multipleZipCodes" :value="data.zip">{{ data.zip }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">Permanent Address</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns mx-0 p-3">
                        <div class="col-md-12 form-check form-check-inline">
                            <!-- <input v-model="sameAsAbove" class="form-check-input" type="checkbox" id="rowchckbox1" value="option1">
                            <label class="form-check-label" for="rowchckbox1" @click="getCurrentAddress">Same as above</label> -->
                            <b-form-checkbox id="checkbox-1" v-model="sameAsAbove" name="checkbox-1"
                                @change="getCurrentAddress">
                                Same as above
                            </b-form-checkbox>
                        </div>
                    </div>
                    <div class="row ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address Line 1</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input v-model="contactinfo.permanentaddress.addressLine1" @input="peradd1"
                                            type="text" maxlength="45" class="form-control">
                                        <div v-if="permenentadd1" class="invalid-feedback">Symbols not allowed</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address Line 2</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input v-model="contactinfo.permanentaddress.addressLine2" @input="peradd2"
                                            type="text" maxlength="45" class="form-control">
                                        <div v-if="permenentadd2" class="invalid-feedback">Symbols not allowed</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Country</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <select v-model="contactinfo.permanentaddress.countryId"
                                        @change="contactinfo.permanentaddress.stateName = ''" class="form-control">
                                        <option value="">Select</option>
                                        <option v-for="(data, index) in countries" :value="data.country_Id">{{ data.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>State/Province</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <select v-if="contactinfo.permanentaddress.countryId == 'US'"
                                        v-model="contactinfo.permanentaddress.stateName"
                                        @change="onStateChange($event, 'permanent')" class="form-control" required>
                                        <option value="">Select</option>
                                        <option v-for="(data, index) in usstates" :value="data.stateName">{{ data.stateName }}
                                        </option>
                                    </select>
                                    <input v-if="contactinfo.permanentaddress.countryId != 'US'"
                                        v-model="contactinfo.permanentaddress.stateName" type="text" maxlength="25"
                                        class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>City</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input v-model="contactinfo.permanentaddress.cityName" @input="percity" type="text"
                                            maxlength="45" class="form-control"
                                            @blur="handleInputCityName($event, 'permanent')">
                                        <div v-if="permentcity" class="invalid-feedback">Symbols not allowed</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Zip Code</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input v-if="!isMultipleZipCode" v-model="contactinfo.permanentaddress.zipCode"
                                            type="text" maxlength="10" class="form-control">
                                        <select v-if="isMultipleZipCode" v-model="contactinfo.permanentaddress.zipCode"
                                            class="form-control" required>
                                            <option value="">Select</option>
                                            <option v-for="(data, index) in multipleZipCodes" :value="data.zip">{{ data.zip }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">Emergency Contact</div>
            <div class="card-body p-2">
                <div class="row ten-columns">
                    <div class="col-sm-12">
                        <div class="table-responsive">
                            <table class="table table-borderless all-table">
                                <thead>
                                    <tr>
                                        <th class="" scope="col">Name</th>
                                        <th class="" scope="col">Phone</th>
                                        <th class="" scope="col">Relationship</th>
                                        <th class="" scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody class="cloned_tr">
                                    <tr v-for="(info, index) in contactinfo.emergency">
                                        <td>
                                            <input v-model="contactinfo.emergency[index].name" type="text" maxlength="25"
                                                class="form-control"
                                                v-on:input="contactinfo.emergency[index].name = contactinfo.emergency[index].name.trim()">
                                        </td>
                                        <td class="input-group phone-border">
                                            <div class="input-group phone-border">
                                                <select
                                                    class="form-control box-none col-sm-3 pl-0 pr-0 max-w-45 border-bottom-0"
                                                    v-model="contactinfo.emergency[index].countryCode">
                                                    <option value="+1">+1</option>
                                                    <option value="+91">+91</option>
                                                </select>
                                                <input class="form-control box-none border-bottom-0 pl-0 pr-0" id="mobileNo"
                                                    type="text" v-model="contactinfo.emergency[index].mobile"
                                                    @input="acceptNumber(index)" />
                                                <!-- <input-mask class="form-control box-none col-sm-9 border-bottom-0" v-model="contactinfo.emergency[index].phoneNo" mask="(999)-999-9999" placeholder="Phone No" :alwaysShowMask="false" maskChar="_"></input-mask>
                                           
                                            <input v-if="false" v-model="contactinfo.emergency[index].phone" type="text" maxlength="15" class="form-control" v-on:input="contactinfo.emergency[index].phone = contactinfo.emergency[index].phone.trim()" @keypress="validatePhone($event,'#primaryPhone')"> -->
                                            </div>
                                        </td>
                                        <td>
                                            <input v-model="contactinfo.emergency[index].relation" type="text"
                                                maxlength="25" class="form-control"
                                                v-on:input="contactinfo.emergency[index].relation = contactinfo.emergency[index].relation.trim()">
                                        </td>
                                        <td>
                                            <a v-if="contactinfo.viewonly != 1" href="Javascript:void(0);"
                                                data-toggle="modal" data-target="#removEmergency"
                                                @click="confirmEmergency(index, info.id)" style="margin-top:5px;"><span
                                                    class="ti-trash"></span></a>
                                        </td>
                                    </tr>
                                    <tr class="filled4" :class="{ 'noaddedmore': contactinfo.viewonly == 1 }">
                                        <td>
                                            <input ref="addcandidate_contactinfo_add_name" v-model="newcontact.name"
                                                type="text" maxlength="25" class="form-control">
                                        </td>
                                        <td class="input-group phone-border">
                                            <PhoneNumber ref="phoneNumber" :value="newcontact.phoneNo"
                                                @phone-input="phoneControlInput" :isValidcheck="isValidCheck" />
                                            <!-- <select class="form-control box-none col-sm-3 pl-0 pr-0 max-w-45 border-bottom-0" v-model="newcontact.countryCode">
                                                <option value="+1">+1</option>
                                                <option value="+91">+91</option>
                                            </select>
                                            <input-mask class="form-control box-none col-sm-9 border-bottom-0" v-model="newcontact.phoneNo" mask="(999)-999-9999" placeholder="Phone No" :alwaysShowMask="false" maskChar="_"></input-mask>
                                            <input v-if="false" v-model="newcontact.phone" type="text" maxlength="15" class="form-control" @keypress="validatePhone($event,'#primaryPhone')"> -->
                                        </td>
                                        <td class="">
                                            <input v-model="newcontact.relation" type="text" maxlength="25"
                                                class="form-control">
                                        </td>
                                        <td>
                                            <a href="Javascript:void(0);" @click="addNewContact"
                                                style="margin-top:5px;"><span class="ti-plus"></span></a>
                                        </td>
                                    </tr>
                                    <tr :class="{ 'norecordfound': contactinfo.length }" style="display: none;">
                                        <td colspan="4" align="center">No item found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="removEmergency" :onYes="deleteEmergency" :returnParams="delEmrParam" />
    </div>
</template>

<script>
export default {
    props: {
        contactinfo: Object,
    },
    data() {
        return {
            isValidCheck: false,
            addressError2: false,
            city1:false,
            permenentadd1:false,
            permenentadd2:false,
            permentcity:false,

            contactinformation: {
                currentaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                },
                permanentaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                },
            },
            addressError: false,

            specialCharacterRegex: /[!@#$%^&*?":{}|<>]/,

            countries: this.$store.getters.getCountries,
            usstates: this.$store.getters.getUSStates,
            newcontact: {
                name: "",
                phoneNo: "",
                relation: "",
                mobile: "",
                countryCode: ""
            },
            delEmrParam: {
                index: -1,
                id: 0
            },
            sameAsAbove: false,
            selectedState: "",
            selectedCity: "",
            addressType: "",
            multipleZipCodes: [],
            isMultipleZipCode: "",
            isMultipleCurrentZip: ""
        }
    },
    mounted() {
        this.isMultipleZipCode = false;
        this.isMultipleCurrentZip = false;
    },
    methods: {
        validateAddress() {
            this.addressError = this.specialCharacterRegex.test(this.contactinfo.currentaddress.addressLine1);
        },
        validateAddress2() {
            this.addressError2 = this.specialCharacterRegex.test(this.contactinfo.currentaddress.addressLine2);
        },
        cityvalid() {
            this.city1 = this.specialCharacterRegex.test(this.contactinfo.currentaddress.cityName);
        },
        peradd1() {
            this.permenentadd1 = this.specialCharacterRegex.test(this.contactinfo.permanentaddress.addressLine1);

        },
        peradd2() {
            this.permenentadd2 = this.specialCharacterRegex.test(this.contactinfo.permanentaddress.addressLine2);

        },
        percity() {
            this.permentcity = this.specialCharacterRegex.test(this.contactinfo.permanentaddress.cityName);
        },

        onStateChange(event, addressType) {
            this.selectedState = event.target.value;
            this.addressType = addressType;
            if (this.selectedState != '') {
                this.getZipCode();
            }
            else if (this.selectedState == '' && addressType == 'current') {
                this.getZipCode();
                this.isMultipleCurrentZip = false;
                this.contactinfo.currentaddress.zipCode = "";
            }
            else if (this.selectedState == '' && addressType == 'permanent') {
                this.getZipCode();
                this.isMultipleZipCode = false;
                this.contactinfo.permanentaddress.zipCode = "";
            }
        },
        handleInputCityName(e, addressType) {
            this.selectedCity = e.target.value;
            this.addressType = addressType;
            if (this.selectedCity != '') {
                this.getZipCode();
            }
            else if (this.selectedCity == '' && addressType == 'current') {
                this.isMultipleCurrentZip = false;
                this.contactinfo.currentaddress.zipCode = "";
            }
            else {
                this.isMultipleZipCode = false;
                this.contactinfo.permanentaddress.zipCode = "";
            }
        },
        phoneControlInput: function (value, isValid) {
            this.newcontact.phoneNo = value;
            this.isValidCheck = isValid;
        },
        getZipCode: function () {
            let zipCodeData = {
                stateName: this.selectedState,
                city: this.selectedCity
            };
            axios.post(this.$store.getters.getAPIBasePath + '/commonresource/US/zipcodes', zipCodeData)
                .then(response => {
                    let result = response.data;
                    if (result.usZipCode != null && result.usZipCode.length > 1) {

                        if (this.addressType == 'current') {
                            this.isMultipleCurrentZip = true;
                            this.multipleZipCodes = result.usZipCode;
                        }
                        else if (this.addressType == 'permanent') {
                            this.isMultipleZipCode = true;
                            this.multipleZipCodes = result.usZipCode;
                        }

                    } else if (result.usZipCode != null && result.usZipCode.length == 1) {

                        if (this.addressType == 'current') {
                            this.isMultipleCurrentZip = false;
                            this.contactinfo.currentaddress.zipCode = result.usZipCode[0].zip;
                        }
                        else if (this.addressType == 'permanent') {
                            this.isMultipleZipCode = false;
                            this.contactinfo.permanentaddress.zipCode = result.usZipCode[0].zip;
                        }

                    } else if (result.usZipCode.length == 0) {

                        if (this.addressType == 'current') {
                            this.isMultipleCurrentZip = false;
                            this.contactinfo.currentaddress.zipCode = "";
                        }
                        else if (this.addressType == 'permanent') {
                            this.isMultipleZipCode = false;
                            this.contactinfo.permanentaddress.zipCode = "";
                        }
                    }
                })
                .catch(error => {
                    console.log(error.data);
                });

        },
        resetNewForm() {
            this.newcontact = {
                name: "",
                phoneNo: "",
                relation: "",
                mobile: "",
                countryCode: ""
            };
            this.$refs.addcandidate_contactinfo_add_name.focus();
        },



        addNewContact() {
            this.newcontact.name = this.newcontact.name.trim();
            // this.newcontact.phoneNo = this.newcontact.phoneNo.trim();
            if (this.newcontact.phoneNo != null && this.newcontact.phoneNo.startsWith("+")) {
                let phoneData = this.newcontact.phoneNo.split(" ");
                this.newcontact.countryCode = phoneData[0];
                this.newcontact.mobile = phoneData[1];
            } else {
                this.newcontact.mobile = this.newcontact.phoneNo;
            }
            console.log("phoneNo", this.newcontact.mobile);
            this.newcontact.relation = this.newcontact.relation.trim();
            if (this.newcontact.name != "" && this.newcontact.phoneNo != "" && !this.isValidCheck && this.newcontact.relation != "") {
                this.contactinfo.emergency.push(this.newcontact);
                this.resetNewForm();
            }
        },
        deleteEmergency: function (arg) {

            this.contactinfo.emergency.splice(arg.index, 1);
            let id = this.delEmrParam.id;
            axios.delete(this.$store.getters.getAPIBasePath + '/candidateresource/delete/candidate/emergencycontact/' + id)
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error.data);
                });

        },
        confirmEmergency: function (arg1, arg2) {
            this.delEmrParam.index = arg1;
            this.delEmrParam.id = arg2;
        },
        getCurrentAddress() {
            if (this.sameAsAbove) {
                this.contactinfo.permanentaddress.addressLine1 = this.contactinfo.currentaddress.addressLine1;
                this.contactinfo.permanentaddress.addressLine2 = this.contactinfo.currentaddress.addressLine2;
                this.contactinfo.permanentaddress.cityName = this.contactinfo.currentaddress.cityName;
                this.contactinfo.permanentaddress.stateName = this.contactinfo.currentaddress.stateName;
                this.contactinfo.permanentaddress.zipCode = this.contactinfo.currentaddress.zipCode;
                this.contactinfo.permanentaddress.countryId = this.contactinfo.currentaddress.countryId;
            } else {
                this.contactinfo.permanentaddress.addressLine1 = "";
                this.contactinfo.permanentaddress.addressLine2 = "";
                this.contactinfo.permanentaddress.cityName = "";
                this.contactinfo.permanentaddress.stateName = "";
                this.contactinfo.permanentaddress.zipCode = "";
                this.contactinfo.permanentaddress.countryId = "";
            }
        },
        validatePhone: function (evt, idx) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 33 && (charCode < 48 || charCode > 57)) && charCode !== 45 && charCode !== 46 && charCode !== 40 && charCode !== 41) {
                evt.preventDefault();
            } //except numbers,open/close brackets,minus and space         
        },
        acceptNumber(index) {
            console.log("hai")
            var x = this.contactinfo.emergency[index].mobile.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.contactinfo.emergency[index].mobile = !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '-' + x[3] : '');
        },
    }
};


</script>
<style scoped>.is-invalid {
    border: 1px solid #dc3545;
}

.invalid-feedback {
    color: #dc3545;
}</style>