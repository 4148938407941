<template>
    <div class="job reports">
        <div class="row m-0">
            <div class="col-sm-12 p-10">
                <div id="recruiter-settings" class="recruiter settings mt-10">
                    <div class="box-wrapper shadow-sm bg-white rounded pos-rel">
                        <div class="p-4 header mx-0 mb-n1">
                            <div class="row col-md-12 mx-0 px-0">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <h4 class="page-title text-left">User Activities</h4>
                                </div>
                            </div>
                        </div>
                        <div class="row col-md-12 py-3 text-left">
                            <div class="col-md-4">
                                <div @click="$emit('setSubTab',1)" class="row col-md-12 ml-0 left-bar-tab">
                                    <div class="col-md-3 p-3">
                                        <img src="@/assets/icons/applicant-hire-ratio.png" width="100%" height="100%">
                                    </div>
                                    <div class="col-md-8 align-self-center">
                                        <h6><b>Activity Log</b></h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div @click="$emit('setSubTab',2)" class="row col-md-12 ml-0 left-bar-tab">
                                    <div class="col-md-3 p-3">
                                        <img src="@/assets/icons/applicant-hire-ratio.png" width="100%" height="100%">
                                    </div>
                                    <div class="col-md-8 align-self-center">
                                        <h6><b>Resumes loaded by Recruiters</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    data() {
        return {

        }
    },
    mounted() {},
    methods: {

    }
}

</script>
