<template>
    <div>
        <div class="card">
            <div class="card-header p-2">Client Contacts</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col"> Name</th>
                                            <th class="recruiter" scope="col">Default Contact</th>
                                            <th class="title" scope="col">Title</th>
                                            <th class="state" scope="col">Phone</th>
                                            <th class="lastnote" scope="col">Mobile</th>
                                            <th class="email" scope="col"> Email</th>
                                            <th class="gpm" scope="col"> Department</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="">
                                            <td colspan="8" v-if="clientcontactinfo.length==0" class="text-center">No Client Contact List Found</td>
                                        </tr>
                                        <tr v-for="(info, index) in clientcontactinfo">
                                            <td> {{ clientcontactinfo[index].contactName }} </td>
                                            <td class="lastnote">
                                                <input :disabled="true" class="form-check-input" id="isdefaultContact" type="checkbox" v-model="clientcontactinfo[index].isDefault">
                                                <label class="form-check-label" for="isdefaultContact"></label>
                                                </input>
                                            </td>
                                            <td class="recruiter"> {{ clientcontactinfo[index].title }} </td>
                                            <td class="title"> {{ clientcontactinfo[index].phoneNumber }} </td>
                                            <td class="specialityunit"> {{ clientcontactinfo[index].mobile }} </td>
                                            <td class="specialityunit"> {{ clientcontactinfo[index].email }} </td>
                                            <td class="specialityunit"> {{ clientcontactinfo[index].department }} </td>                                      
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {
    computed: {
        clientid() {
            return this.$route.params.clientid;
        }
    },
    props: {
        clientcontactinfo: Array,
    },
    data() {
        return {
            isEdit: 0,
            countrieslist: this.$store.getters.getCountries,
            usstates: this.$store.getters.getUSStates,
            contacttypes: this.$store.getters.getClientContactType,
            newclientcontactinfo: {
                id: "",
                department: "",
                city: "",
                stateName: "",
                firstName: "",
                middleName: "",
                lastName: "",
                countryId: "US",
                title: "",
                contactType: "",
                email: "",
                phoneNumber: "",
                address: "",
                zipCode: "",
                faxNumber: "",
                mobile: "",
                notes: "",
                isDefault: 0

            },
            formErrors: {}
        }
    },
    validations: {
        newclientcontactinfo: {
            firstName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            lastName: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(25)
            },
            phoneNumber: {
                required,
                maxLength: maxLength(15)
            },
            email: {
                email
            }
        }
    },
    methods: {
        resetNewClientContactForm() {
            this.newclientcontactinfo = {
                id: "",
                department: "",
                city: "",
                stateName: "",
                firstName: "",
                middleName: "",
                lastName: "",
                countryId: "US",
                title: "",
                contactType: "",
                email: "",
                phoneNumber: "",
                address: "",
                zipCode: "",
                faxNumber: "",
                mobile: "",
                notes: "",
                isDefault: 0
            };
            this.$refs.addclientcontactinfo_add_name.focus();
            this.$v.newclientcontactinfo.$reset();
        },
        loadClientContact(contactId) {
            axios.get(this.$store.getters.getAPIBasePath + '/clientresource/view/clientcontact/' + contactId)
                .then(response => {
                    let result = response.data;
                    if (!result.status) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    let self = this;
                    this.newclientcontactinfo = {
                        id: result.clientContact.id,
                        department: result.clientContact.department,
                        city: result.clientContact.city,
                        stateName: result.clientContact.stateName,
                        firstName: result.clientContact.firstName,
                        middleName: result.clientContact.middleName,
                        lastName: result.clientContact.lastName,
                        countryId: result.clientContact.countryId,
                        title: result.clientContact.title,
                        contactType: result.clientContact.contactType,
                        email: result.clientContact.email,
                        phoneNumber: result.clientContact.phoneNumber,
                        address: result.clientContact.address,
                        zipCode: result.clientContact.zipCode,
                        faxNumber: result.clientContact.faxNumber,
                        mobile: result.clientContact.mobile,
                        notes: result.clientContact.notes,
                        isDefault: result.clientContact.isDefault,
                    };
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliConLoadErr,
                        position: 'topRight'
                    });
                });
        },
        saveClientContactInfo() {
            this.$v.newclientcontactinfo.$touch();
            let clientcontactinfodata = this.newclientcontactinfo;            
            axios.post(this.$store.getters.getAPIBasePath + '/clientresource/update/client/' + this.clientid + '/contact', clientcontactinfodata)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CliConInfoUpSuc,
                        position: 'topRight'
                    });
                    $('#clientcontact').modal('hide');
                    this.resetNewClientContactForm();
                    this.$emit('refresh-client');
                })
                .catch(error => {
                    console.log(error);
                    let data = error.response.data;
                    if (data.message.toLowerCase() == "validation error") {
                        this.formErrors = data.validationErrorList;
                    }
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliConUpErr,
                        position: 'topRight'
                    });
                });
        },
        deleteClientContact(clientContactId) {
            if (confirm("Do you really want to delete?")) {
                var params = new URLSearchParams();
                params.append('id', clientContactId)
                axios.post(this.$store.getters.getAPIBasePath + '/clientresource/delete/clientcontact', params)
                    .then(response => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.$emit('refresh-client');
                        }
                    })
                    .catch(error => {
                        console.log(error.data);
                    });
            }
        },
    }
}

</script>
