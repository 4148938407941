import Dashboard from '@/components/guest/Dashboard';
import ListJob from '@/components/guest/job/Listjobs';
import ListCandidates from '@/components/guest/candidate/ListCandidates';
import ViewJob from '@/components/guest/job/ViewJob';
import ViewCandidate from '@/components/guest/candidate/ViewCandidate';
import Profile from '@/components/guest/Profile';
import EditGuest from '@/components/guest/profile/EditProfile';
import ChangePassword from '@/components/guest/ChangePassword';

export default [{
        path: '/guest/dashboard',
        name: 'guestdashboard',
        component: Dashboard,
        meta: { accessedby: 'guest' }
    },
    {
        path: '/guest/profile',
        name: 'guestprofile',
        component: Profile,
        meta: { accessedby: 'guest' }
    },
    {
        path: '/guest/edit/:id',
        name: 'guestedit',
        component: EditGuest,
        meta: { accessedby: 'guest' }
    },
    {
        path: '/guest/jobs',
        name: 'guestjoblist',
        component: ListJob,
        meta: { accessedby: 'guest' }
    },
    {
        path: '/guest/job/view/:jobid',
        name: 'guestviewjob',
        component: ViewJob,
        meta: { accessedby: 'guest' }
    },
    {
        path: '/guest/candidates',
        name: 'guestcandidates',
        component: ListCandidates,
        meta: { accessedby: 'guest' }
    },
    {
        path: '/guest/candidate/view/:candidateid',
        name: 'guestviewcandidate',
        component: ViewCandidate,
        meta: { accessedby: 'guest' }
    },
    {
        path: '/guest/changepassword',
        name: 'changepasswordguest',
        component: ChangePassword,
        meta: { accessedby: 'guest' }
    }
];