import Dashboard from '@/components/candidate/Dashboard';
import Profile from '@/components/candidate/Profile';
import Editprofile from '@/components/candidate/Editprofile';
import Requests from '@/components/candidate/Requests';
import Interviews from '@/components/candidate/Interviews';
import ChangePassword from '@/components/candidate/ChangePassword';
import Jobs from '@/components/candidate/Jobs';
import ViewJob from '@/components/candidate/ViewJob';


export default [{
        path: '/candidate/dashboard',
        name: 'candidatedashboard',
        component: Dashboard,
        meta: { accessedby: 'candidate' }
    },
    {
        path: '/candidate/profile',
        name: 'candidateprofile',
        component: Profile,
        meta: { accessedby: 'candidate' }
    },
    {
        path: '/candidate/jobs',
        name: 'candidatejobs',
        component: Jobs,
        meta: { accessedby: 'candidate' }
    },
    {
        path: '/candidate/job/view/:jobid',
        name: 'candidateviewjob',
        component: ViewJob,
        meta: { accessedby: 'candidate' }
    },
    {
        path: '/candidate/edit/:candidateid',
        name: 'editprofile',
        component: Editprofile,
        meta: { accessedby: 'candidate' }
    },
    {
        path: '/candidate/interviews',
        name: 'candidateinterviews',
        component: Requests,
        meta: { accessedby: 'candidate' }
    },
     {
        path: '/candidate/scheduled/interviews',
        name: 'candidatescheduledinterviews',
        component: Interviews,
        meta:{accessedby:'interviewer'}
    },
    {
        path: '/candidate/changepassword',
        name: 'changepasswordcandidate',
        component: ChangePassword,
        meta: { accessedby: 'candidate' }
    }
];
