<template>
    <div id="main" class="tech-skill-settings settings recruiter">
        <div class="row">
            <div class="col-12">
                <h5>
                    <span>Domain Settings</span>
                    <a href="Javascript:void(0);" class="pull-right btn-back text-blue" @click="$emit('setSubTab',0)">
                        <i class="ti-arrow-left"></i> Back
                    </a>
                </h5>
            </div>
        </div>
        <p>&nbsp;</p>
        <div>
            <div class="row" v-if="editIndex<0">
                <div class="col-md-6 col-sm-8 col-6">
                    <multiItemInput id="multiTech" placeholder="Enter new domain" :items="newDomainList"></multiItemInput>
                    <p v-if="formErrors.length">
                        <ul>
                            <li class="error" v-for="error in formErrors">{{ error.message }}</li>
                        </ul>
                    </p>
                </div>
                <div class="col-md-1 col-sm-4 col-1 pt-2">
                    <a href="Javascript:void(0);" @click="addDomain" class="px-2">
                        <span class="ti-plus"></span>
                    </a>
                </div>
            </div>
            <div class="row" v-if="editIndex!=-1">
                <div class="col-md-6 col-sm-8 col-6">
                    <input class="form-control required-field-block" placeholder="Enter-- new domain" v-model="newDomain.domainName">
                    <span class="required-icon">
                    </span>
                </div>
                <div class="col-md-1 col-sm-4 col-1 pt-2">
                    <a style="float:left;margin-right:5px;" href="Javascript:void(0);" @click="updateDomain(1)">
                        <span class="ti-check"></span>
                    </a>
                    <a style="float:left;" href="Javascript:void(0);" @click="updateDomain(0)">
                        <span class="ti-close"></span>
                    </a>
                </div>
            </div>
            <div class="row item" v-for="(domaininfo, index) in domainlist" v-bind:key="index">
                <div class="col-md-6 col-sm-8 col-6">
                    <div class="form-control">{{domaininfo.domainName}}</div>
                </div>
                <div class="col-md-1 col-sm-4 col-1 pt-2">
                    <!-- <a style="float:left;margin-right:5px;" href="Javascript:void(0);" @click="removeTech(index)" ><span class="ti-trash"></span></a> -->
                    <a style="float:left;margin-right:5px;" href="Javascript:void(0);" @click="editDomain(index)">
                        <span class="ti-pencil"></span>
                    </a>
                    <a style="float:left;margin-right:5px;" href="Javascript:void(0);" @click="removeDomain(index,domaininfo.id)" data-toggle="modal" data-target="#removDomain" title="Remove" >
                        <span class="ti-trash"></span>
                    </a>
                    <!-- <a style="float:left;" title="Skills" href="Javascript:void(0);" @click="openSkill(index)"><span class="ti-files"></span></a> -->
                </div>
            </div>
        </div>
         <Dialog id="removDomain" :onYes="deleteDomain" :returnParams="delParam" />
    </div>
</template>
<style scoped>
div.item {
    margin-top: 5px;
}

</style>
<script>
import multiItemInput from "@/components/plugins/multiItemInput";
import iziToast from "izitoast";

export default {
    components: {
        multiItemInput
    },
    data() {
        return {
            formErrors: [],
            techSkills: [],
            skillName: "",
            addSkills: [],
            editSkillIndex: -1,
            techSel: "",
            skillIndex: -1,
            editIndex: -1,
            technologies: [],
            newTech: { id: 0, technology: "" },
            addTechs: [],
            domainlist: [],
            domainInfo: {
                domainName: ""
            },
            domainlist: [],
            newDomainList: [],
            editDomainList: [],
            domainIndex: -1,
            selectedDomain: "",
            newDomain: { id: 0, domainName: "", domainList: [{ domainName: 1 }] },
            browsepagination: this.$store.state.paginationconfig,
            searchlimit: this.$store.state.searchlimit,
            delParam: {
                index: -1,
                id: 0
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    domainName: ""
                }
            }
        };
    },

    mounted() {
        this.loadDomains();
    },
    methods: {
        loadDomains() {
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/settingsresource/domain/list")
                .then(response => {
                    let result = response.data;
                    this.domainlist = result.domainList;                    
                })
                .catch(error => {
                    iziToast.error({
                        title: "Error",
                        message: error,
                        position: "topRight"
                    });
                });
        },
        addDomain() {
            let domains = this.newDomainList.map(domain => ({ domainName: domain }));
            axios
                .post(this.$store.getters.getAPIBasePath + "/settingsresource/add/domain", { domainList: domains })
                .then(response => {
                    this.domainInfo.domainName = "";
                    //this.$v.domainInfo.$reset();
                    this.newDomainList = [];
                    this.formErrors = [];
                    iziToast.success({
                        title: "Success",
                        message: response.data.message,
                        position: "topRight"
                    });
                    this.loadDomains();
                })
                .catch(error => {
                    this.formErrors = error.response.data.validationErrorList;
                });
            //}
        },
        openDomain(index) {
            this.editIndex = -1;
            this.newDomain = { id: 0, domainName: "" };
            this.domainIndex = index;
            this.selectedDomain = this.domainlist[index].domainName;
            this.editDomainList = this.domainlist[index].settingsTechnologySkill.map(
                skills => ({ skillId: skills.skillId, skillName: skills.skillName })
            );
        },
        editDomain(index) {
            this.editIndex = index;
            this.newDomain = {
                id: this.domainlist[index].id,
                domainName: this.domainlist[index].domainName,
                domainList: [{ domainName: 1 }] //because of validation not null
            };
            // console.log(this.newDomain);
        },
        updateDomain(mode) {

            console.log(this.newDomain);
            if (mode == 1) {

                axios
                    .post(this.$store.getters.getAPIBasePath + "/settingsresource/update/domain", this.newDomain)
                    .then(response => {
                        // this.newDomain[
                        //   this.editIndex
                        // ].domainName = this.newDomain.domainName;
                        this.editIndex = -1;
                        this.newDomain = { id: 0, domainName: "" };
                        iziToast.success({
                            title: "Success",
                            message: this.$config.DomainUpSuc,
                            position: "topRight"
                        });
                        this.loadDomains();
                    })
                    .catch(error => {
                        iziToast.error({
                            title: "Error",
                            message: error,
                            position: "topRight"
                        });
                    });
            } else {
                this.editIndex = -1;
                this.newTech = { id: 0, technology: "" };
            }
        },
         removeDomain:function(arg1, arg2)
            {
                this.delParam.index=arg1;
                this.delParam.id=arg2;
            },
            deleteDomain:function(arg)
            {
            axios.delete(this.$store.getters.getAPIBasePath + '/settingsresource/delete/domain/'+this.delParam.id)
                 .then(response => {
                    let result = response.data;
                    console.log("dsd",result)
                    if (result.status == "OK") {
                        iziToast.success({
                            title: "Success",
                            message: result.message,
                            position: "topRight",
                            timeout: 1000
                        });
                        this.domainlist.splice(arg.index, 1);
                    }else{
                        iziToast.error({
                            title: "Error",
                            message: this.$config.DomainUpErr,
                            position: "topRight"
                        });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },

        loadTechnologies() {
            axios
                .get(
                    this.$store.getters.getAPIBasePath + "/commonresource/technologylist"
                )
                .then(response => {
                    this.technologies = response.data.technologylist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addNewTech() {
            console.log(this.addTechs);
            let technologies = this.addTechs.map(tech => ({ technology: tech }));
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/technologyresource/add/clienttechnology", { technologyList: technologies }
                )
                .then(response => {
                    this.addTechs = [];
                    this.loadTechnologies();
                })
                .catch(function(error) {
                    console.log(error);
                });
        },

        updateTech(mode) {
            if (mode == 1) {
                axios
                    .post(
                        this.$store.getters.getAPIBasePath +
                        "/technologyresource/update/technology",
                        this.newTech
                    )
                    .then(response => {
                        this.technologies[
                            this.editIndex
                        ].technology = this.newTech.technology;
                        this.editIndex = -1;
                        this.newTech = { id: 0, technology: "" };
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            } else {
                this.editIndex = -1;
                this.newTech = { id: 0, technology: "" };
            }
        },
        editTech(index) {
            this.editIndex = index;
            this.newTech = {
                id: this.technologies[index].id,
                technology: this.technologies[index].technology
            };
        },
        openSkill(index) {
            this.editIndex = -1;
            this.newTech = { id: 0, technology: "" };
            this.skillIndex = index;
            this.techSel = this.technologies[index].technology;

            this.techSkills = this.technologies[index].settingsTechnologySkill.map(
                skills => ({ skillId: skills.skillId, skillName: skills.skillName })
            );
        },
        addNewSkills() {
            //console.log(this.addSkills);
            let skillItems = this.addSkills.map(skill => ({
                categoryId: null,
                technologyId: this.technologies[this.skillIndex].id,
                skillName: skill,
                isDeleted: 0,
                id: null
            }));

            axios
                .post(
                    this.$store.getters.getAPIBasePath + "/technologyresource/add/skill", { technologySkill: skillItems }
                )
                .then(response => {
                    this.showTech();
                    this.loadTechnologies();
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        updateSkill(mode) {
            if (mode == 1) {
                let newSkill = {
                    id: this.techSkills[this.editSkillIndex].skillId,
                    skillName: this.skillName
                };
                axios
                    .post(
                        this.$store.getters.getAPIBasePath +
                        "/technologyresource/update/skill",
                        newSkill
                    )
                    .then(response => {
                        this.technologies[this.skillIndex].settingsTechnologySkill[
                            this.editSkillIndex
                        ].skillName = this.skillName;
                        this.techSkills[this.editSkillIndex].skillName = this.skillName;
                        this.editSkillIndex = -1;
                        this.skillName = "";
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            } else {
                this.editSkillIndex = -1;
                this.skillName = "";
            }
        },
        showTech() {
            this.techSkills = [];
            this.skillName = "";
            this.addSkills = [];
            this.editSkillIndex = -1;
            this.techSel = "";
            this.skillIndex = -1;
        },
        removeSkill(index) {
            let skillItems = [{
                categoryId: null,
                technologyId: this.technologies[this.skillIndex].id,
                skillName: "",
                isDeleted: 1,
                id: this.techSkills[index].skillId
            }];

            // axios.post(this.$store.getters.getAPIBasePath + '/technologyresource/delete/skill',{technologySkill:skillItems})
            // .then(response => {
            this.techSkills.splice(index, 1);
            this.technologies[this.skillIndex].settingsTechnologySkill.splice(
                index,
                1
            );
            this.editSkillIndex = -1;
            this.skillName = "";
            // })
            // .catch(function (error) {
            // console.log(error);
            // });
        },
        editSkill(index) {
            this.editSkillIndex = index;
            this.skillName = this.techSkills[index].skillName;
        }
    }
};

</script>
