<template>
    <div id="main" class="mt-70">
        <div class="container-fluid recruiter-dashboard interviewer dashboard">
            <div class="row">
                <main role="main" class="col-md-12 ml-sm-auto col-lg-12 my-3 pt-2">
                    <div class="row">
                        <div class="card-list">
                            <div class="row" style="padding-left:15px; padding-right:15px">
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: 'referraljoblist' }" class="">
                                        <div class="card green">
                                            <div class="value">Jobs</div>
                                            <div class="value"> <img class="" src="@/assets/icons/jobprofile-white.png" alt=""></div>
                                            <div class="stat">Manage Job</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: 'referralcandidates' }" class="">
                                        <div class="card blue">
                                            <div class="value">Candidates</div>
                                            <div class="value"><img class="" src="@/assets/icons/candidate-white.png" alt=""></div>
                                            <div class="stat">Manage Candidate</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: 'referralinterviewers' }" class="">
                                        <div class="card orange">
                                            <div class="value">Interviewers</div>
                                            <div class="value"><img class="" src="@/assets/icons/meeting-white.png" alt=""></div>
                                            <div class="stat">Manage Interviewer</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: 'referralsettings' }" class="">
                                        <div class="card grey">
                                            <div class="value">Tools & Settings</div>
                                            <div class="value"><img class="" src="@/assets/icons/settings-tools-white.png" alt="" width="32px" height="100%"></div>
                                            <div class="stat">Manage Setting</div>
                                        </div>
                                    </router-link>
                                </div>

                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: 'referralreport' }" class="">
                                        <div class="card" style="background-color: #c0ca33">
                                            <div class="value">Reports</div>
                                            <div class="value"><img class="" src="@/assets/icons/statistics-white.png" alt=""></div>
                                            <div class="stat">Manage Report</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: 'referraltooldemo' }" class="">
                                        <div class="card" style="background-color: #3F729B">
                                            <div class="value">Tool Demo</div>
                                            <div class="value"><img class="" src="@/assets/icons/tool-demo-white.png" alt=""></div>
                                            <div class="stat">Tool Demo</div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <h5 style="background-color: #37a0e4;color: #fff;padding: 5px; " class="">Inbox</h5>
                            <div class="box-wrapper p-1 mb-5 rounded">
                                <b-tabs card class="tab-list inbox-tab-list driver-tab-list tab-bg">
                                    <b-tab title="Jobs" class="scroll-down-inbox">
                                        <div class="card-media" v-if="joblist.length==0">
                                            <div class="col-md-12 pt-4 pb-4" style="text-align : center;background-color: #ffff">No records found
                                            </div>
                                        </div>
                                        <div class="card-media" v-for="(jobinfo, index) in joblist">
                                            <div class="card-media-body">
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <router-link :to="{ name: 'referralviewjob', params: { jobid: jobinfo.id }}"> <span class="card-media-body-heading job-link pl-0">{{ jobinfo.jobTitle }} <span v-if="checkEmpty(jobinfo.jobCode)">- [{{jobinfo.jobCode}}]</span> </span> </router-link>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <div class="vacancies"><span v-if="checkEmpty(jobinfo.vacancies)" title="Vacancies" class="badge badgebgdashboard pull-right">{{jobinfo.vacancies}}</span></div>
                                                        <div class="hotJob"><span v-if="jobinfo.priority == true" class="redclr">Hot Job</span></div>
                                                    </div>
                                                    <div v-if="checkEmpty(jobinfo.clientName)" class="col-md-12 col-sm-12 mb-2">
                                                        <span class="">{{ jobinfo.clientName }}</span>
                                                    </div>
                                                </div>
                                                <ul class="mb-2">
                                                    <li v-if="checkEmpty(jobinfo.experience)" class="d-inline-block pr-4"><i class="ti-bag"></i> {{jobinfo.experience}} years</li>
                                                    <li v-if="checkEmpty(jobinfo.location)" class="d-inline-block pr-4"><i class="ti-location-pin"></i> {{jobinfo.location}}</li>
                                                    <li v-if="checkEmpty(jobinfo.days)" class="d-inline-block pull-right"><i class="ti-alarm-clock"></i> <span class="pl-2 font-weight-bold" style="color:#e83e8c;">{{jobinfo.days}}</span> <span class="pl-1 small ">days more</span></li>
                                                </ul>
                                                <div class="card-media-body-top">
                                                    <div class="card-media-body-top u-float-right  w-100 text-right hrline">
                                                        <span class="float-left" v-if="checkEmpty(jobinfo.dueDate)"><i class="ti-calendar pr-2"></i> {{jobinfo.dueDate | formatDate}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Candidates" class="scroll-down-inbox">
                                        <div class="card-media" v-if="candidatesList.length==0">
                                            <div class="col-md-12 pt-4 pb-4" style="text-align : center;background-color: #ffff">No records found
                                            </div>
                                        </div>
                                        <div class="card-media" v-for="(candidateInfo, index) in candidatesList">
                                            <div class="card-media-body">
                                                <div class="row">
                                                    <div class="col-sm-10">
                                                        <router-link :to="{ name: 'referralviewcandidate', params: { candidateid: candidateInfo.id }}"><span class="card-media-body-heading job-link pl-0">{{ candidateInfo.candidateName }} </span> </router-link>
                                                    </div>
                                                    <div class="col-sm-2">
                                                        <span v-if="checkEmpty(candidateInfo.experience)" title="Experience" class="badge badgebgcaninbox pull-right">{{candidateInfo.experience}} yrs</span>
                                                    </div>
                                                </div>
                                                <div v-if="checkEmpty(candidateInfo.position)" class="row">
                                                    <span style="margin-left:15px;"> {{candidateInfo.position}} </span>
                                                </div>
                                                <div v-if="checkEmpty(candidateInfo.industryName)" class="row">
                                                    <span style="margin-left:15px;"> <i class="ti-location-arrow"> </i> {{candidateInfo.industryName}}</span>
                                                </div>
                                                <div class="row">
                                                    <span v-if="checkEmpty(candidateInfo.email)" style="margin-left:15px;"><i class="ti-email"> </i> {{candidateInfo.email}} </span>
                                                    <span v-if="checkEmpty(candidateInfo.mobile)" style="margin-left:15px;"><i class="ti-mobile"> </i> {{candidateInfo.mobile}}</span>
                                                </div>
                                                <!-- <div class="card-media-body-top">
                                                    <div class="card-media-body-top u-float-right  w-100 text-right hrline" style="margin-top:8px;">
                                                        <span class="job-link float-left pt-0 pb-0">
                                                            <router-link :to="{ name: 'referralviewcandidate', params: { candidateid: candidateInfo.id }}">
                                                                <span class="ti-eye"></span> Review
                                                            </router-link>
                                                        </span>
                                                        <span class="job-link pt-0 pb-0">
                                                            <a title="Reject" href="javascript:void(0);" @click="setCandidateId(candidateInfo.id)" data-toggle="modal" data-target="#RejectCandidate"><i class="ti-close" style="color: red;"></i><span style="color: red; padding-right:10px; font-family: sans-serif;"> Reject</span></a>
                                                        </span>
                                                        <span class="job-link float-right pt-0 pb-0">
                                                            <a title="Accept" href="javascript:void(0);" @click="setCandidateId(candidateInfo.id)" data-toggle="modal" data-target="#AcceptCandidate"><i class="ti-check" style="color: green;"></i><span class="pr-10" style="color: green; font-family: sans-serif;">Accept</span></a>
                                                        </span>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Interviewers" class="scroll-down-inbox">
                                        <div class="card-media" v-if="interviewerList.length==0">
                                            <div class="col-md-12 pt-4 pb-4" style="text-align : center;background-color: #ffff">No records found
                                            </div>
                                        </div>
                                        <div class="card-media" v-for="(interviewerInfo, index) in interviewerList">
                                            <div class="card-media-body">
                                                <div class="row">
                                                    <div class="col-sm-10">
                                                        <router-link :to="{ name: 'referralviewinterviewer', params: { interviewerid: interviewerInfo.id }}"> <span class="card-media-body-heading job-link pl-0">{{ interviewerInfo.interviewerName }} </span> </router-link>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <span style="margin-left:15px;"> Email : {{ interviewerInfo.email }} </span>
                                                </div>
                                                <div class="row">
                                                    <span style="margin-left:15px;"> Phone : {{ interviewerInfo.phoneNo }} </span>
                                                </div>
                                                <div class="row">
                                                    <span style="margin-left:15px;"> Position : {{ interviewerInfo.position }} </span>
                                                </div>
                                                <div class="card-media-body-top">
                                                    <div class="card-media-body-top u-float-right  w-100 text-right hrline" style="margin-top:8px;">
                                                        <span v-if="false" class="job-link float-left pt-0 pb-0">
                                                            <a title="Reject" href="javascript:void(0);" @click="setInterviewerId(interviewerInfo.id)" data-toggle="modal" data-target="#RejectRequest"><i class="ti-close" style="color: red;"></i><span style="color: red; padding-right:10px; font-family: sans-serif;"> Reject</span></a>
                                                        </span>
                                                        <span v-if="false" class="job-link float-right pt-0 pb-0">
                                                            <a title="Accept" href="javascript:void(0);" @click="setInterviewerId(interviewerInfo.id)" data-toggle="modal" data-target="#AcceptRequest"><i class="ti-check" style="color: green;"></i><span class="pr-10" style="color: green; font-family: sans-serif;">Accept</span></a>
                                                        </span>
                                                        <span >
                                                            &nbsp;
                                                           <!--  waiting for approval -->
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <div class="row">
                                <div v-if="false" class="col-md-12 col-xl-3">
                                    <div class="card">
                                        <div class="p-0 card-body">
                                            <div class="p-3 media">
                                                <div class="media-body"><span class="text-muted text-uppercase font-size-12 font-weight-bold">Client</span>
                                                    <h2 class="text-black mb-0">{{referral.clients}}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xl-4">
                                    <div class="card">
                                        <div class="p-0 card-body">
                                            <div class="p-3 media">
                                                <div class="media-body"><span class="text-muted text-uppercase font-size-12 font-weight-bold">Candidate</span>
                                                    <h2 class="text-black mb-0">{{referral.candidates}}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xl-4">
                                    <div class="card">
                                        <div class="p-0 card-body">
                                            <div class="p-3 media">
                                                <div class="media-body"><span class="text-muted text-uppercase font-size-12 font-weight-bold">Interviewers</span>
                                                    <h2 class="text-black mb-0">{{referral.interviewers}}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="false" class="col-md-12 col-xl-3">
                                    <div class="card">
                                        <div class="p-0 card-body">
                                            <div class="p-3 media">
                                                <div class="media-body"><span class="text-muted text-uppercase font-size-12 font-weight-bold">Referrals</span>
                                                    <h2 class="text-black mb-0">{{referral.referrals}}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-xl-12 col-sm-12">
                                    <h5 class="pt-2">Monthly Referral Performance
                                        <!-- <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:15px; margin-right: 20px;">Monthly Referral performance</span> -->
                                    </h5>
                                    <!--   <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel"> -->
                                    <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
                                    <!--  </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
        <div class="modal " id="RejectRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Interview confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to reject this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="interviewConfirmation('reject')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal " id="AcceptRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Interview confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to accept this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="interviewConfirmation('approve')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal " id="RejectCandidate" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Candidate confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to reject this candidate?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="candidateConfirmation('reject')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal " id="AcceptCandidate" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Candidate confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to accept this candidate?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="candidateConfirmation('approve')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { GChart } from "vue-google-charts";
import iziToast from 'izitoast';

export default {

    name: 'app',
    components: {
        GChart
    },
    props: {
        // screeninginfo:Object
    },
    computed: {
        recownerid() {
            return this.$store.getters.currentUser.recruitOwner;
        }
    },
    data() {
        return {
            joblist: [],
            interviewerList: [],
            candidatesList: [],
            browsepagination: this.$store.state.paginationconfig,
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            industrylist: this.$store.getters.getIndustries,
            clientlist: this.$store.getters.getClients,
            jobStatuslist: this.$store.getters.getJobStatus,
            elements: {
                showadvancedsearch: false,
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    jobTitle: "",
                    jobType: "",
                    technologyId: "",
                    startDate: "",
                    endDate: "",
                    recruiterId: this.recruiterId,
                    clientId: "",
                    industryId: "",
                    status: ""
                },
            },
            coldisplay: {
                jobTitle: true,
                jobCode: true,
                client: true,
                industry: true,
                position: true
            },
            delJobParam: {
                index: -1,
                id: 0
            },
            referral: {
                clients: 0,
                candidates: 0,
                interviewers: 0,
                referrals: 0
            },
            height: 347,
            width: 300,
            chartData: [],
            chartOptions: {
                //title: 'Interview [Month-wise]',
                subtitle: 'Month, Candidates, Interviewers',
                height: 350
                //colors: ['#2298f1', '#898a8e'],
            }
        }
    },

    mounted() {
        this.loadJobs();
        this.loadCandidatesList();
        this.loadInterviewerList();
        this.loadReferralCounts();
        this.loadGraph();
    },
    methods: {
        loadGraph() {
            axios.post(this.$store.getters.getAPIBasePath + '/dashboardresource/referralperformance/dashboard/chart/'+ this.$store.getters.currentUser.profileId)
                .then(response => {
                    let result = response.data;
                    this.chartData = result.guestPerformance;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },
        loadJobs() {
            axios.post(this.$store.getters.getAPIBasePath + '/jobresource/public/joblist?page=' + this.browsepagination.currentpage + '&size=' + this.search.limit, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.joblist = result.jobs;
                    this.browsepagination.totalrecords = result.pagination[0].total;
                    this.browsepagination.currentpage = result.pagination[0].currentPage;
                    this.browsepagination.totalpages = result.pagination[0].lastPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadCandidatesList() {
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/referral/dashboard/candidatelist?page=' + this.browsepagination.currentpage + '&size=' + this.search.limit, this.search.params)
                .then(response => {
                    let result = response.data;
                    if (result.status)
                        this.candidatesList = result.candidates;
                    // this.browsepagination.totalrecords = result.pagination[0].total;
                    // this.browsepagination.currentpage = result.pagination[0].currentPage;
                    // this.browsepagination.totalpages = result.pagination[0].lastPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadInterviewerList() {
            axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/referral/dashboard/interviewers?page=' + this.browsepagination.currentpage + '&size=' + this.search.limit, this.search.params)
                .then(response => {
                    let result = response.data;

                    if (result.status)
                        this.interviewerList = result.interviewer;
                    // this.browsepagination.totalrecords = result.pagination[0].total;
                    // this.browsepagination.currentpage = result.pagination[0].currentPage;
                    // this.browsepagination.totalpages = result.pagination[0].lastPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        candidateConfirmation(type) {
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/referral/candidate/approval', { 'id': this.candidateId, 'statusName': type })
                .then(response => {
                    let result = response.data;
                    if (result.status) {
                        iziToast.success({
                            title: 'Confirmation Request',
                            message: result.message,
                            position: 'topRight'
                        });

                        this.loadCandidatesList();
                    } else {
                        iziToast.error({
                            title: 'Confirmation Request',
                            message: this.$config.ConfirmReqErr,
                            position: 'topRight'
                        });
                    }
                });
            return true;
        },

        loadYearlyRecruitments() {

            var currentDate = new Date();
            var month = currentDate.getMonth();
            let data = { "createdYear": "2019" };

            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/yearly/recruitment/candidate', data)
                .then(response => {

                    let result = response.data;
                    this.values = result.recruitment;

                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadRecruitmentsByJob(jobId) {
            var currentDate = new Date();
            var month = currentDate.getMonth();
            let data = { "jobId": jobId };

            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/recruitment/job', data)
                .then(response => {

                    let result = response.data;
                    this.values = result.recruitment;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        setInterviewerId(id) {
            this.interviewId = id;
        },
        setCandidateId(id) {
            this.candidateId = id;
        },
        interviewConfirmation(type) {
            axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/referral/interviewer/approval', { 'id': this.interviewId, 'statusName': type })
                .then(response => {
                    let result = response.data;
                    if (result.status) {
                        iziToast.success({
                            title: 'Confirmation Request',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadInterviewerList();
                    } else {
                        iziToast.error({
                            title: 'Confirmation Request',
                            message: this.$config.ConfirmReqErr,
                            position: 'topRight'
                        });
                    }
                });
            return true;
        },
        loadReferralCounts() {
            axios.get(this.$store.getters.getAPIBasePath + '/dashboardresource/referral/dashboard')
                .then(response => {
                    let result = response.data;
                    if (result.status) {
                        this.referral.clients = result.clients;
                        this.referral.candidates = result.candidates;
                        this.referral.interviewers = result.interviewers;
                        this.referral.referrals = result.referrals;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
    }
}

</script>

