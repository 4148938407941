<template>
    <div id="interviewer-edit-block" class="interviewer-edit-block mt-70">
        <div class="container-fluid driver-model" id="main-data">
            <div class="row">
                <div class="col-md-3">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card border-5 mb-3">
                                <h6 class="about-box m-0 text-left p-2">
                                    <a href="javascript:" @click="$router.go(-1)" title="Back" class="p-2">
                                        <i class="fa fa-arrow-left"></i>
                                        <span> Back</span>
                                    </a>
                                    <router-link class="float-right link pr-2" title="Edit Recruiter" :to="{ name: 'editrecruiter', params: {recruiterid: profileId} }"><i class="fa fa-pencil-square-o fa_custom fa-lg px-1"></i> </router-link>
                                </h6>
                                <div class="user-card pl-5 pr-5 pt-3">
                                    <div class="information">
                                        <div class="imgUp">
                                            <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' +  (ProfileData.image===''?ProfileData.imagePath:ProfileData.image) + ')' }"></div>
                                        </div>
                                        <div class="name">{{ProfileData.firstName}} {{ProfileData.lastName}}</div>
                                        <div>{{ProfileData.position}} </div>
                                        <div class="position"><i v-if="checkEmpty(ProfileData.email)" class="ti-email"></i> {{ProfileData.email}}</div>
                                        <div class="position"><i v-if="checkEmpty(ContactInfo.mobileNo)" class="ti-mobile"></i> {{ContactInfo.mobileNo}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card border-5">
                                <div class="user-card p-0">
                                    <h5 class="about-box m-0 text-left p-2">About Me</h5>
                                    <div class="p-3 float-left w-100">
                                        <h5>Company</h5>
                                        <div class="informations">
                                            <span v-if="ProfileData.company == ''" class="badge p-2 mb-1">No records found</span>
                                            <span v-if="ProfileData.companyName != ''">{{ProfileData.company}}</span>
                                        </div>
                                        <div v-if="ContactInfo!== null" class="w-100 float-left">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="box-wrapper shadow-sm bg-white rounded pos-rel main-search">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row no-gutters">
                                    <div class="col-md-12 pop-tab">
                                        <div class="driver-model">
                                            <div class="row no-gutters">
                                                <b-tabs v-model="currentTab" small card class="tab-list driver-tab-list tab-bg">
                                                    <b-tab title="Primary Info">
                                                        <PersonalInfo :ProfileData=ProfileData ref="personalinfocomponent"></PersonalInfo>
                                                    </b-tab>
                                                    <b-tab title="Contact Info">
                                                        <ContactInfo :ContactInfo=ContactInfo ref="contactInfocomponent"></ContactInfo>
                                                    </b-tab>
                                                    <b-tab title="Settings">
                                                        <Settings :settingsInfo="settingsInfo" :profileId="profileId" @loadRecruiter="loadRecruiter" ref="settingscomponent"></Settings>
                                                    </b-tab>
                                                    <b-tab title="Activity Log">
                                                        <ActivityLog :profileId="profileId" :ActivityLog=ActivityLog ref="incidenthistorycomponent"></ActivityLog>
                                                    </b-tab>
                                                    <b-tab title="Caller Id">
                                                    <Inprogress/>
                                                        <!-- <AddCallerId ref="incidenthistorycomponent"></AddCallerId> -->
                                                    </b-tab>
                                                    <b-tab  title="Plan Info">
                                                        <plan :PlanData="PlanData" ref="planInfocomponent"></plan>
                                                    </b-tab>
                                                </b-tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import PersonalInfo from './blocks/viewrecruiter/PersonalInfo';
import ContactInfo from './blocks/viewrecruiter/ContactInfo';
import ActivityLog from './blocks/viewrecruiter/ActivityLog';
import AddCallerId from './blocks/viewrecruiter/AddCallerId';
import Plan from './blocks/viewrecruiter/Plan';
import Inprogress from '@/components/candidates/blocks/viewcandidate/Inprogress';
import Settings from '@/components/recruiters/blocks/editrecruiter/Settings';
export default {
    components: {
        PersonalInfo,
        ContactInfo,
        ActivityLog,
        Inprogress,
        Settings,
        AddCallerId,
        Plan
    },
    computed: {
        recownerid() {
            return this.$store.getters.currentUser.recruitOwner;
        }
    },
    data() {
        return {
            profileId: this.$store.getters.currentUser.profileId,
            elements: {
                showAvailabilityInfo: false
            },
            currentTab: 0,
            interviewerId: this.$route.params.interviewerid,
            ProfileData: {
                id: 0,
                firstName: '',
                lastName: '',
                nickName: '',
                email: '',
                dateOfBirth: '',
                gender: '',
                position: '',
                image: '',
                imageName: '',
                imagePath: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
                image: '',
                industryId: null,
                company: ''
            },
            PlanData: {
                planName: '',
                viewResumeCount: '',
                downloadResumeCount: '',
                shareResumeCount: '',
                expiryDate:''
            },
            ContactInfo: Object,
            ActivityLog: Object,
            settingsInfo: {
                timezone: "",
                accessLevel: 1
            },
            TechSkills: [],
            colours: ['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info']
        }
    },
    mounted() {
        this.loadRecruiter();
    },
    methods: {

        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },

        loadRecruiter: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/recruiterresource/view/recruiter/' + this.profileId)
                .then(response => {
                    if (response.data.status) {
                        let recruiterData = response.data.recruiter;
                        this.ProfileData.id = recruiterData.id;
                        this.ProfileData.firstName = recruiterData.firstName;
                        this.ProfileData.lastName = recruiterData.lastName;
                        this.ProfileData.nickName = recruiterData.nickName;
                        this.ProfileData.email = recruiterData.email;
                        this.ProfileData.dateOfBirth = recruiterData.dateOfBirth;
                        this.ProfileData.gender = recruiterData.gender;
                        this.ProfileData.position = recruiterData.position;
                        this.ProfileData.imageName = recruiterData.imageName;
                        this.ProfileData.imagePath = (recruiterData.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : recruiterData.imagePath);
                        // this.ProfileData.image = (recruiterData.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : recruiterData.imagePath);
                        this.ProfileData.industryId = recruiterData.industryId;
                        this.ProfileData.company = recruiterData.companyName;
                        this.updateSkills(recruiterData.interviewersSkill);
                        this.ContactInfo = Object.assign({}, recruiterData.recruiterContact);
                        this.settingsInfo.timezone = recruiterData.timezone == null ? '' : recruiterData.timezone;
                        this.PlanData.planName = recruiterData.planName;
                        this.PlanData.viewResumeCount =  recruiterData.viewResumeCount;
                        this.PlanData.downloadResumeCount =  recruiterData.downloadResumeCount;
                        this.PlanData.shareResumeCount =  recruiterData.shareResumeCount;
                        this.PlanData.expiryDate =  recruiterData.expiryDate;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        updateSkills: function(Skills) {
            let self = this;
            self.TechSkills = [];
            $.each(Skills, function(key, value) {
                if (value.technologySkillSet != null)
                    self.TechSkills.push(value.technologySkillSet);
            });
        }
    }
}

</script>
