<template>
	 <div id="main" class="mt-80 d-height">
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div v-if="false" class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
					</div>
					<div class="col-md-6 col-sm-6 col-6">
						<ul class="ul-horizontal box-tools text-right mb-0">
							<li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :class="isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
						</ul>
					</div>
				</div>
				 <form id="main-search" :class="isExpand?'d-block':'d-none'" v-on:submit.prevent="">
                        <div class="row form-group ten-columns">
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Plan Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" v-on:keyup.enter="FindPlan" v-model="params.servicePlan" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr pl-0">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;">End Date</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12 float-right">
                                        <button type="button" class="btn btn-primary float-left" @click="FindPlan" style="margin-left: 10px;">Find</button>
                                        <button type="button" class="btn btn-primary float-left" @click="resetFilter()" style="margin-left: 10px;">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
			</div>
		</div>
		<div class="container-fluid">
         <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
			<div class="row col-md-12 col-sm-12 col-12 py-3 mx-0">
				<div class="col-md-6 col-sm-6 col-6 text-left p-0">
					<h4 class="page-title">Service Plans
					</h4>
				</div>
				<div class="col-md-6 col-sm-6 col-6 p-0">
					 <button type="button" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">
                        <router-link :to="{ name: 'adminaddserviceplan' }" style="color: #fff;">Add Service Plan</router-link>
                    </button>
				</div>
			</div>
			<div class="col-md-12 table-responsive py-3">
				<table class="table table-borderless all-table text-left">
					<thead>
						<tr>
                            <th> </th>
                            <th>Plan Name</th>
                            <th>Service Charge</th>
                            <th>Period</th>
                            <th>Description</th>
                            <th class= "pl-5">Action</th>
                        </tr>
					</thead>
					<tbody>
						<tr v-if="ServiceplanList.length==0 && elements.loadingUsers == true">
							<td align="center" colspan="8">No Service Plans found</td>
						</tr>
						<template v-if="ServiceplanList.length" v-for="(serviceInfo,index) in ServiceplanList">
							<tr>
								<td>
									<a href="Javascript:void(0);" class="expand" data-targetopen="panel1">
										<span @click="getPerms(serviceInfo.id)"><i :class="(currentIndex==serviceInfo.id) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
										</span>
									</a>
								</td>
								<td>
									<a href="Javascript:void(0);" @click="setviewdata(serviceInfo)" v-b-modal.viewplan>{{serviceInfo.servicePlan}}</a>
								</td>
                                <td>{{serviceInfo.serviceCharge}}</td>
                                <td>{{serviceInfo.planPeriod}}</td>
                                <td>{{serviceInfo.description}}</td>
								<td class= "pl-4">
									<ul class="navbar-nav">
										<li class="nav-item">
                                        <a href="javascript:" @click="AddplanPerm(serviceInfo)" v-b-modal.planPer><span class="fa fa-plus fa-lg pr-2"></span></a>
											<a href="javascript:" @click="setdata(serviceInfo)" v-b-modal.editplan><span class="fa fa-pencil fa-lg pr-2"></span></a>
											<a href="javascript:" @click="deletePlan(serviceInfo.id)" data-toggle="modal" data-target="#removePlan" class="" title="Delete Plan"><span class="fa fa-trash fa-lg pr-2"></span> </a>
										</li>
									</ul>
								</td>
							</tr>
							<tr v-if="currentIndex==serviceInfo.id">
								<td colspan="8">
									<div class="col-md-12 table-responsive py-3">
										<table class="table table-borderless all-table text-left">
											<thead>
												<tr>
                                                    <th>Permission</th>
                                                    <th>Permission Count</th>
                                                    <th>Action</th>
                                                </tr>
											</thead>
											<tbody>
												<tr v-if="Permissioninfolist.length==0">
													<td align="center" colspan="5">No plan permissions found</td>
												</tr>
												<template v-if="Permissioninfolist.length" v-for="(Permission,index) in Permissioninfolist">
													<tr>
														<td>
															{{Permission.permission}}
														</td>
														<td>{{Permission.permissionCount}}</td>
														<td>
															<ul class="navbar-nav">
																<li class="nav-item">
                                                                    
																	<a href="javascript:" @click="EditplanPerm(Permission.id)" v-b-modal.EditplanPer><span class="fa fa-pencil fa-lg pr-2" title="Edit"></span></a>
																	<a href="javascript:" @click="deleteplanPerm(Permission.id,Permission.planId)" data-toggle="modal" data-target="#removePlanper" class=""><span class="fa fa-trash fa-lg pr-2" title="Delete"></span> </a>
																</li>
															</ul>
														</td>
													</tr>
												</template>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
            </div>
		</div>
        </b-overlay>
		<Dialog id="removePlan" :onYes="delete_Plan" :returnParams="delPlanParam" title="Delete Confirmation" message="Are you sure to delete ?" />
		<Dialog id="removePlanper" :onYes="delete_Planper" :returnParams="delPlanperParam" title="Delete Confirmation" message="Are you sure to delete ?" />
        <Editplanperm ref="EditplanRef" @refresh-per="getPerms(permPlanid.id)"  :seteditperdata="Permissioninfolist"/>
        <Planpermission ref="planPerRef" @refresh-per="getPerms(permPlanid.id)"  :setperdata="Permissioninfolist"/>
        <EditserviceDialog ref="editplanRef" @refresh-user="loadServiceplanList"  :SerData="serviceInfo" />
        <Viewserviceplan ref="viewplanRef" @refresh-user="loadServiceplanList"  :setviewdata="serviceInfo" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import EditserviceDialog from '@/components/admin/serviceplan/blocks/EditserviceDialog'
import Viewserviceplan from '@/components/admin/serviceplan/blocks/Viewserviceplan'
import Editplanperm from '@/components/admin/serviceplan/blocks/EditPlanpermission'
import Planpermission from '@/components/admin/serviceplan/blocks/Planpermission'
// import { BModal, VBModal } from 'bootstrap-vue'
export default {
    components: {
        EditserviceDialog,
        Viewserviceplan,
        Planpermission,
        Editplanperm
    },
    data() {
        return {
            showOverlay:false,
            isExpand: false,
			currentIndex: null,
            browsepagination: {
                totalrecords: 0,
                rowsPerPage: 10,
                currentpage: 1
            },
            searchlimit: this.$store.state.searchlimit,
            edit_mode: false,
            ServiceplanList: [],
            params: {
                servicePlan: "",
            },
            search: {
                limit: 10,
            },
            loading: false,
            elements: {
                loadingUsers: false,
            },
            delPlanParam: {
				index: -1,
				id: 0
			},
            delPlanperParam:{
                index: -1,
				id: 0
            },
            permPlanid: {
				id: "",
                planId: "",
            },
            Permissioninfolist:[],
            permissionInfo:{
                id:'',
                planId:'',
                permission:'',
                permissionCount:''
            },
            serviceInfo:{
                id:'',
                servicePlan:'',
                description:'',
                planPeriod:'',
                serviceCharge:'',
            },
        }
    },
    mounted() {
        this.loadServiceplanList();
    },
    methods: {
        async loadServiceplanList() {
            this.showOverlay=true;
            await this.getPlansList();
            this.showOverlay=false;
        },
        getPlansList() {
            this.showOverlay=false;
            return axios.post(this.$store.getters.getAPIBasePath + '/serviceplan/list',this.params)
                .then(response => {
                    let result = response.data;
                    if (result.status)
                        this.ServiceplanList = result.recordinfo;
                })
                .catch(e => {
                    this.showOverlay=false;
                    this.errors.push(e)
                });
        },
        FindPlan(){
            this.browsepagination.currentpage = 1;
             this.loadServiceplanList();
        },
        filterInterviewers() {
            this.loadServiceplanList();
        },
        resetFilter() {
            this.params.servicePlan = "";
            this.loadServiceplanList();
        },
        changeLimit(newlimit) {
            this.browsepagination.currentpage = 1;
            this.browsepagination.rowsPerPage = newlimit;
            this.loadServiceplanList();
        },
        deletePlan(id) {
			this.delPlanParam.id = id;
			return;
		},
        delete_Plan(){
            axios.delete(this.$store.getters.getAPIBasePath + '/serviceplan/delete/'+this.delPlanParam.id)
             .then(response => {
                    let result = response.data;
                    if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.loadServiceplanList();
                        }
                })
                .catch(error => {
                        console.log(error.data);
                });
        },
        deleteplanPerm(id, planId) {
			this.delPlanperParam.id = id;
			this.permPlanid.planId = planId;
			return;
		},
        delete_Planper: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/serviceplan/delete/serviceplanpermission/' + this.delPlanperParam.id)
			.then(response => {
				var result = response.data;
				if (result.status == "OK") {
					iziToast.success({title: 'Success', message: result.message, position: 'topRight'});
						//this.$emit('loadProjects');
						this.getPerms(this.permPlanid.planId);
					}
				})
			.catch(error => {
				console.log(error);
			});
        },
        getPerms(id){
            if (this.currentIndex == id) {
				this.currentIndex = null;
			} else {
				this.currentIndex = id;
			}
            axios.get(this.$store.getters.getAPIBasePath + '/serviceplan/list/permission/'+id)
             .then(response => {
                    let result = response.data;
                    if (result.status)
                        this.Permissioninfolist = result.recordinfo;
                })
                .catch(e => {
                    this.showOverlay=false;
                });
        },
        AddplanPerm(service){
            this.serviceInfo.id = service.id;
            this.serviceInfo.servicePlan = service.servicePlan;
            this.serviceInfo.description = service.description;
            this.serviceInfo.planPeriod = service.planPeriod;
            this.serviceInfo.serviceCharge = service.serviceCharge;
            // this.$refs.editplanRef.setData(this.serviceInfo);
            this.$refs.planPerRef.setperData(this.serviceInfo);
        },
        EditplanPerm(id){
            this.permPlanid.id=id;
            this.$refs.EditplanRef.seteditperData(this.Permissioninfolist);
        },
         setdata:function(service){
            this.serviceInfo.id = service.id;
            this.serviceInfo.servicePlan = service.servicePlan;
            this.serviceInfo.description = service.description;
            this.serviceInfo.planPeriod = service.planPeriod;
            this.serviceInfo.serviceCharge = service.serviceCharge;
            this.$refs.editplanRef.setData(this.serviceInfo);
        },
        setviewdata:function(viewserv){
            this.serviceInfo.id = viewserv.id;
            this.serviceInfo.servicePlan = viewserv.servicePlan;
            this.serviceInfo.description = viewserv.description;
            this.serviceInfo.planPeriod = viewserv.planPeriod;
            this.serviceInfo.serviceCharge = viewserv.serviceCharge;
            this.$refs.viewplanRef.setviewData(this.serviceInfo);
        },
    }
}

</script>
