<template>
    <div id="main" class="reports settings recruiter mt-70">
        <div class="row col-md-12 box-wrapper shadow-sm my-4 rounded pos-rel mx-0 px-0">
            <div class="wrapper settings-leftbar w-100 px-0">
                <aside class="main-sidebar bg-white">
                    <!-- <nav class="navbar navbar-static-top">
                        <a href="#" @click="sidebarToggle" class="fa fa-bars sidebar-toggle" data-toggle="push-menu" role="button">
                            <span class="sr-only">Toggle navigation</span>
                        </a>
                    </nav> -->
                    <section class="sidebar">
                        <div class="p-4 header d-flex flex-row justify-content-between">
                            <i class="fa fa-bars mr-3 fa-2x text-secondary" @click="sidebarToggle" data-toggle="push-menu" role="button"></i>
                            <div class="reports-title">
                                <h5>Reports</h5>
                            </div>
                            <div class="back-link">
                                <router-link :to="{name: recownerid? 'rmdashboard' : 'candidatesdashboard'}">
                                    <h5 class="text-secondary">
                                        <span class="ti-arrow-left py-4" title="Go Back"></span>
                                        <span class="pl-2 back-link">Back</span>
                                    </h5>
                                </router-link>
                            </div>
                        </div>
                        <ul class="sidebar-menu" data-widget="tree">
                            <li @click="elements.activeTab=1" :class="elements.activeTab==1?'left-tab-active':''"><a href="#"><img alt="images" src="@/assets/icons/kpi-icon.png" class="menuicon"> <span>Recruitment Performance Indicator</span></a></li>
                            <li @click="elements.activeTab=2" :class="elements.activeTab==2?'left-tab-active':''"><a href="#"><img alt="images" src="@/assets/icons/recruiter-performance.png" class="menuicon"> <span>Overall Recruiter Performance</span></a></li>
                            <li @click="elements.activeTab=3" :class="elements.activeTab==3?'left-tab-active':''" v-if="recownerid"><a href="#"><img alt="images" src="@/assets/icons/recruiter-performance.png" class="menuicon"> <span>Recruiter-wise Hiring</span></a></li>
                            <li @click="candidateReportTab" :class="elements.activeTab==4?'left-tab-active':''"><a href="#"><img alt="images" src="@/assets/icons/recruiter-performance.png" class="menuicon"> <span>Candidate Reports</span></a></li>
                            <li @click="jobReportTab" :class="elements.activeTab==5?'left-tab-active':''"><a href="#"><img alt="images" src="@/assets/icons/recruiter-performance.png" class="menuicon"> <span>Job Reports</span></a></li>
                            <li @click="recruitmentReportTab" :class="elements.activeTab==6?'left-tab-active':''"><a href="#"><img alt="images" src="@/assets/icons/recruiter-performance.png" class="menuicon"> <span>Recruitment Reports</span></a></li>
                            <li v-if="false" @click="elements.activeTab=7" :class="elements.activeTab==7?'left-tab-active':''"><a href="#"><img alt="images" src="@/assets/icons/hire-source.png" class="menuicon"> <span>Source of Hire</span></a></li>
                            <li v-if="false" @click="elements.activeTab=8" :class="elements.activeTab==8?'left-tab-active':''"><a href="#"><img alt="images" src="@/assets/icons/effectiveness.png" class="menuicon"> <span>Sourcing Channel Effectiveness</span></a></li>
                            <li @click="userActivitiesTab" :class="elements.activeTab==9?'left-tab-active':''"><a href="#"><img alt="images" src="@/assets/icons/effectiveness.png" class="menuicon"> <span>User Activities</span></a></li>
                            <li @click="reportActivitiesTab" :class="elements.activeTab==10?'left-tab-active':''"><a href="#"><img alt="images" src="@/assets/icons/effectiveness.png" class="menuicon"> <span>Referral Activities</span></a></li>
                        </ul>
                    </section>
                </aside>
                <div class="basic-block text-center">
                    <div class="content-wrapper">
                        <section class="content-header">
                            <KPIs v-if="elements.activeTab==1" @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity" />
                            <OverallRecruiterPerformance v-if="elements.activeTab==2"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity"/>
                            <RecruiterPerformance v-if="elements.activeTab==3"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity"/>
                            <CandidateReport v-if="elements.activeTab==4 && elements.subActiveTab==0" @setSubTab="setSubTab"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity"/>
                            <CandidateListReport v-if="elements.activeTab==4 && elements.subActiveTab==1" @setSubTab="setSubTab"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity" :candContactAccess="candContactAccess"/>
                            <CandidateCustomReport v-if="elements.activeTab==4 && elements.subActiveTab==2" :action="elements.action" @setSubTab="setSubTab" reportType="candidate"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity" :candContactAccess="candContactAccess"/>
                            <SharedCandidates v-if="elements.activeTab==4 && elements.subActiveTab==3" :action="elements.action" @setSubTab="setSubTab" reportType="candidate"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity"/>
                            <JobReport v-if="elements.activeTab==5 && elements.subActiveTab==0" @setSubTab="setSubTab"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity"/>
                            <JobListReport v-if="elements.activeTab==5 && elements.subActiveTab==1" @setSubTab="setSubTab"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity"/>
                            <JobCustomReport v-if="elements.activeTab==5 && elements.subActiveTab==2" @setSubTab="setSubTab" :action="elements.action"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity"/>
                            <RecruitmentReport v-if="elements.activeTab==6 && elements.subActiveTab==0" @setSubTab="setSubTab"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity" :candContactAccess="candContactAccess"/>
                            <RecruitmentDetailsReport v-if="elements.activeTab==6 && elements.subActiveTab==1" @setSubTab="setSubTab"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity" :candContactAccess="candContactAccess"/>
                            <RecruitmentCustomReport v-if="elements.activeTab==6 && elements.subActiveTab==2" @setSubTab="setSubTab" :action="elements.action"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity"/>
                            <RecruitmentWiseHiringReport v-if="elements.activeTab==6 && elements.subActiveTab==3" @setSubTab="setSubTab"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity" :candContactAccess="candContactAccess"/>
                            <HireSource v-if="elements.activeTab==7"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity"/>
                            <SourcingEffectiveness v-if="elements.activeTab==8"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity"/>
                            <UserActivities v-if="elements.activeTab==9 && elements.subActiveTab==0" @setSubTab="setSubTab"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity"/>
                            <ActivityLog v-if="elements.activeTab==9 && elements.subActiveTab==1" @setSubTab="setSubTab"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity"/>
                            <ResumeReport v-if="elements.activeTab==9 && elements.subActiveTab==2" @setSubTab="setSubTab"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity"/>
                            <BulkResumeLog v-if="elements.activeTab==9 && elements.subActiveTab==3" @setSubTab="setSubTab"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity"/>
                            <ReferralActivities v-if="elements.activeTab==10 && elements.subActiveTab==0" @setSubTab="setSubTab"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity"/>
                            <ReferralList v-if="elements.activeTab==10 && elements.subActiveTab==1" @setSubTab="setSubTab"  @addLogActivity="addLogActivity" @updateLogActivity="updateLogActivity"/>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KPIs from './blocks/reports/KPIs';
import RecruiterPerformance from './blocks/reports/RecruiterPerformance';
import OverallRecruiterPerformance from './blocks/reports/OverallRecruiterPerformance';
import CandidateReport from './blocks/reports/CandidateReport';
import CandidateListReport from './blocks/reports/candidate/CandidateListReport';
import CandidateCustomReport from './blocks/reports/candidate/CandidateCustomReport';
import SharedCandidates from './blocks/reports/candidate/SharedCandidates';
import JobReport from './blocks/reports/JobReport';
import JobListReport from './blocks/reports/job/JobListReport';
import JobCustomReport from './blocks/reports/job/JobCustomReport';
import RecruitmentReport from './blocks/reports/RecruitmentReport'
import RecruitmentDetailsReport from './blocks/reports/job/recruitment/RecruitmentDetailsReport';
import RecruitmentCustomReport from './blocks/reports/job/recruitment/RecruitmentCustomReport';
import RecruitmentWiseHiringReport from './blocks/reports/job/recruitment/RecruitmentWiseHiringsReport ';
import HireSource from './blocks/reports/HireSource';
import SourcingEffectiveness from './blocks/reports/SourcingEffectiveness';
import UserActivities from './blocks/reports/UserActivities';
import ReferralActivities from './blocks/reports/ReferralActivities';
import ActivityLog from './blocks/reports/activities/ActivityLog';
import ResumeReport from './blocks/reports/activities/ResumeReport';
import BulkResumeLog from './blocks/reports/activities/BulkResumeLog';
import ReferralList from './blocks/reports/referral/ReferralList';

export default {
    components: {
        KPIs,
        RecruiterPerformance,
        OverallRecruiterPerformance,
        CandidateReport,
        CandidateListReport,
        CandidateCustomReport,
        SharedCandidates,
        JobReport,
        JobListReport,
        JobCustomReport,
        RecruitmentReport,
        RecruitmentDetailsReport,
        RecruitmentCustomReport,
        RecruitmentWiseHiringReport,
        HireSource,
        SourcingEffectiveness,
        UserActivities,
        ActivityLog,
        ResumeReport,
        BulkResumeLog,
        ReferralActivities,
        ReferralList
    },
    data() {
        return {
            elements: {
                activeTab: 1,
                subActiveTab: 0,
                action: {
                    mode: "list",
                    id: null
                }
            },
            recownerid: this.$store.getters.currentUser.recruitOwner,
            candContactAccess: localStorage.getItem('candidateContactAccess')
        }
    },
    mounted(){
        this.addLogActivity('Recruitment Performance Indicator');
    },
    methods: {
        setSubTab: function(subTab, mode, id) {
            this.elements.subActiveTab = subTab;
            if (mode != "")
                this.elements.action.mode = mode;
            if (id)
                this.elements.action.id = id;
        },
        candidateReportTab: function() {
            this.elements.activeTab = 4;
            this.elements.subActiveTab = 0;
        },
        jobReportTab: function() {
            this.elements.activeTab = 5;
            this.elements.subActiveTab = 0;
        },
        recruitmentReportTab: function() {
            this.elements.activeTab = 6;
            this.elements.subActiveTab = 0;
        },
        userActivitiesTab: function() {
            this.elements.activeTab = 9;
            this.elements.subActiveTab = 0;
        },
        reportActivitiesTab: function() {
            this.elements.activeTab = 10;
            this.elements.subActiveTab = 0;
        },
        sidebarToggle: function(event) {
            $(".reports.settings").toggleClass('sidebar-collapse');
        },
        addLogActivity: function(action) {
            this.updateLogActivity();
            this.logActivityData = {
                entityName: 'Report',
                actionName: action,
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {})
                .catch(error => {});
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
