<template>
	<div class="recruitment-feedback" id="RecruitmentFeedback">
		<div class="modal-dialog mw-100 w-100" role="document">
			<div class="modal-content">
				<div class="modal-header text-white blue-bg-title pt-0 pb-0">
					<h5 class="modal-title pt-2" id="FeedbackModal">FEEDBACK</h5>
					<ul>
						<!-- <li><a href="javascript:" @click="getPrevUrl()" class="btn btn-primary pull-right" style="margin-top: 5px;margin-right: 10px;">Back</a></li> -->
						<li>
							<a href="javascript:" @click="$emit('closeFeedback')" class="ctm-btn close text-white opacity-100" aria-label="Close"><span aria-hidden="true">&times;</span></a>
						</li>
					</ul>
				</div>
				<div class="modal-body">
					<b-carousel v-if="feedback" id="recruitment-carousel" :controls="feedback?true:false" :interval="0" background="#fff" no-animation img-height=500 :indicators=false class="col-md-12 pl-0 pr-0">
						<b-carousel-slide img-blank pt-0 v-for="(Recruitment,index) in rec_interviews" :key="index">
							<div class="box-wrapper shadow-sm bg-white rounded pos-rel p-0">
								<div class="row">
									<div class="col-sm-12">
										<div class="modal-content border border-0">
											<div class="modal-header col-md-12 col-sm-12 col-12 pl-0 pr-0">
												<h4 class="text-center page-title w-100 p-2 d-inline-block text-white blue-bg-title">{{Recruitment.interviewTitle}}</h4>
											</div>
											<div role="tablist" class="interview-feedback-accordion">
												<!-- <button class="btn btn-primary m-1" v-for="(categorySkill,index) in Recruitment.interviewers" :key="index" @click="activeSkill = categorySkill" >{{categorySkill.interviewerName}} - [ {{categorySkill.categoryName}} ]</button> -->
												<div>
													<b-tabs content-class="mt-3">
														<b-tab v-for="(interviewers,index) in Recruitment.interviewers" :key="index" :title="`${interviewers.interviewerName}`">
															<b-card  no-body class="mb-1" v-for="(categorySkill,index) in interviewers.categorySkill" :key="index">
																<b-card-header header-tag="header" class="p-1" role="tab">
																	<b-button block href="#" v-b-toggle="'accordion-'+categorySkill.id"  variant="info" class="text-left">{{categorySkill.categoryName}}</b-button>
																</b-card-header>
																<b-collapse :id="'accordion-'+categorySkill.id" visible accordion="my-accordion" role="tabpanel" class="accordion-content">
																	<b-card-body>
																		<div v-for="(Technology,key) in categorySkill.technologyList" v-bind:key="key" class="row m-0 pt-1 pb-1 border-1-bottom" >
																			<div class="col-sm-3 pos-relative">
																				<span class="pt-8">{{Technology.technology}}</span>
																			</div>
																			<div class="col-sm-3 pos-relative">
																				<span v-for="n in Technology.rating" :key="n" class="rating-star mt-1">&#9733;</span>
																			</div>
																			<div class="col-sm-6">
																				<span class="pt-8 form-control status">{{Technology.comment}}</span>
																			</div>
																		</div>
																		<div class="col-sm-12 pos-relative pl-3">
																			<span class="data-output-label col-md-2 pl-1">Comment </span>
																			<span class="pt-10"> {{categorySkill.individualComment}}</span>
																		</div>
																	</b-card-body>
																</b-collapse>
															</b-card>
														</b-tab>
													</b-tabs>
												</div>
												<!-- <b-card  v-if="feedback" no-body class="mb-1" v-for="(categorySkill,index) in Recruitment.interviewers.categorySkill" :key="index">
													<b-card-header header-tag="header" class="p-1" role="tab">
														<b-button block href="#" v-b-toggle="'accordion-'+categorySkill.id"  variant="info" class="text-left">{{categorySkill.interviewerName}} - [ {{categorySkill.categoryName}} ]</b-button>
													</b-card-header>
													<b-collapse :id="'accordion-'+categorySkill.id" visible accordion="my-accordion" role="tabpanel" class="accordion-content">
														<b-card-body>
															<div v-for="(Technology,key) in categorySkill.technologyList" v-bind:key="key" class="row m-0 pt-1 pb-1 border-1-bottom" >
																<div class="col-sm-3 pos-relative">
																	<span class="pt-8">{{Technology.technology}}</span>
																</div>
																<div class="col-sm-3 pos-relative">
																	<span v-for="n in Technology.rating" :key="n" class="rating-star mt-1">&#9733;</span>
																</div>
																<div class="col-sm-6">
																	<span class="pt-8 form-control status">{{Technology.comment}}</span>
																</div>
															</div>
															<div class="col-sm-12 pos-relative">
																<span class="data-output-label col-md-2">Individual Comment: </span>
																<span class="pt-10"> {{categorySkill.individualComment}}</span>
															</div>
														</b-card-body>
													</b-collapse>
												</b-card> -->

												<!-- <div class="box-wrapper shadow-sm bg-white rounded pos-rel p-0">
													<div class="row">
														<div class="col-sm-12">
															{{activeContent}}
															<div class="modal-content border border-0">
																<b-card  v-if="feedback" no-body class="mb-1">
																	<b-card-header header-tag="header" class="p-1" role="tab">
																		<b-button v-if="interviewerName!='undefined'" block href="#" v-b-toggle="'accordion-'+activeSkill.id"  variant="info" class="text-left blue-bg-title">{{interviewerName}}</b-button>
																	</b-card-header>
																	<b-collapse :id="'accordion-'+activeSkill.id" visible accordion="my-accordion" role="tabpanel" class="accordion-content">
																		<b-card-body>
																			<div v-for="(Technology,key) in skillSet" v-bind:key="key" class="row m-0 pt-1 pb-1 border-1-bottom" >
																				<div class="col-sm-3 pos-relative">
																					<span class="pt-8">{{Technology.technology}}</span>
																				</div>
																				<div class="col-sm-3 pos-relative">
																					<span v-for="n in Technology.rating" :key="n" class="rating-star mt-1">&#9733;</span>
																				</div>
																				<div class="col-sm-6">
																					<span class="pt-8 form-control status">{{Technology.comment}}</span>
																				</div>
																			</div>
																			<div class="col-sm-12 pos-relative pl-2 blue-bg-title">
																				<span class="data-output-label col-md-2 pl-0 text-white">Individual Comment: </span>
																				<span class="pt-10 text-white"> {{individualComment}}</span>
																			</div>
																		</b-card-body>
																	</b-collapse>
																</b-card>
															
															
															</div>
														</div>
													</div>
												</div> -->
												
												<div class="card col-md-12 data-cell mt-4">
													<div v-if="feedback" class="card-header p-3">
														<span class="data-output-label col-md-3">Overall Comments</span>
														<span class="data-output-value col-md-3">{{Recruitment.overallComments}}</span>
														<span class="data-output-label col-md-3">Overall Rating</span>
														<span class="data-output-value col-md-3">
															<fieldset class="rating">
																<span v-for="n in Recruitment.overallRating" :key="n" class="rating-star mt-1">&#9733;</span>
															</fieldset> 
														</span>
														<div style="clear:both"></div>
													</div>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</b-carousel-slide>
					</b-carousel>
					<div v-if="feedback==false" class="card-header text-center p-3">
						<span>No feedback found</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import iziToast from 'izitoast';
	export default {
		props: {
			recId: Number
		},
		// computed: {
		// 	activeContent() {
		// 		console.log('activeSkill',this.activeSkill);
		// 		if(this.startFlag) {
		// 			// this.interviewerName=this.activeSkill.categorySkill[0].interviewerName + ' -  [ '+ this.activeSkill.categorySkill[0].categoryName +' ]';
		// 			// this.skillSet=this.activeSkill.categorySkill[0].technologyList;
		// 			// this.individualComment = this.activeSkill.categorySkill[0].individualComment
		// 			this.interviewerName=this.activeSkill.interviewers[0].categorySkill[0].interviewerName + ' -  [ '+ this.activeSkill.interviewers[0].categorySkill[0].categoryName +' ]';
		// 			this.skillSet=this.activeSkill.interviewers[0].technologyList;
		// 			this.individualComment = this.activeSkill.interviewers[0].categorySkill[0].individualComment
		// 		}
		// 		else {
		// 			this.interviewerName=this.activeSkill.interviewerName + ' -  [ '+ this.activeSkill.categoryName +' ]';
		// 			this.skillSet=this.activeSkill.technologyList;
		// 			this.individualComment = this.activeSkill.individualComment
		// 		}
		// 		this.startFlag = false;
		// 		console.log(this.interviewerName);
		// 		//return this.content.filter((x) => x.category === this.activeCategory);
		// 	}
		// },
		data(){
			return {
				rec_interviews: [],
				feedback: false,
				activeSkill: [],
				interviewerName: null,
				skillSet: [],
				individualComment: null,
				startFlag: true
			}
		},
		mounted(){
			this.viewFeedback(this.recId);
		},
		methods: {
			viewFeedback(recruitmentId){
				axios.get(this.$store.getters.getAPIBasePath+'/interviewresource/view/feedbacks/'+recruitmentId).then(response =>{
					if(response.data.status){
						this.rec_interviews=response.data.interviews
						// console.log(this.rec_interviews);
						// this.activeSkill=this.rec_interviews[0];
						// this.interviewerName=this.activeSkill.interviewers[0].categorySkill[0].interviewerName + ' -  [ '+ this.activeSkill.interviewers[0].categorySkill[0].categoryName +' ]';
						// this.skillSet=this.activeSkill.interviewers[0].technologyList;
						// this.individualComment = this.activeSkill.interviewers[0].categorySkill[0].individualComment
						// console.log('this.interviewerName',this.interviewerName);
						// console.log('this.activeSkill',this.activeSkill);
						this.feedback=true
					}
					else{
						this.feedback=false
						this.rec_interviews=[]
						// let errmsg = (response.data.message!=undefined)?response.data.message:'No feedback found';
						// iziToast.error({title: 'Error', message: errmsg, position:'topRight'});
					}

				})
				.catch(error => {
					this.feedback=false
					this.rec_interviews=[]
					let errmsg = this.$config.LoadingErr;
					iziToast.error({title: 'Error', message: errmsg, position:'topRight'});
				});
			}
		}
	}
</script>
