<template>
    <div id="main">
        <div class="container-fluid" id="quick-search">
            <div class="row h-50">
                <div class="col-sm-12 h-100 d-table">
                    <div class="d-table-cell align-middle login_div">
                        <div class="col-md-4 offset-md-4">
                            <div class="interviewer-register-block">
                                <div v-if="!showForm" class="box-wrapper shadow-sm p-5 mb-4 bg-white rounded pos-rel">
                                    <h3 class="text-color text-center" style="text-transfomargin-top:10px">Confirmation Message</h3>
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col-sm-12 clientinfo">
                                                <p style="text-align: center;">{{message}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="approval" :onYes="confirmApproval" :onNo="cancelApproval" title="Approval" :message="title" />
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {
    computed: {
        key() {
            return this.$route.params.key;
        },
        type() {
            return this.$route.params.type;
        },
    },
    data() {
        return {
            message: "",
            showForm: true,
            showConfirm: false,
            title: "Are you sure you want to accept this interview request?"
        }
    },

    mounted() {
        this.isInterviewersSelected();
        if (this.type == 'accept') {
            this.title = "Are you sure you want to accept this interview request?";
        } else if (this.type == 'reject') {
            this.title = "Are you sure you want to to reject this interview request?";
        }
    },
    methods: {
        loadCheckAccessToken(key) {

            let data = { "key": this.key };
            axios.get(this.$store.getters.getAPIBasePath + '/interviewerresource/update/public/confirmrequest/' + this.key)
                .then(response => {
                    let result = response.data;
                    if (result.status) {
                        this.message = result.message;
                    } else {
                        this.message = result.message;
                    }
                    this.showForm = false;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        confirmApproval: function() {
            this.loadCheckAccessToken(this.key);
        },
        cancelApproval: function() {

        },
        isInterviewersSelected: function() {         
            axios.get(this.$store.getters.getAPIBasePath + '/interviewerresource/public/confirm/interviewrequest/' + this.key)
                .then(response => {
                    this.showConfirm = response.data;

                    if (this.showConfirm) {
                        this.message = "Interviewers already selected for the interview.";
                        this.showForm = false;
                    } else {
                        $('#approval').modal('show');
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
    }
}

</script>
