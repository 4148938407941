import Viewclients from '@/components/clients/Viewclients';
import Addclient from '@/components/clients/Addclient';
import Editclient from '@/components/clients/Editclient';
import ViewClient from '@/components/clients/ViewClient';

export default [
    {
		path: '/clients',
		name: 'viewclients',
		component: Viewclients,
		meta:{accessedby:'recruiter'}
	},
	{
		path: '/clients/add',
		name: 'addclient',
		component: Addclient,
		meta:{accessedby:'recruiter'}
	},
	{
		path: '/clients/edit/:clientid',
		name: 'editclient',
		component: Editclient,
		meta:{accessedby:'recruiter'}
	},
	{
		path: '/client/view/:clientid',
		name: 'viewclient',
		component: ViewClient,
		meta:{accessedby:'recruiter'}
	}
];