<template>
    <div id="main" class="tech-skill-settings settings recruiter">
        <div class="row">
            <div class="col-12">
                <h5>
                    <span v-if="activityIndex<0">Task/Activity Settings</span>
                    <span v-if="activityIndex>=0">Activity Settings</span>
                    <a v-if="activityIndex<0" href="javscript:" class="pull-right btn-back text-blue" @click="$emit('setSubTab',0)"><i class="ti-arrow-left"></i> Back</a>
                     <a v-if="activityIndex>=0" href="javscript:" class="pull-right btn-back text-blue" @click="$emit('setSubTab',0)"><i class="ti-arrow-left"></i> Back</a>                   
                </h5>
                <p></p>
                <h5 v-if="activityIndex>=0">TaskType : {{taskTypeSelected}}</h5>
            </div>
        </div>
        <p>&nbsp;</p>
        <div v-if="activityIndex<0">
            <div class="row" v-if="editIndex<0">
                <div class="col-md-6 col-sm-8 col-6">
                    <multiItemInput id="multiTech" placeholder="Enter new Task type" :items="addTaskTypes"></multiItemInput>
                    <p v-if="formErrors.length">
                        <ul>
                            <li class="error" v-for="error in formErrors">{{ error.message }}</li>
                        </ul>
                    </p>
                </div>
                <div class="col-md-2 col-sm-4 col-1 pt-2">
                    <a href="javscript::void(0);" @click="addTaskType" class="px-2"><span class="ti-plus"></span></a>
                </div>
            </div>
            <div class="row" v-if="editIndex!=-1">
                <div class="col-md-6 col-sm-8 col-6">
                    <input class="form-control" placeholder="Enter new Task type" v-model="taskType.name" />
                </div>
                <div class="col-md-2 col-sm-4 col-1 pt-2">
                    <a style="float:left;margin-right:5px;" href="javscript:" @click="updateTaskType(1)"><span class="ti-check"></span></a>
                    <a style="float:left;" href="javscript:" @click="updateTaskType(0)"><span class="ti-close"></span></a>
                </div>
            </div>
            <div class="row item" v-for="(taskType, index) in taskTypeList" v-bind:key="index">
                <div class="col-md-6 col-sm-8 col-6">
                    <div class="form-control">{{taskType.name}}</div>
                </div>
                <div class="col-md-2 col-sm-4 col-1 pt-2">
                    <span v-if="false"> <a href="javscript:" @click="removeTech(index)" title="Remove" class="px-2"><span class="ti-trash"></span></a></span>
                    <a href="javscript:"  @click="removeTask(index,taskType.id)" data-toggle="modal" data-target="#removTask" title="Remove" class="px-2"><span class="ti-trash"></span></a>
                    <a href="javscript:" @click="editTaskType(index)" title="Edit" class="px-2"><span class="fa fa-pencil fa-lg"></span></a>
                    <a href="javscript:" @click="openActivity(index)" title="Activities" class="px-2"><span class="ti-files"></span></a>
                </div>
            </div>
        </div>
        <div v-if="activityIndex>=0">
            <div class="row" v-if="editActivityIndex<0">
                <div class="col-md-11 col-sm-8 col-11">
                    <multiItemInput id="multiSkill" placeholder="Enter new Activity" :items="addActivities"></multiItemInput>
                    <p v-if="formActivityErrors.length">
                        <ul>
                            <li class="error" v-for="error in formActivityErrors">{{ error.message }}</li>
                        </ul>
                    </p>
                </div>
                <div class="col-md-1 col-sm-4 col-1">
                    <a href="javscript:" @click="addActivity"><span class="ti-plus"></span></a>
                </div>
            </div>
            <div class="row" v-if="editActivityIndex!=-1">
                <div class="col-md-11 col-sm-8 col-11">
                    <input class="form-control" placeholder="Enter new Activity" v-model="activityName" />
                </div>
                <div class="col-md-1 col-sm-4 col-1">
                    <a style="float:left;margin-right:5px;" href="javscript:" @click="updateActivity(1)"><span class="ti-check"></span></a>
                    <a style="float:left;" href="javscript:" @click="updateActivity(0)"><span class="ti-close"></span></a>
                </div>
            </div>
            <div class="row ">
                <div class="col">
                    <div class="group-ctrl" v-for="(activity, index) in taskTypeActivities" v-bind:key="index">
                        <ul>
                            <li>{{activity.activityName}}</li>
                            <!-- <li class="icon"><a href="javscript:" @click="editSkill(index)"><span class="ti-pencil"></span></a></li> -->
                            <li class="icon"><a href="javscript:" @click="removeSkill(index)"><span class="ti-trash"></span></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="col-md-11 col-sm-8 col-11">
                    
                    <div class="form-control" >{{skill.activityName}}</div>
                </div>
                <div class="col-md-1 col-sm-4 col-1">
                    <a style="float:left;margin-right:5px;" href="javscript:" @click="removeSkill(index)" ><span class="ti-trash"></span></a>
                    <a style="float:left;" href="javscript:" @click="editSkill(index)"><span class="ti-pencil"></span></a>
                </div> -->
            </div>
        </div>
         <Dialog id="removTask" :onYes="deleteTask" :returnParams="delParam" />
    </div>
</template>
<style scoped>
div.item {
    margin-top: 5px;
}

</style>
<script>
import multiItemInput from '@/components/plugins/multiItemInput';
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import iziToast from "izitoast";

export default {
    components: {
        multiItemInput
    },
    data() {
        return {
            formErrors: [],
            formActivityErrors: [],
            taskTypeActivities: [],
            activityName: '',
            addActivities: [],
            addTaskTypes: [],
            editActivityIndex: -1,
            taskTypeSelected: '',
            activityIndex: -1,
            editIndex: -1,
            delParam: {
                index: -1,
                id: 0
            },
            taskTypeList: [],
            taskType: { id: 0, name: '' }

        }
    },

    mounted() {
        this.loadTaskTypes();
    },
    methods: {
        loadTaskTypes() {
            axios.get(this.$store.getters.getAPIBasePath + '/settingsresource/list/tasktype')
                .then(response => {
                    this.taskTypeList = response.data.taskTypeList;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addTaskType() {

            let taskTypeList = this.addTaskTypes.map(taskTypeName => ({ name: taskTypeName }));
            axios.post(this.$store.getters.getAPIBasePath + '/settingsresource/add/task/type', { taskTypeList: taskTypeList })
                .then(response => {

                    this.taskType.name = "";
                    this.addTaskTypes = [];
                    this.formErrors = [];
                    iziToast.success({
                        title: "Success",
                        message: response.data.message,
                        position: "topRight"
                    });
                    this.loadTaskTypes();
                })
                .catch(error => {
                    this.formErrors = error.response.data.validationErrorList;
                });
        },
        updateTaskType(mode) {

            if (mode == 1) {
                axios.post(this.$store.getters.getAPIBasePath + '/settingsresource/update/task/type', this.taskType)
                    .then(response => {
                        this.taskTypeList.name = this.taskType.name;
                        this.editIndex = -1;
                        this.taskType = { id: 0, name: '' };
                        iziToast.success({
                            title: "Success",
                            message: this.$config.TskTypeUpSuc,
                            position: "topRight"
                        });
                        this.loadTaskTypes();
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            } else {
                this.editIndex = -1;
                this.taskType = { id: 0, name: '' };
            }

        },
        removeTask:function(arg1, arg2)
            {
                 console.log("dsd",arg2)
                this.delParam.index=arg1;
                this.delParam.id=arg2;
            },
         deleteTask:function(arg)
            {
            axios.delete(this.$store.getters.getAPIBasePath + '/settingsresource/delete/settingstasktype/'+this.delParam.id)
                 .then(response => {
                    let result = response.data;
                    if (result.status == true && result.message == "Settings Task Type Deleted Successfully.") {
                        iziToast.success({
                            title: "Success",
                            message: result.message,
                            position: "topRight",
                            timeout: 1000
                        });
                        this.taskTypeList.splice(arg.index, 1);
                    }else if (result.status == true && result.message == "Cannot Delete Settings Task Type."){
                        iziToast.info({
                            title: "info",
                            message: result.message,
                            position: "topRight"
                        });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        removeTech(index) {
            this.taskTypeList.splice(index, 1);
            this.editIndex = -1;
            this.taskType = { id: 0, name: '' };
        },
        editTaskType(index) {

            this.editIndex = index;
            this.taskType = {
                id: this.taskTypeList[index].id,
                name: this.taskTypeList[index].name,
                taskTypeList: [{ activityName: "1" }] //because of validation not null
            };
        },
        openActivity(index) {
            this.editIndex = -1;
            this.taskType = { id: 0, name: '' };
            this.activityIndex = index;
            this.taskTypeSelected = this.taskTypeList[index].name;
            /*this.taskTypeActivities = this.taskTypeList.map(activities => ({ taskTypeId : activities.id, activityName: activities.name }));*/

            this.loadTaskTypeActivities(this.taskTypeList[index].id);

        },
        loadTaskTypeActivities(taskTypeId) {
            axios.get(this.$store.getters.getAPIBasePath + '/settingsresource/tasktype/activity/' + taskTypeId)
                .then(response => {
                    this.taskTypeActivities = response.data.activityList;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addActivity() {
            let activities = this.addActivities.map(activity => ({
                id: null,
                taskTypeId: this.taskTypeList[this.activityIndex].id,
                activityName: activity
            }));

            axios.post(this.$store.getters.getAPIBasePath + '/settingsresource/add/task/type/activity', { taskTypeActivityList: activities })
                .then(response => {
                    this.showActivity();
                    this.formActivityErrors = [];
                    iziToast.success({
                        title: "Success",
                        message: this.$config.ActCreSuc,
                        position: "topRight"
                    });
                    this.loadTaskTypes();
                })
                .catch(error => {
                    this.formActivityErrors = error.response.data.validationErrorList;
                });
        },
        updateActivity(mode) {
            if (mode == 1) {
                let activity = { id: this.taskTypeActivities[this.editActivityIndex].id, activityName: this.activityName };
                axios.post(this.$store.getters.getAPIBasePath + '/settingsresource/update/task/type/activity', activity)
                    .then(response => {
                        /*this.taskTypeList[this.activityIndex].settingsTechnologySkill[this.editActivityIndex].activityName = this.activityName;*/
                        this.taskTypeActivities[this.editActivityIndex].activityName = this.activityName;
                        this.editActivityIndex = -1;
                        this.activityName = '';
                        iziToast.success({
                            title: "Success",
                            message: this.$config.ActUpSuc,
                            position: "topRight"
                        });
                    })
                    .catch(function(error) {
                        console.log(error);
                    });

            } else {
                this.editActivityIndex = -1;
                this.activityName = '';
            }

        },
        showActivity() {
            this.taskTypeActivities = [];
            this.activityName = '';
            this.addActivities = [];
            this.editActivityIndex = -1;
            this.taskTypeSelected = '';
            this.activityIndex = -1;
        },
        removeSkill(index) {
            /*let skillItems = [{
                    categoryId: null,
                    technologyId: this.taskTypeList[this.activityIndex].id,
                    activityName: '',
                    isDeleted: 1,
                    id: this.taskTypeActivities[index].skillId
                }];
            */

            let id = this.taskTypeActivities[index].id;

            axios.delete(this.$store.getters.getAPIBasePath + '/settingsresource/delete/tasktype/activity/' + id)
                .then(response => {

                    this.taskTypeActivities.splice(index, 1);
                    this.taskTypeList[index].splice(index, 1);
                    this.editActivityIndex = -1;
                    this.activityName = '';

                })
                .catch(function(error) {
                    console.log(error);
                });

        },
        editSkill(index) {
            this.editActivityIndex = index;
            this.activityName = this.taskTypeActivities[index].activityName;
        }
    }
}

</script>
