<template>
    <div class="">
        <div class="card">
            <div class="card-header p-2">Edit Client Account</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <!-- <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>First Name</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="text" name="firstName" v-model="clientInfo.firstName" maxlength="45" size="30" class="form-control" />
                                        <span class="required-icon"></span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <label v-if="$v.clientInfo.firstName.$error && !$v.clientInfo.firstName.required" class="text-danger">First Name is required</label>
                                    <label v-if="$v.clientInfo.firstName.$error && !$v.clientInfo.firstName.minLength" class="text-danger">First Name must've minimum 2 characters</label>
                                    <label v-if="$v.clientInfo.firstName.$error && !$v.clientInfo.firstName.maxLength" class="text-danger">Maximum limit is 30 characters</label>
                                </div>
                            </div>
                        </div>
                        <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Last Name</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" name="lastName" v-model="clientInfo.lastName" maxlength="45" size="30" class="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Email</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="companyEmail" name="companyEmail" v-model="clientInfo.companyEmail" maxlength="45" size="30" class="form-control" @blur="$v.clientInfo.companyEmail.$touch" />
                                        <span class="required-icon"></span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <label v-if="$v.clientInfo.companyEmail.$error && !$v.clientInfo.companyEmail.required" class="text-danger">Email is required</label>
                                    <label v-if="$v.clientInfo.companyEmail.$error && !$v.clientInfo.companyEmail.companyEmail" class="text-danger">Invalid Email</label>
                                    <label v-if="$v.clientInfo.companyEmail.$error && $v.clientInfo.companyEmail.companyEmail && !$v.clientInfo.companyEmail.emailexists" class="text-danger">Email already exists</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Account Name</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="text" name="accountName" v-model="clientInfo.accountName" maxlength="45" size="30" class="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Account Type</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <select class="form-control" name="accountType" v-model="clientInfo.accountType">
                                        <option v-for="Options in accountType" v-bind:key="Options.id" :value="Options.id" :disabled="Options.name =='Individual'">{{Options.name}}</option>
                                    </select>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <label v-if="$v.clientInfo.accountType.$error && !$v.clientInfo.accountType.required" class="text-danger">Account Type is required</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Company Name</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="text" name="companyName" v-model="clientInfo.companyName" maxlength="45" size="30" class="form-control" />
                                        <span class="required-icon"></span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <label v-if="$v.clientInfo.companyName.$error && !$v.clientInfo.companyName.required" class="text-danger">Company Name is required</label>
                                    <label v-if="$v.clientInfo.companyName.$error && !$v.clientInfo.companyName.minLength" class="text-danger">Company Name must've minimum 2 characters</label>
                                    <label v-if="$v.clientInfo.companyName.$error && !$v.clientInfo.companyName.maxLength" class="text-danger">Maximum limit is 30 characters</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Phone No</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input id="companyPhoneno" type="text" name="companyPhoneno" v-model="clientInfo.companyPhoneno" maxlength="10" size="30" class="form-control" @keypress="formatUsPhone($event,'#companyPhoneno')" @blur="$v.clientInfo.companyPhoneno.$touch" />
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <label v-if="$v.clientInfo.companyPhoneno.$error && !$v.clientInfo.companyPhoneno.usPhone" class="text-danger">Invalid Phone No.</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Country</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <select class="form-control" name="companyCountry" v-model="clientInfo.companyCountry">
                                            <option value="">Select</option>
                                            <option v-for="Country in Countries" v-bind:key="Country.country_Id" :value="Country.country_Id">{{Country.name}}</option>
                                            <!-- <option v-for="State in USStates" v-bind:key="State.id" :value="State.id">{{State.stateName}}</option> -->
                                        </select>
                                        <span class="required-icon"></span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <label v-if="$v.clientInfo.companyCountry.$error && !$v.clientInfo.companyCountry.required" class="text-danger">Country is required</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Referral</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <!-- <Select2 v-model="clientInfo.referralId" :options="referralList" /> -->
                                    <select class="form-control" name="country" v-model="clientInfo.referralId">
                                        <option value="">Select</option>
                                        <option v-for="referral in referralList" v-bind:key="referral.id" :value="referral.id">{{referral.referralName}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Cand Contact Access</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <select class="form-control" name="plan" v-model="clientInfo.candidateContactAccess">
                                        <option value="">Select</option>
                                        <option v-for="access in candContactAccess" v-bind:key="access.id" :value="access.id">{{access.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div> 
                         <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Client Access Level</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <select class="form-control" name="plan" v-model="clientInfo.clientAccessLevelId">
                                        <option value="" selected="selected">Select</option>
                                        <option v-for="access in clientAccessList" v-bind:key="access.id" :value="access.id">{{access.clientAccessLevel}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>                      
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Time Zone</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <Select2 v-model="clientInfo.timezone" :options="filteredTimeZones" :settings="{width:'45%'}"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            
        </div>
        
        </div>

        <div class="card">
            <div class="card-header p-2">Settings</div>
            <div class="card-body p-2">
                <div class="pl-22 row">
					<div class="col-md-4 col-sm-12 input-group radio_btns p-2">Salary Negotiation<span class="pl-2">
                        <p>
					        <input type="radio" id="acceptlocal1"  v-model="clientInfo.salaryNegotiationAccess" value="true" name="acceptlocal" checked="">
								<label for="acceptlocal1">Enable</label>
						</p>
						<p>
							<input type="radio" id="acceptlocal2"  v-model="clientInfo.salaryNegotiationAccess" value="false" name="acceptlocal">
							<label for="acceptlocal2">Disable</label>
						</p></span>
					</div>
                    <div class="col-md-4 col-sm-12 input-group radio_btns p-2">Display Vendor and Client Information
                        <span class="pl-2">
                            <input class="form-check-input" type="checkbox" v-model="clientInfo.vendorClientInfoAccess" id="dispalyInfo" value="true">
                            <label class="form-check-label" for="dispalyInfo"></label>   
                        </span>
					</div>
                    <div class="col-md-3 col-sm-12 input-group radio_btns p-2">Offer Letter<span class="pl-2">
                        <p>
					        <input type="radio" id="enable"  v-model="clientInfo.offerLetterAccess" value="true" name="offer" checked="">
								<label for="enable">Enable</label>
						</p>
						<p>
							<input type="radio" id="disable"  v-model="clientInfo.offerLetterAccess" value="false" name="offer">
							<label for="disable">Disable</label>
						</p></span>
					</div>
				</div>
            </div>
            <div class="card-header p-2"><a href="javascript:" @click="saveClient" class="btn btn-success float-right">Save</a></div>        
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import Select2 from 'v-select2-component';
import iziToast from 'izitoast';
const usPhone = helpers.regex('usPhone', /^\(\d{3}\)\s\d{3}-\d{4}$/);

export default {
    computed: {
        accountid() {
            return this.$route.params.accountid;
        }
    },
    components: {
        Select2
    },
    data() {
        return {
            elements: {
                submitted: false
            },
            Countries: this.$store.getters.getCountries,
            usstates: this.$store.getters.getUSStates,
            timeZones: this.$store.getters.getTimeZones,
            accountType: this.$store.getters.getAccountTypeOptions,
            candContactAccess: this.$store.getters.getCandContactAccess,
            planList: [],
            referralList: [],
            clientAccessList:[],
            clientInfo: {
                clientCode: "",
                referralId: "",
                firstName: "",
                lastName: "",
                accountName: "",
                companyId: "",
                companyName: "",
                accountType: "2",
                companyEmail: "",
                companyPhoneno: "",
                companyZipcode: "",
                companyCountry: "",
                timezone: "",
                planId: "2",
                clientAccessLevelId: "",
                candidateContactAccess: "",
                salaryNegotiationAccess: false,
                offerLetterAccess: false,
                vendorClientInfoAccess: false
            },
        }
    },
    validations: {
        clientInfo: {
            companyName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            accountType: {
                required
            },
            companyEmail: {
                required,
                email,
                emailexists(email) {
                    return axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/checkemailavailability', { email: email, ttClientAccountId: this.accountid })
                        .then(response => {
                            if (response.data.status) {
                                return true;
                            }
                            return false;
                        })
                        .catch(error => {
                            return false;
                        });
                }
            },
            companyPhoneno: {
                usPhone
            },
            companyCountry: {
                required
            },
        }
    },
    watch: {
        'clientInfo.companyCountry': {
            handler: function(value) {
                var filteredTimeZones = this.timeZones.filter(timeZone => {
                if (value === 'US' || value === 'CA') {
                    this.clientInfo.timezone = 'US/Eastern';
                    return timeZone.id.startsWith('US/');
                } else if (value === 'IN') {
                    this.clientInfo.timezone = 'Asia/Kolkata';
                    return timeZone.id === 'Asia/Kolkata';
                } else {
                    this.clientInfo.timezone = ''
                    return timeZone.id === '';
                }
                });
                this.filteredTimeZones = filteredTimeZones;
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.loadByAccountId();
        this.loadReferrals();
        this.loadServicePlan();
        this.loadClientAccesslevel();
    },
    methods: {
        formatUsPhone: function(evt, idx) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                var curchr = $(idx).val().length;
                var curval = $(idx).val();
                if (curchr == 3 && curval.indexOf("(") <= -1) {
                    $(idx).val("(" + curval + ")" + " ");
                } else if (curchr == 4 && curval.indexOf("(") > -1) {
                    $(idx).val(curval + ")-");
                } else if (curchr == 5 && curval.indexOf(")") > -1) {
                    $(idx).val(curval + "-");
                } else if (curchr == 9) {
                    $(idx).val(curval + "-");
                    $(idx).attr('maxlength', '14');
                }
                return true;
            }
        },
        getCountryName: function(countryCode) {
            if (!countryCode)
                return "";
            let country = this.countrieslist.filter(obj => {
                return obj.country_Id === countryCode;
            });
            return country[0].name;
        },
        getStateName: function(stateId) {
            if (!stateId)
                return "";
            let state = this.usstates.filter(obj => {
                return obj.id === stateId;
            });
            return state[0].stateName;
        },
        loadByAccountId() {
            axios.get(this.$store.getters.getAPIBasePath + '/recruiterresource/client/account/view/' + this.accountid)
                .then(response => {
                    let result = response.data;
                    if (!result.status) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$router.push('/candidates');
                    }
                    if (result.clientAccount) {
                        this.clientInfo = {
                            accountName: (result.clientAccount.accountName == null ? '' : result.clientAccount.companyName),
                            companyId: result.clientAccount.companyId,
                            companyName: result.clientAccount.companyName,
                            accountType: result.clientAccount.accountType,
                            accountTypeName: result.clientAccount.accountTypeName,
                            companyEmail: result.clientAccount.companyEmail,
                            companyPhoneno: result.clientAccount.companyPhoneno,
                            companyZipcode: result.clientAccount.companyZipcode,
                            companyCountry: result.clientAccount.companyCountry,
                            timezone: result.clientAccount.timezone,
                            referralId: (result.clientAccount.referralId == null ? '' : result.clientAccount.referralId),
                            planId: result.clientAccount.planId,
                            clientAccessLevelId: (result.clientAccount.clientAccessLevelId == null ? '' : result.clientAccount.clientAccessLevelId),
                            candidateContactAccess: result.clientAccount.candidateContactAccess,
                            salaryNegotiationAccess: (result.clientAccount.salaryNegotiationAccess == null ? false : result.clientAccount.salaryNegotiationAccess),
                            vendorClientInfoAccess: (result.clientAccount.vendorClientInfoAccess == null ? false : result.clientAccount.vendorClientInfoAccess),
                            offerLetterAccess: (result.clientAccount.offerLetterAccess == null ? false : result.clientAccount.offerLetterAccess),
                        
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadReferrals() {
            console.log("candContactAccess",this.candContactAccess)
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/referrallist')
                .then(response => {
                    let result = response.data;
                    this.referralList = result.referrallist;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        loadServicePlan() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/service/plan/list')
                .then(response => {
                    let result = response.data;
                    this.planList = result.servicePlanList;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
         loadClientAccesslevel() {
            axios.post(this.$store.getters.getAPIBasePath + '/clientaccesslevel/list')
                .then(response => {
                    let result = response.data;
                    this.clientAccessList = result.recordinfo;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        saveClient: function() {
            this.elements.submitted = true;
            this.$v.clientInfo.$touch();
            axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/update/client/account/' + this.accountid, this.clientInfo)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message: this.$config.cliupdatesuc , position: 'topRight' });
                    } else {
                        iziToast.error({ title: 'Error', message: this.$config.Updateerr, position: 'topRight' });
                    }
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.Updateerr, position: 'topRight' });
                });
            this.elements.submitted = false;
        }, 
    }
}

</script>
