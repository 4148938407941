 <template>
 <b-modal id="planPer" ref="planPerRef" centered @ok="closeModal()" modal-class="" title="" ok-only ok-title='OK'>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
            <div class="card">
            <div class="card-header p-2">Add Plan Permission</div>
            <div class="card-body p-2">
                <div class="basic-block col-md-12">
                    <div class="table-responsive">
                        <table class="table table-striped table-borderless s_table compare-table">
                         <thead>
                                    <tr>
                                        <th>Permission</th>
                                        <th>Permission Count</th>
                                    </tr>
                                </thead>
                            <tbody>
                                 <tr><td class="pl-0"><input type="text" v-model="newpermission.permission" class="form-control text_per"></td>
                                <td class="pr-0"><input type="text" v-model="newpermission.permissionCount" v-on:keyup.enter="addPerm(Permissioninfolist[0].planId)" class="form-control text_per"></td>
                                <td class="pt-3"><i class="ti-plus edit_plan" @click="addPerm(serviceInfo.id)"></i></td>
                                </tr>
                                <tr v-for="(Permissioninfo,index) in Permissioninfolist">
                                    <td>{{Permissioninfo.permission}}</td>
                                    <td>{{Permissioninfo.permissionCount}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
            </div>
        </b-modal>
</template>
<script>
import iziToast from "izitoast";
export default {
    name: 'Planper',
    props: {  
        setperdata: Array
    },
    data() {
        return {
            showOverlay:false,
            edit_mode: false,
            loading: false,
            newpermission:{
                id:0,
                planId:0,
                permission:"",
                permissionCount:"",
            },
             serviceInfo:{
                id:'',
                servicePlan:'',
                description:'',
                planPeriod:'',
                serviceCharge:'',
            },
            params: {
                servicePlan: "",
            },
            elements: {
                loadingUsers: false,
            },
            Permissioninfolist:[],
        }
    },
    mounted(){
        this.setperData(this.setperdata);
    },
    methods: {
      closeModal(bvModalEvt) {
            this.$bvModal.hide(planPer);
        },
        addPerm(id){
            if((this.newpermission.permission && this.newpermission.permissionCount)!=""){
            let addPerm = {id: 0,planId:id,permission:this.newpermission.permission,permissionCount: this.newpermission.permissionCount};
            axios.post(this.$store.getters.getAPIBasePath + '/serviceplan/add/permission',addPerm)
             .then(response => {
                    let result = response.data;
                    if (result.status=="ACCEPTED"){
                        iziToast.success({title: '',message: result.message,position: 'topRight'});
                        // this.$emit('refresh-per');
                        this.$refs.planPerRef.hide(); 
                    }
                     else{
                         iziToast.info({title: '',message: result.message,position: 'topRight'});
                    }
                    this.newpermission = {id: 0,planId:0,permission:'',permissionCount: ''};
                })
                .catch(e => {
                    this.showOverlay=false;
                    iziToast.info({title: '',message: "Service Plan Permission already exists.",position: 'topRight'});
                    this.newpermission = {id: 0,planId:0,permission:'',permissionCount: ''};
                });
             }
             else{
                iziToast.info({title: '',message: "fields should not be empty",position: 'topRight'});
             }
           },
         setperData:function(Service){
            this.serviceInfo.id = Service.id;
            this.serviceInfo.servicePlan = Service.servicePlan;
            this.serviceInfo.description = Service.description;
            this.serviceInfo.planPeriod = Service.planPeriod;
            this.serviceInfo.serviceCharge = Service.serviceCharge;
        }
    }
}
</script>