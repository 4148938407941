<template>
    <div id="main" class="template-settings settings recruiter mt-70">
        <div class="row col-md-12 box-wrapper shadow-sm my-4 mx-0 px-0 rounded pos-rel">
            <div class="col-md-3 settings-leftbar px-0 bg-white" id="social-media-accordion">
                <div class="p-4 row header mx-0">
                    <div class="col-md-9 px-0">
                        <h5>Project Management</h5>
                    </div>
                    <div class="col-md-3 px-0 text-right">
                        <h5 class="text-blue">
                            <span class="ti-arrow-left py-4" title="Go Back"></span>
                            <span class="pl-2" @click="getPrevUrl()">Back</span>
                        </h5>
                    </div>
                </div>
                <div @click="elements.activeTab=1" class="row col-md-12 py-4 mx-0 left-bar-tab" :class="elements.activeTab==1?'left-tab-active':''">
                    <div class="col-md-3">
                        <img src="@/assets/icons/settings-border.png" width="60%" height="80%">
                    </div>
                    <div class="col-md-8">Projects</div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="basic-block p-4 col-md-12 bg-white">
                    <Projects v-if="elements.activeTab==1 && elements.showList" :pagination="pagination" :getProjectName="getProjectName" @changeLimit="changeLimit" @loadProjects="loadProjects" @resetProjectsSearch="resetProjectsSearch" :projectList="projectList" :searchParams="search.params" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Projects from './projectsettings/Projects';
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
    components: {
        Projects,
    },
    data() {
        return {
            isExpand: false,
            projectList: [],

            elements: {
                activeTab: 1,
                action: "",
                showList: true,
            },
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                searchlimit: this.$store.state.searchlimit
            },
            search: {
                params: {
                    projectName: ""
                }
            },
            saveProject: {
                projectName: "",
                keyName: ""
            },
            data: {
                email: {
                    templatesList: [],
                    templateData: {}
                },
                socialmedia: {
                    socialMediaList: [],
                    templatesList: []
                }
            }
        }
    },
    validations: {
        saveProject: {
            projectName: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(50)
            }
        }
    },
    mounted() {
        this.$store.commit('refreshPagination');
    },
    methods: {
        getProjectName(value){
            this.search.params.projectName = value;
            this.pagination.currentPage = 1;
            this.loadProjects();
        },
        getPrevUrl() {
            this.$router.go(-1);
        },
        resetProjectsSearch() {
            this.search.params.projectName = "";
            this.loadProjects();
        },
        loadProjects() {

            axios.post(this.$store.getters.getAPIBasePath + '/projectresource/list/all/projects?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)

                .then(response => {
                    let result = response.data;
                    this.projectList = result.projectList;
                    this.pagination.totalRecords = result.pagination[0].total;
                    this.pagination.currentPage = result.pagination[0].currentPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addProject: function() {
            if (!this.validateProjectData()) {
                return false;
            }
            axios.post(this.$store.getters.getAPIBasePath + '/projectresource/add/project', this.saveProject)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                        this.$refs.createProjectModal.hide();
                        this.refreshProjectData();
                        this.loadProjects();
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                });
        },


        refreshProjectData: function() {
            this.saveProject.projectName = "";
            this.saveProject.keyName = "";
        },
        refreshPagination: function() {
            this.rowsPerPage = 10;
            this.pagination.currentPage = 1;
        },

        changeLimit: function(newlimit) {
            this.pagination.rowsPerPage = newlimit;
            this.pagination.currentPage = 1;
        }


    }
}

</script>
