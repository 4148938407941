<template>
    <div class="referenceDetailPopup">

        <b-modal id="editData" ref="editCallerRef" centered @ok="saveData"  @hide="clearData" modal-class="" title="Edit User" ok-only ok-title="Save" >
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="card-body p-0 px-0 mx-0 mb-1">
                                <div class="col-lg-12 col-xl-12">                                   
                                    
                      
                                        <div class="row mb-2">
                                            <div class="col-4 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block">Account Id</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <div class="input-group required-field-block">
                                                    <input v-model="editcallerinfo.accountId" id="email" type="text" class="form-control">
                                                    <span class="required-icon"></span>
                                                </div>                                       
                                                <label v-if="$v.editcallerinfo.accountId.$error && !$v.editcallerinfo.accountId.required" class="text-danger">Account Id is required</label>
                                                <label v-if="$v.editcallerinfo.accountId.$error && !$v.editcallerinfo.accountId.minLength" class="text-danger">First Name must've minimum 2 characters</label>
                                                <label v-if="$v.editcallerinfo.accountId.$error && !$v.editcallerinfo.accountId.maxLength" class="text-danger">Maximum limit is 30 characters</label>
                                            </div>                                         
                                        </div>
               
                                        <div class="row mt-0 mb-2">
                                            <div class="col-4 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block">Phone Number</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <div class="input-group required-field-block">
                                                    <input v-model="editcallerinfo.phoneNumber" id="email" type="text" class="form-control">
                                                    <span class="required-icon"></span>
                                                </div>                                       
                                                <label v-if="$v.editcallerinfo.phoneNumber.$error && !$v.editcallerinfo.phoneNumber.required" class="text-danger">phone Number is required</label>
                                               
                                            </div>                                                                          
                                        </div>

                                        <div class="row mt-0 mb-2">
                                            <div class="col-4 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block">Country Code</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <div class="input-group required-field-block">
                                                    <input v-model="editcallerinfo.countryCode" id="email" type="text" class="form-control">
                                                    <span class="required-icon"></span>
                                                </div>                                       
                                                <label v-if="$v.editcallerinfo.countryCode.$error && !$v.editcallerinfo.countryCode.required" class="text-danger">Country Code is required</label>
                                               
                                            </div>                                                                          
                                        </div>

                                         <div class="row mt-0 mb-2">
                                            <div class="col-4 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block">Caller Id</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <div class="input-group required-field-block">
                                                    <input v-model="editcallerinfo.callerId" id="email" type="text" class="form-control">
                                                    <span class="required-icon"></span>
                                                </div>                                       
                                                <label v-if="$v.editcallerinfo.callerId.$error && !$v.editcallerinfo.callerId.required" class="text-danger">Caller Id is required</label>
                                               
                                            </div>                                                                          
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!--<b>Custom Footer</b> -->
            <template v-if="false" #modal-footer="">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="saveData()" class="float-right" >Save</b-button>
                    <b-button size="sm" variant="primary" @click="clearData()" class="float-right  mr-2" >Cancel</b-button>
                
                </div>
            </template>

        </b-modal>
       
    </div>
</template>
<script>
import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import iziToast from 'izitoast';

export default {
    name: 'editData',

    props: {  
        callData: Object
    },

    data() {

        return {
            loading: false,
            error:false,
            editcallerinfo:{
                accountId: '',
                phoneNumber: '',
                callerId: '',
                countryCode: '',
                recruiterId:this.$store.getters.currentUser.profileId,
                id:0
            }
        }
     },
      validations: {
        editcallerinfo: {
            accountId: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            phoneNumber: {
                required
            },
             callerId: {
                required
            },
             countryCode: {
                required
            }
        }
    },
    mounted(){
        this.setData(this.callData);
    },
     methods: {
          setData:function(call){
            this.error = false;
            this.editcallerinfo.accountId = call.accountId;
            this.editcallerinfo.phoneNumber = call.phoneNumber;
            this.editcallerinfo.callerId = call.callerId;
            this.editcallerinfo.countryCode = call.countryCode;  
            this.editcallerinfo.recruiterId = this.$store.getters.currentUser.profileId;
            this.editcallerinfo.id = call.id;
        },
        saveData(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.error = false;
            this.$v.editcallerinfo.$touch();
            if(!this.$v.editcallerinfo.$invalid) {
            axios.put(this.$store.getters.getAPIBasePath + '/exotel/update', this.editcallerinfo)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {      
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CliInfoCreSuc,
                        position: 'topRight'
                       
                    });
                    }
                    this.$emit('refresh-caller');
                    this.$refs.editCallerRef.hide();
                   
                })
            }
        },
         clearData:function(){

        },
     },

}

</script>