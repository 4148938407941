<template>
	<div class="demo-media">
		<section class="media-section p-4">
			<div class="media-container">
				<div class="media-container-row">
					<div v-if="!mediaInfo.length" class="col-md-12 col-sm-6 text-center">
						<h5>No videos found</h5>
					</div>
					<div v-if="mediaInfo.length" class="video-wrapper p-4 col-12 col-md-6 col-lg-3" v-for="media in mediaInfo">
						<div class="video-img">
							<b-embed
							type="video"
							aspect="16by9"
							controls
							allowfullscreen
							><source :src="media.videoPath+media.videoName | getFilePath" type="video/mp4">
							</b-embed>
						</div>
						<div class="video-text-content">{{media.functionalityName}}</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	export default {
		props: {
			mediaInfo: {
				default: [],
				type: Array
			}
		}
	}
</script>