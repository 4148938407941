<template>
    <div class="kpis reports text-left">
        <div class="row">
            <div class="col-sm-12 p-10">
                <div id="recruiter-settings" class="recruiter settings mt-10">
                    <div class="container-fluid" id="quick-search">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="text-center">Recruitment Wise Hiring</h5>
                            </div>
                        </div>
                        <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <h4 class="page-title">Filter</h4>
                                </div>
                                <div class="col-md-6 col-sm-6 col-6">
                                    <ul class="ul-horizontal box-tools text-right mb-0">
                                        <li><a href="Javascript:void(0);" v-on:click="elements.isExpand=!elements.isExpand;" data-target="main-search" class="boxhide"><span :title="elements.isExpand?'Hide':'Expand'" :class="elements.isExpand?'ti-angle-up':'ti-angle-down'" class="hover-hand"></span></a></li>
                                        <li><i class="ti-close hover-hand px-2" @click="$emit('setSubTab',0);$emit('updateLogActivity');" title="Close"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <form id="main-search" :class="elements.isExpand?'d-block':'d-none'" class="pb-4">
                                <div class="row form-group ten-columns">
                                    <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Job Title</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" v-on:keyup.enter="loadJobs()" name="jobTitle" maxlength="50" value="" id="jobTitle" class="form-control" v-model="search.params.jobTitle">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Job Code</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" v-on:keyup.enter="loadJobs()" v-model="search.params.jobCode" name="jobId" maxlength="25" value="" id="jobId" class="smallCombo form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Recruiter</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <select class="form-control" v-model="search.params.recruiterId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in options.recruiterlist" :value="info.id" >{{info.recruiterName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Start Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledStartDates" placeholder="Start Date" name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.startDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>End Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledendDates" placeholder="End Date" name="endDate" value="" id="endDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.endDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label style="visibility: hidden;">Technology</label>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="button" @click="resetJobSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                                <button type="button" @click="loadRecruiters" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Go</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group ten-columns">
                                   <!-- <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Start Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.startDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>End Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker name="endDate" value="" id="endDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.endDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    </div>
                                    <!--<div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label style="visibility: hidden;">Technology</label>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="button" @click="resetJobSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                                <button type="button" @click="loadRecruiters" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Go</button>
                                            </div>
                                        </div>
                                    </div>-->
                                </div>
                                <div class="col-md-12">
                                </div>
                            </form>
                            <div class="load-indicator"></div>
                        </div>
                    </div>
                    <div class="container-fluid" id="main-data">
                        <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                            <div v-if="list.recruiterlist.length" class="row" style="margin-bottom: 15px;">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <h4 class="page-title">Recruiters Hired
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                            <div class="dropdown">
                                                <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                                <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                    <a v-for="(value, index) in options.searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                                </div>
                                            </div>
                                        </span>
                                    </h4>
                                </div>
                                <div class="col-md-6 d-none d-sm-none d-md-block">
                                   <span class="float-right" @click="exportRecruiterHired()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i></span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                       <table class="table table-borderless all-table">
                                         <thead>
                                                <tr>
                                                    <th></th>
                                                    <th class="" scope="col">Sl.No</th>
                                                    <th class="recruiter" scope="col">Recruiter Name</th>
                                                    <th class="status" scope="col">TalendIdentified</th>
                                                    <th class="status" scope="col">Hiredcount</th>
                                                    <th class="status" scope="col">Success Rate % Date</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                <tr v-if="list.recruiterlist.length==0">
                                                    <td colspan="6" align="center">No records found</td>
                                                </tr>
                                                <template v-for="(recruiterInfo, index) in list.recruiterlist">
                                                    <tr>
                                                        <td>
                                                            <a href="javascript:" class="expand" data-targetopen="panel1">
                                                                <span @click="getTalentIdentifiedHired(recruiterInfo.id)"><i :class="(currentIndex==recruiterInfo.id) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
                                                                </span>
                                                            </a>
                                                        </td>
                                                        <td>{{((pagination.currentPage-1) * (pagination.rowsPerPage)) + (index+1)}}</td>
                                                        <td class="recruiter">{{ recruiterInfo.firstName }}</td>
                                                        <td>
                                                            {{recruiterInfo.talentIdentified}}
                                                        </td>
                                                        <td>
                                                            {{recruiterInfo.hired}}
                                                        </td>
                                                        <td>{{ recruiterInfo.successRate }}</td>
                                                    </tr>
                                                     <tr v-if="currentIndex==recruiterInfo.id">
                                                        <td colspan="6">
                                                            <div class="table-responsive scroll-down-inbox">
                                                                <table class="table table-borderless all-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">Sl.No</th>
                                                                            <th class="text-left" scope="col">Recruiter Name</th>
                                                                            <th class="text-left" scope="col">Candidate Name</th>
                                                                            <th class="text-left" scope="col">Hired For</th>
                                                                            <th class="text-left" scope="col">Hired Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <template v-for="(candInfo, index1) in list.candidatelist">
                                                                            <tr>
                                                                                <td>{{(index1+1)}}</td>
                                                                                <td class="text-left">{{ candInfo.recruiterName }}</td>
                                                                                <td class="text-left">{{ candInfo.candidateName }}</td>
                                                                                <td class="text-left">{{ candInfo.hired }}</td>
                                                                                <td class="text-left">{{ candInfo.hiredDate | formatDate }}</td>
                                                                            </tr> 
                                                                        </template>
                                                                        <tr v-if="list.candidatelist.length==0">
                                                                            <td colspan="6" align="center">No Candidates found</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                         </tbody>       
                                       </table>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <b-pagination v-if="list.recruiterlist.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadRecruiters()"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
export default {
    components: {
        Multiselect
    },
    data() {
        return {
            elements: {
                isExpand: false,
                showadvancedsearch: false,
                currentExpandedId: null,
            },
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            options: {
                searchlimit: this.$store.state.searchlimit,
                industrylist: this.$store.getters.getIndustries,
                clientlist: this.$store.getters.getClients,
                jobStatuslist: this.$store.getters.getJobStatus,
                recruiterlist: this.$store.getters.getRecruiterList,
                //recruiterlist: this.$store.getters.getRecruiters,
                fieldList: {
                    candidateFieldList: [],
                    jobFieldList: [],
                    recruiterFieldList :[]
                }
            },
            search: {
                params: {
                    jobTitle: "",
                    jobType: "",
                    technologyId: "",
                    startDate: "",
                    endDate: "",
                    clientId: "",
                    industryId: "",
                    status: "",
                    recId: "",
                    recruiterId:""
                }
            },
            list: {
                joblist: [],
                candidatelist: [],
                recruiterlist: []
            },
            currentIndex: null,
            disabledStartDates: {
                from: new Date()
            
            },
            disabledendDates:{
                from: new Date(),
                to: ''
            }
        }
    },
     watch: {
        'search.params.startDate': {
            handler: function(value) {
                this.disabledendDates.to = new Date(value);
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
        this.$emit('updateLogActivity');
        this.$emit('addLogActivity','Recruitment Details Report'); 
        this.loadDate();
        //this.loadJobs();
        this.loadRecruiters();
        //this.loadFieldList(1); // Candidate Field List
        //this.loadFieldList(2); // Job Field List
        this.loadFieldList(3); // Recruiter Field List
        this.loadFieldList(4); // CandidateHIred List
        // console.log(this.loadFieldList(1));
        /*this.options.fieldList.candidateFieldList = this.loadFieldList(1).then(function(value){
        	return value;
        });
        this.options.fieldList.jobFieldList = this.loadFieldList(2).then(function(value){
        	return value;
        });
        console.log(this.options.fieldList.candidateFieldList);
        console.log(this.options.fieldList.jobFieldList);*/
    },
    methods: {
        resetJobSearch() {
            this.search.params = {
                jobTitle: "",
                jobType: "",
                technologyId: "",
                clientId: "",
                industryId: "",
                status: "",
                recId: "",
                jobId :"",
                recruiterId:"",
                startDate: "",
                endDate: "",
            };
            this.loadDate();
            this.disabledStartDates.from =  new Date();
            this.disabledendDates.from =  new Date();
            this.loadJobs();
            this.loadRecruiters();
            this.currentIndex = null;
        },
        loadDate() {
            let date = new Date();
            this.search.params.startDate =  date;//new Date(date.getFullYear(), date.getMonth(), 1);
            this.search.params.endDate = date;
        },
        loadFieldList: function(reportTypeId) {
            return new Promise((resolve, reject) => {
                axios.get(this.$store.getters.getAPIBasePath + '/commonresource/fieldlist/' + reportTypeId)
                    .then(response => {
                        if (response.data.status) {
                            let excludeArr = ["industryName", "referralName", "dateOfBirth", "candidateSource", "candidateStatus", "statusName", "communication", "technology", "closedDate", "jobDescription", "createdDate"];
                            let customFieldlist = response.data.fieldlist.filter(field => !excludeArr.includes(field.key));
                            if (reportTypeId == 1) {
                                let fieldset = customFieldlist.unshift({ key: "s_no", label: "S.No." });
                                this.options.fieldList.candidateFieldList = customFieldlist;
                            } else if (reportTypeId == 2) {
                                let fieldset = customFieldlist.unshift({ key: "show_details", label: " " });
                                this.options.fieldList.jobFieldList = customFieldlist;
                            } else if(reportTypeId == 3) {
                                console.log(customFieldlist);
                                let fieldset = customFieldlist.unshift({ key: "show_details", label: " " });
                                this.options.fieldList.recruiterFieldList = customFieldlist;
                            }
                            else if(reportTypeId == 4) {
                                console.log(customFieldlist);
                                let fieldset = customFieldlist.unshift({ key: "s_no", label: "S.No."  });
                                this.options.fieldList.candidateFieldList = customFieldlist;
                            }
                            // resolve(response.data.fieldlist);
                        } else {
                            if (reportTypeId == 1) {
                                this.options.fieldList.candidateFieldList = [];
                            } else if (reportTypeId == 2) {
                                this.options.fieldList.jobFieldList = [];
                            }else if (reportTypeId == 3) {
                                this.options.fieldList.recruiterFieldList = [];
                            }else if (reportTypeId == 4) {
                                this.options.fieldList.candidateFieldList = [];
                            }
                            // resolve([]);
                            iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        if (reportTypeId == 1) {
                            this.options.fieldList.candidateFieldList = [];
                        } else if (reportTypeId == 2) {
                            this.options.fieldList.jobFieldList = [];
                        }
                        // resolve([]);
                    });
            });
        },
        loadJobs() {
            this.search.params.pageNo = this.pagination.currentPage;
            this.search.params.limit = this.pagination.rowsPerPage;
            axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/jobreport' , this.search.params)
            //axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/jobreport?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.list.joblist = result.data;
                    console.log(result)
                    //this.pagination.totalRecords = result.pagination[0].total;
                    //this.pagination.currentPage = result.pagination[0].currentPage;
                    this.pagination.totalRecords = result.totalRecords;
                    this.pagination.currentPage = result.currentPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadRecruiters() {
            if (this.search.params.startDate > this.search.params.endDate) {
                    iziToast.error({
                        title: 'Error',
                        message: this.$config.DateMismatchErr,
                        position: 'topRight'
                    });
                }
            console.log(this.search.params);
            axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/recruiterReport?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.list.recruiterlist = result.recruiterHired;
                    console.log(this.list.recruiterlist);
                    this.pagination.totalRecords = result.pagination[0].total;
                    this.pagination.currentPage = result.pagination[0].currentPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        changeLimit(newlimit) {
            this.pagination.rowsPerPage = newlimit;
            this.pagination.currentPage = 1;
            //this.loadJobs();
            this.loadRecruiters();
        },
        getTalentIdentifiedHired(jobId) {
            console.log(this.currentIndex);
           // console.log(jobId);
            if (this.currentIndex == jobId) {
                this.currentIndex = null;
            } else {
                this.currentIndex = jobId;
            }
            this.search.params.jobId = jobId;
            console.log(jobId);
            console.log(this.search.params);
            axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/recruiter/identified/hired' , this.search.params)
                .then(response => {
                    console.log(response.data.identifiedCandidates);
                    this.list.candidatelist = response.data.identifiedCandidates;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        exportJobs() {
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/report/jobs?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "Jobs" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });
        },
        exportRecruiterHired() {
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/report/hiredCandidate?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "Hired" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });
        },
        getSerialNumber: function(index) {
            // (currentPage-1*rowsPerPage) + (index+1)
            return index + 1;
        }
    }
}

</script>
