<template>
    <div id="main" class="mt-70">
        <div class="container-fluid" id="quick-search">
            <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded pos-rel">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-6">
                        <h4 class="page-title">Search</h4>
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <ul class="ul-horizontal box-tools text-right">
                            <li><a @click="serarch_more_toggle" href="Javascript:void(0);" data-target="main-search" class="boxhide"><span :class="{'ti-angle-up': serarch_more, 'ti-angle-down': !serarch_more }" ></span></a></li>   
                        </ul>
                    </div>
                </div>
                <form v-if="serarch_more" id="main-search" style="margin-top:15px;">
                    <div class="row form-group ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label> Candidate Name</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                    <input type="text" v-modal="search.params.candidateName" maxlength="11" class="smallCombo form-control">
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Interviewer</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                    <input type="text" v-modal="search.params.interviewer" maxlength="11" class="smallCombo form-control">
                                    </div>
                                </div>
                            </div>  
                        </div>

                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Initiated Date</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <datepicker v-modal="search.params.initiatedDate" placeholder="Initiated Date" input-class="form-control" maxlength="10" wrapper-class="input-group date" format="MM/dd/yyyy"></datepicker>                                    
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb" style="visibility: hidden;">
                                    <label>End Date</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12 float-right">
                                    <button type="button" @click="loadPage(1);" class="btn btn-primary float-right" style="margin-left: 10px;">Reset</button>
                                    <button type="button" @click="loadPage(0);" class="btn btn-primary float-right" style="margin-left: 10px;">Find</button>
                                </div>
                            </div>  
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="container-fluid" id="main-data">
            <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                <div class="row" style="margin-bottom: 15px;">
                    <div class="col-md-6 col-sm-12 col-12">
                        <h4 class="page-title">Completed Interviews</h4>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 15px;">
                    <div class="col-md-12 col-sm-12 col-12">
                        Rows/Page
                        <select v-model="search.limit" @change="loadPage(0)">
                            <option v-for="(item, key) in this.$store.state.searchlimit" v-bind:key="key" :value="item">{{item}}</option>
                        </select>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-borderless all-table">
                                <thead>
                                <tr>
                                    <th>Candidate Name</th>
                                    <th>Interviewer(s)</th>
                                    <th>Interview Title</th>
                                    <th>Initiated Date</th>
                                    <th>Due Date</th>
                                    <th>Status</th>
                                    
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(Interview,index) in InterviewList" v-bind:key="index">
                                    <td>{{Interview.candidateName}}</td>
                                    <td>{{Interview.interviewers}}</td>
                                    <td>{{Interview.interviewTitles}}</td>
                                    <td>{{Interview.initiatedDate | formatDate}}</td>
                                    <td>{{Interview.dueDate | formatDate}}</td>
                                    <td>
                                        <span class="label bg-primary text-white p-1 rounded">Completed</span>                                         
                                    </td>
                                    
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <b-pagination v-if="page_conf.total>0" size="md" class='pull-right' :current-page="page_conf.currentPage" :total-rows="page_conf.total" v-model="page_conf.currentPage" :per-page="search.limit" @input="loadPage(0)"></b-pagination>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            InterviewList:{},
            serarch_more:true,
            page_conf:{
                currentPage:1,
                total:0,
            },
            search:{
                limit:10,
                params:{
                    candidateName:'',
                    interviewer:'',
                    initiatedDate:'',
                    status:''
                }
            },
            page_conf:{
                total:3,
                currentPage:1,                
            },
            
        }
    },
    mounted:function(){
        this.loadPage(0);
    },
    methods:{        
        loadPage:function(arg)
        {
            if(arg == 1)
            {
                this.search.params.candidateName='';
                this.search.params.interviewer='';
                this.search.params.initiatedDate='';
            }
            this.InterviewList=[
                {
                    candidateId:1,
                    candidateName:'Jeba A',
                    interviewers:'Jhon G',
                    interviewTitles:'Aaron Inc',
                    initiatedDate:'2019-08-22',
                    dueDate:'2019-08-28',
                    status:1,
                    statusTitle:'In Progress'
                },
                {
                    candidateId:2,
                    candidateName:'Jeba A',
                    interviewers:'Jhon G',
                    interviewTitles:'Aaron Inc',
                    initiatedDate:'2019-08-22',
                    dueDate:'2019-08-28',
                    status:1,
                    statusTitle:'In Progress'
                },
                {
                    candidateId:3,
                    candidateName:'Jeba A',
                    interviewers:'Jhon G',
                    interviewTitles:'Aaron Inc',
                    initiatedDate:'2019-08-22',
                    dueDate:'2019-08-28',
                    status:1,
                    statusTitle:'In Progress'
                }
            ];
        },
        serarch_more_toggle:function(){
            this.serarch_more = !this.serarch_more;
        },
        additional_interview:function(id){
            console.log(id);
            this.$router.push('scheduled-interviews')
        },
    }
}
</script>
