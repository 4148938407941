<template>
    <div class="modal" v-bind:id="id" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">{{title}}</h5>
                    <button type="button" class="close" data-dismiss="modal" @click="closeClick"  aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" v-html="message">
                </div>
                <div v-if="showFooter" class="modal-footer">                                            
                    <button v-if="yesButton" type="button" class="btn btn-primary" @click="yesClick" data-dismiss="modal" >Yes</button>
                    <button v-if="noButton" type="button" class="btn btn-secondary" @click="noClick" data-dismiss="modal">No</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        showFooter:{type:Boolean, default:true},
        id:{type:String, default:'_confirm1'},
        title:{type:String, default:'Delete Confirmation'},
        message:{type:String, default:'Are you sure to delete ?'},
        yesButton:{type:String, default:'1'},
        noButton:{type:String, default:'1'},
        returnParams:{type:Object, default:function () { return {} }},
        onYes:Function,
        onNo:{type:Function, default:function(){return false;}},
        onExit:{type:Function, default:function(){return false;}}
    },
    data(){
        return {
            htmlData: "<b>testing</b>"
        }
    },
    methods:{
        yesClick:function(){
            this.onYes(this.returnParams);
        },
        noClick:function(){
            this.onNo();
        },
        closeClick:function(){
            this.onExit();
        }
    }
}
</script>
