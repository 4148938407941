<template>
    <div>
        <div v-if="!showResult" class="col-md-12 col-12">
            <div class="basic-block text-center p-4">Click <a href="javascript:" @click="showVerificationDiv">here</a> to validate</div>
        </div>
        <div v-if="showResult" class="card-header p-2">Screening Results<span @click="showResult=false"><i class="ti-close pull-right font-weight-bold p-0 pr-3"></i></span></div>
        <div v-if="showResult" class="card-body p-2">
            <div class="col-md-12 col-12 p-0">
                <div class="col-md-12 col-12">
                    <div class="row row-tencolumns pt-3">
                        <div class="col-md-3 col-3">SSN verification</div>
                        <div class="col-md-7 col-7 pt-1">
                            <!-- <div class="progress">
                                <div class="progress-bar progress-bar-striped" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" ><span class="title">100%</span></div>
                            </div> -->
                            <b-progress :max="max">
                                <b-progress-bar :value="value" :label="`${((value / max) * 100)}%`" show-progress striped></b-progress-bar>
                            </b-progress>
                        </div>
                        <div v-if="showProgress" class="col-md-2 col-2">
                            <span><i class="ti-check-box font-weight-bold h4 p-0 pr-3 text-success"></i>Success</span>
                        </div>
                    </div>
                    <div class="row row-tencolumns pt-3">
                        <div class="col-md-3 col-3">Email verification</div>
                        <div class="col-md-7 col-7 pt-1">
                            <b-progress :max="max">
                                <b-progress-bar :value="value" :label="`${((value / max) * 100)}%`" show-progress striped></b-progress-bar>
                            </b-progress>
                        </div>
                        <div v-if="showProgress" class="col-md-2 col-2">
                            <span><i class="ti-check-box font-weight-bold h4 p-0 pr-3 text-success"></i>Success</span>
                        </div>
                    </div>
                    <div class="row row-tencolumns pt-3">
                        <div class="col-md-3 col-3">Phone verification</div>
                        <div class="col-md-7 col-7 pt-1">
                            <b-progress :max="max">
                                <b-progress-bar :value="value" :label="`${((value / max) * 100)}%`" show-progress striped></b-progress-bar>
                            </b-progress>
                        </div>
                        <div v-if="showProgress" class="col-md-2 col-2">
                            <span><i class="ti-check-box font-weight-bold h4 p-0 pr-3 text-success"></i>Success</span>
                        </div>
                    </div>
                    <div class="row row-tencolumns pt-3">
                        <div class="col-md-3 col-3">Passport verification</div>
                        <div class="col-md-7 col-7 pt-1">
                            <b-progress :max="max">
                                <b-progress-bar :value="value" :label="`${((value / max) * 100)}%`" show-progress striped></b-progress-bar>
                            </b-progress>
                        </div>
                        <div v-if="showProgress" class="col-md-2 col-2">
                            <span><i class="ti-na font-weight-bold h4 p-0 pr-3 text-danger"></i>Failed</span>
                        </div>
                    </div>
                    <div class="row row-tencolumns pt-3">
                        <div class="col-md-3 col-3">Driver Id verification</div>
                        <div class="col-md-7 col-7 pt-1">
                            <b-progress :max="max">
                                <b-progress-bar :value="value" :label="`${((value / max) * 100)}%`" show-progress striped></b-progress-bar>
                            </b-progress>
                        </div>
                        <div v-if="showProgress" class="col-md-2 col-2">
                            <i class="ti-check-box font-weight-bold h4 p-0 pr-3 text-success"></i><span>Success</span>
                        </div>
                    </div>
                    <div class="row row-tencolumns pt-3">
                        <div class="col-md-3 col-3">Identity verification</div>
                        <div class="col-md-7 col-7 pt-1">
                            <b-progress :max="max">
                                <b-progress-bar :value="value" :label="`${((value / max) * 100)}%`" show-progress striped></b-progress-bar>
                            </b-progress>
                        </div>
                        <div v-if="showProgress" class="col-md-2 col-2">
                            <span><i class="ti-check-box font-weight-bold h4 p-0 pr-3 text-success"></i>Success</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    props:{
        // screeninginfo:Object
    },
    data(){
        return {
            showResult:false,
            value: 100,
            max: 100,
            showProgress: false
        }
    },
    methods:{
        showVerificationDiv(){
            this.showResult = true;
            this.showSuccessDiv();
        },
        showSuccessDiv(){
            this.showProgress = false;
            var self = this;
            setTimeout(function(){
                self.showProgress = true;
            }, 1500);
        }
    }
}
</script>