<template>
	<div>
		<div class="card">
			<div class="card-header p-2"></div>
			<div class="card-body p-2">
				<div class="basic-block">
					<div class="row ten-columns">
	                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
	                        <div class="row">
	                           <div class="col-md-12 col-sm-12 col-12 lb">
	                               <label>Client Name</label>
	                           </div>
	                           <div class="col-md-12 col-sm-12 col-12">
	                                <div class="input-group required-field-block" >
	                                    <input type="text" v-model="clientinfo.clientName" maxlength="50" class="form-control text-required" >
	                                    <span class="required-icon">
	                                    </span>
	                                </div>
	                                <label v-if="$v.clientinfo.clientName.$error && !$v.clientinfo.clientName.required" class="text-danger">Please enter client name</label>
	                           </div>
	                        </div>  
	                    </div>
	                     <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
	                        <div class="row">
	                           <div class="col-md-12 col-sm-12 col-12 lb">
	                               <label>Client Code</label>
	                           </div>
	                           <div class="col-md-12 col-sm-12 col-12">
	                                <div class="input-group" >
	                                  <input type="text" disabled="disabled" name ="clientCode" v-model="clientinfo.clientCode" maxlength="10" class="form-control">
	                                </div>
	                           </div>
	                        </div>
	                    </div>
	                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
	                        <div class="row">
	                           <div class="col-md-12 col-sm-12 col-12 lb">
	                               <label>DUNS Number</label>
	                           </div>
	                           <div class="col-md-12 col-sm-12 col-12">
	                                <div class="input-group" >
	                                  <input type="number" v-model="clientinfo.dunsNumber" maxlength="10" class="form-control">
	                                </div>
	                           </div>
	                        </div>
	                    </div>
	                  
	                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
	                        <div class="row">
	                           <div class="col-md-12 col-sm-12 col-12 lb">
	                               <label>Account Name(Email Id)</label>
	                           </div>
	                           <div class="col-md-12 col-sm-12 col-12">
	                              <div class="input-group required-field-block" >
	                                  <input type="email" class="form-control" v-model="clientinfo.accountName" maxlength="50">
	                                  <span class="required-icon">
	                                  </span>
	                              </div>
								  <label v-if="$v.clientinfo.accountName.$error && !$v.clientinfo.accountName.required" class="text-danger">Please enter email id</label>
								  <label v-if="$v.clientinfo.accountName.$error && !$v.clientinfo.accountName.email" class="text-danger">email id not valid</label>
	                           </div>
	                        </div>  
	                    </div>
	
	                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
	                        <div class="row">
	                           <div class="col-md-12 col-sm-12 col-12 lb">
	                               <label>Website</label>
	                           </div>
	                           <div class="col-md-12 col-sm-12 col-12">
	                                <div class="input-group" >
	                                    <input type="url" v-model="clientinfo.url" maxlength="100" class="form-control">
	                                </div>
									<label v-if="clientinfo.url && !checkUrl()" class="text-danger">Invalid url</label>
	                           </div>
	                        </div>  
	                    </div>
	                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
	                        <div class="row">
	                           <div class="col-md-12 col-sm-12 col-12 lb">
	                               <label>Status</label>
	                           </div>
	                           <div class="col-md-12 col-sm-12 col-12">
	                                <select v-model="clientinfo.status" class="form-control">
	                                	<option value="" selected="selected">Select</option>
	                                	<option value="0">Active</option>
	                                	<option value="1">InActive</option>
	                                </select>
	                           </div>
	                        </div>  
	                    </div>
	                </div>
	                
				</div>
			</div>
		</div>
		<div class="card">
	        <div class="card-header p-2">Description</div>
	        <div class="card-body p-2">
	          <div class="basic-block">
	            <div class="row ten-columns">
					<div class="col-md-12">
						<div class="row">
							<div class="col-md-12 col-sm-12 col-xs-12">
								<textarea rows="3"  v-model="clientinfo.description"  class="form-control"></textarea>
							</div>
						</div>
					</div>
	            </div>
	          </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
	export default {
		props: {
            clientinfo: Object,
        },
		data(){
			return {
			}	
		},
		validations: {
            clientinfo:{
                clientName:{
                    required
				},
				accountName:{
					required,
					email
				}
            }
        },
		methods: {
			validateClientInfo(){
                this.$v.clientinfo.$touch();
                if (this.$v.clientinfo.$invalid || !this.checkUrl()){
                    return false;
                }
                return true;
            },
			checkUrl() {
      			const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
      			return urlRegex.test(this.clientinfo.url);
    		},
		}
	}
</script>