<template>
    <div class="view-note">
        <b-modal scrollable ref="viewNoteModal" size="lg" id="viewNoteModal" title="Note" ok-only @show="loadNotes">
            <form >
                <div class="row col-md-12 mx-0 px-0">
                    <div class="form-group col-md-12 col-sm-12">
                        <p class="htmlText" v-html="candidateNotes.callbackNotes"></p>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    props: {
        referenceId: Number,
    },
    components: {

    },
    data() {
        return {
            candidateNotes: {}
        }
    },
    mounted() {

    },
    methods: {
        loadNotes() {
            axios.get(this.$store.getters.getAPIBasePath + '/candidateresource/view/candidate/notes/' + this.referenceId)
                .then(response => {
                    if (response.data.status) {
                        this.candidateNotes = response.data.candidateNotes;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
