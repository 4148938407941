<template>
    <div id="main" class="tech-skill-settings settings recruiter">
        <div class="container-fluid">
            <div class=" shadow-sm p-4 mb-2 bg-white rounded ">
                <div class="row">
                    <div class="col-12">
                        <h5>
                            <span style="padding-top:10px;">Additional Field</span>
                            <a href="Javascript:void(0);" class="pull-right btn-back text-blue" @click="$emit('setSubTab',0)">
                                <i class="ti-arrow-left"></i> Back
                            </a>
                        </h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 col-sm-8 col-6">
                        <div class="input-group">
                            <input type="text" name="" placeholder="Enter Custom Field" maxlength="30" value="" v-model="name" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-8 col-6">
                        <div class="input-group">
                            <select class="form-control" v-model="valueFormat">
                               <!--  <option value="">Select Format</option>
                                <option value="1">Integer</option>
                                <option value="2">Varchar</option> -->

                                 <option value="">Select</option>
                                    <option v-for="(value, index) in valueFormatList" :value="value.id">{{value.valueFormat}}</option>
                            </select>
                            
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-4 col-1">
                        <!-- <a href="Javascript:void(0);" @click="addFieldSettings">
                            <span class="ti-plus">Save</span>
                        </a> -->
                        <!-- <button type="button" class="btn btn-primary btn-sm float-left"  @click="addFieldSettings" style="margin-top: 2px;">Save</button> -->
                        <a href="javascript:" @click="reset" class="cancel-icon btn btn-secondary mx-1">Cancel</a>
                        <a href="javascript:" @click="addFieldSettings" class="save-icon btn btn-primary mx-1">Save</a>
                    </div>
                </div>
            </div>
            <div class="box-wrapper shadow-sm pt-4 pb-4 bg-white rounded">
                <div calss="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-borderless all-table">
                                <thead>
                                    <tr>
                                        <th class="" scope="col" style="text-align:left; padding-left:20px;">Field name</th>
                                        <th class="accountManager" scope="col">Value Format</th>
                                        <th class="accountManager" scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody id="table-length">
                                    <tr v-if="nameValueList != null && nameValueList.length==0">
                                        <td colspan="4" align="center">No Additional Fields found</td>
                                    </tr>
                                    <tr v-for="(nameValueinfo, index) in nameValueList">
                                        <td class="accountManager" style="text-align:left; padding-left:20px;">{{ nameValueinfo.name }}</td>
                                        <td class="accountManager">{{ nameValueinfo.valueFormat }}</td>
                                        <td>
                                            <ul class="navbar-nav">
                                                <li class="nav-item dropdown">
                                                    <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                        <li>
                                                            <!-- <router-link class="dropdown-item" :to="{ name: 'editjob', params: { jobid: 1 }}"><span class="ti-pencil"></span>Edit</router-link> -->
                                                            <a class="dropdown-item" href="javascript:;" @click="editFieldSettings(nameValueinfo.id)" data-toggle="modal"><span class="ti-pencil pr-2"></span>Edit</a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" href="javascript:;" data-toggle="modal" data-target="#removeField" @click="changeIdValue(nameValueinfo.id)"><span class="ti-cut pr-2"></span>Delete</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="removeField" :onYes="deleteField" title="Delete Confirmation" message="Are you sure to delete field setting?" />
    </div>
</template>
<style scoped>
div.item {
    margin-top: 5px;
}

</style>
<script>
import iziToast from "izitoast";

export default {
    components: {

    },
    data() {
        return {
            id: '',
            name: '',
            valueFormat: '',
            editMode: 0,
            nameValueList: [],
            valueFormatList: [],
            sourcingChannelInfo: {
                sourcingChannel: ""
            },
            newNameValueList: [],
            editSourcingChannelList: [],
            sourcingChannelIndex: -1,
            selectedSourcingChannel: "",
            newSourcingChannel: { id: 0, sourcingChannel: "" }
        };
    },
    mounted() {
        this.loadFieldSettings();
        this.loadValueFormatList();
    },
    methods: {
        loadFieldSettings() {
            axios.post(this.$store.getters.getAPIBasePath + "/settingsresource/namevalue/list")
                .then(response => {
                    let result = response.data;
                    this.nameValueList = result.nameValueList;
                })
                .catch(error => {
                    iziToast.error({ title: "Error", message: error, position: "topRight" });
                });
        },
        addFieldSettings() {
            let url = "";
            let params = {};
            if (this.editMode === 0) {
                url = this.$store.getters.getAPIBasePath + "/settingsresource/add/namevalue";
                params = { name: this.name, valueFormat: this.valueFormat };
            } else if(this.editMode === 1) {
                url = this.$store.getters.getAPIBasePath + "/settingsresource/update/namevalue";
                params = { id: this.id, name: this.name, valueFormat: this.valueFormat };
            }
            axios.post(url, params)
                .then(response => {
                    this.reset();
                    iziToast.success({ title: "Success", message: this.$config.FieldSavSuc, position: "topRight" });
                    this.loadFieldSettings();
                })
                .catch(error => {
                    iziToast.error({ title: "Error", message: this.$config.EtrNameFor, position: "topRight" });
                });
        },
        editFieldSettings(id) {
            this.editMode = 1;
            this.id = id;
            axios.get(this.$store.getters.getAPIBasePath + "/settingsresource/view/namevalue/" + id)
                .then(response => {
                    this.name = response.data.nameValue.name;
                    this.valueFormat = response.data.nameValue.valueFormat;
                })
                .catch(error => {
                    iziToast.error({ title: "Error", message: error, position: "topRight" });
                });
        },
        loadValueFormatList() {
            axios.get(this.$store.getters.getAPIBasePath + "/settingsresource/valueformat/list")
                .then(response => {
                    let result = response.data;
                    this.valueFormatList = result.valueFormatList;
                })
                .catch(error => {
                    iziToast.error({ title: "Error", message: error, position: "topRight" });
                });
        },
        reset() {
            this.name = "";
            this.valueFormat = "";
            this.editMode = 0;
        },
        changeIdValue(id) {
            this.id = id;
            return;
        },
        deleteField() {

            axios.delete(this.$store.getters.getAPIBasePath + "/settingsresource/delete/name/value/" + this.id)
                .then(response => {
                    iziToast.success({ title: "Success", message: this.$config.SourchnlDelSuc, position: "topRight" });
                    this.loadFieldSettings();
                })
                .catch(error => {
                    iziToast.error({ title: "Error", message: error, position: "topRight" });
                });
        }
    }
};

</script>
