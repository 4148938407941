<template>
    <div class="auto-approvals text-left">
        <label>
            <h5>Auto Approvals</h5>
        </label>
        <div class="row col-md-12 col-sm-12 col-12 py-3 text-left">
            <div class="col-sm-6 col-6">
                <div class="form-check form-check-inline">
                    <b-form-checkbox id="checkbox-1" v-model="elements.autoAppovalReferral" name="checkbox-1">
                        Auto Approve New Referral
                    </b-form-checkbox>
                    <!-- <input type="checkbox"  class="form-check-input" v-model="general.autoAppovalReferral" :checked="general.autoAppovalReferral">
                    <label class="form-check-label" for="referral">Auto Approve New Referral</label> -->
                </div>
            </div>
        </div>
        <div class="row col-md-12 col-sm-12 col-12 py-3 text-left">
            <div class="col-sm-6 col-6">
                <div class="form-check form-check-inline">
                    <b-form-checkbox id="checkbox-2" v-model="elements.autoApprovalInterviewer" name="checkbox-2">
                        Auto Approve New Interviewer
                    </b-form-checkbox>
                    <!-- <input type="checkbox" class="form-check-input"  v-model="autoApprovalInterviewer">
                    <label class="form-check-label" for="interviewer">Auto Approve New Interviewer</label> -->
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-12 py-3 mx-0">
            <button type="button" class="btn btn-primary btn-sm float-left" @click="saveGeneral">Apply</button>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';

export default {   
    data() {
        return {
            elements: {
                activeTab: 1,
                action: "",
                showList: true,
                autoAppovalReferral: false,
                autoApprovalInterviewer: false,
            },
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                searchlimit: this.$store.state.searchlimit
            }
        }
    },
    mounted() {
        this.loadGeneral();
    },
    methods: {

        refreshPagination: function() {
            this.rowsPerPage = 10;
            this.pagination.currentPage = 1;
        },
        resetEmailTemplates: function() {
            this.refreshPagination();
            this.elements.activeTab = 1;
            this.elements.showList = true;
        },
        saveGeneral: function() {
            let general = {
                autoAppovalReferral: this.elements.autoAppovalReferral,
                autoApprovalInterviewer: this.elements.autoApprovalInterviewer,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/settingsresource/update/general', general)
                .then(response => {
                    let result = response.data;
                    if (result.status) {
                        iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                    }
                })
                .catch(error => {
                    iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                });
        },
        loadGeneral: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/settingsresource/view/general')
                .then(response => {
                    if (response.data.status) {
                        let result = response.data;
                        this.elements.autoAppovalReferral = result.general.autoAppovalReferral;
                        this.elements.autoApprovalInterviewer = result.general.autoApprovalInterviewer;
                    }
                });
        }

    }
}

</script>
