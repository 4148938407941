<template>
    <div class="recruiter-performance reports">
        <div class="row m-0">
            <div class="col-sm-12 p-10">
                <div id="recruiter-settings" class="recruiter settings mt-10">
                    <div class="box-wrapper shadow-sm bg-white rounded pos-rel">
                        <div class="basic-block">
                            <h5 style="background-color: rgb(38 133 198);color: #fff;" class="text-center p-2">Technology Standards</h5>
                            <div class="row col-md-12" style="padding-top: 10px; margin-left: 0px;">
                                <div class="table-responsive">
                                    <table class="table table-borderless all-table">
                                        <thead>
                                            <tr>
                                                <th class="" scope="col" style="text-align:left; padding-left:20px;">Technology</th>
                                                <th class="accountManager" scope="col">Standard</th>
                                            </tr>
                                        </thead>
                                        <tbody id="table-length">
                                            <tr v-if="technologylist != null && technologylist.length==0">
                                                <td colspan="4" align="center">No Technology found</td>
                                            </tr>
                                            <tr v-for="(tech, index) in technologylist">
                                                <td class="accountManager" style="text-align:left; padding-left:20px;">{{ tech.technology }}</td>
                                                <td>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" :id="'standard-chckbox-'+tech.id" checked="" @change="changeTechVal(tech.id,tech.isStandard)" v-model="tech.isStandard" :value="tech.isStandard" data-toggle="modal" data-target="#standard" />
                                                        <label class="form-check-label" :for="'standard-chckbox-'+tech.id"></label>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="standard" :onYes="updateTech" :onNo="cancelUpdate" :onExit="cancelUpdate" title="Confirmation" message="Are you sure to change this standard?" />
    </div>
</template>
<script>
import { GChart } from "vue-google-charts";
export default {
    components: {
        GChart
    },
    data() {
        return {
            browsepagination: this.$store.state.paginationconfig,
            searchlimit: this.$store.state.searchlimit,
            technologylist: [],

        }
    },
    mounted() {
        this.loadTechnologies();
    },
    methods: {

        loadTechnologies() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/list/all/technology')
                .then(response => {
                    this.technologylist = response.data.technologylist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        changeTechVal(id, isStandard) {

            this.newTech = {
                id: id,
                technologyList: [{ technology: 1 }], //because of validation not null
                isStandard: isStandard
            };

        },
        updateTech() {

            axios.post(this.$store.getters.getAPIBasePath + '/technologyresource/update/technology', this.newTech)
                .then(response => {
                    iziToast.success({
                        title: "Success",
                        message: this.$config.TechUpSuc,
                        position: "topRight"
                    });
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        cancelUpdate() {
        	this.loadTechnologies();
            this.newTech = {
                id: '',
                technologyList: [{ technology: 1 }], //because of validation not null
                isStandard: ''
            };
        }
    },

}

</script>
