<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="card-header p-2">
                    <div class="row">
                        <div class="col-md-6">
                            Add Referral
                        </div>
                        <div class="col-md-6 text-right">
                            {{referralinfo.referralCode}}
                        </div>
                    </div>
                </div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div v-if="false" class="row">
                            <div class="col-md-6 col-sm-8 col-6">
                                <p v-if="formErrors.length">
                                    <b>Please correct the following error(s):</b>
                                    <ul>
                                        <li class="error" v-for="error in formErrors">{{ error.message }}</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div class="row ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>First Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group required-field-block">
                                            <input type="text" name="firstName" maxlength="45" size="30" v-model="referralinfo.firstName" class="form-control " :class="{ 'is-invalid': $v.referralinfo.firstName.$error }">
                                            <span class="required-icon"></span>
                                        </div>
                                        <label v-if="$v.referralinfo.firstName.$error && !$v.referralinfo.firstName.minLength" class="text-danger">First Name must have at least {{$v.referralinfo.firstName.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.referralinfo.firstName.$error && !$v.referralinfo.firstName.required" class="text-danger">Please enter First Name</label>
                                        <label v-if="$v.referralinfo.firstName.$error && !$v.referralinfo.firstName.maxLength" class="text-danger">First Name must not exceed {{$v.referralinfo.firstName.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Last Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" name="lastName" maxlength="45" size="30" v-model="referralinfo.lastName" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group required-field-block">
                                            <input type="text" name="primaryEmail" maxlength="80" v-model="referralinfo.email" class="Submitaddress form-control" :class="{ 'is-invalid': $v.referralinfo.email.$error }" @blur="$v.referralinfo.email.$touch">
                                            <span class="required-icon"></span>
                                        </div>
                                        <label v-if="$v.referralinfo.email.$error && !$v.referralinfo.email.required" class="text-danger">Please enter Email</label>
                                        <label v-if="$v.referralinfo.email.$error && !$v.referralinfo.email.email" class="text-danger">Please enter a valid email</label>
                                        <label v-if="emailexists" class="text-danger">Email already exists</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Mobile</label>
                                        
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <PhoneNumber ref="phoneNumber" :value="referralinfo.referralContact.mobileNo" :mandatory="phoneMandatory" :isValidcheck="isValidCheck"  @phone-input="phoneControlInput" />
                                        
                                    </div>                                    
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Industry</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="referralinfo.industryId" class="form-control" required>
                                            <option value="" selected="true">Select</option>
                                            <option v-for="(Industry, index) in industries" :value="Industry.id" v-bind:key="index">{{Industry.industryName}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Position</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" name="primaryPhone" v-model="referralinfo.position" id="mobile" class="SubmitPhone form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Timezone</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <Select2 v-model="referralinfo.timezone" :options="timezones" :settings="{width:'100%'}" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 pt-2 mt-3 text-right">
                        <a href="Javascript:void(0);" @click="updateLogActivity()" class="btn btn-secondary mr-2">
                            <router-link :to="{ name: 'listreferrals' }" class="text-white">Cancel</router-link>
                        </a>
                        <a href="Javascript:void(0);" @click="saveReferral" class="btn btn-primary ">Invite</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs, helpers, requiredIf } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import Select2 from 'v-select2-component';
const usPhone = helpers.regex('usPhone', /^\(\d{3}\)\s\d{3}-\d{4}$/)

export default {
    components: {
        Multiselect,
        Select2
    },
    computed: {
        dobDates() {
            let date = new Date();
            date.setFullYear(date.getFullYear() - 15);
            return {
                from: date
            };
        },
        referralId() {
            if (this.$route.params.referralId)
                return this.$route.params.referralId;
            else return 0;
        }
    },
    data() {
        return {
            formErrors: [],
            elements: {
                submitted: false
            },
            emailexists: false,
            // technologies:this.$store.getters.getTechOptions,
            scopeList: this.$store.getters.getInterviewerScopeList,
            industries: this.$store.getters.getIndustries,
            timezones: this.$store.getters.getTimeZones,
            technology_value: "", //dropdown value
            availableDates: {
                to: new Date(Date.now() - 864000)
            },
            phoneMandatory:true,
            isValidCheck:false,
            referralinfo: {
                referralCode: "",
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                phoneNo: "",
                countryCode: "+1",
                blockpersonaltab: 0,
                viewonly: 0,
                scope: 1,
                industryId: "",
                position: "",
                referralContact: {
                    mobileNo: "",
                },
                timezone: ""
            }
        }
    },
    validations: {
        referralinfo: {
            firstName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            email: {
                required,
                email,

            },
            // referralContact:{
            //     mobile: {
            //         required
            //     },
            //     countryCode:{
            //     required: requiredIf(function() {
            //     return this.referralinfo.referralContact.mobile != null && this.referralinfo.referralContact.mobile.length > 0;
            //         })
            //     }
            // }
        }
    },
    mounted() {
        this.updateLogActivity();
        this.getReferralCode();
    },
    methods: {
        emailValidation(email) {
            if (email === '') return true;
            let ajaxdata = { email: email };
            if (this.referralinfo.profileid != undefined) {
                ajaxdata.id = this.referralinfo.profileid;
            }
            return axios.post(this.$store.getters.getAPIBasePath + '/referralresource/checkemailavailability', ajaxdata)
                .then(response => {
                    if (response.data.status) {
                        return true;
                    }
                    return false;
                })
                .catch(error => {
                    return false;
                });
        },
        getReferralCode() {
            axios.post(this.$store.getters.getAPIBasePath + '/commonresource/autoincrementcode/referral')
                .then(response => {
                    if (response.data.status) {
                        this.referralinfo.referralCode = response.data.code;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        
        async saveReferral() {
            //this.elements.submitted = true;
            //this.emailexists = false;
            this.$v.referralinfo.$touch();
            if (!this.isValidCheck && this.$refs.phoneNumber.validatePhone()) {
                if (!this.$v.referralinfo.$invalid){
                let isexists = await this.emailValidation(this.referralinfo.email);

                if (!isexists) {
                    this.emailexists = true;
                    iziToast.info({ message: this.$config.EmailExistErr, position: 'topRight' });
                    return;
                }

                // let postData = this.referralinfo;
                // if(postData.referralContact.mobile!= '')
                //     postData.referralContact.mobileNo = postData.referralContact.countryCode + " " + postData.referralContact.mobile;
                //     postData.countryCode = postData.referralContact.countryCode;
                axios.post(this.$store.getters.getAPIBasePath + '/referralresource/update/referral/' + this.referralId, this.referralinfo)
                    .then(response => {
                        let result = response.data;
                        if (!result.status) {
                            iziToast.error({
                                title: 'Error',
                                message: result.message,
                                position: 'topRight'
                            });
                        } else {
                            iziToast.success({
                                title: 'Success',
                                message: this.$config.RefDataSavSuc,
                                position: 'topRight'
                            });
                            this.$router.push('/recruiter/referrals');
                        }
                    })
                    .catch(error => {
                        this.formErrors = error.response.data.validationErrorList;
                        iziToast.error({
                            title: 'Error',
                            message: this.$config.CreRefErr,
                            position: 'topRight'
                        });
                    });
            }
        }
        },
        phoneControlInput:function(value,isValid){
            this.isValidCheck = isValid;
            this.referralinfo.referralContact.mobileNo = value;
        },
        addLogActivity: function() {
            this.logActivityData = {
                entityName: 'Referral',
                actionName: 'New Referral',
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {
                    localStorage.setItem('candIdForActivity', response.data.id);
                })
                .catch(error => {

                });
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        }
       
    }
}

</script>
