<template>
    <div class="kpis reports text-left">
        <div class="row">
            <div class="col-sm-12 p-10">
                <div id="recruiter-settings" class="recruiter settings mt-10">
                    <div class="container-fluid" id="quick-search">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="text-center">Activity Log</h5>
                            </div>
                        </div>
                        <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <h4 class="page-title">Filter</h4>
                                </div>
                                <div class="col-md-6 col-sm-6 col-6">
                                    <ul class="ul-horizontal box-tools text-right mb-0">
                                        <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :title="isExpand?'Hide':'Expand'" :class="isExpand?'ti-angle-up':'ti-angle-down'" class="hover-hand"></span></a></li>
                                        <li><i class="ti-close hover-hand px-2" @click="$emit('setSubTab',0);$emit('updateLogActivity');" title="Close"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <form id="main-search" :class="isExpand?'d-block':'d-none'">
                                <div class="row form-group ten-columns">
                                    <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>User Name</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" name="userName" maxlength="50" v-on:keyup.enter="loadLogActivities" value="" id="userName" class="form-control" v-model="search.params.userName">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Module</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" v-model="search.params.entityName" v-on:keyup.enter="loadLogActivities" name="module" maxlength="50" value="" id="module" class="smallCombo form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                    <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="date" v-model="search.params.loggedTime" class="form-control" id="date" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label style="visibility: hidden;"></label>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="button" @click="resetLogSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                                <button type="button" @click="loadLogActivities" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Go</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="load-indicator"></div>
                        </div>
                    </div>
                    <div class="container-fluid" id="main-data">
                        <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                            <div class="row" style="margin-bottom: 15px;">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <h4 class="page-title">Log Activities
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                            <div class="dropdown">
                                                <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                                <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                    <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                                </div>
                                            </div>
                                        </span>
                                    </h4>
                                </div>
                                <div v-if="false" class="col-md-6 d-none d-sm-none d-md-block">
                                    <span class="float-right" @click="exportJobs()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt="Download"></i></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive" style="min-height: 178px;">
                                        <table class="table table-borderless all-table">
                                            <thead>
                                                <tr>
                                                    <th class="text-center" scope="col">Sl.No</th>
                                                    <th class="text-center" scope="col">User Name</th>
                                                    <th class="text-center" scope="col">User Type</th>
                                                    <th class="" scope="col">Module</th>
                                                    <th class="" scope="col">Action</th>
                                                    <th class="text-center" scope="col">In Time</th>
                                                    <th class="text-center" scope="col">Out Time</th>
                                                    <th class="text-center" scope="col">Duration</th>
                                                    <th v-if="false" class="" scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="logActivities.length==0">
                                                    <td colspan="8" class="text-center">No data found</td>
                                                </tr>
                                                <tr v-for="(loginfo, index) in logActivities">
                                                    <td class="text-center">{{((pagination.currentPage-1) * (pagination.rowsPerPage)) + (index+1)}}</td>
                                                    <td class="text-center">{{ loginfo.userName }}</td>
                                                    <td class="text-center">{{ capitalizeFirstLetter(loginfo.userType) }}</td>
                                                    <td class="pr-3 ">
                                                        {{ loginfo.entityName }}
                                                    </td>
                                                    <td class="">{{ loginfo.actionName }}</td>
                                                    <td class="text-center">
                                                        <span v-if="loginfo.loggedTime != null">{{ loginfo.loggedTime | formatDateAndTime }}</span>
                                                        <span v-if="loginfo.loggedTime == null">--</span>
                                                        </td>
                                                    

                                                    <td class="text-center">
                                                        <span v-if="loginfo.loggedOutTime != null">{{ loginfo.loggedOutTime | formatDateAndTime }}</span> 
                                                        <span v-if="loginfo.loggedOutTime == null">-- </span>
                                                    </td>


                                                    <td class="text-center">
                                                        <span v-if="loginfo.loggedOutTime != null">{{ updateDiffs(loginfo.loggedTime,loginfo.loggedOutTime) }}</span>
                                                        <span v-if="loginfo.loggedOutTime == null || loginfo.loggedTime == null">--</span>
                                                    </td>                                                    
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <b-pagination v-if="logActivities.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadLogActivities()"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
export default {

    data() {
        return {
            isExpand: false,
            logActivities: [],
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            searchlimit: this.$store.state.searchlimit,
            search: {
                params: {
                    userName: "",
                    entityName: "",
                    loggedTime: ""
                },
            },
            coldisplay: {
                jobTitle: true,
                jobCode: true,
                client: true,
                industry: true,
                position: false
            }
        }
    },
    mounted() {
        this.$emit('updateLogActivity');
        this.$emit('addLogActivity','Activity Log');
        this.$store.commit('refreshPagination');
        this.loadLogActivities();
        this.loadDate();
    },
    methods: {
        resetLogSearch() {
            this.search.params = {
                userName: "",
                module: "",
                loggedTime: ""
            };
            this.pagination.currentPage = 1;
            this.loadLogActivities();
        },
        loadDate() {
            let date = new Date();
            this.search.params.startDate = new Date(date.getFullYear(), date.getMonth(), 1);
            this.search.params.endDate = date;
        },
        loadLogActivities() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/log/activity/list?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.logActivities = result.logActivities;
                    this.pagination.totalRecords = result.pagination[0].total;
                    this.pagination.currentPage = result.pagination[0].currentPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        changeLimit(newlimit) {
            this.pagination.currentPage=1;
            this.pagination.rowsPerPage = newlimit;
            this.loadLogActivities();
        },
        findActivities() {
            //this.pagination.currentPage = 1;
            this.loadLogActivities();
        },
        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },
        capitalizeFirstLetter(value) {
            let output = "";
            if(value!= null){
                output = value.charAt(0).toUpperCase() + value.slice(1);
            }
            return output;
        },
        updateDiffs(logInTime, logOutTime) {
            if (logInTime != null && logOutTime != null) {                  
                return moment.utc(moment(logOutTime, "HH:mm").diff(moment(logInTime, "HH:mm"))).format("HH:mm");
            }
            return '';
        }

    }
}

</script>
