<template>
    <div class="tools email-settings w-100">
        <div v-if="!elements.isPreview" class="card email-tools">
            <div class="card-header">
                <h5>
                    <span>Email</span>
                    <span v-if="$route.name=='recruitersettings'" class="text-blue pull-right hover-hand" title="Go Back" @click="$emit('setSubTab',0)">
                        <span class="ti-arrow-left"></span>
                        <span class="pl-2">Back</span>
                    </span>
                    <span v-if="$route.name!='recruitersettings'" class="text-blue pull-right hover-hand" title="Close" @click="$emit('closeEmailTools')">
                        <span class="ti-close"></span>
                    </span>
                </h5>
            </div>
            <div class="card-body mail-multi-select">
                <div class="row col-md-12 py-1 mx-0 justify-content-end">
                    <div class="col-md-12">
                        <ul class="ul-horizontal text-right mb-0">
                            <li><a href="javascript:" v-on:click="elements.expand.importContacts=!elements.expand.importContacts;" data-target="importContacts" class="boxhide">Import Contacts from<span :class="elements.expand.importContacts?'ti-angle-up':'ti-angle-down'" class="px-2"></span></a></li>
                        </ul>
                    </div>
                </div>
                <div id="importContacts" class="row col-md-12 py-1 mx-0" :class="elements.expand.importContacts?'d-flex':'d-none'">
                    <div class="col-md-2 px-0">
                        <h6 class="text-center py-3" style="background-color: #eff6fc;">Import Contacts from</h6>
                    </div>
                    <div class="col-md-10">
                        <multiselect v-model="emailParams.group" :searchable="false" :allow-empty="false" :options="options.group" label="label" track-by="value" placeholder="Select Group" select-label="" deselect-label="" @select="changeGroup"></multiselect>
                    </div>
                </div>
                <div class="row col-md-12 py-1 mx-0 ">
                    <div class="col-md-1 px-0">
                        <h6 class="text-center py-3" style="background-color: #eff6fc;">To</h6>
                    </div>
                    <div class="col-md-8">
                        <multiselect v-model="emailParams.toList" :options="options.toList" label="name" track-by="email" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" placeholder="Choose Contacts" :taggable="emailParams.group.value=='others'" @tag="addToTag" tag-placeholder="Add New Emails">
                        <template slot="option" slot-scope="{ option }">
                            <div :title="option.email">{{ option.name }}</div>
                        </template>
                        </multiselect>
                    </div>
                    <div class="col-md-3">
                        <multiselect v-model="emailParams.template" :searchable="false" :allow-empty="false" :options="options.templatesList" label="template_Name" track-by="id" placeholder="Select Existing template" select-label="" deselect-label="" @select="loadTemplateContent"></multiselect>
                    </div>
                </div>
                <div class="row col-md-12 py-1 mx-0 justify-content-end">
                    <div class="col-md-12">
                        <ul class="ul-horizontal text-right mb-0">
                            <li><a href="javascript:" v-on:click="elements.expand.ccBcc=!elements.expand.ccBcc;" data-target="ccBcc" class="boxhide">Add Cc, Bcc<span :class="elements.expand.ccBcc?'ti-angle-up':'ti-angle-down'" class="px-2"></span></a></li>
                        </ul>
                    </div>
                </div>
                <div id="ccBcc" class="row col-md-12 py-1 mx-0" :class="elements.expand.ccBcc?'d-flex':'d-none'">
                    <div class="col-md-1 px-0">
                        <h6 class="text-center py-3" style="background-color: #eff6fc;">Cc</h6>
                    </div>
                    <div class="col-md-5">
                        <multiselect v-model="emailParams.ccList" :options="options.toList" label="name" track-by="email" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" placeholder="Choose Contacts" :taggable="emailParams.group.value=='others'" @tag="addCcTag" tag-placeholder="Add New Emails"></multiselect>
                    </div>
                    <div class="col-md-1 px-0">
                        <h6 class="text-center py-3" style="background-color: #eff6fc;">Bcc</h6>
                    </div>
                    <div class="col-md-5">
                        <multiselect v-model="emailParams.bccList" :options="options.toList" label="name" track-by="email" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" placeholder="Choose Contacts" :taggable="emailParams.group.value=='others'" @tag="addBccTag" tag-placeholder="Add New Emails"></multiselect>
                    </div>
                </div>
                <div class="row col-md-12 py-1 mx-0">
                    <div class="col-md-1 px-0">
                        <h6 class="text-center py-3" style="background-color: #effbfc;">Subject</h6>
                    </div>
                    <div class="col-md-11">
                        <input type="text" class="tools-input form-control" maxlength="200" v-model="emailParams.subject">
                    </div>
                </div>
                <div class="row col-md-12 py-4 mx-0">
                    <div class="col-md-12 pl-0">
                        <TinymceEditor api-key="yn4wx6bfcohfz6bwlu5k4urno027lgfkcpxolz0f6k4kku01" :toolbar="options.toolbarOptions" :init="options.initOptions" v-model="emailParams.content"></TinymceEditor>
                    </div>
                </div>
                <div class="row col-md-12 mx-0">
                    <div class="icons col-md-1 offset-md-11 pull-right">
                        <a href="javascript:" @click="handleSend" class="edit-only save-icon btn btn-primary">Send</a>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="elements.isPreview" class="card email-preview">
            <div class="card-header">
                <h5>
                    <span>Preview</span>
                    <span class="text-blue pull-right" title="Close" @click="elements.isPreview=false">
                        <span class="ti-close"></span>
                        <span class="pl-2">Close</span>
                    </span>
                </h5>
            </div>
            <div class="card-body">
                <div class="col-md-12" v-html="elements.previewContent"></div>
            </div>
        </div>
        <b-modal ref="previewModal" id="previewModal" size="lg" :hide-footer="true" title="Preview">
            <div class="col-md-12" v-html="elements.previewContent"></div>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import TinymceEditor from '@tinymce/tinymce-vue';
import _ from 'lodash';
export default {
    props: {
        mailTo: {
            type: Object,
            default: () => {}
        },
        jobInfo: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        Multiselect,
        TinymceEditor
    },
    watch: {
        'jobInfo.jobTitle': {
            handler: function(value) {
                this.emailParams.subject = value;
            },
            deep: true,
            immediate: true
        },
        'jobInfo.jobRequirement': {
            handler: function(value) {
                if(this.emailParams.content != null && this.emailParams.content.length > 0){
                    this.emailParams.content = this.emailParams.content.replace(/##CONTENT##/gi, value);
                    this.emailParams.content  = this.emailParams.content .replace(/##COMPANYNAME##/gi, this.companyName);
                }
                else{
                    this.emailParams.content = value;
                }
               
                if (this.emailParams.toList.length) {
                    this.emailParams.content  = this.emailParams.content .replace(/##NAME##/gi, this.emailParams.toList[0].name);
                }
               
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        let self = this;
        return {
            elements: {
                expand: {
                    ccBcc: false,
                    importContacts: false
                },
                previewContent: "",
                isPreview: false
            },
            options: {
                group: [{
                        value: "candidates",
                        label: "Candidates"
                    },
                    {
                        value: "interviewers",
                        label: "Interviewers"
                    },
                    {
                        value: "others",
                        label: "Others"
                    }
                ],
                candidatesList: [],
                interviewersList: [],
                toList: [],
                templatesList: [],
                weblinks: this.$store.getters.getWeblinks,
                tagNames: this.$store.getters.getTagNames,
                toolbarOptions: "undo redo previewModal | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks tagNames",
                initOptions: {
                    height: 300,
                    width: "100%",
                    body_class: "tools-input form-control",
                    branding: false,
                    plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools'],
                    paste_data_images: true,
                    images_upload_url: './js/tinymce/postAcceptor.php',
                    images_upload_handler: function(blobInfo, success, failure) {
                        if (!/\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(blobInfo.filename())) {
                            iziToast.error({
                                title: 'Photo update',
                                message: this.$config.FileFormatErr,
                                position: 'topRight'
                            });
                            return false;
                        }
                        let formData = new FormData();
                        formData.append('file', blobInfo.blob(), blobInfo.filename());
                        formData.append('id', 0);
                        formData.append('folderName', 'emailtool');
                        axios.post(self.$store.getters.getAPIBasePath + '/resumeresource/uploadfile',
                                formData, { headers: { 'Content-Type': 'multipart/form-data' } }
                            ).then(response => {
                                if (response.data.status)
                                    success(response.data.fileDetails.filePath);
                                else
                                    failure("Upload Error. Please try again");
                            })
                            .catch(function(error) {                            
                                failure("Upload Error. Please try again");
                            });
                        /*setTimeout(function () {
                            
                        }, 2000);*/
                    },
                    automatic_uploads: true,
                    file_picker_types: 'file image',
                    relative_urls: false,
                    remove_script_host: false,
                    setup: function(editor) {
                        editor.ui.registry.addMenuButton('weblinks', {
                            text: "Web Link",
                            icon: "link",
                            fetch: function(callback) {
                                var items = [];
                                self.options.weblinks.map(function(value, key) {
                                    items.push({
                                        type: 'menuitem',
                                        text: value.name,
                                        onAction: function() {
                                            editor.insertContent('<a href="' + value.id + '">' + value.name + '</a>');
                                        }
                                    })
                                });
                                callback(items);
                            },
                            /*onSelect: function(e) {
                                tinyMCE.execCommand(this.value());
                                console.log(this.value());
                            },*/
                        });
                        editor.ui.registry.addMenuButton('tagNames', {
                            text: "Tag Names",
                            icon: "link",
                            fetch: function(callback) {
                                var items = [];
                                self.options.tagNames.map(function(value, key) {
                                    items.push({
                                        type: 'menuitem',
                                        text: value.name,
                                        onAction: function() {
                                            editor.insertContent('<span>' + value.id + '</span>');
                                        }
                                    })
                                });
                                callback(items);
                            }
                            /*onSelect: function(e) {
                                tinyMCE.execCommand(this.value());
                                console.log(this.value());
                            },*/
                        });
                        editor.ui.registry.addButton('previewModal', {
                            text: "Preview",
                            icon: "preview",
                            onAction: function() {
                                self.elements.previewContent = self.emailParams.content;
                                if (self.emailParams.toList.length) {
                                    if (self.emailParams.toList[0].id == 0 && self.emailParams.toList[0].email == "allCandidates") {
                                        self.elements.previewContent = self.elements.previewContent.replace(/##NAME##/gi, self.options.candidatesList[1].name);
                                        self.elements.previewContent = self.elements.previewContent.replace(/##EMAIL##/gi, self.options.candidatesList[1].email);
                                    } else {
                                        self.elements.previewContent = self.elements.previewContent.replace(/##NAME##/gi, self.emailParams.toList[0].name);
                                        self.elements.previewContent = self.elements.previewContent.replace(/##EMAIL##/gi, self.emailParams.toList[0].email);
                                    }
                                }
                                if (self.jobInfo != null) {
                                    self.elements.previewContent = self.elements.previewContent.replace(/##JOB_TITLE##/gi, self.jobInfo.jobTitle);
                                    self.elements.previewContent = self.elements.previewContent.replace(/##URL##/gi, self.jobInfo.redirectURL);
                                    self.elements.previewContent = self.elements.previewContent.replace(/##CONTENT##/gi, self.jobInfo.jobRequirement);
                                    self.elements.previewContent = self.elements.previewContent.replace(/##COMPANYNAME##/gi, self.companyName);
                                    if (self.emailParams.toList.length) {
                                        self.elements.previewContent = self.elements.previewContent.replace(/##NAME##/gi, self.emailParams.toList[0].name);
                                    }

                                }
                                // self.$refs['previewModal'].show();
                                self.elements.isPreview = true;
                            }
                        });
                    }
                }
            },
            emailParams: {
                group: { value: "others", label: "Others" },
                toList: [],
                ccList: [],
                bccList: [],
                subject: '',
                content: '',
                template: null,
                templateId: null,
            },
            companyName:this.$store.getters.currentUser.companyName,
        }
    },
    mounted() {
        this.getEmailTemplatesList();
        this.emailParams.group.value = "others";
        this.setGroupContacts("candidates");
        this.setGroupContacts("interviewers");
        if (!_.isEmpty(this.mailTo)) {
            this.emailParams.toList.push(this.mailTo);
        }
        if (!_.isEmpty(this.jobInfo)) {
            this.loadTemplateByType(this.jobInfo);
        }
    },
    methods: {
        upload_photo: function() {
            if (!/\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(this.$refs.candidate_add_profile_photo.files[0].name)) {
                iziToast.error({
                    title: 'Photo update',
                    message: this.$config.FileFormatErr,
                    position: 'topRight'
                });
                return false;
            }
            let formData = new FormData();
            formData.append('file', this.$refs.candidate_add_profile_photo.files[0]);
            formData.append('id', 0);
            formData.append('folderName', 'emailtools');
            axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/uploadFile',
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function() {
                    console.log('SUCCESS!!');
                    iziToast.success({ title: 'Success', message: this.$config.ProfileUpSuc, position: 'topRight' });
                })
                .catch(function(error) {
                    console.log('FAILURE!!');
                    console.log(error);
                    iziToast.error({ title: 'Error Occured', message: this.$config.ProfileUpErr, position: 'topRight' });
                });
        },
        getEmailTemplatesList: function(paginationParams) {
            axios.post(this.$store.getters.getAPIBasePath + '/templateresource/templatelist', {})
                .then(response => {
                    let result = response.data;
                    if (!response.status) {
                        iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                    }
                    this.options.templatesList = result.emailTemplates;
                })
                .catch(error => {
                    iziToast.error({ title: 'Error', message: this.$config.LoadTempListErr, position: 'topRight' });
                });
        },
        loadTemplateByType: function(template) {
            axios.post(this.$store.getters.getAPIBasePath + '/templateresource/view/template/by/type', { "templateType": template.id })
                .then(response => {
                    let template = response.data;
                    this.emailParams.content = template.content;
                    if (this.jobInfo != null) {
                        this.emailParams.content = this.emailParams.content.replace(/##JOB_TITLE##/gi, this.jobInfo.jobTitle);
                        this.emailParams.content = this.emailParams.content.replace(/##URL##/gi, this.jobInfo.redirectURL);
                        this.emailParams.content = this.emailParams.content.replace(/##CONTENT##/gi, this.jobInfo.jobRequirement);
                        this.emailParams.content = this.emailParams.content.replace(/##COMPANYNAME##/gi, this.companyName);
                        this.emailParams.subject = this.jobInfo.jobTitle;
                    }


                    if (!template.status && template.message) {
                        iziToast.error({ title: 'Error', message: template.message, position: 'topRight' });
                    }
                })
                .catch(error => {
                    /*iziToast.error({ title: 'Error', message: 'Unable to load templatessssss. Please try again', position: 'topRight' });*/
                });
        },
        loadTemplateContent: function(template) {

            axios.post(this.$store.getters.getAPIBasePath + '/templateresource/view/template', { "id": template.id })
                .then(response => {
                    let result = response.data;
                    this.emailParams.content = result.emailTemplate.content;
                     if(this.emailParams.content != null ){ 
                        this.emailParams.content = this.emailParams.content.replace(/##COMPANYNAME##/gi, this.companyName);
                            if (this.jobInfo != null) {
                                this.emailParams.content = this.emailParams.content.replace(/##JOB_TITLE##/gi, this.jobInfo.jobTitle);
                                this.emailParams.content = this.emailParams.content.replace(/##URL##/gi, this.jobInfo.redirectURL);
                                this.emailParams.content = this.emailParams.content.replace(/##CONTENT##/gi, this.jobInfo.jobRequirement);
                                this.emailParams.subject = this.jobInfo.jobTitle;
                            }
                    }

                    if (!result.status) {
                        iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                    }
                })
                .catch(error => {
                    iziToast.error({ title: 'Error', message: this.$config.TempLoadErr, position: 'topRight' });
                });
        },
        handleSend: function() {
            this.prepareDataBeforeSend();
            this.emailParams.templateId = (this.emailParams.template != null) ? this.emailParams.template.id : null;

            if (this.jobInfo != null) {
                this.emailParams.content = this.emailParams.content.replace(/##JOB_TITLE##/gi, this.jobInfo.jobTitle);
                this.emailParams.content = this.emailParams.content.replace(/##URL##/gi, this.jobInfo.redirectURL);
                this.emailParams.content = this.emailParams.content.replace(/##CONTENT##/gi, this.jobInfo.jobRequirement);
                this.emailParams.content = this.emailParams.content.replace(/##COMPANYNAME##/gi, this.companyName);
                //this.emailParams.content = tinymce.get('here').set("this.jobInfo.redirectURL");
            }

            axios.post(this.$store.getters.getAPIBasePath + '/emailresource/add/email/sent/items', this.emailParams)
                .then(response => {
                    let result = response.data;
                    if (!response.status) {
                        iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                    }
                    iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                    //if (this.jobInfo != null)
                        this.refreshVariables();
                })
                .catch(error => {
                    iziToast.error({ title: 'Error', message: this.$config.UnableToSendMail, position: 'topRight' });
                });
        },
        changeGroup: function(group) {
            switch (group.value) {
                default:
                    this.emailParams.group.value = "others";
                    this.options.toList = [];
                    break;
                case 'candidates':
                    this.options.toList = [];
                    this.options.toList = this.options.candidatesList;
                    // this.setGroupContacts(group.value);
                    break;
                case 'interviewers':
                    this.options.toList = [];
                    this.options.toList = this.options.interviewersList;
                    // this.setGroupContacts(group.value);
                    break;
                case 'others':
                    this.options.toList = [];
                    break;
            }
        },
        setGroupContacts(group) {
            axios.post(this.$store.getters.getAPIBasePath + '/emailresource/email/contact/list/' + group)
                .then(response => {
                    let result = response.data;
                    if (!result.status) {
                        iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                        this.options.toList = [];
                    }
                    switch (group) {
                        default:
                            break;
                        case "interviewers":
                            this.options.interviewersList = [];
                            this.options.interviewersList = result.emailContactList;
                            break;
                        case "candidates":
                            this.options.candidatesList = [];
                            this.options.candidatesList.push({ email: "allCandidates", id: 0, name: "All Candidates" });
                            let self = this;
                            result.emailContactList.map(function(emailContact) {
                                self.options.candidatesList.push(emailContact);
                            });
                            break;
                    }
                })
                .catch(error => {
                    iziToast.error({ title: 'Error', message: this.$config.LoadContactsErr, position: 'topRight' });
                    this.options.toList = [];
                });
        },
        addToTag: function(newEmail) {
            this.emailParams.toList.push({ id: null, name: newEmail.substr(0, newEmail.indexOf('@')), email: newEmail, userType: "none" });
        },
        addCcTag: function(newEmail) {
            this.emailParams.ccList.push({ id: null, name: newEmail.substr(0, newEmail.indexOf('@')), email: newEmail, userType: "none" });
        },
        addBccTag: function(newEmail) {
            this.emailParams.bccList.push({ id: null, name: newEmail.substr(0, newEmail.indexOf('@')), email: newEmail, userType: "none" });
        },
        refreshVariables: function() {
            this.options.toList = [];
            this.emailParams.group = { value: "others", label: "Others" };
            this.emailParams.toList = [];
            this.emailParams.ccList = [];
            this.emailParams.bccList = [];
            this.emailParams.subject = "";
            this.emailParams.content = '<p>Enter your content here</p>';
            this.emailParams.template = null;
            this.emailParams.templateId = null;
        },
        prepareDataBeforeSend: function() {
            let self1 = this;
            this.emailParams.toList.map(function(contact) {
                switch (contact.email) {
                    default:
                        break;
                    case "allCandidates":
                        Array.prototype.push.apply(self1.emailParams.toList, self1.options.candidatesList);
                        break;
                }
            });
            _.remove(this.emailParams.toList, function(resultContact) {
                return resultContact.email == "allCandidates";
            });
            this.emailParams.ccList.map(function(contact) {
                switch (contact.email) {
                    default:
                        break;
                    case "allCandidates":
                        Array.prototype.push.apply(self1.emailParams.ccList, self1.options.candidatesList);
                        break;
                }
            });
            _.remove(this.emailParams.ccList, function(resultContact) {
                return resultContact.email == "allCandidates";
            });
            this.emailParams.bccList.map(function(contact) {
                switch (contact.email) {
                    default:
                        break;
                    case "allCandidates":
                        Array.prototype.push.apply(self1.emailParams.bccList, self1.options.candidatesList);
                        break;
                }
            });
            _.remove(this.emailParams.bccList, function(resultContact) {
                return resultContact.email == "allCandidates";
            });
        }
    }
}

</script>
