<template>
    <div id="main" class="mt-70">
        <div id="quick-search" class="container-fluid mtb-4 bg-white p-tb-15">
            <div class="driver-model" style="padding: 0px 0px 10px;">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="box-wrapper shadow-sm p-2 mtb-4 bg-white rounded pos-rel">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="card ">
                                        <div class="my-inbox user-card  ">
                                            <h5 class="inboxcolor">My Inbox</h5>
                                            <div class="">
                                                <div class="card-media" v-for="(jobinfo, index) in joblist">
                                                    <div class="card-media-body">
                                                        <a href="Javascript:void(0);" @click="getJobInfo(jobinfo.jobId)">
                                                            <span class="card-media-body-heading">{{ jobinfo.candidateName }} - {{ jobinfo.jobTitle }} [{{ jobinfo.jobCode }}]</span></a>
                                                        <ul class="mb-2">
                                                            <li class="d-inline-block pr-4">Status - <span>{{ jobinfo.jobStatus }}</span></li>
                                                            <li class="d-inline-block"><i class="ti-calendar"></i> {{ jobinfo.dueDate | formatDate}}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> <!-- end col-sm-3 div -->
                    <div class="col-md-8">
                        <div class="box-wrapper shadow-sm p-4 mtb-4 bg-white rounded pos-rel">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="row no-gutters">
                                        <div class="col-md-12 pop-tab">
                                            <div class="card">
                                                <div class="card-body p-4">
                                                    <div class="p-4" style="background-color: #f1f1f1;">
                                                        <div class="row">
                                                            <div class="col-sm-9">
                                                                <div class="row">
                                                                    <div class="col-sm-12">
                                                                        <div class="headjobs-left">
                                                                            <h1>{{jobDetail.jobTitle}}</h1>
                                                                            <ul class="mb-2">
                                                                                <li class="d-inline-block pr-4"><i class="ti-bag"></i> {{jobDetail.experience}} yrs </li>
                                                                                <!-- <li class="d-inline-block"><i class="ti-location-pin"></i> {{jobDetail.location}} </li> -->
                                                                                <li class="d-inline-block"><i class="ti-money"></i> {{jobDetail.salary}} </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-sm-12 nomarginplz">
                                                                        <div class="row jobbrief padgap">
                                                                            <div class="col-sm-10">
                                                                                <div class="row">
                                                                                    <div class="col-sm-3 nomarginplz">Industry : </div>
                                                                                    <div class="col-sm-9 nomarginplz">{{jobDetail.industryName}}</div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-3 nomarginplz">Position :</div>
                                                                                    <div class="col-sm-9 nomarginplz">{{jobDetail.position}}</div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-3 nomarginplz">Work Type : </div>
                                                                                    <div class="col-sm-9 nomarginplz">{{jobDetail.workType}}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-3">
                                                                <div class="row">
                                                                    <div class="col-sm-12 text-center">
                                                                        <div class="headjobs-right p-4">
                                                                            <!-- <div class="card-media" v-for="(jobinfo, index) in joblist" -->
                                                                            <div class="card-media-body">
                                                                                <span class="card-media-body-heading">Job code #: [{{jobDetail.jobCode}}]</span>
                                                                                <span class="card-media-body-heading" v-if="checkEmpty(jobDetail.dueDate)">Due Date: {{jobDetail.dueDate | formatDate}}</span>
                                                                            </div>
                                                                            <!--  </div> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- Card Div-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!-- col-md-9 Div-->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    props: {
        newJobForm: Object,
    },

    data() {
        return {
            joblist: [],
            jobDetail: {
                jobTitle: "",
                jobCode: "",
                clientName: "",
                industryName: "",
                position: "",
                eligibility: "",
                experience: "",
                salary: "",
                vacancies: "",
                workType: "",
                dueDate: "",
                jobRequirement: "",
                jobSkill: [],
                contact: {
                    contactPerson: "",
                    email: "",
                    phone1: "",
                    phone2: "",
                    website: "",
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    countryId: null,
                    zipCode: ""
                }
            },
            browsepagination: this.$store.state.paginationconfig,
            searchlimit: this.$store.state.searchlimit,
            elements: {
                showadvancedsearch: false,
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit
            },
            coldisplay: {
                jobTitle: true,
                jobCode: true,
                client: true,
                industry: true,
                position: true
            },
        }
    },
    mounted() {
        this.loadJobs();

    },
    methods: {
        checkEmpty(obj) {
            if(obj != null && isNaN(obj) && obj.trim().length > 0){
               return true;
            }else if(obj != null && obj > 0){
                return true;
            }
            return false;
        },
        loadJobs() {
            axios.get(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/list/job/candidate/shortlist?page=' + this.browsepagination.currentpage + '&size=' + this.search.limit)
                .then(response => {
                    let result = response.data;
                    console.log(result);
                    this.joblist = result.candShortlist;
                    this.browsepagination.totalrecords = result.pagination[0].total;
                    this.browsepagination.currentpage = result.pagination[0].currentPage;
                    this.browsepagination.totalpages = result.pagination[0].lastPage;

                    if (this.joblist != null && this.joblist.length > 0) {
                       this.getJobInfo(this.joblist[0].jobId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        getJobInfo(jobId) {

            axios.get(this.$store.getters.getAPIBasePath + '/jobresource/view/job/' + jobId)
                .then(response => {

                    let result = response.data;
                    if (!result.status) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    this.jobDetail.experience = response.data.jobs.experience;

                    this.jobDetail.jobTitle = response.data.jobs.jobTitle;

                    this.jobDetail.salary = response.data.jobs.salary;
                    this.jobDetail.industryName = response.data.jobs.industryName;
                    this.jobDetail.position = response.data.jobs.position;
                    this.jobDetail.workType = response.data.jobs.workType;
                    this.jobDetail.jobCode = response.data.jobs.jobCode;
                    this.jobDetail.dueDate = response.data.jobs.dueDate;


                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.JobLoadErr,
                        position: 'topRight'
                    });
                });
        },
    }
}

</script>
