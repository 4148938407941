<template>
    <div class="email-template-add text-left">
        <div class="row col-md-12 p-4 mx-0">
            <h5>Create Email Template</h5>
        </div>
        <div class="row col-md-12 py-4 mx-0">
            <div class="col-md-6">
                <div class="row col-md-12 px-0">
                    <div class="col-md-4">Template Name</div>
                    <div class="col-md-8">
                        <div class="col-md-12 col-sm-12 col-12">
                            <input type="text" v-model="template.templateName" maxlength="90" class="form-control">
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                            <div v-if="elements.submitted && !$v.template.templateName.required" class="invalid-feedback mt-0">Template Name is required</div>
                            <div v-if="elements.submitted && !$v.template.templateName.minLength" class="invalid-feedback mt-0">Template Name must have atleast 5 characters</div>
                            <div v-if="elements.submitted && !$v.template.templateName.maxLength" class="invalid-feedback mt-0">Template Name shouldn't exceed more than 100 characters</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row col-md-12 px-0" data-toggle="modal" data-target="#existingTemplates">
                    <div class="col-md-12 pt-2 hover-hand hover-text-blue text-blue">
                        <h5>Modify from Existing Templates</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-md-12 py-4 mx-0">
            <div class="col-md-6">
                <div class="row col-md-12 px-0">
                    <div class="col-md-4">Template Type</div>
                    <div class="col-md-8">
                        <div class="col-md-12 col-sm-12 col-12">
                            <select v-model="template.templateType" class="form-control" required>
                                <option value="">Select</option>
                                <option v-for="Type in templateTypesList" :value="Type.id">{{Type.name}}</option>
                            </select>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                            <div v-if="elements.submitted && !$v.template.templateType.required" class="invalid-feedback mt-0">Template Type is required</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row col-md-12 px-0">
                    <div class="col-md-4">Scope</div>
                    <div class="col-md-8">
                        <div class="col-md-12 col-sm-12 col-12">
                            <select v-model="template.accessLevel" class="form-control" required disabled="true">
                                <option v-for="Scope in emailTemplateScope" :value="Scope.id">{{Scope.name}}</option>
                            </select>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                            <div v-if="elements.submitted && !$v.template.accessLevel.required" class="invalid-feedback mt-0">Template Scope is required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-md-12 py-4 mx-0">
            <div class="col-md-2">Content</div>
            <div class="col-md-10 pl-0">
                <div class="col-md-12 col-sm-12 col-12">
                    <editor api-key="yn4wx6bfcohfz6bwlu5k4urno027lgfkcpxolz0f6k4kku01" :toolbar="toolbar" :init="initObj" v-model="template.content"></editor>
                </div>
                <div class="col-md-12 col-sm-12 col-12">
                    <div v-if="elements.submitted && !$v.template.content.required" class="invalid-feedback mt-0">Content is required</div>
                    <div v-if="elements.submitted && !$v.template.content.minLength" class="invalid-feedback mt-0">Content length must be 30 atleast</div>
                </div>
            </div>
        </div>
        <div class="row col-md-12 py-2">
            <div class="col-md-12">
                <!-- <input type="checkbox" id="isDefault">
                <label class="p-3" for="checkbox">Is Primary Account?</label> -->
                 <b-form-checkbox id="isDefault" name="isDefault" @change="" class="px-5">Is default?</b-form-checkbox>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-12 py-4">
            <div class="icons pull-right pr-3">
                <a href="javascript:" @click="$emit('closeActionTemplate')" class="edit-only cancel-icon btn btn-secondary mx-1">Cancel</a>
                <a href="javascript:" @click="saveTemplate" class="edit-only save-icon btn btn-primary mx-1">Save</a>
            </div>
        </div>
        <div class="modal fade" id="existingTemplates" tabindex="-1" role="dialog" aria-labelledby="templatesList" aria-hidden="true">
            <div class="modal-dialog mw-100 w-75" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="templatesList">Templates</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="card m-b-3">
                            <!-- Template List -->
                            <div class="card-body p-2">
                                <div class="row col-md-12 col-sm-12 col-12 py-3 mx-0">
                                    <div class="col-md-6 col-sm-6 col-6 text-left p-0">
                                        <h4 class="page-title">Templates
                                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.emailtotalRecords}} - Total)</span>
                                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                                <div class="dropdown">
                                                    <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                                    <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                        <a v-for="(value, index) in pagination.searchlimit" class="dropdown-item" href="javascript:" @click="changeLimit(value)">{{value}}</a>
                                                    </div>
                                                </div>
                                            </span>
                                        </h4>
                                    </div>
                                </div>
                                <div class="basic-block table-responsive">
                                    <table class="table table-borderless all-table">
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Scope</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="templatesList.length==0">
                                                <td align="center" colspan="5">No records found</td>
                                            </tr>
                                            <tr v-if="templatesList.length" v-for="(Template,index) in templatesList">
                                                <td>{{((pagination.currentPage-1) * (pagination.rowsPerPage)) + (index+1)}}</td>
                                                <td>{{Template.template_Name}}</td>
                                                <td>{{Template.template_Type | getNameById(templateTypesList)}}</td>
                                                <td>{{Template.access_Level | getNameById(emailTemplateScope)}}</td>
                                                <td><a href="javascript:" @click="cloneTemplate(Template)" data-dismiss="modal">Choose</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-12">
                                    <b-pagination v-if="templatesList.length!=0" size="md" class='pull-right' :current-page="pagination.currentPage" :total-rows="pagination.emailtotalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="$emit('getEmailTemplatesList')"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue';
import iziToast from 'izitoast';
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
    components: {
        Editor
    },
    props: {
        templatesList: Array,
        pagination: Object
    },
    data() {
        let self = this;
        return {
            elements: {
                submitted: false
            },
            templateTypesList: this.$store.getters.getTemplateTypesList,
            emailTemplateScope: this.$store.getters.getEmailTemplateScope,
            weblinks: this.$store.getters.getWeblinks,
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks tagNames',
            template: {
                templateName: "",
                templateType: null,
                accessLevel: 1,
                content: 'Edit Your Content Here!',
                existingTemplate: ""
            },
            tagNames: [
					{
						"id": "##NAME##",
						"name": "Name"
					},
					{
						"id": "##COMPANYNAME##",
						"name": "Company Name"
					},
					{
						"id": "##JOBTITLE##",
						"name": "Job Title"
					},
					{
						"id": "##JOININGDATE##",
						"name": "Joinining Date"
					},
                    {
						"id": "##SALARY##",
						"name": "Salary"
					},
                    {
						"id": "##BENEFITS##",
						"name": "Benefits"
					},
			],
            initObj: {
                height: 300,
                branding: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
                setup: function(editor) {
                    editor.ui.registry.addMenuButton('weblinks', {
                        text: "Web URL",
                        icon: "link",
                        fetch: function(callback) {
                            var items = [];
                            self.weblinks.map(function(value, key) {
                                console.log(value);
                                items.push({
                                    type: 'menuitem',
                                    text: value.name,
                                    onAction: function() {
                                        editor.insertContent('<a href="' + value.id + '">' + value.name + '</a>');
                                    }
                                })
                            });
                            callback(items);
                        },
                        onSelect: function(e) {
                            tinyMCE.execCommand(this.value());
                            console.log(this.value());
                        },
                    });
                    editor.ui.registry.addMenuButton('tagNames', {
                        text: "Tag Names",
                        icon: "link",
                        fetch: function(callback) {
                        var items = [];
                        self.tagNames.map(function(value, key) {
                            items.push({
                                type: 'menuitem',
                                text: value.name,
                                onAction: function() {
                                    editor.insertContent('<span>' + value.id + '</span>');
                                }
                            })
                        });
                        callback(items);
                        }
                            /*onSelect: function(e) {
                                tinyMCE.execCommand(this.value());
                                  console.log(this.value());
                            },*/
                        });
          		}
            }
        }
    },
    validations: {
        template: {
            templateName: { required, minLength: minLength(5), maxLength: maxLength(100) },
            templateType: { required },
            accessLevel: { required },
            content: { required, minLength: minLength(10) }
        }
    },
    mounted() {
        this.$emit('getEmailTemplatesList');
    },
    methods: {
        saveTemplate: function() {
            this.elements.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) return false;
            axios.post(this.$store.getters.getAPIBasePath + '/templateresource/add/email/template', this.template)
                .then(response => {
                    let result = response.data;
                    if (result.status != "ACCEPTED" || result.status == "CONFLICT") {
                        iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                        return false;
                    }
                    iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                    this.elements.submitted = false;
                    this.$emit('closeActionTemplate');
                })
                .catch(error => {
                    iziToast.error({ title: 'Error', message: this.$config.TempSavErr, position: 'topRight' });
                });
        },
        cloneTemplate: function(template_data) {
            this.template.templateName = template_data.template_Name + " - Copy";
            this.template.templateType = template_data.template_Type;
            this.template.accessLevel = template_data.access_Level;
            this.template.existingTemplate = template_data.template_Name;
            let self = this;
            axios.post(this.$store.getters.getAPIBasePath + '/templateresource/view/template', { "id": template_data.id })
                .then(response => {
                    let result = response.data;
                    if (!response.status) {
                        iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                    }
                    self.template.content = result.emailTemplate.content;
                })
                .catch(error => {
                    iziToast.error({ title: 'Error', message: this.$config.LoadTempErr, position: 'topRight' });
                });
        },
        changeLimit: function(newValue) {
            this.$emit('changeLimit', newValue);
            this.$emit('getEmailTemplatesList');
        }
    }
}

</script>
