<template>    
    <div class="row ten-columns">
        <div class="col-md-12">
            <table class="table table-borderless all-table">
                <thead>
                    <tr>
                        <th class="" scope="col">Additional Field</th>
                        <th class="" scope="col">Value</th>
                        <th class="" scope="col">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(ainfo, index1) in additionalInfo" v-bind:key="index1" >
                        <td>
                            <select class="form-control" placeholder="Document" v-model="ainfo.nameValueId" @change="showInput(index1)">
                                <option>Select</option>
                                <option v-for="(doc, index) in nameValueList" v-bind:key="index" :value="doc.id">{{doc.name}}</option>
                            </select>
                        </td>
                        <td>                            
                            <input v-if="ainfo.valueFormatId==dataType.xNumber" class="form-control" type="text" v-model="ainfo.intValue" @keydown="isNumber" />
                            <input v-if="ainfo.valueFormatId==dataType.xText" class="form-control" type="text" v-model="ainfo.stringValue" />
                            <select v-if="ainfo.valueFormatId==dataType.xYesNo" class="form-control" v-model="ainfo.booleanValue">
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                            <datepicker v-if="ainfo.valueFormatId==dataType.xDate" v-model="ainfo.dateValue" placeholder="Date" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" ></datepicker>
                        </td>
                        <td>
                            <a href="javascript:;" @click="delAdditInfo(index1,ainfo.id)" ><span class="ti-trash"></span></a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <select class="form-control" placeholder="Document" v-model="newItem.nameValueId" @change="showInput(-1)">
                                <option :value="0">Select</option>
                                <option v-for="(doc, index) in nameValueList" v-bind:key="index" :value="doc.id">{{doc.name}}</option>
                            </select>
                        </td>
                        <td>                            
                            <input v-if="newItem.valueFormatId==dataType.xNumber" class="form-control xNumber" type="text" v-model="newItem.intValue" @keypress="isNumber" />
                            <input v-if="newItem.valueFormatId==dataType.xText" class="form-control xText" type="text" v-model="newItem.stringValue" />
                            <select v-if="newItem.valueFormatId==dataType.xYesNo" class="form-control xYesNo" v-model="newItem.booleanValue">
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                            <datepicker v-if="newItem.valueFormatId==dataType.xDate" v-model="newItem.dateValue" placeholder="Date" input-class="form-control enablefield xDate" wrapper-class="input-group date" format="MM/dd/yyyy" ></datepicker>
                        </td>
                        <td>
                            <a href="javascript:;" @click="addAdditInfo"><span class="ti-plus"></span></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    
</template>

<script>
import iziToast from 'izitoast';
export default {
    props: {        
        additionalInfo:{type:Array, default:[]},
        newItem:{type:Object, default:{
            id:0,
            nameValueId : 0,
            valueFormatId : "",
            intValue : "",
            stringValue : "",
            dateValue : "",
            booleanValue : ""}
        }
        //documents:{type:Array, default:[]}
    },
    data() {
        return {
            nameValueList:this.$store.getters.getNameValueList,
            dataType:this.$store.getters.getAdditionalFieldsType,
        }
    },
    mounted(){
        this.loadFieldSettings();
    },
    methods:{
        loadFieldSettings() {
            axios.post(this.$store.getters.getAPIBasePath + "/settingsresource/namevalue/list")
            .then(response => {
                let result = response.data;
                this.nameValueList = result.nameValueList;                
                this.$store.commit('setNameValueList', result.nameValueList);
                this.nameValueList = this.$store.getters.getNameValueList;
            })
            .catch(error => {
                iziToast.error({title: "Error", message: error, position: "topRight"});
            });
        },
        showInput(index){
            if(index<0)
            {                
                let tempType = this.nameValueList.filter( data => { return data.id === this.newItem.nameValueId } );
                if( tempType.length>0 )
                    this.newItem.valueFormatId=tempType[0].valueFormatId;
                else
                    this.newItem.valueFormatId="";
            }
            else
            {
                let tempType = this.nameValueList.filter( data => { return data.id === this.additionalInfo[index].nameValueId } );
                if( tempType.length>0 )
                    this.additionalInfo[index].valueFormatId=tempType[0].valueFormatId;
                else
                    this.newItem.valueFormatId="";
            }
        },
        isNumber(event){
            if((event.keyCode > 47 && event.keyCode < 59) || event.keyCode==8 || event.keyCode == 46 ) return true;
            event.preventDefault();
        },
        delAdditInfo(index,id){
            this.additionalInfo.splice(index,1);
            axios.delete(this.$store.getters.getAPIBasePath+'/candidateresource/delete/candidate/additional/field/' + id);
        },
        addAdditInfo(){
            this.additionalInfo.push({...this.newItem});
            this.newItem.nameValueId = 0;
            this.newItem.valueFormatId = "";
            this.newItem.intValue = "";
            this.newItem.stringValue = "";
            this.newItem.dateValue = "";
            this.newItem.booleanValue = "";
        },
        clearData(){
            this.newItem.nameValueId = 0;
            this.newItem.valueFormatId = "";
            this.newItem.intValue = "";
            this.newItem.stringValue = "";
            this.newItem.dateValue = "";
            this.newItem.booleanValue = ""; 
        }
    }
}

</script>