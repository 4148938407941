<template>
    <div class="kpis reports text-left">
        <div class="row">
            <div class="col-sm-12 p-10">
                <div id="recruiter-settings" class="recruiter settings mt-10">
                    <div class="container-fluid" id="quick-search">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="text-center">Recruitment Custom Report</h5>
                            </div>
                        </div>
                        <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <h4 class="page-title">Filter</h4>
                                </div>
                                <div class="col-md-6 col-sm-6 col-6">
                                    <ul class="ul-horizontal box-tools text-right mb-0">
                                        <li><a href="Javascript:void(0);" v-on:click="elements.isExpand=!elements.isExpand;" data-target="main-search" class="boxhide"><span :title="elements.isExpand?'Hide':'Expand'" :class="elements.isExpand?'ti-angle-up':'ti-angle-down'" class="hover-hand"></span></a></li>
                                        <li><i class="ti-close hover-hand px-2" @click="$emit('setSubTab',0);$emit('updateLogActivity');" title="Close"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <form id="main-search" :class="elements.isExpand?'d-block':'d-none'" class="pb-4">
                                <div class="row form-group ten-columns">
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Job Title</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text"  v-on:keyup.enter="filterJobs()" name="jobTitle" maxlength="50" value="" id="jobTitle" class="form-control" v-model="search.params.jobTitle">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Job Code</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text"  v-on:keyup.enter="filterJobs()" v-model="search.params.jobCode" name="jobId" maxlength="25" value="" id="jobId" class="smallCombo form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Client</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <select v-model="search.params.clientId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(data, index) in options.clientlist" :value="data.id">{{data.client_Name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Industry</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <select v-model="search.params.industryId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(data, index) in options.industrylist" :value="data.id">{{data.industryName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Position</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text"  v-on:keyup.enter="filterJobs()" v-model="search.params.position" name="jobId" maxlength="100" value="" id="jobId" class="smallCombo form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Status</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <select v-model="search.params.status" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(data, index) in options.jobStatuslist" :value="data.id">{{data.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group ten-columns">
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Start Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledStartDates" placeholder="Start Date" name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.startDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>End Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledendDates" placeholder="End Date" name="endDate" value="" id="endDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.endDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label style="visibility: hidden;">Technology</label>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="button" @click="resetJobSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                                <button type="button" @click="filterJobs" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Go</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                </div>
                            </form>
                            <div class="load-indicator"></div>
                        </div>
                    </div>
                    <div v-if="action.mode!='view'" class="container-fluid" id="filter">
                        <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                            <h4 class="page-title">Choose Column Header</h4>
                            <div class="row col-md-12 mx-0 my-2">
                                <div class="col-md-6">
                                    <label>Master Table Columns</label>
                                </div>
                                <div class="col-md-6">
                                    <label>Detail Table Columns</label>
                                </div>
                            </div>
                            <div class="row col-md-12 mx-0">
                                <div class="col-md-6">
                                    <multiselect v-model="reportData.jobFieldList" :options="options.fieldList.jobFieldList" name="jobtablefields" label="label" track-by="fieldId" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" :allow-empty="true" @select="selectMasterTableFields" @remove="removeMasterTableFields" />
                                </div>
                                <div class="col-md-6">
                                    <multiselect v-model="reportData.candidateFieldList" :options="options.fieldList.candidateFieldList" name="candidatetablefields" label="label" track-by="fieldId" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" :allow-empty="true" @select="selectDetailTableFields" @remove="removeDetailTableFields" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid" id="main-data">
                        <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                            <div v-if="reportData.jobFieldList.length" class="row" style="margin-bottom: 15px;">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <h4 class="page-title">Jobs
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                            <div class="dropdown">
                                                <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                                <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                    <a v-for="(value, index) in options.searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                                </div>
                                            </div>
                                        </span>
                                    </h4>
                                </div>
                                <div class="col-md-6 col-sm-6 col-6 pull-right">
                                    <button v-if="action.mode!='view'" type="button" v-b-modal.reportFormModal class="btn btn-primary btn-sm float-right">Save Report</button>
                                    <span class="float-right pr-2" @click="exportRecruitment()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i></span>
                                    <!-- <span v-if="true" class="float-right pr-2" @click="exportAllRecruitment()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download(Like Daily tracker)" alt=""></i></span> -->
                                </div>
                            </div>
                            <div v-if="reportData.jobFieldList.length" class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <b-table :fields="options.tableFields.jobFieldList" :items="list.joblist" head-variant="primary" striped show-empty responsive="sm" class="all-table">
                                            <template slot="clientName" slot-scope="data">
                                                <span v-if="data.item.ttClientAccountId == ttClientAccountId">  
                                                    {{data.value}}
                                                </span>
                                            </template> 
                                            <template  #cell(show_details)="row">
                                                <a href="javascript:"  @click="onRowClicked(row.item)">
                                                    <span @click="getTalentIdentified(row.item.id)">
                                                        <i class="ti-arrow-circle-down"></i>
                                                    </span>
                                                </a>
                                            </template>
                                            <template #row-details="row">
                                                <div v-if="reportData.candidateFieldList.length" class="table-responsive">
                                                    <b-table striped hover show-empty thClass="bg-info" head-variant="info" :items="list.candidatelist" :fields="options.tableFields.candidateFieldList">
                                                        <template #cell(s_no)="row">
                                                            <span>{{row.index+1}}</span>
                                                        </template>
                                                        <template v-slot:cell(email)="row">
                                                            <span v-if="(ttClientAccountId == row.item.ttClientAccountId || row.item.viewed==1)" class="">{{row.item.email}}</span>
                                                         </template>
                                                         <template v-slot:cell(mobile)="row">
                                                            <span v-if="(ttClientAccountId == row.item.ttClientAccountId || row.item.viewed==1)" class="">{{row.item.mobile}}</span>
                                                         </template>
                                                         <template v-slot:cell(sourcingRefLink)="row">
                                                            <span v-if="(ttClientAccountId == row.item.ttClientAccountId || row.item.viewed==1)" class="">{{row.item.sourcingRefLink}}</span>
                                                         </template>
                                                        <template v-slot:empty="scope">
                                                            <label class="text-center w-100">No data found</label>
                                                        </template>
                                                    </b-table>
                                                </div>
                                                <div v-if="!reportData.candidateFieldList.length" class="row col-md-12 mx-0">
                                                    <div class="card-header w-100 text-center">Sorry, there is no data to be displayed. Please select child table column headers to view the report</div>
                                                </div>
                                            </template>
                                            <template v-slot:empty="scope">
                                                <label class="text-center w-100">No data found</label>
                                            </template>
                                        </b-table>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <b-pagination v-if="list.joblist.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadJobs()"></b-pagination>
                                </div>
                            </div>
                            <div v-if="!reportData.jobFieldList.length" class="row col-md-12 mx-0">
                                <div class="card-header w-100 text-center">Sorry, there is no data to be displayed. Please select main table column headers to view the report</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal ref="reportFormModal" id="reportFormModal" :hide-footer="true" title="Custom Report">
            <div class="row col-md-12 mx-0 px-0">
                <div class="col-md-8 px-0 offset-md-2">
                    <div class="row col-md-12">
                        <label>Report Name</label>
                    </div>
                    <div class="row col-md-12">
                        <input class="form-control" type="text" name="reportName" v-model="reportData.reportName" />
                    </div>
                    <div class="row col-md-12">
                        <label v-if="$v.reportData.reportName.$error && !$v.reportData.reportName.required" class="text-danger">Report Name is required</label>
                        <label v-if="$v.reportData.reportName.$error && !$v.reportData.reportName.minLength" class="text-danger">Report Name must have at least {{$v.reportData.reportName.$params.minLength.min}} letters.</label>
                        <label v-if="$v.reportData.reportName.$error && !$v.reportData.reportName.maxLength" class="text-danger">Report Name must not exceed {{$v.reportData.reportName.$params.maxLength.max}} letters.</label>
                        <label v-if="$v.reportData.jobFieldList.$error && !$v.reportData.jobFieldList.required" class="text-danger">Master Table Fields are required. Please choose some fields.</label>
                        <label v-if="$v.reportData.candidateFieldList.$error && !$v.reportData.candidateFieldList.required" class="text-danger">Detail Table Fields are required. Please choose some fields.</label>
                    </div>
                </div>
                <div class="row col-md-10 justify-content-end py-3">
                    <a href="javascript:" class="btn btn-primary ml-2" @click="saveReport">Save</a>
                    <a href="javascript:" class="btn btn-primary ml-2" @click="$refs.reportFormModal.hide()">Cancel</a>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import { required, minLength, maxLength, email, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        action: Object,
    },
    components: {
        Multiselect
    },
    computed: {
        ttClientAccountId() {
            if (this.$store.getters.currentUser.ttClientAccountId)
                return this.$store.getters.currentUser.ttClientAccountId;
            else return 0;
        }
    },
    watch: {
        'search.params.startDate': {
            handler: function(value) {
                this.disabledendDates.to = new Date(value);
            },
            deep: true,
            immediate: true
        },
    },
    data() {
        return {
            elements: {
                isExpand: false,
                showadvancedsearch: false,
                currentExpandedId: null,
            },
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            options: {
                searchlimit: this.$store.state.searchlimit,
                industrylist: this.$store.getters.getIndustries,
                clientlist: this.$store.getters.getClients,
                jobStatuslist: this.$store.getters.getJobStatus,
                recruiterlist: this.$store.getters.getRecruiterList,
                fieldList: {
                    candidateFieldList: [],
                    jobFieldList: []
                },
                tableFields: {
                    candidateFieldList: [],
                    jobFieldList: []
                }
            },
            search: {
                params: {
                    jobTitle: "",
                    jobType: "",
                    technologyId: "",
                    startDate: "",
                    endDate: "",
                    clientId: "",
                    industryId: "",
                    status: "",
                    recId: "",
                    reportFields: []
                }
            },
            list: {
                joblist: [],
                candidatelist: []
            },
            reportData: {
                id: 0,
                reportName: "",
                reportTypeId: 3,
                customReportFields: [],
                jobFieldList: [],
                candidateFieldList: []
            },
            candContactAccess: localStorage.getItem('candidateContactAccess'),
            disabledStartDates: {
                from: new Date()
            
            },
            disabledendDates:{
                from: new Date(),
                to: ''
            }
        }
    },
    validations: {
        reportData: {
            reportName: { required, minLength: minLength(3), maxLength: maxLength(100) },
            jobFieldList: { required },
            candidateFieldList: { required }
        }
    },
    mounted() {
        this.$emit('updateLogActivity');
        this.$emit('addLogActivity','Recruitment Custom Report'); 
        this.loadDate();
        this.loadJobs();        
        this.loadFieldList(1); // Candidate Field List
        this.loadFieldList(2); // Job Field List
        /*this.options.fieldList.candidateFieldList = this.loadFieldList(1).then(function(value){
            return value;
        });
        this.options.fieldList.jobFieldList = this.loadFieldList(2).then(function(value){
            return value;
        });*/
        if (this.action.mode == 'edit' || this.action.mode == 'view') {
            this.loadCustomReport(this.action.id);
        }
    },
    methods: {
        onRowClicked(item) {
            const { detailsRow } = this
            if (detailsRow && detailsRow !== item) {
                detailsRow._showDetails = false;
            }
            this.$set(item, "_showDetails", !item._showDetails);
            this.detailsRow = item;
        },
        resetJobSearch() {
            this.search.params = {
                jobTitle: "",
                jobType: "",
                technologyId: "",
                recruiterId: this.recruiterId,
                clientId: "",
                industryId: "",
                status: "",
                recId: "",
                startDate: "",
                endDate: ""
            };
            this.loadDate();
            this.disabledStartDates.from =  new Date();
            this.disabledendDates.from =  new Date();
            this.loadJobs();
        },
        loadDate() {
            let date = new Date();
            this.search.params.startDate = date;
            this.search.params.endDate = date;
        },
        loadFieldList: function(reportTypeId) {
            return new Promise((resolve, reject) => {
                axios.get(this.$store.getters.getAPIBasePath + '/commonresource/fieldlist/' + reportTypeId)
                    .then(response => {
                        if (response.data.status) {
                            if (reportTypeId == 1) {                               
                                this.options.fieldList.candidateFieldList = response.data.fieldlist;
                                console.log(this.options.fieldList.candidateFieldList);
                                 if (this.candContactAccess == 0) {
                                    this.options.fieldList.candidateFieldList  = this.filterReportFields("email", "mobile");
                                    this.options.fieldList.candidateFieldList  = this.filterReportFields("candidateSource","sourcingRefLink");
                                }
                                console.log(this.options.fieldList.candidateFieldList);
                            } else if (reportTypeId == 2) {
                                // let fieldset = response.data.fieldlist.unshift({key: "show_details", label: "Details"});
                                this.options.fieldList.jobFieldList = response.data.fieldlist;
                            }
                            // resolve(response.data.fieldlist);
                        } else {
                            if (reportTypeId == 1) {
                                this.options.fieldList.candidateFieldList = [];
                            } else if (reportTypeId == 2) {
                                this.options.fieldList.jobFieldList = [];
                            }
                            // resolve([]);
                            iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        if (reportTypeId == 1) {
                            this.options.fieldList.candidateFieldList = [];
                        } else if (reportTypeId == 2) {
                            this.options.fieldList.jobFieldList = [];
                        }
                        // resolve([]);
                    });
            });
        },
        filterReportFields(field1, field2) {        
            return this.options.fieldList.candidateFieldList.filter(function(data, key) {
                if (data.key != field1 && data.key != field2) {
                    return data;
                }
            });
        },
        loadJobs() {
            if (this.search.params.startDate > this.search.params.endDate) {
                iziToast.error({
                    title: 'Error',
                    message: this.$config.DateMismatchErr,
                    position: 'topRight'
                });
                return;
            }
            this.search.params.pageNo = this.pagination.currentPage;
            this.search.params.limit = this.pagination.rowsPerPage;
            axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/jobreport' , this.search.params)
           // axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/jobreport?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    //this.list.joblist = result.jobs;
                    this.list.joblist = result.data;
                    // this.pagination.totalRecords = result.pagination[0].total;
                    // this.pagination.currentPage = result.pagination[0].currentPage;
                    this.pagination.totalRecords = result.totalRecords;
                    this.pagination.currentPage = result.currentPage;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadCustomReport: function(id) {
            this.reportData.id = id;
            axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/view/custom/report', this.reportData)
                .then(response => {
                    this.reportData.reportName = response.data.customReport.reportName;
                    this.reportData.reportTypeId = response.data.customReport.reportTypeId;
                    this.reportData.jobFieldList = response.data.customReport.settingsReportFields.filter(field => field.reportTypeId == 2);
                    this.reportData.candidateFieldList = response.data.customReport.settingsReportFields.filter(field => field.reportTypeId == 1);
                    // Defining Table Fields with S.No & Show Details
                    this.options.tableFields.jobFieldList.unshift({ key: "show_details", label: "Details" });
                    this.options.tableFields.candidateFieldList.unshift({ key: "s_no", label: "S.No." });
                    this.options.tableFields.jobFieldList = this.options.tableFields.jobFieldList.concat(this.reportData.jobFieldList);
                    this.options.tableFields.candidateFieldList = this.options.tableFields.candidateFieldList.concat(this.reportData.candidateFieldList);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        changeLimit(newlimit) {
            this.pagination.rowsPerPage = newlimit;
            this.pagination.currentPage = 1;
            this.loadJobs();
        },
        filterJobs(){
            this.pagination.currentPage=1;
            this.loadJobs();
        },
        saveReport: function() {
            if (!this.validateReportData()) {
                return false;
            }
            this.reportData.customReportFields = this.reportData.jobFieldList.concat(this.reportData.candidateFieldList);
            let axiosUrl = this.reportData.id ? '/reportsresource/update/custom/report' : '/reportsresource/add/custom/report';
            axios.post(this.$store.getters.getAPIBasePath + axiosUrl, this.reportData)
                .then(response => {
                    let result = response.data;
                    if (result.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                        this.$refs.reportFormModal.hide();
                        this.$emit('setSubTab', 0, 'list', null);
                    } else {
                        iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        validateReportData: function() {
            this.$v.reportData.$touch();
            if (this.$v.reportData.$invalid) {
                return false;
            }
            return true;
        },
        getTalentIdentified(jobId) {
            axios.get(this.$store.getters.getAPIBasePath + '/reportsresource/job/identified/candidates/' + jobId)
                .then(response => {
                    this.list.candidatelist = response.data.identifiedCandidates;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        selectMasterTableFields: function(option) {
            if (!this.reportData.jobFieldList.length)
                this.options.tableFields.jobFieldList.unshift({ key: "show_details", label: "Details" });
            this.options.tableFields.jobFieldList.push(option);
        },
        removeMasterTableFields: function(option) {
            this.options.tableFields.jobFieldList = this.options.tableFields.jobFieldList.filter(field => field.key != option.key)
            if (this.options.tableFields.jobFieldList.length == 1)
                this.options.tableFields.jobFieldList.pop();
        },
        selectDetailTableFields: function(option) {
            if (!this.reportData.candidateFieldList.length)
                this.options.tableFields.candidateFieldList.unshift({ key: "s_no", label: "S.No." });
            this.options.tableFields.candidateFieldList.push(option);
        },
        removeDetailTableFields: function(option) {
            this.options.tableFields.candidateFieldList = this.options.tableFields.candidateFieldList.filter(field => field.key != option.key)
            if (this.options.tableFields.candidateFieldList.length == 1)
                this.options.tableFields.candidateFieldList.pop();
        },
        /*toggleDetails(row) {
            console.log(row)
            if(row._showDetails) {
                this.$set(row, '_showDetails', false)
            } else {
                this.currentItems.forEach(item => {
                    this.$set(item, '_showDetails', false)
                })
                this.$nextTick(() => {
                    this.$set(row, '_showDetails', true)
                })
            }
        },*/
        exportRecruitment() {

            let jFieldList = this.reportData.jobFieldList.filter(field => field.key != "show_details");
            let cFieldList = this.reportData.candidateFieldList.filter(field => field.key != "s_no");
            this.search.params.reportFields = jFieldList.concat(cFieldList);
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/recruitment/report?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "Jobs" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });
        },
        exportAllRecruitment() {

            let jFieldList = this.reportData.jobFieldList.filter(field => field.key != "show_details");
            let cFieldList = this.reportData.candidateFieldList.filter(field => field.key != "s_no");
            this.search.params.reportFields = jFieldList.concat(cFieldList);
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/new/export/recruitment/report?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "Jobs" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });
        }
    }
}

</script>
