import Home from '@/components/home/Home';
import Services from '@/components/home/Services';
import Pricing from '@/components/home/Pricing';
import PrivacyPolicy from '@/components/home/PrivacyPolicy';
import TermsCondition from '@/components/home/TermsCondition';
import MobilePrivacyPolicy from '@/components/home/PrivacyPolicyMobile';
import MobileTermsCondition from '@/components/home/TermsConditionMobile';
import Contact from '@/components/home/Contact';
import InterviewerLanding from '@/components/home/interviewer/InterviewerLanding';
import InterviewerRegister from '@/components/home/interviewer/InterviewerRegister';
import Activation from '@/components/home/interviewer/Activation';
import ClientAccountRegister from '@/components/home/ClientAccountRegister';
import AddJob from '@/components/home/job/AddJob';
import ListJobs from '@/components/home/job/ListJobs';
import CandidateRegister from '@/components/home/candidate/Register';
import ReferralRegister from '@/components/home/referral/ReferralRegister';
import IntermediatePage from '@/components/home/login/IntermediatePage';
import ShareJobView from '@/components/home/job/ShareJobView';
import ConfirmRequest from '@/components/home/interviewer/InterviewRequest';
import ForgotPassword from '@/components/home/ForgotPassword';
import ResetPassword from '@/components/home/ResetPassword';
import Confirmoffer from '@/components/home/candidate/Confirmoffer';
export default [{
        path: '/',
        name: 'home',
        component: Home,
        meta: { layout: 'home' }
    },
    {
        path: '/services',
        name: 'services',
        component: Services,
        meta: { layout: 'home' }
    },
    {
        path: '/pricing',
        name: 'pricing',
        component: Pricing,
        meta: { layout: 'home' }
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: { layout: 'home' }
    },
    {
        path: '/privacy-policy',
        name: 'privacypolicy',
        component: PrivacyPolicy,
        meta: { layout: 'home' }
    },
    {
        path: '/terms-of-service',
        name: 'termsofservice',
        component: TermsCondition,
        meta: { layout: 'home' }
    },
    {
        path: '/interviewer/onboarding',
        name: 'invlanding',
        component: InterviewerLanding,
        meta: { layout: 'home' }
    },
    {
        path: '/interviewer/register/:key',
        name: 'invregister',
        component: InterviewerRegister,
        meta: { layout: 'login' }
    },
    {
        path: '/interviewer/activation/:key',
        name: 'invactivation',
        component: Activation,
        meta: { layout: 'login' }
    },
    {
        path: '/recruiter/activation/:key',
        name: 'invactivation',
        component: Activation,
        meta: { layout: 'login' }
    },
    {
        path: '/admin/register',
        name: 'clientaccountregister',
        component: ClientAccountRegister,
        meta: { layout: 'login' }
    },
    {
        path: '/job/add/:key',
        name: 'addjob-publicportal',
        component: AddJob,
        meta: { layout: 'login' }
    },
    {
        path: '/job/list',
        name: 'listjobs-publicportal',
        component: ListJobs,
        meta: { layout: 'login' }
    },
    {
        path: '/candidate/register/:key/:jobId?',
        name: 'candidateregister-publicportal',
        component: CandidateRegister,
        meta: { layout: 'login' }
    },
    {
        path: '/referral/register/:key',
        name: 'referralregister',
        component: ReferralRegister,
        meta: { layout: 'login' }
    },
    {
        path: '/referral/activation/:key',
        name: 'invactivation',
        component: Activation,
        meta: { layout: 'login' }
    },
    {
        path: '/share/job/view/:title/:jobcode/:key',
        name: 'sharejobview',
        component: ShareJobView,
        meta: { layout: 'login' }
    },
    {
        path: '/interviewer/confirmrequest/:type/:key',
        name: 'confirmrequest',
        component: ConfirmRequest,
        meta: { layout: 'login' }
    },
    {
        path: '/forgotpassword',
        name: 'forgotpassword',
        component: ForgotPassword,
        meta: { layout: 'login' }
    },
    {
        path: '/candidate/confirmoffer/:type/:key',
        name: 'confirmoffer',
        component: Confirmoffer,
        meta: { layout: 'login' }
    },
    {
        path: '/resetpassword/:userid',
        name: 'resetpassword',
        component: ResetPassword,
        meta: { layout: 'login' }
    },
    {
        path: '/login/validation/:key',
        name: 'intermediatepage',
        component: IntermediatePage,
        meta: { layout: 'login' }
    },
    {
        path: '/mobile-privacy-policy',
        name: 'mobileprivacypolicy',
        component: MobilePrivacyPolicy,
        meta: { layout: 'mobile' }
    },
    {
        path: '/mobile-terms-of-service',
        name: 'mobiletermsofservice',
        component: MobileTermsCondition,
        meta: { layout: 'mobile' }
    },
];