<template>
    <div id="main" class="mt-70">
        <div id="quick-search" class="container-fluid mtb-4 bg-white p-tb-15">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row no-gutters">
                    <b-tabs  v-model="currentTab" small card class="tab-list driver-tab-list tab-bg">
                        <b-tab title="Client Account">
                            <ListClientAccount/>
                        </b-tab>
                        <b-tab title="Client Activity">
                            <ListClientActivity/>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ListClientAccount from '@/components/admin/account/ListClientAccount.vue';
import ListClientActivity from '@/components/admin/account/ListClientActivity.vue';

export default {
    components : {
        ListClientAccount,
        ListClientActivity
    },
    data() {
        return {
            currentTab:0,
        }
    },
    mounted:function(){
        
    },
    methods:{
        changeTab: function(tab){
            this.currentTab=tab;
        },
    }
}
</script>