<template>
    <div id="main" class="tech-skill-settings settings recruiter">
        <div class="row col-md-12 px-0 py-4">
            <div class="col-md-4 offset-md-4">
                <h4 class="">Qualification</h4>
            </div>
            <div class="col-md-1 offset-md-3">
                <a href="Javascript:void(0);" class="pull-right btn-back" @click="$emit('setSubTab',0)">
                    <!-- <i class="ti-arrow-left"></i> Back -->
                    <h5 class="text-blue"><span title="Go Back" class="ti-arrow-left py-4"></span><span class="pl-2">Back</span></h5>
                </a>
            </div>
        </div>
        <div class="row" v-if="editIndex<0">
            <div class="col-md-6 col-sm-8 col-6">
                <!-- <multiItemInput id="multiTech" placeholder="Enter Qualification" :items="newQualificationList" class="float-left"></multiItemInput> -->
                <input class="form-control" placeholder="Enter Qualification" 
                v-model="newQualification.qualificationName" />
                <p v-if="formErrors.length">
                    <ul>
                        <li class="error" v-for="error in formErrors">{{ error.message }}</li>
                    </ul>
                </p>
            </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                <a href="Javascript:void(0);" @click="addQualification">
                    <span class="ti-plus"></span>
                </a>
            </div>
        </div>
        <div class="row" v-if="editIndex!=-1">
            <div class="col-md-6 col-sm-8 col-6">
                <input class="form-control" placeholder="Enter Qualification" v-model="newQualification.qualificationName" />
            </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                <a style="float:left; margin-right:5px;" href="Javascript:void(0);" @click="updateQualification(1)">
                    <span class="ti-check"></span>
                </a>
                <a style="float:left;" href="Javascript:void(0);" @click="updateQualification(0)">
                    <span class="ti-close"></span>
                </a>
            </div>
        </div>
        <div class="row item" v-for="(qualificationInfo, index) in settingsQualificationList" v-bind:key="index">
            <div class="col-md-6 col-sm-8 col-6">
                <div class="form-control">{{qualificationInfo.qualificationName}}</div>
            </div>
            <div class="col-md-1 col-sm-4 col-1 pt-2">
                 <a href="javscript:void(0);"  @click="removeQualification(index,qualificationInfo.id)" data-toggle="modal" data-target="#removQualification" title="Remove" class="px-2"><span class="ti-trash"></span></a>
                <a style="float:left; margin-right:5px;" href="Javascript:void(0);" @click="editQualification(index)">
                    <span class="ti-pencil"></span>
                </a>
            </div>
        </div>
        <Dialog id="removQualification" :onYes="deleteQualification" :returnParams="delParam" />
    </div>
</template>
<style scoped>
div.item {
    margin-top: 5px;
}

</style>
<script>
import multiItemInput from "@/components/plugins/multiItemInput";
import iziToast from "izitoast";

export default {
    components: {
        multiItemInput
    },
    data() {
        return {
            formErrors: [],
            editIndex: -1,
            settingsQualificationList: [],
            qualificationInfo: {
                qualificationName: ""
            },
            newQualificationList: [],
            delParam: {
                index: -1,
                id: 0
            },
            //editSourcingChannelList: [],
            //sourcingChannelIndex: -1,
            //selectedSourcingChannel: "",
            newQualification: { id: 0, qualificationName: "" },
            browsepagination: this.$store.state.paginationconfig,
            searchlimit: this.$store.state.searchlimit,
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    qualificationName: ""
                }
            }
        };
    },
    mounted() {
        this.loadQualification();
    },
    methods: {
        loadQualification() {
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/settingsresource/settingqualification/list")
                .then(response => {
                    let result = response.data;
                    console.log("result", result);
                    this.settingsQualificationList = result.settingsQualification;
                  /*  this.browsepagination.totalrecords = result.pagination[0].total;
                    this.browsepagination.currentpage = result.pagination[0].currentPage;
                    this.browsepagination.totalpages = result.pagination[0].lastPage;*/
                    this.newQualification= { id: 0, qualificationName: "" };
                })
                .catch(error => {
                    iziToast.error({
                        title: "Error",
                        message: error,
                        position: "topRight"
                    });
                });
        },
        addQualification() {
            //let sourcingChannelList = this.newQualificationList.map(
              //  qualificationName => ({ qualificationName: qualificationName })
            //);            
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/settingsresource/add/settingqualification", this.newQualification
                )
                .then(response => {
                    this.qualificationInfo.qualificationName = "";                   
                    this.newQualification= "";
                    this.formErrors = [];
                    if(response.data.status == true) {
                        iziToast.success({
                            title: "Success",
                            message: response.data.message,
                            position: "topRight"
                        });
                    }
                    else {
                        iziToast.info({
                            title: "Info",
                            message: response.data.message,
                            position: "topRight"
                        });
                    }
                    this.loadQualification();
                })
                .catch(error => {
                    this.formErrors = error.response.data.validationErrorList;
                });
            //}
        },       
        editQualification(index) {
            this.editIndex = index;
            this.newQualification= {
                id: this.settingsQualificationList[index].id,
                qualificationName: this.settingsQualificationList[index].qualificationName,
                qualificationList : [ {qualificationName : 1} ] //because of validation not null
            };
            console.log(this.newQualification);
        },
         removeQualification:function(arg1, arg2)
            {
                this.delParam.index=arg1;
                this.delParam.id=arg2;
            },
        deleteQualification:function(arg)
            { 
            axios.delete(this.$store.getters.getAPIBasePath + '/settingsresource/delete/qualification/' + this.delParam.id)
                .then(response => {
                    let result = response.data;
                    if (result.status == true) {
                        iziToast.success({
                            title: "Success",
                            message: this.$config.QualDelSuc,
                            position: "topRight",
                            timeout: 1000
                        });
                        this.settingsQualificationList.splice(arg.index,1);
                    }else{
                        iziToast.error({
                            title: "Error",
                            message: this.$config.QualDelErr,
                            position: "topRight"
                        });
                    }
                })
                .catch(function(error) {
                    iziToast.error({ title: 'Error', message: this.$config.ErrMsg, position: 'topRight' });
                    console.log(error);
                });

        },
        updateQualification(mode) {
            console.log(this.newQualification);
            if (mode == 1) {
                axios
                    .post(
                        this.$store.getters.getAPIBasePath +
                        "/settingsresource/update/settingqualification",
                        this.newQualification
                    )
                    .then(response => {                       
                        this.editIndex = -1;
                        this.newQualification= { id: 0, qualificationName: "" };
                       
                        if(response.data.status == true) {
                            iziToast.success({
                                title: "Success",
                                message: this.$config.QualUpSuc,
                                position: "topRight"
                            });
                        }
                        else {
                            iziToast.info({
                                title: "Info",
                                message: response.data.message,
                                position: "topRight"
                            });
                        }
                        this.loadQualification();
                    })
                    .catch(error => {
                        iziToast.error({
                            title: "Error",
                            message: error,
                            position: "topRight"
                        });
                    });
            } else {
                this.editIndex = -1;
                this.newQualification= { id: 0, qualificationName: "" };
            }
        }
    }
};

</script>
