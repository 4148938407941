<template>
    <div class="">
        <div class="card">
            <div class="card-header p-2">Edit Plan</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Plan</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <select class="form-control" name="plan" @change="updateCount(planInfo.planId)" v-model="planInfo.planId">
                                        <option v-for="plan in planList" v-bind:key="plan.planId" :value="plan.planId" :disabled="plan.servicePlan=='Trial'">{{plan.servicePlan}}</option>                                    </select>
                                </div>
                            </div>
                        </div>
                        <div v-if="false" class="col-md-3 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Remaining View Resume Count</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="number" name="viewCount" v-model="planInfo.viewResumeCount" maxlength="45" size="30" class="form-control" @keypress="onlyNumber" :disabled="disableviewCount"/>
                                    </div>
                                </div>     
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Remaining Download Resume Count</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="number" name="downloadCount" v-model="planInfo.downloadResumeCount" maxlength="45" size="30" class="form-control" @keypress="onlyNumber" :disabled="disabledownloadCount"/>
                                    </div>
                                </div>     
                            </div>
                        </div>
                        <div v-if="false" class="col-md-3 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Remaining Share Resume count</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="number" name="shareCount" v-model="planInfo.shareResumeCount" maxlength="45" size="30" class="form-control" @keypress="onlyNumber" :disabled="disableshareCount"/>
                                    </div>
                                </div>     
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="card-header p-2"><a href="javascript:" @click="saveRecruiter" class="btn btn-success float-right">Save</a></div>
        </div>
    </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import Select2 from 'v-select2-component';
import iziToast from 'izitoast';
import moment from 'moment';
const usPhone = helpers.regex('usPhone', /^\(\d{3}\)\s\d{3}-\d{4}$/);

export default {
    computed: {
        accountid() {
            return this.$route.params.accountid;
        }
    },
    components: {
        Select2
    },
    data() {
        return {
            elements: {
                submitted: false
            },
            accountType: this.$store.getters.getAccountTypeOptions,
            candContactAccess: this.$store.getters.getCandContactAccess,
            planList: [],
            disableviewCount: false,
            disabledownloadCount: false,
            disableshareCount: false,
            currentDate:"",
            expDate:"",
            planInfo: {
                viewResumeCount: "",
                downloadResumeCount: "",
                shareResumeCount: "",
                planName: "",
                recruiterCode: "",
                referralId: "",
                firstName: "",
                lastName: "",
                accountName: "",
                companyId: "",
                companyName: "",
                accountType: "2",
                companyEmail: "",
                companyPhoneno: "",
                companyZipcode: "",
                companyCountry: "US",
                timezone: "",
                planId: "",
                candidateContactAccess: "",
                expiryDate: ""
            },
        }
    },
    
    mounted() {
        this.loadByAccountId();
        this.loadServicePlan();
         
    },
    methods: {
        onlyNumber($event) {
            //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
       showDate()
                {  
                    var currentdate = moment().format('DD MMMM YYYY');
                    const dateIsBefore = moment(this.planInfo.expiryDate).isBefore(moment().format('DD MMMM YYYY'));
                    this.expDate = dateIsBefore
                },
        updateCount(id){
            if(id == 1){
                // this.planInfo.viewResumeCount = '100',
                this.planInfo.downloadResumeCount = '50'
                // this.planInfo.shareResumeCount = '',

            }else if(id == 2){
                // this.planInfo.viewResumeCount = '',
                this.planInfo.downloadResumeCount = '100'
                // this.planInfo.shareResumeCount = '100',

            }else if(id == 3){
                // this.planInfo.viewResumeCount = '',
                this.planInfo.downloadResumeCount = '200'
                // this.planInfo.shareResumeCount = '300',
            }else if(id == 4){
                // this.planInfo.viewResumeCount = '',
                this.planInfo.downloadResumeCount = '300'
                // this.planInfo.shareResumeCount = '300',
            }
        },
        loadByAccountId() {
            axios.get(this.$store.getters.getAPIBasePath + '/recruiterresource/client/account/view/' + this.accountid)
                .then(response => {
                    let result = response.data;
                    if (!result.status) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$router.push('/candidates');
                    }
                    if (result.clientAccount) {
                        this.planInfo = {
                            viewResumeCount: (result.clientAccount.viewResumeCount == 0 ? '' : result.clientAccount.viewResumeCount),
                            downloadResumeCount: (result.clientAccount.downloadResumeCount == 0 ? '' : result.clientAccount.downloadResumeCount),
                            shareResumeCount: (result.clientAccount.shareResumeCount == 0 ? '' : result.clientAccount.shareResumeCount),
                            planName: result.clientAccount.planName,
                            companyId: result.clientAccount.companyId,
                            planId: result.clientAccount.planId,
                            expiryDate: result.clientAccount.expiryDate
                        }
                    }
                    // if(result.clientAccount.planId == 1){
                    //     this.planInfo.downloadResumeCount = "",
                    //     this.planInfo.shareResumeCount = "",
                    //     this.disableviewCount = false,
                    //     this.disabledownloadCount = true,
                    //     this.disableshareCount = true
                    // }
                    // if(result.clientAccount.planId == 2){
                    //     this.planInfo.viewResumeCount = "",
                    //     this.disableviewCount = true,
                    //     this.disabledownloadCount = false,
                    //     this.disableshareCount = false
                    // }
                    // if(result.clientAccount.planId == 3){
                    //     this.planInfo.viewResumeCount = "",
                    //     this.disableviewCount = true,
                    //     this.disabledownloadCount = false,
                    //     this.disableshareCount = false
                    // }
                    this.showDate()
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadServicePlan() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/service/plan/list')
                .then(response => {
                    let result = response.data;
                    this.planList = result.servicePlanList;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        saveRecruiter: function() {
            this.elements.submitted = true;
            axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/update/client/account/' + this.accountid, this.planInfo)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message: this.$config.cliupdatesuc, position: 'topRight' });
                    } else {
                        iziToast.error({ title: 'Error', message: this.$config.Updateerr, position: 'topRight' });
                    }
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.Updateerr, position: 'topRight' });
                });
            this.elements.submitted = false;
            this.$router.push('/admin/clientaccount');
        },
    }
}

</script>
