<template>
	<div id="main" class="mt-70">
		<div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
			<div class="driver-model" style="padding:0px 0px 10px;">
				<div class="row">
					<div class="col-sm-3">
						<div class="row">
							<div class="col-sm-12">
								<h5 style="background-color: #37a0e4;color: #fff;padding: 5px; " class="">
									Candidate Information
									<router-link class="pull-right" style="color:white;" :to="{ name: 'adminlistcandidate' }">
										<i class="fa fa-arrow-left"></i>
										<span> Back</span>
									</router-link>
								</h5>
								<div class="card border-5 mb-3">
									<div class="user-card pl-5 pr-5	">
									  	<div class="information">
									  		<div class="imgUp">
												<div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + personalinfo.imagePath + '?' + profilePhotoArg + ')' }"></div>
												<label v-if="personalinfo.viewonly==0" class="upload-icon"><i class="ti-camera"></i><input type="file" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;"></label>
											</div>
											<div class="name">{{personalinfo.firstName}} {{personalinfo.lastName}}</div>
											<div>{{/*personalinfo.industry.industryName*/}} {{personalinfo.position}}</div>
										    <div class="position"><i class="ti-email"></i> {{personalinfo.email}}</div> 
										    <div class="position"><i class="ti-mobile"></i> {{personalinfo.mobile}}</div>
										   <!--  <div class="position"><i class="ti-mobile"></i> {{personalinfo.firstName}}, {{personalinfo.experience}} Y </div> -->
										    <h4>Profile Completion {{personalinfo.profileCompletion}}%</h4>

										    <div class="progress">
											    <div class="progress-bar" :style="applyPercentage(personalinfo.profileCompletion)" role="progressbar" aria-valuenow="personalinfo.profileCompletion" aria-valuemin="0" aria-valuemax="100" >
											    <span class="title">{{personalinfo.profileCompletion}}%</span>
											    </div>
											</div>
									  	</div>
									</div>
								</div>
								<div class="card border-5">
									<div class="user-card p-0">
										<h5 class="about-box m-0 text-left p-2">About Me</h5>
										<div class="p-3 float-left w-100">
									  	<h5>Specialization</h5>
									  	<div class="informations">

											<span v-for="(info, index) in technologyinfo.skillset" v-bind:key="index" class="p-1 mb-2 mr-1 badge " :class="colours[index%5]">
												<span v-if="checkEmpty(info.technologyName) ">
													<!-- {{technologyinfo[index].technologyName}} - {{technologyinfo[index].years}} y -->
													{{info.technologyName}}
												</span>
											</span>
									  		<!-- <span v-for="(info, index) in technologyinfo.skillset" class="p-1 mb-2 mr-1 badge " :class="colours[index%5]">{{technologyinfo.skillset[index].technologySet.technology}} - {{technologyinfo.skillset[index].years}} y
 											</span> -->
										</div>
										<div v-if="contactinfo.currentaddress.addressLine1!== null" class="w-100 float-left">
										<hr>
									  	<h5><i class="ti-location-pin"></i> Location</h5>
									  	<div class="information">
										  	<p> {{personalinfo.location}}. </p>
										</div> 
										</div>
										<div v-if="personalinfo.educationinfo.length!=0" class="w-100 float-left">
										<hr>
										<h5><i class="ti-briefcase"></i> Education</h5>
									  	<div class="information">
									  		<p v-for="(info, index) in personalinfo.educationinfo">
									  			{{personalinfo.educationinfo[index].degree}} - {{personalinfo.educationinfo[index].year}}, {{personalinfo.educationinfo[index].schoolName}}, {{personalinfo.educationinfo[index].city}}
									  		</p>
										</div>
										</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-9">
						<div class="row no-gutters">
		                    <b-tabs v-model="currentCandidateTab" small card class="tab-list driver-tab-list">
								<b-tab title="Primary Info">
									<PersonalInfo :personalinfo="personalinfo" ref="personalinfocomponent"></PersonalInfo>
								</b-tab>
								<b-tab title="Address Info">
									<ContactInfo :contactinfo="contactinfo" ref="contactinfocomponent"></ContactInfo>
								</b-tab>
								<b-tab title="Employment History">
									<EmploymentInfo :employmentinfo="employmentinfo" ref="employmentinfocomponent"></EmploymentInfo>
								</b-tab>
								<b-tab title="Reference Details">
									<ReferenceInfo :referenceinfo="employmentinfo"></ReferenceInfo>
								</b-tab>
								<b-tab title="Technology/Skills">
									<TechnologyInfo :technologyinfo="technologyinfo" ref="technologyinfocomponent"></TechnologyInfo>
								</b-tab>
								<b-tab title="Projects">
									<ProjectInfo :projectinfo="projectinfo" ref="projectinfocomponent"></ProjectInfo>
								</b-tab>
								<b-tab title="Preferences">
									<PreferenceInfo :preferenceinfo="preferenceinfo" ref="preferenceinfocomponent"></PreferenceInfo>
								</b-tab>
								<b-tab title="Availability" @click="refreshCalendar">
									<AvailabilityInfo v-if="showAvailabilityInfo" :availabilityinfo="availabilityinfo" ref="availabilityinfocomponent"></AvailabilityInfo>
								</b-tab>
								<b-tab title="Resume">
									<ResumeAttach :resumeinfo="resumeinfo" ref="resumeattachcomponent"></ResumeAttach>
								</b-tab>
								<b-tab title="Interviews">
									<Interviews v-if="!elements.feedback.showFeedback" @viewFeedback="viewFeedback" :interviews="interviews" ref="interviewscomponent"></Interviews>
									<Feedback v-if="elements.feedback.showFeedback" :recId="elements.feedback.recruitmentId" @closeFeedback="closeFeedback" />
								</b-tab>
							</b-tabs>
							<!-- <div class="col-md-12">
								<a href="Javascript:void(0);" @click="saveCurrentTab" class="btn btn-success btn-sm float-right" style="margin-left: 10px;color: #fff;">Save</a>
							</div> -->
		                </div>
		            </div>
		        </div>
			</div>
		</div>
	</div>
</template>
<style>
ul.skill-root > li
{
	margin-bottom: 15px;
}
ul.skill-root > li > span, ul.skill-root > li > ul > li > span
{
	color: #fff;
	background-color: #007bff;
	border-radius: .25rem;
	padding: 2px;
}
ul.skill-root > li > ul > li:first-child
{
	padding-left: 15px !important;
}
ul.skill-root > li > ul > li 
{
	display: inline-block;	
	margin: 4px 0 4px 2px;
}
ul.skill-root > li > ul > li > span
{
	background-color: #28a745 !important;
}
</style>
<script>
	import iziToast from 'izitoast';
	    import moment from 'moment';
	import PersonalInfo from './blocks/viewcandidate/PersonalInfo';
	import ContactInfo from './blocks/viewcandidate/ContactInfo';
	import EmploymentInfo from './blocks/viewcandidate/EmploymentInfo';
	import ReferenceInfo from './blocks/viewcandidate/ReferenceInfo';
	import TechnologyInfo from './blocks/viewcandidate/TechnologyInfo';
	import PreferenceInfo from './blocks/viewcandidate/PreferenceInfo';
	import AvailabilityInfo from './blocks/viewcandidate/AvailabilityInfo';
	import ResumeAttach from './blocks/viewcandidate/ResumeAttach';
	import ProjectInfo from './blocks/viewcandidate/ProjectInfo';
	import Interviews from './blocks/viewcandidate/Interviews';
	import Feedback from '../../../layouts/blocks/Feedback';
	export default {
		computed:{
	        candidateid(){
	            return this.$route.params.candidateid;
	        }
	    },
	    components : {
			PersonalInfo,
			ContactInfo,
			EmploymentInfo,
			ReferenceInfo,
			TechnologyInfo,
			PreferenceInfo,
			AvailabilityInfo,
			ResumeAttach,
			ProjectInfo,
			Interviews,
			Feedback
		},
	    data() {
			return {
				elements: {
					feedback: {
						recruitmentId: null,
						showFeedback: false
					}
				},
				currentCandidateTab: 0,
				maxtabs:6,
				personalinfo:{
					candidateCode:"",
					firstName:"",
					middlename:"",
					lastName:"",
					email:"",
					mobile:"",
					dateOfBirth:"",
					dateAvailable:"",
					industryName:"",
					recruiterName:"",
				    position:"",
				    location:"",
					experience:"",
					referredBy:"",
					payMode:"",
					clientId:"",
					visaTypeId:"",
					notes:"",
					educationinfo:[],
					blockpersonaltab:0,
					viewonly:1,
					profileCompletion:0,
					imageName:'',
					imagePath:'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
					candidateLanguage:[]
				},
				contactinfo:{
					currentaddress:{
						addressLine1:"",
						addressLine2:"",
						cityName:"",
						stateName:"",
						zipCode:"",
						countryId:"US"
					},
					permanentaddress:{
						addressLine1:"",
						addressLine2:"",
						cityName:"",
						stateName:"",
						zipCode:"",
						countryId:"US"
					},
					emergency:[],
					viewonly:1
				},
				employmentinfo:[],
				technologyinfo:{
					skillset:[],
					specialnotes:"",
					viewonly:1,
					Industry:""
				},
				preferenceinfo:{
					basepreference:[],
					otherpreference:{
						timetocall:"",
						parttime:"no",
						fulltime:"no"
					},
					viewonly:1
				},
				availabilityinfo:{
					viewmode:1
				},
				resumeinfo:{
					file:'',
					fileurl:'',
					filename:'',
					viewonly:1
				},
				projectinfo:[],
				interviews:[],
				Skills:[],
				showAvailabilityInfo:false,
				colours:['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info'],
				profilePhotoArg:1
			}
		},
		watch:{
			interviews(newValue,oldValue){
				let recId = parseInt(localStorage.getItem('recruitmentId'));
				if(recId){
					this.currentCandidateTab=9;
					this.viewFeedback(recId);
				}
			}
		},
	    mounted(){
	    	this.loadCandidateById(this.candidateid);
			console.log("Skills",this.Skills)
	    },
	    methods:{
			 checkEmpty(obj) {

		        if(obj != null && isNaN(obj) && obj.trim().length > 0){
		          return true;
		        }else if(obj != null && obj > 0){
		          return true;
		        }
		        return false;            
		     },
	    	applyPercentage(percentage){
	    		return 'max-width:'+percentage+'%'
	    	},
	        loadCandidateById(candidateId){
	            axios.get(this.$store.getters.getAPIBasePath+'/candidateresource/view/candidate/'+candidateId)
	            .then(response => {
	                let result = response.data;
	                if(!result.status){
	                	iziToast.error({
		                    title: 'Error Occured',
		                    message: result.message,
		                    position:'topRight'
		                });
		                this.$router.push('/adminlistcandidate');
	                }
	                let self = this;
	                this.personalinfo = result.candidates;
	                this.personalinfo = {
	                	candidateCode:result.candidates.candidateCode,
						firstName:result.candidates.firstName,
						middlename:result.candidates.middlename,
						lastName:result.candidates.lastName,
						email:result.candidates.email,
						mobile:result.candidates.mobile,
						dateOfBirth:result.candidates.dateOfBirth,
						dateAvailable:result.candidates.dateAvailable,
						industryName:result.candidates.industryName,
						recruiterName:result.candidates.recruiterName,
						position:result.candidates.position,
						location:result.candidates.location,
						experience:result.candidates.experience,
						referredBy:result.candidates.referredBy,
						payMode:(result.candidates.payMode==undefined)?'':result.candidates.payMode,
						clientId:(result.candidates.clientId==undefined)?'':result.candidates.clientId,
						visaTypeId:(result.candidates.visaTypeId==undefined)?'':result.candidates.visaTypeId,
						notes:result.candidates.notes,
						educationinfo:(result.candidates.candidateEducation==undefined)?'':result.candidates.candidateEducation,
						blockpersonaltab:0,
						viewonly:1,
						profileid:this.candidateid,
						profileCompletion:result.candidates.profileCompletion,
						imageName:result.candidates.imageName,
						imagePath:(result.candidates.imagePath==''?'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png':result.candidates.imagePath),
						candidateLanguage:result.candidates.candidateLanguage
					};
					result.candidates.candidateContact.forEach(function(data){
					    if(data.contactType.toLowerCase()=="current"){
					    	self.contactinfo.currentaddress = data;
					    }
					    else{
					    	self.contactinfo.permanentaddress = data;	
					    }
					});
					this.contactinfo.emergency = result.candidates.candidateEmergencyContact;
					this.employmentinfo = result.candidates.candidateEmployment;
					this.employmentinfo.viewonly = 1;
					
					this.projectinfo = result.candidates.candidateProject;
					this.projectinfo.viewonly = 1;
					/*this.projectinfo.forEach((info)=>{
						info['viewonly'] = 1;
					});*/

					let techId=0;
					let j=0;
					for(let i = 0 ; i<result.candidates.candidateSkill.length; i++)
					{
						if(result.candidates.candidateSkill[i].technologyId == techId )
						{
							console.log("d",result.candidates.candidateSkill)
							if(result.candidates.candidateSkill[i].title != null){
								this.Skills[j-1].techSkills.push({'skill':result.candidates.candidateSkill[i].title, 'exprYear': result.candidates.candidateSkill[i].years});
							}
						}
						else
						{
							techId = result.candidates.candidateSkill[i].technologyId;
                            if (result.candidates.candidateSkill[i].technologySet != null){
                                this.Skills.push({ 'techTitle':   result.candidates.candidateSkill[i].technologySet.technology, 'techSkills': [{ 'skill': result.candidates.candidateSkill[i].title, 'exprYear': result.candidates.candidateSkill[i].years }] });
                            }
							j++;
						}
					}
					this.technologyinfo.Industry = result.candidates.industryName;
					this.technologyinfo.skillset = result.candidates.techList;
					this.preferenceinfo.basepreference = result.candidates.candidatePreference;
					this.preferenceinfo.otherpreference.timetocall = result.candidates.timeToCall;
					if(result.candidates.isPartTime){
						this.preferenceinfo.otherpreference.parttime = "yes";
					}
					else{
						this.preferenceinfo.otherpreference.parttime = "no";	
					}
					if(result.candidates.isFullTime){
						this.preferenceinfo.otherpreference.fulltime = "yes";
					}
					else{
						this.preferenceinfo.otherpreference.fulltime = "no";	
					}
					if(result.candidates.fileName!=undefined && result.candidates.fileName!=""){
						this.resumeinfo.filename = result.candidates.fileName;
						this.resumeinfo.fileurl = result.candidates.filePath;
					}
					console.log("-----------contactinfo");
					console.log(this.contactinfo);
	            })
	            .catch(error => {
	            	console.log(error);
	                iziToast.error({
	                    title: 'Error Occured',
	                    message: this.$config.CandidLoadErr,
	                    position:'topRight'
	                });
	                this.$router.push('/admin/candidates');
	            });

		        axios.get(this.$store.getters.getAPIBasePath+'/interviewerresource/candidate/recruitment/'+candidateId)
	            .then(response => {
	                let result = response.data;
	                this.interviews = result.interviews;	           
	            })
	            .catch(error => {
	                console.log(error);
	            });
	        },
	        refreshCalendar() {
	        	this.showAvailabilityInfo=true;
	        },
			viewFeedback(recId) {
				this.elements.feedback.recruitmentId = recId;
				this.elements.feedback.showFeedback = true;
			},
			closeFeedback(){
				this.elements.feedback.showFeedback=false;
				if(parseInt(localStorage.getItem('recruitmentId'))){
					localStorage.setItem('recruitmentId',null);
					this.$router.push('/progress-interviews');
				}
			}
    	}
	}
</script>