<template>
    <div id="main" class="mt-70">
        <div id="quick-search" class="container-fluid mb-4 bg-white p-b-15">
            <a href="javascript:" @click="$router.go(-1);updateLogActivity();" title="Back" class="nav-link p-2">
                <i class="ti-arrow-left"></i> Back
            </a>
            <div class="driver-model pt-2">
                <div class="row no-gutters">
                    <b-tabs v-model="currentTab" small card class="tab-list driver-tab-list tab-bg">
                        <!-- <b-tab title="Pending Interviews">
                            <PendingInterviews ref="ProgressInterviews" />
                        </b-tab> -->
                        <b-tab title="Interviews InProgress" >
                            <ProgressInterviews />
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';

import ProgressInterviews from './blocks/interviews/progressInterviews';
import PendingInterviews from './blocks/interviews/pendingInterviews';

export default {
    components: {
        ProgressInterviews,
        PendingInterviews
    },
    data() {
        return {

            currentTab: 0,
        }
    },
    mounted: function() {

    },
    methods: {
        changeTab: function(tab) {
            this.currentTab = tab;
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
