<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div class="card border-5 mb-3">
                                    <div class="col-md-12 col-sm-12 px-0 about-box">
                                        <div class="col-md-8 px-0 float-left">
                                            <h6 class="m-0 text-left p-2">
                                                <a href="javascript:" @click="getPrevUrl()" title="Back" class="p-2">
                                                    <i class="fa fa-arrow-left"></i>
                                                    <span> Back</span>
                                                </a>
                                            </h6>
                                        </div>
                                        <div class="col-md-4 px-0 pull-right py-2 text-right pr-2">
                                            <a v-if="false" href="javascript:" @click="emailtab" title="Email To"><i class="ti-email px-1"></i></a>
                                            <a v-if="false" href="javascript:" v-b-modal.notes-form-modal @click="addCallback" title="Callback"><i v-if="false" class="fa fa-phone px-1"></i></a>
                                            <a v-if="false" href="javascript:" v-b-modal.notes-form-modal @click="addNotes" title="Add Notes"><i class="fa fa-sticky-note px-1"></i></a>
                                            <a v-if="false" href="javascript:" title="Schedule Interview" @click="scheduleInterview"><i class="fa fa-calendar px-1"></i></a>
                                            <a href="javascript:" title="Edit Profile" @click="editCandidate" class="pl-5"><i class="fa fa-pencil-square-o fa_custom fa-lg px-1"></i></a>
                                            <!-- <router-link class="link" title="Edit Candidate" :to="{ name: 'editcandidate'}"><i class="fa fa-pencil px-1"></i></router-link> -->
                                        </div>
                                    </div>
                                    <div class="user-card pl-5 pr-5 pb-2 pt-3">
                                        <div class="information">
                                            <div class="userimage-progress">
                                                <div class="progress-circle">
                                                    <vue-circle v-if="showProgress" :progress="personalinfo.profileCompletion" :size="50" :reverse="true" line-cap="round" :fill="fill" empty-fill="rgba(0, 0, 0, .1)" :animation-start-value="0.0" :start-angle="0" insert-mode="append" :thickness="5" :show-percent="true" @vue-circle-progress="progress" @vue-circle-end="progress_end">
                                                        <div class="imgUp">
                                                            <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + personalinfo.imagePath + '?' + profilePhotoArg + ')' }"></div>
                                                            <label v-if="personalinfo.viewonly==0" class="upload-icon"><i class="ti-camera"></i><input type="file" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;"></label>
                                                        </div>
                                                    </vue-circle>
                                                </div>
                                            </div>
                                            <div class="name">{{personalinfo.firstName}} {{personalinfo.lastName}}</div>
                                            <div>{{/*personalinfo.industry.industryName*/}} {{personalinfo.position}}</div>
                                            <div v-if="personalinfo.email" class="position"><i class="ti-email"></i> {{personalinfo.email}}</div>
                                            <div v-if="personalinfo.mobile" class="position"><i class="ti-mobile"></i> {{personalinfo.mobile}}</div>
                                            <!--  <div class="position"><i class="ti-mobile"></i> {{personalinfo.firstName}}, {{personalinfo.experience}} Y </div> -->
                                            <h4>Profile Completion {{personalinfo.profileCompletion}}%</h4>
                                            <div class="progress">
                                                <div class="progress-bar" :style="applyPercentage(personalinfo.profileCompletion)" role="progressbar" aria-valuenow="personalinfo.profileCompletion" aria-valuemin="0" aria-valuemax="100">
                                                    <span class="title">{{personalinfo.profileCompletion}}%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card border-5">
                                    <div class="user-card p-0">
                                        <h5 class="about-box m-0 text-left p-2">About Me</h5>
                                        <div class="p-3 float-left w-100">
                                            <h5>Specialization</h5>
                                            <div class="informations">
                                                <span v-for="(info, index) in technologyinfo" v-bind:key="index" class="p-1 mb-2 mr-1 badge " :class="colours[index%5]">
                                                    <span v-if="checkEmpty(technologyinfo[index].technologyName) ">
                                                        {{technologyinfo[index].technologyName}}
                                                    </span>
                                                </span>
                                            </div>
                                            <div v-if="contactinfo.currentaddress.addressLine1!== null" class="w-100 float-left">
                                                <hr>
                                                <h5><i class="ti-location-pin"></i> Location</h5>
                                                <div class="information">
                                                    <p> {{personalinfo.location}}. </p>
                                                </div>
                                            </div>
                                            <div v-if="personalinfo.educationinfo.length!=0" class="w-100 float-left">
                                                <hr>
                                                <h5><i class="ti-briefcase"></i> Education</h5>
                                                <div class="information">
                                                    <p v-for="(info, index) in personalinfo.educationinfo">
                                                        {{personalinfo.educationinfo[index].degree}} - {{personalinfo.educationinfo[index].year}}, {{personalinfo.educationinfo[index].schoolName}}, {{personalinfo.educationinfo[index].city}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-9">
                        <div class="row no-gutters text-break">
                            <b-tabs v-if="!elements.showEmailTools" v-model="currentCandidateTab" small card class="tab-list driver-tab-list">
                                <b-tab title="Primary Info">
                                    <PersonalInfo :personalinfo="personalinfo" ref="personalinfocomponent" :candContactAccess="candContactAccess" ></PersonalInfo>
                                </b-tab>
                                <b-tab title="Address Info">
                                    <ContactInfo :contactinfo="contactinfo" ref="contactinfocomponent" :candContactAccess="candContactAccess" ></ContactInfo>
                                </b-tab>
                                <b-tab title="Employment History">
                                    <EmploymentInfo :employmentinfo="employmentinfo" ref="employmentinfocomponent"></EmploymentInfo>
                                </b-tab>
                                <!-- <b-tab title="Reference Details">
                                    <ReferenceInfo :referenceinfo="employmentinfo"></ReferenceInfo>
                                </b-tab> -->
                                <b-tab title="Technology/Skills">
                                    <TechnologyInfo :technologyinfo="technologyinfo" ref="technologyinfocomponent"></TechnologyInfo>
                                </b-tab>
                                <b-tab title="Projects">
                                    <ProjectInfo :projectinfo="projectinfo" ref="projectinfocomponent"></ProjectInfo>
                                </b-tab>
                                <b-tab title="Preferences">
                                    <PreferenceInfo :preferenceinfo="preferenceinfo" ref="preferenceinfocomponent"></PreferenceInfo>
                                </b-tab>
                                <b-tab title="Availability" @click="refreshCalendar">
                                    <AvailabilityInfo v-if="showAvailabilityInfo" :candidateid="candidateid" :availabilityinfo="availabilityinfo" ref="availabilityinfocomponent"></AvailabilityInfo>
                                </b-tab>
                                <b-tab title="Resume">
                                    <ResumeAttach :resumeinfo="resumeinfo" :isResumeCapture="isResumeCapture" :attachments="attachments" :attachmentTypes="attachmentTypes" :multipleFile="multipleFile" ref="resumeattachcomponent"></ResumeAttach>
                                </b-tab>
                                <b-tab title="Interviews">
                                    <Interviews v-if="!elements.feedback.showFeedback" @viewFeedback="viewFeedback" :interviews="interviews" ref="interviewscomponent"></Interviews>
                                    <Feedback v-if="elements.feedback.showFeedback" :recId="elements.feedback.recruitmentId" @closeFeedback="closeFeedback" />
                                </b-tab>
                                <!-- <b-tab title="History">
                                    <ChangeHistory :changeHistoryInfo="changeHistoryInfo" ref="changeHistoryInfocomponent"></ChangeHistory>
                                </b-tab> -->
                                <b-tab title="Settings">
                                    <Settings :settingsInfo="settingsInfo" ref="settingscomponent"></Settings>
                                </b-tab>
                                <!-- <b-tab title="Incident History">
                                    <IncidentHistory ref="incidentHistoryComponent"></IncidentHistory>
                                </b-tab> -->
                                <!-- <b-tab @click="emailtab" title="Mail">
                                    <EmailTools v-if="elements.showEmailTools" :mailTo="{email: personalinfo.email, id: candidateid, name: personalinfo.firstName+' '+personalinfo.lastName}"></EmailTools>
                                </b-tab> -->
                            </b-tabs>
                            <EmailTools v-if="elements.showEmailTools" :mailTo="{email: personalinfo.email, id: candidateid, name: personalinfo.firstName+' '+personalinfo.lastName, userType: 'candidate'}" @closeEmailTools="elements.showEmailTools=false"></EmailTools>
                            <!-- <div class="col-md-12">
                                <a href="Javascript:void(0);" @click="saveCurrentTab" class="btn btn-success btn-sm float-right" style="margin-left: 10px;color: #fff;">Save</a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="notes-form-modal" ref="notesModal" title="Add Notes" @show="resetModal" @hidden="resetModal" @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Communication Mode" label-for="communcation-mode" invalid-feedback="Communication Mode is required">
                    <b-form-select id="communcation-mode" v-model="form.communcation_mode" required>
                        <option value="phone">Phone</option>
                        <option value="email">Email</option>
                    </b-form-select>
                </b-form-group>
                <b-form-group lable="Notes" label-for="notes" invalid-feedback="Notes is required">
                    <editor mode="wysiwyg" :html="form.notes" :options="$store.getters.editorOptions" />
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>
<style>
ul.skill-root>li {
    margin-bottom: 15px;
}

ul.skill-root>li>span,
ul.skill-root>li>ul>li>span {
    color: #fff;
    background-color: #007bff;
    border-radius: .25rem;
    padding: 2px;
}

ul.skill-root>li>ul>li:first-child {
    padding-left: 15px !important;
}

ul.skill-root>li>ul>li {
    display: inline-block;
    margin: 4px 0 4px 2px;
}

ul.skill-root>li>ul>li>span {
    background-color: #28a745 !important;
}

</style>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import PersonalInfo from '@/components/candidates/blocks/viewcandidate/PersonalInfo';
import ContactInfo from '@/components/candidates/blocks/viewcandidate/ContactInfo';
import EmploymentInfo from '@/components/candidates/blocks/viewcandidate/EmploymentInfo';
import ReferenceInfo from '@/components/candidates/blocks/viewcandidate/ReferenceInfo';
import TechnologyInfo from '@/components/candidates/blocks/viewcandidate/TechnologyInfo';
import PreferenceInfo from '@/components/candidates/blocks/viewcandidate/PreferenceInfo';
import AvailabilityInfo from '@/components/candidates/blocks/viewcandidate/AvailabilityInfo';
import ResumeAttach from '@/components/candidates/blocks/viewcandidate/ResumeAttach';
import ProjectInfo from '@/components/candidates/blocks/viewcandidate/ProjectInfo';
import Interviews from '@/components/candidates/blocks/viewcandidate/Interviews';
import EmailTools from '@/components/recruiters/blocks/tools/EmailTools';
import Settings from '@/components/candidates/blocks/addcandidate/Settings';

import Feedback from '../../layouts/blocks/Feedback';
//import 'tui-editor/dist/tui-editor.css';
//import 'tui-editor/dist/tui-editor-contents.css';
//import 'codemirror/lib/codemirror.css';
import { Editor } from '@toast-ui/vue-editor';
import VueCircle from 'vue2-circle-progress';

export default {
    computed: {
        candidateid() {
            return this.$store.getters.currentUser.profileId;
        }
    },
    components: {
        PersonalInfo,
        ContactInfo,
        EmploymentInfo,
        ReferenceInfo,
        TechnologyInfo,
        PreferenceInfo,
        AvailabilityInfo,
        ResumeAttach,
        ProjectInfo,
        Interviews,
        EmailTools,
        Feedback,
        'editor': Editor,
        VueCircle,
        Settings
    },
    data() {
        return {
            elements: {
                feedback: {
                    recruitmentId: null,
                    showFeedback: false
                },
                submitted: false,
                showEmailTools: false
            },
            currentCandidateTab: 0,
            maxtabs: 6,
            candContactAccess: 1,//localStorage.getItem('candidateContactAccess'),
            personalinfo: {
                candidateCode: "",
                firstName: "",
                middlename: "",
                lastName: "",
                email: "",
                mobile: "",
                dateOfBirth: "",
                dateAvailable: "",
                industryName: "",
                recruiterName: "",
                position: "",
                location: "",
                experience: "",
                expType: "",
                expMonth: "",
                referredBy: "",
                payMode: "",
                clientId: "",
                visaTypeId: "",
                visaTypeText: "",
                notes: "",
                educationinfo: [],
                blockpersonaltab: 0,
                viewonly: 1,
                profileCompletion: 0,
                imageName: '',
                imagePath: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
                candidateLanguage: [],
                sourcingChannelName: "",
                sourcingRefLink: "",
                linkedInUrl: "",
                candidateAdditionalFields: [],
                profileCompletion: 0,
                candidateType: "",
                willRelocate: "",
                editIndex: true
            },
            contactinfo: {
                currentaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "US"
                },
                permanentaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "US"
                },
                emergency: [],
                viewonly: 1
            },
            employmentinfo: [],
            // technologyinfo: {
            //     skillset: [],
            //     specialnotes: "",
            //     viewonly: 1
            // },
            technologyinfo: [],
            preferenceinfo: {
                communicationMode: [],
                basepreference: [],
                otherpreference: {
                    timetocall: "",
                    workType: "",
                    expectedSalary: "",
                    minSalary: "",
                    maxSalary: "",
                    frequencyPercentage: "",
                    currency: "$",
                    payFrequency: "Per Month",
                    parttime: "no",
                    fulltime: "no"
                },
                viewonly: 1
            },
            availabilityinfo: {
                viewmode: 1
            },
            resumeinfo: {
                file: '',
                fileurl: '',
                filename: '',
                viewonly: 1
            },
            projectinfo: [],
            interviews: [],
            changeHistoryInfo: [],
            Skills: [],
            showAvailabilityInfo: false,
            colours: ['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info'],
            profilePhotoArg: 1,
            settingsInfo: {
                timeZone: "",
                accessLevel: 1
            },
            form: {
                recruiter_id: this.$store.getters.currentUser.accountId,
                user_id: this.candidateid,
                user_type: "candidate",
                communication_type: "",
                communcation_mode: "",
                notes: "<h6>Add your notes here</h6>"
            },
            showProgress: false,
            fill: { gradient: ["#37A0E4", "#37A0E4", "#808080"] },
            isResumeCapture: false,
            attachments: [],
            attachmentTypes: [],
            multipleFile: [],
        }
    },
    watch: {
        /*interviews(newValue, oldValue) {
            let recId = parseInt(localStorage.getItem('recruitmentId'));
            if (recId) {
                this.currentCandidateTab = 9;
                this.viewFeedback(recId);
            }
        }*/
    },
    mounted() {
        this.loadCandidateById(this.candidateid);
    },
    methods: {
        progress(event, progress, stepValue) {
            // console.log(stepValue);
        },
        progress_end(event) {
            //  console.log("Circle progress end");
        },
        editCandidate: function() {
            this.$router.push('/candidate/edit/' + this.candidateid);
        },
        checkEmpty(obj) {

            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },
        applyPercentage(percentage) {
            return 'max-width:' + percentage + '%'
        },
        loadCandidateById(candidateId) {
            axios.get(this.$store.getters.getAPIBasePath + '/candidateresource/view/candidate/' + candidateId)
                .then(response => {
                    let result = response.data;
                    if (!result.status) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    let self = this;
                    this.personalinfo = result.candidates;
                    this.personalinfo = {
                        candidateCode: result.candidates.candidateCode,
                        firstName: result.candidates.firstName,
                        middlename: result.candidates.middlename,
                        lastName: result.candidates.lastName,
                        email: result.candidates.email,
                        mobile: result.candidates.mobile,
                        dateOfBirth: result.candidates.dateOfBirth,
                        dateAvailable: result.candidates.dateAvailable,
                        industryName: result.candidates.industryName,
                        recruiterName: result.candidates.recruiterName,
                        position: result.candidates.position,
                        location: result.candidates.location,
                        experience: result.candidates.experience,
                        expMonth: result.candidates.expMonth,
                        expType: result.candidates.expType,
                        // expType: (result.candidates.expType == null) ? "Years" : result.candidates.expType,
                        referredBy: result.candidates.referredBy,
                        payMode: (result.candidates.payMode == undefined) ? '' : result.candidates.payMode,
                        clientId: (result.candidates.clientId == undefined) ? '' : result.candidates.clientId,
                        visaTypeId: (result.candidates.visaTypeId == undefined) ? '' : result.candidates.visaTypeId,
                        visaTypeText: result.candidates.visaTypeText,
                        notes: result.candidates.notes,
                        educationinfo: result.candidates.candidateEducation,
                        blockpersonaltab: 0,
                        viewonly: 1,
                        profileid: this.candidateid,
                        imageName: result.candidates.imageName,
                        imagePath: (result.candidates.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : result.candidates.imagePath),
                        candidateLanguage: result.candidates.candidateLanguage,
                        sourcingChannelName: result.candidates.sourcingChannelName,
                        sourcingRefLink: result.candidates.sourcingRefLink,
                        linkedInUrl: result.candidates.linkedInUrl,
                        candidateAdditionalFields: result.candidates.candidateAdditionalFields,
                        profileCompletion: result.candidates.profileCompletion,
                        candidateType: result.candidates.candidateTypeName
                    };
                    this.multipleFile = result.candidates.multipleFileData;
                    result.candidates.candidateContact.forEach(function(data) {
                        if (data.contactType.toLowerCase() == "current") {
                            self.contactinfo.currentaddress = data;
                        } else {
                            self.contactinfo.permanentaddress = data;
                        }
                    });
                    this.contactinfo.emergency = result.candidates.candidateEmergencyContact;
                    this.employmentinfo = result.candidates.candidateEmployment;
                    this.employmentinfo.viewonly = 1;

                    this.projectinfo = result.candidates.candidateProject;
                    this.projectinfo.viewonly = 1;
                    /*this.projectinfo.forEach((info)=>{
                        info['viewonly'] = 1;
                    });*/

                    let techId = 0;
                    let j = 0;
                    for (let i = 0; i < result.candidates.candidateSkill.length; i++) {
                        if (result.candidates.candidateSkill[i].technologyId == techId && this.Skills[j - 1] != null) {

                            this.Skills[j - 1].techSkills.push({ 'skill': result.candidates.candidateSkill[i].title, 'exprYear': result.candidates.candidateSkill[i].years });
                        } else {
                            techId = result.candidates.candidateSkill[i].technologyId;
                            if (result.candidates.candidateSkill[i].technologySet != null) {
                                this.Skills.push({ 'techTitle': result.candidates.candidateSkill[i].technologySet.technology, 'techSkills': [{ 'skill': result.candidates.candidateSkill[i].title, 'exprYear': result.candidates.candidateSkill[i].years }] });
                            }
                            j++;
                        }
                    }
                    //this.technologyinfo.skillset = result.candidates.candidateSkill;

                    let techInfo = [];
                    if (result.candidates.techList != null) {
                        for (let i = 0; i < result.candidates.techList.length; i++) {
                            techInfo.push({
                                technologyName: result.candidates.techList[i].technologyName,
                                years: null,
                                industryName: '',
                                skills: [],
                                isPrimary: (result.candidates.techList[i].isPrimary == null ? false : result.candidates.techList[i].isPrimary)
                            });

                            for (let j = 0; j < result.candidates.techList[i].technologySkillData.length; j++) {
                                if (j == 0) {
                                    techInfo[i].years = result.candidates.techList[i].technologySkillData[0].years;
                                    techInfo[i].industryName = result.candidates.techList[i].technologySkillData[0].industryName;
                                }
                                techInfo[i].skills.push(result.candidates.techList[i].technologySkillData[j].skillName);
                            }

                        }
                    }

                    this.technologyinfo = techInfo;
                    this.preferenceinfo.basepreference = result.candidates.candidatePreference;
                    this.preferenceinfo.otherpreference.timetocall = result.candidates.timeToCall;
                    this.preferenceinfo.otherpreference.workType = result.candidates.workType;
                    this.preferenceinfo.otherpreference.expectedSalary = result.candidates.expectedSalary;
                    this.preferenceinfo.otherpreference.minSalary =  (result.candidates.minSalary == 0.0 ? '' : result.candidates.minSalary);
                    this.preferenceinfo.otherpreference.maxSalary =  (result.candidates.maxSalary == 0.0 ? '' : result.candidates.maxSalary);
                    let freq = (result.candidates.flexibilityPercentage == 0.0 ? '' : result.candidates.flexibilityPercentage);
                    if(freq>0){this.preferenceinfo.otherpreference.frequencyPercentage=freq+"%"}
                    else{
                        this.preferenceinfo.otherpreference.frequencyPercentage = freq;
                    }
                    this.preferenceinfo.otherpreference.currency = result.candidates.currency;
                    this.preferenceinfo.otherpreference.payFrequency = result.candidates.payFrequency;
                    this.preferenceinfo.communicationMode = result.candidates.communication;
                    
                    if (result.candidates.isPartTime) {
                        this.preferenceinfo.otherpreference.parttime = "yes";
                    } else {
                        this.preferenceinfo.otherpreference.parttime = "no";
                    }
                    if (result.candidates.isFullTime) {
                        this.preferenceinfo.otherpreference.fulltime = "yes";
                    } else {
                        this.preferenceinfo.otherpreference.fulltime = "no";
                    }
                    if (result.candidates.fileName != undefined && result.candidates.fileName != "") {
                        this.resumeinfo.filename = result.candidates.fileName;
                        this.resumeinfo.fileurl = result.candidates.filePath;
                    }
                    this.settingsInfo.timeZone = result.candidates.timeZone == null ? '' : result.candidates.timeZone;
                    this.settingsInfo.accessLevel = result.candidates.accessLevel == null ? '' : result.candidates.accessLevel;
                    this.showProgress = true;
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CandidLoadErr,
                        position: 'topRight'
                    });
                });

            axios.get(this.$store.getters.getAPIBasePath + '/interviewerresource/candidate/recruitment/' + candidateId)
                .then(response => {
                    let result = response.data;
                    this.interviews = result.interviews;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        refreshCalendar() {
            this.showAvailabilityInfo = true;
        },
        viewFeedback(recId) {
            this.elements.feedback.recruitmentId = recId;
            this.elements.feedback.showFeedback = true;
        },
        closeFeedback() {
            this.elements.feedback.showFeedback = false;
            if (parseInt(localStorage.getItem('recruitmentId'))) {
                localStorage.setItem('recruitmentId', null);
                this.$router.push('/progress-interviews');
            }
        },
        getPrevUrl() {
            this.$router.go(-1);
        },
        scheduleInterview() {
            localStorage.setItem('recPlanId', null);
            localStorage.setItem('jobId', null);
            localStorage.setItem('recruitmentId', null);
            this.$router.push('/candidates/interview/' + this.candidateid);
        },
        addNotes() {
            this.form.communication_type = "notes";
        },
        addCallback() {
            this.form.communication_type = "callback";
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.elements.submitted = true;
            this.handleSubmit();

        },
        handleSubmit() {
            if (this.elements.submitted) {
                if (!this.checkFormValidity()) {
                    console.log("error");
                    return false;
                }
                this.$nextTick(() => {
                    this.$refs.notesModal.hide();
                });
            } else {
                return false;
            }
        },
        resetModal() {
            this.form.communication_type = "";
            this.form.communcation_mode = "";
            this.form.notes = "<h6>Add your notes here</h6>";
            this.elements.submitted = false;
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity();
            return valid;
        },
        emailtab() {
            this.elements.showEmailTools = true;
        }
    }
}

</script>
