<template>
    <div class="col-md-9 col-sm-9">
        <div class="collapse navbar-collapse" id="demo-navbar">
            <ul class=" mr-auto ul-horizontal main-menu mb-0">
                <!-- <li class="nav-item dropdown active">
                    <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img alt="images" src="@/assets/icons/candidate.png" class="menuicon">
                        <img alt="images" src="@/assets/icons/candidates-hvr.png" class="hovericon">
                        My Profile              
                    </a>
                    
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <//     <router-link :to="{ name: 'candidateprofile' }" class="dropdown-item">Profile</router-link>      //>                
                    </div>
                </li> -->
            </ul>
            <form class="form-inline my-2 my-lg-0">
                <ul class="ul-horizontal userprofile-submenu mb-0">
                    <li v-if="false" class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle " href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="ti-bell"></span>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" href="">notification 1</a>
                            <a class="dropdown-item" href="">notification 2 </a>
                        </div>
                    </li>
                    
                    <li class="">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="">{{userinfo.name}} <i class="fa fa-angle-down"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <router-link :to="{ name: 'candidateprofile' }" class="dropdown-item">Profile</router-link>
                            <router-link :to="{ name: 'changepasswordcandidate' }" class="dropdown-item">Change Password</router-link>
                            <a v-if="!userinfo.havingReferralAccount" class="dropdown-item" v-b-modal.referralAccount href="javascript:;">Add as Referral</a>
                            <a v-if="userinfo.havingReferralAccount" class="dropdown-item" @click="updateUserAccount('referral')" href="javascript:;">Switch to Referral Account</a>
                            <a v-if="!userinfo.havingInterviewerAccount" class="dropdown-item"  v-b-modal.interviewerAccount href="javascript:;">Add as Interviewer</a>
                            <a v-if="userinfo.havingInterviewerAccount" class="dropdown-item" @click="updateUserAccount('interviewer')" href="javascript:;">Switch to Interviewer Account</a>
                            <a style="display: none" class="dropdown-item" href="">Setting</a>
                            <a class="dropdown-item" @click="performLogout" href="Javascript:void(0);">Logout</a>
                        </div>
                    </li>
                </ul>
            </form>
        </div>
        <BDialog id="referralAccount" :onYes="createReferralAccount" title="Referral Account" message="Do you want to register as Referral with the same account?" />
        <BDialog id="interviewerAccount" :onYes="createInterviewerAccount" title="Interviewer Account" message="Do you want to register as Interviewer with the same account?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    mounted() {
        this.InlineButtonClickHandler();
        localStorage.setItem('loggedOut','false');
        //console.log(this.$store.getters.currentUser);
    },
    data() {
        return {
            userinfo: this.$store.getters.currentUser,
            login: {
                email: '',
                password: '',
                switchAccount: true,
            },
            userType: ""
        }
    },
    methods: {
        performLogout() {
            this.$store.dispatch("doLogout");
            localStorage.setItem('loggedOut',true);
            //this.$router.push('/login');
        },
        InlineButtonClickHandler() {
            $(document).ready(function() {
                $('.main-menu > li > div > a').click(function() {
                    $('.main-menu > li').removeClass('active');
                    $(this).parent().parent().addClass('active');
                });
            });
        },
        createReferralAccount: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/referralresource/add/referral/account')
                .then(response => {
                    let result = response.data;
                    this.login.userType = "referral";
                    this.performLogin();

                    if (!response.status) {
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position: 'topRight'
                        });
                    } else {
                        iziToast.success({
                            title: 'Success',
                            message: this.$config.RefRegSuc,
                            position: 'topRight'
                        });

                    }
                })
                .catch(error => {

                    iziToast.error({
                        title: 'Error',
                        message: this.$config.CreRefErr,
                        position: 'topRight'
                    });
                });

        },
        createInterviewerAccount: function() {

            axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/add/interviewer/account')
                .then(response => {

                    let result = response.data;
                    this.login.userType = "interviewer";
                    this.performLogin();

                    if (!response.status) {
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position: 'topRight'
                        });
                    } else {
                        iziToast.success({
                            title: 'Success',
                            message: this.$config.IntRegSuc,
                            position: 'topRight'
                        });

                    }
                })
                .catch(error => {

                    iziToast.error({
                        title: 'Error',
                        message: this.$config.CreInterErr,
                        position: 'topRight'
                    });
                });
        },
        performLogin() {
            this.login.email = this.userinfo.email;
            axios.post(this.$store.getters.getAPIBasePath + '/userresource/authenticate', this.login)
                .then(response => {
                    let result = response.data;
                    if (result.result) {
                        localStorage.setItem(this.$store.getters.getLocalStorageName, response.data.data.token);
                        window.axios.defaults.headers.common['Authorization'] = response.data.data.token;
                        const defaultredirects = this.$store.getters.getDefaultRedirects;
                        this.$store.dispatch("setupLogin", result).then(() => {
                            if (result.data.userType == "interviewer") {
                                this.$router.push(defaultredirects.interviewer);
                            } else if (result.data.userType == "candidate") {
                                this.$router.push(defaultredirects.candidate);
                            } else if (result.data.userType == "admin") {
                                //this.$router.push(defaultredirects.admin);
                                if (result.data.isSuperAdmin) {
                                    this.$router.push({ path: defaultredirects.superadmin });
                                } else {
                                    this.$router.push({ path: defaultredirects.admin });
                                }
                            } else if (result.data.userType == "guest") {
                                        this.$router.push({ path: defaultredirects.guest });
                            } else if (result.data.userType == "referral") {
                                this.$router.push({ path: defaultredirects.referral });
                            } else {
                                //if (result.data.recruitOwner)
                                if (result.data.recruitType == 2 || result.data.recruitType == 3) 
                                    this.$router.push(defaultredirects.recruiteradmin);
                                else
                                    this.$router.push(defaultredirects.recruiter);
                            }
                            this.$router.go(0);
                        });
                    } else {
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.InvCredential,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.TryAg,
                        position: 'topRight'
                    });
                });
        },
        updateUserAccount: function(value) {
            this.userType = value;
            axios.post(this.$store.getters.getAPIBasePath + '/userresource/update/usertype/' + this.userType)
                .then(response => {
                    let result = response.data;
                    if (response.data.status == "OK") {
                        this.login.userType = this.userType;
                        this.performLogin();
                    }
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error',
                        message: 'Unable to switch account , please try one more time',
                        position: 'topRight'
                    });
                });
        }
    }
}

</script>
