<template>
        <div class="card">
            <div class="card-header p-2">Add Previous Employment History</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col">Company Name</th>
                                            <th class="videoprofile" scope="col">Position</th>
                                            <th class="recruiter" scope="col">Technology/Skills</th>
                                            <th class="title" scope="col">City</th>
                                            <th class="state" scope="col">State</th>
                                            <th class="phone" scope="col">From</th>
                                            <th class="lastnote" scope="col">To</th>
                                            <th class="" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="cloned_tr">
                                    	<tr v-for="(info, index) in employmentinfo" :class="{ 'viewonly' : employmentinfo.viewonly == 1}">
                                    		<td>
                                                <label>{{employmentinfo[index].companyName}}</label>
                                    		</td>
                                            <td class="lastnote">
                                                <label>{{employmentinfo[index].jobTitle}}</label>
                                            </td>
                                            <td class="recruiter">
                                                <div class="position-relative">
                                                    <label>{{employmentinfo[index].skillSet}}</label>
                                                </div>
                                            </td>
                                            <td class="title">
                                                <label>{{employmentinfo[index].city}}</label>
                                            </td>
                                            <td class="specialityunit">
                                                <label>{{employmentinfo[index].stateName}}</label>
                                            </td>
                                            <td class="currentcity">
                                                <label>{{employmentinfo[index].employedFrom | formatDate}}</label>
                                            </td>
                                            <td class="state">
                                                <label>{{employmentinfo[index].employedTo | formatDate}}</label>
                                            </td>
                                            <td>
                                                <a class="removemoretextbox" :class="{ 'removeaddbtn' : employmentinfo.viewonly == 1}" href="Javascript:void(0);" @click="confirmCompany(index,0)" data-toggle="modal" data-target="#removCompany"><span class="ti-trash"></span></a>
                                            </td>
                                    	</tr>
                                        <tr :class="{'norecordfound': employmentinfo.length}"><td colspan="8" align="center">No item found</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default{
        props: {
            employmentinfo: Array,
        }
	}
</script>