<template>
	<div id="main" class="tool-demo recruiter mt-70">
		<div class="row col-md-12 box-wrapper shadow-sm my-4 mx-0 px-0 rounded pos-rel">
			<div class="col-md-3 settings-leftbar px-0 bg-white">
				<div class="p-4 row header mx-0">
					<div class="col-md-9 px-0"><h5>Tool Demo</h5></div>
					<div class="col-md-3 px-0 text-right">
						<router-link :to="recownerid==0?{name: 'candidatesdashboard'}:{name: 'rmdashboard'}">
							<h5 class="text-blue"><span class="ti-arrow-left py-4" title="Go Back"></span><span class="pl-2">Back</span></h5>
						</router-link>
					</div>
				</div>
				<!-- Left Tab for Module List -->
				<div v-for="Module in list.moduleList" v-bind:key="Module.id" class="row col-md-12 px-0 mx-0">
					<div @click="setDemoData(Module.id)" class="row col-md-12 py-4 mx-0 left-bar-tab"  :class="elements.activeTab==Module.id?'left-tab-active': Module.id ">
						<div class="col-md-3">
							<img class="p-1" :src="Module.moduleIcon | getFilePath" width="100%" height="100%">
						</div>
						<div class="col-md-8 align-self-center">{{ Module.moduleName }}</div>
					</div>
				</div>
			</div>
			<div class="col-md-9">
				<div class="basic-block bg-white" style="min-height: 392px;">
					<div class="p-4 header mx-0 mb-n1">
						<div class="row col-md-12 mx-0 px-0">
							<div class="col-md-6 col-sm-6 col-6">
								<h4 class="page-title">Search</h4>
							</div>
							<div class="col-md-6 col-sm-6 col-6">
								<ul class="ul-horizontal box-tools text-right mb-0">
									<li><a href="Javascript:void(0);" v-on:click="elements.isExpand=!elements.isExpand;" data-target="main-search" class="boxhide"><span :class="elements.isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
								</ul>
							</div>
						</div>
						<form id="main-search" class="pt-3" :class="elements.isExpand?'d-block':'d-none'">
							<div class="row form-group ten-columns">
								<div class="col-md-3 col-sm-6 col-12 mb-10-mr">
									<div class="row">
										<div class="col-md-12 col-sm-12 col-12 lb">
											<label>Functionality</label>
										</div>
										<div class="col-md-12 col-sm-12 col-12">
											<div class="input-group">
												<input class="form-control" type="text" />
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-2 offset-md-7 align-self-center">
									<button type="button" class="btn btn-primary btn-sm float-right mx-2">Reset</button>
									<button type="button" class="btn btn-primary btn-sm float-right mx-2">Find</button>
								</div>
							</div>
						</form>
					</div>
					<MediaContainer :mediaInfo="list.moduleFunctionalityList" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MediaContainer from './blocks/tooldemo/MediaContainer';
	export default {
		components: {
			MediaContainer,
		},
		data(){
			return {
				elements: {
					isExpand: false,
					activeTab: 0
				},
				list: {
					moduleList: [],
					moduleFunctionalityList: []
				},
				recownerid : this.$store.getters.currentUser.recruitOwner
			}
		},
		mounted(){
			this.loadModules();
		},
		methods: {
			loadModules: function() {
				axios.get(this.$store.getters.getAPIBasePath + '/settingsresource/module/list')
				.then(response => {
					if (response.data.status) {
						this.list.moduleList = response.data.moduleList;
						this.loadModuleFunctionalities(this.list.moduleList[0].id);
						this.elements.activeTab = this.list.moduleList[0].id;
					}
				})
				.catch(error => {
					console.log(error);
				});
			},
			loadModuleFunctionalities: function(moduleId) {
				axios.get(this.$store.getters.getAPIBasePath + '/settingsresource/modulefunctionality/list/'+moduleId)
				.then(response => {
					if (response.data.status) {
						this.list.moduleFunctionalityList = response.data.moduleFunctionalityList;
					}
				})
				.catch(error => {
					console.log(error);
				});
			},
			setDemoData: function(activeTab) {
				this.elements.activeTab = activeTab;
				this.loadModuleFunctionalities(activeTab);
			}
		}
	}
</script>
